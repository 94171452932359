import { admin, superAdmin } from '../../Roles';
import Courses from '../../../views/CourseLearning/CourseManager/Courses';
import Topics from '../../../views/CourseLearning/CourseManager/Topics';
import LearningGoals from '../../../views/CourseLearning/CourseManager/LearningGoals';
import Activities from '../../../views/CourseLearning/CourseManager/Activities';
import GradingTables from '../../../views/CourseLearning/CourseManager/GradingTables';
import Evaluations from '../../../views/CourseLearning/CourseManager/Evaluations';

export default [
    {
        path: '/Courses',
        name: 'courses',
        component: Courses,
        meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionSet: ['MANAGE_COURSE_INSTANCES'],
            requiresRole: true,
            roleSet: [admin, superAdmin],
            navigation: {
                type: { name: 'SingleGroup', groupId: 'Lernmanagement' },
                priority: 0,
                icon: 'mdi-head-cog-outline',
                name: 'Kursmanager',
                navigationKey: 'COURSE_MANAGER'
            }
        }
    },
    {
        path: '/Courses/Topics',
        name: 'topics',
        component: Topics,
        meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionSet: ['MANAGE_COURSE_INSTANCES'],
            requiresRole: true,
            roleSet: [admin, superAdmin]
        }
    },
    {
        path: '/Courses/GradingTables',
        name: 'gradingTables',
        component: GradingTables,
        meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionSet: ['MANAGE_COURSE_INSTANCES'],
            requiresRole: true,
            roleSet: [admin, superAdmin]
        }
    },
    {
        path: '/Courses/Topics/LearningGoals',
        name: 'learningGoals',
        component: LearningGoals,
        meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionSet: ['MANAGE_COURSE_INSTANCES'],
            requiresRole: true,
            roleSet: [admin, superAdmin]
        }
    },
    {
        path: '/Courses/Topics/LearningGoals/Activities',
        name: 'activities',
        component: Activities,
        meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionSet: ['MANAGE_COURSE_INSTANCES'],
            requiresRole: true,
            roleSet: [admin, superAdmin]
        }
    },
    {
        path: '/Courses/Evaluations',
        name: 'evaluations',
        component: Evaluations,
        meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionSet: ['MANAGE_COURSE_INSTANCES'],
            requiresRole: true,
            roleSet: [admin, superAdmin]
        }
    }
];
