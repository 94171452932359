import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters('UserGroupsOp', ['getUserGroup', 'getUserGroupTree', 'getUserGroups', 'getSelectedUserGroup', 'getUserGroupUsers'])
    },
    methods: {
        ...mapActions('User', ['GET_USER_LIST']),
        ...mapActions('UserGroupsOp', [
            // GET
            'GET_USER_GROUP_ROOT',
            'GET_USER_SUBGROUPS',
            'GET_USER_GROUP_TREE',
            'GET_USER_GROUP_MEMBERS',
            'GET_USER_GROUPS',
            'GET_ALL_USER_GROUP_USERS',
            'GET_GROUP_LEADERS',
            // SET
            'SET_SELECTED_USER_GROUP',
            'SET_USER_GROUP_USERS',
            'SET_GROUP_LEADERS',
            // ADD
            'ADD_USER_GROUP',
            'ADD_USER_SUBGROUP',
            // UPDATE
            'UPDATE_USER_GROUP',
            // DELETE
            'DELETE_USER_GROUP'
        ])
    }
};
