<template>
    <v-data-iterator
        :items="getSelectedCustomer.admins"
        :no-data-text="$t('Keine Admins verfügbar')"
        :no-results-text="$t('Kein Ergebnis')"
        :items-per-page="5"
        :footer-props="{
            itemsPerPageText: $t('Admins pro Seite'),
            'items-per-page-options': [5, 10, 20, { text: $t('Alle'), value: -1 }]
        }"
        :hideDefaultFooter="getSelectedCustomer.admins.length <= 5"
    >
        <template v-slot:item="{ item: admin }">
            <v-list-item @click="getInfo(admin)">
                <v-list-item-content>
                    <v-list-item-title v-html="admin.email" />
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-data-iterator>
</template>
<script>
import CustomerManagerMixin from '../Mixins/CustomerManagerMixin';

export default {
    name: 'admin-list',
    mixins: [CustomerManagerMixin],
    props: {
        type: {
            type: String,
            default: 'WL2'
        }
    },
    methods: {
        getInfo(admin) {
            this.$emit('getAdminInfo', admin);
        }
    }
};
</script>
