<template>
    <v-menu
        v-model="showCourseUsers"
        :close-on-content-click="false"
        :width="$vuetify.breakpoint.thresholds.xs"
        offset-y
        :min-width="$vuetify.breakpoint.thresholds.xs"
    >
        <template v-slot:activator="{ on: menu }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn icon v-on="{ ...tooltip, ...menu }">
                        <v-icon>{{ computedIcon }}</v-icon>
                    </v-btn>
                </template>
                <span>{{ computedTitle }}</span>
            </v-tooltip>
        </template>

        <v-card text :width="$vuetify.breakpoint.thresholds.xs">
            <v-toolbar dense flat dark color="secondary">
                <v-toolbar-title>{{ computedTitle }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text style="max-height: 50vh; overflow-y: auto">
                <v-data-iterator
                    :no-data-text="$t(`Keine ${computedTitle} verfügbar`)"
                    :no-results-text="$t(`Kein Ergebnis`)"
                    :items="computedUserAdminData"
                    :hide-default-footer="computedUserAdminData.length <= 10"
                >
                    <template #default="{ items }">
                        <v-list>
                            <v-list-item v-for="item in items" :key="item.id">
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.displayName }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </template>
                </v-data-iterator>
            </v-card-text>
        </v-card>
    </v-menu>
</template>
<script>
import CourseMixin from './Mixins/CourseMixin';
export default {
    name: 'course-admin-user',
    mixins: [CourseMixin],
    data: () => ({
        userAdminIcon: '',
        title: '',
        showCourseUsers: false,
        courseUsers: {
            users: [],
            admins: []
        }
    }),
    watch: {
        showCourseUsers(val) {
            if (val && (this.courseUsers.users.length === 0 || this.courseUsers.admins.length === 0)) {
                this.GET_COURSE_ADMINS_AND_ATTENDEES(this.value).then((response) => (this.courseUsers = response));
            }
        }
    },
    props: {
        value: {
            type: [Array, Object],
            default: () => []
        },
        isAdmin: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedTitle() {
            return this.isAdmin ? `${this.$t('Lehrende')}` : `${this.$t('Lernende')}`;
        },
        computedIcon() {
            return this.isAdmin ? 'mdi-account-multiple' : 'mdi-account-multiple-outline';
        },
        computedUserAdminData() {
            return this.isAdmin ? this.courseUsers.admins : this.courseUsers.users;
        }
    }
};
</script>
