import axios from '../../plugins/Axios/axios';
import Vue from 'vue';

const state = {
    wikiMap: {},
    wikiTree: [],
    selectedWiki: {},
    proposals: [],
    wikiHome: '',
    myWiki: [],
    myCommissions: [],
    view: {
        component: '',
        type: '',
        data: {}
    }
};

// getters
const getters = {
    getWikisWithProposals(state) {
        return state.proposals;
    },
    getWikiList(state) {
        return Object.values(state.wikiMap);
    },
    getWikiTree(state) {
        return state.wikiTree;
    },
    getSelectedWiki(state) {
        return state.selectedWiki;
    },
    getWiki(state) {
        return (id) => {
            if (state.wikiMap.hasOwnProperty(id)) {
                return state.wikiMap[id];
            }
            return undefined;
        };
    },
    getWikiHome(state) {
        return state.wikiHome;
    },
    getMyWiki(state, getters) {
        return state.myWiki.map((id) => getters.getWiki(id));
    },
    getMyCommissions(state, getters) {
        return state.myCommissions.map((id) => getters.getWiki(id));
    },
    getView(state) {
        return state.view;
    }
};

// actions
const actions = {
    ADD_WIKI({ commit, dispatch }, request) {
        return axios
            .post('/wiki', request)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateWikiMap', response);
                dispatch('GET_WIKI_TREE').then(() => commit('updateSelectedWiki', response));
                dispatch('GET_MOST_RECENT_WIKI');
                dispatch('GET_MOST_VIEWED_WIKI');
                return response;
            });
    },
    GET_WIKI_LIST({ commit }) {
        return axios
            .get('/wiki')
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateWikiMap', response);
                return response;
            });
    },
    GET_WIKI_TREE({ commit }) {
        return axios
            .get('/wiki/treeview')
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateWikiTree', response);
                return response;
            });
    },
    GET_WIKI_SUBTREE({ commit }, { id: wikiId }) {
        return axios.get(`/wiki/${wikiId}/treeview`).then((response) => response.data.data);
    },
    GET_SINGLE_WIKI({ commit }, { id: wikiId }) {
        return axios
            .get(`/wiki/${wikiId}`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateWikiMap', response);
                return response;
            });
    },
    DELETE_WIKI({ commit, dispatch }, { id: wikiId }) {
        return axios.delete(`/wiki/${wikiId}`).then(() => {
            commit('deleteWiki', wikiId);
            dispatch('GET_WIKI_TREE');
            dispatch('GET_MOST_VIEWED_WIKI');
            dispatch('GET_MOST_RECENT_WIKI');
        });
    },
    UPDATE_WIKI({ commit, dispatch }, request) {
        return axios
            .patch(`/wiki/${request.id}`, request)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateWikiMap', response);
                dispatch('GET_WIKI_TREE').then(() => commit('updateSelectedWiki', response));
                return response;
            });
    },
    GET_WIKI_WITH_PROPOSALS({ commit }) {
        return axios
            .get(`/wiki/proposal`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateWikiProposals', response);
                return response;
            });
    },
    GET_PROPOSALS({ commit }, { id }) {
        return axios.get(`/wiki/${id}/proposals`).then((response) => response.data.data);
    },
    ACCEPT_PROPOSAL({ commit }, { wiki: { id: wikiId }, proposal: { id: proposalId } }) {
        return axios
            .post(`/wiki/${wikiId}/proposals/${proposalId}`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateWikiMap', response);
                return response;
            });
    },
    GET_MOST_VIEWED_WIKI({ commit }) {
        return axios.get(`/wiki/mostviewed`).then((response) => response.data.data);
    },
    GET_MOST_RECENT_WIKI({ commit }) {
        return axios
            .get(`/wiki/recent`)
            .then((response) => response.data.data)
            .then((response) => {
                if (response.name !== null && response.body !== null) {
                    return response;
                }
                return {};
            });
    },
    SET_SELECTED_WIKI({ commit, dispatch }, wiki) {
        if (!wiki.hasOwnProperty('id')) {
            commit('updateSelectedWiki', {});
            return Promise.resolve();
        }
        return dispatch('GET_SINGLE_WIKI', wiki).then((response) => {
            commit('updateSelectedWiki', response);
            return response;
        });
    },
    GET_WIKI_HOME({ commit }) {
        return axios
            .get(`/wiki/home`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateWikiHome', response);
            });
    },
    UPDATE_WIKI_HOME({ commit }, wiki) {
        return axios
            .patch(`/wiki/home`, wiki)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateWikiHome', response);
            });
    },
    WIKI_CHANGE_STATE({ commit, dispatch }, { wiki: { id: wikiId }, state }) {
        return axios
            .patch(`/wiki/${wikiId}/changeState`, state)
            .then((response) => response.data.data)
            .then((response) => {
                dispatch('GET_WIKI_TREE')
                    .then(() => dispatch('GET_MOST_VIEWED_WIKI'))
                    .then(() => dispatch('GET_MOST_RECENT_WIKI'))
                    .then(() => commit('updateWikiMap', response));
                return response;
            });
    },
    GET_MY_WIKI({ commit }) {
        return axios
            .get(`/wiki/myWiki`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateMyWiki', response);
                return response;
            });
    },
    GET_MY_COMMISSIONS({ commit }) {
        return axios
            .get(`/wiki/myCommissions`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateMyCommissions', response);
                return response;
            });
    },
    SET_VIEW({ commit }, view) {
        commit('updateView', view);
    }
};

// mutations
const mutations = {
    clear: (state) => {
        state.wikiMap = {};
        state.wikiTree = [];
        state.selectedWiki = {};
    },
    deleteWiki: (state, wikiId) => {
        state.wikiTree = state.wikiTree.filter((wiki) => wiki.id !== wikiId);
        if (state.wikiMap.hasOwnProperty(wikiId)) {
            Vue.delete(state.wikiMap, wikiId);
        }
        if (state.selectedWiki.hasOwnProperty('id')) {
            if (state.selectedWiki.id === wikiId) {
                state.selectedWiki = {};
            }
        }
    },
    updateMyWiki: (state, data) => (state.myWiki = data.map(({ id }) => id)),
    updateMyCommissions: (state, data) => (state.myCommissions = data.map(({ id }) => id)),
    updateWikiHome: (state, data) => (state.wikiHome = data),
    updateWikiTree: (state, data) => (state.wikiTree = data),
    updateWikiProposals: (state, data) => (state.proposals = data),
    updateWikiMap: (state, data) => {
        if (Array.isArray(data)) {
            Vue.set(
                state,
                'wikiMap',
                data.reduce((accu, { id, ...rest }) => {
                    accu[id] = { id, ...rest };
                    return accu;
                }, {})
            );
        } else {
            if (data instanceof Object) {
                if (state.selectedWiki.id === data.id) {
                    state.selectedWiki = data;
                }
                Vue.set(state.wikiMap, data.id, data);
            }
        }
    },
    updateSelectedWiki: (state, selectedWiki) => {
        state.selectedWiki = selectedWiki;
    },
    updateSelectedParentWiki: (state, selectedParentWiki) => {
        state.selectedParentWiki = selectedParentWiki;
    },
    updateView: (state, view) => {
        Vue.set(state, 'view', view);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
