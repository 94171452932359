<template>
    <v-card flat>
        <v-toolbar dense flat dark color="secondary">
            <v-toolbar-title>{{ $t('Gruppennachrichten') }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
                <v-btn icon @click="toggleMessagesSearch">
                    <v-icon>mdi-magnify</v-icon>
                </v-btn>
                <v-menu :close-on-content-click="false" offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <ChatNotificationsConfig />
                </v-menu>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-iterator
            :items="getGroupIds"
            :no-data-text="$t('Keine Gruppen verfügbar')"
            :no-results-text="$t('Kein Ergebnis')"
            :hideDefaultFooter="getGroupIds.length === 0"
            :items-per-page="5"
            :footer-props="{
                'item-per-page-text': $t('Gruppen pro Seite'),
                'items-per-page-options': [5, 10, 15, 20, 25]
            }"
            :custom-filter="filterItems"
            :search="search"
        >
            <template v-slot:header>
                <v-expand-transition>
                    <v-card text v-show="showSearch">
                        <v-card-text>
                            <v-text-field
                                prepend-icon="mdi-magnify"
                                :label="$t('Gruppen durchsuchen')"
                                :hint="$t('Geben Sie Begriffe oder Schlagwörter ein')"
                                v-model="search"
                                color="accent"
                            />
                        </v-card-text>
                    </v-card>
                </v-expand-transition>
            </template>
            <template #default="{ items }">
                <v-list dense>
                    <v-list-item-group v-model="getSelectedChatGroup.id">
                        <v-list-item v-for="groupId in items" :key="groupId" :value="groupId" @click="handleClick(groupId)">
                            <v-list-item-icon>
                                <v-container fluid>
                                    <v-row align="center" justify="center">
                                        <v-col :cols="12">
                                            <v-badge :value="getUnreadMessages(groupId) !== 0" color="red" left overlap>
                                                <template v-slot:badge>
                                                    <span>{{ getUnreadMessages(groupId) }}</span>
                                                </template>
                                                <v-card rounded color="primary">
                                                    <span class="white--text px-2">{{ groupInitials(groupId) }}</span>
                                                </v-card>
                                            </v-badge>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ getGroup(groupId).name }}</v-list-item-title>
                                <v-list-item-subtitle>{{ getGroup(groupId).description }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <NotificationMenu :value="groupId" :isUser="true" />
                            </v-list-item-action>
                            <v-list-item-action>
                                <FavouriteMenu :value="groupId" />
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </template>
        </v-data-iterator>
    </v-card>
</template>

<script>
import ChatMixin from '../Mixins/ChatMixin';
import ChatNotificationsConfig from '../ChatNotificationsConfig';
import NotificationMenu from '../NotificationMenu';
import FavouriteMenu from '../ChatFavouriteUserMenu';
export default {
    name: 'personal-chat-drawer',
    mixins: [ChatMixin],
    components: {
        ChatNotificationsConfig,
        NotificationMenu,
        FavouriteMenu
    },
    data: () => ({
        showSearch: false,
        search: '',
        selectedGroup: 0
    }),
    methods: {
        handleClick(value) {
            this.$emit('input', this.getGroup(value));
            this.$emit('selected');
        },
        toggleMessagesSearch() {
            this.showSearch = !this.showSearch;
        },
        groupInitials(groupId) {
            const name = this.getGroup(groupId).name;
            const split = name.split(' ').map((word) => word.charAt(0).toUpperCase());
            return split.length > 2 ? split.slice(0, 3).join('') : split.join('');
        },
        filterItems(items, search) {
            if (search.trim() === '') {
                return items;
            }
            let buildString = search
                .split(' ')
                .filter((word) => word !== '')
                .map((word) => `(?=.*${word})`)
                .join('');
            let regex = new RegExp(buildString, 'i');

            const test = this.getGroups.filter((item) => regex.test(item.name) || regex.test(item.description)).map((group) => group.id);
            return items.filter((item) => test.includes(item));
        }
    }
};
</script>
