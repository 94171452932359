<template>
    <v-container fluid ma-0 pa-0 fill-height>
        <v-layout column>
            <v-flex shrink>
                <Toolbar :toolbarTitle="$t('Admin / Lehrende')" tooltipMessage="Hier können Sie Admin betrachten, hinzufügen, bearbeiten und löschen." />
            </v-flex>
            <v-flex grow>
                <Users admin />
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
import Users from '../../../Components/Users/Prototype/Users';
import Toolbar from '../../../Components/Generic/Toolbar';

export default {
    name: 'admins',
    components: { Users, Toolbar }
};
</script>
