<template>
    <v-card flat outlined>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col :cols="12">
                        <span class="text-caption">{{ `${formatDate(value.createdAt)}` }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col :cols="12">
                        <TextEditor v-model="editText" :loadFilemanager="false" />
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn color="warning" icon @click="save">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-content-save</v-icon>
                    </template>
                    <span>{{ $t('Änderung übernehmen') }}</span>
                </v-tooltip>
            </v-btn>
            <v-btn color="error" icon @click="cancel">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-close</v-icon>
                    </template>
                    <span>{{ $t('Abbrechen') }}</span>
                </v-tooltip>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import moment from 'moment';
import ForumMixin from '../Mixins/GroupForumMixin';
import TextEditor from '../../Generic/TextEditor';

export default {
    name: 'thread-edit-answer',
    mixins: [ForumMixin],
    components: { TextEditor },
    data: () => ({
        editText: ''
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        serialize() {
            return { ...this.value, text: this.editText };
        },
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD HH:mm');
        },
        cancel() {
            this.$emit('edit', false);
        },
        save() {
            this.UPDATE_ANSWER({
                group: this.getSelectedGroup,
                forum: this.getSelectedForum,
                thread: this.getSelectedThread,
                answer: this.serialize()
            }).then(() => {
                this.$emit('edit', false);
            });
        }
    },
    mounted() {
        this.editText = this.value.text;
    }
};
</script>
