<template>
    <v-card flat>
        <v-container>
            <v-row>
                <v-col :cols="12">
                    <v-alert type="info">
                        {{
                            $t(
                                'Halten Sie die Formatierung Ihrer Zertifikate einfach. Stellen Sie sicher, dass Bilder und andere Medien nicht überlappen. Testen Sie Ihr eigenes Zertifikat.'
                            )
                        }}
                    </v-alert>
                </v-col>
                <v-col :cols="12" :sm="6">
                    <v-card height="100%">
                        <v-toolbar dark dense flat color="secondary">
                            <v-toolbar-title>{{ $t('Zertifikat Vorlage') }}</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="text-center">
                            <v-btn color="primary" @click="downloadCertificateTemplate()">
                                {{ $t('Vorlage runterladen') }}
                            </v-btn>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col :cols="12" :sm="6">
                    <v-card height="100%">
                        <v-toolbar dark dense flat color="secondary">
                            <v-toolbar-title>{{ $t('Zertifikat hochladen') }}</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <file-picker v-model="file" :label="$t('Zertifikat')" accept=".docx" />
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col :cols="12">
                    <v-card height="100%">
                        <v-toolbar dark dense flat color="secondary">
                            <v-toolbar-title>{{ $t('Zertifikat Schriftart') }}</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-row align="center">
                                <v-col>
                                    <v-select v-model="selectedFont" :items="fonts" :label="$t('Schriftart')" hide-details />
                                </v-col>
                                <v-col cols="auto">
                                    <v-tooltip bottom>
                                        <template #activator="{ on, attrs }">
                                            <v-btn v-on="on" v-bind="attrs" icon @click="downloadCertificateTemplateWithFont">
                                                <v-icon>mdi-download</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('Zertifikat runterladen') }}</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col :cols="12">
                    <v-card height="100%">
                        <v-toolbar dark dense flat color="secondary">
                            <v-toolbar-title>{{ $t('Zertifikat Variablen') }}</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col :cols="12">
                                    <span>{{
                                        $t('Nutzen Sie die folgenden Text-Vorlagen in Ihrem Zertifikat. Formatierung und Style werden beibehalten.')
                                    }}</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col :cols="12" :sm="6" :md="4">
                                    <v-card height="100%">
                                        <v-card-text class="row no-gutters">
                                            <span class="col-12 text-h6 primary--text">User_Name</span>
                                            <span class="col-12">{{ $t("Ersetzt jedes Vorkommen von 'User_Name' mit dem Vor- und Nachnamen vom User.") }}</span>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col :cols="12" :sm="6" :md="4">
                                    <v-card height="100%">
                                        <v-card-text class="row no-gutters">
                                            <span class="col-12 text-h6 primary--text">User_Personal_Number</span>
                                            <span class="col-12">{{
                                                $t("Ersetzt jede Vorkommen von 'Personal_Number' mit der Personalnummer vom User.")
                                            }}</span>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col :cols="12" :sm="6" :md="4">
                                    <v-card height="100%">
                                        <v-card-text class="row no-gutters">
                                            <span class="col-12 text-h6 primary--text">Company_Name</span>
                                            <span class="col-12">{{ $t("Ersetzt jedes Vorkommen von 'Company_Name' mit Ihrem Firmennamen.") }}</span>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col :cols="12" :sm="6" :md="4">
                                    <v-card height="100%">
                                        <v-card-text class="row no-gutters">
                                            <span class="col-12 text-h6 primary--text">Certificate_Date</span>
                                            <span class="col-12">{{ $t("Ersetzt jedes Vorkommen von 'Certificate_Date' mit dem Ausstellungsdatum.") }}</span>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col :cols="12" :sm="6" :md="4">
                                    <v-card height="100%">
                                        <v-card-text class="row no-gutters">
                                            <span class="col-12 text-h6 primary--text">Task_Name</span>
                                            <span class="col-12">{{ $t("Ersetzt jedes Vorkommen von 'Task_Name' mit der Aufgabenbezeichnung.") }}</span>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col :cols="12" :sm="6" :md="4">
                                    <v-card height="100%">
                                        <v-card-text class="row no-gutters">
                                            <span class="col-12 text-h6 primary--text">Task_Start_Date</span>
                                            <span class="col-12">{{
                                                $t("Ersetzt jedes Vorkommen von 'Task_Start_Date' mit dem Anfangsdatum der Aufgabe.")
                                            }}</span>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col :cols="12" :sm="6" :md="4">
                                    <v-card height="100%">
                                        <v-card-text class="row no-gutters">
                                            <span class="col-12 text-h6 primary--text">Task_End_Date</span>
                                            <span class="col-12">{{ $t("Ersetzt jedes Vorkommen von 'Task_End_Date' mit dem Enddatum der Aufgabe.") }}</span>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col :cols="12" :sm="6" :md="4">
                                    <v-card height="100%">
                                        <v-card-text class="row no-gutters">
                                            <span class="col-12 text-h6 primary--text">KU_Count</span>
                                            <span class="col-12">{{
                                                $t("Ersetzt jedes Vorkommen von 'KU_Count' mit der Gesamtanzahl der Wissenseinheiten in der Aufgabe.")
                                            }}</span>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col :cols="12" :sm="6" :md="4">
                                    <v-card height="100%">
                                        <v-card-text class="row no-gutters">
                                            <span class="col-12 text-h6 primary--text">Correct_KU_Count</span>
                                            <span class="col-12">{{
                                                $t(
                                                    "Ersetzt jedes Vorkommen von 'Correct_KU_Count' mit der Gesamtanzahl von richtig beantworteten Wissenseinheiten."
                                                )
                                            }}</span>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col :cols="12" :sm="6" :md="4">
                                    <v-card height="100%">
                                        <v-card-text class="row no-gutters">
                                            <span class="col-12 text-h6 primary--text">Incorrect_KU_Count</span>
                                            <span class="col-12">{{
                                                $t(
                                                    "Ersetzt jedes Vorkommen von 'Incorrect_KU_Count' mit der Gesamtanzahl von falsch beantworteten Wissenseinheiten."
                                                )
                                            }}</span>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col :cols="12" :sm="6" :md="4">
                                    <v-card height="100%">
                                        <v-card-text class="row no-gutters">
                                            <span class="col-12 text-h6 primary--text">Completed_On</span>
                                            <span class="col-12">{{
                                                $t("Ersetzt jedes Vorkommen von 'Completed_On' mit dem Datum, an der die Aufgabe erledigt worden ist.")
                                            }}</span>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col :cols="12" :sm="6" :md="4">
                                    <v-card height="100%">
                                        <v-card-text class="row no-gutters">
                                            <span class="col-12 text-h6 primary--text">Catalog_Name</span>
                                            <span class="col-12">{{
                                                $t(
                                                    "Ersetzt jedes Vorkommen von 'Catalog_Name' mit einer Komma separierten Auflistung von Katalogen, die in der Aufgabe verwendet werden."
                                                )
                                            }}</span>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-card-actions>
            <v-btn block color="success" @click="save" :disabled="!isSavable">
                {{ $t('Vorlage speichern') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import SystemSettingsMixin from '../../../Components/SystemSettings/Mixins/SystemSettingsMixin';
import FilePicker from '../../../Components/Generic/FilePicker/FilePicker';
import { saveAs } from 'file-saver';

export default {
    name: 'certificate',
    components: { FilePicker },
    mixins: [SystemSettingsMixin],
    data: () => ({
        showList: false,
        file: {},
        fonts: [],
        selectedFont: '',
        oldFont: ''
    }),
    computed: {
        hasFile() {
            return Object.keys(this.file).length > 0;
        },
        hasFont() {
            return this.selectedFont !== this.oldFont;
        },
        isSavable() {
            return this.hasFile || this.hasFont;
        }
    },
    methods: {
        downloadCertificateTemplateWithFont() {
            this.TEST_CERTIFICATE_WITH_FONT(this.selectedFont).then((response) => saveAs(response, this.$t('Vorschau.pdf')));
        },
        downloadCertificateTemplate() {
            saveAs('/resources/Musterzertifikat.docx', this.$t('MusterZertifikat.docx'));
        },
        save() {
            if (this.hasFile) {
                this.file[0].arrayBuffer().then((resp) => {
                    let file = new File([resp], 'certificate.docx', {
                        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                    });
                    return this.UPLOAD_CERTIFICATE(file);
                });
            }
            if (this.hasFont) {
                this.SET_FONTS({ font: this.selectedFont }).then(() => {
                    this.oldFont = this.selectedFont;
                });
            }
        }
    },
    beforeMount() {
        this.GET_FONTS().then(({ systemFonts, font }) => {
            this.fonts = ['Standard', ...systemFonts];
            this.selectedFont = font;
            this.oldFont = font;
        });
    }
};
</script>
