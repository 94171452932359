<template>
    <v-stepper v-model="stepper" non-linear :elevation="0">
        <v-stepper-header>
            <v-stepper-step editable :step="1">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <span v-on="on">{{ $t('Nutzer auswählen') }}</span>
                    </template>
                    <span>{{ $t('Selektieren Sie eine Menge von Nutzer, für der Sie Archiv-Kataloge zuweisen wollen') }}</span>
                </v-tooltip>
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :editable="isSelected" :step="2">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <span v-on="on">{{ $t('Kataloge auswählen') }}</span>
                    </template>
                    <span>{{ $t('Selektieren Sie Kataloge, die Sie den selektierten Nutzern zuweisen wollen') }}</span>
                </v-tooltip>
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :editable="isSelected" :step="3">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <span v-on="on">{{ $t('Archive verwalten') }}</span>
                    </template>
                    <span>{{ $t('Prüfen Sie Ihre Änderungen und anschließend können Sie die Änderungen speichern') }}</span>
                </v-tooltip>
            </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content :step="1">
                <v-card>
                    <UserGroupSelector v-model="selectedComputed" />
                    <v-card-actions>
                        <v-btn color="secondary" block @click="next" :disabled="!isSelected">
                            {{ $t('Weiter') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-stepper-content>
            <v-stepper-content :step="2">
                <v-card>
                    <archive-catalog-select v-model="selectedCatalogsComputed" :disabled="!isSelected" />
                    <v-card-actions>
                        <v-btn color="secondary" block @click="next" :disabled="!isSelected">
                            {{ $t('Weiter') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-stepper-content>
            <v-stepper-content :step="3">
                <v-card>
                    <v-toolbar text dark color="primary">
                        <v-toolbar-title>{{ $t('Zugewiesene Kataloge') }}</v-toolbar-title>
                        <v-spacer />
                        <v-btn icon @click="() => (showSelectedCatalogs = !showSelectedCatalogs)">
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <UserList
                        title="Kataloge"
                        :items="selectedCatalogsComputed"
                        :sortArray="catalogSort"
                        :showAction="false"
                        :showAppend="true"
                        :showOptions="showSelectedCatalogs"
                    >
                        <template v-slot:append="items">
                            <v-btn icon @click="removeSelected(items.append)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                    </UserList>

                    <v-card-actions>
                        <v-btn block color="secondary" :disabled="!isSelected" @click="save">
                            {{ $t('Speichern') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</template>

<script>
import UserMixin from '../Users/Mixins/UserMixin';
import ArchiveToolMixin from './Mixins/ArchiveToolMixin';

import UserSort from '../Users/UserSort/UserSortList';
import CatalogSort from '../Authoringtool/Sort/AuthoringToolSort';

import UserList from '../Generic/List/AdvancedList';
import ArchiveCatalogSelect from './ArchiveCatalogSelect';
import UserGroupSelector from '../Generic/Tree/UserGroupTreeSelector';

import moment from 'moment';

export default {
    name: 'archive-tool',
    mixins: [UserMixin, ArchiveToolMixin],
    components: { UserList, ArchiveCatalogSelect, UserGroupSelector },
    data: () => ({
        selected: [],
        userSort: UserSort,
        showSelectedCatalogs: false,
        catalogs: [],
        selectedCatalogs: [],
        catalogSort: CatalogSort,
        stepper: 1
    }),
    computed: {
        isSelected() {
            return Object.keys(this.selectedComputed).length > 0;
        },
        selectedCatalogsComputed: {
            get() {
                return this.selectedCatalogs;
            },
            set(val) {
                this.selectedCatalogs = val;
            }
        },
        minimumDate() {
            return moment().format('YYYY-MM-DD');
        },
        selectedComputed: {
            get() {
                return this.selected;
            },
            set(val) {
                if (val.length === 0) {
                    this.selected = [];
                    this.selectedCatalogs = [];
                } else {
                    this.selected = val;
                    this.GET_MULTIPLE_ARCHIVE_CATALOGS(val).then((data) => (this.selectedCatalogs = data.catalogs));
                }
            }
        }
    },
    methods: {
        next() {
            this.stepper = parseInt(this.stepper) + 1;
        },
        removeSelected(val) {
            this.selectedCatalogsComputed = this.selectedCatalogsComputed.filter((catalog) => catalog.id !== val.id);
        },
        save() {
            this.ASSIGN_USER_ARCHIVE_CATALOGS({
                users: this.selected,
                catalogs: this.selectedCatalogs
            });
        }
    },
    mounted() {
        this.GET_USER_LIST();
        this.GET_ADMIN_LIST();
    }
};
</script>
