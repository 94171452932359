<template>
    <v-dialog persistent scrollable :width="$vuetify.breakpoint.thresholds.sm" :fullscreen="$vuetify.breakpoint.xs" :value="showDialog">
        <v-card>
            <v-toolbar dark flat dense color="secondary">
                <v-btn dark icon @click="contentGoBack" v-show="breadcrumbs.length > 0">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-toolbar-title>{{ $t(toolbarTitle) }}</v-toolbar-title>
                <v-spacer />
                <v-btn dark icon @click="close()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-alert v-show="!isPreview && !isMediaConsumed" class="ma-0" transition="fade-transition" :value="true" type="info">
                {{ $t('Um fortzufahren, bitte sehen/hören Sie sich alle Medien an') }}
            </v-alert>
            <v-card-text ref="container" class="ma-0 pa-0">
                <v-container>
                    <v-row>
                        <v-col :cols="12" v-if="breadcrumbs.length > 0">
                            <v-breadcrumbs :items.sync="breadcrumbs" divider=">">
                                <template v-slot:item="{ item }">
                                    <a :disabled="!isMediaConsumed" @click="selectContentLink(item)">{{ item.title }}</a>
                                </template>
                            </v-breadcrumbs>
                        </v-col>
                        <v-col :cols="12">
                            <KnowledgeContentTags :items="tags" />
                        </v-col>
                        <v-col :cols="12">
                            <div class="ck ck-content" ref="content" v-html="content" />
                            <div style="clear: both" />
                        </v-col>
                        <v-col :cols="12">
                            <KnowledgeContentLinks :disabled="!isMediaConsumed" :items="links" @input="getContentLink" />
                        </v-col>
                        <v-col :cols="12">
                            <slot></slot>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <!-- <v-divider v-show="showNext && items.length > 0" /> -->
            <v-card-actions v-show="showNext && items.length > 0">
                <v-container>
                    <v-row align="center" justify="center">
                        <v-col cols="auto" v-if="hasMaterial">
                            <CatalogMaterial
                                :catalog="knowledgeUnit.catalogOptions"
                                :icon="{ icon: $vuetify.breakpoint.xs, color: 'secondary' }"
                                position="top"
                                onlyDownload
                            >
                                <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('Lernmaterialien') }}</span>
                                <v-icon v-else>mdi-inbox-full-outline</v-icon>
                            </CatalogMaterial>
                        </v-col>
                        <v-col>
                            <v-pagination
                                v-show="singleItem > -1 && showPagination"
                                :disabled="!isMediaConsumed"
                                circle
                                :length.sync="items.length"
                                v-model="singleItem"
                                :total-visible="$vuetify.breakpoint.xs ? 4 : 6"
                            />
                        </v-col>
                        <v-col cols="auto">
                            <v-layout align-end justify-end row fill-height v-if="showNext">
                                <v-btn @click="nextContent" :outlined="singleItem === items.length" color="primary" :disabled="!isMediaConsumed">
                                    {{ $t('Weiter') }}
                                </v-btn>
                            </v-layout>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import KnowledgeContentPreviewMixin from './Mixins/KnowledgeContentPreviewMixin';

import KnowledgeContentLinks from './KnowledgeContentLinks';
import KnowledgeContentTags from './KnowledgeContentTags';
import CatalogMaterial from '../CatalogMaterial/CatalogMaterial';

export default {
    name: 'knowledge-content-preview',
    mixins: [KnowledgeContentPreviewMixin],
    components: {
        KnowledgeContentLinks,
        KnowledgeContentTags,
        CatalogMaterial
    },
    data: () => ({
        breadcrumbs: [],
        currentContent: {},
        currentPage: 1,
        mediaList: {}
    }),
    computed: {
        showDialog() {
            return Object.keys(this.value).length > 0;
        },
        isMediaConsumptionDisabled() {
            return this.items.map(({ viewTillEnd }) => viewTillEnd).reduce((current, next) => current || next, false);
        },
        isMediaConsumed() {
            return Object.values(this.mediaList).reduce((accu, val) => accu && val, true) || !this.currentContent.viewTillEnd || this.isPreview;
        },
        singleItem: {
            get() {
                return this.items.map((test) => test.id).indexOf(this.value.id) + 1;
            },
            set(val) {
                this.currentPage = val;
                this.$emit('input', this.items[val - 1]);
                this.breadcrumbs = [];
            }
        },
        links() {
            if (this.currentContent.hasOwnProperty('knowledgeContentLinks')) {
                return this.currentContent.knowledgeContentLinks;
            }
            return [];
        },
        state() {
            if (this.currentContent.hasOwnProperty('state') && Object.keys(this.currentContent.state).length > 0) {
                return this.currentContent.state;
            }
            return { state: { stateName: '' } };
        },
        title() {
            if (this.currentContent.hasOwnProperty('title')) {
                return this.currentContent.title;
            }
            return '';
        },
        tags() {
            if (this.currentContent.hasOwnProperty('tags')) {
                return this.currentContent.tags.split(',');
            }
            return [];
        },
        content() {
            if (this.currentContent.hasOwnProperty('content')) {
                return this.currentContent.content;
            }
            return {};
        }
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        items: {
            type: Array,
            default: () => []
        },
        showPagination: {
            type: Boolean,
            default: true
        },
        showNext: {
            type: Boolean,
            default: false
        },
        toolbarTitle: {
            type: String,
            default: 'Wissensbasis'
        },
        isPreview: {
            type: Boolean,
            default: true
        },
        hasMaterial: {
            type: Boolean,
            default: false
        },
        knowledgeUnit: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        isMediaConsumed(value) {
            this.$emit('mediaConsumed', value);
        },
        currentContent() {
            new Promise((resolve) => resolve(this.cleanUpMediaList())).then(() => new Promise((resolve) => resolve(this.createMediaList)));
        },
        value: {
            handler(val) {
                this.cleanUpMediaList();
                this.currentContent = val;
            }
        }
    },
    methods: {
        contentGoBack() {
            const lastContent = this.breadcrumbs.slice(this.breadcrumbs.length - 1)[0];
            this.breadcrumbs = this.breadcrumbs.slice(0, this.breadcrumbs.length - 1);
            this.currentContent = lastContent;
        },
        cleanUpMediaList() {
            return this.$nextTick(() => {
                if (Object.keys(this.mediaList).length > 0) {
                    Object.keys(this.mediaList).forEach((mediaId) => {
                        const media = document.getElementById(mediaId);
                        if (media !== undefined && media !== null) {
                            media.removeEventListener('ended', this.mediaHasEnded);
                        }
                    });
                }
                this.mediaList = {};
            });
        },
        createMediaList() {
            return this.$nextTick(() => {
                this.mediaList = [...this.$refs.content.querySelectorAll('audio'), ...this.$refs.content.querySelectorAll('video')]
                    .map((node, index) => {
                        node.id = `node-${parseInt(performance.now()).toString()}-${index}`;
                        this.setupAudioAndVideoListeners(node);
                        return node;
                    })
                    .reduce((accu, node) => {
                        accu[node.id] = false;
                        return accu;
                    }, {});
            });
        },
        setupAudioAndVideoListeners(node) {
            const element = node;
            let currentTime = 0;
            element.addEventListener('ended', this.mediaHasEnded, { passive: true });
            element.addEventListener(
                'seeking',
                () => {
                    if (element.currentTime > currentTime && !this.isPreview) {
                        if (this.currentContent.hasOwnProperty('disableSkipping') && this.currentContent.disableSkipping) {
                            element.currentTime = currentTime;
                        }
                    }
                },
                { passive: true }
            );
            element.addEventListener(
                'timeupdate',
                () => {
                    if (!element.seeking) {
                        currentTime = element.currentTime;
                    }
                },
                { passive: true }
            );
        },
        mediaHasEnded(e) {
            this.mediaList[e.target.id] = true;
        },
        scrollUp() {
            this.$vuetify.goTo(0, { duration: 750, offset: 0, easing: 'easeInOutCubic', container: this.$refs.container });
        },
        close() {
            this.breadcrumbs = [];
            this.currentPage = 1;
            this.$emit('input', {});
            if (!this.isPreview && this.isMediaConsumptionDisabled) {
                if (this.items.map(({ id }) => id).findIndex((id) => this.currentContent.id === id) === this.items.length - 1) {
                    this.$emit('isPreview', this.isMediaConsumed);
                } else {
                    this.$emit('isPreview', false);
                }
            } else {
                this.$emit('isPreview', true);
            }
        },
        nextContent() {
            if (this.currentPage < this.items.length) {
                this.currentPage++;
                this.$emit('input', this.items[this.currentPage - 1]);
            } else {
                this.close();
            }
        },
        getContentLink(contentLink) {
            this.GET_KNOWLEDGE_CONTENT(contentLink.id).then((link) => {
                this.breadcrumbs.push(this.currentContent);
                this.currentContent = link;
            });
        },
        selectContentLink(contentLink) {
            let index = this.breadcrumbs.map((content) => content.id).indexOf(contentLink.id);
            if (index !== -1) {
                this.breadcrumbs = this.breadcrumbs.slice(0, index);
                this.currentContent = contentLink;
            }
        }
    },
    beforeDestroy() {
        this.cleanUpMediaList();
    }
};
</script>
