<template>
    <v-card flat>
        <v-card-text>
            <PersonalChatEditor v-model="selectedUser" />
        </v-card-text>
    </v-card>
</template>

<script>
import ChatMixins from './../Mixins/ChatMixin';
import PersonalChatEditor from './../PersonalChat/PersonalChatEditor';

export default {
    name: 'chat',
    components: {
        PersonalChatEditor
    },
    mixins: [ChatMixins],
    data: () => ({
        selectedUser: {}
    }),
    mounted() {
        this.CHAT_GET_PERSONAL_NOTIFICATIONS();
        this.CHAT_GET_FAVOURITE_USERS();
    }
};
</script>
