<template>
    <v-card height="100%">
        <v-toolbar dark text color="secondary">
            <v-toolbar-title>{{ $t('Wissenseinheiten') }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="toggleChartType">
                <v-icon>{{ iconComputed }}</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <v-container>
                <v-row align="center">
                    <v-col>
                        <GChart :type="chartType" :options="calculateChartOptions" :data="chartData" :class="{ dark: this.isDark }" />
                    </v-col>
                    <v-col>
                        <v-list dense>
                            <v-list-item v-for="(questionType, idx) in questionTypesComputed" :key="'type-' + idx">
                                <v-list-item-content>
                                    <v-chip dark :color="chipColor(questionType.type)">{{ $t(questionType.text) }}</v-chip>
                                </v-list-item-content>
                                <v-list-item-action>
                                    {{ types(questionType.type) }}
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider class="mt-2 mb-2" />
                            <v-list-item>
                                <v-list-item-content>
                                    <v-label>{{ $t('Gesamt') }}</v-label>
                                </v-list-item-content>
                                <v-list-item-action>
                                    {{ value.totalKnowledgeUnits }}
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
import { GChart } from 'vue-google-charts';
import QuestionTypeCollection from '../../services/DataStructures/QuestionTypeCollection';

export default {
    name: 'knowledge-unit-tally',
    components: { GChart },
    data: () => ({
        chartType: 'ColumnChart',
        chartOptions: {}
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        colors() {
            return this.$vuetify.theme.currentTheme;
        },
        questionTypesComputed() {
            return QuestionTypeCollection.questionType;
        },
        iconComputed() {
            if (this.chartType == 'PieChart') return 'mdi-chart-bar';
            else return 'mdi-chart-pie';
        },
        total() {
            if (this.value.hasOwnProperty('totalKnowledgeUnits')) {
                return this.value.totalKnowledgeUnits;
            }
            return 0;
        },
        calculateChartOptions() {
            return {
                title: '',
                titleTextStyle: {
                    fontSize: 24,
                    bold: true,
                    color: this.isDark ? '#FFF' : '#000'
                },
                width: '100%',
                height: 400,
                legend: 'none',
                is3D: false,
                backgroundColor: this.isDark ? '#1E1E1E' : '#FFF',
                vAxis: {
                    title: this.$t('Wissenseinheiten beantwortet'),
                    titleTextStyle: { color: this.isDark ? '#FFF' : '#000' }
                },
                colors: this.questionTypesComputed.map((type) => type.colorHex),
                animation: { startup: true, duration: 500, easing: 'out' },
                tooltip: { isHtml: this.isDark }
            };
        },
        chartData() {
            let chartDataArray = [[this.$t('Fragentyp'), this.$t('Anzahl'), { role: 'style' }]];
            this.questionTypesComputed.forEach(({ text, type }) => {
                chartDataArray.push([this.$t(text), this.types(type), this.questionTypeColor(type).colorHex]);
            });
            return chartDataArray;
        },
        isDark() {
            return this.$vuetify.theme.dark;
        }
    },
    methods: {
        toggleChartType() {
            this.chartType === 'PieChart' ? (this.chartType = 'ColumnChart') : (this.chartType = 'PieChart');
        },
        chipColor(questionType) {
            const colorClass = QuestionTypeCollection.questionType.find(({ type }) => type == questionType);
            return colorClass ? colorClass.color : 'primary';
        },
        questionTypeColor(questionType) {
            let questionColor = QuestionTypeCollection.questionType.find((type) => {
                return type.type == questionType;
            });
            return questionColor;
        },
        types(type) {
            if (this.value.hasOwnProperty('typeMap')) {
                if (this.value.typeMap.hasOwnProperty(type)) return this.value.typeMap[type];
            }
            return 0;
        },
        onResize() {
            this.$nextTick(() => (this.chartOptions = this.calculateChartOptions()));
        }
    }
};
</script>
