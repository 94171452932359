<template>
    <v-card class="fill-height">
        <Toolbar :toolbarTitle="$t('E-Learning')" :showCloseButton="true" @closeEmit="close" />
        <iframe ref="iframeRef" name="scormplayer_iframe" id="scormplayer_iframe" class="scormplayer_iframe"></iframe>
    </v-card>
</template>
<script>
import Toolbar from '../../Components/Generic/Toolbar';
import ScormMixin from '../../Components/SCORM/Mixins/ScormMixin';
import CourseMixin from '../../Components/CourseLearning/CourseManager/Mixins/CourseMixin';
import * as scormplayerapi from './RegexScormPlayerAPI.min.js';
import axios from '../../plugins/Axios/axios';
import Store from '@/store/index';
import moment from 'moment';

export default {
    name: 'RegexScormPlayer',
    components: { Toolbar },
    mixins: [ScormMixin, CourseMixin],
    props: [],
    data() {
        return {
            exitCall: false
        };
    },
    mounted: async function () {
        var _this = this;
        var student = {
            id: this.getLoggedInAdmin.id,
            name: this.getLoggedInAdmin.firstName + ' ' + this.getLoggedInAdmin.lastName
        };
        var courseUrl = this.getSelectedScorm.scormPackageImsManifestPath;

        const cmidatakey = 'cmidata_' + this.getSelectedScorm.id + '_' + student.id;
        var cmidata = '';

        //@TODO Disable it when deploy to server.
        //courseUrl = courseUrl.replace("https://wiselabapi.wiselab.io/", "http://wiselabapi.wiselab.loc/")
        // console.log(courseUrl);
        //@TODO -----------------------------------------------------------------------------

        const _serverUrl = '/users/scormpackages/' + this.getSelectedScorm.scormPackageUserId + '/activity';

        var data = await axios.get(_serverUrl).then(function (response) {
            return response.data.data;
        });
        cmidata = data.cmi;
        // console.log(cmidata);

        const _saveCMI = function (cmiObj, onDone) {
            // console.log(JSON.stringify(cmidata));
            axios
                .post(_serverUrl, {
                    cmi: cmiObj
                })
                .then(function (response) {
                    // console.log(response.data.data);
                    onDone(response.data.data);
                });
        };

        var scormHandlers = {
            LMSInitialize: function (cmi, value) {},
            LMSSetValue: function (cmi, value) {},
            LMSCommit: function (cmidata) {
                _saveCMI(cmidata, function () {});
            },
            LMSFinish: function (cmidata) {
                Store.dispatch('LoadOperations/START');
                var total_time_seconds = 0,
                    session_time_seconds = 0;
                if (api.getScormVersion() == '1.2') {
                    if (cmidata.core.total_time != '') total_time_seconds = moment.duration(cmidata.core.total_time).asSeconds();
                    if (cmidata.core.session_time != '') session_time_seconds = moment.duration(cmidata.core.session_time).asSeconds();
                    cmidata.core.total_time = moment.utc((total_time_seconds + session_time_seconds) * 1000).format('HH:mm:ss');
                    // console.log(cmidata.core.total_time);
                } else {
                    if (cmidata.total_time != '') total_time_seconds = moment.duration(cmidata.total_time).asSeconds();
                    if (cmidata.session_time != '') session_time_seconds = moment.duration(cmidata.session_time).asSeconds();
                    cmidata.total_time = moment.utc((total_time_seconds + session_time_seconds) * 1000).format('HH:mm:ss');
                    // console.log(cmidata.total_time);
                }
                setTimeout(function () {
                    _saveCMI(cmidata, function () {
                        setTimeout(function () {
                            _this.close();
                        }, 200);
                    });
                }, 1000);
            },
            LMSGetLastError: function (errorCode) {
                // console.log(errorCode);
            },
            LMSGetErrorString: function (errorCode) {
                // console.log(errorCode);
            },
            LMSGetDiagnostic: function (errorCode) {
                // console.log(errorCode);
            }
        };

        Store.dispatch('LoadOperations/START');
        var api = new scormplayerapi.RegexScormPlayerAPI('scormplayer_iframe', courseUrl, student, cmidata, scormHandlers);
        api.initScormPlayer(function (scoList) {
            api.run(function (iframe) {
                // console.log('IFRAMELOADED');
                Store.dispatch('LoadOperations/END');
            });
        });
    },
    methods: {
        close() {
            Store.dispatch('LoadOperations/END');
            if (this.exitCall == true) return;
            this.exitCall = true;
            this.$router.replace('/MyCourses');
            this.SET_SELECTED_TAB(2);
        }
    },
    beforeDestroy() {
        this.SET_SELECTED_SCORM({});
    }
};
</script>

<style scoped>
.scormplayer_iframe {
    display: block;
    width: 100%;
    height: 85vh;
    border: 0;
}
</style>
