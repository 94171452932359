<template>
    <v-layout column>
        <v-flex v-for="(option, index) in computedOptions" :key="`option-${index}`">
            <v-card class="mb-3">
                <v-card-text>
                    {{ option.checkPoint.point }}
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    name: 'check-type',
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        computedOptions() {
            if (this.value !== undefined && this.value !== null && this.value.hasOwnProperty('answers')) {
                return this.value.answers.filter((answer) => answer.checked);
            }
            return [];
        }
    }
};
</script>
