<template>
    <v-card :draggable="true">
        <v-toolbar v-if="isCourseManagerValue" dark flat dense color="secondary">
            <v-spacer />
            <v-toolbar-items>
                <TaxonomyTable :isCourse="true" />
                <CourseAdminUser :isAdmin="true" :value="getSelectedAdminCourse" />
                <CourseAdminUser :isAdmin="false" :value="getSelectedAdminCourse" />
                <AddUpdateTopic v-model="showModal" :type="operationType" />
            </v-toolbar-items>
        </v-toolbar>
        <v-sheet :class="cardStyle" @click="selectCard" v-if="picture !== ''" color="grey lighten-3">
            <ImageLoader :src="picture" :ratio="3.55" :contain="false" />
        </v-sheet>
        <v-tabs v-model="modalActive" show-arrows mandatory>
            <v-tab key="INFO">{{ computedTabTitle }}</v-tab>
            <v-tab key="CONTENT" :disabled="value.knowledgeContents.length === 0">{{ $t('Content Links') }}</v-tab>
        </v-tabs>
        <v-card :class="cardStyle" @click.passive="selectCard" height="100%">
            <v-container>
                <v-row>
                    <v-col :cols="12">
                        <v-tabs-items v-model="modalActive" touchless>
                            <v-tab-item key="INFO">
                                <v-container>
                                    <v-row no-gutters>
                                        <v-col :cols="12">
                                            <span :class="{ title: true, limitTitle: this.selectable }">{{ value.name }}</span>
                                        </v-col>
                                        <v-col :cols="12">
                                            <span class="text-caption primary--text">
                                                {{ `${$t('Lernziele')}: ${value.numLearningGoals}` }}
                                            </span>
                                        </v-col>
                                        <v-col :cols="12">
                                            <span class="text-caption grey--text">
                                                {{ `${$t('erstellt von')} ${value.createdBy.firstName} ${value.createdBy.lastName}` }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-expand-transition mode="out-in">
                                    <v-card flat v-if="showMoreInfo">
                                        <TopicInfo
                                            v-if="!isMyCourse"
                                            :selectable="this.selectable"
                                            :value="value"
                                            :timeScaleInMinutes="this.timeScaleInMinutes"
                                            :durationInDays="showDurationInDays"
                                            :isMyCourse="isMyCourse"
                                        />
                                        <MyTopicInfo
                                            v-else
                                            :selectable="this.selectable"
                                            :value="value"
                                            :timeScaleInMinutes="this.timeScaleInMinutes"
                                            :durationInDays="showDurationInDays"
                                            :isMyCourse="isMyCourse"
                                            :showMoreInfo="showMoreInfo"
                                        />
                                    </v-card>
                                </v-expand-transition>
                            </v-tab-item>
                            <v-tab-item key="CONTENT">
                                <div class="scroll" style="max-height: 20vh" @click.stop>
                                    <KnowledgeContent :contents="value.knowledgeContents" :selectable="false" v-model="computedSelectedKnowledgeContent" />
                                </div>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-col>
                </v-row>
            </v-container>
            <v-card-actions>
                <v-row align="center" justify="center">
                    <v-col cols="auto">
                        <v-btn v-show="computedShowInfoButton" icon @click.stop="toggleMoreInfo"
                            ><v-icon>{{ showInfoIcon }}</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-card>
</template>

<script>
import CourseMixin from '../../Mixins/CourseMixin';

import TopicInfo from './TopicInfo';
import MyTopicInfo from './MyTopicInfo';
import KnowledgeContent from '../../../../KnowledgeContent/KnowledgeContentContent';
import TaxonomyTable from '../../TaxonomyTable';
import CourseAdminUser from '../../CourseAdminUser';
import AddUpdateTopic from '../../AddUpdateTopic';
import ImageLoader from '../../../../Generic/ImageLoader';

import moment from 'moment';

export default {
    name: 'topic',
    components: {
        ImageLoader,
        KnowledgeContent,
        AddUpdateTopic,
        TopicInfo,
        MyTopicInfo,
        TaxonomyTable,
        CourseAdminUser
    },
    mixins: [CourseMixin],
    data: () => ({
        modalActive: 0,
        showMoreInfo: false,
        operationType: 'UPDATE',
        showModal: false
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        timeScaleInMinutes: {
            type: Number,
            default: 60
        },
        courseId: {
            type: String
        },
        isAdmin: {
            type: Boolean,
            default: false
        },
        isMyCourse: {
            type: Boolean,
            default: false
        },
        isTopic: {
            type: Boolean,
            default: false
        },
        isLearningGoal: {
            type: Boolean,
            default: false
        },
        selected: {
            type: Object,
            default: () => ({})
        },
        selectable: {
            type: Boolean,
            default: false
        },
        selectedKnowledgeContent: {
            type: Array,
            default: () => []
        },
        isCourseManagerValue: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedTabTitle() {
            if (this.isAdmin || this.isLearningGoal) {
                if (this.getSelectedAdminCourse.topicTypeName === null && this.value.typeName === null) {
                    return this.$t('Thema');
                } else if (this.value.typeName != null) {
                    return this.value.typeName;
                }
                return this.getSelectedAdminCourse.topicTypeName;
            }
            if (this.getSelectedAttendeeCourse.topicTypeName === null && this.value.typeName === null) {
                return this.$t('Thema');
            } else if (this.value.typeName != null) {
                return this.value.typeName;
            }
            return this.getSelectedAttendeeCourse.topicTypeName;
        },
        computedSelectedKnowledgeContent: {
            get() {
                return this.selectedKnowledgeContent;
            },
            set(value) {
                this.$emit('selectedKnowledgeContent', value);
            }
        },
        computedShowInfoButton() {
            if (this.modalActive === 0 && !this.isLearningGoal) {
                return true;
            } else if (this.modalActive === 0 && this.isLearningGoal && !this.$vuetify.breakpoint.xs) {
                return true;
            }
            return false;
        },
        computedHideBeforeStart() {
            if (this.isMyCourse && this.value.hideBeforeStart && moment().format('YYYY-MM-DD') < this.value.startDate) {
                return true;
            }
            return false;
        },
        emptyDescription() {
            if (this.value.description.length === 0) {
                return '-';
            }
            return this.value.description;
        },
        showDurationInDays() {
            if (this.isMyCourse && this.value.hideDurationInDays) {
                return 0;
            }
            return this.value.durationInDays;
        },
        computedShowClock() {
            if (this.isMyCourse && this.value.hideEstimatedWorkTimeInMinutes) {
                return false;
            }
            return true;
        },
        convertMinutes() {
            const timeUnitMinutes = this.value.estimatedWorkTimeInMinutes % this.timeScaleInMinutes;
            const timeUnit = this.value.estimatedWorkTimeInMinutes / this.timeScaleInMinutes;
            if (timeUnitMinutes.toString().length === 1) {
                return `${timeUnit.toString().split('.')[0]}:${'0' + timeUnitMinutes.toString()}`;
            }
            return `${timeUnit.toString().split('.')[0]}:${timeUnitMinutes.toString()}`;
        },
        isCardSelected() {
            if (this.selected.hasOwnProperty('id') && this.value.hasOwnProperty('id')) {
                return this.selected.id === this.value.id;
            }
            return false;
        },
        cardStyle() {
            return {
                'elevation-12': this.isCardSelected,
                pointer: this.selectable
            };
        },
        showInfoIcon() {
            return this.showMoreInfo === false ? 'mdi-chevron-down' : 'mdi-chevron-up';
        },
        isComparable() {
            return (
                this.getSelectedTopic !== null &&
                this.getSelectedTopic !== undefined &&
                this.value !== null &&
                this.value !== undefined &&
                this.value.hasOwnProperty('id') &&
                this.getSelectedTopic.hasOwnProperty('id')
            );
        },
        isSelected() {
            if (this.isComparable) {
                return this.value.id === this.getSelectedTopic.id;
            }
            return false;
        },
        picture() {
            if (this.value.hasOwnProperty('picture')) {
                if (this.value.picture.hasOwnProperty('fileURL')) {
                    if (this.value.picture.fileURL !== null && this.value.picture.fileURL !== undefined) {
                        return this.value.picture.fileURL;
                    }
                    return this.getDefaultTopicPicture;
                }
            }
            return this.getDefaultTopicPicture;
        }
    },
    methods: {
        update() {
            this.operationType = 'UPDATE';
            this.showModal = true;
        },
        toggleMoreInfo() {
            this.showMoreInfo = !this.showMoreInfo;
        },
        selectCard() {
            if (!this.computedHideBeforeStart) {
                this.$emit('selected', this.value);
            }
        },
        handleCourseSelection() {
            if (this.isComparable) {
                if (this.value.id === this.getSelectedTopic.id) {
                    this.$emit('input', {});
                } else {
                    this.$emit('input', this.value);
                }
            } else {
                this.$emit('input', this.value);
            }
        }
    }
};
</script>

<style scoped>
.tabsDiv {
    min-height: 18vh;
}
.scroll {
    overflow-y: auto;
}
.limit {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
.limitTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.pointer {
    cursor: pointer;
}
</style>
