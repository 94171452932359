<template>
    <div>
        <Toolbar
            :toolbarTitle="$t(toolbarProp.title)"
            :showSearchButton="toolbarProp.showSearchButton"
            :showDrawer="false"
            :tooltipMessage="$t('Hier können Sie Kataloge auswählen, die archiviert sein sollten.')"
            @backEmit="backEmit"
        />

        <v-card>
            <v-toolbar text dark color="secondary">
                <v-toolbar-title>{{ $t('Kataloge') }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="() => (showOptions = !showOptions)">
                    <v-icon>mdi-magnify</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <CatalogList title="Kataloge" :items="catalogList" :sortArray="plantoolSort" :showAction="true" :showOptions="showOptions">
                    <template v-slot:action="item">
                        <v-checkbox
                            color="primary"
                            :value="item.action"
                            v-model="selectedCatalogLocal"
                            :value-comparator="compareObjectEquality"
                            :key="item.action.id"
                        />
                    </template>
                </CatalogList>
            </v-card-text>
        </v-card>

        <v-card>
            <v-toolbar text dark color="secondary">
                <v-toolbar-title>{{ $t('Ausgewählte Kataloge') }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <CatalogList title="Kataloge" :items="selectedCatalogLocal" :sortArray="plantoolSort" :showAction="false" :showAppend="true">
                    <template v-slot:append="item">
                        <v-btn icon @click="removeItem(item.append)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </template>
                </CatalogList>
            </v-card-text>
            <v-layout align-end justify-end class="ma-3">
                <v-btn medium bottom fab fixed :disabled="addButtonDisabled" class="success" @click="pickCatalog()">
                    <v-icon>mdi-check</v-icon>
                </v-btn>
            </v-layout>
        </v-card>
    </div>
</template>

<script>
import CatalogList from '../../Components/Generic/List/AdvancedList';
import Toolbar from '../../Components/Generic/Toolbar';
import TaskArchiveMixins from '../../Components/UserApp/Mixins/TaskArchiveMixins';
import PlantoolSort from '../../Components/Authoringtool/Sort/AuthoringToolSort';

export default {
    name: 'archive-catalog',
    mixins: [TaskArchiveMixins],
    components: {
        CatalogList,
        Toolbar
    },
    data: () => ({
        showOptions: false,
        plantoolSort: PlantoolSort,
        toolbarProp: {
            title: 'Kataloge Auswählen',
            showSearchButton: true
        },
        searchedCatalog: [],
        availableCatalog: [],
        selectedCatalogLocal: []
    }),
    computed: {
        addButtonDisabled() {
            return this.selectedCatalogLocal.length === 0;
        },
        catalogList: {
            //getter
            get: function () {
                return this.searchedCatalog;
            },
            //setter
            set: function (keyword) {
                this.searchedCatalog = this.availableCatalog.filter((catalog) => {
                    keyword = keyword.toLowerCase();
                    return catalog.name.toLowerCase().includes(keyword) || catalog.description.toLowerCase().includes(keyword) || keyword === '';
                });
            }
        }
    },
    methods: {
        compareObjectEquality(a, b) {
            return a.id === b.id;
        },
        removeItem(item) {
            this.selectedCatalogLocal = this.selectedCatalogLocal.filter((catalog) => catalog.id !== item.id);
        },
        loadCatalog() {
            this.GET_ARCHIVE_CATALOG_LIST()
                .then(() => {
                    var archiveCatalogIds = this.selectedCatalogLocal.map((cat) => {
                        return cat.id;
                    });

                    // REMOVE INCONSISTENCE KEY FOR AWHILE
                    this.selectedCatalogLocal.map((cat) => {
                        return delete cat.subItem;
                    });

                    this.getCatalogList.map((validCatalog) => {
                        var matchIdx = archiveCatalogIds.indexOf(validCatalog.id);
                        if (matchIdx != -1) {
                            validCatalog.options = this.selectedCatalogLocal[matchIdx].options;
                        } else {
                            validCatalog.options = {
                                showKnowledgeBase: true,
                                showExplanation: true,
                                answerTimeLimit: 100,
                                passingQuote: 0,
                                archiveImmediately: false,
                                archiveOnEndDate: false,
                                reopenKnowledgeBase: false,
                                archive: false,
                                archiveOnPass: false,
                                onFailure: 'END',
                                onFailureList: ['REPEAT', 'END'],
                                sortBy: 'NATURAL',
                                sortList: ['NATURAL', 'RANDOM', 'DATE']
                            };
                        }
                        return validCatalog;
                    });
                    this.availableCatalog = this.getCatalogList;
                    this.searchedCatalog = this.getCatalogList;
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        //COMPONENT EMITED FUNCTIONS
        backEmit() {
            this.$router.push(this.getBackPath);
        },
        pickCatalog() {
            this.UPDATE_ARCHIVE_SELECTED_CATALOG(this.selectedCatalogLocal).then(() => {
                this.$router.push(this.getBackPath);
            });
        }
    },
    mounted() {
        this.selectedCatalogLocal = this.getArchiveStore.catalogs;
        this.loadCatalog();
    }
};
</script>
