<template>
    <v-dialog :value="toggleModal" persistent scrollable :width="$vuetify.breakpoint.thresholds.sm" :fullscreen="$vuetify.breakpoint.xs">
        <v-card>
            <v-toolbar dense text dark color="secondary">
                <v-toolbar-title>{{ $t('Vorlage erschaffen') }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-tabs v-model="tabs" mandatory show-arrows slider-color="accent">
                <v-tab key="Template">{{ $t('Vorlage') }}</v-tab>
                <v-tab key="Interval">{{ $t('Intervall Einstellungen') }}</v-tab>
                <v-tab key="Users">{{ $t('Nutzer Einstellungen') }}</v-tab>
            </v-tabs>

            <v-card-text class="ma-0 pa-0">
                <v-tabs-items v-model="tabs" touchless>
                    <v-tab-item key="Template">
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        color="accent"
                                        autocomplete="new-password"
                                        v-model="name"
                                        :label="$t('Name')"
                                        :hint="$t('Der Name der Vorlage dient dazu die Vorlage im Editor zu identifizieren')"
                                        persistent-hint
                                    />
                                </v-col>
                                <v-col cols="auto">
                                    <v-switch
                                        color="accent"
                                        v-model="isActive"
                                        :label="$t('Aktiv')"
                                        :hint="$t('E-Mails von inaktiv geschalteten Vorlagen werden nicht gesendet')"
                                        persistent-hint
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="Interval">
                        <v-container>
                            <v-row>
                                <v-col :cols="12">
                                    <IntervalSelector v-model="selectedInterval" />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="Users">
                        <!-- <v-container>
                            <v-row>
                                <v-col :cols="12"> -->
                        <UserSelector v-model="userIds" />
                        <!-- </v-col>
                            </v-row>
                        </v-container> -->
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
            <v-card-actions>
                <v-btn block @click="create" color="primary" :disabled="validateData">{{ $t('Erstellen') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import IntervalSelector from './Interval/IntervalSelector';
import UserSelector from '../Generic/Tree/UserGroupTreeSelector';
import SystemSettingsMixin from './Mixins/SystemSettingsMixin';

export default {
    name: 'add-scheduled-template-type',
    components: { IntervalSelector, UserSelector },
    mixins: [SystemSettingsMixin],
    data: () => ({
        tabs: 0,
        selectedInterval: {},
        userIds: [],
        name: '',
        isActive: false
    }),
    props: {
        modal: {
            type: Boolean,
            default: () => false,
            required: true
        }
    },
    computed: {
        toggleModal: {
            get() {
                return this.modal;
            },
            set(value) {
                this.$emit('toggleModal', value);
            }
        },
        validateData: {
            get() {
                return !(this.name !== '' && this.selectedInterval.hasOwnProperty('startDate'));
            }
        }
    },
    methods: {
        serialize() {
            return {
                active: this.isActive,
                name: this.name,
                userIds: this.userIds.map((user) => user.id),
                ...this.selectedInterval
            };
        },
        reset() {
            this.name = '';
            this.isActive = false;
            this.selectedInterval = {};
            this.userIds = [];
        },
        create() {
            this.CREATE_SCHEDULED_TEMPLATE_TYPE(this.serialize());
            this.$emit('toggleModal', false);
            this.reset();
        },
        close() {
            this.$emit('toggleModal', false);
            this.reset();
        }
    }
};
</script>
