<template>
    <v-card>
        <v-container>
            <v-row>
                <v-col :xs="12" :sm="12" :md="6">
                    <v-card :class="logoBGClassComputed">
                        <v-toolbar flat dark dense color="secondary">
                            <v-toolbar-title>{{ $t('Hintergrund Bild') }}</v-toolbar-title>
                        </v-toolbar>
                        <v-sheet color="grey lighten-3">
                            <v-img :aspect-ratio="16 / 9" contain :src="computedBackgroundImage" :max-height="275" />
                        </v-sheet>
                        <input type="file" style="display: none" ref="backgroundImage" accept="image/*" @change="handleBackgroupImageUpload" />
                        <v-card-actions>
                            <v-spacer />
                            <v-btn text @click="resetBackgroundImage" :disabled="backgroundImage === ''">{{ $t('Zurücksetzen') }}</v-btn>
                            <v-btn color="secondary" @click="$refs.backgroundImage.click()">{{ $t('Hochladen') }}</v-btn>
                            <v-btn color="primary" :disabled="backgroundImage === ''" @click="saveBackgroundImage">{{ $t('Speichern') }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col :xs="12" :sm="12" :md="6">
                    <v-card :class="logoBGClassComputed">
                        <v-toolbar flat dark dense color="secondary">
                            <v-toolbar-title>{{ $t('Logo') }}</v-toolbar-title>
                        </v-toolbar>
                        <v-sheet color="grey lighten-3">
                            <v-img :aspect-ratio="16 / 9" contain :src="computedLogo" :max-height="275" />
                        </v-sheet>
                        <input type="file" style="display: none" ref="logo" accept="image/*" @change="handleLogoUpload" />
                        <v-card-actions>
                            <v-spacer />
                            <v-btn text @click="resetLogo" :disabled="logo === ''">{{ $t('Zurücksetzen') }}</v-btn>
                            <v-btn color="secondary" @click="$refs.logo.click()">{{ $t('Hochladen') }}</v-btn>
                            <v-btn color="primary" :disabled="logo === ''" @click="saveLogo">{{ $t('Speichern') }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="12">
                    <v-card>
                        <v-toolbar dark flat dense color="secondary">
                            <v-toolbar-title>{{ $t('Domain') }}</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-text-field
                                color="accent"
                                autocomplete="new-password"
                                v-model="domainName"
                                :label="$t('Domain')"
                                :hint="
                                    $t('Geben Sie die Domäne an, unter der Ihre Einstellungen wirksam werden sollen. Achten Sie auf Groß- und Kleinschreibung')
                                "
                            />
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <SystemColors :title-mode="$t('Licht')" :current-mode="'light'" :styles="styles" @loadDefault="loadDefaults" />
                <SystemColors :title-mode="$t('Dunkle')" :current-mode="'dark'" :styles="styles" @loadDefault="loadDefaults" />
            </v-row>
            <v-row>
                <v-col :cols="12">
                    <v-card>
                        <v-toolbar dark dense flat color="secondary">
                            <v-toolbar-title>{{ $t('Datenschutz und Impressum') }}</v-toolbar-title>
                        </v-toolbar>
                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header>{{ $t('Impressum') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <Editor v-model="impressum" :loadFilemanager="false" />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>{{ $t('Datenschutz') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <Editor v-model="dataProtection" :loadFilemanager="false" />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-card-actions>
            <v-btn block @click="save" color="primary">{{ $t('Speichern') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import Editor from '../../../Components/Generic/TextEditor';
import DefaultStyles from '../../../services/DataStructures/DefaultStyles';
import SystemSettingsMixin from '../../../Components/SystemSettings/Mixins/SystemSettingsMixin';
import SystemColors from './SystemColors';

export default {
    name: 'system-settings-generic',
    mixins: [SystemSettingsMixin],
    components: {
        Editor,
        SystemColors
    },
    data: () => ({
        styles: {
            light: {
                primary: '',
                secondary: '',
                accent: '',
                error: '',
                info: '',
                success: '',
                warning: '',
                background: ''
            },
            dark: {
                primary: '',
                secondary: '',
                accent: '',
                error: '',
                info: '',
                success: '',
                warning: '',
                background: ''
            }
        },
        backgroundImage: '',
        backgroundImageFile: {},
        logo: '',
        logoFile: {},
        impressum: '',
        dataProtection: '',
        domainName: ''
    }),
    computed: {
        logoBGClassComputed() {
            if (this.$vuetify.breakpoint.xs) {
                return 'mt-3';
            } else {
                return 'ml-1 mr-1 mt-3';
            }
        },
        computedBackgroundImage() {
            if (this.backgroundImage === '') {
                return this.getBackgroundImage;
            }
            return this.backgroundImage;
        },
        computedLogo() {
            if (this.logo === '') {
                return this.getLogo;
            }
            return this.logo;
        }
    },
    methods: {
        saveBackgroundImage() {
            this.UPLOAD_BACKGROUND_IMAGE(this.backgroundImageFile).then(() => this.GET_GENERIC_CUSTOMER_SETTINGS());
        },
        saveLogo() {
            this.UPLOAD_LOGO(this.logoFile).then(() => this.GET_GENERIC_CUSTOMER_SETTINGS());
        },
        resetBackgroundImage() {
            this.backgroundImage = '';
        },
        resetLogo() {
            this.logo = '';
        },
        loadDefaults(mode) {
            this.styles[mode] = DefaultStyles.getDefaultStyles();
        },
        handleBackgroupImageUpload(event) {
            if (event.target.files.length > 0) {
                this.backgroundImageFile = event.target.files[0];
                let fileReader = new FileReader();
                fileReader.onload = (e) => {
                    this.backgroundImage = e.target.result;
                };
                fileReader.readAsDataURL(event.target.files[0]);
            }
        },
        handleLogoUpload(data) {
            if (data.target.files.length > 0) {
                this.logoFile = event.target.files[0];
                let fileReader = new FileReader();
                fileReader.onload = (e) => {
                    this.logo = e.target.result;
                };
                fileReader.readAsDataURL(data.target.files[0]);
            }
        },
        save() {
            let data = {
                styles: this.styles,
                impressum: this.impressum,
                dataProtection: this.dataProtection,
                domainName: this.domainName,
                language: 'de' // quickfix
            };
            this.SET_GENERIC_SETTINGS(data).then(() => this.GET_GENERIC_CUSTOMER_SETTINGS());
        }
    },
    mounted() {
        this.GET_GENERIC_CUSTOMER_SETTINGS().then(() => {
            this.domainName = this.getDomainName;
            this.impressum = this.getImpressum;
            this.dataProtection = this.getDataProtection;
            if (this.getStyles.light && this.getStyles.dark) {
                this.styles = this.getStyles;
                return;
            }
            this.styles.light = this.getStyles;
        });
    }
};
</script>
