<template>
    <v-card flat :color="answerColor(value)" dark>
        <v-container fluid>
            <v-row align="center">
                <v-col cols="auto" v-if="!onlyShowResult">
                    <v-btn icon @click="back">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                </v-col>
                <v-col>
                    <v-row no-gutters>
                        <v-col :cols="12">
                            <span class="text-body-2">{{ $t('Ergebnis') }}</span>
                        </v-col>
                        <v-col :cols="12">
                            <span>{{ answerText(value) }}</span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
export default {
    name: 'quiz-result-knowledge-unit-header',
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        onlyShowResult: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        answerColor({ hasAnswered, hasAnsweredCorrect }) {
            return hasAnswered ? (hasAnsweredCorrect ? 'success' : 'error') : this.$t('info');
        },
        answerText({ hasAnswered, hasAnsweredCorrect }) {
            return hasAnswered
                ? hasAnsweredCorrect
                    ? this.$t('Diese Wissenseinheit wurde richtig beantwortet')
                    : this.$t('Diese Wissenseinheit wurde falsch beantwortet')
                : this.$t('Diese Wissenseinheit wurde nicht beantwortet');
        },
        back() {
            this.$emit('back');
        }
    }
};
</script>
