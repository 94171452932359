<template>
    <v-menu :close-on-content-click="false" offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on }">
            <v-btn block v-on="on" :color="getHex" :class="getTextColor">{{ label }}</v-btn>
        </template>
        <v-card flat>
            <v-card-text>
                <v-color-picker flat v-model="computedColor" dot-size="25" hide-mode-switch mode="hexa" />
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
export default {
    name: 'color-picker',
    data: () => ({
        color: {
            alpha: 1,
            hex: '#FFFFFF',
            hexa: '#FFFFFFFF',
            hsla: { h: 0, s: 0, l: 1, a: 1 },
            hsva: { h: 0, s: 0, v: 1, a: 1 },
            hue: 0,
            rgba: { r: 255, g: 255, b: 255, a: 1 }
        }
    }),
    props: {
        label: {
            type: String,
            required: true,
            default: ''
        },
        value: {
            type: [String, Object],
            required: true,
            default: '#FFFFFF'
        }
    },
    watch: {
        value(val) {
            if (typeof this.value !== 'string' || (typeof this.value === 'string' && this.value.trim() === '')) {
                this.color = { hex: '#FFFFFF' };
            } else {
                if (typeof this.value === 'string') {
                    this.color = { hex: this.value };
                }
            }
        }
    },
    computed: {
        computedColor: {
            get() {
                if (this.color.hex) {
                    return this.color.hex;
                }
                return this.color;
            },
            set(value) {
                // if (this.color.hasOwnProperty('hex')) {
                this.$emit('input', value);
                // }
            }
        },
        getHex() {
            return this.color && this.color.hex ? this.color.hex : '';
        },
        getTextColor() {
            const color = this.color.hex.charAt(0) === '#' ? this.color.hex.substring(1, 7) : this.color.hex;
            const r = parseInt(color.substring(0, 2), 16); // hexToR
            const g = parseInt(color.substring(2, 4), 16); // hexToG
            const b = parseInt(color.substring(4, 6), 16); // hexToB
            return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? 'black--text' : 'white--text';
        }
    },
    mounted() {
        if (typeof this.value !== 'string' || (typeof this.value === 'string' && this.value.trim() === '')) {
            this.color = { hex: '#FFFFFF' };
        } else {
            if (typeof this.value === 'string') {
                this.color = { hex: this.value };
            }
        }
    }
};
</script>
