const RegexScormConstants = {
    SCORM_TRUE: 'true',
    SCORM_FALSE: 'false',
    STATE_NOT_INITIALIZED: 0,
    STATE_INITIALIZED: 1,
    STATE_TERMINATED: 2,
    LOG_LEVEL_DEBUG: 1,
    LOG_LEVEL_INFO: 2,
    LOG_LEVEL_WARNING: 3,
    LOG_LEVEL_ERROR: 4,
    LOG_LEVEL_NONE: 5
};
var _apiLogLevel;
const RegexScormBaseAPI = function () {
        var e = this;
        // console.log(e);
        (e.currentState = RegexScormConstants.STATE_NOT_INITIALIZED),
            (e.lastErrorCode = 0),
            (e.jsonFormatter = function () {
                (this.jsonString = !0), delete this.toJSON;
                var t = JSON.stringify(this);
                delete this.jsonString, (this.toJSON = e.jsonFormatter);
                var r = JSON.parse(t);
                for (var i in (delete r.jsonString, r)) Object.prototype.hasOwnProperty.call(r, i) && 0 === i.indexOf('_') && delete r[i];
                return r;
            }),
            (e.formatMessage = function (e, t, r) {
                for (var i = '', n = 20 - (i += e).length, o = 0; o < n; o++) i += ' ';
                if (((i += ': '), t)) {
                    n = 70 - (i += t).length;
                    for (var s = 0; s < n; s++) i += ' ';
                }
                return r && (i += r), i;
            }),
            (e.apiLog = function (t, r, i, n) {
                if (((i = e.formatMessage(t, r, i)), n >= _apiLogLevel))
                    switch (n) {
                        case RegexScormConstants.LOG_LEVEL_ERROR:
                            console.error(i);
                            break;
                        case RegexScormConstants.LOG_LEVEL_WARNING:
                            console.warn(i);
                            break;
                        case RegexScormConstants.LOG_LEVEL_INFO:
                            console.info(i);
                    }
            }),
            (e.apiLogLevel = RegexScormConstants.LOG_LEVEL_ERROR),
            (_apiLogLevel = RegexScormConstants.LOG_LEVEL_ERROR),
            (e.clearSCORMError = function (e) {
                e !== RegexScormConstants.SCORM_FALSE && (this.lastErrorCode = 0);
            }),
            (e.getLmsErrorMessageDetails = function (e, t) {
                // return console.log(e + ' ' + t), 'No error';
            }),
            (e.isInitialized = function () {
                return this.currentState === RegexScormConstants.STATE_INITIALIZED;
            }),
            (e.isNotInitialized = function () {
                return this.currentState === RegexScormConstants.STATE_NOT_INITIALIZED;
            }),
            (e.isTerminated = function () {
                return this.currentState === RegexScormConstants.STATE_TERMINATED;
            }),
            (e.listenerArray = []),
            (e.on = function (t, r) {
                if (r)
                    for (var i = t.split(' '), n = 0; n < i.length; n++) {
                        var o = i[n].split('.');
                        if (0 === o.length) return;
                        var s = o[0],
                            a = null;
                        o.length > 1 && (a = t.replace(s + '.', '')), e.listenerArray.push({ functionName: s, CMIElement: a, callback: r });
                    }
            }),
            (e.processListeners = function (e, t, r) {
                for (var i = 0; i < this.listenerArray.length; i++) {
                    var n = this.listenerArray[i],
                        o = n.functionName === e,
                        s = !!n.CMIElement,
                        a = n.CMIElement === t;
                    !o || (s && !a) || n.callback(t, r);
                }
            }),
            (e.throwSCORMError = function (t, r) {
                r || (r = e.getLmsErrorMessageDetails(t)),
                    e.apiLog('throwSCORMError', null, t + ': ' + r, RegexScormConstants.LOG_LEVEL_ERROR),
                    (e.lastErrorCode = String(t));
            });
    },
    RegexScormAPIV1_2 = function () {
        var e = this;
        function t(t) {
            return {
                _id: '',
                get id() {
                    return this.jsonString ? this._id : t.throwSCORMError(404);
                },
                set id(e) {
                    this._id = e;
                },
                _time: '',
                get time() {
                    return this.jsonString ? this._time : t.throwSCORMError(404);
                },
                set time(e) {
                    this._time = e;
                },
                _type: '',
                get type() {
                    return this.jsonString ? this._type : t.throwSCORMError(404);
                },
                set type(e) {
                    this._type = e;
                },
                _weighting: '',
                get weighting() {
                    return this.jsonString ? this._weighting : t.throwSCORMError(404);
                },
                set weighting(e) {
                    this._weighting = e;
                },
                _student_response: '',
                get student_response() {
                    return this.jsonString ? this._student_response : t.throwSCORMError(404);
                },
                set student_response(e) {
                    this._student_response = e;
                },
                _result: '',
                get result() {
                    return this.jsonString ? this._result : t.throwSCORMError(404);
                },
                set result(e) {
                    this._result = e;
                },
                _latency: '',
                get latency() {
                    return this.jsonString ? this._latency : t.throwSCORMError(404);
                },
                set latency(e) {
                    this._latency = e;
                },
                objectives: {
                    childArray: [],
                    get _count() {
                        return this.childArray.length;
                    },
                    set _count(e) {
                        t.throwSCORMError(402);
                    },
                    toJSON: e.jsonFormatter
                },
                correct_responses: {
                    childArray: [],
                    get _count() {
                        return this.childArray.length;
                    },
                    set _count(e) {
                        t.throwSCORMError(402);
                    },
                    toJSON: e.jsonFormatter
                },
                toJSON: e.jsonFormatter
            };
        }
        function r(t) {
            return {
                _id: '',
                get id() {
                    return this._id;
                },
                set id(e) {
                    this._id = e;
                },
                _status: '',
                get status() {
                    return this._status;
                },
                set status(e) {
                    this._status = e;
                },
                score: {
                    __children: 'raw,min,max',
                    get _children() {
                        return this.__children;
                    },
                    set _children(e) {
                        t.throwSCORMError(402);
                    },
                    _raw: '',
                    get raw() {
                        return this._raw;
                    },
                    set raw(e) {
                        this._raw = e;
                    },
                    _min: '',
                    get min() {
                        return this._min;
                    },
                    set min(e) {
                        this._min = e;
                    },
                    _max: '',
                    get max() {
                        return this._max;
                    },
                    set max(e) {
                        this._max = e;
                    },
                    toJSON: e.jsonFormatter
                },
                toJSON: e.jsonFormatter
            };
        }
        function i(t) {
            return {
                _id: '',
                get id() {
                    return this.jsonString ? this._id : t.throwSCORMError(404);
                },
                set id(e) {
                    this._id = e;
                },
                toJSON: e.jsonFormatter
            };
        }
        function n(t) {
            return {
                _pattern: '',
                get pattern() {
                    return this.jsonString ? this._pattern : t.throwSCORMError(404);
                },
                set pattern(e) {
                    this._pattern = e;
                },
                toJSON: e.jsonFormatter
            };
        }
        RegexScormBaseAPI.call(e),
            (e.LMSInitialize = function () {
                var t = RegexScormConstants.SCORM_FALSE;
                return (
                    e.isInitialized()
                        ? e.throwSCORMError(101, 'LMS was already initialized!')
                        : e.isTerminated()
                        ? e.throwSCORMError(101, 'LMS is already finished!')
                        : ((e.currentState = RegexScormConstants.STATE_INITIALIZED), (t = RegexScormConstants.SCORM_TRUE), e.processListeners('LMSInitialize')),
                    e.apiLog('LMSInitialize', null, 'returned: ' + t, RegexScormConstants.LOG_LEVEL_INFO),
                    e.clearSCORMError(t),
                    t
                );
            }),
            (e.LMSFinish = function () {
                var t = RegexScormConstants.SCORM_FALSE;
                return (
                    e.checkState() &&
                        ((e.currentState = RegexScormConstants.STATE_TERMINATED), (t = RegexScormConstants.SCORM_TRUE), e.processListeners('LMSFinish')),
                    e.apiLog('LMSFinish', null, 'returned: ' + t, RegexScormConstants.LOG_LEVEL_INFO),
                    e.clearSCORMError(t),
                    t
                );
            }),
            (e.LMSGetValue = function (t) {
                var r = '';
                return (
                    e.checkState() && ((r = e.getCMIValue(t)), e.processListeners('LMSGetValue', t)),
                    e.apiLog('LMSGetValue', t, ': returned: ' + r, RegexScormConstants.LOG_LEVEL_INFO),
                    e.clearSCORMError(r),
                    r
                );
            }),
            (e.LMSSetValue = function (t, r) {
                var i = '';
                return (
                    e.checkState() && ((i = e.setCMIValue(t, r)), e.processListeners('LMSSetValue', t, r)),
                    e.apiLog('LMSSetValue', t, ': ' + r + ': returned: ' + i, RegexScormConstants.LOG_LEVEL_INFO),
                    e.clearSCORMError(i),
                    i
                );
            }),
            (e.LMSCommit = function () {
                var t = RegexScormConstants.SCORM_FALSE;
                return (
                    e.checkState() && ((t = RegexScormConstants.SCORM_TRUE), e.processListeners('LMSCommit')),
                    e.apiLog('LMSCommit', null, 'returned: ' + t, RegexScormConstants.LOG_LEVEL_INFO),
                    e.clearSCORMError(t),
                    t
                );
            }),
            (e.LMSGetLastError = function () {
                var t = e.lastErrorCode;
                return e.processListeners('LMSGetLastError'), e.apiLog('LMSGetLastError', null, 'returned: ' + t, RegexScormConstants.LOG_LEVEL_INFO), t;
            }),
            (e.LMSGetErrorString = function (t) {
                var r = '';
                return (
                    null !== t && '' !== t && ((r = e.getLmsErrorMessageDetails(t)), e.processListeners('LMSGetErrorString')),
                    e.apiLog('LMSGetErrorString', null, 'returned: ' + r, RegexScormConstants.LOG_LEVEL_INFO),
                    r
                );
            }),
            (e.LMSGetDiagnostic = function (t) {
                var r = '';
                return (
                    null !== t && '' !== t && ((r = e.getLmsErrorMessageDetails(t, !0)), e.processListeners('LMSGetDiagnostic')),
                    e.apiLog('LMSGetDiagnostic', null, 'returned: ' + r, RegexScormConstants.LOG_LEVEL_INFO),
                    r
                );
            }),
            (e.checkState = function () {
                return !!this.isInitialized() || (this.throwSCORMError(301), !1);
            }),
            (e.setCMIValue = function (o, s) {
                if (!o || '' === o) return RegexScormConstants.SCORM_FALSE;
                for (var a = o.split('.'), l = e, c = RegexScormConstants.SCORM_FALSE, _ = 0; _ < a.length; _++)
                    if (_ === a.length - 1)
                        Object.prototype.hasOwnProperty.call(l, a[_])
                            ? ((l[a[_]] = s), (c = RegexScormConstants.SCORM_TRUE))
                            : e.throwSCORMError(101, 'setCMIValue did not find an element for: ' + o);
                    else {
                        if (!(l = l[a[_]])) {
                            e.throwSCORMError(101, 'setCMIValue did not find an element for: ' + o);
                            break;
                        }
                        if (Object.prototype.hasOwnProperty.call(l, 'childArray')) {
                            var u = parseInt(a[_ + 1], 10);
                            if (!isNaN(u)) {
                                var m,
                                    d = l.childArray[u];
                                if (d) l = d;
                                else
                                    o.indexOf('cmi.objectives') > -1
                                        ? (m = new r(e))
                                        : o.indexOf('.correct_responses') > -1
                                        ? (m = new n(e))
                                        : o.indexOf('.objectives') > -1
                                        ? (m = new i(e))
                                        : o.indexOf('cmi.interactions') > -1 && (m = new t(e)),
                                        m ? (l.childArray.push(m), (l = m)) : e.throwSCORMError(101, 'Cannot create new sub entity: ' + o);
                                _++;
                            }
                        }
                    }
                return (
                    c === RegexScormConstants.SCORM_FALSE &&
                        e.apiLog(
                            'LMSSetValue',
                            null,
                            'There was an error setting the value for: ' + o + ', value of: ' + s,
                            RegexScormConstants.LOG_LEVEL_WARNING
                        ),
                    c
                );
            }),
            (e.getCMIValue = function (t) {
                if (!t || '' === t) return '';
                for (var r = t.split('.'), i = e, n = null, o = 0; o < r.length; o++)
                    (n = r[o]),
                        o === r.length - 1 &&
                            (Object.prototype.hasOwnProperty.call(i, r[o]) || e.throwSCORMError(101, 'getCMIValue did not find a value for: ' + t)),
                        (i = i[r[o]]);
                return null == i ? ('_children' === n ? e.throwSCORMError(202) : '_count' === n && e.throwSCORMError(203), '') : i;
            }),
            (e.getLmsErrorMessageDetails = function (e, t) {
                var r = '',
                    i = '';
                switch ((e = String(e))) {
                    case '101':
                        (r = 'General Exception'), (i = 'No specific error code exists to describe the error. Use LMSGetDiagnostic for more information');
                        break;
                    case '201':
                        (r = 'Invalid argument error'), (i = 'Indicates that an argument represents an invalid data model element or is otherwise incorrect.');
                        break;
                    case '202':
                        (r = 'Element cannot have children'),
                            (i =
                                'Indicates that LMSGetValue was called with a data model element name that ends in "_children" for a data model element that does not support the "_children" suffix.');
                        break;
                    case '203':
                        (r = 'Element not an array - cannot have count'),
                            (i =
                                'Indicates that LMSGetValue was called with a data model element name that ends in "_count" for a data model element that does not support the "_count" suffix.');
                        break;
                    case '301':
                        (r = 'Not initialized'), (i = 'Indicates that an API call was made before the call to LMSInitialize.');
                        break;
                    case '401':
                        (r = 'Not implemented error'),
                            (i =
                                'The data model element indicated in a call to LMSGetValue or LMSSetValue is valid, but was not implemented by this LMS. SCORM 1.2 defines a set of data model elements as being optional for an LMS to implement.');
                        break;
                    case '402':
                        (r = 'Invalid set value, element is a keyword'),
                            (i =
                                'Indicates that LMSSetValue was called on a data model element that represents a keyword (elements that end in "_children" and "_count").');
                        break;
                    case '403':
                        (r = 'Element is read only'), (i = 'LMSSetValue was called with a data model element that can only be read.');
                        break;
                    case '404':
                        (r = 'Element is write only'), (i = 'LMSGetValue was called on a data model element that can only be written to.');
                        break;
                    case '405':
                        (r = 'Incorrect Data Type'),
                            (i = 'LMSSetValue was called with a value that is not consistent with the data format of the supplied data model element.');
                        break;
                    default:
                        (r = 'Unknown error'), (i = 'Unknown error');
                }
                return t ? i : r;
            }),
            (e.loadFromJSON = function (t, r) {
                if (e.isNotInitialized()) {
                    if ('object' != typeof t && Array.isArray(t)) console.error('JSON object required.');
                    else
                        for (var i in ((r = r || 'cmi'), t))
                            if (Object.prototype.hasOwnProperty.call(t, i) && t[i]) {
                                var n = r + '.' + i,
                                    o = t[i];
                                if (o.childArray) for (var s = 0; s < o.childArray.length; s++) e.loadFromJSON(o.childArray[s], n + '.' + s);
                                else o.constructor === Object ? e.loadFromJSON(o, n) : e.setCMIValue(n, o);
                            }
                } else console.error('loadFromJSON can only be called before the call to LMSInitialize.');
            }),
            (e.replaceWithAnotherScormAPI = function (t) {
                (e.LMSInitialize = t.LMSInitialize),
                    (e.LMSFinish = t.LMSFinish),
                    (e.LMSGetValue = t.LMSGetValue),
                    (e.LMSSetValue = t.LMSSetValue),
                    (e.LMSCommit = t.LMSCommit),
                    (e.LMSGetLastError = t.LMSGetLastError),
                    (e.LMSGetErrorString = t.LMSGetErrorString),
                    (e.LMSGetDiagnostic = t.LMSGetDiagnostic),
                    (e.cmi = t.cmi),
                    (e.checkState = t.checkState),
                    (e.getLmsErrorMessageDetails = t.getLmsErrorMessageDetails),
                    (e.loadFromJSON = t.loadFromJSON),
                    (e.replaceWithAnotherScormAPI = t.replaceWithAnotherScormAPI),
                    (e = t);
            }),
            (e.cmi = new (function (t) {
                return {
                    _suspend_data: '',
                    get suspend_data() {
                        return this._suspend_data;
                    },
                    set suspend_data(e) {
                        this._suspend_data = e;
                    },
                    _launch_data: '',
                    get launch_data() {
                        return this._launch_data;
                    },
                    set launch_data(e) {
                        t.isNotInitialized() ? (this._launch_data = e) : t.throwSCORMError(403);
                    },
                    _comments: '',
                    get comments() {
                        return this._comments;
                    },
                    set comments(e) {
                        this._comments = e;
                    },
                    _comments_from_lms: '',
                    get comments_from_lms() {
                        return this._comments_from_lms;
                    },
                    set comments_from_lms(e) {
                        t.isNotInitialized() ? (this._comments_from_lms = e) : t.throwSCORMError(403);
                    },
                    core: {
                        __children: 'student_id,student_name,lesson_location,credit,lesson_status,entry,score,total_time,lesson_mode,exit,session_time',
                        get _children() {
                            return this.__children;
                        },
                        set _children(e) {
                            t.throwSCORMError(402);
                        },
                        _student_id: '',
                        get student_id() {
                            return this._student_id;
                        },
                        set student_id(e) {
                            t.isNotInitialized() ? (this._student_id = e) : t.throwSCORMError(403);
                        },
                        _student_name: '',
                        get student_name() {
                            return this._student_name;
                        },
                        set student_name(e) {
                            t.isNotInitialized() ? (this._student_name = e) : t.throwSCORMError(403);
                        },
                        _lesson_location: '',
                        get lesson_location() {
                            return this._lesson_location;
                        },
                        set lesson_location(e) {
                            this._lesson_location = e;
                        },
                        _credit: '',
                        get credit() {
                            return this._credit;
                        },
                        set credit(e) {
                            t.isNotInitialized() ? (this._credit = e) : t.throwSCORMError(403);
                        },
                        _lesson_status: '',
                        get lesson_status() {
                            return this._lesson_status;
                        },
                        set lesson_status(e) {
                            this._lesson_status = e;
                        },
                        _entry: '',
                        get entry() {
                            return this._entry;
                        },
                        set entry(e) {
                            t.isNotInitialized() ? (this._entry = e) : t.throwSCORMError(403);
                        },
                        _total_time: '',
                        get total_time() {
                            return this._total_time;
                        },
                        set total_time(e) {
                            t.isNotInitialized() ? (this._total_time = e) : t.throwSCORMError(403);
                        },
                        _lesson_mode: 'normal',
                        get lesson_mode() {
                            return this._lesson_mode;
                        },
                        set lesson_mode(e) {
                            t.isNotInitialized() ? (this._lesson_mode = e) : t.throwSCORMError(403);
                        },
                        _exit: '',
                        get exit() {
                            return this.jsonString ? this._exit : t.throwSCORMError(404);
                        },
                        set exit(e) {
                            this._exit = e;
                        },
                        _session_time: '',
                        get session_time() {
                            return this.jsonString ? this._session_time : t.throwSCORMError(404);
                        },
                        set session_time(e) {
                            this._session_time = e;
                        },
                        score: {
                            __children: 'raw,min,max',
                            get _children() {
                                return this.__children;
                            },
                            set _children(e) {
                                t.throwSCORMError(402);
                            },
                            _raw: '',
                            get raw() {
                                return this._raw;
                            },
                            set raw(e) {
                                this._raw = e;
                            },
                            _min: '',
                            get min() {
                                return this._min;
                            },
                            set min(e) {
                                this._min = e;
                            },
                            _max: '100',
                            get max() {
                                return this._max;
                            },
                            set max(e) {
                                this._max = e;
                            },
                            toJSON: e.jsonFormatter
                        },
                        toJSON: e.jsonFormatter
                    },
                    objectives: {
                        __children: 'id,score,status',
                        get _children() {
                            return this.__children;
                        },
                        set _children(e) {
                            t.throwSCORMError(402);
                        },
                        childArray: [],
                        get _count() {
                            return this.childArray.length;
                        },
                        set _count(e) {
                            t.throwSCORMError(402);
                        },
                        toJSON: e.jsonFormatter
                    },
                    student_data: {
                        __children: 'mastery_score,max_time_allowed,time_limit_action',
                        get _children() {
                            return this.__children;
                        },
                        set _children(e) {
                            t.throwSCORMError(402);
                        },
                        _mastery_score: '',
                        get mastery_score() {
                            return this._mastery_score;
                        },
                        set mastery_score(e) {
                            t.isNotInitialized() ? (this._mastery_score = e) : t.throwSCORMError(403);
                        },
                        _max_time_allowed: '',
                        get max_time_allowed() {
                            return this._max_time_allowed;
                        },
                        set max_time_allowed(e) {
                            t.isNotInitialized() ? (this._max_time_allowed = e) : t.throwSCORMError(403);
                        },
                        _time_limit_action: '',
                        get time_limit_action() {
                            return this._time_limit_action;
                        },
                        set time_limit_action(e) {
                            t.isNotInitialized() ? (this._time_limit_action = e) : t.throwSCORMError(403);
                        },
                        toJSON: e.jsonFormatter
                    },
                    student_preference: {
                        __children: 'audio,language,speed,text',
                        get _children() {
                            return this.__children;
                        },
                        set _children(e) {
                            t.throwSCORMError(402);
                        },
                        _audio: '',
                        get audio() {
                            return this._audio;
                        },
                        set audio(e) {
                            this._audio = e;
                        },
                        _language: '',
                        get language() {
                            return this._language;
                        },
                        set language(e) {
                            this._language = e;
                        },
                        _speed: '',
                        get speed() {
                            return this._speed;
                        },
                        set speed(e) {
                            this._speed = e;
                        },
                        _text: '',
                        get text() {
                            return this._text;
                        },
                        set text(e) {
                            this._text = e;
                        },
                        toJSON: e.jsonFormatter
                    },
                    interactions: {
                        __children: 'id,objectives,time,type,correct_responses,weighting,student_response,result,latency',
                        get _children() {
                            return this.__children;
                        },
                        set _children(e) {
                            t.throwSCORMError(402);
                        },
                        childArray: [],
                        get _count() {
                            return this.childArray.length;
                        },
                        set _count(e) {
                            t.throwSCORMError(402);
                        },
                        toJSON: e.jsonFormatter
                    },
                    toJSON: e.jsonFormatter
                };
            })(e));
    },
    RegexScormAPIV2004 = function () {
        var e = this;
        function t(t) {
            return {
                _comment: '',
                get comment() {
                    return this._comment;
                },
                set comment(e) {
                    this._comment = e;
                },
                _location: '',
                get location() {
                    return this._location;
                },
                set location(e) {
                    this._location = e;
                },
                _timestamp: '',
                get timestamp() {
                    return this._timestamp;
                },
                set timestamp(e) {
                    this._timestamp = e;
                },
                toJSON: e.jsonFormatter
            };
        }
        function r(t) {
            return {
                _comment: '',
                get comment() {
                    return this._comment;
                },
                set comment(e) {
                    t.isNotInitialized() ? (this._comment = e) : t.throwSCORMError(404);
                },
                _location: '',
                get location() {
                    return this._location;
                },
                set location(e) {
                    t.isNotInitialized() ? (this._location = e) : t.throwSCORMError(404);
                },
                _timestamp: '',
                get timestamp() {
                    return this._timestamp;
                },
                set timestamp(e) {
                    t.isNotInitialized() ? (this._timestamp = e) : t.throwSCORMError(404);
                },
                toJSON: e.jsonFormatter
            };
        }
        function i(t) {
            return {
                _id: '',
                get id() {
                    return this._id;
                },
                set id(e) {
                    this._id = e;
                },
                _type: '',
                get type() {
                    return this._type;
                },
                set type(e) {
                    this._type = e;
                },
                _timestamp: '',
                get timestamp() {
                    return this._timestamp;
                },
                set timestamp(e) {
                    this._timestamp = e;
                },
                _weighting: '',
                get weighting() {
                    return this._weighting;
                },
                set weighting(e) {
                    this._weighting = e;
                },
                _learner_response: '',
                get learner_response() {
                    return this._learner_response;
                },
                set learner_response(e) {
                    this._learner_response = e;
                },
                _result: '',
                get result() {
                    return this._result;
                },
                set result(e) {
                    this._result = e;
                },
                _latency: '',
                get latency() {
                    return this._latency;
                },
                set latency(e) {
                    this._latency = e;
                },
                _description: '',
                get description() {
                    return this._description;
                },
                set description(e) {
                    this._description = e;
                },
                objectives: {
                    childArray: [],
                    get _count() {
                        return this.childArray.length;
                    },
                    set _count(e) {
                        t.throwSCORMError(404);
                    },
                    toJSON: e.jsonFormatter
                },
                correct_responses: {
                    childArray: [],
                    get _count() {
                        return this.childArray.length;
                    },
                    set _count(e) {
                        t.throwSCORMError(404);
                    },
                    toJSON: e.jsonFormatter
                },
                toJSON: e.jsonFormatter
            };
        }
        function n(t) {
            return {
                _id: '',
                get id() {
                    return this._id;
                },
                set id(e) {
                    this._id = e;
                },
                toJSON: e.jsonFormatter
            };
        }
        function o(t) {
            return {
                _pattern: '',
                get pattern() {
                    return this._pattern;
                },
                set pattern(e) {
                    this._pattern = e;
                },
                toJSON: e.jsonFormatter
            };
        }
        function s(t) {
            return {
                _id: '',
                get id() {
                    return this._id;
                },
                set id(e) {
                    this._id = e;
                },
                _success_status: 'unknown',
                get success_status() {
                    return this._success_status;
                },
                set success_status(e) {
                    this._success_status = e;
                },
                _completion_status: 'unknown',
                get completion_status() {
                    return this._completion_status;
                },
                set completion_status(e) {
                    this._completion_status = e;
                },
                _progress_measure: '',
                get progress_measure() {
                    return this._progress_measure;
                },
                set progress_measure(e) {
                    this._progress_measure = e;
                },
                _description: '',
                get description() {
                    return this._description;
                },
                set description(e) {
                    this._description = e;
                },
                score: {
                    __children: 'scaled,raw,min,max',
                    get _children() {
                        return this.__children;
                    },
                    set _children(e) {
                        t.throwSCORMError(404);
                    },
                    _scaled: '',
                    get scaled() {
                        return this._scaled;
                    },
                    set scaled(e) {
                        this._scaled = e;
                    },
                    _raw: '',
                    get raw() {
                        return this._raw;
                    },
                    set raw(e) {
                        this._raw = e;
                    },
                    _min: '',
                    get min() {
                        return this._min;
                    },
                    set min(e) {
                        this._min = e;
                    },
                    _max: '',
                    get max() {
                        return this._max;
                    },
                    set max(e) {
                        this._max = e;
                    },
                    toJSON: e.jsonFormatter
                },
                toJSON: e.jsonFormatter
            };
        }
        function a(e) {
            return 'unknown';
        }
        function l(e) {
            return 'unknown';
        }
        RegexScormBaseAPI.call(e),
            (e.version = '1.0'),
            (e.Initialize = function () {
                var t = RegexScormConstants.SCORM_FALSE;
                return (
                    e.isInitialized()
                        ? e.throwSCORMError(103)
                        : e.isTerminated()
                        ? e.throwSCORMError(104)
                        : ((e.currentState = RegexScormConstants.STATE_INITIALIZED),
                          (e.lastErrorCode = 0),
                          (t = RegexScormConstants.SCORM_TRUE),
                          e.processListeners('Initialize')),
                    e.apiLog('Initialize', null, 'returned: ' + t, RegexScormConstants.LOG_LEVEL_INFO),
                    e.clearSCORMError(t),
                    t
                );
            }),
            (e.Terminate = function () {
                var t = RegexScormConstants.SCORM_FALSE;
                return (
                    e.isNotInitialized()
                        ? e.throwSCORMError(112)
                        : e.isTerminated()
                        ? e.throwSCORMError(113)
                        : ((e.currentState = RegexScormConstants.STATE_TERMINATED),
                          (e.lastErrorCode = 0),
                          (t = RegexScormConstants.SCORM_TRUE),
                          e.processListeners('Terminate')),
                    e.apiLog('Terminate', null, 'returned: ' + t, RegexScormConstants.LOG_LEVEL_INFO),
                    e.clearSCORMError(t),
                    t
                );
            }),
            (e.GetValue = function (t) {
                var r = '';
                return (
                    e.isNotInitialized()
                        ? e.throwSCORMError(122)
                        : e.isTerminated()
                        ? e.throwSCORMError(123)
                        : ((e.lastErrorCode = 0), (r = e.getCMIValue(t)), e.processListeners('GetValue', t)),
                    e.apiLog('GetValue', t, ': returned: ' + r, RegexScormConstants.LOG_LEVEL_INFO),
                    e.clearSCORMError(r),
                    r
                );
            }),
            (e.SetValue = function (t, r) {
                var i = '';
                return (
                    e.isNotInitialized()
                        ? e.throwSCORMError(132)
                        : e.isTerminated()
                        ? e.throwSCORMError(133)
                        : ((e.lastErrorCode = 0), (i = e.setCMIValue(t, r)), e.processListeners('SetValue', t, r)),
                    e.apiLog('SetValue', t, ': ' + r + ': result: ' + i, RegexScormConstants.LOG_LEVEL_INFO),
                    e.clearSCORMError(i),
                    i
                );
            }),
            (e.Commit = function () {
                var t = RegexScormConstants.SCORM_FALSE;
                return (
                    e.isNotInitialized()
                        ? e.throwSCORMError(142)
                        : e.isTerminated()
                        ? e.throwSCORMError(143)
                        : ((e.lastErrorCode = 0), (t = RegexScormConstants.SCORM_TRUE), e.processListeners('Commit')),
                    e.apiLog('Commit', null, 'returned: ' + t, RegexScormConstants.LOG_LEVEL_INFO),
                    e.clearSCORMError(t),
                    t
                );
            }),
            (e.GetLastError = function () {
                var t = String(e.lastErrorCode);
                return e.processListeners('GetLastError'), e.apiLog('GetLastError', null, 'returned: ' + t, RegexScormConstants.LOG_LEVEL_INFO), t;
            }),
            (e.GetErrorString = function (t) {
                var r = '';
                return (
                    null !== t && '' !== t && ((r = e.getLmsErrorMessageDetails(t)), e.processListeners('GetErrorString')),
                    e.apiLog('GetErrorString', null, 'returned: ' + r, RegexScormConstants.LOG_LEVEL_INFO),
                    r
                );
            }),
            (e.GetDiagnostic = function (t) {
                var r = '';
                return (
                    null !== t && '' !== t && ((r = e.getLmsErrorMessageDetails(t, !0)), e.processListeners('GetDiagnostic')),
                    e.apiLog('GetDiagnostic', null, 'returned: ' + r, RegexScormConstants.LOG_LEVEL_INFO),
                    r
                );
            }),
            (e.cmi = new (function (t) {
                return {
                    __version: '1.0',
                    get _version() {
                        return this.__version;
                    },
                    set _version(e) {
                        t.throwSCORMError(404);
                    },
                    _completion_status: 'unknown',
                    get completion_status() {
                        return this._completion_status;
                    },
                    set completion_status(e) {
                        this._completion_status = e;
                    },
                    _completion_threshold: '',
                    get completion_threshold() {
                        return this._completion_threshold;
                    },
                    set completion_threshold(e) {
                        t.isNotInitialized() ? (this._completion_threshold = e) : t.throwSCORMError(404);
                    },
                    _credit: 'credit',
                    get credit() {
                        return this._credit;
                    },
                    set credit(e) {
                        t.isNotInitialized() ? (this._credit = e) : t.throwSCORMError(404);
                    },
                    _entry: '',
                    get entry() {
                        return this._entry;
                    },
                    set entry(e) {
                        t.isNotInitialized() ? (this._entry = e) : t.throwSCORMError(404);
                    },
                    _exit: '',
                    get exit() {
                        return this.jsonString ? this._exit : t.throwSCORMError(405);
                    },
                    set exit(e) {
                        this._exit = e;
                    },
                    _launch_data: '',
                    get launch_data() {
                        return this._launch_data;
                    },
                    set launch_data(e) {
                        t.isNotInitialized() ? (this._launch_data = e) : t.throwSCORMError(404);
                    },
                    _learner_id: '',
                    get learner_id() {
                        return this._learner_id;
                    },
                    set learner_id(e) {
                        t.isNotInitialized() ? (this._learner_id = e) : t.throwSCORMError(404);
                    },
                    _learner_name: '',
                    get learner_name() {
                        return this._learner_name;
                    },
                    set learner_name(e) {
                        t.isNotInitialized() ? (this._learner_name = e) : t.throwSCORMError(404);
                    },
                    _location: '',
                    get location() {
                        return this._location;
                    },
                    set location(e) {
                        this._location = e;
                    },
                    _max_time_allowed: '',
                    get max_time_allowed() {
                        return this._max_time_allowed;
                    },
                    set max_time_allowed(e) {
                        t.isNotInitialized() ? (this._max_time_allowed = e) : t.throwSCORMError(404);
                    },
                    _mode: 'normal',
                    get mode() {
                        return this._mode;
                    },
                    set mode(e) {
                        t.isNotInitialized() ? (this._mode = e) : t.throwSCORMError(404);
                    },
                    _progress_measure: '',
                    get progress_measure() {
                        return this._progress_measure;
                    },
                    set progress_measure(e) {
                        this._progress_measure = e;
                    },
                    _scaled_passing_score: '',
                    get scaled_passing_score() {
                        return this._scaled_passing_score;
                    },
                    set scaled_passing_score(e) {
                        t.isNotInitialized() ? (this._scaled_passing_score = e) : t.throwSCORMError(404);
                    },
                    _session_time: '',
                    get session_time() {
                        return this.jsonString ? this._session_time : t.throwSCORMError(405);
                    },
                    set session_time(e) {
                        this._session_time = e;
                    },
                    _success_status: 'unknown',
                    get success_status() {
                        return this._success_status;
                    },
                    set success_status(e) {
                        this._success_status = e;
                    },
                    _suspend_data: '',
                    get suspend_data() {
                        return this._suspend_data;
                    },
                    set suspend_data(e) {
                        this._suspend_data = e;
                    },
                    _time_limit_action: 'continue,no message',
                    get time_limit_action() {
                        return this._time_limit_action;
                    },
                    set time_limit_action(e) {
                        t.isNotInitialized() ? (this._time_limit_action = e) : t.throwSCORMError(404);
                    },
                    _total_time: '0',
                    get total_time() {
                        return this._total_time;
                    },
                    set total_time(e) {
                        t.isNotInitialized() ? (this._total_time = e) : t.throwSCORMError(404);
                    },
                    comments_from_learner: {
                        __children: 'comment,location,timestamp',
                        get _children() {
                            return this.__children;
                        },
                        set _children(e) {
                            t.throwSCORMError(404);
                        },
                        childArray: [],
                        get _count() {
                            return String(this.childArray.length);
                        },
                        set _count(e) {
                            t.throwSCORMError(404);
                        },
                        toJSON: e.jsonFormatter
                    },
                    comments_from_lms: {
                        __children: 'comment,location,timestamp',
                        get _children() {
                            return this.__children;
                        },
                        set _children(e) {
                            t.throwSCORMError(404);
                        },
                        childArray: [],
                        get _count() {
                            return String(this.childArray.length);
                        },
                        set _count(e) {
                            t.throwSCORMError(404);
                        },
                        toJSON: e.jsonFormatter
                    },
                    interactions: {
                        __children: 'id,type,objectives,timestamp,correct_responses,weighting,learner_response,result,latency,description',
                        get _children() {
                            return this.__children;
                        },
                        set _children(e) {
                            t.throwSCORMError(404);
                        },
                        childArray: [],
                        get _count() {
                            return this.childArray.length;
                        },
                        set _count(e) {
                            t.throwSCORMError(404);
                        },
                        toJSON: e.jsonFormatter
                    },
                    learner_preference: {
                        __children: 'audio_level,language,delivery_speed,audio_captioning',
                        get _children() {
                            return this.__children;
                        },
                        set _children(e) {
                            t.throwSCORMError(404);
                        },
                        _audio_level: '1',
                        get audio_level() {
                            return this._audio_level;
                        },
                        set audio_level(e) {
                            this._audio_level = e;
                        },
                        _language: '',
                        get language() {
                            return this._language;
                        },
                        set language(e) {
                            this._language = e;
                        },
                        _delivery_speed: '1',
                        get delivery_speed() {
                            return this._delivery_speed;
                        },
                        set delivery_speed(e) {
                            this._delivery_speed = e;
                        },
                        _audio_captioning: '0',
                        get audio_captioning() {
                            return this._audio_captioning;
                        },
                        set audio_captioning(e) {
                            this._audio_captioning = e;
                        },
                        toJSON: e.jsonFormatter
                    },
                    objectives: {
                        __children: 'id,score,success_status,completion_status,progress_measure,description',
                        get _children() {
                            return this.__children;
                        },
                        set _children(e) {
                            t.throwSCORMError(404);
                        },
                        childArray: [],
                        get _count() {
                            return this.childArray.length;
                        },
                        set _count(e) {
                            t.throwSCORMError(404);
                        },
                        toJSON: e.jsonFormatter
                    },
                    score: {
                        __children: 'scaled,raw,min,max',
                        get _children() {
                            return this.__children;
                        },
                        set _children(e) {
                            t.throwSCORMError(404);
                        },
                        _scaled: '',
                        get scaled() {
                            return this._scaled;
                        },
                        set scaled(e) {
                            this._scaled = e;
                        },
                        _raw: '',
                        get raw() {
                            return this._raw;
                        },
                        set raw(e) {
                            this._raw = e;
                        },
                        _min: '',
                        get min() {
                            return this._min;
                        },
                        set min(e) {
                            this._min = e;
                        },
                        _max: '',
                        get max() {
                            return this._max;
                        },
                        set max(e) {
                            this._max = e;
                        },
                        toJSON: e.jsonFormatter
                    },
                    toJSON: e.jsonFormatter
                };
            })(e)),
            (e.adl = new (function (e) {
                return {
                    nav: {
                        _request: '_none_',
                        get request() {
                            return this._request;
                        },
                        set request(e) {
                            this._request = e;
                        },
                        request_valid: {
                            _continue: 'unknown',
                            get continue() {
                                return this._continue;
                            },
                            set continue(t) {
                                e.throwSCORMError(404);
                            },
                            _previous: 'unknown',
                            get previous() {
                                return this._previous;
                            },
                            set previous(t) {
                                e.throwSCORMError(404);
                            },
                            choice: { _isTargetValid: a },
                            jump: { _isTargetValid: l }
                        }
                    }
                };
            })(e)),
            (e.setCMIValue = function (a, l) {
                if (!a || '' === a) return RegexScormConstants.SCORM_FALSE;
                for (var c = a.split('.'), _ = e, u = RegexScormConstants.SCORM_FALSE, m = 0; m < c.length; m++) {
                    var d = c[m];
                    if (m === c.length - 1)
                        '{target=' == d.substr(0, 8) && 'function' == typeof _._isTargetValid
                            ? e.throwSCORMError(404)
                            : Object.prototype.hasOwnProperty.call(_, d)
                            ? ((_[d] = l), 0 == e.lastErrorCode && (u = RegexScormConstants.SCORM_TRUE))
                            : e.throwSCORMError(401, 'The data model element passed to SetValue (' + a + ') is not a valid SCORM data model element.');
                    else {
                        if (!(_ = _[d])) {
                            e.throwSCORMError(401, 'The data model element passed to SetValue (' + a + ') is not a valid SCORM data model element.');
                            break;
                        }
                        if (Object.prototype.hasOwnProperty.call(_, 'childArray')) {
                            var h = parseInt(c[m + 1], 10);
                            if (!isNaN(h)) {
                                var S,
                                    g = _.childArray[h];
                                if (g) _ = g;
                                else
                                    a.indexOf('cmi.comments_from_learner') > -1
                                        ? (S = new t(e))
                                        : a.indexOf('cmi.comments_from_lms') > -1
                                        ? (S = new r(e))
                                        : a.indexOf('cmi.objectives') > -1
                                        ? (S = new s(e))
                                        : a.indexOf('.correct_responses') > -1
                                        ? (S = new o(e))
                                        : a.indexOf('.objectives') > -1
                                        ? (S = new n(e))
                                        : a.indexOf('cmi.interactions') > -1 && (S = new i(e)),
                                        S
                                            ? (_.childArray.push(S), (_ = S))
                                            : e.throwSCORMError(
                                                  401,
                                                  'The data model element passed to SetValue (' + a + ') is not a valid SCORM data model element.'
                                              );
                                m++;
                            }
                        }
                    }
                }
                return (
                    u === RegexScormConstants.SCORM_FALSE &&
                        e.apiLog(
                            'SetValue',
                            null,
                            'There was an error setting the value for: ' + a + ', value of: ' + l,
                            RegexScormConstants.LOG_LEVEL_WARNING
                        ),
                    u
                );
            }),
            (e.getCMIValue = function (t) {
                if (!t || '' === t) return '';
                for (var r = t.split('.'), i = e, n = 0; n < r.length; n++) {
                    var o = r[n];
                    if ('{target=' == o.substr(0, 8) && 'function' == typeof i._isTargetValid) {
                        var s = o.substr(8, o.length - 9);
                        return i._isTargetValid(s);
                    }
                    if (!Object.prototype.hasOwnProperty.call(i, o))
                        return e.throwSCORMError(401, 'The data model element passed to GetValue (' + t + ') is not a valid SCORM data model element.'), '';
                    i = i[o];
                }
                return i || '';
            }),
            (e.getLmsErrorMessageDetails = function (e, t) {
                var r = '',
                    i = '';
                switch ((e = String(e))) {
                    case '0':
                        (r = 'No Error'), (i = 'No error occurred, the previous API call was successful.');
                        break;
                    case '101':
                        (r = 'General Exception'), (i = 'No specific error code exists to describe the error. Use GetDiagnostic for more information.');
                        break;
                    case '102':
                        (r = 'General Initialization Failure'), (i = 'Call to Initialize failed for an unknown reason.');
                        break;
                    case '103':
                        (r = 'Already Initialized'), (i = 'Call to Initialize failed because Initialize was already called.');
                        break;
                    case '104':
                        (r = 'Content Instance Terminated'), (i = 'Call to Initialize failed because Terminate was already called.');
                        break;
                    case '111':
                        (r = 'General Termination Failure'), (i = 'Call to Terminate failed for an unknown reason.');
                        break;
                    case '112':
                        (r = 'Termination Before Initialization'), (i = 'Call to Terminate failed because it was made before the call to Initialize.');
                        break;
                    case '113':
                        (r = 'Termination After Termination'), (i = 'Call to Terminate failed because Terminate was already called.');
                        break;
                    case '122':
                        (r = 'Retrieve Data Before Initialization'), (i = 'Call to GetValue failed because it was made before the call to Initialize.');
                        break;
                    case '123':
                        (r = 'Retrieve Data After Termination'), (i = 'Call to GetValue failed because it was made after the call to Terminate.');
                        break;
                    case '132':
                        (r = 'Store Data Before Initialization'), (i = 'Call to SetValue failed because it was made before the call to Initialize.');
                        break;
                    case '133':
                        (r = 'Store Data After Termination'), (i = 'Call to SetValue failed because it was made after the call to Terminate.');
                        break;
                    case '142':
                        (r = 'Commit Before Initialization'), (i = 'Call to Commit failed because it was made before the call to Initialize.');
                        break;
                    case '143':
                        (r = 'Commit After Termination'), (i = 'Call to Commit failed because it was made after the call to Terminate.');
                        break;
                    case '201':
                        (r = 'General Argument Error'),
                            (i =
                                'An invalid argument was passed to an API method (usually indicates that Initialize, Commit or Terminate did not receive the expected empty string argument.');
                        break;
                    case '301':
                        (r = 'General Get Failure'),
                            (i = 'Indicates a failed GetValue call where no other specific error code is applicable. Use GetDiagnostic for more information.');
                        break;
                    case '351':
                        (r = 'General Set Failure'),
                            (i = 'Indicates a failed SetValue call where no other specific error code is applicable. Use GetDiagnostic for more information.');
                        break;
                    case '391':
                        (r = 'General Commit Failure'),
                            (i = 'Indicates a failed Commit call where no other specific error code is applicable. Use GetDiagnostic for more information.');
                        break;
                    case '401':
                        (r = 'Undefined Data Model Element'),
                            (i = 'The data model element name passed to GetValue or SetValue is not a valid SCORM data model element.');
                        break;
                    case '402':
                        (r = 'Unimplemented Data Model Element'),
                            (i =
                                'The data model element indicated in a call to GetValue or SetValue is valid, but was not implemented by this LMS. In SCORM 2004, this error would indicate an LMS that is not fully SCORM conformant.');
                        break;
                    case '403':
                        (r = 'Data Model Element Value Not Initialized'),
                            (i =
                                'Attempt to read a data model element that has not been initialized by the LMS or through a SetValue call. This error condition is often reached during normal execution of a SCO.');
                        break;
                    case '404':
                        (r = 'Data Model Element Is Read Only'), (i = 'SetValue was called with a data model element that can only be read.');
                        break;
                    case '405':
                        (r = 'Data Model Element Is Write Only'), (i = 'GetValue was called on a data model element that can only be written to.');
                        break;
                    case '406':
                        (r = 'Data Model Element Type Mismatch'),
                            (i = 'SetValue was called with a value that is not consistent with the data format of the supplied data model element.');
                        break;
                    case '407':
                        (r = 'Data Model Element Value Out Of Range'),
                            (i = 'The numeric value supplied to a SetValue call is outside of the numeric range allowed for the supplied data model element.');
                        break;
                    case '408':
                        (r = 'Data Model Dependency Not Established'),
                            (i =
                                'Some data model elements cannot be set until another data model element was set. This error condition indicates that the prerequisite element was not set before the dependent element.');
                        break;
                    default:
                        (r = ''), (i = '');
                }
                return t ? i : r;
            }),
            (e.loadFromJSON = function (t, r) {
                if (e.isNotInitialized()) {
                    if ('object' != typeof t && Array.isArray(t)) console.error('JSON object required.');
                    else
                        for (var i in ((r = r || 'cmi'), t))
                            if (Object.prototype.hasOwnProperty.call(t, i) && t[i]) {
                                var n = r + '.' + i,
                                    o = t[i];
                                if (o.childArray) for (var s = 0; s < o.childArray.length; s++) e.loadFromJSON(o.childArray[s], n + '.' + s);
                                else o.constructor === Object ? e.loadFromJSON(o, n) : e.setCMIValue(n, o);
                            }
                } else console.error('loadFromJSON can only be called before the call to LMSInitialize.');
            }),
            (e.replaceWithAnotherScormAPI = function (t) {
                (e.Initialize = t.Initialize),
                    (e.Terminate = t.Terminate),
                    (e.GetValue = t.GetValue),
                    (e.SetValue = t.SetValue),
                    (e.Commit = t.Commit),
                    (e.GetLastError = t.GetLastError),
                    (e.GetErrorString = t.GetErrorString),
                    (e.GetDiagnostic = t.GetDiagnostic),
                    (e.cmi = t.cmi),
                    (e.adl = t.adl),
                    (e.getLmsErrorMessageDetails = t.getLmsErrorMessageDetails),
                    (e.loadFromJSON = t.loadFromJSON),
                    (e.replaceWithAnotherScormAPI = t.replaceWithAnotherScormAPI),
                    (e = t);
            });
    },
    RegexScormPlayerAPI = function (e, t, r, i, n) {
        var o,
            s = this,
            a = {},
            l = [],
            c = !1,
            _ = document.getElementsByName(e)[0];
        (s.getSCOList = function () {
            return a;
        }),
            (s.getScormVersion = function () {
                return o;
            }),
            (s.getNextSCO = function () {
                return !(c + 1 > l.length) && c + 1;
            }),
            (s.getSCO = function (e) {
                var t = e.split('_');
                return 1 == t.length ? a.items[t[0]] : 2 == t.length ? a.items[t[0]].items[t[1]] : void 0;
            }),
            (s.run = function (e, t) {
                null == t && (t = l[c]);
                var r = s.getSCO(t),
                    i = function () {
                        _.removeEventListener('load', i), e();
                    };
                _.addEventListener('load', i), (_.src = r.resource.url);
            }),
            (s.initScormPlayer = function (e) {
                const s = new XMLHttpRequest();
                (s.onload = function () {
                    var s = new DOMParser().parseFromString(this.responseText, 'application/xml'),
                        _ = s.getElementsByTagName('metadata')[0].getElementsByTagName('schemaversion')[0].textContent;
                    if (
                        /*console.log('Scorm Version ' + _),*/
                        0 != (o = '1.2' == _ ? '1.2' : ('CAM 1.3' == _ || '2004 3rd Edition' == _ || '2004 4th Edition' == _) && '2004')
                    ) {
                        var u = s.getElementsByTagName('resources')[0].children,
                            m = {};
                        for (let e = 0; e < u.length; e++) {
                            const r = u[e];
                            m[r.getAttribute('identifier')] = {
                                scormtype: r.getAttribute('adlcp:scormType'),
                                type: r.getAttribute('type'),
                                url: t + '/' + r.getAttribute('href')
                            };
                        }
                        console.debug(m);
                        var d,
                            h,
                            S,
                            g,
                            E,
                            p = s.getElementsByTagName('organizations')[0],
                            O = (p.getAttribute('default'), p.children[0]),
                            w = function (e, t, r) {
                                var i = '';
                                ('' == t && '' == e) || ((i = e + '_' + (t - 1)), '' == e && (i = '' + (t - 1)));
                                var n = { id: r.getAttribute('identifier'), title: '', items: [] };
                                r.hasAttribute('identifierref') &&
                                    null != m[r.getAttribute('identifierref')] &&
                                    ((n.resource = m[r.getAttribute('identifierref')]), (n.itrId = i), (n.itrIndex = l.length), l.push(i));
                                for (let e = 0; e < r.children.length; e++) {
                                    const t = r.children[e];
                                    if ('title' == t.tagName) n.title = t.textContent;
                                    else if ('item' == t.tagName) {
                                        var o = w(i, e, t);
                                        n.items.push(o);
                                    }
                                }
                                return 0 == n.items.length && delete n.items, n;
                            };
                        (a = w('', '', O)),
                            // console.log(l),
                            (c = 0),
                            // console.log(a),
                            (d = 'LMSInitialize'),
                            (h = 'LMSFinish'),
                            (S = 'LMSGetValue'),
                            (g = 'LMSSetValue'),
                            (E = 'LMSCommit'),
                            '1.2' == o
                                ? ((window.API = new RegexScormAPIV1_2()),
                                  window.API.loadFromJSON(i),
                                  (window.API.cmi.core.student_id = r.id),
                                  (window.API.cmi.core.student_name = r.name))
                                : ((window.API_1484_11 = new RegexScormAPIV2004()),
                                  (window.API = window.API_1484_11),
                                  (d = 'Initialize'),
                                  (h = 'Terminate'),
                                  (E = 'Commit'),
                                  (S = 'GetValue'),
                                  (g = 'SetValue'),
                                  (window.API.LMSSetValue = window.API.SetValue),
                                  (window.API.LMSGetValue = window.API.GetValue),
                                  window.API.loadFromJSON(i),
                                  (window.API.cmi.learner_id = r.id),
                                  (window.API.cmi.learner_name = r.name)),
                            window.API.on(d, function () {
                                if (/*console.log('LMSInitialize'),*/ null != n.LMSInitialize) return n.LMSInitialize();
                            }),
                            window.API.on(h, function () {
                                if (/*console.log('LMSFinish'),*/ null != n.LMSFinish) return n.LMSFinish(window.API.cmi.toJSON());
                            }),
                            window.API.on(S, function (e) {
                                if (/*console.log('LMSGetValue : ' + e),*/ null != n.LMSGetValue) return n.LMSGetValue(e);
                            }),
                            window.API.on(g, function (e, t) {
                                if (/*console.log('LMSSetValue : ' + e + ' = ' + t),*/ null != n.LMSSetValue) return n.LMSSetValue(e, t);
                            }),
                            window.API.on(E, function () {
                                if (/*console.log('LMSCommit'),*/ null != n.LMSCommit) return n.LMSCommit(window.API.cmi.toJSON());
                            }),
                            window.API.on('LMSGetLastError', function () {
                                if (/*console.log('LMSGetLastError : '),*/ null != n.LMSGetLastError) return n.LMSGetLastError();
                            }),
                            window.API.on('LMSGetErrorString', function (e) {
                                if (/*console.log('LMSGetErrorString : ' + e),*/ null != n.LMSGetErrorString) return n.LMSGetErrorString(e);
                            }),
                            window.API.on('LMSGetDiagnostic', function (e) {
                                if (/*console.log('LMSGetDiagnostic : ' + e),*/ null != n.LMSGetDiagnostic) return n.LMSGetDiagnostic(e);
                            }),
                            e(a.items);
                    } else alert('Unable to detect Scorm Version. This player is compatible with only 1.2 & 2004');
                }),
                    s.open('GET', t + '/imsmanifest.xml', !0),
                    s.send();
            });
    };
export { RegexScormPlayerAPI };
