<template>
    <v-container fluid ma-0 pa-0 fill-height>
        <v-layout column>
            <v-flex shrink>
                <Toolbar tooltipMessage="Hier können Sie Kunden verwalten" toolbarTitle="Kunden" />
            </v-flex>
            <v-flex grow>
                <CustomerManager customer />
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import CustomerManager from '../../Components/CustomerManager/CustomerManager';
import Toolbar from '../../Components/Generic/Toolbar';

import CustomerManagerMixin from '../../Components/CustomerManager/Mixins/CustomerManagerMixin';

export default {
    name: 'customer-manager-view',
    components: {
        CustomerManager,
        Toolbar
    },
    mixins: [CustomerManagerMixin],
    data: () => ({
        typeMap: new Map([
            ['ADD', 'Kunden hinzufügen'],
            ['UPDATE', 'Kunden bearbeiten'],
            ['UPDATE_SUPER_ADMIN', 'Super-Admin bearbeiten']
        ]),
        selectedType: 'ADD',
        showPrompt: false,
        showDelete: false
    }),
    computed: {
        isCustomerSelected() {
            return Object.keys(this.getSelectedCustomer).length > 0;
        },
        getTitle() {
            const type = this.typeMap.get(this.selectedType);
            if (type !== undefined) {
                return type;
            }
            return '';
        }
    },
    methods: {
        close() {
            this.showDelete = false;
        },
        handleSuperAdmin() {
            this.selectedType = 'UPDATE_SUPER_ADMIN';
            this.showPrompt = true;
        },
        addCustomer() {
            this.selectedType = 'ADD';
            this.showPrompt = true;
        },
        updateCustomer() {
            this.selectedType = 'UPDATE';
            this.showPrompt = true;
        },
        showDeleteModal() {
            this.showDelete = true;
        },
        deleteCustomer() {
            this.DELETE_CUSTOMER(this.getSelectedCustomer.customer);
            this.close();
        }
    },
    beforeMount() {
        this.GET_CUSTOMERS();
    }
};
</script>
