export default {
    browserDetection() {
        let browser = {
            isOpera: false,
            isFirefox: false,
            isSafari: false,
            isIE: false,
            isChrome: false,
            isBlink: false
        };
        // Opera 8.0+
        browser.isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

        // Firefox 1.0+
        browser.isFirefox = typeof InstallTrigger !== 'undefined';

        // Safari 3.0+ "[object HTMLElementConstructor]"
        browser.isSafari =
            /constructor/i.test(window.HTMLElement) ||
            (function (p) {
                return p.toString() === '[object SafariRemoteNotification]';
            })(!window['safari'] || (typeof window['safari'] !== 'undefined' && window['safari'].pushNotification));

        // Internet Explorer 6-11
        browser.isIE = /*@cc_on!@*/ false || !!document.documentMode;

        // Edge 20+
        browser.isEdge = !browser.isIE && !!window.StyleMedia;

        // Chrome 1 - 71
        browser.isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

        // Blink engine detection
        browser.isBlink = (browser.isChrome || browser.isOpera) && !!window.CSS;

        return browser;
    }
};
