<template>
    <v-card text>
        <v-subheader>{{ $t('Wissenseinheit') }}</v-subheader>
        <v-list-item>
            <v-list-item-action>
                <v-avatar color="secondary" size="36">
                    <span class="white--text">{{ knowledgeUnit.order }}</span>
                </v-avatar>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>{{ knowledgeUnit.question }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t(knowledgeUnit.questionType) }}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-subheader>{{ $t('Zielort') }}</v-subheader>
        <CatalogSelectorTree v-model="selectedCatalogs" />
        <v-card-actions>
            <v-btn block color="success" :disabled="!selectedCatalogs.length > 0" @click="submit">
                {{ $t('Einfügen') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import CatalogSelectorTree from './MultipleCatalogSelectorTree';
import AuthoringToolMixin from '../Mixins/AuthoringtoolMixin';

export default {
    name: 'knowledge-unit-copy-view',
    components: { CatalogSelectorTree },
    mixins: [AuthoringToolMixin],
    data: () => ({
        selectedCatalogs: []
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        knowledgeUnit: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        submit() {
            this.COPY_KNOWLEDGEUNIT({
                knowledgeUnit: this.knowledgeUnit,
                catalogs: this.selectedCatalogs
            }).then(() => {
                this.$emit('switchView', { component: '', data: {}, type: '' });
                this.$emit('openTree', this.selectedCatalogs.map((catalog) => this.calculateTreePath(catalog)).flat(1));
                if (this.selectedCatalogs.length === 1) {
                    this.$emit('input', this.selectedCatalogs[0]);
                }
                this.selectedCatalogs = [];
            });
        }
    },
    activated() {
        this.selectedCatalogs = [];
    }
};
</script>
