<template>
    <v-card-text>
        <v-container>
            <draggable v-model="optionsComputed">
                <transition-group name="slide-x-transition" tag="v-row" class="row" mode="out-in">
                    <v-col :cols="12" v-for="option in optionsComputed" :key="`option-${option.id}`">
                        <OptionNode :value="option" @media="mediaEmit" :disableInteraction="disableInteraction" move />
                    </v-col>
                </transition-group>
            </draggable>
        </v-container>
    </v-card-text>
</template>

<script>
import draggable from 'vuedraggable';
import OptionNode from '../Helpers/OptionNode';

export default {
    name: 'sequence-option',
    components: {
        draggable,
        OptionNode
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        disableInteraction: {
            default: false,
            type: Boolean
        }
    },
    computed: {
        optionsComputed: {
            get() {
                return this.value && this.value.knowledgeUnitOptions ? this.value.knowledgeUnitOptions : [];
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        mediaEmit(value) {
            this.$emit('media', value);
        }
    },
    activated() {
        this.$emit('valid', true);
    }
};
</script>
