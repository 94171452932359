<template>
    <v-btn icon @click="handleInput" small>
        <v-icon class="ma-0 pa-0" :size="24" :color="value ? 'accent' : 'grey darken-1'">{{ icon }}</v-icon>
    </v-btn>
</template>

<script>
export default {
    name: 'knowledge-content-checkbox',
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        icon() {
            return this.value ? this.$vuetify.icons.checkboxOn : this.$vuetify.icons.checkboxOff;
        }
    },
    methods: {
        handleInput() {
            this.$emit('input', !this.value);
        }
    }
};
</script>
