<template>
    <v-layout column>
        <v-flex v-for="option in options" :key="option.id">
            <v-card class="mb-1">
                <v-card-text>
                    <v-layout>
                        <v-flex shrink align-self-center justify-self-center>
                            <v-checkbox v-model="option.answered" />
                        </v-flex>
                        <v-flex>
                            <span class="text-caption">{{ option.point }}</span>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    name: 'check-answer-type',
    data: () => ({
        options: []
    }),
    watch: {
        options: {
            deep: true,
            handler(value) {
                this.$emit('input', value);
            }
        }
    },
    props: {
        value: {
            type: [Object, Array],
            default: () => ({})
        },
        settings: {
            type: Object,
            default: () => ({})
        }
    },
    mounted() {
        if (this.settings.hasOwnProperty('points')) {
            this.options = this.settings.points.map((option) => ({
                ...option,
                answered: false
            }));
            this.$emit('input', this.options);
            this.$emit('validate', true);
        }
    }
};
</script>
