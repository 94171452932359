<template>
    <v-container>
        <div class="mb-3">
            <CourseBreadcrumbs />
            <LearningGoal
                :value="getSelectedLearningGoal"
                :isActivity="true"
                :isCourseManagerValue="isCourseManagerValue"
                @selectedKnowledgeContent="handleSelectedKnowledgeContent"
                :selectedKnowledgeContent="selectedKnowledgeContent"
            />
        </div>
        <v-card>
            <v-toolbar dark flat dense color="secondary">
                <v-toolbar-title>{{ $t('Aktivitäten') }}</v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                    <AddUpdateActivity type="ADD" />
                    <AddUpdateActivity type="UPDATE" />
                    <DeleteActivity />
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" :small="$vuetify.breakpoint.xs" @click="goToEvaluations" color="yellow">
                                <v-icon color="white">mdi-check-all</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('Bewertungen') }}</span>
                    </v-tooltip>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                <Draggable v-if="getLearningGoalActivities.length > 0" v-model="computedLearningGoalActivities" :move="onMove">
                    <transition-group name="slide-x-transition" tag="v-row" class="row">
                        <v-col :xs="12" :sm="12" :md="6" v-for="item in getLearningGoalActivities" :key="item.id">
                            <Activity
                                :timeScaleInMinutes="selectedCourse.timeScaleInMinutes"
                                :value="item"
                                @input="(value) => (selectedActivity = value)"
                                :selectable="true"
                                :selected="selectedActivity"
                                @selected="handleCardClick"
                                :isAdmin="isAdmin"
                                @selectedKnowledgeContent="handleSelectedKnowledgeContent"
                                :selectedKnowledgeContent="selectedKnowledgeContent"
                            />
                        </v-col>
                    </transition-group>
                </Draggable>
                <v-layout column v-if="getLearningGoalActivities.length === 0">
                    <v-flex align-self-center justify-self-center>
                        <span>{{ $t('Keine Aktivitäten verfügbar') }}</span>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
        <KnowledgeContentPreview toolbarTitle="Content" v-model="computedSelectedKnowledgeContent" />
    </v-container>
</template>

<script>
import LearningGoal from './Helpers/LearningGoal/LearningGoal';
import Activity from './Helpers/Activity/Activity';
import CourseBreadcrumbs from './Breadcrumbs/CourseBreadcrumbs';
import KnowledgeContentPreview from '../../KnowledgeContent/KnowledgeContentPreview';
import CourseMixin from './Mixins/CourseMixin';
import Draggable from 'vuedraggable';
import AddUpdateActivity from './AddUpdateActivity';
import DeleteActivity from './DeleteActivity';

export default {
    name: 'topics',
    mixins: [CourseMixin],
    components: {
        LearningGoal,
        Activity,
        CourseBreadcrumbs,
        Draggable,
        KnowledgeContentPreview,
        AddUpdateActivity,
        DeleteActivity
    },
    data: () => ({
        showSearch: false,
        search: '',
        dragged: {
            order: 0,
            elementId: ''
        },
        selectedKnowledgeContent: []
    }),
    props: {
        isAdmin: {
            type: Boolean,
            value: false
        },
        isCourseManagerValue: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedLearningGoalActivities: {
            get() {
                return this.getLearningGoalActivities;
            },
            set() {
                this.REORDER_ACTIVITIES({
                    learningGoalId: this.getSelectedLearningGoal.id,
                    id: this.dragged.elementId,
                    order: this.dragged.order
                });
            }
        },
        selectedActivity: {
            get() {
                return this.getSelectedActivity;
            },
            set(value) {
                this.SET_SELECTED_ACTIVITY(value);
            }
        },
        selectedCourse: {
            get() {
                if (this.isAdmin) {
                    return this.getSelectedAdminCourse;
                }
                return this.getSelectedAttendeeCourse;
            },
            set(value) {
                if (this.isAdmin) {
                    this.SET_SELECTED_ADMIN_COURSE(value);
                } else {
                    this.SET_SELECTED_ATTENDEE_COURSE(value);
                }
            }
        },
        computedSelectedKnowledgeContent: {
            get() {
                if (this.selectedKnowledgeContent.length > 0) {
                    return this.selectedKnowledgeContent[0];
                }
                return {};
            },
            set() {
                this.selectedKnowledgeContent = [];
            }
        }
    },
    methods: {
        goToEvaluations() {
            this.$router.push({ name: 'evaluations' });
        },
        handleSelectedKnowledgeContent(value) {
            if (value.length > 0) {
                this.selectedKnowledgeContent = [value[value.length - 1]];
            } else {
                this.selectedKnowledgeContent = [];
            }
        },
        onMove(event) {
            this.dragged.order = event.relatedContext.index;
            if (event.relatedContext.index > event.draggedContext.index) {
                this.dragged.order++;
            }
            this.dragged.elementId = event.draggedContext.element.id;
        },
        handleCardClick(value) {
            this.selectedActivity = value;
        }
    },
    mounted() {
        this.GET_LEARNING_GOAL_ACTIVITIES(this.getSelectedLearningGoal);
        this.resetActivitySelection();
    }
};
</script>
