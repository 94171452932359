<template>
    <v-card>
        <v-tabs v-model="tabs" mandatory>
            <v-tab key="HOME">{{ $t('Home') }}</v-tab>
            <v-tab key="CREATEWIKI">{{ $t('Wiki hinzufügen') }}</v-tab>
            <v-tab key="MYWIKI">{{ $t('Meine Wiki') }}</v-tab>
            <v-tab key="MYCOMMISSIONS">{{ $t('Meine Aufträge') }}</v-tab>
            <v-tab v-if="hasProposalPermission" key="CHANGES">{{ $t('Änderungsvorschläge') }}</v-tab>
        </v-tabs>
        <v-card-text>
            <v-tabs-items v-model="tabs" touchless>
                <v-tab-item key="HOME">
                    <v-container grid-list-md fluid>
                        <v-row>
                            <v-autocomplete
                                v-model="selectedComputedWiki"
                                :search-input.sync="computedWikiSearch"
                                :items="getWikiList"
                                return-object
                                item-value="id"
                                item-text="name"
                                :filter="filterWiki"
                                :label="$t('Wiki Suchen')"
                                :hint="$t('Geben Sie Begriffe oder Schlagwörter ein, um nach relevanten Wikis zu suchen')"
                                persistent-hint
                                :no-data-text="$t('Keine Wiki')"
                                :no-results-text="$t('Kein Ergebnis')"
                                :loading="wikiDebounce !== null"
                                :no-filter="wikiDebounce !== null"
                                :disabled="getWikiList.length === 0"
                            >
                                <template v-slot:selection="{ item, selected }">
                                    <v-chip color="primary" dark :value="selected">
                                        {{ item.name }}
                                    </v-chip>
                                </template>
                                <template v-slot:item="{ item }">
                                    <v-list-item-action>
                                        <v-icon medium :class="getStateColor(item)">mdi-file-document-outline</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ tags(item) }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-autocomplete>
                        </v-row>
                        <v-row>
                            <v-autocomplete
                                v-model="selectedContent"
                                :search-input.sync="computedContentSearch"
                                :items="computedContent"
                                return-object
                                item-value="id"
                                item-text="title"
                                :filter="filterContent"
                                :label="$t('Content Suchen')"
                                :hint="$t('Geben Sie Begriffe oder Schlagwörter ein, um nach relevantem Content zu suchen')"
                                persistent-hint
                                :no-data-text="$t('Kein Content')"
                                :no-results-text="$t('Kein Ergebnis')"
                                :loading="contentDebounce !== null"
                                :no-filter="contentDebounce !== null"
                                :disabled="computedContent.length === 0"
                            >
                                <template v-slot:selection="{ item, selected }">
                                    <v-chip color="primary" dark :value="selected">
                                        {{ item.title }}
                                    </v-chip>
                                </template>
                                <template v-slot:item="{ item }">
                                    <v-list-item-action>
                                        <v-icon medium :class="getStateColor(item)">mdi-file</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.title }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ tags(item) }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-autocomplete>
                        </v-row>
                        <v-row v-show="getWikiHome.body !== ''">
                            <v-divider class="my-3" />
                        </v-row>
                        <v-row v-if="!homeDialog">
                            <div class="ck ck-content" v-html="getWikiHome.body"></div>
                            <v-spacer />
                            <v-btn v-if="canChangeWikiHome" color="warning" @click="editWikiHome" text>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on">mdi-pencil</v-icon>
                                    </template>
                                    <span>{{ $t('Hier können Sie die Wiki-Homepage bearbeiten') }}</span>
                                </v-tooltip>
                            </v-btn>
                            <div style="clear: both"></div>
                        </v-row>
                        <v-row v-else>
                            <v-col :cols="12">
                                <WikiHomeManager v-model="homeDialog" />
                            </v-col>
                        </v-row>
                        <v-row v-show="getWikiHome.body !== ''">
                            <v-divider class="my-3" />
                        </v-row>
                        <v-row>
                            <v-col>
                                <WikiMenu />
                            </v-col>
                            <v-col>
                                <v-card>
                                    <v-toolbar dense dark flat color="secondary">
                                        <v-toolbar-title>{{ $t('Am häufigsten gesehen') }}</v-toolbar-title>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-list two-line>
                                            <v-list-item v-for="item in mostViewed" :key="item.id" @click="setSelection(item)">
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <span class="primary--text">{{ item.name }}</span>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        {{ tags(item) }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-card-text>
                                </v-card>
                                <v-card>
                                    <v-toolbar dense dark text color="secondary">
                                        <v-toolbar-title>{{ $t('Neuster Wiki') }}</v-toolbar-title>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-list two-line>
                                            <v-list-item v-if="hasMostRecent" @click="setSelection(mostRecent)">
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <span class="primary--text">{{ mostRecent.name }}</span>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        {{ tags(mostRecent) }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="CREATEWIKI">
                    <v-container grid-list-md>
                        <AddUpdateWiki />
                    </v-container>
                </v-tab-item>
                <v-tab-item key="MYWIKI">
                    <List :items="getMyWiki" v-model="selectedComputedWiki" :showAppend="false" showOptions title="Wiki" :itemKeys="['name', 'tags']">
                        <template v-slot:action="{ action }">
                            <v-icon medium :class="getStateColor(action)">mdi-file-document-outline</v-icon>
                        </template>
                        <template v-slot:content="{ content }">
                            <v-list-item-title>{{ content.name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ tags(content) }}</v-list-item-subtitle>
                        </template>
                    </List>
                </v-tab-item>
                <v-tab-item key="MYCOMMISSIONS">
                    <List :items="getMyCommissions" v-model="selectedComputedWiki" :showAppend="false" showOptions title="Wiki" :itemKeys="['name', 'tags']">
                        <template v-slot:action="{ action }">
                            <v-icon medium :class="getStateColor(action)">mdi-file-document-outline</v-icon>
                        </template>
                        <template v-slot:content="{ content }">
                            <v-list-item-title>{{ content.name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ tags(content) }}</v-list-item-subtitle>
                        </template>
                    </List>
                </v-tab-item>
                <v-tab-item key="CHANGES">
                    <ProposalsList />
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
        <KnowledgeContentPreview v-model="selectedContent" toolbarTitle="Content">
            <WikiSelector v-model="selectedContent" />
        </KnowledgeContentPreview>
    </v-card>
</template>

<script>
import WikiMixin from './Mixins/WikiMixin';
import List from '../Generic/List/AdvancedList';
import ProposalsList from './WikiProposals';
import WikiSelector from './WikiSelector';
import KnowledgeContentPreview from '../KnowledgeContent/KnowledgeContentPreview';
import WikiMenu from './WikiMenu';
import WikiHomeManager from './WikiHomeManager';
import AddUpdateWiki from './AddUpdateWiki';

export default {
    name: 'wiki-homepage',
    mixins: [WikiMixin],
    components: { List, ProposalsList, WikiSelector, KnowledgeContentPreview, WikiMenu, WikiHomeManager, AddUpdateWiki },
    data: () => ({
        mostRecent: {},
        mostViewed: [],
        selectedContent: {},
        wikiSearch: '',
        wikiConfirm: '',
        wikiDebounce: null,
        contentSearch: '',
        contentConfirm: '',
        contentDebounce: null,
        tabs: 0,
        hasProposalPermission: false,
        homeDialog: false,
        canChangeWikiHome: false
    }),
    watch: {
        tabs(value) {
            if (value === 4) {
                if (this.hasProposalPermission) {
                    this.GET_WIKI_WITH_PROPOSALS();
                }
            }
        }
    },
    computed: {
        computedContentSearch: {
            get() {
                return this.contentSearch;
            },
            set(value) {
                this.contentSearch = value;
                if (this.contentDebounce) {
                    clearTimeout(this.contentDebounce);
                    this.contentDebounce = null;
                }
                if (value !== '') {
                    this.contentDebounce = setTimeout(
                        function () {
                            this.data.contentConfirm = this.search;
                            this.data.contentDebounce = null;
                        }.bind({ data: this, search: value }),
                        1500
                    );
                } else {
                    clearTimeout(this.contentDebounce);
                    this.contentDebounce = null;
                    this.contentConfirm = '';
                }
            }
        },
        computedWikiSearch: {
            get() {
                return this.wikiSearch;
            },
            set(value) {
                this.wikiSearch = value;
                if (this.wikiDebounce) {
                    clearTimeout(this.wikiDebounce);
                    this.wikiDebounce = null;
                }
                if (value !== '') {
                    this.wikiDebounce = setTimeout(
                        function () {
                            this.data.wikiConfirm = this.search;
                            this.data.wikiDebounce = null;
                        }.bind({ data: this, search: value }),
                        1500
                    );
                } else {
                    clearTimeout(this.wikiDebounce);
                    this.wikiDebounce = null;
                    this.wikiConfirm = '';
                }
            }
        },
        hasMostRecent() {
            return Object.keys(this.mostRecent).length > 0;
        },
        selectedComputedWiki: {
            get() {
                return this.getSelectedWiki;
            },
            set(value) {
                this.setSelection(value);
            }
        },
        computedContent() {
            return this.getWikiList.map((wiki) => wiki.knowledgeContents).flat(1);
        }
    },
    methods: {
        setSelection(value) {
            if (!!value && Object.keys(value).length > 0) {
                this.SET_SELECTED_WIKI(value);
                this.SET_VIEW({ component: 'GET_WIKI', type: '', data: value });
            }
        },
        getStateColor({ state: { stateName = '' } = {} } = {}) {
            switch (stateName) {
                case 'PENDING':
                    return 'warning--text';
                case 'APPROVED':
                    return 'success--text';
                case 'DECLINED':
                    return 'error--text';
            }
            return 'info--text';
        },
        tags({ tags }) {
            return tags ? tags : '';
        },
        filterContent(item) {
            if (!this.contentConfirm || this.contentConfirm.trim() === '') {
                return true;
            }
            let buildString = [...new Set(this.contentConfirm.split(' '))].filter((word) => word !== '').map((word) => word.toLowerCase());
            const text = Object.values(item)
                .filter((word) => typeof word === 'string' || typeof word === 'number')
                .map((word) => {
                    if (typeof word === 'number') {
                        return word.toString();
                    }
                    return word;
                })
                .join(' ')
                .toLowerCase();
            return buildString.some((word) => text.includes(word));
        },
        filterWiki(item) {
            if (!this.wikiConfirm || this.wikiConfirm.trim() === '') {
                return true;
            }
            if (this.wikiConfirm === this.wikiSearch) {
                let buildString = [...new Set(this.wikiConfirm.split(' '))].filter((word) => word !== '').map((word) => word.toLowerCase());
                const text = Object.values(item)
                    .filter((word) => typeof word === 'string' || typeof word === 'number')
                    .map((word) => {
                        if (typeof word === 'number') {
                            return word.toString();
                        }
                        return word;
                    })
                    .join(' ')
                    .toLowerCase();
                return buildString.some((word) => text.includes(word));
            } else {
                return true;
            }
        },
        editWikiHome() {
            this.homeDialog = true;
        }
    },
    activated() {
        this.GET_MOST_VIEWED_WIKI().then((response) => (this.mostViewed = response));
        this.GET_MOST_RECENT_WIKI().then((response) => (this.mostRecent = response));
        this.GET_MY_WIKI();
        this.GET_MY_COMMISSIONS();
    },
    mounted() {
        this.GET_WIKI_HOME();
        this.validateEditorState({ proposals: ['MANAGE_PROPOSAL_WIKI'] }).then((response) => {
            this.hasProposalPermission = Object.values(response).reduce((result, next) => result && next, true);
        });
        this.validateEditorState({ home: ['UPDATE_WIKI_HOME'] }).then((response) => {
            this.canChangeWikiHome = Object.values(response).reduce((result, next) => result && next, true);
        });
    }
};
</script>
