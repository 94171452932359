<template>
    <v-dialog v-model="showDialog" scrollable :width="$vuetify.breakpoint.thresholds.sm" persistent :fullscreen="$vuetify.breakpoint.xs">
        <template #activator="{ on, attrs }">
            <v-btn block dark color="warning" v-on="on" v-bind="attrs">
                {{ $t('Bearbeiten') }}
            </v-btn>
        </template>
        <v-card>
            <v-toolbar dense flat color="secondary" dark>
                <v-toolbar-title>{{ $t('Eigene Planung') }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col :cols="12">
                            <v-textarea
                                :label="$t('Notizen')"
                                :hint="$t('Schreiben Sie sich eigene Notizen')"
                                persistent-hint
                                v-model="schedule.notes"
                                no-resize
                                outlined
                                :rows="3"
                            />
                        </v-col>
                        <v-col :cols="6">
                            <DatePicker @pickerStartDateEmit="(value) => (startDate = value)" :startDateText="startDate" :min="minimumDate" />
                        </v-col>
                        <v-col :cols="6">
                            <TimePicker v-model="startTime" :label="$t('Startzeit')" />
                        </v-col>
                        <v-col :cols="6">
                            <DatePicker
                                @pickerStartDateEmit="(value) => (endDate = value)"
                                :startDateText="endDate"
                                :min="startDate"
                                :label1="$t('Enddatum')"
                            />
                        </v-col>
                        <v-col :cols="6">
                            <TimePicker v-model="endTime" :label="$t('Endzeit')" />
                        </v-col>
                        <v-col :cols="12">
                            <v-switch
                                v-if="isCourse"
                                v-model="showScheduleTab"
                                :label="$t('Eigene Planung anzeigen')"
                                :hint="$t('Ist diese Option aktiviert, wird die eigene Planung auf allen Kursebenen priorisiert angezeigt')"
                                persistent-hint
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn block @click="save" color="warning">
                    {{ $t('Bearbeiten') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import DatePicker from '../../Generic/PickerDate/PickerDate';
import TimePicker from '../../Generic/TimePicker/TimePicker';
import CourseMixin from './Mixins/CourseMixin';
import moment from 'moment';

export default {
    name: 'update-my-course',
    components: {
        DatePicker,
        TimePicker
    },
    mixins: [CourseMixin],
    data: () => ({
        showDialog: false,
        showScheduleTab: false,
        schedule: {
            estimatedWorkTimeInMinutes: 0,
            notes: null
        },
        validate: false,
        startTime: moment().format('HH:mm'),
        startDate: moment().format('YYYY-MM-DD'),
        endTime: moment().format('HH:mm'),
        endDate: moment().format('YYYY-MM-DD'),
        durationInDays: '',
        tabs: 0,
        id: ''
    }),
    watch: {
        showDialog(value) {
            {
                if (value) {
                    this.schedule = JSON.parse(JSON.stringify(this.items));
                    this.GET_COURSE_USER_PREFERENCES().then((response) => (this.showScheduleTab = response.prefersUserScheduling));
                    if (this.items.startDate !== null) {
                        this.startDate = this.items.startDate.split(' ')[0];
                        this.startTime = this.items.startDate.split(' ')[1].split(':00')[0];
                        this.endDate = this.items.endDate.split(' ')[0];
                        this.endTime = this.items.endDate.split(' ')[1].split(':00')[0];
                    } else {
                        this.startDate = moment().format('YYYY-MM-DD');
                        this.endDate = moment().format('YYYY-MM-DD');
                        this.startTime = moment().format('HH:mm');
                        this.endTime = moment().format('HH:mm');
                    }
                }
            }
        },
        startDate(value) {
            if (moment(value).isAfter(moment(this.endDate))) {
                this.endDate = moment(value).format('YYYY-MM-DD');
            }
        }
    },
    props: {
        items: {
            type: Object,
            default: () => ({})
        },
        course: {
            type: Object,
            default: () => ({})
        },
        courseType: {
            type: String,
            default: ''
        }
    },
    computed: {
        isCourse() {
            if (this.courseType === 'course') {
                return true;
            }
            return false;
        },
        minimumDate() {
            return moment().format('YYYY-MM-DD');
        },
        computedStartDateTime() {
            return `${this.startDate} ${this.startTime}:00`;
        },
        computedEndDateTime() {
            return `${this.endDate} ${this.endTime}:00`;
        },
        handleDurationInDays() {
            if (this.durationInDays === '') {
                return moment(this.endDate).diff(this.startDate, 'days') + 1;
            } else if (this.durationInDays !== moment(this.startDate).diff(this.endDate, 'days') + 1) {
                return this.durationInDays;
            }
            return '';
        }
    },
    methods: {
        serialize() {
            return {
                notes: this.schedule.notes,
                startDate: this.computedStartDateTime,
                endDate: this.computedEndDateTime,
                id: this.course.id
            };
        },
        reset() {
            this.notes = null;
            this.startDate = moment().format('YYYY-MM-DD');
            this.endDate = moment().format('YYYY-MM-DD');
            this.startTime = moment().format('HH:mm');
            this.endTime = moment().format('HH:mm');
        },
        close() {
            this.reset();
            this.showDialog = false;
        },
        save() {
            switch (this.courseType) {
                case 'course': {
                    this.UPDATE_COURSE_SCHEDULING(this.serialize())
                        .then(() =>
                            this.UPDATE_COURSE_USER_PREFERENCES({
                                prefersUserScheduling: this.showScheduleTab
                            })
                        )
                        .then(() => this.close());
                    return;
                }
                case 'topic': {
                    this.UPDATE_TOPIC_SCHEDULING(this.serialize()).then(() => this.close());
                    return;
                }
                case 'learningGoal': {
                    this.UPDATE_LEARNING_GOAL_SCHEDULING(this.serialize()).then(() => this.close());
                    return;
                }
                case 'activity': {
                    this.UPDATE_ACTIVITY_SCHEDULING(this.serialize()).then(() => this.close());
                    return;
                }
            }
        },
        rules(type) {
            switch (type) {
                case 'notNegative':
                    return (v) => {
                        if (v !== undefined && v !== null) {
                            const toNumber = Number(v);
                            return toNumber >= 0 || this.$t('Bitte geben Sie eine Zahl größer oder gleich 0 an');
                        }
                    };
                case 'notEmpty':
                    return (v) => !!v || this.$t('Dieses Feld darf nicht leer sein');
            }
        }
    }
};
</script>
<style scoped></style>
