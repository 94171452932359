<template>
    <v-img :src="getFlag" :max-width="size" :max-height="size" aspect-ratio="1" />
</template>
<script>
export default {
    name: 'flag',
    props: {
        alpha2: {
            type: String,
            default: 'de'
        },
        size: {
            type: Number,
            default: 48
        }
    },
    computed: {
        getFlag() {
            return require(`circle-flags/flags/${this.alpha2}.svg`);
        }
    }
};
</script>
