import axios from '../../plugins/Axios/axios';
import Vue from 'vue';

const state = {
    usersList: [],
    deletedUserList: [],
    selectedUser: {
        itemType: 'USER',
        itemTitle: 'Benutzer',
        path: '/User',
        data: {}
    },
    selectedUserGroupList: [],
    userStore: {}
};

// getters
const getters = {
    getUserList(state) {
        return state.usersList;
    },
    getSelectedUser(state) {
        return state.selectedUser;
    },
    getDeletedUserList(state) {
        return state.deletedUserList;
    },
    getSelectedUserGroupList(state) {
        return state.selectedUserGroupList;
    },
    getUserStore(state) {
        return state.userStore;
    }
};

// actions
const actions = {
    GET_USER_USERGROUPS({ commit }, { id: userId }) {
        return axios
            .get(`/users/${userId}/usergroups`)
            .then((response) => response.data.data)
            .then((response) => {
                return response;
            });
    },
    GET_USER_GROUPS_BY_USER({ commit }) {
        return axios.get(`/usergroups/members`).then((response) => response.data.data.groups);
    },
    ADD_USER({ commit, dispatch }, request) {
        return axios
            .post('/users', request)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateUserList', response);
                dispatch('UserGroupsOp/GET_USER_GROUP_TREE', null, {
                    root: true
                });
                return response;
            });
    },
    RESEND_ACTIVATIONLINK_BY_ID({ commit }, userId) {
        return axios.post('/activation?userId=' + userId).then((response) => response.data.data);
    },
    ADD_USER_TO_USERGROUP_LIST({ commit }, request) {
        return axios.post(`/users/${request.userId}/usergroups`, request.data).then((response) => response.data.data);
    },
    GET_USER_LIST({ commit }) {
        return axios
            .get(`users`)
            .then((response) => response.data.data.users)
            .then((response) => {
                commit('updateUserList', response);
                return response;
            });
    },
    GET_DELETED_USER_LIST({ commit }) {
        return axios
            .get('users?sort=createdAt,asc&deleted=true')
            .then((response) => response.data.data.users)
            .then((response) => {
                commit('updateDeletedUser', response);
                return response;
            });
    },
    DELETE_USER_BY_ID({ commit, dispatch }, userId) {
        return axios.delete(`users/${userId}`).then(() => {
            commit('deleteUser', userId);
            dispatch('UserGroupsOp/REMOVE_USER_FROM_GROUP_MAP', userId, {
                root: true
            });
            dispatch('UserGroupsOp/GET_USER_GROUP_TREE', null, { root: true });
        });
    },
    GET_SINGLE_USER_BY_ID({ commit }, userId) {
        return axios
            .get(`/users/${userId}`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateUserList', response);
                return response;
            });
    },
    UPDATE_USER({ commit, dispatch }, request) {
        return axios
            .patch(`/users/${request.id}`, request)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateUserList', response);
                dispatch('UserGroupsOp/GET_USER_GROUP_TREE', null, {
                    root: true
                });
                return response;
            });
    },
    UPDATE_USER_PROFILE({ commit }, request) {
        const { id: userId, ...user } = request;
        return axios.patch(`users/${userId}/userprofile`, user).then((response) => response.data.data);
    },
    UPDATE_ROLE_USERTOADMIN({ commit, dispatch }, request) {
        return axios.post(`/role`, request).then(() => {
            request.users.forEach((userId) => {
                dispatch('UserPrototype/REMOVE_USER_FROM_MAP', { id: userId }, { root: true });
                commit('UserGroupsOp/removeUserFromGroupMap', userId, {
                    root: true
                });
                commit('deleteUser', userId);
            });
            commit('updateSelectedUser', {});
        });
    },
    RECOVER_USER({ commit, dispatch }, request) {
        return axios
            .patch(`/users/${request.id}/recover`, request)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateUserList', response);
                dispatch('UserGroupsOp/GET_USER_GROUP_TREE', null, {
                    root: true
                });
                return response;
            });
    },
    UPDATE_USER_PASSWORD({ commit }, request) {
        return axios.patch(`/users/${request.id}/password`, request.data).then((response) => response.data.data);
    },
    SELECT_USER({ commit }, selectedUser) {
        commit('updateSelectedUser', selectedUser);
        return true;
    },
    RESET_SELECTED_USER({ commit }) {
        commit('updateSelectedUser', {});
    },
    SELECT_USERGROUP_LIST({ commit }, selectedList) {
        commit('updateSelectedUserGroupList', selectedList);
    },
    UPDATE_USER_STORE({ commit }, userStore) {
        commit('updateUserStore', userStore);
    },
    RESET_USER_STORE({ commit }) {
        commit('updateUserStore', {});
    }
};

// mutations
const mutations = {
    clear: (state) => {
        state.usersList = [];
        state.deletedUserList = [];
        state.selectedUser = {
            itemType: 'USER',
            itemTitle: 'Benutzer',
            path: '/User',
            data: {}
        };
    },
    updateUserStore: (state, userStore) => {
        state.userStore = userStore;
    },
    updateSelectedUserGroupList: (state, selectedList) => {
        state.selectedUserGroupList = selectedList;
    },
    updateUserList: (state, response) => {
        if (Array.isArray(response)) {
            const ids = state.usersList.map(({ id }) => id);
            Vue.set(
                state,
                'usersList',
                response.reduce((acc, curr) => {
                    if (ids.includes(curr.id)) {
                        const index = acc.findIndex(({ id }) => id === curr.id);
                        acc[index] = curr;
                    } else {
                        acc.push(curr);
                    }
                    return acc;
                }, state.usersList)
            );
        } else {
            if (state.selectedUser.data.hasOwnProperty('id')) {
                if (state.selectedUser.data.id === response.id) {
                    Vue.set(state.selectedUser, 'data', response);
                }
            }
            if (state.usersList.map((user) => user.id).includes(response.id)) {
                Vue.set(
                    state,
                    'usersList',
                    state.usersList.map((user) => {
                        if (user.id === response.id) {
                            return response;
                        }
                        return user;
                    })
                );
            } else {
                Vue.set(state, 'usersList', [...state.usersList, response]);
            }
        }
    },
    deleteUser: (state, user) => {
        if (typeof user === 'string') {
            const find = state.usersList.map((us) => us.id).indexOf(user);
            if (find !== -1) {
                state.usersList.splice(find, 1);
            }
            if (state.selectedUser.data.hasOwnProperty('id')) {
                if (state.selectedUser.data.id === user) {
                    state.selectedUser.data = {};
                }
            }
        }
        if (typeof user === 'object') {
            const { id: userId } = user;
            if (userId !== undefined && userId !== null) {
                const find = state.usersList.map((us) => us.id).indexOf(userId);
                if (find !== -1) {
                    state.usersList.splice(find, 1);
                }
            }
            if (state.selectedUser.data.hasOwnProperty('id')) {
                if (state.selectedUser.data.id === userId) {
                    state.selectedUser.data = {};
                }
            }
        }
    },
    updateSelectedUser: (state, selectedUser) => {
        state.selectedUser.data = selectedUser;
    },
    updateDeletedUser: (state, deletedUserList) => {
        state.deletedUserList = deletedUserList;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
