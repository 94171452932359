<template>
    <v-card height="100%">
        <v-container>
            <template v-if="!isSmallDisplay">
                <v-row>
                    <v-col :cols="6">
                        <v-autocomplete
                            v-model="computedSelectedScorm"
                            :search-input.sync="computedContentSearch"
                            :items="scormArray"
                            return-object
                            item-value="id"
                            item-text="originalTitle"
                            :filter="filterContent"
                            :label="$t('Kurse suchen')"
                            :hint="$t('Geben Sie Begriffe oder Schlagwörter ein, um nach relevanten Kursen zu suchen')"
                            persistent-hint
                            :no-data-text="$t('Kein Kurs')"
                            :no-results-text="$t('Kein Ergebnis')"
                            :loading="contentDebounce !== null"
                            :no-filter="contentDebounce !== null"
                            :disabled="scormArray.length === 0"
                        >
                            <template v-slot:selection="{ item, selected }">
                                <v-chip color="primary" :value="selected"> {{ item.localTitle }}</v-chip>
                            </template>
                            <template v-slot:item="{ item }">
                                <v-list-item-content>
                                    <v-list-item-title> {{ item.localTitle }}</v-list-item-title>
                                    <v-list-item-subtitle> {{ item.germanCategory }} </v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col :cols="2">
                        <v-select
                            v-model="computedSelectedCategory"
                            :items="categories"
                            :label="$t('Kategorie wählen')"
                            :hint="$t('Wählen Sie eine Kategorie, um die Liste zu filtern')"
                            persistent-hint
                            :menu-props="{
                                closeOnClick: true,
                                closeOnContentClick: true
                            }"
                        >
                            <template v-slot:prepend-item>
                                <v-list-item @click="computedSelectedCategory = ''">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('Keine Kategorie') }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col :cols="2" v-if="showProducer()">
                        <v-select
                            v-model="computedSelectedProducer"
                            :items="producer"
                            :label="$t('Hersteller wählen')"
                            :hint="$t('Wählen Sie den Hersteller aus, um die Liste zu filtern')"
                            persistent-hint
                            :menu-props="{
                                closeOnClick: true,
                                closeOnContentClick: true
                            }"
                        >
                            <template v-slot:prepend-item>
                                <v-list-item @click="computedSelectedProducer = ''">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('Kein Hersteller') }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col :cols="2" v-else-if="showCompletionStatus()">
                        <v-select
                            v-model="computedSelectedStatus"
                            :items="computedCompletionStatus"
                            :label="$t('Vollständigkeitsstatus wählen')"
                            :hint="$t('Wählen Sie den Vollständigkeitsstatus aus, um die Liste zu filtern')"
                            persistent-hint
                            :menu-props="{
                                closeOnClick: true,
                                closeOnContentClick: true
                            }"
                        >
                            <template v-slot:prepend-item>
                                <v-list-item @click="computedSelectedStatus = ''">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('Alle') }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col :cols="2" v-else>
                        <v-select
                            v-model="computedSelectedEnrollmentType"
                            :items="enrollmentTypes"
                            :label="$t('Anfragestatus wählen')"
                            :hint="$t('Wählen Sie den Anfragestatus aus, um die Liste zu filtern')"
                            persistent-hint
                            item-text="text"
                            item-value="value"
                            :menu-props="{
                                closeOnClick: true,
                                closeOnContentClick: true
                            }"
                        >
                            <template v-slot:prepend-item>
                                <v-list-item @click="computedSelectedEnrollmentType = ''">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('Alle') }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-select>
                    </v-col>

                    <v-col :cols="2">
                        <template v-for="lang in getLanguages">
                            <v-avatar :key="lang" class="ma-3" @click="addSelectedLanguage(lang)" height="58" width="58" :color="getBackgroundColor(lang)">
                                <Flag :alpha2="lang" />
                            </v-avatar>
                        </template>
                    </v-col>
                </v-row>
            </template>
            <template v-else>
                <v-row>
                    <v-col>
                        <v-autocomplete
                            v-model="computedSelectedScorm"
                            :search-input.sync="computedContentSearch"
                            :items="scormArray"
                            return-object
                            item-value="id"
                            item-text="originalTitle"
                            :filter="filterContent"
                            :label="$t('Kurse suchen')"
                            :hint="$t('Geben Sie Begriffe oder Schlagwörter ein, um nach relevanten Kursen zu suchen')"
                            persistent-hint
                            :no-data-text="$t('Kein Kurs')"
                            :no-results-text="$t('Kein Ergebnis')"
                            :loading="contentDebounce !== null"
                            :no-filter="contentDebounce !== null"
                            :disabled="scormArray.length === 0"
                        >
                            <template v-slot:selection="{ item, selected }">
                                <v-chip color="primary" dark :value="selected"> {{ item.localTitle }}</v-chip>
                            </template>
                            <template v-slot:item="{ item }">
                                <v-list-item-content>
                                    <v-list-item-title> {{ item.localTitle }}</v-list-item-title>
                                    <v-list-item-subtitle> {{ item.germanCategory }} </v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select
                            v-model="computedSelectedCategory"
                            :items="categories"
                            :label="$t('Kategorie wählen')"
                            :hint="$t('Wählen Sie eine Kategorie, um die Liste zu filtern')"
                            persistent-hint
                            :menu-props="{
                                closeOnClick: true,
                                closeOnContentClick: true
                            }"
                        >
                            <template v-slot:prepend-item>
                                <v-list-item @click="computedSelectedCategory = ''">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('Keine Kategorie') }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col v-if="showProducer()">
                        <v-select
                            v-model="computedSelectedProducer"
                            :items="producer"
                            :label="$t('Hersteller wählen')"
                            :hint="$t('Wählen Sie den Hersteller aus, um die Liste zu filtern')"
                            persistent-hint
                            :menu-props="{
                                closeOnClick: true,
                                closeOnContentClick: true
                            }"
                        >
                            <template v-slot:prepend-item>
                                <v-list-item @click="computedSelectedProducer = ''">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('Kein Hersteller') }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col v-else-if="showCompletionStatus()">
                        <v-select
                            v-model="computedSelectedStatus"
                            :items="computedCompletionStatus"
                            :label="$t('Vollständigkeitsstatus wählen')"
                            :hint="$t('Wählen Sie den Vollständigkeitsstatus aus, um die Liste zu filtern')"
                            persistent-hint
                            :menu-props="{
                                closeOnClick: true,
                                closeOnContentClick: true
                            }"
                        >
                            <template v-slot:prepend-item>
                                <v-list-item @click="computedSelectedStatus = ''">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('Alle') }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col v-else>
                        <v-select
                            v-model="computedSelectedEnrollmentType"
                            :items="enrollmentTypes"
                            :label="$t('Anfragestatus wählen')"
                            :hint="$t('Wählen Sie den Anfragestatus aus, um die Liste zu filtern')"
                            persistent-hint
                            item-text="text"
                            item-value="value"
                            :menu-props="{
                                closeOnClick: true,
                                closeOnContentClick: true
                            }"
                        >
                            <template v-slot:prepend-item>
                                <v-list-item @click="computedSelectedEnrollmentType = ''">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('Alle') }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <template v-for="lang in getLanguages">
                            <v-avatar :key="lang" class="ma-3" @click="addSelectedLanguage(lang)" height="58" width="58" :color="getBackgroundColor(lang)">
                                <Flag :alpha2="lang" />
                            </v-avatar>
                        </template>
                    </v-col>
                </v-row>
            </template>
            <v-row>
                <v-col :cols="12">
                    <v-data-iterator
                        :items="getFilteredScormList"
                        :no-data-text="$t('Keine Scorms voranden!')"
                        :footer-props="{
                            'items-per-page-text': $t('Scorms pro Seite'),
                            'items-per-page-options': [9, 18, 36, { text: $t('Alle'), value: -1 }]
                        }"
                        :items-per-page="9"
                        @update:page="updatePagination"
                    >
                        <template v-slot:default="{ items }">
                            <v-container>
                                <v-row>
                                    <v-col :xs="12" :sm="12" :md="4" v-for="(item, index) in items" :key="item.id" :cols="getCols(index, items)">
                                        <ScormNode :item="item" :context="nodeContext" />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                    </v-data-iterator>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>
<script>
import ScormMixins from './Mixins/ScormMixin';
import ScormNode from './Helpers/ScormNode';
import Flag from '../Generic/Flags/Flag';

export default {
    name: 'scorm-homepage',
    mixins: [ScormMixins],
    components: { ScormNode, Flag },
    data: () => ({
        contentSearch: '',
        contentConfirm: '',
        contentDebounce: null,
        selectedCategory: '',
        categories: [],
        producer: ['Sonic', 'Pinktum'],
        completionStatus: ['completed', 'incomplete', 'not attempted'],
        selectedStatus: '',
        selectedProducer: '',
        selectedLanguages: [],
        enrollmentTypes: [
            { text: 'Nicht angefragt', value: 'COMMISSIONED' },
            { text: 'PENDING', value: 'PENDING' },
            { text: ' DECLINED', value: 'DECLINED' }
        ],
        selectedEnrollmentType: ''
    }),
    props: {
        scorms: {
            type: Array,
            default: () => []
        },
        eShopPage: {
            type: Boolean,
            default: false
        },
        nodeContext: {
            type: String,
            default: 'SHOW_COURSE'
        }
    },
    computed: {
        computedCompletionStatus: {
            get() {
                this.translateCompletionStatus();
                return this.completionStatus;
            },
            set(value) {
                this.completionStatus = value;
            }
        },
        scormArray: {
            get() {
                return this.scorms;
            },
            set(value) {
                this.scorms = value;
            }
        },
        computedSelectedCategory: {
            get() {
                return this.selectedCategory;
            },
            set(value) {
                this.selectedCategory = value;
            }
        },
        computedSelectedProducer: {
            get() {
                return this.selectedProducer;
            },
            set(value) {
                this.selectedProducer = value;
            }
        },
        computedSelectedStatus: {
            get() {
                return this.selectedStatus;
            },
            set(value) {
                this.selectedStatus = value;
            }
        },
        computedSelectedEnrollmentType: {
            get() {
                return this.selectedEnrollmentType;
            },
            set(value) {
                this.selectedEnrollmentType = value;
            }
        },
        computedSelectedScorm: {
            get() {
                return this.getSelectedScorm;
            },
            set(value) {
                if (!value) {
                    this.SET_SELECTED_SCORM({});
                } else {
                    let n = this.getView.tabs;
                    this.SET_VIEW({ component: 'scormDisplay', type: this.nodeContext, data: this.value, tabs: n });
                    this.SET_SELECTED_SCORM(value);
                    if (this.nodeContext === 'START_COURSE') {
                        this.$router.push({ name: 'scormView' });
                    }
                }
            }
        },
        computedContentSearch: {
            get() {
                return this.contentSearch;
            },
            set(value) {
                this.contentSearch = value;
                if (this.contentDebounce) {
                    clearTimeout(this.contentDebounce);
                    this.contentDebounce = null;
                }
                if (value !== '') {
                    this.contentDebounce = setTimeout(
                        function () {
                            this.data.contentConfirm = this.search;
                            this.data.contentDebounce = null;
                        }.bind({ data: this, search: value }),
                        1500
                    );
                } else {
                    clearTimeout(this.contentDebounce);
                    this.contentDebounce = null;
                    this.contentConfirm = '';
                }
            }
        },
        getLanguages() {
            return ['de', 'gb'];
        },
        getFilteredScormList() {
            let filteredList;
            if (this.eShopPage) {
                filteredList = this.scormArray.filter((obj) => !this.getBoughtScorms.some((obj2) => obj.id === obj2.scormPackageId));
            } else {
                filteredList = this.scormArray;
            }
            if (this.computedSelectedCategory != '') {
                filteredList = filteredList.filter((item) => item.germanCategory == this.computedSelectedCategory);
            }
            if (this.computedSelectedProducer != '') {
                filteredList = filteredList.filter((item) => item.producer == this.computedSelectedProducer);
            }
            if (this.computedSelectedStatus != '') {
                filteredList = filteredList.filter((item) => this.filterForStatus(item));
            }
            if (this.computedSelectedEnrollmentType != '') {
                filteredList = filteredList.filter((item) => {
                    return item.state == this.computedSelectedEnrollmentType;
                });
            }
            if (this.selectedLanguages.length > 0) {
                filteredList = filteredList.filter((item) => {
                    switch (item.language.substring(0, 2)) {
                        case 'en':
                            return this.selectedLanguages.includes('gb');
                        case 'us':
                            return this.selectedLanguages.includes('gb');
                        default:
                            return this.selectedLanguages.includes(item.language.substring(0, 2));
                    }
                });
            }
            return filteredList;
        },
        isSmallDisplay() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return true;
                case 'sm':
                    return true;
                case 'md':
                    return false;
                case 'lg':
                    return false;
                case 'xl':
                    return false;
                default:
                    return false;
            }
        }
    },
    methods: {
        getBackgroundColor(lang) {
            if (this.$vuetify.theme.dark) {
                return this.selectedLanguages.includes(lang) ? 'white' : 'background';
            }
            return this.selectedLanguages.includes(lang) ? 'secondary' : 'white';
        },
        isDark() {
            return this.$vuetify.theme.dark;
        },
        filterForStatus(item) {
            if (!item.cmi) return true;
            switch (item.cmi.completion_status) {
                case 'completed':
                    return this.computedSelectedStatus === 'Completed' || this.computedSelectedStatus === 'Abgeschlossen';
                case 'incomplete':
                    return this.computedSelectedStatus === 'Incomplete' || this.computedSelectedStatus === 'Unvollständig';
                case '':
                    return item.cmi.entry == '' ? this.computedSelectedStatus === 'Not Attempted' || this.computedSelectedStatus === 'Nicht Gestartet' : true;
                default:
                    return true;
            }
        },
        showProducer() {
            return this.nodeContext === 'SHOW_COURSE';
        },
        showCompletionStatus() {
            return this.nodeContext === 'START_COURSE';
        },
        showEnrollmentType() {
            return this.nodeContext === 'SELF_ENROLL';
        },
        addSelectedLanguage(lang) {
            if (this.selectedLanguages.includes(lang)) {
                this.selectedLanguages = this.selectedLanguages.filter((selectedLang) => selectedLang != lang);
            } else {
                this.selectedLanguages.push(lang);
            }
        },
        updatePagination() {
            this.$vuetify.goTo(0);
        },
        getCols(index, items) {
            if (this.$vuetify.breakpoint.xs) {
                return 12;
            }
            if (index === items.length - 1 && index % 3 == 0) {
                return 12;
            }
            return 4;
        },
        filterContent(item) {
            if (!this.contentConfirm || this.contentConfirm.trim() === '') {
                return true;
            }
            let buildString = [...new Set(this.contentConfirm.split(' '))].filter((word) => word !== '').map((word) => word.toLowerCase());
            const text = Object.values(item)
                .filter((word) => typeof word === 'string' || typeof word === 'number')
                .map((word) => {
                    if (typeof word === 'number') {
                        return word.toString();
                    }
                    return word;
                })
                .join(' ')
                .toLowerCase();
            return buildString.some((word) => text.includes(word));
        },
        translateCompletionStatus() {
            this.completionStatus = this.completionStatus.map((status) => this.$t(status));
        },
        selectScorm(item) {
            this.computedSelectedScorm = item;
        }
    },
    created() {
        this.translateCompletionStatus();
    },
    watch: {
        eShopPage: {
            immediate: true,
            handler: function (isEshop) {
                this.GET_SCORM_CATEGORIES(isEshop ? 'eshop' : 'customer_scorms_packages').then((resp) => {
                    this.categories = resp;
                });
            }
        }
    }
};
</script>
