import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters('UserApp', ['getSelectedTaskArchive'])
    },
    methods: {
        ...mapActions('UserApp', ['ADD_ARCHIVE', 'UPDATE_ARCHIVE', 'ASSIGN_USER_ARCHIVE_CATALOGS', 'GET_ARCHIVE_CATALOGS'])
    }
};
