<template>
    <v-card>
        <v-toolbar dark color="secondary" flat dense>
            <v-toolbar-title>{{ $t('Kursauswertung') }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col :cols="12">
                        <v-progress-linear color="success" :value="computedProgress" :height="10" :indeterminate="loading">
                            <span class="caption white--text" v-show="loading">{{ `${computedProgress}%` }}</span>
                        </v-progress-linear>
                    </v-col>
                    <v-expand-transition>
                        <v-col :cols="12" v-if="expand">
                            <v-list>
                                <v-list-item>
                                    <v-list-item-content>
                                        <GradingTableSelector />
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('Maximal erreichbare Punkte') }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <span>{{ computedSelectedGrading.maxPoints }}</span>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('Punkte') }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <span>{{ computedSelectedGrading.achievedPoints }}</span>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('Erforderlich') }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <span>{{ computedSelectedGrading.minPoints }}</span>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('Erforderliche Punkte bis zur nächsten Note') }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <span>{{ computedSelectedGrading.nextGradePoints }}</span>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('Nächste Note') }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <span>{{ computedSelectedGrading.nextGrade }}</span>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('Selbsteinschätzung / Lehrerbewertung') }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-switch v-model="isTeacherGrading" />
                                    </v-list-item-action>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('Nur aktuell erreichbare Punkte') }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-switch v-model="isTimeRelativeGrading" />
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-expand-transition>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions class="pa-0">
            <v-layout column>
                <v-flex align-self-center justify-self-center>
                    <v-btn icon @click="() => (expand = !expand)">
                        <v-icon>{{ !expand ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-card-actions>
    </v-card>
</template>

<script>
import CourseMixin from '../CourseManager/Mixins/CourseMixin';
import GradingTableSelector from '../CourseManager/Helpers/Evaluation/GradingTableSelector';

export default {
    name: 'my-course-evaluation',
    components: { GradingTableSelector },
    mixins: [CourseMixin],
    data: () => ({
        evaluation: {},
        expand: false,
        isTeacherGrading: true,
        isTimeRelativeGrading: false,
        loading: true
    }),
    computed: {
        computedProgress() {
            const prog = ((this.computedSelectedGrading.achievedPoints / this.computedSelectedGrading.nextGradePoints) * 100).toFixed(2);
            return Number.isNaN(prog) ? prog : 100;
        },
        computedIsTeacherGrading() {
            return this.isTeacherGrading ? 'TeacherGradings' : 'UserGradings';
        },
        computedIsTimeRelativeGrading() {
            return this.isTimeRelativeGrading ? 'timeRelative' : 'total';
        },
        computedGrading() {
            return `${this.computedIsTimeRelativeGrading}${this.computedIsTeacherGrading}`;
        },
        computedSelectedGrading() {
            if (this.evaluation.hasOwnProperty('grading')) {
                const { id: gradingTableId } = this.getSelectedGradingTable;
                const find = this.evaluation.grading[this.computedGrading]
                    .map((grading) => {
                        if (grading.gradingTable === null) {
                            return null;
                        }
                        return grading.gradingTable.id;
                    })
                    .indexOf(gradingTableId);
                if (find !== -1) {
                    return this.evaluation.grading[this.computedGrading][find].grading;
                } else {
                    return this.evaluation.grading[this.computedGrading][0].grading;
                }
            }
            return { achievedPoints: 1, nextGradePoints: 1 };
        }
    },
    mounted() {
        this.GET_ATTENDEES_COURSE_EVALUATION({
            course: this.getSelectedAttendeeCourse,
            user: this.getLoggedInAdmin
        })
            .then((response) => (this.evaluation = response))
            .then(() => (this.loading = false));
    }
};
</script>
