import Scorm from '../../views/SCORM/Scorm';
//import ScormPlayerView from '../../views/SCORM/ScormPlayerView';
import ScormPlayerView from '../../views/SCORM/RegexScormPlayer';

import { superAdmin, admin, user, root, sales } from '../Roles';

export default [
    {
        path: '/EShop',
        name: 'EShop',
        component: Scorm,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin, user, admin],
            navigation: {
                type: { name: 'Utility', groupId: 'Sonstiges' },
                priority: 1,
                icon: 'mdi-shopping',
                name: 'E-Shop',
                navigationKey: 'E_SHOP'
            }
        }
    },
    {
        path: '/scormview',
        name: 'scormView',
        component: ScormPlayerView,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: false,
            roleSet: []
        }
    }
];
