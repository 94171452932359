<template>
    <v-data-iterator
        :items="items"
        :items-per-page="4"
        :hideDefaultFooter="items.length < 4"
        :options.sync="pagination"
        :footer-props="{
            'items-per-page-text': $t('Wissenseinheiten pro Seite'),
            'items-per-page-options': [4, 8, { value: -1, text: $t('Alle') }]
        }"
        :value="computedSelection"
    >
        <template #default="{ items, selected }">
            <v-container fluid>
                <v-row>
                    <v-col v-for="item in items" :cols="6" :key="item.id">
                        <v-card
                            :class="{
                                primary: selected,
                                pointer: true,
                                'no-highlight': true,
                                'd-flex': true,
                                'flex-column': true
                            }"
                            :dark="selected"
                            height="100%"
                            @click="selectItem(item)"
                        >
                            <v-toolbar flat dark dense color="secondary">
                                <v-toolbar-title>{{ $t(item.questionType) }}</v-toolbar-title>
                            </v-toolbar>
                            <v-card flat height="100%" class="d-flex flex-column">
                                <v-container fluid>
                                    <v-row>
                                        <v-col :cols="12">
                                            <span>{{ item.question }}</span>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-spacer />
                                <v-container>
                                    <v-row>
                                        <v-col :cols="12">
                                            <v-sheet :color="answerColor(item)" dark pa-0 ma-0>
                                                <v-card-text>
                                                    <span>{{ answerText(item) }}</span>
                                                </v-card-text>
                                            </v-sheet>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </v-data-iterator>
</template>

<script>
export default {
    name: 'quiz-result-selector',
    data: () => ({
        pagination: {
            page: 1
        }
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        items: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        computedSelection: {
            get() {
                if (Object.keys(this.value) > 0) {
                    return [this.value];
                }
                return [];
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        answerColor({ hasAnswered, hasAnsweredCorrect }) {
            return hasAnswered ? (hasAnsweredCorrect ? 'success' : 'error') : this.$t('info');
        },
        selectItem(item) {
            this.computedSelection = item;
        },
        answerText({ hasAnswered, hasAnsweredCorrect }) {
            return hasAnswered
                ? hasAnsweredCorrect
                    ? this.$t('Diese Wissenseinheit wurde richtig beantwortet')
                    : this.$t('Diese Wissenseinheit wurde falsch beantwortet')
                : this.$t('Diese Wissenseinheit wurde nicht beantwortet');
        }
    }
};
</script>
