<template>
    <v-card flat height="100%">
        <v-toolbar flat dense text color="transparent" v-show="getView.type !== ''">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn medium icon @click="reset" v-on="on">
                        <v-icon medium>mdi-chevron-left</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('Zurück') }}</span>
            </v-tooltip>
            <v-toolbar-title class="text-body-2">{{ typeTitle }}</v-toolbar-title>
        </v-toolbar>
        <v-container fluid fill-height>
            <v-row no-gutters class="fill-height">
                <v-col :cols="12">
                    <keep-alive>
                        <component
                            :is="componentSwitch"
                            :value="value"
                            :knowledgeUnit="getView.data"
                            :type="getView.type"
                            @switchView="handleSwitchView"
                            @input="handleInput"
                            :onSubmit="onSubmit"
                            :search="search"
                            @openTree="handleOpenTree"
                        />
                    </keep-alive>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import KnowledgeUnitListView from './KnowledgeUnitListView';
import KnowledgeUnitCopyView from './KnowledgeUnitCopyView';
import KnowledgeUnitMoveView from './KnowledgeUnitMoveView';
import KnowledgeUnitPreviewView from './KnowledgeUnitPreviewView';
import AddUpdateKnowledgeUnit from '../AddUpdateKnowledgeUnit';
import CatalogCopyView from './CatalogCopyView';
import CatalogMoveView from './CatalogMoveView';
import CatalogDeleteConfirm from './CatalogDeleteConfirm.vue';

import RootView from './RootView';
import CatalogView from './CatalogView';

import AddUpdateCatalog from '../AddUpdateCatalogGroup';

import AuthoringToolMixin from '../Mixins/AuthoringtoolMixin';

export default {
    name: 'knowledge-unit-view',
    components: {
        KnowledgeUnitListView,
        KnowledgeUnitCopyView,
        KnowledgeUnitMoveView,
        KnowledgeUnitPreviewView,
        CatalogCopyView,
        CatalogMoveView,
        AddUpdateKnowledgeUnit,
        AddUpdateCatalog,
        CatalogDeleteConfirm
    },
    mixins: [AuthoringToolMixin],
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        search: {
            type: String,
            default: ''
        }
    },
    watch: {
        value() {
            this.reset();
        }
    },
    computed: {
        typeTitle() {
            switch (this.getView.component) {
                case 'ADD_CATALOG_GROUP':
                    return this.$t('Kataloggruppe erstellen');
                case 'ADD_CATALOG_SUBGROUP':
                    return this.$t('Kataloguntergruppe erstellen');
                case 'UPDATE_CATALOG_GROUP':
                    return this.$t('Kataloggruppe bearbeiten');
                case 'UPDATE_CATALOG_SUBGROUP':
                    return this.$t('Kataloguntergruppe bearbeiten');
                case 'ADD_CATALOG':
                    return this.$t('Katalog erstellen');
                case 'UPDATE_CATALOG':
                    return this.$t('Katalog bearbeiten');
                case 'COPY_CATALOG':
                    return this.$t('Katalog kopieren');
                case 'MOVE_CATALOG':
                    return this.$t('Katalog verschieben');
                case 'ADD_KNOWLEDGE_UNIT':
                    return this.$t('Wissenseinheit erstellen');
                case 'UPDATE_KNOWLEDGE_UNIT':
                    return this.$t('Wissenseinheit bearbeiten');
                case 'COPY_KNOWLEDGE_UNIT':
                    return this.$t('Wissenseinheit kopieren');
                case 'MOVE_KNOWLEDGE_UNIT':
                    return this.$t('Wissenseinheit verschieben');
                case 'PREVIEW_KNOWLEDGE_UNIT':
                    return this.$t('Wissenseinheit Vorschau');
            }
            return '';
        },
        componentSwitch() {
            switch (this.getView.component) {
                case 'ADD_CATALOG_GROUP':
                    return AddUpdateCatalog;
                case 'ADD_CATALOG_SUBGROUP':
                    return AddUpdateCatalog;
                case 'ADD_CATALOG':
                    return AddUpdateCatalog;
                case 'UPDATE_CATALOG_GROUP':
                    return AddUpdateCatalog;
                case 'UPDATE_CATALOG_SUBGROUP':
                    return AddUpdateCatalog;
                case 'DELETE_CATALOG_SUBGROUP':
                    return CatalogDeleteConfirm;
                case 'DELETE_CATALOG_GROUP':
                    return CatalogDeleteConfirm;
                case 'DELETE_CATALOG':
                    return CatalogDeleteConfirm;
                case 'UPDATE_CATALOG':
                    return AddUpdateCatalog;
                case 'COPY_CATALOG':
                    return CatalogCopyView;
                case 'MOVE_CATALOG':
                    return CatalogMoveView;
                case 'ADD_KNOWLEDGE_UNIT':
                    return AddUpdateKnowledgeUnit;
                case 'UPDATE_KNOWLEDGE_UNIT':
                    return AddUpdateKnowledgeUnit;
                case 'COPY_KNOWLEDGE_UNIT':
                    return KnowledgeUnitCopyView;
                case 'MOVE_KNOWLEDGE_UNIT':
                    return KnowledgeUnitMoveView;
                case 'PREVIEW_KNOWLEDGE_UNIT':
                    return KnowledgeUnitPreviewView;
            }
            const type = this.isAuthoringToolType(this.value);
            switch (type) {
                case 'CATALOG_GROUP':
                    return CatalogView;
                case 'CATALOG_SUBGROUP':
                    return CatalogView;
                case 'CATALOG':
                    return KnowledgeUnitListView;
            }
            return RootView;
        }
    },
    methods: {
        handleOpenTree(value) {
            this.$emit('openTree', value);
        },
        handleInput(input) {
            this.$emit('input', input);
        },
        handleSwitchView(view) {
            this.SET_VIEW(view);
        },
        reset() {
            this.SET_VIEW({ view: '', data: {}, type: '' });
        },
        onSubmit() {
            const type = this.getView.component;
            switch (type) {
                case 'ADD_CATALOG':
                    return (v) =>
                        this.ADD_CATALOG(v)
                            .then((response) => this.$emit('input', response))
                            .then(() => this.reset());
                case 'ADD_CATALOG_SUBGROUP':
                    return (v) =>
                        this.ADD_CATALOGSUBGROUP(v)
                            .then((response) => this.$emit('input', response))
                            .then(() => this.reset());
                case 'ADD_CATALOG_GROUP':
                    return (v) =>
                        this.ADD_CATALOGGROUP(v)
                            .then((response) => this.$emit('input', response))
                            .then(() => this.reset());
                case 'UPDATE_CATALOG_GROUP':
                    return (v) =>
                        this.UPDATE_CATALOGGROUP(v)
                            .then((response) => this.$emit('input', response))
                            .then(() => this.reset());
                case 'UPDATE_CATALOG_SUBGROUP':
                    return (v) =>
                        this.UPDATE_CATALOGSUBGROUP(v)
                            .then((response) => this.$emit('input', response))
                            .then(() => this.reset());
                case 'UPDATE_CATALOG':
                    return (v) =>
                        this.UPDATE_CATALOG(v)
                            .then((response) => this.$emit('input', response))
                            .then(() => this.reset());
                case 'DELETE_CATALOG_GROUP':
                    return (v) =>
                        this.DELETE_CATALOGGROUP_BY_ID(v)
                            .then(() => this.$emit('input', {}))
                            .then(() => this.reset());
                case 'DELETE_CATALOG_SUBGROUP':
                    return (v) =>
                        this.DELETE_CATALOGSUBGROUP(v)
                            .then(() => this.$emit('input', {}))
                            .then(() => this.reset());
                case 'DELETE_CATALOG':
                    return (v) =>
                        this.DELETE_CATALOG(v)
                            .then(() => this.$emit('input', {}))
                            .then(() => this.reset());
            }
        }
    }
};
</script>
