<template>
    <v-card>
        <v-card-text>
            <v-switch
                v-model="computedConfirm"
                :label="$t('Aufgaben Bestätigung')"
                color="accent"
                :hint="$t('Mit dem ankreutzen dieses Kästchens bestätigen Sie, dass Sie die Aufgabe gewissenhaft erledigt haben')"
                persistent-hint
            />
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'inform-answer-type',
    data: () => ({
        confirm: false
    }),
    computed: {
        computedConfirm: {
            get() {
                return this.confirm;
            },
            set(value) {
                this.confirm = value;
                this.$emit('validate', value);
                this.$emit('input', { dummyKey: 0 });
            }
        }
    }
};
</script>
