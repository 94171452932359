<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click.stop="save" color="accent--text">
                <v-icon>{{ isInGroup ? 'mdi-bell' : 'mdi-bell-outline' }}</v-icon>
            </v-btn>
        </template>
        <span>{{ $t('Benachrichtigungen als E-Mail zusenden ein-/ausschalten') }}</span>
    </v-tooltip>
</template>

<script>
import ChatMixin from './Mixins/ChatMixin';

export default {
    name: 'notification-menu',
    mixins: [ChatMixin],
    props: {
        value: {
            type: String,
            default: ''
        },
        isAdminGroup: {
            type: Boolean,
            default: false
        },
        isUserGroup: {
            type: Boolean,
            default: false
        },
        isUser: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        isInGroup() {
            if (this.computedNotification !== null && this.computedNotification !== undefined && Array.isArray(this.computedNotification)) {
                return this.computedNotification.indexOf(this.value) !== -1;
            }
            return false;
        },
        notificationHint() {
            if (this.isAdminGroup) {
                return this.$t('Schalten Sie diese Option ein, wenn Sie über die Aktivität in dieser Gruppe über E-Mail benachrichtigt werden wollen');
            }
            if (this.isUserGroup) {
                return this.$t('Schalten Sie diese Option ein, wenn Sie über die Aktivität in dieser Gruppe über E-Mail benachrichtigt werden wollen');
            }
            if (this.isUser) {
                return this.$t('Schalten Sie diese Option ein, wenn Sie über die Aktivität mit diesem Nutzer über E-Mail benachrichtigt werden wollen');
            }
            return '';
        },
        computedNotification() {
            if (this.isAdminGroup) {
                return this.getAdminGroupChatConfig;
            }
            if (this.isUserGroup) {
                return this.getUserGroupChatConfig;
            }
            if (this.isUser) {
                return this.getPersonalChatConfig;
            }
            return [];
        }
    },
    methods: {
        serialize() {
            if (this.computedNotification.indexOf(this.value) === -1) {
                return [...this.computedNotification, this.value];
            }
            return this.computedNotification.filter((item) => item !== this.value);
        },
        save() {
            if (this.isAdminGroup) {
                this.CHAT_SAVE_ADMIN_GROUP_NOTIFICATIONS(this.serialize());
            }
            if (this.isUserGroup) {
                this.CHAT_SAVE_USER_GROUP_NOTIFICATIONS(this.serialize());
            }
            if (this.isUser) {
                this.CHAT_SAVE_PERSONAL_NOTIFICATIONS(this.serialize());
            }
        }
    }
};
</script>
