<template>
    <v-container>
        <v-row>
            <v-col :cols="12">
                <v-input :hint="findDescription" persistent-hint class="pb-2">
                    <v-btn-toggle v-model="computedType" mandatory v-if="!isUpdate" class="layout flex wrap elevation-0">
                        <v-row no-gutters>
                            <v-col cols="auto" v-for="activityType in filteredActivityTypes" :key="activityType.name">
                                <v-btn block :value="activityType.name" class="ma-1">
                                    <v-icon class="mr-2">{{ dynamicIcon(activityType.name) }}</v-icon>
                                    {{ $t(activityType.name) }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-btn-toggle>
                    <v-btn-toggle v-model="computedType" mandatory v-if="isUpdate">
                        <v-layout wrap>
                            <v-btn text block :value="value.type">
                                <v-icon class="mr-2">{{ dynamicIcon(value.type) }}</v-icon>
                                {{ $t(value.type) }}
                            </v-btn>
                        </v-layout>
                    </v-btn-toggle>
                </v-input>
            </v-col>
            <v-flex>
                <v-expand-transition mode="out-in">
                    <keep-alive>
                        <component v-model="computedActivity" :is="typeSwitch">
                            <v-alert class="ma-0" outlined :value="true" type="warning">{{ $t('Dieser Aktivitätstyp ist noch nicht verfügbar') }}</v-alert>
                        </component>
                    </keep-alive>
                </v-expand-transition>
            </v-flex>
        </v-row>
    </v-container>
</template>

<script>
import CheckActivity from './Types/CheckActivity';
import TextFieldActivity from './Types/TextFieldActivity';
import UploadActivity from './Types/UploadActivity';
import AppointmentActivity from './Types/AppointmentActivity';
import InformActivity from './Types/InformActivity';
import QuizActivity from './Types/QuizActivity';

import CourseMixin from '../Mixins/CourseMixin';

export default {
    name: 'activity-selector',
    components: {
        CheckActivity,
        TextFieldActivity,
        UploadActivity,
        AppointmentActivity,
        InformActivity,
        QuizActivity
    },
    mixins: [CourseMixin],
    data: () => ({
        filteredActivityTypes: []
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        isUpdate: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        allowedTypes() {
            return ['UPLOAD', 'SELF_CHECK', 'TEXT_FIELD', 'INFORM', 'QUIZ'];
        },
        computedType: {
            get() {
                return this.value.type;
            },
            set(value) {
                this.$emit('input', {
                    ...this.value,
                    type: value
                });
            }
        },
        computedActivity: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', {
                    ...value,
                    type: this.value.type
                });
            }
        },
        typeSwitch() {
            switch (this.value.type) {
                case 'UPLOAD': {
                    this.$emit('validate', true);
                    return UploadActivity;
                }
                case 'SELF_CHECK': {
                    this.$emit('validate', true);
                    return CheckActivity;
                }
                case 'TEXT_FIELD': {
                    this.$emit('validate', true);
                    return TextFieldActivity;
                }
                case 'INFORM': {
                    this.$emit('validate', true);
                    return InformActivity;
                }
                case 'QUIZ': {
                    this.$emit('validate', true);
                    return QuizActivity;
                }
            }
            this.$emit('validate', false);
            return 'div';
        },
        findDescription() {
            const find = this.getActivityTypes.find((activityType) => activityType.name === this.value.type);
            if (find !== undefined) {
                return this.$t(find.description);
            }
            return '';
        }
    },
    methods: {
        dynamicIcon(value) {
            switch (value) {
                case 'UPLOAD':
                    return 'mdi-cloud-upload';
                case 'SELF_CHECK':
                    return 'mdi-note-text';
                case 'TEXT_FIELD':
                    return 'mdi-message-text';
                case 'INFORM':
                    return 'mdi-information';
                case 'QUIZ':
                    return 'mdi-flask-outline';
            }
        }
    },
    mounted() {
        this.GET_ACTIVITY_TYPES().then(() => (this.filteredActivityTypes = this.getActivityTypes.filter((val) => this.allowedTypes.includes(val.name))));
    }
};
</script>
