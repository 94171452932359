<template>
    <div>
        <input :multiple="multiple" type="file" ref="fileInput" style="display: none" :accept="accept" @change="uploadFile" />
        <v-input
            :label="fileLabel"
            :hint="fileHint"
            :color="dragged ? 'primary' : ''"
            persistent-hint
            readonly
            @dragover.native.prevent="() => (dragged = true)"
            @dragenter.native.prevent="() => (dragged = true)"
            @dragend.native.prevent="() => (dragged = false)"
            @dragstart.native.prevent
            @dragleave.native.prevent="() => (dragged = false)"
            @drop.native.prevent="dropData"
        >
            <template v-slot:prepend>
                <v-layout column fill-height justify-center align-center>
                    <v-btn icon text @click="triggerFileUpload" :color="dragged ? 'primary' : ''">
                        <v-icon>{{ fileIcon }}</v-icon>
                    </v-btn>
                </v-layout>
            </template>
        </v-input>
    </div>
</template>
<script>
export default {
    name: 'file-picker',
    data: () => ({
        dragged: false
    }),
    computed: {
        fileHint() {
            if (Object.keys(this.value).length > 0) {
                if (Object.keys(this.value).length > 1) {
                    let datasSize = 0;
                    for (let i = 0; i < this.value.length; i++) {
                        datasSize = datasSize + this.value[i].size;
                    }

                    return `${this.$t('Multidatei')}, ${this.$t('Dateigröße')}: ${datasSize / 1000}kb`;
                } else return `${this.$t('Dateiname')}: ${this.value[0].name}, ${this.$t('Dateigröße')}: ${this.value[0].size / 1000}kb`;
            }
            return this.$t('Keine Datei ausgewählt');
        },
        fileLabel() {
            return this.$t(this.label);
        },
        fileIcon() {
            if (Object.keys(this.value).length > 0) {
                return 'mdi-link-off';
            }
            return 'mdi-attachment';
        }
    },
    props: {
        value: {
            type: [Object, FileList],
            default: () => ({})
        },
        accept: {
            type: String,
            default: () => '.xlsx'
        },
        label: {
            type: String,
            default: () => 'Dateiname'
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        value(val) {
            if (val instanceof Object) {
                if (Object.keys(val).length === 0) {
                    this.$refs.fileInput.value = '';
                }
            }
        }
    },
    methods: {
        dropData(event) {
            this.dragged = false;
            let file = event.dataTransfer.files.item(0);
            let types = this.accept
                .split(',')
                .map((type) => type.split('/'))
                .flat()
                .map((type) => type.trim())
                .filter(
                    (type) => type.includes('.') === false && type.includes('*') === false && type.includes('\\') === false && type.includes('~') === false
                );
            let regex = new RegExp(types.join('|'), 'i');
            if (regex.test(file.name) || regex.test(file.type)) {
                this.$emit('input', event.target.files);
            }
        },
        uploadFile(event) {
            this.$emit('input', event.target.files);
        },
        triggerFileUpload() {
            if (this.value[0] instanceof File) {
                this.$emit('input', {});
                this.$refs.fileInput.value = '';
            } else {
                this.$refs.fileInput.click();
            }
        }
    }
};
</script>
<style scoped>
.dragged {
    outline: 2px dashed black;
    background-color: var(--v-primary-base);
    box-shadow: 7px;
    outline-offset: 7px;
}
</style>
