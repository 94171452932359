<template>
    <v-dialog v-model="computedDialog" persistent :width="$vuetify.breakpoint.thresholds.sm">
        <DeleteConfirm
            :selectedItem="getSelectedAnswer"
            :hasToolbar="true"
            :toolbarTitle="$t('Rückmeldung löschen')"
            :deleteMessage="$t('Sind Sie sicher, dass Sie die Rückmeldung löschen wollen?')"
            :checkboxDeleteMessage="$t('Diese Aktion kann nicht rückgängig gemacht werden.')"
            @deleteItem="deleteAnswer"
            @closeEmit="close"
        />
    </v-dialog>
</template>
<script>
import ForumMixin from './Mixins/ForumMixin';
import DeleteConfirm from '../Generic/DeleteConfirm';

export default {
    name: 'delete-answer',
    mixins: [ForumMixin],
    components: {
        DeleteConfirm
    },
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedDialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        close() {
            this.$emit('input', false);
        },
        deleteAnswer() {
            this.DELETE_FORUM_THREAD_ANSWER({
                forum: this.getSelectedForum,
                thread: this.getSelectedThread,
                answer: this.getSelectedAnswer
            }).then(() => this.close());
        }
    }
};
</script>
