import axios from '../../../plugins/Axios/axios';

const state = {
    hasPermissions: false,
    userGroupPermissionList: []
};

// getters
const getters = {};

// actions
const actions = {
    CHECK_PERMISSIONS({ commit }, permissions) {
        return axios.post(`/checkPermissions`, permissions).then((response) => response.data.data.hasPermission);
    }
};

// mutations
const mutations = {
    clear: (state) => {
        state.userGroupPermissionList = [];
        state.hasPermissions = false;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
