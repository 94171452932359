var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"offset-x":"","close-on-content-click":false,"offset-overflow":true},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-input',{attrs:{"persistent-hint":"","hint":_vm.$t(
                    'Wählen Sie einen Wiki-Eintrag aus, von der der neue Wiki-Eintrag abstammen soll. Wenn Sie keinen Wiki-Eintrag auswählen, wird der neue Wiki-Eintrag im Stammverzeichnis angelegt'
                )},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-file-document-edit")])],1)]},proxy:true}],null,true)},[_c('v-breadcrumbs',{attrs:{"divider":">","items":_vm.displayWikiPath},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{class:{
                            caption: true,
                            'primary--text': item.id === _vm.value
                        }},[_vm._v(_vm._s(item.name))])]}}],null,true)})],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{attrs:{"width":_vm.calculateMenuWidth}},[_c('v-toolbar',{attrs:{"text":"","dark":"","color":"secondary"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('Stammwiki auswählen')))])],1),_c('v-card-text',[_c('v-breadcrumbs',{attrs:{"divider":">","items":_vm.selectedWikiPath},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{class:{
                            'primary--text': item.index !== _vm.selectedWikiPath.length,
                            pointer: item.index !== _vm.selectedWikiPath.length
                        },on:{"click":function($event){return _vm.onClick(item)}}},[_vm._v(_vm._s(item.name))])]}}])}),_c('v-data-iterator',{attrs:{"items":_vm.computedItems,"no-data-text":_vm.$t('keine Wiki-Einträge gefunden'),"no-results-text":_vm.$t('kein Ergebnis'),"items-per-page":5,"footer-props":{
                    'items-per-page-text': _vm.$t('Wiki pro Seite'),
                    'items-per-page-options': [5, 10, 15, { value: -1, text: _vm.$t('Alle') }]
                },"hideDefaultFooter":_vm.computedItems.length <= 5},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-list-item',{attrs:{"value":_vm.isSelected(item.id)},on:{"click":function($event){return _vm.onClick(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)],1)]}}])})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"block":"","color":"success"},on:{"click":function($event){return _vm.handleWikiParentSelection(_vm.selectedParentWiki)}}},[_vm._v(" "+_vm._s(_vm.$t('Auswählen'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }