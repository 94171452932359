<template>
    <v-layout class="fill-height" column>
        <Toolbar
            :toolbarTitle="$t('Berechtigungsprofile')"
            tooltipMessage="Hier können Sie Profile anlegen mit einer vordefinierten Menge an Berechtigungen."
        />
        <v-card class="fill-height">
            <v-container>
                <v-row align="center">
                    <v-col>
                        <ProfileSelector v-model="selectedProfile" />
                    </v-col>
                    <v-col cols="auto">
                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn icon v-on="on" v-bind="attrs" color="success" :disabled="!canAdd" @click="createProfile">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('Berechtigungsprofil Erstellen') }}</span>
                        </v-tooltip>
                    </v-col>
                    <v-col cols="auto">
                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn icon v-on="on" v-bind="attrs" color="warning" :disabled="!canUpdate" @click="updateProfile">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('Berechtigungsprofil bearbeiten') }}</span>
                        </v-tooltip>
                    </v-col>
                    <v-col cols="auto">
                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn icon v-on="on" v-bind="attrs" color="error" :disabled="!canDelete" @click="deleteProfile">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('Berechtigungsprofil Löschen') }}</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-container>
            <v-expand-transition mode="out-in">
                <v-card-text v-if="isProfileSelected">
                    <v-text-field color="accent" v-model="profileName" :label="$t('Profilname')" required :rules="[nameRules()]" class="mb-3" readonly />
                    <v-text-field color="accent" v-model="profileDescription" :label="$t('Beschreibung')" class="mb-3" readonly />

                    <v-tabs slider-color="accent" v-model="tabs" mandatory>
                        <v-tab key="GENERAL">{{ $t('Allgemeine Berechtigungen') }}</v-tab>
                        <v-tab key="AUTHORING">{{ $t('Autorenberechtigungen') }}</v-tab>

                        <v-tab-item key="GENERAL">
                            <v-card>
                                <AdminPermissionList v-model="selectedPermissions" :readOnly="true" />
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="AUTHORING">
                            <v-card>
                                <ProfileGroupSelector v-model="selectedProfile.authoringPermissions" :readOnly="true" />
                            </v-card>
                        </v-tab-item>
                    </v-tabs>
                </v-card-text>
            </v-expand-transition>
        </v-card>
    </v-layout>
</template>

<script>
import Toolbar from '../../../Components/Generic/Toolbar';
import AdminPermissionList from '../../../Components/Permission/AdminPermissionList';
import ProfileSelector from '../../../Components/Permission/Profiles/ProfileSelector';
import ProfileGroupSelector from '../../../Components/Permission/Profiles/ProfileAuthoringSelector';
import { mapGetters, mapActions } from 'vuex';
import UserMixin from '../../../Components/Users/Mixins/UserMixin';

export default {
    name: 'permission-profiles',
    components: {
        Toolbar,
        AdminPermissionList,
        ProfileSelector,
        ProfileGroupSelector
    },
    mixins: [UserMixin],
    data: () => ({
        selectedProfile: {},
        profileName: '',
        profileDescription: '',
        selectedPermissions: [],
        tabs: null,
        validateState: {
            add: ['PERMISSION_MANAGEMENT'],
            update: ['PERMISSION_MANAGEMENT'],
            delete: ['PERMISSION_MANAGEMENT']
        },
        permissions: {}
    }),
    watch: {
        selectedProfile(value) {
            this.profileName = value.name;
            this.profileDescription = value.description;
            this.selectedPermissions = value.permissions;
        }
    },
    computed: {
        ...mapGetters('PermissionProfiles', ['getProfiles']),
        isProfileSelected() {
            return Object.keys(this.selectedProfile).length > 0;
        },
        canAdd() {
            return this.permissions && this.permissions.add;
        },
        canUpdate() {
            return this.isProfileSelected && this.permissions && this.permissions.update;
        },
        canDelete() {
            return this.isProfileSelected && this.permissions && this.permissions.delete;
        }
    },
    methods: {
        ...mapActions('PermissionProfiles', ['UPDATE_PROFILE', 'GET_PROFILES', 'SET_SELECTED_PROFILE']),
        nameRules() {
            return (v) => !!v || this.$t('Das Feld darf nicht leer sein');
        },
        createProfile() {
            this.$router.push({ name: 'permissionProfileAdd' });
        },
        updateProfile() {
            this.$router.push({ name: 'permissionProfileUpdate' });
        },
        deleteProfile() {
            this.$router.push({ name: 'permissionProfileDelete' });
        }
    },
    mounted() {
        this.validateEditorState(this.validateState).then((result) => (this.permissions = result));
    }
};
</script>
