<template>
    <v-card>
        <v-card-text>
            <v-toolbar dark flat color="secondary">
                <v-toolbar-title> {{ $t('FAQ') }} </v-toolbar-title>
                <v-spacer />
                <v-btn medium icon color="success" @click="addFAQGroup" align-center class="mr-3" v-if="showFAQGroupOperations()">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-folder-plus</v-icon>
                        </template>
                        <span>{{ $t('Hier können Sie eine FAQ-Gruppe hinzufügen') }}</span>
                    </v-tooltip>
                </v-btn>
                <v-btn icon color="success" v-if="isFAQGroupSelected && hasCreatePermission()" @click="addFAQ" align-center class="mr-3">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-file-plus</v-icon>
                        </template>
                        <span>{{ $t('Hier können Sie ein FAQ in der ausgewählten FAQ-Gruppe erstellen') }}</span>
                    </v-tooltip>
                </v-btn>
                <v-btn icon color="warning" v-if="isFAQGroupSelected && showFAQGroupOperations()" @click="updateFAQGroup" align-center class="mr-3">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-folder-edit</v-icon>
                        </template>
                        <span>{{ $t('Hier können Sie die ausgewählte FAQ-Gruppe bearbeiten') }}</span>
                    </v-tooltip>
                </v-btn>
                <v-btn icon color="error" v-if="isFAQGroupSelected && showFAQGroupOperations()" @click="deleteFAQGroup" class="mr-3">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-folder-remove</v-icon>
                        </template>
                        <span>{{ $t('Hier können Sie die ausgewählte FAQ-Gruppe löschen') }}</span>
                    </v-tooltip>
                </v-btn>
                <v-dialog v-model="deleteDialog" persistent scrollable :width="$vuetify.breakpoint.thresholds.sm"> </v-dialog>
            </v-toolbar>
            <v-list>
                <v-list-group
                    v-for="faqGroupItems in getFaqMap"
                    :key="faqGroupItems.group.name"
                    @click="updateSelectedFAQGroup(faqGroupItems)"
                    :group="faqGroupItems.group.name"
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            {{ faqGroupItems.group.name }}
                        </v-list-item-content>
                    </template>
                    <v-list-item v-for="faq in faqGroupItems.faqs" :key="faq.id" @click.stop :append-icon="wiselabFile" @dblclick="onClick(faq)">
                        <v-list-item-icon>
                            <template v-if="faq.isDefaultFaq">
                                <ImageLoader :src="wiselabFile" :width="30" :height="30" />
                            </template>
                            <template v-else>
                                <ImageLoader :src="customerLogo" :width="30" :height="30" />
                            </template>
                        </v-list-item-icon>
                        <v-menu
                            :close-on-content-click="false"
                            :bottom="true"
                            offset-x
                            :max-width="calculateMenuWidth"
                            :value="getMenuState(faq)"
                            @input="setMenuState(faq, $event)"
                        >
                            <template v-slot:activator="{ on }">
                                <v-list-item-content v-on="on">
                                    <v-list-item-title>{{ faq.question }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ computeTags(faq) }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                            <v-card>
                                <v-toolbar color="secondary">
                                    <v-toolbar-title class="white--text">{{ faq.question }}</v-toolbar-title>
                                </v-toolbar>
                                <v-flex pa-3>
                                    <div class="ck ck-content">{{ getThumbnailText(faq) }}</div>
                                    <div style="clear: both"></div>
                                </v-flex>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn @click="close(faq)">{{ $t('Schließen') }}</v-btn>
                                    <v-btn color="primary" @click="onClick(faq)">{{ $t('Weiterlesen') }}</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-menu>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </v-card-text>
    </v-card>
</template>
<script>
import FAQMixin from './Mixins/FAQMixins';
import WiselabImage from '../../assets/Resources/PNG/Bildmarke.png';
import ImageLoader from '../Generic/ImageLoader';
import { mapGetters } from 'vuex';

export default {
    name: 'faq-list',
    mixins: [FAQMixin],
    components: {
        ImageLoader
    },
    data: () => ({
        deleteDialog: false,
        map: {},
        permissions: {
            faqGroup: false,
            create: false
        }
    }),
    computed: {
        ...mapGetters('SystemSettings', ['getLogo', 'getCompanyName']),
        calculateMenuWidth() {
            return this.$vuetify.breakpoint.smAndUp ? this.$vuetify.breakpoint.thresholds.xs : undefined;
        },
        isFAQGroupSelected() {
            return this.getSelectedFaqGroup && this.getSelectedFaqGroup.hasOwnProperty('id');
        },
        wiselabFile() {
            return WiselabImage;
        },
        customerLogo() {
            return this.getLogo;
        }
    },
    methods: {
        onClick(faq) {
            this.GET_SINGLE_FAQ(faq);
            this.$emit('switchView', this.getSelectedFaq);
        },
        addFAQGroup() {
            this.$emit('switchView', {
                component: 'ADD_FAQ_GROUP',
                type: 'ADD',
                data: {}
            });
        },
        updateFAQGroup() {
            this.$emit('switchView', {
                component: 'UPDATE_FAQ_GROUP',
                type: 'UPDATE',
                data: this.getSelectedFaqGroup
            });
        },
        deleteFAQGroup() {
            this.$emit('switchView', {
                component: 'DELETE_FAQ_GROUP',
                type: 'DELETE',
                data: this.getSelectedFaqGroup
            });
        },
        addFAQ() {
            this.$emit('switchView', {
                component: 'ADD_FAQ',
                type: 'ADD',
                data: this.getSelectedFaqGroup
            });
        },
        updateSelectedFAQGroup(faqGroupItems) {
            if (this.getSelectedFaqGroup && this.getSelectedFaqGroup.name == faqGroupItems.group.name) {
                this.SET_SELECTED_FAQ_GROUP({});
            } else {
                this.SET_SELECTED_FAQ_GROUP(faqGroupItems.group);
            }
        },
        /*isSelected(faqGroupName) {
      if (faqGroupName === this.getSelectedFaqGroup.name) {
        return true;
      }
      return false;
    },*/
        handleDelete(value) {
            this.deleteDialog = value;
        },
        getThumbnailText(faq) {
            if (faq.answer.length <= 0) {
                return this.$t('Noch kein Text vorhanden.');
            }
            var length = 300;
            var thumbnailText = faq.answer.length > length ? faq.answer.substring(0, length - 3) + '...' : faq.answer;
            thumbnailText = thumbnailText
                .replace(/<style[^>]*>.*<\/style>/gm, '')
                .replace(/<script[^>]*>.*<\/script>/gm, '')
                .replace(/<[^>]+>/gm, '')
                .replace(/([\r\n]+ +)+/gm, '');
            return thumbnailText;
        },
        setMenuState({ id }, event) {
            this.$set(this.map, id, event);
        },
        getMenuState({ id }) {
            if (this.map.hasOwnProperty(id)) {
                return this.map[id];
            }
            return false;
        },
        close({ id }) {
            this.$set(this.map, id, false);
        },
        computeTags({ tags }) {
            return tags ? tags : '';
        },
        showFAQGroupOperations() {
            return this.permissions.faqGroup || this.getLoggedInAdmin.role == 'ROOT' || this.getLoggedInAdmin.role == 'SALES';
        },
        hasCreatePermission() {
            return this.permissions.create || this.getLoggedInAdmin.role == 'ROOT' || this.getLoggedInAdmin.role == 'SALES';
        }
    },
    beforeMount() {
        this.validateEditorState({
            faqGroup: ['FAQ_GROUP_PERMISSIONS'],
            create: ['ADD_FAQ']
        }).then((result) => (this.permissions = result));
    }
};
</script>
