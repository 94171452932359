import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
    computed: {
        ...mapGetters('ArchiveToolOperations', ['getSelectedUsers', 'getSelectedCatalogs'])
    },
    methods: {
        ...mapActions('ArchiveToolOperations', ['GET_USER_ARCHIVE_CATALOGS', 'GET_MULTIPLE_ARCHIVE_CATALOGS', 'ASSIGN_USER_ARCHIVE_CATALOGS']),
        ...mapMutations('ArchiveToolOpereations', ['updateSelectedUsers', 'updateSelectedCatalogs'])
    }
};
