<template>
    <v-dialog v-model="computedDialog" :width="$vuetify.breakpoint.thresholds.sm" persistent scrollable :fullscreen="$vuetify.breakpoint.xs">
        <v-card>
            <v-toolbar dense flat dark color="secondary">
                <v-toolbar-title>{{ $t('Einschätzung') }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col :cols="12" v-if="parseInt(activity.score) > 0">
                            <span class="text-caption">{{ $t('Wie würden Sie Ihr Ergebnis für diese Aktivität einschätzen?') }}</span>
                        </v-col>
                        <v-col :cols="12" v-if="parseInt(activity.score) > 0">
                            <v-container>
                                <v-row align="center">
                                    <v-col cols="auto">
                                        <v-btn icon>
                                            <v-icon>mdi-thumb-down</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col>
                                        <v-slider v-model="selfAssginedScore" :min="0" :max="parseInt(activity.score)" hide-details />
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-btn icon>
                                            <v-icon>mdi-thumb-up</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                        <v-col :cols="12">
                            <v-textarea
                                v-model="userNotes"
                                :label="$t('Notizen')"
                                :hint="$t('Sie können den Kursverwaltern eine Notiz zu der Aktivität hinterlegen')"
                                persistent-hint
                                no-resize
                                :rows="3"
                                outlined
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn block color="success" @click="save">
                    {{ $t('Abschicken') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import CourseMixin from '../CourseManager/Mixins/CourseMixin';

export default {
    name: 'my-evaluation',
    mixins: [CourseMixin],
    data: () => ({
        selfAssginedScore: 0,
        userNotes: ''
    }),
    watch: {
        value(value) {
            if (value) {
                this.selfAssginedScore = 0;
                this.userNotes = '';
            }
        }
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        activity: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        computedDialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        close() {
            this.$emit('input', false);
        },
        save() {
            this.EVALUATE_OWN_ACTIVITY({
                activity: this.activity,
                evaluation: this.$data
            }).then(() => this.close());
        }
    }
};
</script>
