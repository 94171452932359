<template>
    <v-card flat v-if="type !== 'CONTENT_ONLY'">
        <v-toolbar dark flat dense color="secondary">
            <v-toolbar-title>{{ $t('Antwortoptionen') }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="createOption" :disabled="disableCreate">
                            <v-icon>mdi-note-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Option hinzufügen') }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="type === 'BUCKET_SORT'">
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="createBucket" :disabled="disableCreate">
                            <v-icon>mdi-application</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Kategorie hinzufügen') }}</span>
                </v-tooltip>
            </v-toolbar-items>
        </v-toolbar>
        <keep-alive>
            <component
                v-model="computedValue"
                :buckets="buckets"
                :is="selectComponent"
                @media="handleMedia"
                @preview="handlePreview"
                @buckets="handleBuckets"
                :createOption="createOption"
            />
        </keep-alive>
    </v-card>
</template>

<script>
import MultiSelect from './Types/MultiSelect';
import SingleSelect from './Types/SingleSelectItem';
import TrueFalse from './Types/TrueFalse';
import FillBlank from './Types/FillBlank';
import Picker from './Types/Picker';
import SequenceImage from './Types/Sequence';
import BucketSort from './Types/BucketSort';

export default {
    name: 'knowledge-unit-question-types',
    components: {
        MultiSelect,
        SingleSelect,
        TrueFalse,
        FillBlank,
        Picker,
        SequenceImage,
        BucketSort
    },
    props: {
        value: {
            type: Array,
            default: () => []
        },
        buckets: {
            type: Array,
            default: () => []
        },
        type: {
            type: String,
            default: ''
        }
    },
    computed: {
        disableCreate() {
            return ['TRUE_FALSE', 'PICKER', 'CONTENT_ONLY'].includes(this.type);
        },
        computedValue: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.type !== 'BUCKET_SORT') {
                    this.$emit(
                        'input',
                        value.map((item, index) => ({
                            ...item,
                            sequenceNo: index + 1
                        }))
                    );
                } else {
                    this.$emit('input', value);
                }
            }
        },
        selectComponent() {
            switch (this.type) {
                case 'SINGLE_SELECT':
                    return SingleSelect;
                case 'MULTI_SELECT':
                    return MultiSelect;
                case 'TRUE_FALSE':
                    return TrueFalse;
                case 'PICKER':
                    return Picker;
                case 'FILL_BLANK':
                    return FillBlank;
                case 'SEQUENCE':
                    return SequenceImage;
                case 'BUCKET_SORT':
                    return BucketSort;
            }
            return undefined;
        }
    },
    methods: {
        handleBuckets(value) {
            this.$emit('buckets', value);
        },
        handlePreview(value) {
            this.$emit('preview', value);
        },
        handleMedia(value) {
            this.$emit('media', value);
        },
        createBucket({ text = '' }) {
            this.$emit('buckets', [
                ...this.buckets,
                {
                    id: parseInt(performance.now()).toString(),
                    media: {
                        fileName: '',
                        fileType: '',
                        fileURL: '',
                        id: '',
                        size: 0,
                        staticResource: false
                    },
                    text: text,
                    orderNumber: this.buckets.length
                }
            ]);
        },
        createOption({ text = '' }) {
            let data = {
                id: parseInt(performance.now()).toString(),
                text: text,
                isAnswer: false,
                minimum: 0,
                maximum: 100,
                steps: 1,
                sequenceNo: this.value.length + 1,
                mediaId: '',
                fileURL: '',
                fileName: '',
                fileType: '',
                size: 0,
                answeredOrder: 0
            };
            if (this.type === 'SEQUENCE' || this.type === 'FILL_BLANK') {
                data.isAnswer = true;
            }
            if (this.type === 'BUCKET_SORT') {
                data.isAnswer = true;
                data.sequenceNo = -1;
            }
            this.$emit('input', [...this.value, data]);
        }
    }
};
</script>
