import axios from '../../../plugins/Axios/axios';
import Vue from 'vue';

const state = {
    adminGroupTree: [],
    adminGroupMap: {},
    selectedAdminGroup: {},
    adminGroupUserMap: {}
};

// getters
const getters = {
    getAdminGroupUsers(state) {
        return (adminGroupId) => {
            if (state.adminGroupUserMap.hasOwnProperty(adminGroupId)) {
                const admins = state.adminGroupUserMap[adminGroupId];
                admins.sort((a, b) =>
                    a.displayName.localeCompare(b.displayName, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    })
                );
                return admins;
            }
            return undefined;
        };
    },
    getAdminGroup(state) {
        return (adminGroupId) => {
            if (state.adminGroupMap.hasOwnProperty(adminGroupId)) {
                return state.adminGroupMap[adminGroupId];
            }
            return undefined;
        };
    },
    getAdminGroupTree(state) {
        return state.adminGroupTree;
    },
    getAdminGroups(state) {
        return Object.values(state.adminGroupMap);
    },
    getSelectedAdminGroup(state) {
        return state.selectedAdminGroup;
    }
};

// actions
const actions = {
    SET_SELECTED_ADMIN_GROUP({ commit }, adminGroup) {
        return commit('updateSelectedAdminGroup', adminGroup);
    },
    GET_ADMIN_GROUPS({ commit }) {
        return axios
            .get(`/admingroups/all`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateAdminGroupMap', response);
                return response;
            });
    },
    GET_ADMIN_GROUP_TREE({ commit }) {
        return axios
            .get(`/admingroups/treeview`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateAdminGroupTree', response);
                return response;
            });
    },
    GET_ADMIN_SUBGROUPS({ commit }, { id: adminGroupId }) {
        return axios
            .get(`/admingroups/${adminGroupId}/subgroups`)
            .then((response) => response.data.data.subgroups)
            .then((response) => {
                commit('updateAdminGroupMap', response);
                return response;
            });
    },
    GET_ADMIN_GROUP_ROOT({ commit }) {
        return axios
            .get('/admingroups')
            .then((response) => response.data.data.groups)
            .then((response) => {
                commit('updateAdminGroupMap', response);
                return response;
            });
    },
    GET_ADMIN_GROUP_MEMBERS({ commit }, { id: groupId }) {
        return axios.get(`/admingroups/${groupId}/admins`).then((response) => response.data.data.admins);
    },
    GET_ALL_ADMIN_GROUP_USERS({ commit, dispatch }) {
        return Promise.all([
            axios
                .get(`admingroups/adminsmap`)
                .then((response) => response.data.data)
                .then((response) => {
                    commit('updateAdminGroupUsersMap', response);
                    return response;
                }),
            dispatch('GET_ADMIN_GROUPS'),
            dispatch('GET_ADMIN_GROUP_TREE')
        ]);
    },
    SET_ADMIN_GROUP_USERS({ commit, rootGetters }, { admin: { id: adminId }, groups }) {
        return axios
            .patch(`/admingroups/admin/${adminId}`, { groups })
            .then((response) => response.data.data.groups)
            .then((response) => {
                commit('UserPrototype/updateAssignedGroups', { [adminId]: response }, { root: true });
                commit('removeAdminFromGroupMap', adminId);
                const admin = rootGetters['Admin/getAdminList'].find((user) => user.id === adminId);
                if (admin) {
                    commit('addAdminToGroupMap', { admin, groups });
                }
                return response;
            });
    },
    ADD_ADMIN_GROUP({ commit, dispatch }, request) {
        return axios
            .post('admingroups', request)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateAdminGroupMap', response);
                commit('updateSelectedAdminGroup', response);
                return dispatch('GET_ADMIN_GROUP_TREE').then(() => response);
            });
    },
    ADD_ADMIN_SUBGROUP({ commit, dispatch }, { parentId: adminGroupId, ...request }) {
        return axios
            .post(`admingroups/${adminGroupId}/subgroups`, request)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateAdminGroupMap', response);
                commit('updateSelectedAdminGroup', response);
                return dispatch('GET_ADMIN_GROUP_TREE').then(() => response);
            });
    },
    UPDATE_ADMIN_GROUP({ commit, dispatch }, { id: adminGroupId, ...request }) {
        return axios
            .patch(`/admingroups/${adminGroupId}`, request)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateAdminGroupMap', response);
                commit('updateSelectedAdminGroup', response);
                return dispatch('GET_ADMIN_GROUP_TREE').then(() => response);
            });
    },
    DELETE_ADMIN_GROUP({ commit, dispatch }, { id: adminGroupId }) {
        return axios.delete(`/admingroups/${adminGroupId}`).then(() => {
            commit('deleteAdminGroup', adminGroupId);
            dispatch('UserPrototype/BUILD_ADMIN_MAPS', null, { root: true });
        });
    },
    REMOVE_ADMIN_FROM_GROUP_MAP({ commit }, adminId) {
        commit('removeAdminFromGroupMap', adminId);
    }
};

// mutations
const mutations = {
    clear: (state) => {
        state.adminGroupTree = [];
        state.adminGroupMap = {};
        state.selectedAdminGroup = {};
        state.adminGroupUserMap = {};
    },
    updateSelectedAdminGroup: (state, adminGroup) => {
        state.selectedAdminGroup = adminGroup;
    },
    updateAdminGroupUsersMap: (state, data) => {
        Vue.set(state, 'adminGroupUserMap', data);
    },
    updateAdminGroupMap: (state, data) => {
        if (Array.isArray(data)) {
            state.adminGroupMap = data.reduce((accu, { id, ...rest }) => {
                accu[id] = { id, ...rest };
                return accu;
            }, {});
        } else {
            if (data instanceof Object) {
                if (state.selectedAdminGroup.id === data.id) {
                    state.selectedAdminGroup = data;
                }
                Vue.set(state.adminGroupMap, data.id, data);
            }
        }
    },
    updateAdminGroupTree: (state, data) => {
        state.adminGroupTree = data;
    },
    deleteAdminGroup: (state, data) => {
        Vue.delete(state.adminGroupMap, data);
        Vue.delete(state.adminGroupUserMap, data);
    },
    addAdminToGroupMap: (state, { admin, groups }) => {
        const newMap = {
            ...groups.reduce((acc, curr) => {
                acc[curr] = [];
                return acc;
            }, {}),
            ...state.adminGroupUserMap
        };
        Vue.set(
            state,
            'adminGroupUserMap',
            Object.entries(newMap).reduce((acc, [key, value]) => {
                if (groups.includes(key) && !value.map(({ id }) => id).includes(admin.id)) {
                    acc[key] = [...value, admin];
                } else {
                    acc[key] = value;
                }
                return acc;
            }, {})
        );
    },
    removeAdminFromGroupMap: (state, userId) => {
        Vue.set(
            state,
            'adminGroupUserMap',
            Object.entries(state.adminGroupUserMap).reduce((acc, [key, value]) => {
                const users = value.filter((user) => user.id !== userId);
                if (users.length > 0) {
                    acc[key] = users;
                }

                return acc;
            }, {})
        );
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
