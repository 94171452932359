<template>
    <v-container grid-list-md pa-0>
        <v-layout wrap>
            <v-flex xs12>
                <v-text-field
                    autocomplete="new-password"
                    :label="$t(label)"
                    :value="value"
                    :hint="$t(hint)"
                    persistent-hint
                    @input="emitChange"
                    class="mb-3"
                    color="accent"
                />
            </v-flex>
            <v-flex shrink v-if="showTags">
                <v-chip close @input="removeTag(tag)" :color="tagColor" text-color="white" v-for="(tag, index) in textSplitter" :key="`tag-${index}`">
                    <strong>{{ tag }}</strong>
                </v-chip>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'tag-generator',
    props: {
        value: {
            default: '',
            type: String
        },
        tagColor: {
            default: 'primary',
            type: String
        },
        showTags: {
            default: true,
            type: Boolean
        },
        label: {
            default: 'Schlagwörter',
            type: String
        },
        hint: {
            default: 'Separieren Sie die Schlagwörter mit einem Komma',
            type: String
        }
    },
    computed: {
        textSplitter() {
            return this.value
                .split(',')
                .filter((word) => word.trim() !== '')
                .map((word) => word.trim());
        }
    },
    methods: {
        emitChange(val) {
            this.$emit('input', val);
        },
        removeTag(tag) {
            this.$emit('input', this.textSplitter.filter((filter) => filter !== tag).join(','));
        }
    }
};
</script>
