<template>
    <v-card>
        <v-card-text>
            <v-form ref="form" v-model="validate">
                <v-list two-line>
                    <v-list-item v-for="(option, index) in points" :key="`option-${index}`">
                        <v-text-field
                            class="mr-3"
                            :value="option.point"
                            @input="serializeOption(option, $event)"
                            :label="`${$t('Option')}-${index + 1}`"
                            :hint="$t('Geben Sie der Option einen Text')"
                            persistent-hint
                            :rules="[notEmpty]"
                        />
                        <v-list-item-action v-if="index !== 0">
                            <v-layout fill-height align-center justify-center>
                                <v-btn icon @click="removeOption(index)" color="error">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-layout>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn block color="success" @click="createOption">
                {{ $t('Add Option') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name: 'check-activity',
    data: () => ({
        validate: false,
        points: []
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        serializeOption(option, value) {
            option.point = value;
            this.serialize();
        },
        serialize() {
            this.$emit('input', this.$data);
        },
        removeOption(index) {
            this.points.splice(index, 1);
            this.points.forEach((option, index) => {
                option.order = index;
            });
            this.serialize();
            this.$nextTick(() => this.$refs.form.validate());
        },
        createOption() {
            this.points.push({ order: this.points.length, point: '' });
            this.serialize();
            this.$nextTick(() => this.$refs.form.validate());
        },
        notEmpty(value) {
            return !!value || this.$t('Dieses Feld darf nicht leer sein');
        }
    },
    activated() {
        this.points = [];
        if (this.value.hasOwnProperty('points')) {
            this.points = this.value.points;
        }
        if (this.points.length === 0) {
            this.createOption();
        }
        this.serialize();
    }
};
</script>
