<template>
    <v-card flat height="100%">
        <v-toolbar flat dense text color="transparent" v-show="getView.type !== ''">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn medium icon @click="reset" v-on="on">
                        <v-icon medium>mdi-chevron-left</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('Zurück') }}</span>
            </v-tooltip>
            <v-toolbar-title class="text-body-2">{{ typeTitle }}</v-toolbar-title>
        </v-toolbar>
        <keep-alive>
            <component
                :is="componentSwitch"
                :value="value"
                :type="getView.type"
                :onSubmit="onSubmit"
                @switchView="handleSwitchView"
                @input="handleInput"
                :search="search"
                @openTree="handleOpenTree"
                :permissions="permissions"
                @media="handleMedia"
            />
        </keep-alive>
    </v-card>
</template>
<script>
import FilemanagerDeleteConfirm from './FilemanagerDeleteConfirm';
import AddUpdateFilemanagerFolder from '../AddUpdateFilemanagerFolder';
import FilemanagerUploadView from './FilemanagerUploadView';
import FileView from './MediaSelection';
import FolderView from './FolderSelecton';

import StaticFilemanagerMixin from '../../Mixin/StaticFilemanagerPrototypeMixin';

export default {
    name: 'static-filemanager-view',
    components: {
        AddUpdateFilemanagerFolder,
        FilemanagerDeleteConfirm,
        FilemanagerUploadView
    },
    mixins: [StaticFilemanagerMixin],
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        search: {
            type: String,
            default: ''
        },
        permissions: {
            type: Object,
            default: () => ({
                add: false,
                update: false,
                delete: false,
                change: false
            })
        }
    },
    watch: {
        value() {
            this.reset();
        }
    },
    computed: {
        componentSwitch() {
            if (this.getView.component !== '') {
                switch (this.getView.component) {
                    case 'UPLOAD_FILE':
                        return FilemanagerUploadView;
                    case 'CREATE_FOLDER':
                        return AddUpdateFilemanagerFolder;
                    case 'UPDATE_FOLDER':
                        return AddUpdateFilemanagerFolder;
                    case 'DELETE_FOLDER':
                        return FilemanagerDeleteConfirm;
                    case 'DELETE_FILE':
                        return FilemanagerDeleteConfirm;
                }
            }
            switch (this.isFilemanagerType(this.value)) {
                case 'FILE':
                    return FileView;
                case 'FOLDER':
                    return FolderView;
            }
            return FolderView;
        },
        typeTitle() {
            switch (this.getView.component) {
                case 'UPLOAD_FILE':
                    return this.$t('Statische Datei hochladen');
                case 'CREATE_FOLDER':
                    return this.$t('Statischen Ordner erstellen');
                case 'UPDATE_FOLDER':
                    return this.$t('Statischen Ordner bearbeiten');
                case 'DELETE_FOLDER':
                    return this.$t('Statischen Ordner löschen');
                case 'DELETE_FILE':
                    return this.$t('Statische Datei löschen');
            }
            return '';
        }
    },
    methods: {
        onSubmit() {
            switch (this.getView.component) {
                case 'DELETE_FOLDER':
                    return (v) =>
                        this.DELETE_FOLDER_BY_ID(v.id)
                            .then(() => this.GET_TREE())
                            .then(() => this.$emit('input', {}))
                            .then(() => this.reset());
                case 'DELETE_FILE':
                    return (v) =>
                        this.DELETE_MEDIA_BY_ID(v.id)
                            .then(() => this.$emit('input', {}))
                            .then(() => this.reset());
            }
            return () => {};
        },
        reset() {
            this.SET_VIEW({ view: '', data: {}, type: '' });
        },
        handleInput(value) {
            this.$emit('input', value);
        },
        handleOpenTree(value) {
            this.$emit('openTree', value);
        },
        handleSwitchView(value) {
            this.SET_VIEW(value);
        },
        handleMedia(value) {
            this.$emit('media', value);
        }
    }
};
</script>
