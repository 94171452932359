<template>
    <v-card flat>
        <v-toolbar flat dense text color="transparent">
            <v-spacer />
            <v-toolbar-items>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon @click="create" :disabled="!permissions.create" color="success">
                            <v-icon medium>mdi-folder-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ createText }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon @click="update" :disabled="!permissions.update" color="warning">
                            <v-icon medium>mdi-folder-edit</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ editText }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon @click="remove" :disabled="!permissions.delete" color="error">
                            <v-icon medium>mdi-folder-remove</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ deleteText }}</span>
                </v-tooltip>
            </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-list>
            <v-subheader>{{ $t('Zusätzliche Infos') }}</v-subheader>
            <v-list-item>
                <v-list-item-action>
                    <v-icon>mdi-information-outline</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('Beschreibung') }}</v-list-item-title>
                    <v-list-item-subtitle>
                        {{ value.description || $t('Keine') }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-action>
                    <UserProfile :value="value.createdBy" small :displayName="false" />
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('Erstellt') }}</v-list-item-title>
                    <v-list-item-subtitle>
                        {{ value.createdAt }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-action>
                    <UserProfile :value="value.updatedBy" small :displayName="false" />
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('Bearbeitet') }}</v-list-item-title>
                    <v-list-item-subtitle>
                        {{ value.updatedAt }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>
import AuthoringToolMixin from '../Mixins/AuthoringtoolMixin';
import UserProfile from '../../Generic/Profile/UserProfile';

export default {
    name: 'catalog-view',
    mixins: [AuthoringToolMixin],
    components: { UserProfile },
    data: () => ({
        showAddUpdateCatalogGroup: false,
        type: 'ADD'
    }),
    props: {
        search: {
            type: String,
            default: ''
        },
        value: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        permissions() {
            return this.getPermissions(this.value.id);
        },
        createText() {
            const type = this.isAuthoringToolType(this.value);
            switch (type) {
                case 'CATALOG_GROUP':
                    return this.$t('Kataloguntergruppe erstellen');
                case 'CATALOG_SUBGROUP':
                    return this.$t('Katalog erstellen');
            }
            return '';
        },
        editText() {
            const type = this.isAuthoringToolType(this.value);
            switch (type) {
                case 'CATALOG_GROUP':
                    return this.$t('Kataloggruppe bearbeiten');
                case 'CATALOG_SUBGROUP':
                    return this.$t('Kataloguntergruppe bearbeiten');
            }
            return '';
        },
        deleteText() {
            const type = this.isAuthoringToolType(this.value);
            switch (type) {
                case 'CATALOG_GROUP':
                    return this.$t('Kataloggruppe löschen');
                case 'CATALOG_SUBGROUP':
                    return this.$t('Kataloguntergruppe löschen');
            }
            return '';
        },
        title() {
            const type = this.isAuthoringToolType(this.value);
            switch (type) {
                case 'CATALOG_GROUP':
                    return this.$t('Kataloguntergruppen');
                case 'CATALOG_SUBGROUP':
                    return this.$t('Kataloge');
            }
            return '';
        },
        folders() {
            return this.value && this.value.children ? this.value.children : [];
        }
    },
    methods: {
        onClick(object) {
            this.$emit('input', object);
        },
        create() {
            const type = this.isAuthoringToolType(this.value);
            switch (type) {
                case 'CATALOG_GROUP':
                    return this.$emit('switchView', {
                        component: 'ADD_CATALOG_SUBGROUP',
                        data: {},
                        type: 'ADD'
                    });
                case 'CATALOG_SUBGROUP':
                    return this.$emit('switchView', {
                        component: 'ADD_CATALOG',
                        data: {},
                        type: 'ADD'
                    });
            }
        },
        update() {
            const type = this.isAuthoringToolType(this.value);
            switch (type) {
                case 'CATALOG_GROUP':
                    return this.$emit('switchView', {
                        component: 'UPDATE_CATALOG_GROUP',
                        data: {},
                        type: 'UPDATE'
                    });
                case 'CATALOG_SUBGROUP':
                    return this.$emit('switchView', {
                        component: 'UPDATE_CATALOG_SUBGROUP',
                        data: {},
                        type: 'UPDATE'
                    });
            }
        },
        remove() {
            const type = this.isAuthoringToolType(this.value);
            switch (type) {
                case 'CATALOG_GROUP':
                    return this.$emit('switchView', {
                        component: 'DELETE_CATALOG_GROUP',
                        data: {},
                        type: 'DELETE'
                    });
                case 'CATALOG_SUBGROUP':
                    return this.$emit('switchView', {
                        component: 'DELETE_CATALOG_SUBGROUP',
                        data: {},
                        type: 'DELETE'
                    });
            }
        },
        filter(items, search) {
            if (search.trim() === '') {
                return items;
            }
            let buildString = [...new Set(search.split(' '))].filter((word) => word !== '');
            return items.filter((item) =>
                buildString.some((word) =>
                    Object.values(item)
                        .filter((val) => typeof val === 'string' || typeof val === 'number')
                        .map((val) => {
                            if (val === 'number') {
                                return val.toString();
                            }
                            return val;
                        })
                        .join(' ')
                        .toLowerCase()
                        .includes(word.toLowerCase())
                )
            );
        },
        sort(items) {
            items.sort((a, b) =>
                a.name.localeCompare(b.name, undefined, {
                    numeric: true,
                    sensitivity: 'base'
                })
            );
            return items;
        }
    }
};
</script>
