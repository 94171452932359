<template>
    <v-card>
        <Toolbar :toolbarTitle="computedTitle" />
        <v-tabs slider-color="accent" v-model="tabs" mandatory show-arrows>
            <v-tab key="OVERVIEW">{{ $t('Übersicht') }}</v-tab>
            <v-tab key="MATERIAL" :disabled="getSelectedAttendeeCourse.material === ''">{{ $t('Material') }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs" touchless>
            <v-tab-item key="OVERVIEW">
                <v-card flat>
                    <CourseBreadcrumbs />
                    <v-container v-if="getSelectedAttendeeCourse.useEvaluation">
                        <MyCourseEvaluation />
                    </v-container>
                    <v-data-iterator
                        :items="getCourseTopics"
                        :no-data-text="$t('Keine Themen verfügbar')"
                        :no-results-text="$t('Kein Ergebnis')"
                        :footer-props="{
                            itemsPerPageText: $t('Themen pro Seite'),
                            'items-per-page-options': [5, 10, 15, { text: $t('Alle'), value: -1 }]
                        }"
                        :hideDefaultFooter="getCourseTopics.length <= 5"
                    >
                        <template #default="{ items }">
                            <v-container>
                                <v-row>
                                    <v-col :xs="12" :sm="12" :md="6" v-for="item in items" :key="item.id">
                                        <Topic
                                            :value="item"
                                            :timeScaleInMinutes="selectedCourse.timeScaleInMinutes"
                                            @input="(value) => (selectedTopic = value)"
                                            :selectable="true"
                                            :isMyCourse="true"
                                            :selected="selectedCard"
                                            @selected="handleCardClick"
                                            @selectedKnowledgeContent="handleSelectedKnowledgeContent"
                                            :selectedKnowledgeContent="selectedKnowledgeContent"
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                    </v-data-iterator>
                    <v-container>
                        <v-layout column>
                            <v-flex>
                                <span class="text-h6">{{ $t('Kursbeschreibung') }}</span>
                            </v-flex>
                            <v-flex>
                                <span>{{ getSelectedAttendeeCourse.description }}</span>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
            </v-tab-item>
            <v-tab-item key="MATERIAL">
                <v-card text>
                    <v-card-text>
                        <div v-html="getSelectedAttendeeCourse.material" class="ck ck-content"></div>
                        <div style="clear: both"></div>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <!-- <v-tab-item key="GROUPS">
                <MyCourseGroups
                    @showGroupList="emitShowGroupList"
                    @showGroup="emitShowGroup"
                    @isMemberTab="emitIsMemberTab"
                    @isGroupForumTab="emitIsGroupForumTab"
                    :resetToShowGroupList="resetGroupList"
                    @showForumForumEditor="emitShowGroupForumEditor"
                    @showForumThreadEditor="emitShowGroupThreadEditor"
                    @showForumAnswerEditor="emitShowGroupAnswerEditor"
                />
            </v-tab-item>
            <v-tab-item key="FORUM">
                <MyCourseGroupForum
                    :isGroup="false"
                    @showForumForumEditor="emitShowGroupForumEditor"
                    @showForumThreadEditor="emitShowGroupThreadEditor"
                    @showForumAnswerEditor="emitShowGroupAnswerEditor"
                />
            </v-tab-item> -->
        </v-tabs-items>
        <KnowledgeContentPreview v-model="computedSelectedKnowledgeContent" toolbarTitle="Content" />
        <!-- <Editor
            :permissions="editorPermissions"
            :editMode="isSelected"
            v-if="computedShowEditor"
            @addItemEmit="addGroup"
            @updateItemEmit="updateGroup"
            @deleteItemEmit="deleteGroup"
        >
            <v-flex v-if="isSelected">
                <v-tooltip top v-if="editorPermissions.abort">
                    <template v-slot:activator="{ on }">
                        <v-btn fab v-on="on" :disabled="!editorPermissions.abort" @click="abortRequest" :small="$vuetify.breakpoint.xs" color="error">
                            <v-icon color="white">clear</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Anfrage abbrechen') }}</span>
                </v-tooltip>
            </v-flex>
            <v-flex v-if="isSelected && !showGroup">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn fab v-on="on" :disabled="!editorPermissions.exit" :small="$vuetify.breakpoint.xs" @click="leaveGroup" color="purple">
                            <v-icon color="white">exit_to_app</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Gruppe verlassen') }}</span>
                </v-tooltip>
            </v-flex>
            <v-flex v-if="isSelected">
                <v-tooltip top v-if="editorPermissions.request">
                    <template v-slot:activator="{ on }">
                        <v-btn fab v-on="on" :disabled="!editorPermissions.request" :small="$vuetify.breakpoint.xs" @click="joinGroup" color="accent">
                            <v-icon color="white">person_add</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Gruppe Beitreten') }}</span>
                </v-tooltip>
            </v-flex>
            <v-flex>
                <v-tooltip top v-if="editorPermissions.removeUser">
                    <template v-slot:activator="{ on }">
                        <v-btn fab v-on="on" :disabled="!editorPermissions.removeUser" :small="$vuetify.breakpoint.xs" @click="remove" color="error">
                            <v-icon color="white">person_remove</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Nutzer entfernen') }}</span>
                </v-tooltip>
            </v-flex>
            <v-flex>
                <v-tooltip top v-if="editorPermissions.removeJoinRequest">
                    <template v-slot:activator="{ on }">
                        <v-btn fab v-on="on" :disabled="!editorPermissions.removeJoinRequest" :small="$vuetify.breakpoint.xs" @click="remove" color="error">
                            <v-icon color="white">person</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Anfrage ablehnen') }}</span>
                </v-tooltip>
            </v-flex>
            <v-flex>
                <v-tooltip top v-if="editorPermissions.acceptJoinRequest">
                    <template v-slot:activator="{ on }">
                        <v-btn fab v-on="on" :disabled="!editorPermissions.acceptJoinRequest" :small="$vuetify.breakpoint.xs" @click="accept" color="success">
                            <v-icon color="white">check</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Anfrage annehmen') }}</span>
                </v-tooltip>
            </v-flex>
        </Editor> -->
        <!-- <component
            v-show="isForumForum"
            v-if="isGroupForumTab || tabs === 3"
            :editMode="isSelectedForum"
            :is="componentForumSwitch"
            @addItemEmit="addForum"
            @updateItemEmit="updateForum"
            @deleteItemEmit="deleteForum"
        />
        <component
            v-show="isForumThread"
            v-if="isGroupForumTab || tabs === 3"
            :editMode="isSelectedThread"
            :is="componentThreadSwitch"
            @addItemEmit="addThread"
            @updateItemEmit="updateThread"
            @deleteItemEmit="deleteThread"
        />
        <component v-show="isForumAnswer" v-if="isGroupForumTab || tabs === 3" :is="componentAnswerSwitch" @addItemEmit="addAnswer" />
        <AddUpdateGroup :type="type" v-model="showAddUpdateGroup" />
        <DeleteMyGroup v-model="showDeleteModal" />
        <AddUpdateForum :type="type" v-model="showAddUpdateForum" :isGroup="tabs !== 3" />
        <AddUpdateThread :type="type" v-model="showAddUpdateThread" :isGroup="tabs !== 3" />
        <MyCourseDeleteForum v-model="showDeleteForumModal" :isGroup="tabs !== 3" />
        <MyCourseDeleteThread v-model="showDeleteThreadModal" :isGroup="tabs !== 3" :type="'THREAD'" /> -->
        <!-- <AddAnswer v-model="showAddAnswerForum" :text="answerText" @text="(value) => (answerText = value)" :isGroup="tabs !== 3" /> -->
    </v-card>
</template>

<script>
import CourseMixin from '../../../Components/CourseLearning/CourseManager/Mixins/CourseMixin';
import Topic from '../../../Components/CourseLearning/CourseManager/Helpers/Topic/Topic';
import CourseBreadcrumbs from '../../../Components/CourseLearning/CourseManager/Breadcrumbs/CourseBreadcrumbs';
import MyCourseEvaluation from '../../../Components/CourseLearning/MyCourses/MyCourseEvaluation';
import KnowledgeContentPreview from '../../../Components/KnowledgeContent/KnowledgeContentPreview';
import Toolbar from '../../../Components/Generic/Toolbar';

import moment from 'moment';

export default {
    name: 'my-topics',
    mixins: [CourseMixin],
    components: {
        Toolbar,
        Topic,
        CourseBreadcrumbs,
        MyCourseEvaluation,
        KnowledgeContentPreview
    },
    data: () => ({
        showAddUpdateForum: false,
        showAddUpdateGroup: false,
        showDeleteForumModal: false,
        showAddUpdateThread: false,
        showDeleteThreadModal: false,
        showAddAnswerForum: false,
        showGroupList: true,
        showGroup: false,
        showDeleteModal: false,
        isMemberTab: false,
        isGroupForumTab: false,
        isForumForum: false,
        isForumThread: false,
        isForumAnswer: false,
        isGroup: false,
        resetGroupList: false,
        tabs: 0,
        selectedCard: {},
        selectedKnowledgeContent: [],
        type: 'ADD',
        userRequests: [],
        users: [],
        answerText: '',
        editorPermissions: {
            add: true,
            update: false,
            delete: false,
            abort: false,
            exit: false,
            request: false,
            removeUser: false,
            removeJoinRequest: false,
            acceptJoinRequest: false
        }
    }),
    watch: {
        tabs(value) {
            if (value === 2) {
                if (!this.isMemberTab && !this.isGroupForumTab) {
                    this.GET_CURRENT_SELECTED_COURSE_GROUP({
                        course: this.getSelectedAttendeeCourse
                    }).then(() =>
                        this.GET_SELECTED_COURSE_GROUPS({
                            course: this.getSelectedAttendeeCourse
                        })
                    );
                }
            } else if (this.getCourseGroupSwitch !== 'myGroup') {
                this.resetGroupList = true;
                this.editorPermissions.add = true;
                this.SET_SELECTED_COURSE_GROUP({});
                this.SET_COURSE_GROUP_SELECTED_FORUM({});
                this.SET_COURSE_GROUP_SELECTED_THREAD({});
            } else {
                this.resetGroupList = true;
            }
        },
        isMemberTab(value) {
            if (!value) {
                this.SET_SELECTED_COURSE_GROUP_USER({});
            } else {
                this.editorPermissions.add = false;
                this.editorPermissions.update = false;
            }
        },
        getCourseGroupSwitch(value) {
            if (value === '') {
                this.editorPermissions.add = true;
            }
        },
        getSelectedCourseGroup(value) {
            if (this.getCourseGroupSwitch != 'myGroup') {
                if (Object.keys(value).length > 0) {
                    if (value.id === this.getCurrentGroup.id) {
                        this.editorPermissions.add = true;
                        this.editorPermissions.update = true;
                        this.editorPermissions.delete = true;
                        this.editorPermissions.exit = true;
                        this.editorPermissions.request = false;
                        this.editorPermissions.abort = false;
                        this.editorPermissions.removeUser = false;
                        this.editorPermissions.acceptJoinRequest = false;
                        this.editorPermissions.removeJoinRequest = false;
                    } else {
                        this.editorPermissions.delete = false;
                        this.editorPermissions.exit = false;
                        this.editorPermissions.update = false;
                        let checkUserJoinRequest = this.getGroupJoinRequests(value.id).filter((item) => item.id === this.getLoggedInAdmin.id);
                        if (checkUserJoinRequest.length > 0) {
                            this.editorPermissions.abort = true;
                            this.editorPermissions.request = false;
                        } else {
                            this.editorPermissions.abort = false;
                            this.editorPermissions.request = true;
                        }
                    }
                } else {
                    this.editorPermissions.delete = false;
                    this.editorPermissions.update = false;
                    this.editorPermissions.exit = false;
                    this.editorPermissions.request = false;
                    this.editorPermissions.abort = false;
                    this.editorPermissions.removeUser = false;
                    this.editorPermissions.acceptJoinRequest = false;
                    this.editorPermissions.removeJoinRequest = false;
                }
            }
        },
        getSelectedCourseGroupUser(value) {
            if (Object.keys(value).length > 0 && this.getCourseGroupSwitch === 'myGroup') {
                this.userRequests = this.getGroupJoinRequests(this.getSelectedCourseGroup.id);
                this.users = this.getCourseGroupUsers(this.getSelectedCourseGroup.id).filter((user) => user.id !== this.getLoggedInAdmin.id);
                this.editorPermissions.removeUser = false;
                this.editorPermissions.acceptJoinRequest = false;
                this.editorPermissions.removeJoinRequest = false;
                this.editorPermissions.delete = false;
                this.editorPermissions.exit = false;
                this.editorPermissions.request = false;
                this.editorPermissions.abort = false;

                const filterUserRequest = this.userRequests.filter((user) => user.id === value.id);
                const filterUsers = this.users.filter((user) => user.id === value.id);
                if (filterUserRequest.length > 0) {
                    this.editorPermissions.acceptJoinRequest = true;
                    this.editorPermissions.removeJoinRequest = true;
                } else if (filterUsers.length > 0 && value.id !== this.getLoggedInAdmin.id) {
                    this.editorPermissions.removeUser = true;
                }
            } else {
                this.editorPermissions.add = false;
                this.editorPermissions.delete = false;
                this.editorPermissions.exit = false;
                this.editorPermissions.request = false;
                this.editorPermissions.abort = false;
                this.editorPermissions.removeUser = false;
                this.editorPermissions.acceptJoinRequest = false;
                this.editorPermissions.removeJoinRequest = false;
            }
        },
        showGroup(value) {
            if (value === true) {
                this.SET_SELECTED_COURSE_GROUP_USER({});
                this.editorPermissions.add = false;
                this.editorPermissions.delete = false;
                this.editorPermissions.exit = false;
                this.editorPermissions.request = false;
                this.editorPermissions.abort = false;
            }
        },
        showGroupList(value) {
            if (value === true) {
                this.editorPermissions.delete = true;
                this.editorPermissions.exit = true;
                this.editorPermissions.add = true;
                this.editorPermissions.removeUser = false;
                this.editorPermissions.acceptJoinRequest = false;
                this.editorPermissions.removeJoinRequest = false;
            }
        }
    },
    computed: {
        computedShowEditor() {
            if (this.tabs === 2) {
                if (this.isGroupForumTab) {
                    return false;
                } else if (this.isMemberTab) {
                    return true;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        },
        isSelected() {
            return Object.keys(this.getSelectedCourseGroup).length > 0;
        },
        isSelectedForum() {
            if (this.tabs === 2) {
                return Object.keys(this.getCourseGroupSelectedForum).length > 0;
            }
            if (this.tabs === 3) {
                return Object.keys(this.getSelectedForum).length > 0;
            }
            return false;
        },
        isSelectedThread() {
            if (this.tabs === 2) {
                return Object.keys(this.getCourseGroupSelectedThread).length > 0;
            }
            if (this.tabs === 3) {
                return Object.keys(this.getSelectedThread).length > 0;
            }
            return false;
        },
        computedTitle() {
            if (this.getSelectedAttendeeCourse.topicTypeName === null) {
                return `${this.$t('Meine Kurse')} - ${this.$t('Themen')}`;
            }
            return `${this.$t('Meine Kurse')} - ${this.getSelectedAttendeeCourse.topicTypeName}`;
        },
        selectedTopic: {
            get() {
                return this.getSelectedTopic;
            },
            set(value) {
                this.SET_SELECTED_TOPIC(value);
            }
        },
        selectedCourse: {
            get() {
                if (this.isAdmin) {
                    return this.getSelectedAdminCourse;
                }
                return this.getSelectedAttendeeCourse;
            },
            set(value) {
                if (this.isAdmin) {
                    this.SET_SELECTED_ADMIN_COURSE(value);
                } else {
                    this.SET_SELECTED_ATTENDEE_COURSE(value);
                }
            }
        },
        computedSelectedKnowledgeContent: {
            get() {
                if (this.selectedKnowledgeContent.length > 0) {
                    return this.selectedKnowledgeContent[0];
                }
                return {};
            },
            set() {
                this.selectedKnowledgeContent = [];
            }
        }
    },
    methods: {
        leaveGroup() {
            this.LEAVE_SELECTED_COURSE_GROUP({
                course: this.getSelectedAttendeeCourse,
                group: this.getSelectedCourseGroup
            })
                .then(() =>
                    this.GET_CURRENT_SELECTED_COURSE_GROUP({
                        course: this.getSelectedAttendeeCourse
                    })
                )
                .then(() =>
                    this.GET_SELECTED_COURSE_GROUPS({
                        course: this.getSelectedAttendeeCourse
                    })
                )
                .then(() => this.SET_SELECTED_COURSE_GROUP({}));
            this.editorPermissions.delete = false;
            this.editorPermissions.exit = false;
        },
        joinGroup() {
            this.SELECTED_COURSE_JOIN_GROUP_REQUEST({
                course: this.getSelectedAttendeeCourse,
                group: this.getSelectedCourseGroup
            })
                .then(() =>
                    this.GET_CURRENT_SELECTED_COURSE_GROUP({
                        course: this.getSelectedAttendeeCourse
                    })
                )
                .then(() =>
                    this.GET_SELECTED_COURSE_GROUPS({
                        course: this.getSelectedAttendeeCourse
                    })
                );
            this.editorPermissions.abort = true;
            this.editorPermissions.request = false;
        },
        abortRequest() {
            this.LEAVE_SELECTED_COURSE_GROUP({
                course: this.getSelectedAttendeeCourse,
                group: this.getSelectedCourseGroup
            })
                .then(() =>
                    this.GET_CURRENT_SELECTED_COURSE_GROUP({
                        course: this.getSelectedAttendeeCourse
                    })
                )
                .then(() =>
                    this.GET_SELECTED_COURSE_GROUPS({
                        course: this.getSelectedAttendeeCourse
                    })
                );
            this.editorPermissions.abort = false;
            this.editorPermissions.request = true;
        },
        handleSelectedKnowledgeContent(value) {
            if (value.length > 0) {
                this.selectedKnowledgeContent = [value[value.length - 1]];
            } else {
                this.selectedKnowledgeContent = [];
            }
        },
        accept() {
            this.SELECTED_COURSE_ACCEPT_GROUP_REQUEST({
                course: this.getSelectedAttendeeCourse,
                group: this.getSelectedCourseGroup,
                user: { id: this.getSelectedCourseGroupUser.id }
            })
                .then(() =>
                    this.GET_SELECTED_COURSE_GROUP_USERS({
                        course: this.getSelectedAttendeeCourse,
                        group: this.getSelectedCourseGroup
                    })
                )
                .then(() => (this.userRequests = this.getGroupJoinRequests(this.getSelectedCourseGroup.id)))
                .then(() => (this.users = this.getCourseGroupUsers(this.getSelectedCourseGroup.id).filter((user) => user.id !== this.getLoggedInAdmin.id)))
                .then(() => this.SET_SELECTED_COURSE_GROUP_USER({}));
        },
        remove() {
            this.REMOVE_USER_FROM_SELECTED_COURSE_GROUP({
                course: this.getSelectedAttendeeCourse,
                group: this.getSelectedCourseGroup,
                user: { id: this.getSelectedCourseGroupUser.id }
            })
                .then(() =>
                    this.GET_CURRENT_SELECTED_COURSE_GROUP({
                        course: this.getSelectedAttendeeCourse
                    })
                )
                .then(() =>
                    this.GET_SELECTED_COURSE_GROUPS({
                        course: this.getSelectedAttendeeCourse
                    })
                )
                .then(() =>
                    this.GET_SELECTED_COURSE_GROUP_USERS({
                        course: this.getSelectedAttendeeCourse,
                        group: this.getSelectedCourseGroup
                    })
                )
                .then(() => (this.userRequests = this.getGroupJoinRequests(this.getSelectedCourseGroup.id)))
                .then(() => (this.users = this.getCourseGroupUsers(this.getSelectedCourseGroup.id).filter((user) => user.id !== this.getLoggedInAdmin.id)))
                .then(() => this.SET_SELECTED_COURSE_GROUP_USER({}));
        },
        deleteForum() {
            this.showDeleteForumModal = true;
        },
        addForum() {
            this.type = 'ADD';
            this.showAddUpdateForum = true;
        },
        updateForum() {
            this.type = 'UPDATE';
            this.showAddUpdateForum = true;
        },
        addThread() {
            this.type = 'ADD';
            this.showAddUpdateThread = true;
        },
        updateThread() {
            this.type = 'UPDATE';
            this.showAddUpdateThread = true;
        },
        deleteThread() {
            this.showDeleteThreadModal = true;
        },
        addAnswer() {
            this.showAddAnswerForum = true;
        },
        emitIsMemberTab() {
            this.isMemberTab = true;
            this.isGroupForumTab = false;
        },
        emitIsGroupForumTab() {
            this.isGroupForumTab = true;
            this.isMemberTab = false;
        },
        emitShowGroupForumEditor(value) {
            this.isForumForum = value;
        },
        emitShowGroupThreadEditor(value) {
            this.isForumThread = value;
        },
        emitShowGroupAnswerEditor(value) {
            this.isForumAnswer = value;
        },
        emitShowGroup() {
            this.showGroup = true;
            this.showGroupList = false;
        },
        emitShowGroupList() {
            this.showGroupList = true;
            this.showGroup = false;
        },
        deleteGroup() {
            this.showDeleteModal = true;
        },
        addGroup() {
            this.type = 'ADD';
            this.showAddUpdateGroup = true;
        },
        updateGroup() {
            this.type = 'UPDATE';
            this.showAddUpdateGroup = true;
        },
        handleCardClick(value) {
            if (this.selectedCard.id === value.id) {
                this.selectedTopic = value;
                this.$router.push({ name: 'myLearningGoals' });
            } else {
                this.selectedCard = value;
            }
        }
    },
    created() {
        this.resetTopicSelection();
    },
    mounted() {
        this.GET_COURSE_TOPICS(this.getSelectedAttendeeCourse).then(() => this.GET_TOPIC_PICTURE());
        if (this.getSelectedAttendeeCourse.endDate < moment().format('YYYY-MM-DD')) {
            this.editorPermissions.add = false;
        }
    },
    beforeDestroy() {
        this.SET_COURSE_SWITCH('');
    }
};
</script>
