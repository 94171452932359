import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            selectedProfiles: [],
            selectedGroups: [],
            inputData: {
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                displayName: '',
                mobileNo: '',
                personalNumber: '',
                active: false,
                city: '',
                street: '',
                state: '',
                houseNo: '',
                disableFrom: ''
            },
            toolbarProp: {
                title: '',
                showDrawer: false,
                showSearchBar: false,
                showSearchButton: false,
                lastPath: -1,
                itemType: '',
                progressStatus: false,
                searchItem: '',
                showMoreButton: true
            },
            selectedUserGroupList: [],
            tooltipMessage: '',
            submitButtonLabel: 'Hinzufügen',
            submitButtonColor: '',
            submitButtonIcon: '',
            messagePopupOption: '',
            showPopupOption: false,
            menus: [
                {
                    title: 'Felder zurücksetzen',
                    actionType: 'RESET',
                    icon: 'clear',
                    color: 'error'
                }
            ],
            newPassword: '',
            confirmPassword: '',
            isAdmin: false,
            switchRole: false,
            showAddToMultiGroup: false,
            showSwitchRole: false
        };
    },
    computed: {
        ...mapGetters('General', ['getLoggedInAdmin', 'getAdminProfilePicture']),
        ...mapGetters('User', ['getDeletedUserList', 'getSelectedUser', 'getUserStore']),
        ...mapGetters('Admin', ['getDeletedAdminList', 'getSelectedAdmin', 'getAdminStore']),
        ...mapGetters('ProfileOperations', ['getOptData']),
        translatedTitle() {
            return this.$t(this.toolbarProp.title);
        }
    },
    methods: {
        ...mapActions('User', [
            'ADD_USER',
            'UPDATE_USER',
            'SELECT_USER',
            'RESET_SELECTED_USER',
            'ADD_USER_TO_USERGROUP_LIST',
            'GET_DELETED_USER_LIST',
            'RECOVER_USER',
            'UPDATE_USER_PROFILE',
            'DELETE_USER_BY_ID',
            'UPDATE_ROLE_USERTOADMIN',
            'UPDATE_USER_PASSWORD',
            'UPDATE_USER_STORE',
            'RESET_USER_STORE'
        ]),
        ...mapActions('Admin', [
            'SELECT_ADMIN',
            'RESET_SELECTED_ADMIN',
            'UPDATE_ROLE_ADMINTOUSER',
            'RECOVER_ADMIN',
            'ADD_ADMIN',
            'RECOVER_ADMIN',
            'UPDATE_ADMIN',
            'UPDATE_ADMIN_PROFILE',
            'ADD_ADMIN_TO_ADMINGROUP_LIST',
            'GET_DELETED_ADMIN_LIST',
            'DELETE_ADMIN_BY_ID',
            'GET_PERMISSION_PROFILES_FROM_USER',
            'SET_PERMISSION_PROFILES_TO_USER',
            'UPDATE_ADMIN_PASSWORD',
            'UPDATE_ADMIN_STORE',
            'RESET_ADMIN_STORE'
        ]),
        ...mapActions('AdminPermission', ['CHECK_PERMISSIONS']),
        ...mapActions('ErrorReporterOperations', ['SET_ERROR_MESSAGES', 'CLEAR_ERROR_MESSAGES']),
        ...mapActions('General', [
            'UPDATE_LOGGED_IN_ADMIN',
            'UPDATE_ADMIN_PROFILEPICTURE',
            'RESET_ADMIN_PROFILEPICTURE_BY_ID',
            'GET_ADMIN_PROFILEPICTURE_BY_ID'
        ]),
        ...mapActions('ProfileOperations', ['GET_USER_MAIL_OPTIONS', 'SET_USER_MAIL_OPTIONS'])
    }
};
