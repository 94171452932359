import Vue from 'vue';
import axios from '../../plugins/Axios/axios';
import { Storage } from '../../plugins/Storage/storage';

const state = {
    storage: {},
    mediaMap: {},
    folderMap: {},
    resourceTree: [],
    view: {
        component: '',
        type: '',
        data: {}
    }
};

// getters
const getters = {
    getView(state) {
        return state.view;
    },
    getStorage(state) {
        return state.storage;
    },
    getFiles(state) {
        return Object.values(state.mediaMap).flat(1);
    },
    getFileFolder(state, getters) {
        return ({ id } = { id: '' }) => {
            const folderId = Object.entries(state.mediaMap).reduce((acc, [key, value]) => {
                if (acc !== '') {
                    return acc;
                }
                if (value.map(({ id }) => id).includes(id)) {
                    return key;
                }
                return acc;
            }, '');
            return getters.getTreeObject({ id: folderId });
        };
    },
    getFolderMedia(state) {
        return ({ id: folderId } = { id: '' }) => {
            if (state.mediaMap.hasOwnProperty(folderId)) {
                return state.mediaMap[folderId];
            }
            return [];
        };
    },
    getFolder(state) {
        return ({ id } = { id: '' }) => {
            if (state.folderMap.hasOwnProperty(id)) {
                return state.folderMap[id];
            }
            return { id: '', children: [] };
        };
    },
    getParent(state, getters) {
        const findParent = (nodes, id) => {
            const parent = nodes.find((node) => {
                if (node.children && node.children.length > 0) {
                    return node.children.map(({ id }) => id).includes(id);
                }
            });
            if (!parent) {
                const hasChildren = nodes
                    .map((node) => node.children)
                    .filter((node) => node.length > 0)
                    .flat();
                if (hasChildren.length > 0) {
                    return findParent(hasChildren, id);
                } else {
                    return undefined;
                }
            }
            return parent;
        };

        return ({ id } = { id: '' }) => {
            return findParent(getters.getTree, id);
        };
    },
    getTreeObject(state, getters) {
        return ({ id: objId }) => {
            const find = (nodes, id) =>
                nodes.reduce((acc, curr) => {
                    if (acc) return acc;
                    if (curr.id && curr.id === id) {
                        return curr;
                    }
                    if (curr.children && curr.children.length > 0) {
                        return find(curr.children, id);
                    }
                }, undefined);
            return find(getters.getTree, objId) || {};
        };
    },
    getTree(state, getters) {
        const buildSubtree = (node) => {
            const folder = getters.getFolder(node);
            if (node.children.length === 0) {
                if (folder.id === '') {
                    return { ...node, children: [] };
                }
                return { ...node, ...folder, children: [] };
            }
            if (folder.id === '') {
                return {
                    ...node,
                    children: node.children.map((resource) => buildSubtree(resource))
                };
            }
            const children = node.children.map((resource) => buildSubtree(resource));
            return {
                ...node,
                ...folder,
                children: children
            };
        };
        const tree = state.resourceTree.map((resource) => buildSubtree(resource));
        return tree;
    }
};

// actions
const actions = {
    GET_TREE({ commit }) {
        return axios
            .get(`/medias/folderTree`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateResourceTree', response);
                return response;
            });
    },
    GET_ALL_MEDIA({ commit }) {
        return axios
            .get(`/medias/list`)
            .then((response) => response.data.data)
            .then((response) => {
                commit(
                    'updateFolderMap',
                    response.folders.reduce((folders, current) => {
                        folders[current.id] = current;
                        return folders;
                    }, {})
                );
                commit('updateMediaMap', response.medias);
                return response;
            });
    },
    CREATE_FOLDER({ commit, state }, request) {
        return axios
            .post('/folders', request)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateFolderMap', {
                    ...state.folderMap,
                    [response.id]: response
                });
                return response;
            });
    },
    GET_ROOT_FOLDER({ commit }) {
        return axios.get('/folders').then((response) => {
            commit('updateRootFolderList', response.data.data);
            return response;
        });
    },
    GET_SUB_FOLDER_BY_ID({ commit }, parentId) {
        return axios.get('/folders/' + parentId + '/subfolders').then((response) => {
            commit('updateSubFolderList', response.data.data);
            return response;
        });
    },
    UPLOAD_MEDIA({ commit, dispatch }, { name = '', parentId = '', isRoot = true, isPublic = false, file }) {
        let formData = new FormData();
        formData.append('name', name);
        formData.append('parentId', parentId);
        formData.append('isRoot', isRoot);
        formData.append('type', 'media');
        formData.append('file', file);
        formData.append('isPublic', isPublic);

        return axios
            .post(`/medias?`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateMediaMap', response);
                dispatch('GET_STORAGE');
                return response;
            });
    },
    GET_MEDIA_BY_ID({ commit }, mediaId) {
        return axios.get('/medias/' + mediaId + '?authorization=' + Storage.getData('token')).then((response) => {
            commit('updateMedia', response.data.data);
            return response;
        });
    },
    DELETE_MEDIA_BY_ID({ commit, dispatch, state }, mediaId) {
        return axios.delete(`medias/${mediaId}`).then((response) => {
            dispatch('GET_STORAGE');
            const mapArray = Object.entries(state.mediaMap).reduce((acc, [key, value]) => {
                const media = value.filter((item) => item.id !== mediaId);
                if (media.length > 0) {
                    acc[key] = media;
                }
                return acc;
            }, {});
            commit('setMediaMap', mapArray);
            return response;
        });
    },
    DELETE_FOLDER_BY_ID({ commit, state }, folderId) {
        return axios.delete(`folders/${folderId}`).then((response) => {
            const map = Object.entries(state.folderMap).reduce((acc, [key, value]) => {
                if (key !== folderId) {
                    acc[key] = value;
                }
                return acc;
            }, {});
            commit('updateFolderMap', map);
            return response;
        });
    },
    UPDATE_FOLDER({ commit, state }, { id, ...request }) {
        return axios
            .patch(`/folders/${id}`, request)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateFolderMap', {
                    ...state.folderMap,
                    [response.id]: response
                });
                return response;
            });
    },
    UPDATE_MEDIA({ commit }, { id, ...request }) {
        return axios
            .patch(`/medias/${id}`, request)
            .then((response) => response.data.data)
            .then((response) => {
                commit('deleteMediaFromMap', response);
                commit('updateMediaMap', response);
                return response;
            });
    },
    MOVE_MEDIA({ commit }, { media, request }) {
        return axios.patch(`/medias/${media.id}`, request).then((response) => {
            return response;
        });
    },
    MOVE_FOLDER({ commit }, { folder, request }) {
        return axios.patch(`/folders/${folder.id}`, request).then((response) => {
            return response;
        });
    },
    GET_STORAGE({ commit }) {
        return axios
            .get(`/medias/storage`)
            .then((response) => response.data.data)
            .then((response) => commit('updateStorage', response));
    },
    SET_VIEW({ commit }, view) {
        commit('updateView', view);
    }
};

// mutations
const mutations = {
    clear: (state) => {
        state.mediaMap = {};
        state.folderMap = {};
        state.storage = {};
        state.resourceTree = [];
        state.view = {
            component: '',
            type: '',
            data: {}
        };
    },
    updateView: (state, view) => {
        state.view = view;
    },
    ////////////////////////////
    updateRootFolderList: (state, rootList) => {
        state.rootList = rootList;
    },
    updateSubFolderList: (state, subList) => {
        state.subList = subList;
    },
    updateStorage: (state, response) => {
        state.storage = response;
    },
    /////////////////////////////
    updateResourceTree: (state, tree) => {
        Vue.set(state, 'resourceTree', tree);
        // state.resourceTree = tree;
    },
    setMediaMap: (state, map) => {
        Vue.set(state, 'mediaMap', map);
    },
    deleteMediaFromMap: (state, media) => {
        const filteredMap = Object.entries(state.mediaMap).reduce((acc, [key, value]) => {
            const filter = value.reduce((acc, curr) => {
                if (media.id !== curr.id) {
                    acc.push(curr);
                }
                return acc;
            }, []);
            acc[key] = filter;
            return acc;
        }, {});
        Vue.set(state, 'mediaMap', filteredMap);
    },
    updateMediaMap: (state, media) => {
        if (Array.isArray(media)) {
            const map = media.reduce(
                (resources, current) => {
                    const folderId = current.folderId === '' ? 'root' : current.folderId;
                    if (resources.hasOwnProperty(folderId)) {
                        const folderMedia = resources[folderId];
                        if (folderMedia.map(({ id }) => id).includes(current.id)) {
                            resources[folderId] = folderMedia.map((item) => {
                                if (item.id === current.id) {
                                    return current;
                                }
                                return item;
                            });
                        } else {
                            resources[folderId] = [...resources[folderId], current];
                        }
                    } else {
                        resources[folderId] = [current];
                    }
                    resources[folderId].sort((a, b) => a.fileName.localeCompare(b.fileName, undefined, { numeric: true, sensitivity: 'base' }));
                    return resources;
                },
                { ...state.mediaMap }
            );
            Vue.set(state, 'mediaMap', map);
        } else {
            if (typeof media === 'object') {
                const folderId = media.folderId === '' ? 'root' : media.folderId;
                if (state.mediaMap.hasOwnProperty(folderId)) {
                    const folderMedia = state.mediaMap[folderId];
                    if (folderMedia.map(({ id }) => id).includes(media.id)) {
                        const map = {
                            ...state.mediaMap,
                            [folderId]: folderMedia.map((item) => {
                                if (item.id === media.id) {
                                    return media;
                                }
                                return item;
                            })
                        };
                        Vue.set(state, 'mediaMap', map);
                    } else {
                        Vue.set(state.mediaMap, folderId, [...state.mediaMap[folderId], media]);
                    }
                } else {
                    Vue.set(state.mediaMap, folderId, [media]);
                }
            }
        }
    },
    updateFolderMap: (state, resources) => {
        Vue.set(state, 'folderMap', resources);
    },
    updateContentMap: (state, { folder: { parentId }, files }) => {
        Vue.set(state.contents, parentId, files);
    },
    updateFolders: (state, folders) => {
        if (Array.isArray(folders)) {
            state.folders = folders.reduce((accu, folder) => {
                const ids = accu.map(({ id }) => id);
                if (ids.includes(folder.id)) {
                    const index = ids.findIndex((id) => id === folder.id);
                    if (index !== -1) {
                        Vue.set(state.folders, index, folder);
                    }
                } else {
                    accu.push(folder);
                }
                return accu;
            }, state.folders);
        } else {
            const ids = state.folders.map(({ id }) => id);
            if (ids.includes(folders.id)) {
                const index = ids.findIndex((id) => id === folders.id);
                if (index !== -1) {
                    Vue.set(state.folders, index, folders);
                }
            } else {
                state.folders.push(folders);
            }
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
