<template>
    <v-card class="mb-2">
        <v-card-text>
            <v-layout column>
                <v-flex>
                    <span
                        :class="{
                            subheading: true,
                            'font-weight-medium': true,
                            'primary--text': isSelf
                        }"
                    >
                        {{ user.displayName }}
                    </span>
                </v-flex>
                <v-flex>
                    <span class="pre">{{ value.message }}</span>
                </v-flex>
            </v-layout>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <span>{{ `${value.date} ${value.time}` }}</span>
        </v-card-actions>
    </v-card>
</template>

<script>
import ChatMixin from '../Mixins/ChatMixin';

export default {
    name: 'message-block',
    mixins: [ChatMixin],
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        isNewOrUnreadMessage() {
            return (this.value.hasOwnProperty('isNewMessage') && !!this.value.isNewMessage) || !this.value.read;
        },
        user() {
            if (this.value.hasOwnProperty('senderId')) {
                const user = this.getUser(this.value.senderId);
                if (Object.keys(user).length > 0) {
                    return user;
                }
            }
            return this.getLoggedInAdmin;
        },
        isSelf() {
            return Object.keys(this.getUser(this.value.senderId)).length === 0;
        }
    }
};
</script>
