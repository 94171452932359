<template>
    <v-dialog v-model="showDialog" :width="$vuetify.breakpoint.thresholds.sm" :fullscreen="$vuetify.breakpoint.xs" scrollable persistent>
        <v-card>
            <v-toolbar dense flat dark color="secondary">
                <v-toolbar-title>{{ $t('Nutzerdetails') }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon text @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col :cols="12">
                            <v-text-field
                                color="accent"
                                :label="$t('Angezeigter Name')"
                                v-model="displayName"
                                persistent-hint
                                :hint="$t('Dieser Name wird App intern gebraucht')"
                            />
                        </v-col>
                        <v-col :cols="12">
                            <v-text-field
                                color="accent"
                                :label="$t('E-Mail')"
                                v-model="email"
                                readonly
                                persistent-hint
                                disabled
                                :hint="$t('E-Mail und Primärschlüssel')"
                            />
                        </v-col>
                        <v-col :cols="12">
                            <v-text-field
                                color="accent"
                                :label="$t('Vorname')"
                                v-model="firstName"
                                persistent-hint
                                :hint="$t('Vorname wird in E-Mails verwendet')"
                            />
                        </v-col>
                        <v-col :cols="12">
                            <v-text-field
                                color="accent"
                                :label="$t('Nachname')"
                                v-model="lastName"
                                persistent-hint
                                :hint="$t('Nachname wird in E-Mails verwendet')"
                            />
                        </v-col>
                        <v-col :cols="12">
                            <v-text-field
                                color="accent"
                                :label="$t('Personalnummer')"
                                v-model="personalNumber"
                                persistent-hint
                                :hint="$t('Personalnummer, zusätzliche Erkennung für Filter und Suche')"
                            />
                        </v-col>
                        <v-col :cols="12">
                            <v-label>{{ $t('Gruppen') }}</v-label>
                        </v-col>
                        <v-col :cols="12">
                            <v-data-iterator
                                :items="groups"
                                :no-data-text="$t('Keine Gruppen verfügbar')"
                                :no-results-text="$t('Kein Ergebnis')"
                                :items-per-page="5"
                                :footer-props="{
                                    'items-per-page-options': [5, 10, 15, { value: -1, text: $t('Alle') }]
                                }"
                                :hideDefaultFooter="groups.length === 0"
                            >
                                <template v-slot:item="items">
                                    <v-list-item>
                                        <v-list-item-action>
                                            <v-icon>mdi-account-multiple</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ items.item.name }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ items.item.description }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn icon @click.stop="removeGroup(items.item)">
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </template>
                            </v-data-iterator>
                        </v-col>
                        <v-col :cols="12">
                            <v-label>{{ $t('Berechtigungsprofile') }}</v-label>
                        </v-col>
                        <v-col :cols="12">
                            <v-data-iterator
                                :items="permissionProfiles"
                                :no-data-text="$t('Keine Profile verfügbar')"
                                :no-results-text="$t('Kein Ergebnis')"
                                :items-per-page="5"
                                :footer-props="{
                                    'items-per-page-options': [5, 10, 15, { value: -1, text: $t('Alle') }]
                                }"
                                :hideDefaultFooter="permissionProfiles.length === 0"
                            >
                                <template v-slot:item="items">
                                    <v-list-item>
                                        <v-list-item-action>
                                            <v-icon>mdi-account-circle</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ items.item.name }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ items.item.description }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn icon @click.stop="removePermissionProfile(items.item)">
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </template>
                            </v-data-iterator>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn block color="success" @click="save">
                    {{ $t('Speichern') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: 'excel-user-details',
    data: () => ({
        user: {}
    }),
    watch: {
        value(value) {
            if (value) {
                // deep copy. For some reason, only this works
                this.user = JSON.parse(JSON.stringify(this.selectedUser));
            }
        }
    },
    computed: {
        displayName: {
            get() {
                if (!this.user.hasOwnProperty('user')) {
                    return '';
                }
                if (!this.user.user.hasOwnProperty('displayName')) {
                    return '';
                }
                return this.user.user.displayName;
            },
            set(value) {
                this.user.user.displayName = value;
            }
        },
        email: {
            get() {
                if (!this.user.hasOwnProperty('user')) {
                    return '';
                }
                if (!this.user.user.hasOwnProperty('email')) {
                    return '';
                }
                return this.user.user.email;
            },
            set(value) {
                this.user.user.email = value;
            }
        },
        firstName: {
            get() {
                if (!this.user.hasOwnProperty('user')) {
                    return '';
                }
                if (!this.user.user.hasOwnProperty('firstName')) {
                    return '';
                }
                return this.user.user.firstName;
            },
            set(value) {
                this.user.user.firstName = value;
            }
        },
        lastName: {
            get() {
                if (!this.user.hasOwnProperty('user')) {
                    return '';
                }
                if (!this.user.user.hasOwnProperty('lastName')) {
                    return '';
                }
                return this.user.user.lastName;
            },
            set(value) {
                this.user.user.lastName = value;
            }
        },
        personalNumber: {
            get() {
                if (!this.user.hasOwnProperty('user')) {
                    return '';
                }
                if (!this.user.user.hasOwnProperty('personalNumber')) {
                    return '';
                }
                return this.user.user.personalNumber;
            },
            set(value) {
                this.user.user.personalNumber = value;
            }
        },
        groups() {
            if (this.user.hasOwnProperty('adminGroups')) {
                if (this.user.adminGroups.length > 0) {
                    return this.user.adminGroups;
                }
            }
            if (this.user.hasOwnProperty('userGroups')) {
                if (this.user.userGroups.length > 0) {
                    return this.user.userGroups;
                }
            }
            return [];
        },
        permissionProfiles() {
            if (this.user.hasOwnProperty('permissionProfiles')) {
                if (this.user.permissionProfiles.length > 0) {
                    return this.user.permissionProfiles;
                }
            }
            return [];
        },
        showDialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        selectedUser: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        save() {
            this.$emit('changeData', { ...this.user });
            this.close();
        },
        close() {
            this.user = {};
            this.$emit('input', false);
        },
        removeGroup(group) {
            if (this.user.hasOwnProperty('adminGroups')) {
                this.user.adminGroups = this.user.adminGroups.filter((adminGroup) => adminGroup.id !== group.id);
                this.user.userGroups = this.user.userGroups.filter((userGroup) => userGroup.id !== group.id);
            }
        },
        removePermissionProfile(profile) {
            if (this.user.hasOwnProperty('permissionProfiles')) {
                this.user.permissionProfiles = this.user.permissionProfiles.filter((permission) => permission.id !== profile.id);
            }
        }
    }
};
</script>
