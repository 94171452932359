import axios from '../../plugins/Axios/axios';
import Vue from 'vue';

const state = {
    /**
     * A Map containing the following Objects:
     * root: [..catalogGroups],
     * catalogGroupId: [...catalogSubgroups],
     * catalogSubgroupId: [...catalogs],
     */
    authoringToolMap: {},
    /**
     * A Map containing the following Objects:
     * catalogId: [...knowledgeUnits]
     */
    knowledgeUnitMap: {},
    /**
     * A Map containing the following Objects:
     * catalogGroupId: [...permissions]
     * catalogSubgroupId: [...permissions]
     */
    permissionMap: {},
    /**
     * avoid prop drilling with this
     */
    view: {
        component: '',
        type: '',
        data: {}
    }
};

const getters = {
    getCatalogs(state) {
        const catalogs = Object.values(state.authoringToolMap)
            .flat(1)
            .filter((item) => item.id.startsWith('cat'));
        catalogs.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, {
                sensitivity: 'base',
                numeric: true
            })
        );
        return catalogs;
    },
    getView(state) {
        return state.view;
    },
    getPermissions(state) {
        return (id) => {
            if (state.permissionMap.hasOwnProperty(id)) {
                return state.permissionMap[id];
            }
            return [];
        };
    },
    getKnowledgeUnits(state) {
        return (id) => {
            if (state.knowledgeUnitMap.hasOwnProperty(id)) {
                return state.knowledgeUnitMap[id];
            }
            return [];
        };
    },
    getMapData(state) {
        return (id) => {
            if (state.authoringToolMap.hasOwnProperty(id)) {
                return state.authoringToolMap[id];
            }
            return [];
        };
    },
    getParent(state, getters) {
        const findParent = (nodes, id) => {
            const parent = nodes.find((node) => {
                if (node && !!node.children && node.children.length > 0) {
                    return node.children.map(({ id }) => id).includes(id);
                }
            });
            if (!parent) {
                const hasChildren = nodes
                    .map((node) => node.children)
                    .filter((node) => node.length > 0)
                    .flat();
                if (hasChildren.length > 0) {
                    return findParent(hasChildren, id);
                } else {
                    return undefined;
                }
            }
            return parent;
        };

        return ({ id }) => {
            return findParent(getters.getTree, id);
        };
    },
    getTree(state, getters) {
        const buildTree = (nodes) => {
            return nodes
                .map((node) => {
                    const children = buildTree(getters.getMapData(node.id));
                    return { ...node, children };
                })
                .sort((a, b) =>
                    a.name.localeCompare(b.name, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    })
                );
        };
        return buildTree(getters.getMapData('root'));
    }
};

const actions = {
    SET_VIEW({ commit }, view) {
        commit('updateView', view);
    },
    LOAD_PERMISSIONS({ commit }) {
        return axios
            .get(`/authoringtool/permissions`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updatePermissionMap', response);
                return response;
            });
    },
    LOAD_KNOWLEDGE_UNITS({ commit, dispatch }, catalog) {
        return dispatch('KnowledgeUnitOperations/GET_KNOWLEDGEUNITS_BY_CATALOG', catalog, { root: true }).then((result) => {
            commit('updateKnowledgeUnitMap', { [catalog.id]: result });
        });
    },
    BUILD_MAP({ commit }) {
        return axios
            .get(`/authoringtooldetails`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('setMap', {
                    ...response.catalogGroups,
                    ...response.catalogSubgroups,
                    ...response.catalogs
                });
                commit('setKnowledgeUnitMap', { ...response.knowledgeUnits });
            });
    }
};

const mutations = {
    clear: (state) => {
        state.authoringToolMap = {};
        state.permissionMap = {};
        state.knowledgeUnitMap = {};
    },
    setMap: (state, data) => {
        Vue.set(state, 'authoringToolMap', data);
    },
    updateMap: (state, data) => {
        const [key, value] = Object.entries(data)[0];
        Vue.set(state.authoringToolMap, key, value);
    },
    setKnowledgeUnitMap: (state, data) => {
        Vue.set(state, 'knowledgeUnitMap', data);
    },
    updateKnowledgeUnitMap: (state, data) => {
        const [key, value] = Object.entries(data)[0];
        Vue.set(state.knowledgeUnitMap, key, value);
    },
    updatePermissionMap: (state, data) => {
        Vue.set(state, 'permissionMap', data);
    },
    updateView: (state, view) => {
        Vue.set(state, 'view', view);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
