<template>
    <div>
        <div v-for="(item, index) in serializedValue" :key="`notifcation-${index}`">
            <v-layout>
                <v-flex xs11>
                    <v-slider thumb-label :value="item.day" @change="emitValue($event, index)" step="1" :min="min" :max="max" hide-details>
                        <template v-slot:label>
                            <div class="text-caption">
                                {{ `${item.day} ${$t('Tage zuvor')}` }}
                            </div>
                        </template>
                    </v-slider>
                </v-flex>
                <v-flex xs1>
                    <v-layout fill-height align-center justify-center>
                        <v-btn icon v-if="index == serializedValue.length - 1" @click="addNotification()">
                            <v-icon color="success">mdi-plus</v-icon>
                        </v-btn>
                        <v-btn icon v-if="index < serializedValue.length - 1" @click="removeNotification(index, item)">
                            <v-icon color="error">mdi-delete</v-icon>
                        </v-btn>
                    </v-layout>
                </v-flex>
            </v-layout>
        </div>
    </div>
</template>
<script>
export default {
    name: 'notify-users',
    data: () => ({
        max: 60,
        min: 1
    }),
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        serializedValue() {
            return this.value.map((day) => ({ day: day }));
        }
    },
    methods: {
        emitValue(value, index) {
            let copy = [...this.value];
            copy[index] = value;
            this.$emit('input', copy);
        },
        addNotification() {
            this.$emit('input', [...this.value, this.min]);
        },
        removeNotification(index) {
            this.$emit(
                'input',
                this.value.filter((val, idx) => idx !== index)
            );
        }
    }
};
</script>
