<template>
    <div>
        <v-card :color="calculateColor" flat dark>
            <v-container fluid>
                <v-row>
                    <v-col cols="auto" v-show="!!$slots.default">
                        <v-layout fill-height align-center justify-center>
                            <v-flex>
                                <slot></slot>
                            </v-flex>
                        </v-layout>
                    </v-col>
                    <v-col>
                        <v-layout column>
                            <span class="text-h6">{{ $t('Resultat') }}</span>
                            <span>{{ $t(reason) }}</span>
                        </v-layout>
                    </v-col>
                    <v-col cols="auto" v-show="isLoopingTask">
                        <v-layout fill-height align-center justify-center>
                            <v-flex>
                                <v-tooltip left>
                                    <template #activator="{ on, attrs }">
                                        <v-btn icon v-on="on" v-bind="attrs" @click="loopTask">
                                            <v-icon>mdi-redo-variant</v-icon>
                                        </v-btn>
                                    </template>
                                    {{ $t('Wiederholen') }}
                                </v-tooltip>
                            </v-flex>
                        </v-layout>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-container fluid>
            <v-row>
                <v-col :xs="6" :sm="3">
                    <v-card>
                        <v-toolbar flat dense dark color="secondary">
                            <v-toolbar-title>{{ $t('Beantwortet') }}</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <span class="success--text">{{ `${answered} (${percentage(answered)}%)` }}</span>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col :xs="6" :sm="3">
                    <v-card>
                        <v-toolbar flat dense dark color="secondary">
                            <v-toolbar-title>{{ $t('Unbeantwortet') }}</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <span class="info--text">{{ `${unanswered} (${percentage(unanswered)}%)` }}</span>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col :xs="6" :sm="3">
                    <v-card>
                        <v-toolbar flat dense dark color="secondary">
                            <v-toolbar-title>{{ $t('Richtig') }}</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <span class="success--text">{{ `${correct} (${percentage(correct)}%)` }}</span>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col :xs="6" :sm="3">
                    <v-card>
                        <v-toolbar flat dense dark color="secondary">
                            <v-toolbar-title>{{ $t('Falsch') }}</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <span class="error--text">{{ `${incorrect} (${percentage(incorrect)}%)` }}</span>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'quiz-result-selector-header',
    props: {
        settings: {
            type: Object,
            default: () => ({})
        },
        reason: {
            type: String,
            default: ''
        },
        correctAnswer: {
            type: Array,
            default: () => []
        },
        onLoop: {
            type: Function,
            default: () => new Promise(),
            validator: (func) => func instanceof Promise || 'onResult must be of type Promise'
        },
        onStart: {
            type: Function,
            default: () => new Promise(),
            validator: (func) => func instanceof Promise || 'onStart must be of type Promise'
        }
    },
    computed: {
        isLoopingTask() {
            return this.settings && this.settings.quiz && this.settings.quiz.taskOptions && this.settings.quiz.taskOptions.loopingTask;
        },
        calculateColor() {
            if (this.reason.includes('failed')) {
                return 'error';
            }
            return 'success';
        },
        correct() {
            return this.correctAnswer.reduce((acc, { hasAnsweredCorrect, hasAnswered }) => (hasAnsweredCorrect && hasAnswered ? acc + 1 : acc), 0);
        },
        incorrect() {
            return this.correctAnswer.reduce((acc, { hasAnsweredCorrect, hasAnswered }) => (!hasAnsweredCorrect && hasAnswered ? acc + 1 : acc), 0);
        },
        answered() {
            return this.correctAnswer.reduce((acc, { hasAnswered }) => (hasAnswered ? acc + 1 : acc), 0);
        },
        unanswered() {
            return this.correctAnswer.reduce((acc, { hasAnswered }) => (!hasAnswered ? acc + 1 : acc), 0);
        }
    },
    methods: {
        loopTask() {
            this.onLoop().then(this.onStart);
        },
        percentage(attribute) {
            return attribute === 0 || this.correctAnswer.length === 0 ? 0 : ((attribute / this.correctAnswer.length) * 100).toFixed(2);
        }
    }
};
</script>
