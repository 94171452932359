<template>
    <v-card flat height="100%">
        <Toolbar :showDrawer="isBackable" :toolbarTitle="translatedTitle" @backEmit="$emit('goBack')" tooltipMessage="Hier können Sie Statistiken ansehen." />
        <v-container fluid>
            <v-row align="start">
                <!-- MANAGER -->
                <v-col :cols="12">
                    <DashboardManager
                        v-model="filterList"
                        :statistics="statisticAttribute"
                        :filterList="getGroupItemList"
                        :isAdminStatistic="isAdminStatistic"
                        @intervalByEmit="intervalByEmit"
                        @groupByEmit="groupByEmit"
                        @startDateEmit="startDateEmit"
                        @endDateEmit="endDateEmit"
                        @dayDividedEmit="dayDividedEmit"
                    />
                </v-col>
                <!-- GOOGLE CHART COMP -->
                <v-col :cols="12">
                    <Chart
                        :statisticAttribute="statisticAttribute"
                        selectedChartType="ColumnChart"
                        :userStatisticList="userStatisticLocal"
                        :mappedDataList="mappedDataList"
                        :keyComputed="keyComputed"
                        v-if="hasData"
                    />
                    <v-layout v-if="!hasData" align-center justify-center fill-height>
                        <span class="text-center">{{ $t('Keine Daten') }}</span>
                    </v-layout>
                    <template v-if="hasData">
                        <v-checkbox class="export-checkbox" v-model="isGroupedExport" :label="$t('Exportieren Sie in einem Blatt')" />
                        <v-card-actions>
                            <v-btn block color="secondary" @click="exportStatisticsAsExcel">
                                {{ $t('Excel Export') }}
                            </v-btn>
                        </v-card-actions>
                    </template>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { saveAs } from 'file-saver';

import Toolbar from '../../Components/Generic/Toolbar';
import DashboardManager from './DashboardManager';
import Chart from '../../Components/Chart/Chart';

import UserMixin from '../../Components/Users/Mixins/UserMixin';

export default {
    name: 'dashboard',
    mixins: [UserMixin],
    components: {
        Toolbar,
        DashboardManager,
        Chart
    },
    data() {
        return {
            toolbarTitle: 'Statistiken',
            statisticAttribute: {
                //INTERVAL
                interval: {
                    startDate: new Date().toISOString().substr(0, 10),
                    endDate: new Date().toISOString().substr(0, 10),
                    groupDays: 7,
                    month: new Date().toISOString().substr(6, 1),
                    year: new Date().toISOString().substr(0, 4),
                    type: [
                        { title: 'Day', code: 'DAY' },
                        { title: 'Week', code: 'WEEK' },
                        { title: 'Month', code: 'MONTH' },
                        { title: 'Year', code: 'YEAR' },
                        { title: 'Range', code: 'RANGE' }
                    ],
                    selectedType: { title: 'Day', code: 'DAY' }
                },
                //GROUP
                group: {
                    type: [
                        { title: 'Users', code: 'USERS', key: 'users' },
                        {
                            title: 'Catalogs',
                            code: 'CATALOGS',
                            key: 'catalogs'
                        },
                        {
                            title: 'Knowledge Units',
                            code: 'KNOWLEDGEUNITS',
                            key: 'knowledgeUnits'
                        },
                        { title: 'Tasks', code: 'TASKS', key: 'tasks' }
                    ],
                    selectedType: {
                        title: 'Users',
                        code: 'USERS',
                        key: 'users'
                    }
                },
                itemList: []
            },
            // FILTER
            filterList: {
                users: [],
                catalogs: [],
                knowledgeUnits: [],
                tasks: [],
                userGroups: [],
                adminGroups: []
            },
            userStatisticLocal: [],
            mappedDataList: [],
            isGroupedExport: false
        }; // end.return
    },
    props: {
        isAdminStatistic: {
            default: true,
            type: Boolean
        }
    },
    watch: {
        filterList(value, old) {
            const isSame = Object.entries(value).reduce((result, [key, val]) => {
                if (old.hasOwnProperty(key)) {
                    const oldList = old[key];
                    const oldIds = oldList.map(({ id }) => id);
                    const newIds = val.map(({ id }) => id);
                    return result && oldIds.every((id) => newIds.includes(id)) && newIds.every((id) => oldIds.includes(id));
                }
                return false;
            }, true);
            if (!isSame) {
                this.getFilterStatistics();
            }
        },
        statisticAttribute: {
            handler() {
                this.getStatistic();
            },
            deep: true
        }
    },
    computed: {
        ...mapGetters('StatisticOperations', ['getUserStatistic', 'getGroupItemList']),
        ...mapGetters('LanguageOperations', ['getSelectedLanguage']),
        hasData() {
            return this.userStatisticLocal.length > 0;
        },
        keyComputed() {
            const key = this.statisticAttribute.group.selectedType.key;
            switch (key) {
                case 'users':
                    return {
                        groupTypeKey: key,
                        detailKey: 'userDetails',
                        nameKey: 'displayName'
                    };
                case 'catalogs':
                    return {
                        groupTypeKey: key,
                        detailKey: 'catalogDetails',
                        nameKey: 'name'
                    };
                case 'knowledgeUnits':
                    return {
                        groupTypeKey: key,
                        detailKey: 'knowledgeUnitDetails',
                        nameKey: 'question'
                    };
                case 'tasks':
                    return {
                        groupTypeKey: key,
                        detailKey: 'taskDetails',
                        nameKey: 'name'
                    };
            }
            return {
                groupTypeKey: '',
                detailKey: '',
                nameKey: ''
            };
        },
        translatedTitle() {
            if (this.isAdminStatistic) {
                return this.$t('User-Reports');
            } else {
                return this.$t('Persönliche Reports');
            }
        },
        isBackable() {
            return this.$route.name === 'statistics';
        }
    },
    methods: {
        ...mapActions('StatisticOperations', ['GET_USERSTATISTIC', 'GET_ADMINSTATISTIC', 'GET_GROUPITEM_LIST', 'EXPORT_STATISTICS']),
        serializeData() {
            const interval = this.statisticAttribute.interval;
            let endDate = moment(interval.endDate).format('YYYY-MM-DD');
            let startDate = moment(interval.startDate).format('YYYY-MM-DD');
            const year = moment(interval.startDate).year();

            if (interval.selectedType.code === 'WEEK') {
                endDate = moment(startDate).add(6, 'days').format('YYYY-MM-DD');
            }

            return {
                interval: {
                    startDate: startDate,
                    endDate: endDate,
                    type: interval.selectedType.code,
                    groupDays: interval.groupDays.toString(),
                    month: interval.month,
                    year: year
                },
                groupBy: this.statisticAttribute.group.selectedType.code,
                filter: this.filterList,
                language: this.getSelectedLanguage.shortLang,
                list: ['USERS', 'CATALOGS', 'KNOWLEDGEUNITS', 'TASKS']
            };
        },
        getFilterStatistics() {
            if (this.isAdminStatistic) {
                return this.GET_ADMINSTATISTIC(this.serializeData()).then(this.handleLocalData);
            } else {
                return this.GET_USERSTATISTIC(this.serializeData()).then(this.handleLocalData);
            }
        },
        getStatistic() {
            if (this.isAdminStatistic) {
                return this.GET_ADMINSTATISTIC(this.serializeData()).then(() => this.gettingGroupItem(this.serializeData()));
            } else {
                return this.GET_USERSTATISTIC(this.serializeData()).then(() => this.gettingGroupItem(this.serializeData()));
            }
        },
        exportStatisticsAsExcel() {
            let request = this.serializeData();
            if (request.interval.type === 'WEEK') {
                const curr = new Date(request.interval.startDate);
                const firstWeekDate = new Date(curr.setDate(curr.getDate() - curr.getDay()));
                const finalWeekDate = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7));
                request.interval.groupDays = 8;
                request.interval.startDate = moment(firstWeekDate).format('YYYY-MM-DD');
                request.interval.endDate = moment(finalWeekDate).format('YYYY-MM-DD');
            }
            if (request.interval.type === 'MONTH') {
                const date = new Date(request.interval.startDate);
                const firstDayMonth = new Date(date.getFullYear(), date.getMonth(), 1);
                const lastDayMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                request.interval.startDate = moment(firstDayMonth).format('YYYY-MM-DD');
                request.interval.endDate = moment(lastDayMonth).format('YYYY-MM-DD');
                request.interval.groupDays = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
            }
            if (request.interval.type === 'YEAR') {
                const firstDayYear = new Date(new Date(request.interval.year.toString()).getFullYear(), 0, 1);
                const lastDayYear = new Date(new Date(request.interval.year.toString()).getFullYear(), 11, 31);
                request.interval.startDate = moment(firstDayYear).format('YYYY-MM-DD');
                request.interval.endDate = moment(lastDayYear).format('YYYY-MM-DD');
                request.interval.groupDays = 365;
                request.interval.month = '';
            }
            request.interval.type = this.isGroupedExport ? 'RANGE' : request.interval.type;
            this.EXPORT_STATISTICS(request).then((response) => {
                saveAs(
                    response,
                    `${moment().format('YYYY-MM-DD')}_${this.$t(this.statisticAttribute.group.selectedType.title)}_${request.interval.startDate}_${
                        request.interval.endDate
                    }.xlsx`
                );
            });
        },
        gettingGroupItem({ filter, ...rest }) {
            this.GET_GROUPITEM_LIST({
                filter: {
                    users: filter.users ? filter.users.map(({ id }) => ({ id })) : [],
                    catalogs: filter.catalogs ? filter.catalogs.map(({ id }) => ({ id })) : [],
                    knowledgeUnits: filter.knowledgeUnits ? filter.knowledgeUnits.map(({ id }) => ({ id })) : [],
                    tasks: filter.tasks ? filter.tasks.map(({ id }) => ({ id })) : []
                },
                ...rest
            }).then(this.handleLocalData);
        },
        handleLocalData() {
            const hasGroupTypeKeys = this.getUserStatistic.map((data) => data[this.keyComputed.groupTypeKey]).flat().length;
            if (hasGroupTypeKeys > 0) {
                const headers = this.getUserStatistic
                    .map((data) => data[this.keyComputed.groupTypeKey])
                    .flat()
                    .map((data) => data[this.keyComputed.detailKey])
                    .filter((header, index, arr) => arr.map((data) => data.id).indexOf(header.id) === index);

                const stats = this.getUserStatistic.map((data) => {
                    const dataMap = headers.map((h) => {
                        const value = data[this.keyComputed.groupTypeKey].find((findData) => findData[this.keyComputed.detailKey].id === h.id);
                        return value === undefined ? 0 : value.votes.total;
                    });
                    return [`KW-${data.interval.week} ${data.interval.startDate} ${data.interval.endDate}`, ...dataMap];
                });

                this.mappedDataList = this.getUserStatistic.map((data) => {
                    const dataMap = headers.map((h) => {
                        const value = data[this.keyComputed.groupTypeKey].find((findData) => findData[this.keyComputed.detailKey].id === h.id);
                        return value === undefined ? 0 : { ...value };
                    });
                    return [`KW-${data.interval.week} ${data.interval.startDate} ${data.interval.endDate}`, ...dataMap];
                });

                this.userStatisticLocal = [['Interval', ...headers.map((header) => header[this.keyComputed.nameKey])], ...stats];
            } else {
                this.userStatisticLocal = [];
            }
        },
        // EMITED FUNCTIONS
        intervalByEmit(interval) {
            this.filterList = {
                users: [],
                tasks: [],
                knowledgeUnits: [],
                catalogs: [],
                userGroups: [],
                adminGroups: []
            };
            this.statisticAttribute.interval.selectedType = interval;
        },
        groupByEmit(group) {
            this.statisticAttribute.group.selectedType = group;
        },
        dayDividedEmit(dayDivided) {
            this.statisticAttribute.interval.groupDays = dayDivided;
        },
        startDateEmit(val) {
            this.filterList = {
                users: [],
                tasks: [],
                knowledgeUnits: [],
                catalogs: [],
                userGroups: [],
                adminGroups: []
            };
            this.statisticAttribute.interval.startDate = val;
            const date = moment(val);
            this.statisticAttribute.interval.month = date.month() + 1;
            this.statisticAttribute.interval.year = date.year();
        },
        endDateEmit(val) {
            this.filterList = {
                users: [],
                tasks: [],
                knowledgeUnits: [],
                catalogs: [],
                userGroups: [],
                adminGroups: []
            };
            this.statisticAttribute.interval.endDate = val;
        }
    },
    mounted() {
        if (!this.isAdminStatistic) {
            this.statisticAttribute.group.type.splice(0, 1);
            this.statisticAttribute.group.selectedType = {
                title: 'Catalogs',
                code: 'CATALOGS',
                key: 'catalogs'
            };
        } else {
            this.intervalByEmit({ title: 'Day', code: 'DAY', key: 'users' });
        }
        if (Object.keys(this.$route.params).length > 0) {
            this.statisticAttribute.group.selectedType = {
                title: 'Tasks',
                code: 'TASKS',
                key: 'tasks'
            };
            this.statisticAttribute.interval.selectedType = {
                title: 'Range',
                code: 'RANGE'
            };
            this.startDateEmit(moment(this.$route.params.startDate).format('YYYY-MM-DD'));
            this.endDateEmit(moment(this.$route.params.endDate).format('YYYY-MM-DD'));
            this.dayDividedEmit(1);
        }
    },
    beforeMount() {
        Promise.all([this.BUILD_USER_MAPS(), this.BUILD_ADMIN_MAPS(), this.GET_EXPLORER_CONFIG()]);
    }
};
</script>

<style scoped>
.export-checkbox {
    max-width: 25%;
    margin: 0 auto;
    height: 30px;
}
</style>
