import axios from '../../plugins/Axios/axios';
import Vue from 'vue';

const state = {
    /**
     * Generic
     */
    styles: {},
    backgroundImage: '',
    logo: '',
    impressum: '',
    dataProtection: '',
    domainName: '',
    companyName: '',
    /**
     * Menu
     */
    menuPresets: {},
    /**
     * Server
     */
    serverProperties: {},
    /**
     * Templates
     */
    defaultLanguage: {},
    languages: [],
    templates: [],
    selectedLanguage: {},
    /**
     * Scheduled
     */
    scheduledTemplates: [],
    scheduledTemplateAvailabileFields: [],
    selectedScheduledTemplate: {},
    /**
     * Options
     */
    mailOptions: {}
};

const getters = {
    getMenuPresets(state) {
        return state.menuPresets;
    },
    getCompanyName(state) {
        return state.companyName;
    },
    getDomainName(state) {
        return state.domainName;
    },
    getBackgroundImageStyle(state) {
        return {
            'background-image': `url(${state.backgroundImage})`,
            'image-resolution': '72dpi'
        };
    },
    getBackgroundImage(state) {
        return state.backgroundImage;
    },
    getLogo(state) {
        return state.logo;
    },
    getStyles(state) {
        return state.styles;
    },
    getImpressum(state) {
        return state.impressum;
    },
    getDataProtection(state) {
        return state.dataProtection;
    },
    getDefaultLanguage(state) {
        return state.defaultLanguage;
    },
    getSelectedLanguage(state) {
        return state.selectedLanguage;
    },
    getSelectedTemplate(state) {
        return state.selectedTemplate;
    },
    getServerProperties(state) {
        return state.serverProperties;
    },
    getLanguages(state) {
        return state.languages;
    },
    getTemplates(state) {
        return state.templates;
    },
    getScheduledTemplates(state) {
        return state.scheduledTemplates;
    },
    getScheduledTemplateAvailabileFields(state) {
        return state.scheduledTemplateAvailabileFields;
    },
    getSelectedScheduledTemplate(state) {
        return state.selectedScheduledTemplate;
    },
    getMailOptions(state) {
        return state.mailOptions;
    }
};

const actions = {
    SET_FONTS({ commit }, font) {
        return axios.post(`/fonts`, font).then((response) => response.data.data);
    },
    GET_FONTS() {
        return axios.get(`/fonts`).then((response) => response.data.data);
    },
    TEST_CERTIFICATE_WITH_FONT({ commit }, font) {
        return axios
            .get(`/certificate?font=${font}`, {
                responseType: 'blob'
            })
            .then((response) => response.data);
    },
    GET_MENU_CONFIG({ commit }) {
        return axios
            .get(`/config/menu/presets`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateMenuPresets', response);
                return response;
            });
    },
    SET_MENU_CONFIG({ commit }, data) {
        return axios
            .post(`/config/menu/presets`, data)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateMenuPresets', response);
                return response;
            });
    },
    GET_SIGNATURE({
        getters: {
            getSelectedLanguage: { alpha2: lang }
        }
    }) {
        return axios.get(`/mail/template/${lang}/signature`).then((response) => response.data.data);
    },
    UPDATE_SIGNATURE(
        {
            getters: {
                getSelectedLanguage: { alpha2: lang }
            },
            dispatch
        },
        request
    ) {
        return axios
            .patch(`/mail/template/${lang}/signature`, request)
            .then((response) => response.data.data)
            .then((response) => {
                dispatch('GET_TEMPLATES', lang);
                return response;
            });
    },
    SEND_TEST_MAIL({ commit }, data) {
        return axios.post(`/mail/template/test`, data);
    },
    UPLOAD_LOGO({ commit }, data) {
        let formData = new FormData();
        formData.append('file', data);
        return axios.post(`/logo`, formData).then((response) => {
            commit('updateLogo', response.data.data.fileDownloadUri);
        });
    },
    UPLOAD_BACKGROUND_IMAGE({ commit }, data) {
        let formData = new FormData();
        formData.append('file', data);
        return axios.post(`/backgroundImage`, formData).then((response) => {
            commit('updateBackgroundImage', response.data.data.fileDownloadUri);
        });
    },
    CREATE_SCHEDULED_TEMPLATE_TYPE({ commit }, data) {
        return axios
            .post(`/mail/schedule`, data)
            .then((response) => response.data.data)
            .then((response) => commit('updateScheduledTemplates', response));
    },
    CREATE_SCHEDULED_TEMPLATE({ commit }, data) {
        return axios.post(`/mail/schedule/${data.id}`, data);
    },
    UPDATE_SCHEDULED_TEMPLATE({ commit }, data) {
        return axios
            .patch(`mail/schedule/${data.id}`, data)
            .then((response) => response.data.data)
            .then((response) => commit('updateScheduledTemplates', response));
    },
    GET_GENERIC_SETTINGS({ commit }) {
        return axios.get(`/getsettings?domain=${window.location.hostname}`).then((response) => {
            commit('updateCompanyName', response.data.data.companyName);
            commit('updateBackgroundImage', response.data.data.backgroundImage);
            commit('updateLogo', response.data.data.logo);
            commit('updateDomainName', response.data.data.domainName);
            commit('updateStyles', response.data.data.styles);
            commit('updateImpressum', response.data.data.impressum);
            commit('updateDataProtection', response.data.data.dataProtection);
        });
    },
    GET_GENERIC_CUSTOMER_SETTINGS({ commit }) {
        return axios.get(`/settings`).then((response) => {
            commit('updateCompanyName', response.data.data.companyName);
            commit('updateBackgroundImage', response.data.data.backgroundImage);
            commit('updateLogo', response.data.data.logo);
            commit('updateDomainName', response.data.data.domainName);
            commit('updateStyles', response.data.data.styles);
            commit('updateImpressum', response.data.data.impressum);
            commit('updateDataProtection', response.data.data.dataProtection);
        });
    },
    GET_SERVER_PROPERTIES({ commit }) {
        return axios.get('/mail/serverDetails').then((response) => commit('updateServerProperties', response.data.data));
    },
    GET_MAIL_OPTIONS({ commit }) {
        return axios.get(`/mail/opt/customer`).then((response) => commit('updateMailOptions', response.data.data));
    },
    GET_LANGUAGES({ commit }) {
        return axios.get('/language').then((response) => {
            commit('updateDefaultLanguage', response.data.data.defaultLanguage);
            commit('updateLanguages', response.data.data.languages);
        });
    },
    GET_TEMPLATES({ commit }, language) {
        return axios.get(`/mail/template/${language}`).then((response) => commit('updateTemplates', response.data.data.templateList));
    },
    GET_SCHEDULED_TEMPLATES({ commit }) {
        return axios.get(`mail/schedule`).then((response) => {
            commit('updateScheduledTemplateAvailabileFields', response.data.data.availableFields);
            commit('updateScheduledTemplates', response.data.data.scheduledMailTypes);
        });
    },
    GET_SCHEDULED_TEMPLATE({ commit }, templateId) {
        return axios.get(`mail/schedule/${templateId}`).then((response) => commit('updateSelectedScheduledTemplate', response.data.data));
    },
    SET_GENERIC_SETTINGS({ commit }, settings) {
        return axios.post(`/settings`, settings);
    },
    SET_SELECTED_LANGUAGE({ commit }, language) {
        commit('updateSelectedLanguage', language);
    },
    SET_TEMPLATE({ commit }, template) {
        return axios
            .post('/mail/template', template)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateTemplates', response);
            });
    },
    SET_SERVER_PROPERTIES({ commit }, properties) {
        return axios.post('/mail/serverDetails', properties);
    },
    SET_MAIL_OPTIONS({ commit }, data) {
        return axios.post(`/mail/opt/customer`, data);
    },
    DELETE_TEMPLATE({ commit }, data) {
        return axios.delete(`/mail/template/${data.language}/type/${data.template}`);
    },
    DELETE_SCHEDULED_MAIL_TEMPLATE({ commit }, template) {
        return axios.delete(`/mail/schedule/${template.id}`);
    },
    DELETE_SERVER_PROPERTIES({ commit }) {
        return axios.delete('/mail/serverDetails');
    },
    UPLOAD_CERTIFICATE({ commit }, certificate) {
        let formData = new FormData();
        formData.append('file', certificate);
        return axios
            .post('/certificate', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })
            .then((response) => response.data.data);
    }
};

const mutations = {
    clear: (state) => {
        // state.logo = "";
        state.serverProperties = {};
        state.defaultLanguage = {};
        state.languages = [];
        state.templates = [];
        state.selectedLanguage = {};
        state.scheduledTemplates = [];
        state.scheduledTemplateAvailabileFields = [];
        state.selectedScheduledTemplate = {};
        state.mailOptions = {};
    },
    updateStyles: (state, value) => {
        state.styles = value;
    },
    updateBackgroundImage: (state, value) => {
        Vue.set(state, 'backgroundImage', '');
        Vue.nextTick(() => {
            Vue.set(state, 'backgroundImage', value);
        });
    },
    updateLogo: (state, value) => {
        state.logo = value;
    },
    updateImpressum: (state, value) => {
        state.impressum = value;
    },
    updateDataProtection: (state, value) => {
        state.dataProtection = value;
    },
    updateServerProperties: (state, value) => {
        state.serverProperties = value;
    },
    updateLanguages: (state, value) => {
        state.languages = value;
    },
    updateTemplates: (state, value) => {
        if (Array.isArray(value)) {
            state.templates = value;
        } else {
            state.templates = state.templates.map((template) => {
                if (template.templateName === value.type) {
                    template.message = value.message;
                    template.subject = value.subject;
                }
                return template;
            });
        }
    },
    updateScheduledTemplates: (state, value) => {
        if (Array.isArray(value)) {
            state.scheduledTemplates = value;
        } else {
            if (state.scheduledTemplates.map((template) => template.id).indexOf(value.id) !== -1) {
                state.scheduledTemplates = state.scheduledTemplates.map((template) => {
                    if (template.id === value.id) {
                        return value;
                    }
                    return template;
                });
            } else {
                state.scheduledTemplates.push(value);
            }
        }
    },
    updateSelectedScheduledTemplate: (state, value) => {
        state.selectedScheduledTemplate = value;
    },
    updateScheduledTemplateAvailabileFields: (state, value) => {
        state.scheduledTemplateAvailabileFields = value;
    },
    updateMailOptions: (state, value) => {
        state.mailOptions = value;
    },
    updateSelectedLanguage: (state, value) => {
        state.selectedLanguage = value;
    },
    updateDefaultLanguage: (state, value) => {
        state.defaultLanguage = value;
    },
    updateDomainName: (state, value) => {
        state.domainName = value;
    },
    updateCompanyName: (state, value) => {
        state.companyName = value;
    },
    updateMenuPresets: (state, value) => {
        state.menuPresets = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
