<template>
    <v-select v-model="computedDate" :disabled="textBoxDisabled" prepend-icon="mdi-calendar-blank" :label="label" :items="years" />
</template>

<script>
import moment from 'moment';

export default {
    name: 'picker-date-type-year',
    props: {
        dateText: {
            type: String,
            default: moment().year()
        },
        textBoxDisabled: {
            default: false,
            type: Boolean
        },
        label: {
            type: String,
            default: ''
        }
    },
    computed: {
        computedDate: {
            get() {
                return moment(this.dateText).year();
            },
            set(value) {
                this.$emit('dateDataEmit', moment(`${value}-01-01`).format('YYYY-MM-DD'));
            }
        },
        years() {
            const years = [];
            for (let i = 0; i <= 10; i++) {
                years.push(moment().year() - i);
            }
            return years;
        }
    }
};
</script>
