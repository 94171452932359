<template>
    <v-card>
        <Toolbar
            :toolbarTitle="$t('Übersicht')"
            tooltipMessage="Hier sehen Sie algemeine Statistiken"
            :showMoreButton="false"
            :menus="menus"
            @otherMenuActionEmit="moreActionSelectedEmit"
        />
        <Dashboard :chartMode="chartMode" />
    </v-card>
</template>

<script>
import Dashboard from '../../Components/Dashboard/Dashboard';
import Toolbar from '../../Components/Generic/Toolbar';

export default {
    name: 'archive-tool',
    components: { Dashboard, Toolbar },
    data: () => ({
        chartMode: 'MORECHART',
        menus: [
            {
                title: 'Einzel Statistik',
                actionType: 'COMPACTCHART',
                icon: 'multiline_chart',
                color: 'success'
            },
            {
                title: 'Mehr Statistik',
                actionType: 'MORECHART',
                icon: 'bar_chart',
                color: 'amber darken-1'
            }
        ]
    }),
    methods: {
        moreActionSelectedEmit(menu) {
            this.chartMode = menu;
        }
    }
};
</script>
