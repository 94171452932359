<template>
    <div>
        <draggable v-model="contents" tag="div" handle=".cursor" v-show="contents.length > 0">
            <transition-group name="slide-x-transition" mode="out-in" tag="v-list" class="v-list--two-line">
                <v-list-item xs12 v-for="content in selected" :key="content.id">
                    <v-list-item-action>
                        <v-btn icon @click="handleContent(content)">
                            <v-icon :color="iconColor(content)">mdi-file</v-icon>
                        </v-btn>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{ content.title }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon class="cursor pointer">mdi-cursor-move</v-icon>
                    </v-list-item-action>
                    <v-list-item-action>
                        <v-btn icon @click="remove(content)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </transition-group>
        </draggable>
        <v-list-item v-show="contents.length === 0">
            <v-list-item-content>
                <v-list-item-title class="text-center">{{ $t('Kein Content ausgewählt') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
    name: 'knowledge-content-selection-view',
    components: { draggable },
    props: {
        selected: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        contents: {
            get() {
                return this.selected;
            },
            set(value) {
                this.$emit('selected', value);
            }
        }
    },
    methods: {
        remove({ id }) {
            this.$emit(
                'selected',
                this.selected.filter((content) => content.id !== id)
            );
        },
        iconColor({ state: { stateName } }) {
            switch (stateName) {
                case 'APPROVED':
                    return 'success';
                case 'PENDING':
                    return 'warning';
                case 'DECLINED':
                    return 'error';
            }
            return '';
        },
        handleContent(value) {
            this.$emit('input', [value]);
            this.$emit('switchView', false);
        }
    }
};
</script>
