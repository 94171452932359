<template>
    <v-card>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col :cols="4" v-show="!isFiltered">
                        <PickerDate v-model="startDate" :label="$t('Anfangsdatum')" />
                    </v-col>
                    <v-col :cols="4" v-show="!isFiltered">
                        <PickerDate v-model="endDate" :min="startDate" :label="$t('Enddatum')" :disableStartDate="!isFiltered" />
                    </v-col>
                    <v-col :cols="4" :offset="isFiltered ? 8 : 0">
                        <v-checkbox v-model="isFiltered" :label="$t('Alle Aufgaben')" hide-details />
                    </v-col>
                    <v-col :cols="12" :sm="6" :md="4">
                        <v-card height="100%">
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span class="primary--text">{{ $t('Durschnittlicher Fortschritt') }}</span>
                                    </v-col>
                                    <v-col cols="auto">
                                        <span>{{ `${averageProgress}%` }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col :cols="12" :sm="6" :md="4">
                        <v-card height="100%">
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span class="primary--text">{{ $t('Durschnittlich gesamt Richtig') }}</span>
                                    </v-col>
                                    <v-col cols="auto">
                                        <span>{{ `${averageTotalCorrect}%` }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col :cols="12" :sm="6" :md="4">
                        <v-card height="100%">
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span class="primary--text">{{ $t('Durschnittlich relativ Richtig') }}</span>
                                    </v-col>
                                    <v-col cols="auto">
                                        <span>{{ `${averageRelativeCorrect}%` }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col :cols="12" :sm="6" :md="4">
                        <v-card height="100%">
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span class="primary--text">{{ $t('Gesamt Wissenseinheiten') }}</span>
                                    </v-col>
                                    <v-col cols="auto">
                                        <span>{{ totalKnowledgeUnits }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col :cols="12" :sm="6" :md="4">
                        <v-card height="100%">
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span class="primary--text">{{ $t('Beantwortete Wissenseinheiten') }}</span>
                                    </v-col>
                                    <v-col cols="auto">
                                        <span>{{ totalAnsweredKnowledgeUnits }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col :cols="12" :sm="6" :md="4">
                        <v-card height="100%">
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span class="primary--text">{{ $t('Gesamt Aufgaben') }}</span>
                                    </v-col>
                                    <v-col cols="auto">
                                        <span>{{ filteredTasks.length }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col :cols="12" :sm="6" :md="4">
                        <v-card height="100%">
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span class="primary--text">{{ $t('Bestandene Aufgaben') }}</span>
                                    </v-col>
                                    <v-col cols="auto">
                                        <span>{{ totalPassed }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col :cols="12">
                        <v-row align="center">
                            <v-col>
                                <v-divider />
                            </v-col>
                            <v-col cols="auto">
                                <span class="primary--text">{{ $t('Aufgaben') }}</span>
                            </v-col>
                            <v-col>
                                <v-divider />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-data-iterator
                    :items="filteredTasks"
                    :no-data-text="$t('Keine Aufgaben verfügbar')"
                    :no-results-text="$t('Kein Ergebnis')"
                    :items-per-page="5"
                    :footer-props="{
                        'items-per-page-text': $t('Aufgaben pro Seite'),
                        'items-per-page-options': [5, 10, 15, { value: -1, text: $t('Alle') }]
                    }"
                    :hideDefaultFooter="tasks.length <= 5"
                    :custom-sort="sortData"
                >
                    <template #default="{ items }">
                        <v-row>
                            <v-col v-for="item in items" :key="item.id">
                                <TaskNode :value="item" :showResults="false" />
                            </v-col>
                        </v-row>
                    </template>
                </v-data-iterator>
            </v-container>
        </v-card-text>
    </v-card>
    <!-- </v-dialog> -->
</template>

<script>
import TaskNode from '../../../UserApp/Helpers/TaskNode';
import PickerDate from '../../../Generic/DatePicker/DatePicker';

import UserMixin from '../../Mixins/UserMixin';
import moment from 'moment';

export default {
    name: 'user-task-details',
    mixins: [UserMixin],
    components: { TaskNode, PickerDate },
    data: () => ({
        isFiltered: true,
        startDate: '',
        endDate: '',
        tasks: []
    }),
    watch: {
        startDate(value) {
            const startDate = moment(value);
            const endDate = moment(this.endDate);
            if (startDate > endDate) {
                this.endDate = value;
            }
        }
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        disabled: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        filteredTasks() {
            return !this.isFiltered
                ? this.tasks.filter(
                      (task) => moment(task.startDate).isSameOrAfter(moment(this.startDate)) && moment(task.endDate).isSameOrBefore(moment(this.endDate))
                  )
                : this.tasks;
        },
        totalPassed() {
            return this.filteredTasks.reduce((acc, curr) => acc + (curr.passed ? 1 : 0), 0);
        },
        totalAnsweredKnowledgeUnits() {
            return this.filteredTasks.reduce((acc, curr) => acc + curr.voteKUCount, 0);
        },
        totalKnowledgeUnits() {
            return this.filteredTasks.reduce((acc, curr) => acc + curr.totalKnowledgeUnits, 0);
        },
        averageRelativeCorrect() {
            return this.filteredTasks.length > 0
                ? (this.filteredTasks.reduce((acc, curr) => acc + curr.correctRelativePercentage, 0) / this.filteredTasks.length).toFixed(2)
                : 0;
        },
        averageTotalCorrect() {
            return this.filteredTasks.length > 0
                ? (this.filteredTasks.reduce((acc, curr) => acc + curr.correctTotalPercentage, 0) / this.filteredTasks.length).toFixed(2)
                : 0;
        },
        averageProgress() {
            return this.filteredTasks.length > 0
                ? (this.filteredTasks.reduce((acc, curr) => acc + curr.taskProgress, 0) / this.filteredTasks.length).toFixed(2)
                : 0;
        },
        isUserSelected() {
            return Object.keys(this.value).length > 0;
        }
    },
    methods: {
        findEndDate() {
            const dates = this.tasks.map(({ endDate }) => moment(endDate));
            return moment.max(dates).format('YYYY-MM-DD');
        },
        findStartDate() {
            const dates = this.tasks.map(({ startDate }) => moment(startDate));
            return moment.min(dates).format('YYYY-MM-DD');
        },
        sortData(items) {
            items.sort((a, b) => {
                const ma = moment(a.lastModified).valueOf();
                const mb = moment(b.lastModified).valueOf();
                return ma - mb || isNaN(ma) - isNaN(mb);
            });
            return items;
        },
        close() {
            this.isFiltered = true;
            this.tasks = [];
            this.showDialog = false;
        }
    },
    activated() {
        this.tasks = [];
        this.GET_USER_TASK(this.value).then((response) => {
            this.tasks = response;
            this.startDate = this.findStartDate();
            this.endDate = this.findEndDate();
        });
    }
};
</script>
