<template>
    <div class="fill-height" :class="isDark ? 'theme--dark v-sheet' : 'white'">
        <Toolbar
            tooltipMessage="Hier können Sie alle Ihre unvollständigen und vollständigen Aufgaben anschauen, auch noch können Sie sofort unvollständige Aufgabe beginnen zu lösen."
            :toolbarTitle="$t('Aufgaben')"
        />

        <Task @startTask="startQuiz" :activeTaskArchiveList="getUserIncompleteTaskList" :inactiveTaskArchiveList="getUserCompleteTaskList" type="TASK" />
    </div>
</template>

<script>
import Toolbar from '../../Components/Generic/Toolbar';
import QuizMixin from '../../Components/UserApp/Mixins/QuizMixin';
import Task from '../../Components/UserApp/TaskArchive';

export default {
    name: 'user-tasks',
    components: {
        Toolbar,
        Task
    },
    mixins: [QuizMixin],
    data: () => ({
        activeTaskList: [],
        inactiveTaskList: [],
        showQuizResult: false,
        quizResult: {},
        activeTab: null
    }),
    computed: {
        isValidQuiz() {
            return (
                this.getSelectedTaskArchive &&
                this.getSelectedTaskArchive.data &&
                Object.keys(this.getSelectedTaskArchive.data).length > 0 &&
                this.getUserIncompleteTaskList.map((task) => task.id).includes(this.getSelectedTaskArchive.data.id) &&
                this.getActiveTab == 0
            );
        },
        isDark() {
            return this.$vuetify.theme.dark;
        }
        // selectedTask: {
        // 	get() {
        // 		return this.getSelectedTaskArchive.data;
        // 	},
        // 	set(value) {
        // 		this.SELECT_TASK(value);
        // 	}
        // }
    },
    methods: {
        startQuiz(task) {
            this.SELECT_TASK(task);
            // this.OPEN_DRAWER(false).then(() => {
            this.$router.push({ name: 'appQuiz' });
            // });
        }
    }
};
</script>
