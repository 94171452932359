<template>
    <v-card>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col :cols="12">
                        <v-slider
                            class="mt-5"
                            :min="computedValue.minimum"
                            :max="computedValue.maximum"
                            :step="computedValue.steps"
                            thumb-label="always"
                            :value="computedValue.text"
                            hide-details
                            @end="handleInput('text', $event)"
                            :label="$t('Lösung')"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            color="accent"
                            :label="$t('Minimum')"
                            type="number"
                            autocomplete="new-password"
                            :value="computedValue.minimum"
                            hide-details
                            @input="handleInput('minimum', $event)"
                            :min="0"
                        />
                    </v-col>
                    <v-col>
                        <v-text-field
                            color="accent"
                            :label="$t('Maximum')"
                            type="number"
                            autocomplete="new-password"
                            :value="computedValue.maximum"
                            hide-details
                            @input="handleInput('maximum', $event)"
                            :min="0"
                        />
                    </v-col>
                    <v-col>
                        <v-text-field
                            color="accent"
                            :label="$t('Schritte')"
                            type="number"
                            autocomplete="new-password"
                            :value="computedValue.steps"
                            @input="handleInput('steps', $event)"
                            hide-details
                            :min="1"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: 'picker',
    data: () => ({
        loaded: false
    }),
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },
    watch: {
        computedValue({ text: answer, steps }) {
            if (!Number.isNaN(answer) && !Number.isNaN(steps) && Number.isInteger(answer) && Number.isInteger(steps)) {
                const rest = answer % steps;
                if (rest !== 0) {
                    const calc = answer - rest;
                    this.$emit('input', [{ ...this.computedValue, text: calc }]);
                }
            }
        }
    },
    computed: {
        computedValue() {
            if (this.value.length > 0) {
                return this.value[0];
            }
            return {
                text: 0,
                isAnswer: true,
                minimum: 0,
                maximum: 100,
                steps: 1,
                sequenceNo: 1,
                mediaId: '',
                fileURL: '',
                fileName: '',
                fileType: '',
                size: 0,
                answeredOrder: 0
            };
        }
    },
    methods: {
        handleInput(key, value) {
            this.$emit('input', [{ ...this.computedValue, [key]: value }]);
        }
    },
    activated() {
        this.$emit('input', [
            {
                id: parseInt(performance.now()).toString(),
                text: 0,
                isAnswer: true,
                minimum: 0,
                maximum: 100,
                steps: 1,
                sequenceNo: 1,
                mediaId: '',
                fileURL: '',
                fileName: '',
                fileType: '',
                size: 0,
                answeredOrder: 0
            }
        ]);
    }
};
</script>
