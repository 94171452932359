import Users from '../../views/User/User/Users.vue';
import Admins from '../../views/User/Admin/Admins.vue';

import { superAdmin, admin } from '../Roles';

export default [
    /**
     * User
     * @description
     */
    {
        path: '/Users',
        name: 'users',
        component: Users,
        meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionSet: ['USER_MANAGEMENT'],
            requiresRole: true,
            roleSet: [superAdmin, admin],
            navigation: {
                type: { name: 'Groups', groupId: 'Nutzermanagement' },
                priority: 0,
                icon: 'mdi-account',
                name: 'Lernende',
                navigationKey: 'USERS'
            }
        }
    },
    /**
     * Admins
     * @description
     */
    {
        path: '/Admins',
        name: 'admins',
        component: Admins,
        meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionSet: ['ADMIN_MANAGEMENT'],
            requiresRole: true,
            roleSet: [superAdmin, admin],
            navigation: {
                type: { name: 'Groups', groupId: 'Nutzermanagement' },
                priority: 0,
                icon: 'mdi-account-outline',
                name: 'Admin / Lehrende',
                navigationKey: 'ADMINS'
            }
        }
    }
];
