<template>
    <v-layout column>
        <v-flex justify-self-center align-self-center>
            {{ $t('Keine Daten') }}
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    name: 'exception-chart-details'
};
</script>
