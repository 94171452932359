import { mapActions, mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('StaticFileManagerOperations', ['getStorage', 'getTree', 'getParent', 'getView', 'getFiles', 'getFolder', 'getFolderMedia'])
    },
    methods: {
        ...mapActions('StaticFileManagerOperations', [
            'CREATE_FOLDER',
            'GET_ROOT_FOLDER',
            'GET_FOLDER_LIST',
            'GET_SUB_FOLDER_BY_ID',
            'UPLOAD_MEDIA',
            'GET_MEDIA_BY_ID',
            'DELETE_MEDIA_BY_ID',
            'DELETE_FOLDER_BY_ID',
            'UPDATE_FOLDER',
            'UPDATE_MEDIA',
            'MOVE_MEDIA',
            'MOVE_FOLDER',
            'GET_STORAGE',
            'GET_TREE',
            'GET_ALL_MEDIA',
            'SET_VIEW'
        ]),
        isFilemanagerType(value) {
            if (value.hasOwnProperty('fileURL')) {
                return 'FILE';
            }
            return 'FOLDER';
        },
        calculateTreePath(value) {
            let currentNode = value;
            let path = [];
            if (Object.keys(currentNode).length > 0) {
                while (currentNode) {
                    path.unshift({ ...currentNode });
                    currentNode = this.getParent(currentNode);
                }
            }
            return path;
        }
    }
};
