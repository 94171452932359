<template>
    <v-card flat outlined>
        <v-container>
            <v-row :cols="12">
                <v-container>
                    <v-row align="center">
                        <v-col cols="auto">
                            <UserProfile :value="value.user" :displayName="false" />
                        </v-col>
                        <v-spacer />
                        <v-col cols="auto">
                            <v-menu left offset-x>
                                <template #activator="{ on, attrs }">
                                    <v-btn icon v-on="on" v-bind="attrs">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item @click="quote">
                                        <v-list-item-icon>
                                            <v-icon>mdi-comment-quote</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ $t('Zitieren') }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="edit" v-if="isCommentOwner">
                                        <v-list-item-icon>
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ $t('Bearbeiten') }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="remove" v-if="isCommentOwner">
                                        <v-list-item-icon>
                                            <v-icon>mdi-delete</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ $t('Löschen') }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-container>
            </v-row>
            <v-row>
                <v-col :cols="12">
                    <v-divider />
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="12">
                    <div class="ck ck-content" v-html="value.text"></div>
                    <div style="clear: both"></div>
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="12">
                    <v-divider />
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="auto">
                    <span class="text-caption">{{ `${$t('Erstellt')}: ${formatDate(value.createdAt)}` }}</span>
                </v-col>
                <v-spacer />
                <v-col cols="auto" v-if="isEdited(value)">
                    <span class="text-caption">{{ `${$t('Zuletzt bearbeitet')}: ${formatDate(value.lastUpdated)}` }}</span>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import moment from 'moment';
import UserProfile from '../../Generic/Profile/UserProfile';

import ForumMixin from '../Mixins/ForumMixin';

export default {
    name: 'thread-answer',
    components: { UserProfile },
    mixins: [ForumMixin],
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        isCommentOwner() {
            return this.value.user.id === this.getLoggedInAdmin.id;
        },
        isUpdated() {
            return this.value.createdAt !== this.value.lastUpdated;
        }
    },
    methods: {
        isEdited({ createdAt, lastUpdated }) {
            return this.formatDate(createdAt) !== this.formatDate(lastUpdated);
        },
        formatQuote() {
            return `<p><blockquote><p>${this.formatDate(this.value.createdAt)}</p><p>${this.value.text}</p><p><strong>${
                this.value.user.displayName
            }</strong></p></blockquote></p>`;
        },
        quote() {
            this.$emit('quote', this.formatQuote(this.value));
        },
        edit() {
            this.$emit('edit', true);
        },
        remove() {
            this.SET_SELECTED_ANSWER(this.value);
            this.$emit('delete', this.value);
        },
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD HH:mm');
        }
    }
};
</script>
