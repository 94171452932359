<template>
    <v-container fluid pa-0 ma-0>
        <v-row>
            <v-col :cols="12">
                <v-text-field readonly :label="$t('Link')" :value="value" hide-details />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: 'summary-link',
    props: {
        value: {
            type: String,
            default: ''
        }
    }
};
</script>
