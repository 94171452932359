<template>
    <v-card flat>
        <v-tabs v-model="tabs" show-arrows mandatory slider-color="accent">
            <v-tab key="CONTENT">{{ $t('Content') }}</v-tab>
            <v-tab key="LINKS">{{ $t('Content verknüpfen') }}</v-tab>
        </v-tabs>
        <v-card-text style="overflow-x: hidden">
            <v-tabs-items v-model="tabs" touchless>
                <v-tab-item key="CONTENT">
                    <v-container>
                        <v-row>
                            <v-col :cols="12">
                                <v-card height="100%">
                                    <v-card-text>
                                        <v-text-field color="accent" autocomplete="new-password" v-model="title" attach :label="$t('Titel')" />
                                        <Editor v-model="content" />
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col :cols="12">
                                <v-card height="100%">
                                    <v-card-text>
                                        <TagGenerator v-model="tags" />
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col :xs="12" :sm="6">
                                <v-card height="100%">
                                    <v-card-text>
                                        <v-switch
                                            color="accent"
                                            v-model="viewTillEnd"
                                            :label="$t('Video & Audio Medien müssen konsumiert werden')"
                                            :hint="
                                                $t(
                                                    'Wenn diese Option eingeschaltet ist, müssen Nutzer Videos und/oder Audio bis zum ende gesehen bzw. gehört haben, damit der Nutzer die Wissenseinheit bearbeiten kann'
                                                )
                                            "
                                            persistent-hint
                                        />
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col :xs="12" :sm="6">
                                <v-card height="100%">
                                    <v-card-text>
                                        <v-switch
                                            color="accent"
                                            v-model="disableSkipping"
                                            :label="$t('Video & Audio Medien können nicht übersprungen werden')"
                                            :hint="$t('Wenn diese Option eingeschaltet ist, kann der Nutzer Videos und/oder Audio nicht überspringen')"
                                            :disabled="!viewTillEnd"
                                            persistent-hint
                                        />
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="LINKS">
                    <KnowledgeContentContentSelector v-model="knowledgeContentLinks" :disabledContent="[value]" />
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
        <v-card-actions>
            <v-btn block :disabled="!valid" :color="submitButtonColor" @click="submit">
                {{ submitButtonText }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import Editor from '../Generic/TextEditor';
import TagGenerator from '../Generic/TagGenerator';
import KnowledgeContentContentSelector from './Helpers/KnowledgeContentContentSelector';

import KnowledgeContentMixin from './Mixins/KnowledgeContentMixin';

export default {
    name: 'add-update-knowledge-content',
    mixins: [KnowledgeContentMixin],
    components: {
        TagGenerator,
        Editor,
        KnowledgeContentContentSelector
    },
    data: () => ({
        id: '',
        title: '',
        knowledgeContentLinks: [],
        tags: '',
        content: '',
        viewTillEnd: false,
        disableSkipping: false,
        folderId: '',
        tabs: 0
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        type: {
            type: String,
            default: 'ADD'
        }
    },
    watch: {
        viewTillEnd(value) {
            if (!value) {
                this.disableSkipping = false;
            }
        }
    },
    computed: {
        submitButtonText() {
            switch (this.type) {
                case 'ADD':
                    return this.$t('Content erstellen');
                case 'UPDATE':
                    return this.$t('Content bearbeiten');
            }
            return '';
        },
        submitButtonColor() {
            switch (this.type) {
                case 'ADD':
                    return 'success';
                case 'UPDATE':
                    return 'warning';
            }
            return '';
        },
        valid() {
            return this.content.trim() !== '' && this.title.trim() !== '';
        }
    },
    methods: {
        reset() {
            this.id = '';
            this.title = '';
            this.knowledgeContentLinks = [];
            this.tags = '';
            this.content = '';
            this.viewTillEnd = false;
            this.disableSkipping = false;
        },
        deserialize(value) {
            this.$nextTick(() => {
                Object.entries(value).forEach(([key, val]) => {
                    if (this.$data.hasOwnProperty(key)) {
                        this.$data[key] = val;
                    }
                });
            });
        },
        serialize() {
            return {
                ...(this.type !== 'ADD' && { id: this.id }),
                folderId: this.type === 'ADD' ? (this.value && this.value.id ? this.value.id : '') : this.folderId,
                title: this.title,
                knowledgeContentLinks: this.knowledgeContentLinks,
                viewTillEnd: this.viewTillEnd,
                disableSkipping: this.disableSkipping,
                tags: this.tags,
                content: this.content,
                isDraft: false
            };
        },
        submit() {
            switch (this.type) {
                case 'ADD':
                    return this.CREATE_KNOWLEDGE_CONTENT(this.serialize())
                        .then((response) => {
                            this.$emit('input', response);
                            this.$emit('onCreate', response);
                        })
                        .then(() => this.close());
                case 'UPDATE':
                    return this.UPDATE_KNOWLEDGE_CONTENT(this.serialize())
                        .then((response) => {
                            this.$emit('input', response);
                            this.$emit('onUpdate', response);
                        })
                        .then(() => this.close());
            }
            return;
        },
        close() {
            this.SET_VIEW({ view: '', data: {}, type: '' });
        }
    },
    activated() {
        this.reset();
        if (this.type == 'UPDATE') {
            this.deserialize(this.value);
        }
    }
};
</script>
