<template>
    <v-container>
        <draggable v-model="computedItems" tag="div" handle=".cursor">
            <transition-group name="slide-x-transition" mode="out-in" tag="v-row" class="row">
                <v-col :cols="12" v-for="(option, index) in computedItems" :key="option.id">
                    <v-card tile :key="`option-${option.id}`">
                        <v-toolbar flat dark dense color="secondary">
                            <v-toolbar-title class="text-body-2">{{ `${$t('Sequenz')}-${index + 1}` }}</v-toolbar-title>
                            <v-spacer />
                            <v-btn icon @click="setMedia(option)">
                                <v-icon>mdi-image</v-icon>
                            </v-btn>
                            <v-btn icon @click="remove(option)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <v-row align="center">
                                <v-col>
                                    <v-row>
                                        <v-slide-x-transition mode="out-in">
                                            <v-col cols="auto" v-show="option.fileURL">
                                                <KnowledgeUnitOptionMedia :value="option" @preview="handlePreview" @input="replace" />
                                            </v-col>
                                        </v-slide-x-transition>
                                        <v-col>
                                            <v-textarea
                                                :value="option.text"
                                                @input="handleInput(option, 'text', $event)"
                                                no-resize
                                                :label="$t('Text')"
                                                outlined
                                                hide-details
                                            />
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="auto">
                                    <v-icon class="cursor">mdi-cursor-move</v-icon>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </transition-group>
        </draggable>
    </v-container>
</template>

<script>
import draggable from 'vuedraggable';
import KnowledgeUnitOptionMedia from '../KnowledgeUnitOptionMedia';

export default {
    name: 'sequence',
    components: {
        draggable,
        KnowledgeUnitOptionMedia
    },
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        computedItems: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        handlePreview(option) {
            this.$emit('preview', option);
        },
        setMedia(option) {
            this.$emit('media', option);
        },
        remove(option) {
            this.$emit(
                'input',
                this.value.filter((item) => option !== item)
            );
        },
        replace(option) {
            this.$emit(
                'input',
                this.value.map((item) => {
                    if (item.id === option.id) {
                        return option;
                    }
                    return item;
                })
            );
        },
        handleInput(option, key, value) {
            this.$emit(
                'input',
                this.value.map((item) => {
                    if (item === option) {
                        let copy = { ...item, [key]: value };
                        return copy;
                    }
                    return item;
                })
            );
        }
    },
    activated() {
        this.$emit('input', [
            {
                id: parseInt(performance.now()).toString(),
                text: '',
                isAnswer: true,
                minimum: 0,
                maximum: 100,
                steps: 1,
                sequenceNo: 1,
                mediaId: '',
                fileURL: '',
                fileName: '',
                fileType: '',
                size: 0,
                answeredOrder: 0
            }
        ]);
    }
};
</script>

<style scoped>
.cursor {
    cursor: move;
}
</style>
