import News from '../../views/News/News';
import NewsEditor from '../../views/News/NewsEditor';
import NewsAdd from '../../views/News/AddNews';
import NewsDelete from '../../views/News/DeleteNews';
import NewsUpdate from '../../views/News/EditNews';

import { superAdmin, admin, user } from '../Roles';

export default [
    /**
     * News
     * @description
     */
    {
        path: '/News',
        name: 'landingPage',
        component: News,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin, admin, user],
            navigation: {
                type: { name: 'General', groupId: '' },
                priority: 0,
                icon: 'mdi-home',
                name: 'Hauptseite'
            }
        }
    },
    {
        path: '/News/Editor/',
        name: 'newsEditor',
        component: NewsEditor,
        meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionSet: ['GET_NEWS'],
            requiresRole: true,
            roleSet: [superAdmin, admin],
            navigation: {
                type: { name: 'SingleGroup', groupId: 'Lernmanagement' },
                priority: 0,
                icon: 'mdi-newspaper',
                name: 'Newstool',
                navigationKey: 'NEWS_TOOL'
            }
        }
    },
    {
        path: '/News/Editor/Add',
        name: 'newsAdd',
        component: NewsAdd,
        meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionSet: ['ADD_NEWS'],
            requiresRole: true,
            roleSet: [superAdmin, admin]
        }
    },
    {
        path: '/News/Editor/Update',
        name: 'newsUpdate',
        component: NewsUpdate,
        meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionSet: ['UPDATE_NEWS'],
            requiresRole: true,
            roleSet: [superAdmin, admin]
        }
    },
    {
        path: '/News/Editor/Delete',
        name: 'newsDelete',
        component: NewsDelete,
        meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionSet: ['DELETE_NEWS'],
            requiresRole: true,
            roleSet: [superAdmin, admin]
        }
    }
];
