import CustomerManager from '../../views/CustomerManager/CustomerManager';

import { root, sales } from '../Roles';

export default [
    {
        path: '/Customers',
        name: 'customers',
        component: CustomerManager,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [root, sales],
            navigation: {
                type: { name: 'General', groupId: '' },
                priority: 0,
                icon: 'mdi-account-supervisor-circle-outline',
                name: 'Manager',
                navigationKey: 'CUSTOMER_MANAGER'
            }
        }
    }
];
