import axios from '../../../plugins/Axios/axios';

const state = {
    selectedKnowledgeUnit: {
        itemType: 'KNOWLEDGEUNIT',
        itemTitle: 'Wissenseinheit',
        path: '/Authoringtool/KnowledgeUnit',
        data: {}
    }
};

const getters = {
    getSelectedKnowledgeUnit(state) {
        return state.selectedKnowledgeUnit;
    }
};

const actions = {
    GET_KNOWLEDGEUNITS_BY_CATALOG({ commit }, { id: catalogId }) {
        return axios.get(`/catalogs/${catalogId}/knowledgeUnits`).then((response) => response.data.data);
    },
    GET_KNOWLEDGEUNIT_LIST({ commit }, request) {
        return axios
            .get(`cataloggroups/${request.catalogGroupId}/catalogsubgroups/${request.catalogSubGroupId}/catalogs/${request.catalogId}/knowledgeunits`)
            .then((response) => response.data.data);
    },
    CREATE_KNOWLEDGEUNIT({ commit, rootGetters }, { id: knowledgeUnitId, ...knowledgeUnit }) {
        const { id: catalogGroupId } = rootGetters['CatalogGroupOperations/getSelectedCatalogGroup'].data;
        const { id: catalogSubgroupId } = rootGetters['CatalogSubgroupOperations/getSelectedCatalogSubGroup'].data;
        const { id: catalogId } = rootGetters['CatalogOperations/getSelectedCatalog'].data;
        return axios
            .post(`cataloggroups/${catalogGroupId}/catalogsubgroups/${catalogSubgroupId}/catalogs/${catalogId}/knowledgeunits`, knowledgeUnit)
            .then((response) => response.data.data)
            .then((response) => {
                const knowledgeUnits = rootGetters['AuthoringToolOperations/getKnowledgeUnits'](catalogId);
                commit('AuthoringToolOperations/updateKnowledgeUnitMap', { [catalogId]: [...knowledgeUnits, response] }, { root: true });
                return response;
            });
    },
    UPDATE_KNOWLEDGEUNIT({ commit, rootGetters }, { id: knowledgeUnitId, ...knowledgeUnit }) {
        const { id: catalogGroupId } = rootGetters['CatalogGroupOperations/getSelectedCatalogGroup'].data;
        const { id: catalogSubgroupId } = rootGetters['CatalogSubgroupOperations/getSelectedCatalogSubGroup'].data;
        const { id: catalogId } = rootGetters['CatalogOperations/getSelectedCatalog'].data;
        return axios
            .patch(
                `cataloggroups/${catalogGroupId}/catalogsubgroups/${catalogSubgroupId}/catalogs/${catalogId}/knowledgeunits/${knowledgeUnitId}`,
                knowledgeUnit
            )
            .then((response) => response.data.data)
            .then((response) => {
                const knowledgeUnits = rootGetters['AuthoringToolOperations/getKnowledgeUnits'](catalogId).map((ku) => (ku.id === response.id ? response : ku));
                commit('AuthoringToolOperations/updateKnowledgeUnitMap', { [catalogId]: knowledgeUnits }, { root: true });
                return response;
            });
    },
    UPDATE_KNOWLEDGEUNIT_ORDER({ commit }, request) {
        return axios
            .post(
                `cataloggroups/${request.catalogGroupId}/catalogsubgroups/${request.catalogSubGroupId}/catalogs/${request.catalogId}/knowledgeunits/reorder/${request.order}`,
                request.data
            )
            .then((response) => {
                return response;
            });
    },
    DELETE_KNOWLEDGE_UNIT({ commit, rootGetters }, { id: knowledgeUnitId }) {
        const { id: catalogGroupId } = rootGetters['CatalogGroupOperations/getSelectedCatalogGroup'].data;
        const { id: catalogSubgroupId } = rootGetters['CatalogSubgroupOperations/getSelectedCatalogSubGroup'].data;
        const { id: catalogId } = rootGetters['CatalogOperations/getSelectedCatalog'].data;
        return axios
            .delete(`cataloggroups/${catalogGroupId}/catalogsubgroups/${catalogSubgroupId}/catalogs/${catalogId}/knowledgeunits/${knowledgeUnitId}`)
            .then(() => {
                const knowledgeUnits = rootGetters['AuthoringToolOperations/getKnowledgeUnits'](catalogId).filter(
                    (knowledgeUnit) => knowledgeUnit.id !== knowledgeUnitId
                );
                commit('AuthoringToolOperations/updateKnowledgeUnitMap', { [catalogId]: knowledgeUnits }, { root: true });
            });
    },
    SELECT_KNOWLEDGEUNIT({ commit }, selectedKnowledgeUnit) {
        commit('updateSelectedKnowledgeUnit', selectedKnowledgeUnit);
    },
    MOVE_KNOWLEDGEUNIT({ dispatch, rootGetters }, { catalog: { id: targetCatalogId }, knowledgeUnit }) {
        const { id: catalogGroupId } = rootGetters['CatalogGroupOperations/getSelectedCatalogGroup'].data;
        const { id: catalogSubgroupId } = rootGetters['CatalogSubgroupOperations/getSelectedCatalogSubGroup'].data;
        const { id: catalogId } = rootGetters['CatalogOperations/getSelectedCatalog'].data;
        return axios.post(
            `cataloggroups/${catalogGroupId}/catalogsubgroups/${catalogSubgroupId}/catalogs/${catalogId}/knowledgeunits/move/${targetCatalogId}`,
            [knowledgeUnit]
        );
        // .then(() => dispatch('AuthoringToolOperations/BUILD_MAP', null, { root: true }));
    },
    COPY_KNOWLEDGEUNIT({ dispatch, rootGetters }, { knowledgeUnit: { id: knowledgeUnitId }, catalogs }) {
        const { id: catalogGroupId } = rootGetters['CatalogGroupOperations/getSelectedCatalogGroup'].data;
        const { id: catalogSubgroupId } = rootGetters['CatalogSubgroupOperations/getSelectedCatalogSubGroup'].data;
        const { id: catalogId } = rootGetters['CatalogOperations/getSelectedCatalog'].data;
        return axios.post(
            `cataloggroups/${catalogGroupId}/catalogsubgroups/${catalogSubgroupId}/catalogs/${catalogId}/knowledgeunits/${knowledgeUnitId}/copy`,
            { isDeepCopy: true, catalogs }
        );
        // .then(() => dispatch('AuthoringToolOperations/BUILD_MAP', null, { root: true }));
    },
    UPDATE_EXCEPTION_REMOVE({ rootGetters, getters }, data) {
        let { id: catalogGroupId } = rootGetters['CatalogGroupOperations/getSelectedCatalogGroup'].data;
        let { id: catalogSubGroupId } = rootGetters['CatalogSubgroupOperations/getSelectedCatalogSubGroup'].data;
        let { id: catalogId } = rootGetters['CatalogOperations/getSelectedCatalog'].data;
        let { id: knowledgeUnitId } = getters.getSelectedKnowledgeUnit.data;
        return axios
            .patch(
                `/cataloggroups/${catalogGroupId}/catalogsubgroups/${catalogSubGroupId}/catalogs/${catalogId}/knowledgeunits/${knowledgeUnitId}/reference`,
                data
            )
            .then((response) => response.data.data);
    },
    UPDATE_EXCEPTION_KEEP({ rootGetters }, { id: knowledgeUnitId, ...data }) {
        let { id: catalogGroupId } = rootGetters['CatalogGroupOperations/getSelectedCatalogGroup'].data;
        let { id: catalogSubGroupId } = rootGetters['CatalogSubgroupOperations/getSelectedCatalogSubGroup'].data;
        let { id: catalogId } = rootGetters['CatalogOperations/getSelectedCatalog'].data;
        return axios
            .patch(
                `/cataloggroups/${catalogGroupId}/catalogsubgroups/${catalogSubGroupId}/catalogs/${catalogId}/knowledgeunits/${knowledgeUnitId}/updatedetails`,
                data
            )
            .then((response) => response.data.data);
    },
    DELETE_EXCEPTION_REFERENCE({ rootGetters }, { id: knowledgeUnitId }) {
        let { id: catalogGroupId } = rootGetters['CatalogGroupOperations/getSelectedCatalogGroup'].data;
        let { id: catalogSubGroupId } = rootGetters['CatalogSubgroupOperations/getSelectedCatalogSubGroup'].data;
        let { id: catalogId } = rootGetters['CatalogOperations/getSelectedCatalog'].data;
        return axios
            .delete(`/cataloggroups/${catalogGroupId}/catalogsubgroups/${catalogSubGroupId}/catalogs/${catalogId}/knowledgeunits/${knowledgeUnitId}/reference`)
            .then((response) => response.data.data);
    }
};

const mutations = {
    clear: (state) => {
        state.knowledgeUnitList = [];
        state.selectedKnowledgeUnit = {
            itemType: 'KNOWLEDGEUNIT',
            itemTitle: 'Wissenseinheit',
            path: '/Authoringtool/KnowledgeUnit',
            data: {}
        };
    },
    updateSelectedKnowledgeUnit: (state, knowledgeUnit) => (state.selectedKnowledgeUnit.data = knowledgeUnit)
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
