import axios from '../../plugins/Axios/axios';

const state = {
    forums: [],
    threads: [],
    answers: [],
    selectedForum: {},
    selectedThread: {},
    selectedAnswer: {},
    forumPath: []
};

const getters = {
    getForums() {
        return state.forums;
    },
    getThreads() {
        return state.threads;
    },
    getAnswers() {
        return state.answers;
    },
    getSelectedForum() {
        return state.selectedForum;
    },
    getSelectedThread() {
        return state.selectedThread;
    },
    getSelectedAnswer() {
        return state.selectedAnswer;
    },
    getForumPath() {
        return state.forumPath;
    }
};

const actions = {
    REMOVE_FORUM_PATH({ commit }, data) {
        commit('removeForumPath', data);
    },
    ADD_FORUM_PATH({ commit }, data) {
        commit('addForumPath', data);
    },
    SET_SELECTED_ANSWER({ commit }, answer) {
        commit('updateSelectedAnswer', answer);
    },
    SET_SELECTED_FORUM({ commit }, forum) {
        commit('updateSelectedForum', forum);
    },
    SET_SELECTED_THREAD({ commit }, thread) {
        commit('updateSelectedThread', thread);
    },
    GET_ALL_FORUMS({ commit }) {
        return axios
            .get(`/forums`)
            .then((response) => response.data.data)
            .then((response) => commit('updateForum', response));
    },
    CREATE_FORUM({ commit }, request) {
        return axios
            .post(`/forums`, request)
            .then((response) => response.data.data)
            .then((response) => commit('updateForum', response));
    },
    UPDATE_FORUM({ commit }, request) {
        const { id } = request;
        return axios
            .patch(`/forums/${id}`, request)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateSelectedForum', response);
                commit('updateForum', response);
            });
    },
    DELETE_FORUM({ commit }, request) {
        const { id } = request;
        return axios.delete(`/forums/${id}`).then(() => {
            commit('updateSelectedForum', {});
            commit('deleteForum', request);
        });
    },
    GET_FORUM_THREADS({ commit }, forum) {
        const { id: forumId } = forum;
        return axios
            .get(`/forums/${forumId}/threads`)
            .then((response) => response.data.data)
            .then((response) => commit('updateThreads', response));
    },
    CREATE_FORUM_THREAD({ commit }, { forum, thread }) {
        const { id: forumId } = forum;
        return axios
            .post(`/forums/${forumId}/threads`, thread)
            .then((response) => response.data.data)
            .then((response) => commit('updateThreads', response));
    },
    UPDATE_FORUM_THREAD({ commit }, { forum, thread }) {
        const { id: forumId } = forum;
        const { id: threadId } = thread;
        return axios
            .patch(`/forums/${forumId}/threads/${threadId}`, thread)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateSelectedThread', response);
                commit('updateThreads', response);
            });
    },
    DELETE_FORUM_THREAD({ commit }, { forum, thread }) {
        const { id: forumId } = forum;
        const { id: threadId } = thread;
        return axios
            .delete(`/forums/${forumId}/threads/${threadId}`)
            .then((response) => response.data.data)
            .then(() => {
                commit('updateSelectedThread', {});
                commit('deleteThread', thread);
            });
    },
    GET_FORUM_THREAD_ANSWERS({ commit }, { forum, thread }) {
        const { id: forumId } = forum;
        const { id: threadId } = thread;
        return axios
            .get(`/forums/${forumId}/threads/${threadId}/answers`)
            .then((response) => response.data.data)
            .then((response) => commit('updateAnswers', response));
    },
    CREATE_FORUM_THREAD_ANSWER({ commit }, { forum, thread, answer }) {
        const { id: forumId } = forum;
        const { id: threadId } = thread;
        return axios
            .post(`/forums/${forumId}/threads/${threadId}/answers`, answer)
            .then((response) => response.data.data)
            .then((response) => commit('updateAnswers', response));
    },
    UPDATE_FORUM_THREAD_ANSWER({ commit }, { forum, thread, answer }) {
        const { id: forumId } = forum;
        const { id: threadId } = thread;
        const { id: answerId } = answer;

        return axios
            .patch(`/forums/${forumId}/threads/${threadId}/answers/${answerId}`, answer)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateSelectedAnswer', response);
                commit('updateAnswers', response);
            });
    },
    DELETE_FORUM_THREAD_ANSWER({ commit }, { forum, thread, answer }) {
        const { id: forumId } = forum;
        const { id: threadId } = thread;
        const { id: answerId } = answer;
        return axios
            .delete(`/forums/${forumId}/threads/${threadId}/answers/${answerId}`)
            .then((response) => response.data.data)
            .then(() => {
                commit('updateSelectedAnswer', {});
                commit('deleteAnswer', answer);
            });
    }
};

const mutations = {
    clear: (state) => {
        state.forums = [];
        state.selectedThread = {};
        state.selectedForum = {};
        state.selectedAnswer = {};
        state.forumPath = [];
    },
    addForumPath: (state, data) => {
        if (state.forumPath.map((forum) => forum.id).indexOf(data.id) === -1) {
            state.forumPath.push(data);
        }
    },
    removeForumPath: (state, data) => {
        const find = state.forumPath.map((forum) => forum.id).findIndex((forum) => forum === data.id);
        if (find !== -1) {
            state.forumPath = state.forumPath.slice(0, find);
        }
    },
    updateSelectedForum: (state, forum) => {
        state.selectedForum = forum;
        state.selectedThread = {};
        state.selectedAnswer = {};
    },
    updateSelectedThread: (state, thread) => {
        state.selectedThread = thread;
        state.selectedAnswer = {};
    },
    updateSelectedAnswer: (state, answer) => (state.selectedAnswer = answer),
    updateForum: (state, response) => {
        if (Array.isArray(response)) {
            state.forums = response;
        } else {
            if (state.forums.map((forum) => forum.id).includes(response.id)) {
                state.forums = state.forums.map((forum) => {
                    if (forum.id === response.id) {
                        return response;
                    }
                    return forum;
                });
            } else {
                state.forums.push(response);
            }
        }
    },
    updateThreads: (state, response) => {
        if (Array.isArray(response)) {
            state.threads = response;
        } else {
            if (state.threads.map((thread) => thread.id).includes(response.id)) {
                state.threads = state.threads.map((thread) => {
                    if (thread.id === response.id) {
                        return response;
                    }
                    return thread;
                });
            } else {
                state.threads.push(response);
            }
        }
    },
    updateAnswers: (state, response) => {
        if (Array.isArray(response)) {
            state.answers = response;
        } else {
            if (state.answers.map((answer) => answer.id).includes(response.id)) {
                state.answers = state.answers.map((answer) => {
                    if (answer.id === response.id) {
                        return response;
                    }
                    return answer;
                });
            } else {
                state.answers.push(response);
            }
        }
    },
    deleteForum: (state, response) => {
        const index = state.forums.map((forum) => forum.id).indexOf(response.id);
        if (index !== -1) {
            state.forums.splice(index, 1);
        }
    },
    deleteThread: (state, response) => {
        const index = state.threads.map((thread) => thread.id).indexOf(response.id);
        if (index !== -1) {
            state.threads.splice(index, 1);
        }
    },
    deleteAnswer: (state, response) => {
        const index = state.answers.map((answer) => answer.id).indexOf(response.id);
        if (index !== -1) {
            state.answers.splice(index, 1);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
