import axios from '../../plugins/Axios/axios';

const state = {
    userIncompleteTaskList: [],
    userCompleteTaskList: [],
    userStatistic: [
        {
            interval: {},
            users: []
        }
    ],
    taskKnowledgeUnit: {},
    //
    userIncompleteArchiveList: [],
    userCompleteArchiveList: [],
    archiveKnowledgeUnit: {},
    //
    selectedTaskArchive: {
        itemType: '',
        itemTitle: '',
        path: '',
        data: {}
    },
    archive: {},
    todaysRemainingKUCount: '',
    todaysCompletedKUCount: '',
    dailyAnsweredLimit: '',
    isVoting: false,
    taskArchiveResult: {},
    userCertificate: {},
    ///STORE
    archiveStore: {},
    archiveBackPath: '',
    quizHistories: [],
    quizHistoryDrawerState: false,
    activeTab: 0
};

// getters
const getters = {
    getQuizHistoryDrawerState(state) {
        return state.quizHistoryDrawerState;
    },
    // DASHBNOARD
    getUserStatistic(state) {
        return state.userStatistic;
    },
    //TASK
    getUserIncompleteTaskList(state) {
        return state.userIncompleteTaskList;
    },
    getUserCompleteTaskList(state) {
        return state.userCompleteTaskList;
    },
    getTaskKnowledgeUnit(state) {
        return state.taskKnowledgeUnit;
    },
    //ARCHIVE
    getArchive(state) {
        return state.archive;
    },
    getUserIncompleteArchiveList(state) {
        return state.userIncompleteArchiveList;
    },
    getUserCompleteArchiveList(state) {
        return state.userCompleteArchiveList;
    },
    getArchiveKnowledgeUnit(state) {
        return state.archiveKnowledgeUnit;
    },
    //TASK & ARCHIVE=
    getSelectedTaskArchive(state) {
        return state.selectedTaskArchive;
    },
    //
    getTodaysRemainingKUCount(state) {
        return state.todaysRemainingKUCount;
    },
    getTodaysCompletedKUCount(state) {
        return state.todaysCompletedKUCount;
    },
    getDailyAnsweredLimit(state) {
        return state.dailyAnsweredLimit;
    },
    getTaskArchiveResult(state) {
        return state.taskArchiveResult;
    },
    getUserCertificate(state) {
        return state.userCertificate;
    },
    //////STORE
    getArchiveStore(state) {
        return state.archiveStore;
    },
    getBackPath(state) {
        return state.archiveBackPath;
    },
    getActiveTab(state) {
        return state.activeTab;
    },
    getQuizHistories(state) {
        return state.quizHistories;
    }
};

// actions
const actions = {
    GET_USER_TASK({ commit }, { id: userId }) {
        return axios.get(`/users/${userId}/tasks`).then((response) => response.data.data);
    },
    GET_INCOMPLETE_USERTASK_LIST({ commit }) {
        return axios
            .get('usertasks?completed=false')
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateUserIncompleteTaskList', response);
                return response;
            });
    },
    GET_COMPLETE_USERTASK_LIST({ commit }) {
        return axios
            .get('usertasks?completed=true')
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateUserCompleteTaskList', response);
                return response;
            });
    },
    GET_PASSED_USERTASK_LIST({ commit }) {
        return axios.get('usertasks?completed=true&passed=true').then((response) => {
            var taskList = response.data.data;
            taskList.forEach((task) => {
                task.selected = false;
            });
            commit('updateUserCompleteTaskList', taskList);
            return response;
        });
    },
    SELECT_TASK({ commit }, selectedTask) {
        commit('updateSelectedTask', selectedTask);
    },
    //ARCHIVE
    GET_INCOMPLETE_ARCHIVE_LIST({ commit }) {
        return axios
            .get('archivetasks?completed=false')
            .then((response) => response.data.data.tasks)
            .then((response) => {
                commit('updateUserIncompleteArchiveList', response);
                return response;
            });
    },
    GET_COMPLETE_ARCHIVE_LIST({ commit }) {
        return axios
            .get('archivetasks?completed=true')
            .then((response) => response.data.data.tasks)
            .then((response) => {
                commit('updateUserCompleteArchiveList', response);
                return response;
            });
    },
    GET_ARCHIVE_CATALOGS({ commit }) {
        return axios.get(`/archivecatalogs`).then((response) => response.data.data.catalogs);
    },
    SELECT_ARCHIVE({ commit }, selectedArchive) {
        commit('updateSelectedArchive', selectedArchive);
    },
    //
    RESET_TASKARCHIVE_KNOWLEDGEUNIT({ commit }) {
        commit('updateTaskKnowledgeUnit', {});
    },
    RESET_SELECTED_TASKARCHIVE({ commit }) {
        commit('resetSelectedTaskArchive');
    },
    GET_ARCHIVE_BY_ID({ commit }, archiveId) {
        return axios
            .get(`archivetasks/${archiveId}`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateArchive', response);
                return response;
            });
    },
    GET_TASK_KNOWLEDGEUNIT_BY_ID({ commit }, { id: taskId }) {
        return axios.get(`usertasks/${taskId}/knowledgeunits`).then((response) => response.data.data);
    },
    GET_ARCHIVE_KNOWLEDGEUNIT_BY_ID({ commit }, { id: archiveTaskId }) {
        return axios.get(`archivetasks/${archiveTaskId}/knowledgeunits`).then((response) => response.data.data);
    },
    ADD_ARCHIVE(commit, request) {
        return axios.post('/archivetasks', request).then((response) => {
            return response;
        });
    },
    UPDATE_ARCHIVE({ commit }, request) {
        let { id: archiveId, ...archive } = request;
        return axios.patch(`/archivetasks/${archiveId}`, archive).then((response) => {
            return response;
        });
    },
    DELETE_ARCHIVE_BY_ID({ commit }, archiveId) {
        return axios.delete('/archivetasks/' + archiveId).then((response) => {
            return response;
        });
    },
    ADD_USERTASK_VOTE({ commit }, { task: { id: taskId }, vote }) {
        return axios.post(`usertasks/${taskId}/votes`, vote).then((response) => response.data.data);
    },
    ADD_ARCHIVE_VOTE({ commit }, { archive: { id: archiveId }, vote }) {
        return axios.post(`archivetasks/${archiveId}/votes`, vote).then((response) => response.data.data);
    },
    GET_USERTASK_RESULTS({ commit }, { id: taskId }) {
        return axios.get(`usertasks/${taskId}/results?showTodayVote=false`).then((response) => response.data.data);
    },
    GET_ARCHIVETASK_RESULTS({ commit }, task) {
        return axios.get(`archivetasks/${task.id}/results?showTodayVote=false`).then((response) => response.data.data);
    },
    UPDATE_ARCHIVE_STORE({ commit }, archiveStore) {
        commit('updateArchiveStore', archiveStore);
    },
    RESET_ARCHIVE_STORE({ commit }) {
        commit('updateArchiveStore', {});
    },
    UPDATE_ARCHIVE_BACKPATH({ commit }, backPath) {
        commit('updateBackPath', backPath);
    },
    UPDATE_ACTIVE_TAB({ commit }, activeTab) {
        commit('updateActiveTab', activeTab);
    },
    GET_USERCERTIFICATE_BY_ID({ commit }, taskId) {
        return axios.get(`/usertasks/${taskId}/certificate`, { responseType: 'blob' }).then((response) => {
            commit('updateUserCertificate', response.data);
            return response.data;
        });
    },
    UPDATE_QUIZ_HISTORIES({ commit }, history) {
        commit('updateQuizHistory', history);
    },
    RESET_QUIZ_HISTORIES({ commit }) {
        commit('resetQuizHistories');
    },
    UPDATE_QUIZ_HISTORYDRAWER_STATE({ commit }, historyDrawerState) {
        commit('updateQuizHistoryDrawerState', historyDrawerState);
    },
    UPDATE_ARCHIVE_SELECTED_CATALOG({ commit }, selectedCatalogs) {
        commit('updateArchiveSelectedCatalogs', selectedCatalogs);
    },
    // Preview
    KNOWLEDGE_UNIT_PREVIEW({ rootGetters }, { id: knowledgeUnitId }) {
        const { id: catalogGroupId } = rootGetters['CatalogGroupOperations/getSelectedCatalogGroup'].data;
        const { id: catalogSubgroupId } = rootGetters['CatalogSubgroupOperations/getSelectedCatalogSubGroup'].data;
        const { id: catalogId } = rootGetters['CatalogOperations/getSelectedCatalog'].data;
        return axios
            .get(
                `/cataloggroups/${catalogGroupId}/catalogsubgroups/${catalogSubgroupId}/catalogs/${catalogId}/knowledgeunits/${knowledgeUnitId}/preview/knowledgeunits`
            )
            .then((response) => response.data.data);
    },
    KNOWLEDGE_UNIT_PREVIEW_SUBMIT({ rootGetters }, { knowledgeUnit: { id: knowledgeUnitId }, vote }) {
        const { id: catalogGroupId } = rootGetters['CatalogGroupOperations/getSelectedCatalogGroup'].data;
        const { id: catalogSubgroupId } = rootGetters['CatalogSubgroupOperations/getSelectedCatalogSubGroup'].data;
        const { id: catalogId } = rootGetters['CatalogOperations/getSelectedCatalog'].data;
        return axios
            .post(
                `/cataloggroups/${catalogGroupId}/catalogsubgroups/${catalogSubgroupId}/catalogs/${catalogId}/knowledgeunits/${knowledgeUnitId}/preview/votes`,
                vote
            )
            .then((response) => response.data.data);
    },
    LOOP_TASK({ commit }, { id: taskId }) {
        return axios.post(`/usertasks/${taskId}/redo`);
    },
    LOOP_ARCHIVE({ commit }, { id: archiveId }) {
        return axios.post(`/archivetasks/${archiveId}/redo`);
    }
};

// mutations
const mutations = {
    clear: (state) => {
        state.userIncompleteTaskList = [];
        state.userCompleteTaskList = [];
        state.userStatistic = [
            {
                interval: {},
                users: []
            }
        ];
        state.taskKnowledgeUnit = {};
        state.userIncompleteArchiveList = [];
        state.userCompleteArchiveList = [];
        state.archiveKnowledgeUnit = {};
        state.selectedTaskArchive = {
            itemType: '',
            itemTitle: '',
            path: '',
            data: {}
        };
        state.archive = {};
        state.todaysRemainingKUCount = '';
        state.todaysCompletedKUCount = '';
        state.dailyAnsweredLimit = '';
        state.isVoting = false;
    },
    updateArchiveSelectedCatalogs: (state, selectedCatalogs) => {
        state.archiveStore.catalogs = selectedCatalogs;
    },
    updateQuizHistoryDrawerState: (state, quizHistoryDrawerState) => {
        state.quizHistoryDrawerState = quizHistoryDrawerState;
    },
    updateQuizHistory: (state, history) => {
        let historiesIds = state.quizHistories.map((qHistory) => qHistory.id);
        if (historiesIds.includes(history.id)) {
            state.quizHistories.forEach((qHistory) => {
                if (qHistory.id == history.id) {
                    qHistory.isCorrect = history.isCorrect;
                }
            });
        } else {
            state.quizHistories.push(history);
        }
    },
    resetQuizHistories: (state) => {
        state.quizHistories = [];
    },
    updateActiveTab: (state, activeTab) => {
        state.activeTab = activeTab;
    },
    updateBackPath: (state, backPath) => {
        state.archiveBackPath = backPath;
    },
    //DASHBOARD
    updateUserStatistic: (state, userStatistic) => {
        state.userStatistic = userStatistic;
    },
    //TASK
    updateUserIncompleteTaskList: (state, userIncompleteTaskList) => {
        state.userIncompleteTaskList = userIncompleteTaskList;
    },
    updateUserCompleteTaskList: (state, userCompleteTaskList) => {
        state.userCompleteTaskList = userCompleteTaskList;
    },
    updateSelectedTask: (state, selectedTask) => {
        state.selectedTaskArchive.itemType = 'TASK';
        state.selectedTaskArchive.itemTitle = 'Aufgabe';
        state.selectedTaskArchive.path = '/App';
        state.selectedTaskArchive.data = selectedTask;
    },
    //ARCHIVE
    updateArchive: (state, archive) => {
        state.archive = archive;
    },
    updateUserIncompleteArchiveList: (state, userIncompleteArchiveList) => {
        state.userIncompleteArchiveList = userIncompleteArchiveList;
    },
    updateUserCompleteArchiveList: (state, userCompleteArchiveList) => {
        state.userCompleteArchiveList = userCompleteArchiveList;
    },
    updateSelectedArchive: (state, selectedArchive) => {
        state.selectedTaskArchive.itemType = 'ARCHIVE';
        state.selectedTaskArchive.itemTitle = 'Archive';
        state.selectedTaskArchive.path = '/App/Archive';
        state.selectedTaskArchive.data = selectedArchive;
    },
    //
    resetSelectedTaskArchive: (state) => {
        state.selectedTaskArchive.itemType = '';
        state.selectedTaskArchive.itemTitle = '';
        state.selectedTaskArchive.path = '';
        state.selectedTaskArchive.data = {};
    },
    updateTaskKnowledgeUnit: (state, taskKnowledgeUnit) => {
        state.taskKnowledgeUnit = taskKnowledgeUnit;
    },
    updateTodaysRemainingKUCount: (state, todaysRemainingKUCount) => {
        state.todaysRemainingKUCount = todaysRemainingKUCount;
    },
    updateTodaysCompletedKUCount: (state, todaysCompletedKUCount) => {
        state.todaysCompletedKUCount = todaysCompletedKUCount;
    },
    updateDailyAnsweredLimit: (state, dailyAnsweredLimit) => {
        state.dailyAnsweredLimit = dailyAnsweredLimit;
    },
    updateTaskArchiveResult: (state, result) => {
        state.taskArchiveResult = result;
    },
    updateUserCertificate: (state, userCertificate) => {
        state.userCertificate = userCertificate;
    },
    //STORE
    updateArchiveStore: (state, archiveStore) => {
        state.archiveStore = archiveStore;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
