<template>
    <v-container>
        <v-row align="center" justify="center">
            <v-col cols="auto">
                <ImageLoader :src="file" :width="width" :height="height" :class="{ pointer: value.fileURL }" @click.native="preview" />
            </v-col>
        </v-row>
        <v-row>
            <v-col :cols="12">
                <slot>
                    <v-btn block color="error" @click="remove">
                        {{ $t('Entfernen') }}
                    </v-btn>
                </slot>
            </v-col>
        </v-row>
    </v-container>
    <!-- <v-layout column>
        <v-flex grow fill-height align-self-center justify-self-center>
            <ImageLoader :src="file" :width="width" :height="height" :class="{ pointer: value.fileURL }" @click.native="preview" />
        </v-flex>
        <v-flex shrink>
            <slot>
                <v-btn block color="error" @click="remove">
                    {{ $t('Entfernen') }}
                </v-btn>
            </slot>
        </v-flex>
    </v-layout> -->
</template>

<script>
import ImageLoader from '../../Generic/ImageLoader';
import VideoImage from '../../../assets/Resources/SVG/movie.svg';
import AudioImage from '../../../assets/Resources/SVG/music.svg';
import FileImage from '../../../assets/Resources/SVG/file.svg';
import BlockImage from '../../../assets/Resources/SVG/block.svg';

export default {
    name: 'knowledge-unit-option-media',
    components: { ImageLoader },
    props: {
        height: {
            type: [Number, String],
            default: 70
        },
        width: {
            type: [Number, String],
            default: 120
        },
        value: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        computedWidth() {
            if (this.fileType === 'application') {
                return this.$vuetify.breakpoint.thresholds.sm;
            }
            return this.$vuetify.breakpoint.thresholds.xs;
        },
        isFile() {
            return Object.keys(this.value).length > 0 && this.value.hasOwnProperty('fileType') && this.value.fileURL !== '';
        },
        file() {
            switch (this.fileType) {
                case 'image':
                    return this.value.fileURL;
                case 'video':
                    return VideoImage;
                case 'audio':
                    return AudioImage;
                case 'application':
                    return FileImage;
            }
            return BlockImage;
        },
        fileType() {
            if (this.isFile) {
                if (this.value.fileType === undefined) {
                    return undefined;
                }
                return this.value.fileType.substring(0, this.value.fileType.lastIndexOf('/'));
            }
            return '';
        }
    },
    methods: {
        remove() {
            this.$emit('input', {
                ...this.value,
                fileURL: '',
                fileType: '',
                fileName: '',
                mediaId: ''
            });
        },
        preview() {
            if (this.value.fileURL) {
                this.$emit('preview', this.value);
            }
        }
    }
};
</script>
