<template>
    <div v-if="items.length > 0">
        <v-list>
            <v-list-item v-for="(item, index) in items" :key="`material-${index}`">
                <v-list-item-content>
                    <v-list-item-title>{{ item.name || item.fileName }}</v-list-item-title>
                    <v-list-item-subtitle>
                        {{ item.media.fileType.substring(item.media.fileType.indexOf('/') + 1, item.media.fileType.length) }}
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn icon @click.stop="downloadMedia(item)">
                        <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                </v-list-item-action>
                <v-list-item-action>
                    <v-btn icon @click.stop="previewMedia(item)">
                        <v-icon>mdi-eye</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
            <v-list-item v-if="items.length > 1">
                <v-btn block color="primary" @click.stop="downloadAll">
                    {{ $t('Alle Herunterladen') }}
                </v-btn>
            </v-list-item>
        </v-list>
        <MediaPreview :showDialog="showPreview" @showDialog="(value) => (showPreview = value)" :value="selectedMedia" />
    </div>
</template>

<script>
import MediaPreview from '../../FileManager/MediaPreview';
import { saveAs } from 'file-saver';

export default {
    name: 'media-downloader',
    components: { MediaPreview },
    data: () => ({
        showPreview: false,
        selectedMedia: {}
    }),
    props: {
        items: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        downloadMedia(media) {
            fetch(media.media.fileURL)
                .then((response) => response.blob())
                .then((response) => saveAs(response, media.name || media.media.fileName));
        },
        previewMedia(media) {
            this.showPreview = true;
            this.selectedMedia = media.media;
        },
        downloadAll() {
            this.computedMediaList.forEach((item) => this.downloadMedia(item));
        }
    }
};
</script>
