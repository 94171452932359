<template>
    <v-card>
        <v-toolbar dense dark flat color="secondary">
            <v-toolbar-title>{{ $t('Wiki Home bearbeiten') }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="() => (computedDialog = false)">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <div>
                <TextEditor v-model="template" />
            </div>
            <v-sheet color="grey lighten-3">
                <v-card-text>
                    <v-row>
                        <v-flex shrink align-self-center justify-self-center>
                            <span>{{ $t('Zuletzt bearbeitet von: ') }}:</span>
                        </v-flex>
                        <v-flex shrink>
                            <UserProfile :value="getWikiHome.updatedBy" :displayName="false" />
                        </v-flex>
                    </v-row>
                </v-card-text>
            </v-sheet>
        </v-card-text>
        <v-card-actions>
            <v-btn block color="warning" :disabled="!hasChanges" @click="save">
                {{ $t('Aktualisieren') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import TextEditor from '../Generic/TextEditor';
import UserProfile from '../Generic/Profile/UserProfile';
import WikiMixin from './Mixins/WikiMixin';

export default {
    name: 'wiki-home-manager',
    components: { TextEditor, UserProfile },
    mixins: [WikiMixin],
    data: () => ({
        template: ''
    }),
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        value(value) {
            if (value) {
                this.GET_WIKI_HOME();
            }
        },
        getWikiHome(value) {
            this.template = value.body;
        }
    },
    computed: {
        computedDialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        hasChanges() {
            return this.getWikiHome.body !== this.template;
        }
    },
    methods: {
        save() {
            this.UPDATE_WIKI_HOME({ body: this.template }).then(() => (this.computedDialog = false));
        }
    },
    mounted() {
        this.template = this.getWikiHome.body;
    }
};
</script>
