<template>
    <div>
        <v-data-iterator
            :items="users"
            :no-data-text="$t('Keine Daten verfügbar')"
            :no-results-text="$t('Kein Ergebnis')"
            :items-per-page="5"
            :footer-props="{
                'items-per-page-options': [5, 10, 15, { value: -1, text: $t('Alle') }]
            }"
            :rowe-per-page-text="$t('Datensätze pro Seite')"
            ref="excelUserIterator"
            :hideDefaultFooter="!hasUsers"
        >
            <template v-slot:item="{ item }">
                <v-list-item>
                    <v-list-item-action>
                        <v-btn icon text @click="setSelected(item)">
                            <v-icon :class="highlightSelected(item)">{{ selectedIcon(item) }}</v-icon>
                        </v-btn>
                    </v-list-item-action>
                    <v-list-item-content :class="highlightSelected(item)">
                        <v-list-item-title>{{ item.user.displayName }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.user.email }}</v-list-item-subtitle>
                        <v-list-item-subtitle>{{ item.user.role }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon @click="selectUser(item)">
                                    <v-icon v-on="on" :color="icon(item) === 'close' ? 'error' : ''">{{ icon(item) }}</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ iconTooltip(item) }}</span>
                        </v-tooltip>
                    </v-list-item-action>
                </v-list-item>
            </template>
            <template v-slot:footer>
                <v-container>
                    <v-row v-if="hasUsers">
                        <v-col>
                            <v-btn block :disabled="!isSelected" @click="showUserDetails()" color="secondary">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn block :disabled="!isSelected" @click="markForDelete" color="error">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </v-data-iterator>
        <ExcelUserDetails v-model="showDetails" :selectedUser="selected" @changeData="changeData" />
    </div>
</template>
<script>
import UserMixin from '../Mixins/UserMixin';
import ExcelUserDetails from './ExcelUserDetails';

export default {
    name: 'excel-users-list',
    mixins: [UserMixin],
    components: { ExcelUserDetails },
    data: () => ({
        selected: {},
        deleted: [],
        showDetails: false
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        users() {
            return Object.values(this.getExcelUsersMap).flat();
        },
        hasUsers() {
            return this.users.length > 0;
        },
        isSelected() {
            return Object.keys(this.selected).length > 0;
        }
    },
    watch: {
        deleted() {
            this.serialize();
        },
        value() {
            this.selected = {};
        }
    },
    methods: {
        selectUser(item) {
            if (this.selected.hasOwnProperty('user')) {
                if (this.selected.user.hasOwnProperty('id')) {
                    if (item.user.id !== this.selected.user.id) {
                        this.setSelected(item);
                    }
                }
            } else {
                this.setSelected(item);
            }
            this.showUserDetails();
        },
        showUserDetails() {
            if (this.isSelected) {
                this.showDetails = !this.showDetails;
            }
        },
        changeData(data) {
            let map = { ...this.getExcelUsersMap };
            map[data.method] = map[data.method].map((item) => {
                if (item.user.email === data.user.email) {
                    return data;
                }
                return item;
            });
            this.updateExcelUsersMap(map);
            this.serialize(map);
            this.$nextTick(() => (this.selected = data));
        },
        serialize(data) {
            let map;
            if (typeof data === 'undefined') {
                map = { ...this.getExcelUsersMap };
            } else {
                map = { ...data };
            }

            for (var key in map) {
                map[key] = map[key].filter((user) => !this.deleted.includes(user.user.email));
            }
            this.$emit('input', map);
        },
        setSelected(item) {
            if (this.selected.hasOwnProperty('user')) {
                if (this.selected.user.email === item.user.email) {
                    this.selected = {};
                    return;
                }
            }
            this.selected = item;
        },
        markForDelete() {
            let email = this.selected.user.email;
            if (this.deleted.includes(email)) {
                this.deleted = this.deleted.filter((userMail) => userMail !== email);
            } else {
                this.deleted.push(email);
            }
        },
        selectedIcon(item) {
            if (this.selected.hasOwnProperty('user')) {
                if (this.selected.user.email === item.user.email) {
                    return '$vuetify.icons.radioOn';
                }
            }
            return '$vuetify.icons.radioOff';
        },
        highlightSelected(item) {
            if (this.selected.hasOwnProperty('user')) {
                if (this.selected.user.email === item.user.email) {
                    return 'primary--text';
                }
            }
            return '';
        },
        iconTooltip(item) {
            if (this.deleted.includes(item.user.email)) {
                return 'Dieser Nutzer wurde aus der Liste entfernt';
            }
            switch (item.method) {
                case 'CREATE':
                    return 'Dieser Nutzer wird neu angelegt';
                case 'UPDATE':
                    return 'Die Eigenschaften dieses Nutzer werden aktualisiert';
                case 'REACTIVATE':
                    return 'Dieser Nutzer wird reaktiviert';
            }
        },
        icon(item) {
            if (this.deleted.includes(item.user.email)) {
                return 'close';
            }
            switch (item.method) {
                case 'CREATE':
                    return 'mdi-plus';
                case 'UPDATE':
                    return 'mdi-pencil';
                case 'REACTIVATE':
                    return 'mdi-account-circle';
            }
        }
    }
};
</script>
