<template>
    <v-menu offset-x left :close-on-content-click="false" :max-height="300" :max-width="400" :min-width="400">
        <template v-slot:activator="{ on: menu }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-badge :value="value.length" overlap>
                        <template v-slot:badge>
                            <span>{{ value.length }}</span>
                        </template>
                        <v-btn v-on="concatHandlers(menu, tooltip)" icon>
                            <v-icon>{{ icon }}</v-icon>
                        </v-btn>
                    </v-badge>
                </template>
                {{ tooltip }}
            </v-tooltip>
        </template>
        <GroupList v-model="computedValue" :showUserGroups="showUserGroups" :showAdminGroups="showAdminGroups" />
    </v-menu>
</template>

<script>
import GroupList from '../../../Groups/GroupList';

export default {
    name: 'filemanager-menu-badge',
    components: { GroupList },
    props: {
        icon: {
            type: String,
            default: 'mdi-account'
        },
        tooltip: {
            type: String,
            default: ''
        },
        value: {
            type: Array,
            default: () => []
        },
        showUserGroups: {
            type: Boolean,
            default: false
        },
        showAdminGroups: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        concatHandlers(menuHandler, tooltipHandler) {
            return { ...menuHandler, ...tooltipHandler };
        }
    }
};
</script>
