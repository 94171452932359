<template>
    <v-container fluid grid-list-md fill-height>
        <v-layout wrap>
            <v-flex xs12 shrink>
                <v-card text>
                    <v-card-text>
                        <v-layout column>
                            <v-input
                                prepend-icon="mdi-information-outline"
                                :label="$t('Excel vorlage')"
                                :hint="
                                    $t(
                                        'Laden Sie sich die Excel-Vorlage runter und füllen Sie diese aus. Laden Sie die ausgefüllte Excel Datei hoch und überprüfen Sie die Änderungen und bestätigen Sie die Übernahme der Änderungen'
                                    )
                                "
                                persistent-hint
                            />
                            <span>
                                <a class="text-caption" href="/resources/Wiselab2OrganizeUsers.xlsx" download>
                                    {{ $t('Vorlage herunterladen') }}
                                </a>
                            </span>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs12 shrink>
                <v-card flat>
                    <v-card-text>
                        <v-label>{{ $t('Vorlage hochladen') }}</v-label>
                        <FilePicker v-model="excelFile" />
                        <v-layout wrap>
                            <v-flex xs12 sm6>
                                <v-btn block color="secondary" :disabled="!isFile" @click="uploadFile">{{ $t('Hochladen') }}</v-btn>
                            </v-flex>
                            <v-flex xs12 sm6>
                                <v-btn block color="primary" :disabled="!hasResult" @click="confirmFile">{{ $t('Änderungen übernehmen') }}</v-btn>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs12 grow>
                <v-card text v-if="isUploaded">
                    <v-card-text>
                        <v-label>{{ $t('Änderungen') }}</v-label>
                        <excel-users-list v-model="computedUsersMap" />
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
import FilePicker from '../../Generic/FilePicker/FilePicker';
import UserMixin from '../Mixins/UserMixin';
import ExcelUsersList from './ExcelUsersList';

export default {
    name: 'excel-users',
    mixins: [UserMixin],
    components: { FilePicker, ExcelUsersList },
    data: () => ({
        excelFile: {}
    }),
    computed: {
        computedUsersMap: {
            get() {
                return this.getExcelUsersMap;
            },
            set(value) {
                this.updateExcelUsersMap(value);
            }
        },
        isUploaded() {
            return Object.keys(this.getExcelUsersMap).length > 0;
        },
        isFile() {
            return Object.keys(this.excelFile).length > 0;
        },
        hasResult() {
            let hasUsers = Object.values(this.getExcelUsersMap).flat().length > 0;
            return hasUsers && this.isUploaded;
        }
    },
    methods: {
        confirmFile() {
            this.CONFIRM_EXCEL_SHEET(this.getExcelUsersMap);
        },
        uploadFile() {
            this.UPLOAD_EXCEL_SHEET(this.excelFile[0]);
        }
    }
};
</script>
