<template>
    <v-dialog v-model="showModal" persistent :width="$vuetify.breakpoint.thresholds.sm">
        <template #activator="{ on, attrs }">
            <v-tooltip bottom>
                <template #activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                    <v-btn icon v-on="{ ...on, ...tooltipOn }" v-bind="{ ...attrs, ...tooltipAttrs }" :disabled="!isSelected" color="error">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('Aktivität löschen') }}</span>
            </v-tooltip>
        </template>
        <DeleteConfirm
            :hasToolbar="true"
            :toolbarTitle="$t('Aktivität löschen')"
            :deleteMessage="$t('Sind Sie sicher, dass Sie diese Aktivität löschen möchten?')"
            :checkboxDeleteMessage="$t('Diese Aktion kann nicht rückgängig gemacht werden.')"
            @deleteItem="deleteActivity"
            @closeEmit="close"
        />
    </v-dialog>
</template>
<script>
import CourseMixin from './Mixins/CourseMixin';
import DeleteConfirm from '../../Generic/DeleteConfirm';

export default {
    name: 'delete-activity',
    mixins: [CourseMixin],
    data: () => ({
        showModal: false
    }),
    components: {
        DeleteConfirm
    },
    computed: {
        isSelected() {
            return Object.keys(this.getSelectedActivity).length > 0;
        }
    },
    methods: {
        close() {
            this.showModal = false;
        },
        deleteActivity() {
            switch (this.getSelectedActivity.activitySpecification.type) {
                case 'UPLOAD':
                    return this.DELETE_FILE_ACTIVITY(this.getSelectedActivity)
                        .then(this.saveActivityToLearningGoal)
                        .then(() => this.close());
                case 'SELF_CHECK':
                    return this.DELETE_CHECK_ACTIVITY(this.getSelectedActivity)
                        .then(this.saveActivityToLearningGoal)
                        .then(() => this.close());
                case 'TEXT_FIELD':
                    return this.DELETE_TEXTFIELD_ACTIVITY(this.getSelectedActivity)
                        .then(this.saveActivityToLearningGoal)
                        .then(() => this.close());
                case 'INFORM':
                    return this.DELETE_INFORM_ACTIVITY(this.getSelectedActivity)
                        .then(this.saveActivityToLearningGoal)
                        .then(() => this.close());
                case 'QUIZ':
                    return this.DELETE_QUIZ_ACTIVITY(this.getSelectedActivity)
                        .then(this.saveActivityToLearningGoal)
                        .then(() => this.close());
            }
        }
    }
};
</script>
