<template>
    <v-tabs v-model="tabs" show-arrows mandatory>
        <v-tab :key="0" v-show="showUsers">{{ $t('Lernende') }}</v-tab>
        <v-tab :key="1" v-show="showAdmins">{{ $t('Lehrende / Admin') }}</v-tab>
        <v-tabs-items v-model="tabs" touchless>
            <v-tab-item :key="0">
                <v-container fluid fill-height class="verticalLimit">
                    <v-row>
                        <v-col :cols="12">
                            <v-row align="center">
                                <v-col cols="auto">
                                    <UsersConfiguration />
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="searchUsers" :label="$t('Suchen')" persistent-hint prepend-icon="mdi-magnify" />
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col :cols="12">
                            <UserGroupTree
                                v-if="isShowTree"
                                v-model="computedSelect"
                                :assigned-users="assignedUsers"
                                :tree="getUserTreeFiltered"
                                :search="searchUsers"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-tab-item>
            <v-tab-item :key="1">
                <v-container fluid fill-height class="verticalLimit">
                    <v-row>
                        <v-col :cols="12">
                            <v-row align="center">
                                <v-col cols="auto">
                                    <UsersConfiguration />
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="searchAdmins" :label="$t('Suchen')" persistent-hint prepend-icon="mdi-magnify" />
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col :cols="12">
                            <UserGroupTree
                                v-model="computedSelect"
                                :tree="getAdminTreeFiltered"
                                :assigned-users="assignedAdmins"
                                :showSelf="showSelf"
                                :search="searchAdmins"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-tab-item>
        </v-tabs-items>
    </v-tabs>
</template>

<script>
import UserMixin from '../../Users/Mixins/UserMixin';
import UserGroupTree from './Helpers/UserGroupTree';
import UsersConfiguration from '../../Users/Prototype/Helpers/UsersConfiguration';

export default {
    name: 'user-group-tree-selector',
    components: { UserGroupTree, UsersConfiguration },
    mixins: [UserMixin],
    data: function () {
        return {
            searchUsers: '',
            searchAdmins: '',
            tabs: 0,
            loaded: [],
            isShowTree: false
        };
    },
    props: {
        value: {
            type: Array,
            default: () => []
        },
        showAdmins: {
            type: Boolean,
            default: true
        },
        showUsers: {
            type: Boolean,
            default: true
        },
        showSelf: {
            type: Boolean,
            default: true
        },
        assignedUsers: {
            type: Array,
            default: () => []
        },
        assignedAdmins: {
            type: Array,
            default: () => []
        }
    },
    watch: {
        tabs: {
            immediate: true,
            handler(value) {
                switch (value) {
                    case 0: {
                        if (!this.showUsers) {
                            this.tabs = this.tabs + 1;
                        } else {
                            if (!this.loaded.includes(value)) {
                                this.BUILD_USER_MAPS().then(() => {
                                    this.isShowTree = true;
                                    this.$emit('loader', false);
                                });
                                this.loaded.push(value);
                            }
                        }
                        break;
                    }
                    case 1: {
                        if (!this.showAdmins) {
                            this.tabs = this.tabs - 1;
                        } else {
                            if (!this.loaded.includes(value)) {
                                this.$emit('loader', true);
                                this.BUILD_ADMIN_MAPS().then(() => {
                                    this.$emit('loader', false);
                                });
                                this.loaded.push(value);
                            }
                        }
                        break;
                    }
                }
            }
        }
    },
    computed: {
        computedSelect: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    created() {
        this.$emit('loader', true);
    }
};
</script>
<style>
.verticalLimitUserTree {
    overflow-y: auto;
    max-height: calc(100vh - 358px) !important;
}
</style>
