<template>
    <v-card flat>
        <EvaluationSelector v-model="selectedType" />
        <v-container>
            <v-row align="center" justify="center">
                <v-col>
                    <v-text-field readonly hide-details :value="selectedObject.score" :label="$t('Maximale Punkte Vergabe')" />
                </v-col>
                <v-col cols="auto">
                    <v-switch
                        :label="$t('Zeitlich relevante Bewertung')"
                        :hint="$t('Kurse, die inaktiv sind, werden in der Berechnung der Note nicht berücksichtigt')"
                        persistent-hint
                        v-model="isTimeRelevant"
                    />
                </v-col>
                <v-col :cols="12">
                    <v-data-table
                        :items="getCourseEvaluations"
                        :headers="filteredHeaders"
                        :no-data-text="$t('Keine Kursteilnehmer verfügbar')"
                        :no-results-text="$t('Kein Ergebnis')"
                        :items-per-page="5"
                        :footer-props="{
                            'items-per-page-text': $t('Kursteilnehmer pro Seite'),
                            'items-per-page-options': [5, 10, 15, 20, { text: $t('Alle'), value: -1 }]
                        }"
                    >
                        <template #item.attendees="{ item, header }">
                            <v-container v-show="header.show">
                                <v-row no-gutters>
                                    <v-col :cols="12">
                                        <span class="primary--text">{{ `${item.user.firstName} ${item.user.lastName}` }}</span>
                                    </v-col>
                                    <v-col :cols="12">
                                        <span class="text-caption grey--text lighten-3">{{ item.user.email }}</span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                        <template #item.evaluation="{ item, header }">
                            <v-container v-show="header.show">
                                <v-row no-gutters justify="center">
                                    <v-col cols="auto">
                                        <span class="primary--text">
                                            {{
                                                `${teachersGrading(item)} ( ${
                                                    item.teacherAssignedScore !== -1 ? `${item.teacherAssignedScore}` : $t('Nicht Bewertet')
                                                } )`
                                            }}
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                        <template #item.self-evaluation="{ item, header }">
                            <v-container v-show="header.show">
                                <v-row no-gutters justify="center">
                                    <v-col cols="auto">
                                        <span class="primary--text">
                                            {{
                                                `${usersGrading(item)} ( ${
                                                    item.selfAssginedScore !== -1 ? `${item.selfAssginedScore}` : $t('Nicht Bewertet')
                                                } )`
                                            }}
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                        <template #item.delivered="{ item, header }">
                            <v-container v-show="header.show">
                                <v-row no-gutters>
                                    <v-col :cols="12">
                                        <span>{{ isActivityAnsweredText(item) }}</span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                        <template #item.assessment="{ item, header }">
                            <v-container v-show="header.show">
                                <v-row no-gutters>
                                    <v-col :cols="12">
                                        <v-btn :disabled="!isActivityAnswered(item)" icon @click.prevent="openEvaluationMenu(item)">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                        <template v-slot:foot>
                            <tr>
                                <td>
                                    <v-container>
                                        <v-row align="center" justify="end">
                                            <v-col cols="auto">
                                                <span>{{ $t('Durchschnitt:') }}</span>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </td>
                                <td>
                                    <v-container>
                                        <v-row no-gutters justify="center">
                                            <v-col cols="auto">
                                                <span class="primary--text">{{ averageTeacherPoints }}</span>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters justify="center">
                                            <v-col cols="auto">
                                                <span class="primary--text">{{ averageTeacherGrade }}</span>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </td>
                                <td>
                                    <v-container>
                                        <v-row no-gutters justify="center">
                                            <v-col cols="auto">
                                                <span class="primary--text">{{ averageAttendeePoints }}</span>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters justify="center">
                                            <v-col cols="auto">
                                                <span class="primary--text">{{ averageAttendeeGrade }}</span>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </td>
                                <td v-for="index in headers.filter((x) => x.show).length - 3" :key="`dummy-footer-${index}`" />
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        <EvaluationTableMenu @menu="(value) => (showEvaluationMenu = value)" :menu="showEvaluationMenu" :value="selectedEvaluation" :type="selectedType" />
    </v-card>
</template>

<script>
import CourseMixin from '../../Mixins/CourseMixin';

import EvaluationSelector from './EvaluationSelector';
import EvaluationTableMenu from './EvaluationTableMenu';

export default {
    name: 'evaluation-table',
    components: { EvaluationSelector, EvaluationTableMenu },
    mixins: [CourseMixin],
    data: () => ({
        selectedType: 'COURSE',
        isTimeRelevant: false,
        showEvaluationMenu: false,
        selectedEvaluation: {}
    }),
    watch: {
        getSelectedAdminCourse(value) {
            if (this.selectedType === 'COURSE' && Object.keys(value).length > 0) {
                this.GET_COURSE_EVALUATIONS(value);
            }
        },
        getSelectedTopic(value) {
            if (this.selectedType === 'TOPIC' && Object.keys(value).length > 0) {
                this.GET_TOPIC_EVALUATIONS(value);
            }
        },
        getSelectedLearningGoal(value) {
            if (this.selectedType === 'LEARNING_GOAL' && Object.keys(value).length > 0) {
                this.GET_LEARNING_GOAL_EVALUATIONS(value);
            }
        },
        getSelectedActivity(value) {
            if (this.selectedType === 'ACTIVITY' && Object.keys(value).length > 0) {
                this.GET_ACTIVITY_EVALUATIONS(value);
                this.GET_ACTIVITY_ANSWERS({ activity: value });
            }
        },
        selectedType(value) {
            switch (value) {
                case 'COURSE': {
                    return this.GET_COURSE_EVALUATIONS(this.getSelectedAdminCourse);
                }
                case 'TOPIC': {
                    return this.GET_TOPIC_EVALUATIONS(this.getSelectedTopic);
                }
                case 'LEARNING_GOAL': {
                    return this.GET_LEARNING_GOAL_EVALUATIONS(this.getSelectedLearningGoal);
                }
                case 'ACTIVITY': {
                    return this.GET_ACTIVITY_EVALUATIONS(this.getSelectedActivity).then(() =>
                        this.GET_ACTIVITY_ANSWERS({
                            activity: this.getSelectedActivity
                        })
                    );
                }
            }
        }
    },
    computed: {
        averageTeacherGrade() {
            if (!(this.getSelectedGradingTable.hasOwnProperty('percentToGradeMap') && this.getSelectedGradingTable.hasOwnProperty('id'))) {
                return this.$t('Keine Bewertung');
            }
            const averagePointsInPercent = (this.averageTeacherPoints / this.selectedObject.score).toFixed(2);
            const grades = Object.entries(this.getSelectedGradingTable.percentToGradeMap).sort(([key1], [key2]) => key1 - key2);
            const grade = grades.find(([key], index, array) => {
                if (array[index + 1] === undefined) {
                    return true;
                }
                return key <= averagePointsInPercent && array[index + 1][0] > averagePointsInPercent;
            });
            if (!grade) {
                return averagePointsInPercent;
            }
            return grade[1];
        },
        averageAttendeeGrade() {
            if (!(this.getSelectedGradingTable.hasOwnProperty('percentToGradeMap') && this.getSelectedGradingTable.hasOwnProperty('id'))) {
                return this.$t('Keine Bewertung');
            }
            const averagePointsInPercent = (this.averageAttendeePoints / this.selectedObject.score).toFixed(2);
            const grades = Object.entries(this.getSelectedGradingTable.percentToGradeMap).sort(([key1], [key2]) => key1 - key2);
            const grade = grades.find(([key], index, array) => {
                if (array[index + 1] === undefined) {
                    return true;
                }
                return key <= averagePointsInPercent && array[index + 1][0] > averagePointsInPercent;
            });
            if (!grade) {
                return averagePointsInPercent;
            }
            return grade[1];
        },
        averageTeacherPoints() {
            return (
                this.getCourseEvaluations
                    .map((evaluation) => {
                        if (!this.getSelectedGradingTable.hasOwnProperty('id')) {
                            return evaluation.teacherAssignedScore;
                        }
                        const grading = evaluation.grading[this.teacherGrading];
                        const find = grading
                            .map((table) => (table.gradingTable !== null ? table.gradingTable.id : null))
                            .indexOf(this.getSelectedGradingTable.id);
                        if (find === -1) {
                            return 0;
                        }
                        return grading[find].grading.achievedPoints;
                    })
                    .reduce((accumulator, evaluation) => {
                        if (evaluation < 0) {
                            return accumulator;
                        }
                        return accumulator + evaluation;
                    }, 0) / this.getCourseEvaluations.length
            ).toFixed(1);
        },
        averageAttendeePoints() {
            return (
                this.getCourseEvaluations
                    .map((evaluation) => {
                        if (!this.getSelectedGradingTable.hasOwnProperty('id')) {
                            return evaluation.selfAssginedScore;
                        }
                        const grading = evaluation.grading[this.userGrading];
                        const find = grading
                            .map((table) => (table.gradingTable !== null ? table.gradingTable.id : null))
                            .indexOf(this.getSelectedGradingTable.id);
                        if (find === -1) {
                            return 0;
                        }
                        return grading[find].grading.achievedPoints;
                    })
                    .reduce((accumulator, evaluation) => {
                        if (evaluation < 0) {
                            return accumulator;
                        }
                        return accumulator + evaluation;
                    }, 0) / this.getCourseEvaluations.length
            ).toFixed(1);
        },
        selectedObject() {
            if (this.selectedType === 'COURSE') return this.getSelectedAdminCourse;
            if (this.selectedType === 'TOPIC') return this.getSelectedTopic;
            if (this.selectedType === 'LEARNING_GOAL') return this.getSelectedLearningGoal;
            if (this.selectedType === 'ACTIVITY') return this.getSelectedActivity;
            return {};
        },
        teacherGrading() {
            return this.isTimeRelevant ? 'timeRelativeTeacherGradings' : 'totalTeacherGradings';
        },
        userGrading() {
            return this.isTimeRelevant ? 'timeRelativeUserGradings' : 'totalUserGradings';
        },
        filteredHeaders() {
            return this.headers.filter((header) => header.show);
        },
        headers() {
            return [
                {
                    text: this.$t('Kursteilnehmer'),
                    value: 'attendees',
                    sortable: false,
                    show: true
                },
                {
                    text: this.$t('Bewertung ( pkt )'),
                    value: 'evaluation',
                    sortable: false,
                    show: true
                },
                {
                    text: this.$t('Eigenbewertung ( pkt )'),
                    value: 'self-evaluation',
                    sortable: false,
                    show: true
                },
                // {
                //     text: this.$t('Notizen'),
                //     value: 'notes',
                //     sortable: false,
                //     show: true
                // },
                {
                    text: this.$t('Abgegeben'),
                    value: 'delivered',
                    sortable: true,
                    show: this.selectedType === 'ACTIVITY'
                },
                {
                    text: this.$t('Bewerten'),
                    value: 'assessment',
                    sortable: false,
                    show: this.selectedType !== 'COURSE'
                }
            ];
        }
    },
    methods: {
        openEvaluationMenu(item) {
            this.showEvaluationMenu = !this.showEvaluationMenu;
            this.selectedEvaluation = item;
        },
        isActivityAnswered({ user: { id: userId } }) {
            if (Object.keys(this.getActivityAnswers).length > 0) {
                const find = this.getActivityAnswers.find(({ user: { id } }) => id === userId);
                if (find) {
                    return find.activityAnswerBasePayload.activity.answered;
                }
            }
            return false;
        },
        isActivityAnsweredText({ user: { id: userId } }) {
            if (Object.keys(this.getActivityAnswers).length > 0) {
                const find = this.getActivityAnswers.find(({ user: { id } }) => id === userId);
                if (find) {
                    return find.activityAnswerBasePayload.activity.answered ? this.$t('Ja') : this.$t('Nein');
                }
            }
            return '';
        },
        teachersGrading(item) {
            if (item.grading.hasOwnProperty(this.teacherGrading)) {
                const find = item.grading[this.teacherGrading]
                    .map((table) => (table.gradingTable !== null && table.gradingTable !== undefined ? table.gradingTable.id : null))
                    .indexOf(this.getSelectedGradingTable.id);
                if (find !== -1) {
                    return item.grading[this.teacherGrading][find].grading.grade;
                }
            }
            return '';
        },
        usersGrading(item) {
            if (item.grading.hasOwnProperty(this.userGrading)) {
                const find = item.grading[this.userGrading]
                    .map((table) => (table.gradingTable !== null && table.gradingTable !== undefined ? table.gradingTable.id : null))
                    .indexOf(this.getSelectedGradingTable.id);
                if (find !== -1) {
                    return item.grading[this.userGrading][find].grading.grade;
                }
            }
            return '';
        }
    },
    mounted() {
        this.GET_COURSE_EVALUATIONS(this.getSelectedAdminCourse);
    }
};
</script>
