<template>
    <v-card text>
        <v-card-text>
            <v-text-field :label="$t('Name')" v-model="name" />
        </v-card-text>
        <v-card-actions>
            <v-btn block :color="submitButtonColor" @click="submit">
                {{ submitButtonText }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'add-update-knowledge-content-folder',
    data: () => ({
        id: '',
        name: '',
        parentId: ''
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        type: {
            type: String,
            default: 'ADD'
        },
        onSubmit: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        submitButtonColor() {
            return this.type === 'ADD' ? 'success' : 'warning';
        },
        submitButtonText() {
            return this.type === 'ADD' ? this.$t('Ordner erstellen') : this.$t('Ordner bearbeiten');
        }
    },
    methods: {
        reset() {
            this.id = '';
            this.name = '';
            this.parentId = '';
        },
        deserialize() {
            Object.entries(this.value).forEach(([key, value]) => {
                if (this.$data.hasOwnProperty(key)) {
                    this.$data[key] = value;
                }
            });
        },
        serialize() {
            return {
                id: this.id,
                name: this.name,
                parentId: this.parentId
            };
        },
        submit() {
            this.onSubmit()(this.serialize());
        }
    },
    activated() {
        if (this.type === 'ADD') {
            this.reset();
            if (this.value.hasOwnProperty('id')) {
                this.parentId = this.value.id;
            }
        } else {
            this.deserialize();
        }
    }
};
</script>
