<template>
    <v-card height="100%">
        <v-container grid-list-md pa-3 ma-0 fluid fill-height>
            <v-col>
                <v-row>
                    <v-autocomplete
                        v-model="computedSelectedFaq"
                        :search-input.sync="computedContentSearch"
                        :items="getFaqList"
                        return-object
                        item-value="id"
                        item-text="title"
                        :loading="contentDebounce !== null"
                        :no-filter="contentDebounce !== null"
                        :disabled="getFaqList.length === 0"
                        :filter="filterContent"
                        :label="$t('FAQ Suchen')"
                        :hint="$t('Geben Sie Begriffe oder Schlagwörter ein, um nach FAQs zu suchen')"
                        persistent-hint
                        :no-data-text="$t('Kein FAQ')"
                        :no-results-text="$t('Kein Ergebnis')"
                    >
                        <template v-slot:selection="{ item, selected }">
                            <v-chip color="primary" dark :value="selected">
                                {{ item.question }}
                            </v-chip>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-list-item-action>
                                <v-icon medium :class="getStateColor(item)">mdi-help-circle-outline</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.question }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ computeTags(item) }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </v-autocomplete>
                </v-row>
                <v-row>
                    <v-col :cols="12">
                        <keep-alive>
                            <component
                                :is="componentSwitch"
                                :onSubmit="onSubmit"
                                :onClose="reset"
                                :type="getView.type"
                                :value="getView.data"
                                @switchView="handleSwitchView"
                            />
                        </keep-alive>
                    </v-col>
                </v-row>
                <v-row>
                    <v-divider class="my-3" />
                </v-row>
                <v-row>
                    <v-col>
                        <v-card>
                            <v-card-text>
                                <v-toolbar dark flat color="secondary">
                                    <v-toolbar-title>
                                        {{ $t('Letzte Änderungen') }}
                                    </v-toolbar-title>
                                </v-toolbar>
                                <v-list two-line>
                                    <v-list-item v-for="faq in getRecentChanges" :key="faq.id" @click="onClick(faq)">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <span class="primary--text">{{ faq.question }}</span>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ computeTags(faq) }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card>
                            <v-card-text>
                                <v-toolbar dark flat color="secondary">
                                    <v-toolbar-title>
                                        {{ $t('Beliebteste FAQs') }}
                                    </v-toolbar-title>
                                </v-toolbar>
                                <v-list v-if="hasBestRated" two-line>
                                    <v-list-item v-for="faq in getBestRatedFAQ" :key="faq.id" @click="onClick(faq)">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <span class="primary--text">{{ faq.question }}</span>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ computeTags(faq) }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                <v-list v-else>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <span>{{ $t('Nicht genug Bewertungen') }}</span>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-divider class="my-3" />
                </v-row>
                <v-row v-if="!(getLoggedInAdmin.role == 'ROOT' || getLoggedInAdmin.role == 'SALES')">
                    <v-col :cols="12">
                        <FAQRequest />
                    </v-col>
                </v-row>
            </v-col>
        </v-container>
    </v-card>
</template>
<script>
import FAQMixin from './Mixins/FAQMixins';

import DeleteConfirm from '../../Components/Generic/DeleteConfirm';
import Editor from '../../Components/Generic/Editor/Editor';
import FAQList from './FAQList';
import AddUpdateFAQGroup from './Helpers/AddUpdateFAQGroup';
import FAQGroupDeleteConfirm from './Helpers/FAQGroupDeleteConfirm.vue';
import AddUpdateFAQ from '../../Components/FAQ/Helpers/AddUpdateFAQ';
import FAQRequest from './FAQRequest.vue';

export default {
    name: 'faq-homepage',
    mixins: [FAQMixin],
    components: {
        DeleteConfirm,
        Editor,
        FAQList,
        AddUpdateFAQGroup,
        FAQGroupDeleteConfirm,
        AddUpdateFAQ,
        FAQRequest
    },
    data: () => ({
        contentSearch: '',
        contentConfirm: '',
        contentDebounce: null
    }),
    computed: {
        componentSwitch() {
            if (this.getView.component !== '') {
                switch (this.getView.component) {
                    case 'ADD_FAQ_GROUP':
                        return AddUpdateFAQGroup;
                    case 'UPDATE_FAQ_GROUP':
                        return AddUpdateFAQGroup;
                    case 'DELETE_FAQ_GROUP':
                        return FAQGroupDeleteConfirm;
                    case 'ADD_FAQ':
                        this.$emit('addFaq', this.getView.data.id);
                        return FAQList;
                }
            }
            return FAQList;
        },
        hasBestRated() {
            return Object.keys(this.getBestRatedFAQ).length > 0;
        },
        computedContentSearch: {
            get() {
                return this.contentSearch;
            },
            set(value) {
                this.contentSearch = value;
                if (this.contentDebounce) {
                    clearTimeout(this.contentDebounce);
                    this.contentDebounce = null;
                }
                if (value !== '') {
                    this.contentDebounce = setTimeout(
                        function () {
                            this.data.contentConfirm = this.search;
                            this.data.contentDebounce = null;
                        }.bind({ data: this, search: value }),
                        1500
                    );
                } else {
                    clearTimeout(this.contentDebounce);
                    this.contentDebounce = null;
                    this.contentConfirm = '';
                }
            }
        },
        computedSelectedFaq: {
            get() {
                return this.getSelectedFaq;
            },
            set(value) {
                this.setSelection(value);
            }
        }
    },
    methods: {
        computeTags({ tags }) {
            return tags ? tags : '';
        },
        setSelection(value) {
            if (!!value && Object.keys(value).length > 0) {
                this.GET_SINGLE_FAQ(value);
                this.$emit('getFaq', this.getSelectedFaq);
            }
        },
        getStateColor(faq) {
            if (faq && faq.state && faq.state.stateName) {
                switch (faq.state.stateName) {
                    case 'PENDING':
                        return 'warning--text';
                    case 'APPROVED':
                        return 'success--text';
                    case 'DECLINED':
                        return 'error--text';
                }
                return 'info--text';
            }
        },
        onSubmit() {
            switch (this.getView.component) {
                case 'ADD_FAQ_GROUP':
                    return (v) => this.ADD_FAQ_GROUP(v).then(() => this.reset());
                case 'UPDATE_FAQ_GROUP':
                    return (v) => {
                        this.UPDATE_FAQ_GROUP({
                            id: v.id,
                            request: { name: v.name }
                        }).then(() => this.reset());
                    };
                case 'DELETE_FAQ_GROUP':
                    return (v) => this.DELETE_FAQ_GROUP(v).then(() => this.reset());
            }
            this.reset();
            return () => {};
        },
        reset() {
            this.SET_VIEW({ component: '', type: '', data: {} });
        },
        handleSwitchView(value) {
            if (value.hasOwnProperty('component')) {
                this.SET_VIEW(value);
            } else {
                this.$emit('getFaq', value);
            }
        },
        onClick(faq) {
            this.GET_SINGLE_FAQ(faq);
            this.$emit('getFaq', this.getSelectedFaq);
        },
        filterContent(item) {
            if (!this.contentConfirm || this.contentConfirm.trim() === '') {
                return true;
            }
            let buildString = [...new Set(this.contentConfirm.split(' '))].filter((word) => word !== '').map((word) => word.toLowerCase());
            const text = Object.values(item)
                .filter((word) => typeof word === 'string' || typeof word === 'number')
                .map((word) => {
                    if (typeof word === 'number') {
                        return word.toString();
                    }
                    return word;
                })
                .join(' ')
                .toLowerCase();
            return buildString.some((word) => text.includes(word));
        }
    }
};
</script>
