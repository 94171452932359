<template>
    <v-data-iterator
        :items="computedItems"
        :options.sync="pagination"
        :no-data-text="$t(`Keine ${title} verfügbar`)"
        :no-results-text="$t('Kein Ergebnis')"
        :footer-props="{
            'items-per-page-text': $t(`${title} pro Seite`),
            'items-per-page-options': [5, 10, 15, { value: -1, text: $t('Alle') }]
        }"
        :items-per-page="5"
        :hideDefaultFooter="computePaginationDetails || items.length === 0"
        :search="search"
        :custom-sort="evaluateSelectedSort"
        :custom-filter="searchFilter"
        @update:pagination="onPagination"
    >
        <template v-slot:header>
            <slot v-bind:headerPrepend="items" name="headerPrepend" />
            <v-expand-transition>
                <v-card flat v-show="showOptions">
                    <v-container>
                        <v-row align="center">
                            <v-col :cols="12">
                                <v-text-field
                                    color="accent"
                                    prepend-icon="mdi-magnify"
                                    :label="$t(`${title} durchsuchen`)"
                                    :hint="$t('Geben Sie Begriffe oder Schlagwörter ein')"
                                    v-model="computedSearch"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="sortArray.length > 0" align="center" justify="center">
                            <v-col>
                                <v-select
                                    color="accent"
                                    prepend-icon="mdi-sort"
                                    attach
                                    :hint="$t(`Wählen Sie eine Methode aus, nach der Sie die ${title} sortieren wollen`)"
                                    return-object
                                    persistent-hint
                                    v-model="computeSelectedSort"
                                    item-value="name"
                                    :items="sortArray"
                                >
                                    <template v-slot:item="{ item }">
                                        <div>{{ $t(item.name) }}</div>
                                    </template>
                                    <template v-slot:selection="{ item }">
                                        <div>{{ $t(item.name) }}</div>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn icon @click="reverseList">
                                    <v-icon>mdi-swap-vertical</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-expand-transition>
            <slot v-bind:headerAppend="items" name="headerAppend"></slot>
        </template>
        <template v-slot:item="items">
            <component :is="isExpanded" v-bind="$props" :item="items.item" @input="selectRow" v-if="!refresh">
                <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
                    <slot :name="slot" v-bind="scope" />
                </template>
            </component>
        </template>
    </v-data-iterator>
</template>

<script>
import GroupTile from './Helpers/ListMoreActionGroup';
import Tile from './Helpers/ListMoreActionTile';

export default {
    name: 'advanced-list',
    components: { GroupTile, Tile },
    data: () => ({
        search: '',
        selectedSort: {},
        pagination: {},
        refresh: false,
        searchDebounceTimer: null
    }),
    props: {
        /**
         * applies filter functions to items.
         * bind additional parameters with .bind
         * handle additional info in filter function with 'this'
         * first parameter must accept array
         * return type must be of type array
         */
        filterFunctions: {
            type: Array,
            default: () => []
        },
        value: {
            type: [Object, Array],
            default: () => ({})
        },
        title: {
            type: String,
            default: ''
        },
        items: {
            type: Array,
            default: () => []
        },
        sortArray: {
            type: Array,
            default: () => []
        },
        /**
         * '.'-Notation is supported. ex: ['test.one', 'test.one.two'].
         * If the key contains a Array value, search will be applied to the Array
         */
        itemKeys: {
            type: Array,
            default: () => ['name', 'description']
        },
        showAction: {
            type: Boolean,
            default: true
        },
        showAppend: {
            type: Boolean,
            default: true
        },
        showOptions: {
            type: Boolean,
            default: false
        },
        expand: {
            type: Boolean,
            default: false
        },
        itemKey: {
            type: String,
            default: 'id'
        }
    },
    computed: {
        isExpanded() {
            if (this.expand) {
                return GroupTile;
            }
            return Tile;
        },
        /**
         * Debounce the search input. sets search after 300ms of not typing
         */
        computedSearch: {
            get() {
                return this.search;
            },
            set(value) {
                clearTimeout(this.searchDebounceTimer);
                this.searchDebounceTimer = setTimeout(() => {
                    this.search = value;
                }, 300);
            }
        },
        computedItems() {
            let items = [...this.items];
            for (let index = 0; index < this.filterFunctions.length; index++) {
                items = this.filterFunctions[index](items);
            }
            return items;
        },
        computePaginationDetails() {
            // divide by the minimum rowsPerPage
            return this.items.length === 0 || this.items.length / 5 <= 1;
        },
        computeSelectedSort: {
            get() {
                if (Object.keys(this.selectedSort).length > 0) {
                    if (this.selectedSort.hasOwnProperty('sort')) {
                        return this.selectedSort;
                    }
                }
                if (this.sortArray.length > 0) {
                    return this.sortArray[0];
                }
                return (items) => items;
            },
            set(value) {
                this.selectedSort = value;
            }
        },
        evaluateSelectedSort() {
            if (this.computeSelectedSort.hasOwnProperty('sort')) {
                return this.computeSelectedSort.sort;
            }
            return this.computeSelectedSort;
        }
    },
    methods: {
        onPagination() {
            if (this.expand) {
                this.refresh = true;
                this.$nextTick(() => (this.refresh = false));
            }
        },
        highlight(item) {
            if (Array.isArray(this.value)) {
                return this.value.map((value) => value.id).indexOf(item.id) !== -1;
            }
            return this.value.id === item.id;
        },
        reverseList() {
            this.$nextTick(() => (this.pagination.descending = !this.pagination.descending));
        },
        selectRow(item) {
            this.$emit('input', item);
        },
        selectAction(item) {
            this.$emit('action', item);
        },
        traverseObject(keys, item, regex) {
            if (typeof keys === 'string') {
                if (keys.split('.') === undefined || keys.split('.') === null) {
                    keys = [keys];
                } else {
                    keys = keys.split('.');
                }
            }
            if (Array.isArray(item)) {
                return item.some((object) => {
                    if (object instanceof Object) {
                        return regex.test(Object.values(object).join(' '));
                    }
                    return regex.test(object);
                });
            }
            if (keys.length === 0) {
                if (item === undefined || item === null || item instanceof Object) {
                    return false;
                }
                return regex.test(item);
            }
            const find = keys.shift();
            if (find !== undefined && find !== null && item !== null && item !== undefined) {
                if (item.hasOwnProperty(find)) {
                    item = item[find];
                    if (item === null || item === undefined) {
                        return false;
                    }
                    return this.traverseObject(keys, item, regex);
                }
            }

            return false;
        },
        searchFilter(items, search) {
            if (search.trim() === '') {
                return items;
            }
            let stringBuilder = search
                .split(' ')
                .map((word) => `(?=.*${word})`)
                .filter((word) => word !== '')
                .map((word) => `(?=.*${word})`)
                .join('');

            let regex = new RegExp(stringBuilder, 'i');

            return items.filter((item) => this.itemKeys.some((key) => this.traverseObject(key, item, regex)));
        }
    }
};
</script>
