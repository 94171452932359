import UserArchive from '../../views/UserApp/UserArchive';
import UserArchiveAdd from '../../views/Archive/ArchiveAdd';
import UserArchiveUpdate from '../../views/Archive/ArchiveUpdate.vue';
import UserArchiveDelete from '../../views/Archive/ArchiveDelete.vue';
import UserArchiveCatalog from '../../views/Archive/ArchiveCatalog';
import UserChangePassword from '../../views/UserApp/UserChangePassword';
import UserDashboard from '../../views/UserApp/UserStatistics/UserDashboard';
import UserTasks from '../../views/UserApp/UserTasks';
import UserQuiz from '../../views/UserApp/UserQuiz';
import UserCertficate from '../../views/UserApp/UserCertificate';

import { superAdmin, admin, user } from '../Roles';

export default [
    /**
     * App
     */
    {
        path: '/App/Quiz',
        name: 'appQuiz',
        component: UserQuiz,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin, admin, user]
        }
    },
    {
        path: '/App/',
        name: 'userLandingPage',
        component: UserTasks,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin, admin, user],
            navigation: {
                type: { name: 'Groups', groupId: 'Meine Lernumgebung' },
                priority: 0,
                icon: 'mdi-clipboard-check-outline',
                name: 'Meine Aufgaben',
                badge: 'ChatOperations/getActiveTaskCount',
                navigationKey: 'MY_TASKS'
            }
        }
    },
    {
        path: '/App/Archive',
        name: 'appArchive',
        component: UserArchive,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin, admin, user],
            navigation: {
                type: { name: 'Groups', groupId: 'Meine Lernumgebung' },
                priority: 0,
                icon: 'mdi-archive',
                name: 'Mein Archiv',
                navigationKey: 'MY_ARCHIVE'
            }
        }
    },
    {
        path: '/App/Certificate',
        name: 'appCertificate',
        component: UserCertficate,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin, admin, user],
            navigation: {
                type: { name: 'Groups', groupId: 'Meine Lernumgebung' },
                priority: 0,
                icon: 'mdi-certificate',
                name: 'Meine Zertifikate',
                navigationKey: 'MY_CERTIFICATES'
            }
        }
    },
    {
        path: '/App/Archive/Add',
        name: 'appArchiveAdd',
        component: UserArchiveAdd,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin, admin, user]
        }
    },
    {
        path: '/App/Archive/Update',
        name: 'appArchiveUpdate',
        component: UserArchiveUpdate,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin, admin, user]
        }
    },
    {
        path: '/App/Archive/Delete',
        name: 'appArchiveDelete',
        component: UserArchiveDelete,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin, admin, user]
        }
    },
    {
        path: '/App/Archive/Catalog',
        name: 'appArchiveCatalog',
        component: UserArchiveCatalog,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin, admin, user]
        }
    },
    {
        path: '/App/ChangePassword',
        name: 'appChangePassword',
        component: UserChangePassword,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin, admin, user],
            navigation: {
                type: { name: 'Groups', groupId: 'Meine Lernumgebung' },
                priority: 0,
                icon: 'mdi-key',
                name: 'Mein Passwort Ändern',
                navigationKey: 'CHANGE_PASSWORD'
            }
        }
    },
    {
        path: '/App/Dashboard',
        name: 'appDashboard',
        component: UserDashboard,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin, admin, user],
            navigation: {
                type: { name: 'Groups', groupId: 'Meine Lernumgebung' },
                priority: 0,
                icon: 'mdi-chart-bar-stacked',
                name: 'Meine Reports',
                navigationKey: 'MY_REPORTS'
            }
        }
    }
];
