<template>
    <div>
        <v-tabs slider-color="accent" v-model="tabs" mandatory show-arrows>
            <v-tab key="OVERVIEW">{{ $t('Übersicht') }}</v-tab>
            <v-tab key="LEARNING_GOALS">{{ computedTabTitle }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs" touchless>
            <v-tab-item key="OVERVIEW">
                <v-card>
                    <v-card-text>
                        <v-layout column>
                            <v-flex>
                                <span class="text-h6">{{ getSelectedTopic.name }}</span>
                            </v-flex>
                            <v-flex>
                                <span>{{ getSelectedTopic.description }}</span>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-tab-item>

            <v-tab-item key="LEARNING_GOALS">
                <CourseBreadcrumbs />
                <v-data-iterator
                    :items="learningGoals"
                    :no-data-text="$t('Keine Lernziele verfügbar')"
                    :no-results-text="$t('Kein Ergebnis')"
                    :hideDefaultFooter="true"
                    row
                    wrap
                >
                    <template #default="{ items }">
                        <v-container>
                            <v-row>
                                <v-col v-for="item in items" :key="item.id" :xs="12" :sm="12" :md="6">
                                    <MyLearningGoal
                                        :value="item"
                                        :timeScaleInMinutes="selectedCourse.timeScaleInMinutes"
                                        :isMyCourse="true"
                                        @activity="handleActivity"
                                        @selectedKnowledgeContent="handleSelectedKnowledgeContent"
                                        :selectedKnowledgeContent="selectedKnowledgeContent"
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>
                </v-data-iterator>
            </v-tab-item>
        </v-tabs-items>
        <KnowledgeContentPreview toolbarTitle="Content" v-model="computedSelectedKnowledgeContent" />
    </div>
</template>

<script>
import CourseBreadcrumbs from '../CourseManager/Breadcrumbs/CourseBreadcrumbs';
import MyLearningGoal from './Helpers/MyLearningGoal';
import moment from 'moment';
import CourseMixin from '../CourseManager/Mixins/CourseMixin';
import KnowledgeContentPreview from '../../KnowledgeContent/KnowledgeContentPreview';

export default {
    name: 'my-learning-goals',
    components: { CourseBreadcrumbs, MyLearningGoal, KnowledgeContentPreview },
    mixins: [CourseMixin],
    data: () => ({
        tabs: 1,
        learningGoals: [],
        selectedKnowledgeContent: []
    }),
    computed: {
        computedTabTitle() {
            if (this.selectedCourse.learningGoalTypeName === null && this.getSelectedTopic.learningGoalTypeName === null) {
                return `${this.$t('Meine Kurse')} - ${this.$t('Lernziele')}`;
            } else if (this.getSelectedTopic.learningGoalTypeName !== null) {
                return `${this.$t('Meine Kurse')} - ${this.getSelectedTopic.learningGoalTypeName}`;
            }
            return `${this.$t('Kursmanager')} - ${this.selectedCourse.learningGoalTypeName}`;
        },
        selectedLearningGoal: {
            get() {
                return this.getSelectedLearningGoal;
            },
            set(value) {
                this.SET_SELECTED_LEARNING_GOAL(value);
            }
        },
        selectedCourse: {
            get() {
                if (this.isAdmin) {
                    return this.getSelectedAdminCourse;
                }
                return this.getSelectedAttendeeCourse;
            },
            set(value) {
                if (this.isAdmin) {
                    this.SET_SELECTED_ADMIN_COURSE(value);
                } else {
                    this.SET_SELECTED_ATTENDEE_COURSE(value);
                }
            }
        },
        computedSelectedKnowledgeContent: {
            get() {
                if (this.selectedKnowledgeContent.length > 0) {
                    return this.selectedKnowledgeContent[0];
                }
                return {};
            },
            set() {
                this.selectedKnowledgeContent = [];
            }
        }
    },
    methods: {
        handleSelectedKnowledgeContent(value) {
            if (value.length > 0) {
                this.selectedKnowledgeContent = [value[value.length - 1]];
            } else {
                this.selectedKnowledgeContent = [];
            }
        },
        handleActivity(value) {
            this.$emit('activity', value);
        }
    },
    mounted() {
        this.GET_TOPIC_LEARNING_GOALS(this.getSelectedTopic).then(
            (response) =>
                (this.learningGoals = response.filter(
                    (filteredGoal) => filteredGoal.startDate <= moment().format('YYYY-MM-DD') || filteredGoal.hideBeforeStart === false
                ))
        );
        // this.REMOVE_COURSE_BREADCRUMB(this.getSelectedLearningGoal);
    }
};
</script>
