<template>
    <v-treeview :items="computedTree" :search="search" :filter="filter" return-object class="pt-2" item-key="treeId" dense>
        <template v-slot:prepend="{ item, open }">
            <v-icon :color="iconColor(item)">
                {{ icon(item, open) }}
            </v-icon>
        </template>
        <template v-slot:label="{ item, active }">
            <v-row no-gutters class="flex-nowrap">
                <v-col cols="auto" v-if="isUserOrGroup(item) === 'GROUP'">
                    <v-simple-checkbox
                        :ripple="false"
                        :value="isGroupChecked(item)"
                        :indeterminate="isGroupIndeterminate(item)"
                        @input="handleGroup(item)"
                        :color="!isGroupIndeterminate(item) ? 'accent' : ''"
                    />
                </v-col>
                <v-col cols="auto" v-else>
                    <v-simple-checkbox
                        color="accent"
                        :ripple="false"
                        :value="isChecked(item)"
                        :disabled="isDisabled(item)"
                        @input="handleCheck($event, item)"
                    />
                </v-col>
                <v-col>
                    <span>{{ item.name || getDisplay(item) }}</span>
                </v-col>
            </v-row>
        </template>
    </v-treeview>
</template>

<script>
import UserMixin from '../../../Users/Mixins/UserMixin';

export default {
    name: 'user-group-tree',
    mixins: [UserMixin],
    props: {
        value: {
            type: Array,
            default: () => []
        },
        tree: {
            type: Array,
            default: () => []
        },
        showSelf: {
            type: Boolean,
            default: false
        },
        search: {
            type: String,
            default: ''
        },
        assignedUsers: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        computedTree() {
            return this.showSelf ? [this.getLoggedInAdmin, ...this.tree] : this.tree;
        },
        computedSelect: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        filter(item, search) {
            if (!search || search.trim() === '') {
                return true;
            }
            let buildString = [...new Set(search.split(' '))].filter((word) => word !== '').map((word) => word.toLowerCase());
            const text = Object.values(item)
                .filter((data) => typeof data === 'string' || typeof data === 'number')
                .map((data) => {
                    if (typeof data === 'number') {
                        return data.toString();
                    }
                    return data;
                })
                .join(' ')
                .toLowerCase();
            return buildString.every((word) => text.includes(word));
        },
        isGroupChecked(group) {
            const combinedList = [...this.value, ...this.assignedUsers];
            const users = this.getTreeItemCascade(group)
                .filter((object) => this.isUserOrGroup(object) === 'USER')
                .map(({ id }) => id);
            if (!users || users.length === 0) {
                return false;
            }
            const selectedUsers = combinedList.map(({ id }) => id);
            return users.every((userId) => selectedUsers.includes(userId));
        },
        isGroupIndeterminate(group) {
            const combinedList = [...this.value, ...this.assignedUsers];
            const users = this.getTreeItemCascade(group)
                .filter((object) => this.isUserOrGroup(object) === 'USER')
                .map(({ id }) => id);
            return this.isGroupChecked(group) ? false : users.some((userId) => combinedList.map(({ id }) => id).includes(userId));
        },
        handleGroup(group) {
            if (this.isGroupChecked(group)) {
                const users = this.getTreeItemCascade(group)
                    .filter((object) => this.isUserOrGroup(object) === 'USER')
                    .map(({ id }) => id);
                this.$emit(
                    'input',
                    this.value.filter(({ id }) => !users.includes(id))
                );
            } else {
                this.$emit(
                    'input',
                    [...this.value, ...this.getTreeItemCascade(group).filter((object) => this.isUserOrGroup(object) === 'USER')].filter(
                        (item, pos, self) => self.indexOf(item) == pos
                    )
                );
            }
        },
        iconColor(item) {
            if (this.isUserOrGroup(item) === 'USER') {
                return item.active ? 'success' : 'warning';
            }
            return '';
        },
        icon(item, open) {
            if (item.hasOwnProperty('name')) {
                return open && item.children && item.children.length > 0 ? 'mdi-account-multiple-outline' : 'mdi-account-multiple';
            }
            return 'mdi-account';
        },
        isChecked({ id }) {
            const combinedList = [...this.value, ...this.assignedUsers];
            return combinedList.map((user) => user.id).includes(id);
        },
        isDisabled({ id }) {
            return this.assignedUsers.map((user) => user.id).includes(id);
        },
        handleCheck(event, item) {
            if (event) {
                this.$emit('input', [...this.value, item]);
            } else {
                this.$emit(
                    'input',
                    this.value.filter((val) => val.id !== item.id)
                );
            }
        }
    }
};
</script>
<style scoped>
.v-treeview {
    height: 100%;
    width: max-content;
}
</style>
