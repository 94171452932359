<template>
    <v-select
        color="accent"
        flat
        v-model="selectedLanguage"
        :items="getAvailableLanguages"
        item-value="shortLang"
        :label="$t('Sprache')"
        class="ma-2"
        hide-details
        menu-props="auto"
    >
        <template v-slot:selection="{ item }">
            <div>{{ convertMessage(item) }}</div>
        </template>
        <template v-slot:item="{ item, on, attrs }">
            <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-content>
                    <v-list-item-title>{{ convertMessage(item) }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'language-selector',
    watch: {
        getSelectedLanguage(change) {
            this.$store.state.AppLanguage.selectedLanguage = change;
            this.$i18n.locale = change;
        }
    },
    computed: {
        ...mapGetters('LanguageOperations', ['getSelectedLanguage', 'getAvailableLanguages']),
        selectedLanguage: {
            get() {
                return this.getSelectedLanguage;
            },
            set(value) {
                this.SET_SELECTED_LANGUAGE(value);
            }
        }
    },
    methods: {
        ...mapActions('LanguageOperations', ['SET_SELECTED_LANGUAGE']),
        convertMessage({ longLang, shortLang }) {
            if (shortLang === 'us') {
                return `[EN] ${longLang}`;
            }
            return `[${shortLang.toUpperCase()}] ${longLang}`;
        }
    }
};
</script>
