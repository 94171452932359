<template>
    <v-card class="transparent elevation-0" v-if="getTotalLicences">
        <v-card-text>
            <v-layout wrap>
                <v-layout column align-center>
                    <v-progress-circular size="80" color="success" :width="15" :value="100">
                        {{ getTotalLicences }}
                    </v-progress-circular>
                    <span class="success--text text-center">{{ $t('Gesamte Lizenzen') }}</span>
                </v-layout>

                <v-layout column align-center>
                    <v-progress-circular size="80" color="warning" :width="15" :value="percentRemaining">
                        {{ getRemainingLicences }}
                    </v-progress-circular>
                    <span class="warning--text text-center">{{ $t('Verbleibende Lizenzen') }}</span>
                </v-layout>

                <v-layout column align-center>
                    <v-progress-circular size="80" color="error" :width="15" :value="percentConsumed">
                        {{ getConsumedLicences }}
                    </v-progress-circular>
                    <span class="error--text text-center">{{ $t('Verbrauchte Lizenzen') }}</span>
                </v-layout>
            </v-layout>
        </v-card-text>
    </v-card>
</template>
<script>
import UserMixin from './Mixins/UserMixin.js';

export default {
    name: 'licences',
    mixins: [UserMixin],
    computed: {
        percentRemaining() {
            return (this.getRemainingLicences / this.getTotalLicences) * 100;
        },
        percentConsumed() {
            return (this.getConsumedLicences / this.getTotalLicences) * 100;
        }
    },
    activated() {
        this.GET_LICENCES();
    }
};
</script>
