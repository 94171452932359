<template>
    <v-container fluid ma-0 pa-0 fill-height>
        <v-layout column>
            <v-flex shrink>
                <Toolbar
                    :toolbarTitle="$t('Content')"
                    showDrawer
                    :lastPath="-1"
                    tooltipMessage="Hier können Sie Contents und Ordner ansehen, hinzufügen, bearbeiten und löschen."
                />
            </v-flex>
            <v-flex grow>
                <KnowledgeContentManager />
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import Toolbar from '../../Components/Generic/Toolbar';
import KnowledgeContentMixin from '../../Components/KnowledgeContent/Mixins/KnowledgeContentMixin';

import KnowledgeContentManager from '../../Components/KnowledgeContent/KnowledgeContentManager';

export default {
    name: 'knowledge-content',
    mixins: [KnowledgeContentMixin],
    components: {
        Toolbar,
        KnowledgeContentManager
    },
    beforeMount() {
        this.GET_KNOWLEDGE_CONTENTS();
    }
};
</script>
