<template>
    <v-card flat>
        <v-toolbar flat dense color="transparent">
            <v-spacer />
            <v-toolbar-items>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon color="success" @click="createFolder" :disabled="!computedPermission">
                            <v-icon medium>mdi-folder-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Ordner erstellen') }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="isFolderSelected">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon color="warning" @click="updateFolder" :disabled="!computedPermission">
                            <v-icon medium>mdi-folder-edit</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Ordner bearbeiten') }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="isFolderSelected">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon color="error" @click="deleteFolder" :disabled="!computedPermission">
                            <v-icon medium>mdi-folder-remove</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Ordner löschen') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon color="success" @click="uploadFile" :disabled="!computedPermission">
                            <v-icon medium>mdi-file-upload</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Datei hochladen') }}</span>
                </v-tooltip>
            </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-subheader v-show="isFolderSelected">{{ $t('Zusätzliche Infos') }}</v-subheader>
        <v-list v-show="isFolderSelected">
            <v-list-item @click.stop>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('Erstellt') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ formatDate(value.createdAt) }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item @click.stop>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('Bearbeitet') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ formatDate(value.updatedAt) }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item @click.stop>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('Erstellt von') }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <UserProfile :value="value.createdBy" small :displayName="false" />
                </v-list-item-action>
            </v-list-item>
            <v-list-item @click.stop>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('Bearbeitet von') }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <UserProfile :value="value.updatedBy" small :displayName="false" />
                </v-list-item-action>
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>
import UserProfile from '../../../Generic/Profile/UserProfile';
import moment from 'moment';

export default {
    name: 'static-folder-selection',
    components: {
        UserProfile
    },
    props: {
        permissions: {
            type: Object,
            default: () => ({})
        },
        value: {
            type: Object,
            default: () => ({})
        },
        path: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        computedPermission() {
            if (Object.keys(this.value).lengh > 0) {
                return this.value.canChange;
            }
            return true;
        },
        isFolderSelected() {
            return Object.keys(this.value).length > 0;
        }
    },
    methods: {
        uploadFile() {
            this.$emit('switchView', {
                component: 'UPLOAD_FILE',
                type: 'UPDATE',
                data: {}
            });
        },
        createFolder() {
            this.$emit('switchView', {
                component: 'CREATE_FOLDER',
                type: 'CREATE',
                data: {}
            });
        },
        updateFolder() {
            this.$emit('switchView', {
                component: 'UPDATE_FOLDER',
                type: 'UPDATE',
                data: {}
            });
        },
        deleteFolder() {
            this.$emit('switchView', {
                component: 'DELETE_FOLDER',
                type: 'DELETE',
                data: {}
            });
        },
        moveFolder() {
            this.$emit('switchView', {
                component: 'MOVE_FOLDER',
                type: 'MOVE',
                data: {}
            });
        },
        formatDate(value) {
            return moment(value).format('YYYY-MM-DD, HH:mm');
        }
    }
};
</script>
