import Vue from 'vue';
import axios from '../../plugins/Axios/axios';

const state = {
    tasks: {}
};

const getters = {
    getTasks(state) {
        return Object.values(state.tasks).sort((a, b) =>
            a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: 'base'
            })
        );
    },
    getTaskMap(state) {
        return state.tasks;
    }
};

const actions = {
    LOAD_ALL_TASK_OWNERS({ commit, getters, dispatch }) {
        return Promise.all(
            getters.getTasks.map((task) =>
                dispatch('GET_TASK_OWNERS', task).then((response) => ({
                    ...task,
                    owners: response
                }))
            )
        ).then((result) => {
            commit('updateTasks', result);
            return result;
        });
    },
    LOAD_ALL_TASK_USERS({ commit, getters, dispatch }) {
        return Promise.all(
            getters.getTasks.map((task) =>
                dispatch('GET_TASK_USERS', task).then((response) => ({
                    ...task,
                    users: response
                }))
            )
        ).then((result) => {
            commit('updateTasks', result);
            return result;
        });
    },
    ADD_TASK({ commit, dispatch }, request) {
        return axios
            .post('/tasks', request)
            .then((response) => response.data.data)
            .then((task) => {
                commit('updateTasks', task);
                return task;
            });
    },
    GET_ACTIVE_TASKS({ commit }) {
        return axios
            .get(`/tasks?isActive=true`)
            .then(({ data: { data: response } }) => response.tasks)
            .then((response) => {
                commit('updateTasks', response);
                return response;
            });
    },
    GET_TASK_KNOWLEDGE_UNITS({ commit }, { id: taskId }) {
        return axios
            .get(`/tasks/${taskId}/knowledgeunits`)
            .then((response) => response.data.data)
            .then((response) => {
                return response;
            });
    },
    GET_TASK_OWNERS({ commit }, { id: taskId }) {
        return axios.get(`/tasks/${taskId}/owners`).then((response) => response.data.data);
    },
    GET_TASK_USERS({ commit }, { id }) {
        return axios
            .get(`/tasks/${id}/users`)
            .then((response) => response.data.data)
            .then((response) => {
                return response;
            });
    },
    GET_INACTIVE_TASKS({ commit }) {
        return axios
            .get(`/tasks?isActive=false`)
            .then(({ data: { data: response } }) => response.tasks)
            .then((response) => {
                commit('updateTasks', response);
                return response;
            });
    },
    GET_TASK({ commit }, { id: taskId }) {
        return axios
            .get(`/tasks/${taskId}`)
            .then((respone) => respone.data.data)
            .then((response) => {
                commit('updateTasks', response);
                return response;
            });
    },
    DELETE_TASK({ commit }, { id: taskId }) {
        return axios.delete(`/tasks/${taskId}`).then((response) => {
            commit('deleteTask', taskId);
            return response;
        });
    },
    UPDATE_TASK({ commit }, { id, ...task }) {
        return axios
            .patch(`/tasks/${id}`, task)
            .then((response) => response.data.data)
            .then((task) => {
                commit('updateTasks', task);
                return task;
            });
    },
    GET_LINK({ commit }, request) {
        return axios.get(request).then((response) => response.data.data);
    }
};

// mutations
const mutations = {
    clear: (state) => {
        state.tasks = {};
    },
    deleteTask: (state, taskId) => {
        if (state.tasks.hasOwnProperty(taskId)) {
            Vue.delete(state.tasks, taskId);
        }
    },
    updateTasks: (state, tasks) => {
        if (Array.isArray(tasks)) {
            const map = tasks.reduce((acc, curr) => {
                acc[curr.id] = curr;
                return acc;
            }, {});
            const merge = { ...state.tasks, ...map };
            Vue.set(state, 'tasks', merge);
        } else {
            if (typeof tasks === 'object') {
                if (state.tasks.hasOwnProperty(tasks.id)) {
                    Vue.set(state.tasks, tasks.id, tasks);
                } else {
                    Vue.set(state, 'tasks', {
                        ...state.tasks,
                        ...{ [tasks.id]: tasks }
                    });
                }
            }
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
