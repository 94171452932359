import Vue from 'vue';
import axios from '../../plugins/Axios/axios';
import { sortUserArray } from './UserPrototypeUtility';

const state = {
    assignedGroups: {}, // User/Admin id to Group list
    assignedProfiles: {}, // Admin id to Permissionprofile list || PENDING
    explorerConfig: {
        sort: 'displayName',
        display: 'DISPLAYNAME',
        order: 'ASC'
    },
    view: { component: '', type: '', data: {} }
};

const getters = {
    getExplorerConfig(state) {
        return state.explorerConfig;
    },
    getTreeObject(state, getters) {
        return ({ treeId }) => {
            const find = (nodes, id) =>
                nodes.reduce((acc, curr) => {
                    if (acc) return acc;
                    if (curr.treeId && curr.treeId === id) {
                        return curr;
                    }
                    if (curr.children && curr.children.length > 0) {
                        return find(curr.children, id);
                    }
                }, undefined);
            if (!treeId) {
                return {};
            }

            return find(getters.getUserTree, treeId) || find(getters.getAdminTree, treeId) || {};
        };
    },
    getUsersProfiles(state) {
        return ({ id: userId }) => state.assignedProfiles[userId] || [];
    },
    getUsersGroups(state) {
        return ({ id: userId }) => state.assignedGroups[userId] || [];
    },
    getGroupUsers(state, getters, rootState, rootGetters) {
        return ({ id: groupId }) => {
            if (!groupId) {
                return [];
            }
            return rootGetters['AdminGroupsOp/getAdminGroupUsers'](groupId) || rootGetters['UserGroupsOp/getUserGroupUsers'](groupId) || [];
        };
    },
    getUserTreeFilteredByUsers(state, getters) {
        return (userArray) => {
            if (Array.isArray(userArray)) {
                const userIds = userArray.map(({ id }) => id);
                const filterUsers = (nodes) => {
                    return nodes
                        .filter((user) => userIds.includes(user.id) || user.hasOwnProperty('children'))
                        .map((node) => {
                            if (node.hasOwnProperty('children') && node.children.length > 0) {
                                return { ...node, children: filterUsers(node.children) };
                            }
                            return node;
                        })
                        .filter((user) => (user.hasOwnProperty('children') && user.children.length > 0) || !user.hasOwnProperty('children'));
                };
                return filterUsers(getters.getUserTreeFiltered);
            }
            return [];
        };
    },
    getUserTreeFiltered(state, getters) {
        const filterChildren = (nodes) => {
            return nodes.reduce((acc, curr) => {
                if (curr.children && curr.children.length > 0) {
                    acc.push(curr);
                } else {
                    if (!curr.children) {
                        acc.push(curr);
                    }
                }
                return acc;
            }, []);
        };
        return filterChildren(getters.getUserTree);
    },
    getUserTree(state, getters, rootState, rootGetters) {
        const assignedUserKeys = Object.keys(state.assignedGroups);
        const users = rootGetters['User/getUserList'].filter((user) => !assignedUserKeys.includes(user.id)).map((user) => ({ ...user, treeId: user.id }));
        sortUserArray(users, state.explorerConfig);
        return [...getters.getUserTreeWithoutRoot, ...users];
    },
    getUserTreeWithoutRoot(state, getters, rootState, rootGetters) {
        const tree = rootGetters['UserGroupsOp/getUserGroupTree'];
        const buildTree = (nodes, id = 'tree') => {
            return nodes.map((node) => {
                if (node.children && node.children.length > 0) {
                    const users = getters.getGroupUsers(node).map((user) => ({
                        ...user,
                        treeId: `${id}-${node.id}-${user.id}`
                    }));
                    sortUserArray(users, state.explorerConfig);
                    const children = [...buildTree(node.children, `${id}-${node.id}`), ...users];
                    return { ...node, children, treeId: `${id}-${node.id}` };
                } else {
                    const users = getters.getGroupUsers(node).map((user) => ({
                        ...user,
                        treeId: `${id}-${node.id}-${user.id}`
                    }));
                    sortUserArray(users, state.explorerConfig);
                    const children = [...node.children, ...users];
                    return { ...node, children, treeId: `${id}-${node.id}` };
                }
            });
        };
        return buildTree(tree);
    },
    getAdminTreeFilteredByUsers(state, getters) {
        return (userArray) => {
            if (Array.isArray(userArray)) {
                const userIds = userArray.map(({ id }) => id);
                const filterUsers = (nodes) => {
                    return nodes
                        .filter((user) => userIds.includes(user.id) || user.hasOwnProperty('children'))
                        .map((node) => {
                            if (node.hasOwnProperty('children') && node.children.length > 0) {
                                return { ...node, children: filterUsers(node.children) };
                            }
                            return node;
                        })
                        .filter((user) => (user.hasOwnProperty('children') && user.children.length > 0) || !user.hasOwnProperty('children'));
                };
                return filterUsers(getters.getAdminTreeFiltered);
            }
            return [];
        };
    },
    getAdminTreeFiltered(state, getters) {
        const filterChildren = (nodes) => {
            return nodes.reduce((acc, curr) => {
                if (curr.children && curr.children.length > 0) {
                    acc.push(curr);
                } else {
                    if (!curr.children) {
                        acc.push(curr);
                    }
                }
                return acc;
            }, []);
        };
        return filterChildren(getters.getAdminTree);
    },
    getAdminTree(state, getters, rootState, rootGetters) {
        const assignedUserKeys = Object.keys(state.assignedGroups);
        const admins = rootGetters['Admin/getAdminList'].filter((user) => !assignedUserKeys.includes(user.id)).map((user) => ({ ...user, treeId: user.id }));
        sortUserArray(admins, state.explorerConfig);
        return [...getters.getAdminTreeWithoutRoot, ...admins];
    },
    getAdminTreeWithoutRoot(state, getters, rootState, rootGetters) {
        const tree = rootGetters['AdminGroupsOp/getAdminGroupTree'];
        const buildTree = (nodes, id = 'tree') => {
            return nodes.map((node) => {
                if (node.children && node.children.length > 0) {
                    const admins = getters.getGroupUsers(node).map((user) => ({
                        ...user,
                        treeId: `${id}-${node.id}-${user.id}`
                    }));
                    sortUserArray(admins, state.explorerConfig);
                    const children = [...buildTree(node.children, `${id}-${node.id}`), ...admins];
                    return { ...node, children, treeId: `${id}-${node.id}` };
                } else {
                    const admins = getters.getGroupUsers(node).map((user) => ({
                        ...user,
                        treeId: `${id}-${node.id}-${user.id}`
                    }));
                    sortUserArray(admins, state.explorerConfig);
                    const children = [...node.children, ...admins];
                    return { ...node, children, treeId: `${id}-${node.id}` };
                }
            });
        };
        return buildTree(tree);
    },
    getTreeItemPath(state, getters) {
        const findPath = (path, nodes, itemId) => {
            if (!!itemId && itemId === '') {
                return [];
            }
            return nodes.reduce((acc, curr) => {
                if (acc.map(({ treeId }) => treeId).includes(itemId)) {
                    return acc;
                }
                if (curr.treeId === itemId) {
                    return [...acc, ...path, curr];
                }
                if (curr.children && curr.children.length > 0) {
                    return findPath([...path, curr], curr.children, itemId);
                }
                return acc;
            }, []);
        };
        return (treeItem) => {
            if (!!treeItem && treeItem.hasOwnProperty('treeId')) {
                const adminPath = findPath([], getters.getAdminTree, treeItem.treeId);
                const userPath = findPath([], getters.getUserTree, treeItem.treeId);
                return adminPath.length === 0 ? userPath : adminPath;
            }
            return [];
        };
    },
    getTreeItemCascade(state, getters) {
        return ({ treeId }) => {
            const find = getters.getTreeObject({ treeId });
            if (find) {
                const flatten = (nodes) => (nodes ? nodes.reduce((acc, curr) => [...acc, curr, ...flatten(curr.children)], []) : []);
                return [find, ...flatten(find.children)];
            }
            return [];
        };
    },
    getParent(state, getters) {
        const findParent = (nodes, id) => {
            const parent = nodes.find((node) => {
                if (node && !!node.children && node.children.length > 0) {
                    return node.children.map(({ id }) => id).includes(id);
                }
            });
            if (!parent) {
                const hasChildren = nodes
                    .filter((node) => !!node.children && node.children.length > 0)
                    .map((node) => node.children)
                    .filter((node) => node.length > 0)
                    .flat();
                if (hasChildren.length > 0) {
                    return findParent(hasChildren, id);
                } else {
                    return undefined;
                }
            }
            return parent;
        };

        return ({ treeId }) => {
            return findParent(getters.getAdminTree, treeId) || findParent(getters.getUserTree, treeId);
        };
    },
    getView(state) {
        return state.view;
    }
};

const actions = {
    GET_USER_TASK_MAP({ commit }, users) {
        return axios.post(`/users/usertasks`, users).then((response) => response.data.data);
    },
    GET_EXPLORER_CONFIG({ commit }) {
        return axios
            .get('/config/user')
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateExplorerConfig', response);
                return response;
            });
    },
    UPDATE_EXPLORER_CONFIG({ commit }, config) {
        return axios
            .patch(`/config/user`, config)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateExplorerConfig', response);
                return response;
            });
    },
    RESET_EXPLORER_CONFIG({ commit }) {
        return axios
            .delete(`/config/user`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateExplorerConfig', response);
                return response;
            });
    },
    GET_ADMIN_PERMISSION_PROFILE_MAP({ commit }) {
        return axios
            .get(`/permissionprofilesmap`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateAssignedProfiles', response);
                return response;
            });
    },
    GET_ADMIN_GROUP_MAP({ commit }) {
        return axios
            .get(`/admingroupsmap`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateAssignedGroups', response);
                return response;
            });
    },
    GET_USER_GROUP_MAP({ commit }) {
        return axios
            .get(`/usergroupsmap`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateAssignedGroups', response);
                return response;
            });
    },
    SET_VIEW({ commit }, view) {
        commit('updateView', view);
    },
    BUILD_USER_MAPS({ commit, dispatch }) {
        commit('clearAssignedGroups');
        return Promise.all([
            dispatch('User/GET_USER_LIST', 0, { root: true }),
            dispatch('UserGroupsOp/GET_ALL_USER_GROUP_USERS', null, {
                root: true
            }),
            dispatch('GET_USER_GROUP_MAP'),
            dispatch('UserGroupsOp/GET_USER_GROUP_TREE', null, { root: true })
        ]);
    },
    BUILD_ADMIN_MAPS({ commit, dispatch }) {
        commit('clearAssignedGroups');
        return Promise.all([
            dispatch('Admin/GET_ADMIN_LIST', 0, { root: true }),
            dispatch('AdminGroupsOp/GET_ALL_ADMIN_GROUP_USERS', null, {
                root: true
            }),
            dispatch('GET_ADMIN_GROUP_MAP'),
            dispatch('GET_ADMIN_PERMISSION_PROFILE_MAP'),
            dispatch('AdminGroupsOp/GET_ADMIN_GROUP_TREE', null, { root: true })
        ]);
    },
    REMOVE_USER_FROM_MAP({ commit }, user) {
        commit('removeUserFromMap', user);
    }
};

const mutations = {
    clear: (state) => {
        state.assignedGroups = {};
        state.assignedProfiles = {};
        state.efforts = {};
    },
    updateExplorerConfig: (state, config) => {
        Vue.set(state, 'explorerConfig', config);
    },
    removeUserFromMap: (state, { id: userId }) => {
        const groups = Object.entries(state.assignedGroups).reduce((acc, [key, value]) => {
            if (key !== userId) {
                acc[key] = value;
            }
            return acc;
        }, {});
        Vue.set(state, 'assignedGroups', groups);
        // state.assignedGroups = groups;
    },
    clearAssignedGroups: (state) => (state.assignedGroups = {}),
    updateAssignedGroups: (state, map) => {
        Object.entries(map).forEach(([key, value]) => {
            if (value.length === 0) {
                if (state.assignedGroups.hasOwnProperty(key)) {
                    Vue.delete(state.assignedGroups, key);
                }
            } else {
                Vue.set(state.assignedGroups, key, value);
            }
        });
    },
    updateAssignedProfiles: (state, profiles) => {
        Object.entries(profiles).forEach(([key, value]) => {
            Vue.set(state.assignedProfiles, key, value);
        });
    },
    updateView: (state, view) => (state.view = view)
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
