<template>
    <v-card>
        <v-card-text>
            <v-toolbar dark flat color="secondary">
                <v-toolbar-title> {{ $t('Noch nicht fündig geworden?') }} </v-toolbar-title>
            </v-toolbar>
            <v-container pa-2 v-if="showRequest">
                <v-form v-model="valid" ref="form">
                    <v-text-field :label="$t('Themenvorschlag')" v-model="faqRequest.topic" persistent-hint :rules="[notEmpty]" />
                </v-form>
                <v-form ref="form">
                    <v-text-field :label="$t('Begründung')" v-model="faqRequest.content" persistent-hint />
                </v-form>
                <v-card-actions>
                    <v-btn block color="success" @click="submit" :disabled="!valid">
                        {{ $t('FAQ-Vorschlag einreichen') }}
                    </v-btn>
                </v-card-actions>
            </v-container>
            <v-container v-else>
                <span> {{ $t('Sie haben den Vorschlag erfolgreich eingereicht') }} </span>
                <v-card-actions>
                    <v-btn block color="success" @click="submit">
                        {{ $t('Einen neuen FAQ-Vorschlag einreichen') }}
                    </v-btn>
                </v-card-actions>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import FAQMixins from './Mixins/FAQMixins';
import TextEditor from '../Generic/TextEditor';

export default {
    name: 'faq-request',
    mixins: [FAQMixins],
    components: {
        // TextEditor
    },
    data: () => ({
        valid: false,
        faqRequest: {
            topic: '',
            content: ''
        },
        showRequest: true
    }),
    methods: {
        notEmpty(value) {
            return (!!value && value.trim() !== '' && value.trim().length > 0) || this.$t('Das Feld darf nicht leer sein');
        },
        submit() {
            if (this.showRequest) {
                //Hier noch den Controller aufrufen
                this.REQUEST_FAQ(this.faqRequest);
            }
            this.reset();
            this.showRequest = !this.showRequest;
        },
        reset() {
            this.faqRequest = {
                topic: '',
                content: ''
            };
        }
    }
};
</script>
