<template>
    <v-card flat outlined v-if="this.getStorage.maxFileStorage > 0">
        <!-- <v-toolbar dark flat dense color="secondary">
            <v-toolbar-title>
                {{ $t('Verfügbarer Speicher') }}
            </v-toolbar-title>
        </v-toolbar> -->
        <v-container>
            <v-row>
                <v-col :cols="12">
                    <v-progress-linear :height="17" :indeterminate="!hasStorage" :value="calculateProgress" :color="calculateColor">
                        {{ `${calculateProgress}%` }}
                    </v-progress-linear>
                </v-col>
                <v-col :cols="6">
                    <v-text-field :value="formatBytes(this.getStorage.usedFileStorage)" :label="$t('Belegter Speicher')" />
                </v-col>
                <v-col :cols="6">
                    <v-text-field :value="formatBytes(this.getStorage.maxFileStorage)" :label="$t('Speicher')" />
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import FileManagerMixin from './Mixins/FilemanagerPrototypeMixin';

export default {
    name: 'file-manager-storage',
    mixins: [FileManagerMixin],
    computed: {
        hasStorage() {
            return Object.keys(this.getStorage).length > 0;
        },
        calculateProgress() {
            if (this.getStorage.hasOwnProperty('usedFileStorage') && this.getStorage.hasOwnProperty('maxFileStorage')) {
                if (this.getStorage.maxFileStorage > 0) {
                    return ((this.getStorage.usedFileStorage / this.getStorage.maxFileStorage) * 100).toFixed(2);
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        },
        storageComputed() {
            if (this.getStorage.hasOwnProperty('usedFileStorage') && this.getStorage.hasOwnProperty('maxFileStorage')) {
                if (this.getStorage.maxFileStorage > 0) {
                    return {
                        max: (this.getStorage.maxFileStorage / 1000000).toFixed(2),
                        used: (this.getStorage.usedFileStorage / 1000000).toFixed(2)
                    };
                } else {
                    return 0;
                }
            }
            return 0;
        },
        calculateColor() {
            if (this.calculateProgress === 0) {
                return 'accent';
            }
            if (this.calculateProgress > 0 && this.calculateProgress <= 50) {
                return 'success';
            }
            if (this.calculateProgress > 50 && this.calculateProgress < 75) {
                return 'warning';
            }
            return 'error';
        }
    },
    mounted() {
        this.GET_STORAGE();
    }
};
</script>
