<template>
    <v-card flat>
        <v-card-actions v-if="hasCatalogOptions">
            <v-spacer />
            <v-btn @click="setGlobalCatalogSettings()" :disabled="catalogs.length === 0">
                <v-container grid-list-md fluid class="pa-0 ma-0">
                    <v-layout justify-center align-center>
                        <v-flex shrink>
                            <v-icon>mdi-wrench</v-icon>
                        </v-flex>
                        <v-flex shrink>
                            {{ $t('Alle Einstellungen') }}
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-btn>
        </v-card-actions>
        <draggable v-model="catalogs" tag="div" handle=".cursor" v-show="catalogs.length > 0">
            <transition-group name="slide-x-transition" mode="out-in" tag="v-list" class="v-list--two-line">
                <v-list-item xs12 v-for="catalog in selected" :key="catalog.id">
                    <v-list-item-action>
                        <v-btn icon @click="handleCatalog(catalog)">
                            <v-icon>mdi-card-bulleted-outline</v-icon>
                        </v-btn>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{ catalog.name }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon class="cursor pointer">mdi-cursor-move</v-icon>
                    </v-list-item-action>
                    <v-list-item-action v-if="!hasCatalogOptions">
                        <v-btn icon @click="remove(catalog)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-list-item-action>
                    <v-list-item-action v-else>
                        <v-menu left>
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click.stop="setCatalogSettings(catalog)">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('Einstellungen') }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="remove(catalog)">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('Entfernen') }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-list-item-action>
                </v-list-item>
            </transition-group>
        </draggable>
        <v-list-item v-show="catalogs.length === 0">
            <v-list-item-content>
                <v-list-item-title class="text-center">{{ $t('Keine Kataloge ausgewählt') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <CatalogOptionConfigurator v-model="showCatalogSettings" :options="catalogSettings" @options="handleOptions" :enableGlobalConfig="!isGlobal" />
    </v-card>
</template>

<script>
import draggable from 'vuedraggable';
import CatalogOptionConfigurator from '../../CourseLearning/CourseManager/ActivityTypes/Helpers/QuizCatalogConfigurator';

export default {
    name: 'selection-list-view',
    components: { draggable, CatalogOptionConfigurator },
    data: () => ({
        selectedCatalog: {},
        isGlobal: false,
        showCatalogSettings: false
    }),
    props: {
        selected: {
            type: Array,
            default: () => []
        },
        hasCatalogOptions: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        catalogSettings() {
            return Object.keys(this.selectedCatalog).length > 0 && this.selectedCatalog.options ? this.selectedCatalog.options : {};
        },
        catalogs: {
            get() {
                return this.selected;
            },
            set(value) {
                this.$emit('selected', value);
            }
        }
    },
    methods: {
        setGlobalCatalogSettings() {
            this.selectedCatalog = {};
            this.isGlobal = true;
            this.showCatalogSettings = true;
        },
        setCatalogSettings(catalog) {
            this.selectedCatalog = catalog;
            this.isGlobal = false;
            this.showCatalogSettings = true;
        },
        handleOptions({ applyToAllCatalogs, ...options }) {
            if (Object.keys(this.selectedCatalog).length > 0) {
                if (this.isGlobal) {
                    this.$emit(
                        'selected',
                        this.selected.map((option) => ({ ...option, options }))
                    );
                } else {
                    if (applyToAllCatalogs) {
                        this.$emit(
                            'selected',
                            this.selected.map((option) => ({
                                ...option,
                                options
                            }))
                        );
                    } else {
                        this.$emit(
                            'selected',
                            this.selected.map((option) => {
                                if (this.selectedCatalog.id === option.id) {
                                    return { ...option, options };
                                }
                                return option;
                            })
                        );
                    }
                }
            } else {
                if (this.isGlobal) {
                    this.$emit(
                        'selected',
                        this.selected.map((option) => ({ ...option, options }))
                    );
                } else {
                    if (applyToAllCatalogs) {
                        this.$emit(
                            'selected',
                            this.selected.map((option) => ({
                                ...option,
                                options
                            }))
                        );
                    } else {
                        this.$emit(
                            'selected',
                            this.selected.map((option) => {
                                if (this.selectedCatalog.id === option.id) {
                                    return { ...option, options };
                                }
                                return option;
                            })
                        );
                    }
                }
            }
        },
        remove({ id }) {
            this.$emit(
                'selected',
                this.selected.filter((content) => content.id !== id)
            );
        },
        handleCatalog(value) {
            this.$emit('input', [value]);
            this.$emit('switchView', false);
        }
    }
};
</script>
