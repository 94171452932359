<template>
    <v-card-text>
        <v-container>
            <v-row align="center" justify="center">
                <v-btn-toggle :value="answer">
                    <v-col :cols="6" v-for="option in knowledgeUnitOptions" :key="`option-${option.id}`">
                        <v-btn block :value="option.id" :class="selectionColor(option.isAnswer)">
                            {{ $t(option.text) }}
                        </v-btn>
                    </v-col>
                </v-btn-toggle>
            </v-row>
        </v-container>
    </v-card-text>
</template>

<script>
export default {
    name: 'true-false-result',
    props: {
        knowledgeUnitOptions: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        answer() {
            const find = this.knowledgeUnitOptions.find((option) => option.isAnswer).id;
            return find ? find : '';
        }
    },
    methods: {
        selectionColor(val) {
            return val
                ? {
                      success: true,
                      'white--text': true,
                      'px-3': true,
                      'disable-events': true
                  }
                : { 'px-3': true, 'disable-events': true };
        }
    }
};
</script>
<style>
.disable-events {
    pointer-events: none;
}
</style>
