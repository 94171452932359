<template>
    <v-layout column>
        <Toolbar
            :toolbarTitle="$t('Berechtigungsprofil Erstellen')"
            :showDrawer="false"
            :lastPath="-1"
            tooltipMessage="Hier können Sie Berechtigungsprofile erstellen."
        />
        <v-card>
            <v-card-text>
                <v-text-field
                    color="accent"
                    v-model="profileName"
                    :label="$t('Profilname')"
                    :hint="$t('Geben Sie dem Berechtigungsprofil einen Namen, der die Berechtigungen möglichst genau wiederspiegelt')"
                    required
                    :rules="nameRules"
                    class="mb-3"
                />
                <v-text-field
                    color="accent"
                    v-model="profileDescription"
                    :label="$t('Beschreibung')"
                    :hint="$t('Beschreiben Sie die Funktion des Berechtigungsprofils noch näher')"
                    class="mb-3"
                />
                <v-tabs show-arrows slider-color="accent" v-model="tabs" mandatory>
                    <v-tab key="GENERAL">{{ $t('Allgemeine Berechtigungen') }}</v-tab>
                    <v-tab key="AUTHORING">{{ $t('Autorenberechtigungen') }}</v-tab>

                    <v-tab-item key="GENERAL">
                        <v-card>
                            <AdminPermissionList v-model="selectedPermissions" />
                        </v-card>
                    </v-tab-item>
                    <v-tab-item key="AUTHORING">
                        <v-card>
                            <ProfileGroupSelector v-model="authoringPermissions" />
                        </v-card>
                    </v-tab-item>
                </v-tabs>
            </v-card-text>
            <v-card-actions>
                <v-btn color="success" block :disabled="isNameEmpty" @click="createPermissionProfile">{{ $t('Erstellen') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-layout>
</template>
<script>
import Toolbar from '../../../Components/Generic/Toolbar';
import AdminPermissionList from '../../../Components/Permission/AdminPermissionList';
import ProfileGroupSelector from '../../../Components/Permission/Profiles/ProfileAuthoringSelector';
import { mapActions } from 'vuex';

export default {
    name: 'add-permission-profile',
    components: { Toolbar, AdminPermissionList, ProfileGroupSelector },
    data: () => ({
        profileName: '',
        profileDescription: '',
        selectedPermissions: [],
        authoringPermissions: {
            catalogGroups: [],
            catalogSubGroups: []
        },
        nameRules: [(v) => !!v || 'Name darf nicht leer sein'],
        tabs: null
    }),
    methods: {
        ...mapActions('PermissionProfiles', ['CREATE_PROFILE', 'GET_PROFILES']),
        createPermissionProfile() {
            let data = {
                name: this.profileName,
                description: this.profileDescription,
                permissions: this.selectedPermissions,
                authoringPermissions: this.authoringPermissions
            };
            this.CREATE_PROFILE(data).then(() => this.$router.push({ name: 'permissionProfiles' }));
        }
    },
    computed: {
        isNameEmpty() {
            return this.profileName.trim() === '';
        }
    }
};
</script>
