<template>
    <v-container class="pa-5">
        <v-card flat>
            <v-toolbar dark flat>
                <v-btn icon @click="close">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-toolbar-title>{{ title }}</v-toolbar-title>
                <v-spacer />
            </v-toolbar>
            <v-tabs v-model="tabs">
                <v-tab key="CUSTOMER">{{ $t('Customer') }}</v-tab>
                <v-tab key="SUPERADMIN">{{ $t('Super Admin') }}</v-tab>
            </v-tabs>
            <v-card-text class="ma-0 pa-0">
                <v-tabs-items v-model="tabs" touchless>
                    <v-tab-item key="CUSTOMER">
                        <v-form v-model="validCustomer" ref="form">
                            <v-text-field
                                color="accent"
                                type="text"
                                v-model="request.customer.companyName"
                                :label="$t('Firma')"
                                :hint="$t('Der Firmenname representiert gleichzeitig den Kunden')"
                                persistent-hint
                                class="mb-3"
                                :rules="rules('notEmpty')"
                                prepend-icon="mdi-home"
                            />
                            <v-text-field
                                color="accent"
                                type="text"
                                v-model="request.customer.domainName"
                                :label="$t('Firmen Domäne')"
                                :hint="
                                    $t(
                                        'Die Firmendomäne representiert die Subdomäne in der URL ( z.B FIRMENDOMAENE.wiselab.io ). Sie dient dazu Systemeinstellungen beim Seitenbesuch zu laden'
                                    )
                                "
                                :rules="rules('notEmpty')"
                                persistent-hint
                                prepend-icon="mdi-web"
                                class="mb-3"
                            />
                            <v-text-field
                                color="accent"
                                type="number"
                                v-model="request.customer.maxLicense"
                                :label="$t('Maximale Anzahl der Lizenzen')"
                                :hint="
                                    $t(
                                        'Jeder aktive Benutzer verbraucht eine Lizenz. Wenn dieser Wert auf 0 gesetzt ist, kann der Kunde unbegrenzte aktive Benutzer haben'
                                    )
                                "
                                :rules="rules('greaterEqualsZero', 'onlyNumbers', 'notEmpty')"
                                persistent-hint
                                prepend-icon="mdi-account"
                                class="mb-3"
                            />
                            <v-text-field
                                color="accent"
                                type="number"
                                v-model="request.customer.maxCourses"
                                :label="$t('Maximale Anzahl der Kurslizenzen')"
                                :hint="
                                    $t(
                                        'Jeder erstellte Kurs verbraucht eine Kurslizenz. Wenn dieser Wert auf 0 gesetzt ist, kann der Kunde unbegrenzt Kurse erstellen '
                                    )
                                "
                                :rules="rules('greaterEqualsZero', 'onlyNumbers', 'notEmpty')"
                                persistent-hint
                                prepend-icon="mdi-head-cog-outline"
                                class="mb-3"
                            />
                            <v-text-field
                                color="accent"
                                type="number"
                                v-model="request.customer.maxFileStorage"
                                :label="$t('Maximaler Speicherplatz')"
                                :hint="$t('Maximal verfügbarer Speicher für Dateien auf dem Server in byte')"
                                :rules="rules('greaterEqualsZero', 'onlyNumbers', 'notEmpty')"
                                persistent-hint
                                suffix="byte"
                                prepend-icon="mdi-memory"
                                class="mb-3"
                            />
                            <LanguageSelector
                                v-model="request.customer.language"
                                hint="App Inhalte werden, falls die Sprache unterstützt wird, automatisch für die Besucher der App in die ausgewählte Sprache umgestellt"
                                class="mb-3"
                            />
                            <v-layout>
                                <v-flex xs6>
                                    <v-switch
                                        color="accent"
                                        :label="$t('Aktiv')"
                                        :hint="$t('Aktive Mandanten haben Zuganng zu der App. API aufrufe sind bei inaktiven Mandanten nicht möglich')"
                                        persistent-hint
                                        v-model="request.customer.active"
                                    />
                                </v-flex>
                            </v-layout>
                        </v-form>
                    </v-tab-item>
                    <v-tab-item key="SUPERADMIN">
                        <v-form v-model="validSuperAdmin" ref="form">
                            <v-text-field
                                color="accent"
                                :label="$t('Vorname')"
                                v-model="request.superAdmin.firstName"
                                :hint="$t('Der Vorname des Veratnwortlichen für den Mandanten')"
                                :rules="rules('notEmpty')"
                                persistent-hint
                                prepend-icon="mdi-account"
                                class="mb-3"
                            />
                            <v-text-field
                                color="accent"
                                :label="$t('Nachname')"
                                v-model="request.superAdmin.lastName"
                                :hint="$t('Der Nachname des Veratnwortlichen für den Mandanten')"
                                :rules="rules('notEmpty')"
                                persistent-hint
                                prepend-icon="mdi-account"
                                class="mb-3"
                            />
                            <v-text-field
                                color="accent"
                                :label="$t('Anzeigename')"
                                v-model="request.superAdmin.displayName"
                                :hint="$t('Der Anzeigename des Veratnwortlichen für den Mandanten')"
                                :rules="rules('notEmpty')"
                                persistent-hint
                                prepend-icon="mdi-account"
                                class="mb-3"
                            />
                            <v-text-field
                                color="accent"
                                autocomplete="new-password"
                                :label="$t('E-Mail')"
                                v-model="request.superAdmin.email"
                                :hint="$t('Die E-Mail Adresse des Veratnwortlichen für den Mandanten')"
                                :rules="rules('notEmpty')"
                                persistent-hint
                                prepend-icon="mdi-email"
                                class="mb-3"
                            />
                            <v-switch
                                color="accent"
                                :label="$t('Aktiv')"
                                v-model="request.superAdmin.active"
                                :hint="$t('Schaltet den Super Admin aktiv oder inaktiv')"
                                persistent-hint
                            />
                            <v-text-field
                                v-if="request.superAdmin.active"
                                color="accent"
                                autocomplete="new-password"
                                :type="showPassword ? 'text' : 'password'"
                                :label="$t('Passwort')"
                                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                @click:append="() => (showPassword = !showPassword)"
                                v-model="request.superAdmin.password"
                                :hint="$t('Initial Passwort')"
                                :rules="rules('password')"
                                persistent-hint
                                prepend-icon="mdi-key"
                                class="mb-3"
                                :disabled="!request.superAdmin.active"
                            />
                            <v-select
                                :label="$t('Wählen Sie die gewünschte WWC-Rolle aus')"
                                v-model="request.superAdmin.wwc.role"
                                :items="options"
                                :hint="''"
                                persistent-hint
                            >
                                <template v-slot:item="{ item }">
                                    {{ $t(item) }}
                                </template>
                                <template v-slot:selection="{ item }">
                                    {{ $t(item) }}
                                </template>
                            </v-select>
                        </v-form>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
            <v-card-actions>
                <v-btn block :color="submitColor" :disabled="!(validCustomer && validSuperAdmin)" @click="submit">{{ submitLabel }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import CustomerMixin from '../Mixins/CustomerManagerMixin';
import LanguageSelector from '../../Generic/LanguageSelector';

export default {
    name: 'add-update-customer',
    mixins: [CustomerMixin],
    components: {
        LanguageSelector
    },
    data: () => ({
        options: ['USER', 'FREE_ADMIN', 'LICENSED_ADMIN'],
        tabs: 0,
        validCustomer: false,
        validSuperAdmin: false,
        showPassword: false,
        request: {
            customer: {
                id: '',
                active: true,
                companyName: '',
                domainName: '',
                maxLicense: 0,
                maxCourses: 0,
                isWebConferenceCustomer: false,
                language: 'de',
                maxFileStorage: 0
            },
            superAdmin: {
                id: '',
                firstName: '',
                lastName: '',
                email: '',
                active: true,
                displayName: '',
                language: '',
                password: '',
                wwc: {
                    role: 'FREE_ADMIN'
                }
            }
        }
    }),
    computed: {
        title() {
            switch (this.getView.type) {
                case 'ADD':
                    return this.$t('Kunden Hinzufügen');
                case 'UPDATE':
                    return this.$t('Kunden Bearbeiten');
            }

            return this.$t('Kunden Hinzufügen');
        },
        submitLabel() {
            switch (this.getView.type) {
                case 'ADD':
                    return this.$t('Hinzufügen');
                case 'UPDATE':
                    return this.$t('Bearbeiten');
            }
            return this.$t('Hinzufügen');
        },
        submitColor() {
            switch (this.getView.type) {
                case 'ADD':
                    return 'success';
                case 'UPDATE':
                    return 'warning';
            }
            return 'success';
        },
        addUpdateRule() {
            return true;
        }
    },
    methods: {
        rules(...rules) {
            const nonEmpty = (v) => {
                if (typeof v === 'string') {
                    return v.trim() !== '';
                }
                return v !== '';
            };
            return rules
                .map((rule) => {
                    if (rule === 'notEmpty') {
                        return (v) => nonEmpty(v) || this.$t('Das Feld darf nicht leer sein');
                    }
                    if (rule === 'onlyNumbers') {
                        return (v) => !isNaN(v) || this.$t('Es sind nur Zahlen zugelassen');
                    }
                    if (rule === 'greaterEqualsZero') {
                        return (v) => v >= 0 || this.$t('Die Angegebene Zahl muss größer oder gleich 0 sein');
                    }
                    if (rule === 'password') {
                        return (v) => (this.superAdmin.active ? this.rules('notEmpty') : null);
                    }
                    return null;
                })
                .filter((rule) => rule !== null);
        },
        close() {
            this.SET_VIEW({ component: '', type: '', data: {}, tab: '' });
            this.reset();
            this.tabs = 0;
        },
        reset() {
            this.request = {
                customer: {
                    id: '',
                    active: true,
                    companyName: '',
                    domainName: 'app.wiselab.io',
                    maxLicense: 0,
                    maxCourses: 0,
                    isWebConferenceCustomer: false,
                    language: 'de',
                    maxFileStorage: 0
                },
                superAdmin: {
                    id: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    active: true,
                    displayName: '',
                    language: '',
                    password: '',
                    wwc: {
                        role: ''
                    }
                }
            };
        },
        submit() {
            switch (this.getView.type) {
                case 'ADD':
                    this.request.customer.isWebConferenceCustomer = this.getView.tab == 'WWC';
                    return this.ADD_CUSTOMER(this.request).then((response) => {
                        this.SET_SELECTED_CUSTOMER(response);
                        this.close();
                    });
                case 'UPDATE':
                    if (this.request.superAdmin.id == '') {
                        this.ADD_SUPER_ADMIN(this.request);
                    } else {
                        this.UPDATE_SUPER_ADMIN(this.request);
                    }
                    return this.UPDATE_CUSTOMER(this.request).then((response) => {
                        this.GET_CUSTOMERS();
                        this.SET_SELECTED_CUSTOMER(response);
                        this.close();
                    });
            }
            this.close();
        }
    },
    activated() {
        switch (this.getView.type) {
            case 'ADD':
                return this.reset();
            case 'UPDATE':
                this.request.customer.domainName = this.getView.data.settings.domainName;
                return Object.entries(this.getView.data).forEach(([key, value]) => {
                    if (this.request.hasOwnProperty(key) && value) {
                        this.request[key] = value;
                    }
                });
        }
    }
};
</script>
