<template>
    <v-card class="fill-height">
        <Toolbar
            :toolbarTitle="$t(toolbarTitle)"
            :showDrawer="false"
            :lastPath="lastPath"
            tooltipMessage="Hier können Sie eine Nachricht hinzufügen und sofort entscheiden, ob Sie diese Nachricht publizieren wollen."
        />
        <AddNews v-model="news" />
        <v-card-actions>
            <v-btn block color="success" @click="createNews" :disabled="!isNewsComplete">{{ $t('Hinzufügen') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import NewsMixin from '../../Components/News/Mixins/NewsMixin';

import Toolbar from '../../Components/Generic/Toolbar';
import AddNews from '../../Components/News/AddUpdateNews';

export default {
    name: 'add-news',
    mixins: [NewsMixin],
    components: { Toolbar, AddNews },
    data: () => ({
        lastPath: { name: 'newsEditor' },
        toolbarTitle: 'Nachricht Hinzufügen',
        news: {}
    }),
    computed: {
        isNewsComplete() {
            return true;
        }
    },
    methods: {
        createNews() {
            this.CREATE_NEWS(this.news).then(() => this.$router.replace(this.lastPath));
        }
    }
};
</script>
