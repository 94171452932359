<template>
    <v-card>
        <v-toolbar dark dense text color="secondary">
            {{ $t('Confirmation') }}
            <v-spacer />
            <v-btn icon @click="closeModal" class="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-layout column align-center justify-center class="modal-layout">
            <v-card-text>
                <v-container ma-0>
                    <v-row>
                        <v-col>
                            <v-checkbox v-model="isConfirmed" color="primary" :label="checkboxText" hide-details></v-checkbox>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions>
                    <v-container>
                        <v-row>
                            <v-col :cols="6">
                                <v-btn color="success" block @click="buy" :disabled="!isConfirmed">{{ $t('Kaufen') }}</v-btn>
                            </v-col>
                            <v-col :cols="6">
                                <v-btn color="error" block @click="closeModal"> {{ $t('Abbrechen') }} </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-actions>
            </v-card-text>
        </v-layout>
    </v-card>
</template>
<script>
export default {
    name: 'confirmation-window',
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        checkboxText: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        isConfirmed: false
    }),
    methods: {
        closeModal() {
            this.$emit('closeModal');
        },
        buy() {
            this.$emit('buy');
        }
    }
};
</script>
<style lang="scss">
.modal-layout {
    position: relative;
    .close {
        position: absolute;
        top: 0;
        right: 0;
    }
}

// .label {
//     min-width: 400px;
//     margin-bottom: 15px;
//     h2 {
//         width: calc(50% - 5px);
//         &:first-child {
//             text-align: right;
//         }
//         &:last-child {
//             text-align: left;
//         }
//     }
// }
</style>
