import PermissionProfiles from '../../views/Permissions/Profiles/PermissionProfiles.vue';
import AddPermissionProfiles from '../../views/Permissions/Profiles/AddPermissionProfiles';
import DeletePermissionProfiles from '../../views/Permissions/Profiles/DeletePermissionProfiles';
import UpdatePermissionProfiles from '../../views/Permissions/Profiles/UpdatePermissionProfiles';

import { superAdmin, admin } from '../Roles';

export default [
    /**
     * Author Permissions
     * @description
     */
    {
        path: '/PermissionProfiles/',
        name: 'permissionProfiles',
        component: PermissionProfiles,
        meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionSet: ['PERMISSION_MANAGEMENT'],
            requiresRole: true,
            roleSet: [superAdmin, admin],
            navigation: {
                type: { name: 'Groups', groupId: 'Nutzermanagement' },
                priority: 0,
                icon: 'mdi-account-circle-outline',
                name: 'Berechtigungsprofile',
                navigationKey: 'PERMISSION_PROFILES'
            }
        }
    },
    {
        path: '/PermissionProfiles/Add',
        name: 'permissionProfileAdd',
        component: AddPermissionProfiles,
        meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionSet: ['PERMISSION_MANAGEMENT'],
            requiresRole: true,
            roleSet: [superAdmin, admin]
        }
    },
    {
        path: '/PermissionProfiles/Update',
        name: 'permissionProfileUpdate',
        component: UpdatePermissionProfiles,
        meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionSet: ['PERMISSION_MANAGEMENT'],
            requiresRole: true,
            roleSet: [superAdmin, admin]
        }
    },
    {
        path: '/PermissionProfiles/Delete',
        name: 'permissionProfileDelete',
        component: DeletePermissionProfiles,
        meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionSet: ['PERMISSION_MANAGEMENT'],
            requiresRole: true,
            roleSet: [superAdmin, admin]
        }
    }
];
