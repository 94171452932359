<template>
    <v-card height="100%">
        <v-container fluid fill-height ma-0 pa-0>
            <v-layout column>
                <v-flex shrink>
                    <v-toolbar flat dense>
                        <v-autocomplete
                            v-model="computedSearchItem"
                            hide-details
                            return-object
                            :search-input.sync="computedSearch"
                            item-key="id"
                            item-value="id"
                            :filter="filter"
                            :items="getFiles"
                            :label="$t('Suchen')"
                            :no-data-text="$t('Keine Daten')"
                            :no-results-text="$t('Kein Ergebnis')"
                            :loading="searchDebouncer !== null"
                            :no-filter="searchDebouncer !== null"
                            :disabled="getFiles.length === 0"
                            @focus="resetSearchSelection"
                            :menu-props="{ transition: 'slide-y-transition' }"
                        >
                            <template v-slot:selection="{ item, selected }">
                                <v-chip color="primary" :value="selected" dark>
                                    <span>{{ item.fileName }}</span>
                                </v-chip>
                            </template>
                            <template v-slot:item="{ item, on, attrs }">
                                <v-list-item v-on="on" v-bind="attrs" dense>
                                    <v-list-item-icon>
                                        <v-icon medium>{{ icon(item, false) }}</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.name || item.fileName }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ pathToSearchItem(item) }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-autocomplete>
                        <template v-slot:extension>
                            <v-flex shrink>
                                <v-btn icon @click="goUp" :disabled="!isSelected">
                                    <v-icon>mdi-folder-upload</v-icon>
                                </v-btn>
                            </v-flex>
                            <v-flex grow>
                                <v-breadcrumbs :items="breadcrumbPath">
                                    <template v-slot:item="{ item }">
                                        <span
                                            :class="{
                                                pointer: item !== computedSelectedItem,
                                                'truncate-path': true
                                            }"
                                            @click="computedSelectedItem = [item]"
                                            >{{ item.name || item.fileName }}
                                        </span>
                                    </template>
                                </v-breadcrumbs>
                            </v-flex>
                        </template>
                    </v-toolbar>
                </v-flex>
                <v-flex grow>
                    <keep-alive>
                        <component
                            :is="filemanagerComponent"
                            :component="selectedComponent"
                            v-model="computedSelectedItemArray"
                            :search="searchConfirm"
                            @openTree="handleOpenTree"
                            @selected="handleSelection"
                            :permissions="permissions"
                            @media="handleMedia"
                            @selectedMedia="handleSelectedMedia"
                            :media="media"
                            :selectable="selectable"
                        >
                            <v-treeview
                                :items="getTree"
                                transition
                                activatable
                                :active.sync="computedSelectedItemArray"
                                return-object
                                :open.sync="openPath"
                                class="pt-2"
                                ref="tree"
                                item-key="id"
                                dense
                            >
                                <template v-slot:prepend="{ item, open }">
                                    <v-badge :value="getFolderMedia(item).length" overlap>
                                        <template v-slot:badge>
                                            <span>{{ getFolderMedia(item).length }}</span>
                                        </template>
                                        <v-icon :color="item.color">
                                            {{ icon(item, open) }}
                                        </v-icon>
                                    </v-badge>
                                </template>
                                <template v-slot:label="{ item, active }">
                                    <v-layout>
                                        <v-flex align-self-center>
                                            <span
                                                :class="{
                                                    'primary--text': active,
                                                    pointer: true,
                                                    'no-highlight': true,
                                                    'truncate-tree-item': true
                                                }"
                                            >
                                                {{ item.name || item.fileName }}
                                            </span>
                                        </v-flex>
                                    </v-layout>
                                </template>
                            </v-treeview>
                        </component>
                    </keep-alive>
                </v-flex>
            </v-layout>
        </v-container>
    </v-card>
</template>
<script>
import FilemanagerView from './Helpers/FilemanagerView';
import FilemanagerDesktopView from './Helpers/FilemanagerDesktopView';
import FilemanagerMobileView from './Helpers/FilemanagerMobileView.vue';

import KnowledgeContentCheckbox from '../../KnowledgeContent/Helpers/KnowledgeContentCheckbox';

import StaticFilemanagerMixin from '../Mixin/StaticFilemanagerPrototypeMixin';

export default {
    name: 'filemanager',
    components: {
        FilemanagerView,
        FilemanagerDesktopView,
        FilemanagerMobileView,
        KnowledgeContentCheckbox
    },
    mixins: [StaticFilemanagerMixin],
    data: () => ({
        isLoaded: false,
        search: '',
        selectedSearch: {},
        searchConfirm: '',
        searchDebouncer: null,
        selectedItem: [],
        openPath: [],
        media: {},
        permissions: {
            create: false,
            update: false,
            delete: false,
            move: false
        }
    }),
    props: {
        selectedMedia: {
            type: Object,
            default: () => ({})
        },
        selectable: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        selectedMedia: {
            immediate: true,
            handler(value) {
                this.media = value;
            }
        }
    },
    computed: {
        computedSearch: {
            get() {
                return this.search ? this.search : '';
            },
            set(value) {
                this.search = value;
                if (this.searchDebouncer) {
                    clearTimeout(this.searchDebouncer);
                    this.searchDebouncer = null;
                }
                if (value !== '') {
                    this.searchDebouncer = setTimeout(
                        function () {
                            this.data.searchConfirm = this.search;
                            this.data.searchDebouncer = null;
                        }.bind({ data: this, search: value }),
                        1500
                    );
                } else {
                    clearTimeout(this.searchDebouncer);
                    this.searchDebouncer = null;
                    this.searchConfirm = '';
                }
            }
        },
        breadcrumbPath() {
            return [...this.parentPath, this.computedSelectedItem];
        },
        parentPath() {
            let path = [];
            const pathfinder = (parent) => {
                const node = this.getParent(parent);
                if (node !== undefined) {
                    path.unshift(node);
                    pathfinder(node);
                }
            };
            if (this.hasParent) {
                pathfinder(this.selectedItem[0]);
            }
            return path;
        },
        isSelected() {
            return this.selectedItem.length > 0;
        },
        hasParent() {
            return this.isSelected ? !!this.getParent(this.selectedItem[0]) : false;
        },
        computedSelectedItemArray: {
            get() {
                return this.selectedItem;
            },
            set(value) {
                this.selectedItem = value;
                this.openPath = [...this.openPath, ...this.parentPath];
                this.media = {};
            }
        },
        computedSelectedItem: {
            get() {
                return this.selectedItem.length > 0 ? this.selectedItem[0] : {};
            },
            set(value) {
                this.selectedItem = value;
                this.openPath = [...this.openPath, ...this.parentPath];
            }
        },
        computedSearchItem: {
            get() {
                return this.selectedSearch;
            },
            set(value) {
                if (value) {
                    const folderId = value.folderId && value.folderId === '' ? 'root' : value.folderId;
                    const folder = this.getFolder({ id: folderId });
                    this.computedSelectedItem = folder.id !== '' ? [folder] : [];
                    this.selectedSearch = value;
                    this.media = value;
                } else {
                    this.selectedSearch = {};
                    this.media = {};
                    this.search = '';
                }
            }
        },
        filemanagerComponent() {
            if (this.isLoaded) {
                if (this.$vuetify.breakpoint.xs) {
                    return FilemanagerMobileView;
                }
                return FilemanagerDesktopView;
            } else {
                if (this.$vuetify.breakpoint.xs) {
                    return FilemanagerDesktopView;
                } else {
                    return FilemanagerMobileView;
                }
            }
        },
        selectedComponent() {
            return FilemanagerView;
        }
    },
    methods: {
        handleSelectedMedia(value) {
            this.$emit('selectedMedia', value);
        },
        handleMedia(value) {
            this.media = value;
        },
        resetSearchSelection() {
            this.selectedSearch = {};
        },
        filter(item) {
            if (!this.searchConfirm || this.searchConfirm.trim() === '') {
                return true;
            }
            if (this.searchConfirm === this.search) {
                let buildString = [...new Set(this.searchConfirm.split(' '))].filter((word) => word !== '').map((word) => word.toLowerCase());
                const text = Object.values(item)
                    .filter((data) => typeof data === 'string' || typeof data === 'number')
                    .map((data) => {
                        if (typeof data === 'number') {
                            return data.toString();
                        }
                        return data;
                    })
                    .join(' ')
                    .toLowerCase();
                return buildString.some((word) => text.includes(word));
            } else {
                return true;
            }
        },
        reset() {
            this.SET_VIEW({ component: '', type: '', data: {} });
        },
        handleSelection(value) {
            this.$emit('input', value);
        },
        goUp() {
            if (this.hasParent) {
                if (this.isSelected) {
                    const selected = this.getParent(this.selectedItem[0]);
                    if (selected) {
                        this.computedSelectedItem = [selected];
                    }
                }
            } else {
                this.computedSelectedItem = [];
            }
        },
        handleOpenTree(value) {
            this.openPath = value;
        },
        icon(item, open) {
            switch (this.isFilemanagerType(item)) {
                case 'FILE':
                    switch (this.extractType(item)) {
                        case 'image':
                            return 'mdi-file-image-outline';
                        case 'video':
                            return 'mdi-file-video-outline';
                        case 'application':
                            return 'mdi-file-pdf-outline';
                    }
                    break;
                case 'FOLDER':
                    return item.name
                        ? open && item && item.children && item.children.length > 0
                            ? 'mdi-folder-open-outline'
                            : 'mdi-folder-outline'
                        : 'mdi-file';
            }
            return 'mdi-file-outline';
        },
        extractType({ fileType }) {
            return fileType.substring(0, fileType.indexOf('/'));
        },
        pathToSearchItem(item) {
            let treeItem = this.getFolder({ id: item.folderId });
            let path = [];
            while (treeItem) {
                if (treeItem && treeItem.name) {
                    path.unshift(treeItem.name);
                }
                treeItem = this.getParent(treeItem);
            }
            return path.length > 0 ? path.join(' / ') : this.$t('Stammverzeichnis');
        }
    },
    mounted() {
        this.$nextTick(() => (this.isLoaded = true));
        this.media = this.selectedMedia;
    },
    beforeMount() {
        this.SET_VIEW({ view: '', data: {}, type: '' });
        Promise.all([this.GET_ALL_MEDIA(), this.GET_TREE()]);
        this.permissions.create = true;
        this.permissions.update = true;
        this.permissions.delete = true;
        this.permissions.move = true;
    }
};
</script>

<style scoped>
.truncate-tree-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-treeview {
    height: 100%;
    width: max-content;
}
.truncate-path {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 7vw;
}
.v-input--selection-controls {
    margin-top: 0;
}
</style>
