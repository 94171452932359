import AuthoringTool from '../../views/AuthoringTool/AuthoringTool';
import { superAdmin, admin } from '../Roles';

export default [
    /**
     * Authoringtool - Catalog Group
     * @description
     */
    {
        path: '/Authoringtool',
        name: 'authoringTool',
        component: AuthoringTool,
        meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionSet: ['AUTHORING_TOOL_ACCESS'],
            requiresRole: true,
            roleSet: [superAdmin, admin],
            navigation: {
                type: { name: 'SingleGroup', groupId: 'Lernmanagement' },
                priority: 0,
                icon: 'mdi-file-cog',
                name: 'Autorentool',
                navigationKey: 'AUTHORING_TOOL'
            }
        }
    }
];
