import axios from '../../../plugins/Axios/axios';

const state = {
    adminPermissionList: [],
    systemPermissionList: [],
    hasPermissions: false
};

// getters
const getters = {
    getAdminPermissionList(state) {
        return state.adminPermissionList;
    },
    getSystemPermissionList() {
        return state.systemPermissionList;
    },
    getPermissions(state) {
        return state.hasPermissions;
    }
};

// actions
const actions = {
    ADD_PERMISSION_TO_ADMIN({ commit }, dataToSend) {
        return axios.post(`/permissions`, dataToSend).then((response) => {
            return response;
        });
    },
    GET_ALL_SYSTEM_PERMISSIONS({ commit }) {
        return axios.get('/permissions').then((response) => {
            commit('updateSystemPermissionList', response.data.data.permissions);
            return response;
        });
    },
    GET_ADMINPERMISSION_LIST_BY_ID({ commit }, adminId) {
        return axios.get(`admins/${adminId}/permissions`).then((response) => {
            commit('updateAdminPermissionList', response.data.data.permissions);
            return response;
        });
    },
    CHECK_PERMISSIONS({ commit }, permissions) {
        return axios.post(`/checkPermissions`, permissions).then((response) => response.data.data.hasPermission);
    }
};

// mutations
const mutations = {
    clear: (state) => {
        state.adminPermissionList = [];
        state.systemPermissionList = [];
        state.hasPermissions = false;
    },
    updateAdminPermissionList: (state, permissionList) => {
        state.adminPermissionList = permissionList;
    },
    updateSystemPermissionList: (state, systemPermissionList) => {
        state.systemPermissionList = systemPermissionList;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
