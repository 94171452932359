<template>
    <v-autocomplete
        v-model="computedSelect"
        :label="$t(label)"
        :hint="$t(hint)"
        :items="users"
        persistent-hint
        prepend-icon="mdi-account"
        :filter="filter"
        :search-input.sync="search"
        :no-data-text="$t('')"
        return-object
        item-text="displayName"
        item-value="id"
        hide-no-data
        multiple
        @change="$emit('onSelect')"
    >
        <template v-slot:selection="{ item, selected }">
            <v-chip color="primary" dark :value="selected">
                {{ displayName(item) }}
            </v-chip>
        </template>
        <template v-slot:item="{ item, tile: show }">
            <v-list-item-avatar>
                <component :is="isImage(item, show)" :src="image(item)" :value="image(item)" color="success">
                    {{ show ? 'mdi-check' : '' }}
                </component>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>
                    {{ displayName(item) }}
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{ email(item) }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
import UserMixin from '../../Users/Mixins/UserMixin';

export default {
    name: 'user-picker',
    mixins: [UserMixin],
    data: () => ({
        search: ''
    }),
    props: {
        excludeSelf: {
            type: Boolean,
            default: false
        },
        hint: {
            type: String,
            default: 'Wählen Sie einen Nutzer aus'
        },
        label: {
            type: String,
            default: 'Nutzer auswählen'
        },
        value: {
            type: Array,
            default: () => []
        },
        showUsers: {
            type: Boolean,
            default: false
        },
        showAdmins: {
            type: Boolean,
            default: false
        },
        showEmptyElement: {
            type: Boolean,
            default: false
        },
        emptyElement: {
            type: Object,
            default() {
                return {
                    value: this.$t('Alle'),
                    icon: 'group'
                };
            }
        }
    },
    watch: {
        showUsers(value) {
            if (value) {
                this.GET_USER_LIST();
            }
        },
        showAdmins(value) {
            if (value) {
                this.GET_ADMIN_LIST();
            }
        }
    },
    computed: {
        computedSelect: {
            get() {
                if (this.value.length === 0) {
                    return [this.emptyElement];
                }
                return this.value;
            },
            set(value) {
                if (value.length > 0 && value[value.length - 1] === this.emptyElement) {
                    this.$emit('input', [this.emptyElement]);
                } else {
                    this.$emit(
                        'input',
                        value.filter((user) => user !== this.emptyElement)
                    );
                }
                this.search = '';
            }
        },
        users() {
            let users = [];
            if (this.showEmptyElement) {
                users.push(this.emptyElement);
            }
            if (this.showUsers) {
                users.push(...this.getUserList);
            }
            if (this.showAdmins) {
                users.push(...this.getAdminList);
            }
            if (!users.map((user) => user.id).includes(this.getLoggedInAdmin.id)) {
                users.push(this.getLoggedInAdmin);
            } else {
                users = users.map((user) => {
                    if (user.id === this.getLoggedInAdmin.id) {
                        return this.getLoggedInAdmin;
                    }
                    return user;
                });
            }
            if (this.excludeSelf) {
                users = users.filter((user) => user.id !== this.getLoggedInAdmin.id);
            }
            return users;
        }
    },
    methods: {
        isImage({ profileImage }, show) {
            return profileImage && !show ? 'v-img' : 'v-icon';
        },
        image({ profileImage, icon }) {
            return profileImage || icon || '';
        },
        displayName({ displayName, value }) {
            return displayName || value || '';
        },
        email({ email }) {
            return email || '';
        },
        filter(item, search) {
            if (search.trim() === '') {
                return true;
            }
            const buildString = search
                .split(' ')
                .filter((word) => word !== '')
                .map((word) => `(?=.*${word})`)
                .join('');
            const regex = new RegExp(buildString, 'i');
            const user = `${item.displayName} ${item.firstName} ${item.lastName} ${item.email} ${item.value}`;
            return regex.test(user);
        }
    },
    mounted() {
        if (this.showUsers) {
            this.GET_USER_LIST();
        }
        if (this.showAdmins) {
            this.GET_ADMIN_LIST();
        }
    }
};
</script>
