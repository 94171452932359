<template>
    <v-card flat>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col :cols="12" :sm="6" :md="4">
                        <v-card height="100%">
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span class="primary--text">{{ $t('Durchschnittlicher Fortschritt') }}</span>
                                    </v-col>
                                    <v-col cols="auto">
                                        <span>{{ `${averageProgress}%` }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col :cols="12" :sm="6" :md="4">
                        <v-card height="100%">
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span class="primary--text">{{ $t('Durchschnittlich gesamt Richtig') }}</span>
                                    </v-col>
                                    <v-col cols="auto">
                                        <span>{{ `${averageCorrectPercentage}%` }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col :cols="12" :sm="6" :md="4">
                        <v-card height="100%">
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span class="primary--text">{{ $t('Durchschnittlich relativ Richtig') }}</span>
                                    </v-col>
                                    <v-col cols="auto">
                                        <span>{{ `${averageCorrectRelativePercentage}%` }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col :cols="12" :sm="6" :md="4">
                        <v-card height="100%">
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span class="primary--text">{{ $t('Gesamt Wissenseinheiten') }}</span>
                                    </v-col>
                                    <v-col cols="auto">
                                        <span>{{ totalKnowledgeUnits }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col :cols="12" :sm="6" :md="4">
                        <v-card height="100%">
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span class="primary--text">{{ $t('Beantwortete Wissenseinheiten') }}</span>
                                    </v-col>
                                    <v-col cols="auto">
                                        <span>{{ totalAnsweredknowledgeUnits }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col :cols="12" :sm="6" :md="4">
                        <v-card height="100%">
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span class="primary--text">{{ $t('Gesamt Aufgaben') }}</span>
                                    </v-col>
                                    <v-col cols="auto">
                                        <span>{{ totalTasks }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col :cols="12" :sm="6" :md="4">
                        <v-card height="100%">
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span class="primary--text">{{ $t('Bestandene Aufgaben') }}</span>
                                    </v-col>
                                    <v-col cols="auto">
                                        <span>{{ totalPassed }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col :cols="12">
                        <v-data-iterator
                            :items="groupUsers"
                            :no-data-text="$t('Keine Nutzer in Gruppe verfügbar')"
                            :no-results-text="$t('Kein Ergebnis')"
                            :items-per-page="5"
                            :footer-props="{
                                itemsPerPageText: $t('Nutzer pro Seite'),
                                'items-per-page-options': [5, 10, 15, { text: $t('Alle'), value: -1 }]
                            }"
                            :hide-default-footer="groupUsers.length < 5"
                        >
                            <template #default="{ items }">
                                <v-list>
                                    <v-list-item v-for="item in items" :key="item.id" @click.stop="handleUserClick(item)">
                                        <v-list-item-content>
                                            <v-list-item-title>{{ getDisplay(item) }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </template>
                        </v-data-iterator>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import UserMixin from '../../Mixins/UserMixin';

export default {
    name: 'group-task-details',
    mixins: [UserMixin],
    data: () => ({
        taskMap: {}
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        groupUsers: {
            deep: true,
            immediate: true,
            handler(value) {
                if (value.length > 0) {
                    this.GET_USER_TASK_MAP({ users: value.map(({ id }) => id) }).then((response) => (this.taskMap = response));
                }
            }
        }
    },
    computed: {
        groupUsers() {
            return this.value.children ? this.value.children.filter((child) => this.isUserOrGroup(child) === 'USER') : [];
        },
        totalKnowledgeUnits() {
            return Object.values(this.taskMap)
                .flat(1)
                .reduce((acc, { totalKnowledgeUnits }) => {
                    acc += totalKnowledgeUnits;
                    return acc;
                }, 0);
        },
        totalAnsweredknowledgeUnits() {
            return Object.values(this.taskMap)
                .flat(1)
                .reduce((acc, { voteKUCount }) => {
                    acc += voteKUCount;
                    return acc;
                }, 0);
        },
        totalTasks() {
            return Object.values(this.taskMap).flat(1).length;
        },
        totalPassed() {
            return Object.values(this.taskMap)
                .flat(1)
                .reduce((acc, { passed }) => {
                    acc += passed ? 1 : 0;
                    return acc;
                }, 0);
        },
        averageProgress() {
            if (!this.hasMap()) {
                return 0;
            }
            return (
                Object.values(this.taskMap)
                    .flat(1)
                    .reduce((acc, { taskProgress }) => {
                        acc += taskProgress;
                        return acc;
                    }, 0) / Object.values(this.taskMap).flat(1).length
            );
        },
        averageCorrectPercentage() {
            if (!this.hasMap()) {
                return 0;
            }
            return (
                Object.values(this.taskMap)
                    .flat(1)
                    .reduce((acc, { correctTotalPercentage }) => {
                        acc += correctTotalPercentage;
                        return acc;
                    }, 0) / Object.values(this.taskMap).flat(1).length
            );
        },
        averageCorrectRelativePercentage() {
            if (!this.hasMap()) {
                return 0;
            }
            return (
                Object.values(this.taskMap)
                    .flat(1)
                    .reduce((acc, { correctRelativePercentage }) => {
                        acc += correctRelativePercentage;
                        return acc;
                    }, 0) / Object.values(this.taskMap).flat(1).length
            );
        }
    },
    methods: {
        hasMap() {
            return Object.keys(this.taskMap).length > 0;
        },
        handleUserClick(user) {
            this.$emit('input', user);
            this.$nextTick(() =>
                this.$emit('switchView', {
                    component: 'USER_EFFORTS',
                    type: 'UPDATE',
                    data: user
                })
            );
        }
    },
    activated() {
        this.GET_EXPLORER_CONFIG();
    },
    deactivated() {
        this.taskMap = {};
    }
};
</script>
