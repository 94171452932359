<template>
    <v-container fluid ma-0 pa-0 fill-height>
        <v-layout column>
            <v-flex shrink>
                <Toolbar
                    :toolbarTitle="$t(getSelectedTaskArchive.itemTitle)"
                    :lastPath="-1"
                    :showDrawer="false"
                    tooltipMessage="Hier können Sie Ihre ausgewählte Aufgabe beginnen zu lösen."
                />
            </v-flex>
            <v-flex grow>
                <v-card flat class="pa-2 fill-height">
                    <Quiz
                        :settings="{ quiz: getSelectedTaskArchive.data }"
                        :isPreview="isPreview"
                        @isPreview="(value) => (isPreview = value)"
                        :onStart="handleOnStart"
                        :onSubmit="handleOnSubmit"
                        :onResult="handleOnResult"
                        :onLoop="handleOnLoop"
                    />
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import Toolbar from '../../Components/Generic/Toolbar';
import Quiz from '../../Components/Quiz/QuizApp';
import QuizMixin from '../../Components/UserApp/Mixins/QuizMixin';

export default {
    name: 'user-quiz',
    components: {
        Toolbar,
        Quiz
    },
    mixins: [QuizMixin],
    data: () => ({
        isPreview: false
    }),
    watch: {
        '$vuetify.breakpoint.name': {
            handler(value) {
                if (!this.getDrawerState) {
                    if (value !== 'xs' && value !== 'sm' && value !== 'md') {
                        this.OPEN_DRAWER(true);
                    }
                }
            },
            immediate: true
        }
    },
    computed: {
        isArchiveTask() {
            return this.getSelectedTaskArchive && this.getSelectedTaskArchive.itemType && this.getSelectedTaskArchive.itemType === 'ARCHIVE';
        }
    },
    methods: {
        handleOnLoop() {
            return this.isArchiveTask ? this.LOOP_ARCHIVE(this.getSelectedTaskArchive.data) : this.LOOP_TASK(this.getSelectedTaskArchive.data);
        },
        handleOnStart() {
            return this.isArchiveTask
                ? this.GET_ARCHIVE_KNOWLEDGEUNIT_BY_ID(this.getSelectedTaskArchive.data)
                : this.GET_TASK_KNOWLEDGEUNIT_BY_ID(this.getSelectedTaskArchive.data);
        },
        handleOnSubmit(vote) {
            return this.isArchiveTask
                ? this.ADD_ARCHIVE_VOTE({
                      archive: this.getSelectedTaskArchive.data,
                      vote: vote
                  })
                : this.ADD_USERTASK_VOTE({
                      task: this.getSelectedTaskArchive.data,
                      vote: vote
                  });
        },
        handleOnResult() {
            return this.isArchiveTask
                ? this.GET_ARCHIVETASK_RESULTS(this.getSelectedTaskArchive.data)
                : this.GET_USERTASK_RESULTS(this.getSelectedTaskArchive.data);
        }
    }
};
</script>
