<template>
    <v-container>
        <v-card flat>
            <v-card-title>
                <v-input
                    prepend-icon="mdi-help-circle"
                    persistent-hint
                    :label="$t('Rolle selektieren')"
                    :hint="
                        $t(
                            'Hier können Sie die Rolle des Nutzers ändern. Berechtigungsprofile und Gruppenzugehörigkeit müssen dem Nutzer neu zugewiesen werden wenn die Rolle geändert wird'
                        )
                    "
                />
            </v-card-title>
            <v-card-text>
                <v-btn-toggle v-model="selectedRole" mandatory style="width: 100%">
                    <v-row>
                        <v-col :cols="6">
                            <v-btn :value="true" color="primary" :text="!selectedRole" :disabled="disabled" block>{{ $t('Admin') }}</v-btn>
                        </v-col>
                        <v-col :cols="6">
                            <v-btn :value="false" color="primary" :text="selectedRole" :disabled="disabled" block>{{ $t('Nutzer') }}</v-btn>
                        </v-col>
                    </v-row>
                </v-btn-toggle>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
export default {
    name: 'role-switch',
    computed: {
        selectedRole: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
};
</script>
