<template>
    <v-treeview :items="getTree" transition return-object open-on-click class="pt-2">
        <template v-slot:prepend="{ item, open }">
            <v-icon>{{
                isAuthoringToolType(item) === 'CATALOG' ? 'mdi-card-bulleted-outline' : open ? 'mdi-folder-open-outline' : 'mdi-folder-outline'
            }}</v-icon>
        </template>
        <template v-slot:label="{ item, active }">
            <v-radio-group v-model="computedCheckbox" hide-details v-if="isAuthoringToolType(item) === 'CATALOG'">
                <v-radio hide-details :value="item" :key="item.id" :label="item.name" :disabled="isDisabled(item)" :ripple="false" />
            </v-radio-group>
            <span
                v-else
                :class="{
                    'primary--text': active,
                    'no-highlight': true,
                    'truncate-tree-item': true
                }"
            >
                {{ item.name || item.question }}
            </span>
        </template>
    </v-treeview>
</template>

<script>
import AuthoringToolMixin from '../Mixins/AuthoringtoolMixin';
export default {
    name: 'single-catalog-selector-tree',
    mixins: [AuthoringToolMixin],
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        disabledCatalogs: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        computedCheckbox: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        isDisabled(item) {
            return this.disabledCatalogs.map(({ id }) => id).includes(item.id) && this.getPermissions(this.getParent(item).id).get;
        },
        handleInput(item, value) {
            this.$emit('input', value);
        }
    }
};
</script>

<style scoped>
.truncate-tree-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-treeview {
    height: 100%;
}
.v-input--selection-controls {
    margin-top: 0;
}
</style>
