import Vue from 'vue';
import Router from 'vue-router';
import Store from '@/store';
import Routes from './routes/Routes';
import { superAdmin } from './routes/Roles';
import { Storage } from './plugins/Storage/storage';

Vue.use(Router);

/**
 * Router meta key description
 * @link https://trello.com/c/KeQD6hN3/151-navigation-drawer
 *
 * @param requiresAuth
 * @type {Boolean}
 * @description does the page require the client to have a valid token?
 *
 * @param requiresPermission
 * @type {Boolean}
 * @description does the page require the client to have a certain permission?
 *
 * @param permissionSet
 * @type {String Array}
 * @description an Array of permission codes that is required to have
 *
 * @param requiresRole
 * @type {Boolean}
 * @description does the page require the client to have a specific role?
 *
 * @param roleSet
 * @type {String Array}
 * @description an Array of roles that is required to have
 *
 * @param navigation
 * @type {Object}
 * @property {Object} type ( name: "General/Groups/Utility", groupId: String )
 * @property {Number} priority Zero or positive number. The lower the priority, the higher it is in the list
 * @property {String} icon MaterialIcon String
 * @property {String} name Displayname for drawer
 *
 */

const router = new Router({
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        ...Routes,
        {
            path: '*',
            beforeEnter: (to, from, next) => {
                next(false);
            }
        }
    ]
});

/**
 * This function checks if Client has token or not
 */
function authenticate() {
    return new Promise((resolve, reject) => {
        let token = Storage.getData('token');
        if (token) {
            return resolve('Authorized');
        }
        Storage.removeData('token');
        return reject({ message: 'Unauthorized', action: { name: 'login' } });
    });
}

/**
 * This function checks if User has the required Permissions
 * @param {Router} to
 */
function checkPermission(to) {
    return new Promise((resolve, reject) => {
        if (to.meta.requiresPermission) {
            const user = Store.getters['General/getLoggedInAdmin'];
            if (user.role === superAdmin) {
                resolve('Does not require Permissions');
                return;
            }
            console.log(`permissions needed %c${to.meta.permissionSet}`, 'color: orange');
            const permissionDataSet = {
                userId: user.id,
                permissions: to.meta.permissionSet
            };
            return Store.dispatch('AuthoringAdminPermission/CHECK_PERMISSIONS', permissionDataSet)
                .then((permission) => {
                    if (permission) {
                        resolve('Permission granted');
                    } else {
                        reject({
                            message: 'Permission rejected',
                            action: false
                        });
                    }
                })
                .catch(() => reject({ message: 'Permission rejected', action: false }));
        } else {
            resolve('Does not require Permissions');
        }
    });
}

/**
 * This function checks if User has the required Role
 * @param {Router} to
 */
function checkRole(to) {
    return new Promise((resolve, reject) => {
        if (to.meta.requiresRole) {
            const user = Store.getters['General/getLoggedInAdmin'];
            const currentRole = user.role;
            if (to.meta.roleSet.some((role) => role === currentRole)) {
                resolve('Role requirement is met');
            } else {
                reject({ message: 'Role requirement not met', action: false });
            }
        } else {
            resolve('Does not require Role');
        }
    });
}

/**
 * authenticate user check role and permission
 */
function authenticateCheckPermission(to, from, next) {
    if (to.meta.requiresAuth) {
        //authenticate the User
        authenticate()
            .then((isAuthorized) => {
                console.log(`%c${isAuthorized}`, 'color: green');
                //check role
                return checkRole(to);
            })
            .then((hasRole) => {
                console.log(`%c${hasRole}`, 'color:green');
                //check permission
                return checkPermission(to);
            })
            .then((hasPermission) => {
                console.log(`%c${hasPermission}`, 'color: green');
                return true; // subject to change
            })
            .then((route) => {
                // finally, confirm route
                next(route);
            })
            .catch((error) => {
                console.log(`%c${error.message}`, 'background-color: red; padding: 2px');
                //do action on error
                Store.dispatch('NotificationOperations/SET_NOTIFICATION_MESSAGE', error.message);
                next(error.action);
            });
    } else {
        next();
    }
}

/**
 * router-guard definition
 */
router.beforeEach((to, from, next) => {
    if (to.name === 'login') {
        // check if token exist
        if (Storage.checkValidToken(to)) {
            return next('/News');
        }
        Store.dispatch('General/CLEAR_USER_DATA');
        next();
    } else {
        console.log(`%ccoming from ${from.path} : going to ${to.path}`, 'background-color: green; padding: 2px;');
        Store.dispatch('ErrorReporterOperations/CLEAR_ERROR_MESSAGES');
        if (!to.meta.requiresAuth) {
            return next();
        }
        if (Store.getters['General/getLoggedInAdmin'].role) {
            authenticateCheckPermission(to, from, next);
            return;
        }
        if (Storage.isStayLoggedIn()) {
            Store.dispatch('General/GET_CURRENT_USER')
                .then(() => {
                    authenticateCheckPermission(to, from, next);
                })
                .catch(() => {
                    Store.dispatch('General/CLEAR_USER_DATA');
                    Store.dispatch('General/LOGOUT');
                });
            return;
        }
        Store.dispatch('General/CLEAR_USER_DATA');
        Store.dispatch('General/LOGOUT');
    }
});

/**
 * Router Error handler
 */
router.onError(() => {
    router.push({ name: 'login' });
});

export default router;
