<template>
    <v-card>
        <v-toolbar dark color="secondary" text>
            <v-toolbar-title>{{ $t('Einstellungen') }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-switch
                v-model="getNotificationScheduleConfig.anyTime"
                :label="$t('Jederzeit')"
                :hint="$t('Wenn diese Option eingeschaltet ist, werden Ihnen E-Mail Benachrichtigungen ohne zeitliche Einschränkungen zugeschickt')"
                persistent-hint
            />
            <TimePicker
                :label="$t('Startzeit')"
                :disabled="getNotificationScheduleConfig.anyTime"
                v-model="getNotificationScheduleConfig.startTime"
                :hint="$t('Geben Sie eine Zeit an, ab der Sie Benachrichtigungen als E-Mail empfangen wollen')"
            />
            <TimePicker
                :label="$t('Endzeit')"
                :min="getNotificationScheduleConfig.startTime"
                :disabled="!isStartTimeSet"
                v-model="getNotificationScheduleConfig.endTime"
                :hint="$t('Geben Sie eine Zeit an, ab der Sie keine Benachrichtigungen empfangen wollen')"
            />
        </v-card-text>
        <v-card-actions>
            <v-btn block color="success" @click="save" :disabled="!canSave">
                {{ $t('Speichern') }}
            </v-btn>
            <v-btn block color="warning" @click="reset">
                {{ $t('Zurücksetzen') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import ChatMixin from './Mixins/ChatMixin';
import TimePicker from '../Generic/TimePicker/TimePicker';

export default {
    name: 'chat-notifications-config',
    components: { TimePicker },
    mixins: [ChatMixin],
    data: () => ({
        test: '',
        test2: ''
    }),
    watch: {
        'getNotificationScheduleConfig.anyTime'(value) {
            if (value) {
                this.getNotificationScheduleConfig.startTime = '';
                this.getNotificationScheduleConfig.endTime = '';
            }
        }
    },
    computed: {
        canSave() {
            return (
                this.getNotificationScheduleConfig.anyTime ||
                (this.getNotificationScheduleConfig.endTime !== '' && this.getNotificationScheduleConfig.startTime !== '')
            );
        },
        isStartTimeSet() {
            return (
                this.getNotificationScheduleConfig.startTime !== '' &&
                this.getNotificationScheduleConfig.startTime !== null &&
                this.getNotificationScheduleConfig.startTime !== undefined &&
                this.getNotificationScheduleConfig.anyTime === false
            );
        }
    },
    methods: {
        reset() {
            this.CHAT_GET_GENERAL_NOTIFICATIONS();
        },
        save() {
            this.CHAT_SAVE_GENERAL_NOTIFICATIONS(this.getNotificationScheduleConfig);
        }
    },
    mounted() {
        this.CHAT_GET_GENERAL_NOTIFICATIONS();
    }
};
</script>
