<template>
    <v-btn small icon color="primary--text" @click.prevent="handleClick">
        <v-icon>{{ favouriteIcon }}</v-icon>
    </v-btn>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'navigation-favourite',
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapGetters('NavigationOperations', ['getFavouriteMap']),
        isFavourite() {
            return this.getFavouriteMap.hasOwnProperty(this.value);
        },
        favouriteIcon() {
            return this.isFavourite && this.getFavouriteMap[this.value] ? 'mdi-star' : 'mdi-star-outline';
        },
        validKeys() {
            return this.$router.options.routes
                .filter((route) => route && route.meta && route.meta.navigation && route.meta.navigation.navigationKey)
                .map(
                    ({
                        meta: {
                            navigation: { navigationKey: key }
                        }
                    }) => key
                );
        }
    },
    methods: {
        ...mapActions('NavigationOperations', ['SAVE_FAVOURITE_MAP']),
        handleClick() {
            if (this.isFavourite) {
                const map = Object.entries({
                    ...this.getFavouriteMap,
                    ...{ [this.value]: !this.getFavouriteMap[this.value] }
                }).reduce((acc, [key, value]) => {
                    if (this.validKeys.includes(key)) {
                        acc[key] = value;
                    }
                    return acc;
                }, {});
                this.SAVE_FAVOURITE_MAP(map);
            } else {
                const map = Object.entries({
                    ...this.getFavouriteMap,
                    ...{ [this.value]: true }
                }).reduce((acc, [key, value]) => {
                    if (this.validKeys.includes(key)) {
                        acc[key] = value;
                    }
                    return acc;
                }, {});
                this.SAVE_FAVOURITE_MAP(map);
            }
        }
    }
};
</script>
