<template>
    <v-card-text>
        <v-container>
            <v-row>
                <v-col :cols="getColumn(index)" v-for="(option, index) in optionsComputed" :key="`option-${option.id}`">
                    <OptionNode
                        :value="option"
                        :selected="selectedOptions"
                        @selected="handleSelectOption"
                        @media="mediaEmit"
                        :disableInteraction="disableInteraction"
                    />
                </v-col>
            </v-row>
        </v-container>
    </v-card-text>
</template>

<script>
import OptionNode from '../Helpers/OptionNode';

export default {
    name: 'multi-select-option',
    components: {
        OptionNode
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        valid: {
            type: Boolean,
            default: false
        },
        disableInteraction: {
            default: false,
            type: Boolean
        }
    },
    computed: {
        optionsComputed() {
            return this.value && this.value.knowledgeUnitOptions ? this.value.knowledgeUnitOptions : [];
        },
        selectedOptions: {
            get() {
                const selected = this.optionsComputed.filter((option) => option.hasOwnProperty('selected') && option.selected);
                this.$emit('valid', selected.length > 0);
                return selected;
            },
            set(value) {
                const selectedIds = value.map((option) => option.id);
                this.$emit(
                    'input',
                    this.optionsComputed.map((option) => {
                        if (selectedIds.includes(option.id)) {
                            return { ...option, selected: true };
                        }
                        return { ...option, selected: false };
                    })
                );
            }
        }
    },
    methods: {
        getColumn(index) {
            if (index === this.optionsComputed.length - 1 && index % 2 === 0) {
                return 12;
            }
            return 6;
        },
        mediaEmit(value) {
            this.$emit('media', value);
        },
        handleSelectOption(value) {
            this.selectedOptions = value;
        }
    },
    activated() {
        this.$emit('valid', false);
    },
    mounted() {
        this.$emit('valid', false);
    }
};
</script>
