import { render, staticRenderFns } from "./SingleSelectOption.vue?vue&type=template&id=023569a4&"
import script from "./SingleSelectOption.vue?vue&type=script&lang=js&"
export * from "./SingleSelectOption.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports