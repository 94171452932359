import Vue from 'vue';
import axios from '../../plugins/Axios/axios';

const state = {
    userTaskCertificateMap: {}
};

const getters = {
    getUserCertificateTasks(state) {
        return (userId) => {
            if (state.userTaskCertificateMap.hasOwnProperty(userId)) {
                return state.userTaskCertificateMap[userId];
            }
            return undefined;
        };
    }
};

const actions = {
    DOWNLOAD_USER_TASK_CERTIFICATE({ commit }, { user: { id: userId }, task: { id: taskId } }) {
        return axios
            .get(`/users/${userId}/tasks/${taskId}/certificate`, {
                responseType: 'blob'
            })
            .then((response) => response.data);
    },
    GET_CERTIFICATE_TASKS({ commit }, request) {
        if (Array.isArray(request)) {
            return Promise.all(
                request.map(({ id: userId }) => {
                    return axios
                        .get(`/users/${userId}/tasks`)
                        .then((response) => response.data.data.sort((a, b) => new Date(b.startDate) - new Date(a.startDate)))
                        .then((response) => ({
                            userId: userId,
                            tasks: response
                        }));
                })
            ).then((result) => {
                result.forEach((task) => commit('updateUserTaskCertificateMap', task));
                return result;
            });
        }
        return axios
            .get(`/users/${request.id}/tasks`)
            .then((response) => ({
                userId: request.id,
                tasks: response.data.data.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
            }))
            .then((response) => {
                commit('updateUserTaskCertificateMap', response);
                return response;
            });
    }
};

const mutations = {
    clear: (state) => {
        state.userTaskCertificateMap = {};
    },
    updateUserTaskCertificateMap: (state, { userId, tasks }) => {
        if (tasks.length > 0) {
            Vue.set(state.userTaskCertificateMap, userId, tasks);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
