const state = {
    isLoading: false,
    activeRequestCount: 0,
    currentProgress: 0
};

const getters = {
    isLoading(state) {
        return state.isLoading;
    },
    getActiveRequestCount(state) {
        return state.activeRequestCount;
    },
    getCurrentProgress(state) {
        return state.currentProgress;
    }
};

const actions = {
    START({ commit }) {
        commit('startLoader');
    },
    PROGRESS({ commit }, progress) {
        commit('setProgress', progress);
    },
    END({ commit }) {
        commit('stopLoader');
    }
};

const mutations = {
    clear: (state) => {},
    startLoader: (state) => {
        state.activeRequestCount += 1;
        state.isLoading = true;
        state.currentProgress = 0;
    },
    stopLoader: (state) => {
        if (state.activeRequestCount > 0) {
            state.activeRequestCount -= 1;
            if (state.activeRequestCount === 0) {
                state.isLoading = false;
            }
        }
    },
    setProgress: (state, value) => {
        state.currentProgress = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
