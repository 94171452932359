import SystemSettings from '../../views/SystemSettings/SystemSettings.vue';
import SystemSettingsGeneric from '../../views/SystemSettings/Generic/SystemSettingsGeneric';
import SystemSettingsEmailServer from '../../views/SystemSettings/Email/MailServer';
import SystemSettingsEmailSchedule from '../../views/SystemSettings/Email/MailSchedule';
import SystemSettingsEmailTemplates from '../../views/SystemSettings/Email/MailTemplates';
import SystemSettingsEmailOptions from '../../views/SystemSettings/Email/MailOptions';
import SystemSettingsCertificate from '../../views/SystemSettings/Certificate/Certificate';
import SystemSettingsNavigationMenu from '../../views/SystemSettings/NavigationMenu/NavigationMenuSettings';

import { superAdmin } from '../Roles';

export default [
    /**
     * System
     * @description
     */
    {
        path: '/SystemSettings/',
        component: SystemSettings,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin],
            navigation: {
                type: { name: 'Utility', groupId: '' },
                priority: 0,
                icon: 'mdi-cog',
                name: 'System Einstellungen'
            }
        },
        children: [
            {
                path: '',
                component: SystemSettingsGeneric,
                meta: {
                    requiresAuth: true,
                    requiresPermission: false,
                    permissionSet: [],
                    requiresRole: true,
                    roleSet: [superAdmin]
                }
            },
            {
                path: 'Generic',
                name: 'systemSettingsGeneric',
                component: SystemSettingsGeneric,
                meta: {
                    requiresAuth: true,
                    requiresPermission: false,
                    permissionSet: [],
                    requiresRole: true,
                    roleSet: [superAdmin]
                }
            },
            {
                path: 'Mail/Server',
                name: 'systemSettingsMailServer',
                component: SystemSettingsEmailServer,
                meta: {
                    requiresAuth: true,
                    requiresPermission: false,
                    permissionSet: [],
                    requiresRole: true,
                    roleSet: [superAdmin]
                }
            },
            {
                path: 'Mail/Schedule',
                name: 'systemSettingsMailSchedule',
                component: SystemSettingsEmailSchedule,
                meta: {
                    requiresAuth: true,
                    requiresPermission: false,
                    permissionSet: [],
                    requiresRole: true,
                    roleSet: [superAdmin]
                }
            },
            {
                path: 'Mail/Templates',
                name: 'systemSettingsMailTemplates',
                component: SystemSettingsEmailTemplates,
                meta: {
                    requiresAuth: true,
                    requiresPermission: false,
                    permissionSet: [],
                    requiresRole: true,
                    roleSet: [superAdmin]
                }
            },
            {
                path: 'Mail/Options',
                name: 'systemSettingsMailOptions',
                component: SystemSettingsEmailOptions,
                meta: {
                    requiresAuth: true,
                    requiresPermission: false,
                    permissionSet: [],
                    requiresRole: true,
                    roleSet: [superAdmin]
                }
            },
            {
                path: 'Certificate',
                name: 'systemSettingsCertificate',
                component: SystemSettingsCertificate,
                meta: {
                    requiresAuth: true,
                    requiresPermission: false,
                    permissionSet: [],
                    requiresRole: true,
                    roleSet: [superAdmin]
                }
            },
            {
                path: 'NavigationMenu',
                name: 'systemSettingsNavigationMenu',
                component: SystemSettingsNavigationMenu,
                meta: {
                    requiresAuth: true,
                    requiresPermission: false,
                    permissionSet: [],
                    requiresRole: true,
                    roleSet: [superAdmin]
                }
            }
        ]
    }
];
