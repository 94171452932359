import axios from 'axios';
import Store from '@/store/index';
import ExcludeList from './AxiosExtensions/ExcludeList';

axios.defaults.baseURL = 'https://app.wiselab.io/api/wiselab/';
// axios.defaults.baseURL = 'https://wiselabapi.wiselab.io/api/wiselab/';
// axios.defau lts.baseURL = 'http://localhost:9555/wiselab/';
// axios.defaults.baseURL = 'https://staging.wiselab.io/api/wiselab/';
// axios.defaults.baseURL = "http://192.168.10.10:9555/wiselab/";

axios.defaults.headers['Content-Type'] = 'application/json';

axios.interceptors.request.use(
    function (config) {
        const token = window.localStorage.getItem('token');
        if (!ExcludeList.excludeTest(config.url)) {
            Store.dispatch('LoadOperations/START');
        }
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    function (error) {
        Store.dispatch('LoadOperations/END');
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    function (response) {
        if (response.data.hasOwnProperty('message')) {
            Store.dispatch('NotificationOperations/SET_NOTIFICATION_MESSAGE', response.data.message);
        }
        Store.dispatch('ErrorReporterOperations/CLEAR_ERROR_MESSAGES');
        Store.dispatch('LoadOperations/END');
        return response;
    },
    function (error) {
        if (error.hasOwnProperty('response')) {
            if (error.response.request.responseType !== 'blob') {
                if (typeof error.response === 'undefined') {
                    Store.dispatch('LoadOperations/END');
                    return Promise.reject(error);
                }
                if (error.response.hasOwnProperty('data')) {
                    Store.dispatch('ErrorReporterOperations/SET_ERROR_MESSAGES', []);
                    Store.dispatch('ErrorReporterOperations/SET_ERROR_MESSAGES', error.response.data.errors);
                } else {
                    if (error && error.message) {
                        let errorArray = [];
                        errorArray.push({ message: error.message });
                        Store.dispatch('ErrorReporterOperations/SET_ERROR_MESSAGES', []);
                        Store.dispatch('ErrorReporterOperations/SET_ERROR_MESSAGES', errorArray);
                    }
                }
            }
        }
        Store.dispatch('LoadOperations/END');
        return Promise.reject(error);
    }
);

export default axios;
