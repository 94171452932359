<template>
    <v-card>
        <v-card-text>
            <v-form v-model="validate">
                <v-text-field />
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'appointment-activity',
    data: () => ({
        validate: false
    })
};
</script>
