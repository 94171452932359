<template>
    <v-card @click="onSelection" :elevation="4">
        <v-toolbar flat dark :color="selected ? 'primary' : 'secondary'">
            <v-toolbar-title>{{ value.name }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon color="warning" v-if="showButton()" @click="updateArchive" align-center>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-pencil</v-icon>
                    </template>
                    <span>{{ $t('Hier können Sie das ausgewählte Archiv bearbeiten') }}</span>
                </v-tooltip>
            </v-btn>
            <v-btn icon color="error" v-if="showButton()" @click="deleteArchive">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-trash-can</v-icon>
                    </template>
                    <span>{{ $t('Hier können Sie das ausgewählte Archiv löschen') }}</span>
                </v-tooltip>
            </v-btn>
        </v-toolbar>
        <v-progress-linear :value="value.taskProgress" color="primary" :height="15" class="ma-0" />
        <v-tabs v-model="tabs" mandatory show-arrows>
            <v-tab key="OVERVIEW" @click.stop>{{ $t('Überblick') }}</v-tab>
            <v-tab key="REPORT" @click.stop>{{ $t('Report') }}</v-tab>
            <v-tab key="CATALOGS" @click.stop>{{ $t('Kataloge') }}</v-tab>
        </v-tabs>
        <div style="height: 270px; max-height: 270px; overflow-x: hidden" class="overflow-y-auto">
            <v-tabs-items v-model="tabs" touchless>
                <v-tab-item key="OVERVIEW">
                    <v-container fluid>
                        <v-row no-gutters>
                            <v-col :cols="12" v-if="value.completed && !!value.taskDoneReason">
                                <v-input outline readonly class="primary--text" :messages="[$t(value.taskDoneReason)]">
                                    {{ $t('Resultat') }}
                                </v-input>
                            </v-col>
                            <v-col :cols="12" :sm="6">
                                <v-input readonly :messages="[value.startDate]">
                                    <template v-slot:prepend>
                                        <v-layout align-center justify-center fill-height>
                                            <v-col>
                                                <v-icon large>mdi-calendar</v-icon>
                                            </v-col>
                                        </v-layout>
                                    </template>
                                    {{ $t('Startdatum') }}
                                </v-input>
                            </v-col>
                            <v-col :cols="12" :sm="6">
                                <v-input readonly :messages="[value.endDate]">
                                    <template v-slot:prepend>
                                        <v-layout align-center justify-center fill-height>
                                            <v-col>
                                                <v-icon large>mdi-calendar-remove</v-icon>
                                            </v-col>
                                        </v-layout>
                                    </template>
                                    {{ $t('Enddatum') }}
                                </v-input>
                            </v-col>
                            <v-col :cols="12" :sm="6" v-if="value.taskOptions.hasPassingQuote">
                                <v-input readonly :messages="[`${value.taskOptions.passingQuote}%`]">
                                    <template v-slot:prepend>
                                        <v-layout align-center justify-center fill-height>
                                            <v-col>
                                                <v-icon large>mdi-school</v-icon>
                                            </v-col>
                                        </v-layout>
                                    </template>
                                    {{ $t('Bestehensquote') }}
                                </v-input>
                            </v-col>
                            <v-col :cols="12" :sm="6">
                                <v-input
                                    readonly
                                    :messages="[value && value.completed ? (value.passed ? $t('Bestanden') : $t('Nicht Bestanden')) : $t('Nicht Vollständig')]"
                                >
                                    <template v-slot:prepend>
                                        <v-layout align-center justify-center fill-height>
                                            <v-col>
                                                <v-icon large :color="value.completed ? (value.passed ? 'success' : 'error') : ''">{{
                                                    value.completed ? (value.passed ? 'mdi-check' : 'mdi-close') : 'mdi-minus'
                                                }}</v-icon>
                                            </v-col>
                                        </v-layout>
                                    </template>
                                    {{ $t('Bestanden') }}
                                </v-input>
                            </v-col>
                            <v-col :cols="12" :sm="6">
                                <v-input readonly :messages="[value && value.completed ? value.taskCompletedDate : $t('Nicht Vollständig')]">
                                    <template v-slot:prepend>
                                        <v-layout align-center justify-center fill-height>
                                            <v-col>
                                                <v-icon large :color="value.completed ? 'success' : 'error'">{{
                                                    value.completed ? 'mdi-check' : 'mdi-close'
                                                }}</v-icon>
                                            </v-col>
                                        </v-layout>
                                    </template>
                                    {{ $t('Vollständig') }}
                                </v-input>
                            </v-col>
                            <v-col :cols="12" :sm="6">
                                <v-input readonly :messages="[value.taskOptions.limits > 1 ? `min. ${value.taskOptions.limits}` : $t('Keins')]">
                                    <template v-slot:prepend>
                                        <v-layout align-center justify-center fill-height>
                                            <v-col>
                                                <v-icon large>mdi-stop-circle</v-icon>
                                            </v-col>
                                        </v-layout>
                                    </template>
                                    {{ $t('Limit') }}
                                </v-input>
                            </v-col>
                            <v-col :cols="12" :sm="6">
                                <v-input readonly :messages="[value.taskOptions.loopingTask ? $t('Ja') : $t('Nein')]">
                                    <template v-slot:prepend>
                                        <v-layout align-center justify-center fill-height>
                                            <v-col>
                                                <v-icon large>mdi-infinity</v-icon>
                                            </v-col>
                                        </v-layout>
                                    </template>
                                    {{ $t('Fortlaufend') }}
                                </v-input>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>

                <v-tab-item key="REPORT">
                    <v-container fluid>
                        <v-row no-gutters>
                            <v-col :cols="12" :sm="6">
                                <v-input readonly :messages="[value.totalKnowledgeUnits.toString()]">
                                    <template v-slot:prepend>
                                        <v-layout align-center justify-center fill-height>
                                            <v-col>
                                                <v-icon large>mdi-format-list-bulleted</v-icon>
                                            </v-col>
                                        </v-layout>
                                    </template>
                                    {{ $t('Gesamt') }}
                                </v-input>
                            </v-col>
                            <v-col :cols="12" :sm="6">
                                <v-input readonly :messages="[value.voteKUCount.toString()]">
                                    <template v-slot:prepend>
                                        <v-layout align-center justify-center fill-height>
                                            <v-col>
                                                <v-icon large>mdi-format-list-bulleted</v-icon>
                                            </v-col>
                                        </v-layout>
                                    </template>
                                    {{ $t('Beantwortet') }}
                                </v-input>
                            </v-col>
                            <v-col :cols="12" :sm="6">
                                <v-input readonly :messages="[`${value.taskProgress}%`]">
                                    <template v-slot:prepend>
                                        <v-layout align-center justify-center fill-height>
                                            <v-col>
                                                <v-icon large>mdi-format-list-bulleted</v-icon>
                                            </v-col>
                                        </v-layout>
                                    </template>
                                    {{ $t('Fortschritt') }}
                                </v-input>
                            </v-col>
                            <v-col :cols="12" :sm="6">
                                <v-input readonly :messages="[`${value.correctTotalPercentage}%`]">
                                    <template v-slot:prepend>
                                        <v-layout align-center justify-center fill-height>
                                            <v-col>
                                                <v-icon large>mdi-format-list-bulleted</v-icon>
                                            </v-col>
                                        </v-layout>
                                    </template>
                                    {{ $t('Gesamt Richtig') }}
                                </v-input>
                            </v-col>
                            <v-col :cols="12" :sm="6">
                                <v-input readonly :messages="[`${value.correctRelativePercentage}%`]">
                                    <template v-slot:prepend>
                                        <v-layout align-center justify-center fill-height>
                                            <v-col>
                                                <v-icon large>mdi-format-list-bulleted</v-icon>
                                            </v-col>
                                        </v-layout>
                                    </template>
                                    {{ $t('Relativ Richtig') }}
                                </v-input>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>

                <v-tab-item key="CATALOGS">
                    <v-data-iterator
                        :items="value.catalogs"
                        :no-data-text="$t('Keine Kataloge verfügbar')"
                        :no-results-text="$t('Kein Ergebnis')"
                        :hideDefaultFooter="value.catalogs.length <= 5"
                        :items-per-page="5"
                        :footer-props="{
                            'items-per-page-text': $t('Kataloge pro Seite'),
                            'items-per-page-options': [5, 10, 15, { value: -1, text: $t('Alle') }]
                        }"
                        :custom-sort="catalogSort"
                    >
                        <template #default="{ items }">
                            <v-list dense>
                                <v-list-item v-for="catalog in items" :key="catalog.id">
                                    <v-list-item-action @click.stop>
                                        <v-menu offset-x :width="$vuetify.breakpoint.thresholds.xs">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon v-on="on" v-bind="attrs">
                                                    <v-icon color="primary">mdi-information-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-card flat :width="$vuetify.breakpoint.thresholds.xs">
                                                <v-toolbar flat dark color="secondary">
                                                    <v-toolbar-title>{{ catalog.name }}</v-toolbar-title>
                                                </v-toolbar>
                                                <v-card-text>
                                                    <span>{{ !!catalog.description ? catalog.description : '-' }}</span>
                                                </v-card-text>
                                                <v-container>
                                                    <v-row no-gutters align="center">
                                                        <v-col>
                                                            <v-divider />
                                                        </v-col>
                                                        <v-col cols="auto">
                                                            <span class="text-caption primary--text px-2">{{ $t('Einstellungen') }}</span>
                                                        </v-col>
                                                        <v-col>
                                                            <v-divider />
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                                <v-list dense>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ $t('Bestehensquote') }}</v-list-item-title>
                                                            <v-list-item-subtitle>{{ `${catalog.options.passingQuote}%` }}</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ $t('Gesamt Richtig') }}</v-list-item-title>
                                                            <v-list-item-subtitle>{{ `${catalog.correctTotalPercentage}%` }}</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ $t('Relativ richtig') }}</v-list-item-title>
                                                            <v-list-item-subtitle>{{ `${catalog.correctRelativePercentage}%` }}</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ $t('Wissensbasis anzeigen') }}</v-list-item-title>
                                                            <v-list-item-subtitle>
                                                                <v-icon :color="catalog.options.showKnowledgeBase ? 'success' : 'error'">{{
                                                                    catalog.options.showKnowledgeBase ? 'mdi-check' : 'mdi-close'
                                                                }}</v-icon>
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ $t('Erklärungen anzeigen') }}</v-list-item-title>
                                                            <v-list-item-subtitle>
                                                                <v-icon :color="catalog.options.showExplanation ? 'success' : 'error'">{{
                                                                    catalog.options.showExplanation ? 'mdi-check' : 'mdi-close'
                                                                }}</v-icon>
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ $t('Wissensbasis abrufbar') }}</v-list-item-title>
                                                            <v-list-item-subtitle>
                                                                <v-icon :color="catalog.options.reopenKnowledgeBase ? 'success' : 'error'">{{
                                                                    catalog.options.reopenKnowledgeBase ? 'mdi-check' : 'mdi-close'
                                                                }}</v-icon>
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </v-card>
                                        </v-menu>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ catalog.name }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </template>
                    </v-data-iterator>
                </v-tab-item>
            </v-tabs-items>
        </div>
        <v-divider v-show="value.completed" />
        <v-card-actions>
            <slot name="action" v-bind="value">
                <v-btn block color="primary" @click.stop="handleResults" v-if="value.completed && showResults">
                    {{ $t('Ergebnisse') }}
                </v-btn>
            </slot>
        </v-card-actions>
    </v-card>
</template>
<script>
import QuizMixin from '../Mixins/QuizMixin';

export default {
    name: 'task-node',
    data: () => ({
        tabs: 0
    }),
    mixins: [QuizMixin],
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        selected: {
            type: Boolean,
            default: false
        },
        showResults: {
            type: Boolean,
            default: true
        },
        type: {
            type: String,
            validator: (value) => ['TASK', 'ARCHIVE'].includes(value) || 'incorrect type',
            default: 'TASK'
        }
    },
    computed: {},
    methods: {
        catalogSort(items) {
            items.sort((a, b) =>
                a.name.localeCompare(b.name, undefined, {
                    numeric: true,
                    sensitivity: 'base'
                })
            );
            return items;
        },
        onSelection() {
            if (!this.selected && this.value && !this.value.completed) {
                this.$emit('selected', this.value);
            } else {
                this.$emit('selected', {});
            }
        },
        handleResults() {
            if (this.value && this.value.id) {
                this.$emit('results', this.value.id);
            }
        },
        showButton() {
            return this.type === 'ARCHIVE';
        },
        updateArchive() {
            this.SELECT_ARCHIVE(this.value);
            this.$router.push({ name: 'appArchiveUpdate' });
        },
        deleteArchive() {
            this.$router.push({ name: 'appArchiveDelete' });
        }
    }
};
</script>
