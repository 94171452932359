<template>
    <v-expansion-panels accordion v-if="items.length > 0">
        <v-expansion-panel>
            <v-expansion-panel-header>
                {{ $t('Relevante Wiki') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-data-iterator
                    :items="items"
                    :no-data-text="$t('keine Wiki-Einträge gefunden')"
                    :no-results-text="$t('kein Ergebnis')"
                    :items-per-page="5"
                    :footer-props="{
                        'items-per-page-text': $t('Wiki pro Seite'),
                        'items-per-page-options': [5, 10, 15, { value: -1, text: $t('Alle') }]
                    }"
                    :hideDefaultFooter="items.length <= 5"
                    justify-space-around
                    row
                    wrap
                >
                    <template v-slot:item="{ item }">
                        <v-row>
                            <span class="primary--text pointer" @click="onClick(item)">{{ item.name }}</span>
                        </v-row>
                    </template>
                </v-data-iterator>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import WikiMixin from '../Mixins/WikiMixin';

export default {
    name: 'wiki-selector',
    mixins: [WikiMixin],
    props: {
        items: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onClick(value) {
            if (!this.disabled) {
                this.$emit('input', value);
            }
        }
    }
};
</script>
