<template>
    <v-menu v-model="menu" offset-x :close-on-content-click="false" :offset-overflow="true">
        <template v-slot:activator="{ on }">
            <v-input
                persistent-hint
                :hint="
                    $t(
                        'Wählen Sie einen Wiki-Eintrag aus, von der der neue Wiki-Eintrag abstammen soll. Wenn Sie keinen Wiki-Eintrag auswählen, wird der neue Wiki-Eintrag im Stammverzeichnis angelegt'
                    )
                "
            >
                <template v-slot:prepend>
                    <v-btn v-on="on" icon>
                        <v-icon>mdi-file-document-edit</v-icon>
                    </v-btn>
                </template>
                <v-breadcrumbs divider=">" :items="displayWikiPath">
                    <template v-slot:item="{ item }">
                        <span
                            :class="{
                                caption: true,
                                'primary--text': item.id === value
                            }"
                            >{{ item.name }}</span
                        >
                    </template>
                </v-breadcrumbs>
            </v-input>
        </template>
        <v-card :width="calculateMenuWidth">
            <v-toolbar text dark color="secondary">
                <v-toolbar-title>{{ $t('Stammwiki auswählen') }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-breadcrumbs divider=">" :items="selectedWikiPath">
                    <template v-slot:item="{ item }">
                        <span
                            :class="{
                                'primary--text': item.index !== selectedWikiPath.length,
                                pointer: item.index !== selectedWikiPath.length
                            }"
                            @click="onClick(item)"
                            >{{ item.name }}</span
                        >
                    </template>
                </v-breadcrumbs>
                <v-data-iterator
                    :items="computedItems"
                    :no-data-text="$t('keine Wiki-Einträge gefunden')"
                    :no-results-text="$t('kein Ergebnis')"
                    :items-per-page="5"
                    :footer-props="{
                        'items-per-page-text': $t('Wiki pro Seite'),
                        'items-per-page-options': [5, 10, 15, { value: -1, text: $t('Alle') }]
                    }"
                    :hideDefaultFooter="computedItems.length <= 5"
                >
                    <template v-slot:item="{ item }">
                        <v-list-item :value="isSelected(item.id)" @click="onClick(item)">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-data-iterator>
            </v-card-text>
            <v-card-actions>
                <v-btn block color="success" @click="handleWikiParentSelection(selectedParentWiki)">
                    {{ $t('Auswählen') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
import WikiMixin from '../Mixins/WikiMixin';

export default {
    name: 'wiki-parent-selector',
    mixins: [WikiMixin],
    data: () => ({
        selectedParentWiki: '',
        menu: false
    }),
    props: {
        value: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: ''
        }
    },
    computed: {
        rootPathObject() {
            return {
                name: this.$t('Stammverzeichnis'),
                parentId: '',
                id: '',
                index: 0
            };
        },
        calculateMenuWidth() {
            return this.$vuetify.breakpoint.smAndUp ? this.$vuetify.breakpoint.thresholds.xs : undefined;
        },
        computedSelect: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        computedItems() {
            return this.getWikiList.filter((wiki) => wiki.parentId === this.selectedParentWiki && wiki.id !== this.id);
        },
        displayWikiPath() {
            if (this.value === '') {
                return [this.rootPathObject];
            }
            return [
                this.rootPathObject,
                ...this.findPath(
                    [],
                    this.getWikiList.find((wiki) => wiki.id === this.value)
                )
            ].map((item, index) => ({ ...item, index: index + 1 }));
        },
        selectedWikiPath() {
            if (this.selectedParentWiki === '') {
                return [this.rootPathObject];
            }
            return [
                this.rootPathObject,
                ...this.findPath(
                    [],
                    this.getWikiList.find((wiki) => wiki.id === this.selectedParentWiki)
                )
            ].map((item, index) => ({ ...item, index: index + 1 }));
        }
    },
    methods: {
        handleWikiParentSelection(value) {
            this.computedSelect = value;
            this.menu = false;
        },
        isSelected(id) {
            return id === this.value;
        },
        onClick({ id }) {
            this.selectedParentWiki = id;
        },
        findPath(path, item) {
            if (item === undefined || item === null || item === -1) {
                return path;
            }
            return this.findPath(
                [item, ...path],
                this.getWikiList.find((wiki) => wiki.id === item.parentId)
            );
        }
    }
};
</script>
