<template>
    <v-card text>
        <v-toolbar text color="secondary" dense dark>
            <v-app-bar-nav-icon>
                <v-btn icon @click="back">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
            </v-app-bar-nav-icon>
        </v-toolbar>
        <v-card-text :style="floatingBlock">
            <v-form v-model="validate" ref="form">
                <v-layout column>
                    <v-flex>
                        <v-text-field
                            :label="$t('Name')"
                            :hint="$t('Name der neuen Taxonomie')"
                            persistent-hint
                            class="mb-3"
                            v-model="name"
                            :rules="[nonEmpty]"
                        />
                    </v-flex>
                    <v-flex>
                        <compact-picker class="elevation-0" v-model="color" />
                    </v-flex>
                </v-layout>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn block color="warning" :disabled="!enableCreateTaxonomy" @click="saveTaxonomy">{{ $t('Taxonomie aktualisieren') }}</v-btn>
        </v-card-actions>
        <v-card-actions>
            <v-btn block color="error" @click="deleteTaxonomy">{{ $t('Taxonomie löschen') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import CourseMixin from '../../Mixins/CourseMixin';
import { Compact } from 'vue-color';

export default {
    name: 'update-delete-taxonomy-table',
    mixins: [CourseMixin],
    components: { 'compact-picker': Compact },
    data: () => ({
        id: '',
        color: {
            hex: '#FFFFFF'
        },
        name: '',
        validate: false
    }),
    props: {
        taxonomy: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        taxonomy: {
            immediate: true,
            handler(value) {
                Object.entries(value).forEach(([key, value]) => {
                    if (this.$data.hasOwnProperty(key)) {
                        this.$data[key] = value;
                    }
                });
                if (value.hasOwnProperty('hexColor')) {
                    this.color.hex = value.hexColor;
                }
            }
        }
    },
    computed: {
        floatingBlock() {
            let height = '50vh';
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': {
                    height = '90vh';
                    break;
                }
                case 'sm': {
                    height = '70vh';
                    break;
                }
                case 'md': {
                    height = '50vh';
                    break;
                }
                case 'xl': {
                    height = '30vh';
                    break;
                }
            }

            return {
                'max-height': height,
                'overflow-y': 'auto'
            };
        },
        enableCreateTaxonomy() {
            return this.validate && this.color.hasOwnProperty('hex') && this.color.hex !== '' && this.color.hex !== undefined && this.color.hex !== null;
        }
    },
    methods: {
        back() {
            this.$emit('switchComponent', '');
        },
        nonEmpty(value) {
            return !!value || this.$t('Dieses Feld darf nicht leer sein');
        },
        serializeTaxonomy() {
            return {
                id: this.id,
                name: this.name,
                hexColor: this.color.hex,
                resetable: false,
                folderId: ''
            };
        },
        serializeTaxonomyTable(taxonomy) {
            return {
                id: this.getCourseTaxonomies.id,
                name: 'empty',
                labels: [...this.getCourseTaxonomies.labels, taxonomy],
                folderId: ''
            };
        },
        saveTaxonomy() {
            this.UPDATE_TAXONOMY(this.serializeTaxonomy())
                .then((taxonomy) => this.UPDATE_TAXONOMY_TABLE(this.serializeTaxonomyTable(taxonomy)))
                .then((response) =>
                    this.UPDATE_COURSE_TAXONOMY_TABLE({
                        course: this.getSelectedAdminCourse,
                        taxonomyTable: response
                    })
                )
                .then(this.back());
        },
        deleteTaxonomy() {
            this.DELETE_TAXONOMY(this.serializeTaxonomy()).then(() => this.back());
        }
    },
    mounted() {
        this.$nextTick(() => this.$refs.form.validate());
    }
};
</script>
