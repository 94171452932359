<template>
    <v-container>
        <!-- Fortschritt -->
        <v-row>
            <v-col :xs="12" :sm="6">
                <v-card>
                    <v-toolbar dense flat dark color="primary">
                        <v-toolbar-title>{{ $t('Zeitlicher Fortschritt') }}</v-toolbar-title>
                    </v-toolbar>
                    <v-container>
                        <v-row>
                            <v-col :cols="12" align-self="center">
                                <v-progress-linear color="secondary" :value="calculateTimeProgress" :size="75" :height="14">
                                    <span class="white--text">{{ `${calculateTimeProgress}%` }}</span>
                                </v-progress-linear>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col :xs="12" :sm="6">
                <v-card>
                    <v-toolbar dense flat dark color="primary">
                        <v-toolbar-title>{{ $t('Aufgaben Fortschritt') }}</v-toolbar-title>
                    </v-toolbar>
                    <v-container>
                        <v-row>
                            <v-col :cols="12" align-self="center">
                                <v-progress-linear color="secondary" :value="task.taskProgress.toFixed(2)" :size="75" :height="14">
                                    <span class="white--text">{{ `${task.taskProgress.toFixed(2)}%` }}</span>
                                </v-progress-linear>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <!-- Task Details -->
        <v-row>
            <v-col :cols="12">
                <v-card>
                    <v-toolbar dense flat dark color="primary">
                        <v-toolbar-title>{{ $t('Aufgabe') }}</v-toolbar-title>
                    </v-toolbar>
                    <v-container>
                        <v-row>
                            <v-col :cols="6">
                                <v-text-field readonly hide-details :value="$t(task.name)" :label="$t('Aufgabenbezeichnung')" />
                            </v-col>
                            <v-col :cols="6">
                                <v-text-field readonly hide-details :value="task.startDate" :label="$t('Anfangsdatum')" />
                            </v-col>
                            <v-col :cols="6">
                                <v-text-field readonly hide-details :value="task.endDate" :label="$t('Enddatum')" />
                            </v-col>
                            <v-col :cols="6">
                                <v-text-field
                                    readonly
                                    hide-details
                                    :value="task.taskOptions.extendEndDate ? task.taskOptions.extendEndDate : $t('Keine')"
                                    :label="$t('Erweitertes Enddatum')"
                                />
                            </v-col>
                            <v-col :cols="6">
                                <v-text-field readonly hide-details :value="task.taskOptions.limits" :label="$t('Limit')" />
                            </v-col>
                            <v-col :cols="6">
                                <v-text-field readonly hide-details :value="task.taskOptions.passingQuote" :label="$t('Bestehensquote')" />
                            </v-col>
                            <v-col :cols="6">
                                <v-text-field readonly hide-details :value="$t(task.taskOptions.sortBy)" :label="$t('Katalog Sortierung')" />
                            </v-col>
                            <v-col :cols="12">
                                <v-expansion-panels accordion tile class="pt-2">
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>{{ $t('Ergebnis') }}</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <ResizeContainer v-model="invervallDimensions">
                                                <GChart type="BarChart" :class="{ dark: this.isDark }" :data="formatBarChartData" :options="barChartOptions" />
                                            </ResizeContainer>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <!-- Catalog Details -->
            <v-col :cols="12">
                <v-card>
                    <v-toolbar dense flat dark color="primary">
                        <v-toolbar-title>{{ $t('Kataloge') }}</v-toolbar-title>
                        <v-spacer />
                        <v-toolbar-items>
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn :disabled="taskCatalogsKnowledgeUnits.length > 0" icon v-on="on" v-bind="attrs" @click="loadKnowledgeUnits">
                                        <v-icon>mdi-file-document</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('Wissenseinheiten laden') }}</span>
                            </v-tooltip>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-data-iterator
                        :items="catalogs"
                        :no-data-text="$t('Keine Kataloge verfügbar')"
                        :no-results-text="$t('Kein Ergebnis')"
                        :hideDefaultFooter="catalogs.length === 0"
                        :items-per-page="5"
                        :footer-props="{
                            'items-per-page-text': $t('Kataloge pro Seite'),
                            'items-per-page-options': [5, 10, 15, { text: $t('Alle'), value: -1 }]
                        }"
                    >
                        <template #default="{ items }">
                            <v-expansion-panels accordion tile>
                                <v-expansion-panel v-for="item in items" :key="item.id">
                                    <v-expansion-panel-header>{{ item.name }}</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-container>
                                            <v-row>
                                                <v-col :cols="6">
                                                    <v-text-field readonly hide-details :value="item.description" :label="$t('Beschreibung')" />
                                                </v-col>
                                                <v-col :cols="6">
                                                    <v-text-field
                                                        readonly
                                                        hide-details
                                                        :value="$t(item.options.sortBy)"
                                                        :label="$t('Wissenseinheiten Sortierung')"
                                                    />
                                                </v-col>
                                                <v-col :cols="6">
                                                    <v-text-field
                                                        readonly
                                                        hide-details
                                                        :value="catalogArchiveOption(item.options)"
                                                        :label="$t('Archivieren')"
                                                    />
                                                </v-col>
                                                <v-col :cols="6">
                                                    <v-text-field
                                                        readonly
                                                        hide-details
                                                        :value="$t(item.options.onFailure)"
                                                        :label="$t('Wenn Katalog nicht bestanden wird')"
                                                    />
                                                </v-col>
                                                <v-col :cols="4">
                                                    <v-switch
                                                        readonly
                                                        hide-details
                                                        :label="$t('Wissensbasis anzeigen')"
                                                        :input-value="item.options.showKnowledgeBase"
                                                    />
                                                </v-col>
                                                <v-col :cols="4">
                                                    <v-switch
                                                        readonly
                                                        hide-details
                                                        :label="$t('Erklärung anzeigen')"
                                                        :input-value="item.options.showExplanation"
                                                    />
                                                </v-col>
                                                <v-col :cols="4">
                                                    <v-switch
                                                        readonly
                                                        hide-details
                                                        :label="$t('Wissensbasis öffnen')"
                                                        :input-value="item.options.reopenKnowledgeBase"
                                                    />
                                                </v-col>
                                                <v-col :cols="12">
                                                    <v-data-iterator
                                                        :items="catalogKnowledgeUnits(item.id)"
                                                        :no-data-text="$t('Keine Wissenseinheiten verfügbar')"
                                                        :no-results-text="$t('Kein Ergebnis')"
                                                        :hideDefaultFooter="taskCatalogsKnowledgeUnits.length === 0"
                                                        :items-per-page="5"
                                                        :footer-props="{
                                                            'items-per-page-text': $t('Wissenseinheiten pro Seite'),
                                                            'items-per-page-options': [5, 10, 15, { text: $t('Alle'), value: -1 }]
                                                        }"
                                                        v-if="taskCatalogsKnowledgeUnits.length !== 0"
                                                    >
                                                        <template #default="{ items: knowledgeUnits }">
                                                            <v-expansion-panels accordion tile>
                                                                <v-expansion-panel v-for="knowledgeUnit in knowledgeUnits" :key="knowledgeUnit.id">
                                                                    <v-expansion-panel-header>{{ knowledgeUnit.question }}</v-expansion-panel-header>
                                                                    <v-expansion-panel-content>
                                                                        <v-card flat>
                                                                            <v-container>
                                                                                <v-row>
                                                                                    <v-col :cols="6">
                                                                                        <v-text-field
                                                                                            readonly
                                                                                            hide-details
                                                                                            :value="questionTypeText(knowledgeUnit.questionType)"
                                                                                            :label="$t('Fragentyp')"
                                                                                        />
                                                                                    </v-col>
                                                                                    <v-col :cols="6">
                                                                                        <v-text-field
                                                                                            readonly
                                                                                            hide-details
                                                                                            :value="$t(knowledgeUnit.order)"
                                                                                            :label="$t('Reihenfolge')"
                                                                                        />
                                                                                    </v-col>
                                                                                    <v-col :cols="12">
                                                                                        <v-expansion-panels accordion tile>
                                                                                            <v-expansion-panel
                                                                                                v-for="(option, index) in knowledgeUnit.knowledgeUnitOptions"
                                                                                                :key="option.id"
                                                                                            >
                                                                                                <v-expansion-panel-header>
                                                                                                    {{ `${$t('Option')} ${index + 1}` }}
                                                                                                </v-expansion-panel-header>
                                                                                                <v-expansion-panel-content>
                                                                                                    <v-container>
                                                                                                        <v-row>
                                                                                                            <v-col :cols="6" v-if="option.text !== ''">
                                                                                                                <v-text-field
                                                                                                                    readonly
                                                                                                                    hide-details
                                                                                                                    :value="option.text"
                                                                                                                    :label="$t('Antwort')"
                                                                                                                />
                                                                                                            </v-col>
                                                                                                            <v-col :cols="6" v-if="option.maximum !== ''">
                                                                                                                <v-text-field
                                                                                                                    readonly
                                                                                                                    hide-details
                                                                                                                    :value="option.maximum"
                                                                                                                    :label="$t('Maximum')"
                                                                                                                />
                                                                                                            </v-col>
                                                                                                            <v-col :cols="6" v-if="option.minimum !== ''">
                                                                                                                <v-text-field
                                                                                                                    readonly
                                                                                                                    hide-details
                                                                                                                    :value="option.minimum"
                                                                                                                    :label="$t('Minimum')"
                                                                                                                />
                                                                                                            </v-col>
                                                                                                            <v-col :cols="6" v-if="option.steps !== ''">
                                                                                                                <v-text-field
                                                                                                                    readonly
                                                                                                                    hide-details
                                                                                                                    :value="option.steps"
                                                                                                                    :label="$t('Schritte')"
                                                                                                                />
                                                                                                            </v-col>
                                                                                                            <v-col :cols="6" v-if="option.sequenceNo !== ''">
                                                                                                                <v-text-field
                                                                                                                    readonly
                                                                                                                    hide-details
                                                                                                                    :value="option.sequenceNo"
                                                                                                                    :label="$t('Sequenz')"
                                                                                                                />
                                                                                                            </v-col>
                                                                                                            <v-col :cols="6">
                                                                                                                <v-switch
                                                                                                                    readonly
                                                                                                                    hide-details
                                                                                                                    :input-value="option.isAnswer"
                                                                                                                    :label="$t('Ist Antwort')"
                                                                                                                />
                                                                                                            </v-col>
                                                                                                        </v-row>
                                                                                                    </v-container>
                                                                                                </v-expansion-panel-content>
                                                                                            </v-expansion-panel>
                                                                                        </v-expansion-panels>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </v-container>
                                                                            <v-card-actions>
                                                                                <v-row>
                                                                                    <v-col :xs="12" :sm="6">
                                                                                        <v-btn
                                                                                            block
                                                                                            color="primary"
                                                                                            :disabled="
                                                                                                knowledgeUnit.knowledgeBase.knowledgeContents.length === 0
                                                                                            "
                                                                                            @click="
                                                                                                showKnowledgeContents(
                                                                                                    knowledgeUnit.knowledgeBase.knowledgeContents
                                                                                                )
                                                                                            "
                                                                                        >
                                                                                            {{ $t('Wissensbasis anzeigen') }}
                                                                                        </v-btn>
                                                                                    </v-col>
                                                                                    <v-col :xs="12" :sm="6">
                                                                                        <v-btn
                                                                                            block
                                                                                            color="secondary"
                                                                                            :disabled="
                                                                                                knowledgeUnit.knowledgeExplanation.knowledgeContents.length ===
                                                                                                0
                                                                                            "
                                                                                            @click="
                                                                                                showKnowledgeContents(
                                                                                                    knowledgeUnit.knowledgeExplanation.knowledgeContents
                                                                                                )
                                                                                            "
                                                                                        >
                                                                                            {{ $t('Erklärung anzeigen') }}
                                                                                        </v-btn>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </v-card-actions>
                                                                        </v-card>
                                                                    </v-expansion-panel-content>
                                                                </v-expansion-panel>
                                                            </v-expansion-panels>
                                                        </template>
                                                    </v-data-iterator>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </template>
                    </v-data-iterator>
                </v-card>
            </v-col>
            <!-- User Details -->
            <v-col :cols="12">
                <v-card>
                    <v-toolbar dense flat dark color="primary">
                        <v-toolbar-title>{{ $t('Nutzer') }}</v-toolbar-title>
                        <v-spacer />
                        <v-toolbar-items>
                            <v-tooltip>
                                <template #activator="{ on, attrs }">
                                    <v-btn icon @click="loadUsers" :disabled="users.length > 0">
                                        <v-icon>mdi-account</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('Nutzer laden') }}</span>
                            </v-tooltip>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-data-iterator
                        :items="users"
                        :no-data-text="$t('Keine Nutzer verfügbar')"
                        :no-results-text="$t('Kein Ergebnis')"
                        :hideDefaultFooter="users.length === 0"
                        :items-per-page="5"
                        :footer-props="{
                            'items-per-page-text': $t('Nutzer pro Seite'),
                            'items-per-page-options': [5, 10, 15, { text: $t('Alle'), value: -1 }]
                        }"
                    >
                        <template #default="{ items }">
                            <v-expansion-panels accordion tile>
                                <v-expansion-panel v-for="item in items" :key="item.id">
                                    <v-expansion-panel-header>{{ item.displayName }}</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-container>
                                            <v-row>
                                                <v-col :cols="6">
                                                    <v-text-field readonly hide-details :value="item.displayName" :label="$t('Angezeigter Name')" />
                                                </v-col>
                                                <v-col :cols="6">
                                                    <v-text-field readonly hide-details :value="item.firstName" :label="$t('Vorname')" />
                                                </v-col>
                                                <v-col :cols="6">
                                                    <v-text-field readonly hide-details :value="item.lastName" :label="$t('Nachname')" />
                                                </v-col>
                                                <v-col :cols="6">
                                                    <v-text-field readonly hide-details :value="item.email" :label="$t('E-Mail')" />
                                                </v-col>
                                                <v-col :cols="6">
                                                    <v-text-field readonly hide-details :value="item.personalNumber" :label="$t('Personalnummer')" />
                                                </v-col>
                                                <v-col :cols="6">
                                                    <v-text-field readonly hide-details :value="role(item)" :label="$t('Rolle')" />
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </template>
                    </v-data-iterator>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { GChart } from 'vue-google-charts';
import ResizeContainer from '../../Generic/Resize/ResizeContainer';

import ChartDetailsMixin from '../Mixins/ChartDetailsMixin';
import moment from 'moment';

export default {
    name: 'task-chart-details',
    components: {
        GChart,
        ResizeContainer
    },
    mixins: [ChartDetailsMixin],
    data: () => ({
        taskUsers: [],
        taskCatalogsKnowledgeUnits: [],
        invervallDimensions: {
            height: 0,
            width: 0,
            deltaheight: 0,
            deltaWidth: 0
        }
    }),
    props: {
        value: {
            type: Object
        }
    },
    watch: {
        value() {
            this.taskUsers = [];
            this.taskCatalogsKnowledgeUnits = [];
        }
    },
    computed: {
        users() {
            return this.taskUsers;
        },
        task() {
            return this.value.taskDetails;
        },
        catalogs() {
            return this.value.taskDetails.catalogs;
        },
        calculateTimeProgress() {
            let now = moment();
            let startDate = moment(this.task.startDate);
            let endDate = moment(this.task.endDate);

            if (now.isBefore(startDate)) {
                return Number(0).toFixed(2);
            }

            if (now.isAfter(endDate)) {
                return Number(100).toFixed(2);
            }

            let MaxDist = endDate.diff(startDate, 'days');
            let currentDist = now.diff(startDate, 'days');

            return ((currentDist / MaxDist) * 100).toFixed(2);
        },
        calculateTaskProgress() {
            return (this.value.totalAnswered / this.value.totalKUCount) * 100;
        },
        barChartOptions() {
            return {
                title: this.$t('Intervallergebnis'),
                titleTextStyle: {
                    color: this.isDark ? '#FFF' : '#000'
                },
                width: this.invervallDimensions.width,
                height: 300,
                isStacked: 'percent',
                legend: { position: 'top', maxLines: 3 },
                backgroundColor: this.isDark ? '#1E1E1E' : '#FFF',
                vAxis: {
                    format: 'percent',
                    titleTextStyle: { color: this.isDark ? '#FFF' : '#000' }
                },
                hAxis: {
                    minValue: 0,
                    format: 'percent',
                    ticks: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
                    titleTextStyle: { color: this.isDark ? '#FFF' : '#000' }
                },
                series: {
                    0: { color: this.$vuetify.theme.currentTheme.success },
                    1: { color: this.$vuetify.theme.currentTheme.error },
                    2: { color: this.$vuetify.theme.currentTheme.accent }
                },
                tooltip: { isHtml: this.isDark }
            };
        },
        formatBarChartData() {
            if (this.value.hasOwnProperty('votes')) {
                return [
                    [this.$t('Ergebnis'), this.$t('Richtig'), this.$t('Falsch'), this.$t('Unbeantwortet')],
                    [this.$t('Ergebnis'), this.value.votes.correct, this.value.votes.incorrect, this.value.votes.unanswered]
                ];
            }
            return [
                [this.$t('Ergebnis'), this.$t('Richtig'), this.$t('Falsch'), this.$t('Unbeantwortet')],
                [this.$t('Ergebnis'), 0, 0, 0]
            ];
        },
        isDark() {
            return this.$vuetify.theme.dark;
        }
    },
    methods: {
        role(user) {
            switch (user.role) {
                case 'SUPER_ADMIN':
                    return 'Super Admin';
                case 'ADMIN':
                    return 'Admin';
                case 'USER':
                    return 'User';
            }
        },
        catalogKnowledgeUnits(catalogId) {
            return this.taskCatalogsKnowledgeUnits.filter((knowledgeUnit) => knowledgeUnit.catalogId === catalogId).sort((a, b) => a.order - b.order);
        },
        loadKnowledgeUnits() {
            this.GET_LINK(this.task.links.knowledgeUnits).then((data) => (this.taskCatalogsKnowledgeUnits = data));
        },
        loadUsers() {
            this.GET_LINK(this.task.links.users).then((data) => (this.taskUsers = data));
        },
        notificationDays(notificationDaysBefore) {
            return notificationDaysBefore.join(', ');
        },
        catalogArchiveOption(catalogOptions) {
            if (!catalogOptions.archive) {
                return 'Katalog wird nicht archiviert';
            }
            switch (true) {
                case catalogOptions.archiveOnPass:
                    return 'Katalog wird nach Beendigung der Aufgabe archiviert';
                case catalogOptions.archiveImmediately:
                    return 'Katalog wird sofort archiviert';
                case catalogOptions.archiveOnEndDate:
                    return 'Katalog wird nach Enddatum der Aufgabe archiviert';
            }
            return '';
        },
        showKnowledgeContents(knowledgeContents) {
            if (knowledgeContents.length > 0) {
                this.$emit('knowledgeContents', knowledgeContents);
            }
        },
        questionTypeText(type) {
            return this.$t(type);
        }
    }
};
</script>
