const styles = {
    primaryBorder: '#1976D2',
    primary: '#1976D2',
    secondary: '#424242',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
    background: '#FFFFFF'
};

const getDefaultStyles = () => Object.assign({}, styles);

export default {
    getDefaultStyles
};
