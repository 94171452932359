<template>
    <v-menu :close-on-content-click="false" offset-y transition="scale-transition" :disabled="disabled">
        <template v-slot:activator="{ on }">
            <v-text-field
                :disabled="disabled"
                prepend-icon="mdi-clock-outline"
                :label="label"
                v-on="on"
                readonly
                :value="computedValue"
                :hint="hint"
                persistent-hint
            />
        </template>
        <v-time-picker format="24hr" :min="min" :max="max" :disabled="disabled" v-model="computedValue" full-width />
    </v-menu>
</template>

<script>
export default {
    name: 'time-picker',
    props: {
        value: {
            type: String,
            default: '0:00'
        },
        label: {
            type: String,
            default: ''
        },
        hint: {
            type: String,
            default: ''
        },
        min: {
            type: String,
            default: undefined
        },
        max: {
            type: String,
            default: undefined
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        min(value) {
            if (this.value < value) {
                this.$emit('input', value);
            }
        }
    },
    computed: {
        computedValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    }
};
</script>
