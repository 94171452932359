<template>
    <v-card>
        <v-tabs grow v-model="tabs">
            <v-tab key="GENERIC">{{ $t('Einstellungen') }}</v-tab>
            <v-tab key="CATALOGS">{{ $t('Kataloge') }}</v-tab>
        </v-tabs>
        <v-card-text>
            <v-tabs-items v-model="tabs" touchless>
                <v-tab-item key="GENERIC">
                    <QuizActivityOptions v-model="taskOptionsComputed" :isTask="false" :isArchive="false" />
                </v-tab-item>
                <v-tab-item key="CATALOGS">
                    <ArchiveCatalogSelect v-model="catalogsComputed" @excludeKnowledgeUnits="handleExcludeKnowledgeUnitsDialog" :isTask="false" />
                </v-tab-item>
            </v-tabs-items>
            <QuizActivityExcludeCatalogs
                v-model="showExcludeKnowledgeUnits"
                :catalog="selectedCatalog"
                :knowledgeUnits="knowledgeUnitsComputed"
                @knowledgeUnits="handleKnowledgeUnits"
            />
        </v-card-text>
    </v-card>
</template>

<script>
import QuizActivityOptions from '../Helpers/QuizActivityOptions';
import ArchiveCatalogSelect from '../Helpers/QuizActivityCatalogsSwitch';
import QuizActivityExcludeCatalogs from '../Helpers/QuizActivityExcludeCatalogs';

import CourseMixin from '../../Mixins/CourseMixin';

export default {
    name: 'quiz-activity',
    components: {
        QuizActivityOptions,
        ArchiveCatalogSelect,
        QuizActivityExcludeCatalogs
    },
    mixins: [CourseMixin],
    data: () => ({
        tabs: 0,
        validate: false,
        showExcludeKnowledgeUnits: false,
        selectedCatalog: {}
    }),
    props: {
        value: {
            type: Object,
            default: () => ({
                quiz: {}
            })
        }
    },
    computed: {
        taskOptionsComputed: {
            get() {
                if (this.value && this.value.quiz && this.value.quiz.taskOptions && Object.keys(this.value.quiz.taskOptions).length > 0) {
                    return this.value.quiz.taskOptions;
                }
                return {
                    sortBy: 'NATURAL',
                    sortList: ['NATURAL', 'RANDOM', 'DATE'],
                    limits: 0,
                    extendEndDate: '',
                    loopingTask: false,
                    passingQuote: 0,
                    methodName: '',
                    methodList: [],
                    shuffleOptions: false,
                    showResult: true
                };
            },
            set(value) {
                this.$emit('input', {
                    quiz: { ...this.serialize(), taskOptions: value }
                });
            }
        },
        catalogsComputed: {
            get() {
                if (this.value && this.value.quiz && this.value.quiz.catalogs && Array.isArray(this.value.quiz.catalogs)) {
                    return this.value.quiz.catalogs;
                }
                return [];
            },
            set(value) {
                if (this.value && this.value.quiz && this.value.quiz.excludeKnowledgeUnits && this.value.quiz.excludeKnowledgeUnits.length > 0) {
                    const catalogIds = value.map((catalog) => catalog.id);
                    const filteredKnowledgeUnits = this.value.quiz.excludeKnowledgeUnits.filter((knowledgeUnit) =>
                        catalogIds.includes(knowledgeUnit.catalogId)
                    );
                    return this.$emit('input', {
                        quiz: {
                            ...this.serialize(),
                            catalogs: value,
                            excludeKnowledgeUnits: filteredKnowledgeUnits
                        }
                    });
                }
                this.$emit('input', {
                    quiz: { ...this.serialize(), catalogs: value }
                });
            }
        },
        knowledgeUnitsComputed: {
            get() {
                if (this.value && this.value.quiz && this.value.quiz.excludeKnowledgeUnits && Array.isArray(this.value.quiz.excludeKnowledgeUnits)) {
                    return this.value.quiz.excludeKnowledgeUnits;
                }
                return [];
            },
            set(value) {
                this.$emit('input', {
                    quiz: { ...this.serialize(), excludeKnowledgeUnits: value }
                });
            }
        }
    },
    methods: {
        handleKnowledgeUnits(value) {
            this.$emit('input', {
                quiz: { ...this.serialize(), excludeKnowledgeUnits: value }
            });
        },
        handleExcludeKnowledgeUnitsDialog(value) {
            this.selectedCatalog = value;
            this.showExcludeKnowledgeUnits = true;
        },
        serialize() {
            return {
                name: `${this.getSelectedLearningGoal.name}-Quiz`,
                color: '#62b5ad',
                excludeKnowledgeUnits: [],
                ...this.value.quiz
            };
        }
    },
    activated() {
        this.$emit('input', {
            quiz: { ...this.serialize(), taskOptions: this.taskOptionsComputed }
        });
    }
};
</script>
