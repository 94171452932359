import axios from '../../plugins/Axios/axios';

const state = {
    totalLicences: 0,
    consumedLicences: 0,
    remainingLicences: 0
};

const getters = {
    getTotalLicences() {
        return state.totalLicences;
    },
    getConsumedLicences() {
        return state.consumedLicences;
    },
    getRemainingLicences() {
        return state.remainingLicences;
    }
};

const actions = {
    GET_LICENCES({ commit }) {
        return axios.get(`/licences`).then((response) => {
            commit('updateTotalLicences', response.data.data.totalLicenseCount);
            commit('updateRemainingLicences', response.data.data.remainingLicenseCount);
            commit('updateConsumedLicences', response.data.data.consumedLicenseCount);
            return response;
        });
    }
};

const mutations = {
    clear: (state) => {
        state.totalLicences = 0;
        state.consumedLicences = 0;
        state.remainingLicences = 0;
    },
    updateTotalLicences: (state, value) => (state.totalLicences = value),
    updateRemainingLicences: (state, value) => (state.remainingLicences = value),
    updateConsumedLicences: (state, value) => (state.consumedLicences = value)
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
