<template>
    <v-navigation-drawer fixed touchless :disable-resize-watcher="disableResizeWatcher" v-model="drawerState" app v-if="clientRole" nav>
        <UserProfile />
        <v-divider />
        <v-list dense>
            <!-- General setup -->
            <navigation-drawer-single-tile v-for="(item, index) in generalFilter" :key="`General-${index}`" :value="item" />

            <v-divider v-if="generalFilter.length > 0" />

            <!-- Favourite setup -->
            <navigation-drawer-single-tile v-for="(item, index) in favouriteFilter" :key="`favourite-${index}`" :value="item" />

            <v-divider v-if="favouriteFilter.length > 0" />

            <!-- Single Group Setup -->
            <navigation-drawer-group-tile v-for="(item, index) in singleGroupGroupedById" :key="`SingleGroup-${index}`" :value="item" />
            <!-- Group setup -->
            <navigation-drawer-group-tile v-for="(item, index) in groupsGroupedById" :key="`Groups-${index}`" :value="item" />

            <v-divider v-if="groupsGroupedById.size > 0" />

            <!-- Utility setup -->
            <navigation-drawer-single-tile v-for="(item, index) in utilityFilter" :key="`Utility-${index}`" :value="item" />
            <v-divider v-if="utilityFilter.length > 0" />

            <!-- log out -->
            <v-list-item @click="openWebconference" v-if="this.getLoggedInAdmin.role !== 'ROOT' && this.getLoggedInAdmin.role !== 'SALES'">
                <v-list-item-icon>
                    <v-icon>mdi-video</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('Webkonferenz') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item @click="signOut">
                <v-list-item-icon>
                    <v-icon>mdi-exit-to-app</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('Abmelden') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider v-if="utilityFilter.length > 0" />
        </v-list>

        <LanguageSelector />
        <v-divider />

        <v-list-item>
            <v-list-item-icon>
                <v-icon size="20" color="white" :class="isDark ? 'light' : 'dark'" class="mode-icon">mdi-weather-night</v-icon>
            </v-list-item-icon>
            <v-switch v-model="isDark" :label="$t('Dunkler Modus')" />
        </v-list-item>
    </v-navigation-drawer>
</template>

<script>
import UserProfile from '../Profile/UserProfile';
import LanguageSelector from './LanguageSelector';
import NavigationDrawerSingleTile from './Helpers/NavigationDrawerSingleTile';
import NavigationDrawerGroupTile from './Helpers/NavigationDrawerGroupTile';
import { mapGetters, mapActions } from 'vuex';
import { Storage } from '../../plugins/Storage/storage';

export default {
    name: 'navigation-drawer',
    components: {
        UserProfile,
        LanguageSelector,
        NavigationDrawerSingleTile,
        NavigationDrawerGroupTile
    },
    data: () => ({
        navigationFilter: [],
        disableResizeWatcherIncludeList: ['appQuiz', 'authoringToolKnowledgeUnitPreview'],
        disableResizeWatcher: false
    }),
    computed: {
        ...mapGetters('NavigationOperations', ['getDrawerState', 'getFavouriteMap', 'getHiddenMap']),
        ...mapGetters('General', ['accesstoken']),
        clientRole() {
            return this.getLoggedInAdmin.role;
        },
        client() {
            return this.getLoggedInAdmin.id;
        },
        flattendRouter() {
            return this.flattenRouter(this.$router.options.routes);
        },
        generalFilter() {
            return this.navigationFilter.filter((item) => item.meta.navigation.type.name === 'General').sort(this.sortAlphabetically);
        },
        favouriteFilter() {
            return this.navigationFilter
                .filter(
                    (item) => this.hasOwn(this.getFavouriteMap, item.meta.navigation.navigationKey) && this.getFavouriteMap[item.meta.navigation.navigationKey]
                )
                .sort(this.sortAlphabetically);
        },
        singleGroupFilter() {
            return this.navigationFilter
                .filter((item) => item.meta.navigation.type.name === 'SingleGroup')
                .filter((item) =>
                    this.hasOwn(this.getFavouriteMap, item.meta.navigation.navigationKey) ? !this.getFavouriteMap[item.meta.navigation.navigationKey] : true
                )
                .sort(this.sortAlphabetically)
                .sort((itemA, itemB) =>
                    itemA.meta.navigation.type.groupId.localeCompare(itemB.meta.navigation.type.groupId, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    })
                );
        },
        groupsFilter() {
            const groupMap = this.navigationFilter
                .filter((item) => item.meta.navigation.type.name === 'Groups')
                .filter((item) =>
                    this.hasOwn(this.getFavouriteMap, item.meta.navigation.navigationKey) ? !this.getFavouriteMap[item.meta.navigation.navigationKey] : true
                )
                .sort((itemA, itemB) =>
                    itemA.meta.navigation.type.groupId.localeCompare(itemB.meta.navigation.type.groupId, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    })
                )
                .reduce((map, item) => {
                    if (map.has(item.meta.navigation.type.groupId)) {
                        map.set(item.meta.navigation.type.groupId, [...map.get(item.meta.navigation.type.groupId), item]);
                    } else {
                        map.set(item.meta.navigation.type.groupId, [item]);
                    }
                    return map;
                }, new Map());

            groupMap.forEach((group) => {
                group.sort((itemA, itemB) => {
                    const priority = itemA.meta.navigation.priority - itemB.meta.navigation.priority;
                    if (priority === 0) {
                        return this.sortAlphabetically(itemA, itemB);
                    }
                    return priority;
                });
            });

            return Array.from(groupMap, ([, value]) => value).flat();
        },
        utilityFilter() {
            return this.navigationFilter.filter((item) => item.meta.navigation.type.name === 'Utility').sort(this.sortAlphabetically);
        },
        groupsGroupedById() {
            const map = new Map();
            this.groupsFilter.forEach((item) => {
                const collection = map.get(item.meta.navigation.type.groupId);
                if (!collection) {
                    map.set(item.meta.navigation.type.groupId, [item]);
                } else {
                    collection.push(item);
                }
            });
            return map;
        },
        singleGroupGroupedById() {
            const map = new Map();
            this.singleGroupFilter.forEach((item) => {
                const collection = map.get(item.meta.navigation.type.groupId);
                if (!collection) {
                    map.set(item.meta.navigation.type.groupId, [item]);
                } else {
                    collection.push(item);
                }
            });
            return map;
        },
        drawerState: {
            get() {
                return this.getDrawerState;
            },
            set(value) {
                this.OPEN_DRAWER(value);
            }
        },
        isDark: {
            get() {
                return this.$vuetify.theme.dark;
            },
            set(value) {
                this.changeMode(value);
            }
        }
    },
    methods: {
        ...mapActions('NavigationOperations', ['GET_FAVOURITE_MAP']),
        ...mapActions({
            checkPermission: (action, data) => {
                return action('AdminPermission/CHECK_PERMISSIONS', data);
            }
        }),
        ...mapActions('General', ['LOGOUT', 'CLEAR_USER_DATA']),
        ...mapActions('SystemSettings', ['GET_GENERIC_CUSTOMER_SETTINGS']),
        sortAlphabetically(itemA, itemB) {
            return this.$t(itemA.meta.navigation.name).localeCompare(this.$t(itemB.meta.navigation.name), undefined, {
                numeric: true,
                sensitivity: 'base'
            });
        },
        hasRole(item) {
            if (!item.meta.requiresRole) return true;
            return item.meta.roleSet.some((role) => role === this.clientRole);
        },
        hasPermission(item) {
            if (!item.meta.requiresPermission) return { data: item, hasPermission: true };
            if (this.clientRole === 'SUPER_ADMIN') return { data: item, hasPermission: true };
            var permissions = {
                userId: this.client,
                permissions: item.meta.permissionSet
            };
            return this.checkPermission(permissions)
                .then((permission) => ({
                    data: item,
                    hasPermission: permission
                }))
                .catch(() => ({ data: item, hasPermission: false }));
        },
        asyncFilter() {
            const filter = this.flattendRouter
                .filter((item) => this.hasOwn(item, 'meta') && this.hasOwn(item.meta, 'navigation') && this.hasRole(item))
                .filter((route) => (this.getHiddenMap[route.meta.navigation.navigationKey] ? !this.getHiddenMap[route.meta.navigation.navigationKey] : true))
                .map((item) => this.hasPermission(item));
            Promise.all(filter).then((results) => (this.navigationFilter = results.filter((item) => item.hasPermission).map((item) => item.data)));
        },
        flattenRouter(array) {
            return array
                ? array.map(({ component, name, ...item }) => item).reduce((result, item) => [...result, item, ...this.flattenRouter(item.children)], [])
                : [];
        },
        openWebconference() {
            window.open(`https://bbb.wiselab.io/b/u/token/Bearer ${this.accesstoken}`);
        },
        signOut() {
            this.CLEAR_USER_DATA();
            this.LOGOUT();
            this.$vuetify.theme.dark = false;
        },
        changeMode(value) {
            this.$vuetify.theme.dark = value;
            if (value) {
                Storage.setData('isDarkMode', this.isDark);
            } else {
                Storage.removeData('isDarkMode');
            }
            this.GET_GENERIC_CUSTOMER_SETTINGS();
        }
    },
    watch: {
        getFavouriteMap() {
            this.asyncFilter();
        },
        getLoggedInAdmin(value) {
            if (Object.keys(value).length > 0) {
                this.asyncFilter();
                this.GET_FAVOURITE_MAP();
                this.drawerState = true;
                const isDarkMode = JSON.parse(Storage.getData('isDarkMode'));
                if (isDarkMode) this.$vuetify.theme.dark = isDarkMode;
            }
        },
        $route(to) {
            this.disableResizeWatcher = this.disableResizeWatcherIncludeList.includes(to.name);
            if (this.disableResizeWatcher) {
                this.drawerState = false;
            }
        }
    },
    mounted() {
        if (Object.keys(this.getLoggedInAdmin).length > 0) {
            this.asyncFilter();
            this.GET_FAVOURITE_MAP();
            this.drawerState = true;
            const isDarkMode = JSON.parse(Storage.getData('isDarkMode'));
            if (isDarkMode) this.$vuetify.theme.dark = isDarkMode;
        }
    }
};
</script>

<style lang="scss" scoped>
.mode-icon {
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    &.light {
        background: #084f95;
    }
    &.dark {
        background: #012e5b;
    }
}
</style>
