import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters('CatalogMaterialOperations', ['getMaterials', 'getLinks', 'getMedia'])
    },
    methods: {
        ...mapActions('CatalogMaterialOperations', [
            'GET_MATERIALS',
            'ADD_MATERIAL',
            'UPDATE_MATERIAL',
            'DELETE_MATERIAL',
            'DOWNLOAD_MATERIAL',
            'DOWNLOAD_ALL_MATERIALS',
            'DOWNLOAD_SELECTED_MATERIALS'
        ]),
        isFileOrLink(item) {
            if (item.media && item.media.hasOwnProperty('fileURL') && !item.link) {
                return 'FILE';
            }
            if (item.srmedia && item.media.hasOwnProperty('fileURL') && !item.link) {
                return 'FILE';
            }
            if (item.hasOwnProperty('link')) {
                return 'LINK';
            }
            // if ((item.hasOwnProperty('media') && Object.keys(item.media).length > 0 && item.media.fileURL !== '') || !item.link) {
            //     return 'FILE';
            // }
            // if ((item.hasOwnProperty('srmedia') && Object.keys(item.srmedia).length > 0 && item.srmedia.fileURL !== '') || !item.link) {
            //     return 'FILE';
            // }
            // if (item.hasOwnProperty('link') || (!item.media && !item.srMedia)) {
            //     return 'LINK';
            // }
        }
    }
};
