<template>
    <v-card-text>
        <v-text-field
            autocomplete="new-password"
            v-model="computedName"
            :label="$t('Nachricht')"
            :hint="$t('Diese Bezeichnung ist für die Identifikation der Nachricht innerhalb des Nachrichtentools')"
            persistent-hint
            class="mb-3"
        />

        <v-tabs v-model="selectedMainTab" mandatory slider-color="accent">
            <v-tab key="News">{{ $t('Nachricht') }}</v-tab>
            <v-tab key="Options">{{ $t('Einstellungen') }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="selectedMainTab" touchless>
            <v-tab-item key="News">
                <v-card>
                    <v-card-text>
                        <v-text-field
                            color="accent"
                            autocomplete="new-password"
                            v-model="computedTitle"
                            :label="$t('Titel')"
                            :hint="$t('Die Überschrift der Nachricht')"
                            persistent-hint
                            class="mb-3"
                        />
                        <Editor v-model="computedContent" />
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item key="Options">
                <v-card>
                    <v-card-text>
                        <v-switch
                            color="accent"
                            v-model="computedPublished"
                            :label="$t('Publizieren')"
                            :hint="
                                $t('Wenn eine Nachricht publiziert ist, wird es auf der Hauptseite sichtbar und der Zeitpunkt der Publikation wird vermerkt')
                            "
                            persistent-hint
                            class="mb-3"
                        />
                        <v-switch
                            color="accent"
                            v-model="computedEnablePublishFrom"
                            :label="$t('Publikationszeitpunkt angeben')"
                            :hint="$t('Sie können einen Datum angeben zu der die Nachricht publiziert wird')"
                            :disabled="!computedPublished"
                            persistent-hint
                            class="mb-3"
                        />
                        <DatePicker
                            v-if="enablePublishFrom"
                            :min="computeMinimumDate"
                            :startDateText="computedPublishFrom"
                            @pickerStartDateEmit="handlePublishFrom"
                            type="Day"
                            label1="Publikationsdatum"
                        />
                        <v-select
                            color="accent"
                            v-model="computedVisibility"
                            :label="$t('Sichtbarkeit')"
                            :items="getVisibilityOptions"
                            :hint="$t('Hier können Sie einstellen, welche Rollen, Gruppen und/oder User diese Nachricht sehen können')"
                            persistent-hint
                            class="mb-3"
                            prepend-icon="mdi-eye"
                        >
                            <template v-slot:item="{ item }">
                                {{ $t(item) }}
                            </template>
                            <template v-slot:selection="{ item }">
                                {{ $t(item) }}
                            </template>
                        </v-select>

                        <v-expand-transition>
                            <v-tabs v-model="selectedOptionTab" mandatory grow slider-color="accent" v-show="isCustomVisibility">
                                <v-tab key="AdminGroups">
                                    <span>{{ $t('Admingruppen') }}</span>
                                    <v-btn @click.stop="() => (showAdminGroupSearch = !showAdminGroupSearch)" icon>
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                </v-tab>
                                <v-tab key="UserGroups">
                                    <span>{{ $t('Benutzergruppen') }}</span>
                                    <v-btn @click.stop="() => (showUserGroupSearch = !showUserGroupSearch)" icon>
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                </v-tab>
                                <v-tab key="Users">
                                    <span>{{ $t('Nutzer') }}</span>
                                </v-tab>
                            </v-tabs>
                        </v-expand-transition>

                        <v-expand-transition>
                            <v-tabs-items v-model="selectedOptionTab" touchless v-show="isCustomVisibility">
                                <v-tab-item key="AdminGroups">
                                    <v-card text>
                                        <v-card-text>
                                            <PaginatedList
                                                :showAppend="false"
                                                :showOptions="showAdminGroupSearch"
                                                title="Admingruppen"
                                                :items="getAdminGroups"
                                                :sortArray="adminGroupSort"
                                            >
                                                <template v-slot:action="item">
                                                    <v-checkbox
                                                        color="primary"
                                                        @change="() => (computedAdminGroups = item.action)"
                                                        :input-value="selectedAdminGroups"
                                                        :value-comparator.sync="compare"
                                                        :value="item.action"
                                                        :key="`${item.action.id}-${Math.floor(Math.random() * 256)}`"
                                                    />
                                                </template>
                                            </PaginatedList>
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>
                                <v-tab-item key="UserGroups">
                                    <v-card text>
                                        <v-card-text>
                                            <PaginatedList
                                                :showAppend="false"
                                                :showOptions="showUserGroupSearch"
                                                title="Nutzergruppen"
                                                :items="getUserGroups"
                                                :sortArray="userGroupSort"
                                            >
                                                <template v-slot:action="item">
                                                    <v-checkbox
                                                        color="primary"
                                                        @change="() => (computedUserGroups = item.action)"
                                                        :input-value="selectedUserGroups"
                                                        :value-comparator.sync="compare"
                                                        :value="item.action"
                                                        :key="`${item.action.id}-${Math.floor(Math.random() * 256)}`"
                                                    />
                                                </template>
                                            </PaginatedList>
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>
                                <v-tab-item key="Users">
                                    <v-card text>
                                        <UserList v-model="computedUsers" :showSelf="false" />
                                    </v-card>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-expand-transition>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </v-card-text>
</template>

<script>
import moment from 'moment';

import NewsMixin from './Mixins/NewsMixin';
import AdminGroupMixin from '../Groups/Mixins/AdminGroupMixins';
import UserGroupMixin from '../Groups/Mixins/UserGroupMixins';

import GroupSort from '../Groups/Sort/GroupSort';

import Editor from '../Generic/TextEditor';
import PaginatedList from '../Generic/List/AdvancedList';
import UserList from '../Generic/Tree/UserGroupTreeSelector';
import DatePicker from '../Generic/PickerDate/PickerDate';

export default {
    name: 'add-update-news',
    components: { Editor, PaginatedList, UserList, DatePicker },
    mixins: [NewsMixin, UserGroupMixin, AdminGroupMixin],
    data: () => ({
        selectedMainTab: 0,
        selectedOptionTab: 0,
        showAdminGroupSearch: false,
        showUserGroupSearch: false,
        adminGroupSort: GroupSort,
        userGroupSort: GroupSort,
        enablePublishFrom: false,
        id: '',
        name: '',
        title: '',
        content: '',
        isPublished: false,
        publishFrom: moment().format('YYYY-MM-DD'),
        selectedVisibility: '',
        selectedAdminGroups: [],
        selectedUserGroups: [],
        selectedUsers: []
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        computedName: {
            get() {
                return this.name;
            },
            set(value) {
                this.name = value;
                this.serialize();
            }
        },
        computedTitle: {
            get() {
                return this.title;
            },
            set(value) {
                this.title = value;
                this.serialize();
            }
        },
        computedContent: {
            get() {
                return this.content;
            },
            set(value) {
                this.content = value;
                this.serialize();
            }
        },
        computedPublished: {
            get() {
                return this.isPublished;
            },
            set(value) {
                if (!value) {
                    this.enablePublishFrom = false;
                    this.publishFrom = this.defaultPublishFromDate();
                }
                this.isPublished = value;
                this.serialize();
            }
        },
        computedEnablePublishFrom: {
            get() {
                return this.enablePublishFrom;
            },
            set(value) {
                if (value) {
                    this.publishFrom = this.defaultPublishFromDate();
                } else {
                    this.publishFrom = null;
                }
                this.enablePublishFrom = value;
                this.serialize();
            }
        },
        computedPublishFrom: {
            get() {
                return this.publishFrom;
            },
            set(val) {
                this.publishFrom = val;
                this.serialize();
            }
        },
        computedVisibility: {
            get() {
                return this.selectedVisibility;
            },
            set(value) {
                this.selectedVisibility = value;
                this.serialize();
            }
        },
        computedAdminGroups: {
            get() {
                return this.selectedAdminGroups;
            },
            set(value) {
                this.handleAdminGroups(value);
                this.serialize();
            }
        },
        computedUserGroups: {
            get() {
                return this.selectedUserGroups;
            },
            set(value) {
                this.handleUserGroups(value);
                this.serialize();
            }
        },
        computedUsers: {
            get() {
                return this.selectedUsers;
            },
            set(value) {
                this.selectedUsers = value;
                this.serialize();
            }
        },
        isCustomVisibility() {
            return this.selectedVisibility === 'CUSTOM';
        },
        computeMinimumDate() {
            return this.defaultPublishFromDate();
        }
    },
    methods: {
        serialize() {
            if (!this.isCustomVisibility) {
                this.selectedAdminGroups = [];
                this.selectedUserGroups = [];
                this.selectedUsers = [];
            }
            let serial = {
                id: this.id,
                name: this.name,
                title: this.title,
                content: this.content,
                isPublished: this.isPublished,
                publishFrom: this.publishFrom,
                visibility: this.selectedVisibility,
                options: {
                    adminGroups: this.selectedAdminGroups.map((group) => ({
                        id: group.id
                    })),
                    userGroups: this.selectedUserGroups.map((group) => ({
                        id: group.id
                    })),
                    users: this.selectedUsers.map((user) => ({ id: user.id }))
                }
            };
            if (this.id === '') {
                delete serial.id;
            }
            if (this.publishFrom === null) {
                delete serial.publishFrom;
            }
            this.$emit('input', serial);
        },
        deserialize() {
            if (this.isNewsObject(this.value)) {
                this.id = this.value.id;
                this.name = this.value.name;
                this.title = this.value.title;
                this.content = this.value.content;
                this.isPublished = this.value.isPublished;
                this.selectedVisibility = this.value.visibility;
                this.selectedAdminGroups = this.value.options.adminGroups;
                this.selectedUserGroups = this.value.options.userGroups;
                this.selectedUsers = this.value.options.users;
                if (this.value.publishFrom !== null && this.value.publishFrom !== '') {
                    this.publishFrom = moment(this.value.publishFrom).format('YYYY-MM-DD');
                    this.enablePublishFrom = true;
                }
            }
        },
        compare(a, b) {
            if (a === undefined || b === undefined || a === null || b === null) {
                return false;
            }
            return a.id === b.id;
        },
        isInAdminGroup(group) {
            return this.selectedAdminGroups.map((adminGroup) => adminGroup.id).includes(group.id);
        },
        isInUserGroup(group) {
            return this.selectedUserGroups.map((userGroup) => userGroup.id).includes(group.id);
        },
        handleAdminGroups(obj) {
            if (obj !== null && obj !== undefined) {
                if (this.isInAdminGroup(obj)) {
                    this.selectedAdminGroups = this.selectedAdminGroups.filter((group) => group.id !== obj.id);
                } else {
                    this.selectedAdminGroups.push(obj);
                }
            }
        },
        handleUserGroups(obj) {
            if (obj !== null && obj !== undefined) {
                if (this.isInUserGroup(obj)) {
                    this.selectedUserGroups = this.selectedUserGroups.filter((group) => group.id !== obj.id);
                } else {
                    this.selectedUserGroups.push(obj);
                }
            }
        },
        handlePublishFrom(value) {
            this.computedPublishFrom = value;
        },
        defaultPublishFromDate() {
            return moment().add(1, 'days').format('YYYY-MM-DD');
        }
    },
    mounted() {
        this.GET_ADMIN_GROUP_ROOT();
        this.GET_USER_GROUP_ROOT();
        this.VISIBILITY_OPTIONS()
            .then(() => (this.selectedVisibility = this.getVisibilityOptions[0]))
            .then(() => this.deserialize());
    }
};
</script>
