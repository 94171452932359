<template>
    <v-select
        v-model="computedSelect"
        :items="getCourseTopics"
        :label="$t('Themen')"
        :no-data-text="$t('Keine Themen verfügbar')"
        :hint="computedHint"
        persistent-hint
        return-object
        item-text="name"
    />
</template>

<script>
import CourseMixin from '../../Mixins/CourseMixin';

export default {
    name: 'topic-selector',
    mixins: [CourseMixin],
    watch: {
        getCourseTopics(value) {
            if (value.length === 0) {
                this.computedSelect = {};
                this.SET_SELECTED_TOPIC({});
            }
        },
        getSelectedAdminCourse(value) {
            if (Object.keys(value).length !== 0) {
                this.GET_COURSE_TOPICS(value).then(() => () => {
                    if (this.getCourseTopics.length > 0) {
                        this.computedSelect = this.getCourseTopics[0];
                    } else {
                        this.computedSelect = {};
                    }
                });
            } else {
                this.SET_TOPICS([]);
            }
        }
    },
    computed: {
        computedHint() {
            if (Object.keys(this.computedSelect).length > 0 && this.computedSelect.hasOwnProperty('score') && this.computedSelect.hasOwnProperty('minScore')) {
                return `min: ${this.computedSelect.minScore}, max: ${this.computedSelect.score}`;
            }
            return '';
        },
        computedSelect: {
            get() {
                return this.getSelectedTopic;
            },
            set(value) {
                this.SET_SELECTED_TOPIC(value);
            }
        }
    },
    mounted() {
        if (this.getCourseTopics.length === 0) {
            this.GET_COURSE_TOPICS(this.getSelectedAdminCourse).then(() => () => {
                if (this.getCourseTopics.length > 0) {
                    this.computedSelect = this.getCourseTopics[0];
                } else {
                    this.computedSelect = {};
                }
            });
        } else {
            this.computedSelect = this.getCourseTopics[0];
        }
    }
};
</script>
