<template>
    <DeleteConfirm
        :selectedItem="getSelectedForum"
        :toolbarTitle="computedTitle"
        :deleteMessage="computedDeleteMessage"
        :checkboxDeleteMessage="$t('Diese Aktion kann nicht rückgängig gemacht werden.')"
        @deleteItem="deleteForum"
        @closeEmit="close"
    />
</template>
<script>
import GroupForumMixin from './Mixins/GroupForumMixin';
import DeleteConfirm from '../Generic/DeleteConfirm';

export default {
    name: 'delete-forum',
    mixins: [GroupForumMixin],
    components: {
        DeleteConfirm
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: ''
        }
    },
    computed: {
        computedTitle() {
            if (this.type === 'THREAD') {
                return this.$t('Themen Eintrag löschen');
            } else {
                return this.$t('Forum Eintrag löschen');
            }
        },
        computedDeleteMessage() {
            if (this.type === 'THREAD') {
                return this.$t('Sind Sie sicher, dass Sie den folgenden Themen Eintrag löschen wollen?');
            } else {
                return this.$t('Sind Sie sicher, dass Sie den folgenden Forum Eintrag löschen wollen?');
            }
        }
    },
    methods: {
        close() {
            this.$emit('input', false);
        },
        deleteForum() {
            if (this.type === 'THREAD') {
                this.DELETE_THREAD({
                    group: this.getSelectedGroup,
                    forum: this.getSelectedForum,
                    thread: this.getSelectedThread
                }).then(() => this.close());
            } else {
                this.DELETE_FORUM({
                    group: { ...this.getSelectedGroup },
                    forum: { ...this.getSelectedForum }
                }).then(() => this.close());
            }
        }
    }
};
</script>
