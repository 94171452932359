<template>
    <v-card>
        <!-- <v-toolbar dark flat dense color="secondary">
            <v-toolbar-title>{{ $t('Abgabe') }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar> -->
        <v-tabs v-model="tabs" show-arrows mandatory slider-color="accent">
            <v-tab key="ACTIVITY">{{ $t('Aktivität') }}</v-tab>
            <v-tab key="OVERVIEW" :disabled="!hasMaterial">{{ $t('Material') }}</v-tab>
            <v-tab key="CONTENTS" :disabled="!hasContents">{{ $t('Contents') }}</v-tab>
        </v-tabs>
        <v-card-text>
            <v-tabs-items v-model="tabs" touchless>
                <v-tab-item key="ACTIVITY">
                    <v-container>
                        <v-row>
                            <v-col :cols="12" v-if="!!activity.description">
                                <v-card>
                                    <v-card-text>
                                        <v-row>
                                            <v-col :cols="12">
                                                <span class="text-h6">{{ $t('Aufgabe') }}</span>
                                            </v-col>
                                            <v-col :cols="12">
                                                <span class="text-caption">{{ activity.description }}</span>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col :cols="12">
                                <component
                                    v-if="value"
                                    :is="componentSwitch"
                                    v-model="activityResponse"
                                    :activity="activity"
                                    :answerText="answerText"
                                    :answerFile="answerFile"
                                    :settings="activity.activitySpecification"
                                    @validate="(value) => (validateActivity = value)"
                                    @answerFile="handleAnswerFile"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="OVERVIEW">
                    <div v-html="activity.material" class="ck ck-content"></div>
                    <div style="clear: both"></div>
                    <MaterialDownloader :items="getMaterial(activity)" />
                </v-tab-item>
                <v-tab-item key="CONTENT">
                    <v-card-text class="scroll" style="max-height: 20vh" @click.stop>
                        <KnowledgeContent :contents="activity.knowledgeContents" :selectable="false" v-model="computedSelectedKnowledgeContent" />
                    </v-card-text>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
        <v-card-actions v-if="!answerException">
            <v-btn block color="success" :disabled="!isActivityValid" @click="save">{{ $t('Abschicken') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import UploadAnswerType from './AnswerTypes/UploadAnswerType';
import CheckAnswerType from './AnswerTypes/CheckAnswerType';
import TextAnswerType from './AnswerTypes/TextAnswerType';
import InformAnswerType from './AnswerTypes/InformAnswerType';
import QuizAnswerType from './AnswerTypes/QuizAnswerType';
import CourseContent from '../CourseManager/CourseContent';
import KnowledgeContent from '../../KnowledgeContent/KnowledgeContentContent';
import MaterialDownloader from '../CourseManager/MaterialDownloader';

import CourseMixin from '../CourseManager/Mixins/CourseMixin';

export default {
    name: 'my-answer',
    mixins: [CourseMixin],
    components: {
        UploadAnswerType,
        CheckAnswerType,
        TextAnswerType,
        InformAnswerType,
        QuizAnswerType,
        CourseContent,
        KnowledgeContent,
        MaterialDownloader
    },
    data: () => ({
        tabs: 0,
        activityResponse: {},
        validateActivity: false,
        answerText: '',
        answerFile: {},
        Activitytype: ''
    }),
    props: {
        value: {
            type: Boolean,
            default: false
        },
        activity: {
            type: Object,
            default: () => ({})
        },
        selectedKnowledgeContent: {
            type: Array,
            default: () => []
        },
        hasMaterial: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        answerException() {
            return this.activity.activityType === 'QUIZ';
        },
        computedSelectedKnowledgeContent: {
            get() {
                return this.selectedKnowledgeContent;
            },
            set(value) {
                this.$emit('selectedKnowledgeContent', value);
            }
        },
        hasContents() {
            if (this.activity.hasOwnProperty('knowledgeContents')) {
                return this.activity.knowledgeContents.length > 0;
            }
            return false;
        },
        isActivityValid() {
            return Object.keys(this.activityResponse).length > 0 && this.validateActivity;
        },
        componentSwitch() {
            switch (this.activity.activityType) {
                case 'UPLOAD':
                    return UploadAnswerType;
                case 'SELF_CHECK':
                    return CheckAnswerType;
                case 'TEXT_FIELD':
                    return TextAnswerType;
                case 'INFORM':
                    return InformAnswerType;
                case 'QUIZ':
                    return QuizAnswerType;
                default:
                    return '';
            }
        }
    },
    methods: {
        close() {
            this.$emit('input', false);
            this.answerText = '';
            this.answerFile = {};
            this.Activitytype = '';
        },
        handleAnswerFile(value) {
            this.answerFile = value;
        },
        signalEvaluation(response) {
            if (this.getSelectedAttendeeCourse.useEvaluation) {
                this.$emit('evaluation', response.activity);
            }
            this.close();
        },
        save() {
            switch (this.activity.activityType) {
                case 'UPLOAD':
                    return this.SUBMIT_FILE_ACTIVITY({
                        activity: this.activity,
                        file: this.activityResponse.item(0)
                    })
                        .then(this.signalEvaluation)
                        .catch(() => this.close());
                case 'TEXT_FIELD':
                    return this.SUBMIT_TEXTFIELD_ACTIVITY({
                        activity: this.activity,
                        text: this.activityResponse
                    })
                        .then(this.signalEvaluation)
                        .catch(() => this.close());
                case 'SELF_CHECK':
                    return this.SUBMIT_CHECK_ACTIVITY({
                        activity: this.activity,
                        check: this.activityResponse
                    })
                        .then(this.signalEvaluation)
                        .catch(() => this.close());
                case 'INFORM':
                    return this.SUBMIT_INFORM_ACTIVITY({
                        activity: this.activity
                    })
                        .then(this.signalEvaluation)
                        .catch(() => this.close());
            }
        }
    },
    activated() {
        this.tabs = 0;
        this.activityResponse = {};
        this.validateActivity = false;
        this.answerText = '';
        this.answerFile = {};
        this.Activitytype = '';
        if (this.activity.activityType === 'TEXT_FIELD' && this.activity.answered === true) {
            this.GET_TEXTFIELD_ANSWER(this.activity).then((response) => (this.answerText = response.text));
        } else if (this.activity.activityType === 'UPLOAD' && this.activity.answered === true) {
            this.GET_FILE_ANSWER(this.activity).then((response) => (this.answerFile = response.mediaResponse));
        }
    }
};
</script>
