import axios from '../../plugins/Axios/axios';
import Vue from 'vue';

const state = {
    scormList: [],
    selectedScorm: {},
    view: {
        component: '',
        type: '',
        data: {},
        tabs: 0
    },
    boughtScorms: [],
    assignedScorms: [],
    enrollableScorms: [],
    uploadedScorms: [],
    openRequests: [],
    buyRequests: []
};

// getters
const getters = {
    getScormList(state) {
        return state.scormList;
    },
    getSelectedScorm(state) {
        return state.selectedScorm;
    },
    getView(state) {
        return state.view;
    },
    getBoughtScorms(state) {
        return state.boughtScorms;
    },
    getAssignedUserScorms(state) {
        return (state.assignedScorms = state.assignedScorms.map((obj) => {
            obj.scormPackage.cmi = obj.cmi;
            return obj.scormPackage;
        }));
    },
    getEnrollableScorms(state) {
        return state.enrollableScorms;
    },
    getUploadedScorms(state) {
        return state.uploadedScorms;
    },
    getOpenRequests(state) {
        return state.openRequests;
    },
    getBuyRequests(state) {
        return state.buyRequests;
    }
};

// actions
const actions = {
    GET_SCORM_USER_CONFIG({ commit }, { id: scormId }) {
        return axios.get(`/users/scormpackages/${scormId}/activity`).then((response) => response.data.data);
    },
    BUY_SCORM({ dispatch }, { id: scormId, ...request }) {
        return axios
            .post(`/scormpackages/${scormId}/purchase`, request.request)
            .then((response) => response.data.data)
            .then((response) => {
                dispatch('GET_BOUGHT_SCORMS').then((response) => {
                    let item = response.filter((scorm) => {
                        return scorm.scormPackageId == state.selectedScorm.id;
                    })[0];
                    if (item) {
                        dispatch('SET_SELECTED_SCORM', item);
                    }
                });
                dispatch('GET_SCORM_LIST');
                return response;
            })
            .catch((err) => Promise.reject(err));
    },
    ENROLL_SCORM_TO_USERS({ dispatch }, body) {
        return axios
            .post(`/customer/scormpackages/${body.scormId}/users`, body.enrollData)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => Promise.reject(err));
    },
    GET_BOUGHT_SCORMS({ commit }) {
        return axios
            .get(`/customer/scormpackages`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateBoughtScorms', response);
                return response;
            });
    },
    GET_ASSIGNED_USER_SCORMS({ commit }) {
        return axios
            .get('/users/scormpackages')
            .then((resp) => resp.data.data)
            .then((resp) => {
                commit('updateAssignedScorms', resp);
                return resp;
            });
    },
    GET_SCORM_REPORT_USER_ACTIVITIES({ commit }, scormId) {
        return axios
            .get(`/customer/scormpackages/${scormId}/useractivities`)
            .then((resp) => resp.data.data)
            .then((resp) => {
                return resp;
            });
    },
    GET_SCORM_LIST({ commit }) {
        return axios
            .get('/scormpackages')
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateScormList', response);
                return response;
            });
    },
    GET_MEDIA_BY_ID({ commit }, mediaId) {
        return axios.get('/scormpackages/' + mediaId + '/courseimage', { responseType: 'blob' }).then((response) => {
            if (response == '') {
                return '';
            }
            return response.data;
        });
    },
    GET_VIDEO_BY_ID({ commit }, mediaId) {
        return axios.get(mediaId + '/coursevideo', { responseType: 'blob' }).then((response) => {
            if (response == '') {
                return '';
            }
            return response.data;
        });
    },
    GET_SINGLE_SCORM({ commit }, { id: scormId }) {
        return axios
            .get(`/scormpackages/${scormId}`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateSelectedScrom', response);
                return response;
            });
    },
    VIEW_SCORM_FILE({ commit }, id) {
        return axios
            .get(`/scormpackages/${id}/package`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateSelectedScorm', response);
                return response;
            });
    },
    SET_VIEW({ commit }, view) {
        commit('updateView', view);
    },
    SET_SELECTED_SCORM({ commit }, data) {
        const hasData = Object.values(data).length;
        if (hasData && data.scormPackageId) {
            data.purchasedId = data.id;
        }
        if (hasData && !data.scormPackageId) {
            data.scormPackageId = data.id;
        }
        commit('updateSelectedScrom', data);
    },
    GET_SCORM_CATEGORIES({ commit }, type) {
        return axios
            .get(`/scormpackages/categories?type=${type}`)
            .then((resp) => resp.data.data)
            .then((response) => {
                return response;
            });
    },
    GET_SPECIFIC_SCORM_DETAILS({ commit }, scormId) {
        return axios
            .get(`/customer/scormpackages/${scormId}`)
            .then((resp) => resp.data.data)
            .then((response) => {
                return response;
            });
    },
    GET_ENROLLABLE_SCORMS({ commit }) {
        return axios
            .get(`/scormpackages/enroll`)
            .then((resp) => resp.data.data)
            .then((response) => {
                commit('updateEnrollableScorms', response);
                return response;
            });
    },
    SELF_ENROLL({ commit, dispatch }, scormId) {
        return axios.get(`/customer/scormpackages/${scormId}/enroll`).then(() => {
            dispatch('GET_ENROLLABLE_SCORMS');
            dispatch('GET_ASSIGNED_USER_SCORMS');
        });
    },
    GET_UPLOADED_SCORMS({ commit }) {
        return axios
            .get(`/customer/custom/scormpackages`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateUploadedScorms', response);
                return response;
            });
    },
    GET_OPEN_REQUESTS({ commit }) {
        return axios
            .get(`/scormpackages/openrequests`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateOpenRequests', response);
                return response;
            });
    },
    CHANGE_STATE({ dispatch }, { id, accepted }) {
        return axios.patch(`/scormpackages/enroll/${id}?approved=${accepted}`).then(() => {
            dispatch('GET_OPEN_REQUESTS');
        });
    },
    CREATE_BUY_REQUEST({ dispatch }, { scormId }) {
        return axios.get(`/scormpackages/${scormId}/buyRequest`).then(() => {
            dispatch(`GET_SCORM_LIST`);
        });
    },
    GET_OPEN_BUY_REQUESTS({ commit }) {
        return axios
            .get(`/scormpackages/openbuyrequests`)
            .then((response) => response.data.data)
            .then((response) => commit('updateBuyRequests', response));
    },
    DECLINE_BUY_REQUESTS({ dispatch }, { id }) {
        return axios.patch(`/scormpackages/openbuyrequests/${id}`).then(() => dispatch('GET_OPEN_BUY_REQUESTS'));
    },
    UPLOAD_SCORM(
        { dispatch },
        {
            name = '',
            video = {},
            targetgroup = '',
            tags = '',
            competencies = '',
            description = '',
            learninggoals = '',
            time = 0,
            germanSubCategory = '',
            germanCategory = '',
            language = '',
            file,
            picture = {},
            enrollmentType = ''
        }
    ) {
        let formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);

        if (video) formData.append('videoUrl', video);
        formData.append('tags', tags);
        formData.append('competencies', competencies);
        formData.append('targetgroup', targetgroup);
        formData.append('learninggoals', learninggoals);
        formData.append('time', time);
        formData.append('germanSubCategory', germanSubCategory);
        formData.append('germanCategory', germanCategory);
        formData.append('language', language);
        formData.append('file', file);
        formData.append('enrollmentType', enrollmentType);
        if (picture) formData.append('pictureUrl', picture);
        return axios
            .post('/customer/custom/scormpackages?', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })
            .then((resp) => resp.data.data)
            .then((resp) => {
                dispatch('GET_UPLOADED_SCORMS');
                dispatch('GET_BOUGHT_SCORMS');
                return resp;
            });
    }
};
// mutations
const mutations = {
    clear: (state) => {
        state.scormList = [];
        state.selectedScorm = {};
        state.view = {
            component: '',
            type: '',
            data: {}
        };
        state.boughtScorms = [];
        state.assignedScorms = [];
        state.enrollableScorms = [];
        state.uploadedScorms = [];
        state.openRequests = [];
        state.buyRequests = [];
    },
    updateView: (state, view) => {
        Vue.set(state, 'view', view);
    },
    updateSelectedScrom: (state, data) => {
        //Vue.set(state, 'selectedScorm', data)
        state.selectedScorm = data;
    },
    updateScormList: (state, data) => {
        state.scormList = data;
    },
    updateBoughtScorms: (state, data) => {
        state.boughtScorms = data;
    },
    updateAssignedScorms: (state, data) => {
        state.assignedScorms = data.userScormPackages;
    },
    updateEnrollableScorms: (state, data) => {
        state.enrollableScorms = data;
    },
    updateUploadedScorms: (state, data) => {
        state.uploadedScorms = data;
    },
    updateOpenRequests: (state, data) => {
        state.openRequests = data;
    },
    updateBuyRequests: (state, data) => {
        state.buyRequests = data;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
