<template>
    <v-card @click.stop="handleSelect" :ripple="computedRipple" :class="computedCursor" :elevation="selectedElevation" height="100%">
        <v-container fluid fill-height pa-3>
            <!-- <v-layout :row="$vuetify.breakpoint.mdAndUp" :column="$vuetify.breakpoint.smAndDown" fill-height> -->
            <v-row :cols="12">
                <v-flex shrink class="hand" v-show="!!imageSource">
                    <v-layout column fill-height align-center justify-center>
                        <ImageLoader :height="imageHeight" :width="imageHeight" :src="imageSource" @click.native.stop="mediaEmit" :showDefaultImage="true" />
                    </v-layout>
                </v-flex>
                <v-flex shrink v-show="!!imageSource">
                    <v-divider :vertical="$vuetify.breakpoint.mdAndUp" class="mx-2" />
                </v-flex>
                <v-flex fill-height>
                    <v-layout align-center justify-center fill-height>
                        <span :class="selectedText">{{ optionText }}</span>
                    </v-layout>
                </v-flex>
            </v-row>
            <!-- </v-layout> -->
        </v-container>
    </v-card>
</template>
<script>
import ImageLoader from '../../Generic/ImageLoader';
import FileSVG from '../../../assets/Resources/SVG/file.svg';
import MusicSVG from '../../../assets/Resources/SVG/music.svg';
import MovieSVG from '../../../assets/Resources/SVG/movie.svg';

export default {
    name: 'option-node',
    components: { ImageLoader },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        selected: {
            type: [Object, Array, Boolean],
            default: () => []
        },
        comparatorKey: {
            type: String,
            default: 'id'
        },
        imageHeight: {
            type: Number,
            default: 80
        },
        disableInteraction: {
            type: Boolean,
            default: false
        },
        move: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        responsiveFont() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return { 'font-weight-light': true };
                case 'sm':
                    return { 'font-weight-light': true, 'body-2': true };
                case 'md':
                    return { 'font-weight-light': true, subheading: true };
                case 'lg':
                    return { 'font-weight-light': true, title: true };
                case 'xl':
                    return { 'font-weight-light': true, headline: true };
            }
            return '';
        },
        computedCursor() {
            return !this.disableInteraction
                ? this.move
                    ? { cross: true, accent: this.isSelected }
                    : { hand: true, accent: this.isSelected }
                : { accent: this.isSelected };
        },
        hasMedia() {
            return this.imageSource !== '';
        },
        isSelected() {
            if (Array.isArray(this.selected)) {
                return this.selected.map((answer) => answer[this.comparatorKey]).includes(this.value[this.comparatorKey]);
            }
            if (!this.selected.hasOwnProperty(this.comparatorKey)) {
                return false;
            }
            return !(this.selected instanceof Boolean) ? this.value[this.comparatorKey] === this.selected[this.comparatorKey] : this.selected;
        },
        optionText() {
            return this.value && this.value.text ? this.value.text : '';
        },
        imageSource() {
            if (this.fileType) {
                if (this.fileType === 'audio') {
                    return MusicSVG;
                }
                if (this.fileType === 'application') {
                    return FileSVG;
                }
                if (this.fileType === 'video') {
                    return MovieSVG;
                }
            }

            return this.value && this.value.fileURL ? this.value.fileURL : '';
        },
        computedRipple() {
            if (this.disableInteraction || this.move) {
                return false;
            }
            return { class: 'primary--text' };
        },
        selectedElevation() {
            return this.isSelected ? 6 : 1;
        },
        selectedText() {
            return this.isSelected ? { ...this.responsiveFont, 'white--text': true, 'ma-3': true } : { ...this.responsiveFont, 'ma-3': true };
        },
        fileType() {
            return this.value && this.value.fileType ? this.value.fileType.substring(0, this.value.fileType.indexOf('/')) : '';
        }
    },
    methods: {
        mediaEmit() {
            if (this.hasMedia) {
                const { fileName, fileType, fileURL, mediaId, size } = this.value;
                this.$emit('media', {
                    fileName,
                    fileType,
                    fileURL,
                    mediaId,
                    size
                });
            }
        },
        handleSelect() {
            if (!this.disableInteraction) {
                if (Array.isArray(this.selected)) {
                    if (this.isSelected) {
                        this.$emit(
                            'selected',
                            this.selected.filter((answer) => answer[this.comparatorKey] !== this.value[this.comparatorKey])
                        );
                    } else {
                        this.$emit('selected', [...this.selected, this.value]);
                    }
                } else {
                    if (this.selected.hasOwnProperty(this.comparatorKey)) {
                        if (this.selected[this.comparatorKey] === this.value[this.comparatorKey]) {
                            this.$emit('selected', {});
                        } else {
                            this.$emit('selected', this.value);
                        }
                    } else {
                        this.$emit('selected', this.value);
                    }
                }
            }
        }
    }
};
</script>

<style scoped>
.hand {
    cursor: pointer;
}
.cross {
    cursor: move;
}
</style>
