<template>
    <v-dialog v-model="computedDialog" scrollable persistent :width="$vuetify.breakpoint.thresholds.sm" :fullscreen="$vuetify.breakpoint.xs">
        <v-card>
            <v-toolbar dark flat color="secondary">
                <v-toolbar-title>{{ title }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-tabs mandatory slider-color="accent" show-arrows v-model="tabs">
                <v-tab key="SETTINGS">{{ $t('Einstellungen') }}</v-tab>
                <v-tab key="FAQ">{{ $t('FAQ') }}</v-tab>
            </v-tabs>
            <v-card-text class="ma-0 pa-0">
                <v-tabs-items v-model="tabs" touchless>
                    <v-tab-item key="SETTINGS">
                        <v-form v-model="valid" ref="form">
                            <v-container grid-list-md fluid>
                                <v-layout row wrap>
                                    <v-flex xs12>
                                        <v-text-field
                                            :label="$t('Frage')"
                                            v-model="faq.question"
                                            :hint="$t('Die Frage des FAQ')"
                                            persistent-hint
                                            :rules="[notEmpty]"
                                        />
                                    </v-flex>
                                    <v-flex xs12 sm12 md6 lg6 xl6 v-show="addUpdateRule">
                                        <FAQGroupSelector :id="faq.id" :type="type" />
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-form>
                    </v-tab-item>
                    <v-tab-item key="FAQ">
                        <v-container grid-list-md>
                            <v-layout row wrap>
                                <v-flex xs12>
                                    <div style="overflow-x: hidden">
                                        <TextEditor v-model="faq.answer" />
                                    </div>
                                </v-flex>
                                <v-flex xs12>
                                    <TagGenerator v-model="faq.tags" />
                                </v-flex>
                                <v-flex xs12>
                                    <!-- <StateSelector :value="{ stateName: state }" :permissions="{ faq: [] }" :onChange="changeFaqState" /> -->
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
            <v-card-actions>
                <v-btn block :color="submitColor" :disabled="!valid" @click="submit">{{ submitLabel }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import TextEditor from '../../Generic/TextEditor';
import TagGenerator from '../../Generic/TagGenerator';
import StateSelector from '../Helpers/NewStateSelector';
import FAQGroupSelector from '../Helpers/FAQGroupSelector';

import FAQMixin from '../Mixins/FAQMixins';
export default {
    name: 'add-update-faq',
    mixins: [FAQMixin],
    components: {
        TextEditor,
        TagGenerator,
        // StateSelector,
        FAQGroupSelector
    },
    data: () => ({
        tabs: 0,
        valid: false,
        faq: {
            id: '',
            question: '',
            answer: '',
            tags: '',
            faqGroupId: '',
            state: {
                commissionedTo: []
            }
        },
        state: 'PENDING',
        hasStateChange: false,
        selectedFaqGroupId: ''
    }),
    watch: {
        value(value) {
            if (value) {
                switch (this.type) {
                    case 'ADD':
                        this.reset();
                        return;
                    case 'UPDATE':
                        return Object.entries(this.getSelectedFaq).forEach(([key, value]) => {
                            if (this.faq.hasOwnProperty(key)) {
                                this.faq[key] = value;
                            }
                        });
                }
            }
        },
        type(value) {
            switch (value) {
                case 'ADD':
                    return this.reset();
                case 'UPDATE':
                    return Object.entries(this.getSelectedFaq).forEach(([key, value]) => {
                        if (this.faq.hasOwnProperty(key)) {
                            this.faq[key] = value;
                        }
                    });
            }
        }
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'ADD',
            validator: (v) => ['ADD', 'UPDATE'].includes(v)
        }
    },
    computed: {
        computedDialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        title() {
            switch (this.type) {
                case 'ADD':
                    return this.$t('FAQ Hinzufügen');
                case 'UPDATE':
                    return this.$t('FAQ Bearbeiten');
            }

            return this.$t('FAQ Hinzufügen');
        },
        submitLabel() {
            switch (this.type) {
                case 'ADD':
                    return this.$t('Hinzufügen');
                case 'UPDATE':
                    return this.$t('Bearbeiten');
            }
            return this.$t('Hinzufügen');
        },
        submitColor() {
            switch (this.type) {
                case 'ADD':
                    return 'success';
                case 'UPDATE':
                    return 'warning';
            }
            return 'success';
        },
        addUpdateRule() {
            return true;
        }
    },
    methods: {
        changeFaqState(state) {
            return this.CHANGE_FAQ_STATE({ id: this.getSelectedFaq.id, state: state });
        },
        handleState({ state }) {
            this.state = state;
        },
        notEmpty(value) {
            return (!!value && value.trim() !== '' && value.trim().length > 0) || this.$t('Das Feld darf nicht leer sein');
        },
        close() {
            this.computedDialog = false;
            this.SET_VIEW({ component: '', type: '', data: {} });
            this.reset();
        },
        reset() {
            this.faq = {
                id: '',
                question: '',
                answer: '',
                tags: '',
                faqGroupId: '',
                state: {
                    commissionedTo: []
                }
            };
            this.state = 'PENDING';
        },
        getFaqGroupId() {
            if (this.faq.faqGroupId === '') {
                return this.getSelectedFaqGroup;
            } else {
                return this.faq.faqGroupId;
            }
        },
        submit() {
            switch (this.type) {
                case 'ADD':
                    this.faq.faqGroupId = this.getSelectedFaqGroup.id;
                    return this.ADD_FAQ(this.faq)
                        .then((response) => {
                            if (this.hasStateChange) {
                                this.CHANGE_FAQ_STATE({ id: response.id, state: { state: this.state } });
                            }
                        })
                        .then(() => this.close());
                case 'UPDATE':
                    if (this.selectedFaqGroupId) {
                        this.faq.faqGroupId = this.selectedFaqGroupId;
                    }
                    return this.UPDATE_FAQ(this.faq)
                        .then((response) => {
                            this.selectedFAQ = response;
                            if (this.hasStateChange) {
                                this.CHANGE_FAQ_STATE({ id: this.faq.id, state: { state: this.state } });
                            }
                        })
                        .then(() => {
                            this.GET_FAQS();
                            this.GET_FAQ_LIST();
                            this.close();
                        });
            }
        }
    },
    mounted() {
        this.validateEditorState({
            stateChange: ['CHANGE_WIKI_STATE']
        }).then((response) => {
            this.hasStateChange = response.stateChange;
        });
    }
};
</script>
