import CertificateManager from '../../views/Certificates/CertificateManager';

import { superAdmin, admin } from '../Roles';

export default [
    {
        path: '/CertificateManager',
        name: 'certificateManager',
        component: CertificateManager,
        meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionSet: ['CERTIFICATE_MANAGEMENT_PERMISSION'],
            requiresRole: true,
            roleSet: [superAdmin, admin],
            navigation: {
                type: { name: 'SingleGroup', groupId: 'Lernmanagement' },
                priority: 0,
                icon: 'mdi-certificate',
                name: 'Zertifikatsmanager',
                navigationKey: 'CERTIFICATE_MANAGER'
            }
        }
    }
];
