<template>
    <v-card height="100%" flat>
        <v-container>
            <v-row>
                <v-col :cols="12">
                    <v-card>
                        <v-container>
                            <v-row>
                                <v-col :cols="12">
                                    <v-select
                                        color="accent"
                                        :label="$t('Sprache')"
                                        :items="getLanguages"
                                        :hint="`${$t(selectedLanguage.name)}, ${selectedLanguage.alpha2}`"
                                        persistent-hint
                                        v-model="selectedLanguage"
                                        @input="clearTemplate"
                                        item-text="name"
                                        return-object
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-select
                                        color="accent"
                                        :label="$t('Vorlage')"
                                        :items="computeTemplates"
                                        :no-data-text="$t('Keine Vorlagen verfügbar')"
                                        v-model="selectedTemplate"
                                        return-object
                                        item-value="templateName"
                                        :item-text="(x) => templateSelectionText(x)"
                                    />
                                </v-col>
                                <v-col cols="auto" align-self="center">
                                    <add-update-signature v-model="signatureDialog" />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>

                <v-col :cols="12">
                    <v-slide-y-transition>
                        <v-card height="100%" v-show="isTemplateSelected">
                            <v-container>
                                <v-row>
                                    <v-col :cols="12">
                                        <v-text-field
                                            color="accent"
                                            autocomplete="new-password"
                                            class="mt-3 mb-3"
                                            v-model="inputData.subject"
                                            :label="$t('Betreff')"
                                            :disabled="isDisabled"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col :cols="9">
                                        <div style="overflow-x: hidden">
                                            <Editor :value="inputData.content" @input="(data) => (inputData.content = data)" :disabled="isDisabled" />
                                        </div>
                                    </v-col>
                                    <v-col :cols="3">
                                        <v-container fluid>
                                            <v-row>
                                                <v-col :cols="12">
                                                    <v-list dense>
                                                        <!-- possible vars -->
                                                        <v-subheader inset>{{ $t('Verfügbare Variablen') }}</v-subheader>
                                                        <v-list-item v-for="(availableVar, index) in availableVars" :key="`availableVar-${index}`">
                                                            <v-list-item-icon>
                                                                <v-icon>mdi-label</v-icon>
                                                            </v-list-item-icon>
                                                            <v-list-item-title>{{ availableVar }}</v-list-item-title>
                                                        </v-list-item>
                                                        <!-- required vars -->
                                                        <v-subheader inset>{{ $t('Benötigte Variablen') }}</v-subheader>
                                                        <v-list-item v-for="(requiredVar, index) in requiredVars" :key="`requiredVar-${index}`">
                                                            <v-list-item-avatar>
                                                                <v-icon>{{ checkRequiredVars(requiredVar) ? 'mdi-check' : 'mdi-close' }}</v-icon>
                                                            </v-list-item-avatar>
                                                            <v-list-item-title>{{ requiredVar }}</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <v-btn color="secondary" :disabled="isDataComplete" @click="sendTestMail">
                                                        <span class="caption">{{ $t('Vorlage als Testmail senden') }}</span>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-card-actions>
                                <v-spacer />
                                <v-btn text :disabled="isDataResettable" @click="reset">{{ $t('Zurücksetzen') }}</v-btn>
                                <v-btn color="primary" :disabled="isDataComplete" @click="save">{{ $t('Speichern') }}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-slide-y-transition>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>
<script>
import Editor from '../../../Components/Generic/TextEditor';
import AddUpdateSignature from '../../../Components/SystemSettings/AddUpdateSignature.vue';
import SystemSettingsMixin from '../../../Components/SystemSettings/Mixins/SystemSettingsMixin';

export default {
    name: 'mail-templates',
    components: { Editor, AddUpdateSignature },
    mixins: [SystemSettingsMixin],
    data: () => ({
        selectedLanguage: '',
        selectedTemplate: '',
        inputData: {
            content: '',
            subject: ''
        },
        signatureDialog: false
    }),
    computed: {
        isTemplateSelected() {
            return Object.keys(this.selectedTemplate).length > 0;
        },
        isDisabled() {
            return !(this.selectedLanguage !== '' && this.selectedTemplate !== '');
        },
        computeTemplates() {
            if (this.selectedLanguage === '') return [];
            let templates = this.getTemplates;
            templates.sort((a, b) => {
                const tA = this.templateSelectionText(a);
                const tB = this.templateSelectionText(b);

                return tA.localeCompare(tB, {
                    numeric: true,
                    sensitivity: 'base'
                });
            });
            return templates;
        },
        availableVars() {
            if (this.selectedTemplate === '') return '';
            return this.selectedTemplate.availableFields;
        },
        requiredVars() {
            if (this.selectedTemplate === '') return '';
            return this.selectedTemplate.requiredFields;
        },
        saveData() {
            return {
                language: this.selectedLanguage.alpha2,
                mailTemplateType: this.selectedTemplate.templateName,
                message: this.inputData.content,
                subject: this.inputData.subject
            };
        },
        isDataComplete() {
            if (this.requiredVars !== '') {
                return !this.requiredVars.every((reqVar) => this.checkRequiredVars(reqVar));
            }
            return true;
        },
        isDataResettable() {
            return !(this.selectedLanguage !== '' && this.selectedTemplate !== '');
        }
    },
    methods: {
        sendTestMail() {
            let template = {
                ...this.selectedLanguage,
                ...this.selectedTemplate
            };
            const { alpha2: language, templateName: mailTemplateType, requiredFields, availableFields, customField } = template;
            const destructured = {
                language,
                mailTemplateType,
                message: this.inputData.content,
                subject: this.inputData.subject,
                requiredFields,
                availableFields,
                customField
            };
            this.SEND_TEST_MAIL(destructured);
        },
        templateSelectionText(val) {
            let normalize = val.templateName.replace(/_/gi, ' ').toLowerCase();
            return this.$t(normalize.charAt(0).toUpperCase() + normalize.slice(1));
        },
        checkRequiredVars(requiredVar) {
            let escape = requiredVar.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
            let regex = new RegExp(escape);
            return regex.test(this.inputData.content);
        },
        save() {
            this.SET_TEMPLATE(this.saveData);
        },
        reset() {
            this.DELETE_TEMPLATE({
                language: this.selectedLanguage.alpha2,
                template: this.selectedTemplate.templateName
            });
        },
        clearTemplate() {
            this.selectedTemplate = '';
        }
    },
    watch: {
        selectedLanguage(value) {
            this.GET_TEMPLATES(value.alpha2);
            this.SET_SELECTED_LANGUAGE(value);
            this.inputData.subject = '';
            this.inputData.content = '';
        },
        selectedTemplate(value) {
            this.inputData.subject = value.subject;
            this.inputData.content = value.message;
        }
    },
    mounted() {
        if (Object.keys(this.getLanguages).length === 0) {
            this.GET_LANGUAGES().then(() => {
                this.selectedLanguage = this.getDefaultLanguage;
            });
        } else {
            if (Object.keys(this.getSelectedLanguage).length !== 0) {
                this.selectedLanguage = this.getSelectedLanguage;
            } else {
                this.selectedLanguage = this.getDefaultLanguage;
            }
        }
    }
};
</script>
