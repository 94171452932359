<template>
    <v-card height="100%">
        <v-container grid-list-md fluid pa-3>
            <v-flex>
                <v-autocomplete
                    v-model="computedSelectedCustomer"
                    :search-input.sync="computedContentSearch"
                    :items="getCustomers"
                    return-object
                    item-value="id"
                    item-text="companyName"
                    :filter="filterContent"
                    :label="$t('Kunden Suchen')"
                    :hint="$t('Geben Sie Begriffe oder Schlagwörter ein, um nach relevanten Kunden zu suchen')"
                    persistent-hint
                    :no-data-text="$t('Kein Kunde')"
                    :no-results-text="$t('Kein Ergebnis')"
                    :loading="contentDebounce !== null"
                    :no-filter="contentDebounce !== null"
                    :disabled="getCustomers.length === 0"
                >
                    <template v-slot:selection="{ item, selected }">
                        <v-chip color="primary" dark :value="selected">
                            {{ item.companyName }}
                        </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                        <v-list-item-action>
                            <ImageLoader :src="item.logo" :width="30" :height="30" />
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.companyName }}
                            </v-list-item-title>
                            <!-- <v-list-item-subtitle v-if="item && item.superAdmin">
                                {{ item.superAdmin.email }}
                            </v-list-item-subtitle> -->
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
            </v-flex>
            <v-flex>
                <v-expansion-panels accordion>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            {{ $t('Filterkriterien') }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-container>
                                <v-row>
                                    <v-col :cols="10">
                                        <v-checkbox :disabled="!filter.active.active" :label="$t('Aktiv')" v-model="filter.active.value" />
                                    </v-col>
                                    <v-col :cols="2">
                                        <v-checkbox :label="$t('Filter anwenden')" v-model="filter.active.active" />
                                    </v-col>
                                </v-row>
                                <!-- <v-row>
                                    <v-col :cols="10">
                                        <v-checkbox :disabled="!filter.online.active" :label="$t('Online')" v-model="filter.online.value" />
                                    </v-col>
                                    <v-col :cols="2">
                                        <v-checkbox :label="$t('Filter anwenden')" v-model="filter.online.active" />
                                    </v-col>
                                </v-row> -->
                                <v-row>
                                    <v-col :cols="10">
                                        <v-range-slider
                                            :disabled="!filter.maxLicense.active"
                                            v-model="filter.maxLicense.range"
                                            :max="highestUserLicences"
                                            :min="0"
                                            hide-details
                                            class="align-center"
                                            :label="$t('Nutzerlizenzen')"
                                        >
                                            <template v-slot:prepend>
                                                <v-text-field
                                                    :value="filter.maxLicense.range[0]"
                                                    class="mt-0 pt-0"
                                                    hide-details
                                                    single-line
                                                    @change="$set(filter.maxLicense.range, 0, $event)"
                                                ></v-text-field>
                                            </template>
                                            <template v-slot:append>
                                                <v-text-field
                                                    :value="filter.maxLicense.range[1]"
                                                    class="mt-0 pt-0"
                                                    hide-details
                                                    single-line
                                                    @change="$set(filter.maxLicense.range, 1, $event)"
                                                ></v-text-field>
                                            </template>
                                        </v-range-slider>
                                    </v-col>
                                    <v-col :cols="2">
                                        <v-checkbox :label="$t('Filter anwenden')" v-model="filter.maxLicense.active" />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col :cols="10">
                                        <v-range-slider
                                            :disabled="!filter.maxCourses.active"
                                            v-model="filter.maxCourses.range"
                                            :max="highestCourseLicences"
                                            :min="0"
                                            hide-details
                                            class="align-center"
                                            :label="$t('Kurslizenzen')"
                                        >
                                            <template v-slot:prepend>
                                                <v-text-field
                                                    :value="filter.maxCourses.range[0]"
                                                    class="mt-0 pt-0"
                                                    hide-details
                                                    single-line
                                                    @change="$set(filter.maxCourses.range, 0, $event)"
                                                ></v-text-field>
                                            </template>
                                            <template v-slot:append>
                                                <v-text-field
                                                    :value="filter.maxCourses.range[1]"
                                                    class="mt-0 pt-0"
                                                    hide-details
                                                    single-line
                                                    @change="$set(filter.maxCourses.range, 1, $event)"
                                                ></v-text-field>
                                            </template>
                                        </v-range-slider>
                                    </v-col>
                                    <v-col :cols="2">
                                        <v-checkbox :label="$t('Filter anwenden')" v-model="filter.maxCourses.active" />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col :cols="10">
                                        <v-range-slider
                                            :disabled="!filter.maxFileStorage.active"
                                            v-model="filter.maxFileStorage.range"
                                            :max="highestFileStorage"
                                            :min="0"
                                            hide-details
                                            class="align-center"
                                            :label="$t('Speicherplatz')"
                                        >
                                            <template v-slot:prepend>
                                                <v-text-field
                                                    :value="filter.maxFileStorage.range[0]"
                                                    class="mt-0 pt-0"
                                                    hide-details
                                                    single-line
                                                    @change="$set(filter.maxFileStorage.range, 0, $event)"
                                                ></v-text-field>
                                            </template>
                                            <template v-slot:append>
                                                <v-text-field
                                                    :value="filter.maxFileStorage.range[1]"
                                                    class="mt-0 pt-0"
                                                    hide-details
                                                    single-line
                                                    @change="$set(filter.maxFileStorage.range, 1, $event)"
                                                ></v-text-field>
                                            </template>
                                        </v-range-slider>
                                    </v-col>
                                    <v-col :cols="2">
                                        <v-checkbox :label="$t('Filter anwenden')" v-model="filter.maxFileStorage.active" />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <!-- <v-expansion-panel>
                        <v-expansion-panel-header>
                            {{ $t('Sortierung') }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-btn @click="sort()"></v-btn>
                        </v-expansion-panel-content>
                    </v-expansion-panel> -->
                </v-expansion-panels>
            </v-flex>
        </v-container>
        <v-tabs v-model="tab" show-arrows>
            <v-tab key="WL2">{{ $t('Wiselab2 Kunden') }}</v-tab>
            <v-tab key="WWC">{{ $t('WWC Kunden') }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item key="WL2">
                <v-container fluid fill-height ma-0 pa-0>
                    <v-layout column>
                        <v-divider class="my-3" />
                        <v-row>
                            <v-flex xs4 class="customerManagerLimit">
                                <v-data-iterator
                                    :items="getFilteredList('WL2')"
                                    :no-data-text="$t('Keine Kunden erstellt')"
                                    :no-results-text="$t('Kein Ergebnis')"
                                    :items-per-page="20"
                                    :footer-props="{
                                        itemsPerPageText: $t(''),
                                        'items-per-page-options': [20, 30, 40, { text: $t('Alle'), value: -1 }]
                                    }"
                                    :hideDefaultFooter="getItems <= 5"
                                >
                                    <template v-slot:default="{ items }">
                                        <template v-for="item in items">
                                            <v-list-item :key="item.id" @click="selectCustomer(item)">
                                                <v-list-item-avatar>
                                                    <ImageLoader :src="item.logo" :width="30" :height="30" />
                                                </v-list-item-avatar>
                                                <v-list-item-content> {{ item.companyName }}</v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </template>
                                </v-data-iterator>
                            </v-flex>
                            <v-flex>
                                <v-divider vertical />
                            </v-flex>
                            <v-flex xs8>
                                <v-expand-transition mode="out-in">
                                    <keep-alive>
                                        <component :is="componentSwitch" :tabValue="'WL2'" type="ADD" role="ADMIN" />
                                    </keep-alive>
                                </v-expand-transition>
                            </v-flex>
                        </v-row>
                    </v-layout>
                </v-container>
            </v-tab-item>
            <v-tab-item key="WWC">
                <v-container fluid fill-height ma-0 pa-0>
                    <v-layout column>
                        <v-divider class="my-3" />
                        <v-row>
                            <v-flex xs4 class="customerManagerLimit">
                                <v-data-iterator
                                    :items="getFilteredList('WWC')"
                                    :no-data-text="$t('Keine Kunden erstellt')"
                                    :no-results-text="$t('Kein Ergebnis')"
                                    :items-per-page="20"
                                    :footer-props="{
                                        itemsPerPageText: $t(''),
                                        'items-per-page-options': [20, 30, 40, { text: $t('Alle'), value: -1 }]
                                    }"
                                    :hideDefaultFooter="getItems <= 5"
                                >
                                    <template v-slot:default="{ items }">
                                        <template v-for="item in items">
                                            <v-list-item :key="item.id" @click="selectCustomer(item)">
                                                <v-list-item-avatar>
                                                    <ImageLoader :src="item.logo" :width="30" :height="30" />
                                                </v-list-item-avatar>
                                                <v-list-item-content> {{ item.companyName }}</v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </template>
                                </v-data-iterator>
                            </v-flex>
                            <v-flex>
                                <v-divider vertical />
                            </v-flex>
                            <v-flex xs8>
                                <v-expand-transition mode="out-in">
                                    <keep-alive>
                                        <component :is="componentSwitch" :tabValue="'WWC'" type="ADD" role="ADMIN" />
                                    </keep-alive>
                                </v-expand-transition>
                            </v-flex>
                        </v-row>
                    </v-layout>
                </v-container>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>

<script>
import CustomerManagerMixin from './Mixins/CustomerManagerMixin';
import ImageLoader from '../Generic/ImageLoader';
import SelectedCustomerInfo from './Helpers/SelectedCustomerInfo';
import AddUpdateCustomer from './Helpers/AddUpdateCustomer';
import DeleteCustomerConfrim from './Helpers/DeleteCustomerConfrim';
import AddUpdateUser from './../Users/AddUpdateUser.vue';

export default {
    name: 'customer-manager',
    components: {
        ImageLoader,
        SelectedCustomerInfo,
        DeleteCustomerConfrim,
        AddUpdateUser
    },
    mixins: [CustomerManagerMixin],
    data: () => ({
        tab: 0,
        filter: {
            active: {
                active: false,
                value: false
            },
            maxLicense: {
                active: false,
                range: [0, 0]
            },
            maxCourses: {
                active: false,
                range: [0, 0]
            },
            maxFileStorage: {
                active: false,
                range: [0, 0]
            },
            online: {
                active: false,
                value: false
            }
        },
        contentSearch: '',
        contentConfirm: '',
        contentDebounce: null
    }),
    watch: {
        tab() {
            this.SET_SELECTED_CUSTOMER({});
            this.SET_SELECTED_ADMIN({});
            this.SET_VIEW({ component: '', type: '', data: {}, tab: '' });
        }
    },
    computed: {
        computedSelectedCustomer: {
            get() {
                return this.getSelectedCustomer;
            },
            set(value) {
                if (value.hasOwnProperty('id')) {
                    this.SET_SELECTED_CUSTOMER(this.GET_SINGLE_CUSTOMER(value.id));
                } else {
                    if (value) {
                        this.SET_SELECTED_CUSTOMER(value);
                    } else {
                        this.SET_SELECTED_CUSTOMER({});
                    }
                }
                this.SET_SELECTED_ADMIN({});
            }
        },
        computedContentSearch: {
            get() {
                return this.contentSearch;
            },
            set(value) {
                this.contentSearch = value;
                if (this.contentDebounce) {
                    clearTimeout(this.contentDebounce);
                    this.contentDebounce = null;
                }
                if (value !== '') {
                    this.contentDebounce = setTimeout(
                        function () {
                            this.data.contentConfirm = this.search;
                            this.data.contentDebounce = null;
                        }.bind({ data: this, search: value }),
                        1500
                    );
                } else {
                    clearTimeout(this.contentDebounce);
                    this.contentDebounce = null;
                    this.contentConfirm = '';
                }
            }
        },
        componentSwitch() {
            if (this.getView.component !== '') {
                switch (this.getView.component) {
                    case 'ADD_UPDATE_SUPER_ADMIN':
                        return SelectedCustomerInfo;
                    case 'ADD_UPDATE_CUSTOMER':
                        return AddUpdateCustomer;
                    case 'DELETE_CUSTOMER':
                        return DeleteCustomerConfrim;
                    case 'ADD_ADMIN':
                        return AddUpdateCustomer;
                    default:
                        return SelectedCustomerInfo;
                }
            }
            return SelectedCustomerInfo;
        },
        highestUserLicences() {
            if (this.getCustomers.length <= 0) {
                return 1000000;
            }
            var maxLicense = 0;
            this.getCustomers.forEach((item) => {
                if (item && item.maxLicense > maxLicense) {
                    maxLicense = item.maxLicense;
                }
            });
            return maxLicense;
        },
        highestCourseLicences() {
            if (this.getCustomers.length <= 0) {
                return 1000000;
            }
            var maxLicense = 0;
            this.getCustomers.forEach((item) => {
                if (item && item.maxCourses > maxLicense) {
                    maxLicense = item.maxCourses;
                }
            });
            return maxLicense;
        },
        highestFileStorage() {
            if (this.getCustomers.length <= 0) {
                return 150000000;
            }
            var maxFileStorage = 0;
            this.getCustomers.forEach((item) => {
                if (item && item.maxFileStorage > maxFileStorage) {
                    maxFileStorage = item.maxFileStorage;
                }
            });
            return maxFileStorage;
        },
        getItems() {
            return this.getCustomers;
        }
    },
    methods: {
        selectCustomer(item) {
            if (item) {
                this.computedSelectedCustomer = this.GET_SINGLE_CUSTOMER(item.id);
            } else {
                this.computedSelectedCustomer = {};
            }
        },
        handleSuperAdmin(customer, superAdmin) {
            this.SET_SELECTED_SUPER_ADMIN({ customer, ...superAdmin });
            this.$emit('editSuperAdmin');
        },
        displayKey(item, key) {
            if (item !== undefined && item !== null) {
                if (item.hasOwnProperty(key)) {
                    return item[key];
                }
            }
            return '';
        },
        getFilteredList(tabValue) {
            return this.getCustomers.filter((customer) => {
                switch (tabValue) {
                    case 'WL2':
                        return !customer.isWebConferenceCustomer && this.filterList(customer, tabValue);
                    case 'WWC':
                        return customer.isWebConferenceCustomer && this.filterList(customer, tabValue);
                    default:
                        return false;
                }
            });
        },
        filterList(item, criteria) {
            var fulfillFilter = true;
            Object.entries(this.filter).forEach(([key, value]) => {
                if (value.active) {
                    switch (key) {
                        case 'active':
                            if (value.value !== item.active) {
                                fulfillFilter = false;
                            }
                            break;
                        case 'maxLicense':
                            if (value.range[0] > item.maxLicense || value.range[1] < item.maxLicense) {
                                fulfillFilter = false;
                            }
                            break;
                        case 'maxCourses':
                            if (value.range[0] > item.maxCourses || value.range[1] < item.maxCourses) {
                                fulfillFilter = false;
                            }
                            break;
                        case 'maxFileStorage':
                            if (value.range[0] > item.maxFileStorage || value.range[1] < item.maxFileStorage) {
                                fulfillFilter = false;
                            }
                            break;
                    }
                }
            });
            return fulfillFilter;
        },
        filterContent(item) {
            if (!this.contentConfirm || this.contentConfirm.trim() === '') {
                return true;
            }
            let buildString = [...new Set(this.contentConfirm.split(' '))].filter((word) => word !== '').map((word) => word.toLowerCase());
            const text = Object.values(item)
                .filter((word) => typeof word === 'string' || typeof word === 'number')
                .map((word) => {
                    if (typeof word === 'number') {
                        return word.toString();
                    }
                    return word;
                })
                .join(' ')
                .toLowerCase();
            return buildString.some((word) => text.includes(word));
        },
        sort() {
            this.SORT_CUSTOMERS('maxLicense', true);
        }
    },
    mounted() {
        this.filter.maxLicense.range[1] = this.highestUserLicences;
        this.filter.maxCourses.range[1] = this.highestCourseLicences;
        this.filter.maxFileStorage.range[1] = this.highestFileStorage;
    }
};
</script>
