<template>
    <v-card>
        <v-data-iterator
            :items="items"
            :no-data-text="$t('Keine Aufgaben zugewiesen!')"
            :footer-props="{
                'items-per-page-text': $t('Aufgabe pro Seite'),
                'items-per-page-options': [6, 12, 18, { text: $t('Alle'), value: -1 }]
            }"
            :items-per-page="6"
            :options.sync="pagination"
            :search="search"
            :custom-filter="handleFilter"
            :custom-sort="evaluateSelectedSort"
            :hideDefaultFooter="computePaginationDetails"
        >
            <template v-slot:header>
                <v-expand-transition>
                    <v-card flat v-show="showOptions">
                        <v-card-text>
                            <v-row align="center">
                                <v-col :cols="12">
                                    <v-text-field
                                        color="accent"
                                        prepend-icon="mdi-magnify"
                                        :label="$t('Aufgaben durchsuchen')"
                                        :hint="$t('Geben Sie Begriffe oder Schlagwörter ein')"
                                        v-model="search"
                                    />
                                </v-col>
                                <v-col v-if="sortArray.length > 0">
                                    <v-select
                                        color="accent"
                                        prepend-icon="mdi-sort"
                                        attach
                                        :hint="$t(`Wählen Sie eine Methode aus, nach der Sie die Aufgaben sortieren wollen`)"
                                        return-object
                                        persistent-hint
                                        v-model="computeSelectedSort"
                                        item-value="name"
                                        :items="sortArray"
                                    >
                                        <template v-slot:item="{ item }">
                                            <div>{{ $t(item.name) }}</div>
                                        </template>
                                        <template v-slot:selection="{ item }">
                                            <div>{{ $t(item.name) }}</div>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn icon @click="reverseList">
                                        <v-icon>mdi-swap-vertical</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn icon color="success" v-if="showButton()" @click="addArchive">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-expand-transition>
            </template>
            <template v-slot:default="{ items }">
                <v-container fluid>
                    <v-row>
                        <v-col v-for="(item, index) in items" :key="item.id" :cols="getCols(index, items)">
                            <TaskNode :value="item" :selected="isSelected(item)" @selected="handleSelection" @results="handleResults" :type="type">
                                <template v-slot:action="action">
                                    <slot name="action" v-bind="action"></slot>
                                </template>
                            </TaskNode>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </v-data-iterator>
    </v-card>
</template>
<script>
import TaskNode from './Helpers/TaskNode';

export default {
    name: 'user-task-list',
    components: {
        TaskNode
    },
    data: () => ({
        search: '',
        selectedSort: {},
        pagination: {}
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        items: {
            type: Array,
            default: () => []
        },
        showOptions: {
            type: Boolean,
            default: false
        },
        sortArray: {
            type: Array,
            default: () => []
        },
        type: {
            type: String,
            validator: (value) => ['TASK', 'ARCHIVE'].includes(value) || 'incorrect type',
            default: 'TASK'
        }
    },
    computed: {
        computePaginationDetails() {
            // divide by the minimum rowsPerPage
            return this.items.length === 0 || this.items.length / 5 <= 1;
        },
        computeSelectedSort: {
            get() {
                if (Object.keys(this.selectedSort).length > 0) {
                    if (this.selectedSort.hasOwnProperty('sort')) {
                        return this.selectedSort;
                    }
                }
                if (this.sortArray.length > 0) {
                    return this.sortArray[0];
                }
                return (items) => items;
            },
            set(value) {
                this.selectedSort = value;
            }
        },
        evaluateSelectedSort() {
            if (this.computeSelectedSort.hasOwnProperty('sort')) {
                return this.computeSelectedSort.sort;
            }
            return this.computeSelectedSort;
        }
    },
    methods: {
        isSelected({ id }) {
            return this.value && this.value.id && id ? this.value.id === id : false;
        },
        handleSelection(item) {
            this.$emit('input', item);
        },
        reverseList() {
            this.pagination.descending = !this.pagination.descending;
        },
        showResultEmit(task) {
            this.$emit('showResultEmit', task);
        },
        handleResults(id) {
            this.$emit('results', id);
        },
        handleFilter(items, search) {
            if (search.trim() === '') {
                return items;
            }
            let stringBuilder = search
                .split(' ')
                .map((word) => `(?=.*${word})`)
                .filter((word) => word !== '')
                .map((word) => `(?=.*${word})`)
                .join('');

            let regex = new RegExp(stringBuilder, 'i');

            return items.filter(
                (item) =>
                    regex.test(item.name) ||
                    regex.test(item.startDate) ||
                    regex.test(item.endDate) ||
                    regex.test(this.$t(item.taskDoneReason)) ||
                    regex.test(item.taskCompletedDate) ||
                    regex.test(item.catalogs.map((catalog) => catalog.name).join(' '))
            );
        },
        showButton() {
            return this.type === 'ARCHIVE';
        },
        addArchive() {
            this.$router.push({ name: 'appArchiveAdd' });
        },
        getCols(index, items) {
            if (this.$vuetify.breakpoint.xs) {
                return 12;
            }
            if (index === items.length - 1 && index % 2 == 0) {
                return 12;
            }
            return 6;
        }
    }
};
</script>
