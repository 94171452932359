import Login from '../../views/Generic/Login';
import ForgotPassword from '../../views/Generic/ForgotPassword';
import SetPassword from '../../views/Generic/SetPassword';
import Impressum from '../../views/Generic/Impressum';
import DataProtection from '../../views/Generic/DataProtection';
import ActivateAccount from '../../views/Generic/ActivateAccount';

import { superAdmin, admin, user } from '../Roles';

export default [
    /**
     * Login
     * @description
     */
    {
        path: '/',
        name: 'login',
        component: Login,
        meta: {
            requiresAuth: false,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: false,
            roleSet: []
        }
    },
    {
        path: '/SetPassword',
        name: 'setPassword',
        component: SetPassword,
        meta: {
            requiresAuth: false,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: false,
            roleSet: []
        }
    },
    {
        path: '/ForgotPassword',
        name: 'forgotPassword',
        component: ForgotPassword,
        meta: {
            requiresAuth: false,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: false,
            roleSet: []
        }
    },
    {
        path: '/ActivateAccount/',
        name: 'activateAccount',
        component: ActivateAccount,
        meta: {
            requiresAuth: false,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: false,
            roleSet: []
        }
    },
    {
        path: '/Impressum/',
        name: 'impressum',
        component: Impressum,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin, admin, user],
            navigation: {
                type: { name: 'Utility', groupId: '' },
                priority: 0,
                icon: 'mdi-web',
                name: 'Impressum'
            }
        }
    },
    {
        path: '/DataProtection/',
        name: 'dataProtection',
        component: DataProtection,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin, admin, user],
            navigation: {
                type: { name: 'Utility', groupId: '' },
                priority: 1,
                icon: 'mdi-help-circle-outline',
                name: 'Datenschutz'
            }
        }
    }
];
