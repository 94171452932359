<template>
    <div>
        <v-alert v-for="(errorMessage, index) in getErrorMessages" :key="`errorMessage-${index}`" dismissible :value="true" type="warning" class="ma-0">
            {{ $t(errorMessage.message) }}
        </v-alert>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters('ErrorReporterOperations', ['getErrorMessages'])
    },
    watch: {
        getErrorMessages(val) {
            if ((typeof val !== 'object' && Object.keys(val).length > 0) || (Array.isArray(val) && val.length > 0)) {
                this.$vuetify.goTo(0, { duration: 500, easing: 'easeInCubic' });
            }
        }
    }
};
</script>
