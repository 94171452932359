<template>
    <v-dialog v-model="chartModal" scrollable persistent :fullscreen="$vuetify.breakpoint.smAndDown" :width="$vuetify.breakpoint.thresholds.sm">
        <v-card>
            <v-toolbar dense flat dark color="primary">
                <v-toolbar-title>{{ $t(keyComputed.groupTypeKey) }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon text @click="showChartDetailEmit(false)">
                    <v-icon color="white">mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-container>
                    <v-row no-gutters>
                        <v-col :cols="12">
                            <v-expansion-panels accordion tile>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>{{ $t('Gesamtergebnis') }}</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <ResizeContainer v-model="dimensions">
                                            <GChart
                                                ref="gChartDetail"
                                                type="BarChart"
                                                :class="{ dark: this.isDark }"
                                                :data="generateChartData"
                                                :options="barChartOptions"
                                            />
                                        </ResizeContainer>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-col>
                        <v-col :cols="12">
                            <component :is="chartTypeSwitch" :value.sync="selectedChartDetail" @knowledgeContents="test" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
        <KnowledgeContentPreview v-model="selectedKnowledgeContent" :items="knowledgeContents" toolbarTitle="Content" />
    </v-dialog>
</template>

<script>
import { GChart } from 'vue-google-charts';
import UserChartDetails from './ChartTypes/UserChartDetails';
import CatalogChartDetails from './ChartTypes/CatalogChartDetails';
import KnowledgeUnitChartDetails from './ChartTypes/KnowledgeUnitChartDetails';
import TaskChartDetails from './ChartTypes/TaskChartDetails';
import KnowledgeContentPreview from '../KnowledgeContent/KnowledgeContentPreview';
import ExceptionChartDetails from './ChartTypes/ExceptionChartDetails';

import ResizeContainer from '../Generic/Resize/ResizeContainer';

export default {
    components: {
        GChart,
        UserChartDetails,
        CatalogChartDetails,
        KnowledgeUnitChartDetails,
        TaskChartDetails,
        ExceptionChartDetails,
        KnowledgeContentPreview,
        ResizeContainer
    },
    data: () => ({
        knowledgeContents: [],
        knowledgeContent: {},
        selectedKnowledgeContent: {},
        dimensions: {
            height: 0,
            width: 0,
            deltaHeight: 0,
            deltaWidth: 0
        }
    }),
    props: {
        selectedChartDetail: {
            type: Object,
            default: () => ({})
        },
        showChartDetail: {
            default: false,
            type: Boolean
        },
        keyComputed: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        barChartOptions() {
            return {
                title: this.$t('Gesamtergebnis'),
                titleTextStyle: {
                    color: this.isDark ? '#FFF' : '#000'
                },
                width: this.dimensions.width,
                height: 300,
                isStacked: 'percent',
                legend: { position: 'top', maxLines: 3 },
                backgroundColor: this.isDark ? '#1E1E1E' : '#FFF',
                vAxis: {
                    format: 'percent',
                    titleTextStyle: { color: this.isDark ? '#FFF' : '#000' }
                },
                hAxis: {
                    minValue: 0,
                    format: 'percent',
                    ticks: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
                    titleTextStyle: { color: this.isDark ? '#FFF' : '#000' }
                },
                series: {
                    0: { color: this.$vuetify.theme.currentTheme.success },
                    1: { color: this.$vuetify.theme.currentTheme.error },
                    2: { color: this.$vuetify.theme.currentTheme.accent }
                },
                tooltip: { isHtml: this.isDark }
            };
        },
        chartTypeSwitch() {
            switch (this.keyComputed.groupTypeKey) {
                case 'users':
                    return UserChartDetails;
                case 'catalogs':
                    return CatalogChartDetails;
                case 'knowledgeUnits':
                    return KnowledgeUnitChartDetails;
                case 'tasks':
                    return TaskChartDetails;
            }
            return ExceptionChartDetails;
        },
        chartModal: {
            get() {
                return this.showChartDetail && Object.keys(this.selectedKnowledgeContent).length === 0;
            },
            set(value) {
                this.$emit('showChartDetailEmit', value);
            }
        },
        generateChartData() {
            if (Object.keys(this.selectedChartDetail).length > 0) {
                let { correct, incorrect, unanswered } = this.selectedChartDetail.summary.votes;
                return [
                    [this.$t('Gesamtergebnis'), this.$t('Richtig'), this.$t('Falsch'), this.$t('Unbeantwortet')],
                    [this.$t('Ergebnis'), correct, incorrect, unanswered]
                ];
            }
            return [
                [this.$t('Gesamtergebnis'), this.$t('Richtig'), this.$t('Falsch'), this.$t('Unbeantwortet')],
                [this.$t('Ergebnis'), 0, 0, 0]
            ];
        },
        isDark() {
            return this.$vuetify.theme.dark;
        }
    },
    methods: {
        test(val) {
            if (val.length > 0) {
                this.knowledgeContents = val;
                this.selectedKnowledgeContent = val[0];
            }
        },
        showChartDetailEmit(val) {
            this.$emit('showChartDetailEmit', val);
        }
    }
};
</script>
