<template>
    <v-container>
        <v-row>
            <template v-if="chartMode == 'MORECHART'">
                <v-col :cols="12" :sm="6">
                    <vote-tally :value="compactChartData.votes" />
                </v-col>
                <v-col :cols="12" :sm="6">
                    <knowledge-content-tally :value="compactChartData.knowledgeContents" />
                </v-col>
                <v-col :cols="12">
                    <knowledge-unit-tally :value="compactChartData.knowledgeUnits" />
                </v-col>
                <v-col :cols="12">
                    <knowledge-unit-ranking />
                </v-col>
                <v-col :cols="12">
                    <user-activity-tally :value="computedUserActivity" :days="days" @days="setDays" />
                </v-col>
                <v-col :cols="12">
                    <quiz-activity />
                </v-col>
            </template>
            <template v-if="chartMode == 'COMPACTCHART'">
                <v-flex>
                    <CompactChart :chartData="compactChartData" selectedChartDataKey="knowledgeUnits" />
                </v-flex>
            </template>
        </v-row>
    </v-container>
</template>
<script>
import DashboardMixin from './Mixins/DashboardMixin';

import VoteTally from './VoteTally';
import UserActivityTally from './UserActivityTally';
import KnowledgeContentTally from './KnowledgeContentTally';
import KnowledgeUnitTally from './KnowledgeUnitTally';
import KnowledgeUnitRanking from './KnowledgeUnitRanking';
import CompactChart from './CompactChart';
import QuizActivity from './QuizActivity';

export default {
    name: 'dashboard',
    mixins: [DashboardMixin],
    components: {
        VoteTally,
        UserActivityTally,
        KnowledgeContentTally,
        KnowledgeUnitTally,
        KnowledgeUnitRanking,
        QuizActivity,
        CompactChart
    },
    data: () => ({
        days: 31,
        userActivity: {},
        compactChartData: {
            knowledgeUnits: {},
            vote: {},
            knowledgeContents: {},
            knowledgeUnitsRank: {},
            userActivity: {},
            quizActivity: {}
        },
        selectedChartDataKey: 'knowledgeUnits'
    }),
    props: {
        chartMode: {
            default: 'MORECHART',
            type: String
        }
    },
    computed: {
        computedUserActivity: {
            get() {
                return this.userActivity;
            },
            set(val) {
                this.days = val;
                this.GET_USER_ACTIVITY(this.days).then((data) => (this.userActivity = data));
            }
        }
    },
    methods: {
        setDays(days) {
            this.computedUserActivity = parseInt(days, 10);
        }
    },
    mounted() {
        this.GET_KNOWLEDGE_UNIT_TOTAL().then((data) => (this.compactChartData.knowledgeUnits = data));
        this.GET_KNOWLEDGE_CONTENT_TOTAL().then((data) => (this.compactChartData.knowledgeContents = data));
        this.GET_VOTES_TOTAL().then((data) => (this.compactChartData.votes = data));
        this.GET_USER_ACTIVITY(this.days).then((data) => (this.userActivity = data));
    }
};
</script>
