<template>
    <v-card text height="100%" style="overflow: auto">
        <v-toolbar flat dense text color="transparent">
            <v-spacer />
            <v-toolbar-items>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="createGroup" v-on="on" medium icon color="success" @click.stop :disabled="!permissions.create">
                            <v-icon medium>mdi-account-multiple-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Gruppe erstellen') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="createUser" v-on="on" medium icon color="success" @click.stop :disabled="!permissions.create">
                            <v-icon medium>mdi-account-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Nutzer erstellen') }}</span>
                </v-tooltip>
            </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <Licenses />
    </v-card>
</template>

<script>
import UsersMixin from '../../Mixins/UserMixin';
import Licenses from '../../Licences';
export default {
    name: 'users-no-view',
    mixins: [UsersMixin],
    components: { Licenses },
    props: {
        search: {
            type: String,
            default: ''
        },
        value: {
            type: Object,
            default: () => ({})
        },
        permissions: {
            permissions: {
                type: Object,
                default: () => ({
                    add: false,
                    update: false,
                    delete: false,
                    change: false
                })
            }
        },
        tree: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        filter(items, search) {
            if (search.trim() === '') {
                return items;
            }
            const buildString = search
                .split(' ')
                .filter((word) => word !== '')
                .map((word) => `(?=.*${word})`)
                .join('');
            const regex = new RegExp(buildString, 'i');
            const fulltext = (item) =>
                Object.values(item)
                    .filter((val) => typeof val === 'string' || typeof val === 'number')
                    .map((val) => {
                        if (typeof val === 'number') {
                            return val.toString();
                        }
                        return val;
                    })
                    .join(' ');

            return items.filter((text) => regex.test(fulltext(text)));
        },
        iconColor(item) {
            if (this.isUserOrGroup(item) === 'USER') {
                return item.active ? 'success' : 'warning';
            }
            return '';
        },
        selectItem(item) {
            this.$emit('input', item);
        },
        createUser() {
            this.$emit('switchView', {
                component: 'CREATE_USER',
                type: 'ADD',
                data: {}
            });
        },
        createGroup() {
            this.$emit('switchView', {
                component: 'CREATE_GROUP',
                type: 'ADD',
                data: {}
            });
        }
    }
};
</script>
