<template>
    <div>
        <v-card>
            <v-toolbar flat dense dark color="secondary">
                <v-toolbar-title class="white--text">{{ computedWiki.name }}</v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                    <v-btn icon color="warning" @click="updateWiki" align-center>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on">mdi-pencil</v-icon>
                            </template>
                            <span>{{ $t('Hier können Sie das ausgewählte Wiki bearbeiten') }}</span>
                        </v-tooltip>
                    </v-btn>
                    <v-btn icon color="error" v-if="canDeleteWiki" @click="deleteWiki">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on">mdi-trash-can</v-icon>
                            </template>
                            <span>{{ $t('Hier können Sie das ausgewählte Wiki löschen') }}</span>
                        </v-tooltip>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text v-if="hasValue">
                <v-col>
                    <v-row>
                        <WikiBreadcrumbs />
                    </v-row>
                    <v-row>
                        <v-col :cols="12">
                            <v-alert :value="true" :type="alertStateType" outlined>{{ alertStateText }}</v-alert>
                        </v-col>
                    </v-row>
                    <v-row v-show="computedWiki.body !== ''">
                        <v-divider class="my-3" />
                    </v-row>
                    <v-row v-if="computedWiki.body !== ''">
                        <div class="ck ck-content" v-html="computedWiki.body"></div>
                        <div style="clear: both"></div>
                    </v-row>
                    <v-row v-show="computedWiki.body !== ''">
                        <v-divider class="my-3" />
                    </v-row>
                    <v-row>
                        <v-col>
                            <KnowledgeContentTags :items="wikiTags" />
                        </v-col>
                        <v-spacer />
                        <v-col align="right">
                            <span class="pr-2">{{ $t('Aufrufe') }}:</span>
                            <span class="primary--text">{{ computedWiki.viewCount }}</span>
                        </v-col>
                    </v-row>
                    <v-row v-if="!isWikiProposal">
                        <WikiSelector :items="relevantWiki" @input="setSelectedWiki" />
                    </v-row>
                    <v-row v-if="computedWiki && computedWiki.knowledgeContents && computedWiki.knowledgeContents.length > 0">
                        <WikiKnowledgeContentLinks
                            label="Content verknüpft mit diesem Wiki"
                            v-if="computedWiki.knowledgeContents.length > 0"
                            :items="computedWiki.knowledgeContents"
                            @input="setSelectedKnowledgeContent"
                        />
                    </v-row>
                    <v-row>
                        <v-expansion-panels accordion>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    {{ $t('Zusätzliche Informationen') }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-container>
                                        <v-row align="center" class="mb-3">
                                            <span class="pr-2">{{ $t('Status') }}:</span>
                                            <StateSelector :value="computedWiki" :permission="hasStateChange" />
                                        </v-row>
                                        <v-row align="center" class="mb-3">
                                            <span class="pr-2">{{ $t('Änderungsvorschläge') }}:</span>
                                            <WikiProposalsMenu :value="computedWiki" />
                                        </v-row>
                                        <v-row align="center" class="mb-3">
                                            <span class="pr-2">{{ $t('Erstellt von') }}:</span>
                                            <UserProfile :value="computedWiki.createdBy" small :displayName="false" />
                                        </v-row>
                                        <v-row align="center" class="mb-3">
                                            <span class="pr-2">{{ $t('Zuletzt bearbeitet von') }}:</span>
                                            <UserProfile :value="computedWiki.updatedBy" small :displayName="false" />
                                        </v-row>
                                        <v-row
                                            v-if="
                                                computedWiki &&
                                                computedWiki.state &&
                                                computedWiki.state.commissionedTo &&
                                                computedWiki.state.commissionedTo.length > 0
                                            "
                                            align="center"
                                        >
                                            <span class="pr-2">{{ $t('In Auftrag gegeben an') }}:</span>
                                            <UserProfile
                                                v-for="user in computedWiki.state.commissionedTo"
                                                :key="user.id"
                                                :value="user"
                                                small
                                                :displayName="false"
                                            />
                                        </v-row>
                                    </v-container>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-row>
                </v-col>
            </v-card-text>
        </v-card>
        <KnowledgeContentPreview v-model="selectedKnowledgeContent" :items="computedWiki.knowledgeContents" toolbarTitle="Content" />
    </div>
</template>

<script>
import WikiMixin from './Mixins/WikiMixin';
import WikiBreadcrumbs from './Helpers/WikiBreadcrumbs';
import WikiKnowledgeContentLinks from './Helpers/WikiKnowledgeContentLinks';
import KnowledgeContentPreview from '../KnowledgeContent/KnowledgeContentPreview';
import WikiSelector from './Helpers/WikiSelector';
import StateSelector from '../Generic/StateSelector';
import UserProfile from '../Generic/Profile/UserProfile';
import KnowledgeContentTags from '../KnowledgeContent/KnowledgeContentTags';

export default {
    name: 'wiki-display',
    components: {
        WikiKnowledgeContentLinks,
        WikiSelector,
        KnowledgeContentPreview,
        WikiBreadcrumbs,
        UserProfile,
        StateSelector,
        KnowledgeContentTags,
        WikiProposalsMenu: () => import('./Helpers/WikiProposalsMenu.vue')
    },
    mixins: [WikiMixin],
    data: () => ({
        selectedKnowledgeContent: {},
        hasStateChange: false
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        isWikiProposal: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        relevantWiki() {
            return this.computedWiki && this.computedWiki.id && this.getWikiList
                ? this.getWikiList.filter((wiki) => wiki.parentId === this.computedWiki.id)
                : [];
        },
        alertStateType() {
            if (this.computedWiki && this.computedWiki.state && this.computedWiki.state.stateName) {
                switch (this.computedWiki.state.stateName) {
                    case 'APPROVED':
                        return 'success';
                    case 'PENDING':
                        return 'warning';
                    case 'DECLINED':
                        return 'error';
                }
            }
            return 'info';
        },
        alertStateText() {
            if (this.computedWiki && this.computedWiki.state && this.computedWiki.state.stateName) {
                switch (this.computedWiki.state.stateName) {
                    case 'APPROVED':
                        return this.$t('Die Informationen in diesem Wiki-Eintrag sind aktuell und wurden von einem Admin abgenommen');
                    case 'PENDING':
                        return this.$t('Dieser Wiki-Eintrag wurde noch nicht von einem Admin abgenommen. Die Informationen sind möglicherweise nicht Korrekt');
                    case 'DECLINED':
                        return this.$t('Dieser Wiki-Eintrag wurde von einem Admin abgenommen und für unzureichend empfunden');
                    case 'PROPOSAL':
                        return this.$t('Dieser Wiki-Eintrag ist ein Änderungsvorschlag');
                    case 'COMMISSIONED':
                        return this.$t('Dieser Wiki-Eintrag wurde in Auftrag gegeben und noch nicht vollständig');
                }
            }
            return '';
        },
        wikiTags() {
            return this.computedWiki && this.computedWiki.tags ? this.computedWiki.tags.split(',') : [];
        },
        computedWiki() {
            if (Object.keys(this.value).length > 0) {
                return this.value;
            }
            return Object.keys(this.getSelectedWiki).length > 0 ? this.getSelectedWiki : {};
        },
        hasValue() {
            return Object.keys(this.value).length > 0 || Object.keys(this.getSelectedWiki).length > 0;
        },
        canDeleteWiki() {
            return Object.keys(this.getSelectedWiki).length > 0 && this.getSelectedWiki && this.getSelectedWiki.createdBy && this.getSelectedWiki.createdBy.id
                ? this.getSelectedWiki.createdBy.id == this.getLoggedInAdmin.id
                : false;
        }
    },
    methods: {
        setSelectedWiki(value) {
            if (!this.hasValue) {
                this.SET_SELECTED_WIKI(value);
                this.SET_VIEW({ component: 'GET_WIKI', type: '', data: value });
            }
        },
        setSelectedKnowledgeContent(value) {
            this.selectedKnowledgeContent = value;
        },
        deleteWiki() {
            this.SET_VIEW({ component: 'DELETE_WIKI', type: '', data: this.getSelectedWiki });
        },
        updateWiki() {
            this.SET_VIEW({ component: 'UPDATE_WIKI', type: 'UPDATE', data: this.getSelectedWiki });
        }
    },
    mounted() {
        this.validateEditorState({
            stateChange: ['CHANGE_WIKI_STATE']
        }).then((response) => {
            this.hasStateChange = response.stateChange;
        });
    }
};
</script>
