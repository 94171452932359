<template>
    <v-menu
        v-model="showMenu"
        :bottom="position === 'bottom'"
        :top="position === 'top'"
        :left="position === 'left'"
        :right="position === 'right'"
        :offset-y="position === 'bottom' || position === 'top'"
        :offset-x="position === 'left' || position === 'right'"
        :close-on-content-click="false"
        allow-overflow
    >
        <template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
            <v-tooltip :bottom="position === 'bottom'" :top="position === 'top'" :left="position === 'left'" :right="position === 'right'">
                <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                    <v-btn :disabled="isDisabled" v-on="{ ...tooltipOn, ...menuOn }" v-bind="{ ...tooltipAttrs, ...menuAttrs, ...icon }">
                        <slot>
                            <v-icon>mdi-inbox-full-outline</v-icon>
                        </slot>
                    </v-btn>
                </template>
                {{ $t('Material') }}
            </v-tooltip>
        </template>
        <v-card :width="$vuetify.breakpoint.thresholds.sm">
            <v-toolbar flat dark dense color="secondary">
                <v-slide-x-transition mode="out-in">
                    <v-btn icon v-if="!!type" @click="back">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                </v-slide-x-transition>
                <v-toolbar-title>{{ computedTitle }}</v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                    <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                            <v-btn icon v-on="on" v-bind="attrs" color="success" @click="addMaterial" :disabled="type === 'ADD' || onlyDownload">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        {{ $t('Material Hinzufügen') }}
                    </v-tooltip>
                </v-toolbar-items>
            </v-toolbar>
            <v-scroll-x-transition mode="out-in">
                <keep-alive>
                    <v-component
                        :is="computedComponent"
                        v-bind="{ ...$props, ...$data }"
                        @selectedMaterial="handleMaterial"
                        @showMenu="(value) => (showMenu = value)"
                        @materials="handleMaterials"
                        @switchView="handleSwitchView"
                    />
                </keep-alive>
            </v-scroll-x-transition>
        </v-card>
    </v-menu>
</template>
<script>
import CatalogMaterialMixin from './Mixins/CatalogMaterialMixin';
import MaterialList from './Helpers/MaterialLIst';
import AddUpdateMaterial from './Helpers/AddUpdateMaterial';
import DeleteMaterial from './Helpers/DeleteMaterial';

export default {
    name: 'catalog-material',
    mixins: [CatalogMaterialMixin],
    components: { MaterialList, AddUpdateMaterial, DeleteMaterial },
    data: () => ({
        showMenu: false,
        materials: [],
        selectedMaterial: {},
        type: ''
    }),
    props: {
        catalog: {
            type: Object,
            default: () => ({})
        },
        onlyDownload: {
            type: Boolean,
            default: false
        },
        position: {
            type: String,
            default: 'bottom'
        },
        icon: {
            type: Object,
            default: () => ({
                icon: true,
                color: 'secondary'
            })
        }
    },
    watch: {
        catalog(value) {
            if (value && value.id) {
                this.GET_MATERIALS({ catalog: value });
            }
        },
        getMaterials(value) {
            this.materials = [...value];
        },
        showMenu(value) {
            if (!value) {
                this.type = '';
            }
        }
    },
    computed: {
        isDisabled() {
            return this.materials.length === 0 && this.onlyDownload;
        },
        computedComponent() {
            switch (this.type) {
                case 'ADD':
                    return AddUpdateMaterial;
                case 'UPDATE':
                    return AddUpdateMaterial;
                case 'DELETE':
                    return;
            }
            return MaterialList;
        },
        computedTitle() {
            switch (this.type) {
                case 'ADD':
                    return this.$t('Material Hinzufügen');
                case 'UPDATE':
                    return this.$t('Material Bearbeiten');
                case 'DELETE':
                    return this.$t('Material Entfernen');
            }
            return this.$t('Material');
        }
    },
    methods: {
        back() {
            this.type = '';
        },
        addMaterial() {
            this.type = 'ADD';
        },
        handleMaterials(value) {
            this.UPDATE_MATERIAL({ catalog: this.catalog, material: value }).then((result) => this.GET_MATERIALS({ catalog: this.catalog }));
        },
        handleMaterial(value) {
            this.selectedMaterial = value;
        },
        handleSwitchView({ type, data }) {
            this.type = type;
            this.selectedMaterial = data;
        }
    },
    beforeMount() {
        if (this.catalog && this.catalog.id) {
            this.GET_MATERIALS({ catalog: this.catalog });
        }
    }
};
</script>
