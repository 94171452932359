<template>
    <v-treeview :items="getTree" transition return-object class="pt-2">
        <template v-slot:prepend="{ item, open }">
            <v-icon>{{
                isAuthoringToolType(item) === 'CATALOG' ? 'mdi-card-bulleted-outline' : open ? 'mdi-folder-open-outline' : 'mdi-folder-outline'
            }}</v-icon>
        </template>
        <template v-slot:label="{ item, active }">
            <v-checkbox
                v-if="isAuthoringToolType(item) === 'CATALOG_SUBGROUP'"
                hide-details
                :value="item"
                :key="item.id"
                :label="item.name"
                v-model="computedCheckbox"
                :disabled="isDisabled(item)"
                :ripple="false"
            />
            <span
                v-else
                :class="{
                    'primary--text': active,
                    'no-highlight': true,
                    'truncate-tree-item': true
                }"
            >
                {{ item.name || item.question }}
            </span>
        </template>
    </v-treeview>
</template>

<script>
import AuthoringToolMixin from '../Mixins/AuthoringtoolMixin';
export default {
    name: 'multiple-catalog-sub-group-selector-tree',
    mixins: [AuthoringToolMixin],
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        computedCheckbox: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        isDisabled(item) {
            return !this.getPermissions(item.id).get;
        }
    }
};
</script>

<style scoped>
.truncate-tree-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-treeview {
    height: 100%;
}
.v-input--selection-controls {
    margin-top: 0;
}
</style>
