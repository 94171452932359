<template>
    <v-layout column>
        <Toolbar
            :toolbarTitle="$t('Berechtigungsprofil bearbeiten')"
            tooltipMessage="Hier können Sie Profile anlegen mit einer vordefinierten Menge an Berechtigungen."
            :showDrawer="false"
            :lastPath="-1"
        />
        <v-card>
            <v-card-text>
                <v-text-field
                    color="accent"
                    v-model="profileName"
                    :label="$t('Profilname')"
                    :hint="$t('Geben Sie dem Berechtigungsprofil einen Namen, der die Berechtigungen möglichst genau wiederspiegelt')"
                    persistent-hint
                    v-if="isProfileSelected"
                    required
                    :rules="[nameRules()]"
                    class="mb-3"
                />
                <v-text-field
                    color="accent"
                    v-model="profileDescription"
                    :label="$t('Beschreibung')"
                    :hint="$t('Beschreiben Sie die Funktion des Berechtigungsprofils noch näher')"
                    persistent-hint
                    v-if="isProfileSelected"
                    class="mb-3"
                />

                <v-tabs slider-color="accent" v-model="tabs" mandatory v-if="isProfileSelected">
                    <v-tab key="GENERAL">{{ $t('Allgemeine Berechtigungen') }}</v-tab>
                    <v-tab key="AUTHORING">{{ $t('Autorenberechtigungen') }}</v-tab>

                    <v-tab-item key="GENERAL">
                        <v-card>
                            <AdminPermissionList v-model="selectedPermissions" />
                        </v-card>
                    </v-tab-item>
                    <v-tab-item key="AUTHORING">
                        <v-card>
                            <ProfileGroupSelector v-model="selectedProfile.authoringPermissions" />
                        </v-card>
                    </v-tab-item>
                </v-tabs>
            </v-card-text>
            <v-card-actions>
                <v-btn color="warning" block :disabled="!isProfileSelected" @click="savePermissionProfile">{{ $t('Speichern') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-layout>
</template>

<script>
import Toolbar from '../../../Components/Generic/Toolbar';
import AdminPermissionList from '../../../Components/Permission/AdminPermissionList';
import ProfileGroupSelector from '../../../Components/Permission/Profiles/ProfileAuthoringSelector';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'update-permission-profiles',
    components: {
        Toolbar,
        AdminPermissionList,
        ProfileGroupSelector
    },
    data: () => ({
        selectedProfile: {},
        profileName: '',
        profileDescription: '',
        selectedPermissions: [],
        tabs: null
    }),
    watch: {
        selectedProfile(value) {
            this.profileName = value.name;
            this.profileDescription = value.description;
            this.selectedPermissions = value.permissions;
        }
    },
    computed: {
        ...mapGetters('PermissionProfiles', ['getProfiles', 'getSelectedProfile']),
        isProfileSelected() {
            return Object.keys(this.selectedProfile).length > 0;
        }
    },
    methods: {
        ...mapActions('PermissionProfiles', ['UPDATE_PROFILE', 'GET_PROFILES', 'SET_SELECTED_PROFILE']),
        nameRules() {
            return (v) => !!v || this.$t('Das Feld darf nicht leer sein');
        },
        savePermissionProfile() {
            let requestBody = {
                id: this.selectedProfile.id,
                name: this.profileName,
                authoringPermissions: this.selectedProfile.authoringPermissions,
                description: this.profileDescription,
                permissions: this.selectedPermissions
            };
            this.UPDATE_PROFILE(requestBody).then(() =>
                this.GET_PROFILES().then(() => {
                    let find = this.getProfiles.find((item) => item.id === requestBody.id);
                    this.SET_SELECTED_PROFILE(find).then(() => this.$router.go(-1));
                })
            );
        }
    },
    mounted() {
        this.selectedProfile = { ...this.getSelectedProfile };
    }
};
</script>
