import axios from '../../../plugins/Axios/axios';
import Vue from 'vue';

const state = {
    materialMap: {}
};

const getters = {
    getMaterial() {
        return ({ id }) => {
            if (state.materialMap.hasOwnProperty(id)) {
                return state.materialMap[id];
            }
            return undefined;
        };
    }
};

const actions = {
    CREATE_MATERIAL({ commit }, material) {
        return axios.post(`/courselearning/materialinstances`, material).then((response) => response.data.data);
    },
    UPDATE_MATERIAL({ commit }, { id: materialId, ...material }) {
        return axios.patch(`/courselearning/materialinstances/${materialId}`, material).then((response) => response.data.data);
    },
    UPDATE_COURSE_MATERIALS({ commit }, { id: courseId, materials }) {
        return axios
            .patch(`/courselearning/courseinstances/${courseId}/material`, materials)
            .then((response) => response.data.data)
            .then((response) =>
                commit('updateMaterialMap', {
                    id: courseId,
                    materials: response
                })
            );
    },
    UPDATE_ACTIVITY_MATERIALS({ commit }, { id: activityId, materials }) {
        return axios
            .patch(`/courselearning/activities/${activityId}/material`, materials)
            .then((response) => response.data.data)
            .then((response) =>
                commit('updateMaterialMap', {
                    id: activityId,
                    materials: response
                })
            );
    },
    DELETE_MATERIAL({ commit }, { id: materialId }) {
        return axios.delete(`/courselearning/materialinstances/${materialId}`).then((response) => response.data.data);
    },
    GET_COURSE_MATERIALS({ commit }, { id: courseId }) {
        return axios
            .get(`/courselearning/courseinstances/${courseId}/material`)
            .then((response) => response.data.data)
            .then((response) =>
                commit('updateMaterialMap', {
                    id: courseId,
                    materials: response
                })
            );
    },
    GET_ACTIVITY_MATERIALS({ commit }, { id: activityId }) {
        return axios
            .get(`/courselearning/activities/${activityId}/material`)
            .then((response) => response.data.data)
            .then((response) =>
                commit('updateMaterialMap', {
                    id: activityId,
                    materials: response
                })
            );
    }
};

const mutations = {
    clear: (state) => {
        state.materialMap = {};
    },
    updateMaterialMap: (state, { id, materials }) => {
        if (!Array.isArray(materials)) {
            if (state.materialMap[id] === undefined) {
                Vue.set(state.materialMap, id, [materials]);
            } else {
                const hasMaterial = state.materialMap[id].map((mat) => mat.id).indexOf(materials.id);
                if (hasMaterial !== -1) {
                    state.materialMap[id] = state.materialMap[id].map((mat) => {
                        if (mat.id === materials.id) {
                            return materials;
                        }
                        return mat;
                    });
                } else {
                    state.materialMap[id].push(materials);
                }
            }
        } else {
            Vue.set(state.materialMap, id, materials);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
