export default [
    {
        text: 'Foren',
        value: 'title',
        align: 'left',
        sortable: false,
        width: '40%'
    },
    {
        text: 'Priorität',
        value: 'order',
        align: 'left',
        sortable: true,
        width: '10%'
    },
    {
        text: 'Themen',
        value: 'threadCount',
        align: 'left',
        sortable: false,
        width: '10%'
    },
    {
        text: 'Letzte Antwort',
        value: 'latestAnswer',
        align: 'left',
        sortable: false,
        width: '10%'
    },
    {
        text: 'Thema',
        value: 'thread',
        align: 'left',
        sortable: false,
        width: '30%'
    },
    {
        text: '',
        value: 'menu',
        align: 'left',
        sortable: false,
        width: 'auto'
    }
];
