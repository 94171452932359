<template>
    <v-card flat height="100%">
        <v-layout fill-height>
            <v-flex xs3>
                <v-layout column fill-height class="verticalLimit">
                    <v-flex>
                        <slot></slot>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex shrink>
                <v-divider vertical />
            </v-flex>
            <v-flex xs9>
                <v-layout column fill-height class="verticalLimit">
                    <keep-alive>
                        <component v-model="computedSelectedItem" :is="component" :search="search" @openTree="handleOpenTree" />
                    </keep-alive>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-card>
</template>

<script>
export default {
    name: 'authoring-tool-view',
    computed: {
        computedSelectedItem: {
            get() {
                return this.value.length > 0 ? this.value[0] : {};
            },
            set(value) {
                this.$emit('input', [value]);
            }
        }
    },
    props: {
        value: {
            type: Array,
            default: () => []
        },
        component: {
            type: [String, Object],
            default: '<div/>'
        },
        search: {
            type: String,
            default: ''
        }
    },
    methods: {
        handleOpenTree(value) {
            this.$emit('openTree', value);
        }
    }
};
</script>
