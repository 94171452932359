<template>
    <v-card text>
        <v-toolbar dense text dark color="secondary">
            <v-toolbar-title>{{ $t('Taxonomien') }}</v-toolbar-title>
        </v-toolbar>
        <v-tabs v-model="tabs" mandatory slider-color="accent">
            <v-tab key="STANDARD_TAXONOMIES">{{ $t('Standard') }}</v-tab>
            <v-tab key="COURSE_TAXONOMIES">{{ $t('Benutzerdefiniert') }}</v-tab>
        </v-tabs>
        <v-card-text :style="floatingBlock">
            <v-tabs-items v-model="tabs" touchless>
                <v-tab-item key="STANDARD_TAXONOMIES">
                    <v-list two-line>
                        <v-list-item
                            :style="{ 'background-color': tax.hexColor }"
                            v-for="(tax, index) in getDefaultTaxonomies"
                            :key="`default-tax-${index}`"
                            class="mb-1"
                            @click.prevent="selectTaxonomy(tax)"
                            ripple
                        >
                            <v-list-item-action v-if="selectable">
                                <v-icon dark v-show="isSelected(tax)">mdi-check-circle-outline</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span class="white--text">{{ $t(tax.name) }}</span>
                                </v-list-item-title>
                                <v-list-item-subtitle>{{ tax.hexColor }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-tab-item>

                <v-tab-item key="COURSE_TAXONOMIES">
                    <v-list two-line>
                        <v-list-item
                            :style="{ 'background-color': tax.hexColor }"
                            v-for="(tax, index) in getCourseTaxonomies.labels"
                            :key="`course-tax-${index}`"
                            class="mb-1"
                            @click.prevent="selectTaxonomy(tax)"
                            ripple
                        >
                            <v-list-item-action v-if="selectable">
                                <v-icon dark v-show="isSelected(tax)">mdi-check-circle-outline</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span class="white--text">{{ tax.name }}</span>
                                </v-list-item-title>
                                <v-list-item-subtitle>{{ tax.hexColor }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn dark icon @click.stop="updateDeleteTaxonomy(tax)">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
        <v-card-actions>
            <v-btn block color="success" @click="createTaxonomy">
                {{ $t('Benutzerdefinierte Taxonomie erstellen') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import CourseMixin from '../../Mixins/CourseMixin';

export default {
    name: 'taxonomy-selector',
    mixins: [CourseMixin],
    data: () => ({
        tabs: 0
    }),
    props: {
        selectable: {
            type: Boolean,
            default: false
        },
        value: {
            type: [Array, Object],
            default: () => []
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        floatingBlock() {
            let height = '50vh';
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': {
                    height = '90vh';
                    break;
                }
                case 'sm': {
                    height = '70vh';
                    break;
                }
                case 'md': {
                    height = '50vh';
                    break;
                }
                case 'xl': {
                    height = '30vh';
                    break;
                }
            }
            return {
                'max-height': height,
                'overflow-y': 'auto'
            };
        }
    },
    methods: {
        isSelected(taxonomy) {
            if (this.value === undefined || this.value === null || !this.value.hasOwnProperty('id')) {
                return false;
            }
            if (this.multiple) {
                return this.value.map((tax) => tax.id).includes(taxonomy.id);
            }
            return this.value.id === taxonomy.id;
        },
        selectTaxonomy(taxonomy) {
            if (this.selectable) {
                if (this.multiple) {
                    if (this.value.map((tax) => tax.id).includes(taxonomy.id)) {
                        this.$emit(
                            'input',
                            this.value.filter((tax) => tax.id !== taxonomy.id)
                        );
                        this.$emit('close', false);
                    } else {
                        this.$emit('input', [...this.value, taxonomy]);
                        this.$emit('close', false);
                    }
                } else {
                    if (
                        taxonomy !== null &&
                        taxonomy !== undefined &&
                        this.value !== null &&
                        this.value !== undefined &&
                        taxonomy.hasOwnProperty('id') &&
                        this.value.hasOwnProperty('id')
                    ) {
                        if (taxonomy.id === this.value.id) {
                            this.$emit('input', {});
                        } else {
                            this.$emit('input', taxonomy);
                        }
                    } else {
                        this.$emit('input', taxonomy);
                    }
                    this.$emit('close', false);
                }
            }
        },
        updateDeleteTaxonomy(taxonomy) {
            this.$emit('switchComponent', 'UPDATE');
            this.$emit('taxonomy', taxonomy);
        },
        createTaxonomy() {
            this.$emit('switchComponent', 'ADD');
        }
    },
    mounted() {
        this.GET_DEFAULT_TAXONOMIES();
        this.GET_COURSE_TAXONOMY_TABLE(this.getSelectedAdminCourse);
    }
};
</script>
