<template>
    <v-card flat>
        <v-expand-transition mode="out-in">
            <v-carousel
                v-show="hasFiles"
                v-model="currentIndex"
                :cycle="false"
                touchless
                :show-arrows="files.length > 1"
                :hide-delimiters="files.length <= 1"
                class="elevation-0"
                :height="300"
            >
                <v-carousel-item v-for="(file, index) in files" :key="`file-${index}`">
                    <v-layout column fill-height align-center justify-center>
                        <component :is="componentSwitch(file)" :src="getSource(index)" />
                    </v-layout>
                </v-carousel-item>
            </v-carousel>
        </v-expand-transition>
        <v-container fluid>
            <v-row>
                <v-col :cols="12">
                    <v-menu v-model="menu" offset-y :close-on-content-click="false" :max-height="400" :min-width="300">
                        <template v-slot:activator="{ on }">
                            <v-input
                                persistent-hint
                                :hint="
                                    $t(
                                        'Wählen Sie eine Gruppe aus, wo die Dateien abgelegt werden sollen. Wenn Sie keine Gruppe auswählen, werden die Dateien im Stammverzeichnis angelegt'
                                    )
                                "
                            >
                                <template v-slot:prepend="">
                                    <v-btn v-on="on" icon>
                                        <v-icon>mdi-file-tree</v-icon>
                                    </v-btn>
                                </template>
                                <v-breadcrumbs divider=">" :items="groupPath">
                                    <template v-slot:item="{ item }">
                                        <span class="text-caption">{{ item.name || item.fileName }}</span>
                                    </template>
                                </v-breadcrumbs>
                            </v-input>
                        </template>
                        <v-card>
                            <v-toolbar dense color="secondary" text dark>
                                <v-toolbar-title>{{ $t('Gruppen') }}</v-toolbar-title>
                            </v-toolbar>
                            <v-sheet :max-height="300" class="overflow-y-auto">
                                <FilemanagerFolderSelector v-model="selectedFolder" />
                            </v-sheet>
                        </v-card>
                    </v-menu>
                </v-col>
                <v-col :cols="8">
                    <v-text-field :label="$t('Datei')" v-model="computedFileName" hide-details :disabled="!hasFiles" />
                </v-col>
                <v-col :cols="4">
                    <v-text-field :label="$t('Dateityp')" :value="fileType(currentIndex)" readonly hide-details :disabled="!hasFiles" />
                </v-col>
                <v-col :cols="12">
                    <v-text-field
                        :label="$t('Size')"
                        :value="formatBytes(getCurrentFile(currentIndex) && getCurrentFile(currentIndex).size ? getCurrentFile(currentIndex).size : 0)"
                        readonly
                        hide-details
                        :disabled="!hasFiles"
                    />
                </v-col>
                <v-col :cols="12">
                    <FilePicker
                        v-model="fileList"
                        multiple
                        :label="$t('Bild, Video, Audio, Pdf Datei')"
                        accept="audio/*, video/mp4, video/ogg, video/webm, image/*, application/pdf"
                    />
                </v-col>
            </v-row>
        </v-container>
        <v-card-actions>
            <v-btn block color="success" :disabled="!hasFiles" @click="save">
                {{ $t('Hochladen') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import FilePreviewAudio from '../../FilePreviewTypes/FilePreviewAudio';
import FilePreviewPDF from '../../FilePreviewTypes/FilePreviewPDF';
import FilePreviewVideo from '../../FilePreviewTypes/FilePreviewVideo';
import ImageLoader from '../../../Generic/ImageLoader';
import FilePicker from '../../../Generic/FilePicker/FilePicker';
import FilemanagerFolderSelector from './FilemanagerFolderSelector';

import FilemanagerMixin from '../../Mixins/FilemanagerPrototypeMixin';

export default {
    name: 'filemanager-upload-view',
    components: {
        FilePicker,
        FilePreviewAudio,
        FilePreviewPDF,
        FilePreviewVideo,
        ImageLoader,
        FilemanagerFolderSelector
    },
    mixins: [FilemanagerMixin],
    data: () => ({
        menu: false,
        selectedFolder: {},
        fileList: {},
        sourceMap: {},
        fileNameMap: {},
        currentIndex: 0
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        fileList(value) {
            if (!value.length) {
                this.resetMaps();
            }
        }
    },
    computed: {
        computedFileName: {
            get() {
                return this.fileNameMap.hasOwnProperty(this.currentIndex) ? this.fileNameMap[this.currentIndex] : ' ';
            },
            set(value) {
                this.$set(this.fileNameMap, this.currentIndex, value);
            }
        },
        hasFiles() {
            return this.files.length > 0;
        },
        files() {
            let files = [];
            for (let i = 0; i < this.fileList.length; i++) {
                const file = this.fileList.item(i);
                files.push(file);
                this.loadFile(file, i);
                this.$set(this.fileNameMap, i, file.name.substring(0, file.name.lastIndexOf('.')));
            }
            return files;
        },
        componentSwitch() {
            return (file) => {
                if (file && file.type) {
                    if (file.type.includes('video')) {
                        return FilePreviewVideo;
                    }
                    if (file.type.includes('audio')) {
                        return FilePreviewAudio;
                    }
                    if (file.type.includes('application')) {
                        return FilePreviewPDF;
                    }
                }
                return ImageLoader;
            };
        },
        groupPath() {
            return this.calculateTreePath(this.selectedFolder) || [{ name: this.$t('Stammverzeichnis') }];
        }
    },
    methods: {
        resetMaps() {
            this.sourceMap = {};
            this.fileNameMap = {};
        },
        reset() {
            this.fileList = {};
            this.sourceMap = {};
            this.fileNameMap = {};
            this.currentIndex = 0;
            this.selectedFolder = {};
        },
        fileType(index) {
            const file = this.fileList[index];
            return file && file.name ? file.name.substring(file.name.lastIndexOf('.') + 1) : ' ';
        },
        serialize(index) {
            return {
                name: this.fileNameMap[index],
                parentId: this.selectedFolder && this.selectedFolder.id ? this.selectedFolder.id : '',
                isRoot: this.selectedFolder && this.selectedFolder.id ? false : true,
                isPublic: false,
                file: this.fileList[index]
            };
        },
        getCurrentFile(index) {
            if (this.files.length > 0) {
                return this.files[index];
            }
            return { name: ' ', size: 0 };
        },
        getSource(index) {
            return this.sourceMap.hasOwnProperty(index) ? this.sourceMap[index] : '';
        },
        loadFile(file, index) {
            new Promise((resolve) => {
                const fileReader = new FileReader();
                fileReader.onload = (event) => resolve(event.target.result);
                fileReader.readAsDataURL(file);
            }).then((result) => {
                this.$set(this.sourceMap, index, result);
            });
        },
        save() {
            Promise.all(this.files.map((file, index) => this.UPLOAD_MEDIA(this.serialize(index))))
                .then((result) => this.GET_TREE().then(() => result))
                .then((result) => this.$emit('media', result[0]));
        }
    },
    activated() {
        this.reset();
        if (Object.keys(this.value).length > 0) {
            if (this.isFilemanagerType(this.value) === 'FOLDER') {
                this.selectedFolder = this.value;
            } else {
                const parent = this.getFileFolder(this.value);
                if (parent) {
                    this.selectedFolder = parent;
                }
            }
        }
    }
};
</script>
