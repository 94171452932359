<template>
    <div :style="{ height: height, width: width }">
        <iframe :src="src" width="100%" height="100%" />
    </div>
</template>

<script>
export default {
    name: 'file-preview-pdf',

    props: {
        width: {
            type: [String, Number],
            default: '100%'
        },
        height: {
            type: [String, Number],
            default: '80vh'
        },
        src: {
            type: String,
            default: ''
        }
    }
};
</script>
