<template>
    <v-dialog v-model="showDialog" persistent :width="$vuetify.breakpoint.thresholds.sm" scrollable :fullscreen="$vuetify.breakpoint.xs">
        <template #activator="{ on, attrs }">
            <v-tooltip bottom>
                <template #activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                    <v-btn
                        icon
                        v-on="{ ...on, ...tooltipOn }"
                        v-bind="{ ...attrs, tooltipAttrs }"
                        :color="type === 'ADD' ? 'success' : 'warning'"
                        :disabled="computedDisableType"
                    >
                        <v-icon>{{ type === 'ADD' ? 'mdi-plus' : 'mdi-pencil' }}</v-icon>
                    </v-btn>
                </template>
                <span>{{ computedTooptip }}</span>
            </v-tooltip>
        </template>
        <v-card>
            <v-toolbar color="secondary" dark flat dense>
                <v-toolbar-title>{{ toolbarTitle }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-form v-model="validate">
                    <v-container>
                        <v-row>
                            <v-col :cols="12">
                                <v-text-field
                                    v-model="name"
                                    :label="$t('Titel')"
                                    :hint="$t('Bewertungstabellen Titel')"
                                    persistent-hint
                                    :rules="[rules('notEmpty')]"
                                />
                            </v-col>
                            <v-col :cols="12">
                                <v-textarea
                                    :label="$t('Beschreibung')"
                                    :hint="$t('Die Beschreibung der Bewertungstabelle')"
                                    persistent-hint
                                    v-model="description"
                                    no-resize
                                    row-height="10"
                                    outlined
                                    :rows="3"
                                />
                            </v-col>
                            <v-col :cols="12">
                                <GradingTable v-model="percentToGradeMap" @validation="handleGradeMapValidation" />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn block :color="saveButtonColor" @click="save" :disabled="!disableSave">
                    {{ saveButtonText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import GradingTable from './Helpers/GradingTable/GradingTableSelector';
import CourseMixin from './Mixins/CourseMixin';

export default {
    name: 'add-update-grading-table',
    mixins: [CourseMixin],
    components: { GradingTable },
    data: () => ({
        name: '',
        description: '',
        folderId: '',
        percentToGradeMap: {
            0: ''
        },
        validateGradeMap: false,
        validate: false,
        isLoaded: false,
        showDialog: false
    }),
    props: {
        type: {
            type: String,
            default: 'ADD',
            validator: (value) => ['ADD', 'UPDATE'].includes(value)
        }
    },
    watch: {
        showDialog(value) {
            if (value) {
                if (this.type === 'UPDATE') {
                    Object.entries(this.getSelectedGradingTable).forEach(([key, value]) => (this.$data[key] = value));
                }
            }
        }
    },
    computed: {
        computedTooptip() {
            switch (this.type) {
                case 'ADD':
                    return this.$t('Bewertungstabelle erstellen');
                case 'UPDATE':
                    return this.$t('Bewertungstabelle bearbeiten');
            }
            return '';
        },
        computedDisableType() {
            if (this.type === 'ADD') {
                return false;
            }
            if (this.type === 'UPDATE') {
                return Object.keys(this.getSelectedGradingTable).length === 0;
            }
            return false;
        },
        toolbarTitle() {
            switch (this.type) {
                case 'ADD':
                    return this.$t('Benotungstabelle erstellen');
                case 'UPDATE':
                    return this.$t('Benotungstabelle bearbeiten');
            }
            return '';
        },
        saveButtonColor() {
            switch (this.type) {
                case 'ADD':
                    return 'success';
                case 'UPDATE':
                    return 'warning';
            }
            return '';
        },
        saveButtonText() {
            switch (this.type) {
                case 'ADD':
                    return this.$t('Hinzufügen');
                case 'UPDATE':
                    return this.$t('Bearbeiten');
            }
            return '';
        },
        disableSave() {
            return (
                Object.keys(this.percentToGradeMap).length > 0 &&
                (this.percentToGradeMap.hasOwnProperty('0') || this.percentToGradeMap.hasOwnProperty('0.0') || this.percentToGradeMap.hasOwnProperty('0.00')) &&
                this.validateGradeMap &&
                this.validate
            );
        }
    },
    methods: {
        handleGradeMapValidation(value) {
            this.validateGradeMap = value;
        },
        reset() {
            this.name = '';
            this.description = '';
            this.folderId = '';
            this.percentToGradeMap = {
                0: ''
            };
            this.isLoaded = false;
        },
        close() {
            this.showDialog = false;
            this.$nextTick(() => {
                this.reset();
            }, 500);
        },
        save() {
            switch (this.type) {
                case 'ADD':
                    return this.CREATE_GRADING_TABLE(this.$data)
                        .then((response) =>
                            this.UPDATE_COURSE_GRADING_TABLE({
                                course: this.getSelectedAdminCourse,
                                gradingTables: [...this.getCourseGradingTables, response]
                            })
                        )
                        .then(() => this.close());
                case 'UPDATE':
                    return this.UPDATE_GRADING_TABLE(this.$data).then(() => this.close());
            }
        },
        rules(type) {
            switch (type) {
                case 'notNegative':
                    return (v) => {
                        if (v !== undefined && v !== null) {
                            const toNumber = Number(v);
                            return toNumber >= 0 || this.$t('Bitte geben Sie eine Zahl größer oder gleich 0 an');
                        }
                    };
                case 'notEmpty':
                    return (v) => !!v || this.$t('Dieses Feld darf nicht leer sein');
            }
        }
    }
};
</script>
