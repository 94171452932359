<template>
    <v-card class="fill-height">
        <Toolbar :toolbarTitle="$t('Zertifikate')" :showDrawer="true" :tooltipMessage="tooltipMessage" />

        <v-card-text>
            <CertificateList
                title="Zertifikate"
                :showAction="false"
                :sortArray="taskSort"
                :showOptions="true"
                :showAppend="true"
                :itemKeys="itemKeys"
                :items="getUserCompleteTaskList"
                :filterFunctions="filterTaskComputed"
            >
                <template v-slot:headerPrepend>
                    <v-layout>
                        <v-select
                            color="accent"
                            prepend-icon="mdi-filter-variant"
                            attach
                            return-object
                            persistent-hint
                            v-model="selectedFilterMethods"
                            item-value="name"
                            :items="filterMethods"
                        >
                            <template v-slot:item="{ item }">
                                <span>{{ $t(item.name) }}</span>
                            </template>
                            <template v-slot:selection="{ item }">
                                <span>{{ $t(item.name) }}</span>
                            </template>
                        </v-select>
                    </v-layout>

                    <DatePicker type="Year" v-if="showYear" :label1="$t('Jahr')" :startDateText="yearMonth" @pickerStartDateEmit="pickerStartDateEmit" />
                    <DatePicker type="Month" v-if="showMonth" :label1="$t('Monat')" :startDateText="yearMonth" @pickerStartDateEmit="pickerStartDateEmit" />
                    <DatePicker
                        type="Range"
                        v-if="showDate"
                        :label1="$t('Anfangsdatum')"
                        :label2="$t('Enddatum')"
                        :startDateText="startDateRange"
                        :endDateText="endDateRange"
                        @pickerStartDateEmit="startDateRangeEmit"
                        @pickerEndDateEmit="endDateRangeEmit"
                    />
                </template>

                <template v-slot:append="items">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" icon @click="downloadCertificate(items.append)">
                                <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('Zertifikat Herunterladen') }}</span>
                    </v-tooltip>
                </template>
            </CertificateList>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Toolbar from '../../Components/Generic/Toolbar';
import CertificateList from '../../Components/Generic/List/AdvancedList';
import TaskSort from '../../Components/UserApp/Sort/TaskSort';
import DatePicker from '../../Components/Generic/PickerDate/PickerDate';
import moment from 'moment';
import CertificateFilter from '../../Components/UserApp/Sort/CertificateFilter';
import { saveAs } from 'file-saver';

export default {
    components: {
        Toolbar,
        CertificateList,
        DatePicker
    },
    data: () => ({
        taskSort: TaskSort,
        tooltipMessage: '',
        itemKeys: ['name', 'taskCompletedDate'],
        yearMonth: moment().format('YYYY-MM-DD'),
        startDateRange: moment().format('YYYY-MM-DD'),
        endDateRange: moment().format('YYYY-MM-DD'),
        selectedFilterMethods: CertificateFilter.filterMethods[0],
        filterMethods: CertificateFilter.filterMethods,
        showYear: false,
        showMonth: false,
        showDate: false
    }),
    watch: {
        selectedFilterMethods(val) {
            switch (val.type) {
                case 'ALL':
                    this.showYear = false;
                    this.showMonth = false;
                    this.showDate = false;
                    break;
                case 'YEAR':
                    this.showYear = true;
                    this.showMonth = false;
                    this.showDate = false;
                    break;
                case 'MONTH':
                    this.showYear = false;
                    this.showMonth = true;
                    this.showDate = false;
                    break;
                case 'DATE':
                    this.showYear = false;
                    this.showMonth = false;
                    this.showDate = true;
                    break;
            }
        }
    },
    computed: {
        ...mapGetters('UserApp', ['getUserCertificate', 'getUserCompleteTaskList']),
        filterTaskComputed() {
            if (this.selectedFilterMethods.type != 'DATE')
                return [
                    this.selectedFilterMethods.filter.bind({
                        date: this.yearMonth
                    })
                ];
            else
                return [
                    this.selectedFilterMethods.filter.bind({
                        date: {
                            startDate: this.startDateRange,
                            endDate: this.endDateRange
                        }
                    })
                ];
        }
    },
    methods: {
        ...mapActions('UserApp', ['GET_USERCERTIFICATE_BY_ID', 'GET_COMPLETE_USERTASK_LIST', 'GET_PASSED_USERTASK_LIST']),
        downloadCertificate(task) {
            this.GET_USERCERTIFICATE_BY_ID(task.id).then((response) => {
                saveAs(response, `${task.name}_${task.startDate}_${task.endDate}_${this.getLoggedInAdmin.email}`.replace(/[^\w\s-]/gi, '_'));
            });
        },
        getCompletedTask() {
            this.GET_PASSED_USERTASK_LIST().then(() => {});
        },
        pickerStartDateEmit(val) {
            if (val.length > 7) this.yearMonth = val;
            else this.yearMonth = val + '-01';
            this.yearMonth = val;
        },
        startDateRangeEmit(val) {
            this.startDateRange = val;
        },
        endDateRangeEmit(val) {
            this.endDateRange = val;
        }
    },
    mounted() {
        this.getCompletedTask();
    }
};
</script>
