import axios from '../../../plugins/Axios/axios';

const state = {
    forums: [],
    threads: [],
    answers: [],
    selectedGroup: {},
    selectedForum: {},
    selectedThread: {},
    selectedAnswer: {},
    forumPath: []
};

const getters = {
    getForums() {
        return state.forums;
    },
    getThreads() {
        return state.threads;
    },
    getAnswers() {
        return state.answers;
    },
    getSelectedGroup() {
        return state.selectedGroup;
    },
    getSelectedForum() {
        return state.selectedForum;
    },
    getSelectedThread() {
        return state.selectedThread;
    },
    getSelectedAnswer() {
        return state.selectedAnswer;
    },
    getForumPath() {
        return state.forumPath;
    }
};

const actions = {
    REMOVE_FORUM_PATH({ commit }, data) {
        commit('removeForumPath', data);
    },
    ADD_FORUM_PATH({ commit }, data) {
        commit('addForumPath', data);
    },
    SET_SELECTED_GROUP({ commit }, group) {
        commit('updateSelectedGroup', group);
    },
    SET_SELECTED_FORUM({ commit }, forum) {
        commit('updateSelectedForum', forum);
    },
    SET_SELECTED_THREAD({ commit }, thread) {
        commit('updateSelectedThread', thread);
    },
    SET_SELECTED_ANSWER({ commit }, answer) {
        commit('updateSelectedAnswer', answer);
    },
    GET_FORUMS({ commit }, course) {
        const { id: courseId } = course;
        return axios
            .get(`/courselearning/courseinstances/${courseId}/forums`)
            .then((response) => response.data.data)
            .then((response) => commit('updateForums', response));
    },
    CREATE_FORUM({ commit }, { course: { id: courseId }, forum }) {
        return axios
            .post(`/courselearning/courseinstances/${courseId}/forums`, forum)
            .then((response) => response.data.data)
            .then((response) => commit('updateForums', response));
    },
    UPDATE_FORUM({ commit }, { course: { id: courseId }, forum }) {
        const { id: forumId } = forum;
        return axios
            .patch(`/courselearning/courseinstances/${courseId}/forums/${forumId}`, forum)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateForums', response);
                commit('updateSelectedForum', response);
            });
    },
    DELETE_FORUM({ commit }, { course: { id: courseId }, forum }) {
        const { id: forumId } = forum;
        return axios
            .delete(`/courselearning/courseinstances/${courseId}/forums/${forumId}`)
            .then((response) => response.data.data)
            .then(() => commit('deleteForum', forum));
    },
    GET_THREADS({ commit }, { course: { id: courseId }, forum }) {
        const { id: forumId } = forum;
        return axios
            .get(`/courselearning/courseinstances/${courseId}/forums/${forumId}/threads`)
            .then((response) => response.data.data)
            .then((response) => commit('updateThreads', response));
    },
    CREATE_THREAD({ commit }, { course: { id: courseId }, forum: { id: forumId }, thread }) {
        return axios
            .post(`/courselearning/courseinstances/${courseId}/forums/${forumId}/threads`, thread)
            .then((response) => response.data.data)
            .then((response) => commit('updateThreads', response));
    },
    UPDATE_THREAD({ commit }, { course: { id: courseId }, forum: { id: forumId }, thread }) {
        const { id: threadId } = thread;
        return axios
            .patch(`/courselearning/courseinstances/${courseId}/forums/${forumId}/threads/${threadId}`, thread)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateThreads', response);
                commit('updateSelectedThread', response);
            });
    },
    DELETE_THREAD({ commit }, { course: { id: courseId }, forum: { id: forumId }, thread }) {
        const { id: threadId } = thread;
        return axios
            .delete(`/courselearning/courseinstances/${courseId}/forums/${forumId}/threads/${threadId}`)
            .then((response) => response.data.data)
            .then(() => commit('deleteThread', thread));
    },
    GET_ANSWERS({ commit }, { course: { id: courseId }, forum: { id: forumId }, thread: { id: threadId } }) {
        return axios
            .get(`/courselearning/courseinstances/${courseId}/forums/${forumId}/threads/${threadId}/answers`)
            .then((response) => response.data.data)
            .then((response) => commit('updateAnswers', response));
    },
    CREATE_ANSWER({ commit }, { course: { id: courseId }, forum: { id: forumId }, thread: { id: threadId }, answer }) {
        return axios
            .post(`/courselearning/courseinstances/${courseId}/forums/${forumId}/threads/${threadId}/answers`, answer)
            .then((response) => response.data.data)
            .then((response) => commit('updateAnswers', response));
    },
    UPDATE_ANSWER({ commit }, { course: { id: courseId }, forum: { id: forumId }, thread: { id: threadId }, answer }) {
        const { id: answerId } = answer;
        return axios
            .patch(`/courselearning/courseinstances/${courseId}/forums/${forumId}/threads/${threadId}/answers/${answerId}`, answer)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateAnswers', response);
                /* 	commit('updateSelectedAnswer', response); */
            });
    },
    DELETE_ANSWER({ commit }, { course: { id: courseId }, forum: { id: forumId }, thread: { id: threadId }, answer }) {
        const { id: answerId } = answer;
        return axios
            .delete(`/courselearning/courseinstances/${courseId}/forums/${forumId}/threads/${threadId}/answers/${answerId}`)
            .then((response) => response.data.data)
            .then(() => commit('deleteAnswer', answer));
    }
};

const mutations = {
    clear: (state) => {
        state.forums = [];
        state.threads = [];
        state.answers = [];
        state.selectedAnswer = {};
        state.selectedForum = {};
        state.selectedGroup = {};
        state.selectedThread = {};
    },
    updateForums: (state, response) => {
        if (Array.isArray(response)) {
            state.forums = response;
        } else {
            if (state.forums.map((forum) => forum.id).includes(response.id)) {
                state.forums = state.forums.map((forum) => {
                    if (forum.id === response.id) {
                        return response;
                    }
                    return forum;
                });
            } else {
                state.forums.push(response);
            }
        }
    },
    updateThreads: (state, response) => {
        if (Array.isArray(response)) {
            state.threads = response;
        } else {
            if (state.threads.map((thread) => thread.id).includes(response.id)) {
                state.threads = state.threads.map((thread) => {
                    if (thread.id === response.id) {
                        return response;
                    }
                    return thread;
                });
            } else {
                state.threads.push(response);
            }
        }
    },
    updateAnswers: (state, response) => {
        if (Array.isArray(response)) {
            state.answers = response;
        } else {
            if (state.answers.map((answer) => answer.id).includes(response.id)) {
                state.answers = state.answers.map((answer) => {
                    if (answer.id === response.id) {
                        return response;
                    }
                    return answer;
                });
            } else {
                state.answers.push(response);
            }
        }
    },
    deleteForum: (state, forum) => {
        const index = state.forums.map((forum) => forum.id).indexOf(forum.id);
        if (index !== -1) {
            state.forums.splice(index, 1);
        }
    },
    deleteThread: (state, thread) => {
        const index = state.threads.map((thread) => thread.id).indexOf(thread.id);
        if (index !== -1) {
            state.threads.splice(index, 1);
        }
    },
    deleteAnswer: (state, answer) => {
        const index = state.answers.map((answer) => answer.id).indexOf(answer.id);
        if (index !== -1) {
            state.answers.splice(index, 1);
        }
    },
    updateSelectedGroup: (state, group) => (state.selectedGroup = group),
    updateSelectedForum: (state, forum) => (state.selectedForum = forum),
    updateSelectedThread: (state, thread) => (state.selectedThread = thread),
    updateSelectedAnswers: (state, answer) => (state.selectedAnswer = answer),
    addForumPath: (state, data) => {
        if (state.forumPath.map((forum) => forum.id).indexOf(data.id) === -1) {
            state.forumPath.push(data);
        }
    },
    removeForumPath: (state, data) => {
        const find = state.forumPath.map((forum) => forum.id).findIndex((forum) => forum === data.id);
        if (find !== -1) {
            state.forumPath = state.forumPath.slice(0, find);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
