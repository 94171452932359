<template>
    <v-card text>
        <v-subheader>{{ $t('Katalog') }}</v-subheader>
        <v-form v-model="valid">
            <v-container grid-list-md>
                <v-layout wrap>
                    <v-flex xs12>
                        <v-text-field v-model="name" :label="$t('Name')" :rules="[notEmpty]" />
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field v-model="description" :label="$t('Beschreibung')" />
                    </v-flex>
                </v-layout>
            </v-container>
        </v-form>
        <v-subheader>{{ $t('Zielort') }}</v-subheader>
        <CatalogSelectorTree v-model="selectedCatalog" :disabledCatalogs="[this.getParent(this.value)]" />
        <v-card-actions>
            <v-btn block color="success" :disabled="!Object.keys(selectedCatalog).length > 0 && !valid" @click="submit">
                {{ $t('Einfügen') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import CatalogSelectorTree from './SingleCatalogSubgroupSelectorTree';
import AuthoringToolMixin from '../Mixins/AuthoringtoolMixin';

export default {
    name: 'catalog-move-view',
    components: { CatalogSelectorTree },
    mixins: [AuthoringToolMixin],
    data: () => ({
        name: '',
        description: '',
        valid: false,
        selectedCatalog: {}
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        knowledgeUnit: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        serialize(catalogSubgroup) {
            return {
                catalogGroup: this.getParent(catalogSubgroup),
                catalogSubgroup,
                name: this.name,
                description: this.description
            };
        },

        submit() {
            this.MOVE_CATALOG(this.serialize(this.selectedCatalog)).then(() => {
                this.$emit('openTree', this.calculateTreePath(this.selectedCatalog));
                this.selectedCatalog = {};
                this.$emit('switchView', { component: '', data: {}, type: '' });
            });
        },
        notEmpty(value) {
            return (!!value && value.trim().length > 0) || this.$t('Dieses Feld darf nicht leer sein');
        }
    },
    activated() {
        this.name = this.value.name;
        this.description = this.value.description;
        this.selectedCatalog = {};
    }
};
</script>
