import FileManager from '../..//views/FileManager/FileManager';
import StaticFileManager from '../../views/StaticFileManager/StaticFileManager';
import { superAdmin, admin, user, root, sales } from '../Roles';

export default [
    /**
     * FileManager
     * @description
     */
    {
        path: '/FileManager',
        name: 'filemanager',
        component: FileManager,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin, admin, user],
            navigation: {
                type: { name: 'SingleGroup', groupId: 'Lernmanagement' },
                priority: 0,
                icon: 'mdi-folder-cog',
                name: 'Dateimanager',
                navigationKey: 'FILE_MANAGER'
            }
        }
    },
    {
        path: '/staticFileManager',
        name: 'staticFilemanager',
        component: StaticFileManager,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [root, sales],
            navigation: {
                type: { name: 'SingleGroup', groupId: 'Lernmanagement' },
                priority: 0,
                icon: 'mdi-folder-cog',
                name: 'Dateimanager',
                navigationKey: 'FILE_MANAGER'
            }
        }
    }
];
