<template>
    <v-card text>
        <v-subheader>{{ $t('Content') }}</v-subheader>
        <v-list two-line>
            <v-list-item>
                <v-list-item-action>
                    <v-icon :color="iconColor()">mdi-folder-outline</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ value.name }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-subheader>{{ $t('Zielort') }}</v-subheader>
            <KnowledgeContentFolderSelector :tree="tree" v-model="selectedFolder" :disabledFolders="contentFolder" />
            <v-card-actions>
                <v-btn block color="success" @click="submit" :disabled="!isSelected">
                    {{ $t('Ordner verschieben') }}
                </v-btn>
            </v-card-actions>
        </v-list>
    </v-card>
</template>
<script>
import KnowledgeContentFolderSelector from './KnowledgeContentFolderSelector';

import KnowledgeContentMixin from '../Mixins/KnowledgeContentMixin';

export default {
    name: 'knowledge-content-folder-move-view',
    components: { KnowledgeContentFolderSelector },
    mixins: [KnowledgeContentMixin],
    data: () => ({
        selectedFolder: {}
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        onSubmit: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        isSelected() {
            return Object.keys(this.selectedFolder).length > 0;
        },
        tree() {
            return [{ id: '', name: this.$t('Stammverzeichnis') }, ...this.getTree];
        },
        contentFolder() {
            const flatten = (nodes) => {
                return nodes.reduce((acc, curr) => {
                    if (curr.children && curr.children.length > 0) {
                        return [...acc, curr, ...flatten(curr.children)];
                    }
                    return [...acc, curr];
                }, []);
            };
            return this.value && this.value.children && this.value.children.length > 0 ? flatten(this.value.children) : [];
        }
    },
    methods: {
        iconColor() {
            if (this.value && this.value.state && this.value.state.stateName) {
                switch (this.value.state.stateName) {
                    case 'APPROVED':
                        return 'success';
                    case 'PENDING':
                        return 'warning';
                    case 'DECLINED':
                        return 'error';
                }
                return 'info';
            }
            return '';
        },
        serialize() {
            return {
                source: this.value,
                target: this.selectedFolder
            };
        },
        submit() {
            this.onSubmit()(this.serialize());
        }
    },
    activated() {
        this.selectedFolder = {};
    }
};
</script>
