<template>
    <div>
        <v-tabs v-model="tabs">
            <v-tab key="SETTINGS">{{ $t('Einstellungen') }}</v-tab>
            <v-tab key="EMAILNOTIFICATIONS">
                {{ $t('Benachrichtigungsoptionen') }}
            </v-tab>
        </v-tabs>
        <div class="dialogScrollLimit">
            <v-tabs-items v-model="tabs">
                <v-tab-item key="SETTINGS">
                    <v-container>
                        <v-form v-model="validData">
                            <v-text-field
                                v-model="userData.email"
                                :label="$t('E-Mail')"
                                color="accent"
                                browser-aoutocomlete="new-password"
                                type="email"
                                disabled
                                prepend-icon="mdi-email"
                            />
                            <v-text-field
                                v-model="userData.displayName"
                                :label="$t('Angezeigter Name')"
                                color="accent"
                                browser-aoutocomlete="new-password"
                                required
                                prepend-icon="mdi-account"
                            />
                            <v-text-field
                                v-model="userData.firstName"
                                :label="$t('Vorname')"
                                color="accent"
                                browser-aoutocomlete="new-password"
                                prepend-icon="mdi-account"
                            />
                            <v-text-field
                                v-model="userData.lastName"
                                :label="$t('Nachname')"
                                color="accent"
                                browser-aoutocomlete="new-password"
                                prepend-icon="mdi-account"
                            />
                            <v-text-field
                                v-model="userData.mobileNo"
                                :label="$t('Handynummer')"
                                color="accent"
                                browser-aoutocomlete="new-password"
                                prepend-icon="mdi-phone"
                            />
                        </v-form>
                        <v-card class="d-flex align-content-end flex-wrap">
                            <v-btn color="amber darken-2 white--text" @click="update" :disabled="!validData" block>Edit</v-btn>
                        </v-card>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="EMAILNOTIFICATIONS">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-select
                                    :items="frequencyOptions"
                                    v-model="computedNotificationFrequency"
                                    :hint="getNotificationFrequencyHint"
                                    persistent-hint
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-data-iterator :items="options.opts" hide-default-footer>
                                    <template #default="{ items }">
                                        <v-row no-gutters>
                                            <v-col :cols="12" v-for="(item, index) in items" :key="item.id">
                                                <v-list-item>
                                                    <v-list-item-action>
                                                        <v-switch
                                                            :input-value="item.userWants"
                                                            :disabled="!item.customerAllowsUnsubscription"
                                                            @change="handleOptionClick(index, item)"
                                                        ></v-switch>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            {{ $t(item.templateName) }}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-data-iterator>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </div>
</template>

<script>
import UserViewsMixins from '../../Users/Mixins/UserViewsMixins';
export default {
    mixins: [UserViewsMixins],
    data: () => ({
        tabs: 0,
        options: {
            notificationFrequency: 'IMMEDIATELY',
            opts: []
        },
        showProfile: false,
        validData: false,
        userData: {}
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        getOptData(value) {
            this.options = { ...value };
        },
        value: {
            immediate: true,
            deep: true,
            handler(val) {
                this.userData = { ...val };
            }
        }
    },
    computed: {
        frequencyOptions() {
            return ['IMMEDIATELY', 'HOURLY', 'DAILY'];
        },
        getNotificationFrequencyHint() {
            switch (this.options.notificationFrequency) {
                case 'IMMEDIATELY':
                    return this.$t('Die E-Mails werden sofort nach einer bestimmten Aktion aus der Liste durchgeführt wurde.');
                case 'HOURLY':
                    return this.$t('Die E-Mails werden jede Stunde kommen, nachdem die eine oder andere Aktion aus der Liste durchgeführt wurde.');
                case 'DAILY':
                    return this.$t('Die E-Mails werden jeden Tag kommen, nachdem die eine oder andere Aktion aus der Liste durchgeführt wurde.');
            }
            return '';
        },
        computedNotificationFrequency: {
            get() {
                return this.options && this.options.notificationFrequency ? this.options.notificationFrequency : '';
            },
            set(value) {
                this.options.notificationFrequency = value;
                this.SET_USER_MAIL_OPTIONS(this.options);
            }
        }
    },
    methods: {
        editProfile() {
            this.showProfile = true;
        },
        onImageSelected(e) {
            const files = e.target.files;
            if (files[0] !== undefined) {
                this.image.imageName = files[0].name;
                if (this.image.imageName.lastIndexOf('.') <= 0) {
                    return;
                }
                this.image.imageBinary = files[0];
                this.uploadImage(this.image);
            } else {
                this.image.imageName = '';
                this.image.imageBinary = '';
            }
        },
        cancel() {
            this.showProfile = false;
        },
        update() {
            if (this.userData.role == 'USER') {
                this.UPDATE_USER_PROFILE(this.userData).then((response) => this.UPDATE_LOGGED_IN_ADMIN(response));
            } else if (this.userData.role == 'ADMIN' || this.userData.role == 'SUPER_ADMIN') {
                this.UPDATE_ADMIN_PROFILE(this.userData).then((response) => this.UPDATE_LOGGED_IN_ADMIN(response));
            }
        },
        handleOptionClick(index, item) {
            this.options.opts[index].allow = !this.options.opts[index].allow;
            this.SET_USER_MAIL_OPTIONS(this.options);
        }
    },
    mounted() {
        this.GET_USER_MAIL_OPTIONS();
    }
};
</script>
