<template>
    <v-card flat style="overflow: auto">
        <v-toolbar flat dense color="transparent">
            <v-spacer />
            <v-toolbar-items>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="showEfforts" v-on="on" medium icon color="success" @click.stop :disabled="!efforts">
                            <v-icon medium>mdi-clipboard-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Leistungen') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="createGroup" v-on="on" medium icon color="success" @click.stop :disabled="!permissions.create">
                            <v-icon medium>mdi-account-multiple-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Gruppe erstellen') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="createUser" v-on="on" medium icon color="success" @click.stop :disabled="!permissions.create">
                            <v-icon medium>mdi-account-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Nutzer erstellen') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="updateUser" v-on="on" medium icon color="warning" @click.stop :disabled="!permissions.update">
                            <v-icon medium>mdi-account-edit</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Nutzer bearbeiten') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="deleteUser" v-on="on" medium icon color="error" @click.stop :disabled="!permissions.delete">
                            <v-icon medium>mdi-account-remove</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Nutzer löschen') }}</span>
                </v-tooltip>
            </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-tabs v-model="tab" show-arrows>
            <v-tab key="DETAILS">{{ $t('Details') }}</v-tab>
            <v-tab key="ALLOCATION">{{ $t('Zuweisungen') }}</v-tab>
            <v-tab key="OPTIONAL">{{ $t('Optionale Angaben') }}</v-tab>
        </v-tabs>
        <v-card-text>
            <v-container>
                <v-tabs-items v-model="tab">
                    <v-tab-item key="DETAILS">
                        <v-row wrap>
                            <v-col :cols="12">
                                <v-text-field
                                    :color="value.active ? 'success' : 'error'"
                                    :prepend-inner-icon="value.active ? 'mdi-check' : 'mdi-close'"
                                    :label="$t('Aktiv')"
                                    hide-details
                                    :value="value.active ? $t('Ja') : $t('Nein')"
                                    readonly
                                />
                            </v-col>
                            <v-col :cols="12">
                                <v-text-field
                                    prepend-inner-icon="mdi-account-off"
                                    :label="$t('Inaktiv ab')"
                                    hide-details
                                    :value="value.disableFrom ? value.disableFrom : $t('Keine Angabe')"
                                    readonly
                                />
                            </v-col>
                            <v-col :cols="12">
                                <v-text-field
                                    color="success"
                                    prepend-inner-icon="mdi-account"
                                    :label="$t('Name')"
                                    hide-details
                                    :value="value.lastName"
                                    readonly
                                />
                            </v-col>
                            <v-col :cols="12">
                                <v-text-field
                                    color="success"
                                    prepend-inner-icon="mdi-account"
                                    :label="$t('Vorname')"
                                    hide-details
                                    :value="value.firstName"
                                    readonly
                                />
                            </v-col>
                            <v-col :cols="12">
                                <v-text-field
                                    color="success"
                                    prepend-inner-icon="mdi-account"
                                    :label="$t('Anzeigename')"
                                    hide-details
                                    :value="value.displayName"
                                    readonly
                                />
                            </v-col>
                            <v-col :cols="12">
                                <v-text-field color="success" prepend-inner-icon="mdi-email" :label="$t('E-Mail')" hide-details :value="value.email" readonly />
                            </v-col>
                            <v-col :cols="12">
                                <v-text-field
                                    color="success"
                                    prepend-inner-icon="mdi-account"
                                    :label="$t('Rolle')"
                                    hide-details
                                    :value="$t(value.role)"
                                    readonly
                                />
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item key="ALLOCATION">
                        <v-row>
                            <v-col :cols="12">
                                <v-input :label="$t('Gruppen')" hide-details>
                                    <v-row>
                                        <v-col cols="auto" v-for="group in groups" :key="group.id">
                                            <v-chip class="no-highlight" @click.prevent @mousedown.prevent>
                                                <v-avatar>
                                                    <v-btn icon @click="routeToGroup(group)">
                                                        <v-icon>mdi-account-multiple</v-icon>
                                                    </v-btn>
                                                </v-avatar>
                                                {{ group.name }}
                                            </v-chip>
                                        </v-col>
                                        <v-col cols="auto" v-if="groups.length === 0">
                                            <v-chip class="no-highlight" @click.prevent @mousedown.prevent>
                                                {{ $t('Keine') }}
                                            </v-chip>
                                        </v-col>
                                    </v-row>
                                </v-input>
                            </v-col>
                            <v-col :cols="12" v-if="value.role === 'ADMIN'">
                                <v-input :label="$t('Profile')" hide-details>
                                    <v-container pa-0 ma-0 fluid grid-list-md>
                                        <v-row wrap>
                                            <v-col cols="auto" v-for="profile in profiles" :key="profile.id">
                                                <v-menu offset-y :max-height="400">
                                                    <template v-slot:activator="{ on }">
                                                        <v-chip v-on="on">
                                                            <span class="no-highlight pointer">{{ profile.name }}</span>
                                                        </v-chip>
                                                    </template>
                                                    <v-list>
                                                        <v-list-item v-for="permission in profile.permissions" :key="permission.permissionCode">
                                                            <v-list-item-action>
                                                                <v-icon>{{ permissionIcon(permission) }}</v-icon>
                                                            </v-list-item-action>
                                                            <v-list-item-content>
                                                                <v-list-item-title>{{
                                                                    $t(permission.groupCode.replace(/_/g, ' ').toLowerCase())
                                                                }}</v-list-item-title>
                                                                <v-list-item-subtitle>{{ $t(permission.description) }}</v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </v-col>
                                            <v-col cols="auto" align-self-center justify-self-center v-if="profiles.length === 0">
                                                <v-chip class="no-highlight" @click.prevent @mousedown.prevent>
                                                    {{ $t('Keine') }}
                                                </v-chip>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-input>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item key="OPTIONAL">
                        <v-row>
                            <v-col :cols="12">
                                <v-text-field
                                    :color="value.houseNo ? 'success' : 'warning'"
                                    prepend-inner-icon="mdi-home"
                                    :label="$t('Hausnummer')"
                                    hide-details
                                    :value="value.houseNo ? value.houseNo : $t('Keine Angabe')"
                                    readonly
                                />
                            </v-col>
                            <v-col :cols="12">
                                <v-text-field
                                    :color="value.city ? 'success' : 'warning'"
                                    prepend-inner-icon="mdi-map-marker"
                                    :label="$t('Stadt')"
                                    hide-details
                                    :value="value.city ? value.city : $t('Keine Angabe')"
                                    readonly
                                />
                            </v-col>
                            <v-col :cols="12">
                                <v-text-field
                                    :color="value.street ? 'success' : 'warning'"
                                    prepend-inner-icon="mdi-map-marker"
                                    :label="$t('Straße')"
                                    hide-details
                                    :value="value.street ? value.street : $t('Keine Angabe')"
                                    readonly
                                />
                            </v-col>
                            <v-col :cols="12">
                                <v-text-field
                                    :color="value.postalcode ? 'success' : 'warning'"
                                    prepend-inner-icon="mdi-map-marker"
                                    :label="$t('Postleitzahl')"
                                    hide-details
                                    :value="value.postalcode ? value.postalcode : $t('Keine Angabe')"
                                    readonly
                                />
                            </v-col>
                            <v-col :cols="12">
                                <v-text-field
                                    :color="value.mobileNo ? 'success' : 'warning'"
                                    prepend-inner-icon="mdi-phone"
                                    :label="$t('Telefon')"
                                    hide-details
                                    :value="value.mobileNo ? value.mobileNo : $t('Keine Angabe')"
                                    readonly
                                />
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import UsersMixin from '../../Mixins/UserMixin';

export default {
    name: 'users-display-view',
    mixins: [UsersMixin],
    data: () => ({
        tab: 0,
        efforts: false
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        permissions: {
            permissions: {
                type: Object,
                default: () => ({
                    add: false,
                    update: false,
                    delete: false,
                    change: false
                })
            }
        }
    },
    watch: {
        value() {
            this.efforts = false;
            if (this.getLoggedInAdmin.role === 'SUPER_ADMIN') {
                this.efforts = true;
            } else {
                if (this.value && this.value.role && this.value.role === 'USER') {
                    this.canViewEfforts();
                }
            }
        }
    },
    computed: {
        profiles() {
            return this.getUsersProfiles(this.value);
        },
        groups() {
            return this.getUsersGroups(this.value);
        }
    },
    methods: {
        canViewEfforts() {
            const parentAndSubgroups = this.getTreeItemPath(this.value).filter((item) => this.isUserOrGroup(item) === 'GROUP');
            Promise.all(
                parentAndSubgroups.map((group) =>
                    this.GET_GROUP_LEADERS(group).then((response) => response.map(({ id }) => id).includes(this.getLoggedInAdmin.id))
                )
            ).then((result) => (this.efforts = result.reduce((acc, curr) => acc || curr, false)));
        },
        permissionIcon({ type }) {
            switch (type) {
                case 'CREATE':
                    return 'mdi-plus';
                case 'UPDATE':
                    return 'mdi-pencil';
                case 'DELETE':
                    return 'mdi-delete';
                case 'GET':
                    return 'mdi-eye';
                case 'MANAGEMENT':
                    return 'mdi-gavel';
                case 'MOVE':
                    return 'mdi-file-move';
                case 'COPY':
                    return 'mdi-content-copy';
            }
            return '';
        },
        routeToGroup(group) {
            this.$emit('input', this.getTreeObject(group));
        },
        showEfforts() {
            this.$emit('switchView', {
                component: 'USER_EFFORTS',
                type: 'UPDATE',
                data: this.value
            });
        },
        createUser() {
            this.$emit('switchView', {
                component: 'CREATE_USER',
                type: 'ADD',
                data: {}
            });
        },
        updateUser() {
            this.$emit('switchView', {
                component: 'UPDATE_USER',
                type: 'UPDATE',
                data: {}
            });
        },
        deleteUser() {
            this.$emit('switchView', {
                component: 'DELETE_USER',
                type: 'DELETE',
                data: {}
            });
        },
        createGroup() {
            this.$emit('switchView', {
                component: 'CREATE_GROUP',
                type: 'ADD',
                data: {}
            });
        }
    },
    activated() {
        this.efforts = false;
        if (this.getLoggedInAdmin.role === 'SUPER_ADMIN') {
            this.efforts = true;
        } else {
            if (this.value && this.value.role && this.value.role === 'USER') {
                let parent = this.getParent(this.value);
                if (parent) {
                    this.canViewEfforts();
                }
            }
        }
    }
};
</script>
