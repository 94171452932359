<template>
    <v-container>
        <v-row>
            <v-col :cols="12">
                <v-text-field
                    :color="value.active ? 'success' : 'error'"
                    :prepend-inner-icon="value.active ? 'mdi-check' : 'mdi-close'"
                    :label="$t('Aktiv')"
                    hide-details
                    :value="value.active ? $t('Ja') : $t('Nein')"
                    readonly
                />
            </v-col>
            <v-col :cols="12">
                <v-text-field
                    :color="value.isOnline ? 'success' : 'error'"
                    :prepend-inner-icon="value.isOnline ? 'mdi-check' : 'mdi-clock-outline'"
                    :label="value.isOnline ? $t('Online') : $t('Zuletzt online')"
                    hide-details
                    :value="offlineAtText"
                    readonly
                />
            </v-col>
            <v-col :cols="12">
                <v-text-field :color="'primary'" :prepend-inner-icon="'mdi-account'" :label="$t('Vorname')" hide-details :value="value.firstName" readonly />
            </v-col>
            <v-col :cols="12">
                <v-text-field :color="'primary'" :prepend-inner-icon="'mdi-account'" :label="$t('Nachname')" hide-details :value="value.lastName" readonly />
            </v-col>
            <v-col :cols="12">
                <v-text-field :color="'primary'" :prepend-inner-icon="'mdi-email'" :label="$t('Email')" hide-details :value="value.email" readonly />
            </v-col>
            <v-col :cols="tabValue == 'WL2' ? 12 : 11">
                <v-text-field :color="'primary'" :prepend-inner-icon="'mdi-video'" :label="$t('WWC-Rolle')" hide-details :value="value.wwc.role" readonly />
            </v-col>
            <v-col :cols="1" v-if="tabValue == 'WWC'">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon color="warning" @click.stop @click="editWWCRole(value.id)">
                            <v-icon medium>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('WWC Rolle ändern') }}</span>
                </v-tooltip>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: 'admin-info',
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        tabValue: {
            type: String,
            default: 'WL2'
        }
    },
    computed: {
        computedSelectedValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        offlineAtText() {
            if (!this.computedSelectedValue) {
                return this.$t('');
            }
            if (this.computedSelectedValue.isOnline) {
                return this.$t('Ja');
            }
            if (this.computedSelectedValue.offlineAt == '') {
                return this.$t('Noch nicht online gewesen');
            }
            return this.computedSelectedValue.offlineAt;
        }
    },
    methods: {
        editWWCRole(id) {
            this.$emit('editWWCRole', id);
        }
    }
};
</script>
