import Vue from 'vue';
import axios from '../../plugins/Axios/axios';

const state = {
    adminList: [],
    deletedAdminList: [],
    selectedAdmin: {
        itemType: 'ADMIN',
        itemTitle: 'Admin',
        path: '/User',
        data: {}
    },
    selectedAdminGroupList: [],
    selectedProfileList: [],
    adminStore: {}
};

// getters
const getters = {
    getAdminList(state) {
        return state.adminList;
    },
    getSelectedAdmin(state) {
        return state.selectedAdmin;
    },
    getDeletedAdminList(state) {
        return state.deletedAdminList;
    },
    getSelectedAdminGroupList(state) {
        return state.selectedAdminGroupList;
    },
    getSelectedProfileList(state) {
        return state.selectedProfileList;
    },
    getAdminStore(state) {
        return state.adminStore;
    }
};

// actions
const actions = {
    GET_ADMIN_ADMINGROUPS({ commit }, { id: adminId }) {
        return axios
            .get(`/admins/${adminId}/admingroups`)
            .then((response) => response.data.data)
            .then((response) => {
                return response;
            });
    },
    GET_ADMIN_GROUPS_BY_ADMIN({ commit }) {
        return axios.get(`/admingroups/members`).then((response) => response.data.data.groups);
    },
    ADD_ADMIN({ commit, dispatch }, adminObject) {
        return axios
            .post('admins', adminObject)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateAdminList', response);
                dispatch('AdminGroupsOp/GET_ADMIN_GROUP_TREE', null, {
                    root: true
                });
                return response;
            });
    },
    ADD_ADMIN_TO_ADMINGROUP_LIST({ commit }, request) {
        return axios.post(`/admins/${request.adminId}/admingroups`, request.data).then((response) => response.data.data);
    },
    DELETE_ADMIN_BY_ID({ commit, dispatch }, adminId) {
        return axios.delete(`admins/${adminId}`).then(() => {
            commit('deleteAdmin', adminId);
            dispatch('AdminGroupsOp/REMOVE_ADMIN_FROM_GROUP_MAP', adminId, {
                root: true
            });
            dispatch('AdminGroupsOp/GET_ADMIN_GROUP_TREE', null, {
                root: true
            });
        });
    },
    GET_ADMIN_LIST({ commit }) {
        return axios
            .get(`admins`)
            .then((response) => response.data.data.admins)
            .then((response) => {
                commit('updateAdminList', response);
                return response.flat(1);
            });
    },
    GET_DELETED_ADMIN_LIST({ commit }) {
        return axios
            .get('admins?sort=createdAt,asc&deleted=true')
            .then((response) => response.data.data.admins)
            .then((response) => {
                commit('updateDeletedAdmin', response);
                return response;
            });
    },
    GET_ADMIN_BY_ID({ commit }, adminId) {
        return axios.get(`/admins/${adminId.toString()}`).then((response) => response.data.data);
    },
    UPDATE_ADMIN({ commit, dispatch }, request) {
        return axios
            .patch(`/admins/${request.id}`, request)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateAdminList', response);
                dispatch('AdminGroupsOp/GET_ADMIN_GROUP_TREE', null, {
                    root: true
                });
                return response;
            });
    },
    UPDATE_ADMIN_PROFILE({ commit }, request) {
        const { id: adminId, ...admin } = request;
        return axios.patch(`/admins/${adminId}/adminprofile`, admin).then((response) => response.data.data);
    },
    UPDATE_ROLE_ADMINTOUSER({ commit, dispatch }, request) {
        return axios.post(`/role`, request).then(() => {
            request.admins.forEach((adminId) => {
                dispatch('UserPrototype/REMOVE_USER_FROM_MAP', { id: adminId }, { root: true });
                commit('AdminGroupsOp/removeAdminFromGroupMap', adminId, {
                    root: true
                });
                commit('deleteAdmin', adminId);
            });
            commit('updateSelectedAdmin', {});
        });
    },
    RECOVER_ADMIN({ commit }, request) {
        return axios
            .patch(`/admins/${request.id}/recover`, request)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateAdminList', response);
                return response;
            });
    },
    UPDATE_ADMIN_PASSWORD({ commit }, request) {
        return axios.patch(`/admins/${request.id}/password`, request.data).then((response) => response.data.data);
    },
    SET_PERMISSION_PROFILES_TO_USER({ dispatch }, { adminId, ...request }) {
        return axios.post(`/admins/${adminId}/permissionprofiles`, request).then(() => {
            return dispatch('GET_PERMISSION_PROFILES_FROM_USER', adminId);
        });
    },
    GET_PERMISSION_PROFILES_FROM_USER({ commit }, adminId) {
        return axios
            .get(`/admins/${adminId}/permissionprofiles`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('UserPrototype/updateAssignedProfiles', { [adminId]: response }, { root: true });
                return response;
            });
    },
    SELECT_ADMIN({ commit }, selectedAdmin) {
        commit('updateSelectedAdmin', selectedAdmin);
    },
    RESET_SELECTED_ADMIN({ commit }) {
        commit('updateSelectedAdmin', {});
    },
    SELECT_ADMINGROUP_LIST({ commit }, selectedList) {
        commit('updateSelectedAdminGroupList', selectedList);
    },
    SELECT_PROFILE_LIST({ commit }, selectedList) {
        commit('updateSelectedProfileList', selectedList);
    },
    UPDATE_ADMIN_STORE({ commit }, adminStore) {
        commit('updateAdminStore', adminStore);
    },
    RESET_ADMIN_STORE({ commit }) {
        commit('updateAdminStore', {});
    }
};

// mutations
const mutations = {
    clear: (state) => {
        state.adminList = [];
        state.deletedAdminList = [];
        state.selectedAdmin = {
            itemType: 'ADMIN',
            itemTitle: 'Admin',
            path: '/User',
            data: {}
        };
    },
    updateAdminStore: (state, adminStore) => {
        state.adminStore = adminStore;
    },
    updateSelectedProfileList: (state, selectedList) => {
        state.selectedProfileList = selectedList;
    },
    updateSelectedAdminGroupList: (state, selectedList) => {
        state.selectedAdminGroupList = selectedList;
    },
    updateAdminList: (state, response) => {
        if (Array.isArray(response)) {
            const ids = state.adminList.map(({ id }) => id);
            Vue.set(
                state,
                'adminList',
                response.reduce((acc, curr) => {
                    if (ids.includes(curr.id)) {
                        const index = acc.findIndex(({ id }) => id === curr.id);
                        acc[index] = curr;
                    } else {
                        acc.push(curr);
                    }
                    return acc;
                }, state.adminList)
            );
        } else {
            if (state.selectedAdmin.data.hasOwnProperty('id')) {
                if (state.selectedAdmin.data.id === response.id) {
                    Vue.set(state.selectedAdmin, 'data', response);
                }
            }
            if (state.adminList.map((admin) => admin.id).includes(response.id)) {
                Vue.set(
                    state,
                    'adminList',
                    state.adminList.map((admin) => {
                        if (admin.id === response.id) {
                            return response;
                        }
                        return admin;
                    })
                );
            } else {
                Vue.set(state, 'adminList', [...state.adminList, response]);
            }
        }
    },
    deleteAdmin: (state, admin) => {
        if (typeof admin === 'string') {
            const find = state.adminList.map((ad) => ad.id).indexOf(admin);
            if (find !== -1) {
                state.adminList.splice(find, 1);
            }
            if (state.selectedAdmin.data.hasOwnProperty('id')) {
                if (state.selectedAdmin.data.id === admin) {
                    state.selectedAdmin.data = {};
                }
            }
        }
        if (typeof admin === 'object') {
            const { id: adminId } = admin;
            if (adminId !== undefined && adminId !== null) {
                const find = state.adminList.map((ad) => ad.id).indexOf(adminId);
                if (find !== -1) {
                    state.adminList.splice(find, 1);
                }
            }
            if (state.selectedAdmin.data.hasOwnProperty('id')) {
                if (state.selectedAdmin.data.id === adminId) {
                    state.selectedAdmin.data = {};
                }
            }
        }
    },
    updateSelectedAdmin: (state, selectedAdmin) => {
        state.selectedAdmin.data = selectedAdmin;
    },
    updateDeletedAdmin: (state, deletedAdminList) => {
        state.deletedAdminList = deletedAdminList;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
