import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters('ImageGalleryOperations', ['getSelectedImage', 'getShowSelectedImage']),
        computedShowImageGallery: {
            get() {
                return this.getShowSelectedImage;
            },
            set(value) {
                this.SET_SHOW_SELECTED_IMAGE(value);
            }
        },
        computedImageGallery: {
            get() {
                return this.getSelectedImage.src;
            },
            set(value) {
                this.SET_SELECTED_IMAGE(value);
                this.SET_SHOW_SELECTED_IMAGE(true);
            }
        }
    },
    methods: {
        ...mapActions('ImageGalleryOperations', ['SET_SELECTED_IMAGE', 'SET_SHOW_SELECTED_IMAGE']),
        /**
         * Register this Function on any Container which has the "ck ck-content" class, to trigger the ImageGallery
         * @param {*} event
         */
        imageGalleryClickHandler(event) {
            if (event && event.path && Array.isArray(event.path) && event.path.map((element) => element.className).includes('ck ck-content')) {
                const ckEditorPathIndex = event.path.map((element) => element.className).indexOf('ck ck-content');
                if (ckEditorPathIndex !== -1) {
                    const list = event.path.slice(0, ckEditorPathIndex);
                    if (list.map((element) => element.tagName).includes('FIGURE' && 'IMG')) {
                        const image = list.find((element) => element.tagName === 'IMG');
                        if (image !== undefined && image !== null) {
                            if (image.src !== '') {
                                this.computedImageGallery = image;
                            }
                        }
                    }
                }
            }
        }
    }
};
