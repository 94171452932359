<template>
    <v-select
        color="accent"
        v-model="computedValue"
        :items="languages"
        item-value="alpha2"
        item-text="name"
        :label="$t(label)"
        :hint="$t(hint)"
        persistent-hint
        item-key="alpha2"
        :value-comparator="compare"
    >
        <template v-slot:item="{ item }">
            {{ $t(item.name) }}
        </template>
        <template v-slot:selection="{ item }">
            {{ $t(item.name) }}
        </template>
    </v-select>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'language-selector',
    props: {
        label: {
            type: String,
            default: 'Sprache'
        },
        hint: {
            type: String,
            default: 'Wählen Sie eine Sprache aus'
        },
        value: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapGetters('LanguageOperations', ['getLanguages']),
        languages() {
            return this.getLanguages.languages;
        },
        computedValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        ...mapActions('LanguageOperations', ['GET_AVAILABLE_LANGUAGES']),
        compare(a, b) {
            return a === b;
        }
    },
    mounted() {
        this.GET_AVAILABLE_LANGUAGES().then(() => {
            this.$emit('input', this.getLanguages.defaultLanguage.alpha2);
        });
    }
};
</script>
