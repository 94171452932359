<template>
    <div>
        <Toolbar :toolbarTitle="$t('Wiki löschen')" :showDrawer="false" :lastPath="-1" tooltipMessage="Hier können Sie Wiki Einträge löschen" />
        <ConfirmDelete :deleteMessage="$t('Sind Sie sicher, dass Sie diesen Wiki Ordner löschen wollen?')" :selectedItem="wiki" @deleteItem="deleteItem" />
    </div>
</template>
<script>
import ConfirmDelete from '../../Components/Generic/DeleteConfirm';
import Toolbar from '../../Components/Generic/Toolbar';
import WikiMixin from '../../Components/Wiki/Mixins/WikiMixin';

export default {
    name: 'wiki-delete',
    mixins: [WikiMixin],
    components: { ConfirmDelete, Toolbar },
    data: () => ({
        wiki: {}
    }),
    methods: {
        deleteItem() {
            this.DELETE_WIKI_BY_ID(this.wiki.id).then(() => this.$router.go(-1));
        }
    },
    mounted() {
        this.wiki = this.$route.params.wiki;
    }
};
</script>
