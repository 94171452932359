import { mapActions, mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('ChatOperations', [
            'getSocket',
            'getClient',
            'getUser',
            'getUsers',
            'getUserIds',
            'getMessages',
            'getUnreadMessages',
            'getGroupIds',
            'getGroups',
            'getGroup',
            'getNotificationScheduleConfig',
            'getPersonalChatConfig',
            'getUserGroupChatConfig',
            'getAdminGroupChatConfig',
            'getFavouriteUsers',
            'getTotalUnreadMessages',
            'getSelectedChatUser',
            'getSelectedChatGroup',
            'getTotalElearningCount',
            'getTotalCourseCount'
        ])
    },
    methods: {
        ...mapActions('ChatOperations', [
            // Misc
            'SET_SELECTED_CHAT_USER',
            'SET_SELECTED_CHAT_GROUP',
            // Chat funcs
            'CHAT_CONNECT',
            'CHAT_DISCONNECT',
            'CHAT_SEND_PERSONAL_MESSAGE',
            'CHAT_SEND_GROUP_MESSAGE',
            'CHAT_GET_PERSONAL_MESSAGES_BY_ID',
            'CHAT_GET_GROUP_MESSAGES_BY_ID',
            'BUILD_USER_LIST',
            'BUILD_GROUP_LIST',
            'CHAT_GET_UNREAD_MESSAGES',
            // Config
            'CHAT_GET_GENERAL_NOTIFICATIONS',
            'CHAT_SAVE_GENERAL_NOTIFICATIONS',
            'CHAT_GET_PERSONAL_NOTIFICATIONS',
            'CHAT_SAVE_PERSONAL_NOTIFICATIONS',
            'CHAT_GET_USER_GROUP_NOTIFICATIONS',
            'CHAT_SAVE_USER_GROUP_NOTIFICATIONS',
            'CHAT_GET_ADMIN_GROUP_NOTIFICATIONS',
            'CHAT_SAVE_ADMIN_GROUP_NOTIFICATIONS',
            'CHAT_GET_FAVOURITE_USERS',
            'CHAT_SAVE_FAVOURITE_USERS'
        ])
    }
};
