import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters('SCORMOperations', [
            'getScormList',
            'getSelectedScorm',
            'getView',
            'getBoughtScorms',
            'getAssignedUserScorms',
            'getEnrollableScorms',
            'getUploadedScorms',
            'getOpenRequests',
            'getBuyRequests'
        ]),
        ...mapGetters('CourseOperations', ['getDefaultCoursePicture'])
    },
    methods: {
        ...mapActions('SCORMOperations', [
            'GET_SCORM_LIST',
            'GET_SINGLE_SCORM',
            'SET_VIEW',
            'SET_SELECTED_SCORM',
            'GET_MEDIA_BY_ID',
            'GET_VIDEO_BY_ID',
            'GET_BOUGHT_SCORMS',
            'BUY_SCORM',
            'GET_SCORM_USER_CONFIG',
            'ENROLL_SCORM_TO_USERS',
            'GET_ASSIGNED_USER_SCORMS',
            'GET_SCORM_REPORT_USER_ACTIVITIES',
            'GET_SCORM_CATEGORIES',
            'GET_SPECIFIC_SCORM_DETAILS',
            'UPLOAD_SCORM',
            'SELF_ENROLL',
            'GET_ENROLLABLE_SCORMS',
            'GET_UPLOADED_SCORMS',
            'GET_OPEN_REQUESTS',
            'CHANGE_STATE',
            'CREATE_BUY_REQUEST',
            'GET_OPEN_BUY_REQUESTS',
            'DECLINE_BUY_REQUESTS'
        ]),
        ...mapActions('CourseOperations', ['GET_COURSE_PICTURE'])
    }
};
