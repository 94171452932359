import axios from '../../../plugins/Axios/axios';

const state = {
    courseGroupForums: [],
    courseGroupThreads: [],
    courseGroupAnswers: [],
    courseGroupSelectedGroup: {},
    courseGroupSelectedForum: {},
    courseGroupSelectedThread: {},
    courseGroupSelectedAnswer: {},
    courseGroupForumPath: []
};

const getters = {
    getCourseGroupForums() {
        return state.courseGroupForums;
    },
    getCourseGroupThreads() {
        return state.courseGroupThreads;
    },
    getCourseGroupAnswers() {
        return state.courseGroupAnswers;
    },
    getCourseGroupSelectedGroup() {
        return state.courseGroupSelectedGroup;
    },
    getCourseGroupSelectedForum() {
        return state.courseGroupSelectedForum;
    },
    getCourseGroupSelectedThread() {
        return state.courseGroupSelectedThread;
    },
    getCourseGroupSelectedAnswer() {
        return state.courseGroupSelectedAnswer;
    },
    getCourseGroupForumPath() {
        return state.courseGroupForumPath;
    }
};

const actions = {
    REMOVE_COURSE_GROUP_FORUM_PATH({ commit }, data) {
        commit('removeCourseGroupForumPath', data);
    },
    ADD_COURSE_GROUP_FORUM_PATH({ commit }, data) {
        commit('addCourseGroupForumPath', data);
    },
    SET_COURSE_GROUP_SELECTED_GROUP({ commit }, group) {
        commit('updateCourseGroupSelectedGroup', group);
    },
    SET_COURSE_GROUP_SELECTED_FORUM({ commit }, forum) {
        commit('updateCourseGroupSelectedForum', forum);
    },
    SET_COURSE_GROUP_SELECTED_THREAD({ commit }, thread) {
        commit('updateCourseGroupSelectedThread', thread);
    },
    SET_COURSE_GROUP_SELECTED_ANSWER({ commit }, answer) {
        commit('updateCourseGroupSelectedAnswer', answer);
    },
    GET_COURSE_GROUP_FORUMS({ commit }, { course: { id: courseId }, group: { id: groupId } }) {
        return axios
            .get(`/courselearning/courseinstances/${courseId}/groups/${groupId}/forums`)
            .then((response) => response.data.data)
            .then((response) => commit('updateCourseGroupForums', response));
    },
    CREATE_COURSE_GROUP_FORUM({ commit }, { course: { id: courseId }, group: { id: groupId }, forum }) {
        return axios
            .post(`/courselearning/courseinstances/${courseId}/groups/${groupId}/forums`, forum)
            .then((response) => response.data.data)
            .then((response) => commit('updateCourseGroupForums', response));
    },
    UPDATE_COURSE_GROUP_FORUM({ commit }, { course: { id: courseId }, group: { id: groupId }, forum }) {
        const { id: forumId } = forum;
        return axios
            .patch(`/courselearning/courseinstances/${courseId}/groups/${groupId}/forums/${forumId}`, forum)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateCourseGroupForums', response);
                commit('updateCourseGroupSelectedForum', response);
            });
    },
    DELETE_COURSE_GROUP_FORUM({ commit }, { course: { id: courseId }, group: { id: groupId }, forum }) {
        const { id: forumId } = forum;
        return axios
            .delete(`/courselearning/courseinstances/${courseId}/groups/${groupId}/forums/${forumId}`)
            .then((response) => response.data.data)
            .then(() => commit('deleteCourseGroupForum', forum));
    },
    GET_COURSE_GROUP_THREADS({ commit }, { course: { id: courseId }, group: { id: groupId }, forum: { id: forumId } }) {
        return axios
            .get(`/courselearning/courseinstances/${courseId}/groups/${groupId}/forums/${forumId}/threads`)
            .then((response) => response.data.data)
            .then((response) => commit('updateCourseGroupThreads', response));
    },
    CREATE_COURSE_GROUP_THREAD({ commit }, { course: { id: courseId }, group: { id: groupId }, forum: { id: forumId }, thread }) {
        return axios
            .post(`/courselearning/courseinstances/${courseId}/groups/${groupId}/forums/${forumId}/threads`, thread)
            .then((response) => response.data.data)
            .then((response) => commit('updateCourseGroupThreads', response));
    },
    UPDATE_COURSE_GROUP_THREAD({ commit }, { course: { id: courseId }, group: { id: groupId }, forum: { id: forumId }, thread }) {
        const { id: threadId } = thread;
        return axios
            .patch(`/courselearning/courseinstances/${courseId}/groups/${groupId}/forums/${forumId}/threads/${threadId}`, thread)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateCourseGroupThreads', response);
                commit('updateCourseGroupSelectedThread', response);
            });
    },
    DELETE_COURSE_GROUP_THREAD({ commit }, { course: { id: courseId }, group: { id: groupId }, forum: { id: forumId }, thread }) {
        const { id: threadId } = thread;
        return axios
            .delete(`/courselearning/courseinstances/${courseId}/groups/${groupId}/forums/${forumId}/threads/${threadId}`)
            .then((response) => response.data.data)
            .then(() => commit('deleteCourseGroupThread', thread));
    },
    GET_COURSE_GROUP_ANSWERS({ commit }, { course: { id: courseId }, group: { id: groupId }, forum: { id: forumId }, thread: { id: threadId } }) {
        return axios
            .get(`/courselearning/courseinstances/${courseId}/groups/${groupId}/forums/${forumId}/threads/${threadId}/answers`)
            .then((response) => response.data.data)
            .then((response) => commit('updateCourseGroupAnswers', response));
    },
    CREATE_COURSE_GROUP_ANSWER({ commit }, { course: { id: courseId }, group: { id: groupId }, forum: { id: forumId }, thread: { id: threadId }, answer }) {
        return axios
            .post(`/courselearning/courseinstances/${courseId}/groups/${groupId}/forums/${forumId}/threads/${threadId}/answers`, answer)
            .then((response) => response.data.data)
            .then((response) => commit('updateCourseGroupAnswers', response));
    },
    UPDATE_COURSE_GROUP_ANSWER({ commit }, { course: { id: courseId }, group: { id: groupId }, forum: { id: forumId }, thread: { id: threadId }, answer }) {
        const { id: answerId } = answer;
        return axios
            .patch(`/courselearning/courseinstances/${courseId}/groups/${groupId}/forums/${forumId}/threads/${threadId}/answers/${answerId}`, answer)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateCourseGroupAnswers', response);
                /* 	commit('updateSelectedAnswer', response); */
            });
    },
    DELETE_COURSE_GROUP_ANSWER({ commit }, { course: { id: courseId }, group: { id: groupId }, forum: { id: forumId }, thread: { id: threadId }, answer }) {
        const { id: answerId } = answer;
        return axios
            .delete(`/courselearning/courseinstances/${courseId}/groups/${groupId}/forums/${forumId}/threads/${threadId}/answers/${answerId}`)
            .then((response) => response.data.data)
            .then(() => commit('deleteCourseGroupAnswer', answer));
    }
};

const mutations = {
    clear: (state) => {
        state.courseGroupForums = [];
        state.courseGroupThreads = [];
        state.courseGroupAnswers = [];
        state.courseGroupSelectedAnswer = {};
        state.courseGroupSelectedForum = {};
        state.courseGroupSelectedGroup = {};
        state.courseGroupSelectedThread = {};
    },
    updateCourseGroupForums: (state, response) => {
        if (Array.isArray(response)) {
            state.courseGroupForums = response;
        } else {
            if (state.courseGroupForums.map((forum) => forum.id).includes(response.id)) {
                state.courseGroupForums = state.courseGroupForums.map((forum) => {
                    if (forum.id === response.id) {
                        return response;
                    }
                    return forum;
                });
            } else {
                state.courseGroupForums.push(response);
            }
        }
    },
    updateCourseGroupThreads: (state, response) => {
        if (Array.isArray(response)) {
            state.courseGroupThreads = response;
        } else {
            if (state.courseGroupThreads.map((thread) => thread.id).includes(response.id)) {
                state.courseGroupThreads = state.courseGroupThreads.map((thread) => {
                    if (thread.id === response.id) {
                        return response;
                    }
                    return thread;
                });
            } else {
                state.courseGroupThreads.push(response);
            }
        }
    },
    updateCourseGroupAnswers: (state, response) => {
        if (Array.isArray(response)) {
            state.courseGroupAnswers = response;
        } else {
            if (state.courseGroupAnswers.map((answer) => answer.id).includes(response.id)) {
                state.courseGroupAnswers = state.courseGroupAnswers.map((answer) => {
                    if (answer.id === response.id) {
                        return response;
                    }
                    return answer;
                });
            } else {
                state.courseGroupAnswers.push(response);
            }
        }
    },
    deleteCourseGroupForum: (state, forum) => {
        const index = state.courseGroupForums.map((forum) => forum.id).indexOf(forum.id);
        if (index !== -1) {
            state.courseGroupForums.splice(index, 1);
        }
    },
    deleteCourseGroupThread: (state, thread) => {
        const index = state.courseGroupThreads.map((thread) => thread.id).indexOf(thread.id);
        if (index !== -1) {
            state.courseGroupThreads.splice(index, 1);
        }
    },
    deleteCourseGroupAnswer: (state, answer) => {
        const index = state.courseGroupAnswers.map((answer) => answer.id).indexOf(answer.id);
        if (index !== -1) {
            state.courseGroupAnswers.splice(index, 1);
        }
    },
    updateCourseGroupSelectedGroup: (state, group) => (state.courseGroupSelectedGroup = group),
    updateCourseGroupSelectedForum: (state, forum) => (state.courseGroupSelectedForum = forum),
    updateCourseGroupSelectedThread: (state, thread) => (state.courseGroupSelectedThread = thread),
    updateCourseGroupSelectedAnswers: (state, answer) => (state.courseGroupSelectedAnswer = answer),
    addCourseGroupForumPath: (state, data) => {
        if (state.courseGroupForumPath.map((forum) => forum.id).indexOf(data.id) === -1) {
            state.courseGroupForumPath.push(data);
        }
    },
    removeCourseGroupForumPath: (state, data) => {
        const find = state.courseGroupForumPath.map((forum) => forum.id).findIndex((forum) => forum === data.id);
        if (find !== -1) {
            state.courseGroupForumPath = state.courseGroupForumPath.slice(0, find);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
