<template>
    <v-card-text>
        <v-slider
            readonly
            :max="knowledgeUnitOptionsComputed[0].maximum"
            :min="knowledgeUnitOptionsComputed[0].minimum"
            :step="knowledgeUnitOptionsComputed[0].steps"
            v-model="knowledgeUnitOptionsComputed[0].text"
            :label="knowledgeUnitOptionsComputed[0].text.toString()"
        />
    </v-card-text>
</template>

<script>
export default {
    name: 'picker-result',
    components: {},
    props: {
        knowledgeUnitOptions: {
            default: () => [],
            type: Array
        }
    },
    computed: {
        knowledgeUnitOptionsComputed() {
            if (this.knowledgeUnitOptions.length == 0) {
                return [{ maximum: 0, minimum: 0, steps: 0, text: '' }];
            }
            return this.knowledgeUnitOptions;
        }
    }
};
</script>
