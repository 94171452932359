<template>
    <GroupList v-model="selectedGroup" :items="getGroups" title="Gruppen" :showAction="false" :showAppend="false" :showOptions="true" :sortArray="groupSort">
        <template v-slot:content="{ content }">
            <v-list-item-title>{{ content.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ content.description }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ isSubGroupText(content) }}</v-list-item-subtitle>
        </template>
    </GroupList>
</template>

<script>
import GroupList from '../Generic/List/AdvancedList';
import GroupForumMixin from './Mixins/GroupForumMixin';
import GroupSort from '../Groups/Sort/GroupSort';

export default {
    name: 'group-forum-group-member-list',
    components: { GroupList },
    mixins: [GroupForumMixin],
    data: () => ({
        groupSort: GroupSort
    }),
    computed: {
        selectedGroup: {
            get() {
                return this.getSelectedGroup;
            },
            set(value) {
                this.ADD_FORUM_PATH({
                    id: value.id,
                    title: value.name,
                    href: { name: 'groupForumGroups' }
                });
                this.SET_SELECTED_GROUP(value);
                this.$router.push({ name: 'groupForumForums' });
            }
        }
    },
    methods: {
        isSubGroupText(group) {
            if (group.hasOwnProperty('hasParent')) {
                if (group.hasParent) {
                    return this.$t('Untergruppe');
                }
            }
            return '';
        }
    },
    mounted() {
        this.GET_GROUPS();
    }
};
</script>
