<template>
    <v-card>
        <v-expand-transition>
            <v-card-text v-show="!intermission">
                <v-breadcrumbs v-if="breadcrumbs.length > 0" :items.sync="breadcrumbs" divider=">">
                    <template v-slot:item="{ item }">
                        <a @click="selectContentLink(item)">{{ item.title }}</a>
                    </template>
                </v-breadcrumbs>
                <KnowledgeContentTags :items="tags" />
                <v-card class="my-3">
                    <v-card-text>
                        <div class="ck ck-content" v-html="content" @click="imageGalleryClickHandler" />
                        <div style="clear: both"></div>
                    </v-card-text>
                </v-card>
                <KnowledgeContentLinks :items="links" @input="getContentLink" />
            </v-card-text>
        </v-expand-transition>
        <v-divider v-if="items.length > 1" />
        <v-card-actions v-if="items.length > 1">
            <v-layout column align-center justify-center fill-height>
                <v-pagination circle :length.sync="items.length" v-model="item" :total-visible="$vuetify.breakpoint.xs ? 4 : 6" />
            </v-layout>
        </v-card-actions>
    </v-card>
</template>
<script>
import KnowledgeContentPreviewMixin from './Mixins/KnowledgeContentMixin';
import ImageGalleryMixin from '../Global/Mixins/ImageGalleryMixin';

import KnowledgeContentLinks from './KnowledgeContentLinks';
import KnowledgeContentTags from './KnowledgeContentTags';

export default {
    name: 'knowledge-content-preview',
    mixins: [KnowledgeContentPreviewMixin, ImageGalleryMixin],
    components: {
        KnowledgeContentLinks,
        KnowledgeContentTags
    },
    data: () => ({
        breadcrumbs: [],
        currentContent: {},
        currentPage: 1,
        intermission: false,
        intermissionTimer: null
    }),
    computed: {
        item: {
            get() {
                return this.items.map((test) => test.id).indexOf(this.value.id) + 1;
            },
            set(val) {
                this.currentPage = val;
                this.$emit('input', this.items[val - 1]);
                this.breadcrumbs = [];
            }
        },
        links() {
            if (this.currentContent.hasOwnProperty('knowledgeContentLinks')) {
                return this.currentContent.knowledgeContentLinks;
            }
            return [];
        },
        title() {
            if (this.currentContent.hasOwnProperty('title')) {
                return this.currentContent.title;
            }
            return '';
        },
        tags() {
            if (this.currentContent.hasOwnProperty('tags')) {
                return this.currentContent.tags.split(',');
            }
            return [];
        },
        content() {
            if (this.currentContent.hasOwnProperty('content')) {
                return this.currentContent.content;
            }
            return {};
        }
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        items: {
            type: Array,
            default: () => []
        },
        showPagination: {
            type: Boolean,
            default: true
        },
        showNext: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                if (Object.keys(val).length > 0) {
                    this.intermission = true;
                    this.GET_KNOWLEDGE_CONTENT(val.id).then((link) => {
                        this.intermissionTimer = setTimeout(() => {
                            this.intermission = false;
                        }, 350);
                        this.currentContent = link;
                    });
                }
            }
        }
    },
    methods: {
        contentGoBack() {
            const lastContent = this.breadcrumbs.slice(this.breadcrumbs.length - 1)[0];
            this.breadcrumbs = this.breadcrumbs.slice(0, this.breadcrumbs.length - 1);
            this.currentContent = lastContent;
        },
        nextContent() {
            if (this.currentPage < this.items.length) {
                this.currentPage++;
                this.$emit('input', this.items[this.currentPage - 1]);
            }
        },
        getContentLink({ id }) {
            this.intermission = true;
            this.GET_KNOWLEDGE_CONTENT(id).then((link) => {
                this.intermissionTimer = setTimeout(() => {
                    this.breadcrumbs.push(this.currentContent);
                    this.currentContent = link;
                    this.intermission = false;
                }, 350);
            });
        },
        selectContentLink(contentLink) {
            this.intermission = true;
            this.intermissionTimer = setTimeout(() => {
                let index = this.breadcrumbs.map((content) => content.id).indexOf(contentLink.id);
                if (index !== -1) {
                    this.breadcrumbs = this.breadcrumbs.slice(0, index);
                    this.currentContent = contentLink;
                }
                this.intermission = false;
            }, 350);
        }
    }
};
</script>
