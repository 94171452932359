<template>
    <v-card flat>
        <v-stepper v-model="tabs" :alt-labels="$vuetify.breakpoint.smAndUp" :elevation="0">
            <v-stepper-header>
                <v-stepper-step editable step="1">
                    <span class="text-center">{{ $t('Wissensbasis') }}</span>
                </v-stepper-step>
                <v-stepper-step editable :rules="[(v) => validate]" step="2">
                    <span class="text-center">{{ $t('Wissensabfrage') }}</span>
                </v-stepper-step>
                <v-stepper-step editable step="3">
                    <span class="text-center">{{ $t('Erklärung') }}</span>
                </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
                <v-stepper-content step="1" class="px-0 pt-2 pb-0">
                    <KnowledgeContentManager
                        v-model="knowledgeBase.knowledgeContents"
                        selectable
                        ref="knowledgeBaseSelector"
                        @onCreate="handleKnowledgeBaseOnCreate"
                    />
                </v-stepper-content>
                <v-stepper-content step="2" class="px-0 pt-2 pb-0">
                    <v-container>
                        <v-row>
                            <v-col :xs="12" :sm="5">
                                <v-card height="100%">
                                    <v-toolbar dark text dense color="secondary">
                                        <v-toolbar-title class="text-body-2">{{ $t('Begleitmedium') }}</v-toolbar-title>
                                    </v-toolbar>
                                    <KnowledgeUnitOptionMedia height="100%" :width="200" :value="computedThumbnail" @preview="handlePreview">
                                        <v-row>
                                            <v-col :cols="6">
                                                <v-btn block :disabled="!thumbnail" color="error" @click="clearThumbnail">
                                                    {{ $t('Entfernen') }}
                                                </v-btn>
                                            </v-col>
                                            <v-col :cols="6">
                                                <v-btn block @click="handleMedia(computedThumbnail)">{{ $t('Auswählen') }}</v-btn>
                                            </v-col>
                                        </v-row>
                                    </KnowledgeUnitOptionMedia>
                                </v-card>
                            </v-col>
                            <v-col :xs="12" :sm="7">
                                <v-card height="100%">
                                    <v-card-text>
                                        <v-form v-model="validate" ref="form">
                                            <v-row no-gutters>
                                                <v-col :cols="12">
                                                    <v-textarea
                                                        outlined
                                                        no-resize
                                                        :label="computedQuestionLabel"
                                                        v-model="question"
                                                        :counter="255"
                                                        :rules="[questionValidation, questionLengthValidation]"
                                                    />
                                                </v-col>
                                                <v-col :cols="12">
                                                    <v-checkbox
                                                        v-model="computedContentOnly"
                                                        value="CONTENT_ONLY"
                                                        :label="$t('Nur Content anzeigen')"
                                                        hide-details
                                                    />
                                                </v-col>
                                                <v-col :cols="12">
                                                    <v-slide-y-transition>
                                                        <v-select
                                                            v-show="computedSelectedQuestionType !== 'CONTENT_ONLY'"
                                                            :label="$t('Fragetyp')"
                                                            v-model="computedSelectedQuestionType"
                                                            :items="questionTypes"
                                                            :hint="questionTypeHint"
                                                            persistent-hint
                                                            :rules="[questionTypeValidation]"
                                                        >
                                                            <template v-slot:selection="{ item }">
                                                                {{ $t(item) }}
                                                            </template>
                                                            <template v-slot:item="{ item }">
                                                                {{ $t(item) }}
                                                            </template>
                                                        </v-select>
                                                    </v-slide-y-transition>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col :cols="12">
                                <KnowledgeUnitOptionsSelector
                                    :type="computedSelectedQuestionType"
                                    :buckets="knowledgeUnitBuckets"
                                    v-model="knowledgeUnitOptions"
                                    @media="handleMedia"
                                    @preview="handlePreview"
                                    @buckets="handleBuckets"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-stepper-content>

                <v-stepper-content step="3" class="px-0 pt-2 pb-0">
                    <v-container class="pa-0 ma-0">
                        <v-row no-gutters>
                            <v-col :cols="12">
                                <v-textarea class="ma-2" outlined :label="$t('Kurzerklärung')" v-model="knowledgeExplanation.message" no-resize hide-details />
                            </v-col>
                            <v-col :cols="12">
                                <KnowledgeContentManager
                                    v-model="knowledgeExplanation.knowledgeContents"
                                    selectable
                                    ref="explanationSelector"
                                    @onCreate="handleKnowledgeExplanationOnCreate"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
        <v-container>
            <v-row>
                <v-col>
                    <v-btn block :color="submitButtonColor" @click="submit" :disabled="!validate">
                        {{ submitButtonText }}
                    </v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn @click="() => tabs++" :disabled="tabs == 3" class="primary">{{ $t('Weiter') }}</v-btn>
                </v-col>
            </v-row>
        </v-container>

        <!-- Dialogs -->
        <v-dialog v-model="showFilemanager" scrollable persistent fullscreen>
            <v-card text>
                <v-toolbar dense dark text color="secondary">
                    <v-toolbar-title>{{ $t('Media auswählen') }}</v-toolbar-title>
                    <v-spacer />
                    <v-btn icon @click="() => (showFilemanager = false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pa-0">
                    <Filemanager @selectedMedia="handleSelectedMedia" selectable />
                </v-card-text>
            </v-card>
        </v-dialog>
        <MediaPreview :showDialog="showPreview" @showDialog="(value) => (showPreview = value)" :value="selectedOption" />
        <UpdateKnowledgeUnitExceptionDialog :dialog="showException" @dialog="(value) => (showException = value)" :value="serialize()" @close="close" />
    </v-card>
</template>

<script>
import MediaPreview from '../FileManager/MediaPreview';
import Filemanager from '../FileManager/Prototype/Filemanager';
import KnowledgeUnitOptionsSelector from './KnowledgeUnitOptionTypes/KnowledgeUnitOptionTypes';
import KnowledgeUnitOptionMedia from './KnowledgeUnitOptionTypes/KnowledgeUnitOptionMedia';
import KnowledgeContentManager from '../KnowledgeContent/KnowledgeContentManager';
import KnowledgeContentMixin from '../KnowledgeContent/Mixins/KnowledgeContentMixin';
import AuthoringToolMixin from './Mixins/AuthoringtoolMixin';
import UpdateKnowledgeUnitExceptionDialog from './UpdateKnowledgeUnitExceptionDialog';

export default {
    name: 'add-update-knowledgeunit',
    components: {
        MediaPreview,
        Filemanager,
        KnowledgeContentManager,
        KnowledgeUnitOptionsSelector,
        KnowledgeUnitOptionMedia,
        UpdateKnowledgeUnitExceptionDialog
    },
    mixins: [KnowledgeContentMixin, AuthoringToolMixin],
    data: () => ({
        tabs: 1,
        showException: false,
        showFilemanager: false,
        showPreview: false,
        validate: false,
        selectedFolder: {},
        selectedOption: {},
        id: '',
        knowledgeBase: {
            knowledgeContents: []
        },
        knowledgeExplanation: {
            message: '',
            knowledgeContents: []
        },
        knowledgeUnitBuckets: [],
        knowledgeUnitOptions: [],
        question: '',
        questionType: 'SINGLE_SELECT',
        thumbnail: '',
        thumbnailFileName: '',
        thumbnailFileSize: 0,
        thumbnailFileType: '',
        thumbnailMediaId: ''
    }),
    watch: {
        tabs(value) {
            if (value == 1) {
                this.$nextTick(() => this.$refs.knowledgeBaseSelector.reset());
            }
            if (value == 3) {
                this.$nextTick(() => this.$refs.explanationSelector.reset());
            }
        },
        questionType() {
            this.$refs.form.validate();
        },
        knowledgeUnitOptions() {
            this.$nextTick(() => this.$refs.form.validate());
        }
    },
    computed: {
        computedQuestionLabel() {
            return this.questionType === 'CONTENT_ONLY' ? this.$t('Informationen zum Content') : this.$t('Fragestellung');
        },
        computedContentOnly: {
            get() {
                return this.questionType;
            },
            set(value) {
                if (value !== null) {
                    this.questionType = value;
                } else {
                    this.questionType = 'SINGLE_SELECT';
                }
            }
        },
        computedThumbnail() {
            return {
                fileType: this.thumbnailFileType,
                fileName: this.thumbnailFileName,
                size: this.thumbnailFileSize,
                fileURL: this.thumbnail
            };
        },
        computedSelectedQuestionType: {
            get() {
                return this.questionType;
            },
            set(value) {
                this.questionType = value;
            }
        },
        questionTypes() {
            return ['SINGLE_SELECT', 'MULTI_SELECT', 'TRUE_FALSE', 'PICKER', 'FILL_BLANK', 'SEQUENCE', 'BUCKET_SORT'];
        },
        questionTypeHint() {
            switch (this.questionType) {
                case 'SINGLE_SELECT':
                    return this.$t('Wählen Sie aus einer Menge an Optionen genau eine Antwort aus');
                case 'MULTI_SELECT':
                    return this.$t('Wählen Sie aus einer Menge an Optionen ein oder mehrere Antworten aus');
                case 'TRUE_FALSE':
                    return this.$t('Wählen Sie aus den Optionen Richtig und Falsch genau eine Antwort aus');
                case 'PICKER':
                    return this.$t('Wählen Sie aus einer Zahlenmenge genau eine Antwort aus');
                case 'FILL_BLANK':
                    return this.$t('Schreiben Sie eine Antwort. Die Antwort muss aus einer Menge möglicher Antworten übereinstimmen');
                case 'SEQUENCE':
                    return this.$t('Bringen Sie die Optionen aus einer Menge an Optionen in genau eine Reihenfolge');
                case 'BUCKET_SORT':
                    return this.$t('Sortieren Sie die Optionen in die richtige Kategorie');
            }
            return '';
        },
        submitButtonColor() {
            return this.type === 'ADD' ? 'success' : 'warning';
        },
        submitButtonText() {
            return this.type === 'ADD' ? this.$t('Erstellen') : this.$t('Speichern');
        }
    },
    props: {
        knowledgeUnit: {
            type: Object,
            default: () => ({})
        },
        type: {
            type: String,
            default: 'ADD',
            validator: (v) => ['ADD', 'UPDATE'].includes(v)
        }
    },
    methods: {
        handleKnowledgeBaseOnCreate(value) {
            this.knowledgeBase.knowledgeContents = [...this.knowledgeBase.knowledgeContents, value];
        },
        handleKnowledgeExplanationOnCreate(value) {
            this.knowledgeExplanation.knowledgeContents = [...this.knowledgeExplanation.knowledgeContents, value];
        },
        handleBuckets(value) {
            this.knowledgeUnitBuckets = value;
        },
        questionValidation(v) {
            if (this.questionType === 'CONTENT_ONLY') {
                return (!!v && v.trim().length > 0) || this.$t('Geben Sie bitte Informationen zum Content an');
            }
            return (!!v && v.trim().length > 0) || this.$t('Die Frage darf nicht leer sein');
        },
        questionLengthValidation(v) {
            return (!!v && v.length <= 255) || this.$t('Die Frage ist zu lang');
        },
        questionTypeValidation(v) {
            switch (v) {
                case 'SINGLE_SELECT':
                    return this.knowledgeUnitOptions.reduce((acc, current) => acc || current.isAnswer, false) || this.$t('Eine Lösung fehlt');
                case 'MULTI_SELECT':
                    return this.knowledgeUnitOptions.reduce((acc, current) => acc || current.isAnswer, false) || this.$t('Eine Lösung fehlt');
                case 'TRUE_FALSE':
                    return this.knowledgeUnitOptions.reduce((acc, current) => acc || current.isAnswer, false) || this.$t('Eine Lösung fehlt');
                case 'PICKER':
                    return true;
                case 'FILL_BLANK':
                    return this.knowledgeUnitOptions.length > 0 || this.$t('Eine Lösung fehlt');
                case 'SEQUENCE':
                    return this.knowledgeUnitOptions.length > 0 || this.$t('Eine Lösung fehlt');
                case 'CONTENT_ONLY':
                    return true;
                case 'BUCKET_SORT':
                    return true;
            }
        },
        clearThumbnail() {
            this.thumbnail = '';
            this.thumbnailFileName = '';
            this.thumbnailFileSize = 0;
            this.thumbnailFileType = '';
            this.thumbnailMediaId = '';
        },
        handlePreview(option) {
            this.selectedOption = option;
            this.showPreview = true;
        },
        handleMedia(option) {
            this.selectedOption = option;
            this.showFilemanager = true;
        },
        handleSelectedMedia({ fileURL, fileType, fileName, size, id }) {
            if (this.selectedOption !== this.computedThumbnail) {
                this.knowledgeUnitOptions = this.knowledgeUnitOptions.map((option) => {
                    if (option === this.selectedOption) {
                        return {
                            ...option,
                            fileURL,
                            fileType,
                            fileName,
                            size,
                            mediaId: id
                        };
                    }
                    return option;
                });
                this.knowledgeUnitBuckets = this.knowledgeUnitBuckets.map((bucket) => {
                    if (bucket === this.selectedOption) {
                        return {
                            ...bucket,
                            media: { fileURL, fileType, fileName, size, id }
                        };
                    }
                    return bucket;
                });
            } else {
                this.thumbnail = fileURL;
                this.thumbnailFileName = fileName;
                this.thumbnailFileSize = size;
                this.thumbnailFileType = fileType;
                this.thumbnailMediaId = id;
            }
            this.showFilemanager = false;
            this.selectedOption = {};
        },
        serialize() {
            return {
                ...(this.id !== '' && { id: this.id }),
                knowledgeBase: this.knowledgeBase,
                knowledgeExplanation: this.knowledgeExplanation,
                knowledgeUnitBuckets: this.knowledgeUnitBuckets,
                knowledgeUnitOptions: this.knowledgeUnitOptions.filter((option) => option.sequenceNo !== -1),
                question: this.question,
                questionType: this.questionType,
                thumbnail: this.thumbnail,
                thumbnailFileName: this.thumbnailFileName,
                thumbnailFileSize: this.thumbnailFileSize,
                thumbnailFileType: this.thumbnailFileType,
                thumbnailMediaId: this.thumbnailMediaId
            };
        },
        submit() {
            switch (this.type) {
                case 'ADD':
                    return this.CREATE_KNOWLEDGEUNIT(this.serialize())
                        .then(() => this.close())
                        .catch(
                            ({
                                response: {
                                    data: { errors }
                                }
                            }) => {
                                if (errors && errors.length > 0 && errors[0].code === 4115) {
                                    this.showException = true;
                                }
                            }
                        );
                case 'UPDATE':
                    return this.UPDATE_KNOWLEDGEUNIT(this.serialize())
                        .then(() => this.close())
                        .catch(
                            ({
                                response: {
                                    data: { errors }
                                }
                            }) => {
                                if (errors && errors.length > 0 && errors[0].code === 4115) {
                                    this.showException = true;
                                }
                            }
                        );
            }
        },
        reset() {
            this.tabs = 1;
            this.showFilemanager = false;
            this.showPreview = false;
            this.selectedOption = {};

            this.id = '';
            this.knowledgeBase = {
                knowledgeContents: []
            };
            this.knowledgeExplanation = {
                message: '',
                knowledgeContents: []
            };
            this.knowledgeUnitOptions = [
                {
                    id: parseInt(performance.now()).toString(),
                    text: '',
                    isAnswer: true,
                    minimum: 0,
                    maximum: 100,
                    steps: 1,
                    sequenceNo: 1,
                    mediaId: '',
                    fileURL: '',
                    fileName: '',
                    fileType: '',
                    size: 0,
                    answeredOrder: 0
                }
            ];
            this.question = '';
            this.questionType = 'SINGLE_SELECT';
            this.thumbnail = '';
            this.thumbnailFileName = '';
            this.thumbnailFileSize = 0;
            this.thumbnailFileType = '';
            this.thumbnailMediaId = '';
        },
        loadKnowledgeUnit() {
            this.$nextTick(() => {
                Object.entries(this.knowledgeUnit).forEach(([key, value]) => {
                    if (this.$data.hasOwnProperty(key)) {
                        switch (key) {
                            case 'knowledgeUnitOptions': {
                                this.$nextTick(() => {
                                    let options = value;
                                    options.sort((a, b) => a.sequenceNo - b.sequenceNo);
                                    this.$data[key] = options;
                                });
                                break;
                            }
                            case 'knowledgeUnitBuckets': {
                                this.$nextTick(() => (this.$data[key] = value));
                                break;
                            }
                            default:
                                this.$data[key] = value;
                        }
                    }
                });
                if (this.knowledgeUnit.hasOwnProperty('knowledgeUnitOptions')) {
                    this.$nextTick(() => (this.knowledgeUnitOptions = [...this.knowledgeUnit.knowledgeUnitOptions]));
                }
            });
        },
        close() {
            this.SET_VIEW({ view: '', data: {}, type: '' });
        }
    },
    activated() {
        if (this.type === 'UPDATE') {
            this.loadKnowledgeUnit();
            this.$nextTick(() => this.$refs.form.validate());
        } else {
            this.reset();
        }
    },
    beforeMount() {
        this.GET_KNOWLEDGE_CONTENTS();
    }
};
</script>
