<template>
    <div>
        <v-tabs slider-color="accent" show-arrows v-model="tab" mandatory>
            <v-tab :key="0">
                {{ $t('Aktiv') }}
            </v-tab>
            <v-tab :key="1">
                {{ $t('Abgeschlossen') }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" touchless>
            <v-tab-item :key="0">
                <TaskList :items="activeTaskArchiveList" showOptions :sortArray="taskSort" @results="handleResults" :type="type">
                    <template v-slot:action="action">
                        <v-btn block color="primary" @click.stop="startTask(action)">
                            {{ $t('Aufgabe Starten') }}
                        </v-btn>
                    </template>
                </TaskList>
            </v-tab-item>
            <v-tab-item :key="1">
                <keep-alive>
                    <component
                        :is="resultsSwitch"
                        :items="inactiveTaskArchiveList"
                        :showOptions="true"
                        :sortArray="taskSort"
                        @results="handleResults"
                        :reason="resultsReason"
                        :correctAnswer="results"
                    >
                        <v-btn icon @click="closeResults">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                    </component>
                </keep-alive>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import TaskList from './TaskArchiveList';
import TaskSort from './Sort/TaskSort';
import QuizResults from '../Quiz/Helpers/QuizResult';

import QuizMixin from './Mixins/QuizMixin';

export default {
    name: 'task-archive',
    components: {
        TaskList,
        QuizResults
    },
    mixins: [QuizMixin],
    data: () => ({
        tab: 0,
        taskSort: TaskSort,
        showResults: false,
        resultsReason: '',
        results: [],
        isLoaded: [],
        settings: {}
    }),
    watch: {
        tab: {
            immediate: true,
            handler(value) {
                switch (value) {
                    case 0: {
                        if (!this.isLoaded.includes(0)) {
                            if (this.type === 'TASK') {
                                this.GET_INCOMPLETE_USERTASK_LIST();
                            }
                            if (this.type === 'ARCHIVE') {
                                this.GET_INCOMPLETE_ARCHIVE_LIST();
                            }
                            this.isLoaded.push(0);
                        }
                        break;
                    }
                    case 1: {
                        if (!this.isLoaded.includes(1)) {
                            if (this.type === 'TASK') {
                                this.GET_COMPLETE_USERTASK_LIST();
                            }
                            if (this.type === 'ARCHIVE') {
                                this.GET_COMPLETE_ARCHIVE_LIST();
                            }
                            this.isLoaded.push(1);
                        }
                        break;
                    }
                }
            }
        }
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        type: {
            type: String,
            validator: (value) => ['TASK', 'ARCHIVE'].includes(value) || 'incorrect type',
            default: 'TASK'
        },
        activeTaskArchiveList: {
            default: () => [],
            type: Array
        },
        inactiveTaskArchiveList: {
            default: () => [],
            type: Array
        }
    },
    computed: {
        resultsSwitch() {
            if (this.showResults) {
                return QuizResults;
            }
            return TaskList;
        }
    },
    methods: {
        editTask(task) {
            this.$emit('editTask', task);
        },
        startTask(task) {
            this.$emit('startTask', task);
        },
        closeResults() {
            this.showResults = false;
            this.results = [];
        },
        handleLoop() {
            return;
        },
        handleResults(value) {
            if (this.type === 'TASK') {
                this.GET_USERTASK_RESULTS({ id: value }).then(({ knowledgeUnitsResults: results, taskDoneReason: reason, ...rest }) => {
                    this.results = results;
                    this.resultsReason = reason;
                    this.settings = { quiz: rest };
                    this.showResults = true;
                });
            }
            if (this.type === 'ARCHIVE') {
                this.GET_ARCHIVETASK_RESULTS({ id: value }).then(({ knowledgeUnitsResults: results, taskDoneReason: reason, ...rest }) => {
                    this.results = results;
                    this.resultsReason = reason;
                    this.settings = { quiz: rest };
                    this.showResults = true;
                });
            }
        }
    }
};
</script>
