<template>
    <v-dialog :value="toggleModal" persistent scrollable :width="$vuetify.breakpoint.thresholds.sm" :fullscreen="$vuetify.breakpoint.xs">
        <v-card>
            <v-toolbar dense flat dark color="secondary">
                <v-toolbar-title>{{ $t('Vorlage bearbeiten') }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-tabs v-model="tabs" mandatory show-arrows slider-color="accent">
                <v-tab key="Template">{{ $t('Vorlage') }}</v-tab>
                <v-tab key="Interval">{{ $t('Intervall Einstellungen') }}</v-tab>
                <v-tab key="Users">{{ $t('Nutzer Einstellungen') }}</v-tab>
            </v-tabs>
            <v-card-text>
                <v-tabs-items v-model="tabs" touchless>
                    <v-tab-item key="Template">
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        color="accent"
                                        autocomplete="new-password"
                                        v-model="template.name"
                                        :label="$t('Name')"
                                        :hint="$t('Der Name der Vorlage dient dazu die Vorlage im Editor zu identifizieren')"
                                        persistent-hint
                                    />
                                </v-col>
                                <v-col cols="auto">
                                    <v-switch
                                        color="accent"
                                        v-model="template.active"
                                        :label="$t('Aktiv')"
                                        :hint="$t('E-Mails von inaktiv geschalteten Vorlagen werden nicht gesendet')"
                                        persistent-hint
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="Interval">
                        <v-container>
                            <v-row>
                                <v-col :cols="12">
                                    <IntervalSelector v-model="selectedInterval" />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="Users">
                        <v-container>
                            <v-row>
                                <v-col :cols="12">
                                    <UserSelector v-model="userList" />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
            <v-card-actions>
                <v-btn block @click="update" color="warning" :disabled="validateData">{{ $t('Speichern') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import IntervalSelector from './Interval/IntervalSelector';
import UserSelector from '../Generic/Tree/UserGroupTreeSelector';
import SystemSettingsMixin from './Mixins/SystemSettingsMixin';

export default {
    name: 'update-scheduled-template-type',
    mixins: [SystemSettingsMixin],
    components: { IntervalSelector, UserSelector },
    data: () => ({
        tabs: 0,
        selectedInterval: {},
        userList: [],
        template: {
            name: '',
            active: false
        }
    }),
    props: {
        modal: {
            type: Boolean,
            default: () => false,
            required: true
        },
        value: {
            type: Object,
            default: () => {}
        }
    },
    watch: {
        value(value) {
            if (Object.keys(value).length > 0) {
                const { executionInterval, sendOnDays, startDate, userIds, ...rest } = value;
                const users = userIds.map((user) => this.GET_SINGLE_USER_BY_ID(user).then((user) => user.data.data));
                Promise.all(users).then((result) => {
                    this.userList = result;
                });
                this.selectedInterval = {
                    executionInterval,
                    sendOnDays,
                    startDate
                };
                this.template = rest;
            }
        }
    },
    computed: {
        toggleModal: {
            get() {
                return this.modal;
            },
            set(value) {
                this.$emit('toggleModal', value);
            }
        },
        validateData: {
            get() {
                return !(this.template.name !== '' && this.selectedInterval.hasOwnProperty('startDate'));
            }
        }
    },
    methods: {
        serialize() {
            return {
                ...this.template,
                userIds: this.userList.map((user) => user.id),
                ...this.selectedInterval
            };
        },
        update() {
            this.$emit('toggleModal', false);
            this.UPDATE_SCHEDULED_TEMPLATE(this.serialize());
        },
        close() {
            this.$emit('toggleModal', false);
        }
    }
};
</script>
