<template>
    <v-container>
        <v-row>
            <v-col :cols="12">
                <v-textarea readonly hide-details :label="$t('Beschreibung')" :value="emptyDescription" no-resize :rows="3" outlined />
            </v-col>
            <v-col :cols="6">
                <v-text-field readonly hide-details :label="$t('Minimale Punktzahl')" :value="value.minScore" />
            </v-col>
            <v-col :cols="6">
                <v-text-field readonly hide-details :label="$t('Maximale Punktzahl')" :value="value.score" />
            </v-col>
        </v-row>
        <v-row>
            <v-col :cols="6">
                <v-btn block color="secondary" @click.stop="switchToTeacherView" :disabled="showTeacherView">
                    <span>{{ $t('VORGABEN') }}</span>
                </v-btn>
            </v-col>
            <v-col :cols="6">
                <v-btn block color="secondary" @click.stop="switchToUserView" :disabled="showUserView">
                    <span> {{ $t('EIGENMANAGEMENT') }}</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-expand-transition mode="out-in">
            <v-row v-if="showTeacherView">
                <v-col :cols="6">
                    <v-text-field readonly hide-details :label="$t('Startdatum')" :value="value.startDate" />
                </v-col>
                <v-col :cols="6">
                    <v-text-field readonly hide-details :label="$t('Enddatum')" :value="value.endDate" />
                </v-col>
                <v-col :cols="6">
                    <v-text-field
                        readonly
                        hide-details
                        :label="$t('Arbeitsaufwand')"
                        :value="`${convertMinutes} ${$t('à')} ${timeScaleInMinutes} ${$t('Minuten')}`"
                    />
                </v-col>
                <v-col :cols="6">
                    <v-text-field readonly hide-details :label="$t('Dauer')" :value="`${hiddenDurationInDays} ${computedDays}`" />
                </v-col>
            </v-row>
        </v-expand-transition>
        <v-expand-transition>
            <v-row v-if="showUserView">
                <v-col :cols="6">
                    <v-text-field readonly hide-details :label="$t('Start')" :value="`${emptyStartDate} / ${emptyStartTime.split(':00')[0]}`" />
                </v-col>
                <v-col :cols="6">
                    <v-text-field readonly hide-details :label="$t('Ende')" :value="`${emptyEndDate} / ${emptyEndTime.split(':00')[0]}`" />
                </v-col>
                <v-col :col="12">
                    <v-textarea readonly hide-details outlined :label="$t('Notizen')" :value="emptyNotes" no-resize :rows="3" />
                </v-col>
                <v-col :cols="12">
                    <UpdateMyCourse :items="this.getScheduling" :course="this.value" :courseType="'course'" />
                </v-col>
            </v-row>
        </v-expand-transition>
    </v-container>
</template>
<script>
import UpdateMyCourse from '../../UpdateMyCourse';
import CourseMixin from '../../Mixins/CourseMixin';
export default {
    name: 'my-topic-info',
    components: { UpdateMyCourse },
    mixins: [CourseMixin],
    data: () => ({
        teacherView: '',
        userView: { outline: true },
        showTeacherView: true,
        showUserView: false,
        showUserUpdate: false,
        modalActive: 0,
        getScheduling: {},
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: ''
    }),
    watch: {
        showUserUpdate(value) {
            if (!value) {
                this.GET_ACTIVITY_SCHEDULING(this.value).then((response) => (this.getScheduling = response));
            }
        },
        showMoreInfo(value) {
            if (value) {
                this.GET_ACTIVITY_SCHEDULING(this.value).then((response) => (this.getScheduling = response));
                this.GET_COURSE_USER_PREFERENCES()
                    .then((response) => response.prefersUserScheduling)
                    .then((response) => this.isUserPreference(response));
            }
        },
        getScheduling(value) {
            if (value) {
                if (this.getScheduling.startDate !== null) {
                    this.startDate = this.getScheduling.startDate.split(' ')[0];
                    this.startTime = this.getScheduling.startDate.split(' ')[1];
                }
                if (this.getScheduling.endDate !== null) {
                    this.endDate = this.getScheduling.endDate.split(' ')[0];
                    this.endTime = this.getScheduling.endDate.split(' ')[1];
                }
            }
        }
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        selectable: {
            type: Boolean,
            default: false
        },
        durationInDays: {
            type: Number,
            default: 0
        },
        isMyCourse: {
            type: Boolean,
            default: false
        },
        timeScaleInMinutes: {
            type: Number,
            default: 60
        },
        showMoreInfo: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedShowScore() {
            if (!this.getSelectedAttendeeCourse.useEvaluation) {
                return false;
            } else if (this.value.minScore === 0) {
                return false;
            }
            return true;
        },
        hiddenDurationInDays() {
            if (this.isMyCourse && this.value.hideDurationInDays) {
                return '-';
            }
            return this.value.durationInDays;
        },
        computedDays() {
            if (this.value.durationInDays === 1) {
                return this.$t('Tag');
            }
            return this.$t('Tage');
        },
        emptyDescription() {
            if (this.value.description.length === 0) {
                return '-';
            }
            return this.value.description;
        },
        emptyNotes() {
            if (this.getScheduling.notes === null) {
                return '-';
            }
            return this.getScheduling.notes;
        },
        emptyStartDate() {
            if (this.getScheduling.startDate === null || this.getScheduling.startDate === '') {
                return '-';
            }
            return this.startDate;
        },
        emptyStartTime() {
            if (this.getScheduling.startDate === null) {
                return '-';
            }
            return this.startTime;
        },
        emptyEndDate() {
            if (this.getScheduling.endDate === null) {
                return '-';
            }
            return this.endDate;
        },
        emptyEndTime() {
            if (this.getScheduling.endDate === null) {
                return '-';
            }
            return this.endTime;
        },
        convertMinutes() {
            if (this.isMyCourse && this.value.hideEstimatedWorkTimeInMinutes) {
                return '-';
            }
            const timeUnitMinutes = this.value.estimatedWorkTimeInMinutes % this.timeScaleInMinutes;
            const timeUnit = this.value.estimatedWorkTimeInMinutes / this.timeScaleInMinutes;

            if (timeUnitMinutes.toString().length === 1) {
                return `${timeUnit.toString().split('.')[0]}:${'0' + timeUnitMinutes.toString()} Einheiten`;
            }
            return `${timeUnit.toString().split('.')[0]}:${timeUnitMinutes.toString()} Einheiten`;
        }
    },
    methods: {
        switchToTeacherView() {
            this.showTeacherView = true;
            this.showUserView = false;
        },
        switchToUserView() {
            this.showTeacherView = false;
            this.showUserView = true;
        },
        isUserPreference(value) {
            if (value) {
                this.switchToUserView();
            } else {
                this.switchToTeacherView();
            }
        },
        updateScheduling() {
            this.showUserUpdate = true;
        }
    }
};
</script>
<style scoped>
.limit {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
.readableText {
    color: black;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 0.3px;
    -webkit-text-stroke-color: black;
    text-shadow: 0 1px 0 black;
}
</style>
