<template>
    <v-img
        :src="computedSource"
        :lazy-src="computedSource"
        :contain="contain"
        :aspect-ratio="ratio"
        :height="height"
        :width="width"
        @error="loadDefaultPicture()"
    >
        <v-layout fill-height align-center justify-center ma-0 v-if="alignCenter">
            <slot></slot>
        </v-layout>
        <v-layout v-else fill-height ma-0>
            <slot></slot>
        </v-layout>
        <template v-slot:placeholder>
            <v-layout fill-height align-center justify-center ma-0>
                <v-progress-circular indeterminate color="grey lighten-3"></v-progress-circular>
            </v-layout>
        </template>
    </v-img>
</template>
<script>
import Folder from '../../assets/Resources/SVG/folder.svg';
import Blank from '../../assets/Resources/SVG/blank.svg';

export default {
    name: 'image-loader',
    data: () => ({
        hasError: false
    }),
    props: {
        src: {
            type: String,
            default: ''
        },
        contain: {
            type: Boolean,
            default: true
        },
        ratio: {
            type: [Number, String],
            default: undefined
        },
        height: {
            type: [Number, String]
        },
        width: {
            type: [Number, String],
            default: '100%'
        },
        showDefaultImage: {
            type: Boolean,
            default: true
        },
        alignCenter: {
            type: Boolean,
            default: true
        },
        defaultPicture: {
            type: String,
            default: ''
        }
    },
    computed: {
        computedSource() {
            if (this.hasError) {
                return this.defaultPicture;
            }
            if (this.src.trim() === '') {
                if (this.showDefaultImage) return Folder;
                else return Blank;
            }
            return this.src;
        }
    },
    methods: {
        loadDefaultPicture() {
            this.hasError = true;
        }
    }
};
</script>
