<template>
    <v-card flat>
        <v-container>
            <v-data-iterator
                ref="userIterator"
                :items="getAllCourses"
                :search="search"
                :no-data-text="$t('Keine Kurse verfügbar')"
                :no-results-text="$t('Kein Ergebnis')"
                :hideDefaultFooter="getAllCourses.length === 0"
                :items-per-page="12"
                :footer-props="{
                    'items-per-page-text': $t('Kurse pro Seite'),
                    'items-per-page-options': [12, 24, 48, { text: $t('Alle'), value: -1 }]
                }"
                :custom-sort="selectedSort.sort"
                :custom-filter="filterItems"
            >
                <template v-slot:header>
                    <v-card flat>
                        <v-toolbar dense flat dark color="secondary">
                            <v-toolbar-title>{{ $t('Kurse') }}</v-toolbar-title>
                            <v-spacer />
                            <v-toolbar-items>
                                <AddUpdateCourse type="ADD" />
                                <AddUpdateCourse type="UPDATE" />
                                <DeleteCourse />
                                <v-tooltip bottom>
                                    <template #activator="{ on, attrs }">
                                        <v-btn v-on="on" v-bind="attrs" icon :disabled="!isSelected" @click="copyCourse" color="accent">
                                            <v-icon>mdi-content-copy</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('Kurs Kopieren') }}</span>
                                </v-tooltip>
                                <v-btn icon @click="toggleSearch">
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-expand-transition>
                            <v-card v-show="showSearch">
                                <v-container fluid>
                                    <v-row>
                                        <v-col :cols="12">
                                            <v-text-field
                                                prepend-icon="mdi-magnify"
                                                :hint="$t('Geben Sie einen Begriff ein, um nach Kursen zu filtern')"
                                                :label="$t('Kurse durchsuchen')"
                                                v-model="search"
                                                hide-details
                                            />
                                        </v-col>
                                        <v-col :cols="12">
                                            <v-row>
                                                <v-col>
                                                    <v-select
                                                        prepend-icon="mdi-sort"
                                                        attach
                                                        :hint="$t('Wählen Sie eine Methode aus, nach der Sie die Kurse sortieren wollen')"
                                                        return-object
                                                        persistent-hint
                                                        v-model="selectedSort"
                                                        item-value="name"
                                                        :items="sortList"
                                                    >
                                                        <template v-slot:item="{ item: { name } }">
                                                            <span>{{ $t(name) }}</span>
                                                        </template>
                                                        <template v-slot:selection="{ item: { name } }">
                                                            <span>{{ $t(name) }}</span>
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="auto" align-self="center">
                                                    <v-btn icon @click="reverseOrder">
                                                        <v-icon>mdi-swap-vertical</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-expand-transition>
                    </v-card>
                </template>
                <template #default="{ items }">
                    <v-container>
                        <v-row>
                            <v-col :xs="12" :sm="12" :md="6" v-for="item in items" :key="item.id">
                                <Course
                                    @selectedKnowledgeContent="handleSelectedKnowledgeContent"
                                    :selectedKnowledgeContent="selectedKnowledgeContent"
                                    :value="item"
                                    @input="(val) => (selectedCourse = val)"
                                    :isAdmin="isAdmin"
                                    :selectable="true"
                                    :selected="selectedCourse"
                                    @selected="handleCardClick"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
            </v-data-iterator>
            <KnowledgeContentPreview toolbarTitle="Content" v-model="computedSelectedKnowledgeContent" />
        </v-container>
    </v-card>
</template>

<script>
import Course from './Helpers/Course/Course';
import CourseSort from './Sort/CourseSort';
import CourseMixin from './Mixins/CourseMixin';
import KnowledgeContentPreview from '../../KnowledgeContent/KnowledgeContentPreview';
import AddUpdateCourse from './AddUpdateCourse';
import DeleteCourse from './DeleteCourse';

export default {
    name: 'my-courses',
    components: {
        Course,
        KnowledgeContentPreview,
        AddUpdateCourse,
        DeleteCourse
    },
    mixins: [CourseMixin],
    data: () => ({
        showSearch: false,
        search: '',
        selectedSort: CourseSort[0],
        sortList: CourseSort,
        selectedKnowledgeContent: []
    }),
    props: {
        isAdmin: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isSelected() {
            return Object.keys(this.getSelectedAdminCourse).length > 0;
        },
        selectedCourse: {
            get() {
                if (this.isAdmin) {
                    return this.getSelectedAdminCourse;
                }
                return this.getSelectedAttendeeCourse;
            },
            set(value) {
                if (this.isAdmin) {
                    this.SET_SELECTED_ADMIN_COURSE(value);
                } else {
                    this.SET_SELECTED_ATTENDEE_COURSE(value);
                }
            }
        },
        computedSelectedKnowledgeContent: {
            get() {
                if (this.selectedKnowledgeContent.length > 0) {
                    return this.selectedKnowledgeContent[0];
                }
                return {};
            },
            set() {
                this.selectedKnowledgeContent = [];
            }
        }
    },

    methods: {
        handleSelectedKnowledgeContent(value) {
            if (value.length > 0) {
                this.selectedKnowledgeContent = [value[value.length - 1]];
            } else {
                this.selectedKnowledgeContent = [];
            }
        },
        toggleSearch() {
            this.showSearch = !this.showSearch;
        },
        reverseOrder() {
            this.$refs.userIterator.computedPagination.descending = !this.$refs.userIterator.computedPagination.descending;
        },
        filterItems(items, search) {
            if (search.trim() === '') {
                return items;
            }
            let buildString = [...new Set(search.split(' '))].filter((word) => word !== '');
            return items.filter((item) =>
                buildString.some((word) =>
                    Object.values(item)
                        .filter((val) => typeof val === 'string' || typeof val === 'number')
                        .map((val) => {
                            if (val === 'number') {
                                return val.toString();
                            }
                            return val;
                        })
                        .join(' ')
                        .toLowerCase()
                        .includes(word.toLowerCase())
                )
            );
        },
        handleCardClick(value) {
            if (this.selectedCourse.id === value.id) {
                this.selectedCourse = value;
                if (this.isAdmin) {
                    this.$router.push({ name: 'topics' });
                } else {
                    this.$router.push({ name: 'myTopics' });
                }
            }
            this.selectedCourse = value;
        },
        copyCourse() {
            this.COPY_COURSE(this.getSelectedAdminCourse);
        }
    },
    mounted() {
        this.GET_ALL_COURSES();
        this.resetCourseSelection();
    }
};
</script>
