<template>
    <v-card>
        <v-card-text>
            {{
                $t(
                    'Diese Aktivität benötigt keine weiteren Einstellungen. Nutzen Sie Materialien und Content Links, um die Inhalte, die gelesen werden sollen, darzustellen'
                )
            }}
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'inform-activity'
};
</script>
