<template>
    <v-container fluid>
        <v-row>
            <v-col :cols="12">
                <draggable :value="materials" @change="onChange" tag="div" handle=".cursor" v-if="materials.length > 0">
                    <transition-group name="slide-x-transition" mode="out-in" tag="v-list" class="v-list--dense overflow-y-auto" style="max-height: 300px">
                        <v-list-item v-for="material in materials" :key="material.id" @click.stop="handleUpdate(material)">
                            <v-list-item-content>
                                <v-list-item-title>{{ material.name }}</v-list-item-title>
                                <v-list-item-subtitle>{{ convertType(material) }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action v-if="isFileOrLink(material) === 'FILE'">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" icon color="accent" @click.stop="downloadMaterial(material)">
                                            <v-icon>mdi-download</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('Material runterladen') }}</span>
                                </v-tooltip>
                            </v-list-item-action>
                            <v-list-item-action v-else>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" icon color="accent" @click.stop="openLink(material)">
                                            <v-icon>mdi-link</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('Link öffnen') }}</span>
                                </v-tooltip>
                            </v-list-item-action>
                            <v-list-item-action v-if="!onlyDownload">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" icon @click.stop="removeMaterial(material)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('Material entfernen') }}</span>
                                </v-tooltip>
                            </v-list-item-action>
                            <v-list-item-action v-if="!onlyDownload">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" class="cursor pointer">mdi-cursor-move</v-icon>
                                    </template>
                                    <span>{{ $t('Verschieben') }}</span>
                                </v-tooltip>
                            </v-list-item-action>
                        </v-list-item>
                    </transition-group>
                </draggable>
                <div v-else>{{ $t('Keine Materialen') }}</div>
            </v-col>
            <v-col :cols="12" v-if="materials.length > 1">
                <v-btn block color="accent" @click="downloadAllMaterials">
                    {{ $t('Alle Materialien runterladen') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import draggable from 'vuedraggable';
import CatalogMaterialMixin from '../Mixins/CatalogMaterialMixin';

export default {
    name: 'material-list',
    components: { draggable },
    mixins: [CatalogMaterialMixin],
    props: {
        catalog: {
            type: Object,
            default: () => ({})
        },
        selectedMaterial: {
            type: Object,
            default: () => ({})
        },
        materials: {
            type: Array,
            default: () => []
        },
        onlyDownload: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onChange(value) {
            this.$emit('materials', { ...value.moved.element, order: value.moved.newIndex });
        },
        removeMaterial(material) {
            this.DELETE_MATERIAL({
                catalog: this.catalog,
                material: material
            }).then(() =>
                this.GET_MATERIALS({
                    catalog: this.catalog
                })
            );
        },
        openLink({ link }) {
            const validLink = link.includes('http') ? link : `http://${link}`;
            window.open(validLink, '');
        },
        downloadMaterial(item) {
            this.DOWNLOAD_MATERIAL({
                catalog: this.catalog,
                material: item
            });
        },
        downloadAllMaterials(item) {
            this.DOWNLOAD_ALL_MATERIALS({
                catalog: this.catalog,
                material: item
            });
        },
        convertType(item) {
            switch (this.isFileOrLink(item)) {
                case 'FILE':
                    return this.$t('Datei');
                case 'LINK':
                    return this.$t('Link');
            }
            return '';
        },
        handleUpdate(material) {
            if (!this.onlyDownload) {
                this.$emit('switchView', { type: 'UPDATE', data: material });
            } else {
                switch (this.isFileOrLink(material)) {
                    case 'FILE':
                        return this.downloadMaterial(material);
                    case 'LINK':
                        return this.openLink(material);
                }
            }
        }
    }
};
</script>
