<template>
    <v-menu v-model="menu" offset-y :close-on-content-click="false" :max-height="400" :min-width="300">
        <template v-slot:activator="{ on }">
            <v-input
                persistent-hint
                :hint="
                    $t(
                        'Wählen Sie eine Gruppe aus, von der die neue Gruppe abstammen soll. Wenn Sie keine Gruppe auswählen, wird die neue Gruppe im Stammverzeichnis angelegt'
                    )
                "
            >
                <template v-slot:prepend="">
                    <v-btn v-on="on" icon>
                        <v-icon>mdi-file-tree</v-icon>
                    </v-btn>
                </template>
                <v-breadcrumbs divider=">" :items="groupPath">
                    <template v-slot:item="{ item }">
                        <span
                            :class="{
                                caption: true,
                                'primary--text': item.id === value
                            }"
                            >{{ item.name }}</span
                        >
                    </template>
                </v-breadcrumbs>
            </v-input>
        </template>
        <v-card>
            <v-toolbar dense color="secondary" text dark>
                <v-toolbar-title>{{ $t('Gruppen') }}</v-toolbar-title>
            </v-toolbar>
            <v-sheet :max-height="300" class="overflow-y-auto">
                <v-treeview :items="groups" transition return-object class="pt-2" item-key="id">
                    <template v-slot:prepend="{ open }">
                        <v-icon>{{ open ? 'mdi-account-multiple-outline' : 'mdi-account-multiple' }}</v-icon>
                    </template>
                    <template v-slot:label="{ item }">
                        <v-layout>
                            <v-flex shrink @click.stop>
                                <v-checkbox class="pa-0 ma-0" hide-details :value="item.id" v-model="computedSelection" :disabled="isDisabled(item)" />
                            </v-flex>
                            <v-flex align-self-center>
                                <span class="pointer no-highlight">
                                    {{ item.name }}
                                </span>
                            </v-flex>
                        </v-layout>
                    </template>
                </v-treeview>
            </v-sheet>
        </v-card>
    </v-menu>
</template>

<script>
export default {
    name: 'wiki-parent-selector',
    data: () => ({
        menu: false
    }),
    props: {
        value: {
            type: String,
            default: ''
        },
        groups: {
            type: Array,
            default: () => []
        },
        disabledGroup: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        rootPathObject() {
            return {
                name: this.$t('Stammgruppe'),
                parentId: '',
                id: '',
                index: 0
            };
        },
        groupPath() {
            return [this.rootPathObject, ...this.findPath([], this.groups)];
        },
        computedSelection: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        flattendDisabledSubtree() {
            const flatten = (nodes) => {
                return nodes.reduce((acc, curr) => {
                    if (curr.children && curr.children.length > 0) {
                        return [...acc, curr, ...flatten(curr.children)];
                    }
                    return [...acc, curr];
                }, []);
            };
            return flatten([this.disabledSubtree(this.groups)]).map(({ id }) => id);
        }
    },
    methods: {
        isDisabled({ id: groupId }) {
            return this.flattendDisabledSubtree.includes(groupId);
        },
        disabledSubtree(nodes) {
            return nodes.reduce((acc, curr) => {
                if (Object.keys(acc).length > 0) {
                    return acc;
                }
                if (curr.id === this.disabledGroup.id) {
                    return curr;
                }
                if (curr.children && curr.children.length > 0) {
                    return this.disabledSubtree(curr.children);
                }
                return acc;
            }, {});
        },
        isChecked({ id }) {
            return this.value === id;
        },
        findPath(path, nodes) {
            if (this.value === '') {
                return [];
            }
            return nodes.reduce((acc, curr) => {
                if (acc.map(({ id }) => id).includes(this.value)) {
                    return acc;
                }
                if (curr.id === this.value) {
                    return [...acc, ...path, curr];
                }
                if (curr.children && curr.children.length > 0) {
                    return this.findPath([...path, curr], curr.children);
                }
                return acc;
            }, []);
        }
    }
};
</script>
