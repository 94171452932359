<template>
    <v-footer height="auto" color="primary" v-if="isLoggedIn">
        <v-flex primary text-center white--text class="my-2">
            <v-avatar tile :size="42" class="mr-2">
                <v-img :src="getLogo" aspect-ratio contain :height="48" />
            </v-avatar>
            {{ getCompanyName }} - &copy;{{ new Date().getFullYear() }}
        </v-flex>
    </v-footer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('SystemSettings', ['getLogo', 'getCompanyName']),
        ...mapGetters('General', ['getLoggedInAdmin']),
        isLoggedIn() {
            return this.getLoggedInAdmin.role;
        }
    }
};
</script>
