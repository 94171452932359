<template>
    <v-dialog v-model="dialog" :width="$vuetify.breakpoint.thresholds.xs" :fullscreen="$vuetify.breakpoint.xs" scrollable persistent>
        <template #activator="{ on, attrs }">
            <v-btn color="primary" xs12 sm4 block v-bind="attrs" v-on="on">{{ $t('Impressum') }}</v-btn>
        </template>
        <v-card>
            <v-toolbar dense flat dark color="primary">
                <v-toolbar-title>{{ $t('Impressum') }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="dialog = false" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="ck ck-content" v-html="getImpressum" />
            <v-card-actions></v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'impressum',
    data: () => ({
        dialog: false
    }),
    computed: {
        ...mapGetters('SystemSettings', ['getImpressum'])
    }
};
</script>
