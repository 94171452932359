<template>
    <v-container fluid ma-0 pa-0 fill-height>
        <v-layout column>
            <v-flex shrink>
                <Toolbar :tooltipMessage="$t('Hier können Sie die Antworten auf häufig gestellte Fragen einsehen.')" :toolbarTitle="$t('FAQ')" />
            </v-flex>
            <v-flex grow>
                <v-expand-transition mode="out-in">
                    <keep-alive>
                        <component
                            :is="componentSwitch"
                            v-model="computedSelectedFAQ"
                            @getFaq="changeComponent"
                            @addFaq="addFaq"
                            @updateFaq="updateFaq"
                            @deleteFaq="handleDelete(true)"
                        />
                    </keep-alive>
                </v-expand-transition>
            </v-flex>
            <AddUpdateFAQ v-model="showAddUpdateFaq" :type="addUpdateType" />

            <v-dialog v-model="deleteDialog" persistent scrollable :width="$vuetify.breakpoint.thresholds.sm">
                <DeleteConfirm @deleteItem="deleteFaq" @closeEmit="handleDelete(false)" hasToolbar :toolbarTitle="$t('FAQ-Eintrag löschen')" />
            </v-dialog>
        </v-layout>
    </v-container>
</template>

<script>
import Toolbar from '../../Components/Generic/Toolbar';
import Editor from '../../Components/Generic/Editor/Editor';
import FAQDisplay from '../../Components/FAQ/FAQDisplay';
import FAQHomepage from '../../Components/FAQ/FAQHomepage';
import DeleteConfirm from '../../Components/Generic/DeleteConfirm';
import AddUpdateFAQ from '../../Components/FAQ/Helpers/AddUpdateFAQ';
import FAQMixins from '../../Components/FAQ/Mixins/FAQMixins';

export default {
    name: 'faq',
    mixins: [FAQMixins],
    components: { Toolbar, Editor, FAQHomepage, FAQDisplay, DeleteConfirm, AddUpdateFAQ },
    data: () => ({
        addUpdateType: 'ADD',
        showAddUpdateFaq: false,
        deleteDialog: false,
        isFaqSelected: false
    }),
    computed: {
        computedSelectedFAQ: {
            get() {
                return this.selectedFAQ;
            },
            set(value) {
                this.selectedFAQ = value;
                this.isFaqSelected = !this.isFaqSelected;
            }
        },
        componentSwitch() {
            if (this.isFaqSelected) return FAQDisplay;
            return FAQHomepage;
        },
        neededPermissions() {
            return {
                add: true,
                update: true,
                delete: true
            };
        }
    },
    methods: {
        changeComponent(value) {
            this.selectedFAQ = value;
            if (value.hasOwnProperty('id') == false || this.isFaqSelected == false) this.isFaqSelected = !this.isFaqSelected;
        },
        handleDelete(value) {
            this.deleteDialog = value;
        },
        deleteFaq() {
            this.DELETE_FAQ(this.getSelectedFaq).then(() => (this.deleteDialog = false));
            this.isFaqSelected = false;
        },
        updateFaq() {
            this.addUpdateType = 'UPDATE';
            this.showAddUpdateFaq = true;
        },
        addFaq() {
            this.addUpdateType = 'ADD';
            this.showAddUpdateFaq = true;
        }
    },
    mounted() {
        this.GET_FAQS();
        this.GET_FAQ_LIST();
        this.GET_RECENT_CHANGES();
        this.GET_BEST_RATED_FAQS();
    },
    beforeDestroy() {
        this.SET_SELECTED_FAQ_GROUP({});
    }
};
</script>
