import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters('SystemSettings', [
            'getCompanyName',
            'getDomainName',
            'getBackgroundImage',
            'getBackgroundImageStyle',
            'getLogo',
            'getStyles',
            'getImpressum',
            'getDataProtection',
            'getDefaultLanguage',
            'getSelectedLanguage',
            'getSelectedTemplate',
            'getServerProperties',
            'getLanguages',
            'getTemplates',
            'getScheduledTemplates',
            'getScheduledTemplateAvailabileFields',
            'getSelectedScheduledTemplate',
            'getMailOptions'
        ])
    },
    methods: {
        ...mapActions('SystemSettings', [
            'GET_FONTS',
            'SET_FONTS',
            'TEST_CERTIFICATE_WITH_FONT',
            'GET_MENU_CONFIG',
            'SET_MENU_CONFIG',
            'GET_SIGNATURE',
            'UPDATE_SIGNATURE',
            'SEND_TEST_MAIL',
            'UPLOAD_LOGO',
            'UPLOAD_BACKGROUND_IMAGE',
            'CREATE_SCHEDULED_TEMPLATE_TYPE',
            'CREATE_SCHEDULED_TEMPLATE',
            'UPDATE_SCHEDULED_TEMPLATE',
            'GET_GENERIC_SETTINGS',
            'GET_GENERIC_CUSTOMER_SETTINGS',
            'GET_SERVER_PROPERTIES',
            'GET_MAIL_OPTIONS',
            'GET_LANGUAGES',
            'GET_TEMPLATES',
            'GET_SCHEDULED_TEMPLATES',
            'GET_SCHEDULED_TEMPLATE',
            'SET_GENERIC_SETTINGS',
            'SET_SELECTED_LANGUAGE',
            'SET_TEMPLATE',
            'SET_SERVER_PROPERTIES',
            'SET_MAIL_OPTIONS',
            'DELETE_TEMPLATE',
            'DELETE_SCHEDULED_MAIL_TEMPLATE',
            'DELETE_SERVER_PROPERTIES',
            'UPLOAD_CERTIFICATE'
        ]),
        ...mapActions('User', ['GET_SINGLE_USER_BY_ID'])
    }
};
