<template>
    <audio ref="audio" :style="`width: ${width}`" :preload="preload" :controls="controls" :src="src" controlsList="nodownload" disablePictureInPicture />
</template>
<script>
export default {
    name: 'file-preview-video',
    props: {
        width: {
            type: String,
            default: () => '100%'
        },
        preload: {
            type: String,
            default: () => 'auto'
        },
        controls: {
            type: Boolean,
            default: true
        },
        src: {
            type: String,
            default: () => ''
        }
    },
    watch: {
        src() {
            this.$refs.audio.load();
        }
    }
};
</script>
