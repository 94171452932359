<template>
    <v-dialog v-model="showDialog" scrollable :width="$vuetify.breakpoint.thresholds.md" persistent :fullscreen="$vuetify.breakpoint.xs">
        <template #activator="{ on, attrs }">
            <v-tooltip bottom>
                <template #activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                    <v-btn
                        icon
                        v-on="{ ...on, ...tooltipOn }"
                        v-bind="{ ...attrs, tooltipAttrs }"
                        :color="type === 'ADD' ? 'success' : 'warning'"
                        :disabled="computedDisableType"
                        ref="vBtn"
                    >
                        <v-icon>{{ type === 'ADD' ? 'mdi-plus' : 'mdi-pencil' }}</v-icon>
                    </v-btn>
                </template>
                <span>{{ computedTooptip }}</span>
            </v-tooltip>
        </template>
        <v-card>
            <v-toolbar dense flat color="secondary" dark>
                <v-toolbar-title>{{ computedTitle }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-tabs v-model="tabs" slider-color="accent" show-arrows mandatory>
                <v-tab key="KURS">{{ $t('Einstellungen') }}</v-tab>
                <v-tab key="MATERIAL">{{ $t('Materialien') }}</v-tab>
                <v-tab key="IMAGE">{{ $t('Bild hinzufügen') }}</v-tab>
                <v-tab key="CONTENT">{{ $t('Content verlinken') }}</v-tab>
                <v-tab key="USERS">{{ $t('Lernende') }}</v-tab>
                <v-tab key="ADMINS">{{ $t('Lehrende') }}</v-tab>
                <!-- <v-tab key="PREVIEW">{{ $t('Vorschau') }}</v-tab> -->
            </v-tabs>
            <v-card-text class="ma-0 pa-0">
                <v-tabs-items v-model="tabs" touchless>
                    <v-tab-item key="KURS" eager>
                        <v-form v-model="validate">
                            <v-container fluid>
                                <v-row>
                                    <v-col :cols="12">
                                        <v-text-field
                                            :label="$t('Titel')"
                                            :hint="$t('Der Titel vom Kurs')"
                                            persistent-hint
                                            v-model="name"
                                            :rules="[rules('notEmpty')]"
                                        />
                                    </v-col>
                                    <v-col :cols="6">
                                        <DatePicker v-model="startDate" :min="minimumDate" :label="$t('Startdatum')" />
                                    </v-col>
                                    <v-col :cols="6">
                                        <DatePicker v-model="endDate" :min="startDate" :label="$t('Enddatum')" />
                                    </v-col>
                                    <v-col :cols="12">
                                        <v-textarea
                                            :label="$t('Beschreibung')"
                                            :hint="$t('Die Beschreibung des Kurses')"
                                            persistent-hint
                                            v-model="description"
                                            no-resize
                                            :rows="3"
                                        />
                                    </v-col>
                                    <v-col :cols="12">
                                        <v-textarea
                                            :label="$t('Notizen')"
                                            :hint="$t('Notizen, nur für Lehrer sichtbar')"
                                            persistent-hint
                                            v-model="notes"
                                            no-resize
                                            :rows="3"
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-expansion-panels accordion tile>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>{{ $t('Erweiterte Einstellungen') }}</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-container fluid>
                                            <v-row>
                                                <v-col :cols="12">
                                                    <v-switch
                                                        v-model="useEvaluation"
                                                        :label="$t('Bewertung erlauben')"
                                                        :hint="$t('Wenn diese Option eingeschaltet ist, können dem Kurs Noten, Punkte, etc. vergeben werden')"
                                                        persistent-hint
                                                    />
                                                </v-col>
                                                <v-col :cols="6">
                                                    <v-text-field
                                                        :disabled="computedDisableSetScore"
                                                        :label="$t('Minimum Punkte')"
                                                        :hint="$t('Geben Sie eine minimale Punkzahl an, um den Kurs zu bestehen')"
                                                        persistent-hint
                                                        type="number"
                                                        min="0"
                                                        :rules="[rules('notNegative')]"
                                                        v-model="minScore"
                                                    />
                                                </v-col>
                                                <v-col :cols="6">
                                                    <v-text-field
                                                        :disabled="computedDisableSetScore"
                                                        :label="$t('Maximal erreichbare Punkte')"
                                                        :hint="$t('Geben Sie die maximal erreichbare Punktzahl an')"
                                                        persistent-hint
                                                        type="number"
                                                        min="0"
                                                        :rules="[rules('notNegative')]"
                                                        v-model="score"
                                                    />
                                                </v-col>
                                                <v-col :cols="6">
                                                    <v-switch
                                                        :label="$t('Startdatum erzwingen')"
                                                        :hint="$t('Wenn das Startdatum erzwungen wird, wird der Kurs erst zum Startdatum erscheinen')"
                                                        persistent-hint
                                                        v-model="forceStartDate"
                                                    />
                                                </v-col>
                                                <v-col :cols="6">
                                                    <v-switch
                                                        :label="$t('Enddatum erzwingen')"
                                                        :hint="$t('Wenn das Enddatum erzwungen wird, wird der Kurs zum nächsten Tag geschlossen')"
                                                        persistent-hint
                                                        v-model="forceEndDate"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>{{ $t('Erweitertes Zeitmanagement') }}</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-container fluid>
                                            <v-row>
                                                <v-col :cols="6">
                                                    <v-text-field
                                                        :label="$t('Approximierte Arbeitszeit')"
                                                        :hint="$t('Geben Sie eine Zeit in Minuten an, in der der Kurs abzuarbeiten ist')"
                                                        persistent-hint
                                                        type="number"
                                                        min="0"
                                                        :rules="[rules('notNegative')]"
                                                        v-model="estimatedWorkTimeInMinutes"
                                                    />
                                                </v-col>
                                                <v-col :cols="6">
                                                    <v-text-field
                                                        :label="$t('Einheitsdauer')"
                                                        :hint="$t('Geben Sie eine Zahl in Minuten an')"
                                                        :rules="[rules('notNegative')]"
                                                        v-model="timeScaleInMinutes"
                                                        persistent-hint
                                                        type="number"
                                                        min="0"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col :cols="12">
                                                    <v-text-field
                                                        :label="$t('Dauer in Tagen')"
                                                        :hint="$t('Geben Sie die Dauer in Tagen an')"
                                                        persistent-hint
                                                        type="number"
                                                        min="0"
                                                        :rules="[rules('notNegative')]"
                                                        v-model="durationInDays"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>{{ $t('Sichtbarkeitsoptionen') }} </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-container fluid>
                                            <v-row>
                                                <v-col :cols="6">
                                                    <v-switch
                                                        :label="$t('Vor Kursbeginn verbergen')"
                                                        :hint="
                                                            $t(
                                                                'Mit dieser Einstellung können Sie Kurse, welche noch nicht begonnen haben, vor dem Benutzer verbergen'
                                                            )
                                                        "
                                                        persistent-hint
                                                        v-model="hideBeforeStart"
                                                    />
                                                </v-col>
                                                <v-col :cols="6">
                                                    <v-switch
                                                        :label="$t('Dauer in Tagen verbergen')"
                                                        :hint="$t('Mit dieser Einstellung können Sie Dauer in Tagen vor dem Benutzer verbergen')"
                                                        persistent-hint
                                                        v-model="hideDurationInDays"
                                                    />
                                                </v-col>
                                                <v-col :cols="12">
                                                    <v-switch
                                                        :label="$t('Approximierte Arbeitszeit verbergen')"
                                                        :hint="$t('Mit dieser Einstellung können Sie die geschätzte Arbeitszeit vor dem Benutzer verbergen')"
                                                        persistent-hint
                                                        v-model="hideEstimatedWorkTimeInMinutes"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <!-- <v-expansion-panel>
                                    <v-expansion-panel-header>{{ $t('Gruppeneinstellungen') }}</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-container>
                                            <v-row>
                                                <v-col :cols="12">
                                                    <v-switch
                                                        v-model="defineDefaultGroup"
                                                        :label="$t('Gruppen erlauben')"
                                                        :hint="$t('Wenn diese Option eingeschaltet ist, können Kursteilnehmer Gruppen erstellen')"
                                                        persistent-hint
                                                    />
                                                </v-col>
                                                <v-col :cols="6">
                                                    <v-text-field
                                                        :disabled="computedDisableSetScore"
                                                        :label="$t('Minimale Gruppengröße')"
                                                        :hint="$t('Geben Sie eine minimale Teilnehmerzahl an, aus der eine Gruppe bestehen kann')"
                                                        persistent-hint
                                                        type="number"
                                                        min="0"
                                                        :rules="[rules('notNegative')]"
                                                        v-model="groupSizeMin"
                                                    />
                                                </v-col>
                                                <v-col :cols="6">
                                                    <v-text-field
                                                        :disabled="computedDisableSetScore"
                                                        :label="$t('Maximale Gruppengröße')"
                                                        :hint="$t('Geben Sie eine maximale Teilnehmerzahl an, aus der eine Gruppe bestehen kann')"
                                                        persistent-hint
                                                        type="number"
                                                        min="0"
                                                        :rules="[rules('notNegative')]"
                                                        v-model="groupSizeMax"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-expansion-panel-content>
                                </v-expansion-panel> -->
                                <v-expansion-panel>
                                    <v-expansion-panel-header>{{ $t('Benutzerdefinierte Typenbezeichnung') }}</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-container fluid>
                                            <v-row>
                                                <v-col :cols="6">
                                                    <v-text-field
                                                        v-model="typeName"
                                                        :label="$t('Kursbezeichnung')"
                                                        :hint="computedCourseTypeNameHint"
                                                        persistent-hint
                                                        clearable
                                                    />
                                                </v-col>
                                                <v-col :cols="6">
                                                    <v-text-field
                                                        v-model="topicTypeName"
                                                        :label="$t('Themenbezeichnung')"
                                                        :hint="computedTopicTypeNameHint"
                                                        persistent-hint
                                                        clearable
                                                    />
                                                </v-col>
                                                <v-col :cols="6">
                                                    <v-text-field
                                                        v-model="learningGoalTypeName"
                                                        :label="$t('Lernzielebezeichnung')"
                                                        :hint="computedLearningGoalTypeNameHint"
                                                        persistent-hint
                                                        clearable
                                                    />
                                                </v-col>
                                                <v-col :cols="6">
                                                    <v-text-field
                                                        v-model="activityTypeName"
                                                        class=""
                                                        :label="$t('Aktivitätenbezeichnung')"
                                                        :hint="computedActivityTypeNameHint"
                                                        persistent-hint
                                                        clearable
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-form>
                    </v-tab-item>
                    <v-tab-item key="MATERIAL" eager>
                        <v-container fluid>
                            <div style="overflow-x: hidden">
                                <TextEditor v-model="material" />
                            </div>
                        </v-container>
                        <Material v-model="materialMedia" />
                    </v-tab-item>
                    <v-tab-item key="IMAGE" eager>
                        <Filemanager :selectedMedia="picture" @selectedMedia="handleSelectedPicture" selectable />
                    </v-tab-item>
                    <v-tab-item key="CONTENT" eager>
                        <KnowledgeContentManager selectable v-model="knowledgeContents" />
                    </v-tab-item>
                    <v-tab-item keys="USERS" eager>
                        <UserSelector v-model="users" />
                    </v-tab-item>
                    <v-tab-item keys="ADMINS" eager>
                        <UserSelector v-model="admins" :showUsers="false" />
                    </v-tab-item>
                    <!-- <v-tab-item keys="PREVIEW" eager>
                        <AddCourseUserPreview v-model="showModal" :serializedData="serializedData" />
                    </v-tab-item> -->
                </v-tabs-items>
            </v-card-text>
            <v-card-actions>
                <v-btn block :color="computedSubmitButtonColor" @click="save" :disabled="!validate">
                    {{ computedSubmitButtonText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
// import DatePicker from '../../Generic/PickerDate/PickerDate';
import DatePicker from '../../Generic/DatePicker/DatePicker';
import TextEditor from '../../Generic/TextEditor';
import UserSelector from '../../Generic/Tree/UserGroupTreeSelector';
import Filemanager from '../../FileManager/Prototype/Filemanager';
import KnowledgeContentManager from '../../KnowledgeContent/KnowledgeContentManager';
import CourseMixin from './Mixins/CourseMixin';
import KnowledgeContentMixin from '../../KnowledgeContent/Mixins/KnowledgeContentMixin';
// import AddCourseUserPreview from './AddCourseUserPreview';
import Material from './Material';
import moment from 'moment';

export default {
    name: 'add-update-course',
    components: {
        Material,
        DatePicker,
        TextEditor,
        UserSelector,
        Filemanager,
        KnowledgeContentManager
        // AddCourseUserPreview
    },
    mixins: [CourseMixin, KnowledgeContentMixin],
    data: () => ({
        showDialog: false,
        showModal: false,
        showAdd: false,
        validate: false,
        users: [],
        admins: [],
        tabs: 0,
        id: '',
        name: '',
        description: '',
        notes: '',
        estimatedWorkTimeInMinutes: 0,
        durationInDays: '',
        folderId: '',
        forceStartDate: false,
        forceEndDate: false,
        hideBeforeStart: false,
        hideEstimatedWorkTimeInMinutes: false,
        hideDurationInDays: false,
        knowledgeContents: [],
        material: '',
        materialMedia: [],
        useEvaluation: true,
        defineDefaultGroup: false,
        groupSizeMin: 0,
        groupSizeMax: 0,
        score: 0,
        minScore: 0,
        picture: {},
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        timeScaleInMinutes: 60,
        serializedData: {},
        coursePicturePath: {},
        initialLoad: false,
        typeName: null,
        topicTypeName: null,
        learningGoalTypeName: null,
        activityTypeName: null
    }),
    watch: {
        showDialog(value) {
            if (value && !this.initialLoad) {
                this.initialLoad = true;
            }
            if (value) {
                if (this.type === 'UPDATE') {
                    Object.entries(this.getSelectedAdminCourse).forEach(([key, value]) => (this.$data[key] = value));
                    this.GET_COURSE_ADMINS_AND_ATTENDEES(this.getSelectedAdminCourse).then((response) => {
                        this.users = response.users;
                        this.admins = response.admins;
                    });
                    this.GET_COURSE_MATERIALS(this.getSelectedAdminCourse).then(() => (this.materialMedia = this.getMaterial(this.getSelectedAdminCourse)));
                }
            }
        },
        startDate(value) {
            if (moment(value).isAfter(moment(this.endDate))) {
                this.endDate = moment(value).format('YYYY-MM-DD');
            }
        },
        minScore(value) {
            const toNumber = Number(value);
            if (toNumber >= this.score) {
                this.score = toNumber;
            }
        },
        tabs(value) {
            if (value === 6) {
                this.openUserPreview();
            }
        }
    },
    props: {
        type: {
            type: String,
            default: 'ADD',
            validator: (value) => ['ADD', 'UPDATE'].includes(value)
        }
    },
    computed: {
        computedDisableType() {
            if (this.type === 'ADD') {
                return false;
            }
            if (this.type === 'UPDATE') {
                return !Object.keys(this.getSelectedAdminCourse).length > 0;
            }
            return false;
        },
        computedTooptip() {
            switch (this.type) {
                case 'ADD':
                    return this.$t('Kurs erstellen');
                case 'UPDATE':
                    return this.$t('Kurs bearbeiten');
            }
            return '';
        },
        computedDisableSetScore() {
            if (!this.useEvaluation) {
                return true;
            }
            return false;
        },
        computedCourseTypeNameHint() {
            return this.$t('Sie können statt `Kurs` eine eigene Bezeichnung festlegen');
        },
        computedTopicTypeNameHint() {
            return this.$t('Sie können statt `Thema` eine eigene Bezeichnung festlegen');
        },
        computedLearningGoalTypeNameHint() {
            return this.$t('Sie können statt `Lernziel` eine eigene Bezeichnung festlegen');
        },
        computedActivityTypeNameHint() {
            return this.$t('Sie können statt `Aktivität` eine eigene Bezeichnung festlegen');
        },
        computedTitle() {
            switch (this.type) {
                case 'ADD':
                    return this.$t('Kurs erstellen');

                case 'UPDATE':
                    if (this.typeName !== null && this.typeName !== '') {
                        return `${this.typeName} ${this.$t('bearbeiten')}`;
                    }
                    return this.$t('Kurs bearbeiten');
            }
            return '';
        },
        computedSubmitButtonText() {
            switch (this.type) {
                case 'ADD':
                    return this.$t('Hinzufügen');
                case 'UPDATE':
                    return this.$t('Aktualisieren');
            }
            return '';
        },
        computedSubmitButtonColor() {
            switch (this.type) {
                case 'ADD':
                    return 'success';
                case 'UPDATE':
                    return 'warning';
            }
            return '';
        },
        minimumDate() {
            return moment().format('YYYY-MM-DD');
        },
        handleDurationInDays() {
            if (this.durationInDays === '') {
                return moment(this.endDate).diff(this.startDate, 'days') + 1;
            } else if (this.durationInDays !== moment(this.endDate).diff(this.startDate, 'days') + 1) {
                return this.durationInDays;
            }
            return '';
        },
        timeScaleInMinutesDefaultSetter() {
            if (this.timeScaleInMinutes == 0) {
                return 60;
            }
            return this.timeScaleInMinutes;
        }
    },
    methods: {
        handleSelectedMaterialMedia(media) {
            this.materialMedia = media;
        },
        openUserPreview() {
            this.serializedData = this.serialize();
            this.showModal = true;
        },
        addItem() {
            this.showAdd = true;
        },
        handleSelectedPicture(value) {
            this.picture = value;
        },
        serialize() {
            return {
                id: this.id,
                name: this.name,
                description: this.description,
                notes: this.notes,
                durationInDays: this.handleDurationInDays,
                estimatedWorkTimeInMinutes: this.estimatedWorkTimeInMinutes,
                folderId: this.folderId,
                forceStartDate: this.forceStartDate,
                forceEndDate: this.forceEndDate,
                knowledgeContents: this.knowledgeContents,
                material: this.material,
                score: this.score,
                minScore: this.minScore,
                startDate: this.startDate,
                endDate: this.endDate,
                picture: this.picture,
                hideBeforeStart: this.hideBeforeStart,
                hideEstimatedWorkTimeInMinutes: this.hideEstimatedWorkTimeInMinutes,
                hideDurationInDays: this.hideDurationInDays,
                timeScaleInMinutes: this.timeScaleInMinutesDefaultSetter,
                typeName: this.typeName,
                topicTypeName: this.topicTypeName,
                learningGoalTypeName: this.learningGoalTypeName,
                activityTypeName: this.activityTypeName,
                useEvaluation: this.useEvaluation,
                defineDefaultGroup: this.defineDefaultGroup,
                groupSizeMin: this.groupSizeMin,
                groupSizeMax: this.groupSizeMax
            };
        },
        reset() {
            this.tabs = 0;
            this.users = [];
            this.id = '';
            this.name = '';
            this.description = '';
            this.notes = null;
            this.estimatedWorkTimeInMinutes = 0;
            this.folderId = '';
            this.forceStartDate = false;
            this.forceEndDate = true;
            this.knowledgeContents = [];
            this.material = '';
            this.materialMedia = [];
            this.score = 0;
            this.minScore = 0;
            this.picture = {};
            this.startDate = moment().format('YYYY-MM-DD');
            this.endDate = moment().format('YYYY-MM-DD');
            this.hideEndDate = false;
            this.hideEstimatedWorkTimeInMinutes = false;
            this.hideDurationInDays = false;
            this.durationInDays = '';
            this.timeScaleInMinutes = 60;
            this.typeName = null;
            this.topicTypeName = null;
            this.learningGoalTypeName = null;
            this.activityTypeName = null;
            this.useEvaluation = true;
            this.defineDefaultGroup = false;
            (this.groupSizeMin = 0), (this.groupSizeMax = 0);
        },
        close() {
            this.showDialog = false;
            this.$nextTick(() => {
                this.reset();
                this.$refs.vBtn.$el.blur();
            });
        },
        saveAdminsAndAttendees(course) {
            this.UPDATE_COURSE_ADMINS_AND_ATTENDEES({
                course: course,
                admins: this.admins,
                users: this.users
            });
            return course;
        },
        saveMaterials(course) {
            const materialList = this.materialMedia.map((material) => {
                if (material.hasOwnProperty('id')) {
                    return this.UPDATE_MATERIAL(material);
                }
                return this.CREATE_MATERIAL(material);
            });
            Promise.all(materialList).then((result) => {
                this.UPDATE_COURSE_MATERIALS({ ...course, materials: result });
            });
            return course;
        },
        save() {
            switch (this.type) {
                case 'ADD': {
                    this.CREATE_COURSE(this.serialize())
                        .then(this.saveAdminsAndAttendees)
                        .then(this.saveMaterials)
                        .then(this.GET_REMAINING_COURSES())
                        .then(() => this.close());
                    return;
                }
                case 'UPDATE': {
                    this.UPDATE_COURSE(this.serialize())
                        .then(this.saveAdminsAndAttendees)
                        .then(this.saveMaterials)
                        .then(() => this.close());
                    return;
                }
            }
        },
        rules(type) {
            switch (type) {
                case 'notNegative':
                    return (v) => {
                        if (v !== undefined && v !== null) {
                            const toNumber = Number(v);
                            return toNumber >= 0 || this.$t('Bitte geben Sie eine Zahl größer oder gleich 0 an');
                        }
                    };
                case 'notEmpty':
                    return (v) => !!v || this.$t('Dieses Feld darf nicht leer sein');
            }
        }
    },
    beforeMount() {
        this.GET_KNOWLEDGE_CONTENTS();
    }
};
</script>
