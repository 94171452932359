import axios from '../../../plugins/Axios/axios';

const state = {
    defaultTaxonomies: [],
    courseTaxonomies: {}
};

const getters = {
    getDefaultTaxonomies() {
        return state.defaultTaxonomies;
    },
    getCourseTaxonomies() {
        return state.courseTaxonomies;
    }
};

const actions = {
    GET_DEFAULT_TAXONOMIES({ commit }) {
        return axios
            .get(`/courselearning/default/taxonomies`)
            .then((response) => response.data.data)
            .then((response) => commit('updateDefaultTaxonomies', response));
    },
    GET_COURSE_TAXONOMY_TABLE({ commit }, request) {
        const { id: courseInsId } = request;
        if (courseInsId === undefined || courseInsId === null) {
            commit('updateCourseTaxonomies', undefined);
        }
        return axios
            .get(`/courselearning/courseinstances/${courseInsId}/taxonomytable`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateCourseTaxonomies', response);
            });
    },
    UPDATE_COURSE_TAXONOMY_TABLE({ commit }, { course: { id: courseInsId }, taxonomyTable: { id: taxonomyTableId } }) {
        return axios
            .post(`/courselearning/courseinstances/${courseInsId}/taxonomytable`, taxonomyTableId)
            .then((response) => response.data.data)
            .then((response) => commit('updateCourseTaxonomies', response));
    },
    // Taxonomy Tables
    GET_TAXONOMY_TABLE({ commit }, { id: taxonomyTableId }) {
        return axios.get(`/courselearning/taxonomytableinstances/${taxonomyTableId}`).then((response) => response.data.data);
    },
    CREATE_TAXONOMY_TABLE({ commit }, request) {
        return axios.post(`/courselearning/taxonomytableinstances`, request).then((response) => response.data.data);
    },
    UPDATE_TAXONOMY_TABLE({ commit }, request) {
        const { id: taxonomyTableId, ...taxonomyTable } = request;
        return axios.patch(`/courselearning/taxonomytableinstances/${taxonomyTableId}`, taxonomyTable).then((response) => response.data.data);
    },
    DELETE_TAXONOMY_TABLE({ commit }, { id: taxonomyTableId }) {
        return axios.delete(`/courselearning/courseinstances/${taxonomyTableId}/taxonomytable`);
    },
    // Taxonomy
    CREATE_TAXONOMY({ commit }, request) {
        return axios.post(`/courselearning/taxonomyinstances`, request).then((response) => response.data.data);
    },
    UPDATE_TAXONOMY({ commit }, request) {
        const { id: taxonomyTableId, ...taxonomy } = request;
        return axios.patch(`/courselearning/taxonomyinstances/${taxonomyTableId}`, taxonomy).then((response) => response.data.data);
    },
    DELETE_TAXONOMY({ commit }, { id: taxonomyId }) {
        return axios.delete(`/courselearning/taxonomyinstances/${taxonomyId}`);
    }
};

const mutations = {
    clear: (state) => {
        state.defaultTaxonomies = [];
        state.courseTaxonomies = {};
    },
    updateDefaultTaxonomies: (state, request) => (state.defaultTaxonomies = request),
    updateCourseTaxonomies: (state, request) => {
        if (request === undefined || request === null) {
            state.courseTaxonomies = {};
        } else {
            state.courseTaxonomies = request;
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
