<template>
    <v-layout align-center>
        <v-btn icon @click.stop="handleClick" @mousedown.stop>
            <v-icon :color="checked ? 'primary' : ''">{{ checkedIcon }}</v-icon>
        </v-btn>
        <v-label v-if="label !== ''" @click.self="handleClick">{{ label }}</v-label>
    </v-layout>
</template>

<script>
export default {
    name: 'group-radio',
    props: {
        checked: {
            type: Boolean,
            default: false
        },
        value: {
            type: null,
            default: null
        },
        label: {
            type: String,
            default: ''
        }
    },
    methods: {
        handleClick() {
            this.$emit('input', this.value);
        }
    },
    computed: {
        checkedIcon() {
            if (this.checked) {
                return this.$vuetify.icons.radioOn;
            }
            return this.$vuetify.icons.radioOff;
        }
    }
};
</script>
