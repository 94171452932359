import SockJS from 'sockjs-client';
import Stomp from 'webstomp-client';
import axios from '../../plugins/Axios/axios';
import Vue from 'vue';

// state
const state = {
    socket: null,
    client: null,
    userMap: {},
    messageMap: {},
    unreadMessageMap: {},
    totalUnreadMessages: 0,
    groupMap: {},
    favouriteUsers: [],
    selectedChatUser: {},
    selectedChatGroup: {},
    //=== Settings ===
    notificationScheduleConfig: {},
    personalChatNotificationConfig: {},
    userGroupChatNotificationConfig: {},
    adminGroupChatNotificationConfig: {},
    //=== MISC ===
    activeTaskCount: 0,
    wikiProposalCount: 0,
    totalMyCourseCount: 0,
    totalCourseCount: 0,
    totalElearningCount: 0
};

// getters
const getters = {
    getSocket() {
        return state.socket;
    },
    getClient() {
        return state.client;
    },
    getGroupIds() {
        return Object.keys(state.groupMap);
    },
    getGroups() {
        return Object.values(state.groupMap);
    },
    getGroup() {
        return (groupId) => {
            if (helpers.hasGroup(groupId)) {
                return state.groupMap[groupId];
            }
            return {};
        };
    },
    getUserIds() {
        return Object.entries(state.userMap)
            .sort(([, value1], [, value2]) => value2.online - value1.online)
            .sort(([, value1], [, value2]) => state.favouriteUsers.includes(value2.id) - state.favouriteUsers.includes(value1.id))
            .map(([key]) => key);
    },
    getUsers() {
        return Object.values(state.userMap);
    },
    getUser() {
        return (userId) => {
            if (helpers.hasUserMapUser(userId)) {
                return state.userMap[userId];
            }
            return {};
        };
    },
    getMessages() {
        return (id) => {
            if (helpers.hasMessage(id)) {
                return state.messageMap[id];
            }
            return [];
        };
    },
    getUnreadMessages() {
        return (id) => {
            if (helpers.hasUnreadMessages(id)) {
                return state.unreadMessageMap[id];
            }
            return 0;
        };
    },
    getTotalUnreadMessages() {
        return state.totalUnreadMessages;
    },
    getNotificationScheduleConfig() {
        return state.notificationScheduleConfig;
    },
    getPersonalChatConfig() {
        return state.personalChatNotificationConfig;
    },
    getUserGroupChatConfig() {
        return state.userGroupChatNotificationConfig;
    },
    getAdminGroupChatConfig() {
        return state.adminGroupChatNotificationConfig;
    },
    getFavouriteUsers() {
        return state.favouriteUsers;
    },
    getSelectedChatUser() {
        return state.selectedChatUser;
    },
    getSelectedChatGroup() {
        return state.selectedChatGroup;
    },
    getActiveTaskCount() {
        return state.activeTaskCount;
    },
    getWikiProposalCount() {
        return state.wikiProposalCount;
    },
    getTotalMyCourseCount() {
        return state.totalMyCourseCount;
    },
    getTotalCourseCount() {
        return state.totalCourseCount;
    },
    getTotalElearningCount() {
        return state.totalElearningCount;
    }
};

// actions
const actions = {
    SET_SELECTED_CHAT_USER({ commit }, user) {
        commit('updateSelectedChatUser', user);
    },
    SET_SELECTED_CHAT_GROUP({ commit }, group) {
        commit('updateSelectedChatGroup', group);
    },
    // This Function will probably have to be removed at some point
    GET_SUPER_ADMINS() {
        return axios.get(`/superadmins`).then((response) => response.data.data.admins);
    },
    // helper Action
    BUILD_ADMIN_GROUPS({ dispatch }) {
        return dispatch('Admin/GET_ADMIN_GROUPS_BY_ADMIN', null, {
            root: true
        }).then((response) => {
            return response.reduce((accu, { id, ...group }) => {
                accu[id] = { id, ...group, isAdminGroup: true };
                return accu;
            }, {});
        });
    },
    // helper Action
    BUILD_USER_GROUPS({ dispatch }) {
        return dispatch('User/GET_USER_GROUPS_BY_USER', null, {
            root: true
        }).then((response) => {
            return response.reduce((accu, { id, ...group }) => {
                accu[id] = { id, ...group, isAdminGroup: false };
                return accu;
            }, {});
        });
    },
    BUILD_GROUP_LIST({ dispatch, commit, rootGetters }) {
        const { role } = rootGetters['General/getLoggedInAdmin'];
        switch (role) {
            case 'ADMIN':
                return dispatch('BUILD_ADMIN_GROUPS').then((map) => {
                    commit('updateGroupMap', map);
                    return map;
                });
            case 'USER':
                return dispatch('BUILD_USER_GROUPS').then((map) => {
                    commit('updateGroupMap', map);
                    return map;
                });
            case 'SUPER_ADMIN':
                return Promise.all([
                    dispatch('AdminGroupsOp/GET_ADMIN_GROUP_ROOT', null, {
                        root: true
                    }).then((groups) =>
                        groups.map((group) => ({
                            ...group,
                            isAdminGroup: true
                        }))
                    ),
                    dispatch('UserGroupsOp/GET_USER_GROUP_ROOT', null, {
                        root: true
                    }).then((groups) =>
                        groups.map((group) => ({
                            ...group,
                            isAdminGroup: false
                        }))
                    )
                ]).then((result) => {
                    const map = result.flat().reduce((accu, { id, ...group }) => {
                        accu[id] = { id, ...group };
                        return accu;
                    }, {});
                    commit('updateGroupMap', map);
                    return map;
                });
        }
    },
    BUILD_USER_LIST({ dispatch, commit, rootGetters }) {
        return Promise.all([
            dispatch('Admin/GET_ADMIN_LIST', null, { root: true }),
            dispatch('User/GET_USER_LIST', null, { root: true }),
            dispatch('GET_SUPER_ADMINS')
        ]).then((result) => {
            const map = result
                .flat()
                .filter((user) => user.id !== rootGetters['General/getLoggedInAdmin'].id)
                .reduce((accu, { id, ...user }) => {
                    accu[id] = { id, ...user };
                    return accu;
                }, {});
            commit('updateUserMap', map);
            return map;
        });
    },
    CHAT_CONNECT({ commit, dispatch }, request) {
        return dispatch('BUILD_USER_LIST')
            .then(() => dispatch('BUILD_GROUP_LIST'))
            .then(() => commit('clientConnect', request));
    },
    CHAT_DISCONNECT({ commit }) {
        commit('clear');
    },
    CHAT_GET_GENERAL_NOTIFICATIONS({ commit }) {
        return axios
            .get(`/chat/notifications/schedule`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateNotificationsSchedule', response);
                return response;
            });
    },
    CHAT_SAVE_GENERAL_NOTIFICATIONS({ dispatch }, request) {
        return axios.post(`/chat/notifications/schedule`, request).then(() => dispatch(`CHAT_GET_GENERAL_NOTIFICATIONS`));
    },
    CHAT_GET_PERSONAL_NOTIFICATIONS({ commit }) {
        return axios
            .get(`/chat/notifications/messages/config/personal`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updatePersonalChatConfig', response);
                return response;
            });
    },
    CHAT_SAVE_PERSONAL_NOTIFICATIONS({ dispatch }, request) {
        if (Array.isArray(request)) {
            request = { senderIds: request.map((id) => ({ id: id })) };
        }
        return axios.post(`/chat/notifications/messages/config/personal`, request).then(() => dispatch('CHAT_GET_PERSONAL_NOTIFICATIONS'));
    },
    CHAT_GET_USER_GROUP_NOTIFICATIONS({ commit }) {
        return axios
            .get(`/chat/notifications/messages/config/usergroups`)
            .then((response) => response.data.data.groups)
            .then((response) => {
                commit('updateUserGroupChatConfig', response);
                return response;
            });
    },
    CHAT_SAVE_USER_GROUP_NOTIFICATIONS({ dispatch }, request) {
        if (Array.isArray(request)) {
            request = { groups: request.map((id) => ({ id: id })) };
        }
        return axios.post(`/chat/notifications/messages/config/admingroups`, request).then(() => dispatch('CHAT_GET_USER_GROUP_NOTIFICATIONS'));
    },
    CHAT_GET_ADMIN_GROUP_NOTIFICATIONS({ commit }) {
        return axios
            .get(`/chat/notifications/messages/config/admingroups`)
            .then((response) => response.data.data.groups)
            .then((response) => {
                commit('updateAdminGroupChatConfig', response);
                return response;
            });
    },
    CHAT_SAVE_ADMIN_GROUP_NOTIFICATIONS({ dispatch }, request) {
        if (Array.isArray(request)) {
            request = { groups: request.map((id) => ({ id: id })) };
        }
        return axios
            .post(`/chat/notification/admingroupmessages/config`, request)
            .then((response) => response.data.data)
            .then(() => dispatch('CHAT_GET_ADMIN_GROUP_NOTIFICATIONS'));
    },
    CHAT_SEND_PERSONAL_MESSAGE({ commit }, request) {
        commit('clientSendPersonalMessage', request);
    },
    CHAT_SEND_GROUP_MESSAGE({ commit }, request) {
        commit('clientSendGroupMessage', request);
    },
    CHAT_GET_PERSONAL_MESSAGES_BY_ID({ commit }, request) {
        commit('clientGetPersonalMessagesById', request);
    },
    CHAT_GET_GROUP_MESSAGES_BY_ID({ commit }, request) {
        commit('clientGetGroupMessagesById', request);
    },
    CHAT_GET_UNREAD_MESSAGES({ commit }) {
        commit('clientGetUnreadMessages');
    },
    CHAT_GET_FAVOURITE_USERS({ commit }) {
        return axios
            .get(`chat/favourite/users`)
            .then((response) => response.data.data.users)
            .then((response) => {
                commit('updateFavouriteUsers', response);
                return response;
            });
    },
    CHAT_SAVE_FAVOURITE_USERS({ commit }, users) {
        if (Array.isArray(users)) {
            users = { users: users };
        }
        return axios
            .post(`/chat/favourite/users`, users)
            .then((response) => response.data.data.users)
            .then((response) => {
                commit('updateFavouriteUsers', response);
                return response;
            });
    }
};

const helpers = {
    handleTaskCount: (count) => (state.activeTaskCount = count),
    handleWikiProposalCount: (count) => (state.wikiProposalCount = count),
    handleTotalMyCourseCount: (count) => (state.totalMyCourseCount = count),
    handleTotalCourseCount: (count) => (state.totalCourseCount = count),
    handleTotalElearningCount: (count) => (state.totalElearningCount = count),
    hasUserMapUser: (userId) => state.userMap.hasOwnProperty(userId),
    hasMessage: (id) => state.messageMap.hasOwnProperty(id),
    hasUnreadMessages: (id) => state.unreadMessageMap.hasOwnProperty(id),
    hasGroup: (groupId) => state.groupMap.hasOwnProperty(groupId),
    isAdminGroup: (groupId) => {
        if (state.groupMap.hasOwnProperty(groupId)) {
            return state.groupMap[groupId].isAdminGroup;
        }
        return undefined;
    },
    handleJoin: ({ onlineUsers, time, senderId }) => {
        if (onlineUsers !== undefined && onlineUsers !== null && onlineUsers.length > 0) {
            onlineUsers.forEach((user) => {
                if (helpers.hasUserMapUser(user.id)) {
                    Vue.set(state.userMap, user.id, {
                        ...state.userMap[user.id],
                        online: true,
                        onlineAt: time
                    });
                }
            });
        }
        if (senderId !== undefined && senderId !== null) {
            if (helpers.hasUserMapUser(senderId)) {
                Vue.set(state.userMap, senderId, {
                    ...state.userMap[senderId],
                    online: true,
                    onlineAt: time
                });
            }
        }
    },
    handleLeave: ({ senderId }) => {
        if (senderId !== undefined && senderId !== null) {
            if (helpers.hasUserMapUser(senderId)) {
                Vue.set(state.userMap, senderId, {
                    ...state.userMap[senderId],
                    online: false,
                    onlineAt: undefined
                });
            }
        }
    },
    // initial message load
    handleMessages: ({ receiverId, messages, groupId }) => {
        if (groupId !== undefined && groupId !== null && groupId !== '') {
            Vue.set(state.messageMap, groupId, messages);
            helpers.markMessagesAsRead(groupId, helpers.isAdminGroup(groupId) ? 'ADMIN_GROUP_CHAT' : 'USER_GROUP_CHAT');
            Vue.set(state.unreadMessageMap, groupId, 0);
            return;
        }
        if (receiverId !== undefined && receiverId !== null && receiverId !== '') {
            Vue.set(state.messageMap, receiverId, messages);
            helpers.markMessagesAsRead(receiverId, 'PERSONAL_CHAT');
            Vue.set(state.unreadMessageMap, receiverId, 0);
            return;
        }
    },
    handleGroupMessages: ({ groupId, messages }) => {
        if (groupId !== undefined && groupId !== null && groupId !== '' && messages !== null && messages.length > 0) {
            messages.forEach((message) => {
                // if (helpers.hasMessage(groupId)) {
                const messages = state.messageMap[groupId] ? state.messageMap[groupId] : [];
                Vue.set(state.messageMap, groupId, [...messages, { ...message, isNewMessage: true }]);
                if (state.selectedChatGroup !== null && state.selectedChatGroup !== undefined) {
                    if (state.selectedChatGroup.hasOwnProperty('id')) {
                        if (state.selectedChatGroup.id === groupId) {
                            helpers.markMessagesAsRead(groupId, helpers.isAdminGroup(groupId) ? 'ADMIN_GROUP_CHAT' : 'USER_GROUP_CHAT');
                            return;
                        }
                    }
                }
                state.client.send(`/app/getMessages/unread`, '', {
                    sessionId: ''
                });
                // }
            });
        }
    },
    // perpetual message load
    handlePersonalChat: ({ receiverId, messages }) => {
        if (receiverId !== undefined && receiverId !== null && messages.length > 0) {
            messages.forEach((message) => {
                // if (helpers.hasMessage(receiverId)) {
                const messages = state.messageMap[receiverId] ? state.messageMap[receiverId] : [];
                Vue.set(state.messageMap, receiverId, [...messages, { ...message, isNewMessage: true }]);
                if (state.selectedChatUser !== null && state.selectedChatUser !== undefined) {
                    if (state.selectedChatUser.hasOwnProperty('id')) {
                        if (state.selectedChatUser.id === receiverId) {
                            helpers.markMessagesAsRead(receiverId, 'PERSONAL_CHAT');
                            return;
                        }
                    }
                }
                state.client.send(`/app/getMessages/unread`, '', {
                    sessionId: ''
                });
                // }
            });
        }
    },
    handleTotalUnreadMessages: (unreadTotalMessages) => {
        state.totalUnreadMessages = unreadTotalMessages;
    },
    handleUnreadMessages: (unreadPersonalMessages) => {
        if (unreadPersonalMessages.length > 0) {
            unreadPersonalMessages.forEach(({ unReadCount: count, receiverId, groupId }) => {
                if (receiverId === undefined && groupId !== undefined) {
                    Vue.set(state.unreadMessageMap, groupId, count);
                }
                if (receiverId !== undefined && groupId === undefined) {
                    Vue.set(state.unreadMessageMap, receiverId, count);
                }
            });
        }
    },
    markMessagesAsRead: (id, type) => {
        if (helpers.hasMessage(id)) {
            const unreadMessages = state.messageMap[id].filter((message) => !message.read).map((message) => message.messageId);
            if (unreadMessages.length > 0) {
                const markedMessages = state.messageMap[id].map((message) => {
                    if (unreadMessages.includes(message.messageId)) {
                        message.read = true;
                    }
                    return message;
                });
                Vue.set(state.messageMap, id, markedMessages);
                state.client.send('/app/markasread', JSON.stringify({ messages: unreadMessages, type }), { sessionId: '' });
            }
        }
    },
    handleFrame: ({
        type,
        senderId,
        receiverId,
        groupId,
        messages,
        time,
        onlineUsers,
        userUnReadMessageCount: unreadPersonalMessages,
        userGroupUnReadMessagesCount: unreadUserGroupMessages,
        adminGroupUnReadMessagesCount: unreadAdminGroupMessages,
        totalUnReadMessageCount: totalUnreadMessages,
        totalMyCourseCount: totalMyCourseCount,
        totalCourseCount: totalCourseCount,
        totalElearningCount: totalElearningCount,
        current,
        count
    }) => {
        switch (type) {
            case 'WIKI_PROPOSAL_COUNT':
                return Promise.resolve(helpers.handleWikiProposalCount(count));
            case 'TOTAL_MYCOURSE_COUNT':
                return Promise.all([
                    helpers.handleTotalMyCourseCount(totalMyCourseCount),
                    helpers.handleTotalCourseCount(totalCourseCount),
                    helpers.handleTotalElearningCount(totalElearningCount)
                ]);
            case 'ACTIVE_TASK_COUNT':
                return Promise.resolve(helpers.handleTaskCount(count));
            case 'ONLINEUSERS':
                return Promise.resolve(helpers.handleJoin({ onlineUsers, time, senderId })).then(() =>
                    state.client.send(`/app/getMessages/unread`, '', {
                        sessionId: ''
                    })
                );
            case 'JOIN':
                return Promise.resolve(helpers.handleJoin({ onlineUsers, time, senderId })).then(() =>
                    state.client.send(`/app/getMessages/unread`, '', {
                        sessionId: ''
                    })
                );
            case 'LEAVE':
                return Promise.resolve(helpers.handleLeave({ senderId })).then(() =>
                    state.client.send(`/app/getMessages/unread`, '', {
                        sessionId: ''
                    })
                );
            case 'MESSAGES':
                return Promise.resolve(helpers.handleMessages({ receiverId, messages, groupId }));
            case 'PERSONAL_CHAT':
                return Promise.resolve(
                    helpers.handlePersonalChat({
                        receiverId,
                        messages,
                        current
                    })
                );
            case 'ADMIN_GROUP_CHAT':
                return Promise.resolve(helpers.handleGroupMessages({ groupId, messages }));
            case 'USER_GROUP_CHAT':
                return Promise.resolve(helpers.handleGroupMessages({ groupId, messages }));
            case 'UNREADMESSAGES':
                return Promise.all([
                    helpers.handleTotalUnreadMessages(totalUnreadMessages),
                    helpers.handleUnreadMessages(unreadPersonalMessages),
                    helpers.handleUnreadMessages(unreadUserGroupMessages),
                    helpers.handleUnreadMessages(unreadAdminGroupMessages)
                ]);
            default:
                return new Promise((resolve) => resolve());
        }
    }
};

// mutations
const mutations = {
    clear: (state) => {
        if (state.client !== null && state.socket !== null) {
            state.client.disconnect();
            state.socket.close();
        }
        state.socket = null;
        state.client = null;
        state.userMap = {};
        state.messages = [];
        state.selectedChatGroup = {};
        state.selectedChatUser = {};
        state.notificationScheduleConfig = {};
        state.personalChatNotificationConfig = {};
        state.userGroupChatNotificationConfig = {};
        state.adminGroupChatNotificationConfig = {};
    },
    updateGroupMap: (state, groups) => (state.groupMap = groups),
    updateUserMap: (state, users) => (state.userMap = users),
    updateSelectedChatUser: (state, user) => (state.selectedChatUser = user),
    updateSelectedChatGroup: (state, group) => (state.selectedChatGroup = group),
    clientConnect: (state, { id: adminId }) => {
        const baseURL = axios.defaults.baseURL;
        if (state.socket === null) {
            state.socket = new SockJS(`${baseURL}socket?userId=${adminId}`);
        } else {
            return;
        }
        state.client = Stomp.over(state.socket, {
            protocols: ['v12.stomp'],
            debug: false
        });
        // console.log('stomp-client:', state.client);
        state.client.connect(
            {},
            () => {
                state.client.subscribe('/user/queue/updates', ({ body }) => {
                    helpers.handleFrame({ ...JSON.parse(body), current: adminId }).then(() => window.dispatchEvent(new Event('ChatUpdate')));
                    // console.log('socket-resp:', body);
                });
                if (state.client && state.client.connected) {
                    state.client.send('/app/useronline', JSON.stringify({ sender: adminId, type: 'JOIN' }), { sessionId: '' });
                    state.client.send(`/app/getMessages/unread`, '', {
                        sessionId: ''
                    });
                    state.client.send(`/app/tasks/count`, '', {
                        sessionId: ''
                    });
                    state.client.send(`/app/wiki/count`, '', { sessionId: '' });
                    state.client.send(`/app/users/courses/count`, '', { sessionId: '' });
                }
            },
            (error) => console.error(error)
        );
    },
    clientSendPersonalMessage: (state, { senderId, receiverId, content: message }) => {
        if (state.client && state.client.connected) {
            state.client.send(
                '/app/sendMessage',
                JSON.stringify({
                    senderId: senderId,
                    receiverId: receiverId,
                    type: 'PERSONAL_CHAT',
                    content: message,
                    hasAttachment: false,
                    tags: 'tag1 tag2'
                }),
                { sessionId: '' }
            );
        } else {
            throw new Error('No Connection to the Chat Service');
        }
    },
    clientSendGroupMessage: (state, { senderId, receiverId, group: { id: groupId, isAdminGroup }, content: message }) => {
        if (state.client && state.client.connected) {
            state.client.send(
                '/app/sendMessage',
                JSON.stringify({
                    senderId: senderId,
                    groupId: groupId,
                    groupType: isAdminGroup ? 'ADMINGROUP' : 'USRGROUP',
                    receiverId: receiverId,
                    type: isAdminGroup ? 'ADMIN_GROUP_CHAT' : 'USER_GROUP_CHAT',
                    content: message,
                    hasAttachment: false,
                    tags: 'tag1 tag2'
                }),
                { sessionId: '' }
            );
        } else {
            throw new Error('No Connection to the Chat Service');
        }
    },
    clientGetGroupMessagesById: (state, { id: groupId, isAdminGroup }) => {
        if (state.client && state.client.connected) {
            state.client.send(
                `/app/getMessages`,
                JSON.stringify({
                    ...(isAdminGroup ? { adminGroupId: groupId } : { userGroupId: groupId })
                }),
                { sessionId: '' }
            );
        }
    },
    clientGetPersonalMessagesById: (state, { id: userId }) => {
        if (state.client && state.client.connected) {
            state.client.send(`/app/getMessages`, JSON.stringify({ userId: userId }), { sessionId: '' });
        } else {
            throw new Error('No Connection to the Chat Service');
        }
    },
    clientGetUnreadMessages: (state) => {
        if (state.client && state.client.connected) {
            state.client.send(`/app/getMessages/unread`, '', { sessionId: '' });
        } else {
            throw new Error('No Connection to the Chat Service');
        }
    },
    updateNotificationsSchedule: (state, config) => (state.notificationScheduleConfig = config),
    updatePersonalChatConfig: (state, config) => (state.personalChatNotificationConfig = config.map((configuration) => configuration.id)),
    updateUserGroupChatConfig: (state, config) => (state.userGroupChatNotificationConfig = config.map((configuration) => configuration.id)),
    updateAdminGroupChatConfig: (state, config) => (state.adminGroupChatNotificationConfig = config.map((configuration) => configuration.id)),
    updateFavouriteUsers: (state, users) => (state.favouriteUsers = users)
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
