<template>
    <v-container fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm10 md8 lg6 xl4>
                <v-card>
                    <v-toolbar flat dark color="primary">
                        <v-toolbar-title>{{ $t('Passwort vergessen') }}</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form v-model="validate">
                            <v-text-field
                                color="accent"
                                autocomplete="new-password"
                                prepend-icon="mdi-account"
                                :label="$t('E-Mail')"
                                :hint="$t('Geben Sie Ihre E-Mail adresse, für die Sie das Passwort vergessen haben')"
                                type="text"
                                v-model="email"
                                @keyup.enter.stop="resetPassword"
                                :rules="[validateEmail]"
                            />
                            <LanguageSelector />
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-row>
                            <v-spacer></v-spacer>
                            <v-col>
                                <v-btn color="primary" @click="back">{{ $t('Abbrechen') }}</v-btn>
                            </v-col>
                            <v-col>
                                <v-btn color="primary" :disabled="!validate" @click="resetPassword">{{ $t('Passwort Zurücksetzen') }}</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>

        <v-dialog v-model="showCustomers" scrollable persistent :width="$vuetify.breakpoint.thresholds.xs">
            <v-card>
                <v-toolbar dense flat dark color="primary">
                    <v-toolbar-title>{{ $t('Wählen Sie Ihren Mandanten') }}</v-toolbar-title>
                    <v-spacer />
                    <v-btn icon @click="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-list>
                        <v-list-item
                            v-for="customer in customers"
                            :key="customer.id"
                            @click="setSelectedCustomer(customer)"
                            :class="{
                                'primary--text': customer.id === selectedCustomer.id
                            }"
                            v-ripple
                        >
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ customer.companyName }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-btn block color="primary" @click="save" :disabled="Object.keys(selectedCustomer).length === 0">
                        {{ $t('Auswählen') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import LanguageSelector from '../../Components/Global/LanguageSelector';

export default {
    name: 'forgot-password',
    data: () => ({
        email: '',
        validate: false,
        customers: [],
        showCustomers: false,
        selectedCustomer: {}
    }),
    components: { LanguageSelector },
    computed: {
        validateEmail() {
            let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i;
            return (v) => (v.trim() !== '' && regex.test(v)) || this.$t('Bitte geben Sie eine gültige E-Mail Adresse an');
        }
    },
    methods: {
        ...mapActions('General', ['FORGOT_PASSWORD']),
        save() {
            this.FORGOT_PASSWORD({
                email: this.email,
                customerId: this.selectedCustomer.id
            }).then(() => this.$router.push({ name: 'login' }));
        },
        close() {
            this.showCustomers = false;
            this.selectedCustomer = {};
        },
        setSelectedCustomer(customer) {
            if (customer.id === this.selectedCustomer.id) {
                this.selectedCustomer = {};
            } else {
                this.selectedCustomer = customer;
            }
        },
        resetPassword() {
            this.FORGOT_PASSWORD({ email: this.email })
                .then((response) => response.data.data)
                .then((response) => {
                    if (Array.isArray(response)) {
                        this.customers = response;
                        this.showCustomers = true;
                    } else {
                        this.$router.push({ name: 'login' });
                    }
                });
        },
        back() {
            this.$router.push({ name: 'login' });
        }
    }
};
</script>
