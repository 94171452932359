<template>
    <v-card flat>
        <v-toolbar flat dense color="transparent">
            <v-spacer />
            <v-toolbar-items>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon color="accent" @click="showPreview">
                            <v-icon medium>mdi-fullscreen</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Vorschau') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon color="success" @click="createFolder" :disabled="!folderPermissions">
                            <v-icon medium>mdi-folder-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Ordner Erstellen') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon color="success" @click="uploadFile" :disabled="!folderPermissions">
                            <v-icon medium>mdi-file-upload</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Datei hochladen') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon color="error" @click="deleteFile" :disabled="!folderPermissions">
                            <v-icon medium>mdi-file-remove</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Datei löschen') }}</span>
                </v-tooltip>
            </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-container>
            <v-row>
                <v-col :cols="12">
                    <v-menu offset-y :close-on-content-click="false" :max-height="400" :min-width="300">
                        <template v-slot:activator="{ on }">
                            <v-input
                                persistent-hint
                                :hint="
                                    $t(
                                        'Wählen Sie eine Gruppe aus, wo die Dateien abgelegt werden sollen. Wenn Sie keine Gruppe auswählen, werden die Dateien im Stammverzeichnis angelegt'
                                    )
                                "
                            >
                                <template v-slot:prepend="">
                                    <v-btn v-on="on" icon>
                                        <v-icon>mdi-file-tree</v-icon>
                                    </v-btn>
                                </template>
                                <v-breadcrumbs divider=">" :items="groupPath">
                                    <template v-slot:item="{ item }">
                                        <span class="text-caption">{{ item.name || item.fileName }}</span>
                                    </template>
                                </v-breadcrumbs>
                            </v-input>
                        </template>
                        <v-card>
                            <v-toolbar dense color="secondary" flat dark>
                                <v-toolbar-title>{{ $t('Gruppen') }}</v-toolbar-title>
                            </v-toolbar>
                            <v-sheet :max-height="300" class="overflow-y-auto">
                                <FilemanagerFolderSelector v-model="selectedFolder" />
                            </v-sheet>
                        </v-card>
                    </v-menu>
                </v-col>
                <v-col :cols="12">
                    <v-text-field :label="$t('Dateiname')" v-model="fileName" hide-details />
                </v-col>
                <v-col :cols="6">
                    <v-text-field :label="$t('Dateigröße')" readonly :value="formatBytes(value.size)" hide-details />
                </v-col>
                <v-col :cols="6">
                    <v-text-field :label="$t('Dateityp')" readonly :value="fileExtension" hide-details />
                </v-col>
                <v-col :cols="6">
                    <v-text-field :label="$t('Erstellt')" readonly :value="formatDate(value.createdAt)" hide-details />
                </v-col>
                <v-col :cols="6">
                    <v-text-field :label="$t('Bearbeitet')" readonly :value="formatDate(value.updatedAt)" hide-details />
                </v-col>
                <v-col :cols="12" align-self="center">
                    <keep-alive>
                        <component :is="componentSwitch" :src="value.fileURL" />
                    </keep-alive>
                </v-col>
            </v-row>
        </v-container>
        <v-card-actions>
            <v-btn block color="warning" :disabled="!isDisabled" @click="save">
                {{ $t('Bearbeiten') }}
            </v-btn>
        </v-card-actions>
        <Filemanager-Preview :value="value" :showDialog="showFilePreview" @showDialog="(val) => (showFilePreview = val)" />
    </v-card>
</template>

<script>
import ImagePreview from '../../../Generic/ImageLoader';
import AudioPreview from '../../FilePreviewTypes/FilePreviewAudio';
import VideoPreview from '../../FilePreviewTypes/FilePreviewVideo';
import PdfPreview from '../../FilePreviewTypes/FilePreviewPDF';

import FilemanagerPreview from '../../MediaPreview';
import FilemanagerFolderSelector from '../Helpers/FilemanagerFolderSelector';

import moment from 'moment';

import FilemanagerMixin from '../../Mixins/FilemanagerPrototypeMixin';

export default {
    name: 'media-selection',
    mixins: [FilemanagerMixin],
    components: {
        ImagePreview,
        AudioPreview,
        VideoPreview,
        PdfPreview,
        FilemanagerPreview,
        FilemanagerFolderSelector
    },
    data: () => ({
        fileName: '',
        selectedFolder: {},
        showFilePreview: false
    }),
    props: {
        permissions: {
            type: Object,
            default: () => ({})
        },
        value: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        value(value) {
            this.fileName = value.fileName;
            this.selectedFolder = this.getFolder({ id: value.folderId });
        }
    },
    computed: {
        groupPath() {
            let path = this.calculateTreePath(this.selectedFolder);
            if (path.map(({ id }) => id).some((id) => id === '')) {
                path = [];
            }
            return path.length > 0 ? this.calculateTreePath(this.selectedFolder) : [{ name: this.$t('Stammverzeichnis') }];
        },
        folderPermissions() {
            if (Object.keys(this.value).length > 0) {
                const parent = this.getFolder({ id: this.value.folderId });
                if (!!parent && parent && parent.canChange) {
                    return parent.canChange;
                } else {
                    return true;
                }
            }
            return true;
        },
        isDisabled() {
            return (this.value.fileName !== this.fileName && this.fileName.trim() !== '') || this.value.folderId !== this.selectedFolder.id;
        },
        fileExtension() {
            return this.value && this.value.fileType ? this.value.fileType.substring(this.value.fileType.indexOf('/') + 1) : '';
        },
        fileType() {
            return this.value && this.value.fileType ? this.value.fileType.substring(0, this.value.fileType.indexOf('/')) : '';
        },
        componentSwitch() {
            switch (this.fileType) {
                case 'image':
                    return ImagePreview;
                case 'audio':
                    return AudioPreview;
                case 'video':
                    return VideoPreview;
                case 'application':
                    return PdfPreview;
            }
            return undefined;
        }
    },
    methods: {
        showPreview() {
            this.showFilePreview = true;
        },
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD, HH:mm');
        },
        save() {
            this.UPDATE_MEDIA({ name: this.fileName, id: this.value.id, parentId: this.selectedFolder.id }).then((response) => this.$emit('media', response));
        },
        uploadFile() {
            this.$emit('switchView', {
                component: 'UPLOAD_FILE',
                type: 'UPDATE',
                data: {}
            });
        },
        createFolder() {
            this.$emit('switchView', {
                component: 'CREATE_FOLDER',
                type: 'CREATE',
                data: {}
            });
        },
        deleteFile() {
            this.$emit('switchView', {
                component: 'DELETE_FILE',
                type: 'UPDATE',
                data: {}
            });
        }
    },
    activated() {
        this.fileName = this.value.fileName;
    }
};
</script>
