<template>
    <video
        ref="video"
        :width="width"
        :controls="controls"
        :preload="preload"
        :poster="videoPoster"
        :src="src"
        controlsList="nodownload"
        disablePictureInPicture
        playsinline
    />
</template>
<script>
import FileManagerMixins from '../Mixins/FilemanagerPrototypeMixin';

export default {
    name: 'file-preview-video',
    mixins: [FileManagerMixins],
    props: {
        width: {
            type: String,
            default: () => '100%'
        },
        preload: {
            type: String,
            default: () => 'auto'
        },
        poster: {
            type: String,
            default: () => ''
        },
        controls: {
            type: Boolean,
            default: true
        },
        src: {
            type: String,
            default: () => ''
        }
    },
    computed: {
        videoPoster() {
            if (this.poster === '') {
                return this.getLogo;
            }
            return this.poster;
        }
    },
    watch: {
        src() {
            this.$refs.video.load();
        }
    }
};
</script>
