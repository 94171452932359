<template>
    <v-card height="100%" flat>
        <v-container>
            <v-row>
                <v-col :cols="12">
                    <v-card>
                        <v-container>
                            <v-row>
                                <v-col :cols="12">
                                    <v-select
                                        color="accent"
                                        :label="$t('Sprache')"
                                        :items="getLanguages"
                                        :item-text="translateLanguage"
                                        :hint="`${$t(selectedLanguage.name)}, ${selectedLanguage.alpha2}`"
                                        persistent-hint
                                        v-model="selectedLanguage"
                                        @change="clearTemplate"
                                        return-object
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-select
                                        color="accent"
                                        v-model="computedSelectedTemplate"
                                        :label="$t('Vorlage')"
                                        :items="getScheduledTemplates"
                                        item-text="name"
                                        item-key="id"
                                        item-value="id"
                                        return-object
                                    />
                                </v-col>
                                <v-col cols="auto" align-self="center">
                                    <v-btn icon @click="openCreateModal">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="auto" align-self="center">
                                    <v-btn icon :disabled="!isTemplateSelected" @click="openUpdateModal">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="auto" align-self="center">
                                    <v-btn icon :disabled="!isTemplateSelected" @click="openDeleteModal">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col :cols="12">
                    <v-card height="100%" text v-show="isTemplateSelected">
                        <v-container>
                            <v-row>
                                <v-col :cols="9">
                                    <v-text-field
                                        color="accent"
                                        autocomplete="new-password"
                                        class="mt-3 mb-3"
                                        v-model="getSelectedTemplateSubject"
                                        :label="$t('Betreff')"
                                        :hint="$t('Betreffzeile der Mail')"
                                        persistent-hint
                                        :disabled="isDisabled"
                                    />
                                    <TextEditor v-model="getSelectedTemplateMessage" :disabled="isDisabled" />
                                </v-col>
                                <v-col cols="auto">
                                    <v-divider vertical />
                                </v-col>
                                <v-col cols="auto">
                                    <v-list dense>
                                        <v-subheader inset>{{ $t('Verfügbare Variablen') }}</v-subheader>
                                        <v-list-item v-for="item in getScheduledTemplateAvailabileFields" :key="item">
                                            <v-list-item-icon>
                                                <v-icon>mdi-label</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-card-actions>
                            <v-btn
                                block
                                color="primary"
                                :disabled="selectedLanguage === '' || Object.keys(selectedTemplate).length === 0"
                                @click="saveScheduledMail"
                            >
                                {{ $t('Speichern') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <AddScheduledTemplateType :modal="toggleAddTemplate" @toggleModal="setCreateModal" />
        <UpdateScheduledTemplateType :modal="toggleUpdateTemplate" @toggleModal="setUpdateModal" :value="selectedTemplate" @input="handleUpdate" />
        <DeleteScheduledTemplateType :modal="toggleDeleteTemplate" @toggleModal="setDeleteModal" v-model="selectedTemplate" />
    </v-card>
</template>
<script>
import TextEditor from '../../../Components/Generic/TextEditor';
import AddScheduledTemplateType from '../../../Components/SystemSettings/AddScheduledTemplateType';
import UpdateScheduledTemplateType from '../../../Components/SystemSettings/UpdateScheduledTemplateType';
import DeleteScheduledTemplateType from '../../../Components/SystemSettings/DeleteScheduledTemplateType';

import SystemSettingsMixin from '../../../Components/SystemSettings/Mixins/SystemSettingsMixin';

export default {
    name: 'mail-schedule',
    mixins: [SystemSettingsMixin],
    components: {
        TextEditor,
        AddScheduledTemplateType,
        UpdateScheduledTemplateType,
        DeleteScheduledTemplateType
    },
    data: () => ({
        toggleAddTemplate: false,
        toggleUpdateTemplate: false,
        toggleDeleteTemplate: false,
        selectedLanguage: '',
        selectedTemplate: {},
        test: {
            content: ''
        },
        templates: [],
        editorConfig: {
            language: 'de'
        }
    }),
    computed: {
        isTemplateSelected() {
            return Object.keys(this.selectedTemplate).length !== 0;
        },
        isDisabled() {
            return !(this.selectedLanguage !== '' && Object.keys(this.selectedTemplate).length > 0);
        },
        computedSelectedTemplate: {
            get() {
                return this.selectedTemplate;
            },
            set(value) {
                this.selectedTemplate = value;
            }
        },
        getSelectedTemplateSubject: {
            get() {
                if (Object.keys(this.selectedTemplate).length !== 0) {
                    const findTemplate = this.findTemplate();
                    if (typeof findTemplate !== 'undefined') {
                        return findTemplate.subject;
                    }
                }
                return '';
            },
            set(value) {
                if (Object.keys(this.selectedTemplate).length !== 0) {
                    const findTemplate = this.findTemplateIndex();
                    if (findTemplate !== -1) {
                        this.selectedTemplate.templateList[findTemplate].subject = value;
                    } else {
                        const data = {
                            language: this.selectedLanguage.alpha2,
                            subject: value,
                            message: ''
                        };
                        this.selectedTemplate.templateList.push(data);
                    }
                }
            }
        },
        getSelectedTemplateMessage: {
            get() {
                if (Object.keys(this.selectedTemplate).length !== 0) {
                    let findTemplate = this.findTemplate();
                    if (typeof findTemplate !== 'undefined') {
                        return findTemplate.message;
                    }
                }
                return '';
            },
            set(value) {
                if (Object.keys(this.selectedTemplate).length !== 0) {
                    let findTemplate = this.findTemplateIndex();
                    if (findTemplate !== -1) {
                        this.selectedTemplate.templateList[findTemplate].message = value;
                    } else {
                        let data = {
                            language: this.selectedLanguage.alpha2,
                            subject: '',
                            message: value
                        };
                        this.selectedTemplate.templateList.push(data);
                    }
                }
            }
        }
    },
    methods: {
        handleUpdate(value) {
            this.selectedTemplate = { ...this.selectedTemplate, ...value };
        },
        setCreateModal(value) {
            this.toggleAddTemplate = value;
        },
        setUpdateModal(value) {
            this.toggleUpdateTemplate = value;
        },
        setDeleteModal(value) {
            this.toggleDeleteTemplate = value;
        },
        openCreateModal() {
            this.toggleAddTemplate = true;
        },
        openUpdateModal() {
            this.toggleUpdateTemplate = true;
        },
        openDeleteModal() {
            this.toggleDeleteTemplate = true;
        },
        clearTemplate() {
            this.selectedTemplate = {};
        },
        translateLanguage(value) {
            return this.$t(value.name);
        },
        findTemplate() {
            return this.selectedTemplate.templateList.find((find) => find.language === this.selectedLanguage.alpha2);
        },
        findTemplateIndex() {
            return this.selectedTemplate.templateList.findIndex((find) => find.language === this.selectedLanguage.alpha2);
        },
        saveScheduledMail() {
            let data = {
                message: this.getSelectedTemplateMessage,
                subject: this.getSelectedTemplateSubject,
                language: this.selectedLanguage.alpha2,
                id: this.selectedTemplate.id
            };
            this.CREATE_SCHEDULED_TEMPLATE(data).then(() => {
                this.GET_SCHEDULED_TEMPLATES();
            });
        }
    },
    watch: {
        test: {
            handler(value) {
                this.getSelectedTemplateMessage = value.content;
            },
            deep: true
        }
    },
    mounted() {
        this.GET_LANGUAGES().then(() => {
            this.selectedLanguage = this.getDefaultLanguage;
            this.GET_SCHEDULED_TEMPLATES();
        });
    }
};
</script>
