<template>
    <v-treeview :items="tree" transition return-object class="pt-2">
        <template v-slot:prepend="{ item, open }">
            <v-icon :color="iconColor(item)">
                {{ icon(item, open) }}
            </v-icon>
        </template>
        <template v-slot:label="{ item, active }">
            <v-checkbox
                v-if="isKnowledgeContentType(item) === 'FOLDER'"
                v-model="computedCheckbox"
                multiple
                hide-details
                :value="item"
                :key="item.id"
                :label="item.name"
                :disabled="isDisabled(item)"
                :ripple="false"
            />
            <span
                v-else
                :class="{
                    'primary--text': active,
                    'no-highlight': true,
                    'truncate-tree-item': true
                }"
            >
                {{ item.name || item.title }}
            </span>
        </template>
    </v-treeview>
</template>

<script>
import KnowledgeContentMixin from '../Mixins/KnowledgeContentMixin';
export default {
    name: 'knowledge-content-multiple-folder-selector',
    mixins: [KnowledgeContentMixin],
    props: {
        tree: {
            type: Array,
            default: function () {
                return this.getTree;
            }
        },
        value: {
            type: Array,
            default: () => []
        },
        disabledContent: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        computedCheckbox: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        isDisabled({ id: contentId }) {
            return this.disabledContent.map(({ id }) => id).includes(contentId);
        },
        iconColor(item) {
            if (item && item.state && item.state.stateName) {
                switch (item.state.stateName) {
                    case 'APPROVED':
                        return 'success';
                    case 'PENDING':
                        return 'warning';
                    case 'DECLINED':
                        return 'error';
                }
            }
            return '';
        },
        icon(item, open) {
            if (item.name) {
                return open && item.children && item.children.length > 0 ? 'mdi-folder-open-outline' : 'mdi-folder-outline';
            }
            return 'mdi-file';
        }
    }
};
</script>

<style scoped>
.truncate-tree-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-treeview {
    height: 100%;
}
.v-input--selection-controls {
    margin-top: 0;
}
</style>
