import axios from '../../../plugins/Axios/axios';

const state = {
    adminPermissionList: [],
    systemPermissionList: [],
    selectedAdmins: [],
    selectedCatalogGroup: {},
    selectedCatalogSubGroup: {},
    hasPermissions: false
};

// getters
const getters = {
    getAdminPermissionList() {
        return state.adminPermissionList;
    },
    getSystemPermissionList() {
        return state.systemPermissionList;
    },
    getPermissions() {
        return state.hasPermissions;
    },
    getSelectedAdmins() {
        return state.selectedAdmins;
    },
    getSelectedCatalogGroup() {
        return state.selectedCatalogGroup;
    },
    getSelectedCatalogSubGroup() {
        return state.selectedCatalogSubGroup;
    }
};

// actions
const actions = {
    ADD_CATALOG_GROUP_PERMISSION({ commit }, dataToSend) {
        return axios.post(`/cataloggroups/${dataToSend.catalogGroupId}/permissions`, dataToSend).then((response) => {
            return response.data.data;
        });
    },
    ADD_CATALOG_SUB_GROUP_PERMISSION({ commit }, dataToSend) {
        return axios
            .post(`/cataloggroups/${dataToSend.catalogGroupId}/catalogsubgroups/${dataToSend.catalogSubGroupId}/permissions`, dataToSend)
            .then((response) => {
                return response.data.data;
            });
    },
    REMOVE_CATALOG_GROUP_PERMISSION({ commit }, dataToSend) {
        return axios.patch(`/cataloggroups/${dataToSend.catalogGroupId}/permissions`, dataToSend).then((response) => response.data.data);
    },
    REMOVE_CATALOG_SUB_GROUP_PERMISSION({ commit }, dataToSend) {
        return axios
            .patch(`/cataloggroups/${dataToSend.catalogGroupId}/catalogsubgroups/${dataToSend.catalogSubGroupId}/permissions`, dataToSend)
            .then((response) => response.data.data);
    },
    GET_AUTHORING_PERMISSIONS_FOR_CATALOG_GROUP_FOR_ADMINS({ commit }, dataToSend) {
        return axios.post(`/cataloggroups/${dataToSend.catalogGroupId}/getPermissions`, dataToSend).then((response) => response.data.data);
    },
    GET_AUTHORING_PERMISSIONS_FOR_CATALOG_SUB_GROUP_FOR_ADMINS({ commit }, dataToSend) {
        return axios
            .post(`/cataloggroups/${dataToSend.catalogGroupId}/catalogsubgroups/${dataToSend.catalogSubGroupId}/getPermissions`, dataToSend)
            .then((response) => response.data.data);
    },
    GET_ALL_SYSTEM_PERMISSIONS({ commit }, isAuthoringPermission) {
        return axios.get(`/permissions?isAuthoringPermission=${isAuthoringPermission}`).then((response) => {
            commit('updateSystemPermissionList', response.data.data.permissions);
            return response;
        });
    },
    GET_ADMINPERMISSION_LIST_BY_ID({ commit }, adminId) {
        return axios.get(`admins/${adminId}/authoringpermissions`).then((response) => {
            commit('updateAdminPermissionList', response.data.data);
            return response;
        });
    },
    GET_ADMIN_CATALOGGROUPPERMISSION_LIST({ commit }, dataToSend) {
        return axios.get(`/admins/${dataToSend.adminId}/cataloggroups/${dataToSend.catalogGroupId}/permissions`).then((response) => {
            commit('updateAdminPermissionList', response.data.data.permissions);
            return response;
        });
    },
    GET_ADMIN_CATALOGSUBGROUPPERMISSION_LIST({ commit }, dataToSend) {
        return axios
            .get(`/admins/${dataToSend.adminId}/cataloggroups/${dataToSend.catalogGroupId}/catalogsubgroups/${dataToSend.catalogSubgroupId}/permissions`)
            .then((response) => {
                commit('updateAdminPermissionList', response.data.data.permissions);
                return response;
            });
    },
    CHECK_PERMISSIONS({ commit }, permissions) {
        return axios.post(`/checkPermissions`, permissions).then((response) => response.data.data.hasPermission);
    },
    SET_SELECTED_ADMINS({ commit }, admins) {
        commit('updateSelectedAdmins', admins);
    },
    SET_SELECTED_CATALOG_GROUP({ commit }, catalogGroup) {
        commit('updateSelectedCatalogGroup', catalogGroup);
    },
    SET_SELECTED_CATALOG_SUB_GROUP({ commit }, catalogSubGroup) {
        commit('updateSelectedCatalogSubGroup', catalogSubGroup);
    }
};

// mutations
const mutations = {
    clear: (state) => {
        state.adminPermissionList = [];
        state.systemPermissionList = [];
        state.selectedAdmins = [];
        state.selectedCatalogGroup = {};
        state.selectedCatalogSubGroup = {};
        state.hasPermissions = false;
    },
    updateAdminPermissionList: (state, permissionList) => {
        state.adminPermissionList = permissionList;
    },
    updateSystemPermissionList: (state, data) => {
        state.systemPermissionList = data;
    },
    updateSelectedCatalogGroup: (state, catalogGroup) => {
        state.selectedCatalogGroup = catalogGroup;
    },
    updateSelectedCatalogSubGroup: (state, catalogSubGroup) => {
        state.selectedCatalogSubGroup = catalogSubGroup;
    },
    updateSelectedAdmins: (state, admins) => {
        state.selectedAdmins = admins;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
