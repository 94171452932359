<template>
    <v-dialog :value="multipleCustomersDialog" persistent :width="$vuetify.breakpoint.thresholds.xs" scrollable>
        <v-card>
            <v-toolbar dense flat dark color="primary">
                <v-toolbar-title>{{ $t('Wählen Sie Ihren Mandanten') }}</v-toolbar-title>
                <v-spacer />
                <v-btn text icon @click="cancelCompanySelectEmit">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-list>
                    <v-list-item
                        v-for="({ id, companyName }, index) in getCompanies"
                        :key="`${id}-${index}`"
                        :class="id === selectedCompany ? 'primary--text' : ''"
                        @click="selectCompanyEmit(id)"
                        ripple
                    >
                        <v-list-item-content>
                            <v-list-item-title>{{ companyName }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-subheader inset>{{ $t('Weitere Mandanten (anderes Passwort)') }}</v-subheader>
                    <v-list-item v-for="({ id, companyName }, index) in getUnmatchedCompanies" :key="`${id}-${index}`">
                        <v-list-item-content>
                            <v-list-item-title>{{ companyName }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" :disabled="selectedCompany === ''" @click="logInSelectedCompanyEmit">
                    {{ $t('Einloggen') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        multipleCustomersDialog: {
            default: false,
            type: Boolean
        },
        selectedCompany: {
            default: '',
            type: String
        }
    },
    computed: {
        ...mapGetters('General', ['getCompanies', 'getUnmatchedCompanies'])
    },
    methods: {
        logInSelectedCompanyEmit() {
            this.$emit('logInSelectedCompanyEmit');
        },
        cancelCompanySelectEmit() {
            this.$emit('cancelCompanySelectEmit');
        },
        selectCompanyEmit(val) {
            this.$emit('selectCompanyEmit', val);
        }
    }
};
</script>
