<template>
    <v-card>
        <Toolbar :toolbarTitle="computedTitle" :lastPath="-1" :showDrawer="false" />
        <LearningGoals :isAdmin="true" :isCourseManagerValue="isCourseManagerValue" />
    </v-card>
</template>

<script>
import CourseMixin from '../../../Components/CourseLearning/CourseManager/Mixins/CourseMixin';

import Toolbar from '../../../Components/Generic/Toolbar';
import Editor from '../../../Components/Generic/Editor/Editor';

import LearningGoals from '../../../Components/CourseLearning/CourseManager/LearningGoals';
import AddUpdateLearningGoal from '../../../Components/CourseLearning/CourseManager/AddUpdateLearningGoal';
import DeleteLearningGoals from '../../../Components/CourseLearning/CourseManager/DeleteLearningGoals';

export default {
    name: 'learning-goals',
    mixins: [CourseMixin],
    components: {
        Toolbar,
        LearningGoals
    },
    data: () => ({
        tabs: 0,
        operationType: 'ADD',
        isCourseManagerValue: false
    }),
    computed: {
        computedTitle() {
            if (this.getSelectedTopic.learningGoalTypeName !== null) {
                return `${this.$t('Kursmanager')} - ${this.getSelectedTopic.learningGoalTypeName}`;
            } else if (this.getSelectedAdminCourse.learningGoalTypeName !== null) {
                return `${this.$t('Kursmanager')} - ${this.getSelectedAdminCourse.learningGoalTypeName}`;
            }
            return `${this.$t('Kursmanager')} - ${this.$t('Lernziele')}`;
        },
        permissions() {
            return {
                add: this.isCourseManagerValue,
                update: this.isCourseManagerValue,
                delete: this.isCourseManagerValue,
                eval: this.isCourseManagerValue && this.getSelectedAdminCourse.useEvaluation
            };
        },
        isSelected() {
            return Object.keys(this.getSelectedLearningGoal).length > 0;
        }
    },
    methods: {
        // goToEvaluations() {
        //     this.$router.push({ name: 'evaluations' });
        // },
        // create() {
        //     this.operationType = 'ADD';
        //     this.showModal = true;
        // },
        // update() {
        //     this.operationType = 'UPDATE';
        //     this.showModal = true;
        // },
        // deleteLearningGoals() {
        //     this.showDeleteModal = true;
        // }
    },
    mounted() {
        this.isCourseManager().then((response) => (this.isCourseManagerValue = response));
    }
};
</script>
