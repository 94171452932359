import axios from '../../../plugins/Axios/axios';

const state = {
    selectedLearningGoal: {},
    topicLearningGoals: [],
    schedulingMyLearningGoal: []
};

const getters = {
    getTopicLearningGoals() {
        return state.topicLearningGoals.sort((a, b) => a.order - b.order);
    },
    getSelectedLearningGoal() {
        return state.selectedLearningGoal;
    },
    getSchedulingMyLearningGoal() {
        return state.schedulingMyLearningGoal;
    }
};

const actions = {
    SET_LEARNING_GOALS({ commit }, learningGoals) {
        commit('updateLearningGoals', learningGoals);
    },
    SET_SELECTED_LEARNING_GOAL({ commit }, learningGoal) {
        commit('updateSelectedLearningGoal', learningGoal);
    },
    GET_LEARNING_GOAL({ commit }, { id: learningGoalTempId }) {
        return axios
            .get(`/courselearning/learninggoalinstances/${learningGoalTempId}`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateLearningGoals', response);
                return response;
            });
    },
    GET_TOPIC_LEARNING_GOALS({ commit }, { id: topicTempId }) {
        return axios
            .get(`/courselearning/topicinstances/${topicTempId}/learninggoals`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateLearningGoals', response);
                return response;
            });
    },
    CREATE_LEARNING_GOAL({ commit }, request) {
        return axios.post(`/courselearning/learninggoalinstances`, request).then((response) => response.data.data);
    },
    UPDATE_LEARNING_GOAL({ commit }, request) {
        const { id: learningGoalTempId, ...learningGoal } = request;
        return axios
            .patch(`/courselearning/learninggoalinstances/${learningGoalTempId}`, learningGoal)
            .then((response) => response.data.data)
            .then((response) => commit('updateLearningGoals', response));
    },
    UPDATE_TOPIC_LEARNING_GOALS({ commit, dispatch }, { course, topic, learningGoals }) {
        const { id: topicTempId } = topic;
        const learningGoalIds = learningGoals.map((learningGoal) => ({
            id: learningGoal.id
        }));
        return axios
            .patch(`/courselearning/topicinstances/${topicTempId}/learninggoals`, learningGoalIds)
            .then((response) => response.data.data)
            .then((response) => {
                dispatch('CourseOperations/GET_COURSE', course, { root: true });
                dispatch('TopicOperations/GET_TOPIC', topic, { root: true });
                commit('updateLearningGoals', response);
            });
    },
    DELETE_LEARNING_GOAL({ commit }, request) {
        const { id: learningGoalTempId } = request;
        return axios.delete(`/courselearning/learninggoalinstances/${learningGoalTempId}`).then(() => commit('deleteLearningGoals', request));
    },
    GET_LEARNING_GOAL_SCHEDULING({ commit }, { id: learningGoalInsId }) {
        return axios.get(`/courselearning/learninggoalinstances/${learningGoalInsId}/scheduling`).then((response) => response.data.data);
    },
    UPDATE_LEARNING_GOAL_SCHEDULING({ commit }, { id: learningGoalInsId, ...topic }) {
        return axios
            .patch(`/courselearning/learninggoalinstances/${learningGoalInsId}/scheduling`, topic)
            .then((response) => response.data.data)
            .then((response) => commit('updateScheduling', response));
    },
    REORDER_LEARNING_GOALS({ commit }, { topicId, ...learningGoal }) {
        return axios
            .patch(`/courselearning/topicinstances/${topicId}/learninggoals/reorder`, learningGoal)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateLearningGoals', response);
                return response;
            });
    }
};

const mutations = {
    clear: (state) => {
        state.topicLearningGoals = [];
        state.selectedLearningGoal = {};
        state.schedulingMyTopic = [];
    },
    updateSelectedLearningGoal: (state, request) => (state.selectedLearningGoal = request),
    updateLearningGoals: (state, request) => {
        if (Array.isArray(request)) {
            state.topicLearningGoals = request;
        } else {
            if (request.hasOwnProperty('id')) {
                if (state.selectedLearningGoal.id === request.id) {
                    state.selectedLearningGoal = request;
                }
                if (state.topicLearningGoals.map((course) => course.id).includes(request.id)) {
                    state.topicLearningGoals = state.topicLearningGoals.map((course) => {
                        if (course.id === request.id) {
                            return request;
                        }
                        return course;
                    });
                } else {
                    state.topicLearningGoals.push(request);
                }
            }
        }
    },
    deleteLearningGoals: (state, request) => {
        if (request.hasOwnProperty('id')) {
            if (state.selectedLearningGoal.id === request.id) {
                state.selectedLearningGoal = {};
            }
            const find = state.topicLearningGoals.map((data) => data.id).indexOf(request.id);
            if (find !== -1) {
                state.topicLearningGoals.splice(find, 1);
            }
        }
    },
    updateScheduling: (state, response) => {
        if (Array.isArray(response)) {
            state.schedulingMyLearningGoal = response;
        } else {
            if (state.schedulingMyLearningGoal.map((thread) => thread.id).includes(response.id)) {
                state.schedulingMyLearningGoal = state.schedulingMyLearningGoal.map((thread) => {
                    if (thread.id === response.id) {
                        return response;
                    }
                    return thread;
                });
            } else {
                state.schedulingMyLearningGoal.push(response);
            }
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
