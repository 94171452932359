<template>
    <v-card :elevation="12" outlined class="rounded-xl">
        <ImageLoader :src="getPhoto()" :alignCenter="true" />
        <v-card-subtitle class="card-title" v-if="item.germanSubCategory">
            <v-icon> mdi-target </v-icon>
            {{ germanSubCategory }}
        </v-card-subtitle>
        <v-card-subtitle class="card-title">
            <v-icon> mdi-clock-outline </v-icon>
            {{ timeText() }}
        </v-card-subtitle>
        <template v-if="isBought">
            <v-card-subtitle class="card-title" v-if="scormDetail && scormDetail.purchaseDate">
                <v-icon class="info-icon">mdi-information-outline</v-icon>
                <span>
                    <b>{{ $t('Purchase Date') }}</b
                    >:
                    {{ changeDateFormate(scormDetail.purchaseDate.slice(0, 10)) }}
                </span>
            </v-card-subtitle>
            <v-card-subtitle class="card-title" v-if="scormDetail && scormDetail.expirationDate">
                <v-icon class="info-icon">mdi-information-outline</v-icon>
                <span>
                    <b>{{ $t('Expiration Date') }}</b
                    >:
                    {{ changeDateFormate(scormDetail.expirationDate.slice(0, 10)) }}
                </span>
            </v-card-subtitle>
            <template v-if="scormDetail.scormPackageType === 'ENTERPRISE'">
                <v-card-subtitle class="card-title">
                    <v-icon class="info-icon">mdi-information-outline</v-icon>
                    <span>
                        <b>{{ $t('Licences') }}</b
                        >: Corporate
                    </span>
                </v-card-subtitle>
            </template>
            <template v-else>
                <v-card-subtitle class="licences">
                    <v-icon class="info-icon"> mdi-account-circle </v-icon>
                    <b>{{ $t('Licences') }}:</b>
                </v-card-subtitle>
                <div class="d-flex justify-space-between licence-blocks">
                    <div class="v-card text-center">
                        <v-card-subtitle class="licence-label">{{ $t('Gesamt') }}</v-card-subtitle>
                        <v-progress-circular :rotate="-90" :size="40" :width="5" value="100" color="primary">
                            {{ scormDetail.totalLicence }}
                        </v-progress-circular>
                    </div>
                    <div class="v-card text-center">
                        <v-card-subtitle class="licence-label">{{ $t('Remaining') }}</v-card-subtitle>
                        <v-progress-circular :rotate="-90" :size="40" :width="5" :value="countFullness(scormDetail, 'remaningLicence')" color="success">
                            {{ scormDetail.remaningLicence }}
                        </v-progress-circular>
                    </div>
                    <div class="v-card text-center">
                        <v-card-subtitle class="licence-label">{{ $t('Used') }}</v-card-subtitle>
                        <v-progress-circular :rotate="-90" :size="40" :width="5" :value="countFullness(scormDetail, 'consumeLicence')" color="red">
                            {{ scormDetail.consumeLicence }}
                        </v-progress-circular>
                    </div>
                </div>
            </template>
        </template>
        <v-card-actions class="justify-center" v-if="isBought && this.getLoggedInAdmin.role == 'SUPER_ADMIN' && this.getView.type !== 'SELF_ENROLL'">
            <v-chip :disabled="!userReportItems.length && !adminReportItems.length" color="primary" @click="openReportModal"> {{ $t('Report') }} </v-chip>
        </v-card-actions>
        <v-card-actions class="justify-center">
            <v-chip color="secondary" @click="buyScorm">
                {{ chipText }}
            </v-chip>
        </v-card-actions>
    </v-card>
</template>

<script>
import ScormMixin from '../Mixins/ScormMixin';
import ImageLoader from '../../Generic/ImageLoader';
export default {
    name: 'scorm-display-node',
    mixins: [ScormMixin],
    components: {
        ImageLoader
    },
    data: () => ({
        picture: ''
    }),
    props: {
        item: {
            type: Object,
            deafult: () => ({})
        },
        scormDetail: {
            type: Object,
            default: () => ({})
        },
        isBought: {
            type: Boolean,
            default: false
        },
        userReportItems: {
            type: Array,
            default: () => []
        },
        adminReportItems: {
            type: Array,
            default: () => []
        },
        permissions: {
            type: Object,
            default: () => ({})
        },
        context: {
            type: String,
            default: ''
        }
    },
    methods: {
        changeDateFormate(date) {
            let newDate = date.slice(8, 10) + '.' + date.slice(5, 7) + '.' + date.slice(0, 4);
            return newDate;
        },
        timeText() {
            if (!(this.item && this.item.learningMinutes)) return 'Keine Zeit verfügbar';
            switch (this.getLanguage) {
                case 'de':
                    return this.item.learningMinutes + ' Minuten';
                case 'en':
                    return this.item.learningMinutes + ' minutes';
                default:
                    return this.item.learningMinutes + ' Minuten';
            }
        },
        countFullness(scormDetail, field) {
            const percentVal = 100 / parseInt(scormDetail.totalLicence);
            return scormDetail[field] * percentVal;
        },
        openReportModal() {
            this.$emit('openReportModal');
        },
        buyScorm() {
            this.$emit('buyScorm');
        },
        getPhoto() {
            if (this.item.scormPackageType === 'OWN') {
                this.picture = this.item.photo;
                return this.item.photo;
            }

            this.GET_MEDIA_BY_ID(this.item.scormPackageId)
                .then((blob) => {
                    const filereader = new FileReader();
                    filereader.onload = () => {
                        this.picture = filereader.result;
                    };
                    filereader.readAsDataURL(blob);
                })
                .catch(() => {
                    this.picture = this.getDefaultCoursePicture;
                });
            return this.picture;
        }
    },
    computed: {
        germanSubCategory() {
            return this.item.germanSubCategory;
        },
        chipText() {
            if (this.context == 'SELF_ENROLL') {
                return this.item.model == 'ON_REQUEST' ? this.$t('Anfragen') : this.$t('Einschreiben');
            }
            if (!this.isBought) {
                return this.hasPurchasePermissions ? this.$t('Kaufen') : this.getInterestText;
            }
            return this.hasAssignPermissions ? this.$t('Einschreiben') : this.getInterestText;
        },
        hasAssignPermissions() {
            return this.isSuperAdmin || this.permissions.assignUserToScorm;
        },
        hasPurchasePermissions() {
            return this.isSuperAdmin || this.permissions.purchaseScorm;
        },
        isSuperAdmin() {
            return this.getLoggedInAdmin.role === 'SUPER_ADMIN';
        },
        getInterestText() {
            if (this.item.buyRequestStatus === undefined || this.item.buyRequestStatus === '') {
                return this.$t('Interesse bekunden');
            }
            switch (this.item.buyRequestStatus) {
                case 'PENDING':
                    return this.$t('Ihre Anfrage steht noch aus');
                case 'DECLINED':
                    return this.$t('Ihre Anfrage wurde abgelehnt');
                default:
                    return '';
            }
        }
    }
};
</script>
