<template>
    <v-card>
        <v-container>
            <v-row>
                <v-col :cols="12">
                    <v-card>
                        <v-toolbar flat dark color="secondary">
                            <v-toolbar-title>{{ $t('Vorlagen bestellen') }}</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-switch :label="$t(`Alle ${toggleText}`)" v-model="toggleCollapse" />
                            <v-list expand>
                                <v-list-group :value="toggleCollapse" v-for="(option, index) in options" :key="`option-${index}`">
                                    <template v-slot:activator>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ translateTemplateName(option.templateName) }} </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                    <v-card flat>
                                        <v-container>
                                            <v-row no-gutters>
                                                <v-col :cols="12" :sm="6">
                                                    <v-switch
                                                        v-model="option.allow"
                                                        :label="$t('Abbestellen erlauben')"
                                                        :hint="$t('Benutzer dürfen diese Mail abbestellen und die Mail nicht bekommen')"
                                                        persistent-hint
                                                        color="accent"
                                                    />
                                                </v-col>
                                                <v-col :cols="12" :sm="6">
                                                    <v-switch
                                                        v-model="option.allowSending"
                                                        :label="$t('E-Mail senden zulassen')"
                                                        :hint="$t('E-Mails von diesem Typ werden gänzlich ein- oder abbestellt')"
                                                        persistent-hint
                                                        color="accent"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card>
                                </v-list-group>
                            </v-list>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer />
                            <v-btn class="primary" @click="save">{{ $t('Speichern') }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>
<script>
import SystemSettingsMixin from '../../../Components/SystemSettings/Mixins/SystemSettingsMixin';

export default {
    name: 'mail-options',
    mixins: [SystemSettingsMixin],
    data: () => ({
        toggleCollapse: false
    }),
    computed: {
        options() {
            let templates = this.getMailOptions.opts;
            if (templates) {
                templates.sort((a, b) => {
                    const tA = this.translateTemplateName(a.templateName);
                    const tB = this.translateTemplateName(b.templateName);

                    return tA.localeCompare(tB, {
                        numeric: true,
                        sensitivity: 'base'
                    });
                });
            }
            return templates ? templates : [];
        },
        toggleText() {
            if (this.toggleCollapse) return 'schließen';
            return 'öffnen';
        }
    },
    methods: {
        translateTemplateName(value) {
            let normalize = value.replace(/_/gi, ' ').toLowerCase();
            return this.$t(normalize.charAt(0).toUpperCase() + normalize.slice(1));
        },
        save() {
            this.SET_MAIL_OPTIONS(this.getMailOptions).then(() => this.GET_MAIL_OPTIONS());
        }
    },
    mounted() {
        this.GET_MAIL_OPTIONS();
    }
};
</script>
