<template>
    <v-dialog v-model="showDialog" scrollable :width="$vuetify.breakpoint.thresholds.md" persistent :fullscreen="$vuetify.breakpoint.xs">
        <template #activator="{ on, attrs }">
            <v-tooltip bottom>
                <template #activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                    <v-btn
                        icon
                        v-on="{ ...on, ...tooltipOn }"
                        v-bind="{ ...attrs, tooltipAttrs }"
                        :color="type === 'ADD' ? 'success' : 'warning'"
                        :disabled="computedDisableType"
                    >
                        <v-icon>{{ type === 'ADD' ? 'mdi-plus' : 'mdi-pencil' }}</v-icon>
                    </v-btn>
                </template>
                <span>{{ computedTooptip }}</span>
            </v-tooltip>
        </template>
        <v-card>
            <v-toolbar dense flat color="secondary" dark>
                <v-toolbar-title>{{ computedTitle }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-tabs v-model="tabs" slider-color="accent" show-arrows mandatory>
                <v-tab key="LEARNING_GOAL">{{ $t('Lernziel') }}</v-tab>
                <v-tab key="CONTENT"> {{ $t('Content verlinken') }} </v-tab>
                <!-- <v-tab key="PREVIEW"> {{ $t('Vorschau') }} </v-tab> -->
            </v-tabs>
            <v-card-text class="ma-0 pa-0">
                <v-tabs-items v-model="tabs" touchless>
                    <v-tab-item key="LEARNING_GOAL" eager>
                        <v-form v-model="validate">
                            <v-container fluid>
                                <v-row>
                                    <v-col :cols="12">
                                        <v-text-field
                                            :label="$t('Titel')"
                                            :hint="$t('Der Titel des Lernziels')"
                                            persistent-hint
                                            v-model="name"
                                            :rules="[rules('notEmpty')]"
                                        />
                                    </v-col>
                                    <v-col :cols="6">
                                        <DatePicker v-model="startDate" :min="minimumDate" :label="$t('Startdatum')" />
                                    </v-col>
                                    <v-col :cols="6">
                                        <DatePicker v-model="endDate" :min="startDate" :label1="$t('Enddatum')" />
                                    </v-col>
                                    <v-col :cols="12">
                                        <v-textarea
                                            :label="$t('Beschreibung')"
                                            :hint="$t('Die Beschreibung des Lernziels')"
                                            persistent-hint
                                            v-model="description"
                                            no-resize
                                            :rows="3"
                                        />
                                    </v-col>
                                    <v-col :cols="12">
                                        <v-textarea
                                            :label="$t('Notizen')"
                                            :hint="$t('Notizen, nur für Lehrer sichtbar')"
                                            persistent-hint
                                            v-model="notes"
                                            no-resize
                                            :rows="3"
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>{{ $t('Erweiterte Einstellungen') }}</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-container fluid>
                                            <v-row>
                                                <v-col :cols="6">
                                                    <v-text-field
                                                        :disabled="computedDisableSetScore"
                                                        :label="$t('Minimum Punkte')"
                                                        :hint="$t('Geben Sie eine minimale Punkzahl an, um den Kurs zu bestehen')"
                                                        persistent-hint
                                                        type="number"
                                                        min="0"
                                                        :rules="[rules('notNegative')]"
                                                        v-model="minScore"
                                                    />
                                                </v-col>
                                                <v-col :cols="6">
                                                    <v-text-field
                                                        :disabled="computedDisableSetScore"
                                                        :label="$t('Maximal erreichbare Punkte')"
                                                        :hint="$t('Geben Sie die maximal erreichbare Punktzahl an')"
                                                        persistent-hint
                                                        type="number"
                                                        min="0"
                                                        :rules="[rules('notNegative')]"
                                                        v-model="score"
                                                    />
                                                </v-col>
                                                <v-col :cols="6">
                                                    <v-switch
                                                        :label="$t('Startdatum erzwingen')"
                                                        :hint="$t('Wenn das Startdatum erzwungen wird, wird das Thema erst zum Startdatum verbergen')"
                                                        persistent-hint
                                                        v-model="forceStartDate"
                                                    />
                                                </v-col>
                                                <v-col :cols="6">
                                                    <v-switch
                                                        :label="$t('Enddatum erzwingen')"
                                                        :hint="$t('Wenn das Enddatum erzwungen wird, wird das Thema zum nächsten Tag geschlossen')"
                                                        persistent-hint
                                                        v-model="forceEndDate"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>{{ $t('Erweitertes Zeitmanagement') }}</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-container fluid>
                                            <v-row>
                                                <v-col :cols="6">
                                                    <v-text-field
                                                        :label="$t('Approximierte Arbeitszeit')"
                                                        :hint="$t('Geben Sie eine Zeit in Minuten an, in der der Kurs abzuarbeiten ist')"
                                                        persistent-hint
                                                        type="number"
                                                        min="0"
                                                        :rules="[rules('notNegative')]"
                                                        v-model="estimatedWorkTimeInMinutes"
                                                    />
                                                </v-col>
                                                <v-col :cols="6">
                                                    <v-text-field
                                                        :label="$t('Dauer in Tagen')"
                                                        :hint="$t('Geben Sie die Dauer in Tagen an')"
                                                        persistent-hint
                                                        type="number"
                                                        min="0"
                                                        :rules="[rules('notNegative')]"
                                                        v-model="durationInDays"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>{{ $t('Sichtbarkeitsoptionen') }}</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-container fluid>
                                            <v-row>
                                                <v-col :cols="6">
                                                    <v-switch
                                                        :label="$t('Vor Lernzielbeginn verbergen')"
                                                        :hint="
                                                            $t(
                                                                'Mit dieser Einstellung können Sie Lernziele, welche noch nicht begonnen haben, vor dem Benutzer verbergen'
                                                            )
                                                        "
                                                        persistent-hint
                                                        v-model="hideBeforeStart"
                                                    />
                                                </v-col>
                                                <v-col :cols="6">
                                                    <v-switch
                                                        :label="$t('Dauer in Tagen verbergen')"
                                                        :hint="$t('Mit dieser Einstellung können Sie Dauer in Tagen vor dem Benutzer verbergen')"
                                                        persistent-hint
                                                        v-model="hideDurationInDays"
                                                    />
                                                </v-col>
                                                <v-col :cols="12">
                                                    <v-switch
                                                        :label="$t('Approximierte Arbeitszeit verbergen')"
                                                        :hint="$t('Mit dieser Einstellung können Sie die geschätzte Arbeitszeit vor dem Benutzer verbergen')"
                                                        persistent-hint
                                                        v-model="hideEstimatedWorkTimeInMinutes"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>{{ $t('Benutzerdefinierte Typenbezeichnung') }}</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-container fluid>
                                            <v-row>
                                                <v-col :xs="12" :sm="6">
                                                    <v-text-field
                                                        v-model="typeName"
                                                        :label="$t('Lernzielbezeichnung')"
                                                        :hint="computedLearningGoalTypeNameHint"
                                                        persistent-hint
                                                        clearable
                                                    />
                                                </v-col>
                                                <v-col :xs="12" :sm="6">
                                                    <v-text-field
                                                        v-model="activityTypeName"
                                                        :label="$t('Aktivitätsbezeichnung')"
                                                        :hint="computedActivityTypeNameHint"
                                                        persistent-hint
                                                        clearable
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-form>
                    </v-tab-item>
                    <v-tab-item key="CONTENT" eager>
                        <KnowledgeContentManager selectable v-model="knowledgeContents" />
                    </v-tab-item>
                    <!-- <v-tab-item keys="PREVIEW">
                        <AddCourseUserPreview
                            v-model="showModal"
                            :isLearningGoal="true"
                            :serializedData="serializedData"
                            :timeScaleInMinutes="getSelectedAdminCourse.timeScaleInMinutes"
                        />
                    </v-tab-item> -->
                </v-tabs-items>
            </v-card-text>
            <v-card-actions>
                <v-btn block :color="computedSubmitButtonColor" @click="save" :disabled="!validate">
                    {{ computedSubmitButtonText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import DatePicker from '../../Generic/DatePicker/DatePicker';
import KnowledgeContentManager from '../../KnowledgeContent/KnowledgeContentManager';
import CourseMixin from './Mixins/CourseMixin';
import KnowledgeContentMixin from '../../KnowledgeContent/Mixins/KnowledgeContentMixin';
import moment from 'moment';
// import AddCourseUserPreview from './AddCourseUserPreview';

export default {
    name: 'add-update-learning-goal',
    components: {
        DatePicker,
        KnowledgeContentManager
        // AddCourseUserPreview
    },
    mixins: [CourseMixin, KnowledgeContentMixin],
    data: () => ({
        showDialog: false,
        showModal: false,
        validate: false,
        tabs: 0,
        id: '',
        name: '',
        description: '',
        notes: '',
        estimatedWorkTimeInMinutes: 0,
        folderId: '',
        durationInDays: '',
        forceStartDate: false,
        forceEndDate: false,
        hideBeforeStart: false,
        hideEstimatedWorkTimeInMinutes: false,
        hideDurationInDays: false,
        knowledgeContents: [],
        score: 0,
        minScore: 0,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        typeName: null,
        activityTypeName: null,
        learningGoalPicturePath: {},
        initialLoad: false,
        serializedData: {}
    }),
    watch: {
        showDialog(value) {
            if (value && !this.initialLoad) {
                this.initialLoad = true;
            }
            if (value) {
                if (this.type === 'UPDATE') {
                    Object.entries(this.getSelectedLearningGoal).forEach(([key, value]) => (this.$data[key] = value));
                } else if (this.type === 'ADD') {
                    this.startDate = this.getSelectedTopic.startDate;
                    this.endDate = this.getSelectedTopic.endDate;
                }
            }
        },
        startDate(value) {
            if (moment(value).isAfter(moment(this.endDate))) {
                this.endDate = moment(value).format('YYYY-MM-DD');
            }
        },
        minScore(value) {
            const toNumber = Number(value);
            if (toNumber >= this.score) {
                this.score = toNumber;
            }
        },
        tabs(value) {
            if (value === 2) {
                this.openUserPreview();
            }
        }
    },
    props: {
        type: {
            type: String,
            default: 'ADD',
            validator: (value) => ['ADD', 'UPDATE'].includes(value)
        }
    },
    computed: {
        computedDisableType() {
            if (this.type === 'ADD') {
                return false;
            }
            if (this.type === 'UPDATE') {
                return !Object.keys(this.getSelectedLearningGoal).length > 0;
            }
            return false;
        },
        computedTooptip() {
            switch (this.type) {
                case 'ADD':
                    return this.$t('Lernziel erstellen');
                case 'UPDATE':
                    return this.$t('Lernziel bearbeiten');
            }
            return '';
        },
        computedDisableSetScore() {
            if (!this.getSelectedAdminCourse.useEvaluation) {
                return true;
            }
            return false;
        },
        computedLearningGoalTypeNameHint() {
            return this.$t('Sie können statt "Lernziel" eine eigene Bezeichnung festlegen');
        },
        computedActivityTypeNameHint() {
            return this.$t('Sie können statt "Aktivität" eine eigene Bezeichnung festlegen');
        },
        computedTitle() {
            switch (this.type) {
                case 'ADD':
                    if (this.getSelectedTopic.learningGoalTypeName !== null && this.getSelectedTopic.learningGoalTypeName !== '') {
                        return `${this.getSelectedTopic.learningGoalTypeName} ${this.$t('erstellen')}`;
                    } else if (this.getSelectedAdminCourse.learningGoalTypeName !== null && this.getSelectedAdminCourse.learningGoalTypeName !== '') {
                        return `${this.getSelectedAdminCourse.learningGoalTypeName} ${this.$t('erstellen')}`;
                    }
                    return this.$t('Lernziel erstellen');
                case 'UPDATE':
                    if (this.typeName !== null && this.typeName !== '') {
                        return `${this.typeName} ${this.$t('bearbeiten')}`;
                    } else if (this.getSelectedTopic.learningGoalTypeName !== null && this.getSelectedTopic.learningGoalTypeName !== '') {
                        return `${this.getSelectedTopic.learningGoalTypeName} ${this.$t('bearbeiten')}`;
                    } else if (this.getSelectedAdminCourse.learningGoalTypeName !== null && this.getSelectedAdminCourse.learningGoalTypeName !== '') {
                        return `${this.getSelectedAdminCourse.learningGoalTypeName} ${this.$t('bearbeiten')}`;
                    }
                    return this.$t('Lernziele bearbeiten');
            }
            return '';
        },
        computedSubmitButtonText() {
            switch (this.type) {
                case 'ADD':
                    return this.$t('Hinzufügen');
                case 'UPDATE':
                    return this.$t('Aktualisieren');
            }
            return '';
        },
        computedSubmitButtonColor() {
            switch (this.type) {
                case 'ADD':
                    return 'success';
                case 'UPDATE':
                    return 'warning';
            }
            return '';
        },
        minimumDate() {
            return moment().format('YYYY-MM-DD');
        },
        handleDurationInDays() {
            if (this.durationInDays === '') {
                return moment(this.endDate).diff(this.startDate, 'days') + 1;
            } else if (this.durationInDays !== moment(this.endDate).diff(this.startDate, 'days') + 1) {
                return this.durationInDays;
            }
            return '';
        },
        setTypeName() {
            if (this.typeName === '') {
                return null;
            }
            return this.typeName;
        },
        setActivityTypeName() {
            if (this.activityTypeName === '') {
                return null;
            }
            return this.activityTypeName;
        }
    },
    methods: {
        openUserPreview() {
            this.serializedData = this.serialize();
            this.showModal = true;
            if (this.type === 'UPDATE') {
                this.GET_LEARNING_GOAL_ACTIVITIES({
                    ...this.serializedData,
                    map: true
                });
            }
        },
        handleSelected(value) {
            this.picture = value;
        },
        serialize() {
            return {
                id: this.id,
                name: this.name,
                description: this.description,
                notes: this.notes,
                durationInDays: this.handleDurationInDays,
                estimatedWorkTimeInMinutes: this.estimatedWorkTimeInMinutes,
                folderId: this.folderId,
                forceStartDate: this.forceStartDate,
                forceEndDate: this.forceEndDate,
                knowledgeContents: this.knowledgeContents,
                score: this.score,
                minScore: this.minScore,
                startDate: this.startDate,
                endDate: this.endDate,
                hideBeforeStart: this.hideBeforeStart,
                typeName: this.setTypeName,
                activityTypeName: this.setActivityTypeName,
                hideEstimatedWorkTimeInMinutes: this.hideEstimatedWorkTimeInMinutes,
                hideDurationInDays: this.hideDurationInDays
            };
        },
        reset() {
            this.id = '';
            this.tabs = 0;
            this.name = '';
            this.description = '';
            this.notes = null;
            this.estimatedWorkTimeInMinutes = 0;
            this.folderId = '';
            this.forceStartDate = false;
            this.forceEndDate = true;
            this.knowledgeContents = [];
            this.score = 0;
            this.minScore = 0;
            this.startDate = moment().format('YYYY-MM-DD');
            this.endDate = moment().format('YYYY-MM-DD');
            this.hideBeforeStart = false;
            this.hideEstimatedWorkTimeInMinutes = false;
            this.hideDurationInDays = false;
            this.durationInDays = '';
            this.typeName = null;
            this.activityTypeName = null;
        },
        close() {
            this.showDialog = false;
            this.$nextTick(() => this.reset());
        },
        save() {
            switch (this.type) {
                case 'ADD': {
                    this.CREATE_LEARNING_GOAL(this.serialize())
                        .then((response) =>
                            this.UPDATE_TOPIC_LEARNING_GOALS({
                                course: this.getSelectedAdminCourse,
                                topic: this.getSelectedTopic,
                                learningGoals: [...this.getTopicLearningGoals, response]
                            })
                        )
                        .then(() => this.close());
                    return;
                }
                case 'UPDATE': {
                    this.UPDATE_LEARNING_GOAL(this.serialize()).then(() => this.close());
                    return;
                }
            }
        },
        rules(type) {
            switch (type) {
                case 'notNegative':
                    return (v) => {
                        if (v !== undefined && v !== null) {
                            const toNumber = Number(v);
                            return toNumber >= 0 || this.$t('Bitte geben Sie eine Zahl größer oder gleich 0 an');
                        }
                    };
                case 'notEmpty':
                    return (v) => !!v || this.$t('Dieses Feld darf nicht leer sein');
            }
        }
    },
    beforeMount() {
        this.GET_KNOWLEDGE_CONTENTS();
    }
};
</script>
