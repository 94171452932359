<template>
    <v-card>
        <v-toolbar dense dark flat color="secondary">
            <v-toolbar-title>{{ $t('Antwort erstellen') }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text style="overflow-x: hidden">
            <v-container>
                <v-row>
                    <v-col :cols="12">
                        <TextEditor v-model="computedText" :loadFilemanager="isAdmin" />
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-btn block color="success" @click="save" :disabled="isLoading">
                {{ $t('Hinzufügen') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import TextEditor from '../Generic/TextEditor';
import Forummixin from './Mixins/ForumMixin';
import { mapGetters } from 'vuex';

export default {
    name: 'add-answer',
    mixins: [Forummixin],
    components: { TextEditor },
    data: () => ({
        isLoading: false
    }),
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    computed: {
        isAdmin() {
            return this.getLoggedInAdmin.role === 'ADMIN' || this.getLoggedInAdmin.role === 'SUPER_ADMIN';
        },
        computedText: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        close(val = false) {
            this.isLoading = false;
            this.$emit('input', '');
            this.$emit('saved', val);
        },
        save() {
            this.isLoading = true;
            this.CREATE_FORUM_THREAD_ANSWER({
                forum: this.getSelectedForum,
                thread: this.getSelectedThread,
                answer: { text: this.value }
            }).then(() => {
                this.close(true);
            });
        }
    }
};
</script>
