<template>
    <QuizApp
        :settings="settings"
        :isPreview="isPreview"
        @isPreview="(value) => (isPreview = value)"
        :onStart="handleOnStart"
        :onSubmit="handleOnSubmit"
        :onResult="handleOnResult"
    />
</template>

<script>
import CourseMixin from '../../CourseManager/Mixins/CourseMixin';
import QuizApp from '../../../Quiz/QuizApp';

export default {
    name: 'quiz-answer-type',
    components: { QuizApp },
    mixins: [CourseMixin],
    data: () => ({
        isPreview: false
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        settings: {
            type: Object,
            default: () => ({})
        },
        activity: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        handleOnStart() {
            return this.GET_USER_QUIZ(this.activity);
        },
        handleOnSubmit(vote) {
            return this.CAST_USER_QUIZ_VOTE({
                activity: this.activity,
                vote: vote
            });
        },
        handleOnResult() {
            this.EVALUATE_OWN_ACTIVITY({
                activity: this.activity,
                evaluation: {
                    selfAssginedScore: 0,
                    userNotes: ''
                }
            });
            return this.GET_USER_QUIZ_ANSWERS(this.activity);
        }
    }
};
</script>
