<template>
    <v-container>
        <v-row>
            <v-col :cols="computedItems.length > 0 ? 6 : 12">
                <v-row>
                    <v-col :cols="12" v-show="computedItems.length > 0">
                        <v-subheader>{{ $t('Nicht zugewiesene Optionen') }}</v-subheader>
                    </v-col>
                    <v-col :cols="12">
                        <draggable v-model="computedItems" tag="div" handle=".cursor" group="buckets">
                            <transition-group name="slide-x-transition" mode="out-in" tag="v-row" class="row">
                                <v-col :cols="12" v-for="(option, index) in computedItems" :key="option.id">
                                    <v-card tile :key="`option-${option.id}`">
                                        <v-toolbar flat dark dense color="secondary">
                                            <v-toolbar-title class="text-body-2">{{ `${$t('Option')}-${index + 1}` }}</v-toolbar-title>
                                            <v-spacer />
                                            <v-toolbar-items>
                                                <v-tooltip bottom>
                                                    <template #activator="{ on, attrs }">
                                                        <v-btn v-on="on" v-bind="attrs" icon @click="setMedia(option)">
                                                            <v-icon>mdi-image</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    {{ $t('Media hinzufügen') }}
                                                </v-tooltip>
                                                <v-tooltip bottom>
                                                    <template #activator="{ on, attrs }">
                                                        <v-btn v-on="on" v-bind="attrs" icon @click="removeOption(option)">
                                                            <v-icon>mdi-delete</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    {{ $t('Option entfernen') }}
                                                </v-tooltip>
                                            </v-toolbar-items>
                                        </v-toolbar>
                                        <v-container>
                                            <v-row align="center">
                                                <v-col>
                                                    <v-row no-gutters align="center">
                                                        <v-col cols="auto" v-show="option.fileURL">
                                                            <v-slide-x-transition mode="out-in">
                                                                <KnowledgeUnitOptionMedia :value="option" @preview="handlePreview" @input="replaceOption" />
                                                            </v-slide-x-transition>
                                                        </v-col>
                                                        <v-col>
                                                            <v-text-field
                                                                :value="option.text"
                                                                @input="handleOption(option, 'text', $event)"
                                                                :label="$t('Text')"
                                                                outlined
                                                                hide-details
                                                            />
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <v-icon class="cursor">mdi-cursor-move</v-icon>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card>
                                </v-col>
                            </transition-group>
                        </draggable>
                    </v-col>
                </v-row>
            </v-col>
            <v-col :cols="computedItems.length > 0 ? 6 : 12">
                <v-row>
                    <v-col :cols="computedBuckets.length > 0 && computedItems.length > 0 ? 12 : 6" v-for="(bucket, index) in computedBuckets" :key="bucket.id">
                        <v-card tile :key="`bucket-${bucket.id}`" height="100%">
                            <v-toolbar flat dark dense color="primary">
                                <v-toolbar-title class="text-body-2">{{ `${$t('Kategorie')}-${index + 1}` }}</v-toolbar-title>
                                <v-spacer />
                                <v-toolbar-items>
                                    <v-tooltip bottom>
                                        <template #activator="{ on, attrs }">
                                            <v-btn v-on="on" v-bind="attrs" icon @click="createBucketOption(bucket)">
                                                <v-icon>mdi-note-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        {{ $t('Option in Kategorie erstellen') }}
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template #activator="{ on, attrs }">
                                            <v-btn v-on="on" v-bind="attrs" icon @click="setMedia(bucket)">
                                                <v-icon>mdi-image</v-icon>
                                            </v-btn>
                                        </template>
                                        {{ $t('Media hinzufügen') }}
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template #activator="{ on, attrs }">
                                            <v-btn v-on="on" v-bind="attrs" icon @click="removeBucket(bucket)">
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        {{ $t('Kategorie entfernen') }}
                                    </v-tooltip>
                                </v-toolbar-items>
                            </v-toolbar>
                            <v-container>
                                <v-row align="center">
                                    <v-col :cols="12">
                                        <v-row align="center" no-gutters>
                                            <v-col cols="auto" v-show="bucket.media.fileURL">
                                                <v-slide-x-transition mode="out-in">
                                                    <KnowledgeUnitOptionMedia
                                                        :value="bucket.media"
                                                        @preview="handlePreview"
                                                        @input="replaceBucket(bucket, $event)"
                                                    />
                                                </v-slide-x-transition>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    :value="bucket.text"
                                                    @input="handleBucket(bucket, 'text', $event)"
                                                    :label="$t('Text')"
                                                    outlined
                                                    hide-details
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col :cols="12">
                                        <draggable :list="bucketList(bucket)" group="buckets" @change="handleChange(bucket, $event)">
                                            <transition-group name="slide-x-transition" mode="out-in" tag="v-row" class="row">
                                                <v-col :cols="12" v-for="(option, index) in bucketList(bucket)" :key="option.id">
                                                    <v-card tile :key="`option-${option.id}`">
                                                        <v-toolbar flat dark dense color="secondary">
                                                            <v-toolbar-title class="text-body-2">{{ `${$t('Option')}-${index + 1}` }}</v-toolbar-title>
                                                            <v-spacer />
                                                            <v-toolbar-items>
                                                                <v-tooltip bottom>
                                                                    <template #activator="{ on, attrs }">
                                                                        <v-btn v-on="on" v-bind="attrs" icon @click="setMedia(option)">
                                                                            <v-icon>mdi-image</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    {{ $t('Media hinzufügen') }}
                                                                </v-tooltip>
                                                                <v-tooltip bottom>
                                                                    <template #activator="{ on, attrs }">
                                                                        <v-btn v-on="on" v-bind="attrs" icon @click="removeOption(option)">
                                                                            <v-icon>mdi-delete</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    {{ $t('Option entfernen') }}
                                                                </v-tooltip>
                                                            </v-toolbar-items>
                                                        </v-toolbar>
                                                        <v-card-text>
                                                            <v-row align="center">
                                                                <v-col>
                                                                    <v-row>
                                                                        <v-col cols="auto" v-show="option.fileURL">
                                                                            <v-slide-x-transition mode="out-in">
                                                                                <KnowledgeUnitOptionMedia
                                                                                    :value="option"
                                                                                    @preview="handlePreview"
                                                                                    @input="replaceOption"
                                                                                />
                                                                            </v-slide-x-transition>
                                                                        </v-col>
                                                                        <v-col>
                                                                            <v-text-field
                                                                                :value="option.text"
                                                                                @input="handleOption(option, 'text', $event)"
                                                                                :label="$t('Text')"
                                                                                outlined
                                                                                hide-details
                                                                            />
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                                <v-col cols="auto">
                                                                    <v-icon class="cursor">mdi-cursor-move</v-icon>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                            </transition-group>
                                        </draggable>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import draggable from 'vuedraggable';
import KnowledgeUnitOptionMedia from '../KnowledgeUnitOptionMedia';

export default {
    name: 'bucket-sort',
    components: { draggable, KnowledgeUnitOptionMedia },
    props: {
        buckets: {
            type: Array,
            default: () => []
        },
        value: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        computedBuckets: {
            get() {
                return this.buckets;
            },
            set(value) {
                this.$emit('buckets', value);
            }
        },
        computedItems: {
            get() {
                return this.value.filter((option) => option.sequenceNo === -1);
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        bucketList({ orderNumber }) {
            return this.value.filter((option) => parseInt(option.sequenceNo) === orderNumber);
        },
        createBucketOption({ orderNumber }) {
            this.$emit('input', [
                ...this.value,
                {
                    id: parseInt(performance.now()).toString(),
                    text: '',
                    isAnswer: true,
                    minimum: 0,
                    maximum: 100,
                    steps: 1,
                    sequenceNo: orderNumber,
                    mediaId: '',
                    fileURL: '',
                    fileName: '',
                    fileType: '',
                    size: 0,
                    answeredOrder: 0
                }
            ]);
        },
        removeBucket({ id, orderNumber }) {
            this.$emit(
                'input',
                this.value.map((option) => {
                    if (parseInt(option.sequenceNo) === orderNumber) {
                        return { ...option, sequenceNo: -1 };
                    }
                    return option;
                })
            );
            this.$emit(
                'buckets',
                this.buckets.filter((bucket) => bucket.id !== id)
            );
        },
        handlePreview(option) {
            this.$emit('preview', option);
        },
        setMedia(option) {
            this.$emit('media', option);
        },
        removeOption(option) {
            this.$emit(
                'input',
                this.value.filter((item) => option !== item)
            );
        },
        replaceBucket(bucket, value) {
            this.$emit(
                'buckets',
                this.buckets.map((buck) => {
                    if (buck.id === bucket.id) {
                        return { ...buck, media: value };
                    }
                    return buck;
                })
            );
        },
        replaceOption(option) {
            this.$emit(
                'input',
                this.value.map((item) => {
                    if (item.id === option.id) {
                        return option;
                    }
                    return item;
                })
            );
        },
        handleBucket(bucket, key, value) {
            this.$emit(
                'buckets',
                this.buckets.map((item) => {
                    if (item === bucket) {
                        let copy = { ...item, [key]: value };
                        return copy;
                    }
                    return item;
                })
            );
        },
        handleOption(option, key, value) {
            this.$emit(
                'input',
                this.value.map((item) => {
                    if (item === option) {
                        let copy = { ...item, [key]: value };
                        return copy;
                    }
                    return item;
                })
            );
        },
        handleChange({ orderNumber }, element) {
            if (element && element.added) {
                this.$emit(
                    'input',
                    this.value.map((option) => {
                        if (option.id === element.added.element.id) {
                            return { ...option, sequenceNo: orderNumber };
                        }
                        return option;
                    })
                );
            }
        }
    },
    activated() {
        this.$emit('input', []);
        this.$emit('buckets', [
            {
                id: parseInt(performance.now()).toString(),
                media: {
                    fileName: '',
                    fileType: '',
                    fileURL: '',
                    id: '',
                    size: 0,
                    staticResource: false
                },
                text: '',
                orderNumber: 0
            }
        ]);
    }
};
</script>
