<template>
    <div class="fill-height" :class="isDark ? 'theme--dark v-sheet' : 'white'">
        <Toolbar :toolbarTitle="$t('Archivmanager')" tooltipMessage="Hier können Sie Kataloge im Archiv von User bearbeiten." />
        <ArchiveTool />
    </div>
</template>

<script>
import ArchiveTool from '../../Components/ArchiveTool/ArchiveTool';
import Toolbar from '../../Components/Generic/Toolbar';

export default {
    name: 'archive-tool',
    components: { ArchiveTool, Toolbar },
    computed: {
        isDark() {
            return this.$vuetify.theme.dark;
        }
    }
};
</script>
