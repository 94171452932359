<template>
    <v-card>
        <v-card-text>
            <v-form v-model="isValidated">
                <v-text-field
                    color="accent"
                    :label="$t('Überschrift')"
                    :hint="$t('Geben Sie dem Thema eine Überschrift')"
                    persistent-hint
                    :rules="[notEmpty()]"
                    v-model="thread.title"
                    class="mb-3"
                />
                <v-text-field
                    color="accent"
                    :label="$t('Beschreibung')"
                    :hint="$t('Geben Sie dem Thema eine Eintragsbeschreibung')"
                    persistent-hint
                    v-model="thread.text"
                />
                <v-switch
                    color="accent"
                    :label="$t('Antworten erlauben')"
                    :hint="$t('Nutzer dürfen in diesem Thema antworten')"
                    persistent-hint
                    v-model="thread.canAnswer"
                />
                <v-switch
                    color="accent"
                    :label="$t('Anheften')"
                    :hint="$t('Dieses Thema wird ganz oben in der Liste angeheftet')"
                    persistent-hint
                    v-model="thread.sticky"
                />
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn block :color="saveButtonColor" :disabled="!isValidated" @click="save">
                {{ saveButtonText }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import ForumMixin from './Mixins/ForumMixin';

export default {
    name: 'add-update-thread',
    mixins: [ForumMixin],
    data: () => ({
        isValidated: false,
        thread: {
            title: '',
            text: '',
            canAnswer: true,
            sticky: false
        }
    }),
    props: {
        type: {
            type: String,
            validator: (value) => ['ADD', 'UPDATE'].indexOf(value) !== -1
        }
    },
    computed: {
        saveButtonText() {
            switch (this.type) {
                case 'ADD':
                    return this.$t('Hinzufügen');
                case 'UPDATE':
                    return this.$t('Speichern');
            }
            return '';
        },
        saveButtonColor() {
            switch (this.type) {
                case 'ADD':
                    return 'success';
                case 'UPDATE':
                    return 'warning';
            }
            return '';
        }
    },
    methods: {
        serialize() {
            return this.thread;
        },
        notEmpty() {
            return (value) => !!value || this.$t('Dieses Feld darf nicht leer sein');
        },
        close() {
            this.$emit('input', false);
            this.thread = {
                title: '',
                text: '',
                canAnswer: true,
                sticky: false
            };
        },
        save() {
            switch (this.type) {
                case 'ADD':
                    return this.CREATE_FORUM_THREAD({
                        forum: this.getSelectedForum,
                        thread: this.serialize()
                    }).then(() => this.close());
                case 'UPDATE':
                    return this.UPDATE_FORUM_THREAD({
                        forum: this.getSelectedForum,
                        thread: this.serialize()
                    }).then(() => this.close());
            }
        }
    },
    activated() {
        if (this.type === 'UPDATE') {
            this.thread = JSON.parse(JSON.stringify(this.getSelectedThread));
        } else {
            this.thread = {
                title: '',
                text: '',
                canAnswer: true,
                sticky: false
            };
        }
    }
};
</script>
