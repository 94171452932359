<template>
    <v-card text height="100%" style="overflow: auto">
        <v-toolbar flat dense text color="transparent" v-show="getView.type !== ''">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn medium icon @click="reset" v-on="on">
                        <v-icon medium>mdi-chevron-left</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('Zurück') }}</span>
            </v-tooltip>
            <v-toolbar-title class="text-body-2">{{ typeTitle }}</v-toolbar-title>
        </v-toolbar>
        <keep-alive>
            <component
                :is="componentSwitch"
                :value="value"
                :type="getView.type"
                :onSubmit="onSubmit"
                @switchView="handleSwitchView"
                @input="handleInput"
                :search="search"
                @openTree="handleOpenTree"
                :permissions="permissions"
                :tree="tree"
                :role="computedRole"
                :user="user"
                :admin="admin"
            />
        </keep-alive>
    </v-card>
</template>
<script>
import UsersDisplayView from './UsersDisplayView';
import UsersGroupDisplayView from './UsersGroupDisplayView';
import UsersNoView from './UsersNoView';

import AddUpdateUser from '../../../Users/AddUpdateUser';
import AddUpdateGroup from '../../../Groups/AddUpdateGroup';
import UsersDeleteConfirm from './UsersDeleteConfirm';
import UserTaskDetails from './UserTaskDetails';
import GroupTaskDetails from './GroupTaskDetails';

import UserMixin from '../../Mixins/UserMixin';

export default {
    name: 'users-view',
    components: {
        UsersDisplayView,
        UsersGroupDisplayView,
        UsersNoView,
        AddUpdateUser,
        AddUpdateGroup,
        UsersDeleteConfirm,
        UserTaskDetails
    },
    mixins: [UserMixin],
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        search: {
            type: String,
            default: ''
        },
        permissions: {
            type: Object,
            default: () => ({
                add: false,
                update: false,
                delete: false,
                change: false
            })
        },
        tree: {
            type: Array,
            default: () => []
        },
        user: {
            type: Boolean,
            default: false
        },
        admin: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedRole() {
            if (this.user) {
                return 'USER';
            }
            if (this.admin) {
                return 'ADMIN';
            }
            return '';
        },
        componentSwitch() {
            if (this.getView.component !== '') {
                switch (this.getView.component) {
                    case 'CREATE_USER':
                        return AddUpdateUser;
                    case 'UPDATE_USER':
                        return AddUpdateUser;
                    case 'DELETE_USER':
                        return UsersDeleteConfirm;
                    case 'CREATE_GROUP':
                        return AddUpdateGroup;
                    case 'UPDATE_GROUP':
                        return AddUpdateGroup;
                    case 'DELETE_GROUP':
                        return UsersDeleteConfirm;
                    case 'USER_EFFORTS':
                        return UserTaskDetails;
                    case 'GROUP_EFFORTS':
                        return GroupTaskDetails;
                }
            }
            switch (this.isUserOrGroup(this.value)) {
                case 'USER':
                    return UsersDisplayView;
                case 'GROUP':
                    return UsersGroupDisplayView;
            }
            return UsersNoView;
        },
        typeTitle() {
            switch (this.getView.component) {
                case 'CREATE_USER':
                    return this.user ? this.$t('Nutzer erstellen') : this.admin ? this.$t('Admin erstellen') : '';
                case 'UPDATE_USER':
                    return this.user ? this.$t('Nutzer bearbeiten') : this.admin ? this.$t('Admin bearbeiten') : '';
                case 'DELETE_USER':
                    return this.user ? this.$t('Nutzer löschen') : this.admin ? this.$t('Admin löschen') : '';
                case 'CREATE_GROUP':
                    return this.user ? this.$t('Nutzergruppe erstellen') : this.admin ? this.$t('Admingruppe erstellen') : '';
                case 'UPDATE_GROUP':
                    return this.user ? this.$t('Nutzergruppe bearbeiten') : this.admin ? this.$t('Admingruppe bearbeiten') : '';
                case 'DELETE_GROUP':
                    return this.user ? this.$t('Nutzergruppe löschen') : this.admin ? this.$t('Admingruppe löschen') : '';
                case 'USER_EFFORTS':
                    return this.user ? this.$t('Nutzer Leistungen') : this.admin ? this.$t('Admin Leistungen') : '';
                case 'GROUP_EFFORTS':
                    return this.user ? this.$t('Nutzergruppen Leistungen') : this.admin ? this.$t('Admingruppen Leistungen') : '';
            }
            return '';
        }
    },
    methods: {
        onSubmit() {
            switch (this.getView.component) {
                case 'DELETE_GROUP':
                    return (v) => {
                        if (this.user) {
                            return this.DELETE_USER_GROUP(v)
                                .then(() => this.$emit('input', {}))
                                .then(() => this.reset());
                        }
                        if (this.admin) {
                            return this.DELETE_ADMIN_GROUP(v)
                                .then(() => this.$emit('input', {}))
                                .then(() => this.reset());
                        }
                    };
                case 'DELETE_USER':
                    return (v) => {
                        if (this.user) {
                            return this.DELETE_USER_BY_ID(v.id)
                                .then(() => this.$emit('input', {}))
                                .then(() => this.reset());
                        }
                        if (this.admin) {
                            return this.DELETE_ADMIN_BY_ID(v.id)
                                .then(() => this.$emit('input', {}))
                                .then(() => this.reset());
                        }
                    };
            }
            return () => {};
        },
        reset() {
            this.SET_VIEW({ view: '', data: {}, type: '' });
        },
        handleInput(value) {
            this.$emit('input', value);
        },
        handleOpenTree(value) {
            this.$emit('openTree', value);
        },
        handleSwitchView(value) {
            this.SET_VIEW(value);
        }
    }
};
</script>
