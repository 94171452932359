<template>
    <v-card flat height="100%">
        <v-toolbar flat dense text color="transparent">
            <v-spacer />
            <v-toolbar-items>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon color="warning" @click="editKnowledgeContent()" :disabled="!permissions.update">
                            <v-icon medium>mdi-file-document-edit</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Content bearbeiten') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon color="accent" @click="copyKnowledgeContent()" :disabled="!permissions.copy">
                            <v-icon medium>mdi-file-multiple</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Content kopieren') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon color="accent" @click="moveKnowledgeContent()" :disabled="!permissions.move">
                            <v-icon medium>mdi-file-move</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Content verschieben') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon color="error" @click="deleteKnowledgeContent()" :disabled="!permissions.delete">
                            <v-icon medium>mdi-file-remove</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Content löschen') }}</span>
                </v-tooltip>
            </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-toolbar text dark color="secondary" dense>
            <v-toolbar-title class="text-body-2">{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-container>
            <v-row>
                <v-col :cols="12">
                    <v-card>
                        <v-card-text>
                            <div class="ck ck-content" v-html="content" />
                            <div style="clear: both" />
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col :cols="12" v-show="tags.length > 0">
                    <KnowledgeContentTags :items="tags" />
                </v-col>
                <v-col :cols="12" v-show="links.length > 0">
                    <KnowledgeContentLinks :items="links" @input="handleLink" />
                </v-col>
                <v-col :cols="12">
                    <KnowledgeContentStateChanger :key="stateKey" :value="value" :permission="permissions.change" />
                </v-col>
                <v-col :xs="12" :sm="6">
                    <v-card height="100%">
                        <v-card-text>
                            <v-switch
                                color="accent"
                                readonly
                                :input-value="viewTillEnd"
                                :label="$t('Video & Audio Medien müssen konsumiert werden')"
                                :hint="
                                    $t(
                                        'Wenn diese Option eingeschaltet ist, müssen Nutzer Videos und/oder Audio bis zum ende gesehen bzw. gehört haben, damit der Nutzer die Wissenseinheit bearbeiten kann'
                                    )
                                "
                                persistent-hint
                            />
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col :xs="12" :sm="6">
                    <v-card height="100%">
                        <v-card-text>
                            <v-switch
                                color="accent"
                                readonly
                                :input-value="disableSkipping"
                                :label="$t('Video & Audio Medien können nicht übersprungen werden')"
                                :hint="$t('Wenn diese Option eingeschaltet ist, kann der Nutzer Videos und/oder Audio nicht überspringen')"
                                persistent-hint
                            />
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-list>
            <v-subheader>{{ $t('Zusätzliche Infos') }}</v-subheader>
            <v-list-item>
                <v-list-item-action>
                    <UserProfile :value="value.createdBy" small :displayName="false" />
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('Erstellt') }}</v-list-item-title>
                    <v-list-item-subtitle>
                        {{ value.createdAt }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-action>
                    <UserProfile :value="value.updatedBy" small :displayName="false" />
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('Bearbeitet') }}</v-list-item-title>
                    <v-list-item-subtitle>
                        {{ value.updatedAt }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>
import KnowledgeContentMixin from '../Mixins/KnowledgeContentMixin';
import KnowledgeContentTags from '../KnowledgeContentTags';
import KnowledgeContentLinks from '../KnowledgeContentLinks';
import KnowledgeContentStateChanger from './KnowledgeContentStateChanger';
import UserProfile from '../../Generic/Profile/UserProfile';

export default {
    name: 'knowledge-content-content-view',
    mixins: [KnowledgeContentMixin],
    components: {
        KnowledgeContentTags,
        KnowledgeContentLinks,
        KnowledgeContentStateChanger,
        UserProfile
    },
    data: () => ({
        stateKey: 0
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        search: {
            type: String,
            default: ''
        },
        permissions: {
            type: Object,
            default: () => ({
                add: false,
                update: false,
                delete: false,
                change: false
            })
        }
    },
    watch: {
        value() {
            this.stateKey = performance.now();
        }
    },
    computed: {
        viewTillEnd() {
            return this.value && this.value.viewTillEnd ? this.value.viewTillEnd : false;
        },
        disableSkipping() {
            return this.value && this.value.disableSkipping ? this.value.disableSkipping : false;
        },
        title() {
            return this.value && this.value.title ? this.value.title : '';
        },
        content() {
            return this.value && this.value.content ? this.value.content : '';
        },
        tags() {
            return this.value && this.value.tags ? this.value.tags.split(',') : [];
        },
        links() {
            return this.value && this.value.knowledgeContentLinks ? this.value.knowledgeContentLinks : [];
        }
    },
    methods: {
        editKnowledgeContent() {
            this.$emit('switchView', {
                component: 'UPDATE_KNOWLEDGE_CONTENT',
                type: 'UPDATE',
                data: {}
            });
        },
        moveKnowledgeContent() {
            this.$emit('switchView', {
                component: 'MOVE_KNOWLEDGE_CONTENT',
                type: 'MOVE',
                data: {}
            });
        },
        deleteKnowledgeContent() {
            this.$emit('switchView', {
                component: 'DELETE_KNOWLEDGE_CONTENT',
                type: 'DELETE',
                data: {}
            });
        },
        copyKnowledgeContent() {
            this.$emit('switchView', {
                component: 'COPY_KNOWLEDGE_CONTENT',
                type: 'DELETE',
                data: {}
            });
        },
        handleLink(value) {
            this.$emit('input', value);
        }
    }
};
</script>
