<template>
    <v-app id="app" :style="getBackgroundImageStyle">
        <NavigationDrawer />
        <NotificationReporter />
        <AjaxLoader />
        <v-main>
            <router-view />
        </v-main>
        <AppFooter />
        <MediaPreview :showDialog="showMedia" @showDialog="(value) => (showMedia = value)" v-model="media" />
    </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AjaxLoader from './Components/Global/AjaxLoader';
import NotificationReporter from './Components/Global/NotificationReporter';
import NavigationDrawer from './Components/Global/NavigationDrawer';
import AppFooter from './Components/Global/ApplicationFooter';
import MediaPreview from './Components/FileManager/MediaPreview';
import Vue from 'vue';
import Vuetify from 'vuetify';
import GlobalMixin from './Utilities/GlobalMixin';
Vue.use(Vuetify);

Vue.mixin(GlobalMixin);

export default {
    name: 'app',
    data: () => ({
        showMedia: false,
        media: {},
        invalid: false
    }),
    components: {
        AjaxLoader,
        NotificationReporter,
        NavigationDrawer,
        AppFooter,
        MediaPreview
    },
    watch: {
        getLoggedInAdmin(value) {
            if (Object.keys(value).length > 0) {
                this.CHAT_CONNECT(value);
                this.GET_GENERIC_SETTINGS();
                this.GET_GENERIC_CUSTOMER_SETTINGS();
            }
        },
        getStyles(styles) {
            if (styles.light && styles.dark) {
                this.setColors(styles[this.currentMode], this.currentMode);
                return;
            }
            this.setColors(styles, 'light');
        },
        getErrorMessages() {
            if (this.getErrorMessages.map((error) => (error && error.type ? error.type : '')).some((type) => type && type === 'INVALID_TOKEN')) {
                if (!this.invalid) {
                    this.CLEAR_USER_DATA();
                    this.LOGOUT({ invalidToken: true });
                    this.invalid = true;
                    this.$vuetify.theme.dark = false;
                    setTimeout(() => {
                        this.invalid = false;
                    }, 900);
                }
            }
        }
    },
    computed: {
        ...mapGetters('SystemSettings', ['getStyles', 'getBackgroundImageStyle', 'getLogo']),
        ...mapGetters('ErrorReporterOperations', ['getErrorMessages']),
        currentMode() {
            return this.$vuetify.theme.dark ? 'dark' : 'light';
        }
    },
    methods: {
        ...mapActions('NotificationOperations', ['SET_NOTIFICATION_MESSAGE']),
        ...mapActions('SystemSettings', ['GET_GENERIC_SETTINGS', 'GET_GENERIC_CUSTOMER_SETTINGS']),
        ...mapActions('ChatOperations', ['CHAT_CONNECT']),
        ...mapActions('General', ['LOGOUT', 'CLEAR_USER_DATA']),
        updateOnlineStatus() {
            const condition = navigator.onLine ? 'online' : 'offline';
            if (condition === 'online') this.SET_NOTIFICATION_MESSAGE(this.$t('Connection established'));
            if (condition === 'offline') this.SET_NOTIFICATION_MESSAGE(this.$t('Connection lost'));
        },
        mediaHandler(event) {
            const path = event.path.map(({ className }) => className);
            const index = path.indexOf('ck ck-content');
            if (index !== -1) {
                const list = event.path.slice(0, index);
                const image = list.find(({ tagName }) => tagName === 'IMG');
                if (image && image.src) {
                    this.media = { fileURL: image.src, fileType: 'image/*' };
                    this.showMedia = true;
                }
            }
        },
        setColors(styles, mode) {
            Object.entries(styles).forEach(([key, value]) => {
                if (Object.keys(value).length !== 0) {
                    this.$vuetify.theme.themes[mode][key] = value;
                }
            });
        }
    },
    beforeMount() {
        // temporarily, clear old version
        const isCleared = JSON.parse(window.localStorage.getItem('clearedOld'));
        if (!isCleared) {
            window.localStorage.clear();
            window.localStorage.setItem('clearedOld', 'true');
        }
    },
    mounted() {
        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);
        window.addEventListener('click', this.mediaHandler, { capture: true, passive: true });
        this.GET_GENERIC_SETTINGS();
        if (Object.keys(this.getLoggedInAdmin).length > 0) {
            this.GET_GENERIC_CUSTOMER_SETTINGS();
            this.CHAT_CONNECT(this.getLoggedInAdmin);
        }
    }
};
</script>
