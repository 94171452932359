<template>
    <v-card class="fill-height">
        <Toolbar :toolbarTitle="$t('Meine Kurse')" />
        <v-tabs slider-color="accent" show-arrows mandatory v-model="computedSelectedTab" @change.once="selectMyCourseTab">
            <v-tab key="USER_CURRENT_COURSES">
                <v-badge color="error" :content="getTotalCourseCount" :value="getTotalCourseCount > 0" overlap>
                    {{ $t('Aktuelle Kurse') }}
                </v-badge>
            </v-tab>
            <v-tab key="USER_PAST_COURSES">{{ $t('Vergangene Kurse') }}</v-tab>
            <v-tab key="USER_SCORM_COURSES">
                <v-badge color="error" :content="getTotalElearningCount" :value="getTotalElearningCount > 0" overlap>
                    {{ $t('Meine E-Learnings') }}
                </v-badge>
            </v-tab>
            <v-tab key="ENROLLABLE_SCORMS">
                {{ $t('Einschreiben') }}
            </v-tab>
        </v-tabs>
        <v-card-text>
            <v-tabs-items v-model="computedSelectedTab" touchless>
                <v-tab-item key="USER_CURRENT_COURSES">
                    <UserCurrentCourses :isMyCourse="true" />
                </v-tab-item>
                <v-tab-item key="USER_PAST_COURSES">
                    <UserPastCourses :isMyCourse="true" />
                </v-tab-item>
                <v-tab-item key="USER_SCORM_COURSES">
                    <Scormhomepage :scorms="getScorms" nodeContext="START_COURSE" />
                </v-tab-item>
                <v-tab-item key="ENROLLABLE_SCORMS" v-if="this.getLoggedInAdmin !== 'SUPER_ADMIN'">
                    <EnrollableScorm />
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
    </v-card>
</template>

<script>
import CourseMixin from '../../../Components/CourseLearning/CourseManager/Mixins/CourseMixin';
import ScormMixin from '../../../Components/SCORM/Mixins/ScormMixin';

import Toolbar from '../../../Components/Generic/Toolbar';

import UserCurrentCourses from '../../../Components/CourseLearning/CourseManager/UserCurrentCourses';
import UserPastCourses from '../../../Components/CourseLearning/CourseManager/UserPastCourses';
import Scormhomepage from '../../../Components/SCORM/Scormhomepage';
import ChatMixin from '../../../Components/Chat/Mixins/ChatMixin';
import EnrollableScorm from '../../../Components/SCORM/EnrollableScorm';

export default {
    name: 'my-courses',
    mixins: [CourseMixin, ChatMixin, ScormMixin],
    components: { Toolbar, UserCurrentCourses, UserPastCourses, Scormhomepage, EnrollableScorm },
    data: () => ({
        // tabs: 0
    }),
    methods: {
        selectMyCourseTab() {
            this.GET_PAST_COURSES_AS_ATTENDEE();
        }
    },
    computed: {
        getScorms() {
            return this.getAssignedUserScorms;
        },
        computedSelectedTab: {
            get() {
                return this.getTabs;
            },
            set(value) {
                this.SET_SELECTED_TAB(value);
            }
        }
    },
    created() {
        this.resetCourseSelection();
    },
    mounted() {
        this.GET_COURSE_PICTURE();
        this.GET_ASSIGNED_USER_SCORMS();
        this.SET_SELECTED_SCORM({});
    }
};
</script>
