<template>
    <v-container fluid ma-0 pa-0 fill-height>
        <v-layout column>
            <v-flex shrink>
                <Toolbar :toolbarTitle="$t('Lernende')" tooltipMessage="Hier können Sie Benutzer betrachten, hinzufügen, bearbeiten und löschen." />
            </v-flex>
            <v-flex grow>
                <Users user />
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
import Users from '../../../Components/Users/Prototype/Users';
import Toolbar from '../../../Components/Generic/Toolbar';

export default {
    name: 'users',
    components: { Users, Toolbar }
};
</script>
