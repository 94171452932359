<template>
    <v-container>
        <v-card>
            <v-card-text>
                <v-row>
                    <v-col :cols="12">
                        <v-row>
                            <v-col :cols="6">
                                <v-row align="center">
                                    <v-col>
                                        <GradingTableSelector />
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-tooltip bottom>
                                            <template #activator="{ on, attrs }">
                                                <v-btn icon v-on="on" v-bind="attrs" @click="goToGradingTable" color="purple">
                                                    <v-icon>mdi-format-list-checks</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('Bewertungstabellen') }}</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col :cols="6">
                                <TopicSelector />
                            </v-col>
                            <v-col :cols="6">
                                <LearningGoalSelector />
                            </v-col>
                            <v-col :cols="6">
                                <ActivitySelector />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col :cols="12">
                        <EvaluationTable />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import TopicSelector from './Helpers/Evaluation/TopicSelector';
import LearningGoalSelector from './Helpers/Evaluation/LearningGoalSelector';
import ActivitySelector from './Helpers/Evaluation/ActivitySelector';
import GradingTableSelector from './Helpers/Evaluation/GradingTableSelector';
import EvaluationTable from './Helpers/Evaluation/EvaluationTable';
import CourseBreadcrumbs from './Breadcrumbs/CourseBreadcrumbs';

export default {
    name: 'evaluations',
    components: {
        TopicSelector,
        LearningGoalSelector,
        ActivitySelector,
        GradingTableSelector,
        EvaluationTable
    },
    methods: {
        goToGradingTable() {
            this.$router.push({ name: 'gradingTables' });
        }
    }
};
</script>
