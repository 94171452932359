<template>
    <div class="fill-height" :class="isDark ? 'theme--dark v-sheet' : 'white'">
        <Toolbar
            tooltipMessage="Hier können Sie unvollständige und vollständige Aufgaben zu archivieren. Alle Archive, die Sie hinzugefügt haben, können Sie bearbeiten und löschen."
            :toolbarTitle="$t('Archiv')"
        />

        <Archive
            @startTask="startQuiz"
            @editTask="updateArchive"
            :activeTaskArchiveList="getUserIncompleteArchiveList"
            :inactiveTaskArchiveList="getUserCompleteArchiveList"
            type="ARCHIVE"
        />
    </div>
</template>

<script>
import Toolbar from '../../Components/Generic/Toolbar';
import Archive from '../../Components/UserApp/TaskArchive';
import QuizMixin from '../../Components/UserApp/Mixins/QuizMixin';

export default {
    components: {
        Toolbar,
        Archive
    },
    mixins: [QuizMixin],
    data: () => ({
        validateState: {
            add: [],
            update: [],
            delete: [],
            move: [],
            details: [],
            groups: []
        }
    }),
    computed: {
        isValidQuiz() {
            return (
                this.getSelectedTaskArchive &&
                this.getSelectedTaskArchive.data &&
                Object.keys(this.getSelectedTaskArchive.data).length > 0 &&
                this.getUserIncompleteArchiveList.map((task) => task.id).includes(this.getSelectedTaskArchive.data.id) &&
                this.getActiveTab == 0
            );
        },
        isDark() {
            return this.$vuetify.theme.dark;
        }
        // selectedTask: {
        // 	get() {
        // 		return this.getSelectedTaskArchive.data;
        // 	},
        // 	set(value) {
        // 		this.SELECT_ARCHIVE(value);
        // 	}
        // }
    },
    methods: {
        startQuiz(task) {
            this.SELECT_ARCHIVE(task);
            // this.OPEN_DRAWER(false).then(() => {
            this.$router.push({ name: 'appQuiz' });
            // });
        },
        addArchive() {
            this.$router.push({ name: 'appArchiveAdd' });
        },
        updateArchive(task) {
            this.SELECT_ARCHIVE(task);
            this.$router.push({ name: 'appArchiveUpdate' });
        },
        deleteArchive() {
            this.$router.push({ name: 'appArchiveDelete' });
        },
        startQuizEmit() {
            this.$router.push({ name: 'appQuiz' });
        }
    }
};
</script>
