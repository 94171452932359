import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

export default {
    data() {
        return {
            tooltipMessage: '',
            archiveObj: {
                id: '',
                name: '',
                isCompleted: false,
                owners: [],
                startDate: moment().format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD'),
                taskOptions: {
                    sortBy: 'NATURAL',
                    sortList: ['NATURAL', 'RANDOM', 'DATE'],
                    limits: 0,
                    extendEndDate: '',
                    passingQuote: 0,
                    methodName: '',
                    methodList: ['Lernkurs', 'Prüfungs']
                },
                catalogs: [],
                users: [],
                excludeKnowledgeUnits: [],
                links: {
                    self: '',
                    knowledgeUnits: '',
                    owners: '',
                    users: ''
                }
            },
            showExtendDate: false,
            showLimits: false,
            showPassingQuote: false,
            disableStartDate: false,
            actionButtonTitle: '',
            actionButtonColor: '',
            notificationDayList: [1],
            showDatePicker: false
        };
    },
    computed: {
        ...mapGetters('General', ['getLoggedInAdmin']),
        ...mapGetters('UserApp', [
            'getTaskArchiveResult',
            'getUserIncompleteTaskList',
            'getUserCompleteTaskList',
            'getUserIncompleteArchiveList',
            'getUserCompleteArchiveList',
            'getSelectedTaskArchive',
            'getArchive',
            'getArchiveStore',
            'getBackPath'
        ]),
        ...mapGetters('Catalog', ['getCatalogList', 'getSelectedCatalog'])
    },
    methods: {
        ...mapActions('Catalog', ['GET_ARCHIVE_CATALOG_LIST', 'SELECT_CATALOG']),
        ...mapActions('UserApp', [
            'GET_USERTASK_RESULTS',
            'GET_ARCHIVETASK_RESULTS',
            'SELECT_TASK',
            'SELECT_ARCHIVE',
            'GET_ARCHIVE_BY_ID',
            'GET_USERTASK_RESULTS',
            'GET_ARCHIVETASK_RESULTS',
            'GET_INCOMPLETE_USERTASK_LIST',
            'GET_COMPLETE_USERTASK_LIST',
            'GET_INCOMPLETE_ARCHIVE_LIST',
            'GET_COMPLETE_ARCHIVE_LIST',
            'RESET_SELECTED_TASKARCHIVE',
            'RESET_ARCHIVE_STORE',
            'ADD_ARCHIVE',
            'UPDATE_ARCHIVE',
            'UPDATE_ARCHIVE_BACKPATH',
            'UPDATE_ARCHIVE_STORE',
            'DELETE_ARCHIVE_BY_ID',
            'UPDATE_ARCHIVE_SELECTED_CATALOG'
        ]),
        cardColor(task) {
            if (task.selected && !task.completed) {
                return 'headline primary white--text';
            } else if (task.selected == false && !task.completed) {
                return 'headline secondary white--text';
            } else if (task.completed) {
                return 'headline secondary white--text';
            }
        },
        cardHighlight(task) {
            if (task.selected && !task.completed) {
                return 'mb-3 elevation-12 transparent file';
            } else if (task.selected == false && !task.completed) {
                return 'ma-3 transparent file';
            } else if (task.completed) {
                return 'ma-3 transparent file';
            }
        },
        progressColor(task) {
            if (task.completed) {
                return 'primary lighten-1';
            } else {
                return 'primary lighten-3';
            }
        },
        chipColor(task) {
            if (this.isFutureTask(task)) {
                return 'body-2 grey lighten-1 white--text';
            } else if (task.selected && !task.completed) {
                return 'body-2 primary white--text';
            } else if (task.selected == false && !task.completed) {
                return 'body-2 primary lighten-2 white--text';
            } else if (task.completed) {
                return 'body-2 grey lighten-1 white--text';
            }
        },
        progressBar(votedKU, totalKU) {
            return parseInt((votedKU / totalKU) * 100);
        },
        isFutureTask(task) {
            return !moment(task.startDate).isBefore(moment());
        },
        selectTaskEmit(task) {
            if (!this.isFutureTask(task)) this.$emit('selectTaskEmit', task);
        },
        //EMITED FUNCTION
        catalogsEmit(catalogs) {
            this.archiveObj.catalogs = catalogs;
            var catalogIds = catalogs.map((catalog) => {
                return catalog.id;
            });
            // REMOVE THE RELATED CATALOG EXCLUDEKU
            var temp = [];
            var exKU = this.archiveObj.excludeKnowledgeUnits;
            for (var i = 0; i < exKU.length; i++) {
                if (catalogIds.includes(exKU[i].catalogId)) temp.push(exKU[i]);
            }
            this.archiveObj.excludeKnowledgeUnits = temp;
        },
        excludeKnowledgeUnitsEmit(excludeKnowledgeUnitsEmit) {
            this.archiveObj.excludeKnowledgeUnits = excludeKnowledgeUnitsEmit;
        },
        userListEmit(userList) {
            this.archiveObj.users = userList;
        },
        extendEndDateEmit(val) {
            this.archiveObj.taskOptions.extendEndDate = val;
        },
        taskNameEmit(val) {
            this.archiveObj.name = val;
        },
        methodEmit(val) {
            this.archiveObj.taskOptions.methodName = val;
        },
        startDateEmit(val) {
            this.archiveObj.startDate = val;
        },
        endDateEmit(val) {
            this.archiveObj.endDate = val;
        },
        limitsEmit(val) {
            this.archiveObj.taskOptions.limits = parseInt(val);
        },
        passingQuoteEmit(val) {
            this.archiveObj.taskOptions.passingQuote = parseInt(val);
        },
        showExtendDateEmit(val) {
            this.showExtendDate = val;
            if (!this.showExtendDate) this.archiveObj.taskOptions.extendEndDate = '';
            else this.archiveObj.taskOptions.extendEndDate = this.archiveObj.endDate;
        },
        reapplyData(data) {
            this.showExtendDate = data['taskOptions']['extendEndDate'] == '' ? false : true;
            this.showLimits = data['taskOptions']['limits'] == 0 ? false : true;
            this.showPassingQuote = data['taskOptions']['passingQuote'] == 0 ? false : true;
        }
    }
};
