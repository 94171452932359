<template>
    <v-card>
        <span>Quiz Activity</span>
        <pre>{{ value }}</pre>
    </v-card>
</template>

<script>
export default {
    name: 'quiz-type',
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    }
};
</script>
