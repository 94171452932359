<template>
    <v-card height="100%">
        <v-container fluid fill-height ma-0 pa-0>
            <v-layout column>
                <v-flex shrink>
                    <v-toolbar flat dense outlined>
                        <v-autocomplete
                            v-model="computedSearchItem"
                            hide-details
                            return-object
                            :search-input.sync="computedSearch"
                            item-key="id"
                            item-value="id"
                            :filter="filter"
                            :items="flattendTree"
                            :label="$t('Suchen')"
                            :no-data-text="$t('Keine Daten')"
                            :no-results-text="$t('Kein Ergebnis')"
                            :loading="searchDebouncer !== null"
                            :no-filter="searchDebouncer !== null"
                            :disabled="flattendTree.length === 0"
                            @focus="resetSearchSelection"
                            :menu-props="{ transition: 'slide-y-transition' }"
                        >
                            <template v-slot:selection="{ item, selected }">
                                <v-chip :value="!search" color="primary" dark>
                                    <span>{{ item.name || item.title }}</span>
                                </v-chip>
                            </template>
                            <template v-slot:item="{ item, on, attrs }">
                                <v-list-item dense v-on="on" v-bind="attrs">
                                    <v-list-item-icon>
                                        <v-icon medium :color="iconColor(item)">{{ icon(item, false) }}</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.name || item.title }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ pathToSearchItem(item) }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-autocomplete>
                        <template v-slot:extension>
                            <v-flex shrink v-show="selectable">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" icon @click="showListView">
                                            <v-icon>mdi-format-list-bulleted</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('Auswahlliste') }}</span>
                                </v-tooltip>
                            </v-flex>
                            <v-flex shrink v-show="!showList">
                                <v-btn icon @click="goUp" :disabled="!isSelected">
                                    <v-icon>mdi-folder-upload</v-icon>
                                </v-btn>
                            </v-flex>
                            <v-flex grow v-show="!showList">
                                <v-breadcrumbs :items="breadcrumbPath">
                                    <template v-slot:item="{ item }">
                                        <span
                                            :class="{
                                                pointer: item !== computedSelectedItem,
                                                'truncate-path': true
                                            }"
                                            @click="computedSelectedItem = [item]"
                                            >{{ item.name || item.title }}
                                        </span>
                                    </template>
                                </v-breadcrumbs>
                            </v-flex>
                        </template>
                    </v-toolbar>
                </v-flex>
                <v-flex grow>
                    <keep-alive>
                        <component
                            :is="contentManagerComponent"
                            :component="selectedComponent"
                            v-model="computedSelectedItemArray"
                            :search="searchConfirm"
                            @openTree="handleOpenTree"
                            :selected="value"
                            @selected="handleSelection"
                            @switchView="(value) => (showList = value)"
                            :selectable="selectable"
                            :permissions="permissions"
                            @onCreate="handleOnCreate"
                            @onUpdate="handleOnUpdate"
                            @onDelete="handleOnDelete"
                        >
                            <v-treeview
                                :items="getTree"
                                transition
                                activatable
                                :active.sync="computedSelectedItemArray"
                                return-object
                                :open.sync="openPath"
                                class="pt-2"
                                ref="tree"
                                item-key="id"
                                dense
                            >
                                <template v-slot:prepend="{ item, open }">
                                    <v-icon :color="iconColor(item)">
                                        {{ icon(item, open) }}
                                    </v-icon>
                                </template>
                                <template v-slot:label="{ item, active }">
                                    <v-layout :ref="item.id">
                                        <v-flex shrink @click.stop v-if="selectable && isKnowledgeContentType(item) === 'KNOWLEDGE_CONTENT'" class="pa-0 ma-0">
                                            <v-badge :value="isChecked(item)" :content="getSelectedItemIndex(item)" color="primary" inline>
                                                <v-simple-checkbox :ripple="false" :value="isChecked(item)" @input="handleCheck($event, item)" />
                                            </v-badge>
                                        </v-flex>
                                        <v-flex align-self-center>
                                            <span
                                                :class="{
                                                    'primary--text': active,
                                                    pointer: true,
                                                    'no-highlight': true,
                                                    'truncate-tree-item': true
                                                }"
                                            >
                                                {{ item.name || item.title }}
                                            </span>
                                        </v-flex>
                                    </v-layout>
                                </template>
                            </v-treeview>
                        </component>
                    </keep-alive>
                </v-flex>
            </v-layout>
        </v-container>
    </v-card>
</template>
<script>
import KnowledgeContentDesktopView from './Helpers/KnowledgeContentDesktopView';
import KnowledgeContentMobileView from './Helpers/KnowledgeContentMobileView';
import KnowledgeContentView from './Helpers/KnowledgeContentView';
import KnowledgeContentSelectionView from './Helpers/KnowledgeContentSelectionView';

import KnowledgeContentCheckbox from './Helpers/KnowledgeContentCheckbox';

import KnowledgeContentMixin from './Mixins/KnowledgeContentMixin';

export default {
    name: 'knowledge-content-manager',
    components: {
        KnowledgeContentDesktopView,
        KnowledgeContentMobileView,
        KnowledgeContentView,
        KnowledgeContentCheckbox,
        KnowledgeContentSelectionView
    },
    mixins: [KnowledgeContentMixin],
    data: () => ({
        isLoaded: false,
        showList: false,
        search: '',
        selectedSearch: {},
        searchConfirm: '',
        searchDebouncer: null,
        selectedItem: [],
        openPath: [],
        permissions: {
            create: false,
            update: false,
            delete: false,
            move: false,
            copy: false,
            change: false
        }
    }),
    props: {
        selectable: {
            type: Boolean,
            default: false
        },
        value: {
            type: Array,
            default: () => []
        }
    },
    watch: {
        selectedItem() {
            this.reset();
        }
    },
    computed: {
        computedSearch: {
            get() {
                return this.search;
            },
            set(value) {
                this.search = value;
                if (this.searchDebouncer) {
                    clearTimeout(this.searchDebouncer);
                    this.searchDebouncer = null;
                }
                if (value !== '') {
                    this.searchDebouncer = setTimeout(
                        function () {
                            this.data.searchConfirm = this.search;
                            this.data.searchDebouncer = null;
                        }.bind({ data: this, search: value }),
                        1500
                    );
                } else {
                    clearTimeout(this.searchDebouncer);
                    this.searchDebouncer = null;
                    this.searchConfirm = '';
                }
            }
        },
        breadcrumbPath() {
            return [...this.parentPath, this.computedSelectedItem];
        },
        parentPath() {
            let path = [];
            const pathfinder = (parent) => {
                const node = this.getParent(parent);
                if (node !== undefined) {
                    path.unshift(node);
                    pathfinder(node);
                }
            };
            if (this.hasParent) {
                pathfinder(this.selectedItem[0]);
            }
            return path;
        },
        isSelected() {
            return this.selectedItem.length > 0;
        },
        hasParent() {
            return this.isSelected ? !!this.getParent(this.selectedItem[0]) : false;
        },
        computedSelectedItemArray: {
            get() {
                return this.selectedItem;
            },
            set(value) {
                this.selectedItem = value;
                this.openPath = [...this.openPath, ...this.parentPath];
            }
        },
        computedSelectedItem: {
            get() {
                return this.selectedItem.length > 0 ? this.selectedItem[0] : {};
            },
            set(value) {
                this.selectedItem = value;
                this.openPath = [...this.openPath, ...this.parentPath];
            }
        },
        computedSearchItem: {
            get() {
                return this.selectedSearch;
            },
            set(value) {
                this.selectedSearch = value;
                this.computedSelectedItem = value ? [value] : [];
            }
        },
        contentManagerComponent() {
            if (this.showList) {
                return KnowledgeContentSelectionView;
            }
            if (this.isLoaded) {
                if (this.$vuetify.breakpoint.xs) {
                    return KnowledgeContentMobileView;
                }
                return KnowledgeContentDesktopView;
            } else {
                if (this.$vuetify.breakpoint.xs) {
                    return KnowledgeContentDesktopView;
                } else {
                    return KnowledgeContentMobileView;
                }
            }
        },
        selectedComponent() {
            return KnowledgeContentView;
        },
        flattendTree() {
            return this.getKnowledgeContents;
        }
    },
    methods: {
        handleOnCreate(data) {
            this.$emit('onCreate', data);
        },
        handleOnUpdate(data) {
            this.$emit('onUpdate', data);
        },
        handleOnDelete(data) {
            this.$emit('onDelete', data);
        },
        resetSearchSelection() {
            this.selectedSearch = {};
        },
        filter(item) {
            if (!this.searchConfirm || this.searchConfirm.trim() === '') {
                return true;
            }
            if (this.searchConfirm === this.search) {
                let buildString = [...new Set(this.searchConfirm.split(' '))].filter((word) => word !== '').map((word) => word.toLowerCase());
                const text = Object.values(item)
                    .filter((data) => typeof data === 'string' || typeof data === 'number')
                    .map((data) => {
                        if (typeof data === 'number') {
                            return data.toString();
                        }
                        return data;
                    })
                    .join(' ')
                    .toLowerCase();
                return buildString.every((word) => text.includes(word));
            } else {
                return true;
            }
        },
        getSelectedItemIndex({ id }) {
            return this.value.findIndex((item) => item.id === id) + 1;
        },
        reset() {
            this.SET_VIEW({ component: '', type: '', data: {} });
        },
        showListView() {
            this.showList = !this.showList;
        },
        isChecked({ id }) {
            return this.value.map((item) => item.id).includes(id);
        },
        handleSelection(value) {
            this.$emit('input', value);
        },
        handleCheck(event, item) {
            if (event) {
                this.$emit('input', [...this.value, item]);
            } else {
                this.$emit(
                    'input',
                    this.value.filter((val) => val.id !== item.id)
                );
            }
        },
        goUp() {
            if (this.hasParent) {
                if (this.isSelected) {
                    const selected = this.getParent(this.selectedItem[0]);
                    if (selected) {
                        this.computedSelectedItem = [selected];
                    }
                }
            } else {
                this.computedSelectedItem = [];
            }
        },
        handleOpenTree(value) {
            this.openPath = value;
        },
        iconColor(item) {
            if (item && item.state && item.state.stateName) {
                switch (item.state.stateName) {
                    case 'APPROVED':
                        return 'success';
                    case 'PENDING':
                        return 'warning';
                    case 'DECLINED':
                        return 'error';
                }
                return 'info';
            }
            return '';
        },
        icon(item, open) {
            return item.name ? (open && item && item.children && item.children.length > 0 ? 'mdi-folder-open-outline' : 'mdi-folder-outline') : 'mdi-file';
        },
        pathToSearchItem(item) {
            let treeItem = this.getParent(item);
            let path = [];
            while (treeItem) {
                path.unshift(treeItem.name);
                treeItem = this.getParent(treeItem);
            }
            return path.join(' / ');
        }
    },
    mounted() {
        this.$nextTick(() => (this.isLoaded = true));
    },
    beforeMount() {
        this.SET_VIEW({ view: '', data: {}, type: '' });
        this.validateEditorState({
            change: ['CHANGE_KNOWLEDGE_CONTENT_STATE'],
            create: ['ADD_KNOWLEDGE_CONTENT'],
            update: ['UPDATE_KNOWLEDGE_CONTENT'],
            delete: ['DELETE_KNOWLEDGE_CONTENT'],
            copy: ['COPY_KNOWLEDGE_CONTENT'],
            move: ['MOVE_KNOWLEDGE_CONTENT']
        }).then((result) => (this.permissions = result));
    }
};
</script>

<style scoped>
.truncate-tree-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-treeview {
    height: 100%;
    width: max-content;
}
.truncate-path {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 7vw;
}
.v-input--selection-controls {
    margin-top: 0;
}
</style>
