<template>
    <v-container>
        <v-row align="center">
            <v-col>
                <v-form v-model="valid" ref="form">
                    <v-text-field v-model="link" :label="$t('Link')" :rules="[notEmpty, validLink]" @keydown.enter.prevent="save" />
                </v-form>
            </v-col>
            <v-col cols="auto">
                <v-btn icon @click="save" :disabled="link === '' && !valid">
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: 'link-view',
    data: () => ({
        link: '',
        valid: false
    }),
    props: {
        selectedMaterial: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        selectedMaterial(value) {
            if (value.hasOwnProperty('link')) {
                this.link = value.link;
                this.$refs.form.validate();
            }
        }
    },
    methods: {
        notEmpty(v) {
            return !!v || this.$t('Link darf nicht leer sein');
        },
        validLink(v) {
            const regex = /[-a-zA-Z0-9@:%_.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_.~#?&//=]*)?/gi;
            return regex.test(v) || this.$t('Kein valider Link');
        },
        save() {
            if (this.valid) {
                this.$emit('selectedMaterial', { link: this.link });
            }
        }
    },
    activated() {
        if (this.selectedMaterial.hasOwnProperty('link')) {
            this.link = this.selectedMaterial.link;
        }
    }
};
</script>
