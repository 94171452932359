<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on }">
            <v-btn icon :small="$vuetify.breakpoint.xs" @click="handleClick" v-on="on" :disabled="disabled">
                <v-icon :small="$vuetify.breakpoint.xs" :color="checked ? 'accent' : ''">{{ setIcon }}</v-icon>
            </v-btn>
        </template>
        <span>{{ typeTooltip }}</span>
    </v-tooltip>
</template>
<script>
export default {
    name: 'permission-type-selector',
    props: {
        permissionType: {
            type: String,
            validator: (type) => ['CREATE', 'UPDATE', 'DELETE', 'GET', 'MOVE', 'COPY'].includes(type)
        },
        checked: {
            type: Boolean,
            default: false
        },
        value: {
            type: null,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleClick() {
            this.$emit('input', this.value);
        }
    },
    computed: {
        typeTooltip() {
            switch (this.permissionType) {
                case 'CREATE':
                    return this.$t('Diese Berechtigung ermöglicht es, Objekte zu erschaffen');
                case 'UPDATE':
                    return this.$t('Diese Berechtigung ermöglicht es, Eigenschaften von bereits existierenden Objekten zu ändern');
                case 'DELETE':
                    return this.$t('Diese Berechtigung ermöglicht es, Objekte zu löschen');
                case 'GET':
                    return this.$t('Diese Berechtigung ermöglicht es, Objekte abzufragen');
                case 'MOVE':
                    return this.$t('Diese Berechtigung ermöglicht es, Wissenseinheiten in andere Kataloge zu verschieben');
                case 'COPY':
                    return this.$t('Diese Berechtigung ermöglicht es, Kopien oder Referenzen zu erstellen');
            }
            return '';
        },
        setIcon() {
            if (this.checked) {
                switch (this.permissionType) {
                    case 'CREATE':
                        return 'mdi-plus';
                    case 'UPDATE':
                        return 'mdi-pencil';
                    case 'DELETE':
                        return 'mdi-delete';
                    case 'COPY':
                        return 'mdi-file-multiple-outline';
                    case 'MOVE':
                        return 'mdi-file-move-outline';
                    case 'GET':
                        return 'mdi-eye';
                }
            }
            return 'mdi-close';
        }
    }
};
</script>
