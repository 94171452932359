<template>
    <v-card flat>
        <v-container>
            <draggable v-show="options.length > 0" :list="options" group="buckets" @change="handleChange({ orderNumber: -1 }, $event)">
                <transition-group name="slide-x-transition" tag="v-row" class="row" mode="out-in">
                    <v-col :cols="6" v-for="option in options" :key="`option-${option.id}`">
                        <OptionNode :value="option" @media="mediaEmit" :disableInteraction="disableInteraction" move />
                    </v-col>
                </transition-group>
            </draggable>
            <v-row v-show="options.length === 0" align="center" justify="center">
                <v-col cols="auto">
                    <span>{{ $t('Alle Optionen zugewiesen') }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="6" v-for="bucket in buckets" :key="bucket.id">
                    <v-card>
                        <v-card-text class="secondary white--text">
                            <v-row>
                                <v-col>
                                    <span class="text-caption">{{ bucket.text }}</span>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn icon @click="mediaEmit(bucket.media)" :disabled="bucket.media.fileURL === ''" dark>
                                        <v-icon>mdi-image</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-text>
                            <draggable :list="bucketList(bucket)" group="buckets" @change="handleChange(bucket, $event)" :empty-insert-threshold="50">
                                <transition-group name="slide-x-transition" tag="v-row" class="row" mode="out-in">
                                    <v-col :cols="12" v-for="option in bucketList(bucket)" :key="`option-${option.id}`">
                                        <OptionNode :value="option" @media="mediaEmit" :disableInteraction="disableInteraction" move />
                                    </v-col>
                                </transition-group>
                            </draggable>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import draggable from 'vuedraggable';
import OptionNode from '../Helpers/OptionNode';

export default {
    name: 'bucket-sort-option',
    components: { draggable, OptionNode },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        disableInteraction: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        options(value) {
            this.$emit('valid', value.length === 0);
        }
    },
    computed: {
        options: {
            get() {
                return this.value.knowledgeUnitOptions.filter((option) => option.answeredOrder === -1);
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        buckets() {
            return this.value.knowledgeUnitBuckets;
        }
    },
    methods: {
        bucketList({ orderNumber }) {
            return this.value.knowledgeUnitOptions.filter((option) => parseInt(option.answeredOrder) == orderNumber);
        },
        mediaEmit(value) {
            this.$emit('media', value);
        },
        handleChange({ orderNumber }, element) {
            if (element && element.added) {
                this.$emit(
                    'input',
                    this.value.knowledgeUnitOptions.map((option) => {
                        if (option.id === element.added.element.id) {
                            return { ...option, answeredOrder: orderNumber };
                        }
                        return option;
                    })
                );
            }
        }
    }
};
</script>
