<template>
    <v-container>
        <div class="mb-3">
            <CourseBreadcrumbs />
            <Course
                :value="selectedCourse"
                @gradingTable="openGradingTable"
                :isGradingTable="isAdmin"
                :isCourseManagerValue="isCourseManagerValue"
                @selectedKnowledgeContent="handleSelectedKnowledgeContent"
                :selectedKnowledgeContent="selectedKnowledgeContent"
            />
        </div>
        <v-card>
            <v-toolbar dark flat dense color="secondary">
                <v-toolbar-title>{{ $t('Themen') }}</v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                    <AddUpdateTopic type="ADD" />
                    <AddUpdateTopic type="UPDATE" />
                    <DeleteTopic />
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" :small="$vuetify.breakpoint.xs" @click="goToEvaluations" color="yellow">
                                <v-icon color="white">mdi-check-all</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('Bewertungen') }}</span>
                    </v-tooltip>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                <Draggable v-if="getCourseTopics.length > 0" v-model="computedCourseTopics" :move="onMove">
                    <transition-group name="slide-x-transition" tag="v-row" class="row">
                        <v-col cols="12" :sm="6" v-for="item in getCourseTopics" :key="item.id">
                            <Topic
                                :timeScaleInMinutes="selectedCourse.timeScaleInMinutes"
                                :courseId="selectedCourse.id"
                                :value="item"
                                @input="(value) => (selectedTopic = value)"
                                :selectable="true"
                                :isTopic="true"
                                :selected="selectedTopic"
                                @selected="handleCardClick"
                                :isAdmin="isAdmin"
                                @selectedKnowledgeContent="handleSelectedKnowledgeContent"
                                :selectedKnowledgeContent="selectedKnowledgeContent"
                            />
                        </v-col>
                    </transition-group>
                </Draggable>
                <v-layout column v-if="getCourseTopics.length === 0">
                    <v-flex align-self-center justify-self-center>
                        <span>{{ $t('Kein Thema verfügbar') }}</span>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
        <KnowledgeContentPreview toolbarTitle="Content" v-model="computedSelectedKnowledgeContent" />
    </v-container>
</template>

<script>
import Course from './Helpers/Course/Course';
import Topic from './Helpers/Topic/Topic';
import CourseBreadcrumbs from './Breadcrumbs/CourseBreadcrumbs';
import CourseMixin from './Mixins/CourseMixin';
import KnowledgeContentPreview from '../../KnowledgeContent/KnowledgeContentPreview';
import AddUpdateTopic from './AddUpdateTopic';
import DeleteTopic from './DeleteTopic';

import Draggable from 'vuedraggable';

export default {
    name: 'topics',
    mixins: [CourseMixin],
    components: {
        Course,
        Topic,
        CourseBreadcrumbs,
        Draggable,
        KnowledgeContentPreview,
        AddUpdateTopic,
        DeleteTopic
    },
    data: () => ({
        dragged: {
            order: 0,
            elementId: ''
        },
        selectedKnowledgeContent: []
    }),
    props: {
        isAdmin: {
            type: Boolean,
            value: false
        },
        isCourseManagerValue: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedCourseTopics: {
            get() {
                return this.getCourseTopics;
            },
            set() {
                this.REORDER_TOPICS({
                    courseId: this.selectedCourse.id,
                    id: this.dragged.elementId,
                    order: this.dragged.order
                });
            }
        },
        selectedTopic: {
            get() {
                return this.getSelectedTopic;
            },
            set(value) {
                this.SET_SELECTED_TOPIC(value);
            }
        },
        selectedCourse: {
            get() {
                if (this.isAdmin) {
                    return this.getSelectedAdminCourse;
                }
                return this.getSelectedAttendeeCourse;
            },
            set(value) {
                if (this.isAdmin) {
                    this.SET_SELECTED_ADMIN_COURSE(value);
                } else {
                    this.SET_SELECTED_ATTENDEE_COURSE(value);
                }
            }
        },
        computedSelectedKnowledgeContent: {
            get() {
                if (this.selectedKnowledgeContent.length > 0) {
                    return this.selectedKnowledgeContent[0];
                }
                return {};
            },
            set() {
                this.selectedKnowledgeContent = [];
            }
        }
    },
    methods: {
        goToEvaluations() {
            this.$router.push({ name: 'evaluations' });
        },
        handleSelectedKnowledgeContent(value) {
            if (value.length > 0) {
                this.selectedKnowledgeContent = [value[value.length - 1]];
            } else {
                this.selectedKnowledgeContent = [];
            }
        },
        onMove(event) {
            this.dragged.order = event.relatedContext.index;
            if (event.relatedContext.index > event.draggedContext.index) {
                this.dragged.order++;
            }
            this.dragged.elementId = event.draggedContext.element.id;
        },
        openGradingTable(value) {
            this.$emit('gradingTable', value);
        },
        handleCardClick(value) {
            if (this.selectedTopic.id === value.id) {
                this.selectedTopic = value;
                if (this.isAdmin) {
                    this.$router.push({ name: 'learningGoals' });
                } else {
                    this.$router.push({ name: 'myLearningGoals' });
                }
            } else {
                this.selectedTopic = value;
            }
        }
    },
    created() {
        if (this.isAdmin) {
            this.GET_COURSE_TOPICS(this.getSelectedAdminCourse);
        } else {
            this.GET_COURSE_TOPICS(this.getSelectedAttendeeCourse);
        }
        this.resetTopicSelection();
    }
};
</script>
