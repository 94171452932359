import Wiki from '../../views/Wiki/Wiki.vue';
import WikiAdd from '../../Components/Wiki/AddUpdateWiki';
import WikiUpdate from '../../Components/Wiki/AddUpdateWiki';
import WikiDelete from '../../views/Wiki/WikiDelete';

import { superAdmin, admin, user } from '../Roles';

export default [
    /**
     * Wiki
     * @description
     */
    {
        path: '/Wiki',
        name: 'wiki',
        component: Wiki,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin, admin, user],
            navigation: {
                type: { name: 'Groups', groupId: 'Community' },
                priority: 0,
                icon: 'mdi-wikipedia',
                name: 'Wiki',
                badge: 'ChatOperations/getWikiProposalCount',
                navigationKey: 'WIKI'
            }
        }
    },
    {
        path: '/Wiki/Add',
        name: 'wikiAdd',
        component: WikiAdd,
        meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionSet: ['ADD_WIKI'],
            requiresRole: true,
            roleSet: [superAdmin, admin]
        }
    },
    {
        path: '/Wiki/Update',
        name: 'wikiUpdate',
        component: WikiUpdate,
        meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionSet: ['UPDATE_WIKI'],
            requiresRole: true,
            roleSet: [superAdmin, admin]
        }
    },
    {
        path: '/Wiki/Delete',
        name: 'wikiDelete',
        component: WikiDelete,
        meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionSet: ['DELETE_WIKI'],
            requiresRole: true,
            roleSet: [superAdmin, admin]
        }
    }
];
