<template>
    <v-container grid-list-md pa-0>
        <v-layout align-start wrap v-show="tags.length > 0">
            <v-flex shrink v-for="(tag, index) in tags" :key="`knowledge-content-tag-${index}`">
                <v-chip @click.prevent @mousedown.prevent :color="color" dark small>{{ tag }}</v-chip>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
export default {
    name: 'knowledge-content-tags',
    computed: {
        tags() {
            return Array.isArray(this.items) ? this.items.filter((tag) => tag.trim() !== '') : [];
        }
    },
    props: {
        items: {
            type: Array,
            default: () => []
        },
        color: {
            type: String,
            default: 'secondary'
        }
    }
};
</script>
