import { admin, superAdmin } from '../../Roles';
import CourseTemplates from '../../../views/CourseLearning/CourseTool/CourseTemplates';

export default [
    // {
    //   path: "/CourseTemplates",
    //   name: "courseTemplates",
    //   component: CourseTemplates,
    //   meta: {
    //     requiresAuth: true,
    //     requiresPermission: false,
    //     permissionSet: [],
    //     requiresRole: true,
    //     roleSet: [admin, superAdmin],
    //     navigation: {
    //       type: { name: "General", groupId: "" },
    //       priority: 0,
    //       icon: "psychology",
    //       name: "KursTool"
    //     }
    //   }
    // }
];
