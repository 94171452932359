<template>
    <v-card-text>
        <v-container>
            <v-row align="center" justify="center">
                <v-btn-toggle v-model="selectedOption">
                    <v-col :cols="6" v-for="option in computedOptions" :key="`option-${option.id}`">
                        <v-btn block medium :value="option.id" :class="selectionColor(option)" :text="selectedOption !== option.id">
                            {{ $t(option.text) }}
                        </v-btn>
                    </v-col>
                </v-btn-toggle>
            </v-row>
        </v-container>
    </v-card-text>
</template>

<script>
export default {
    name: 'true-false-option',
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        valid: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        selectedOption(value) {
            this.$emit('valid', !!value || value === 0);
        }
    },
    computed: {
        responsiveFont() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return { 'font-weight-light': true };
                case 'sm':
                    return { 'font-weight-light': true, 'body-2': true };
                case 'md':
                    return { 'font-weight-light': true, subheading: true };
                case 'lg':
                    return { 'font-weight-light': true, title: true };
                case 'xl':
                    return { 'font-weight-light': true, headline: true };
            }
            return '';
        },
        selectedOption: {
            get() {
                if (this.value && this.value.knowledgeUnitOptions) {
                    const find = this.value.knowledgeUnitOptions.find((option) => option.selected);
                    if (find) {
                        return find.id;
                    }
                }
                return undefined;
            },
            set(value) {
                this.$emit(
                    'input',
                    this.computedOptions.map((option) => {
                        if (option.id === value) {
                            return { ...option, selected: true };
                        }
                        return { ...option, selected: false };
                    })
                );
            }
        },
        computedOptions() {
            return this.value && this.value.knowledgeUnitOptions ? this.value.knowledgeUnitOptions : [];
        }
    },
    methods: {
        selectionColor({ id, text }) {
            return id === this.selectedOption
                ? {
                      ...this.responsiveFont,
                      success: text === 'True',
                      error: text === 'False',
                      'white--text': true,
                      'px-3': true
                  }
                : { ...this.responsiveFont, 'px-3': true };
        }
    },
    activated() {
        this.$emit('valid', !!this.selectedOption);
    },
    deactivated() {
        this.$emit('valid', true);
    }
};
</script>
