<template>
    <v-dialog v-model="dialog" :width="$vuetify.breakpoint.thresholds.sm" persistent :fullscreen="$vuetify.breakpoint.xs">
        <v-card>
            <v-toolbar dense text dark color="secondary">
                <v-toolbar-title>{{ $t('Sichtbarkeit-Details') }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-tabs v-model="tabs" mandatory slider-color="accent">
                <v-tab key="AdminGroups">
                    <span>{{ $t('Admingruppen') }}</span>
                </v-tab>
                <v-tab key="UserGroups">
                    <span>{{ $t('Benutzergruppen') }}</span>
                </v-tab>
                <v-tab key="Users">
                    <span>{{ $t('Nutzer') }}</span>
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabs" touchless>
                <v-tab-item key="AdminGroups">
                    <v-card text>
                        <v-card-text>
                            <PaginatedList
                                :showAppend="false"
                                :showAction="false"
                                :showOptions="true"
                                :sortArray="adminGroupSort"
                                title="Admingruppen"
                                :items="adminGroups"
                            />
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item key="UserGroups">
                    <v-card text>
                        <v-card-text>
                            <PaginatedList
                                :showAppend="false"
                                :showAction="false"
                                :showOptions="true"
                                :sortArray="userGroupSort"
                                title="Benutzergruppen"
                                :items="userGroups"
                            />
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item key="Users">
                    <v-card text>
                        <v-card-text>
                            <PaginatedList
                                :showAppend="false"
                                :showAction="false"
                                :showOptions="true"
                                :sortArray="userSort"
                                title="Nutzer"
                                :itemKeys="['displayName', 'email', 'role']"
                                :items="users"
                            />
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-dialog>
</template>

<script>
import PaginatedList from '../Generic/List/AdvancedList';

import GroupSort from '../Groups/Sort/GroupSort';
import UserSort from '../Users/UserSort/UserSortList';

export default {
    name: 'custom-visibility-details',
    components: { PaginatedList },
    data: () => ({
        tabs: 0,
        adminGroupSort: GroupSort,
        userGroupSort: GroupSort,
        userSort: UserSort
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        showDialog: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        adminGroups: {
            get() {
                if (this.value.hasOwnProperty('adminGroups')) {
                    return this.value.adminGroups;
                }
                return [];
            }
        },
        userGroups: {
            get() {
                if (this.value.hasOwnProperty('userGroups')) {
                    return this.value.userGroups;
                }
                return [];
            }
        },
        users: {
            get() {
                if (this.value.hasOwnProperty('users')) {
                    return this.value.users;
                }
                return [];
            }
        },
        dialog: {
            get() {
                return this.showDialog;
            },
            set(value) {
                this.$emit('showDialog', value);
            }
        }
    },
    methods: {
        close() {
            this.dialog = false;
        }
    }
};
</script>
