<template>
    <component
        :is="typeSwitch"
        :dateText="startDateText"
        :startDateText="startDateText"
        :endDateText="endDateText"
        :label="$t(label1)"
        :labelStart="$t(label1)"
        :labelEnd="$t(label2)"
        :disableStartDate="disableStartDate"
        :language="localeComputed"
        @startDateDataEmit="pickerStartDateEmit"
        @endDateDataEmit="pickerEndDateEmit"
        @dateDataEmit="pickerStartDateEmit"
        :min="min"
    />
</template>

<script>
import PickerDateDay from './PickerDateType/PickerDateTypeDay';
import PickerDateWeek from './PickerDateType/PickerDateTypeWeek';
import PickerDateRange from './PickerDateType/PickerDateTypeRange';
import PickerDateMonth from './PickerDateType/PickerDateTypeMonth';
import PickerDateYear from './PickerDateType/PickerDateTypeYear';
import { mapGetters } from 'vuex';

export default {
    components: {
        PickerDateDay,
        PickerDateWeek,
        PickerDateRange,
        PickerDateMonth,
        PickerDateYear
    },
    props: {
        type: {
            default: 'Day',
            type: String
        },
        disableStartDate: {
            default: false,
            type: Boolean
        },
        startDateText: {
            default: '',
            type: String
        },
        endDateText: {
            default: '',
            type: String
        },
        label1: {
            default: 'Anfangsdatum',
            type: String
        },
        label2: {
            default: 'Enddatum',
            type: String
        },
        min: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapGetters('LanguageOperations', ['getSelectedLanguage']),
        localeComputed() {
            return this.getSelectedLanguage;
        },
        typeSwitch() {
            switch (this.type) {
                case 'Day':
                    return PickerDateDay;
                case 'Week':
                    return PickerDateWeek;
                case 'Month':
                    return PickerDateMonth;
                case 'Year':
                    return PickerDateYear;
                case 'Range':
                    return PickerDateRange;
            }
            return PickerDateDay;
        }
    },
    methods: {
        pickerStartDateEmit(val) {
            this.$emit('pickerStartDateEmit', val);
        },
        pickerEndDateEmit(val) {
            this.$emit('pickerEndDateEmit', val);
        }
    }
};
</script>
