<template>
    <div class="fill-height">
        <Toolbar
            :toolbarTitle="$t('Archiv Erstellen')"
            :showDrawer="false"
            :lastPath="-1"
            :tooltipMessage="$t('Hier können Sie Archiv hinzufügen. Auch können Sie hier Ihre Aufgaben archivieren.')"
        />
        <ArchiveAdd type="ADD" />
    </div>
</template>

<script>
import ArchiveAdd from '../../Components/Archive/AddUpdateArchive';
import Toolbar from '../../Components/Generic/Toolbar';
import ArchiveMixin from '../../Components/Archive/Mixins/ArchiveMixin';

export default {
    name: 'archive-add',
    components: {
        ArchiveAdd,
        Toolbar
    },
    mixins: [ArchiveMixin]
};
</script>
