<template>
    <v-dialog v-model="computedDialog" persistent :width="$vuetify.breakpoint.thresholds.sm" :fullscreen="$vuetify.breakpoint.xs">
        <v-card>
            <v-toolbar dense text color="secondary" dark>
                <v-toolbar-title>{{ $t('WWC Rolle bearbeiten') }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="close()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col :cols="12">
                            <v-select :label="$t('Wählen Sie die gewünschte Rolle aus')" v-model="role" :items="options" :hint="''" persistent-hint>
                                <template v-slot:item="{ item }">
                                    {{ $t(item) }}
                                </template>
                                <template v-slot:selection="{ item }">
                                    {{ $t(item) }}
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn block :color="'warning'" :disabled="!isValidated" @click="save()">
                    {{ $t('Bearbeiten') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import CustomerManagerMixin from '../Mixins/CustomerManagerMixin';

export default {
    name: 'edit-wwc-role',
    components: {},
    mixins: [CustomerManagerMixin],
    data: () => ({
        options: ['USER', 'FREE_ADMIN', 'LICENSED_ADMIN'],
        role: ''
    }),
    props: {
        id: {
            type: String,
            default: ''
        },
        value: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedDialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        isValidated() {
            return this.role !== '';
        }
    },
    methods: {
        close() {
            this.computedDialog = false;
            this.role = '';
        },
        save() {
            return this.UPDATE_WWWC_ROLE(this.serialize()).then(() => this.close());
        },
        serialize() {
            return {
                id: this.id,
                wwc: {
                    role: this.role
                }
            };
        }
    }
};
</script>
