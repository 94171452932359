import axios from '../../../plugins/Axios/axios';
import Vue from 'vue';

const state = {
    catalogList: [],
    validCatalogList: [],
    selectedCatalog: {
        itemType: 'CATALOG',
        itemTitle: 'Katalog',
        path: '/Authoringtool_Catalog',
        data: {}
    }
};

const getters = {
    getValidCatalogList(state) {
        return state.validCatalogList;
    },
    getCatalogList(state) {
        return state.catalogList;
    },
    getSelectedCatalog(state) {
        return state.selectedCatalog;
    }
};

const actions = {
    GET_SINGLE_CATALOG({ commit }, request) {
        return axios
            .get(`cataloggroups/${request.catalogGroupId}/catalogsubgroups/${request.catalogSubgroupId}/catalogs/${request.catalogId}`)
            .then((response) => response.data.data.catalogs[0]);
    },
    GET_CATALOG_LIST({ commit }, request) {
        return axios
            .get(`cataloggroups/${request.catalogGroupId}/catalogsubgroups/${request.catalogSubgroupId}/catalogs`)
            .then((response) => response.data.data.catalogs)
            .then((response) => {
                commit('updateCatalogList', [...response]);
                return response;
            });
    },
    GET_VALID_CATALOG_LIST({ commit }, request) {
        return axios.get(`cataloggroups/${request.catalogGroupId}/catalogsubgroups/${request.catalogSubgroupId}/catalogs?completed=true`).then((response) => {
            commit('updateValidCatalogList', response.data.data.catalogs);
            return response.data.data.catalogs;
        });
    },
    GET_ARCHIVE_CATALOG_LIST({ commit }) {
        return axios.get(`archivecatalogs`).then((response) => response.data.data.catalogs);
    },
    ADD_CATALOG({ commit, rootGetters }, request) {
        const { id: catalogGroupId } = rootGetters['CatalogGroupOperations/getSelectedCatalogGroup'].data;
        const { id: catalogSubgroupId } = rootGetters['CatalogSubgroupOperations/getSelectedCatalogSubGroup'].data;
        return axios
            .post(`cataloggroups/${catalogGroupId}/catalogsubgroups/${catalogSubgroupId}/catalogs`, request)
            .then((response) => response.data.data)
            .then((response) => {
                const catalogs = rootGetters['AuthoringToolOperations/getMapData'](catalogSubgroupId);
                commit('AuthoringToolOperations/updateMap', { [catalogSubgroupId]: [...catalogs, response] }, { root: true });
                commit('updateCatalogList', { ...response });
                // commit('updateSelectedCatalog', response);
                return response;
            });
    },
    UPDATE_CATALOG({ commit, rootGetters }, { id: catalogId, ...catalog }) {
        const { id: catalogGroupId } = rootGetters['CatalogGroupOperations/getSelectedCatalogGroup'].data;
        const { id: catalogSubgroupId } = rootGetters['CatalogSubgroupOperations/getSelectedCatalogSubGroup'].data;
        return axios
            .patch(`cataloggroups/${catalogGroupId}/catalogsubgroups/${catalogSubgroupId}/catalogs/${catalogId}`, catalog)
            .then((response) => response.data.data)
            .then((response) => {
                const catalogs = rootGetters['AuthoringToolOperations/getMapData'](catalogSubgroupId).map((catalogSubgroup) => {
                    if (catalogSubgroup.id === response.id) {
                        return response;
                    }
                    return catalogSubgroup;
                });
                commit('AuthoringToolOperations/updateMap', { [catalogSubgroupId]: catalogs }, { root: true });
                commit('updateCatalogList', { ...response });
                // commit('updateSelectedCatalog', response);
                return response;
            });
    },
    DELETE_CATALOG({ commit, rootGetters }, { id: catalogId }) {
        const { id: catalogGroupId } = rootGetters['CatalogGroupOperations/getSelectedCatalogGroup'].data;
        const { id: catalogSubgroupId } = rootGetters['CatalogSubgroupOperations/getSelectedCatalogSubGroup'].data;
        return axios.delete(`cataloggroups/${catalogGroupId}/catalogsubgroups/${catalogSubgroupId}/catalogs/${catalogId}`).then((response) => {
            const catalogs = rootGetters['AuthoringToolOperations/getMapData'](catalogSubgroupId).filter((catalogGroup) => catalogGroup.id !== catalogId);
            commit('AuthoringToolOperations/updateMap', { [catalogSubgroupId]: catalogs }, { root: true });
            // commit('updateSelectedCatalog', {});
            commit('deleteCatalog', catalogId);
            return response;
        });
    },
    COPY_CATALOG(
        { commit, rootGetters, getters },
        { catalogGroup: { id: destinationCatalogGroupId }, catalogSubgroup: { id: destinationCatalogSubgroupId }, name, description }
    ) {
        const { id: catalogGroupId } = rootGetters['CatalogGroupOperations/getSelectedCatalogGroup'].data;
        const { id: catalogSubgroupId } = rootGetters['CatalogSubgroupOperations/getSelectedCatalogSubGroup'].data;
        const { id: catalogId } = getters.getSelectedCatalog.data;
        return axios
            .patch(`/cataloggroups/${catalogGroupId}/catalogsubgroups/${catalogSubgroupId}/catalogs/${catalogId}/copy`, {
                destinationCatalogGroupId: destinationCatalogGroupId,
                destinationCatalogSubGroupId: destinationCatalogSubgroupId,
                name,
                description
            })
            .then((response) => response.data.data)
            .then((response) => {
                const catalogs = rootGetters['AuthoringToolOperations/getMapData'](destinationCatalogSubgroupId);
                commit('AuthoringToolOperations/updateMap', { [destinationCatalogSubgroupId]: [...catalogs, response] }, { root: true });
                return response;
            });
    },
    MOVE_CATALOG(
        { commit, rootGetters, getters },
        { catalogGroup: { id: destinationCatalogGroupId }, catalogSubgroup: { id: destinationCatalogSubgroupId }, name, description }
    ) {
        const { id: catalogGroupId } = rootGetters['CatalogGroupOperations/getSelectedCatalogGroup'].data;
        const { id: catalogSubgroupId } = rootGetters['CatalogSubgroupOperations/getSelectedCatalogSubGroup'].data;
        const { id: catalogId } = getters.getSelectedCatalog.data;
        return axios
            .patch(`/cataloggroups/${catalogGroupId}/catalogsubgroups/${catalogSubgroupId}/catalogs/${catalogId}/move`, {
                destinationCatalogGroupId: destinationCatalogGroupId,
                destinationCatalogSubGroupId: destinationCatalogSubgroupId,
                name,
                description
            })
            .then((response) => response.data.data)
            .then((response) => {
                const sourceCatalogs = rootGetters['AuthoringToolOperations/getMapData'](catalogSubgroupId).filter((catalog) => catalog.id !== catalogId);
                commit('AuthoringToolOperations/updateMap', { [catalogSubgroupId]: sourceCatalogs }, { root: true });
                const targetCatalogs = rootGetters['AuthoringToolOperations/getMapData'](destinationCatalogSubgroupId);
                commit(
                    'AuthoringToolOperations/updateMap',
                    {
                        [destinationCatalogSubgroupId]: [...targetCatalogs, response]
                    },
                    { root: true }
                );

                return response;
            });
    },
    SELECT_CATALOG({ commit }, selectedCatalog) {
        commit('updateSelectedCatalog', selectedCatalog);
    }
};

const mutations = {
    clear: (state) => {
        state.catalogList = [];
        state.validCatalogList = [];
        state.selectedCatalog = {
            itemType: 'CATALOG',
            itemTitle: 'Katalog',
            path: '/Authoringtool_Catalog',
            data: {}
        };
    },
    updateCatalogList: (state, catalogList) => {
        if (Array.isArray(catalogList)) {
            state.catalogList = catalogList;
        } else {
            if (state.catalogList.map(({ id }) => id).includes(catalogList.id)) {
                Vue.set(
                    state,
                    'catalogList',
                    state.catalogList.map((group) => {
                        if (group.id === catalogList.id) {
                            return catalogList;
                        }
                        return group;
                    })
                );
            } else {
                Vue.set(state, catalogList, [...state.catalogList, catalogList]);
            }
        }
    },
    updateValidCatalogList: (state, catalogList) => {
        state.validCatalogList = catalogList;
    },
    updateSelectedCatalog: (state, selectedCatalog) => {
        state.selectedCatalog.data = selectedCatalog;
    },
    deleteCatalog: (state, catalogId) => {
        state.catalogList = state.catalogList.filter((catalog) => catalog.id !== catalogId);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
