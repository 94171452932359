<template>
    <v-card>
        <v-card-text>
            <v-form v-model="isValidated">
                <v-text-field
                    color="accent"
                    :label="$t('Überschrift')"
                    :hint="$t('Geben Sie dem Forumseintrag eine Überschrift')"
                    persistent-hint
                    :rules="[notEmpty()]"
                    v-model="forum.title"
                />
                <v-text-field
                    color="accent"
                    :label="$t('Beschreibung')"
                    :hint="$t('Geben Sie dem Forumseintrag eine Eintragsbeschreibung')"
                    persistent-hint
                    v-model="forum.description"
                />
                <v-select
                    color="accent"
                    :label="$t('Priorität')"
                    :items="priorities"
                    v-model="selectedPriority"
                    :hint="selectedPriority.hint"
                    persistent-hint
                    item-text="display"
                    return-object
                >
                    <template v-slot:item="{ item }">
                        <div>{{ $t(item.display) }}</div>
                    </template>
                    <template v-slot:selection="{ item }">
                        <div>{{ $t(item.display) }}</div>
                    </template>
                </v-select>
                <v-switch
                    color="accent"
                    :label="$t('Nur Admins dürfen posten')"
                    :hint="$t('Nur Forum-Admins dürfen Themen hinzufügen, bearbeiten und löschen')"
                    persistent-hint
                    v-model="forum.onlyAdminsCanPost"
                />
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn block :color="saveButtonColor" :disabled="!isValidated" @click="save">
                {{ saveButtonText }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import ForumMixin from './Mixins/ForumMixin';
import Priorities from './Priority/Priorities';

export default {
    name: 'add-update-forum',
    mixins: [ForumMixin],
    data: () => ({
        isValidated: false,
        forum: {
            title: '',
            description: '',
            onlyAdminsCanPost: false
        },
        priorities: [],
        selectedPriority: {}
    }),
    props: {
        type: {
            type: String,
            validator: (value) => ['ADD', 'UPDATE'].indexOf(value) !== -1
        }
    },
    computed: {
        saveButtonText() {
            switch (this.type) {
                case 'ADD':
                    return this.$t('Hinzufügen');
                case 'UPDATE':
                    return this.$t('Speichern');
            }
            return '';
        },
        saveButtonColor() {
            switch (this.type) {
                case 'ADD':
                    return 'success';
                case 'UPDATE':
                    return 'warning';
            }
            return '';
        }
    },
    methods: {
        serialize() {
            return { ...this.forum, order: this.selectedPriority.order };
        },
        notEmpty() {
            return (value) => !!value || this.$t('Dieses Feld darf nicht leer sein');
        },
        close() {
            this.$emit('input', false);
            this.forum = {
                title: '',
                description: '',
                onlyAdminsCanPost: false
            };
        },
        save() {
            switch (this.type) {
                case 'ADD':
                    return this.CREATE_FORUM(this.serialize()).then(() => this.close());
                case 'UPDATE':
                    return this.UPDATE_FORUM(this.serialize()).then(() => this.close());
            }
        }
    },
    activated() {
        this.priorities = Priorities('Foren').map((priority) => {
            priority.hint = this.$t(priority.hint);
            priority.display = this.$t(priority.display);
            return priority;
        });
        this.selectedPriority = this.priorities[2];
        if (this.type === 'UPDATE') {
            this.forum = JSON.parse(JSON.stringify(this.getSelectedForum));
        } else {
            this.forum = {
                title: '',
                description: '',
                onlyAdminsCanPost: false
            };
        }
    }
};
</script>
