<template>
    <Quiz v-if="loaded" :isPreview="true" :onStart="handleOnStart" :onSubmit="handleOnSubmit" :onResult="handleOnResult" />
</template>

<script>
import Quiz from '../../../Components/Quiz/QuizApp';
import QuizMixin from '../../../Components/UserApp/Mixins/QuizMixin';

export default {
    name: 'knowledge-unit-preview-view',
    data: () => ({
        loaded: false
    }),
    components: { Quiz },
    mixins: [QuizMixin],
    props: {
        knowledgeUnit: {
            type: Object,
            default: () => ({})
        },
        value: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        '$vuetify.breakpoint.name': {
            handler(value) {
                if (!this.getDrawerState) {
                    if (value !== 'xs' && value !== 'sm' && value !== 'md') {
                        this.OPEN_DRAWER(true);
                    }
                }
            },
            immediate: true
        }
    },
    methods: {
        handleOnStart() {
            return this.KNOWLEDGE_UNIT_PREVIEW(this.knowledgeUnit);
        },
        handleOnSubmit(vote) {
            return this.KNOWLEDGE_UNIT_PREVIEW_SUBMIT({
                knowledgeUnit: this.knowledgeUnit,
                vote: vote
            });
        },
        handleOnResult() {
            return new Promise();
        }
    },
    activated() {
        this.$nextTick(() => (this.loaded = true));
    },
    deactivated() {
        this.$nextTick(() => (this.loaded = false));
    }
};
</script>
