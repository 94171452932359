<template>
    <v-stepper v-model="picker" non-linear alt-labels :elevation="0">
        <v-alert :value="true" dismissible class="ma-0" type="info">{{
            $t("Damit diese Einstellungen nutzbar sind, sollten Sie 'Autorentool anzeigen' in Algemeine Berechtigungen einem Admin zuweisen")
        }}</v-alert>
        <v-stepper-header class="elevation-0">
            <v-stepper-step step="1" editable>{{ $t('Kataloggruppen') }}</v-stepper-step>
            <v-divider />
            <v-stepper-step step="2">{{ $t('Kataloguntergruppen') }}</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
            <v-stepper-content step="1">
                <profile-catalog-group @step="() => (picker = 2)" :value="permissions.catalogGroups" @input="handleCatalogGroups" :readOnly="readOnly" />
            </v-stepper-content>
            <v-stepper-content step="2">
                <profile-catalog-sub-group :value="permissions.catalogSubGroups" @input="handleCatalogSubGroups" :readOnly="readOnly" />
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</template>
<script>
import ProfileCatalogGroup from './ProfileCatalogGroup';
import ProfileCatalogSubGroup from './ProfileCatalogSubGroup';
import Mixin from '../Mixins/AuthoringPermissionsMixin';
export default {
    name: 'profile-authoring-selector',
    components: { ProfileCatalogGroup, ProfileCatalogSubGroup },
    mixins: [Mixin],
    data: () => ({
        picker: 1,
        permissions: {
            catalogGroups: [],
            catalogSubGroups: []
        }
    }),
    props: {
        value: {
            type: Object
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        value: {
            handler() {
                this.permissions = { ...this.value };
            }
        }
    },
    methods: {
        handleCatalogGroups(catalogGroups) {
            this.permissions.catalogGroups = catalogGroups;
            this.$emit('input', this.permissions);
        },
        handleCatalogSubGroups(catalogSubGroups) {
            catalogSubGroups.forEach((csg) => {
                if (this.permissions.catalogSubGroups.map((group) => group.id).includes(csg.id)) {
                    this.permissions.catalogSubGroups = this.permissions.catalogSubGroups.map((group) => {
                        if (group.id === csg.id) {
                            return csg;
                        }
                        return group;
                    });
                } else {
                    this.permissions.catalogSubGroups.push(csg);
                }
            });

            this.permissions.catalogSubGroups.filter((csg) => csg.permissions.length > 0);

            this.$emit('input', this.permissions);
        }
    },
    mounted() {
        this.getAuthoringPermissions(true);
        this.permissions = { ...this.value };
    }
};
</script>
