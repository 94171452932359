export function sortUserArray(array, { sort: sortEnum, order }) {
    const sort = getSorting(sortEnum);
    if (Array.isArray(array)) {
        if (order === 'DESC') {
            array.sort((a, b) =>
                b[sort].localeCompare(a[sort], undefined, {
                    numeric: true,
                    sensitivity: 'base'
                })
            );
        }
        if (order === 'ASC') {
            array.sort((a, b) =>
                a[sort].localeCompare(b[sort], undefined, {
                    numeric: true,
                    sensitivity: 'base'
                })
            );
        }
    } else {
        console.error("'array' parameter not of type Array");
    }
}

export function getSorting(sort) {
    switch (sort) {
        case 'FIRSTNAME':
            return 'firstName';
        case 'LASTNAME':
            return 'lastName';
        case 'DISPLAYNAME':
            return 'displayName';
        case 'PERSONALNUMBER':
            return 'personalNumber';
    }
    return 'displayName';
}
