var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"text":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-tabs',{attrs:{"slider-color":"accent","grow":""},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab',{key:"USER"},[_vm._v(_vm._s(_vm.$t('Lernende')))]),_c('v-tab',{key:"ADMIN"},[_vm._v(_vm._s(_vm.$t('Admin / Lehrende')))])],1)],1),_c('v-tabs-items',{staticClass:"fill-height",attrs:{"touchless":"","mandatory":""},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab-item',{key:"USER"},[_c('v-flex',{attrs:{"grow":""}},[_c('v-expand-transition',{attrs:{"mode":"out-in"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.reportHeaders,"items":_vm.users,"items-per-page":10,"header-props":_vm.headerProps,"footer-props":{
                            'items-per-page-text': `${_vm.$t('Lernende') + ' '}  ${_vm.$t('pro Seite')}`,
                            'items-per-page-options': [5, 10, 15, { text: _vm.$t('Alle'), value: -1 }]
                        }},scopedSlots:_vm._u([{key:"item.completion_status",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t(item.completion_status))+" ")]}},{key:"item.success_status",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t(item.success_status))+" ")]}},{key:"item.total_time",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t(item.total_time))+" ")]}},{key:"item.progress",fn:function({ item }){return [_c('v-progress-circular',{attrs:{"rotate":-90,"size":40,"width":5,"value":item.progress,"color":"primary"}},[_vm._v(" "+_vm._s(item.progress)+" ")])]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" "+_vm._s(' ' + _vm.$t('von') + ' ')+" "+_vm._s(props.itemsLength)+" ")]}}])})],1)],1)],1),_c('v-tab-item',{key:"ADMIN"},[_c('v-flex',{attrs:{"grow":""}},[_c('v-expand-transition',{attrs:{"mode":"out-in"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.reportHeaders,"items":_vm.admins,"items-per-page":10,"header-props":_vm.headerProps,"footer-props":{
                            'items-per-page-text': `${_vm.$t('Admin / Lehrende') + ' '}  ${_vm.$t('pro Seite')}`,
                            'items-per-page-options': [5, 10, 15, { text: _vm.$t('Alle'), value: -1 }]
                        }},scopedSlots:_vm._u([{key:"item.completion_status",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t(item.completion_status))+" ")]}},{key:"item.success_status",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t(item.success_status))+" ")]}},{key:"item.total_time",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t(item.total_time))+" ")]}},{key:"item.progress",fn:function({ item }){return [_c('v-progress-circular',{attrs:{"rotate":-90,"size":40,"width":5,"value":item.progress,"color":"primary"}},[_vm._v(" "+_vm._s(item.progress)+" ")])]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" "+_vm._s(' ' + _vm.$t('von') + ' ')+" "+_vm._s(props.itemsLength)+" ")]}}])})],1)],1)],1)],1),_c('hr'),_c('v-card-text',[_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"block":"","color":"primary"},on:{"click":_vm.closeReportModal}},[_vm._v(_vm._s(_vm.$t('Schließen')))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }