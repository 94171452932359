<template>
    <v-card text height="100%">
        <v-layout fill-height>
            <v-flex xs3>
                <v-layout column fill-height class="verticalLimit">
                    <v-flex>
                        <slot></slot>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex shrink>
                <v-divider vertical />
            </v-flex>
            <v-flex xs9>
                <v-layout column fill-height class="verticalLimit">
                    <keep-alive>
                        <component
                            v-model="computedSelectedItem"
                            :is="component"
                            :search="search"
                            @openTree="handleOpenTree"
                            :permissions="permissions"
                            @onCreate="handleOnCreate"
                            @onUpdate="handleOnUpdate"
                            @onDelete="handleOnDelete"
                        />
                    </keep-alive>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-card>
</template>

<script>
export default {
    name: 'knowledge-content-desktop-view',
    computed: {
        computedSelectedItem: {
            get() {
                return this.value.length > 0 ? this.value[0] : {};
            },
            set(value) {
                this.$emit('input', [value]);
            }
        }
    },
    props: {
        value: {
            type: Array,
            default: () => []
        },
        component: {
            type: [String, Object],
            default: '<div/>'
        },
        search: {
            type: String,
            default: ''
        },
        permissions: {
            type: Object,
            default: () => ({
                add: false,
                update: false,
                delete: false,
                change: false
            })
        }
    },
    methods: {
        handleOnCreate(data) {
            this.$emit('onCreate', data);
        },
        handleOnUpdate(data) {
            this.$emit('onUpdate', data);
        },
        handleOnDelete(data) {
            this.$emit('onDelete', data);
        },
        handleOpenTree(value) {
            this.$emit('openTree', value);
        }
    }
};
</script>
