<template>
    <v-breadcrumbs divider=">" :items="breadcrumbs">
        <template v-slot:item="{ item }">
            <span v-if="item.id === getSelectedWiki.id">{{ item.name }}</span>
            <span v-else class="primary--text pointer" @click="onClick(item)">{{ item.name }}</span>
        </template>
    </v-breadcrumbs>
</template>

<script>
import WikiMixin from '../Mixins/WikiMixin';

export default {
    name: 'wiki-breadcrumbs',
    mixins: [WikiMixin],
    computed: {
        home() {
            return { name: this.$t('Home') };
        },
        breadcrumbs() {
            if (Object.keys(this.getSelectedWiki).length === 0) {
                return [this.home];
            }
            return [this.home, ...this.findPath([], this.getSelectedWiki)];
        }
    },
    methods: {
        findPath(path, item) {
            if (item === undefined || item === null || item === -1) {
                return path;
            }
            return this.findPath(
                [item, ...path],
                this.getWikiList.find((wiki) => wiki.id === item.parentId)
            );
        },
        onClick(value) {
            if (value === this.home) {
                this.SET_SELECTED_WIKI({});
                this.SET_VIEW({ component: '', type: '', data: {} });
            }
            this.SET_SELECTED_WIKI(value);
            this.SET_VIEW({ component: 'GET_WIKI', type: '', data: value });
        }
    }
};
</script>
