import axios from '../../plugins/Axios/axios';
import { saveAs } from 'file-saver';
import Vue from 'vue';

const state = {
    materials: []
};

const getters = {
    getMaterials(state) {
        return state.materials;
    },
    getLinks(state) {
        return state.materials.filter(({ material }) => material);
    },
    getMedia(state) {
        return state.materials.filter(({ material }) => material);
    }
};

const actions = {
    GET_MATERIALS({ commit }, { catalog: { id: catalogId } }) {
        return axios
            .get(`/catalogs/${catalogId}/material`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateMaterials', response);
                return response;
            });
    },
    ADD_MATERIAL({ commit }, { catalog: { id: catalogId }, material }) {
        return axios.post(`/catalogs/${catalogId}/material`, material).then((response) => response.data.data);
    },
    UPDATE_MATERIAL({ commit }, { catalog: { id: catalogId }, material: { id: materialId, ...material } }) {
        return axios.patch(`/catalogs/${catalogId}/material/${materialId}`, material).then((response) => response.data.data);
    },
    DELETE_MATERIAL({ commit }, { catalog: { id: catalogId }, material: { id: materialId } }) {
        return axios.delete(`/catalogs/${catalogId}/material/${materialId}`).then((response) => response.data.data);
    },
    DOWNLOAD_MATERIAL({ commit }, { catalog: { id: catalogId }, material: { id: materialId, name: materialName } }) {
        return axios
            .get(`/catalogs/${catalogId}/material/${materialId}/download`, {
                responseType: 'blob'
            })
            .then((response) => response.data)
            .then((response) => {
                saveAs(response, `${materialName}_${materialId}_Material`);
            });
    },
    DOWNLOAD_ALL_MATERIALS({ commit }, { catalog: { id: catalogId } }) {
        return axios
            .get(`/catalogs/${catalogId}/material/download/all`, {
                responseType: 'blob'
            })
            .then((response) => response.data)
            .then((response) => {
                saveAs(response, `${catalogId}_Materialien`);
            });
    },
    DOWNLOAD_SELECTED_MATERIALS({ commit }, { catalog: { id: catalogId } }) {
        return axios.get(`/catalogs/${catalogId}/material/download/selected`).then((response) => response.data.data);
    }
};

const mutations = {
    clear: (state) => {
        state.materials = [];
    },
    updateMaterials: (state, materials) => {
        Vue.set(state, 'materials', materials);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
