import { ResizeObserver as RO } from '@juggle/resize-observer';

const state = {
    resizehandlers: [],
    resizeObserver:
        typeof window.ResizeObserver !== 'undefined'
            ? new ResizeObserver(() => {
                  state.resizehandlers.forEach((handler) => {
                      handler();
                  });
              })
            : new RO(() => {
                  state.resizehandlers.forEach((handler) => {
                      handler();
                  });
              })
};

const getters = {
    getObserver() {
        return state.resizeObserver;
    },
    getHandlers() {
        return state.resizehandlers;
    }
};

const actions = {
    REGISTER_ELEMENT({ commit }, { element, callback }) {
        commit('register', { element: element, callback: callback });
    },
    RESET_OBSERVERS({ commit }) {
        commit('reset');
    }
};

const mutations = {
    clear: (state) => {
        mutations.reset(state);
    },
    reset: (state) => {
        state.resizeObserver.disconnect();
        state.resizehandlers = [];
    },
    register: (state, { element, callback }) => {
        state.resizeObserver.observe(element);
        state.resizehandlers.push(callback);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
