<template>
    <v-select
        v-model="computedSelect"
        :items="gradingTableItems"
        :no-data-text="$t('Keine Bewertungstabellen verfügbar')"
        :label="$t('Bewertungstabellen')"
        :hint="computedSelect.description"
        persistent-hint
        return-object
        item-text="name"
    >
        <template v-slot:selection="{ item }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <div v-on="on">{{ item.name }}</div>
                </template>
                <v-layout column>
                    <v-flex v-for="[key, value] in convertToSortedMap(item)" :key="key">
                        <span>{{ `${Number(key * 100).toFixed(2)}% : ${value}` }}</span>
                    </v-flex>
                </v-layout>
            </v-tooltip>
        </template>
    </v-select>
</template>

<script>
import CourseMixin from '../../Mixins/CourseMixin';

export default {
    name: 'grading-table-selector',
    mixins: [CourseMixin],
    watch: {
        getSelectedAdminCourse(value) {
            if (Object.keys(value).length !== 0) {
                this.GET_COURSE_GRADING_TABLES(value).then(() => {
                    if (this.getCourseGradingTables.length > 0) {
                        this.computedSelect = this.getCourseGradingTables[0];
                    }
                });
            } else {
                this.SET_GRADING_TABLES([]);
            }
        },
        getSelectedAttendeeCourse(value) {
            if (Object.keys(value).length !== 0) {
                this.GET_COURSE_GRADING_TABLES(value).then(() => {
                    if (this.getCourseGradingTables.length > 0) {
                        this.computedSelect = this.getCourseGradingTables[0];
                    }
                });
            } else {
                this.SET_GRADING_TABLES([]);
            }
        }
    },
    computed: {
        gradingTableItem() {
            return {
                name: this.$t('Nur Punkte'),
                description: this.$t('Keine Bewertung, nur Punkte'),
                percentToGradeMap: {}
            };
        },
        gradingTableItems() {
            return [this.gradingTableItem, ...this.getCourseGradingTables];
        },
        computedCourse() {
            if (Object.keys(this.getSelectedAdminCourse).length > 0) {
                return this.getSelectedAdminCourse;
            }
            if (Object.keys(this.getSelectedAttendeeCourse).length > 0) {
                return this.getSelectedAttendeeCourse;
            }
            return {};
        },
        computedSelect: {
            get() {
                return this.getSelectedGradingTable;
            },
            set(value) {
                this.SET_SELECTED_GRADING_TABLE(value);
            }
        }
    },
    methods: {
        convertToSortedMap(item) {
            const { percentToGradeMap: gradeMap } = item;
            return Object.entries(gradeMap).sort(([key1], [key2]) => parseFloat(key1) - parseFloat(key2));
        }
    },
    mounted() {
        if (this.getCourseGradingTables.length === 0) {
            if (Object.keys(this.computedCourse).length !== 0) {
                this.GET_COURSE_GRADING_TABLES(this.computedCourse).then(() => {
                    if (this.getCourseGradingTables.length > 0) {
                        this.computedSelect = this.getCourseGradingTables[0];
                    } else {
                        this.computedSelect = this.gradingTableItem;
                    }
                });
            }
        }
    }
};
</script>
