<template>
    <v-list-group>
        <template v-slot:activator>
            <v-list-item>
                <v-list-item-action v-if="showAction">
                    <slot v-bind:action="item" name="action"> </slot>
                </v-list-item-action>
                <v-list-item-content @click.stop="selectRow(item)" :class="{ 'primary--text': highlight(item) }">
                    <slot v-bind:content="item" name="content">
                        <v-list-item-title>{{ item[itemKeys[0]] }}</v-list-item-title>
                        <v-list-item-subtitle v-for="key in itemKeys.slice(1)" :key="key">{{ item[key] }}</v-list-item-subtitle>
                    </slot>
                </v-list-item-content>
                <v-list-item-action v-if="showAppend">
                    <slot v-bind:append="item" name="append"></slot>
                </v-list-item-action>
            </v-list-item>
        </template>
        <slot v-bind:expanded="item" name="expanded"></slot>
    </v-list-group>
</template>
<script>
export default {
    name: 'list-more-action-group',
    props: {
        value: {
            type: [Object, Array],
            default: () => ({})
        },
        item: {
            type: Object,
            default: () => ({})
        },
        showAction: {
            type: Boolean,
            default: true
        },
        showAppend: {
            type: Boolean,
            default: true
        },
        itemKeys: {
            type: Array,
            default: () => ['name', 'description']
        },
        /**
         * NOTE: weird behavior for 'title'.
         * Vue seems to set undefined props as aria-props
         */
        title: {
            type: String,
            default: ''
        }
    },
    methods: {
        highlight(item) {
            if (Array.isArray(this.value)) {
                return this.value.map((value) => value.id).indexOf(item.id) !== -1;
            }
            return this.value.id === item.id;
        },
        selectRow(item) {
            this.$emit('input', item);
        }
    }
};
</script>
