<template>
    <div>
        <Toolbar
            :toolbarTitle="$t('Archiv Aktualisieren')"
            :showDrawer="false"
            :lastPath="-1"
            :tooltipMessage="$t('Hier können Sie Archiv hinzufügen. Auch können Sie hier Ihre Aufgaben archivieren.')"
        />

        <ArchiveAdd :value="getSelectedTaskArchive.data" type="UPDATE" />
    </div>
</template>

<script>
import ArchiveAdd from '../../Components/Archive/AddUpdateArchive';
import Toolbar from '../../Components/Generic/Toolbar';
import ArchiveMixin from '../../Components/Archive/Mixins/ArchiveMixin';

export default {
    name: 'archive-update',
    components: {
        ArchiveAdd,
        Toolbar
    },
    mixins: [ArchiveMixin]
};
</script>
