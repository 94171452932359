import { mapActions, mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('CertificateOperations', ['getUserCertificateTasks'])
    },
    methods: {
        ...mapActions('CertificateOperations', ['DOWNLOAD_USER_TASK_CERTIFICATE', 'GET_CERTIFICATE_TASKS', 'GET_FONTS'])
    }
};
