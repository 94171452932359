<template>
    <v-card height="100%">
        <v-layout column fill-height>
            <v-flex grow>
                <v-stepper v-model="steps" :alt-labels="$vuetify.breakpoint.smAndUp" non-linear :elevation="0">
                    <v-stepper-header>
                        <v-stepper-step :step="1" editable :rules="taskSettingsRules()">
                            <v-tooltip bottom :max-width="300" :close-delay="100">
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">{{ $t('Aufgabeneinstellungen') }}</span>
                                </template>
                                <span>{{ $t('Hier können Sie die mindest Anforderungen treffen, damit die Aufgabe erstellt werden kann') }}</span>
                            </v-tooltip>
                        </v-stepper-step>
                        <!-- <v-divider /> -->
                        <v-stepper-step :step="2" editable>
                            <v-tooltip bottom :max-width="300" :close-delay="100">
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">{{ $t('Aufgabenoptionen') }}</span>
                                </template>
                                <span>{{ $t('Hier können Sie Einstellungen treffen, die das Verhalten der Aufgabe ändert') }}</span>
                            </v-tooltip>
                        </v-stepper-step>
                        <!-- <v-divider /> -->
                        <v-stepper-step :step="3" editable :rules="catalogSettingsRules()">
                            <v-tooltip bottom :max-width="300" :close-delay="100">
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">{{ $t('Katalogeinstellungen') }}</span>
                                </template>
                                <span>{{
                                    $t(
                                        'Hier können Sie Kataloge zu der Aufgabe einbinden und einzelne Wissenseinheiten von der Aufgabe ausschließen. Sie können zusätzliche Einstellungen für die eingebundenen Kataloge treffen'
                                    )
                                }}</span>
                            </v-tooltip>
                        </v-stepper-step>
                        <!-- <v-divider /> -->
                        <v-stepper-step :step="4" editable :rules="userSettingsRules()">
                            <v-tooltip bottom :max-width="300" :close-delay="100">
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">{{ $t('Nutzereinstellungen') }}</span>
                                </template>
                                <span>{{ $t('Hier können Sie die Nutzer aus Admin- bzw. Usergruppen in die Aufgabe einbeziehen oder ausschließen') }}</span>
                            </v-tooltip>
                        </v-stepper-step>
                        <!-- <v-divider /> -->
                        <v-stepper-step :step="5" editable>
                            <v-tooltip bottom :max-width="300" :close-delay="100">
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">{{ $t('Aufgabenverwalter') }}</span>
                                </template>
                                <span>{{ $t('Hier können Sie Admin auswählen, die die Aufgabe verwalten') }}</span>
                            </v-tooltip>
                        </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                        <v-stepper-content :step="1">
                            <v-layout align-center justify-center>
                                <v-container fluid>
                                    <v-row align="center">
                                        <v-col :xs="12" :sm="12" :md="6">
                                            <v-text-field
                                                v-model="name"
                                                :label="$t('Aufgabenbezeichnung')"
                                                :rules="[(value) => (!!value && !!value.trim()) || $t('Dieses Feld darf nicht leer sein')]"
                                            />
                                        </v-col>
                                        <v-col :xs="12" :sm="12" :md="6">
                                            <ColorPicker :label="$t('Aufgabenfarbe')" v-model="color" />
                                        </v-col>
                                        <v-col :cols="12">
                                            <PickerDate
                                                :startDateText="startDate"
                                                :endDateText="endDate"
                                                :label1="$t('Anfangsdatum')"
                                                :label2="$t('Enddatum')"
                                                type="Range"
                                                :disableStartDate="minimumStartDate"
                                                @pickerStartDateEmit="handleStartDate"
                                                @pickerEndDateEmit="handleEndDate"
                                                :min="minimumEndDate"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-layout>
                        </v-stepper-content>

                        <v-stepper-content :step="2" class="ma-0 pa-0">
                            <v-container fluid>
                                <v-row>
                                    <v-col :cols="12">
                                        <v-card flat outlined>
                                            <v-toolbar flat dense dark color="secondary">
                                                <v-toolbar-title>{{ $t('Aufgabenoptionen') }}</v-toolbar-title>
                                            </v-toolbar>
                                            <v-card-text>
                                                <QuizActivityOptions v-model="taskOptions" :endDate="endDate" />
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col :cols="12">
                                        <v-card flat outlined>
                                            <v-toolbar flat dense dark color="secondary">
                                                <v-toolbar-title>{{ $t('Benachrichtigungsoptionen') }}</v-toolbar-title>
                                            </v-toolbar>
                                            <v-card-text>
                                                <v-checkbox
                                                    v-model="notificationOptions.notifyUsers"
                                                    color="primary"
                                                    :label="$t('Benutzer zusätzlich benachrichtigen')"
                                                    :hint="
                                                        $t(
                                                            'Sie können vor Ablauf der Aufgabe den Aufgabenteilnehmern eine oder mehrere Erinnerungsmails schicken'
                                                        )
                                                    "
                                                    :disabled="taskOptions.loopingTask"
                                                    persistent-hint
                                                />
                                                <v-expand-transition>
                                                    <NotifyUsers v-show="notificationOptions.notifyUsers" v-model="notificationOptions.notifyUsersDaysBefore" />
                                                </v-expand-transition>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col :cols="12">
                                        <v-card flat outlined>
                                            <v-toolbar flat dense dark color="secondary">
                                                <v-toolbar-title>{{ $t('Wiederholungskonfiguration') }}</v-toolbar-title>
                                            </v-toolbar>
                                            <v-card-text>
                                                <v-checkbox
                                                    :label="$t('Wiederholungskonfiguration')"
                                                    :hint="
                                                        $t(
                                                            'Die Aufgabe wird in dem gegebenen Intervall bis zum Abbruchsdatum immer wieder neu erstellt. Die Länge der Aufgabe orientiert sich an dem originalen Start- und Enddatum'
                                                        )
                                                    "
                                                    persistent-hint
                                                    color="primary"
                                                    v-model="showRecurringTaskOptions"
                                                    :disabled="taskOptions.loopingTask"
                                                />
                                                <v-expand-transition>
                                                    <v-container v-if="showRecurringTaskOptions">
                                                        <v-row>
                                                            <v-col :xs="12" :sm="12" :md="6">
                                                                <v-text-field
                                                                    type="number"
                                                                    min="0"
                                                                    :label="$t('Jahre')"
                                                                    :rules="[recurringTaskNonNegativeRule()]"
                                                                    v-model="recurringConfiguration.years"
                                                                />
                                                            </v-col>
                                                            <v-col :xs="12" :sm="12" :md="6">
                                                                <v-text-field
                                                                    type="number"
                                                                    min="0"
                                                                    :label="$t('Monate')"
                                                                    :rules="[recurringTaskNonNegativeRule()]"
                                                                    v-model="recurringConfiguration.months"
                                                                />
                                                            </v-col>
                                                            <v-col :xs="12" :sm="12" :md="6">
                                                                <v-text-field
                                                                    type="number"
                                                                    min="0"
                                                                    :label="$t('Tage')"
                                                                    :rules="[recurringTaskNonNegativeRule()]"
                                                                    v-model="recurringConfiguration.days"
                                                                />
                                                            </v-col>
                                                            <v-col :xs="12" :sm="12" :md="6">
                                                                <PickerDate
                                                                    type="Day"
                                                                    :label1="$t('Abbruchsdatum')"
                                                                    :startDateText="recurringConfiguration.exitDate"
                                                                    :min="minimumRecurringExitDate"
                                                                    @pickerStartDateEmit="handleRecurringConfigurationExitDate"
                                                                />
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-expand-transition>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-stepper-content>

                        <v-stepper-content :step="3" class="ma-0 pa-0">
                            <AuthoringTool v-model="catalogs" selectable hasCatalogOptions />
                        </v-stepper-content>

                        <v-stepper-content :step="4" class="ma-0 pa-0">
                            <UserSelector v-model="users" />
                        </v-stepper-content>

                        <v-stepper-content :step="5" class="ma-0 pa-0">
                            <UserSelector v-model="owners" :showUsers="false" />
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-flex>
            <v-flex shrink>
                <v-card-actions>
                    <v-row>
                        <v-col>
                            <v-btn :disabled="!disableSubmitButton" block :class="submitButtonColor" @click.stop="submit">
                                {{ $t(submitButtonText) }}
                            </v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn class="primary" @click="updateProcessStep" :disabled="steps === 5">
                                {{ $t('Weiter') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-flex>
        </v-layout>
    </v-card>
</template>

<script>
import PickerDate from '../Generic/PickerDate/PickerDate';
import NotifyUsers from './NotifyUsers';
import ColorPicker from '../SystemSettings/Picker/ColorPicker';
import AuthoringTool from '../Authoringtool/AuthoringTool';
import QuizActivityOptions from '../CourseLearning/CourseManager/ActivityTypes/Helpers/QuizActivityOptions';
import UserSelector from '../Generic/Tree/UserGroupTreeSelector';

import PlanToolMixin from './Mixins/PlantoolMixin';
import moment from 'moment';

export default {
    name: 'plantool',
    components: {
        PickerDate,
        NotifyUsers,
        ColorPicker,
        AuthoringTool,
        QuizActivityOptions,
        UserSelector
    },
    mixins: [PlanToolMixin],
    data() {
        return {
            steps: 1,
            showRecurringTaskOptions: false,
            showExcludeKnowledgeUnits: false,
            selectedCatalog: {},

            id: '',
            name: '',
            color: '',
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
            notificationOptions: {
                notifyUsers: false,
                notifyUsersDaysBefore: [1]
            },
            taskOptions: {
                sortBy: 'NATURAL',
                loopingTask: false,
                sortList: ['NATURAL', 'RANDOM', 'DATE'],
                limits: 0,
                extendEndDate: '',
                passingQuote: 0,
                shuffleOptions: false,
                hideUnansweredKnowledgeUnits: false,
                hasPassingQuote: false,
                showResult: true,
                useSkipping: false,
                skippingPercentage: 1.0,
                skippingPercentageTolerance: 0.0
            },
            catalogs: [],
            users: [],
            owners: [],
            excludeKnowledgeUnits: [],
            recurringConfiguration: {
                days: 0,
                exitDate: moment().add(1, 'days').format('YYYY-MM-DD'),
                months: 0,
                years: 0
            }
        };
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        type: {
            type: String,
            default: 'ADD',
            validator: (value) => ['ADD', 'UPDATE', 'COPY'].includes(value)
        }
    },
    watch: {
        taskOptions(value) {
            if (value.loopingTask) {
                this.showRecurringTaskOptions = false;
                this.recurringConfiguration = {
                    days: 0,
                    exitDate: moment().add(1, 'days').format('YYYY-MM-DD'),
                    months: 0,
                    years: 0
                };
                this.notificationOptions = {
                    notifyUsers: false,
                    notifyUsersDaysBefore: [1]
                };
            }
        },
        recurringConfiguration(value) {
            if ((value.days > 0 || value.months > 0 || value.years > 0) && value.exitDate !== '') {
                this.showRecurringTaskOptions = true;
            }
        }
    },
    computed: {
        minimumStartDate() {
            return this.type === 'UPDATE';
        },
        minimumEndDate() {
            if (!this.startDate) {
                return moment().format('YYYY-MM-DD');
            }
            return this.startDate;
        },
        minimumRecurringExitDate() {
            return moment().add(1, 'days').format('YYYY-MM-DD');
        },
        maxLimitComputed() {
            return this.catalogs.map((catalog) => catalog.totalKnowledgeUnits).reduce((acc, val) => acc + val, 0);
        },
        disableSubmitButton() {
            return this.name !== '' && this.catalogs.length > 0 && this.users.length > 0;
        },
        submitButtonColor() {
            return this.type === 'ADD' || this.type === 'COPY' ? 'success' : 'warning';
        },
        submitButtonText() {
            return this.type === 'ADD' || this.type === 'COPY' ? this.$t('Hinzufügen') : this.$t('Aktualisieren');
        }
    },
    methods: {
        serialize() {
            return {
                ...(this.type === 'UPDATE' ? { id: this.id } : {}),
                name: this.name,
                color: this.color,
                startDate: this.startDate,
                endDate: this.endDate,
                notificationOptions: this.notificationOptions,
                taskOptions: this.taskOptions,
                catalogs: this.catalogs,
                users: this.users,
                owners: this.owners,
                excludeKnowledgeUnits: this.excludeKnowledgeUnits,
                ...(this.showRecurringTaskOptions ? { recurringConfiguration: this.recurringConfiguration } : {})
            };
        },
        submit() {
            if (this.type === 'ADD' || this.type === 'COPY') {
                this.ADD_TASK(this.serialize())
                    .then((task) => this.$emit('input', task))
                    .then(() =>
                        this.$emit('switchView', {
                            component: 'CALENDAR',
                            type: 'ADD'
                        })
                    );
            }
            if (this.type === 'UPDATE') {
                this.UPDATE_TASK(this.serialize())
                    .then((task) => this.$emit('input', task))
                    .then(() =>
                        this.$emit('switchView', {
                            component: 'CALENDAR',
                            type: 'ADD'
                        })
                    );
            }
        },
        // handle emits
        handleStartDate(value) {
            this.startDate = value;
        },
        handleEndDate(value) {
            this.endDate = value;
        },
        handleExtendedEndDate(value) {
            this.taskOptions.extendEndDate = value;
        },
        handleRecurringConfigurationExitDate(value) {
            this.recurringConfiguration.exitDate = value;
        },
        handleExcludeKnowledgeUnits(value) {
            this.excludeKnowledgeUnits = value;
        },
        handleExcludeKnowledgeUnitsDialog(value) {
            this.selectedCatalog = value;
            this.showExcludeKnowledgeUnits = true;
        },
        recurringTaskNonNegativeRule() {
            return (value) => parseInt(value) >= 0 || this.$t('Geben Sie eine nicht negative Zahl ein');
        },
        //Stepper rules
        taskSettingsRules() {
            return [() => this.name.trim() !== '' || this.$t('Der Name der Aufgabe darf nicht leer sein')];
        },
        catalogSettingsRules() {
            return [() => this.catalogs.length > 0 || this.$t('Es muss mindestens ein Katalog ausgewählt sein')];
        },
        userSettingsRules() {
            return [() => this.users.length > 0 || this.$t('Es muss mindestens ein Nutzer ausgewählt sein')];
        },
        updateProcessStep() {
            this.steps += 1;
        },
        reset() {
            this.steps = 1;
            this.showRecurringTaskOptions = false;
            this.showExcludeKnowledgeUnits = false;
            this.selectedCatalog = {};
            this.id = '';
            this.name = '';
            this.color = '';
            this.startDate = moment().format('YYYY-MM-DD');
            this.endDate = moment().add(1, 'days').format('YYYY-MM-DD');
            this.notificationOptions = {
                notifyUsers: false,
                notifyUsersDaysBefore: [1]
            };
            this.taskOptions = {
                sortBy: 'NATURAL',
                sortList: ['NATURAL', 'RANDOM', 'DATE'],
                limits: 0,
                extendEndDate: '',
                passingQuote: 0,
                shuffleOptions: false,
                hideUnansweredKnowledgeUnits: false,
                hasPassingQuote: false,
                showResult: true,
                loopingTask: false,
                useSkipping: false,
                skippingPercentage: 1.0,
                skippingPercentageTolerance: 0.0
            };
            this.catalogs = [];
            this.users = [];
            this.owners = [];
            this.excludeKnowledgeUnits = [];
            this.recurringConfiguration = {
                days: 0,
                exitDate: moment().add(1, 'days').format('YYYY-MM-DD'),
                months: 0,
                years: 0
            };
        }
    },
    activated() {
        this.reset();
        if (this.type === 'UPDATE' || this.type === 'COPY') {
            this.steps = 1;
            Object.entries(this.value).forEach(([key, value]) => {
                if (this.$data.hasOwnProperty(key)) {
                    this.$data[key] = value;
                }
            });
            this.GET_TASK_USERS(this.value).then((response) => {
                this.users = response;
            });
            this.GET_TASK_OWNERS(this.value).then((response) => {
                this.owners = response;
            });
        }
        if (this.owners.length === 0) {
            this.owners = [this.getLoggedInAdmin];
        }
    }
};
</script>

<style scoped>
.v-stepper__header {
    height: auto;
}
</style>
