<template>
    <v-layout wrap>
        <v-flex xs6>
            <v-layout column justify-center fill-height>
                <v-label>{{ $t(label) }}:</v-label>
            </v-layout>
        </v-flex>
        <v-flex xs6>
            <slot>
                <v-label>{{ `${$t(display.toString())} ${displayAppend}` }}</v-label>
            </slot>
        </v-flex>
    </v-layout>
</template>
<script>
export default {
    name: 'chart-detail-row',
    props: {
        label: {
            type: String,
            default: ''
        },
        display: {
            type: [String, Number],
            default: ''
        },
        displayAppend: {
            type: String,
            default: ''
        }
    }
};
</script>
