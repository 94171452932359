<template>
    <v-container fluid grid-list-md pa-0>
        <v-layout wrap>
            <!-- Sort -->
            <v-flex xs12 sm12 md12 lg12 xl12>
                <v-select v-model="sortBy" :items="sortList" persistent-hint :hint="taskSortingHint" :label="$t('Kataloge sortieren nach')">
                    <template v-slot:item="{ item }">
                        <div>{{ $t(item) }}</div>
                    </template>
                    <template v-slot:selection="{ item }">
                        <div>{{ $t(item) }}</div>
                    </template>
                </v-select>
            </v-flex>

            <!-- Extend Enddate -->
            <v-flex v-if="isTask" xs12 sm12 md6 lg6 xl6>
                <v-checkbox
                    v-model="computedShowExtendedEndDate"
                    :disabled="computedLoopingTask"
                    persistent-hint
                    :label="$t('Erweitertes Enddatum')"
                    :hint="$t('In den Reports ist einsehbar, welche Nutzer die zusätzliche Zeit in Anspruch genommen haben')"
                />
                <v-expand-transition>
                    <div v-show="computedShowExtendedEndDate">
                        <v-menu offset-y :close-on-content-click="false" v-model="extendedEndDateMenu" :min="endDate">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    readonly
                                    :label="$t('Erweitertes Enddatum')"
                                    :value="extendEndDate"
                                    v-on="on"
                                    prepend-icon="mdi-calendar-remove"
                                    :hint="$t('Nach dem eingestellten Datum können Nutzer keine Antworten abgeben')"
                                    persistent-hint
                                />
                            </template>
                            <v-date-picker v-model="extendEndDate" @input="() => (extendedEndDateMenu = false)" :min="extendedEndDateMinimum" />
                        </v-menu>
                    </div>
                </v-expand-transition>
            </v-flex>
            <!-- Limit -->
            <v-flex xs12 sm12 md6 lg6 xl6>
                <v-checkbox
                    v-model="computedShowLimits"
                    :disabled="computedLoopingTask"
                    :label="`${$t('Anzahl der Wissenseinheiten pro Tag limitieren [KI]')}`"
                    :hint="
                        $t(
                            'Nutzer können nur eine begrenzte Zahl an Wissenseinheiten pro Tag beantworten. Die Begrenzung passt sich der Anzahl an verbleibenden Tagen der Aufgabe an, damit jeder Nutzer die Möglichkeit hat, alle Wissenseinheiten zu beantworten'
                        )
                    "
                    persistent-hint
                />
                <v-expand-transition>
                    <v-slider
                        v-show="computedShowLimits"
                        v-model="limits"
                        :label="`${limits} ${$t('Wissenseinheiten')}`"
                        min="0"
                        max="100"
                        step="1"
                        hide-details
                    />
                </v-expand-transition>
            </v-flex>
            <!-- PassingQuote -->
            <v-flex xs12 sm12 md6 lg6 xl6>
                <v-checkbox
                    v-model="computedShowPassingQuote"
                    :disabled="computedLoopingTask"
                    :label="$t('Bestehensquote einstellen [Zertifikat ausstellen]')"
                    :hint="
                        $t(
                            'Geben Sie eine untere Schranke an, die zum Bestehen der Aufgabe mindestens erfüllt werden muss. Zertifikate über bestandene Aufgaben können vom Nutzer heruntergeladen werden'
                        )
                    "
                    persistent-hint
                />
                <v-expand-transition>
                    <v-slider v-show="computedShowPassingQuote" v-model="passingQuote" :label="`${passingQuote}%`" min="0" step="5" max="100" hide-details />
                </v-expand-transition>
            </v-flex>
            <!-- Shuffle Answer Options -->
            <v-flex xs12 sm12 md6 lg6 xl6>
                <v-checkbox
                    v-model="shuffleOptions"
                    :label="$t('Antwortoptionen zufällig anordnen')"
                    persistent-hint
                    :hint="$t('Wenn diese Option aktiviert ist, werden die Optionen der Wissenseinheiten zufällig angeordnet')"
                />
            </v-flex>
            <v-flex xs12 sm12 md6 lg6 xl6>
                <v-checkbox
                    v-model="computedHideUnanswered"
                    :label="$t('Nicht beantwortete Wissenseinheiten im Ergebnis auslassen')"
                    persistent-hint
                    :hint="$t('Wenn diese Option aktiviert ist, werden nicht beantwortete Wissenseinheiten im Ergebnis nicht angezeigt')"
                />
            </v-flex>
            <v-flex xs12 sm12 md6 lg6 xl6>
                <v-checkbox
                    v-model="computedShowResults"
                    :true-value="false"
                    :false-value="true"
                    :label="$t('Ergebnis nicht anzeigen')"
                    persistent-hint
                    :hint="$t('Wenn diese Option aktiviert ist, werden Ergebnisse nicht angezeigt')"
                />
            </v-flex>

            <v-flex xs12 sm12 md12 lg12 xl12 v-if="isTask || isArchive">
                <v-checkbox
                    v-model="computedLoopingTask"
                    :label="$t('Schleifenaufgabe')"
                    :hint="
                        $t(
                            'Wenn diese Option aktiviert ist, läuft die Aufgabe nach Beendigung in einer Schleife weiter. Einige andere Optionen sind nicht verfügbar, wenn diese Option aktiv ist'
                        )
                    "
                    persistent-hint
                />
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import moment from 'moment';

export default {
    name: 'quiz-activity-options',
    data: () => ({
        showLimits: false,
        showPassingQuote: false,
        showExtendEndDate: false,
        extendedEndDateMenu: false
    }),
    props: {
        value: {
            type: Object,
            default() {
                return {
                    sortBy: 'NATURAL',
                    loopingTask: false,
                    limits: 0,
                    passingQuote: 0,
                    shuffleOptions: false,
                    extendEndDate: '',
                    hideUnansweredKnowledgeUnits: false,
                    hasPassingQuote: false,
                    showResult: true
                };
            }
        },
        endDate: {
            type: String,
            default: ''
        },
        isArchive: {
            type: Boolean,
            default: true
        },
        isTask: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        endDate(value) {
            if (this.computedShowExtendedEndDate && moment(this.value.extendEndDate).isBefore(moment(value).add(1, 'days').format('YYYY-MM-DD'))) {
                this.$emit('input', {
                    ...this.value,
                    extendEndDate: moment(value).add(1, 'days').format('YYYY-MM-DD')
                });
            }
        }
    },
    computed: {
        sortList() {
            return ['NATURAL', 'RANDOM', 'DATE'];
        },
        extendedEndDateMinimum() {
            return moment(this.endDate).add(1, 'days').format('YYYY-MM-DD');
        },
        computedShowExtendedEndDate: {
            get() {
                return this.showExtendEndDate || this.value.extendEndDate !== '';
            },
            set(value) {
                this.showExtendEndDate = value;
                if (value) {
                    this.$emit('input', {
                        ...this.value,
                        extendEndDate: moment(this.endDate).add(1, 'days').format('YYYY-MM-DD')
                    });
                } else {
                    this.$emit('input', { ...this.value, extendEndDate: '' });
                }
            }
        },
        computedShowPassingQuote: {
            get() {
                return this.value.hasPassingQuote;
            },
            set(value) {
                if (!value) {
                    this.$emit('input', {
                        ...this.value,
                        hasPassingQuote: value,
                        passingQuote: 0
                    });
                } else {
                    this.$emit('input', {
                        ...this.value,
                        hasPassingQuote: value
                    });
                }
            }
        },
        computedLoopingTask: {
            get() {
                return this.value.loopingTask;
            },
            set(value) {
                if (value) {
                    this.showPassingQuote = false;
                    this.showExtendEndDate = false;
                    this.showLimits = false;
                    this.$emit('input', { ...this.value, limits: 0, hasPassingQuote: false, passingQuote: 0, extendEndDate: '', loopingTask: value });
                } else {
                    this.$emit('input', { ...this.value, loopingTask: value });
                }
            }
        },
        computedShowLimits: {
            get() {
                return this.showLimits || this.value.limits > 1;
            },
            set(value) {
                this.showLimits = value;
                if (!value) {
                    this.$emit('input', { ...this.value, limits: 0 });
                }
            }
        },
        computedShowResults: {
            get() {
                return this.value.showResult;
            },
            set(value) {
                this.$emit('input', { ...this.value, showResult: value });
            }
        },
        computedHideUnanswered: {
            get() {
                return this.value.hideUnansweredKnowledgeUnits;
            },
            set(value) {
                this.$emit('input', {
                    ...this.value,
                    hideUnansweredKnowledgeUnits: value
                });
            }
        },
        sortBy: {
            get() {
                return this.value.sortBy;
            },
            set(value) {
                this.$emit('input', { ...this.value, sortBy: value });
            }
        },
        limits: {
            get() {
                return this.value.limits;
            },
            set(value) {
                this.$emit('input', { ...this.value, limits: value });
            }
        },
        passingQuote: {
            get() {
                return this.value.passingQuote;
            },
            set(value) {
                this.$emit('input', { ...this.value, passingQuote: value });
            }
        },
        shuffleOptions: {
            get() {
                return this.value.shuffleOptions;
            },
            set(value) {
                this.$emit('input', { ...this.value, shuffleOptions: value });
            }
        },
        extendEndDate: {
            get() {
                return this.value.extendEndDate;
            },
            set(value) {
                if (moment(value).isAfter(this.endDate)) {
                    this.$emit('input', {
                        ...this.value,
                        extendEndDate: value
                    });
                } else {
                    this.$emit('input', {
                        ...this.value,
                        extendEndDate: this.endDate
                    });
                }
            }
        },
        taskSortingHint() {
            switch (this.sortBy) {
                case 'NATURAL':
                    return this.$t(
                        'Die Reihenfolge der Kataloge wird unverändert im Quiz wiedergegeben. Es müssen alle Wissenseinheiten von einem Katalog beantwortet werden, bevor die Wissenseinheiten vom nächsten Katalog beantwortet werden können'
                    );
                case 'RANDOM':
                    return this.$t(
                        'Die Reihenfolge der Kataloge wird zufällig im Quiz wiedergegeben. Wissenseinheiten von allen Katalogen können beantwortet werden'
                    );
                case 'DATE':
                    return this.$t(
                        'Die Reihenfolge der Kataloge im Quiz wird nach der Aktualität der Kataloge ausgegeben. Es müssen alle Wissenseinheiten von einem Katalog beantwortet werden, bevor die Wissenseinheiten vom nächsten Katalog beantwortet werden können'
                    );
            }
            return '';
        }
    }
};
</script>
