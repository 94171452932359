<template>
    <v-card :class="cardStyle" @click.passive="selectCard">
        <v-toolbar dense flat dark :color="!!value && !!value.taxonomy && !!value.taxonomy.hexColor ? value.taxonomy.hexColor : 'primary'">
            <v-icon>{{ activityType }}</v-icon>
        </v-toolbar>
        <v-tabs v-model="modalActive" mandatory>
            <v-tab key="ACTIVITY">{{ computedTabTitle }}</v-tab>
            <v-tab key="MATERIAL" :disabled="!hasMaterial">{{ $t('Material') }}</v-tab>
            <v-tab key="CONTENT" :disabled="!hasContentLinks">{{ $t('Content Links') }}</v-tab>
        </v-tabs>
        <v-card>
            <v-container>
                <v-row>
                    <v-col>
                        <v-tabs-items v-model="modalActive" touchless>
                            <v-tab-item key="ACTIVITY">
                                <v-container>
                                    <v-row no-gutters>
                                        <v-col>
                                            <v-row no-gutters>
                                                <v-col :cols="12">
                                                    <span :class="{ title: true, limitTitle: this.selectable }">{{ value.name }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-row no-gutters justify="end">
                                                <v-col :cols="12">
                                                    <v-icon>mdi-clock-outline</v-icon>
                                                </v-col>
                                                <v-col :cols="12">
                                                    <span class="text-caption">{{ convertMinutes }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-expand-transition>
                                    <v-card flat v-show="showMoreInfo">
                                        <ActivityInfo
                                            :selectable="this.selectable"
                                            :value="value"
                                            :timeScaleInMinutes="this.timeScaleInMinutes"
                                            :durationInDays="showDurationInDays"
                                            :isMyCourse="isMyCourse"
                                        />
                                    </v-card>
                                </v-expand-transition>
                            </v-tab-item>
                            <v-tab-item key="MATERIAL">
                                <div class="ck ck-content scroll" style="max-height: 340px" v-html="materialSwitch(value.material)" />
                                <MaterialDownloader :items="getMaterial(value)" />
                            </v-tab-item>
                            <v-tab-item key="CONTENT">
                                <div class="scroll" style="max-height: 20vh" @click.stop>
                                    <KnowledgeContent :contents="value.knowledgeContents" :selectable="false" v-model="computedSelectedKnowledgeContent" />
                                </div>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-col>
                </v-row>
            </v-container>
            <v-card-actions>
                <v-row align="center" justify="center">
                    <v-col cols="auto">
                        <v-btn v-show="modalActive === 0" icon @click.stop="toggleMoreInfo">
                            <v-icon>{{ showInfoIcon }}</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-card>
</template>
<script>
import CourseMixin from '../../Mixins/CourseMixin';

import KnowledgeContent from '../../../../KnowledgeContent/KnowledgeContentContent';
import GroupRadio from '../../../../Groups/GroupRadio';
import MaterialDownloader from '../../MaterialDownloader';
import ActivityInfo from './ActivityInfo';

export default {
    name: 'course',
    components: {
        KnowledgeContent,
        MaterialDownloader,
        ActivityInfo
    },
    mixins: [CourseMixin],
    data: () => ({
        modalActive: 0,
        showMoreInfo: false,
        areMaterialsLoaded: false
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        timeScaleInMinutes: {
            type: Number,
            default: 60
        },
        isAdmin: {
            type: Boolean,
            default: false
        },
        isMyCourse: {
            type: Boolean,
            default: false
        },
        selected: {
            type: Object,
            default: () => ({})
        },
        selectable: {
            type: Boolean,
            default: false
        },
        selectedKnowledgeContent: {
            type: Array,
            default: () => []
        }
    },
    watch: {
        modalActive(value) {
            if (value === 1 && !this.areMaterialsLoaded) {
                this.GET_ACTIVITY_MATERIALS(this.value).then(() => (this.areMaterialsLoaded = true));
            }
        }
    },
    computed: {
        hasMaterial() {
            if (Object.keys(this.value).length > 0) {
                const materials = this.getMaterial(this.value);
                if (materials) {
                    return this.value.material !== '' || materials.length > 0;
                } else {
                    return this.value.material !== '';
                }
            }
            return false;
        },
        hasContentLinks() {
            if (Object.keys(this.value).length > 0) {
                return this.value.KnowledgeContents && this.value.KnowledgeContents.length > 0;
            }
            return false;
        },
        computedTabTitle() {
            if (this.value.typeName !== null && this.value.typeName !== '') {
                return this.value.typeName;
            } else if (this.getSelectedLearningGoal.activityTypeName !== null && this.getSelectedLearningGoal.activityTypeName !== '') {
                return this.getSelectedLearningGoal.activityTypeName;
            } else if (this.getSelectedTopic.activityTypeName !== null && this.getSelectedTopic.activityTypeName !== '') {
                return this.getSelectedTopic.activityTypeName;
            } else if (this.getSelectedAdminCourse.activityTypeName !== null && this.getSelectedAdminCourse.activityTypeName !== '') {
                return this.getSelectedAdminCourse.activityTypeName;
            }
            return this.$t('Aktivität');
        },
        computedSelectedKnowledgeContent: {
            get() {
                return this.selectedKnowledgeContent;
            },
            set(value) {
                this.$emit('selectedKnowledgeContent', value);
            }
        },
        hiddenDurationInDays() {
            if (this.value.durationInDays === 0) {
                return '-';
            }
            return this.value.durationInDays;
        },
        showDurationInDays() {
            if (this.isMyCourse && this.value.hideDurationInDays) {
                return '-';
            }
            return this.value.durationInDays;
        },
        computedDays() {
            if (this.value.durationInDays === 1) {
                return this.$t('Tag');
            }
            return this.$t('Tage');
        },
        emptyDescription() {
            if (this.value.description.length === 0) {
                return '-';
            } else {
                return this.value.description;
            }
        },
        emptyNotes() {
            if (this.value.notes === null) {
                return '-';
            }
            return this.value.notes;
        },
        convertMinutes() {
            const timeUnitMinutes = this.value.estimatedWorkTimeInMinutes % this.timeScaleInMinutes;
            const timeUnit = this.value.estimatedWorkTimeInMinutes / this.timeScaleInMinutes;

            if (timeUnitMinutes.toString().length === 1) {
                return `${timeUnit.toString().split('.')[0]}:${'0' + timeUnitMinutes.toString()}`;
            }
            return `${timeUnit.toString().split('.')[0]}:${timeUnitMinutes.toString()}`;
        },
        convertMinutesInfo() {
            if (this.isMyCourse && this.value.hideEstimatedWorkTimeInMinutes) {
                return '-';
            }
            const timeUnitMinutes = this.value.estimatedWorkTimeInMinutes % this.timeScaleInMinutes;
            const timeUnit = this.value.estimatedWorkTimeInMinutes / this.timeScaleInMinutes;

            if (timeUnitMinutes.toString().length === 1) {
                return `${timeUnit.toString().split('.')[0]}:${'0' + timeUnitMinutes.toString()}`;
            }
            return `${timeUnit.toString().split('.')[0]}:${timeUnitMinutes.toString()} Einheiten`;
        },
        activityType() {
            switch (this.value.activitySpecification.type) {
                case 'UPLOAD':
                    return 'mdi-cloud-upload';
                case 'SELF_CHECK':
                    return 'mdi-note-text';
                case 'TEXT_FIELD':
                    return 'mdi-sticker-text';
                case 'INFORM':
                    return 'mdi-information-outline';
                case 'QUIZ':
                    return 'mdi-flask-outline';
            }
            return 'mdi-pencil';
        },
        isCardSelected() {
            if (this.selected.hasOwnProperty('id') && this.value.hasOwnProperty('id')) {
                return this.selected.id === this.value.id;
            }
            return false;
        },
        cardStyle() {
            return {
                'elevation-12': this.isCardSelected,
                pointer: this.selectable
            };
        },
        showInfoIcon() {
            return this.showMoreInfo === false ? 'mdi-chevron-down' : 'mdi-chevron-up';
        },
        isComparable() {
            return (
                this.getSelectedActivity !== null &&
                this.getSelectedActivity !== undefined &&
                this.value !== null &&
                this.value !== undefined &&
                this.value.hasOwnProperty('id') &&
                this.getSelectedActivity.hasOwnProperty('id')
            );
        },
        isSelected() {
            if (this.isComparable) {
                return this.value.id === this.getSelectedActivity.id;
            }
            return false;
        }
    },
    methods: {
        toggleMoreInfo() {
            this.showMoreInfo = !this.showMoreInfo;
        },
        materialSwitch(content) {
            if (content === '') {
                return `<div class="text-xs-center" style="width:100%">${this.$t('Keine Materialien verfügbar')}</div>`;
            }
            return content;
        },
        selectCard() {
            this.$emit('selected', this.value);
        },
        handleCourseSelection() {
            if (this.isComparable) {
                if (this.value.id === this.getSelectedActivity.id) {
                    this.$emit('input', {});
                } else {
                    this.$emit('input', this.value);
                }
            } else {
                this.$emit('input', this.value);
            }
        }
    }
};
</script>

<style scoped>
.scroll {
    overflow-y: auto;
}
.limitTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>
