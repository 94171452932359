<template>
    <v-card>
        <v-toolbar v-if="hasToolbar" dense text dark color="secondary">
            <v-toolbar-title>{{ toolbarTitle }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="closeEmit">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>

        <v-card-text>
            <v-layout column>
                <v-flex>
                    <span>{{ $t(deleteMessage) }}</span>
                </v-flex>
                <v-flex>
                    <v-checkbox
                        v-model="showDeleteButton"
                        color="error"
                        :hint="$t('Wenn Sie bereits gelöschte Daten wieder herstellen möchten, wenden Sie sich bitte an den Support.')"
                        persistent-hint
                        :label="$t('Löschen bestätigen')"
                    />
                </v-flex>
            </v-layout>
        </v-card-text>

        <v-card-actions>
            <v-btn block :disabled="deleteButtonisDisabled" color="error" @click="deleteItem">{{ $t('Löschen') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    data: () => ({
        showDeleteButton: false
    }),
    props: {
        deleteMessage: {
            type: String,
            default: ''
        },
        checkboxDeleteMessage: {
            type: String,
            default: ''
        },
        hasToolbar: {
            type: Boolean,
            default: false
        },
        toolbarTitle: {
            type: String,
            default: ''
        }
    },
    computed: {
        deleteButtonisDisabled() {
            return !this.showDeleteButton;
        }
    },
    methods: {
        deleteItem() {
            this.$emit('deleteItem');
            this.showDeleteButton = false;
        },
        closeEmit() {
            this.$emit('closeEmit');
            this.showDeleteButton = false;
        }
    }
};
</script>
