<template>
    <v-card text>
        <v-flex shrink>
            <v-tabs slider-color="accent" v-model="tabs" grow>
                <v-tab key="USER">{{ $t('Lernende') }}</v-tab>
                <v-tab key="ADMIN">{{ $t('Admin / Lehrende') }}</v-tab>
            </v-tabs>
        </v-flex>
        <v-tabs-items v-model="tabs" touchless mandatory class="fill-height">
            <v-tab-item key="USER">
                <v-flex grow>
                    <v-expand-transition mode="out-in">
                        <v-data-table
                            :headers="reportHeaders"
                            :items="users"
                            :items-per-page="10"
                            class="elevation-1"
                            :header-props="headerProps"
                            :footer-props="{
                                'items-per-page-text': `${$t('Lernende') + ' '}  ${$t('pro Seite')}`,
                                'items-per-page-options': [5, 10, 15, { text: $t('Alle'), value: -1 }]
                            }"
                        >
                            <template v-slot:item.completion_status="{ item }">
                                {{ $t(item.completion_status) }}
                            </template>
                            <template v-slot:item.success_status="{ item }">
                                {{ $t(item.success_status) }}
                            </template>
                            <template v-slot:item.total_time="{ item }">
                                {{ $t(item.total_time) }}
                            </template>
                            <template v-slot:item.progress="{ item }">
                                <v-progress-circular :rotate="-90" :size="40" :width="5" :value="item.progress" color="primary">
                                    {{ item.progress }}
                                </v-progress-circular>
                            </template>
                            <template #footer.page-text="props">
                                {{ props.pageStart }}-{{ props.pageStop }} {{ ' ' + $t('von') + ' ' }} {{ props.itemsLength }}
                            </template>
                        </v-data-table>
                    </v-expand-transition>
                </v-flex>
            </v-tab-item>
            <v-tab-item key="ADMIN">
                <v-flex grow>
                    <v-expand-transition mode="out-in">
                        <v-data-table
                            :headers="reportHeaders"
                            :items="admins"
                            :items-per-page="10"
                            class="elevation-1"
                            :header-props="headerProps"
                            :footer-props="{
                                'items-per-page-text': `${$t('Admin / Lehrende') + ' '}  ${$t('pro Seite')}`,
                                'items-per-page-options': [5, 10, 15, { text: $t('Alle'), value: -1 }]
                            }"
                        >
                            <template v-slot:item.completion_status="{ item }">
                                {{ $t(item.completion_status) }}
                            </template>
                            <template v-slot:item.success_status="{ item }">
                                {{ $t(item.success_status) }}
                            </template>
                            <template v-slot:item.total_time="{ item }">
                                {{ $t(item.total_time) }}
                            </template>
                            <template v-slot:item.progress="{ item }">
                                <v-progress-circular :rotate="-90" :size="40" :width="5" :value="item.progress" color="primary">
                                    {{ item.progress }}
                                </v-progress-circular>
                            </template>
                            <template #footer.page-text="props">
                                {{ props.pageStart }}-{{ props.pageStop }} {{ ' ' + $t('von') + ' ' }} {{ props.itemsLength }}
                            </template>
                        </v-data-table>
                    </v-expand-transition>
                </v-flex>
            </v-tab-item>
        </v-tabs-items>
        <hr />
        <v-card-text>
            <v-card-actions class="justify-center">
                <v-btn block color="primary" @click="closeReportModal">{{ $t('Schließen') }}</v-btn>
            </v-card-actions>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'report-window',
    data: () => ({
        tabs: 0,
        reportHeaders: [
            { text: 'Vorname', value: 'firstName' },
            { text: 'Nachname', value: 'lastName' },
            { text: 'E-Mail', value: 'email' },
            { text: 'Completion Status', value: 'completion_status' },
            { text: 'Success Status', value: 'success_status' },
            { text: 'Total Time', value: 'total_time' },
            { text: 'Prozent %', value: 'progress' }
        ]
    }),
    props: {
        admins: {
            type: Array,
            default: () => ({})
        },
        users: {
            type: Array,
            default: () => ({})
        }
    },
    methods: {
        setReportHeaders() {
            this.reportHeaders.map((header) => {
                header.text = this.$t(header.text);
            });
        },
        closeReportModal() {
            this.$emit('closeReportModal');
        }
    },
    computed: {
        headerProps() {
            return { sortByText: this.$t('Sortieren'), multiSort: true };
        }
    },
    mounted() {
        this.setReportHeaders();
    }
};
</script>

<style>
.hr-line {
    width: 100%;
    margin: 18px 0 14px;
}
</style>
