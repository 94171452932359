<template>
    <component :is="selectedComponentComputed" :value="valueComputed" />
</template>

<script>
import VoteTally from './VoteTally';
import UserActivityTally from './UserActivityTally';
import KnowledgeContentTally from './KnowledgeContentTally';
import KnowledgeUnitTally from './KnowledgeUnitTally';
import KnowledgeUnitRanking from './KnowledgeUnitRanking';
import QuizActivity from './QuizActivity';

export default {
    components: {
        VoteTally,
        UserActivityTally,
        KnowledgeContentTally,
        KnowledgeUnitTally,
        KnowledgeUnitRanking,
        QuizActivity
    },
    data: () => ({}),
    props: {
        chartData: {
            default: () => ({
                knowledgeUnits: {},
                vote: {},
                knowledgeContents: {},
                knowledgeUnitsRank: {},
                userActivity: {},
                quizActivity: {}
            }),
            type: Object
        },
        selectedChartDataKey: {
            default: 'knowledgeUnits',
            type: String
        }
    },
    computed: {
        selectedComponentComputed() {
            switch (this.selectedChartDataKey) {
                case 'knowledgeUnits':
                    return KnowledgeUnitTally;
                case 'vote':
                    return VoteTally;
                case 'knowledgeContents':
                    return KnowledgeContentTally;
                case 'knowledgeUnitsRank':
                    return KnowledgeUnitRanking;
                case 'userActivity':
                    return UserActivityTally;
                case 'quizActivity':
                    return QuizActivity;
            }
            return '';
        }
    }
};
</script>
