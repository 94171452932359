<template>
    <v-container>
        <v-slide-x-transition mode="out-in">
            <v-row align="center" justify="center">
                <v-btn-toggle v-model="computedValue" mandatory class="flex wrap">
                    <v-col :cols="6" v-for="option in value" :key="option.id">
                        <v-btn block medium :value="option" :color="computedColor(option)" :text="!option.isAnswer">
                            {{ $t(option.text) }}
                        </v-btn>
                    </v-col>
                </v-btn-toggle>
            </v-row>
        </v-slide-x-transition>
    </v-container>
</template>

<script>
export default {
    name: 'true-false',
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        computedValue: {
            get() {
                const find = this.value.find((option) => option.isAnswer);
                return find ? find : {};
            },
            set(value) {
                this.$emit(
                    'input',
                    this.value.map((option) => {
                        if (value === option) {
                            return { ...option, isAnswer: true };
                        }
                        return { ...option, isAnswer: false };
                    })
                );
            }
        }
    },
    methods: {
        computedColor({ text }) {
            if (text === 'True') {
                return 'success';
            }
            return 'error';
        }
    },
    activated() {
        const now = performance.now();
        let correct = {
            id: parseInt(now).toString(),
            text: 'True',
            isAnswer: true,
            minimum: '',
            maximum: '',
            steps: '',
            sequenceNo: 0,
            mediaId: '',
            fileURL: '',
            fileName: '',
            fileType: '',
            size: 0,
            answeredOrder: 0
        };
        let incorrect = {
            id: parseInt(now + 1).toString(),
            text: 'False',
            isAnswer: false,
            minimum: 0,
            maximum: 100,
            steps: 1,
            sequenceNo: 1,
            mediaId: '',
            fileURL: '',
            fileName: '',
            fileType: '',
            size: 0,
            answeredOrder: 0
        };
        this.$emit('input', [correct, incorrect]);
    }
};
</script>
