<template>
    <v-card height="100%">
        <v-container fluid fill-height ma-0 pa-0>
            <v-layout column>
                <v-flex shrink>
                    <v-toolbar flat dense outlined>
                        <v-autocomplete
                            v-model="computedSearchItem"
                            hide-details
                            return-object
                            :search-input.sync="computedSearch"
                            item-key="id"
                            item-value="name"
                            :filter="filter"
                            :items="flattendTree"
                            :label="$t('Suchen')"
                            :no-data-text="$t('Keine Daten')"
                            :no-results-text="$t('Kein Ergebnis')"
                            :loading="searchDebouncer !== null"
                            :no-filter="searchDebouncer !== null"
                            :disabled="flattendTree.length === 0"
                            @focus="resetSearchSelection"
                            :menu-props="{ transition: 'slide-y-transition' }"
                        >
                            <template v-slot:selection="{ item, selected }">
                                <v-chip :value="!search" color="primary" dark>
                                    {{ item.name }}
                                </v-chip>
                            </template>
                            <template v-slot:item="{ item }">
                                <v-list-item-action>
                                    <v-icon medium>{{ icon(item) }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ item.name }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ pathToSearchItem(item) }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                        <template v-slot:extension>
                            <v-flex shrink v-if="selectable">
                                <v-badge top overlap :value="value.length > 0">
                                    <template v-slot:badge>
                                        <span>{{ value.length }}</span>
                                    </template>
                                    <v-btn icon @click="() => (showList = !showList)">
                                        <v-icon>mdi-format-list-bulleted</v-icon>
                                    </v-btn>
                                </v-badge>
                            </v-flex>
                            <v-flex shrink>
                                <v-btn icon @click="goUp" :disabled="!isSelected">
                                    <v-icon>mdi-folder-upload</v-icon>
                                </v-btn>
                            </v-flex>
                            <v-flex grow>
                                <v-breadcrumbs :items="breadcrumbPath">
                                    <template v-slot:item="{ item }">
                                        <span
                                            :class="{
                                                pointer: item !== computedSelectedItem,
                                                'truncate-path': true
                                            }"
                                            @click="computedSelectedItem = [item]"
                                            >{{ item.name }}
                                        </span>
                                    </template>
                                </v-breadcrumbs>
                            </v-flex>
                        </template>
                    </v-toolbar>
                </v-flex>
                <v-flex grow>
                    <keep-alive>
                        <component
                            :is="authoringToolComponent"
                            :component="selectedComponent"
                            v-model="computedSelectedItemArray"
                            :search="searchConfirm"
                            @openTree="handleOpenTree"
                            :selected="value"
                            @selected="handleSelected"
                            :hasCatalogOptions="hasCatalogOptions"
                            @switchView="(value) => (showList = value)"
                        >
                            <v-treeview
                                :items="getTree"
                                transition
                                activatable
                                :active.sync="computedSelectedItemArray"
                                return-object
                                :open.sync="openPath"
                                class="pt-2"
                                ref="tree"
                                dense
                            >
                                <template v-slot:prepend="{ item, open }">
                                    <v-icon>
                                        {{
                                            isAuthoringToolType(item) === 'CATALOG'
                                                ? 'mdi-card-bulleted-outline'
                                                : open && item.children.length > 0
                                                ? 'mdi-folder-open-outline'
                                                : 'mdi-folder-outline'
                                        }}
                                    </v-icon>
                                </template>
                                <template v-slot:label="{ item, active }">
                                    <v-layout :ref="item.id">
                                        <v-flex shrink @click.stop v-if="selectable && isAuthoringToolType(item) === 'CATALOG'" class="pa-0 ma-0">
                                            <v-badge :value="isChecked(item)" :content="getSelectedItemIndex(item)" color="primary" inline>
                                                <v-simple-checkbox :value="isChecked(item)" @input="handleCheck($event, item)" :ripple="false" />
                                            </v-badge>
                                        </v-flex>
                                        <v-flex align-self-center>
                                            <span
                                                :class="{
                                                    'primary--text': active,
                                                    pointer: true,
                                                    'no-highlight': true,
                                                    'truncate-tree-item': true
                                                }"
                                            >
                                                {{ item.name || item.title }}
                                            </span>
                                        </v-flex>
                                    </v-layout>
                                </template>
                            </v-treeview>
                        </component>
                    </keep-alive>
                </v-flex>
            </v-layout>
        </v-container>
    </v-card>
</template>

<script>
import AuthoringToolMixin from './Mixins/AuthoringtoolMixin';

import KnowledgeUnitView from './Helpers/KnowledgeUnitView';
import AuthoringToolView from './Helpers/AuthoringToolView';
import AuthoringToolMiniView from './Helpers/AuthoringToolMiniView';
import SelectedListView from './Helpers/SelectedListView';
import KnowledgeContentCheckbox from '../KnowledgeContent/Helpers/KnowledgeContentCheckbox';

export default {
    name: 'authoring-tool',
    mixins: [AuthoringToolMixin],
    components: {
        KnowledgeUnitView,
        AuthoringToolView,
        AuthoringToolMiniView,
        KnowledgeContentCheckbox,
        SelectedListView
    },
    data: () => ({
        search: '',
        searchConfirm: '',
        searchDebouncer: null,
        selectedSearch: {},
        selectedItem: [],
        openPath: [],
        isLoaded: false,
        showList: false
    }),
    props: {
        selectable: {
            type: Boolean,
            default: false
        },
        hasCatalogOptions: {
            type: Boolean,
            default: false
        },
        value: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        computedSearch: {
            get() {
                return this.search;
            },
            set(value) {
                this.search = value;
                if (this.searchDebouncer) {
                    clearTimeout(this.searchDebouncer);
                    this.searchDebouncer = null;
                }
                if (value !== '') {
                    this.searchDebouncer = setTimeout(
                        function () {
                            this.data.searchConfirm = this.search;
                            this.data.searchDebouncer = null;
                        }.bind({ data: this, search: value }),
                        1500
                    );
                } else {
                    clearTimeout(this.searchDebouncer);
                    this.searchDebouncer = null;
                    this.searchConfirm = '';
                }
            }
        },
        breadcrumbPath() {
            const path = [...this.parentPath, this.computedSelectedItem];
            // Legacy
            const catalogGroup = path.find((item) => this.isAuthoringToolType(item) === 'CATALOG_GROUP');
            if (catalogGroup) {
                this.SELECT_CATALOGGROUP(catalogGroup);
            } else {
                this.SELECT_CATALOGGROUP({});
            }
            const catalogSubgroup = path.find((item) => this.isAuthoringToolType(item) === 'CATALOG_SUBGROUP');
            if (catalogSubgroup) {
                this.SELECT_CATALOGSUBGROUP(catalogSubgroup);
            } else {
                this.SELECT_CATALOGSUBGROUP({});
            }
            const catalog = path.find((item) => this.isAuthoringToolType(item) === 'CATALOG');
            if (catalog) {
                this.SELECT_CATALOG(catalog);
            } else {
                this.SELECT_CATALOG({});
            }
            return path;
        },
        parentPath() {
            let path = [];
            const pathfinder = (parent) => {
                const node = this.getParent(parent);
                if (node !== undefined) {
                    path.unshift(node);
                    pathfinder(node);
                }
            };
            if (this.hasParent) {
                pathfinder(this.selectedItem[0]);
            }
            return path;
        },
        isSelected() {
            return this.selectedItem.length > 0;
        },
        hasParent() {
            return this.isSelected ? !!this.getParent(this.selectedItem[0]) : false;
        },
        computedSelectedItemArray: {
            get() {
                return this.selectedItem;
            },
            set(value) {
                this.selectedItem = value;
                if (value.length > 0 && !!value[0]) {
                    this.openPath = [...this.openPath, ...this.parentPath];
                }
            }
        },
        computedSelectedItem: {
            get() {
                return this.selectedItem.length > 0 ? this.selectedItem[0] : {};
            },
            set(value) {
                this.selectedItem = value;
                if (value.length > 0 && !!value[0]) {
                    this.openPath = [...this.openPath, ...this.parentPath];
                }
            }
        },
        computedSearchItem: {
            get() {
                return this.selectedSearch;
            },
            set(value) {
                this.selectedSearch = value;
                this.selectedItem = value ? [value] : [];
            }
        },
        authoringToolComponent() {
            if (this.showList) {
                return SelectedListView;
            }
            if (this.isLoaded) {
                if (this.$vuetify.breakpoint.xs) {
                    return AuthoringToolMiniView;
                }
                return AuthoringToolView;
            } else {
                if (this.$vuetify.breakpoint.xs) {
                    return AuthoringToolView;
                } else {
                    return AuthoringToolMiniView;
                }
            }
        },
        selectedComponent() {
            return KnowledgeUnitView;
        },
        flattendTree() {
            return this.getCatalogs;
        }
    },
    methods: {
        resetSearchSelection() {
            this.selectedSearch = {};
        },
        icon(item) {
            switch (this.isAuthoringToolType(item)) {
                case 'CATALOG_GROUP':
                    return 'mdi-folder-outline';
                case 'CATALOG_SUBGROUP':
                    return 'mdi-folder-outline';
                case 'CATALOG':
                    return 'mdi-card-bulleted-outline';
            }
            return '';
        },
        pathToSearchItem(item) {
            let treeItem = this.getParent(item);
            let path = [];
            while (treeItem) {
                path.unshift(treeItem.name);
                treeItem = this.getParent(treeItem);
            }
            return path.join(' / ');
        },
        filter(item) {
            if (!this.searchConfirm || this.searchConfirm.trim() === '') {
                return true;
            }
            if (this.searchConfirm === this.search) {
                let buildString = [...new Set(this.searchConfirm.split(' '))].filter((word) => word !== '').map((word) => word.toLowerCase());
                const text = Object.values(item)
                    .filter((data) => typeof data === 'string' || typeof data === 'number')
                    .map((data) => {
                        if (typeof data === 'number') {
                            return data.toString();
                        }
                        return data;
                    })
                    .join(' ')
                    .toLowerCase();
                return buildString.some((word) => text.includes(word));
            } else {
                return true;
            }
        },
        catalogOptions() {
            return {
                answerTimeLimit: 100,
                archive: false,
                archiveImmediately: false,
                archiveOnEndDate: false,
                archiveOnPass: false,
                onFailure: 'END',
                onFailureList: ['END', 'REPEAT'],
                passingQuote: 0,
                reopenKnowledgeBase: true,
                showExplanation: true,
                showKnowledgeBase: true,
                sortBy: 'NATURAL',
                sortList: ['DATE', 'NATURAL', 'RANDOM'],
                useSkipping: false,
                skippingPercentage: 1.0,
                skippingPercentageTolerance: 0.0
            };
        },
        handleSelected(value) {
            this.$emit('input', value);
        },
        isChecked({ id }) {
            return this.value.map((item) => item.id).includes(id);
        },
        getSelectedItemIndex({ id }) {
            return this.value.findIndex((item) => item.id === id) + 1;
        },
        handleCheck(event, item) {
            if (event) {
                if (this.hasCatalogOptions) {
                    this.$emit('input', [...this.value, { ...item, options: { ...this.catalogOptions() } }]);
                } else {
                    this.$emit('input', [...this.value, item]);
                }
            } else {
                this.$emit(
                    'input',
                    this.value.filter((val) => val.id !== item.id)
                );
            }
        },
        handleOpenTree(value) {
            this.openPath = value;
        },
        goUp() {
            if (this.hasParent) {
                if (this.isSelected) {
                    const selected = this.getParent(this.selectedItem[0]);
                    if (selected) {
                        this.computedSelectedItem = [selected];
                    }
                }
            } else {
                this.computedSelectedItem = [];
            }
        }
    },
    beforeMount() {
        this.SET_VIEW({ view: '', data: {}, type: '' });
        this.BUILD_MAP();
        this.LOAD_PERMISSIONS();
    },
    mounted() {
        this.$nextTick(() => (this.isLoaded = true));
    }
};
</script>

<style scoped>
.truncate-tree-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-treeview {
    height: 100%;
    width: max-content;
}
.truncate-path {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 7vw;
}
</style>
