import moment from 'moment';

const sortByKey = (items, index, isDesc, key) => {
    items.sort((a, b) => {
        if (!isDesc) {
            if (typeof b[key] === 'string' && typeof a[key] === 'string') {
                return b[key].localeCompare(a[key], undefined, {
                    numeric: true,
                    sensitivity: 'base'
                });
            } else {
                return b[key] - a[key];
            }
        }
        if (typeof b[key] === 'string' && typeof a[key] === 'string') {
            return a[key].localeCompare(b[key], undefined, {
                numeric: true,
                sensitivity: 'base'
            });
        } else {
            return a[key] - b[key];
        }
    });
    return items;
};

export default [
    {
        name: 'Nach Zuletzt Bearbeitet sortieren',
        sort: (items, index, isDesc) => {
            items.sort((a, b) => {
                if (!!a.lastModified && !!b.lastModified) {
                    if (isDesc) {
                        return moment(a.lastModified).valueOf() - moment(b.lastModified).valueOf();
                    } else {
                        return moment(b.lastModified).valueOf() - moment(a.lastModified).valueOf();
                    }
                }
                return 0;
            });
            return items;
        }
    },
    {
        name: 'Nach Aufgabenbezeichnung sortieren',
        sort: (items, index, isDesc) => sortByKey(items, index, isDesc, 'name')
    },
    {
        name: 'Nach Anfangsdatum sortieren',
        sort: (items, index, isDesc) => sortByKey(items, index, isDesc, 'startDate')
    },
    {
        name: 'Nach Enddatum sortieren',
        sort: (items, index, isDesc) => sortByKey(items, index, isDesc, 'endDate')
    }
];
