<template>
    <DeleteConfirm
        :selectedItem="getSelectedForum"
        :hasToolbar="false"
        :toolbarTitle="$t('Forum Eintrag löschen')"
        :deleteMessage="$t('Sind Sie sicher, dass Sie den folgenden Forumseintrag löschen wollen?')"
        :checkboxDeleteMessage="$t('Diese Aktion kann nicht rückgängig gemacht werden.')"
        @deleteItem="deleteForum"
        @closeEmit="close"
    />
</template>
<script>
import ForumMixin from './Mixins/ForumMixin';
import DeleteConfirm from '../Generic/DeleteConfirm';

export default {
    name: 'delete-forum',
    mixins: [ForumMixin],
    components: {
        DeleteConfirm
    },
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        close() {
            this.$emit('input', false);
        },
        deleteForum() {
            this.DELETE_FORUM(this.getSelectedForum).then(() => this.close());
        }
    }
};
</script>
