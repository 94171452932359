<template>
    <v-card>
        <v-toolbar dense flat dark color="primary">
            <v-toolbar-title>{{ $t('Wissenseinheit') }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col :xs="12" :sm="6">
                        <v-textarea :rows="1" no-resize readonly hide-details :value="knowledgeUnit.question" :label="$t('Frage')" />
                    </v-col>
                    <v-col :xs="12" :sm="6">
                        <v-text-field readonly hide-details :value="questionTypeText(knowledgeUnit.questionType)" :label="$t('Fragentyp')" />
                    </v-col>
                    <v-col :xs="12">
                        <resize-container v-model="dimensions">
                            <GChart type="BarChart" :class="{ dark: isDark }" :data="formatBarChartData" :options="barChartOptions" />
                        </resize-container>
                    </v-col>
                    <v-col :cols="12">
                        <v-expansion-panels accordion tile>
                            <v-expansion-panel v-for="(option, index) in value.knowledgeUnitDetails.knowledgeUnitOptions" :key="option.id">
                                <v-expansion-panel-header>{{ `${$t('Option')} ${index + 1}` }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-container>
                                        <v-row>
                                            <v-col :cols="6" v-if="option.text !== ''">
                                                <v-text-field readonly hide-details :value="option.text" :label="$t('Antwort')" />
                                            </v-col>
                                            <v-col :cols="6" v-if="option.maximum !== ''">
                                                <v-text-field readonly hide-details :value="option.maximum" :label="$t('Maximum')" />
                                            </v-col>
                                            <v-col :cols="6" v-if="option.minimum !== ''">
                                                <v-text-field readonly hide-details :value="option.minimum" :label="$t('Minimum')" />
                                            </v-col>
                                            <v-col :cols="6" v-if="option.steps !== ''">
                                                <v-text-field readonly hide-details :value="option.steps" :label="$t('Schritte')" />
                                            </v-col>
                                            <v-col :cols="6" v-if="option.sequenceNo !== ''">
                                                <v-text-field readonly hide-details :value="option.sequenceNo" :label="$t('Sequenz')" />
                                            </v-col>
                                            <v-col :cols="6">
                                                <v-switch readonly hide-details :input-value="option.isAnswer" :label="$t('Ist Antwort')" />
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
            </v-container>
            <!-- KNOWLEDGEBASE -->
        </v-card-text>
        <v-card-actions>
            <v-container grid-list-md>
                <v-row>
                    <v-col :cols="12" :sm="6" :md="6" :lg="6" :xl="6">
                        <v-btn
                            block
                            color="primary"
                            :disabled="knowledgeUnit.knowledgeBase.knowledgeContents.length === 0"
                            @click="showKnowledgeContents(knowledgeUnit.knowledgeBase.knowledgeContents)"
                            >{{ $t('Wissensbasis anzeigen') }}</v-btn
                        >
                    </v-col>
                    <v-col :cols="12" :sm="6" :md="6" :lg="6" :xl="6">
                        <v-btn
                            block
                            color="primary"
                            :disabled="knowledgeUnit.knowledgeExplanation.knowledgeContents.length === 0"
                            @click="showKnowledgeContents(knowledgeUnit.knowledgeExplanation.knowledgeContents)"
                            >{{ $t('Erklärung anzeigen') }}</v-btn
                        >
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
    </v-card>
</template>
<script>
import { GChart } from 'vue-google-charts';
import QuestionType from '../../../services/DataStructures/QuestionTypeCollection';
import ResizeContainer from '../../Generic/Resize/ResizeContainer.vue';

export default {
    name: 'knowledge-unit-chart-details',
    components: { GChart, ResizeContainer },
    data: () => ({
        dimensions: {
            height: 0,
            width: 0,
            deltaHeight: 0,
            deltaWidth: 0
        }
    }),
    props: {
        value: {
            type: Object
        }
    },
    computed: {
        knowledgeUnit() {
            return this.value.knowledgeUnitDetails;
        },
        formatBarChartData() {
            if (this.value.hasOwnProperty('votes')) {
                return [
                    [this.$t('Ergebnis'), this.$t('Richtig'), this.$t('Falsch'), this.$t('Unbeantwortet')],
                    [this.$t('Ergebnis'), this.value.votes.correct, this.value.votes.incorrect, this.value.votes.unanswered]
                ];
            }
            return [
                [this.$t('Ergebnis'), this.$t('Richtig'), this.$t('Falsch'), this.$t('Unbeantwortet')],
                [this.$t('Ergebnis'), 0, 0, 0]
            ];
        },
        barChartOptions() {
            return {
                title: this.$t('Intervallergebnis'),
                titleTextStyle: {
                    color: this.isDark ? '#FFF' : '#000'
                },
                width: this.dimensions.width,
                height: 200,
                isStacked: 'percent',
                legend: { position: 'top', maxLines: 3 },
                backgroundColor: this.isDark ? '#1E1E1E' : '#FFF',
                vAxis: {
                    format: 'percent',
                    titleTextStyle: { color: this.isDark ? '#FFF' : '#000' }
                },
                hAxis: {
                    minValue: 0,
                    format: 'percent',
                    ticks: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
                    titleTextStyle: { color: this.isDark ? '#FFF' : '#000' }
                },
                series: {
                    0: { color: this.$vuetify.theme.currentTheme.success },
                    1: { color: this.$vuetify.theme.currentTheme.error },
                    2: { color: this.$vuetify.theme.currentTheme.accent }
                },
                tooltip: { isHtml: this.isDark }
            };
        },
        isDark() {
            return this.$vuetify.theme.dark;
        }
    },
    methods: {
        showKnowledgeContents(knowledgeContents) {
            if (knowledgeContents.length > 0) {
                this.$emit('knowledgeContents', knowledgeContents);
            }
        },
        questionTypeText(type) {
            return this.$t(type);
        }
    }
};
</script>
