<template>
    <v-card>
        <v-data-iterator
            :items="groups"
            :no-data-text="$t('Keine Gruppen verfügbar')"
            :no-results-text="$t('Kein Ergebnis')"
            :items-per-page="-1"
            :footer-props="{
                'items-per-page-options': [{ text: $t('Alle'), value: -1 }]
            }"
            hideDefaultFooter
        >
            <template v-slot:item="{ item: { id, name, description } }">
                <v-list-item>
                    <v-list-item-action>
                        <v-simple-checkbox hide-details :value="inputModel(id)" @input="handleInput(id)" :ripple="false" />
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{ name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ description }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-data-iterator>
    </v-card>
</template>

<script>
import AdminGroupsMixin from '../Groups/Mixins/AdminGroupMixins';
import UserGroupsMixin from '../Groups/Mixins/UserGroupMixins';

export default {
    name: 'group-selector',
    mixins: [AdminGroupsMixin, UserGroupsMixin],
    data: () => ({
        search: '',
        searchConfirm: '',
        searchDebounce: null
    }),
    props: {
        value: {
            type: Array,
            default: () => []
        },
        showAdminGroups: {
            type: Boolean,
            default: false
        },
        showUserGroups: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedSearch: {
            get() {
                return this.search;
            },
            set(value) {
                this.search = value;
                if (value.trim() !== '') {
                    if (this.searchDebounce) {
                        clearTimeout(this.searchDebounce);
                    }
                    this.searchDebounce = setTimeout(() => {
                        this.searchConfirm = value;
                        this.searchDebounce = null;
                    }, 1500);
                } else {
                    this.searchConfirm = value;
                    clearTimeout(this.searchDebounce);
                    this.searchDebounce = null;
                }
            }
        },
        groups() {
            if (this.showAdminGroups && this.showUserGroups) {
                return [...this.getAdminGroups, ...this.getUserGroups].sort((a, b) =>
                    a.name.localeCompare(b.name, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    })
                );
            }
            if (this.showAdminGroups) {
                return [...this.getAdminGroups].sort((a, b) =>
                    a.name.localeCompare(b.name, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    })
                );
            }
            if (this.showUserGroups) {
                return [...this.getUserGroups].sort((a, b) =>
                    a.name.localeCompare(b.name, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    })
                );
            }
            return [];
        }
    },
    methods: {
        inputValueComparator(a) {
            return a;
        },
        handleInput(value) {
            if (this.value.map(({ id }) => id).includes(value)) {
                this.$emit(
                    'input',
                    this.value.filter((item) => item.id !== value)
                );
            } else {
                const item = this.getAdminGroup(value) || this.getUserGroup(value);
                this.$emit('input', [...this.value, item]);
            }
        },
        inputModel(id) {
            return this.value.map(({ id }) => id).includes(id);
        },
        debouncedFilter(items, search, filter) {
            if (!this.searchDebounce) {
                return items.filter((item) => filter(item, search));
            }
            return items;
        },
        filter(item, search) {
            if (!search || search.trim() === '') {
                return true;
            }
            let buildString = [...new Set(search.split(' '))].filter((word) => word !== '').map((word) => word.toLowerCase());
            const text = Object.values(item)
                .filter((data) => typeof data === 'string' || typeof data === 'number')
                .map((data) => {
                    if (typeof data === 'number') {
                        return data.toString();
                    }
                    return data;
                })
                .join(' ')
                .toLowerCase();
            return buildString.some((word) => text.includes(word));
        }
    },
    mounted() {
        if (this.showAdminGroups && this.getAdminGroups.length === 0) {
            this.GET_ADMIN_GROUPS();
        }
        if (this.showUserGroups && this.getUserGroups.length === 0) {
            this.GET_USER_GROUPS();
        }
    }
};
</script>
