<template>
    <v-menu v-model="menu" :disabled="disabled" :close-on-content-click="true" :nudge-width="300" offset-x :max-width="$vuetify.breakpoint.thresholds.xs">
        <template v-slot:activator="{ on }">
            <v-badge color="transparent">
                <template v-slot:badge>
                    <v-avatar v-if="selectable" v-ripple size="32" class="pointer no-highlight" @click="handleSelection">
                        <slot name="selection">
                            <v-icon medium :color="selectionColor">{{ selectedIcon }}</v-icon>
                        </slot>
                    </v-avatar>
                </template>
                <v-layout column justify-center align-center>
                    <v-btn fab depressed :small="small" :color="profileImage ? 'secondary' : ''" @click.stop @mousedown.stop>
                        <v-avatar :size="size" round v-on="on" color="grey lighten-1">
                            <ImageLoader :src="profileImage" v-if="profileImage" />
                            <span v-else>{{ userInitials }}</span>
                        </v-avatar>
                    </v-btn>
                    <div v-show="displayName" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 100px">
                        <span class="text-caption text-center-xs" style="text-overflow: ellipsis">{{ hasData ? value.displayName : '' }}</span>
                    </div>
                </v-layout>
            </v-badge>
        </template>
        <v-card v-if="hasData">
            <v-card-text>
                <v-layout column>
                    <v-sheet color="grey lighten-3" v-if="profileImage">
                        <ImageLoader :height="100" :src="profileImage" />
                    </v-sheet>
                    <v-text-field readonly hide-details attach :label="$t('Anzeigename')" :value="value.displayName || ''" />
                    <v-text-field readonly hide-details :label="$t('Vorname')" :value="value.firstName || ''" />
                    <v-text-field readonly hide-details :label="$t('Nachname')" :value="value.lastName || ''" />
                    <v-text-field readonly hide-details :label="$t('E-Mail')" :value="value.email || ''" />
                    <v-text-field readonly hide-details :label="$t('Personalnummer')" :value="value.personalNumber || ''" />
                </v-layout>
            </v-card-text>
            <v-card-actions v-if="hasSlot">
                <slot></slot>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
import ImageLoader from '../ImageLoader';
import UserMixin from '../../Users/Mixins/UserMixin';

export default {
    name: 'user-profile',
    mixins: [UserMixin],
    components: { ImageLoader },
    data: () => ({
        menu: false,
        permissionProfiles: []
    }),
    props: {
        selectable: {
            type: Boolean,
            default: false
        },
        isSelected: {
            type: Boolean,
            default: false
        },
        value: {
            type: Object,
            default: () => ({})
        },
        small: {
            type: Boolean,
            default: false
        },
        displayName: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hasData() {
            return Object.keys(this.value).length > 0;
        },
        selectionColor() {
            return this.isSelected ? 'primary' : '';
        },
        selectedIcon() {
            return this.isSelected ? this.$vuetify.icons.radioOn : this.$vuetify.icons.radioOff;
        },
        userInitials() {
            return this.value && this.value.firstName && this.value.lastName
                ? `${this.value.firstName.charAt(0).toUpperCase()}${this.value.lastName.charAt(0).toUpperCase()}`
                : '';
        },
        hasSlot() {
            return !!this.$slots.default;
        },
        profileImage() {
            return this.value && this.value.profileImage ? this.value.profileImage : '';
        },
        size() {
            if (this.small) {
                return 32;
            }
            return 48;
        }
    },
    methods: {
        handleSelection() {
            if (this.isSelected) {
                this.$emit('selected', {});
            } else {
                this.$emit('selected', this.value);
            }
        }
    }
};
</script>
