<template>
    <v-card height="100%">
        <v-toolbar dark text color="secondary">
            <v-toolbar-title>{{ $t('Gesamt beantwortet') }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="toggleChartType">
                <v-icon>{{ iconComputed }}</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <v-container>
                <v-row align="center">
                    <v-col :cols="12" :sm="6">
                        <GChart :type="chartType" :options="calculateChartOptions" :data="chartData" />
                    </v-col>
                    <v-col :cols="12" :sm="6">
                        <v-list dense>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-chip :color="chipClassColor('TRUE')">
                                        {{ $t('Richtig') }}
                                    </v-chip>
                                </v-list-item-content>
                                <v-list-item-action>
                                    {{ correctVotes }}
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-chip :color="chipClassColor('FALSE')">
                                        {{ $t('Falsch') }}
                                    </v-chip>
                                </v-list-item-content>
                                <v-list-item-action>
                                    {{ incorrectVotes }}
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider class="ma-2" />
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ $t('Gesamt') }}
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    {{ totalVotes }}
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
import { GChart } from 'vue-google-charts';
import DashboardMixin from './Mixins/DashboardMixin';

export default {
    name: 'vote-tally',
    components: { GChart },
    mixins: [DashboardMixin],
    data: () => ({
        chartType: 'PieChart'
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        colors() {
            return this.$vuetify.theme.currentTheme;
        },
        iconComputed() {
            if (this.chartType == 'PieChart') return 'mdi-chart-bar';
            else return 'mdi-chart-pie';
        },
        correctVotes() {
            if (this.value.hasOwnProperty('mapVotes')) {
                return this.value.mapVotes.CORRECT;
            }
            return 0;
        },
        incorrectVotes() {
            if (this.value.hasOwnProperty('mapVotes')) {
                return this.value.mapVotes.INCORRECT;
            }
            return 0;
        },
        totalVotes() {
            if (this.value.hasOwnProperty('totalVotes')) {
                return this.value.totalVotes;
            }
            return 0;
        },
        calculateChartOptions() {
            return {
                width: 300,
                height: 300,
                legend: 'none',
                is3D: false,
                backgroundColor: this.isDark ? '#1E1E1E' : '#FFF',
                vAxis: {
                    title: this.$t('Wissenseinheiten beantwortet')
                },
                colors: [this.colors.success, this.colors.error],
                animation: { startup: true, duration: 1000, easing: 'out' }
            };
        },
        chartData() {
            return [
                [this.$t('Antworten'), this.$t('Anzahl'), { role: 'style' }],
                [this.$t('Richtig'), this.correctVotes, this.colors.success],
                [this.$t('Falsch'), this.incorrectVotes, this.colors.error]
            ];
        },
        isDark() {
            return this.$vuetify.theme.dark;
        }
    },
    methods: {
        chipClassColor(value) {
            if (value == 'TRUE') {
                return 'success';
            } else if (value == 'FALSE') {
                return 'error';
            }
        },
        toggleChartType() {
            this.chartType === 'PieChart' ? (this.chartType = 'ColumnChart') : (this.chartType = 'PieChart');
        }
    }
};
</script>
