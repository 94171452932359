import { mapGetters, mapActions } from 'vuex';
import { renderByMathjax } from 'mathjax-vue';

const state = {
    renderLatex: true
};

export default {
    computed: {
        ...mapGetters('General', ['getLoggedInAdmin']),
        ...mapGetters('NavigationOperations', ['getDrawerState'])
    },
    updated() {
        if (state.renderLatex) {
            const el = document.getElementById('app');
            renderByMathjax(el);
        }
    },
    methods: {
        ...mapActions('PermissionProfiles', ['CHECK_PERMISSIONS']),
        ...mapActions('NavigationOperations', ['OPEN_DRAWER']),
        formatBytes(a, b = 2) {
            if (0 === a) return '0 Bytes';
            const c = 0 > b ? 0 : b,
                d = Math.floor(Math.log(a) / Math.log(1024));
            return parseFloat((a / Math.pow(1024, d)).toFixed(c)) + ' ' + ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d];
        },
        hasOwn(obj, key) {
            return Object.prototype.hasOwnProperty.call(obj, key);
        },
        validateEditorState(object) {
            if (typeof object === 'object' && !Array.isArray(object)) {
                return Promise.all(
                    Object.entries(object).map(([key, value]) => {
                        if (Array.isArray(value)) {
                            const bools = value.filter((permission) => typeof permission === 'boolean').reduce((a, b) => a && b, true);
                            const permissions = value.filter((permission) => typeof permission === 'string');
                            if (permissions.length > 0) {
                                return this.CHECK_PERMISSIONS({
                                    permissions: permissions,
                                    userId: this.getLoggedInAdmin.id
                                })
                                    .then((response) => response)
                                    .then((response) => ({
                                        [key]: response && bools
                                    }));
                            } else {
                                return { [key]: bools };
                            }
                        }
                        if (typeof value === 'boolean') {
                            return { [key]: value };
                        }
                        return { [key]: false };
                    })
                ).then((result) => result.reduce((obj, item) => Object.assign(obj, item), {}));
            } else {
                throw Error('Invalid Type. Must be of type Object');
            }
        },
        blockLatexRendering() {
            state.renderLatex = false;
        },
        activateLatexRendering() {
            state.renderLatex = true;
        }
    }
};
