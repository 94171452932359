<template>
    <v-card :class="cardStyle" @click="selectCard">
        <v-toolbar dense flat dark color="primary">
            <v-toolbar-title>{{ `${$t('Bewertung')} - ${value.name}` }}</v-toolbar-title>
        </v-toolbar>
        <v-container>
            <v-row no-gutters>
                <v-col :cols="12">
                    <v-textarea
                        readonly
                        no-resize
                        outlined
                        :value="value.description ? value.description : ' '"
                        :label="$t('Bewertungsbeschreibung')"
                        :rows="3"
                        hide-details
                    />
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col :cols="12">
                    <v-data-table
                        :items="sortedGrades"
                        :no-data-text="$t('Keine Bewertungen verfügbar')"
                        :no-results-text="$t('Kein Ergebnis')"
                        :hideDefaultFooter="true"
                        :items-per-page="-1"
                        :footer-props="{
                            'items-per-page-text': $t('Bewertungen pro Seite'),
                            'items-per-page-options': [{ text: $t('Alle'), value: -1 }]
                        }"
                        :headers="headers"
                    >
                        <template #item.percent="{ item: { percent } }">
                            <span>{{ `${convertPercent(percent)}%` }}</span>
                        </template>
                        <template #item.name="{ item: { text } }">
                            <span>{{ text }}</span>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import CourseMixin from '../../Mixins/CourseMixin';

export default {
    name: 'grading-table',
    mixins: [CourseMixin],
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        isAdmin: {
            type: Boolean,
            default: false
        },
        selected: {
            type: Object,
            default: () => ({})
        },
        selectable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        headers() {
            return [
                {
                    text: this.$t('Prozent'),
                    value: 'percent',
                    sortable: false,
                    width: '50%'
                },
                {
                    text: this.$t('Bewertung'),
                    value: 'name',
                    sortable: false,
                    width: '50%'
                }
            ];
        },
        sortedGrades() {
            const grades = Object.entries(this.value.percentToGradeMap).reduce((acc, [key, value]) => {
                acc.push({ percent: key, text: value });
                return acc;
            }, []);
            grades.sort(({ percent: a }, { percent: b }) => parseFloat(a) - parseFloat(b));
            return grades;
        },
        isCardSelected() {
            if (this.getSelectedGradingTable.hasOwnProperty('id') && this.value.hasOwnProperty('id')) {
                return this.getSelectedGradingTable.id === this.value.id;
            }
            return false;
        },
        cardStyle() {
            return {
                'elevation-12': this.isCardSelected,
                pointer: this.selectable
            };
        },
        isComparable() {
            return (
                this.getSelectedGradingTable !== null &&
                this.getSelectedGradingTable !== undefined &&
                this.value !== null &&
                this.value !== undefined &&
                this.value.hasOwnProperty('id') &&
                this.getSelectedGradingTable.hasOwnProperty('id')
            );
        },
        isSelected() {
            if (this.isComparable) {
                return this.value.id === this.getSelectedGradingTable.id;
            }
            return false;
        }
    },
    methods: {
        convertPercent(number) {
            return Number((number * 100).toFixed(2));
        },
        selectCard() {
            this.$emit('selected', this.value);
            this.$emit('input', this.value);
        },
        handleCourseSelection() {
            if (this.isComparable) {
                if (this.value.id === this.getSelectedGradingTable.id) {
                    this.$emit('input', {});
                } else {
                    this.$emit('input', this.value);
                }
            } else {
                this.$emit('input', this.value);
            }
        }
    }
};
</script>

<style scoped>
.limit {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
</style>
