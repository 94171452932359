<template>
    <v-card height="100%">
        <v-container>
            <v-row class="mb-1">
                <v-col :cols="4">
                    {{ $t('Scorm') }}
                </v-col>
                <v-col :cols="4">
                    {{ $t('Lernende') }}
                </v-col>
                <v-col :cols="4" class="text-center">
                    {{ $t('Bestätigen') }}
                </v-col>
            </v-row>
            <v-divider />
            <v-row v-for="item in getOpenRequests" :key="item.id" align="center" class="mt-3">
                <v-col :cols="4">
                    {{ item.scormPackageCustomerResponse.originalTitle }}
                </v-col>
                <v-col :cols="4">
                    <UserProfile :value="item.user" />
                </v-col>
                <v-col :cols="2">
                    <v-card-actions>
                        <v-btn color="success" block @click="submit(item.id, true)">{{ $t('Annehmen') }}</v-btn>
                    </v-card-actions>
                </v-col>
                <v-col :cols="2">
                    <v-card-actions>
                        <v-btn color="error" block @click="submit(item.id, false)"> {{ $t('Ablehnen') }} </v-btn>
                    </v-card-actions>
                </v-col>
            </v-row>
            <v-row v-for="item in getBuyRequests" :key="item.scormPackage.id" align="center" class="mt-3">
                <v-col :cols="4">
                    {{ item.scormPackage.originalTitle }}
                </v-col>
                <v-col :cols="4">
                    <UserProfile v-for="singleUser in item.user" :value="singleUser" :key="singleUser.id" />
                </v-col>
                <v-col :cols="2">
                    <v-card-actions>
                        <v-btn color="success" block @click="submitBuyRequest(item.scormPackage.id, true)">{{ $t('Kaufen') }}</v-btn>
                    </v-card-actions>
                </v-col>
                <v-col :cols="2">
                    <v-card-actions>
                        <v-btn color="error" block @click="submitBuyRequest(item.scormPackage.id, false)"> {{ $t('Ablehnen') }} </v-btn>
                    </v-card-actions>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import ScormMixin from './Mixins/ScormMixin';
import UserProfile from '../Generic/Profile/UserProfile';
export default {
    name: 'scorm-requests',
    components: { UserProfile },
    mixins: [ScormMixin],
    beforeMount() {
        this.GET_OPEN_REQUESTS();
        this.GET_OPEN_BUY_REQUESTS();
    },
    methods: {
        submit(id, approved) {
            this.CHANGE_STATE({ id: id, accepted: approved });
        },
        submitBuyRequest(id, approved) {
            let scorm = this.getScormList.filter((scorm) => scorm.id == id)[0];
            if (approved && scorm) {
                this.SET_VIEW({ component: 'buyScorm', type: '', data: this.getSelectedScorm, tabs: 0 });
                this.SET_SELECTED_SCORM(scorm);
                this.getView.data = this.getSelectedScorm;
                return;
            }
            this.DECLINE_BUY_REQUESTS({ id: id });
        }
    }
};
</script>
