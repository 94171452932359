import { mapActions, mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('GroupForumOperations', [
            'getGroups',
            'getForums',
            'getThreads',
            'getAnswers',
            'getSelectedGroup',
            'getSelectedForum',
            'getSelectedThread',
            'getSelectedAnswer',
            'getForumPath'
        ])
    },
    methods: {
        ...mapActions('GroupForumOperations', [
            'ADD_FORUM_PATH',
            'REMOVE_FORUM_PATH',
            'SET_SELECTED_GROUP',
            'SET_SELECTED_FORUM',
            'SET_SELECTED_THREAD',
            'SET_SELECTED_ANSWER',
            'GET_GROUPS',
            'GET_FORUMS',
            'CREATE_FORUM',
            'UPDATE_FORUM',
            'DELETE_FORUM',
            'GET_THREADS',
            'CREATE_THREAD',
            'UPDATE_THREAD',
            'DELETE_THREAD',
            'GET_ANSWERS',
            'CREATE_ANSWER',
            'UPDATE_ANSWER',
            'DELETE_ANSWER'
        ])
    }
};
