export class Storage {
    static currentStorage() {
        if (this.isStayLoggedIn()) {
            return window.localStorage;
        }
        return window.sessionStorage;
    }

    static isStayLoggedIn() {
        return JSON.parse(window.localStorage.getItem('stayLoggedIn'));
    }

    static getData(key) {
        return this.currentStorage().getItem(key);
    }

    static setData(key, data) {
        this.currentStorage().setItem(key, data);
    }

    static removeData(key) {
        this.currentStorage().removeItem(key);
    }

    static localSave(key, data) {
        window.localStorage.setItem(key, data);
    }

    static saveTokenExpireDate() {
        if (this.isStayLoggedIn()) {
            const date = new Date();
            const expireDate = date.setDate(date.getDate() + 13);
            this.setData('expireDate', expireDate);
        }
    }

    static checkValidToken(to) {
        const tokenExist = this.getData('token') && !to.params.invalidToken;
        const expireDate = JSON.parse(this.getData('expireDate'));
        const currentDate = new Date().getTime();
        return tokenExist && expireDate > currentDate;
    }
}
