<template>
    <v-card height="100%">
        <v-toolbar dark text color="secondary">
            <v-toolbar-title>{{ $t('Nutzer Aktivität') }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text ref="test">
            <v-layout column>
                <v-flex>
                    <v-form v-model="validate">
                        <v-text-field
                            color="accent"
                            type="number"
                            v-model="computedDay"
                            @keydown.enter.stop
                            @change="(data) => (computedDay = data)"
                            :rules="[rangeCheck]"
                            :hint="$t('Geben Sie eine Zahl ein, um die Nutzer Aktivität der letzten X Tage zu sehen')"
                            :label="$t('Aktivitätsspanne')"
                            persistent-hint
                        />
                    </v-form>
                    <v-layout column align-center justify-center>
                        <GChart type="LineChart" :options="chartOptions" :data="chartData()" :class="{ dark: this.isDark }" v-if="showChart" />
                    </v-layout>
                </v-flex>
                <v-flex>
                    <v-list dense>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ $t('Admin') }}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                {{ totalAdmins }}
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ $t('Benutzer') }}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                {{ totalUsers }}
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ $t('Gesamt') }}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                {{ total }}
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-flex>
            </v-layout>
        </v-card-text>
    </v-card>
</template>

<script>
import { GChart } from 'vue-google-charts';
import DashboardResizeObserverMixin from './Mixins/DashboardResizeObserverMixin';

export default {
    name: 'user-activity-tally',
    components: { GChart },
    mixins: [DashboardResizeObserverMixin],
    data: () => ({
        validate: false,
        chartOptions: {},
        showChart: true
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        days: {
            type: Number,
            default: 30
        }
    },
    computed: {
        rangeCheck() {
            return (v) => (v > 30 && v !== null && v !== undefined) || this.$t('Bitte wählen Sie eine Zahl größer 30');
        },
        total() {
            if (this.value.hasOwnProperty('totalUsers')) {
                return this.value.totalUsers;
            }
            return 0;
        },
        totalAdmins() {
            if (this.value.hasOwnProperty('userRolesMap')) {
                return this.value.userRolesMap.ADMIN;
            }
            return 0;
        },
        totalUsers() {
            if (this.value.hasOwnProperty('userRolesMap')) {
                return this.value.userRolesMap.USER;
            }
            return 0;
        },
        computedDay: {
            get() {
                return this.days;
            },
            set(val) {
                if (this.validate && Number(val) > 30) {
                    this.$emit('days', val);
                }
            }
        },
        isDark() {
            return this.$vuetify.theme.dark;
        }
    },
    watch: {
        isDark() {
            this.showChart = false;
            this.$nextTick(() => {
                setTimeout(() => {
                    this.showChart = true;
                }, 300);
            });
        }
    },
    methods: {
        getSizeX() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 300;
                case 'sm':
                    return 500;
                case 'md':
                    return 800;
                case 'lg':
                    return 900;
                case 'xl':
                    return 1000;
            }
        },
        getSizeY() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 200;
                case 'sm':
                    return 275;
                case 'md':
                    return 350;
                case 'lg':
                    return 425;
                case 'xl':
                    return 600;
            }
        },
        calculateChartOptions() {
            let width = this.$refs.test;
            if (width !== undefined) {
                width = width.clientWidth * 0.94;
            } else {
                width = this.getSizeX();
            }
            return {
                width: width,
                height: this.getSizeY(),
                legend: 'none',
                backgroundColor: this.isDark ? '#1E1E1E' : '#FFF',
                vAxis: {
                    title: this.$t('Log Ins'),
                    titleTextStyle: { color: this.isDark ? '#FFF' : '#000' }
                },
                animation: { startup: true, duration: 500, easing: 'out' }
            };
        },
        chartData() {
            if (!this.value.hasOwnProperty('activeUsersMap')) {
                return [[this.$t('Tage'), this.$t('aktive Nutzer')]];
            }
            return [
                [this.$t('Tage'), this.$t('aktive Nutzer')],
                [`1 ${this.$t('Tag')}`, this.value.activeUsersMap['1DAY']],
                [`7 ${this.$t('Tage')}`, this.value.activeUsersMap['7DAYS']],
                [`30 ${this.$t('Tage')}`, this.value.activeUsersMap['30DAYS']],
                [`${this.days} ${this.$t('Tage')}`, this.value.activeUsersMap['CUSTOM']]
            ];
        },
        onResize() {
            this.chartOptions = this.calculateChartOptions();
        }
    },
    mounted() {
        this.onResize();
        this.registerResizeElement(this.$refs.test, this.onResize);
    }
};
</script>
