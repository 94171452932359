import axios from '../../plugins/Axios/axios';

const state = {
    adminCatalogPermissionList: [],
    selectedCatalog: {
        itemType: 'ADMINCATALOGPERMISSION',
        itemTitle: 'Admin Catalog Permission',
        path: '/Catalog',
        data: {}
    }
};

// getters
const getters = {
    getAdminCatalogPermissionList(state) {
        return state.adminCatalogGroupPermissionList;
    }
};

// actions
const actions = {
    GET_ADMINCATALOGGROUPPERMISSION_LIST({ commit }) {
        return axios.get(`admins?page=0`).then((response) => {
            commit('updateAdminCatalogGroupPermissionList', response.data.data.admins);
            return response;
        });
    }
};

// mutations
const mutations = {
    clear: (state) => {
        state.adminCatalogPermissionList = [];
        state.selectedCatalog = {
            itemType: 'ADMINCATALOGPERMISSION',
            itemTitle: 'Admin Catalog Permission',
            path: '/Catalog',
            data: {}
        };
    },
    updateAdminCatalogGroupPermissionList: (state, adminList) => {
        state.adminList = adminList;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
