import axios from '../../../plugins/Axios/axios';
import Vue from 'vue';

const state = {
    catalogSubGroupList: [],
    selectedCatalogSubGroup: {
        itemType: 'CATALOGSUBGROUP',
        itemTitle: 'Kataloguntergruppe',
        path: '/Authoringtool_CatalogSubGroup',
        data: {}
    }
};

// getters
const getters = {
    getCatalogSubGroupList(state) {
        return state.catalogSubGroupList;
    },
    getSelectedCatalogSubGroup(state) {
        return state.selectedCatalogSubGroup;
    }
};

// actions
const actions = {
    ADD_CATALOGSUBGROUP({ commit, dispatch, rootGetters }, request) {
        const { id: catalogGroupId } = rootGetters['CatalogGroupOperations/getSelectedCatalogGroup'].data;
        return axios
            .post(`cataloggroups/${catalogGroupId}/catalogsubgroups`, request)
            .then((response) => response.data.data)
            .then((response) => {
                const catalogSubgroups = rootGetters['AuthoringToolOperations/getMapData'](catalogGroupId);
                commit('AuthoringToolOperations/updateMap', { [catalogGroupId]: [...catalogSubgroups, response] }, { root: true });
                dispatch(
                    'AuthoringToolOperations/LOAD_PERMISSIONS',
                    {
                        catalogGroup: { id: catalogGroupId },
                        catalogSubgroup: response
                    },
                    { root: true }
                );
                commit('updateCatalogSubGroupList', { ...response });
                // commit('updateSelectedCatalogSubgroup', response);
                return response;
            });
    },
    DELETE_CATALOGSUBGROUP({ commit, rootGetters }, { id: catalogSubgroupId }) {
        const { id: catalogGroupId } = rootGetters['CatalogGroupOperations/getSelectedCatalogGroup'].data;
        return axios.delete(`cataloggroups/${catalogGroupId}/catalogsubgroups/${catalogSubgroupId}`).then((response) => {
            const catalogSubgroups = rootGetters['AuthoringToolOperations/getMapData'](catalogGroupId).filter(
                (catalogGroup) => catalogGroup.id !== catalogSubgroupId
            );
            commit('AuthoringToolOperations/updateMap', { [catalogGroupId]: catalogSubgroups }, { root: true });
            commit('deleteCatalogSubGroup', catalogSubgroupId);
            // commit('updateSelectedCatalogSubgroup', {});
            return response;
        });
    },
    GET_CATALOGSUBGROUP_LIST({ commit }, catalogGroup) {
        return axios.get(`cataloggroups/${catalogGroup.id}/catalogsubgroups`).then((response) => {
            commit('updateCatalogSubGroupList', response.data.data.subGroups);
            return response;
        });
    },
    GET_SINGLE_CATALOGSUBGROUP({ commit }, request) {
        return axios.get(`cataloggroups/${request.catalogGroupId}/catalogsubgroups/${request.catalogSubgroupId}`).then((response) => {
            commit('updateCatalogSubGroupList', response.data.data);
            return response;
        });
    },
    SELECT_CATALOGSUBGROUP({ commit }, selectedCatalogSubGroup) {
        commit('updateSelectedCatalogSubgroup', selectedCatalogSubGroup);
    },
    UPDATE_CATALOGSUBGROUP({ commit, rootGetters }, { id: catalogSubgroupId, ...request }) {
        const { id: catalogGroupId } = rootGetters['CatalogGroupOperations/getSelectedCatalogGroup'].data;
        return axios
            .patch(`/cataloggroups/${catalogGroupId}/catalogsubgroups/${catalogSubgroupId}`, request)
            .then((response) => response.data.data)
            .then((response) => {
                const catalogSubgroups = rootGetters['AuthoringToolOperations/getMapData'](catalogGroupId).map((catalogSubgroup) => {
                    if (catalogSubgroup.id === response.id) {
                        return response;
                    }
                    return catalogSubgroup;
                });
                commit('AuthoringToolOperations/updateMap', { [catalogGroupId]: catalogSubgroups }, { root: true });
                commit('updateCatalogSubGroupList', { ...response });
                // commit('updateSelectedCatalogSubgroup', response);
                return response;
            });
    }
};

// mutations
const mutations = {
    clear: (state) => {
        state.catalogSubGroupList = [];
        state.selectedCatalogSubGroup = {
            itemType: 'CATALOGSUBGROUP',
            itemTitle: 'Kataloguntergruppe',
            path: '/Authoringtool_CatalogSubGroup',
            data: {}
        };
    },
    updateCatalogSubGroupList: (state, catalogSubGroupList) => {
        if (Array.isArray(catalogSubGroupList)) {
            state.catalogSubGroupList = catalogSubGroupList;
        } else {
            if (state.catalogSubGroupList.map(({ id }) => id).includes(catalogSubGroupList.id)) {
                Vue.set(
                    state,
                    'catalogSubGroupList',
                    state.catalogSubGroupList.map((group) => {
                        if (group.id === catalogSubGroupList.id) {
                            return catalogSubGroupList;
                        }
                        return group;
                    })
                );
            } else {
                Vue.set(state, catalogSubGroupList, [...state.catalogSubGroupList, catalogSubGroupList]);
            }
        }
    },
    updateSelectedCatalogSubgroup: (state, selectedCatalogSubGroup) => {
        state.selectedCatalogSubGroup.data = selectedCatalogSubGroup;
    },
    deleteCatalogSubGroup: (state, groupId) => {
        const index = state.catalogSubGroupList.map(({ id }) => id).findIndex((id) => id === groupId);
        if (index !== -1) {
            Vue.delete(state.catalogSubGroupList, index);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
