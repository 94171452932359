<template>
    <v-dialog v-model="showModal" persistent :width="$vuetify.breakpoint.thresholds.sm">
        <template #activator="{ on, attrs }">
            <v-tooltip bottom>
                <template #activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                    <v-btn icon v-on="{ ...on, ...tooltipOn }" v-bind="{ ...attrs, ...tooltipAttrs }" :disabled="!isSelected" color="error">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('Bewertungstabelle löschen') }}</span>
            </v-tooltip>
        </template>
        <DeleteConfirm
            :hasToolbar="true"
            :toolbarTitle="$t('Bewertungstabelle löschen')"
            :deleteMessage="$t('Sind Sie sicher, dass Sie diese Bewertungstabelle löschen möchten?')"
            :checkboxDeleteMessage="$t('Diese Aktion kann nicht rückgängig gemacht werden.')"
            @deleteItem="deleteGradingTable"
            @closeEmit="close"
        />
    </v-dialog>
</template>
<script>
import CourseMixin from './Mixins/CourseMixin';
import DeleteConfirm from '../../Generic/DeleteConfirm';

export default {
    name: 'delete-grading-table',
    mixins: [CourseMixin],
    components: {
        DeleteConfirm
    },
    data: () => ({
        showModal: false
    }),
    computed: {
        isSelected() {
            return Object.keys(this.getSelectedGradingTable).length > 0;
        }
    },
    methods: {
        close() {
            this.showModal = false;
        },
        deleteGradingTable() {
            this.DELETE_GRADING_TABLE(this.getSelectedGradingTable).then(() => this.close());
        }
    }
};
</script>
