import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters('KnowledgeContentOperations', ['getMapData', 'getParent', 'getTree', 'getView', 'getKnowledgeContents'])
    },
    methods: {
        ...mapActions('KnowledgeContentOperations', [
            'CREATE_KNOWLEDGE_CONTENT',
            'UPDATE_KNOWLEDGE_CONTENT',
            'COPY_KNOWLEDGE_CONTENT',
            'MOVE_KNOWLEDGE_CONTENT',
            'DELETE_KNOWLEDGE_CONTENT',
            'GET_KNOWLEDGE_CONTENTS',
            'GET_KNOWLEDGE_CONTENT',
            'CREATE_KNOWLEDGE_CONTENT_FOLDER',
            'UPDATE_KNOWLEDGE_CONTENT_FOLDER',
            'MOVE_KNOWLEDGE_CONTENT_FOLDER',
            'DELETE_KNOWLEDGE_CONTENT_FOLDER',
            'UPDATE_KNOWLEDGE_CONTENT_STATE',
            'SET_VIEW'
        ]),
        isKnowledgeContentType(value) {
            if (value.hasOwnProperty('title')) {
                return 'KNOWLEDGE_CONTENT';
            }
            if (value.hasOwnProperty('name')) {
                return 'FOLDER';
            }
            return undefined;
        },
        calculateTreePath(value) {
            let currentNode = value;
            let path = [];
            while (currentNode) {
                path.unshift({ ...currentNode });
                currentNode = this.getParent(currentNode);
            }
            return path;
        }
    }
};
