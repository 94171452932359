<template>
    <v-layout :wrap="$vuetify.breakpoint.smAndDown">
        <v-flex>
            <v-data-iterator
                :items="contents"
                :no-data-text="$t('Kein Content verfügbar')"
                :no-results-text="$t('Kein Ergebnis')"
                :footer-props="{
                    'items-per-page-text': $t('Content pro Seite'),
                    'items-per-page-options': [12, 24, 48, { text: $t('Alle'), value: -1 }]
                }"
                :items-per-page="12"
                :hideDefaultFooter="contents.length === 0"
                :options.sync="pagination"
                row
                wrap
            >
                <template v-slot:item="items">
                    <pill
                        @click.native="markContent(items.item)"
                        fileType="Content"
                        :label="items.item.title"
                        :isFile="true"
                        :selectable="selectable"
                        :value="items.item"
                        @input="selectContent"
                    >
                        <v-chip :disabled="true" :value="false" outlined dark>
                            {{ $t(items.item.state.stateName) }}
                        </v-chip>
                    </pill>
                </template>
            </v-data-iterator>
        </v-flex>

        <v-dialog v-model="showModal" scrollable persistent :fullscreen="$vuetify.breakpoint.xs" :width="$vuetify.breakpoint.thresholds.sm">
            <v-card>
                <v-toolbar dense text color="secondary" dark>
                    <v-toolbar-title>{{ $t('Content') }}</v-toolbar-title>
                    <v-spacer />
                    <v-btn icon @click="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text v-html="selectedContent"></v-card-text>
            </v-card>
        </v-dialog>
    </v-layout>
</template>
<script>
import Pill from '../../Generic/Pill';

export default {
    name: 'course-content',
    components: { Pill },
    data: () => ({
        pagination: {},
        showModal: false,
        selectedContent: {}
    }),
    props: {
        selectable: {
            type: Boolean,
            default: false
        },

        contents: {
            type: Array,
            default: () => []
        }
    },

    methods: {
        close() {
            this.showModal = false;
        },
        selectContent(content) {
            if (content.content.includes('image')) {
                this.selectedContent = content.content.split('img ').join(`img width:"100%" height:"500" `);
                this.showModal = true;
            } else {
                this.selectedContent = content.content;

                this.showModal = true;
            }
        }
    }
};
</script>
