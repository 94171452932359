<template>
    <v-dialog v-model="toggleModal" persistent :width="$vuetify.breakpoint.thresholds.xs">
        <v-card>
            <v-toolbar dense text dark color="primary">
                <v-toolbar-title>{{ $t('Vorlage löschen') }}</v-toolbar-title>
                <v-spacer />
                <v-btn text icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>{{ $t(`Sind Sie sicher, dass Sie ${value.name} löschen wollen?`) }}</v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="deleteTemplate">{{ $t('Löschen') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    name: 'delete-scheduled-template-type',
    props: {
        value: { type: Object },
        modal: { type: Boolean, default: () => false }
    },
    computed: {
        toggleModal: {
            get() {
                return this.modal;
            },
            set(value) {
                this.$emit('toggleModal', value);
            }
        }
    },
    methods: {
        ...mapActions('SystemSettings', ['DELETE_SCHEDULED_MAIL_TEMPLATE', 'GET_SCHEDULED_TEMPLATES']),
        close() {
            this.$emit('toggleModal', false);
        },
        deleteTemplate() {
            this.DELETE_SCHEDULED_MAIL_TEMPLATE(this.value).then(() => this.GET_SCHEDULED_TEMPLATES());
            this.close();
            this.$emit('input', {});
        }
    }
};
</script>
