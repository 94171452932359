<template>
    <v-dialog v-model="dialog" scrollable persistent :fullscreen="$vuetify.breakpoint.xs" :width="$vuetify.breakpoint.thresholds.sm">
        <WikiProposalsCore :value="value" @close="handleClose" />
    </v-dialog>
</template>

<script>
import WikiProposalsCore from './WikiProposalsCore';

export default {
    name: 'wiki-proposals-dialog',
    components: { WikiProposalsCore },
    data: () => ({
        dialog: false
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        value(value) {
            if (Object.keys(value).length > 0) {
                this.dialog = true;
            }
        }
    },
    methods: {
        handleClose() {
            this.dialog = false;
            this.$emit('input', {});
        }
    }
};
</script>
