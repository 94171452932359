<template>
    <v-container>
        <v-data-iterator
            :items="children"
            :no-data-text="$t('Keine Medien verfügbar')"
            :no-results-text="$t('Kein Ergebnis')"
            :footer-props="{
                'items-per-page-text': $t('Medien pro Seite'),
                'items-per-page-options': [{ value: -1, text: $t('Alle') }]
            }"
            :items-per-page="-1"
            :custom-filter="filter"
            :search="search"
            hideDefaultFooter
        >
            <template #header>
                <v-text-field :label="$t('Suchen')" v-model="search" hide-details />
            </template>
            <template #default="{ items }">
                <v-list dense>
                    <v-list-item-group v-model="computedSelect" color="primary">
                        <v-list-item
                            v-for="item in items"
                            :key="item.id"
                            @click="onClick(item)"
                            draggable
                            @dragover.prevent
                            @dragenter.prevent
                            @dragleave.prevent
                            @dragstart="startDrag($event, item)"
                            @dragend="endDrag"
                            @drop="dropDrag($event, item)"
                        >
                            <v-list-item-action>
                                <v-icon :color="item.color">
                                    {{ icon(item) }}
                                </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.name || item.fileName || item.text }}</v-list-item-title>
                                <v-list-item-subtitle>{{ formattedSubtext(item) }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action v-if="selectable && isFilemanagerType(item) === 'FILE'">
                                <v-btn icon @click.stop="handleMediaSelect(item)">
                                    <v-icon>mdi-image-move</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </template>
        </v-data-iterator>
    </v-container>
</template>
<script>
import FilemanagerMixin from '../../Mixins/FilemanagerPrototypeMixin';
import moment from 'moment';

export default {
    name: 'filemanager-list',
    mixins: [FilemanagerMixin],
    data: () => ({
        search: '',
        selectedItem: undefined
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        media: {
            type: Object,
            default: () => ({})
        },
        selectable: {
            type: Boolean,
            default: false
        },
        fileType: {
            type: String,
            default: ''
        }
    },
    watch: {
        value() {
            this.$nextTick(() => (this.selectedItem = undefined));
        },
        media(value) {
            const index = this.children.findIndex(({ id }) => id === value.id);
            if (index !== -1) {
                this.$nextTick(() => (this.selectedItem = index));
            } else {
                this.$nextTick(() => (this.selectedItem = undefined));
            }
        }
    },
    computed: {
        computedSelect: {
            get() {
                if (this.selectedItem == 0) {
                    return undefined;
                }
                return this.selectedItem;
            },
            set(value) {
                if (value != 0) {
                    this.selectedItem = value;
                }
            }
        },
        children() {
            if (this.value.children) {
                const parent = this.getParent(this.value);
                return [
                    { id: '', text: this.$t('Übergeordneter Ordner'), icon: 'mdi-folder-upload' },
                    ...this.value.children,
                    ...this.getFolderMedias(this.value).sort((a, b) =>
                        a.fileName.localeCompare(b.fileName, undefined, {
                            numeric: true,
                            sensitivity: 'base'
                        })
                    )
                ];
            }
            return [...this.getTree, ...this.getFolderMedias({ id: 'root' })];
        }
    },
    methods: {
        goUp() {
            const parent = this.getParent(this.value);
            if (parent) {
                this.$emit('input', parent);
            } else {
                this.$emit('input', {});
            }
        },
        startDrag(event, source) {
            event.target.style.opacity = 0.5;
            event.dataTransfer.setData('text/plain', source.id);
            event.dataTransfer.effectAllowed = 'move';
        },
        dropDrag(event, target) {
            const sourceId = event.dataTransfer.getData('text/plain');
            const source = this.children.find(({ id }) => id === sourceId);
            if (source) {
                if (source.id && target.id && source.id !== target.id) {
                    switch (this.isFilemanagerType(source)) {
                        case 'FILE': {
                            if (this.isFilemanagerType(target) === 'FOLDER') {
                                this.UPDATE_MEDIA({ ...source, parentId: target.id }).then(() => this.$emit('input', this.getTreeObject(this.value)));
                            }
                            break;
                        }
                        case 'FOLDER': {
                            if (this.isFilemanagerType(target) === 'FOLDER') {
                                this.UPDATE_FOLDER({ ...source, parentId: target.id })
                                    .then((response) => this.GET_TREE())
                                    .then(() => this.$emit('input', this.getTreeObject(this.value)));
                            }
                            break;
                        }
                    }
                } else {
                    if (!target.id) {
                        switch (this.isFilemanagerType(source)) {
                            case 'FOLDER': {
                                this.UPDATE_FOLDER({ ...source, parentId: this.value.parentId })
                                    .then((response) => this.GET_TREE())
                                    .then(() => this.$emit('input', this.getTreeObject(this.value)));
                                break;
                            }
                            case 'FILE': {
                                this.UPDATE_MEDIA({ ...source, parentId: this.value.parentId }).then(() => this.$emit('input', this.getTreeObject(this.value)));
                                break;
                            }
                        }
                    }
                }
            }
        },
        endDrag(event) {
            event.target.style.opacity = 1;
        },
        extractType({ fileType }) {
            return fileType.substring(0, fileType.indexOf('/'));
        },
        icon(item) {
            switch (this.isFilemanagerType(item)) {
                case 'FILE':
                    switch (this.extractType(item)) {
                        case 'image':
                            return 'mdi-file-image-outline';
                        case 'video':
                            return 'mdi-file-video-outline';
                        case 'application':
                            return 'mdi-file-pdf-box';
                    }
                    break;
                case 'FOLDER':
                    return 'mdi-folder-outline';
            }

            if (item.hasOwnProperty('icon')) {
                return item.icon;
            }

            return 'mdi-folder-outline';
        },
        onClick(item) {
            switch (this.isFilemanagerType(item)) {
                case 'FOLDER':
                    return this.$emit('input', item);
                case 'FILE': {
                    if (this.media.id === item.id) {
                        return this.$emit('media', {});
                    } else {
                        return this.$emit('media', item);
                    }
                }
            }
            this.goUp();
        },
        filter(items, search) {
            if (search.trim() === '') {
                return items;
            }
            let buildString = [...new Set(search.split(' '))].filter((word) => word !== '');
            return items.filter((item) =>
                buildString.every((word) =>
                    Object.entries(item)
                        .filter(([key, value]) => key === 'name' || key === 'fileName' || key === 'fileType')
                        .map(([key, value]) => value)
                        .join(' ')
                        .toLowerCase()
                        .includes(word.toLowerCase())
                )
            );
        },
        formattedSubtext(item) {
            switch (this.isFilemanagerType(item)) {
                case 'FOLDER':
                    return `${this.getFolderMedias(item).length} ${this.$t('Dateien')}, ${moment(item.updatedAt).format('YYYY-MM-DD, h:mm A')}`;
                case 'FILE':
                    return moment(item.updatedAt).format('YYYY-MM-DD, HH:mm');
            }
            return '';
        },
        handleMediaSelect(media) {
            this.$emit('selectedMedia', media);
        },
        getFolderMedias(value) {
            if (this.fileType == '') {
                return this.getFolderMedia(value);
            }
            return this.getFolderMedia(value).filter((media) => media.fileType.includes(this.fileType));
        }
    }
};
</script>
