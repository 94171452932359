<template>
    <v-menu v-model="showDatePicker" :close-on-content-click="false" offset-y max-width="100%">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                color="accent"
                autocomplete="new-password"
                attach
                prepend-icon="mdi-calendar-blank"
                :disabled="textBoxDisabled"
                readonly
                :label="label"
                v-model="dateTextComputed"
                v-on="on"
                v-bind="attrs"
                hide-details
            ></v-text-field>
        </template>
        <v-date-picker
            ref="weekPicker"
            first-day-of-week="1"
            :allowed-dates="allowedDates"
            v-model="dateData"
            scrollable
            :locale="language"
            :reactive="true"
            @input="showDatePicker = false"
            type="date"
        ></v-date-picker>
    </v-menu>
</template>

<script>
import moment from 'moment';

export default {
    data() {
        return {
            dateData: moment().format('YYYY-MM-DD'),
            showDatePicker: false
        };
    },
    computed: {
        dateTextComputed() {
            var startDate = moment(this.dateText);
            var endDate = moment(startDate).add(7, 'days').format('YYYY-MM-DD');
            return startDate.format('YYYY-MM-DD') + ' - ' + endDate;
        }
    },
    props: {
        dateText: String,
        textBoxDisabled: {
            default: false,
            type: Boolean
        },
        label: String,
        language: {
            default: 'de',
            type: String
        }
    },
    methods: {
        allowedDates(val) {
            var date = moment(val);
            if (date.day() == 1) return date.format('YYYY-MM-DD');
        }
    },
    watch: {
        dateData(val) {
            this.$emit('dateDataEmit', val);
        }
    }
};
</script>
