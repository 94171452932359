<template>
    <v-container fill-height>
        <v-row align="center" justify="center">
            <v-col cols="12" :sm="10" :md="8" :lg="6" :xl="4">
                <v-card>
                    <v-toolbar flat dark color="primary">
                        <v-toolbar-items>
                            <v-col align-self="center">
                                <v-avatar>
                                    <ImageLoader :size="32" :src="getLogo" />
                                </v-avatar>
                            </v-col>
                        </v-toolbar-items>
                        <v-toolbar-title>{{ `${getCompanyName} ${$t('Anmelden')}` }}</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form v-model="validCredentials">
                            <v-text-field
                                color="accent"
                                ref="emailField"
                                prepend-icon="mdi-account"
                                :label="$t('E-mail')"
                                type="text"
                                clearable
                                :rules="[notEmpty]"
                                v-model="email"
                                @keyup.enter.stop="login"
                            />
                            <v-text-field
                                color="accent"
                                id="wspassword"
                                prepend-icon="mdi-lock"
                                clearable
                                v-model="wspassword"
                                :label="$t('Passwort')"
                                :rules="[notEmpty]"
                                type="password"
                                @keyup.enter.stop="login"
                            />
                            <a @click="forgotPassword_click">{{ $t('Passwort vergessen?') }}</a>
                            <v-switch color="accent" hide-details v-model="stayLoggedIn" :label="$t('Eingeloggt bleiben')" />
                        </v-form>

                        <v-container>
                            <v-row no-gutters>
                                <v-col>
                                    <Impressum />
                                </v-col>
                                <v-col>
                                    <DataProtection />
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-subheader>{{ $t('Als App installieren') }}</v-subheader>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>
                                    <InstructionsAndroid />
                                </v-col>
                                <v-col>
                                    <InstructionsIOS />
                                </v-col>
                                <v-col>
                                    <InstructionsDesktop />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-row no-gutters>
                            <v-col>
                                <LanguageSelector />
                            </v-col>
                            <v-col align-self="center" cols="auto">
                                <v-btn color="primary" :disabled="isValidLogin" @click="login">{{ $t('Anmelden') }}</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <LoginCookies :showLoginCookies="showLoginCookies" @closeLoginCookiesModalEmit="closeLoginCookiesModalEmit" @acceptCookiesEmit="acceptCookiesEmit" />

        <ErrorLogin :errors="errors" :showErrors="showErrors" @closeErrorDialogEmit="closeErrorDialogEmit" />

        <MultiCustomer
            :multipleCustomersDialog="multipleCustomersDialog"
            :selectedCompany="selectedCompany"
            @logInSelectedCompanyEmit="logInSelectedCompanyEmit"
            @cancelCompanySelectEmit="cancelCompanySelectEmit"
            @selectCompanyEmit="selectCompanyEmit"
        />
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ImageLoader from '../../Components/Generic/ImageLoader';
import LanguageSelector from '../../Components/Global/LanguageSelector';
import Impressum from '../../Components/Login/Impressum';
import DataProtection from '../../Components/Login/DataProtection';
import MultiCustomer from '../../Components/Login/MultipleCustomer';
import LoginCookies from '../../Components/Login/LoginCookies';
import InstructionsDesktop from '../../Components/Login/InstructionsDesktop';
import InstructionsIOS from '../../Components/Login/InstructionsIOS';
import InstructionsAndroid from '../../Components/Login/InstructionsAndroid';
import ErrorLogin from '../../Components/Login/ErrorLogin';
import { Storage } from '../../plugins/Storage/storage';

export default {
    name: 'login',
    components: {
        LanguageSelector,
        ImageLoader,
        Impressum,
        DataProtection,
        MultiCustomer,
        LoginCookies,
        InstructionsDesktop,
        InstructionsIOS,
        InstructionsAndroid,
        ErrorLogin
    },
    data: () => ({
        email: '',
        wspassword: '',
        validCredentials: false,
        selectedCompany: '',
        errors: [],
        multipleCustomersDialog: false,
        showErrors: false,
        showLoginCookies: false,
        stayLoggedIn: Storage.getData('stayLoggedIn'),
        debounceLogin: false
    }),
    watch: {
        stayLoggedIn(value) {
            if (value) {
                this.showLoginCookies = value;
            } else {
                Storage.removeData('stayLoggedIn');
            }
        }
    },
    computed: {
        ...mapGetters('SystemSettings', ['getCompanyName', 'getLogo']),
        ...mapGetters('General', ['getIsInMultipleCompanies']),
        isValidLogin() {
            return this.validCredentials && this.debounceLogin;
        }
    },
    methods: {
        ...mapActions('General', ['LOGIN']),
        ...mapActions('SystemSettings', ['GET_GENERIC_CUSTOMER_SETTINGS']),
        notEmpty(v) {
            if (v === undefined || v === null) {
                return this.$t('Das Feld darf nicht leer sein');
            }
            return v.trim() !== '' || this.$t('Das Feld darf nicht leer sein');
        },
        acceptCookiesEmit() {
            this.showLoginCookies = false;
            Storage.localSave('stayLoggedIn', this.stayLoggedIn);
        },
        closeLoginCookiesModalEmit() {
            this.showLoginCookies = false;
            this.stayLoggedIn = false;
        },
        cancelCompanySelectEmit() {
            this.multipleCustomersDialog = false;
            this.selectedCompany = '';
        },
        selectCompanyEmit(company) {
            this.selectedCompany = company;
        },
        logInSelectedCompanyEmit() {
            this.LOGIN({
                username: this.email,
                password: this.wspassword,
                customerId: this.selectedCompany
            }).then(() => {
                this.multipleCustomersDialog = false;
                this.GET_GENERIC_CUSTOMER_SETTINGS().then(() => {
                    this.$nextTick(() => {
                        this.openDrawerMdAndUp();
                        this.navigateLogin();
                    });
                });
            });
        },
        login() {
            let email = this.email;
            if (!email.includes('@')) {
                email = `${email}@${this.autocompleteEmail()}`;
            }
            this.debounceLogin = true;
            this.LOGIN({ username: email, password: this.wspassword })
                .then(() => {
                    this.debounceLogin = false;
                    if (this.getIsInMultipleCompanies) {
                        this.multipleCustomersDialog = true;
                    } else {
                        this.GET_GENERIC_CUSTOMER_SETTINGS().then(() => {
                            this.openDrawerMdAndUp();
                        });
                        this.navigateLogin();
                    }
                })
                .catch((err) => {
                    this.debounceLogin = false;
                    this.errors = err.response.data.errors;
                    this.showErrors = true;
                });
        },
        openDrawerMdAndUp() {
            if (this.$vuetify.breakpoint.mdAndUp) {
                this.OPEN_DRAWER(true);
            }
        },
        navigateLogin() {
            if (this.getLoggedInAdmin.role === 'ROOT' || this.getLoggedInAdmin.role === 'SALES') {
                this.$router.push({ name: 'customers' });
            } else {
                this.$router.push({ name: 'landingPage' });
            }
        },
        closeErrorDialogEmit() {
            this.showErrors = false;
        },
        forgotPassword_click() {
            this.$router.push({ name: 'forgotPassword' });
        },
        focusEmailField() {
            this.$nextTick(() => this.$refs.emailField.focus());
        },
        autocompleteEmail() {
            let host = location.hostname;
            if (host.includes('localhost')) {
                return '';
            }
            if (host.includes('app')) {
                return '';
            }
            return host.replace('www.', '').replace('https://', '').split('.')[0];
        }
    },
    mounted() {
        this.OPEN_DRAWER(false);
        this.focusEmailField();
    }
};
</script>
