<template>
    <v-dialog v-model="computedDialog" scrollable :width="$vuetify.breakpoint.thresholds.sm" persistent :fullscreen="$vuetify.breakpoint.xs">
        <v-card>
            <v-toolbar dense text color="secondary" dark>
                <v-toolbar-title>{{ `${$t(label)} ${$t('Einstellungen')}` }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-container pa-0 fluid grid-list-md>
                    <v-layout wrap>
                        <v-flex xs12 v-show="enableGlobalConfig">
                            <v-switch
                                :label="$t('Einstellungen auf alle Kataloge anwenden')"
                                :hint="
                                    $t(
                                        'Ist diese Option aktiviert, werden die eingestellten Optionen für alle Kataloge der Aufgabe übernommen, wenn die Änderungen gespeichert werden'
                                    )
                                "
                                persistent-hint
                                v-model="applyToAllCatalogs"
                            />
                        </v-flex>
                        <v-flex xs12 sm6 md6 lg6 xl6>
                            <v-select
                                color="accent"
                                :label="$t('Wissenseinheiten sortieren nach')"
                                :hint="catalogSortingHint"
                                v-model="sortBy"
                                :items="sortList"
                                attach
                                item-value="value"
                                item-text="name"
                                persistent-hint
                                ><template v-slot:item="{ item }">
                                    <div>{{ $t(item) }}</div>
                                </template>
                                <template v-slot:selection="{ item }">
                                    <div>{{ $t(item) }}</div>
                                </template>
                            </v-select>
                        </v-flex>
                        <v-flex xs12 sm6 md6 lg6 xl6>
                            <v-select
                                color="accent"
                                :label="$t('Wenn Katalog nicht bestanden ist')"
                                v-model="onFailure"
                                :hint="catalogFailHint"
                                :items="onFailureList"
                                attach
                                item-value="value"
                                item-text="name"
                                persistent-hint
                            >
                                <template v-slot:item="{ item }">
                                    <span>{{ $t(item) }}</span>
                                </template>
                                <template v-slot:selection="{ item }">
                                    <span>{{ $t(item) }}</span>
                                </template>
                            </v-select>
                        </v-flex>
                        <v-flex xs12 sm6 md6 lg6 xl6>
                            <v-switch
                                :label="$t('Wissensbasis anzeigen')"
                                :hint="
                                    $t(
                                        'Wenn diese Option aktiviert ist, wird vor der Wissenseinheit die Wissensbasis angezeigt. Wenn diese Option deaktiviert ist, wird vor der Wissenseinheit keine Wissensbasis angezeigt'
                                    )
                                "
                                persistent-hint
                                v-model="showKnowledgeBase"
                            />
                        </v-flex>
                        <v-flex xs12 sm6 md6 lg6 xl6>
                            <v-switch
                                :label="$t('Erklärung anzeigen')"
                                :hint="
                                    $t(
                                        'Wenn diese Option aktiviert ist, wird nachdem die Wissenseinheite falsch beantwortet wurde, die Erklärung der Wissenseinheit angezeigt. Wenn diese Option deaktiviert ist, wird nachdem der Nutzer die Wissenseinheit falsch beantwortet wurde, keine Erklärung angezeigt'
                                    )
                                "
                                persistent-hint
                                v-model="showExplanation"
                            />
                        </v-flex>
                        <v-flex xs12 sm6 md6 lg6 xl6>
                            <v-switch
                                :label="$t('Wissensbasis erneut ansehen')"
                                :hint="
                                    $t(
                                        'Wenn diese Option aktiviert ist, kann der Nutzer die Wissensbasis während der Bearbeitung der Wissenseinheit nach belieben aufrufen'
                                    )
                                "
                                persistent-hint
                                v-model="reopenKnowledgeBase"
                            />
                        </v-flex>
                        <v-flex xs12 sm6 md6 lg6 xl6>
                            <v-form v-model="valid" @submit.prevent>
                                <v-text-field
                                    :label="$t('Bestandenquote')"
                                    :hint="$t('Geben Sie eine untere Schranke an, die zum Bestehen des Katalogs mindestens erfüllt werden muss')"
                                    persistent-hint
                                    type="number"
                                    min="0"
                                    max="100"
                                    suffix="%"
                                    v-model.number="passingQuote"
                                    :rules="[passingQuoteMin, passingQuoteMax, passingQuoteNonNull]"
                                />
                            </v-form>
                        </v-flex>
                        <v-flex xs12 sm6 md6 lg6 xl6 v-if="isTask">
                            <v-switch
                                :label="$t('Archivieren')"
                                :hint="$t('Wenn diese Option aktiviert ist, können Nutzer diesen Katalog in Ihrem Archiv nutzen')"
                                persistent-hint
                                v-model="archive"
                            />
                            <v-expand-transition>
                                <v-radio-group v-show="archive" persistent-hint :hint="archiveHint" v-model="computedArchiveOptions">
                                    <v-radio color="primary" value="archiveOnPass" :label="$t('Bei beenden der Aufgabe')" />
                                    <v-radio color="primary" value="archiveOnEndDate" :label="$t('nach Ablauf des Enddatums')" />
                                    <v-radio color="primary" value="archiveImmediately" :label="$t('bei Erstellung der Aufgabe')" />
                                </v-radio-group>
                            </v-expand-transition>
                        </v-flex>
                        <v-flex xs12 sm12 md12 lg12 xl6 v-if="isTask">
                            <v-switch :label="$t('Adaptive Learning')" :hint="$t('')" persistent-hint v-model="useSkipping" />
                            <v-expand-transition>
                                <v-item-group>
                                    <v-slider
                                        v-model="skippingUnscaled"
                                        :max="100"
                                        :min="0"
                                        :label="$t('Skipping Percentage')"
                                        :disabled="!useSkipping"
                                        v-show="useSkipping"
                                    >
                                        <template v-slot:append>
                                            <v-text-field
                                                v-model="skippingUnscaled"
                                                class="mt-0 pt-0"
                                                type="number"
                                                style="width: 60px"
                                                suffix="%"
                                            ></v-text-field> </template
                                    ></v-slider>
                                    <v-slider v-model="skippingToleranceUnscaled" :max="100" :min="0" :label="$t('Tolerance')" v-show="useSkipping">
                                        <template v-slot:append>
                                            <v-text-field
                                                v-model="skippingToleranceUnscaled"
                                                class="mt-0 pt-0"
                                                type="number"
                                                style="width: 60px"
                                                suffix="%"
                                            ></v-text-field>
                                        </template>
                                    </v-slider>
                                </v-item-group>
                            </v-expand-transition>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-layout column>
                    <v-flex>
                        <v-btn block color="warning" @click="save" :disabled="!valid">
                            {{ $t('Aktualisieren') }}
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: 'quiz-catalog-configurator',
    data: () => ({
        showKnowledgeBase: true,
        showExplanation: true,
        answerTimeLimit: 100,
        passingQuote: 0,
        archiveImmediately: false,
        archiveOnEndDate: false,
        reopenKnowledgeBase: true,
        archive: false,
        archiveOnPass: false,
        onFailure: 'REPEAT',
        onFailureList: ['REPEAT', 'END'],
        sortBy: 'NATURAL',
        sortList: ['NATURAL', 'RANDOM', 'DATE'],
        useSkipping: false,
        skippingPercentage: 1.0,
        skippingPercentageTolerance: 0.0,

        applyToAllCatalogs: false,
        valid: false,
        skippingUnscaled: 100,
        skippingToleranceUnscaled: 0
    }),
    watch: {
        value(value) {
            if (value) {
                Object.entries(this.options).forEach(([key, value]) => {
                    if (this.$data.hasOwnProperty(key)) {
                        this.$data[key] = value;
                    }
                });
                this.applyToAllCatalogs = false;
                this.skippingUnscaled = this.skippingPercentage * 100;
                this.skippingToleranceUnscaled = this.skippingPercentageTolerance * 100;
            } else {
                this.reset();
            }
        },
        archive(value) {
            if (value) {
                if (this.computedArchiveOptions === '') {
                    this.archiveOnPass = true;
                }
            }
        },
        skippingUnscaled(value) {
            if (value) {
                this.skippingPercentage = this.skippingUnscaled / 100.0;
            }
        },
        skippingToleranceUnscaled(value) {
            if (value) {
                this.skippingPercentageTolerance = this.skippingToleranceUnscaled / 100.0;
            }
        }
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object,
            default: () => ({})
        },
        label: {
            type: String,
            default: 'Katalog'
        },
        enableGlobalConfig: {
            type: Boolean,
            default: true
        },
        isTask: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        computedDialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        computedArchiveOptions: {
            get() {
                if (this.archiveOnPass) {
                    return 'archiveOnPass';
                }
                if (this.archiveImmediately) {
                    return 'archiveImmediately';
                }
                if (this.archiveOnEndDate) {
                    return 'archiveOnEndDate';
                }
                return '';
            },
            set(value) {
                switch (value) {
                    case 'archiveOnPass': {
                        this.archiveOnPass = true;
                        this.archiveImmediately = false;
                        this.archiveOnEndDate = false;
                        break;
                    }
                    case 'archiveImmediately': {
                        this.archiveOnPass = false;
                        this.archiveImmediately = true;
                        this.archiveOnEndDate = false;
                        break;
                    }
                    case 'archiveOnEndDate': {
                        this.archiveOnPass = false;
                        this.archiveImmediately = false;
                        this.archiveOnEndDate = true;
                        break;
                    }
                }
            }
        },
        catalogFailHint() {
            switch (this.onFailure) {
                case 'END':
                    return this.$t(
                        'Wenn die Bestehensquote für diesen Katalog nicht erreicht worden ist, wird die Aufgabe abgebrochen und als nicht bestanden bewertet'
                    );
                case 'REPEAT':
                    return this.$t(
                        'Wenn die Bestehensquote für diesen Katalog nicht erreicht worden ist, werden alle Wissenseinheiten in diesem Katalog nochmal gestellt'
                    );
            }
            return '';
        },
        catalogSortingHint() {
            switch (this.sortBy) {
                case 'NATURAL':
                    return this.$t('Die Reihenfolge der Wissenseinheiten im Quiz wird so ausgegeben, wie im Katalog sortiert');
                case 'RANDOM':
                    return this.$t('Die Reihenfolge der Wissenseinheiten im Quiz wird zufällig ausgegeben');
                case 'DATE':
                    return this.$t('Die Reihenfolge der Wissenseinheiten im Quiz wird nach der Aktualität ausgegeben');
            }
            return '';
        },
        archiveHint() {
            switch (this.computedArchiveOptions) {
                case 'archiveOnPass':
                    return this.$t('Wenn alle Wissenseinheiten beantwortet wurden, wird dieser Katalog im Archiv verfügbar');
                case 'archiveOnEndDate':
                    return this.$t('Wenn das Enddatum der Aufgabe erreicht worden ist, wird dieser Katalog im Archiv verfügbar');
                case 'archiveImmediately':
                    return this.$t('Wenn diese Aufgabe erstellt ist, wird auch dieser Kataloge im Archiv verfügbar');
            }
            return '';
        }
    },
    methods: {
        passingQuoteMin(value) {
            return value >= 0 || this.$t('Geben Sie eine Zahl größer 0 an');
        },
        passingQuoteMax(value) {
            return value <= 100 || this.$t('Geben Sie eine Zahl kleiner 100 an');
        },
        passingQuoteNonNull(value) {
            return typeof value === 'number' || this.$t('Geben Sie eine Zahl zwischen 0 und 100 an');
        },
        reset() {
            this.showKnowledgeBase = true;
            this.showExplanation = true;
            this.passingQuote = 0;
            this.archiveImmediately = false;
            this.archiveOnEndDate = false;
            this.reopenKnowledgeBase = true;
            this.archive = false;
            this.archiveOnPass = false;
            this.onFailure = 'REPEAT';
            this.onFailureList = ['REPEAT', 'END'];
            this.sortBy = 'NATURAL';
            this.sortList = ['NATURAL', 'RANDOM', 'DATE'];
            this.applyToAllCatalogs = false;
            this.skippingPercentage = 1.0;
            this.skippingPercentageTolerance = 0.0;
            this.skippingUnscaled = 100;
            this.skippingToleranceUnscaled = 0;
        },
        serialize() {
            let { valid, ...data } = this.$data;
            return data;
        },
        close() {
            this.$emit('input', false);
        },
        save() {
            this.$emit('options', this.serialize());
            this.close();
        }
    }
};
</script>
