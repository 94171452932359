<template>
    <v-dialog v-model="showDialog" scrollable persistent :width="$vuetify.breakpoint.thresholds.sm" :fullscreen="$vuetify.breakpoint.xs">
        <v-card>
            <v-toolbar dense dark flat color="seconary">
                <v-toolbar-title>{{ $t('Aktualisieren') }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="() => (showDialog = false)">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                {{ $t('Bevor die Wissenseinheit aktualisert wird, müssen Sie sich für eines der folgenden Optionen entscheiden') }}
                <ul>
                    <li>
                        <span class="primary--text">{{ $t('Entfernen') }}:</span>
                        {{
                            $t(
                                'Sie können die Wissenseinheit aus allen aktuell stattfindenden und zukünftig stattfindenden Aufgaben ausschließen. Alle Antworten von Nutzer zu dieser Wissenseinheit werden entwertet'
                            )
                        }}
                    </li>
                    <li>
                        <span class="primary--text">{{ $t('Beibehalten') }}:</span>
                        {{
                            $t(
                                'die Änderungen an der Wissenseinheit ändert nichts an den aktuell stattfindenden und zukünftig stattfindenden Aufgaben. Alle Antworten von Nutzer zu dieser Wissenseinheit bleiben bestehen und werden nicht reevaluiert, falls die Richtigkeit der Wissenseinheit sich geändert haben sollte. Alle Nutzer, die diese Wissenseinheit nicht beantwortet haben, werden mit der geänderten Wissenseinheit konfrontiert und die Antwort wird nach der Änderung evaluiert'
                            )
                        }}
                    </li>
                </ul>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="remove">{{ $t('Entfernen') }}</v-btn>
                <v-btn color="secondary" @click="keep">{{ $t('Beibehalten') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import AuthoringToolMixin from './Mixins/AuthoringtoolMixin';

export default {
    name: 'update-knowledge-unit-exception-dialog',
    mixins: [AuthoringToolMixin],
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        dialog: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        showDialog: {
            get() {
                return this.dialog;
            },
            set(value) {
                this.$emit('dialog', value);
            }
        }
    },
    methods: {
        remove() {
            this.SELECT_KNOWLEDGEUNIT(this.value);
            this.UPDATE_EXCEPTION_REMOVE(this.value)
                .then(() => (this.showDialog = false))
                .then(() => this.$emit('close'));
        },
        keep() {
            this.SELECT_KNOWLEDGEUNIT(this.value);
            this.UPDATE_EXCEPTION_KEEP(this.value)
                .then(() => (this.showDialog = false))
                .then(() => this.$emit('close'));
        }
    }
};
</script>
