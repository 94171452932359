<template>
    <v-container fluid ma-0 pa-0 fill-height>
        <v-layout column>
            <v-flex shrink>
                <Toolbar
                    :toolbarTitle="$t('Dateimanager')"
                    showDrawer
                    tooltipMessage="Hier können Sie Medien und Ordner hinzufügen, die hinzugefügte Ordner und Medien anschauen, bearbeiten und löschen."
                    showInfoButton
                    infoMessage="Sie können Dateien verschieben indem Sie diese in Ordner ziehen"
                />
            </v-flex>
            <v-flex grow>
                <Filemanager />
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import Toolbar from '../../Components/Generic/Toolbar';
import Filemanager from '../../Components/FileManager/Prototype/Filemanager';
import FilemanagerMixin from '../../Components/FileManager/Mixins/FilemanagerPrototypeMixin';

export default {
    name: 'file-manager',
    mixins: [FilemanagerMixin],
    components: {
        Toolbar,
        Filemanager
    }
};
</script>
