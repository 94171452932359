<template>
    <v-container>
        <v-row>
            <v-col :cols="getColumn(index)" v-for="(option, index) in knowledgeUnitOptions" :key="`option-${option.id}`">
                <OptionNode :value="option" :selected="option.isAnswer ? option : ''" @mediaEmit="mediaEmit" :disableInteraction="true" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import OptionNode from '../Helpers/OptionNode';

export default {
    name: 'multi-select-result',
    components: {
        OptionNode
    },
    props: {
        knowledgeUnitOptions: {
            default: () => [],
            type: Array
        }
    },
    methods: {
        getColumn(index) {
            if (index === this.knowledgeUnitOptions.length - 1 && index % 2 === 0) {
                return 12;
            }
            return 6;
        },
        mediaEmit(val) {
            this.$emit('media', val);
        }
    }
};
</script>
