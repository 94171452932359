<template>
    <v-select
        v-model="computedSelect"
        :items="getLearningGoalActivities"
        :no-data-text="$t('Keine Aktivitäten verfügbar')"
        :label="$t('Aktivitäten')"
        :hint="computedHint"
        persistent-hint
        return-object
        item-text="name"
    />
</template>

<script>
import CourseMixin from '../../Mixins/CourseMixin';

export default {
    name: 'activity-selector',
    mixins: [CourseMixin],
    watch: {
        getLearningGoalActivities(value) {
            if (value.length === 0) {
                this.computedSelect = {};
                this.SET_SELECTED_ACTIVITY({});
            }
        },
        getSelectedLearningGoal(value) {
            if (Object.keys(value).length !== 0) {
                this.GET_LEARNING_GOAL_ACTIVITIES(value).then(() => {
                    if (this.getLearningGoalActivities.length > 0) {
                        this.computedSelect = this.getLearningGoalActivities[0];
                    } else {
                        this.computedSelect = {};
                    }
                });
            } else {
                this.SET_LEARNING_GOAL_ACTIVITIES([]);
            }
        }
    },
    computed: {
        computedHint() {
            if (this.computedSelect.hasOwnProperty('id')) {
                if (this.getLearningGoalActivities.map((goal) => goal.id).includes(this.computedSelect.id)) {
                    return `min: ${this.computedSelect.minScore}, max: ${this.computedSelect.score}, ${this.$t('Typ')}: ${this.$t(
                        this.computedSelect.activityType
                    )}`;
                }
            }
            return '';
        },
        computedSelect: {
            get() {
                return this.getSelectedActivity;
            },
            set(value) {
                this.SET_SELECTED_ACTIVITY(value);
            }
        }
    },
    mounted() {
        if (this.getLearningGoalActivities.length === 0) {
            if (Object.keys(this.getSelectedLearningGoal).length !== 0) {
                this.GET_LEARNING_GOAL_ACTIVITIES(this.getSelectedLearningGoal).then(() => {
                    if (this.getLearningGoalActivities.length > 0) {
                        this.computedSelect = this.getLearningGoalActivities[0];
                    } else {
                        this.computedSelect = {};
                    }
                });
            }
        }
    }
};
</script>
