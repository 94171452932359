<template>
    <v-card flat>
        <v-container>
            <v-row>
                <v-col :cols="12">
                    <v-text-field v-model="name" :label="$t('Name')" />
                </v-col>
                <v-col :cols="12">
                    <color-picker :label="$t('Farbe')" v-model="color" />
                </v-col>
                <v-col :cols="12">
                    <v-menu offset-y :close-on-content-click="false" :max-height="400" :min-width="300">
                        <template v-slot:activator="{ on }">
                            <v-input
                                persistent-hint
                                :hint="
                                    $t(
                                        'Wählen Sie eine Gruppe aus, wo die Dateien abgelegt werden sollen. Wenn Sie keine Gruppe auswählen, werden die Dateien im Stammverzeichnis angelegt'
                                    )
                                "
                            >
                                <template v-slot:prepend="">
                                    <v-btn v-on="on" icon>
                                        <v-icon>mdi-file-tree</v-icon>
                                    </v-btn>
                                </template>
                                <v-breadcrumbs divider=">" :items="groupPath">
                                    <template v-slot:item="{ item }">
                                        <span class="text-caption">{{ item.name || item.fileName }}</span>
                                    </template>
                                </v-breadcrumbs>
                            </v-input>
                        </template>
                        <v-card>
                            <v-toolbar dense color="secondary" flat dark>
                                <v-toolbar-title>{{ $t('Gruppen') }}</v-toolbar-title>
                            </v-toolbar>
                            <v-sheet :max-height="300" class="overflow-y-auto">
                                <FilemanagerFolderSelector v-model="selectedFolder" :disabledNode="disabledNode" />
                            </v-sheet>
                        </v-card>
                    </v-menu>
                </v-col>
            </v-row>
        </v-container>
        <v-tabs show-arrows v-model="tabs">
            <v-tab :key="0">{{ $t('Lesen') }}</v-tab>
            <v-tab :key="1">{{ $t('Schreiben') }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
            <v-tab-item :key="0">
                <v-card text>
                    <v-container>
                        <v-row>
                            <v-col :cols="12">
                                <v-select :label="$t('Lesen')" v-model="visibility" :items="options" :hint="computedVisibilityHint" persistent-hint>
                                    <template v-slot:item="{ item }">
                                        {{ $t(item) }}
                                    </template>
                                    <template v-slot:selection="{ item }">
                                        {{ $t(item) }}
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col :cols="12" v-if="visibility === 'CUSTOM'">
                                <v-layout justify-space-between>
                                    <v-flex shrink>
                                        <FilemanagerMenuBadge
                                            :tooltip="$t('Admin / Lehrende Gruppen')"
                                            v-model="visibilityOptions.adminGroups"
                                            icon="mdi-account-group-outline"
                                            showAdminGroups
                                        />
                                    </v-flex>
                                    <v-flex shrink>
                                        <FilemanagerMenuBadge
                                            :tooltip="$t('Lernende Gruppen')"
                                            v-model="visibilityOptions.userGroups"
                                            icon="mdi-account-group"
                                            showUserGroups
                                        />
                                    </v-flex>
                                    <v-flex shrink>
                                        <v-menu offset-x left :close-on-content-click="false">
                                            <template v-slot:activator="{ on: menu }">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on: tooltip }">
                                                        <v-badge overlap :value="visibilityOptions.users.length">
                                                            <template v-slot:badge>
                                                                <span>{{ visibilityOptions.users.length }}</span>
                                                            </template>
                                                            <v-btn
                                                                v-on="{
                                                                    ...menu,
                                                                    ...tooltip
                                                                }"
                                                                icon
                                                            >
                                                                <v-icon>mdi-account</v-icon>
                                                            </v-btn>
                                                        </v-badge>
                                                    </template>
                                                    {{ $t('Nutzer') }}
                                                </v-tooltip>
                                            </template>
                                            <v-card text>
                                                <UserGroupTreeSelector v-model="visibilityOptions.users" />
                                            </v-card>
                                        </v-menu>
                                    </v-flex>
                                </v-layout>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-tab-item>
            <v-tab-item :key="1">
                <v-card flat>
                    <v-container>
                        <v-row>
                            <v-col :cols="12">
                                <v-select :label="$t('Schreiben')" v-model="changeability" :items="options" :hint="computedChangeabilityHint" persistent-hint>
                                    <template v-slot:item="{ item }">
                                        {{ $t(item) }}
                                    </template>
                                    <template v-slot:selection="{ item }">
                                        {{ $t(item) }}
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col :cols="12" v-if="changeability === 'CUSTOM'">
                                <v-layout justify-space-between>
                                    <v-flex shrink>
                                        <FilemanagerMenuBadge
                                            :tooltip="$t('Admin / Lehrende Gruppen')"
                                            v-model="changeabilityOptions.adminGroups"
                                            icon="mdi-account-group-outline"
                                            showAdminGroups
                                        />
                                    </v-flex>
                                    <v-flex shrink>
                                        <FilemanagerMenuBadge
                                            :tooltip="$t('Lernende Gruppen')"
                                            v-model="changeabilityOptions.userGroups"
                                            icon="mdi-account-group"
                                            showUserGroups
                                        />
                                    </v-flex>
                                    <v-flex shrink>
                                        <v-menu offset-x left :close-on-content-click="false">
                                            <template v-slot:activator="{ on: menu }">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on: tooltip }">
                                                        <v-badge overlap :value="changeabilityOptions.users.length">
                                                            <template v-slot:badge>
                                                                <span>{{ changeabilityOptions.users.length }}</span>
                                                            </template>
                                                            <v-btn
                                                                v-on="{
                                                                    ...menu,
                                                                    ...tooltip
                                                                }"
                                                                icon
                                                            >
                                                                <v-icon>mdi-account</v-icon>
                                                            </v-btn>
                                                        </v-badge>
                                                    </template>
                                                    {{ $t('Nutzer') }}
                                                </v-tooltip>
                                            </template>
                                            <v-card text>
                                                <UserGroupTreeSelector v-model="changeabilityOptions.users" />
                                            </v-card>
                                        </v-menu>
                                    </v-flex>
                                </v-layout>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
        <v-card-actions>
            <v-btn block :color="computedColor" @click="save" :disabled="isDisabled">
                {{ computedSubmitMessage }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import FilemanagerFolderSelector from './Helpers/FilemanagerFolderSelector';
import UserGroupTreeSelector from '../../Generic/Tree/UserGroupTreeSelector';
import FilemanagerMenuBadge from './Helpers/FilemanagerMenuBadge';

import FilemanagerMixin from '../Mixins/FilemanagerPrototypeMixin';
import ColorPicker from '../../SystemSettings/Picker/ColorPicker';

export default {
    name: 'add-update-filemanager-folder',
    components: {
        FilemanagerFolderSelector,
        FilemanagerMenuBadge,
        UserGroupTreeSelector,
        ColorPicker
    },
    mixins: [FilemanagerMixin],
    data: () => ({
        id: '',
        name: '',
        color: '',
        selectedFolder: {},
        tabs: 0,
        options: ['ALL', 'ADMINS', 'USERS', 'SELF', 'CUSTOM'],
        changeability: 'ALL',
        visibility: 'ALL',
        changeabilityOptions: {
            adminGroups: [],
            userGroups: [],
            users: []
        },
        visibilityOptions: {
            adminGroups: [],
            userGroups: [],
            users: []
        }
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        type: {
            type: String,
            default: ''
        }
    },
    computed: {
        computedChangeabilityHint() {
            switch (this.changeability) {
                case 'ALL':
                    return this.$t('Alle Lehrende / Admin und Lernende dürfen Dateien verändern und verschieben');
                case 'ADMINS':
                    return this.$t('Nur Lehrende / Admin dürfen Dateien verändern und verschieben');
                case 'USERS':
                    return this.$t('Nur Lernende dürfen Dateien verändern und verschieben');
                case 'SELF':
                    return this.$t('Persönlicher Ordner. Nur der Ersteller selbst darf Dateien verändern und verschieben');
                case 'CUSTOM':
                    return this.$t('Sie können einstellen, welche Gruppen und/oder Nutzer die Dateien verändern und verschieben dürfen');
            }
            return '';
        },
        computedVisibilityHint() {
            switch (this.visibility) {
                case 'ALL':
                    return this.$t('Alle Lehrende / Admin und Lernende können den Ordner und die darin enthaltene Dateien sehen');
                case 'ADMINS':
                    return this.$t('Nur Lehrende / Admin können den Ordner und die darin enthaltene Dateien sehen');
                case 'USERS':
                    return this.$t('Nur Lernende können den Ordner und die darin enthaltende Dateien sehen');
                case 'SELF':
                    return this.$t('Persönlicher Ordner. Nur der Ersteller selbst kann den Ordner und die darin enthaltene Dateien sehen');
                case 'CUSTOM':
                    return this.$t('Sie können einstellen, welche Gruppen und/oder Nutzer den Ordner und die darin enthaltene Dateien sehen');
            }
            return '';
        },
        disabledNode() {
            return this.value && this.type === 'UPDATE' ? this.value : {};
        },
        isDisabled() {
            return this.name.trim() === '';
        },
        computedColor() {
            switch (this.type) {
                case 'CREATE':
                    return 'success';
                case 'UPDATE':
                    return 'warning';
            }
            return '';
        },
        computedSubmitMessage() {
            switch (this.type) {
                case 'CREATE':
                    return this.$t('Hinzufügen');
                case 'UPDATE':
                    return this.$t('Bearbeiten');
            }
            return '';
        },
        groupPath() {
            let path = this.calculateTreePath(this.selectedFolder);
            if (path.map(({ id }) => id).some((id) => id === '')) {
                path = [];
            }
            return path.length > 0 ? this.calculateTreePath(this.selectedFolder) : [{ name: this.$t('Stammverzeichnis') }];
        }
    },
    methods: {
        reset() {
            this.id = '';
            this.name = '';
            this.color = '';
            this.selectedFolder = {};
            this.changeabilityOptions = {
                adminGroups: [],
                userGroups: [],
                users: []
            };
            this.visibilityOptions = {
                adminGroups: [],
                userGroups: [],
                users: []
            };
        },
        serialize() {
            switch (this.type) {
                case 'CREATE':
                    return {
                        name: this.name,
                        parentId: this.selectedFolder && this.selectedFolder.id ? this.selectedFolder.id : '',
                        changeability: this.changeability,
                        visibility: this.visibility,
                        changeabilityOptions: this.changeabilityOptions,
                        visibilityOptions: this.visibilityOptions,
                        ...(this.color !== '#FFFFFF' && { color: this.color })
                    };
                case 'UPDATE':
                    return {
                        id: this.id,
                        name: this.name,
                        parentId: this.selectedFolder && this.selectedFolder.id ? this.selectedFolder.id : '',
                        changeability: this.changeability,
                        visibility: this.visibility,
                        changeabilityOptions: this.changeabilityOptions,
                        visibilityOptions: this.visibilityOptions,
                        ...(this.color !== '#FFFFFF' && { color: this.color })
                    };
            }
            return {
                id: '',
                name: '',
                parentId: ''
            };
        },
        save() {
            switch (this.type) {
                case 'CREATE':
                    return this.CREATE_FOLDER(this.serialize())
                        .then((response) => this.GET_TREE().then(() => response))
                        .then((response) => this.$emit('input', response));
                case 'UPDATE':
                    return this.UPDATE_FOLDER(this.serialize())
                        .then((response) => this.GET_TREE().then(() => response))
                        .then((response) => this.$emit('input', response));
            }
        }
    },
    activated() {
        this.reset();
        if (this.type === 'UPDATE') {
            Object.entries(this.value).forEach(([key, value]) => {
                if (this.$data.hasOwnProperty(key)) {
                    this.$data[key] = value;
                }
                if (key === 'parentId') {
                    this.selectedFolder = value !== '' ? this.getFolder({ id: value }) : {};
                }
            });
        } else {
            if (this.isFilemanagerType(this.value) === 'FOLDER') {
                this.selectedFolder = this.getFolder(this.value);
            } else {
                const parent = this.getFolder({ id: this.value.folderId });
                if (parent && parent.id && parent.id !== '') {
                    this.selectedFolder = parent;
                }
            }
        }
    }
};
</script>
