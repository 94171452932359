<template>
    <v-card>
        <v-container>
            <v-row>
                <v-col :cols="12">
                    <v-card>
                        <v-toolbar flat dense dark color="secondary">
                            <v-toolbar-title>{{ $t('Einstellungen') }}</v-toolbar-title>
                        </v-toolbar>
                        <v-container>
                            <v-row no-gutters>
                                <v-col :cols="12">
                                    <v-text-field
                                        color="accent"
                                        autocomplete="new-password"
                                        :label="$t('Nutzername')"
                                        :rules="[rules.email]"
                                        type="text"
                                        v-model="textfield.username"
                                        placeholder="Server@Firma.de"
                                        clearable
                                    />
                                </v-col>
                                <v-col :cols="12">
                                    <v-text-field
                                        color="accent"
                                        autocomplete="new-password"
                                        :label="$t('Host')"
                                        v-model="textfield.host"
                                        type="text"
                                        placeholder="smtp.server.com"
                                        clearable
                                    />
                                </v-col>
                                <v-col :cols="12">
                                    <v-text-field
                                        color="accent"
                                        autocomplete="new-password"
                                        :label="$t('Passwort')"
                                        :type="visibility.password ? 'text' : 'password'"
                                        :append-icon="visibility.password ? 'mdi-eye' : 'mdi-eye-off'"
                                        @click:append="visibility.password = !visibility.password"
                                        v-model="textfield.password"
                                        clearable
                                    />
                                </v-col>
                                <v-col :cols="12">
                                    <v-select color="accent" :label="$t('Protokoll')" v-model="select.protocol.value" :items="select.protocol.items" />
                                </v-col>
                                <v-col :cols="12">
                                    <v-text-field
                                        color="accent"
                                        autocomplete="new-password"
                                        :label="$t('Port')"
                                        :rules="[rules.number]"
                                        v-model="textfield.port"
                                        type="text"
                                        placeholder="25"
                                        clearable
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
                <v-col :cols="12">
                    <v-card>
                        <v-toolbar flat dense dark color="secondary">
                            <v-toolbar-title>{{ $t('Erweiterte Einstellungen') }}</v-toolbar-title>
                        </v-toolbar>
                        <v-container>
                            <v-row no-gutters>
                                <v-col :cols="12">
                                    <v-text-field
                                        color="accent"
                                        autocomplete="new-password"
                                        :label="$t('Verbindungszeitüberschreitung')"
                                        :rules="[rules.number]"
                                        v-model="textfield.additionalProperties.connectiontimeout"
                                        type="text"
                                        suffix="ms"
                                        clearable
                                    />
                                </v-col>
                                <v-col :cols="12">
                                    <v-text-field
                                        color="accent"
                                        autocomplete="new-password"
                                        :label="$t('Auszeit')"
                                        :rules="[rules.number]"
                                        v-model="textfield.additionalProperties.timeout"
                                        type="text"
                                        suffix="ms"
                                        clearable
                                    />
                                </v-col>
                                <v-col :cols="12">
                                    <v-text-field
                                        color="accent"
                                        autocomplete="new-password"
                                        :label="$t('Zeitüberschreitung Schreiben')"
                                        :rules="[rules.number]"
                                        v-model="textfield.additionalProperties.writetimeout"
                                        type="text"
                                        suffix="ms"
                                        clearable
                                    />
                                </v-col>
                                <v-col :cols="4">
                                    <v-switch color="accent" v-model="smtpAuth" :label="$t('SMTP Authentication')" />
                                </v-col>
                                <v-col :cols="4">
                                    <v-switch color="accent" v-model="starttlsEnable" :label="$t('SMTP TLS Enable')" />
                                </v-col>
                                <v-col :cols="4">
                                    <v-switch color="accent" v-model="starttlsRequired" :label="$t('SMTP TLS Required')" />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-card-actions>
            <v-spacer />
            <v-btn text @click="reset">{{ $t('Zurücksetzen') }}</v-btn>
            <v-btn color="primary" @click="save">{{ $t('Speichern') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import SystemSettingsMixin from '../../../Components/SystemSettings/Mixins/SystemSettingsMixin';
import { mapActions } from 'vuex';

export default {
    name: 'mail-server',
    mixins: [SystemSettingsMixin],
    data: () => ({
        rules: {
            email: (value) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Die angegebene E-Mail muss gültig sein',
            number: (value) => /^[\d]+$/.test(value) || 'Geben Sie bitte nur Zahlen ein'
        },
        visibility: {
            password: false
        },
        select: {
            protocol: {
                value: 'smtp',
                items: ['smtp']
            }
        },
        switch: {
            mail: {
                smtp: {
                    auth: false,
                    starttls: {
                        enable: false,
                        required: false
                    }
                }
            }
        },
        textfield: {
            username: '',
            host: '',
            password: '',
            port: '',
            additionalProperties: {
                connectiontimeout: 5000,
                timeout: 5000,
                writetimeout: 5000
            }
        }
    }),
    computed: {
        smtpAuth: {
            get() {
                return this.switch.mail.smtp.auth;
            },
            set(value) {
                this.switch.mail.smtp.auth = value;
            }
        },
        starttlsEnable: {
            get() {
                return this.switch.mail.smtp.starttls.enable;
            },
            set(value) {
                this.switch.mail.smtp.starttls.enable = value;
            }
        },
        starttlsRequired: {
            get() {
                return this.switch.mail.smtp.starttls.required;
            },
            set(value) {
                this.switch.mail.smtp.starttls.required = value;
            }
        },
        result() {
            return {
                username: this.textfield.username,
                password: this.textfield.password,
                host: this.textfield.host,
                protocol: this.select.protocol.value,
                port: this.textfield.port,
                additionalProperties: {
                    'mail.smtp.auth': this.switch.mail.smtp.auth,
                    'mail.smtp.starttls.enable': this.switch.mail.smtp.starttls.enable,
                    'mail.smtp.starttls.required': this.switch.mail.smtp.starttls.required,
                    'mail.smtp.connectiontimeout': this.textfield.additionalProperties.connectiontimeout,
                    'mail.smtp.timeout': this.textfield.additionalProperties.timeout,
                    'mail.smtp.writetimeout': this.textfield.additionalProperties.writetimeout
                }
            };
        }
    },
    methods: {
        ...mapActions('ErrorReporterOperations', ['SET_ERROR_MESSAGES', 'CLEAR_ERROR_MESSAGES']),
        save() {
            this.SET_SERVER_PROPERTIES(this.result);
        },
        reset() {
            this.DELETE_SERVER_PROPERTIES().then(() => {
                this.GET_SERVER_PROPERTIES();
            });
        }
    },
    mounted() {
        this.GET_SERVER_PROPERTIES().then(() => {
            this.textfield.host = this.getServerProperties.host;
            this.textfield.port = this.getServerProperties.port;
            this.textfield.username = this.getServerProperties.username;
            this.select.protocol.value = this.getServerProperties.protocol;
            this.textfield.additionalProperties.writetimeout = this.getServerProperties.additionalProperties['mail.smtp.writetimeout'];
            this.textfield.additionalProperties.timeout = this.getServerProperties.additionalProperties['mail.smtp.timeout'];
            this.textfield.additionalProperties.connectiontimeout = this.getServerProperties.additionalProperties['mail.smtp.connectiontimeout'];
            this.switch.mail.smtp.auth = this.getServerProperties.additionalProperties['mail.smtp.auth'];
            this.switch.mail.smtp.starttls.required = this.getServerProperties.additionalProperties['mail.smtp.starttls.required'];
            this.switch.mail.smtp.starttls.enable = this.getServerProperties.additionalProperties['mail.smtp.starttls.enable'];
        });
    }
};
</script>
