import Forum from '../../views/Forum/ForumForums';
import Threads from '../../views/Forum/ForumThreads';
import Answers from '../../views/Forum/ForumAnswers';

import { superAdmin, admin, user } from '../Roles';

export default [
    {
        path: '/Forum',
        name: 'forums',
        component: Forum,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin, admin, user],
            navigation: {
                type: { name: 'Groups', groupId: 'Community' },
                priority: 0,
                icon: 'mdi-forum-outline',
                name: 'Foren',
                navigationKey: 'FORUMS'
            }
        }
    },
    {
        path: '/Threads',
        name: 'threads',
        component: Threads,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin, admin, user]
        }
    },
    {
        path: '/Answers',
        name: 'answers',
        component: Answers,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin, admin, user]
        }
    }
];
