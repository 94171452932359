<template>
    <v-card height="100%" flat class="d-flex flex-column">
        <v-container>
            <v-row>
                <v-col :cols="6">
                    <v-input
                        prepend-icon="mdi-account-heart"
                        :messages="['Hiermit setzen Sie den gewünschten Menüpunkt als Favorit für alle neu erstellten Admins/Lehrende']"
                    >
                        {{ $t('Admin Standard-Favorit') }}
                    </v-input>
                </v-col>
                <v-col :cols="6">
                    <v-input
                        prepend-icon="mdi-account-heart-outline"
                        :messages="['Hiermit setzen Sie den gewünschten Menüpunkt als Favorit für alle neu erstellten Lernenden']"
                    >
                        {{ $t('Nutzer Standard-Favorit') }}
                    </v-input>
                </v-col>
                <v-col :cols="6">
                    <v-input prepend-icon="mdi-eye" :messages="['Hiermit setzen Sie die Sichtbarkeit des gewünschten Menüpunkt für alle Admins/Lehrende']">
                        {{ $t('Admin Menüpunkt Sichtbarkeit') }}
                    </v-input>
                </v-col>
                <v-col :cols="6">
                    <v-input prepend-icon="mdi-eye-outline" :messages="['Hiermit setzen Sie die Sichtbarkeit des gewünschten Menüpunkt für alle Lernenden']">
                        {{ $t('Nutzer Menüpunkt Sichtbarkeit') }}
                    </v-input>
                </v-col>
            </v-row>
        </v-container>
        <v-divider />
        <!-- <pre>{{groupMap}}</pre> -->
        <v-container>
            <v-row>
                <v-col :cols="12">
                    <v-list>
                        <v-list-group prepend-icon="mdi-folder-outline" v-for="[key, value] in groupMap" :key="key">
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ $t(key) }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </template>
                            <v-list-item v-for="navItem in value" :key="navItem.navigation.name">
                                <v-list-item-icon>
                                    <v-icon>{{ navItem.navigation.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ $t(navItem.navigation.name) }}
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <v-btn icon :disabled="!isAdmin(navItem)" @click="setPresetState(navItem, 'adminDefault')">
                                        <v-icon>{{ adminDefaultIcon(navItem) }}</v-icon>
                                    </v-btn>
                                </v-list-item-icon>
                                <v-list-item-icon>
                                    <v-btn icon :disabled="!isAdmin(navItem)" @click="setPresetState(navItem, 'adminHidden')">
                                        <v-icon>{{ adminHiddenIcon(navItem) }}</v-icon>
                                    </v-btn>
                                </v-list-item-icon>
                                <v-list-item-icon>
                                    <v-btn icon :disabled="!isUser(navItem)" @click="setPresetState(navItem, 'userDefault')">
                                        <v-icon>{{ userDefaultIcon(navItem) }}</v-icon>
                                    </v-btn>
                                </v-list-item-icon>
                                <v-list-item-icon>
                                    <v-btn icon :disabled="!isUser(navItem)" @click="setPresetState(navItem, 'userHidden')">
                                        <v-icon>{{ userHiddenIcon(navItem) }}</v-icon>
                                    </v-btn>
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list-group>
                    </v-list>
                </v-col>
            </v-row>
        </v-container>
        <v-spacer />
        <v-card-actions>
            <v-btn block color="success" @click="save">
                {{ $t('Speichern') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import SystemSettingsMixin from '../../../Components/SystemSettings/Mixins/SystemSettingsMixin';

export default {
    name: 'navigation-menu-settings',
    data: () => ({
        presets: {}
    }),
    mixins: [SystemSettingsMixin],
    computed: {
        validKeys() {
            return this.$router.options.routes
                .filter((route) => route && route.meta && route.meta.navigation && route.meta.navigation.navigationKey)
                .map(
                    ({
                        meta: {
                            navigation: { navigationKey: key }
                        }
                    }) => key
                );
        },
        navigationMenu() {
            let navi = this.$router.options.routes
                .map(({ meta }) => meta)
                .filter((route) => !!route && route.navigation && !route.roleSet.includes('ROOT'))
                .map(({ navigation, roleSet }) => ({
                    roleSet,
                    navigation
                }));
            const singleGroups = navi.filter((route) => route.navigation.type.name === 'SingleGroup');
            const groups = navi.filter((route) => route.navigation.type.name === 'Groups');
            const utility = navi.filter((route) => route.navigation.type.name === 'Utility');
            singleGroups.sort((a, b) =>
                b.navigation.name.localeCompare(a.navigation.name, undefined, {
                    numeric: true,
                    sensitivity: 'base'
                })
            );
            groups.sort((a, b) =>
                a.navigation.type.groupId.localeCompare(b.navigation.type.groupId, undefined, {
                    numeric: true,
                    sensitivity: 'base'
                })
            );
            return [...singleGroups, ...groups, ...utility];
        },
        groupMap() {
            return Object.entries(
                this.navigationMenu.reduce((acc, curr) => {
                    const groupId = curr.navigation.type.groupId;
                    if (!!groupId && groupId !== '') {
                        if (acc[groupId]) {
                            let map = [...acc[groupId], curr];
                            map.sort((a, b) =>
                                a.navigation.name.localeCompare(b.navigation.name, undefined, {
                                    numeric: true,
                                    sensitivity: 'base'
                                })
                            );
                            acc[groupId] = map;
                        } else {
                            acc[groupId] = [curr];
                        }
                    }
                    return acc;
                }, {})
            );
        }
    },
    methods: {
        ...mapActions('NavigationOperations', ['GET_FAVOURITE_MAP']),
        save() {
            const presetMap = Object.entries(this.presets).reduce((acc, [key, map]) => {
                const presets = Object.entries(map).reduce((acc, [key, value]) => {
                    if (this.validKeys.includes(key)) {
                        acc[key] = value;
                    }
                    return acc;
                }, {});
                acc[key] = presets;
                return acc;
            }, {});
            this.SET_MENU_CONFIG(presetMap).then(() => this.GET_FAVOURITE_MAP());
        },
        isAdmin({ roleSet }) {
            return roleSet.includes('ADMIN');
        },
        isUser({ roleSet }) {
            return roleSet.includes('USER');
        },
        adminDefaultIcon(navigationItem) {
            return this.getPresetState(navigationItem, 'adminDefault') ? 'mdi-account-heart' : 'mdi-account-remove';
        },
        adminHiddenIcon(navigationItem) {
            return !this.getPresetState(navigationItem, 'adminHidden') ? 'mdi-eye' : 'mdi-eye-off';
        },
        userDefaultIcon(navigationItem) {
            return this.getPresetState(navigationItem, 'userDefault') ? 'mdi-account-heart-outline' : 'mdi-account-remove-outline';
        },
        userHiddenIcon(navigationItem) {
            return !this.getPresetState(navigationItem, 'userHidden') ? 'mdi-eye-outline' : 'mdi-eye-off-outline';
        },
        getPresetState({ navigation: { navigationKey } }, key) {
            if (this.presets.hasOwnProperty(key)) {
                return !!this.presets[key][navigationKey];
            } else {
                return false;
            }
        },
        setPresetState({ navigation: { navigationKey } }, key) {
            if (this.presets.hasOwnProperty(key)) {
                if (!this.presets[key][navigationKey]) {
                    this.$set(this.presets[key], navigationKey, true);
                } else {
                    this.$set(this.presets[key], navigationKey, !this.presets[key][navigationKey]);
                }
            } else {
                this.$set(this.presets[key], navigationKey, true);
            }
        }
    },
    mounted() {
        this.GET_MENU_CONFIG().then((presets) => (this.presets = { ...presets }));
    }
};
</script>
