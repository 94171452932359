<template>
    <v-dialog v-model="dialog" :width="$vuetify.breakpoint.thresholds[zoom]" :fullscreen="zoom === 'xl'" persistent scrollable>
        <v-card>
            <v-toolbar flat dense dark color="primary">
                <v-toolbar-title>{{ $t('Vorschau') }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                            <v-btn v-on="on" v-bind="attrs" icon @click="reduce">
                                <v-icon>{{ reduceZoomIcon }}</v-icon>
                            </v-btn>
                        </template>
                        {{ reduceZoomToolip }}
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                            <v-btn v-on="on" v-bind="attrs" icon @click="magnify">
                                <v-icon>{{ magnifyZoomIcon }}</v-icon>
                            </v-btn>
                        </template>
                        {{ magnifyZoomTooltip }}
                    </v-tooltip>
                </v-toolbar-items>
                <v-btn icon @click="() => (dialog = false)">
                    <v-icon color="white">mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="pa-0 ma-0">
                <v-container fluid>
                    <v-row>
                        <v-col :cols="12">
                            <component :is="file" :src="fileURL" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import ImageLoader from '../Generic/ImageLoader';
import FilePreviewVideo from './FilePreviewTypes/FilePreviewVideo';
import FilePreviewAudio from './FilePreviewTypes/FilePreviewAudio';
import FilePreviewPDF from './FilePreviewTypes/FilePreviewPDF';

export default {
    name: 'media-preview',
    components: {
        ImageLoader,
        FilePreviewVideo,
        FilePreviewAudio,
        FilePreviewPDF
    },
    data: () => ({
        fileURL: '',
        fileType: '',
        id: '',
        zoom: 'xs'
    }),
    props: {
        showDialog: {
            default: false,
            type: Boolean
        },
        value: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        dialog(val) {
            if (!val) {
                this.$emit('input', {});
            } else {
                Object.entries(this.value).forEach(([key, value]) => {
                    if (this.$data.hasOwnProperty(key)) {
                        this.$data[key] = value;
                    }
                });
            }
        }
    },
    computed: {
        reduceZoomIcon() {
            switch (this.zoom) {
                case 'xs':
                    return 'mdi-fullscreen';
            }
            return 'mdi-magnify-minus-outline';
        },
        magnifyZoomIcon() {
            switch (this.zoom) {
                case 'lg':
                    return 'mdi-fullscreen';
                case 'xl':
                    return 'mdi-fullscreen-exit';
            }
            return 'mdi-magnify-plus-outline';
        },
        reduceZoomToolip() {
            switch (this.zoom) {
                case 'xs':
                    return this.$t('Vollbild');
            }
            return this.$t('Verkleinern');
        },
        magnifyZoomTooltip() {
            switch (this.zoom) {
                case 'lg':
                    return this.$t('Vollbild');
                case 'xl':
                    return this.$t('Minimieren');
            }
            return this.$t('Vergrößern');
        },
        dialog: {
            get() {
                return this.showDialog;
            },
            set(value) {
                this.$emit('showDialog', value);
                if (!value) {
                    this.zoom = 'xs';
                }
            }
        },
        isFile() {
            return Object.keys(this.value).length > 0 && this.fileType && this.fileURL && this.fileURL !== '';
        },
        file() {
            if (!this.dialog) {
                return undefined;
            }
            switch (this.getFileType) {
                case 'image':
                    return ImageLoader;
                case 'video':
                    return FilePreviewVideo;
                case 'audio':
                    return FilePreviewAudio;
                case 'application':
                    return FilePreviewPDF;
            }
            return undefined;
        },
        getFileType() {
            return this.fileType.substring(0, this.fileType.lastIndexOf('/'));
        }
    },
    methods: {
        zoomIterator() {
            return {
                next: () => {
                    switch (this.zoom) {
                        case 'xs':
                            return (this.zoom = 'sm');
                        case 'sm':
                            return (this.zoom = 'md');
                        case 'md':
                            return (this.zoom = 'lg');
                        case 'lg':
                            return (this.zoom = 'xl');
                        case 'xl':
                            return (this.zoom = 'xs');
                    }
                },
                previous: () => {
                    switch (this.zoom) {
                        case 'xs':
                            return (this.zoom = 'xl');
                        case 'sm':
                            return (this.zoom = 'xs');
                        case 'md':
                            return (this.zoom = 'sm');
                        case 'lg':
                            return (this.zoom = 'md');
                        case 'xl':
                            return (this.zoom = 'lg');
                    }
                }
            };
        },
        magnify() {
            this.zoomIterator().next();
        },
        reduce() {
            this.zoomIterator().previous();
        }
    }
};
</script>
