import axios from '../../plugins/Axios/axios';

const state = {
    selectedLanguage: 'de',
    languages: [],
    availableLanguages: [
        { shortLang: 'de', longLang: 'Deutsch' },
        { shortLang: 'us', longLang: 'English' },
        { shortLang: 'ua', longLang: 'Українська' },
        { shortLang: 'ru', longLang: 'Русский' }
    ]
};

const getters = {
    getSelectedLanguage() {
        return state.selectedLanguage;
    },
    getAvailableLanguages() {
        return state.availableLanguages;
    },
    getLanguages() {
        return state.languages;
    }
};

const actions = {
    GET_AVAILABLE_LANGUAGES({ commit }) {
        return axios
            .get('/language')
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateLanguages', response);
                return response;
            });
    },
    SET_SELECTED_LANGUAGE({ commit }, language) {
        commit('updateSelectedLanguage', language);
    }
};

const mutations = {
    clear: (state) => {},
    updateSelectedLanguage: (state, value) => {
        state.selectedLanguage = value;
    },
    updateLanguages: (state, value) => (state.languages = value)
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
