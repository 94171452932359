<template>
    <div>
        <v-toolbar dense flat dark color="secondary">
            <v-toolbar-title v-show="hasChatPartner">{{ getSelectedChatGroup.name }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
                <PullUpMenu ref="groupModal" :label="$t('Gruppen')" icon="mdi-account-group">
                    <GroupChatDrawer v-model="pullUpMenuModel" @selected="onSelectGroup" />
                </PullUpMenu>
            </v-toolbar-items>
        </v-toolbar>
        <v-card flat outlined class="scroll chatContainer" ref="container" @scroll="handleScroll">
            <v-card-text>
                <v-layout column>
                    <v-flex v-for="(message, index) in messages" :key="`group-chat-${index}`">
                        <v-layout v-if="isFirstNewMessage(index)">
                            <v-flex align-self-center>
                                <v-divider class="primary" />
                            </v-flex>
                            <v-flex shrink align-start>
                                <span class="primary--text px-2 text-body-2">{{ $t('Neue Nachrichten') }}</span>
                            </v-flex>
                            <v-flex align-self-center>
                                <v-divider class="primary" />
                            </v-flex>
                        </v-layout>
                        <MessageBlock :value="message" />
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
        <v-card flat outlined>
            <v-card-text>
                <v-text-field
                    v-if="showText"
                    counter="1000"
                    color="accent"
                    clearable
                    :label="$t('Geben Sie eine Nachricht ein ...')"
                    type="text"
                    :hint="$t('Mindestens 1 Zeichen')"
                    prepend-icon="mood"
                    v-model="message"
                    :disabled="!hasChatPartner"
                    :rules="[rules.required, rules.limit]"
                    @keyup.enter.exact="sendGroupMessage"
                >
                    <template v-slot:append>
                        <v-icon @click="sendGroupMessage" color="primary" :disabled="!hasMessage">mdi-send</v-icon>
                    </template>
                    <template v-slot:prepend>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="showText = !showText">mdi-arrow-expand</v-icon>
                            </template>
                            <span>{{ $t('Textfeld erweitern') }}</span>
                        </v-tooltip>
                    </template>
                </v-text-field>
                <v-textarea
                    v-else
                    counter="1000"
                    color="accent"
                    clearable
                    :label="$t('Geben Sie eine Nachricht ein ...')"
                    type="text"
                    v-model="message"
                    prepend-icon="mood"
                    :hint="$t('Mindestens 1 Zeichen')"
                    :disabled="!hasChatPartner"
                    :rules="[rules.required, rules.limit]"
                    @keyup.enter.exact="sendGroupMessage"
                    no-resize
                >
                    <template v-slot:append>
                        <v-icon @click="sendGroupMessage" color="primary" :disabled="!hasMessage">mdi-send</v-icon>
                    </template>
                    <template v-slot:prepend>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="showText = !showText">mdi-arrow-collapse</v-icon>
                            </template>
                            <span>{{ $t('Textfeld verkleinern') }}</span>
                        </v-tooltip>
                    </template>
                </v-textarea>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import ChatMixin from '../Mixins/ChatMixin';
import MessageBlock from '../Helpers/MessageBlock';
import PullUpMenu from '../../Generic/PullUpMenu/PullUpMenu.vue';
import GroupChatDrawer from './GroupChatDrawer.vue';

export default {
    name: 'group-chat-editor',
    mixins: [ChatMixin],
    components: { MessageBlock, PullUpMenu, GroupChatDrawer },
    data: () => ({
        message: '',
        showText: true,
        showMessageEditor: false,
        maxRenderedMessages: 10,
        rules: {
            required: (v) => (!!v && v.length >= 1) || 'Mindestens 1 Zeichen',
            limit: (v) => (!!v && v.length <= 1000) || 'Maximal 1000 Zeichen'
        }
    }),
    watch: {
        getSelectedChatGroup({ id: newId, isAdminGroup: newIsAdminGroup }, { id: oldId }) {
            if (newId !== oldId) {
                this.showMessageEditor = true;
                this.maxRenderedMessages = 10;
                this.CHAT_GET_GROUP_MESSAGES_BY_ID({
                    id: newId,
                    isAdminGroup: newIsAdminGroup
                });
            }
        }
    },
    computed: {
        pullUpMenuModel: {
            get() {
                return this.getSelectedChatGroup;
            },
            set(value) {
                this.SET_SELECTED_CHAT_GROUP(value);
            }
        },
        hasMessage() {
            return !!this.message && this.message.trim().length > 0;
        },
        messages() {
            const array = this.getMessages(this.getSelectedChatGroup.id);
            return array.slice(Math.max(array.length - this.maxRenderedMessages, 0));
        },
        hasChatPartner() {
            return Object.keys(this.getSelectedChatGroup).length > 0;
        }
    },
    methods: {
        isFirstNewMessage(index) {
            return this.messages.findIndex((message) => message.hasOwnProperty('isNewMessage')) === index;
        },
        handleScroll(value) {
            if (value.target.scrollTop === 0 && Math.max(this.getMessages(this.getSelectedChatGroup.id).length - this.maxRenderedMessages, 0) > 0) {
                this.maxRenderedMessages += 1;
                value.target.scrollTop += 100;
            }
        },
        sendGroupMessage() {
            if (this.message === '' || this.message.length > 1000) {
                return;
            } else {
                this.CHAT_SEND_GROUP_MESSAGE({
                    senderId: this.getLoggedInAdmin.id,
                    receiverId: this.getSelectedChatGroup.id,
                    group: this.getSelectedChatGroup,
                    content: this.message
                });
                this.message = '';
            }
        },
        scrollDown() {
            this.$vuetify.goTo(0, {
                duration: 500,
                offset: -this.$refs.container.$el.scrollHeight,
                easing: 'easeInOutCubic',
                container: this.$refs.container
            });
            this.maxRenderedMessages += 1;
        },
        onSelectGroup() {
            this.$refs.groupModal.close();
            this.$nextTick(() => {
                this.$refs.groupModal.$parent.$el.querySelector('#v-btn').blur();
            });
        }
    },
    mounted() {
        window.addEventListener('ChatUpdate', this.scrollDown);
        if (Object.keys(this.getSelectedChatGroup).length > 0) {
            this.showMessageEditor = true;
            this.maxRenderedMessages = 10;
            this.CHAT_GET_GROUP_MESSAGES_BY_ID({
                id: this.getSelectedChatGroup.id,
                isAdminGroup: this.getSelectedChatGroup.isAdminGroup
            });
        }
    },
    beforeDestroy() {
        window.removeEventListener('ChatUpdate', this.scrollDown);
    }
};
</script>

<style scoped>
.chatContainer {
    --chat-container-max-height: calc(100vh - 366px);
    max-height: var(--chat-container-max-height);
    height: var(--chat-container-max-height);
}
</style>
