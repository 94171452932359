<template>
    <v-card flat>
        <v-toolbar flat dense text color="transparent">
            <v-spacer />
            <v-toolbar-items>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon @click="create" color="success" :disabled="getLoggedInAdmin.role !== 'SUPER_ADMIN'">
                            <v-icon medium>mdi-folder-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Kataloggruppe erstellen') }}</span>
                </v-tooltip>
            </v-toolbar-items>
        </v-toolbar>
    </v-card>
</template>

<script>
import AuthoringToolMixin from '../Mixins/AuthoringtoolMixin';
import AuthoringToolSort from '../Sort/AuthoringToolSort';

export default {
    name: 'root-view',
    mixins: [AuthoringToolMixin],
    data: () => ({
        showAddUpdateCatalogGroup: false,
        showFilter: false,
        selectedSort: AuthoringToolSort[0],
        sortList: AuthoringToolSort,
        pagination: {}
    }),
    props: {
        search: {
            type: String,
            default: ''
        }
    },
    methods: {
        onClick(object) {
            this.$emit('input', object);
        },
        create() {
            this.$emit('switchView', {
                component: 'ADD_CATALOG_GROUP',
                data: {},
                type: 'ADD'
            });
        },
        filter(items, search) {
            if (search.trim() === '') {
                return items;
            }
            let buildString = [...new Set(search.split(' '))].filter((word) => word !== '');
            return items.filter((item) =>
                buildString.some((word) =>
                    Object.values(item)
                        .filter((val) => typeof val === 'string' || typeof val === 'number')
                        .map((val) => {
                            if (val === 'number') {
                                return val.toString();
                            }
                            return val;
                        })
                        .join(' ')
                        .toLowerCase()
                        .includes(word.toLowerCase())
                )
            );
        },
        sort(items) {
            items.sort((a, b) =>
                a.name.localeCompare(b.name, undefined, {
                    numeric: true,
                    sensitivity: 'base'
                })
            );
            return items;
        }
    }
};
</script>
