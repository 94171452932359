<template>
    <v-card>
        <Toolbar :toolbarTitle="$t('Kursmanager - Bewertungstabellen')" :lastPath="-1" :showDrawer="false" />
        <GradingTables :isAdmin="true" />
    </v-card>
</template>

<script>
import CourseMixin from '../../../Components/CourseLearning/CourseManager/Mixins/CourseMixin';

import Toolbar from '../../../Components/Generic/Toolbar';
import Editor from '../../../Components/Generic/Editor/Editor';

import GradingTables from '../../../Components/CourseLearning/CourseManager/GradingTables';
import AddUpdateGradingTable from '../../../Components/CourseLearning/CourseManager/AddUpdateGradingTable';
import DeleteGradingTable from '../../../Components/CourseLearning/CourseManager/DeleteGradingTable';

export default {
    name: 'grading-tables',
    mixins: [CourseMixin],
    components: {
        Toolbar,
        GradingTables
    },
    data: () => ({
        isCourseManagerValue: false
    }),
    computed: {
        permissions() {
            return {
                add: this.isCourseManagerValue,
                update: this.isCourseManagerValue,
                delete: this.isCourseManagerValue,
                eval: this.isCourseManagerValue
            };
        },
        isSelected() {
            return Object.keys(this.getSelectedGradingTable).length > 0;
        }
    },
    mounted() {
        this.isCourseManager().then((response) => (this.isCourseManagerValue = response));
    }
};
</script>
