<template>
    <v-container fluid ma-0 pa-0 fill-height>
        <v-layout column>
            <v-flex shrink>
                <Toolbar
                    :toolbarTitle="$t('Autorentool')"
                    tooltipMessage="Hier können Sie Kataloggruppen, Kataloguntergruppen, Kataloge uns Wissenseinheiten ansehen, hinzufügen, bearbeiten und löschen."
                />
            </v-flex>
            <v-flex grow>
                <AuthoringTool />
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import Toolbar from '../../Components/Generic/Toolbar';
import AuthoringTool from '../../Components/Authoringtool/AuthoringTool';

export default {
    name: 'catalog-group',
    components: {
        Toolbar,
        AuthoringTool
    }
};
</script>
