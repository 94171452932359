<template>
    <v-card flat height="100%">
        <v-toolbar dark flat color="secondary">
            <v-btn icon @click="close()">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ getSelectedScorm.localTitle }}</v-toolbar-title>
            <v-spacer />
        </v-toolbar>
        <v-card-text class="fill-height" v-bind:style="{ color: $vuetify.theme.dark ? 'white' : 'black', fontSize: 1 + 'rem' }">
            <v-container style="height: 100%" fluid>
                <v-row>
                    <v-col :cols="showCardAtBottom ? 12 : 9">
                        <v-container>
                            <v-row>
                                <v-col :cols="getColWidth">
                                    <span>{{ $t('Name') }}:</span>
                                </v-col>
                                <v-col :cols="12 - getColWidth">
                                    <span>{{ getSelectedScorm.localTitle }}</span>
                                </v-col>
                            </v-row>
                            <v-row align="center" justify="center">
                                <v-col :cols="getColWidth">
                                    <span> {{ $t('Lizenzmodell') + ':' }} </span>
                                </v-col>
                                <v-col :cols="12 - getColWidth">
                                    <v-select
                                        v-model="body.scormPackageType"
                                        :persistent-hint="true"
                                        :hint="selectedType.hint"
                                        :items="licenses"
                                        item-text="text"
                                        item-value="type"
                                        :label="$t('Wählen Sie ihr Lizenzmodell für den Scorm aus')"
                                        @change="onSelect"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <span> {{ $t('Einschreibemodell') + ':' }}</span>
                                </v-col>
                                <v-col :cols="12 - getColWidth">
                                    <v-select
                                        v-model="body.enrollmentType"
                                        :persistent-hint="true"
                                        :hint="getHint"
                                        :items="enrollmentModels"
                                        item-text="text"
                                        item-value="type"
                                        :label="$t('Wählen Sie aus, ob sich Lernende selbst eintragen können')"
                                    />
                                </v-col>
                            </v-row>
                            <v-row v-if="body.scormPackageType === 'PAY_PER_USE'">
                                <v-col :cols="getColWidth">
                                    <span>
                                        {{ $t('Anzahl an Lizenzen') + `:` }}
                                    </span>
                                </v-col>
                                <v-col :cols="12 - getColWidth">
                                    <v-form v-model="valid" ref="form">
                                        <v-text-field
                                            v-model="body.maxLicense"
                                            class="mt-0 pt-0"
                                            hide-details
                                            single-line
                                            type="number"
                                            :rules="rules('greaterEqualsZero', 'onlyNumbers', 'notEmpty', 'wholeNumber', 'notTooBig')"
                                        ></v-text-field>
                                    </v-form>
                                </v-col>
                            </v-row>
                            <v-row v-if="body.scormPackageType">
                                <template v-if="body.scormPackageType === 'PAY_PER_USE'">
                                    <v-col :cols="getColWidth">
                                        <span>
                                            {{ $t('Preis pro Lizenz') + ':' }}
                                        </span>
                                    </v-col>
                                    <v-col :cols="12 - getColWidth">
                                        <span>
                                            {{ selectedScormType.price + ' € ' + $t('pro Lizenz') }}
                                            <!-- <template v-if="selectedScormType.producer === 'Sonic'">{{ $t('Rabatte') + `: ${showDiscount()}` }}</template> -->
                                        </span>
                                    </v-col>
                                </template>
                            </v-row>
                            <v-row v-if="body.scormPackageType">
                                <v-col :cols="getColWidth">
                                    <span>
                                        {{ $t('Preis für die Laufzeit') + ':' }}
                                    </span>
                                </v-col>
                                <v-col :cols="12 - getColWidth">
                                    {{ `${viewScormPrice(selectedScormType, false)} €` }}
                                </v-col>
                            </v-row>
                            <v-row v-if="body.scormPackageType">
                                <v-col :cols="getColWidth">
                                    <span>
                                        {{ $t('Mwst.') + ':' }}
                                    </span>
                                </v-col>
                                <v-col :cols="12 - getColWidth">
                                    <span>
                                        {{ '19%' }}
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row v-if="body.scormPackageType" class="font-weight-bold" v-bind:style="{ fontSize: 1.1 + 'rem' }">
                                <v-col :cols="getColWidth">
                                    <span> {{ $t('Total Price') + ':' }} </span>
                                </v-col>
                                <v-col :cols="12 - getColWidth">
                                    <span> {{ `${viewScormPrice(selectedScormType, true)} €` }}</span>
                                </v-col>
                            </v-row>
                            <v-row v-if="body.scormPackageType">
                                <v-col :cols="getColWidth">
                                    <span>{{ $t('Laufzeit (Monate)') }}:</span>
                                </v-col>
                                <v-col :cols="12 - getColWidth">
                                    <span>{{ selectedScormType.months }}</span>
                                </v-col>
                            </v-row>
                            <v-row v-if="body.scormPackageType">
                                <v-col :cols="getColWidth">
                                    <span>{{ $t('Zahlungsart') + ':' }} </span>
                                </v-col>
                                <v-col :cols="12 - getColWidth">
                                    <span>{{ $t('Kauf auf Rechnung') }}</span>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                    <v-col :cols="3" v-if="!showCardAtBottom">
                        <v-card :elevation="12" outlined class="rounded-xl" v-if="getLoggedInAdmin.role === 'SUPER_ADMIN'">
                            <ImageLoader :src="getPhoto()" :alignCenter="true" />
                            <v-card-subtitle class="card-title" v-if="getSelectedScorm.germanSubCategory">
                                <v-icon> mdi-target </v-icon>
                                {{ germanSubCategory }}
                            </v-card-subtitle>
                            <v-card-subtitle class="card-title">
                                <v-icon> mdi-clock-outline </v-icon>
                                {{ timeText() }}
                            </v-card-subtitle>
                            <v-card-actions class="justify-center">
                                <v-chip :color="isValid() ? 'success' : 'secondary'" @click="checkShowModal()">
                                    {{ $t('Kaufen') }}
                                </v-chip>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col :cols="12" v-if="showCardAtBottom">
                        <v-card :elevation="12" outlined class="rounded-xl" v-if="getLoggedInAdmin.role === 'SUPER_ADMIN'">
                            <ImageLoader :src="getPhoto()" :alignCenter="true" />
                            <v-card-subtitle class="card-title" v-if="getSelectedScorm.germanSubCategory">
                                <v-icon> mdi-target </v-icon>
                                {{ germanSubCategory }}
                            </v-card-subtitle>
                            <v-card-subtitle class="card-title">
                                <v-icon> mdi-clock-outline </v-icon>
                                {{ timeText() }}
                            </v-card-subtitle>
                            <v-card-actions class="justify-center">
                                <v-chip :color="isValid() ? 'success' : 'secondary'" @click="checkShowModal()">
                                    {{ $t('Kaufen') }}
                                </v-chip>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-dialog v-model="showModal" persistent :width="$vuetify.breakpoint.thresholds.sm" :fullscreen="$vuetify.breakpoint.xs">
            <ConfirmationWindow :showModal="computedSelectedModal" @buy="buy()" :checkboxText="getCheckboxText" @closeModal="closeModal()" />
        </v-dialog>
    </v-card>
</template>
<script>
import ScormMixin from '../Mixins/ScormMixin';
import ImageLoader from '../../Generic/ImageLoader';
import ConfirmationWindow from './ConfirmationWindow';

export default {
    name: 'buy-scorm',
    mixins: [ScormMixin],
    components: { ImageLoader, ConfirmationWindow },
    data() {
        return {
            picture: '',
            body: {
                scormPackageType: '',
                maxLicense: 0,
                totalBruttoPrice: 0,
                totalNettoPrice: 0,
                months: 12,
                pricePerLicense: 0,
                tax: 19,
                enrollmentType: ''
            },
            licenses: [
                { type: 'COURSE_RENT', text: 'Kurs Mieten', hint: 'Miete über ein Jahr' },
                { type: 'PAY_PER_USE', text: 'Pay Per Use', hint: 'Jeder Lernende verbraucht eine Lizenz' }
            ],
            enrollmentModels: [
                { type: 'NO_SELF_ENROLLMENT', text: 'Keine Selbsteinschreibung möglich', hint: 'Lernende können sich nicht selbst in den Kurs einschreiben' },
                {
                    type: 'ON_REQUEST',
                    text: 'Selbsteinschreibung auf Anfrage',
                    hint: 'Lernende können eine Selbsteinschreibung anfragen. Sie erhalten eine Mail, falls sich ein:e Lernende:r einschreiben will.'
                },
                { type: 'SELF_ENROLL', text: 'Selbsteinschreibung möglich', hint: 'Lernende können sich selbst einschreiben' }
            ],
            showModal: false,
            selectedScormType: {},
            valid: false
        };
    },
    computed: {
        computedSelectedModal: {
            get() {
                return this.showModal;
            },
            set(value) {
                this.showModal = value;
            }
        },
        selectedType() {
            if (!this.body.scormPackageType.length) {
                return '';
            }
            return this.licenses.find((license) => license.type === this.body.scormPackageType);
        },
        confirm() {
            return this.body && this.body.scormPackageType !== '';
        },
        germanSubCategory() {
            return this.getSelectedScorm.germanSubCategory;
        },
        getCheckboxText() {
            return (
                this.$t('Hiermit bestätige ich den Kauf auf Rechnung des oben genannten Kurses über') +
                ' ' +
                this.viewScormPrice(this.selectedScormType, true) +
                '€.'
            );
        },
        getHint() {
            if (!(this.body && this.body.enrollmentType)) {
                return '';
            }
            return this.enrollmentModels.filter((model) => model.type === this.body.enrollmentType)[0].hint;
        },
        showCardAtBottom() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return true;
                case 'sm':
                    return true;
                case 'md':
                    return false;
                case 'lg':
                    return false;
                case 'xl':
                    return false;
                default:
                    return false;
            }
        },
        getColWidth() {
            return this.showCardAtBottom ? 5 : 2;
        }
    },
    methods: {
        getPhoto() {
            if (this.picture === '') {
                this.GET_MEDIA_BY_ID(this.getSelectedScorm.scormPackageId)
                    .then((blob) => {
                        const filereader = new FileReader();
                        filereader.onload = () => {
                            this.picture = filereader.result;
                        };
                        filereader.readAsDataURL(blob);
                    })
                    .catch(() => {
                        this.picture = this.getDefaultCoursePicture;
                    });
            }
            return this.picture;
        },
        closeModal() {
            this.computedSelectedModal = false;
        },
        checkShowModal() {
            if (!this.isValid()) return;
            this.showModal = true;
        },
        isValid() {
            if (!this.body) return false;
            if (this.body.enrollmentType == '') return false;
            if (this.body.scormPackageType === 'COURSE_RENT') return true;
            return this.confirm && this.valid;
        },
        timeText() {
            if (!(this.getSelectedScorm && this.getSelectedScorm.learningMinutes)) return 'Keine Zeit verfügbar';
            switch (this.getLanguage) {
                case 'de':
                    return this.getSelectedScorm.learningMinutes + ' Minuten';
                case 'en':
                    return this.getSelectedScorm.learningMinutes + ' minutes';
                default:
                    return this.getSelectedScorm.learningMinutes + ' Minuten';
            }
        },
        rules(...rules) {
            const nonEmpty = (v) => {
                if (typeof v === 'string') {
                    return v.trim() !== '';
                }
                return v !== '';
            };
            return rules
                .map((rule) => {
                    if (rule === 'notEmpty') {
                        return (v) => nonEmpty(v) || this.$t('Das Feld darf nicht leer sein');
                    }
                    if (rule === 'onlyNumbers') {
                        return (v) => !isNaN(v) || this.$t('Es sind nur Zahlen zugelassen');
                    }
                    if (rule === 'greaterEqualsZero') {
                        return (v) => v >= 1 || this.$t('Die Angegebene Zahl muss größer oder gleich 1 sein');
                    }
                    if (rule === 'password') {
                        return (v) => (this.superAdmin.active ? this.rules('notEmpty') : null);
                    }
                    if (rule === 'wholeNumber') {
                        return (v) => v % 1 == 0 || this.$t('Die angegebene Zahl muss eine ganze Zahl sein');
                    }
                    if (rule === 'notTooBig') {
                        return (v) => v <= 9007199254740992 || this.$t('Die angegebene Zahl darf nicht zu groß sein');
                    }
                    return null;
                })
                .filter((rule) => rule !== null);
        },
        close() {
            this.SET_VIEW({ component: '', data: this.getSelectedScorm, type: '', tabs: 0 });
        },
        setHints() {
            this.licenses.map((license) => (license.hint = this.$t(license.hint)));
            this.licenses.map((license) => (license.text = this.$t(license.text)));
        },
        buy() {
            this.body.totalBruttoPrice = this.countScormPrice(this.selectedScormType, true);
            this.body.totalNettoPrice = this.countScormPrice(this.selectedScormType, false);
            this.body.months = 12;
            this.body.pricePerLicense = this.selectedScormType.price;
            this.BUY_SCORM({ id: this.getSelectedScorm.id, request: this.body }).then((boughtScorm) => {
                this.SET_VIEW({ component: '', data: this.getSelectedScorm, type: '', tabs: 1 });
                this.closeModal();
            });
        },
        onSelect(type) {
            this.selectedScormType = this.getSelectedScorm.scormPackagePrices.find((scorm) => scorm.scormPackageType === type);
            if (type === 'COURSE_RENT') {
                this.body.maxLicense = 0;
            }
        },
        countScormPrice(scormObj, withTax) {
            if (scormObj.scormPackageType === 'COURSE_RENT') {
                return withTax ? (scormObj.price * 1.19).toFixed(2) : scormObj.price.toFixed(2);
            }
            if (scormObj.scormPackageType === 'PAY_PER_USE' && scormObj.producer === 'Pinktum') {
                return withTax ? (this.body.maxLicense * scormObj.price * 1.19).toFixed(2) : (this.body.maxLicense * scormObj.price).toFixed(2);
            }
            if (scormObj.scormPackageType === 'PAY_PER_USE' && scormObj.producer === 'Sonic') {
                // let typeObj = this.getSelectedScorm.scormPackagePrices.find((typeObj) => typeObj.totalUsers === this.body.maxLicense);
                // if (typeObj) return withTax ? (typeObj.price * 1.19).toFixed(2) : typeObj.price.toFixed(2);
                // return withTax ? (this.body.maxLicense * scormObj.price * 1.19).toFixed(2) : (this.body.maxLicense * scormObj.price).toFixed(2);
                return withTax ? (this.body.maxLicense * scormObj.price * 1.19).toFixed(2) : (this.body.maxLicense * scormObj.price).toFixed(2);
            }
        },
        viewScormPrice(scormObj, withTax) {
            let price = this.countScormPrice(scormObj, withTax);
            if (!price) return '0,00';
            price = price.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            return price;
        },
        showDiscount() {
            if (this.getSelectedScorm.scormPackagePrices) {
                let tenObj = this.getSelectedScorm.scormPackagePrices.find((typeObj) => typeObj.totalUsers === 10);
                let hundredObj = this.getSelectedScorm.scormPackagePrices.find((typeObj) => typeObj.totalUsers === 100);
                return tenObj && hundredObj ? `10 = ${tenObj.price} €, 100 = ${hundredObj.price} €` : '';
            }
        }
    },
    beforeMount() {
        this.setHints();
    },
    activated() {
        this.body = {
            scormPackageType: '',
            maxLicense: 0
        };
    },
    beforeDestroy() {
        this.closeModal();
        this.close();
    }
};
</script>

<style lang="scss">
.modal-layout {
    position: relative;
    .close {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.label {
    min-width: 400px;
    margin-bottom: 15px;
    h2 {
        width: calc(50% - 5px);
        &:first-child {
            text-align: right;
        }
        &:last-child {
            text-align: left;
        }
    }
}
</style>
