import axios from '../../../plugins/Axios/axios';

const state = {};

const getters = {};

const actions = {
    GET_GRADING_TABLE_TEMPLATE({ commit }, request) {
        const { id: gradingTableTempId } = request;
        return axios.get(`/courselearning/gradingtabletemplate/${gradingTableTempId}`).then((response) => response.data.data);
    },
    CREATE_GRADING_TABLE_TEMPLATE({ commit }, request) {
        return axios.post(`/courselearning/gradingtabletemplate`, request).then((response) => response.data.data);
    },
    UPDATE_GRADING_TABLE_TEMPLATE({ commit }, request) {
        const { id: gradingTableTempId } = request;
        return axios.patch(`/courselearning/gradingtabletemplate/${gradingTableTempId}`).then((response) => response.data.data);
    },
    DELETE_GRADING_TABLE_TEMPLATE({ commit }, request) {
        const { id: gradingTableTempId } = request;
        return axios.delete(`/courselearning/gradingtabletemplate/${gradingTableTempId}`);
    }
};

const mutations = {
    clear: (state) => {}
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
