<template>
    <v-card>
        <v-toolbar dark flat dense color="secondary">
            <v-toolbar-title>{{ $t('Löschen bestätigen') }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-container grid-list-md>
                <v-layout row wrap>
                    <v-flex xs12>
                        <span>{{ $t('Sind Sie sicher, dass Sie den folgenden Wikieintrag löschen wollen: ') }}</span>
                        <span class="px-2">:</span>
                        <span class="primary--text">{{ value.name }}</span>
                    </v-flex>
                    <v-flex xs12>
                        <v-checkbox
                            v-model="confirmDelete"
                            :label="$t('Bestätigen')"
                            :hint="$t('Wenn Sie bereits gelöschte Daten wieder herstellen möchten, wenden Sie sich bitte an den Support.')"
                            persistent-hint
                        />
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-btn block color="error" :disabled="!confirmDelete" @click="submit">
                {{ $t('Löschen') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'wiki-delete-confirm',
    data: () => ({
        confirmDelete: false
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        onSubmit: {
            type: Function,
            default: () => ({})
        },
        onClose: {
            type: Function,
            default: () => ({})
        }
    },
    methods: {
        submit() {
            this.onSubmit()(this.value);
        },
        close() {
            this.onClose();
        }
    },
    activated() {
        this.confirmDelete = false;
    }
};
</script>
