import axios from '../../../plugins/Axios/axios';

const state = {};

const getters = {};

const actions = {
    GET_ACTIVITY_TYPES({ commit }) {
        return axios.get(`courselearning/activities/types`).then((response) => response.data.data);
    },
    // FILE ACTIVITY
    GET_FILE_ACTIVITY_TEMPLATE({ commit }, request) {
        const { id: fileUploadTempId } = request;
        return actions.get(`/courselearning/fileuploadactivity/${fileUploadTempId}`).then((response) => response.data.data);
    },
    CREATE_FILE_ACTIVITY_TEMPLATE({ commit }, request) {
        return axios.post(`/courselearning/fileuploadactivity`, request).then((response) => response.data.data);
    },
    UPDATE_FILE_ACTIVITY_TEMPLATE({ commit }, request) {
        const { id: fileUploadTempId, ...file } = request;
        return axios.patch(`/courselearning/fileuploadactivity/${fileUploadTempId}`, file).then((response) => response.data.data);
    },
    DELETE_FILE_ACTIVITY_TEMPLATE({ commit }, request) {
        const { id: fileUploadTempId } = request;
        return axios.delete(`/courselearning/fileuploadactivity/${fileUploadTempId}`);
    },
    // CHECK ACTIVITY
    GET_CHECK_ACTIVITY_TEMPLATE({ commit }, request) {
        const { id: fileUploadTempId } = request;
        return axios.get(`/courselearning/selfcheckactivity/${fileUploadTempId}`).then((response) => response.data.data);
    },
    CREATE_CHECK_ACTIVITY_TEMPLATE({ commit }, request) {
        return axios.post(`/courselearning/selfcheckactivity`, request).then((response) => response.data.data);
    },
    UPDATE_CHECK_ACTIVITY_TEMPLATE({ commit }, request) {
        const { id: selfCheckTempId } = request;
        return axios.patch(`/courselearning/selfcheckactivity/${selfCheckTempId}`).then((response) => response.data.data);
    },
    DELETE_CHECK_ACTIVITY_TEMPLATE({ commit }, request) {
        const { id: selfCheckTempId } = request;
        return axios.delete(`/courselearning/selfcheckactivity/${selfCheckTempId}`).then((response) => response.data.data);
    },
    // TEXTFIELD ACTIVITY
    GET_TEXTFIELD_ACTIVITY_TEMPLATE({ commit }, request) {
        const { id: textfieldActivityId } = request;
        return axios.get(`}/courselearning/textfieldactivity/${textfieldActivityId}`).then((response) => response.data.data);
    },
    CREATE_TEXTFIELD_ACTIVITY_TEMPLATE({ commit }, request) {
        return axios.post(`/courselearning/textfieldactivity`, request).then((response) => response.data.data);
    },
    UPDATE_TEXTFIELD_ACTIVITY_TEMPLATE({ commit }, request) {
        const { id: textfieldActivityId, ...textfieldActivity } = request;
        return axios.patch(`/courselearning/textfieldactivity/${textfieldActivityId}`, textfieldActivity).then((response) => response.data.data);
    },
    DELETE_TEXTFIELD_ACTIVITY_TEMPLATE({ commit }, request) {
        const { id: textfieldActivityId } = request;
        return axios.delete(`/courselearning/textfieldactivity/${textfieldActivityId}`);
    }
};

const mutations = {
    clear: (state) => {}
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
