<template>
    <v-card v-if="items.length > 0">
        <v-toolbar text dark dense color="secondary">
            <v-toolbar-title class="text-body-2">{{ $t(label) }}</v-toolbar-title>
        </v-toolbar>
        <v-container>
            <v-data-iterator
                :items="items"
                :no-data-text="$t('')"
                :no-results-text="$t('')"
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-text': $t('Link pro Seite'),
                    'items-per-page-options': [5, 10, 15, { value: -1, text: $t('Alle') }]
                }"
                :hideDefaultFooter="items.length <= 5"
                justify-space-around
                row
                wrap
            >
                <template #default="{ items }">
                    <v-row>
                        <v-col cols="auto" v-for="item in items" :key="item.id">
                            <span class="primary--text pointer" @click="onClick(item)">{{ item.title }}</span>
                        </v-col>
                    </v-row>
                </template>
            </v-data-iterator>
        </v-container>
    </v-card>
</template>
<script>
export default {
    name: 'knowledge-content-links',
    props: {
        items: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: 'Links'
        }
    },
    methods: {
        onClick(link) {
            if (!this.disabled) {
                this.$emit('input', link);
            }
        }
    }
};
</script>
