import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters('CustomerForumOperations', [
            'getForums',
            'getThreads',
            'getAnswers',
            'getSelectedForum',
            'getSelectedThread',
            'getSelectedAnswer',
            'getForumPath'
        ])
    },
    methods: {
        ...mapActions('CustomerForumOperations', [
            'GET_ALL_FORUMS',
            'GET_SINGLE_FORUM',
            'CREATE_FORUM',
            'UPDATE_FORUM',
            'DELETE_FORUM',
            'GET_FORUM_THREADS',
            'CREATE_FORUM_THREAD',
            'UPDATE_FORUM_THREAD',
            'DELETE_FORUM_THREAD',
            'GET_FORUM_THREAD_ANSWERS',
            'CREATE_FORUM_THREAD_ANSWER',
            'UPDATE_FORUM_THREAD_ANSWER',
            'DELETE_FORUM_THREAD_ANSWER',
            'SET_SELECTED_FORUM',
            'SET_SELECTED_THREAD',
            'SET_SELECTED_ANSWER',
            'REMOVE_FORUM_PATH',
            'ADD_FORUM_PATH'
        ])
    }
};
