<template>
    <v-card>
        <v-form ref="form" v-model="validate">
            <v-container grid-list-md>
                <v-layout wrap>
                    <v-flex xs6>
                        <v-text-field
                            :value="computedMin"
                            @input="(value) => (computedMin = value)"
                            type="number"
                            :label="$t('Untere Wortgrenze')"
                            :hint="$t('Geben Sie eine Mindestmenge an Wörter an')"
                            :rules="[maxRule, minRule]"
                            persistent-hint
                        />
                    </v-flex>
                    <v-flex xs6>
                        <v-text-field
                            v-model="computedMax"
                            type="number"
                            :label="$t('Obere Wortgrenze')"
                            :hint="$t('Geben Sie eine Höchstmenge an Wörter an')"
                            :rules="[minRule]"
                            persistent-hint
                        />
                    </v-flex>
                </v-layout>
            </v-container>
        </v-form>
    </v-card>
</template>

<script>
export default {
    name: 'text-field-activity',
    data: () => ({
        validate: false,
        minWordCount: 0,
        maxWordCount: 0
    }),
    computed: {
        computedMin: {
            get() {
                return this.minWordCount;
            },
            set(value) {
                this.minWordCount = value;
                this.$refs.form.validate();
                this.serialize();
            }
        },
        computedMax: {
            get() {
                return this.maxWordCount;
            },
            set(value) {
                this.maxWordCount = value;
                this.$refs.form.validate();
                this.serialize();
            }
        }
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        serialize() {
            const { validate, ...rest } = this.$data;
            this.$emit('input', rest);
        },
        maxRule(value) {
            const num = parseInt(value);
            return num <= parseInt(this.maxWordCount) || this.$t('Die Mindestwortmenge darf nicht größer sein als die Maximalwortmenge');
        },
        minRule(value) {
            const num = parseInt(value);
            return num >= 0 || this.$t('Geben Sie eine Zahl größer 0 an');
        }
    },
    activated() {
        if (this.value.hasOwnProperty('minWordCount')) {
            this.minWordCount = this.value.minWordCount;
        }
        if (this.value.hasOwnProperty('maxWordCount')) {
            this.maxWordCount = this.value.maxWordCount;
        }
        this.serialize();
    }
};
</script>
