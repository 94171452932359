import axios from '../../../plugins/Axios/axios';

const state = {
    courseTopics: [],
    selectedTopic: {},
    schedulingMyTopic: [],
    defaultTopicPicture: ''
};

const getters = {
    getCourseTopics() {
        return state.courseTopics.sort((a, b) => a.order - b.order);
    },
    getSelectedTopic() {
        return state.selectedTopic;
    },
    getSchedulingMyTopic() {
        return state.schedulingMyTopic;
    },
    getDefaultTopicPicture() {
        return state.defaultTopicPicture;
    }
};

const actions = {
    SET_TOPICS({ commit }, topics) {
        commit('updateCourseTopics', topics);
    },
    SET_SELECTED_TOPIC({ commit }, topic) {
        commit('updateSelectedTopic', topic);
    },
    GET_TOPIC({ commit }, { id: topicTempId }) {
        return axios
            .get(`/courselearning/topicinstances/${topicTempId}`)
            .then((response) => response.data.data)
            .then((response) => commit('updateCourseTopics', response));
    },
    GET_COURSE_TOPICS({ commit }, { id: courseTempId }) {
        return axios
            .get(`/courselearning/courseinstances/${courseTempId}/topics`)
            .then((response) => response.data.data)
            .then((response) => commit('updateCourseTopics', response));
    },
    CREATE_TOPIC({ commit }, request) {
        return axios.post(`/courselearning/topicinstances`, request).then((response) => response.data.data);
    },
    UPDATE_TOPIC({ commit }, request) {
        const { id: topicTempId, ...topic } = request;
        return axios
            .patch(`/courselearning/topicinstances/${topicTempId}`, topic)
            .then((response) => response.data.data)
            .then((response) => commit('updateCourseTopics', response));
    },
    UPDATE_COURSE_TOPICS({ commit, dispatch }, { course, topics }) {
        const topicIds = topics.map((topic) => ({ id: topic.id }));
        const { id: courseTempId } = course;
        return axios
            .patch(`/courselearning/courseinstances/${courseTempId}/topics`, topicIds)
            .then((response) => response.data.data)
            .then((response) => {
                dispatch('CourseOperations/GET_COURSE', course, { root: true });
                commit('updateCourseTopics', response);
            });
    },
    DELETE_TOPIC({ commit }, request) {
        const { id: topicTempId } = request;
        return axios.delete(`/courselearning/topicinstances/${topicTempId}`).then(() => commit('deleteCourseTopic', request));
    },
    GET_TOPIC_SCHEDULING({ commit }, { id: topicInsId }) {
        return axios.get(`/courselearning/topicinstances/${topicInsId}/scheduling`).then((response) => response.data.data);
    },
    UPDATE_TOPIC_SCHEDULING({ commit }, { id: topicInsId, ...topic }) {
        return axios
            .patch(`/courselearning/topicinstances/${topicInsId}/scheduling`, topic)
            .then((response) => response.data.data)
            .then((response) => commit('updateScheduling', response));
    },
    REORDER_TOPICS({ commit }, { courseId, ...topic }) {
        return axios
            .patch(`/courselearning/courseinstances/${courseId}/topics/reorder`, topic)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateCourseTopics', response);
                return response;
            });
    },
    GET_TOPIC_PICTURE({ commit }) {
        return axios
            .get(`/courselearning/topicpicture`, { responseType: 'blob' })
            .then((response) => response.data)
            .then((response) => {
                const fileReader = new FileReader();
                fileReader.addEventListener(
                    'load',
                    () => {
                        commit('updateDefaultPicture', fileReader.result);
                    },
                    false
                );
                fileReader.readAsDataURL(response);
            });
    }
};

const mutations = {
    clear: (state) => {
        state.courseTopics = [];
        state.selectedTopic = {};
        state.schedulingMyTopic = [];
        state.defaultTopicPicture = '';
    },
    updateDefaultPicture: (state, picture) => (state.defaultTopicPicture = picture),
    updateSelectedTopic: (state, request) => {
        state.selectedTopic = request;
    },
    updateCourseTopics: (state, request) => {
        if (Array.isArray(request)) {
            state.courseTopics = request;
        } else {
            if (request.hasOwnProperty('id')) {
                if (state.selectedTopic.id === request.id) {
                    state.selectedTopic = request;
                }
                if (state.courseTopics.map((course) => course.id).includes(request.id)) {
                    state.courseTopics = state.courseTopics.map((course) => {
                        if (course.id === request.id) {
                            return request;
                        }
                        return course;
                    });
                } else {
                    state.courseTopics.push(request);
                }
            }
        }
    },
    deleteCourseTopic: (state, request) => {
        if (request.hasOwnProperty('id')) {
            if (state.selectedTopic.id === request.id) {
                state.selectedTopic = {};
            }
            const find = state.courseTopics.map((data) => data.id).indexOf(request.id);
            if (find !== -1) {
                state.courseTopics.splice(find, 1);
            }
        }
    },
    updateScheduling: (state, response) => {
        if (Array.isArray(response)) {
            state.schedulingMyTopic = response;
        } else {
            if (state.schedulingMyTopic.map((thread) => thread.id).includes(response.id)) {
                state.schedulingMyTopic = state.schedulingMyTopic.map((thread) => {
                    if (thread.id === response.id) {
                        return response;
                    }
                    return thread;
                });
            } else {
                state.schedulingMyTopic.push(response);
            }
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
