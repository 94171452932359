import axios from '../../plugins/Axios/axios';

const state = {
    adminCatalogSubGroupPermissionList: [],
    selectedCatalogSubGroup: {
        itemType: 'ADMINCATALOGSUBGROUPPERMISSION',
        itemTitle: 'Admin Catalog Subgroup Permission',
        path: '/CatalogGroup',
        data: {}
    }
};

// getters
const getters = {
    getAdminCatalogSubGroupPermissionList(state) {
        return state.adminCatalogGroupPermissionList;
    }
};

// actions
const actions = {
    GET_ADMINCATALOGSUBGROUPPERMISSION_LIST({ commit }) {
        return axios.get(`admins?page=0`).then((response) => {
            commit('updateAdminCatalogSubGroupPermissionList', response.data.data.admins);
            return response;
        });
    }
};

// mutations
const mutations = {
    clear: (state) => {
        state.adminCatalogSubGroupPermissionList = [];
        state.selectedCatalogSubGroup = {
            itemType: 'ADMINCATALOGSUBGROUPPERMISSION',
            itemTitle: 'Admin Catalog Subgroup Permission',
            path: '/CatalogGroup',
            data: {}
        };
    },
    updateAdminCatalogGroupPermissionList: (state, adminList) => {
        state.adminList = adminList;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
