<template>
    <v-menu v-model="showDatePicker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="100%">
        <template v-slot:activator="{ on }">
            <v-text-field
                color="accent"
                autocomplete="new-password"
                attach
                :disabled="textBoxDisabled"
                prepend-icon="mdi-calendar-blank"
                readonly
                :label="label"
                v-model="dateTextComputed"
                v-on="on"
                hide-details
            />
        </template>
        <v-date-picker
            ref="dayPicker"
            first-day-of-week="1"
            v-model="dateTextComputed"
            scrollable
            :locale="language"
            :reactive="true"
            @input="showDatePicker = false"
            type="date"
            :min="min"
        ></v-date-picker>
    </v-menu>
</template>

<script>
import moment from 'moment';

export default {
    data() {
        return {
            showDatePicker: false
        };
    },
    props: {
        dateText: {
            default: '',
            type: String
        },
        textBoxDisabled: {
            default: false,
            type: Boolean
        },
        min: String,
        label: String,
        language: {
            default: 'de',
            type: String
        }
    },
    computed: {
        dateTextComputed: {
            get() {
                if (this.dateText === '') {
                    return moment().format('YYYY-MM-DD');
                }
                return moment(this.dateText).format('YYYY-MM-DD');
            },
            set(value) {
                this.$emit('dateDataEmit', value);
            }
        }
    }
};
</script>
