import { mapActions, mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('CatalogGroupOperations', ['getCatalogGroupList', 'getSelectedCatalogGroup']),
        ...mapGetters('CatalogSubgroupOperations', ['getSelectedCatalogSubGroup', 'getCatalogSubGroupList']),
        ...mapGetters('CatalogOperations', ['getSelectedCatalog', 'getCatalogList', 'getValidCatalogList']),
        ...mapGetters('KnowledgeUnitOperations', ['getSelectedKnowledgeUnit']),
        ...mapGetters('AuthoringToolOperations', ['getTree', 'getParent', 'getMapData', 'getKnowledgeUnits', 'getPermissions', 'getView', 'getCatalogs'])
    },
    methods: {
        ...mapActions('CatalogOperations', [
            'GET_CATALOG_LIST',
            'SELECT_CATALOG',
            'ADD_CATALOG',
            'DELETE_CATALOG',
            'UPDATE_CATALOG',
            'COPY_CATALOG',
            'MOVE_CATALOG'
        ]),
        ...mapActions('CatalogSubgroupOperations', [
            'GET_CATALOGSUBGROUP_LIST',
            'SELECT_CATALOGSUBGROUP',
            'ADD_CATALOGSUBGROUP',
            'DELETE_CATALOGSUBGROUP',
            'UPDATE_CATALOGSUBGROUP'
        ]),
        ...mapActions('CatalogGroupOperations', [
            'GET_CATALOGGROUP_LIST',
            'SELECT_CATALOGGROUP',
            'ADD_CATALOGGROUP',
            'UPDATE_CATALOGGROUP',
            'DELETE_CATALOGGROUP_BY_ID'
        ]),
        ...mapActions('KnowledgeUnitOperations', [
            'CREATE_KNOWLEDGEUNIT',
            'UPDATE_KNOWLEDGEUNIT',
            'MOVE_KNOWLEDGEUNIT',
            'COPY_KNOWLEDGEUNIT',
            'DELETE_KNOWLEDGE_UNIT',
            'SELECT_CATALOGSUBGROUP_MOVECOPY',
            'RESET_SELECTED_OPTION',
            'GET_KNOWLEDGEUNIT_LIST',
            'GET_KNOWLEDGEUNITS_BY_CATALOG',
            'RESET_KNOWLEDGEUNIT_LIST',
            'SELECT_KNOWLEDGEUNIT',
            'UPDATE_KNOWLEDGEUNIT_ORDER',
            'UPDATE_KNOWLEDGEUNIT_STORE',
            'RESET_KNOWLEDGEUNIT_STORE',
            'UPDATE_OPERATIONTYPE',
            'UPDATE_TABLE_ROWPERPAGE',
            'UPDATE_EXCEPTION_REMOVE',
            'UPDATE_EXCEPTION_KEEP'
        ]),
        ...mapActions('AuthoringToolOperations', ['BUILD_MAP', 'LOAD_KNOWLEDGE_UNITS', 'SET_VIEW', 'LOAD_PERMISSIONS']),
        isAuthoringToolType({ id }) {
            if (id) {
                const type = id.substring(0, 3);
                if (type.includes('cat')) {
                    return 'CATALOG';
                }
                if (type.includes('cg')) {
                    return 'CATALOG_GROUP';
                }
                if (type.includes('csg')) {
                    return 'CATALOG_SUBGROUP';
                }
                if (type.includes('ku')) {
                    return 'KNOWLEDGE_UNIT';
                }
            }
            return undefined;
        },
        calculateTreePath(value) {
            let currentNode = value;
            let path = [];
            while (currentNode) {
                path.unshift({ ...currentNode });
                currentNode = this.getParent(currentNode);
            }
            return path;
        }
    }
};
