<template>
    <v-list-group prepend-icon="mdi-folder-outline">
        <template v-slot:activator>
            <v-list-item-content>
                <v-list-item-title class="caption">{{ $t(groupKey) }}</v-list-item-title>
            </v-list-item-content>
        </template>
        <NavigationDrawerSingleTile v-for="(item, index) in groupValue" :key="`${item.meta.navigation.name}-${index}`" :value="item" />
    </v-list-group>
</template>
<script>
import NavigationDrawerSingleTile from './NavigationDrawerSingleTile';

export default {
    name: 'navigation-drawer-group-tile',
    components: {
        NavigationDrawerSingleTile
    },
    props: {
        value: {
            type: [Object, Array],
            default: () => ({})
        }
    },
    computed: {
        groupKey() {
            if (Array.isArray(this.value)) {
                if (this.value.length === 2) {
                    return this.value[0];
                }
            }
            return undefined;
        },
        groupValue() {
            if (Array.isArray(this.value)) {
                if (this.value.length === 2) {
                    return this.value[1];
                }
            }
            return undefined;
        }
    }
};
</script>
