import axios from '../../plugins/Axios/axios';

const state = {};

const getters = {};

const actions = {
    GET_KNOWLEDGE_UNIT_TOTAL({ commit }) {
        return axios.get(`/dashboard/knowledgeunits`).then((response) => response.data.data);
    },
    GET_KNOWLEDGE_CONTENT_TOTAL({ commit }) {
        return axios.get(`/dashboard/knowledgecontents`).then((response) => response.data.data);
    },
    GET_VOTES_TOTAL({ commit }) {
        return axios.get(`/dashboard/votes`).then((response) => response.data.data);
    },
    GET_USER_ACTIVITY({ commit }, customRange) {
        return axios.get(`/dashboard/useractivity?days=${customRange}`).then((response) => response.data.data);
    },
    GET_QUIZ_ACTIVITY({ commit }, request) {
        return axios.post(`/dashboard/quizactivity`, request).then((response) => response.data.data);
    },
    GET_KNOWLEDGE_UNIT_RANKING({ commit }) {
        return axios.get(`/dashboard/knowledgeunitranking`).then((response) => response.data.data);
    }
};

const mutations = {
    clear: (state) => {}
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
