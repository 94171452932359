<template>
    <v-card height="100%" class="d-flex flex-column" outlined>
        <QuizHeader
            :value="task"
            :settings="settings"
            :enableSubmit="validQuiz"
            :valid="valid"
            :onSubmit="onSubmit"
            :intermission="isCorrectIntermission"
            @media="handleMedia"
            @submit="handleSubmit"
            @knowledgeBase="handleKnowledgeBase"
            :isNext="showKnowledgeExplanation"
            @isNext="handleKnowledgeExplanation(false)"
        />
        <v-container fluid ma-0 pa-0>
            <v-row align="center">
                <v-col>
                    <v-divider />
                </v-col>
                <v-col v-if="!isContentOnly" class="shrink">
                    <span>{{ $t('Antwortoptionen') }}</span>
                </v-col>
                <v-col v-if="!isContentOnly">
                    <v-divider />
                </v-col>
            </v-row>
        </v-container>
        <v-slide-x-transition mode="out-in">
            <component
                :is="bodySwitch"
                v-model="computedKnowledgeUnit"
                @media="handleMedia"
                :valid="valid"
                @valid="handleValid"
                :correctAnswer="voteExplanation"
                onlyShowResult
            />
        </v-slide-x-transition>
        <!-- <QuizBody v-model="computedKnowledgeUnit" @media="handleMedia" :valid="valid" @valid="handleValid" /> -->
        <v-spacer />
        <v-container fluid>
            <v-row justify="center">
                <v-col cols="auto">
                    <catalog-material
                        :catalog="knowledgeUnit && knowledgeUnit.catalogOptions ? knowledgeUnit.catalogOptions : {}"
                        :icon="{ icon: false, color: 'secondary' }"
                        position="top"
                        onlyDownload
                    >
                        {{ $t('Lernmaterialien') }}
                    </catalog-material>
                </v-col>
            </v-row>
        </v-container>
        <KnowledgeContentPreview
            showNext
            :showPagination="!isKnowledgeContentSkippingDisabled"
            v-model="computedKnowledgeBase"
            :items="computedKnowledgeContents"
            @isPreview="(value) => (computedPreview = value)"
            :isPreview="computedPreview"
            :knowledgeUnit="knowledgeUnit"
            :hasMaterial="true"
        />
        <!-- <v-dialog persistent scrollable v-model="showKnowledgeExplanation" :fullscreen="$vuetify.breakpoint.xs" :width="$vuetify.breakpoint.thresholds.sm">
            <KnowledgeExplanation :correctAnswer="voteExplanation" onlyShowResult>
                <template v-slot:header>
                    <v-toolbar dark dense flat color="secondary">
                        <v-toolbar-title>{{ $t('Erklärung') }}</v-toolbar-title>
                        <v-spacer />
                        <v-btn icon @click="handleKnowledgeExplanation(false)">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                </template>
                <template v-slot:footer>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn outlined color="primary" @click="handleKnowledgeExplanation(false)">
                            {{ $t('Weiter') }}
                        </v-btn>
                    </v-card-actions>
                </template>
            </KnowledgeExplanation>
        </v-dialog> -->
    </v-card>
</template>

<script>
import QuizHeader from './QuizHeader';
import QuizBody from './QuizOptions';
import KnowledgeContentPreview from '../../KnowledgeContent/KnowledgeContentPreview';
import KnowledgeExplanation from './QuizResult';
import CatalogMaterial from '../../CatalogMaterial/CatalogMaterial';

export default {
    name: 'quiz',
    components: {
        QuizHeader,
        QuizBody,
        KnowledgeContentPreview,
        KnowledgeExplanation,
        CatalogMaterial
    },
    data: () => ({
        showKnowledgeBase: false,
        selectedKnowledgeContent: {},
        showKnowledgeExplanation: false,
        task: {},
        valid: true,
        intermission: false,
        isCorrectIntermission: undefined,
        voteExplanation: []
    }),
    props: {
        isPreview: {
            type: Boolean,
            default: false
        },
        settings: {
            type: Object,
            default: () => ({
                quiz: {
                    catalogs: []
                }
            })
        },
        onSubmit: {
            type: Function,
            default: () => new Promise(),
            validator: (func) => func instanceof Promise || 'onSubmit must be of type Promise'
        },
        onStart: {
            type: Function,
            default: () => new Promise(),
            validator: (func) => func instanceof Promise || 'onStart must be of type Promise'
        }
    },
    computed: {
        bodySwitch() {
            return this.showKnowledgeExplanation ? KnowledgeExplanation : QuizBody;
        },
        isContentOnly() {
            return (
                (this.knowledgeUnit && this.knowledgeUnit.questionType && this.knowledgeUnit.questionType === 'CONTENT_ONLY') || this.showKnowledgeExplanation
            );
        },
        computedPreview: {
            get() {
                return this.isPreview;
            },
            set(value) {
                this.$emit('isPreview', value);
            }
        },
        computedKnowledgeBase: {
            get() {
                return this.knowledgeUnit &&
                    this.knowledgeUnit.knowledgeBase &&
                    this.knowledgeUnit.knowledgeBase.knowledgeContents &&
                    this.knowledgeUnit.knowledgeBase.knowledgeContents.length > 0 &&
                    this.showKnowledgeBase
                    ? Object.keys(this.selectedKnowledgeContent).length === 0
                        ? this.knowledgeUnit.knowledgeBase.knowledgeContents[0]
                        : this.selectedKnowledgeContent
                    : {};
            },
            set(value) {
                this.selectedKnowledgeContent = value;
                if (Object.keys(value).length === 0) {
                    this.showKnowledgeBase = false;
                }
            }
        },
        computedKnowledgeContents() {
            return this.knowledgeUnit && this.knowledgeUnit.knowledgeBase && this.knowledgeUnit.knowledgeBase.knowledgeContents
                ? this.knowledgeUnit.knowledgeBase.knowledgeContents
                : [];
        },
        isKnowledgeContentSkippingDisabled() {
            return this.knowledgeUnit && this.knowledgeUnit.knowledgeBase && this.knowledgeUnit.knowledgeBase.knowledgeContents
                ? this.knowledgeUnit.knowledgeBase.knowledgeContents.reduce((current, next) => current || next.viewTillEnd, false)
                : false;
        },
        validQuiz() {
            return (
                (Object.keys(this.computedKnowledgeBase).length === 0 && this.isPreview && !this.showKnowledgeExplanation) ||
                (this.knowledgeUnit &&
                    this.knowledgeUnit.knowledgeBase &&
                    this.knowledgeUnit.knowledgeBase.knowledgeContents &&
                    this.knowledgeUnit.knowledgeBase.knowledgeContents.length === 0 &&
                    !this.showKnowledgeExplanation)
            );
        },
        showQuizBody() {
            return (
                this.validQuiz &&
                this.knowledgeUnit &&
                this.knowledgeUnit.questionType &&
                this.knowledgeUnit.questionType !== 'CONTENT_ONLY' &&
                !this.intermission
            );
        },
        computedKnowledgeUnit: {
            get() {
                return this.knowledgeUnit;
            },
            set(value) {
                this.task = { ...this.task, knowledgeUnit: [value] };
            }
        },
        knowledgeUnit() {
            return Object.keys(this.task).length > 0 && this.task.knowledgeUnit && Array.isArray(this.task.knowledgeUnit) ? this.task.knowledgeUnit[0] : {};
        }
    },
    methods: {
        reset() {
            this.$nextTick(() => {
                this.intermission = false;
                this.isCorrectIntermission = undefined;
            });
        },
        handleShowKnowledgeBase(value) {
            this.showKnowledgeBase = value;
        },
        handleShowKnowledgeExplanation(value) {
            this.showKnowledgeExplanation = value;
        },
        handlePreview(value) {
            this.$emit('isPreview', value);
        },
        handleMedia(value) {
            this.$emit('media', value);
        },
        startQuiz() {
            return this.onStart().then((response) => {
                this.task = response;
                this.$emit('isPreview', false);
                this.$nextTick(() => {
                    this.intermission = false;
                    this.showKnowledgeBase = true;
                });
            });
        },
        handleKnowledgeBase() {
            this.showKnowledgeBase = true;
        },
        handleKnowledgeExplanation(value) {
            this.showKnowledgeExplanation = value;
            if (!value) {
                this.startQuiz();
            }
        },
        handleSubmit(value) {
            if (!this.intermission) {
                this.intermission = true;
                this.onSubmit(value)
                    .then(
                        ({ isCorrect, knowledgeUnit, hasAnswered, hasAnsweredCorrect }) =>
                            new Promise((resolve, reject) => {
                                this.isCorrectIntermission = isCorrect;
                                if (knowledgeUnit) {
                                    this.voteExplanation = [
                                        {
                                            ...knowledgeUnit,
                                            hasAnswered,
                                            hasAnsweredCorrect
                                        }
                                    ];
                                }
                                setTimeout(() => {
                                    this.reset();
                                    return isCorrect ? resolve() : reject();
                                }, 1750);
                            })
                    )
                    .then(() => this.startQuiz())
                    .catch(() => (this.showKnowledgeExplanation = true));
            }
        },
        handleValid(value) {
            this.valid = value;
        }
    },
    activated() {
        this.startQuiz();
    },
    deactivated() {
        this.task = {};
        this.reset();
    },
    mounted() {
        this.startQuiz();
    },
    destroyed() {
        this.task = {};
        this.reset();
    }
};
</script>
