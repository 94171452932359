<template>
    <v-card height="100%">
        <Toolbar
            :toolbarTitle="$t('Wiki')"
            :showDrawer="getView.component === ''"
            @backEmit="handleLastPath"
            tooltipMessage="Hier können Sie alle Wikis anschauen. Jeder kann neue Wikis und Änderungsvorschläge für bestehende Wikis erstellen"
        />
        <keep-alive>
            <component :is="componentSwitch" :onSubmit="onSubmit" :onClose="reset" :type="getView.type" :value="getView.data" @switchView="handleSwitchView" />
        </keep-alive>
    </v-card>
</template>

<script>
import Toolbar from '../../Components/Generic/Toolbar';
import WikiDisplay from '../../Components/Wiki/WikiDisplay';
import WikiHomepage from '../../Components/Wiki/WikiHomepage';
import AddUpdateWiki from '../../Components/Wiki/AddUpdateWiki';
import WikiDeleteConfirm from '../../Components/Wiki/Helpers/WikiDeleteConfirm';

import WikiMixin from '../../Components/Wiki/Mixins/WikiMixin';

export default {
    name: 'wiki',
    mixins: [WikiMixin],
    components: {
        Toolbar,
        WikiDisplay,
        WikiHomepage,
        AddUpdateWiki,
        WikiDeleteConfirm
    },
    computed: {
        componentSwitch() {
            if (this.getView.component !== '') {
                switch (this.getView.component) {
                    case 'UPDATE_WIKI':
                        return AddUpdateWiki;
                    case 'DELETE_WIKI':
                        return WikiDeleteConfirm;
                    case 'GET_WIKI':
                        return WikiDisplay;
                }
            }
            return WikiHomepage;
        }
    },
    methods: {
        handleLastPath() {
            if (this.getView.component === 'UPDATE_WIKI' || this.getView.component === 'DELETE_WIKI') {
                this.SET_VIEW({ component: 'GET_WIKI', type: this.getView.type, data: this.getView.data });
            } else {
                this.reset();
            }
        },
        onSubmit() {
            switch (this.getView.component) {
                case 'ADD_WIKI':
                    return (v) => this.ADD_FAQ_GROUP(v).then(() => this.reset());
                case 'UPDATE_WIKI':
                    return (v) => {
                        this.UPDATE_FAQ_GROUP({
                            id: v.id,
                            request: { name: v.name }
                        }).then(() => this.reset());
                    };
                case 'DELETE_WIKI':
                    return (v) => this.DELETE_WIKI(v).then(() => this.reset());
            }
            this.reset();
            return () => {};
        },
        reset() {
            this.SET_VIEW({ component: '', type: '', data: {} });
        },
        handleSwitchView(value) {
            this.SET_VIEW(value);
        },
        deleteWiki() {
            this.DELETE_WIKI(this.getSelectedWiki).then(() => (this.deleteDialog = false));
        }
    },
    beforeDestroy() {
        this.reset();
        this.SET_SELECTED_WIKI({});
    }
};
</script>
