<template>
    <v-card>
        <v-card-text>
            <v-toolbar dark flat color="secondary">
                <v-btn icon @click="close">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-toolbar-title> {{ submitButtonText }} </v-toolbar-title>
            </v-toolbar>
            <v-container pa-2>
                <v-form v-model="valid" ref="form">
                    <v-text-field :label="$t('Name')" v-model="name" persistent-hint :rules="[notEmpty]" />
                </v-form>
                <v-card-actions>
                    <v-btn block :color="submitButtonColor" :disabled="!valid" @click="submit">
                        {{ submitButtonText }}
                    </v-btn>
                </v-card-actions>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'add-update-faq-group',
    data: () => ({
        id: '',
        name: '',
        valid: false
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        type: {
            type: String,
            default: 'ADD'
        },
        onSubmit: {
            type: Function,
            default: () => {}
        },
        onClose: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        submitButtonColor() {
            return this.type === 'ADD' ? 'success' : 'warning';
        },
        submitButtonText() {
            return this.type === 'ADD' ? this.$t('FAQ-Gruppe erstellen') : this.$t('FAQ-Gruppe bearbeiten');
        }
    },
    methods: {
        reset() {
            this.id = '';
            this.name = '';
        },
        deserialize() {
            this.id = this.value.id;
            this.name = this.value.name;
        },
        serialize() {
            return {
                id: this.id,
                name: this.name
            };
        },
        submit() {
            this.onSubmit()(this.serialize());
        },
        close() {
            this.onClose();
        },
        notEmpty(value) {
            return (!!value && value.trim() !== '' && value.trim().length > 0) || this.$t('Das Feld darf nicht leer sein');
        }
    },
    activated() {
        if (this.type === 'ADD') {
            this.reset();
            //			if (this.value.hasOwnProperty('id')) {
            //			}
        } else {
            this.deserialize();
        }
    }
};
</script>
