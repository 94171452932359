<template>
    <v-card text>
        <v-card-text>
            <v-form v-model="valid">
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-flex xs12>
                            <v-text-field :label="$t('Name')" v-model="name" :rules="[notEmpty]" />
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field :label="$t('Beschreibung')" v-model="description" />
                        </v-flex>
                        <v-flex xs6 v-show="type === 'ADD'">
                            <v-checkbox :label="$t('Gleichnamigen Ordner in Content erstellen')" :hint="$t('')" persistent-hint v-model="copyForContent" />
                        </v-flex>
                        <v-flex xs6 v-show="type === 'ADD'">
                            <v-checkbox
                                :label="$t('Gleichnamigen Ordner im Dateimanager erstellen')"
                                :hint="$t('')"
                                persistent-hint
                                v-model="copyForFilemanager"
                            />
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn :color="computedSubmitButtonColor" block :disabled="!valid" @click="submit">
                {{ computedSubmitButtonText }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import AuthoringToolMixin from './Mixins/AuthoringtoolMixin';
import { mapActions } from 'vuex';

export default {
    name: 'add-update-catalog-group',
    mixins: [AuthoringToolMixin],
    data: () => ({
        valid: false,
        copyForContent: false,
        copyForContentLoaded: false,
        copyForFilemanager: false,
        copyForFilemanagerLoaded: false,
        filemanagerFolders: [],
        contentFolders: [],

        id: '',
        name: '',
        description: ''
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        type: {
            type: String,
            validator: (v) => ['ADD', 'UPDATE'].includes(v),
            default: 'ADD'
        },
        onSubmit: {
            type: Function,
            default: () => Promise.resolve
        }
    },
    watch: {
        copyForContent() {
            if (this.type === 'ADD') {
                if (this.contentFolders.length === 0 && !this.copyForContentLoaded) {
                    this.getContents()
                        .then((response) => response.folders)
                        .then((response) => (this.contentFolders = response));
                    this.copyForContentLoaded = true;
                }
            }
        },
        copyForFilemanager() {
            if (this.type === 'ADD') {
                if (this.filemanagerFolders.length === 0 && !this.copyForFilemanagerLoaded) {
                    this.getFilemanagerRootFolder()
                        .then((response) => response.data.data.folders)
                        .then((response) =>
                            Promise.all(response.map((folder) => this.getFilemamangerSubfolders(folder, response))).then(
                                (result) => (this.filemanagerFolders = result.flat(Infinity))
                            )
                        );
                    this.copyForFilemanagerLoaded = true;
                }
            }
        }
    },
    computed: {
        computedSubmitButtonText() {
            return this.type === 'ADD' ? this.$t('Hinzufügen') : this.$t('Bearbeiten');
        },
        computedSubmitButtonColor() {
            return this.type === 'ADD' ? 'success' : 'warning';
        },
        showUseSkipping() {
            return this.getView.component == 'ADD_CATALOG';
        }
    },
    methods: {
        ...mapActions('FileManagerOperations', {
            addFilemanagerFolder: 'CREATE_FOLDER',
            getFilemanagerSubFolder: 'GET_SUB_FOLDER_BY_ID',
            getFilemanagerRootFolder: 'GET_ROOT_FOLDER'
        }),
        ...mapActions('KnowledgeContentOperations', {
            addContentFolder: 'CREATE_KNOWLEDGE_CONTENT_FOLDER',
            getContents: 'GET_KNOWLEDGE_CONTENTS'
        }),
        serialize() {
            return {
                id: this.id,
                name: this.name,
                description: this.description,
                skippingPercentage: this.skippingPercentage,
                skippingPercentageTolerance: this.skippingPercentageTolerance,
                useSkipping: this.useSkipping
            };
        },
        reset() {
            this.contentFolders = [];
            this.filemanagerFolders = [];
            this.copyForContent = false;
            this.copyForFilemanager = false;
            this.copyForContentLoaded = false;
            this.copyForFilemanagerLoaded = false;
            this.id = '';
            this.name = '';
            this.description = '';
        },
        close() {
            this.computedDialog = false;
            this.reset();
        },
        submit() {
            if (this.type === 'ADD') {
                return this.createFilemanagerFolder()
                    .then(() => this.createContentFolder())
                    .then(() => this.onSubmit()(this.serialize()))
                    .then(() => this.close());
            }
            if (this.type === 'UPDATE') {
                return this.onSubmit()(this.serialize()).then(() => this.close());
            }
        },
        createContentFolder() {
            if (this.copyForContent) {
                if (this.getSelectedCatalog.data.hasOwnProperty('name')) {
                    const find = this.contentFolders.find((folder) => folder.name === this.getSelectedCatalog.data.name);
                    if (find) {
                        return this.addContentFolder({
                            name: this.name,
                            parentId: find.id
                        });
                    }
                }
                if (this.getSelectedCatalogSubGroup.data.hasOwnProperty('name')) {
                    const find = this.contentFolders.find((folder) => folder.name === this.getSelectedCatalogSubGroup.data.name);
                    if (find) {
                        return this.addContentFolder({
                            name: this.name,
                            parentId: find.id
                        });
                    }
                }
                if (this.getSelectedCatalogGroup.data.hasOwnProperty('name')) {
                    const find = this.contentFolders.find((folder) => {
                        return folder.name === this.getSelectedCatalogGroup.data.name;
                    });
                    if (find) {
                        return this.addContentFolder({
                            name: this.name,
                            parentId: find.id
                        });
                    }
                }
                return this.addContentFolder({ name: this.name, parentId: '' });
            }
            return new Promise((resolve) => resolve(true));
        },
        createFilemanagerFolder() {
            if (this.copyForFilemanager) {
                if (this.getSelectedCatalog.data.hasOwnProperty('name')) {
                    const find = this.filemanagerFolders.find((folder) => folder.name === this.getSelectedCatalog.data.name);
                    if (find) {
                        return this.addFilemanagerFolder({
                            name: this.name,
                            parentId: find.id
                        });
                    }
                }
                if (this.getSelectedCatalogSubGroup.data.hasOwnProperty('name')) {
                    const find = this.filemanagerFolders.find((folder) => folder.name === this.getSelectedCatalogSubGroup.data.name);
                    if (find) {
                        return this.addFilemanagerFolder({
                            name: this.name,
                            parentId: find.id
                        });
                    }
                }
                if (this.getSelectedCatalogGroup.data.hasOwnProperty('name')) {
                    const find = this.filemanagerFolders.find((folder) => {
                        return folder.name === this.getSelectedCatalogGroup.data.name;
                    });
                    if (find) {
                        return this.addFilemanagerFolder({
                            name: this.name,
                            parentId: find.id
                        });
                    }
                }
                return this.addFilemanagerFolder({
                    name: this.name,
                    parentId: ''
                });
            }
            return new Promise((resolve) => resolve(true));
        },
        getFilemamangerSubfolders({ id }, result) {
            return this.getFilemanagerSubFolder(id)
                .then((response) => response.data.data.folders)
                .then((response) => {
                    if (response.length > 0) {
                        return Promise.all(response.map((folder) => this.getFilemamangerSubfolders(folder, response))).then((res) => [...res, ...result]);
                    }
                    return result;
                });
        },
        getContentSubfolders({ id }, result) {
            return this.getContentSubFolder({ folderId: id })
                .then((response) => response.data.data.folders)
                .then((response) => {
                    if (response.length > 0) {
                        return Promise.all(response.map((folder) => this.getContentSubfolders(folder, response))).then((res) => [...res, ...result]);
                    }
                    return result;
                });
        },
        notEmpty(value) {
            return (!!value && value.trim().length > 0) || this.$t('Dieses Feld darf nicht leer sein');
        }
    },
    activated() {
        this.reset();
        if (this.type === 'UPDATE') {
            Object.entries(this.value).forEach(([key, val]) => {
                if (this.$data.hasOwnProperty(key)) {
                    this.$data[key] = val;
                }
            });
        }
    }
};
</script>
