<template>
    <v-dialog v-model="dialog" scrollable persistent :width="$vuetify.breakpoint.thresholds.xs" :fullscreen="$vuetify.breakpoint.xs">
        <template #activator="{ on, attrs }">
            <v-btn xs12 sm4 block v-bind="attrs" v-on="on">{{ $t('Android') }}</v-btn>
        </template>
        <v-card>
            <v-toolbar dense flat color="primary" dark>
                <v-toolbar-title>{{ $t('PWA installieren: Android') }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon text @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-subheader>1. {{ $t('Öffnen Sie diese Website mit dem «Chrome for Android» Browser.') }}</v-subheader>
                <v-subheader>2. {{ $t('Drücken Sie auf die «Wiselab zum Startbildschirm hinzufügen» Schaltfläche.') }}</v-subheader>
                <v-container fluid grid-list-sm>
                    <v-layout wrap>
                        <v-flex>
                            <img :src="require('@/assets/Install/installAndroid.png')" class="image" alt="lorem" width="100%" height="100%" />
                        </v-flex>
                    </v-layout>
                </v-container>
                <v-subheader>3. {{ $t('Drücken Sie dann auf «Hinzufügen».') }}</v-subheader>
                <v-container fluid grid-list-sm>
                    <v-layout wrap>
                        <v-flex>
                            <img :src="require('@/assets/Install/installAndroid1.png')" class="image" alt="lorem" width="100%" height="100%" />
                        </v-flex>
                    </v-layout>
                </v-container>
                <v-subheader>4. {{ $t('Wiselab ist zu Ihrem Startbildschirm als App hinzugefügt.') }}</v-subheader>
                <v-container fluid grid-list-sm>
                    <v-layout wrap>
                        <v-flex>
                            <img :src="require('@/assets/Install/installAndroid2.png')" class="image" alt="lorem" width="100%" height="100%" />
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'instructions-android',
    data: () => ({
        dialog: false
    })
};
</script>
