<template>
    <v-card flat height="100%">
        <v-card-text>
            <v-card>
                <v-tabs show-arrows mandatory slider-color="accent">
                    <v-tab href="#tab-1">
                        {{ computedTabTitle }}
                    </v-tab>
                    <v-tab href="#tab-2" :disabled="value.knowledgeContents.length === 0">
                        {{ $t('Content Links') }}
                    </v-tab>
                    <v-tab-item :value="'tab-1'">
                        <v-card-text class="pb-0">
                            <v-container>
                                <v-row no-gutters>
                                    <v-col>
                                        <v-row no-gutters>
                                            <v-col :cols="12">
                                                <span :class="{ title: true, limitTitle: true }">{{ value.name }}</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-row no-gutters justify="end">
                                            <v-col :cols="12">
                                                <v-icon>mdi-clock-outline</v-icon>
                                            </v-col>
                                            <v-col :cols="12">
                                                <span class="text-caption">{{ convertMinutes }}</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <!-- <v-layout class="mt-2">
                                    <v-flex class="py-0" xs10>
                                        <v-layout column>
                                            <v-flex class="pb-0">
                                                <span class="text-h6">
                                                    {{ value.name }}
                                                </span>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout wrap pl-1 pt-0 mt-0>
                                            <v-chip v-for="taxonomy in distinctTaxonomies" :key="taxonomy.name" :color="taxonomy.hexColor" dark>
                                                <span class="text-caption">{{ $t(taxonomy.name) }}</span>
                                            </v-chip>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs2>
                                        <v-layout wrap justify-end align-end class="px-3" column>
                                            <v-flex py-0>
                                                <v-icon>mdi-clock-outline</v-icon>
                                            </v-flex>
                                            <v-flex pt-0>
                                                <span class="text-caption">{{ convertMinutes }}</span>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout> -->
                                <v-expand-transition>
                                    <MyLearningGoalInfo
                                        v-show="showMoreInfo"
                                        :selectable="false"
                                        :value="value"
                                        :hideDurationInDays="value.hideDurationInDays"
                                        :isMyCourse="isMyCourse"
                                        :timeScaleInMinutes="timeScaleInMinutes"
                                        :showMoreInfo="showMoreInfo"
                                    />
                                </v-expand-transition>
                            </v-container>
                            <v-card-actions pa-0>
                                <v-layout justify-center align-center pa-0>
                                    <v-flex text-center pa-0>
                                        <v-btn icon @click.stop="toggleMoreInfo"
                                            ><v-icon>{{ showInfoIcon }}</v-icon></v-btn
                                        >
                                    </v-flex>
                                </v-layout>
                            </v-card-actions>
                        </v-card-text>
                    </v-tab-item>
                    <v-tab-item :value="'tab-2'">
                        <v-card-text class="ck ck-content scroll" style="max-height: 20vh" @click.stop>
                            <KnowledgeContent :contents="value.knowledgeContents" :selectable="false" v-model="computedSelectedKnowledgeContent" />
                        </v-card-text>
                    </v-tab-item>
                </v-tabs>
            </v-card>
            <v-card>
                <v-card-text>
                    <v-data-iterator
                        :items.sync="activities"
                        :no-data-text="$t('Keine Aktivitäten verfügbar')"
                        :no-results-text="$t('Kein Ergebnis')"
                        hide-default-footer
                        :options="{ 'items-per-page': -1 }"
                        :items-per-page="-1"
                    >
                        <template #default="{ items }">
                            <v-container>
                                <v-row>
                                    <v-col cols="auto" v-for="item in items" :key="item.id" v-show="!hideActivity(item.hideBeforeStart, item.startDate)">
                                        <MyActivity
                                            :value="item"
                                            :timeScaleInMinutes="timeScaleInMinutes"
                                            :isMyCourse="true"
                                            @activity="handleActivity"
                                            :getLearningGoalTypeName="value.activityTypeName"
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                    </v-data-iterator>
                </v-card-text>
            </v-card>
        </v-card-text>
    </v-card>
</template>

<script>
import MyActivity from '../Helpers/MyActivity';
import KnowledgeContent from '../../../KnowledgeContent/KnowledgeContentContent';
import CourseMixin from '../../CourseManager/Mixins/CourseMixin';
import MyLearningGoalInfo from '../../CourseManager/Helpers/LearningGoal/MyLearningGoalInfo';
import moment from 'moment';

export default {
    name: 'my-learning-goal',
    components: { MyActivity, MyLearningGoalInfo, KnowledgeContent },
    mixins: [CourseMixin],
    data: () => ({
        showMoreInfo: false,
        activities: []
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        isMyCourse: {
            type: Boolean,
            default: false
        },
        timeScaleInMinutes: {
            type: Number,
            default: 60
        },
        selectedKnowledgeContent: {
            type: Array,
            default: () => []
        }
    },
    watch: {
        getLearningGoalActivitiesMap: {
            immediate: true,
            handler(value) {
                if (this.value.hasOwnProperty('id')) {
                    if (value[this.value.id] !== undefined && value[this.value.id] !== null) {
                        this.activities = [...value[this.value.id]];
                        this.activities.sort((a, b) => a.order - b.order);
                    }
                }
            }
        }
    },
    computed: {
        computedTabTitle() {
            if (
                this.getSelectedAttendeeCourse.learningGoalTypeName === null &&
                this.value.typeName === null &&
                this.getSelectedTopic.learningGoalTypeName == null
            ) {
                return this.$t('Lernziel');
            } else if (this.value.typeName !== null) {
                return this.value.typeName;
            } else if (this.getSelectedTopic.learningGoalTypeName !== null) {
                return this.getSelectedTopic.learningGoalTypeName;
            }
            return this.getSelectedAttendeeCourse.learningGoalTypeName;
        },
        computedSelectedKnowledgeContent: {
            get() {
                return this.selectedKnowledgeContent;
            },
            set(value) {
                this.$emit('selectedKnowledgeContent', value);
            }
        },
        showDurationInDays() {
            if (this.isMyCourse && this.value.hideDurationInDays) {
                return '-';
            }

            return this.value.durationInDays;
        },
        emptyDescription() {
            if (this.value.description.length === 0) {
                return '-';
            } else {
                return this.value.description;
            }
        },
        computedShowClock() {
            if (this.isMyCourse && this.value.hideEstimatedWorkTimeInMinutes) {
                return false;
            }
            return true;
        },
        convertMinutes() {
            const timeUnitMinutes = this.value.estimatedWorkTimeInMinutes % this.timeScaleInMinutes;
            const timeUnit = this.value.estimatedWorkTimeInMinutes / this.timeScaleInMinutes;

            if (timeUnitMinutes.toString().length === 1) {
                return `${timeUnit.toString().split('.')[0]}:${'0' + timeUnitMinutes.toString()}`;
            }
            return `${timeUnit.toString().split('.')[0]}:${timeUnitMinutes.toString()}`;
        },
        showInfoIcon() {
            return this.showMoreInfo === false ? 'mdi-chevron-down' : 'mdi-chevron-up';
        },
        distinctTaxonomies() {
            return this.activities
                .map((activity) => activity.taxonomy)
                .filter((taxonomy) => taxonomy !== null)
                .filter((taxonomy, index, self) => self.map((tax) => tax.name).indexOf(taxonomy.name) === index);
        },
        picture() {
            if (this.value.hasOwnProperty('picture')) {
                if (this.value.picture.hasOwnProperty('fileURL')) {
                    if (this.value.picture.fileURL !== null && this.value.picture.fileURL !== undefined) {
                        return this.value.picture.fileURL;
                    }
                }
            }
            return '';
        }
    },
    methods: {
        hideActivity(hideBeforeStart, startDate) {
            if (hideBeforeStart === true && startDate > moment().format('YYYY-MM-DD')) {
                return true;
            }
            return false;
        },
        toggleMoreInfo() {
            this.showMoreInfo = !this.showMoreInfo;
        },
        handleActivity(value) {
            this.$emit('activity', value);
        }
    },
    mounted() {
        this.GET_LEARNING_GOAL_ACTIVITIES({ ...this.value, map: true });
    }
};
</script>

<style scoped>
.scroll {
    overflow-y: auto;
}
.limit {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
</style>
