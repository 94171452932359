import ArchiveTool from '../../views/ArchiveTool/ArchiveTool';

import { superAdmin, admin } from '../Roles';

export default [
    /**
     * ArchiveTool
     */
    {
        path: '/ArchiveManager',
        name: 'archiveTool',
        component: ArchiveTool,
        meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionSet: ['ARCHIVE_TOOL_MANAGEMENT'],
            requiresRole: true,
            roleSet: [superAdmin, admin],
            navigation: {
                type: { name: 'SingleGroup', groupId: 'Lernmanagement' },
                priority: 0,
                icon: 'mdi-archive',
                name: 'Archivmanager',
                navigationKey: 'ARCHIVE_MANAGER'
            }
        }
    }
];
