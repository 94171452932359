<template>
    <div>
        <Toolbar
            :toolbarTitle="$t(toolbarTitle)"
            :showDrawer="false"
            :lastPath="-1"
            tooltipMessage="Hier können Sie das gewünschte Element löschen."
            @backEmit="backEmit"
        ></Toolbar>

        <DeleteConfirm :deleteMessage="deleteMessage" :selectedItem="getSelectedTaskArchive.data" @deleteItem="deleteItem" />
    </div>
</template>

<script>
import Toolbar from '../../Components/Generic/Toolbar';
import DeleteConfirm from '../../Components/Generic/DeleteConfirm';
import TaskArchiveMixins from '../../Components/UserApp/Mixins/TaskArchiveMixins';

export default {
    mixins: [TaskArchiveMixins],
    components: {
        Toolbar,
        DeleteConfirm
    },
    data: () => ({
        toolbarTitle: 'Archiv Löschen',
        deleteMessage: 'Sind Sie sicher, dass Sie dieses Archiv löschen wollen?'
    }),
    props: {},
    computed: {},
    methods: {
        deleteItem() {
            this.DELETE_ARCHIVE_BY_ID(this.getSelectedTaskArchive.data.id).then(() => this.$router.push({ name: 'appArchive' }));
        },
        backEmit() {
            this.$router.push({ name: 'appArchive' });
        }
    }
};
</script>
