<template>
    <v-card>
        <v-toolbar dense dark flat color="secondary">
            <v-toolbar-title>{{ $t('Verfügbare Lizenzen') }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-progress-linear :indeterminate="isLoading" :value="percentage" :height="14" :color="progessColor">
                <span class="subheading text-center"> {{ licencesLeft }}/{{ getRemainingCourseLicences.maxAmount }}</span>
            </v-progress-linear>
        </v-card-text>
    </v-card>
</template>

<script>
import CourseMixin from './Mixins/CourseMixin';

export default {
    name: 'course-licences',
    mixins: [CourseMixin],
    computed: {
        isLoading() {
            return Object.keys(this.getRemainingCourseLicences).length === 0;
        },
        progessColor() {
            if (this.percentage < 50 && this.percentage > 25) {
                return 'warning';
            }
            if (this.percentage <= 25) {
                return 'error';
            }
            return 'success';
        },
        percentage() {
            if (this.getRemainingCourseLicences.maxAmount === 0) {
                return 0;
            }
            return 100 - (this.getRemainingCourseLicences.usedAmount / this.getRemainingCourseLicences.maxAmount) * 100;
        },
        licencesLeft() {
            if (this.getRemainingCourseLicences.usedAmount >= this.getRemainingCourseLicences.maxAmount) {
                return 0;
            }
            return this.getRemainingCourseLicences.maxAmount - this.getRemainingCourseLicences.usedAmount;
        }
    },
    mounted() {
        this.GET_REMAINING_COURSES();
    }
};
</script>
