<template>
    <v-card>
        <v-toolbar dark text dense color="secondary">
            <v-toolbar-title class="text-body-2">{{ $t('Status') }}</v-toolbar-title>
        </v-toolbar>
        <v-container fluid>
            <v-item-group mandatory v-model="computedState">
                <v-row justify="space-between" no-gutters>
                    <v-col cols="auto" v-for="(state, i) in states" :key="state">
                        <v-item>
                            <v-btn @click="updateStatus(i)" :text="computedState !== state" :color="color(state)" :disabled="!disabled(state)">
                                {{ $t(state) }}
                            </v-btn>
                        </v-item>
                    </v-col>
                </v-row>
            </v-item-group>
        </v-container>
    </v-card>
</template>

<script>
import KnowledgeContentMixin from '../Mixins/KnowledgeContentMixin';

export default {
    name: 'knowledge-content-state-changer',
    mixins: [KnowledgeContentMixin],
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        permission: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedState: {
            get() {
                return this.value && this.value.state && this.value.state.stateName ? this.value.state.stateName : '';
            },
            set() {}
        },
        states() {
            return ['APPROVED', 'PENDING', 'DECLINED'];
        }
    },
    methods: {
        disabled(state) {
            return this.permission || this.computedState === state;
        },
        color(state) {
            switch (state) {
                case 'APPROVED':
                    return 'success';
                case 'PENDING':
                    return 'warning';
                case 'DECLINED':
                    return 'error';
            }
            return '';
        },
        updateStatus(index) {
            this.UPDATE_KNOWLEDGE_CONTENT_STATE({
                ...this.value,
                state: this.states[index]
            }).then(() => (this.value.state.stateName = this.states[index]));
        }
    }
};
</script>
