const state = {
    errorMessages: []
};
const getters = {
    getErrorMessages(state) {
        return state.errorMessages;
    }
};
const actions = {
    CLEAR_ERROR_MESSAGES({ commit }) {
        commit('clearErrorMessages');
    },
    SET_ERROR_MESSAGES({ commit }, messages) {
        if (Array.isArray(messages)) {
            commit('setErrorMessages', messages);
        } else {
            commit('setErrorMessages', [messages]);
        }
    }
};
const mutations = {
    clear: (state) => {
        state.errorMessages = [];
    },
    clearErrorMessages: (state) => {
        state.errorMessages = [];
    },
    setErrorMessages: (state, messages) => {
        state.errorMessages = messages;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
