import axios from '../../plugins/Axios/axios';

const state = {
    groups: [],
    forums: [],
    threads: [],
    answers: [],
    selectedGroup: {},
    selectedForum: {},
    selectedThread: {},
    selectedAnswer: {},
    forumPath: []
};

const getters = {
    getGroups() {
        return state.groups;
    },
    getForums() {
        return state.forums;
    },
    getThreads() {
        return state.threads;
    },
    getAnswers() {
        return state.answers;
    },
    getSelectedGroup() {
        return state.selectedGroup;
    },
    getSelectedForum() {
        return state.selectedForum;
    },
    getSelectedThread() {
        return state.selectedThread;
    },
    getSelectedAnswer() {
        return state.selectedAnswer;
    },
    getForumPath() {
        return state.forumPath;
    }
};

const actions = {
    REMOVE_FORUM_PATH({ commit }, data) {
        commit('removeForumPath', data);
    },
    ADD_FORUM_PATH({ commit }, data) {
        commit('addForumPath', data);
    },
    SET_SELECTED_GROUP({ commit }, group) {
        commit('updateSelectedGroup', group);
    },
    SET_SELECTED_FORUM({ commit }, forum) {
        commit('updateSelectedForum', forum);
    },
    SET_SELECTED_THREAD({ commit }, thread) {
        commit('updateSelectedThread', thread);
    },
    SET_SELECTED_ANSWER({ commit }, answer) {
        commit('updateSelectedAnswer', answer);
    },
    GET_GROUPS({ commit }) {
        return Promise.all([
            axios.get('/usergroups/members').then((response) =>
                response.data.data.groups.map((group) => ({
                    ...group,
                    isAdminGroup: false
                }))
            ),
            axios.get('/admingroups/members').then((response) =>
                response.data.data.groups.map((group) => ({
                    ...group,
                    isAdminGroup: true
                }))
            )
        ]).then((response) => commit('updateGroups', response.flat()));
    },
    GET_FORUMS({ commit }, { group }) {
        const { id, isAdminGroup } = group;
        if (isAdminGroup) {
            return axios
                .get(`/admingroups/${id}/forums`)
                .then((response) => response.data.data)
                .then((response) => commit('updateForums', response));
        }
        return axios
            .get(`/usergroups/${id}/forums`)
            .then((response) => response.data.data)
            .then((response) => commit('updateForums', response));
    },
    CREATE_FORUM({ commit }, { group, forum }) {
        const { id, isAdminGroup } = group;
        if (isAdminGroup) {
            return axios
                .post(`/admingroups/${id}/forums`, forum)
                .then((response) => response.data.data)
                .then((response) => commit('updateForums', response));
        }
        return axios
            .post(`/usergroups/${id}/forums`, forum)
            .then((response) => response.data.data)
            .then((response) => commit('updateForums', response));
    },
    UPDATE_FORUM({ commit }, { group, forum }) {
        const { id: groupId, isAdminGroup } = group;
        const { id: forumId } = forum;
        if (isAdminGroup) {
            return axios
                .patch(`/admingroups/${groupId}/forums/${forumId}`, forum)
                .then((response) => response.data.data)
                .then((response) => {
                    commit('updateForums', response);
                    commit('updateSelectedForum', response);
                });
        }
        return axios
            .patch(`/usergroups/${groupId}/forums/${forumId}`, forum)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateForums', response);
                commit('updateSelectedForum', response);
            });
    },
    DELETE_FORUM({ commit }, { group, forum }) {
        const { id: groupId, isAdminGroup } = group;
        const { id: forumId } = forum;
        if (isAdminGroup) {
            return axios
                .delete(`/admingroups/${groupId}/forums/${forumId}`)
                .then((response) => response.data.data)
                .then(() => commit('deleteForum', forum));
        }
        return axios
            .delete(`/usergroups/${groupId}/forums/${forumId}`)
            .then((response) => response.data.data)
            .then(() => commit('deleteForum', forum));
    },
    GET_THREADS({ commit }, { group, forum }) {
        const { id: groupId, isAdminGroup } = group;
        const { id: forumId } = forum;
        if (isAdminGroup) {
            return axios
                .get(`/admingroups/${groupId}/forums/${forumId}/threads`)
                .then((response) => response.data.data)
                .then((response) => commit('updateThreads', response));
        }
        return axios
            .get(`/usergroups/${groupId}/forums/${forumId}/threads`)
            .then((response) => response.data.data)
            .then((response) => commit('updateThreads', response));
    },
    CREATE_THREAD({ commit }, { group, forum, thread }) {
        const { id: groupId, isAdminGroup } = group;
        const { id: forumId } = forum;
        if (isAdminGroup) {
            return axios
                .post(`/admingroups/${groupId}/forums/${forumId}/threads`, thread)
                .then((response) => response.data.data)
                .then((response) => commit('updateThreads', response));
        }
        return axios
            .post(`/usergroups/${groupId}/forums/${forumId}/threads`, thread)
            .then((response) => response.data.data)
            .then((response) => commit('updateThreads', response));
    },
    UPDATE_THREAD({ commit }, { group, forum, thread }) {
        const { id: groupId, isAdminGroup } = group;
        const { id: forumId } = forum;
        const { id: threadId } = thread;
        if (isAdminGroup) {
            return axios
                .patch(`/admingroups/${groupId}/forums/${forumId}/threads/${threadId}`, thread)
                .then((response) => response.data.data)
                .then((response) => {
                    commit('updateThreads', response);
                    commit('updateSelectedThread', response);
                });
        }
        return axios
            .patch(`/usergroups/${groupId}/forums/${forumId}/threads/${threadId}`, thread)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateThreads', response);
                commit('updateSelectedThread', response);
            });
    },
    DELETE_THREAD({ commit }, { group, forum, thread }) {
        const { id: groupId, isAdminGroup } = group;
        const { id: forumId } = forum;
        const { id: threadId } = thread;
        if (isAdminGroup) {
            return axios
                .delete(`/admingroups/${groupId}/forums/${forumId}/threads/${threadId}`)
                .then((response) => response.data.data)
                .then(() => commit('deleteThread', thread));
        }
        return axios
            .delete(`/usergroups/${groupId}/forums/${forumId}/threads/${threadId}`)
            .then((response) => response.data.data)
            .then(() => commit('deleteThread', thread));
    },
    GET_ANSWERS({ commit }, { group, forum, thread }) {
        const { id: groupId, isAdminGroup } = group;
        const { id: forumId } = forum;
        const { id: threadId } = thread;
        if (isAdminGroup) {
            return axios
                .get(`/admingroups/${groupId}/forums/${forumId}/threads/${threadId}/answers`)
                .then((response) => response.data.data)
                .then((response) => commit('updateAnswers', response));
        }
        return axios
            .get(`/usergroups/${groupId}/forums/${forumId}/threads/${threadId}/answers`)
            .then((response) => response.data.data)
            .then((response) => commit('updateAnswers', response));
    },
    CREATE_ANSWER({ commit }, { group, forum, thread, answer }) {
        const { id: groupId, isAdminGroup } = group;
        const { id: forumId } = forum;
        const { id: threadId } = thread;
        if (isAdminGroup) {
            return axios
                .post(`/admingroups/${groupId}/forums/${forumId}/threads/${threadId}/answers`, answer)
                .then((response) => response.data.data)
                .then((response) => commit('updateAnswers', response));
        }
        return axios
            .post(`/usergroups/${groupId}/forums/${forumId}/threads/${threadId}/answers`, answer)
            .then((response) => response.data.data)
            .then((response) => commit('updateAnswers', response));
    },
    UPDATE_ANSWER({ commit }, { group, forum, thread, answer }) {
        const { id: groupId, isAdminGroup } = group;
        const { id: forumId } = forum;
        const { id: threadId } = thread;
        const { id: answerId } = answer;
        if (isAdminGroup) {
            return axios
                .patch(`/admingroups/${groupId}/forums/${forumId}/threads/${threadId}/answers/${answerId}`, answer)
                .then((response) => response.data.data)
                .then((response) => {
                    commit('updateAnswers', response);
                    commit('updateSelectedAnswer', response);
                });
        }
        return axios
            .patch(`/usergroups/${groupId}/forums/${forumId}/threads/${threadId}/answers/${answerId}`, answer)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateAnswers', response);
                commit('updateSelectedAnswer', response);
            });
    },
    DELETE_ANSWER({ commit }, { group, forum, thread, answer }) {
        const { id: groupId, isAdminGroup } = group;
        const { id: forumId } = forum;
        const { id: threadId } = thread;
        const { id: answerId } = answer;
        if (isAdminGroup) {
            return axios
                .delete(`/admingroups/${groupId}/forums/${forumId}/threads/${threadId}/answers/${answerId}`)
                .then((response) => response.data.data)
                .then(() => commit('deleteAnswer', answer));
        }
        return axios
            .delete(`/usergroups/${groupId}/forums/${forumId}/threads/${threadId}/answers/${answerId}`)
            .then((response) => response.data.data)
            .then(() => commit('deleteAnswer', answer));
    }
};

const mutations = {
    clear: (state) => {
        state.forums = [];
        state.threads = [];
        state.answers = [];
        state.groups = [];
        state.selectedAnswer = {};
        state.selectedForum = {};
        state.selectedGroup = {};
        state.selectedThread = {};
    },
    updateGroups: (state, groups) => (state.groups = groups),
    updateForums: (state, response) => {
        if (Array.isArray(response)) {
            state.forums = response;
        } else {
            if (state.forums.map((forum) => forum.id).includes(response.id)) {
                state.forums = state.forums.map((forum) => {
                    if (forum.id === response.id) {
                        return response;
                    }
                    return forum;
                });
            } else {
                state.forums.push(response);
            }
        }
    },
    updateThreads: (state, response) => {
        if (Array.isArray(response)) {
            state.threads = response;
        } else {
            if (state.threads.map((thread) => thread.id).includes(response.id)) {
                state.threads = state.threads.map((thread) => {
                    if (thread.id === response.id) {
                        return response;
                    }
                    return thread;
                });
            } else {
                state.threads.push(response);
            }
        }
    },
    updateAnswers: (state, response) => {
        if (Array.isArray(response)) {
            state.answers = response;
        } else {
            if (state.answers.map((answer) => answer.id).includes(response.id)) {
                state.answers = state.answers.map((answer) => {
                    if (answer.id === response.id) {
                        return response;
                    }
                    return answer;
                });
            } else {
                state.answers.push(response);
            }
        }
    },
    deleteForum: (state, forum) => {
        const index = state.forums.map((forum) => forum.id).indexOf(forum.id);
        if (index !== -1) {
            state.forums.splice(index, 1);
        }
    },
    deleteThread: (state, thread) => {
        const index = state.threads.map((thread) => thread.id).indexOf(thread.id);
        if (index !== -1) {
            state.threads.splice(index, 1);
        }
    },
    deleteAnswer: (state, answer) => {
        const index = state.answers.map((answer) => answer.id).indexOf(answer.id);
        if (index !== -1) {
            state.answers.splice(index, 1);
        }
    },
    updateSelectedGroup: (state, group) => (state.selectedGroup = group),
    updateSelectedForum: (state, forum) => (state.selectedForum = forum),
    updateSelectedThread: (state, thread) => (state.selectedThread = thread),
    updateSelectedAnswer: (state, answer) => (state.selectedAnswer = answer),
    addForumPath: (state, data) => {
        if (state.forumPath.map((forum) => forum.id).indexOf(data.id) === -1) {
            state.forumPath.push(data);
        }
    },
    removeForumPath: (state, data) => {
        const find = state.forumPath.map((forum) => forum.id).findIndex((forum) => forum === data.id);
        if (find !== -1) {
            state.forumPath = state.forumPath.slice(0, find);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
