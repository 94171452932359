<template>
    <v-card :class="cardStyle" :width="cardWidth" v-ripple height="max-content">
        <v-card-text class="pa-2">
            <v-layout :row="$vuetify.breakpoint.mdAndUp" :column="$vuetify.breakpoint.smAndDown">
                <v-flex shrink align-self-center>
                    <v-card :height="size" :width="size" flat>
                        <v-hover :disabled="$vuetify.breakpoint.smAndDown">
                            <v-layout
                                column
                                fill-height
                                :align-start="$vuetify.breakpoint.mdAndUp"
                                :align-center="$vuetify.breakpoint.smAndDown"
                                justify-center
                                class="pa-1"
                                slot-scope="{ hover }"
                            >
                                <v-flex align-self-center justify-self-center>
                                    <v-scale-transition mode="out-in">
                                        <v-badge :value="isMediaConsumed" bottom overlap color="transparent" :key="value.id">
                                            <template v-slot:badge>
                                                <v-icon small color="primary">video_settings</v-icon>
                                            </template>
                                            <ImageLoader v-show="!onHover(hover)" class="file" :height="size" :width="size" :src="image">
                                                <span v-if="fileType !== ''" class="title readableText">{{ fileType }}</span>
                                            </ImageLoader>
                                            <v-btn
                                                v-show="onHover(hover)"
                                                depressed
                                                fab
                                                medium
                                                @click.stop="handleSelection"
                                                :class="{ accent: isSelected }"
                                                @mousedown.stop
                                            >
                                                <v-icon>mdi-check</v-icon>
                                            </v-btn>
                                        </v-badge>
                                    </v-scale-transition>
                                </v-flex>
                            </v-layout>
                        </v-hover>
                    </v-card>
                </v-flex>
                <v-flex>
                    <v-layout
                        column
                        fill-height
                        :align-start="$vuetify.breakpoint.mdAndUp"
                        :align-center="$vuetify.breakpoint.smAndDown"
                        :justify-center="$vuetify.breakpoint.mdAndUp"
                    >
                        <v-flex grow>
                            <span class="caption description font-weight-light pa-1">{{ label }}</span>
                        </v-flex>
                        <v-flex shrink align-self-center>
                            <slot></slot>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-card-text>
    </v-card>
</template>
<script>
import ImageLoader from './ImageLoader';
import FileSVG from '../../assets/Resources/SVG/file.svg';

export default {
    name: 'pill',
    components: { ImageLoader },
    computed: {
        isMediaConsumed() {
            return typeof this.value === 'object' && this.value.hasOwnProperty('viewTillEnd') && !!this.value.viewTillEnd;
        },
        cardStyle() {
            return {
                file: true,
                'ma-2': true,
                'elevation-3': true,
                grey: this.isMarked,
                'lighten-3': this.isMarked,
                selected: this.isSelected,
                marked: this.isMarked
            };
        },
        cardWidth() {
            return this.size * this.sizeFactor;
        },
        sizeFactor() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 1.36;
                case 'sm':
                    return 1.7;
                case 'md':
                    return 2.8;
                case 'lg':
                    return 3;
                case 'xl':
                    return 3;
            }
            return 0;
        }
    },
    props: {
        isMarked: {
            type: Boolean,
            default: false
        },
        isSelected: {
            type: Boolean,
            default: false
        },
        value: {
            type: [Object, String, Number],
            default: () => ({})
        },
        keepSelection: {
            type: Boolean,
            default: false
        },
        selectable: {
            type: Boolean,
            default: false
        },
        isFile: {
            type: Boolean,
            default: false
        },
        fileType: {
            type: [String, Number],
            default: () => ''
        },
        label: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default() {
                if (this.isFile) {
                    return FileSVG;
                }
                return '';
            }
        },
        size: {
            type: Number,
            default: 80
        }
    },
    methods: {
        onHover(hover) {
            if (this.keepSelection && this.selectable) {
                return true;
            }
            if (this.$vuetify.breakpoint.smAndDown && this.selectable) {
                return true;
            }
            if (!this.selectable) {
                return false;
            }
            return hover || this.isSelected;
        },
        handleSelection() {
            this.$emit('input', this.value);
        }
    }
};
</script>

<style scoped>
.description {
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 120px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
.file {
    cursor: pointer;
    border-radius: 16px;
    user-select: none;
}

.v-card .selected {
    border: 2px solid var(--v-accent-base) !important;
}

.v-card .marked {
    box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.2) !important;
    transform: translate(6px, 6px) !important;
}
</style>
