import KnowledgeContent from '../../views/KnowledgeContent/KnowledgeContent';

import { superAdmin, admin } from '../Roles';

export default [
    /**
     * KnowledgeContent
     * @description
     */
    {
        path: '/KnowledgeContent',
        name: 'knowledgeContent',
        component: KnowledgeContent,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin, admin],
            navigation: {
                type: { name: 'SingleGroup', groupId: 'Lernmanagement' },
                priority: 0,
                icon: 'mdi-file-cog-outline',
                name: 'Contentmanager',
                navigationKey: 'CONTENT_MANAGER'
            }
        }
    }
];
