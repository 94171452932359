import Chat from '../../views/Chat/Chat.vue';
import { superAdmin, admin, user } from '../Roles';

export default [
    /**
     * Chat
     * @description
     */
    {
        path: '/Chat',
        name: 'chat',
        component: Chat,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin, admin, user],
            navigation: {
                type: { name: 'Groups', groupId: 'Community' },
                priority: 0,
                icon: 'mdi-chat-outline',
                name: 'Chat',
                badge: 'ChatOperations/getTotalUnreadMessages',
                navigationKey: 'CHAT'
            }
        }
    }
];
