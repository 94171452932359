<template>
    <v-card>
        <v-toolbar dark text color="secondary">
            <v-toolbar-title>{{ $t('Relevante Wiki') }}</v-toolbar-title>
        </v-toolbar>
        <v-container grid-list-md fluid>
            <v-data-iterator
                :items="wikis"
                :no-data-text="$t('Keine Aufgaben verfügbar')"
                :no-results-text="$t('Kein Ergebnis')"
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-text': $t('Aufgaben pro Seite'),
                    'items-per-page-options': [5, 10, 15, { value: -1, text: $t('Alle') }]
                }"
                :hideDefaultFooter="wikis.length <= 5"
                row
                wrap
                justify-space-between
            >
                <template v-slot:item="{ item }">
                    <v-flex>
                        <span @click="handleClick(item)" class="primary--text pointer">{{ item.name }}</span>
                    </v-flex>
                </template>
            </v-data-iterator>
        </v-container>
    </v-card>
</template>

<script>
import WikiMixin from './Mixins/WikiMixin';

export default {
    name: 'wiki-selector',
    mixins: [WikiMixin],
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        wikis() {
            return this.getWikiList.filter((wiki) => wiki.knowledgeContents.map(({ id }) => id).includes(this.value.id));
        }
    },
    methods: {
        handleClick(wiki) {
            this.$emit('input', {});
            this.$nextTick(() => this.SET_SELECTED_WIKI(wiki));
        }
    }
};
</script>
