import axios from '../../../plugins/Axios/axios';

const state = {};

const getters = {};

const actions = {
    GET_TOPIC_TEMPLATE({ commit }, request) {
        const { id: topicTempId } = request;
        return axios.get(`/courselearning/topic/${topicTempId}`).then((response) => response.data.data);
    },
    GET_TOPIC_TEMPLATE_LEARNING_GOALS({ commit }, request) {
        const { id: topicTempId } = request;
        return axios.get(`/courselearning/topic/${topicTempId}/learninggoals`).then((response) => response.data.data);
    },
    CREATE_TOPIC_TEMPLATE({ commit }, request) {
        return axios.post(`/courselearning/topic`, request).then((response) => response.data.data);
    },
    UPDATE_TOPIC_TEMPLATE({ commit }, request) {
        const { id: topicTempId, ...topic } = request;
        return axios.patch(`/courselearning/topic/${topicTempId}`, topic).then((response) => response.data.data);
    },
    UPDATE_TOPIC_TEMPLATE_LEARNING_GOALS({ commit }, { topic, learningGoals }) {
        const { id: topicTempId } = topic;
        const learningGoalIds = learningGoals.map((learningGoal) => ({
            id: learningGoal.id
        }));
        return axios.patch(`/courselearning/topic/${topicTempId}/learninggoals`, learningGoalIds).then((response) => response.data.data);
    },
    DELETE_TOPIC_TEMPLATE({ commit }, request) {
        const { id: topicTempId } = request;
        return axios.delete(`/courselearning/topic/${topicTempId}`);
    }
};

const mutations = {
    clear: (state) => {}
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
