<template>
    <v-breadcrumbs :items="courseBreadcrumbs" divider=">">
        <template v-slot:item="{ item }">
            <span class="primary--text pointer" @click="onClickRoute(item)">{{ $t(item.text) }}</span>
        </template>
    </v-breadcrumbs>
</template>

<script>
import CourseMixin from '../Mixins/CourseMixin';

export default {
    name: 'course-breadcrumbs',
    mixins: [CourseMixin],
    methods: {
        onClickRoute(path) {
            this.$router.replace(path.href);
        }
    }
};
</script>

<style scoped>
.pointer {
    cursor: pointer;
}
</style>
