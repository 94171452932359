<template>
    <v-card flat>
        <v-card-text>
            <v-toolbar dark flat color="secondary">
                <v-btn icon @click="close">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-toolbar-title>{{ $t('Löschen bestätigen') }}</v-toolbar-title>
            </v-toolbar>
            <v-container grid-list-md>
                <v-layout row wrap>
                    <v-flex xs12>
                        <span>{{ $t('Sind Sie sicher, dass Sie den folgenden Kunden löschen wollen') }}</span>
                        <span class="px-2">:</span>
                        <span class="primary--text">{{ name }}</span>
                    </v-flex>
                    <v-flex xs12>
                        <v-checkbox
                            v-model="confirmDelete"
                            :label="$t('Bestätigen')"
                            :hint="$t('Wenn Sie bereits gelöschte Daten wieder herstellen möchten, wenden Sie sich bitte an den Support.')"
                            persistent-hint
                        />
                    </v-flex>
                </v-layout>
            </v-container>
            <v-card-actions>
                <v-btn block color="error" :disabled="!confirmDelete" @click="submit">
                    {{ $t('Löschen') }}
                </v-btn>
            </v-card-actions>
        </v-card-text>
    </v-card>
</template>

<script>
import CustomerMixins from '../Mixins/CustomerManagerMixin';

export default {
    name: 'delete-customer-confirm',
    mixins: [CustomerMixins],
    data: () => ({
        confirmDelete: false
    }),
    computed: {
        name() {
            return this.getSelectedCustomer && this.getSelectedCustomer.customer ? this.getSelectedCustomer.customer.companyName : '';
        }
    },
    methods: {
        submit() {
            this.DELETE_CUSTOMER(this.getSelectedCustomer.customer);
            this.SET_SELECTED_CUSTOMER({});
            this.close();
        },
        close() {
            this.SET_VIEW({ component: '', type: '', data: {}, tabValue: '' });
        }
    },
    activated() {
        this.confirmDelete = false;
    }
};
</script>
