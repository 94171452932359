<template>
    <v-menu v-model="menu" offset-y :close-on-content-click="false" allow-overflow>
        <template #activator="{ on, attrs }">
            <v-combobox multiple :value="value" :label="label" type="button" readonly v-on="on" v-bind="attrs" hide-details :disabled="items.length === 0">
                <template #selection="{ item }">
                    <v-chip>
                        <v-container class="ma-0 pa-0">
                            <v-row no-gutters align="center">
                                <v-col align-self="center">
                                    <span>{{ getDisplay(item) }}</span>
                                </v-col>
                                <v-col cols="auto" align-self="start">
                                    <v-btn icon x-small @click="removeItem(item)">
                                        <v-icon small>mdi-close</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-chip>
                </template>
            </v-combobox>
        </template>
        <v-card>
            <!-- <v-card-text class="pa-0 ma-0"> -->
            <v-treeview item-key="treeId" return-object transition :items="items">
                <template v-slot:prepend="{ item, open }">
                    <v-icon :color="iconColor(item)">
                        {{ icon(item, open) }}
                    </v-icon>
                </template>
                <template v-slot:label="{ item, active }">
                    <v-layout :ref="item.treeId">
                        <v-flex shrink v-if="isUserOrGroup(item) === 'GROUP'" class="pa-0 ma-0">
                            <v-simple-checkbox
                                :ripple="false"
                                :value="isGroupChecked(item)"
                                :indeterminate="isGroupIndeterminate(item)"
                                @input="handleGroup(item, $event)"
                                :color="!isGroupIndeterminate(item) ? 'success' : ''"
                                :ref="item.id"
                            />
                        </v-flex>
                        <v-flex shrink v-else class="pa-0 ma-0">
                            <v-simple-checkbox color="success" :ripple="false" :value="isChecked(item)" @input="handleCheck($event, item)" :ref="item.id" />
                        </v-flex>
                        <v-flex align-self-center>
                            <span
                                :class="{
                                    'primary--text': active,
                                    pointer: true,
                                    'no-highlight': true,
                                    'truncate-tree-item': true
                                }"
                                @click="() => $refs[item.id].click()"
                            >
                                {{ item.name || getDisplay(item) }}
                            </span>
                        </v-flex>
                    </v-layout>
                </template>
            </v-treeview>
            <!-- </v-card-text> -->
        </v-card>
    </v-menu>
</template>

<script>
import UserMixin from '../../Users/Mixins/UserMixin';
export default {
    name: 'user-tree-select',
    mixins: [UserMixin],
    data: () => ({
        menu: false
    }),
    props: {
        label: {
            type: String,
            default: () => ''
        },
        items: {
            type: Array,
            default: () => []
        },
        value: {
            type: Array,
            default: () => []
        }
    },
    watch: {
        menu(value) {
            if (value) {
                this.$emit('focus', new FocusEvent('focus', this.$el));
            } else {
                this.$emit('blur', new FocusEvent('blur', this.$el));
            }
        }
    },
    methods: {
        removeItem({ id }) {
            this.$emit(
                'input',
                this.value.filter((user) => user.id !== id)
            );
            this.$nextTick(() => {
                this.$emit('blur', new FocusEvent('blur', this.$el));
            });
            this.$emit('setGroupId', { id, isChecked: false });
        },
        flattenSubtree(group) {
            const flatten = (nodes) => (nodes ? nodes.reduce((acc, curr) => [...acc, curr, ...flatten(curr.children)], []) : []);
            return [group, ...flatten(group.children)];
        },
        isGroupChecked(group) {
            const users = this.flattenSubtree(group)
                .filter((object) => this.isUserOrGroup(object) === 'USER')
                .map(({ id }) => id);
            if (!users || users.length === 0) {
                return false;
            }
            const selectedUsers = this.value.map(({ id }) => id);
            return users.every((userId) => selectedUsers.includes(userId));
        },
        isGroupIndeterminate(group) {
            const users = this.flattenSubtree(group)
                .filter((object) => this.isUserOrGroup(object) === 'USER')
                .map(({ id }) => id);
            return this.isGroupChecked(group) ? false : users.some((userId) => this.value.map(({ id }) => id).includes(userId));
        },
        handleGroup(group, isChecked) {
            this.$emit('setGroupId', { id: group.id, isChecked });
            if (this.isGroupChecked(group)) {
                const users = this.flattenSubtree(group)
                    .filter((object) => this.isUserOrGroup(object) === 'USER')
                    .map(({ id }) => id);
                this.$emit(
                    'input',
                    this.value.filter(({ id }) => !users.includes(id))
                );
            } else {
                this.$emit(
                    'input',
                    [...this.value, ...this.flattenSubtree(group).filter((object) => this.isUserOrGroup(object) === 'USER')].filter(
                        (item, pos, self) => self.indexOf(item) == pos
                    )
                );
            }
        },
        iconColor(item) {
            if (this.isUserOrGroup(item) === 'USER') {
                return item.active ? 'success' : 'warning';
            }
            return '';
        },
        icon(item, open) {
            if (item.hasOwnProperty('name')) {
                return open && item.children && item.children.length > 0 ? 'mdi-account-multiple-outline' : 'mdi-account-multiple';
            }
            return 'mdi-account';
        },
        isChecked({ id }) {
            return this.value.map((item) => item.id).includes(id);
        },
        handleCheck(event, item) {
            if (event) {
                this.$emit('input', [...this.value, item]);
            } else {
                this.$emit(
                    'input',
                    this.value.filter((val) => val.id !== item.id)
                );
            }
        }
    }
};
</script>
<style scoped>
.v-treeview {
    height: 100%;
    width: max-content;
}
</style>
