<template>
    <v-list expand two-line>
        <v-subheader>{{ $t('Admin Berechtigungen') }}</v-subheader>
        <v-list-item>
            <v-list-item-content>
                <v-switch :label="$t(`Alle ${toggleText}`)" v-model="toggleCollapse" class="ml-3" />
            </v-list-item-content>
            <v-list-item-content>
                <v-switch color="accent" :label="$t(`Alle ${toggleSelect}`)" @change="selectAll" :input-value="isAllSelected" v-if="!readOnly" class="ml-3" />
            </v-list-item-content>
        </v-list-item>
        <v-list-group v-for="[key, val] in permissions" :key="val.groupCode" :value="toggleCollapse">
            <template v-slot:activator>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>{{ translateItems(key, true) }}</v-list-item-title>
                        <v-list-item-subtitle>{{ translatePermissions(val) }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <v-list-item v-for="(item, index) in val" :key="`permission-${index}`">
                <v-list-item-action>
                    <v-icon>{{ iconSelector(item.type) }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <span v-on="on">{{ translateItems(item.description) }}</span>
                            </template>
                            <span>{{ translateItems(item.description) }}</span>
                        </v-tooltip>
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ translateItems(item.type) }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.dependencies.length > 0">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    <span class="primary--text">{{ $t('Abhängigkeiten') }}:</span>
                                    {{ showDependencies(item) }}
                                </div>
                            </template>
                            <span>{{ $t('Abhängigkeiten') }}:</span>
                            {{ showDependencies(item) }}
                        </v-tooltip>
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-layout wrap justify-center>
                        <v-flex xs12>
                            <v-switch
                                color="accent"
                                :value="item.permissionCode"
                                :input-value="value.map((test) => test.permissionCode)"
                                :disabled="isDependant(item)"
                                @change="selectedPermissions = item"
                                :readonly="readOnly"
                            />
                        </v-flex>
                    </v-layout>
                </v-list-item-action>
            </v-list-item>
        </v-list-group>
    </v-list>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'admin-permission-list',
    data: () => ({
        permissions: [],
        toggleCollapse: false
    }),
    props: {
        value: {
            type: Array,
            required: true
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters('AdminPermission', ['getSystemPermissionList']),
        selectedPermissions: {
            get() {
                return this.value;
            },
            set(value) {
                let hasPermission = this.value
                    .filter((val) => val.hasOwnProperty('permissionCode'))
                    .map((val) => val.permissionCode)
                    .includes(value.permissionCode);
                if (hasPermission) {
                    this.$emit(
                        'input',
                        this.value.filter((item) => ![value].map((permission) => permission.permissionCode).includes(item.permissionCode))
                    );
                } else {
                    this.$emit('input', [
                        ...this.value,
                        ...[value, ...value.dependencies].filter(
                            (permission) => !this.value.map((perm) => perm.permissionCode).includes(permission.permissionCode)
                        )
                    ]);
                }
            }
        },
        isAllSelected() {
            let allPermissionsFlattend = this.permissions
                .map(([key, val]) => val)
                .flat()
                .map((permission) => permission.permissionCode);

            return allPermissionsFlattend.every((permissionCode) => this.value.map((permission) => permission.permissionCode).includes(permissionCode));
        },
        toggleText() {
            if (this.toggleCollapse) return 'schließen';
            return 'öffnen';
        },
        toggleSelect() {
            if (this.isAllSelected) {
                return 'ausschließen';
            }
            return 'auswählen';
        }
    },
    methods: {
        ...mapActions('AdminPermission', ['GET_ALL_SYSTEM_PERMISSIONS']),
        translatePermissions(permissions) {
            return this.$t(permissions[0].groupDescription);
        },
        selectAll() {
            if (this.isAllSelected) {
                this.$emit('input', []);
            } else {
                const permissions = this.permissions.map(([key, val]) => val).flat();
                let deps = permissions
                    .filter((permission) => permission.hasOwnProperty('dependencies'))
                    .filter((permission) => permission.dependencies.length > 0)
                    .map((permission) => permission.dependencies)
                    .flat();
                let permissionsWithDependencies = [...permissions, ...deps].filter((value, index, self) => {
                    return self.map((permission) => permission.permissionCode).indexOf(value.permissionCode) === index;
                });
                this.$emit('input', permissionsWithDependencies);
            }
        },
        isDependant(permission) {
            return this.value
                .filter((item) => item.hasOwnProperty('dependencies'))
                .map((item) => item.dependencies)
                .flat()
                .map((item) => item.permissionCode)
                .includes(permission.permissionCode);
        },
        showDependencies(item) {
            return item.dependencies
                .map((permission) => `${this.translateItems(permission.groupCode, true)}: ${this.translateItems(permission.description)}`)
                .join(', ');
        },
        translateItems(item, capitalize = false) {
            if (capitalize) {
                return this.$t(item.replace(/_/g, ' ').toLowerCase());
            }
            return this.$t(item.replace(/_/g, ' '));
        },
        iconSelector(type) {
            switch (type) {
                case 'CREATE':
                    return 'mdi-plus';
                case 'UPDATE':
                    return 'mdi-pencil';
                case 'DELETE':
                    return 'mdi-delete';
                case 'GET':
                    return 'mdi-eye';
                case 'MANAGEMENT':
                    return 'mdi-gavel';
                case 'MOVE':
                    return 'mdi-file-move';
                case 'COPY':
                    return 'mdi-content-copy';
            }
            return '';
        }
    },
    mounted() {
        if (this.getSystemPermissionList.length === 0) {
            this.GET_ALL_SYSTEM_PERMISSIONS().then((response) => {
                let data = response.data.data.permissions;
                //Convert into Map ( grouped by groupCode)
                this.permissions = Object.entries(
                    data.reduce((k, v) => {
                        k[v.groupCode] = [...(k[v.groupCode] || []), v];
                        return k;
                    }, {})
                );
                //Sort values
                this.permissions.forEach(([k, v]) => {
                    v = v.sort((a, b) => {
                        if (a.type > b.type) {
                            return 1;
                        }
                        if (a.type < b.type) {
                            return -1;
                        }
                        return 0;
                    });
                });
                //Sort keys
                this.permissions = this.permissions.sort();
            });
        } else {
            this.permissions = Object.entries(
                this.getSystemPermissionList.reduce((k, v) => {
                    k[v.groupCode] = [...(k[v.groupCode] || []), v];
                    return k;
                }, {})
            );
            //sort values
            this.permissions.forEach(([k, v]) => {
                v = v.sort((a, b) => {
                    if (a.type > b.type) {
                        return 1;
                    }
                    if (a.type < b.type) {
                        return -1;
                    }
                    return 0;
                });
            });
            //Sort keys
            this.permissions = this.permissions.sort();
        }
    }
};
</script>
