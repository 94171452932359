<template>
    <v-card>
        <Toolbar :toolbarTitle="$t('Kursmanager - Leistungen')" :lastPath="-1" :showDrawer="false" />
        <Evaluation />
    </v-card>
</template>

<script>
import Toolbar from '../../../Components/Generic/Toolbar';
import Evaluation from '../../../Components/CourseLearning/CourseManager/Evaluations';
import CourseMixin from '../../../Components/CourseLearning/CourseManager/Mixins/CourseMixin';

export default {
    name: 'evaluations',
    mixins: [CourseMixin],
    components: { Toolbar, Evaluation },
    methods: {
        backpath() {
            return this.courseBreadcrumbs[this.courseBreadcrumbs.length - 1].href;
        }
    }
};
</script>
