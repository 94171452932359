import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters('WikiOperations', [
            'getWikiList',
            'getSelectedWiki',
            'getWikiTree',
            'getWiki',
            'getWikisWithProposals',
            'getWikiHome',
            'getMyWiki',
            'getMyCommissions',
            'getView'
        ])
    },
    methods: {
        ...mapActions('WikiOperations', [
            'ADD_WIKI',
            'UPDATE_WIKI',
            'GET_WIKI_LIST',
            'GET_WIKI_TREE',
            'GET_WIKI_SUBTREE',
            'GET_SINGLE_WIKI',
            'DELETE_WIKI',
            'SET_SELECTED_WIKI',
            'GET_PROPOSALS',
            'GET_MOST_VIEWED_WIKI',
            'GET_MOST_RECENT_WIKI',
            'GET_WIKI_WITH_PROPOSALS',
            'ACCEPT_PROPOSAL',
            'GET_WIKI_HOME',
            'UPDATE_WIKI_HOME',
            'WIKI_CHANGE_STATE',
            'GET_MY_WIKI',
            'GET_MY_COMMISSIONS',
            'SET_VIEW'
        ])
    }
};
