<template>
    <v-container fluid ma-0 pa-0 fill-height>
        <v-layout column>
            <v-flex shrink>
                <Toolbar
                    :toolbarTitle="$t('Plantool')"
                    :showDrawer="!isBackable"
                    tooltipMessage="Hier können Sie Aufgaben, die aktiv und inaktiv sind, ansehen, hinzufügen, bearbeiten und löschen."
                    @backEmit="handleBack"
                    v-if="component !== 'REPORT_STATISTICS'"
                />
            </v-flex>
            <v-flex grow>
                <Plantool
                    :type="type"
                    :component="component"
                    @type="(value) => (type = value)"
                    @component="(value) => (component = value)"
                    @navBack="handleBack"
                />
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import Toolbar from '../../Components/Generic/Toolbar';
import Plantool from '../../Components/Plantool/Plantool';
import PlantoolMixins from '../../Components/Plantool/Mixins/PlantoolMixin';

export default {
    name: 'plantool',
    components: {
        Toolbar,
        Plantool
    },
    mixins: [PlantoolMixins],
    data: () => ({
        type: 'ADD',
        component: 'CALENDAR'
    }),
    computed: {
        isBackable() {
            return this.component !== 'CALENDAR';
        }
    },
    methods: {
        handleBack() {
            this.type = 'ADD';
            this.component = 'CALENDAR';
        }
    }
};
</script>
