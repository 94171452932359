import axios from '../../plugins/Axios/axios';

const state = {
    news: [],
    publishedNews: [],
    selectedNews: {},
    visibilityOptions: []
};

const getters = {
    getNews() {
        return state.news;
    },
    getPublishedNews() {
        return state.publishedNews;
    },
    getSelectedNews() {
        return state.selectedNews;
    },
    getVisibilityOptions() {
        return state.visibilityOptions;
    }
};

const actions = {
    GET_NEWS({ commit }) {
        return axios.get(`/news`).then((response) => commit('updateNews', response.data.data));
    },
    GET_PUBLISHED_NEWS({ commit }) {
        return axios.get(`/news/published`).then((response) => commit('updatePublishedNews', response.data.data));
    },
    SET_SELECTED_NEWS({ commit }, news) {
        commit('updateSelectedNews', news);
    },
    CREATE_NEWS({ commit }, news) {
        return axios.post(`/news`, news).then((response) => commit('updateSelectedNews', response.data.data));
    },
    UPDATE_NEWS({ commit }, news) {
        return axios.patch(`/news/${news.id}`, news).then((response) => commit('updateSelectedNews', response.data.data));
    },
    DELETE_NEWS({ commit }, newsId) {
        return axios.delete(`/news/${newsId}`).then(() => commit('updateSelectedNews', {}));
    },
    VISIBILITY_OPTIONS({ commit }) {
        return axios
            .get(`/news/visibility`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateVisibility', response);
                return response;
            });
    }
};

const mutations = {
    clear: (state) => {
        state.news = [];
        state.publishedNews = [];
        state.selectedNews = {};
    },
    updateNews: (state, data) => {
        state.news = data;
        if (state.selectedNews.hasOwnProperty('id')) {
            let find = data.find((news) => news.id === state.selectedNews.id);
            if (find !== -1) {
                state.selectedNews = find;
            }
        }
    },
    updatePublishedNews: (state, data) => (state.publishedNews = data),
    updateSelectedNews: (state, data) => (state.selectedNews = data),
    updateVisibility: (state, data) => (state.visibilityOptions = data)
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
