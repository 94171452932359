<template>
    <v-card text>
        <v-subheader>{{ $t('Content') }}</v-subheader>
        <v-container grid-list-md pa-0>
            <v-layout wrap>
                <v-flex xs12>
                    <v-card height="100%">
                        <v-card-text>
                            <v-text-field color="accent" autocomplete="new-password" v-model="title" attach :label="$t('Titel')" />
                            <Editor v-model="content" />
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12>
                    <v-card height="100%">
                        <v-card-text>
                            <TagGenerator v-model="tags" />
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6 xl6>
                    <v-card height="100%">
                        <v-card-text>
                            <v-switch
                                color="accent"
                                v-model="viewTillEnd"
                                :label="$t('Video & Audio Medien müssen konsumiert werden')"
                                :hint="
                                    $t(
                                        'Wenn diese Option eingeschaltet ist, müssen Nutzer Videos und/oder Audio bis zum ende gesehen bzw. gehört haben, damit der Nutzer die Wissenseinheit bearbeiten kann'
                                    )
                                "
                                persistent-hint
                            />
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6 xl6>
                    <v-card height="100%">
                        <v-card-text>
                            <v-switch
                                color="accent"
                                v-model="disableSkipping"
                                :label="$t('Video & Audio Medien können nicht übersprungen werden')"
                                :hint="$t('Wenn diese Option eingeschaltet ist, kann der Nutzer Videos und/oder Audio nicht überspringen')"
                                :disabled="!viewTillEnd"
                                persistent-hint
                            />
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
        <v-subheader>{{ $t('Zielort') }}</v-subheader>
        <KnowledgeContentMultipleFolderSelector :tree="tree" v-model="selectedFolders" />
        <v-card-actions>
            <v-btn block color="success" :disabled="!computedDisable" @click="submit">
                {{ $t('Einfügen') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import KnowledgeContentMixin from '../Mixins/KnowledgeContentMixin';
import KnowledgeContentMultipleFolderSelector from './KnowledgeContentMultipleFolderSelector';
import Editor from '../../Generic/TextEditor';
import TagGenerator from '../../Generic/TagGenerator';

export default {
    name: 'catalog-copy-view',
    mixins: [KnowledgeContentMixin],
    components: {
        KnowledgeContentMultipleFolderSelector,
        Editor,
        TagGenerator
    },
    data: () => ({
        title: '',
        tags: '',
        content: '',
        viewTillEnd: false,
        disableSkipping: false,
        folderId: '',
        selectedFolders: []
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        tree() {
            return [{ id: '', name: this.$t('Stammverzeichnis') }, ...this.getTree];
        },
        computedDisable() {
            return this.selectedFolders.length > 0;
        },
        iconColor() {
            if (this.value && this.value.state && this.value.state.stateName) {
                switch (this.value.state.stateName) {
                    case 'APPROVED':
                        return 'success';
                    case 'PENDING':
                        return 'warning';
                    case 'DECLINED':
                        return 'error';
                }
            }
            return '';
        }
    },
    methods: {
        serialize({ id: folderId }) {
            return {
                title: this.title,
                knowledgeContentLinks: [],
                tags: this.tags,
                content: this.content,
                viewTillEnd: this.viewTillEnd,
                disableSkipping: this.disableSkipping,
                isDraft: false,
                folderId
            };
        },
        submit() {
            Promise.all(this.selectedFolders.map((content) => this.serialize(content)).map((payload) => this.CREATE_KNOWLEDGE_CONTENT(payload))).then(
                (response) => {
                    this.$emit('openTree', this.selectedFolders.map((content) => this.calculateTreePath(content)).flat(1));
                    if (response.length === 1) {
                        this.$emit('input', response[0]);
                    }
                    this.$emit('switchView', { component: '', data: {}, type: '' });
                    this.selectedFolders = [];
                }
            );
        },
        notEmpty(value) {
            return (!!value && value.trim().length > 0) || this.$t('Dieses Feld darf nicht leer sein');
        }
    },
    activated() {
        Object.entries(this.value).forEach(([key, value]) => {
            if (this.$data.hasOwnProperty(key)) {
                this.$data[key] = value;
            }
        });
        this.selectedFolders = [];
    }
};
</script>
