<template>
    <v-menu v-model="showMenu" bottom offset-y :close-on-content-click="false">
        <template #activator="{ on, attrs }">
            <v-tooltip bottom>
                <template #activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                    <v-btn icon v-on="{ ...on, ...tooltipOn }" v-bind="{ ...attrs, ...tooltipAttrs }">
                        <v-icon>mdi-cog-outline</v-icon>
                    </v-btn>
                </template>
                {{ $t('Anzeige- und Sortierungsoptionen') }}
            </v-tooltip>
        </template>
        <v-card :width="$vuetify.breakpoint.thresholds.xs">
            <v-toolbar dark dense flat color="secondary">
                <v-toolbar-title>
                    {{ $t('Anzeige- und Sortierungsoptionen') }}
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col :cols="12">
                            <v-select
                                v-model="computedDisplay"
                                :label="$t('Darstellung')"
                                item-text="name"
                                item-value="value"
                                :items="computedDisplayItems"
                                hide-details
                                prepend-icon="mdi-signature-text"
                            />
                        </v-col>
                        <v-col :cols="12">
                            <v-select
                                v-model="computedOrder"
                                :label="$t('Sortierung')"
                                item-text="name"
                                item-value="value"
                                :items="computedOrderItems"
                                hide-details
                                :prepend-icon="config.order === 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-container>
                    <v-row no-gutters>
                        <v-col :cols="12" :sm="6" @click="reset">
                            <v-btn block class="col-6" color="warning">
                                {{ $t('Zurücksetzen') }}
                            </v-btn>
                        </v-col>
                        <v-col :cols="12" :sm="6">
                            <v-btn block color="success" :disabled="saveDisabled" @click="save">
                                {{ $t('Anwenden') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
import UserMixin from '../../Mixins/UserMixin';

export default {
    name: 'users-configuration',
    mixins: [UserMixin],
    data: () => ({
        showMenu: false,
        config: {
            display: 'DISPLAYNAME',
            sort: 'DISPLAYNAME',
            order: 'DESC'
        }
    }),
    computed: {
        saveDisabled() {
            return (
                this.config.display === this.getExplorerConfig.display &&
                this.config.sort === this.getExplorerConfig.sort &&
                this.config.order === this.getExplorerConfig.order
            );
        },
        computedDisplayItems() {
            return [
                { name: this.$t('Anzeigename'), value: 'DISPLAYNAME' },
                { name: this.$t('Vorname Nachname'), value: 'FIRSTNAME_LASTNAME' },
                { name: this.$t('Nachname, Vorname'), value: 'LASTNAME_FIRSTNAME' },
                { name: this.$t('Personalnummer'), value: 'PERSONALNUMBER' }
            ];
        },
        computedOrderItems() {
            return [
                { name: this.$t('Aufsteigend'), value: 'ASC' },
                { name: this.$t('Absteigend'), value: 'DESC' }
            ];
        },
        computedDisplay: {
            get() {
                return this.config.display;
            },
            set(value) {
                this.$set(this.config, 'display', value);
                this.convertDisplayToSort(value);
            }
        },
        computedOrder: {
            get() {
                return this.config.order;
            },
            set(value) {
                this.$set(this.config, 'order', value);
            }
        }
    },
    methods: {
        convertDisplayToSort(display) {
            switch (display) {
                case 'FIRSTNAME_LASTNAME':
                    this.$set(this.config, 'sort', 'FIRSTNAME');
                    return;
                case 'LASTNAME_FIRSTNAME':
                    this.$set(this.config, 'sort', 'LASTNAME');
                    return;
            }
            this.$set(this.config, 'sort', display);
        },
        save() {
            this.UPDATE_EXPLORER_CONFIG(this.config)
                .then((response) => this.$set(this.$data, 'config', { ...response }))
                .then(() => (this.showMenu = false));
        },
        reset() {
            this.RESET_EXPLORER_CONFIG()
                .then((response) => this.$set(this.$data, 'config', { ...response }))
                .then(() => (this.showMenu = false));
        }
    },
    mounted() {
        this.GET_EXPLORER_CONFIG().then((response) => this.$set(this.$data, 'config', { ...response }));
    }
};
</script>
