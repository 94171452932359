import axios from '../../../plugins/Axios/axios';

const state = {
    courseEvaluations: []
};

const getters = {
    getCourseEvaluations() {
        return state.courseEvaluations;
    }
};

const actions = {
    GET_ATTENDEES_COURSE_EVALUATION({ commit }, { course: { id: courseId }, user: { id: userId } }) {
        return axios
            .get(`/courselearning/courseinstances/${courseId}/evaluations`)
            .then((response) => response.data.data)
            .then((response) => {
                const evaluation = response.find((grading) => grading.user.id === userId);
                if (evaluation !== -1) {
                    return evaluation;
                }
                return response;
            });
    },
    GET_COURSE_EVALUATIONS({ commit }, { id: courseInsId }) {
        return axios
            .get(`/courselearning/courseinstances/${courseInsId}/evaluations`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateCourseEvaluations', response);
                return response;
            });
    },
    GET_TOPIC_EVALUATIONS({ commit }, { id: topicId }) {
        return axios
            .get(`/courselearning/topicinstances/${topicId}/evaluations`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateCourseEvaluations', response);
                return response;
            });
    },
    GET_LEARNING_GOAL_EVALUATIONS({ commit }, { id: learningGoalId }) {
        return axios
            .get(`/courselearning/learninggoalinstances/${learningGoalId}/evaluations`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateCourseEvaluations', response);
                return response;
            });
    },
    GET_ACTIVITY_EVALUATIONS({ commit }, { id: activityId }) {
        return axios
            .get(`/courselearning/activities/${activityId}/evaluations`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateCourseEvaluations', response);
                return response;
            });
    },
    GET_ATTENDEES_ANSWERS({ commit }, { id: activityId }) {
        return axios.get(`/courselearning/activities/${activityId}/answers`).then((response) => response.data.data);
    },
    GET_ATTENDEES_EVALUATION({ commit }, { id: activityId }) {
        return axios.get(`/courselearning/activities/${activityId}/evaluations`).then((response) => response.data.data);
    },
    CREATE_SELF_EVALUATION({ commit }, { id: activityId }) {
        return axios.post(`/courselearning/activities/${activityId}/evaluate/self`).then((response) => response.data.data);
    },
    CREATE_COURSE_EVALUATION({ commit }, { course: { id: courseId }, evaluation }) {
        return axios
            .post(`/courselearning/courseinstances/${courseId}/evaluations/other`, evaluation)
            .then((response) => response.data.data)
            .then((response) => {
                if (response.length === 1) {
                    response = response[0];
                }
                commit('updateCourseEvaluations', response);
                return response;
            });
    },
    CREATE_TOPIC_EVALUATION({ commit }, { topic: { id: topicId }, evaluation }) {
        return axios
            .post(`/courselearning/topicinstances/${topicId}/evaluations/other`, evaluation)
            .then((response) => response.data.data)
            .then((response) => {
                if (response.length === 1) {
                    response = response[0];
                }
                commit('updateCourseEvaluations', response);
                return response;
            });
    },
    CREATE_LEARNING_GOAL_EVALUATION({ commit }, { learningGoal: { id: learningGoalId }, evaluation }) {
        return axios
            .post(`/courselearning/learninggoalinstances/${learningGoalId}/evaluations/other`, evaluation)
            .then((response) => response.data.data)
            .then((response) => {
                if (response.length === 1) {
                    response = response[0];
                }
                commit('updateCourseEvaluations', response);
                return response;
            });
    },
    CREATE_ACTIVITY_EVALUATION({ commit }, { activity: { id: activityId }, evaluation }) {
        return axios
            .post(`/courselearning/activities/${activityId}/evaluations/other`, evaluation)
            .then((response) => response.data.data)
            .then((response) => {
                if (response.length === 1) {
                    response = response[0];
                }
                commit('updateCourseEvaluations', response);
                return response;
            });
    }
};

const mutations = {
    clear: (state) => {
        state.courseEvaluations = [];
    },
    updateCourseEvaluations: (state, response) => {
        if (Array.isArray(response)) {
            state.courseEvaluations = response;
        } else {
            if (response.hasOwnProperty('user')) {
                if (state.courseEvaluations.map((course) => course.user.id).includes(response.user.id)) {
                    state.courseEvaluations = state.courseEvaluations.map((course) => {
                        if (course.user.id === response.user.id) {
                            return response;
                        }
                        return course;
                    });
                } else {
                    state.courseEvaluations.push(response);
                }
            }
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
