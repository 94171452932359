<template>
    <v-card>
        <v-toolbar dense dark flat color="secondary">
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <!-- <v-spacer />
            <v-btn v-if="!isAdd" @click="close" icon>
                <v-icon>mdi-close</v-icon>
            </v-btn> -->
        </v-toolbar>
        <v-tabs mandatory slider-color="accent" show-arrows v-model="tabs">
            <v-tab key="SETTINGS">{{ $t('Einstellungen') }}</v-tab>
            <v-tab key="WIKI">{{ $t('Wiki') }}</v-tab>
            <v-tab key="CONTENT" v-if="isAdminOrSuperAdmin && contentRule">{{ $t('Content') }}</v-tab>
        </v-tabs>
        <v-card-text class="ma-0 pa-0">
            <v-tabs-items v-model="tabs" touchless>
                <v-tab-item key="SETTINGS">
                    <v-form v-model="valid" ref="form">
                        <v-container grid-list-md fluid>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <v-text-field
                                        :label="$t('Name')"
                                        v-model="wiki.name"
                                        :hint="$t('Der Name wird genutzt, um den Wiki-Eintrag im Wiki-Browser darzustellen')"
                                        persistent-hint
                                        :rules="[notEmpty]"
                                    />
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6 xl6 v-show="addUpdateRule">
                                    <WikiParentSelector v-model="wiki.parentId" :id="wiki.id" />
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6 xl6 v-show="addUpdateRule">
                                    <UserPicker
                                        v-model="wiki.state.commissionedTo"
                                        showUsers
                                        showAdmins
                                        showEmptyElement
                                        excludeSelf
                                        label="In Auftrag geben an"
                                        hint="Sie können die Bearbeitung dieses Wiki-Eintrags an Alle, einen oder mehreren Nutzer beschränken"
                                    />
                                </v-flex>
                                <v-flex xs12 v-if="addUpdateRule && VisibilityRule">
                                    <v-select
                                        v-model="wiki.visibility"
                                        :items="visibilityOptions"
                                        :label="$t('Sichtbarkeit')"
                                        :hint="$t(visibilityHint)"
                                        persistent-hint
                                        prepend-icon="mdi-eye"
                                    >
                                        <template v-slot:item="{ item }">
                                            {{ $t(item) }}
                                        </template>
                                        <template v-slot:selection="{ item }">
                                            {{ $t(item) }}
                                        </template>
                                    </v-select>
                                </v-flex>
                                <v-flex xs12 v-if="addUpdateRule && VisibilityRule">
                                    <v-expand-transition>
                                        <v-tabs v-model="selectedOptionTab" mandatory grow slider-color="accent" v-show="isCustomVisibility">
                                            <v-tab key="AdminGroups">
                                                <span>{{ $t('Admingruppen') }}</span>
                                            </v-tab>
                                            <v-tab key="UserGroups">
                                                <span>{{ $t('Benutzergruppen') }}</span>
                                            </v-tab>
                                            <v-tab key="Users">
                                                <span>{{ $t('Nutzer') }}</span>
                                            </v-tab>
                                        </v-tabs>
                                    </v-expand-transition>
                                    <v-expand-transition>
                                        <v-tabs-items v-model="selectedOptionTab" touchless v-show="isCustomVisibility">
                                            <v-tab-item key="AdminGroups">
                                                <v-card text>
                                                    <v-card-text>
                                                        <PaginatedList
                                                            :showAppend="false"
                                                            :showOptions="true"
                                                            title="Admingruppen"
                                                            :items="getAdminGroups"
                                                            :sortArray="adminGroupSort"
                                                        >
                                                            <template v-slot:action="{ action }">
                                                                <v-checkbox
                                                                    v-model="wiki.options.adminGroups"
                                                                    :value="action"
                                                                    :key="`${action.id}-${Math.floor(Math.random() * 256)}`"
                                                                />
                                                            </template>
                                                        </PaginatedList>
                                                    </v-card-text>
                                                </v-card>
                                            </v-tab-item>
                                            <v-tab-item key="UserGroups">
                                                <v-card text>
                                                    <v-card-text>
                                                        <PaginatedList
                                                            :showAppend="false"
                                                            :showOptions="true"
                                                            title="Nutzergruppen"
                                                            :items="getUserGroups"
                                                            :sortArray="userGroupSort"
                                                        >
                                                            <template v-slot:action="{ action }">
                                                                <v-checkbox
                                                                    :value="action"
                                                                    v-model="wiki.options.userGroups"
                                                                    :key="`${action.id}-${Math.floor(Math.random() * 256)}`"
                                                                />
                                                            </template>
                                                        </PaginatedList>
                                                    </v-card-text>
                                                </v-card>
                                            </v-tab-item>
                                            <v-tab-item key="Users">
                                                <v-card text>
                                                    <UserList v-model="wiki.options.users" :showSelf="false" />
                                                </v-card>
                                            </v-tab-item>
                                        </v-tabs-items>
                                    </v-expand-transition>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-form>
                </v-tab-item>
                <v-tab-item key="WIKI">
                    <v-container>
                        <v-row>
                            <v-col :cols="12">
                                <div style="overflow-x: hidden">
                                    <TextEditor v-model="wiki.body" />
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col :cols="12">
                                <TagGenerator v-model="wiki.tags" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <!-- <v-col :cols="12">
                                <StateSelector :value="{ stateName: state }" @input="handleState" v-if="hasStateChange" />
                            </v-col> -->
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="CONTENT" v-if="isAdminOrSuperAdmin && contentRule">
                    <KnowledgeContentManager v-if="tabs == 2" selectable v-model="wiki.knowledgeContents" />
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
        <v-card-actions>
            <v-btn block :color="submitColor" :disabled="!valid" @click="submit">{{ submitLabel }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import PaginatedList from '../Generic/List/AdvancedList';
import UserList from '../Generic/Tree/UserGroupTreeSelector';

import KnowledgeContentManager from '../KnowledgeContent/KnowledgeContentManager';
import WikiParentSelector from './Helpers/WikiParentSelector';
import TextEditor from '../Generic/TextEditor';
import UserPicker from '../Generic/UserPicker/UserPicker';
import TagGenerator from '../Generic/TagGenerator';
import StateSelector from '../Generic/StateSelector';

import GroupSort from '../Groups/Sort/GroupSort';

import UserGroupMixin from '../Groups/Mixins/UserGroupMixins';
import AdminGroupMixin from '../Groups/Mixins/AdminGroupMixins';
import WikiMixin from './Mixins/WikiMixin';
import KnowledgeContentMixin from '../KnowledgeContent/Mixins/KnowledgeContentMixin';

export default {
    name: 'add-update-wiki',
    mixins: [UserGroupMixin, AdminGroupMixin, KnowledgeContentMixin, WikiMixin],
    components: {
        KnowledgeContentManager,
        TextEditor,
        WikiParentSelector,
        UserPicker,
        PaginatedList,
        UserList,
        TagGenerator
        // StateSelector
    },
    data: () => ({
        tabs: 0,
        selectedOptionTab: 0,
        valid: false,
        adminGroupSort: GroupSort,
        userGroupSort: GroupSort,
        wiki: {
            id: '',
            name: '',
            body: '',
            tags: '',
            knowledgeContents: [],
            parentId: '',
            state: {
                commissionedTo: []
            },
            visibility: 'ALL',
            options: {
                adminGroups: [],
                userGroups: [],
                users: []
            }
        },
        state: 'PENDING',
        hasStateChange: false,
        hasProposalPermission: false,
        hasContentPermission: false,
        hasAdminGroupPermission: false,
        hasUserGroupPermission: false
    }),
    watch: {
        hasAdminGroupPermission(value) {
            if (value) {
                this.GET_ADMIN_GROUP_ROOT();
            }
        },
        hasUserGroupPermission(value) {
            if (value) {
                this.GET_USER_GROUP_ROOT();
            }
        }
    },
    computed: {
        contentRule() {
            return this.hasContentPermission;
        },
        VisibilityRule() {
            return this.getLoggedInAdmin.role !== 'USER' && this.hasProposalPermission;
        },
        addUpdateRule() {
            return (
                this.getView.type === '' ||
                (this.getView.type === 'UPDATE' &&
                    this.getLoggedInAdmin &&
                    this.getLoggedInAdmin.id &&
                    this.getSelectedWiki &&
                    this.getSelectedWiki.createdBy &&
                    this.getSelectedWiki.createdBy.id &&
                    this.getLoggedInAdmin.id === this.getSelectedWiki.createdBy.id)
            );
        },
        isAdminOrSuperAdmin() {
            return this.getLoggedInAdmin && this.getLoggedInAdmin.role ? this.getLoggedInAdmin.role !== 'USER' : false;
        },
        visibilityOptions() {
            return ['ALL', 'ADMINS', 'USERS', 'CUSTOM', 'SELF'];
        },
        visibilityHint() {
            switch (this.wiki.visibility) {
                case 'ALL':
                    return 'Alle Nutzer können den Wiki-Eintrag sehen';
                case 'ADMINS':
                    return 'Alle Admin können den Wiki-Eintrag sehen';
                case 'USERS':
                    return 'Alle Benutzer können den Wiki-Eintrag sehen';
                case 'CUSTOM':
                    return 'Benutzerdefinierte Admin-, Nutzergruppen und einzelne Benutzer/Admin können den Wiki-Eintrag sehen';
                case 'SELF':
                    return 'Nur Sie und der Super-Admin können diesen Wiki-Eintrag sehen';
            }
            return '';
        },
        isCustomVisibility() {
            return this.wiki.visibility === 'CUSTOM';
        },
        title() {
            switch (this.getView.type) {
                case 'UPDATE':
                    return this.$t('Wiki Bearbeiten');
                default:
                    return this.$t('Wiki Hinzufügen');
            }
        },
        submitLabel() {
            switch (this.getView.type) {
                case 'UPDATE':
                    return this.$t('Bearbeiten');
                default:
                    return this.$t('Hinzufügen');
            }
        },
        submitColor() {
            switch (this.getView.type) {
                case 'ADD':
                    return 'success';
                case 'UPDATE':
                    return 'warning';
            }
            return 'success';
        },
        isAdd() {
            return this.getView.type !== 'UPDATE';
        }
    },
    methods: {
        handleState({ state }) {
            this.state = state;
        },
        notEmpty(value) {
            return (!!value && value.trim() !== '' && value.trim().length > 0) || this.$t('Das Feld darf nicht leer sein');
        },
        close() {
            this.reset();
            this.SET_VIEW({ component: 'GET_WIKI', type: '', data: this.getSelectedWiki });
            if (this.getView && this.getView.type == 'UPDATE') {
                this.$destroy();
            }
        },
        reset() {
            this.wiki = {
                id: '',
                name: '',
                body: '',
                tags: '',
                knowledgeContents: [],
                parentId: '',
                state: {
                    commissionedTo: []
                },
                visibility: 'ALL',
                options: {
                    adminGroups: [],
                    userGroups: [],
                    users: []
                }
            };
            this.tabs = 0;
            this.state = 'PENDING';
            this.$refs.form.validate();
        },
        submit() {
            switch (this.getView.type) {
                case '':
                    return this.ADD_WIKI(this.wiki)
                        .then((response) => {
                            if (this.hasStateChange) {
                                this.WIKI_CHANGE_STATE({
                                    wiki: response,
                                    state: { state: this.state }
                                });
                            }
                        })
                        .then(() => this.close());
                case 'UPDATE':
                    return this.UPDATE_WIKI(this.wiki)
                        .then((response) => {
                            if (this.hasStateChange) {
                                this.WIKI_CHANGE_STATE({
                                    wiki: response,
                                    state: { state: this.state }
                                });
                            }
                        })
                        .then(() => this.close());
            }
        }
    },
    mounted() {
        this.validateEditorState({
            proposals: ['MANAGE_PROPOSAL_WIKI'],
            contents: true,
            userGroups: ['GET_ADMIN_GROUP'],
            adminGroups: ['GET_USER_GROUP'],
            stateChange: ['CHANGE_WIKI_STATE']
        }).then((response) => {
            this.hasProposalPermission = response.proposals;
            this.hasContentPermission = response.contents;
            this.hasAdminGroupPermission = response.adminGroups;
            this.hasUserGroupPermission = response.userGroups;
            this.hasStateChange = response.stateChange;
        });
    },
    beforeMount() {
        this.GET_KNOWLEDGE_CONTENTS();
        if (this.getView.type === 'UPDATE') {
            Object.entries(this.getSelectedWiki).forEach(([key, value]) => {
                if (this.wiki.hasOwnProperty(key)) {
                    this.wiki[key] = value;
                }
            });
        }
    }
};
</script>
