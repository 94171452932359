import { mapGetters, mapActions } from 'vuex';
import BrowserDetection from '../../../services/Functions/BrowserDetector';

export default {
    computed: {
        ...mapGetters('ResizeObserverOperations', ['getObserver', 'getHandlers'])
    },
    methods: {
        ...mapActions('ResizeObserverOperations', ['REGISTER_ELEMENT', 'RESET_OBSERVERS']),
        registerResizeElement(element, callback) {
            this.REGISTER_ELEMENT({ element: element, callback: callback });
        },
        detectBrowser() {
            return BrowserDetection.browserDetection();
        }
    },
    beforeDestroy() {
        this.RESET_OBSERVERS();
    }
};
