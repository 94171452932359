<template>
    <v-container>
        <v-data-iterator
            ref="userIterator"
            :items="courses"
            :search="search"
            :no-data-text="$t('Keine Kurse verfügbar')"
            :no-results-text="$t('Kein Ergebnis')"
            :hideDefaultFooter="getAllCourses.length === 0"
            :items-per-page="12"
            :footer-props="{
                'items-per-page-text': $t('Kurse pro Seite'),
                'items-per-page-options': [12, 24, 48, { text: $t('Alle'), value: -1 }]
            }"
            :custom-sort="selectedSort.sort"
            :custom-filter="filterItems"
        >
            <template v-slot:header>
                <div v-show="!isMyCourse">
                    <v-toolbar dense color="secondary" dark flat>
                        <v-toolbar-title>{{ $t('Kurse') }}</v-toolbar-title>
                        <v-spacer />
                        <v-toolbar-items>
                            <v-btn icon @click="toggleSearch">
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-expand-transition>
                        <v-card v-show="showSearch">
                            <v-card-text>
                                <v-layout class="pa-3">
                                    <v-text-field
                                        prepend-icon="mdi-magnify"
                                        :hint="$t('Geben Sie einen Begriff ein, um nach Kursen zu filtern')"
                                        :label="$t('Kurse durchsuchen')"
                                        v-model="search"
                                        attach
                                    />
                                </v-layout>
                                <v-layout class="pb-3 px-3 mb-3">
                                    <v-select
                                        prepend-icon="mdi-sort"
                                        attach
                                        :hint="$t('Wählen Sie eine Methode aus, nach der Sie die Kurse sortieren wollen')"
                                        return-object
                                        persistent-hint
                                        v-model="selectedSort"
                                        item-value="name"
                                        :items="sortList"
                                    >
                                        <template v-slot:item="{ item }">
                                            <div>{{ $t(item.name) }}</div>
                                        </template>
                                        <template v-slot:selection="{ item }">
                                            <div>{{ $t(item.name) }}</div>
                                        </template>
                                    </v-select>
                                    <v-btn icon @click="reverseOrder">
                                        <v-icon>mdi-swap-vertical</v-icon>
                                    </v-btn>
                                </v-layout>
                            </v-card-text>
                        </v-card>
                    </v-expand-transition>
                </div>
            </template>

            <template #default="{ items }">
                <v-row>
                    <v-col v-for="item in items" :key="item.id" :xs="12" :sm="12" :md="6">
                        <Course
                            :value="item"
                            @input="(val) => (selectedCourse = val)"
                            :isAdmin="isAdmin"
                            :isMyCourse="isMyCourse"
                            :selectable="true"
                            :selected="selectedCourse"
                            @selected="handleCardClick"
                            @selectedKnowledgeContent="handleSelectedKnowledgeContent"
                            :selectedKnowledgeContent="selectedKnowledgeContent"
                        />
                    </v-col>
                </v-row>
            </template>
        </v-data-iterator>
        <KnowledgeContentPreview toolbarTitle="Content" v-model="computedSelectedKnowledgeContent" />
    </v-container>
</template>

<script>
import Course from './Helpers/Course/Course';
import CourseSort from './Sort/CourseSort';
import CourseMixin from './Mixins/CourseMixin';
import KnowledgeContentPreview from '../../KnowledgeContent/KnowledgeContentPreview';

export default {
    name: 'my-courses',
    components: { Course, KnowledgeContentPreview },
    mixins: [CourseMixin],
    data: () => ({
        showSearch: false,
        search: '',
        selectedSort: CourseSort[0],
        sortList: CourseSort,
        selectedKnowledgeContent: []
    }),
    props: {
        isAdmin: {
            type: Boolean,
            default: false
        },
        isMyCourse: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        courses: {
            get() {
                if (this.isAdmin) {
                    return this.getAdminCourses;
                }
                return this.getAttendeePastCourses;
            }
        },
        selectedCourse: {
            get() {
                if (this.isAdmin) {
                    return this.getSelectedAdminCourse;
                }
                return this.getSelectedAttendeeCourse;
            },
            set(value) {
                if (this.isAdmin) {
                    this.SET_SELECTED_ADMIN_COURSE(value);
                } else {
                    this.SET_SELECTED_ATTENDEE_COURSE(value);
                }
            }
        },
        computedSelectedKnowledgeContent: {
            get() {
                if (this.selectedKnowledgeContent.length > 0) {
                    return this.selectedKnowledgeContent[0];
                }
                return {};
            },
            set() {
                this.selectedKnowledgeContent = [];
            }
        }
    },
    methods: {
        handleSelectedKnowledgeContent(value) {
            if (value.length > 0) {
                this.selectedKnowledgeContent = [value[value.length - 1]];
            } else {
                this.selectedKnowledgeContent = [];
            }
        },
        toggleSearch() {
            this.showSearch = !this.showSearch;
        },
        reverseOrder() {
            this.$refs.userIterator.computedPagination.descending = !this.$refs.userIterator.computedPagination.descending;
        },
        filterItems(items, search) {
            if (search.trim() === '') {
                return items;
            }
            let buildString = search
                .split(' ')
                .filter((word) => word !== '')
                .map((word) => `(?=.*${word})`)
                .join('');
            let regex = new RegExp(buildString, 'i');

            return items.filter((item) => regex.test(item.name) || regex.test(item.description));
        },
        handleCardClick(value) {
            if (this.selectedCourse.id === value.id) {
                this.selectedCourse = value;
                if (this.isAdmin) {
                    this.$router.push({ name: 'topics' });
                } else {
                    this.$router.push({ name: 'myTopics' });
                }
            }
            this.selectedCourse = value;
        }
    },
    created() {
        this.resetCourseSelection();
    }
};
</script>
