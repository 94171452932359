<template>
    <Filemanager selectable @selectedMedia="handleSelection" />
</template>
<script>
import Filemanager from '../../FileManager/Prototype/Filemanager';

export default {
    name: 'file-view',
    components: { Filemanager },
    methods: {
        handleSelection(value) {
            this.$emit('selectedMaterial', { media: value });
        }
    }
};
</script>
