<template>
    <v-card>
        <v-layout column fill-height>
            <v-container grid-list-md>
                <v-layout v-for="[key, value] in Object.entries(getAllowedFileTypes)" wrap class="mb-3" :key="key">
                    <v-flex xs2 justify-self-center align-self-center>
                        <span class="text-caption">{{ `${$t(key)}:` }}</span>
                    </v-flex>
                    <v-flex>
                        <v-btn-toggle multiple v-model="computedFileType">
                            <v-btn v-for="fileType in value" :key="fileType" :value="fileType">{{ fileType }}</v-btn>
                        </v-btn-toggle>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-layout>
    </v-card>
</template>

<script>
import CourseMixin from '../../Mixins/CourseMixin';

export default {
    name: 'upload-activity',
    mixins: [CourseMixin],
    data: () => ({
        fileTypes: []
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                if (value.hasOwnProperty('allowedFileTypes')) {
                    this.fileTypes = value.allowedFileTypes;
                }
            }
        }
    },
    computed: {
        computedFileType: {
            get() {
                return this.fileTypes;
            },
            set(value) {
                this.fileTypes = value;
                this.serialize();
            }
        }
    },
    methods: {
        serialize() {
            this.$emit('input', { allowedFileTypes: this.fileTypes });
        }
    },
    activated() {
        if (this.value.hasOwnProperty('allowedFileTypes')) {
            this.fileTypes = this.value.allowedFileTypes;
        }
        this.serialize();
        this.GET_ACTIVITY_ALLOWED_FILE_TYPES();
    }
};
</script>
