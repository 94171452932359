<template>
    <v-card>
        <Toolbar
            :toolbarTitle="$t('Antworten')"
            :tooltipMessage="$t('Auf dieser Seite haben Sie Überblick auf Rückmeldungen')"
            :showDrawer="false"
            :lastPath="{ name: 'threads' }"
        />
        <v-container>
            <AnswerList />
        </v-container>
    </v-card>
</template>

<script>
import ForumMixin from '../../Components/Forum/Mixins/ForumMixin';
import AnswerList from '../../Components/Forum/AnswerList';
import AddAnswer from '../../Components/Forum/AddAnswer';
import Toolbar from '../../Components/Generic/Toolbar';
import DeleteAnswer from '../../Components/Forum/DeleteAnswer';

export default {
    name: 'threads',
    components: {
        AnswerList,
        Toolbar
    },
    mixins: [ForumMixin],
    data: () => ({
        editorPermissions: {
            add: false,
            update: false,
            delete: false,
            move: false,
            copy: false,
            details: false,
            groups: false
        }
    }),
    mounted() {
        const permissions = {
            add: [this.getSelectedThread.canAnswer],
            update: [this.getSelectedThread.canAnswer],
            delete: [this.getSelectedThread.canAnswer],
            move: false,
            copy: false,
            details: false,
            groups: false
        };
        this.validateEditorState(permissions).then((result) => {
            this.editorPermissions = result;
        });
    }
};
</script>
