<template>
    <v-dialog v-model="dialog" :width="$vuetify.breakpoint.thresholds.xs" :fullscreen="$vuetify.breakpoint.xs" scrollable persistent>
        <template #activator="{ on, attrs }">
            <v-btn color="secondary" xs12 sm4 block v-bind="attrs" v-on="on">{{ $t('Datenschutz') }}</v-btn>
        </template>
        <v-card>
            <v-toolbar dense flat dark color="primary">
                <v-toolbar-title>{{ $t('Datenschutz') }}</v-toolbar-title>
                <v-spacer />
                <v-btn @click="dialog = false" text icon>
                    <v-icon color="white">mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="ck ck-content" v-html="getDataProtection" />
            <v-card-actions />
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'data-protection',
    data: () => ({
        dialog: false
    }),
    computed: {
        ...mapGetters('SystemSettings', ['getDataProtection'])
    }
};
</script>
