import axios from '../../plugins/Axios/axios';
import router from '@/router';
import { Storage } from '../../plugins/Storage/storage';

const state = {
    accessToken: Storage.getData('token'),
    permissionList: [],
    loggedInAdmin: {},
    adminProfilePicture: '',
    isInMultipleCompanies: false,
    companies: [],
    unmatchedCompanies: []
};

// getters
const getters = {
    accesstoken(state) {
        return state.accessToken;
    },
    getPermissionList(state) {
        return state.permissionList;
    },
    getLoggedInAdmin(state) {
        return state.loggedInAdmin;
    },
    getIsInMultipleCompanies() {
        return state.isInMultipleCompanies;
    },
    getAdminProfilePicture(state) {
        return state.adminProfilePicture;
    },
    getCompanies() {
        return state.companies;
    },
    getUnmatchedCompanies() {
        return state.unmatchedCompanies;
    }
};

// actions
const actions = {
    AUTO_LOGIN({ commit, getters, dispatch }, { accessToken, tokenType, user }) {
        axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`;
        dispatch('SAVE_USER_TOKEN_TO_STORAGE', { accessToken, user });
    },
    LOGIN({ commit, getters, dispatch }, loginData) {
        return axios.post('auth/login', loginData).then((response) => {
            commit('updatePermissionList', response.data.data.permissions);
            commit('updateIsInMultipleCompanies', response.data.data.isInMultipleCompany);
            commit('updateCompanies', response.data.data.customers);
            commit('updateUnmatchedCompanies', response.data.data.unmatchedCustomers);
            axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.data.accessToken}`;
            dispatch('SAVE_USER_TOKEN_TO_STORAGE', response.data.data);
            return response;
        });
    },
    SAVE_USER_TOKEN_TO_STORAGE({ commit }, userData) {
        commit('updateToken', userData.accessToken);
        commit('updateLoggedInAdmin', userData.user);
        commit('updateAdminProfilePicture', userData.user.profileImage);
        Storage.setData('token', userData.accessToken);
        Storage.saveTokenExpireDate();
    },
    GET_CURRENT_USER({ commit }) {
        return axios
            .get('/currentuser')
            .then((response) => {
                commit('updateLoggedInAdmin', response.data.data);
                commit('updateAdminProfilePicture', response.data.data.profileImage);
                return response;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    },
    FORGOT_PASSWORD({ commit }, dataToSend) {
        return axios.post('auth/forgotpassword', dataToSend).then((response) => {
            return response;
        });
    },
    SET_NEW_PASSWORD({ commit }, inputData) {
        return axios.post('auth/password', inputData).then((response) => {
            return response;
        });
    },
    ACTIVATE_USER({ commit }, dataToSend) {
        return axios.post('/activate', dataToSend).then((response) => response.data.data);
    },
    CLEAR_USER_DATA({ commit }) {
        commit('clear');
        commit('updateToken', null);
        Storage.removeData('token');
        Storage.removeData('expireDate');
        delete axios.defaults.headers.common['Authorization'];
    },
    LOGOUT({ commit }, obj = {}) {
        router.push({ name: 'login', params: obj });
    },
    UPDATE_LOGGED_IN_ADMIN({ commit }, user) {
        commit('updateLoggedInAdmin', user);
    },
    UPDATE_ADMIN_PROFILEPICTURE({ commit }, dataToSend) {
        var formDataToSend = new FormData();
        formDataToSend.append('file', dataToSend.data.file);
        return axios.post(`/admins/${dataToSend.adminId}/profilepicture`, formDataToSend).then((response) => {
            commit('updateAdminProfilePicture', '');
            return response;
        });
    },
    RESET_ADMIN_PROFILEPICTURE_BY_ID({ commit }, adminId) {
        return axios.delete(`/admins/${adminId}/profilepicture`).then((response) => {
            commit('updateAdminProfilePicture', '');
            return response;
        });
    },
    GET_ADMIN_PROFILEPICTURE_BY_ID({ commit }, adminId) {
        return axios.get(`/admins/${adminId}/profilepicture?authorization=${state.accessToken}`).then((response) => {
            commit('updateAdminProfilePicture', response.config.baseURL + response.config.url);
            return response;
        });
    }
};

// mutations
const mutations = {
    clear: (state) => {
        state.permissionList = [];
        state.loggedInAdmin = {};
        state.adminProfilePicture = '';
        state.isInMultipleCompanies = false;
        state.companies = [];
    },
    updateToken: (state, newvalue) => {
        state.accessToken = newvalue;
    },
    updatePermissionList: (state, permissionList) => {
        state.permissionList = permissionList;
    },
    updateLoggedInAdmin: (state, loggedInAdmin) => {
        state.loggedInAdmin = loggedInAdmin;
    },
    updateAdminProfilePicture: (state, adminProfilePicture) => {
        state.adminProfilePicture = adminProfilePicture;
        state.loggedInAdmin.profileImage = adminProfilePicture;
    },
    updateIsInMultipleCompanies: (state, isInMultipleCompanies) => {
        state.isInMultipleCompanies = isInMultipleCompanies;
    },
    updateCompanies: (state, companies) => {
        state.companies = companies;
    },
    updateUnmatchedCompanies: (state, companies) => {
        state.unmatchedCompanies = companies;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
