<template>
    <v-bottom-sheet :disabled="disabled" v-model="menu" inset>
        <template v-slot:activator="{ on: sheet }">
            <v-tooltip top>
                <template #activator="{ on, attrs }">
                    <v-btn
                        v-on="{ ...on, ...sheet }"
                        v-bind="attrs"
                        :color="color"
                        dark
                        fab
                        :disabled="disabled"
                        :small="$vuetify.breakpoint.xs"
                        @click="emitClick"
                        id="v-btn"
                    >
                        <v-icon>{{ icon }}</v-icon>
                    </v-btn>
                </template>
                <span>{{ label }}</span>
            </v-tooltip>
        </template>
        <v-sheet max-height="70vh" text style="overflow: auto">
            <slot></slot>
        </v-sheet>
    </v-bottom-sheet>
</template>

<script>
export default {
    name: 'pull-up-nav',
    data: () => ({ menu: false }),
    props: {
        label: {
            type: String,
            default: 'Menu'
        },
        icon: {
            type: String,
            default: 'people'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'primary'
        }
    },
    methods: {
        close() {
            this.menu = false;
        },
        emitClick() {
            this.$emit('click');
        }
    }
};
</script>
