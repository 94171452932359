<template>
    <v-layout column>
        <Toolbar
            :toolbarTitle="$t('Berechtigungsprofil Löschen')"
            tooltipMessage="Hier können Sie das gewünschte Element löschen."
            :showDrawer="false"
            :lastPath="lastPath"
        />
        <v-card>
            <v-card-text>{{ `${$t('Sind Sie sicher, dass Sie den folgenden Berechtigungsprofil löschen wollen?')} ${profile.name}` }}</v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="deletePermissionProfile">{{ $t('Löschen') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-layout>
</template>
<script>
import Toolbar from '../../../Components/Generic/Toolbar';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'delete-permission-profile',
    components: { Toolbar },
    data: () => ({
        profile: {},
        lastPath: '/PermissionProfiles'
    }),
    computed: {
        ...mapGetters('PermissionProfiles', ['getSelectedProfile'])
    },
    methods: {
        ...mapActions('PermissionProfiles', ['DELETE_PROFILE', 'SET_SELECTED_PROFILE']),
        deletePermissionProfile() {
            this.DELETE_PROFILE(this.getSelectedProfile).then(() => {
                this.$router.push({ name: 'permissionProfiles' }, () => this.SET_SELECTED_PROFILE({}));
            });
        }
    },
    mounted() {
        this.profile = { ...this.getSelectedProfile };
    }
};
</script>
