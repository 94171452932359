<template>
    <v-expand-transition mode="out-in">
        <keep-alive>
            <component :is="selectComponent" v-model="computedKnowledgeUnit" @media="emitMedia" @valid="emitValid" :valid="valid" />
        </keep-alive>
    </v-expand-transition>
</template>

<script>
import MultiSelect from '../OptionTypes/MultiSelectOption';
import SingleSelect from '../OptionTypes/SingleSelectOption';
import TrueFalse from '../OptionTypes/TrueFalseOption';
import FillBlank from '../OptionTypes/FillBlankOption';
import Picker from '../OptionTypes/PickerOption';
import SequenceImage from '../OptionTypes/SequenceOption';
import BucketSort from '../OptionTypes/BucketSortOption';

export default {
    name: 'quiz-options',
    components: {
        MultiSelect,
        SingleSelect,
        TrueFalse,
        FillBlank,
        Picker,
        SequenceImage,
        BucketSort
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        valid: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedKnowledgeUnit: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', {
                    ...this.value,
                    knowledgeUnitOptions: value
                });
            }
        },
        hasKnowledgeUnit() {
            return Object.keys(this.value).length > 0;
        },
        selectComponent: {
            get() {
                if (this.hasKnowledgeUnit && this.value.questionType) {
                    switch (this.value.questionType) {
                        case 'SINGLE_SELECT':
                            return SingleSelect;
                        case 'MULTI_SELECT':
                            return MultiSelect;
                        case 'TRUE_FALSE':
                            return TrueFalse;
                        case 'PICKER':
                            return Picker;
                        case 'FILL_BLANK':
                            return FillBlank;
                        case 'SEQUENCE':
                            return SequenceImage;
                        case 'BUCKET_SORT':
                            return BucketSort;
                    }
                }
                return 'div';
            }
        }
    },
    methods: {
        emitValid(value) {
            this.$emit('valid', value);
        },
        emitMedia(value) {
            this.$emit('media', value);
        }
    }
};
</script>
