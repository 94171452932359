<template>
    <v-card flat height="100%">
        <v-toolbar flat color="secondary">
            <v-btn icon color="white" align-left @click="onClick({})">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-arrow-left</v-icon>
                    </template>
                    <span>{{ $t('Schließen') }}</span>
                </v-tooltip>
            </v-btn>
            <v-toolbar-title class="white--text">{{ getSelectedFaq.question }}</v-toolbar-title>
            <v-spacer />
            <v-btn medium icon color="warning" align-center @click="updateFaq()" class="mr-3" v-if="editPermission()">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-pencil</v-icon>
                    </template>
                    <span>{{ $t('Hier können Sie das FAQ bearbeiten') }}</span>
                </v-tooltip>
            </v-btn>
            <v-btn medium icon color="error" align-center @click="deleteFaq" class="mr-3" v-if="deletePermission()">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-trash-can</v-icon>
                    </template>
                    <span>{{ $t('Hier können Sie das FAQ löschen') }}</span>
                </v-tooltip>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <v-layout column>
                <v-flex>
                    <v-row>
                        <v-col :cols="6" class="primary--text pointer" @click="onClick(getPreviousElement())">
                            <v-icon color="primary">mdi-chevron-left</v-icon>
                            {{ getPreviousElement().question }}
                        </v-col>
                        <v-col :cols="6" class="primary--text pointer" align="right" @click="onClick(getNextElement())">
                            {{ getNextElement().question }}
                            <v-icon color="primary">mdi-chevron-right</v-icon>
                        </v-col>
                    </v-row>
                </v-flex>
                <v-flex>
                    <v-alert
                        class="mt-3"
                        :value="getSelectedFaq && getSelectedFaq.state && getSelectedFaq.state.stateName == 'DECLINED'"
                        :type="alertStateType"
                        outlined
                        >{{ alertStateText }}</v-alert
                    >
                </v-flex>
                <v-flex v-show="getSelectedFaq.answer !== ''">
                    <v-divider class="my-3" />
                </v-flex>
                <v-flex v-if="getSelectedFaq.answer !== ''">
                    <div class="ck ck-content" v-html="getSelectedFaq.answer"></div>
                    <div style="clear: both"></div>
                </v-flex>
                <v-flex v-show="getSelectedFaq.answer !== ''">
                    <v-divider class="my-3" />
                </v-flex>
                <v-layout rwo wrap align-center class="mb-3">
                    <v-flex shrink>
                        <span class="primary--text">{{ getSelectedFaq.viewCount }}</span>
                    </v-flex>
                    <v-flex shrink>
                        <span class="pr-2">{{ '&nbsp;' + $t('Aufrufe') }}</span>
                    </v-flex>
                    <v-spacer />
                    <v-icon @click="like" :color="getThumbUpColor" align-center class="mr-1"> mdi-thumb-up </v-icon>
                    <v-flex shrink align-center class="mr-2">
                        <span class="text">{{ getSelectedFaq.likes }}</span>
                    </v-flex>
                    <v-icon @click="dislike" :color="getThumbDownColor" align-center class="mr-1"> mdi-thumb-down </v-icon>
                    <v-flex shrink>
                        <span class="text">{{ getSelectedFaq.dislikes }}</span>
                    </v-flex>
                </v-layout>
                <v-card>
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                {{ $t('Zusätzliche Informationen') }}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-card flat>
                                    <v-card-text>
                                        <v-layout row wrap align-center class="mb-3">
                                            <v-flex shrink>
                                                <span class="pr-2">{{ $t('Erstellt von') }}:</span>
                                            </v-flex>
                                            <v-flex shrink v-if="!getSelectedFaq.isDefaultFaq">
                                                <UserProfile :value="getSelectedFaq.createdBy" small :displayName="false" />
                                            </v-flex>
                                            <v-flex shrink v-else>
                                                <ImageLoader :src="wiselabFile" :width="30" :height="30" />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row wrap align-center class="mb-3" v-if="!getSelectedFaq.isDefaultFaq">
                                            <v-flex shrink>
                                                <span class="pr-2">{{ $t('Zuletzt bearbeitet von') }}:</span>
                                            </v-flex>
                                            <v-flex shrink>
                                                <UserProfile :value="getSelectedFaq.updatedBy" small :displayName="false" />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row wrap align-center class="mb-3" v-if="faqTags.length > 0">
                                            <v-flex shrink>
                                                <span class="pr-2">{{ $t('Tags') }}:</span>
                                            </v-flex>
                                            <v-flex shrink>
                                                <KnowledgeContentTags :items="faqTags" />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row wrap align-center class="mb-3" v-if="showStateSelector()">
                                            <v-flex shrink>
                                                <span class="pr-2">{{ $t('Status') }}:</span>
                                            </v-flex>
                                            <v-flex>
                                                <StateSelector
                                                    :value="getSelectedFaq.state"
                                                    :permissions="{ faq: ['CHANGE_FAQ_STATE'] }"
                                                    :onChange="changeFaqState"
                                                />
                                            </v-flex>
                                        </v-layout>
                                    </v-card-text>
                                </v-card>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </v-layout>
        </v-card-text>
    </v-card>
</template>

<script>
import FAQMixins from './Mixins/FAQMixins';
import StateSelector from './Helpers/NewStateSelector';
import UserProfile from '../Generic/Profile/UserProfile';
import KnowledgeContentTags from '../KnowledgeContent/KnowledgeContentTags';
import ImageLoader from '../Generic/ImageLoader';
import WiselabImage from '../../assets/Resources/PNG/Bildmarke.png';

export default {
    name: 'faq-display',
    mixins: [FAQMixins],
    components: {
        UserProfile,
        StateSelector,
        KnowledgeContentTags,
        ImageLoader
    },
    data: () => ({
        permissions: {
            delete: false,
            update: false
        }
    }),
    computed: {
        alertStateType() {
            return 'error';
        },
        alertStateText() {
            const message =
                this.getSelectedFaq && this.getSelectedFaq.state && this.getSelectedFaq.state.stateMessage ? this.getSelectedFaq.state.stateMessage : '';
            return this.$t('Dieses FAQ wurde abgelehnt. Der Grund: ') + message;
        },
        getThumbUpColor() {
            if (this.getSelectedFaq.hasRated && this.getSelectedFaq.ratingIsLike) {
                return 'primary';
            }
            return 'secondary';
        },
        getThumbDownColor() {
            if (this.getSelectedFaq.hasRated && this.getSelectedFaq.ratingIsLike == false) {
                return 'primary';
            }
            return 'secondary';
        },
        faqTags() {
            return this.getSelectedFaq.tags ? this.getSelectedFaq.tags.split(',') : [];
        },
        wiselabFile() {
            return WiselabImage;
        }
    },
    methods: {
        changeFaqState(state) {
            return this.CHANGE_FAQ_STATE({ id: this.getSelectedFaq.id, state: state });
        },
        like() {
            return this.RATE_FAQ({ id: this.getSelectedFaq.id, isLike: true });
        },
        dislike() {
            return this.RATE_FAQ({ id: this.getSelectedFaq.id, isLike: false });
        },
        onClick(faq) {
            if (faq.hasOwnProperty('id')) {
                this.GET_SINGLE_FAQ(faq);
            }
            this.$emit('getFaq', faq);
        },
        getPreviousElement() {
            if (!this.getSelectedFaq.hasOwnProperty('id')) {
                return this.getFaqList[this.getFaqList.length - 1];
            }
            var currentElement = this.getFaqList.filter((faq) => {
                return faq.id === this.getSelectedFaq.id;
            })[0];
            var index = this.getFaqList.indexOf(currentElement);
            if (index <= 0) {
                return this.getFaqList[this.getFaqList.length - 1];
            }
            if (index > 0) {
                return this.getFaqList[index - 1];
            }
        },
        getNextElement() {
            if (!this.getSelectedFaq.hasOwnProperty('id')) {
                return this.getFaqList[this.getFaqList.length - 1];
            }
            var currentElement = this.getFaqList.filter((faq) => {
                return faq.id === this.getSelectedFaq.id;
            })[0];
            var index = this.getFaqList.indexOf(currentElement);
            if (index >= this.getFaqList.length - 1) {
                return this.getFaqList[0];
            } else {
                return this.getFaqList[index + 1];
            }
        },
        updateFaq() {
            this.$emit('updateFaq');
        },
        deleteFaq() {
            this.$emit('deleteFaq');
        },
        editPermission() {
            if (this.getLoggedInAdmin.role == 'ROOT' || this.getLoggedInAdmin.role == 'SALES') {
                return true;
            }
            if (this.getSelectedFaq.isDefaultFaq) {
                return false;
            }
            return this.permissions.update;
        },
        deletePermission() {
            if (this.getLoggedInAdmin.role == 'ROOT' || this.getLoggedInAdmin.role == 'SALES') {
                return true;
            }
            return this.permissions.delete;
        },
        showStateSelector() {
            if (this.getLoggedInAdmin.role == 'ROOT' || this.getLoggedInAdmin.role == 'SALES') {
                return true;
            }
            if (this.getSelectedFaq.isDefaultFaq) {
                return false;
            }
            return true;
        }
    },
    beforeMount() {
        this.validateEditorState({
            delete: ['DELETE_FAQ'],
            update: ['UPDATE_FAQ']
        }).then((result) => (this.permissions = result));
    }
};
</script>
