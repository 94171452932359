<template>
    <v-container>
        <v-data-iterator
            ref="userIterator"
            :items="getCourseGradingTables"
            :search="search"
            :no-data-text="$t('Keine Bewertungstabellen verfügbar')"
            :no-results-text="$t('Kein Ergebnis')"
            :hideDefaultFooter="getCourseGradingTables.length === 0"
            :items-per-page="12"
            :footer-props="{
                'items-per-page-text': $t('Bewertungstabellen pro Seite'),
                'items-per-page-options': [12, 24, 48, { text: $t('Alle'), value: -1 }]
            }"
            :custom-sort="selectedSort.sort"
            :custom-filter="filterItems"
        >
            <template v-slot:header>
                <v-toolbar dense color="secondary" dark flat>
                    <v-toolbar-title>{{ $t('Bewertungstabellen') }}</v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items>
                        <AddUpdateGradingTable type="ADD" />
                        <AddUpdateGradingTable type="UPDATE" />
                        <DeleteGradingTable />
                        <v-btn icon @click="toggleSearch">
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-expand-transition>
                    <v-card v-show="showSearch">
                        <v-container>
                            <v-row align="center">
                                <v-col :cols="12">
                                    <v-text-field
                                        prepend-icon="mdi-magnify"
                                        :hint="$t('Geben Sie einen Begriff ein, um nach Bewertungstabellen zu filtern')"
                                        :label="$t('Bewertungstabellen durchsuchen')"
                                        v-model="search"
                                        attach
                                    />
                                </v-col>
                                <v-col>
                                    <v-select
                                        prepend-icon="mdi-sort"
                                        attach
                                        :hint="$t('Wählen Sie eine Methode aus, nach der Sie die Bewertungstabellen sortieren wollen')"
                                        return-object
                                        persistent-hint
                                        v-model="selectedSort"
                                        item-value="name"
                                        :items="sortList"
                                    >
                                        <template v-slot:item="{ parent, item }">
                                            <div>{{ $t(item.name) }}</div>
                                        </template>
                                        <template v-slot:selection="{ parent, item }">
                                            <div>{{ $t(item.name) }}</div>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn icon @click="reverseOrder">
                                        <v-icon>mdi-swap-vertical</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-expand-transition>
            </template>
            <template #default="{ items }">
                <v-container>
                    <v-row>
                        <v-col :xs="12" :sm="6" v-for="item in items" :key="item.id">
                            <GradingTable
                                :value="item"
                                @input="(value) => (selectedGradingTable = value)"
                                :selectable="true"
                                :selected="selectedCard"
                                @selected="handleCardClick"
                                :isAdmin="isAdmin"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </v-data-iterator>
    </v-container>
</template>

<script>
import GradingTable from './Helpers/GradingTable/GradingTable';
import GradingTablesSort from './Sort/GradingTablesSort';
import AddUpdateGradingTable from './AddUpdateGradingTable';
import DeleteGradingTable from './DeleteGradingTable';
import CourseMixin from './Mixins/CourseMixin';

export default {
    name: 'grading-tables',
    mixins: [CourseMixin],
    components: { GradingTable, AddUpdateGradingTable, DeleteGradingTable },
    data: () => ({
        showSearch: false,
        selectedCard: {},
        search: '',
        showGradingTable: false,
        selectedSort: GradingTablesSort[0],
        sortList: GradingTablesSort
    }),
    props: {
        isAdmin: {
            type: Boolean,
            value: false
        }
    },
    computed: {
        selectedGradingTable: {
            get() {
                return this.getSelectedGradingTable;
            },
            set(value) {
                this.SET_SELECTED_GRADING_TABLE(value);
            }
        },
        selectedCourse: {
            get() {
                if (this.isAdmin) {
                    return this.getSelectedAdminCourse;
                }
                return this.getSelectedAttendeeCourse;
            },
            set(value) {
                if (this.isAdmin) {
                    this.SET_SELECTED_ADMIN_COURSE(value);
                } else {
                    this.SET_SELECTED_ATTENDEE_COURSE(value);
                }
            }
        }
    },
    methods: {
        toggleSearch() {
            this.showSearch = !this.showSearch;
        },
        reverseOrder() {
            this.$refs.userIterator.computedPagination.descending = !this.$refs.userIterator.computedPagination.descending;
        },
        filterItems(items, search) {
            if (search.trim() === '') {
                return items;
            }
            let buildString = search
                .split(' ')
                .filter((word) => word !== '')
                .map((word) => `(?=.*${word})`)
                .join('');
            let regex = new RegExp(buildString, 'i');

            return items.filter((item) => regex.test(item.name) || regex.test(item.description));
        },
        openGradingTable(value) {
            this.$emit('gradingTable', value);
        },
        handleCardClick(value) {
            if (this.selectedCard.id === value.id) {
                // this.selectedTopic = value;
                // this.$router.push({ name: 'learningGoals' });
            } else {
                this.selectedCard = value;
            }
        }
    },
    mounted() {
        this.GET_COURSE_GRADING_TABLES(this.selectedCourse);
    }
};
</script>
