<template>
    <v-layout wrap>
        <v-flex md6>
            <v-menu :close-on-content-click="false" v-model="dateMenuVisibility">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        color="accent"
                        autocomplete="new-password"
                        prepend-icon="mdi-calendar"
                        readonly
                        :value="extractDate"
                        v-on="on"
                        v-bind="attrs"
                        :label="$t('Datum Auswählen')"
                    />
                </template>
                <v-date-picker :locale="localeComputed" width="300" :min="currentDate" :value="extractDate" @input="handleSelectedDate" />
            </v-menu>
        </v-flex>
        <v-flex md6>
            <v-menu :close-on-content-click="false" v-model="timeMenuVisibility" :disabled="extractDate === ''">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        color="accent"
                        autocomplete="new-password"
                        prepend-icon="mdi-clock-outline"
                        readonly
                        :value="extractTime"
                        v-on="on"
                        v-bind="attrs"
                        :label="$t('Zeit Auswählen')"
                    />
                </template>
                <v-time-picker width="300" :value="extractTime" :allowed-minutes="allowedMinutes" @input="handleSelectedTime" format="24hr" />
            </v-menu>
        </v-flex>
    </v-layout>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'date-time-selector',
    props: {
        value: {
            type: Object,
            default: () => ({
                startDate: ''
            })
        }
    },
    data() {
        return {
            dateMenuVisibility: false,
            timeMenuVisibility: false,
            date: new Date().toISOString(),
            time: ''
        };
    },
    computed: {
        ...mapGetters('LanguageOperations', ['getSelectedLanguage', 'getAvailableLanguages']),
        localeComputed() {
            return this.getSelectedLanguage;
        },
        currentDate() {
            return new Date().toISOString();
        },
        extractDate() {
            if (this.value.hasOwnProperty('startDate')) {
                if (this.value.startDate.length < 10) return '';
                return this.value.startDate.substring(0, 10);
            }
            return '';
        },
        extractTime() {
            if (this.value.hasOwnProperty('startDate')) {
                var regex = new RegExp('[0-9]{2}:[0-9]{2}', 'g');
                if (regex.test(this.value.startDate)) return this.value.startDate.match(regex)[0];
            }
            return '';
        },
        getSelectedDateTime() {
            return {
                startDate: `${this.date} ${this.time}`.trim()
            };
        }
    },
    methods: {
        handleSelectedTime(value) {
            this.time = value;
            // this.value.selectedTime = value;
            this.timeMenuVisibility = false;
            this.$emit('input', this.getSelectedDateTime);
        },
        handleSelectedDate(value) {
            this.date = value;
            // this.value.selectedDate = value;
            this.dateMenuVisibility = false;
            this.$emit('input', this.getSelectedDateTime);
        },
        allowedMinutes: (value) => value === 0 || value === 30
    }
};
</script>
