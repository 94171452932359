<template>
    <v-card>
        <v-toolbar dark text color="secondary">
            <v-toolbar-title>{{ $t(label) }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-tabs v-model="tabs">
            <v-tab key="ORIGINAL">{{ $t('Original') }}</v-tab>
            <v-tab key="CHANGES">{{ $t('Änderungen') }}</v-tab>
        </v-tabs>
        <v-card-text>
            <v-tabs-items v-model="tabs" touchless>
                <v-tab-item key="ORIGINAL">
                    <wiki-display :value="value" :isWikiProposal="true" />
                </v-tab-item>
                <v-tab-item key="CHANGES">
                    <keep-alive>
                        <component
                            :is="componentSwitch"
                            :value="selectedProposal"
                            :wiki="value"
                            @selectedProposal="setProposal"
                            @proposals="emitProposals"
                            :isWikiProposal="true"
                        />
                    </keep-alive>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
        <v-card-actions>
            <v-container>
                <v-row>
                    <v-col :cols="6">
                        <v-btn :disabled="!hasProposal" block color="warning" @click="setProposal({})">
                            {{ $t('Zurück zur Vorschlags-Liste') }}
                        </v-btn>
                    </v-col>
                    <v-col :cols="6">
                        <v-btn block color="success" :disabled="!hasProposal" @click="acceptWikiProposal">
                            {{ $t('Vorschlag übernehmen') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
    </v-card>
</template>

<script>
import WikiProposalList from './WikiProposalsList';
import WikiDisplay from '../WikiDisplay';

import WikiMixin from '../Mixins/WikiMixin';

export default {
    name: 'wiki-proposals-core',
    mixins: [WikiMixin],
    components: {
        WikiProposalList,
        WikiDisplay
    },
    data: () => ({
        tabs: 0,
        selectedProposal: {}
    }),
    props: {
        label: {
            type: String,
            default: 'Änderungsvorschläge'
        },
        value: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        value(value) {
            if (Object.keys(value).length > 0) {
                this.$nextTick(() => (this.tabs = 0));
            }
        }
    },
    computed: {
        hasProposal() {
            return Object.keys(this.selectedProposal).length > 0;
        },
        componentSwitch() {
            if (this.hasProposal) {
                return WikiDisplay;
            }
            return WikiProposalList;
        }
    },
    methods: {
        acceptWikiProposal() {
            this.ACCEPT_PROPOSAL({
                wiki: this.value,
                proposal: this.selectedProposal
            })
                .then(() => this.GET_WIKI_WITH_PROPOSALS())
                .then(() => this.close());
        },
        setProposal(value) {
            this.tabs = 1;
            this.selectedProposal = value;
        },
        emitProposals(value) {
            this.$emit('proposals', value);
        },
        close() {
            this.$emit('close');
            this.$nextTick(() => {
                this.tabs = 0;
                this.selectedProposal = {};
            });
        }
    }
};
</script>
