<template>
    <v-card height="100%">
        <Toolbar
            :toolbarTitle="$t('Nachrichten Editor')"
            :tooltipMessage="$t('Hier können Sie Nachrichten, die Sie schon hinzugefügt haben, anschauen, bearbeiten und löschen.')"
        />
        <v-container>
            <v-row align="center">
                <v-col>
                    <v-select
                        color="accent"
                        :items="getNews"
                        :label="$t('Nachrichten')"
                        item-value="id"
                        return-object
                        v-model="selectedNews"
                        :hint="$t('Diese Bezeichnung ist für die Identifikation der Nachricht innerhalb des Nachrichtentools')"
                        persistent-hint
                    >
                        <template v-slot:item="{ item: { name } }">
                            <span>{{ name }}</span>
                        </template>
                        <template v-slot:selection="{ item: { name } }">
                            <span>{{ name }}</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="auto">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" icon color="success" @click="createNews">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('create') }}</span>
                    </v-tooltip>
                </v-col>
                <v-col cols="auto">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" icon color="warning" :disabled="!isSelected" @click="editNews">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('Bearbeiten') }}</span>
                    </v-tooltip>
                </v-col>
                <v-col cols="auto">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" icon color="error" :disabled="!isSelected" @click="deleteNews">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('delete') }}</span>
                    </v-tooltip>
                </v-col>
            </v-row>
            <v-row v-show="isSelected" align-content="center" justify-content="center">
                <v-col :cols="6">
                    <v-card height="100%">
                        <v-card-text>
                            <v-switch
                                color="accent"
                                v-model="selectedNews.isPublished"
                                :label="$t('Publiziert')"
                                readonly
                                :hint="
                                    $t(
                                        'Wenn eine Nachricht publiziert ist, wird es auf der Hauptseite sichtbar und der Zeitpunkt der Publikation wird vermerkt'
                                    )
                                "
                                persistent-hint
                                class="mb-3"
                            />
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col :cols="6">
                    <v-card height="100%" class="d-flex align-center">
                        <v-card-text>
                            <v-input :label="$t(selectedNews.visibility)" :hint="$t(selectedHint)" persistent-hint>
                                <template v-slot:prepend>
                                    <v-btn icon :disabled="selectedNews.visibility !== 'CUSTOM'" @click="showCustomDetailsDialog">
                                        <v-icon>mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                            </v-input>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-show="isPublishedFrom">
                <v-col :cols="12">
                    <v-alert :value="isPublishedFrom" color="info" icon="info" outlined class="mb-3">
                        {{ `${$t('Diese Nachricht wird zu einem späteren Zeitpunkt publiziert')}: ${selectedNews.publishFrom}` }}
                    </v-alert>
                </v-col>
            </v-row>
            <v-row v-show="isSelected">
                <v-col :cols="12">
                    <v-card>
                        <v-toolbar text dense dark color="primary">
                            <v-toolbar-title>{{ selectedNews.title }}</v-toolbar-title>
                            <v-spacer />
                            {{ formatDate(selectedNews.publishedDate) }}
                        </v-toolbar>
                        <v-card-text style="max-height: calc(100vh - 450px)" class="overflow-y-auto">
                            <div class="ck ck-content" @click="imageGalleryClickHandler" v-html="selectedNews.content" />
                            <div style="clear: both"></div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <CustomVisibilityDetails v-model="selectedNews.options" :showDialog="showCustomDetails" @showDialog="(value) => (showCustomDetails = value)" />
    </v-card>
</template>

<script>
import NewsMixin from '../../Components/News/Mixins/NewsMixin';
import ImageGalleryMixin from '../../Components/Global/Mixins/ImageGalleryMixin';

import Toolbar from '../../Components/Generic/Toolbar';
import CustomVisibilityDetails from '../../Components/News/CustomVisibiliyDetails';

import moment from 'moment';

export default {
    name: 'news-editor',
    components: { Toolbar, CustomVisibilityDetails },
    mixins: [NewsMixin, ImageGalleryMixin],
    data: () => ({
        showCustomDetails: false
    }),
    computed: {
        isPublishedFrom() {
            return (
                this.getSelectedNews.publishFrom !== null &&
                this.getSelectedNews.publishFrom !== undefined &&
                this.getSelectedNews.publishFrom !== '' &&
                !this.getSelectedNews.isPublished
            );
        },
        isSelected() {
            return Object.keys(this.selectedNews).length > 0;
        },
        selectedNews: {
            get() {
                return this.getSelectedNews;
            },
            set(value) {
                this.SET_SELECTED_NEWS(value);
            }
        },
        selectedHint() {
            switch (this.getSelectedNews.visibility) {
                case 'ALL':
                    return 'Benutzer und Admin können diese Nachricht sehen';
                case 'ADMINS':
                    return 'Nur Admin können diese Nachricht sehen';
                case 'USERS':
                    return 'Nur Benutzer können diese Nachricht sehen';
                case 'CUSTOM':
                    return 'Nur ausgewählte Nutzer und/oder Nutzer, die der ausgewählten Gruppen angehören, können diese Nachricht sehen';
            }
            return '';
        }
    },
    methods: {
        createNews() {
            this.$router.push({ name: 'newsAdd' });
        },
        formatDate(publishedDate) {
            if (publishedDate == null) return '';
            else return moment(publishedDate).format('YYYY-MM-DD');
        },
        editNews() {
            this.$router.push({ name: 'newsUpdate' });
        },
        deleteNews() {
            this.$router.push({ name: 'newsDelete' });
        },
        showCustomDetailsDialog() {
            this.showCustomDetails = !this.showCustomDetails;
        }
    },
    mounted() {
        this.GET_NEWS().then(() => (this.selectedNews = this.getSelectedNews));
    }
};
</script>
