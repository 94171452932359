<template>
    <keep-alive>
        <component v-model="selectedTask" :is="componentSwitch" :type="type" @switchView="handleSwitchView" @goBack="$emit('navBack')" />
    </keep-alive>
</template>

<script>
import Calendar from './CalendarView';
import AddUpdatePlantool from './AddUpdatePlantool';
import ReportStatistics from '../Statistics/Dashboard';

export default {
    name: 'plantool',
    components: { Calendar, AddUpdatePlantool },
    data: () => ({
        selectedTask: {}
    }),
    props: {
        type: {
            type: String,
            validator: (v) => ['ADD', 'UPDATE', 'COPY', 'REPORT'].includes(v),
            default: 'ADD'
        },
        component: {
            type: String,
            default: 'CALENDAR'
        }
    },
    computed: {
        componentSwitch() {
            switch (this.component) {
                case 'ADD_TASK':
                    return AddUpdatePlantool;
                case 'UPDATE_TASK':
                    return AddUpdatePlantool;
                case 'COPY_TASK':
                    return AddUpdatePlantool;
                case 'CALENDAR':
                    return Calendar;
                case 'REPORT_STATISTICS':
                    return ReportStatistics;
            }
            return Calendar;
        }
    },
    methods: {
        handleSwitchView({ type, component }) {
            this.$emit('type', type);
            this.$emit('component', component);
        }
    }
};
</script>
