<template>
    <v-container>
        <v-row>
            <v-col :cols="12">
                <v-btn-toggle @change="handleToggle" :value="value.sendOnDays" multiple>
                    <v-row>
                        <v-col cols="auto">
                            <v-btn value="MONDAY">{{ $t('Montag') }}</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn value="TUESDAY">{{ $t('Dienstag') }}</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn value="WEDNESDAY">{{ $t('Mittwoch') }}</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn value="THURSDAY">{{ $t('Donnerstag') }}</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn value="FRIDAY">{{ $t('Freitag') }}</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn value="SATURDAY">{{ $t('Samstag') }}</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn value="SUNDAY">{{ $t('Sonntag') }}</v-btn>
                        </v-col>
                    </v-row>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <v-row align="center" justify="center">
            <v-col :cols="12">
                <v-time-picker @input="handleTime" :value="computedStartDate" />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: 'week-selector',
    props: {
        value: {
            type: Object,
            default: () => ({
                sendOnDays: [],
                startDate: ''
            })
        }
    },
    computed: {
        computedStartDate() {
            if (this.value.hasOwnProperty('startDate')) {
                return this.extractTime();
            }
            return '';
        }
    },
    methods: {
        extractTime() {
            var regex = new RegExp('[0-9]{2}:[0-9]{2}', 'g');
            if (regex.test(this.value.startDate)) return this.value.startDate.match(regex)[0];
            return '';
        },
        computeStartDate() {
            if (this.value.startDate === '') return '';
            let date = new Date();
            date.setDate(date.getDate() + 1);
            return `${date.toISOString().substring(0, 10)} ${this.extractTime()}`;
        },
        computedData() {
            return {
                sendOnDays: this.value.sendOnDays,
                startDate: this.computeStartDate()
            };
        },
        handleTime(value) {
            // this.value.startDate = value;
            this.$emit('input', this.computedData());
        },
        handleToggle(value) {
            // this.value.sendOnDays = value;
            this.$emit('input', this.computedData());
        }
    }
};
</script>
