<template>
    <v-card class="wikiMenuHeight">
        <v-toolbar dense dark flat color="secondary">
            <v-toolbar-title>{{ $t('Wiki Browser') }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-row>
                <v-col>
                    <v-text-field
                        :label="$t('Suchen')"
                        v-model="debouncedSearch"
                        :hint="$t('Geben Sie Begriffe ein, um relevante Wiki anzuzeigen')"
                        persistent-hint
                    />
                </v-col>
                <v-col shrink>
                    <v-checkbox
                        v-model="showTags"
                        :label="$t('Schlagwörter anzeigen')"
                        :hint="$t('Wenn diese Option aktiv ist, werden die Schlagwörter im Wiki Browser sichtbar')"
                        persistent-hint
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <v-treeview :search="search" :filter="filter" :items="getWikiTree" transition activatable :active.sync="selectedWiki" return-object class="pt-2">
            <template v-slot:prepend="{ item }">
                <v-badge overlap :value="getSubtreeChildren(item)">
                    <template v-slot:badge>
                        <span class="text-caption">{{ getSubtreeChildren(item) }}</span>
                    </template>
                    <v-icon medium :color="getStateColor(item)">mdi-file-document-outline</v-icon>
                </v-badge>
            </template>
            <template v-slot:label="{ item: { id }, active }">
                <span
                    :class="{
                        'primary--text': active,
                        pointer: true,
                        'no-highlight': true
                    }"
                >
                    {{ getWiki(id) ? getWiki(id).name : '' }}
                </span>
                <v-layout wrap v-show="getWiki(id).tags !== '' && showTags">
                    <v-flex shrink v-for="tag in getWiki(id).tags.split(',')" :key="tag.id">
                        <v-chip> {{ tag }} </v-chip>
                    </v-flex>
                </v-layout>
            </template>
        </v-treeview>
    </v-card>
</template>

<script>
import WikiMixin from './Mixins/WikiMixin';
import KnowledgeContentTags from '../KnowledgeContent/KnowledgeContentTags';

export default {
    name: 'wiki-menu',
    mixins: [WikiMixin, KnowledgeContentTags],
    data: () => ({
        search: '',
        searchDebounceTimer: null,
        showTags: false
    }),
    computed: {
        debouncedSearch: {
            get() {
                return this.search;
            },
            set(value) {
                clearTimeout(this.searchDebounceTimer);
                this.searchDebounceTimer = setTimeout(() => (this.search = value), 300);
            }
        },
        selectedWiki: {
            get() {
                if (Object.keys(this.getSelectedWiki).length === 0) {
                    return [];
                }
                return [this.getSelectedWiki];
            },
            set(value) {
                if (value.length === 0) {
                    this.SET_SELECTED_WIKI({});
                    this.SET_VIEW({ component: '', type: '', data: {} });
                } else {
                    const wiki = this.getWiki(value[0].id);
                    if (wiki !== undefined) {
                        if (this.getSelectedWiki.id !== wiki.id) {
                            this.SET_SELECTED_WIKI(wiki);
                            this.SET_VIEW({ component: 'GET_WIKI', type: '', data: wiki });
                        }
                    } else {
                        this.SET_SELECTED_WIKI({});
                        this.SET_VIEW({ component: '', type: '', data: {} });
                    }
                }
            }
        },
        filter() {
            return (item, search) => {
                if (search.trim() === '') {
                    return true;
                }
                const wiki = this.getWiki(item.id);
                if (wiki === undefined) {
                    return true;
                }
                const buildString = search
                    .split(' ')
                    .filter((word) => word !== '')
                    .map((word) => `(?=.*${word})`)
                    .join('');
                const regex = new RegExp(buildString, 'i');
                return regex.test(wiki.name.concat(wiki.tags));
            };
        }
    },
    methods: {
        getStateColor({ id }) {
            if (this.getWiki(id)) {
                const {
                    state: { stateName }
                } = this.getWiki(id);
                switch (stateName) {
                    case 'PENDING':
                        return 'warning';
                    case 'ACCEPTED':
                        return 'success';
                    case 'DECLINED':
                        return 'error';
                }
            } else {
                return 'primary';
            }
            return 'info';
        },
        getSubtreeChildren(item) {
            if (item.hasOwnProperty('children')) {
                if (item.children.length > 0) {
                    return item.children.length;
                }
            }
            return 0;
        }
    },
    mounted() {
        this.GET_WIKI_LIST().then(() => this.GET_WIKI_TREE());
    }
};
</script>
