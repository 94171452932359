<template>
    <v-select
        color="accent"
        v-model="selectedProfile"
        :items="getProfiles"
        :disabled="!hasProfiles"
        :label="$t('Berechtigungsprofil')"
        :hint="selectedProfile.description"
        item-value="id"
        return-object
        persistent-hint
        prepend-icon="mdi-account-circle-outline"
    >
        <template v-slot:item="{ item }">
            <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
            </v-list-item-content>
        </template>
        <template v-slot:selection="{ item }">{{ item.name }}</template>
    </v-select>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'profile-selector',
    props: {
        value: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters('PermissionProfiles', ['getProfiles', 'getSelectedProfile']),
        selectedProfile: {
            get() {
                return this.value;
            },
            set(value) {
                this.SET_SELECTED_PROFILE(value);
                this.$emit('input', value);
            }
        },
        hasProfiles() {
            return this.getProfiles.length > 0;
        }
    },
    watch: {
        getSelectedProfile(value) {
            this.$emit('input', value);
        }
    },
    methods: {
        ...mapActions('PermissionProfiles', ['SET_SELECTED_PROFILE', 'GET_PROFILES'])
    },
    mounted() {
        this.GET_PROFILES();
        if (Object.keys(this.getSelectedProfile).length > 0) {
            this.$emit('input', this.getSelectedProfile);
        }
    }
};
</script>
