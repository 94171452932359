<template>
    <v-select
        color="accent"
        :value="value"
        @change="handleInput"
        :items="profileListComputed"
        :disabled="!(hasProfiles && hasPermissionComputed)"
        :hint="$t('Wählen Sie die Berechtigungsprofile aus, die Sie zuweisen wollen')"
        :label="$t('Berechtigungsprofile')"
        persistent-hint
        multiple
        attach
        return-object
        item-value="id"
        prepend-icon="mdi-account-circle-outline"
        class="my-2"
    >
        <template v-slot:item="{ item: { name, description }, on, attrs }">
            <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-action>
                    <v-icon :color="attrs.inputValue ? 'accent' : ''">
                        {{ attrs.inputValue ? '$vuetify.icons.checkboxOn' : '$vuetify.icons.checkboxOff' }}
                    </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ description }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template>
        <template v-slot:selection="{ item }">
            <v-chip :color="hasPermissionComputed ? 'primary white--text' : 'grey white--text'" close @input="removeItem(item)">{{ item.name }}</v-chip>
        </template>
    </v-select>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    name: 'profile-combo',
    data: () => ({
        hasPermission: false
    }),
    props: {
        value: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    computed: {
        ...mapGetters('PermissionProfiles', ['getProfiles']),
        ...mapGetters('General', ['getLoggedInAdmin']),
        hasProfiles() {
            return this.profileListComputed.length > 0;
        },
        hasPermissionComputed() {
            return this.hasPermission;
        },
        profileListComputed() {
            if (this.hasPermission) {
                return [...this.getProfiles].sort((a, b) =>
                    a.name.localeCompare(b.name, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    })
                );
            } else {
                return [...this.value].sort((a, b) =>
                    a.name.localeCompare(b.name, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    })
                );
            }
        }
    },
    methods: {
        ...mapActions('PermissionProfiles', ['GET_PROFILES', 'RESET_PROFILE_LIST']),
        ...mapActions('AdminPermission', ['CHECK_PERMISSIONS']),
        sortAlphabetically(a, b) {
            if (a.hasOwnProperty('name') && b.hasOwnProperty('name')) {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
            }
            return 0;
        },
        removeItem(item) {
            let removedList = this.value.filter((filtered) => filtered.id !== item.id).sort(this.sortAlphabetically);
            this.$emit('input', removedList);
        },
        handleInput(value) {
            this.$emit('input', value.sort(this.sortAlphabetically));
        }
    },
    destroyed() {},
    mounted() {
        //check permission
        this.CHECK_PERMISSIONS({
            userId: this.getLoggedInAdmin.id,
            permissions: ['ASSIGN_PERMISSION_PROFILES_TO_ADMIN']
        }).then((hasPermission) => {
            this.hasPermission = hasPermission;
            if (this.hasPermission) {
                this.GET_PROFILES();
            }
        });
    }
};
</script>
