<template>
    <v-dialog v-model="menu" :width="$vuetify.breakpoint.thresholds.sm" :fullscreen="$vuetify.breakpoint.xs" persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-layout column align-center justify-center>
                <v-badge overlap color="success" :value="value.answered">
                    <template v-slot:badge>
                        <v-icon dark>mdi-check</v-icon>
                    </template>
                    <v-btn dark large icon :color="hexColor" v-on="on" v-bind="attrs">
                        <v-icon>{{ activityType }}</v-icon>
                    </v-btn>
                </v-badge>
                <span>{{ value.name }}</span>
            </v-layout>
        </template>
        <v-card>
            <v-toolbar dense flat :color="hexColor" dark>
                <v-toolbar-title :style="{ color: getCorrectTextColor }">{{ computedTabTitle }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="close">
                    <v-icon :style="{ color: getCorrectTextColor }">mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-container>
                    <v-row no-gutters>
                        <v-col>
                            <v-row no-gutters>
                                <v-col :cols="12">
                                    <span :class="{ title: true, limitTitle: true }">{{ value.name }}</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="auto">
                            <v-row no-gutters justify="end">
                                <v-col :cols="12">
                                    <v-icon>mdi-clock-outline</v-icon>
                                </v-col>
                                <v-col :cols="12">
                                    <span class="text-caption">{{ convertMinutes }}</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
                <span v-if="computedShowScore" class="text-caption pl-2">{{ `${value.activityType}: ${value.score} ${$t('Punkte')}` }}</span>
            </v-card-text>
            <v-expand-transition>
                <MyActivityInfo
                    v-show="showMoreInfo"
                    :selectable="false"
                    :value="value"
                    :hideDurationInDays="value.hideDurationInDays"
                    :isMyCourse="isMyCourse"
                    :timeScaleInMinutes="timeScaleInMinutes"
                    :showMoreInfo="showMoreInfo"
                />
            </v-expand-transition>
            <v-card-actions>
                <v-layout justify-center align-center pa-0>
                    <v-flex text-center>
                        <v-btn fab text small light @click="toggleMoreInfo">
                            <v-icon large>{{ showInfoIcon }}</v-icon>
                        </v-btn>
                    </v-flex>
                    <v-btn absolute right color="secondary" icon @click="handleActivity">
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </v-layout>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import MyActivityInfo from '../../CourseManager/Helpers/Activity/MyActivityInfo';
import CourseMixin from '../../CourseManager/Mixins/CourseMixin';
export default {
    name: 'my-activity',
    components: { MyActivityInfo },
    mixins: [CourseMixin],
    data: () => ({
        menu: false,
        showMoreInfo: false
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        timeScaleInMinutes: {
            type: Number,
            default: 60
        },
        isMyCourse: {
            type: Boolean,
            default: false
        },
        getLearningGoalTypeName: {
            type: String,
            default: null
        }
    },
    computed: {
        computedShowScore() {
            if (!this.getSelectedAttendeeCourse.useEvaluation) {
                return false;
            } else if (this.value.minScore === 0) {
                return false;
            }
            return true;
        },
        computedTabTitle() {
            if (this.value.typeName !== null) {
                return this.value.typeName;
            } else if (this.getLearningGoalTypeName !== null) {
                return this.getLearningGoalTypeName;
            } else if (this.getSelectedTopic.activityTypeName !== null) {
                return this.getSelectedTopic.activityTypeName;
            } else if (this.getSelectedAttendeeCourse.activityTypeName !== null) {
                return this.getSelectedAttendeeCourse.activityTypeName;
            }
            return this.$t('Aktivität');
        },
        showInfoIcon() {
            return this.showMoreInfo === false ? 'mdi-chevron-down' : 'mdi-chevron-up';
        },
        hiddenDurationInDays() {
            if (this.value.hideDurationInDays) {
                return '-';
            }
            return this.value.durationInDays;
        },
        computedDays() {
            if (this.value.durationInDays === 1) {
                return this.$t('Tag');
            }
            return this.$t('Tage');
        },
        hexColor() {
            if (this.value.hasOwnProperty('taxonomy')) {
                if (this.value.taxonomy !== null) {
                    return this.value.taxonomy.hexColor;
                }
            }
            return 'secondary';
        },
        activityType() {
            if (this.value.hasOwnProperty('activitySpecification')) {
                if (
                    this.value.activitySpecification !== null &&
                    this.value.activitySpecification !== undefined &&
                    this.value.activitySpecification.hasOwnProperty('type')
                ) {
                    switch (this.value.activitySpecification.type) {
                        case 'UPLOAD':
                            return 'mdi-cloud-upload';
                        case 'SELF_CHECK':
                            return 'mdi-note-text';
                        case 'TEXT_FIELD':
                            return 'mdi-sticker-text';
                        case 'INFORM':
                            return 'mdi-information-outline';
                        case 'QUIZ':
                            return 'mdi-flask-outline';
                    }
                    return 'mdi-information-outline';
                }
            }
            return 'mdi-information-outline';
        },
        computedShowClock() {
            if (this.isMyCourse && this.value.hideEstimatedWorkTimeInMinutes) {
                return false;
            }
            return true;
        },
        convertMinutes() {
            const timeUnitMinutes = this.value.estimatedWorkTimeInMinutes % this.timeScaleInMinutes;
            const timeUnit = this.value.estimatedWorkTimeInMinutes / this.timeScaleInMinutes;

            if (timeUnitMinutes.toString().length === 1) {
                return `${timeUnit.toString().split('.')[0]}:${'0' + timeUnitMinutes.toString()}`;
            }
            return `${timeUnit.toString().split('.')[0]}:${timeUnitMinutes.toString()}`;
        },
        getCorrectTextColor() {
            const threshold = 130;
            const hRed = hexToR(this.hexColor);
            const hGreen = hexToG(this.hexColor);
            const hBlue = hexToB(this.hexColor);
            function hexToR(h) {
                return parseInt(cutHex(h).substring(0, 2), 16);
            }
            function hexToG(h) {
                return parseInt(cutHex(h).substring(2, 4), 16);
            }
            function hexToB(h) {
                return parseInt(cutHex(h).substring(4, 6), 16);
            }
            function cutHex(h) {
                return h.charAt(0) == '#' ? h.substring(1, 7) : h;
            }
            let cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000;
            if (cBrightness > threshold) {
                return '#000000';
            } else {
                return '#ffffff';
            }
        }
    },
    methods: {
        close() {
            this.menu = false;
        },
        toggleMoreInfo() {
            this.showMoreInfo = !this.showMoreInfo;
        },
        handleActivity() {
            this.menu = false;
            this.$emit('activity', this.value);
        }
    }
};
</script>

<style scoped>
.limit {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
</style>
