<template>
    <v-card>
        <v-toolbar v-if="isCourseManagerValue" dark flat dense color="secondary">
            <v-spacer />
            <v-toolbar-items>
                <TaxonomyTable :isCourse="true" />
                <CourseAdminUser :isAdmin="true" :value="getSelectedAdminCourse" />
                <CourseAdminUser :isAdmin="false" :value="getSelectedAdminCourse" />
                <AddUpdateLearningGoal :type="operationType" />
            </v-toolbar-items>
        </v-toolbar>
        <v-tabs v-model="modalActive" show-arrows mandatory>
            <v-tab key="LEARNING_GOAL">{{ computedTabTitle }}</v-tab>
            <v-tab key="CONTENT" :disabled="value.knowledgeContents.length === 0">{{ $t('Content Links') }}</v-tab>
        </v-tabs>
        <v-card :class="cardStyle" @click.passive="selectCard" height="100%">
            <v-container>
                <v-row>
                    <v-col>
                        <v-tabs-items v-model="modalActive" touchless>
                            <v-tab-item key="LEARNING GOAL">
                                <v-container>
                                    <v-row no-gutters>
                                        <v-col>
                                            <v-row no-gutters>
                                                <v-col :cols="12">
                                                    <span :class="{ title: true, limitTitle: this.selectable }">{{ value.name }}</span>
                                                </v-col>
                                                <v-col :cols="12">
                                                    <span class="text-caption primary--text">
                                                        {{ `${$t('Aktivitäten')}: ${value.numActivities}` }}
                                                    </span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-row no-gutters justify="end">
                                                <v-col :cols="12">
                                                    <v-icon>mdi-clock-outline</v-icon>
                                                </v-col>
                                                <v-col :cols="12">
                                                    <span class="text-caption">{{ convertMinutes }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-expand-transition>
                                    <v-card flat v-show="showMoreInfo">
                                        <LearningGoalInfo
                                            v-if="!isMyCourse"
                                            :selectable="this.selectable"
                                            :value="value"
                                            :timeScaleInMinutes="this.timeScaleInMinutes"
                                            :durationInDays="showDurationInDays"
                                            :isMyCourse="isMyCourse"
                                        />
                                        <MyLearningGoalInfo
                                            v-else
                                            :selectable="this.selectable"
                                            :value="value"
                                            :timeScaleInMinutes="this.timeScaleInMinutes"
                                            :durationInDays="showDurationInDays"
                                            :isMyCourse="isMyCourse"
                                        />
                                    </v-card>
                                </v-expand-transition>
                            </v-tab-item>
                            <v-tab-item key="CONTENT">
                                <div class="ck ck-content scroll" style="max-height: 20vh" @click.stop>
                                    <KnowledgeContent :contents="value.knowledgeContents" :selectable="false" v-model="computedSelectedKnowledgeContent" />
                                </div>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-col>
                </v-row>
            </v-container>
            <v-card-actions>
                <v-row align="center" justify="center">
                    <v-col cols="auto">
                        <v-btn v-show="computedShowInfoButton" icon @click.stop="toggleMoreInfo"
                            ><v-icon>{{ showInfoIcon }}</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-card>
</template>

<script>
import CourseMixin from '../../Mixins/CourseMixin';

import LearningGoalInfo from './LearningGoalInfo';
import MyLearningGoalInfo from './MyLearningGoalInfo';
import TaxonomyTable from '../../TaxonomyTable';
import CourseAdminUser from '../../CourseAdminUser';
import AddUpdateLearningGoal from '../../AddUpdateLearningGoal';
import KnowledgeContent from '../../../../KnowledgeContent/KnowledgeContentContent';

export default {
    name: 'learning-goal',
    components: {
        KnowledgeContent,
        TaxonomyTable,
        CourseAdminUser,
        AddUpdateLearningGoal,
        LearningGoalInfo,
        MyLearningGoalInfo
    },
    mixins: [CourseMixin],
    data: () => ({
        modalActive: 0,
        showMoreInfo: false,
        showModal: false,
        operationType: 'UPDATE'
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        timeScaleInMinutes: {
            type: Number,
            default: 60
        },
        isAdmin: {
            type: Boolean,
            default: false
        },
        isMyCourse: {
            type: Boolean,
            default: false
        },
        isLearningGoal: {
            type: Boolean,
            default: false
        },
        isActivity: {
            type: Boolean,
            default: false
        },
        selected: {
            type: Object,
            default: () => ({})
        },
        selectable: {
            type: Boolean,
            default: false
        },
        selectedKnowledgeContent: {
            type: Array,
            default: () => []
        },
        isCourseManagerValue: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedTabTitle() {
            if (this.value.typeName !== null && this.value.typeName !== '') {
                return this.value.typeName;
            } else if (this.getSelectedTopic.learningGoalTypeName !== null && this.getSelectedTopic.learningGoalTypeName !== '') {
                return this.getSelectedTopic.learningGoalTypeName;
            } else if (this.getSelectedAdminCourse.learningGoalTypeName !== null && this.getSelectedAdminCourse.learningGoalTypeName !== '') {
                return this.getSelectedAdminCourse.learningGoalTypeName;
            } else {
                return this.$t('Lernziele');
            }
        },
        computedSelectedKnowledgeContent: {
            get() {
                return this.selectedKnowledgeContent;
            },
            set(value) {
                this.$emit('selectedKnowledgeContent', value);
            }
        },
        computedShowInfoButton() {
            if (this.modalActive === 0 && !this.isActivity) {
                return true;
            } else if (this.modalActive === 0 && this.isActivity && !this.$vuetify.breakpoint.xs) {
                return true;
            }
            return false;
        },
        emptyDescription() {
            if (this.value.description.length === 0) {
                return '-';
            }
            return this.value.description;
        },
        showDurationInDays() {
            if (this.isMyCourse && this.value.hideDurationInDays) {
                return 0;
            }

            return this.value.durationInDays;
        },
        convertMinutes() {
            const timeUnitMinutes = this.value.estimatedWorkTimeInMinutes % this.timeScaleInMinutes;
            const timeUnit = this.value.estimatedWorkTimeInMinutes / this.timeScaleInMinutes;

            if (timeUnitMinutes.toString().length === 1) {
                return `${timeUnit.toString().split('.')[0]}:${'0' + timeUnitMinutes.toString()}`;
            }
            return `${timeUnit.toString().split('.')[0]}:${timeUnitMinutes.toString()}`;
        },
        isCardSelected() {
            if (this.selected.hasOwnProperty('id') && this.value.hasOwnProperty('id')) {
                return this.selected.id === this.value.id;
            }
            return false;
        },
        cardStyle() {
            return {
                'elevation-12': this.isCardSelected,
                pointer: this.selectable
            };
        },
        showInfoIcon() {
            return this.showMoreInfo === false ? 'mdi-chevron-down' : 'mdi-chevron-up';
        },
        isComparable() {
            return (
                this.getSelectedLearningGoal !== null &&
                this.getSelectedLearningGoal !== undefined &&
                this.value !== null &&
                this.value !== undefined &&
                this.value.hasOwnProperty('id') &&
                this.getSelectedLearningGoal.hasOwnProperty('id')
            );
        },
        isSelected() {
            if (this.isComparable) {
                return this.value.id === this.getSelectedLearningGoal.id;
            }
            return false;
        }
    },
    methods: {
        update() {
            this.operationType = 'UPDATE';
            this.showModal = true;
        },
        toggleMoreInfo() {
            this.showMoreInfo = !this.showMoreInfo;
        },
        selectCard() {
            this.$emit('selected', this.value);
        },
        handleCourseSelection() {
            if (this.isComparable) {
                if (this.value.id === this.getSelectedLearningGoal.id) {
                    this.$emit('input', {});
                } else {
                    this.$emit('input', this.value);
                }
            } else {
                this.$emit('input', this.value);
            }
        }
    }
};
</script>
<style scoped>
.tabsDiv {
    min-height: 18vh;
}
.scroll {
    overflow-y: auto;
}
.limit {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}
.limitTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.pointer {
    cursor: pointer;
}
</style>
