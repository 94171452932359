<template>
    <v-container>
        <v-row>
            <v-col :cols="12">
                <v-select
                    color="accent"
                    :label="$t('Intervall')"
                    :items="intervals"
                    item-value="value"
                    :item-text="translateIntervalsDisplay"
                    v-model="selectedIntervalComputed"
                />
            </v-col>
            <v-col :cols="12">
                <component v-model="valueComputed" :is="renderPicker" />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import WeekSelector from './WeekSelector';
import DateTimeSelector from './DateTimeSelector';

export default {
    name: 'interval-selector',
    components: { WeekSelector, DateTimeSelector },
    data: () => ({
        selectedInterval: 'ONCE',
        intervals: [
            { value: 'ONCE', display: 'Einmal' },
            { value: 'WEEKLY', display: 'Wöchentlich' },
            { value: 'MONTHLY_FIRST_WEEK', display: 'Erste Woche des Monats' },
            { value: 'MONTHLY_LAST_WEEK', display: 'Letzte Woche des Monats' },
            { value: 'YEARLY', display: 'Jährlich' }
        ]
    }),
    props: {
        value: {
            type: Object,
            default: () => ({
                sendOnDays: [],
                startDate: ''
            })
        }
    },
    computed: {
        valueComputed: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', {
                    executionInterval: this.selectedInterval,
                    ...value
                });
            }
        },
        selectedIntervalComputed: {
            get() {
                return this.selectedInterval;
            },
            set(value) {
                this.selectedInterval = value;
                this.$emit('input', {
                    executionInterval: this.selectedInterval
                });
            }
        },
        renderPicker() {
            switch (this.selectedInterval) {
                case 'ONCE':
                    return 'DateTimeSelector';
                case 'WEEKLY':
                    return 'WeekSelector';
                case 'MONTHLY_FIRST_WEEK':
                    return 'WeekSelector';
                case 'MONTHLY_LAST_WEEK':
                    return 'WeekSelector';
                case 'YEARLY':
                    return 'DateTimeSelector';
                default:
                    return '';
            }
        }
    },
    methods: {
        translateIntervalsDisplay(value) {
            return this.$t(value.display);
        }
    }
};
</script>
