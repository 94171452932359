import FAQ from '../../views/FAQ/FAQ';

import { superAdmin, admin, user, root, sales } from '../Roles';

export default [
    {
        path: '/faq',
        name: 'faq',
        component: FAQ,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [user, admin, superAdmin],
            navigation: {
                type: { name: 'Utility', groupId: 'Sonstiges' },
                priority: 1,
                icon: 'mdi-help',
                name: 'FAQ',
                navigationKey: 'FAQ'
            }
        }
    },
    {
        path: '/faq',
        name: 'faq',
        component: FAQ,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [root, sales],
            navigation: {
                type: { name: 'Utility', groupId: 'Sonstiges' },
                priority: 1,
                icon: 'mdi-help',
                name: 'FAQ',
                navigationKey: 'FAQ'
            }
        }
    }
];
