<template>
    <v-card flat>
        <v-card-text>
            <GroupChatEditor v-model="selectedGroup" />
        </v-card-text>
    </v-card>
</template>

<script>
import ChatMixins from './../Mixins/ChatMixin';
import GroupChatEditor from './GroupChatEditor';

export default {
    name: 'group-chat',
    components: { GroupChatEditor },
    mixins: [ChatMixins],
    data: () => ({
        selectedGroup: {}
    }),
    mounted() {
        this.CHAT_GET_USER_GROUP_NOTIFICATIONS();
        this.CHAT_GET_ADMIN_GROUP_NOTIFICATIONS();
    }
};
</script>
