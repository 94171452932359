<template>
    <v-card>
        <v-tabs v-model="modalActive" show-arrows slider-color="accent">
            <v-tab key="REQUIRED">{{ $t('Erforderliche Angaben') }}</v-tab>
            <v-tab key="OPTIONAL">{{ $t('Optionale Angaben') }}</v-tab>
            <v-tab key="ASSIGNMENTS">{{ $t('Zuweisungen') }}</v-tab>
        </v-tabs>
        <v-card-text>
            <v-tabs-items v-model="modalActive" touchless>
                <v-tab-item key="REQUIRED">
                    <v-form v-model="validate" ref="form">
                        <v-container>
                            <v-row>
                                <v-col :cols="12">
                                    <v-text-field
                                        color="accent"
                                        autocomplete="new-password"
                                        v-model="displayName"
                                        type="text"
                                        :rules="[nameRules]"
                                        :label="$t('Angezeigter Name')"
                                        hint
                                        persistent-hint
                                        attach
                                        prepend-icon="mdi-account"
                                    />
                                </v-col>
                                <v-col :cols="12">
                                    <v-text-field
                                        color="accent"
                                        autocomplete="new-password"
                                        v-model="email"
                                        type="email"
                                        :rules="[emailRequired, validEmail]"
                                        :label="$t('E-Mail')"
                                        required
                                        prepend-icon="mdi-email"
                                    />
                                </v-col>
                                <v-col :cols="12">
                                    <v-text-field
                                        color="accent"
                                        v-model="firstName"
                                        autocomplete="new-password"
                                        type="text"
                                        :rules="[nameRules]"
                                        :label="$t('Vorname')"
                                        required
                                        prepend-icon="mdi-account"
                                    />
                                </v-col>
                                <v-col :cols="12">
                                    <v-text-field
                                        color="accent"
                                        v-model="lastName"
                                        autocomplete="new-password"
                                        type="text"
                                        :rules="[nameRules]"
                                        :label="$t('Nachname')"
                                        required
                                        prepend-icon="mdi-account"
                                    />
                                </v-col>
                                <!-- ACTIVATE USER/ADMIN -->
                                <v-col :cols="12">
                                    <v-switch
                                        color="accent"
                                        v-model="active"
                                        :label="$t('Benutzer aktivieren')"
                                        :hint="$t('Wenn sie diese Option auswählen, aktivieren Sie diesen Benutzer')"
                                        persistent-hint
                                    />
                                </v-col>
                                <!-- PASSWORD -->
                                <v-expand-transition mode="out-in">
                                    <v-row v-show="active">
                                        <v-col :cols="12">
                                            <v-text-field
                                                color="accent"
                                                autocomplete="new-password"
                                                v-model="password"
                                                :label="$t('Passwort eingeben')"
                                                :rules="active && type === 'ADD' ? [required, min, passwordMatch] : []"
                                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                :type="showPassword ? 'text' : 'password'"
                                                @click:append="showPassword = !showPassword"
                                                prepend-icon="mdi-lock"
                                            />
                                        </v-col>
                                        <!-- PASSWORD CONFIRM -->
                                        <v-col :cols="12">
                                            <v-text-field
                                                color="accent"
                                                autocomplete="new-password"
                                                block
                                                v-model="verifyPassword"
                                                :label="$t('Passwort bestätigen')"
                                                :rules="active && type === 'ADD' ? [required, min, passwordMatch] : [passwordMatch]"
                                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                :type="showPassword ? 'text' : 'password'"
                                                @click:append="showPassword = !showPassword"
                                                prepend-icon="mdi-lock"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-expand-transition>
                                <!-- DISABLE FROM -->
                                <v-col :cols="12">
                                    <v-switch
                                        color="accent"
                                        v-model="showDisableComputed"
                                        :label="$t('Benutzer deaktivieren von')"
                                        :hint="$t('Geben Sie ein Datum an, ab der der Nutzer deaktivert wird')"
                                        persistent-hint
                                    />
                                </v-col>
                                <!-- PICKERDATE -->
                                <v-col :cols="12" v-show="showDisableComputed">
                                    <PickerDate
                                        type="Day"
                                        :label1="$t('Disable from')"
                                        :startDateText="disableFrom"
                                        :min="minDate"
                                        @pickerStartDateEmit="disableFromEmit"
                                    />
                                </v-col>
                                <!-- REACTIVATE LINK -->
                                <v-col :cols="12" v-if="showActivationButton">
                                    <v-btn :disabled="disableActivationLink" class="secondary" @click="resendActivationLink()">
                                        <v-icon class="mr-2">mdi-refresh</v-icon>
                                        {{ $t('Aktivierungslink erneut senden') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-tab-item>
                <v-tab-item key="OPTIONAL">
                    <v-container>
                        <v-row>
                            <v-col :cols="12">
                                <v-text-field
                                    color="accent"
                                    autocomplete="new-password"
                                    v-model="personalNumber"
                                    type="text"
                                    :label="$t('Personalnummer')"
                                    required
                                    prepend-icon="mdi-account"
                                    hide-details
                                />
                            </v-col>
                            <v-col :cols="12">
                                <v-text-field
                                    color="accent"
                                    autocomplete="new-password"
                                    v-model="state"
                                    type="text"
                                    :label="$t('Bundesland')"
                                    required
                                    prepend-icon="mdi-google-maps"
                                    hide-details
                                />
                            </v-col>
                            <v-col :cols="12">
                                <v-text-field
                                    color="accent"
                                    autocomplete="new-password"
                                    v-model="city"
                                    type="text"
                                    :label="$t('Stadt')"
                                    required
                                    prepend-icon="mdi-google-maps"
                                    hide-details
                                />
                            </v-col>
                            <v-col :cols="12">
                                <v-text-field
                                    color="accent"
                                    autocomplete="new-password"
                                    v-model="street"
                                    type="text"
                                    :label="$t('Straße')"
                                    required
                                    prepend-icon="mdi-google-maps"
                                    hide-details
                                />
                            </v-col>
                            <v-col :cols="12">
                                <v-text-field
                                    color="accent"
                                    autocomplete="new-password"
                                    v-model="houseNo"
                                    type="text"
                                    :label="$t('Hausnummer')"
                                    required
                                    prepend-icon="mdi-home"
                                    hide-details
                                />
                            </v-col>
                            <v-col :cols="12">
                                <v-text-field
                                    color="accent"
                                    autocomplete="new-password"
                                    v-model="mobileNo"
                                    type="text"
                                    :label="$t('Telefon')"
                                    required
                                    prepend-icon="mdi-cellphone"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="ASSIGNMENTS">
                    <v-container>
                        <v-row>
                            <v-col :cols="12" v-if="showPermissionProfiles">
                                <ProfileCombo v-model="profiles" />
                            </v-col>
                            <v-col :cols="12" v-if="showAddToGroups">
                                <GroupSelector v-model="selectedGroups" :showAdminGroups="role === 'ADMIN'" :showUserGroups="role === 'USER'" />
                            </v-col>
                            <v-col :cols="12" v-if="showSwitchRole">
                                <role-switch v-model="switchRole" />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>

        <v-card-actions>
            <v-btn :color="computedSubmitButtonColor" block @click="save" :disabled="!validate">
                {{ computedSubmitButtonText }}
            </v-btn>
        </v-card-actions>

        <v-dialog v-model="showErrorValidation" persistent :width="$vuetify.breakpoint.thresholds.sm">
            <v-card>
                <v-toolbar dark dense flat color="secondary">
                    <v-card-title>{{ $t('Achtung!') }}</v-card-title>
                </v-toolbar>
                <v-card-text>{{ `${$t('Fehler')}: ${$t(errorMessage)}` }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="showErrorValidation = false">{{ $t('Nein') }}</v-btn>
                    <v-btn class="primary" @click="reactivateUser">{{ $t('Ja') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import UserMixin from './Mixins/UserMixin';
import GroupSelector from '../Groups/GroupSelector';
import PickerDate from '../Generic/PickerDate/PickerDate';
import RoleSwitch from './Role/RoleSwitch';
import ProfileCombo from '../Permission/Profiles/ProfileCombo';
import moment from 'moment';

export default {
    name: 'add-update-user',
    components: {
        GroupSelector,
        RoleSwitch,
        ProfileCombo,
        PickerDate
    },
    mixins: [UserMixin],
    data: () => ({
        permissions: {
            switchRole: false,
            groupAssignment: false,
            permissionProfileAssignment: false
        },
        showPassword: false,
        validate: false,
        selectedGroups: [],
        selectedProfiles: [],
        minDate: moment().format('YYYY-MM-DD'),
        showErrorValidation: false,
        errorMessage: '',
        switchRole: false,
        modalActive: 0,

        active: false,
        id: '',
        city: '',
        disableFrom: '',
        displayName: '',
        email: '',
        firstName: '',
        houseNo: '',
        language: '',
        lastName: '',
        mobileNo: '',
        personalNumber: '',
        password: '',
        verifyPassword: '',
        postalcode: '',
        profileImage: '',
        profiles: [],
        state: '',
        street: ''
    }),
    watch: {
        password() {
            this.$refs.form.validate();
        },
        verifyPassword() {
            this.$refs.form.validate();
        },
        active(value) {
            if (!value) {
                this.password = '';
                this.verifyPassword = '';
            }
        }
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        role: {
            type: String,
            default: 'USER'
        },
        type: {
            type: String,
            validator: (v) => ['ADD', 'UPDATE'].includes(v),
            default: 'ADD'
        }
    },
    computed: {
        computedSubmitButtonText() {
            switch (this.type) {
                case 'ADD':
                    return this.$t('Hinzufügen');
                case 'UPDATE':
                    return this.$t('Aktualisieren');
            }
            return '';
        },
        computedSubmitButtonColor() {
            switch (this.type) {
                case 'ADD':
                    return 'success';
                case 'UPDATE':
                    return 'warning';
            }
            return '';
        },
        showDisableComputed: {
            set(val) {
                if (!val) this.disableFrom = '';
                else this.disableFrom = moment().format('YYYY-MM-DD');
            },
            get() {
                return this.disableFrom !== '';
            }
        },
        disableActivationLink() {
            return this.disableFrom !== '' && this.disableFrom <= moment().format('YYYY-MM-DD');
        },
        showAddToGroups() {
            return this.permissions.groupAssignment;
        },
        showSwitchRole() {
            return this.type === 'UPDATE' && this.permissions.switchRole;
        },
        showActivationButton() {
            return this.type === 'UPDATE';
        },
        showPermissionProfiles() {
            return this.role === 'ADMIN' && this.permissions.permissionProfileAssignment;
        },
        passwordMatch() {
            return (v) => (v === this.password && v === this.verifyPassword) || this.$t('Passwort muss übereinstimmen');
        }
    },
    methods: {
        resendActivationLink() {
            this.RESEND_ACTIVATIONLINK_BY_ID(this.id);
        },
        min(v) {
            return (!!v && v.length >= 8) || this.$t('Mindestens 8 Zeichen');
        },
        required(value) {
            return !!value || this.$t('Erforderlich');
        },
        emailRequired(v) {
            return !!v || this.$t('E-mail muss angegeben werden');
        },
        validEmail(v) {
            return /.+@.+/.test(v) || this.$t('Geben sie eine gültige E-mail Adresse an');
        },
        nameRules(v) {
            return !!v || this.$t('Pflichtfeld');
        },
        disableFromEmit(val) {
            this.disableFrom = val;
        },
        close(user = {}) {
            if (Object.keys(user).length > 0) {
                this.$emit('input', this.getTreeObject(user));
            }
            this.$emit('switchView', { component: '', type: '', data: {} });
        },
        serialize() {
            return {
                active: this.active,
                ...(this.type === 'UPDATE' ? { id: this.id } : {}),
                ...(this.type === 'UPDATE' && this.password !== '' ? { password: this.password } : {}),
                city: this.city,
                disableFrom: this.disableFrom,
                displayName: this.displayName,
                email: this.email,
                firstName: this.firstName,
                houseNo: this.houseNo,
                language: this.language,
                lastName: this.lastName,
                mobileNo: this.mobileNo,
                personalNumber: this.personalNumber,
                password: this.password,
                postalcode: this.postalcode,
                state: this.state,
                street: this.street
            };
        },
        groupSerializeUser(getUserId) {
            return {
                user: { id: getUserId },
                groups: this.selectedGroups.map((group) => group.id)
            };
        },
        groupSerializeAdmin(getAdminId) {
            return {
                admin: { id: getAdminId },
                groups: this.selectedGroups.map((group) => group.id)
            };
        },
        selectedProfileSerialize(getAdminId) {
            return {
                adminId: getAdminId,
                profiles: this.profiles.map((profiles) => profiles.id)
            };
        },
        save() {
            //USER ADD
            if (this.role === 'USER' && this.type === 'ADD') {
                this.ADD_USER(this.serialize())
                    .then((response) => {
                        if (this.showAddToGroups) {
                            return this.SET_USER_GROUP_USERS(this.groupSerializeUser(response.id)).then(() => response);
                        }
                        return response;
                    })
                    .then((response) => this.close(response))
                    .catch((err) => {
                        if (err.response != undefined && err.response.data.errors[0].code !== 4002) {
                            this.showErrorValidation = true;
                            this.errorMessage = err.response.data.errors[0].message;
                        }
                    });
            }
            //USER UPDATE
            if (this.role === 'USER' && this.type === 'UPDATE') {
                return this.UPDATE_USER(this.serialize())
                    .then((response) => {
                        if (this.switchRole && this.showSwitchRole) {
                            return this.UPDATE_ROLE_USERTOADMIN({
                                users: [response.id],
                                role: 'ADMIN'
                            });
                        }
                        if (this.showAddToGroups) {
                            return this.SET_USER_GROUP_USERS(this.groupSerializeUser(response.id)).then(() => response);
                        }
                        return response;
                    })
                    .then((response) => this.close(response));
            }
            //ADMIN ADD
            if (this.role === 'ADMIN' && this.type === 'ADD') {
                return this.ADD_ADMIN(this.serialize())
                    .then((response) => {
                        let resolve = [];
                        if (this.showAddToGroups) {
                            resolve.push(this.SET_ADMIN_GROUP_USERS(this.groupSerializeAdmin(response.id)));
                        }
                        if (this.showPermissionProfiles) {
                            resolve.push(this.SET_PERMISSION_PROFILES_TO_USER(this.selectedProfileSerialize(response.id)));
                        }
                        return Promise.all(resolve).then(() => response);
                    })
                    .then((response) => this.close(response))
                    .catch((err) => {
                        if (err.response != undefined && err.response.data.errors[0].code !== 4002) {
                            this.showErrorValidation = true;
                            this.errorMessage = err.response.data.errors[0].message;
                        }
                    });
            }
            //ADMIN UPDATE
            if (this.role === 'ADMIN' && this.type === 'UPDATE') {
                return this.UPDATE_ADMIN(this.serialize())
                    .then((response) => {
                        let resolve = [];
                        if (!this.switchRole && this.showSwitchRole) {
                            return this.UPDATE_ROLE_ADMINTOUSER({
                                admins: [response.id],
                                role: 'USER'
                            });
                        }
                        if (this.showAddToGroups) {
                            resolve.push(this.SET_ADMIN_GROUP_USERS(this.groupSerializeAdmin(response.id)));
                        }
                        if (this.showPermissionProfiles) {
                            resolve.push(this.SET_PERMISSION_PROFILES_TO_USER(this.selectedProfileSerialize(response.id)));
                        }
                        return Promise.all(resolve).then(() => response);
                    })
                    .then((response) => this.close(response));
            }
        },
        reactivateUser() {
            this.GET_DELETED_USER_LIST().then(() => {
                const find = this.getDeletedUserList.find((user) => user.email === this.email);
                if (find !== undefined) {
                    return this.RECOVER_USER({
                        active: true,
                        id: find.id
                    }).then(() => this.close());
                }
            });
            this.GET_DELETED_ADMIN_LIST().then(() => {
                const find = this.getDeletedAdminList.find((user) => user.email === this.email);
                if (find !== undefined) {
                    return this.RECOVER_ADMIN({
                        active: true,
                        id: find.id
                    }).then(() => this.close());
                }
            });
        }
    },
    activated() {
        Object.entries(this.$data).forEach(([key, value]) => {
            if (key === 'minDate') {
                this.$data[key] = moment().format('YYYY-MM-DD');
                return;
            }
            if (typeof value === 'string') {
                this.$data[key] = '';
                return;
            }
            if (typeof value === 'boolean') {
                this.$data[key] = false;
                return;
            }
            if (Array.isArray(value)) {
                this.$data[key] = [];
                return;
            }
            if (typeof value === 'number') {
                this.$data[key] = 0;
            }
        });
        if (this.type === 'ADD') {
            if (this.isUserOrGroup(this.value) === 'GROUP') {
                this.selectedGroups = [this.value];
            }
        }
        if (this.type === 'UPDATE') {
            this.selectedGroups = this.getUsersGroups(this.value);
            this.selectedProfiles = this.getUsersProfiles(this.value);
            Object.entries(this.value).forEach(([key, value]) => (this.$data[key] = value));
            this.switchRole = this.role === 'ADMIN';
        }
        const permissions = {
            switchRole: ['CHANGE_ROLE'],
            groupAssignment: [this.role === 'ADMIN' ? 'ADMIN_MANAGEMENT' : 'USER_MANAGEMENT'],
            permissionProfileAssignment: ['ASSIGN_PERMISSION_PROFILES_TO_ADMIN']
        };
        this.validateEditorState(permissions).then((response) => {
            this.permissions = response;
            if (this.showPermissionProfiles && this.role === 'ADMIN' && this.type === 'UPDATE') {
                this.GET_PERMISSION_PROFILES_FROM_USER(this.id).then((profiles) => (this.profiles = profiles));
            }
        });
        this.$nextTick(() => {
            this.validate = this.$refs.form.validate();
        });
    }
};
</script>
