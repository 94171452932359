import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters('FAQOperations', [
            'getFaqMap',
            'getFaqGroups',
            'getFaqGroup',
            'getSelectedFaq',
            'getSelectedFaqGroup',
            'getFaqList',
            'getView',
            'getRecentChanges',
            'getBestRatedFAQ'
        ])
    },
    methods: {
        ...mapActions('FAQOperations', [
            'ADD_FAQ',
            'UPDATE_FAQ',
            'GET_SINGLE_FAQ',
            'GET_FAQS',
            'GET_FAQ_LIST',
            'GET_RECENT_CHANGES',
            'DELETE_FAQ',
            'RATE_FAQ',
            'CHANGE_FAQ_STATE',
            'ADD_FAQ_GROUP',
            'UPDATE_FAQ_GROUP',
            'DELETE_FAQ_GROUP',
            'SET_VIEW',
            'GET_BEST_RATED_FAQS',
            'REQUEST_FAQ',
            'SET_SELECTED_FAQ_GROUP'
        ])
    }
};
