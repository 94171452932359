<template>
    <v-treeview :items="getTree" return-object class="pt-2" item-key="id" :open="groupPath">
        <template v-slot:prepend="{ item, open }">
            <v-icon>{{ icon(item, open) }}</v-icon>
        </template>
        <template v-slot:label="{ item }">
            <v-layout>
                <v-flex shrink @click.stop v-show="isFilemanagerType(item) === 'FOLDER'" align-self-center justify-self-center>
                    <v-checkbox
                        class="pa-0 ma-0"
                        hide-details
                        :value="item.id"
                        v-model="computedSelection"
                        :multiple="multiple"
                        :disabled="isDisabled(item)"
                        :on-icon="multiple ? $vuetify.icons.checkboxOn : $vuetify.icons.radioOn"
                        :off-icon="multiple ? $vuetify.icons.checkboxOff : $vuetify.icons.radioOff"
                        :ripple="false"
                    />
                </v-flex>
                <v-flex align-self-center>
                    <span class="no-highlight">
                        {{ item.name || item.fileName }}
                    </span>
                </v-flex>
            </v-layout>
        </template>
    </v-treeview>
</template>

<script>
import FilemanagerMixin from '../../Mixins/FilemanagerPrototypeMixin';
export default {
    name: 'filemanager-folder-selector',
    mixins: [FilemanagerMixin],
    data: () => ({
        groupPath: []
    }),
    props: {
        multiple: {
            type: Boolean,
            default: false
        },
        value: {
            type: [Array, Object],
            default: function () {
                return this.multiple ? [] : {};
            }
        },
        disabledNode: {
            type: Object,
            default: () => ({})
        }
    },
    // watch:{
    //     value()
    // },
    computed: {
        disabledNodes() {
            const flatten = (nodes) => {
                return nodes.reduce((acc, curr) => {
                    if (curr.children && curr.children.length > 0) {
                        return [...acc, curr, ...flatten(curr.children)];
                    }
                    return [...acc, curr];
                }, []);
            };
            return this.disabledNode && this.disabledNode.children ? [this.disabledNode.id, ...flatten(this.disabledNode.children).map(({ id }) => id)] : [];
        },
        computedSelection: {
            get() {
                if (Array.isArray(this.value)) {
                    return this.value.map(({ id }) => id);
                }
                return this.value && this.value.id ? this.value.id : '';
            },
            set(value) {
                if (this.multiple) {
                    if (this.value.includes(value)) {
                        this.$emit(
                            'input',
                            this.value.filter((item) => item.id !== value)
                        );
                    } else {
                        this.$emit('input', [...this.value, this.convertIdToObject(value)]);
                    }
                } else {
                    if (value !== '') {
                        this.$emit('input', this.convertIdToObject(value));
                    }
                }
            }
        }
    },
    methods: {
        setPath() {
            this.groupPath = this.calculateTreePath(this.value).filter((item) => item.id !== this.value.id);
        },
        isDisabled({ id }) {
            return this.disabledNodes.includes(id);
        },
        icon(item, open) {
            switch (this.isFilemanagerType(item)) {
                case 'FILE':
                    return 'mdi-file';
                case 'FOLDER':
                    return open ? 'mdi-folder-open-outline' : 'mdi-folder-outline';
            }
            return '';
        },
        convertIdToObject(id) {
            return this.getFolder({ id });
        }
    },
    activated() {
        this.setPath();
    }
};
</script>

<style scoped>
.v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
}
</style>
