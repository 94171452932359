import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import i18n from '@/plugins/I18n/i18n';
import Vuetify from '@/plugins/Vuetify/vuetify';
import './registerServiceWorker';
import MathJax, { initMathJax, renderByMathjax } from 'mathjax-vue';
// import VueMathjax from 'vue-mathjax'
/**
 * SCORM
 */
// require('scorm-again');

import 'scorm-again/dist/scorm2004';

/**
 * Load CSS globally
 */
import './styles/main.scss';

/**
 * CKEditor config
 */
import '@wiselab/ckeditor/build/ckeditor.js';

/**
 * Vue Config
 */
Vue.config.productionTip = false;

function onMathJaxReady() {
    const el = document.getElementById('app');
    renderByMathjax(el);
}

initMathJax({}, onMathJaxReady);

Vue.use(MathJax);

const instance = new Vue({
    router,
    store,
    i18n,
    vuetify: Vuetify,
    render: (h) => h(App)
});

instance.$mount('#app');

export default instance;
