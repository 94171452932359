import axios from '../../plugins/Axios/axios';
import Vue from 'vue';

const state = {
    faqMap: [],
    faqList: [],
    recentChanges: [],
    bestRatedFAQ: [],
    selectedFAQ: {},
    selectedFAQGroup: {},
    view: {
        component: '',
        type: '',
        data: {}
    }
};

// getters
const getters = {
    getSelectedFaqGroup(state) {
        return state.selectedFAQGroup;
    },
    getFaqMap(state) {
        return state.faqMap;
    },
    getFaqGroups(state) {
        //Object.values(state.faqMap)
        //console.log(Object.keys(state.faqMap))
        return Object.keys(state.faqMap);
    },
    getFaqGroup(state) {
        return state.faqMap.group;
    },
    getSelectedFaq(state) {
        return state.selectedFAQ;
    },
    getFaqList(state) {
        return state.faqList;
    },
    getView(state) {
        return state.view;
    },
    getRecentChanges(state) {
        return state.recentChanges;
    },
    getBestRatedFAQ(state) {
        return state.bestRatedFAQ;
    }
};

// actions
const actions = {
    ADD_FAQ({ commit, dispatch }, request) {
        return axios
            .post('/faq', request)
            .then((response) => response.data.data)
            .then((response) => {
                Promise.all([dispatch('GET_FAQS'), dispatch('GET_FAQ_LIST').then(() => commit('updateSelectedFaq', response))]);
                return response;
            });
    },
    GET_FAQS({ commit }) {
        return axios
            .get('/faq')
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateFaqMap', response);
                return response;
            });
    },
    GET_FAQ_LIST({ commit }) {
        return axios
            .get('/faq/list')
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateFaqList', response);
                return response;
            });
    },
    GET_RECENT_CHANGES({ commit }) {
        return axios
            .get('/faq/recent')
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateRecentChanges', response);
                return response;
            });
    },
    GET_BEST_RATED_FAQS({ commit }) {
        return axios
            .get('/faq/rating')
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateRating', response);
                return response;
            });
    },
    GET_SINGLE_FAQ({ commit }, { id: faqId }) {
        return axios
            .get(`/faq/${faqId}`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateSelectedFAQ', response);
                commit('updateSelectedFaqGroup', response.faqGroupName);
                return response;
            });
    },
    UPDATE_FAQ({ commit, dispatch }, { id: faqId, ...request }) {
        return axios
            .patch(`/faq/${faqId}`, request)
            .then((response) => response.data.data)
            .then((response) => {
                dispatch('GET_FAQS');
                dispatch('GET_FAQ_LIST').then(() => commit('updateSelectedFAQ', response));
                return response;
            });
    },
    DELETE_FAQ({ commit, dispatch }, { id: faqId }) {
        return axios.delete(`/faq/${faqId}`).then(() => {
            commit('updateSelectedFAQ', {});
            dispatch('GET_FAQS');
            dispatch('GET_FAQ_LIST');
            dispatch('GET_RECENT_CHANGES');
            dispatch('GET_BEST_RATED_FAQS');
        });
    },
    RATE_FAQ({ commit, dispatch }, { id: faqId, isLike }) {
        return axios
            .patch(`/faq/${faqId}/rate?isLike=${isLike}`)
            .then((response) => response.data.data)
            .then((response) => {
                dispatch('GET_FAQS');
                commit('updateSelectedFAQ', response);
                return response;
            });
    },
    CHANGE_FAQ_STATE({ commit }, { id: faqId, state }) {
        return axios
            .patch(`/faq/${faqId}/changestate`, state)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateSelectedFAQ', response);
                return response;
            });
    },
    ADD_FAQ_GROUP({ dispatch }, request) {
        return axios
            .post(`/faq/faq-group`, request)
            .then((response) => response.data.data)
            .then((response) => {
                dispatch('GET_FAQS');
                dispatch('GET_FAQ_LIST');
                return response;
            });
    },
    UPDATE_FAQ_GROUP({ dispatch }, { id: faqGroupId, ...request }) {
        return axios
            .patch(`/faq/faq-group/${faqGroupId}`, request.request)
            .then((response) => response.data.data)
            .then((response) => {
                dispatch('GET_FAQS');
                dispatch('GET_FAQ_LIST');
                return response;
            });
    },
    DELETE_FAQ_GROUP({ dispatch }, { id: faqGroupId }) {
        return axios
            .delete(`/faq/faq-group/${faqGroupId}`)
            .then((response) => response.data.data)
            .then((response) => {
                dispatch('GET_FAQS');
                dispatch('GET_FAQ_LIST');
                return response;
            });
    },
    REQUEST_FAQ({ dispatch }, request) {
        return axios.post(`/faq/proposal`, request).then((response) => response.data.data);
    },
    SET_VIEW({ commit }, view) {
        commit('updateView', view);
    },
    SET_SELECTED_FAQ_GROUP({ commit }, group) {
        commit('updateSelectedFaqGroup', group);
    }
};

// mutations
const mutations = {
    clear: (state) => {
        state.faqMap = [];
        state.selectedFAQ = {};
        state.faqList = [];
        state.selectedFAQGroup = {};
    },
    updateFaqMap: (state, data) => {
        state.faqMap = data;
    },
    updateSelectedFAQ: (state, data) => {
        state.selectedFAQ = data;
    },
    updateFaqList: (state, data) => {
        state.faqList = data;
    },
    updateView: (state, view) => {
        Vue.set(state, 'view', view);
    },
    updateRecentChanges: (state, data) => {
        state.recentChanges = data;
    },
    updateRating: (state, data) => {
        state.bestRatedFAQ = data;
    },
    updateSelectedFaqGroup: (state, data) => {
        state.selectedFAQGroup = data;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
