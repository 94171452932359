import i18n from '@/plugins/I18n/i18n';

const state = {
    notificationMessage: '',
    notificationTimeout: 3750,
    notificationSwitch: false
};
const getters = {
    getNotificationMessages() {
        return state.notificationMessage;
    },
    getNotificationTimeout() {
        return state.notificationTimeout;
    },
    getNotificationSwitch() {
        return state.notificationSwitch;
    }
};
const actions = {
    SET_NOTIFICATION_MESSAGE({ commit }, message) {
        commit('setNotificationSwitch', true);
        commit('setNotificationMessage', message);
    },
    SET_NOTIFICATION_SWITCH({ commit }, bool) {
        commit('setNotificationSwitch', bool);
    }
};
const mutations = {
    clear: (state) => {},
    setNotificationSwitch: (state, bool) => {
        state.notificationSwitch = bool;
    },
    setNotificationMessage: (state, message) => {
        state.notificationMessage = i18n.t(message);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
