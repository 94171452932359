<template>
    <v-card>
        <v-toolbar text dark color="secondary">
            <v-toolbar-title>{{ $t('Wissenseinheiten Ranking') }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text ref="test">
            <KnowledgeUnitList title="Wissenseinheiten" :items="knowledgeUnits" :showAppend="false" :showAction="false" :showOptions="false">
                <template v-slot:content="item">
                    <v-list-item-title>{{ item.content.knowledgeUnit.question }}</v-list-item-title>
                    <v-list-item-subtitle>
                        {{ `${$t('Richtig:')} ${correctProgress(item.content)}% (${item.content.vote.correct})` }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                        {{ `${$t('Falsch:')} ${incorrectProgress(item.content)}% (${item.content.vote.incorrect})` }}
                    </v-list-item-subtitle>
                </template>
            </KnowledgeUnitList>
        </v-card-text>
    </v-card>
</template>

<script>
import DashboardMixin from './Mixins/DashboardMixin';
import KnowledgeUnitList from '../Generic/List/AdvancedList';

export default {
    name: 'knowledge-unit-ranking',
    mixins: [DashboardMixin],
    components: { KnowledgeUnitList },
    data: () => ({
        knowledgeUnits: [],
        chartType: 'BarChart',
        chartOptions: {}
    }),
    methods: {
        incorrectProgress(item) {
            let prog = (item.vote.incorrect / item.vote.total) * 100;
            return prog !== prog ? 0 : prog.toFixed(2);
        },
        correctProgress(item) {
            let prog = (item.vote.correct / item.vote.total) * 100;
            return prog !== prog ? 0 : prog.toFixed(2);
        }
    },
    mounted() {
        this.GET_KNOWLEDGE_UNIT_RANKING().then((response) => (this.knowledgeUnits = response));
    }
};
</script>
