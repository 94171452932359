<template>
    <v-card flat style="overflow: auto">
        <EditWWCRole :id="editWWCRoleId" v-model="showEditWWCRole" />
        <template v-if="isCustomerSelected()">
            <v-tabs v-model="tab" show-arrows>
                <v-tab key="DETAILS">{{ $t('Customer-Details') }}</v-tab>
                <v-tab key="SUPERADMIN">{{ $t('Super Admin') }}</v-tab>
                <v-tab key="ADMINS">{{ $t('Admins') }}</v-tab>
            </v-tabs>
        </template>
        <v-card-text>
            <template v-if="!isCustomerSelected()">
                <v-toolbar flat dense text color="transparent">
                    <v-spacer />
                    <v-toolbar-items>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" medium icon color="success" @click="addCustomer()">
                                    <v-icon medium>mdi-account-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ getCreateText() }}</span>
                        </v-tooltip>
                    </v-toolbar-items>
                </v-toolbar>
            </template>
            <template v-else>
                <v-toolbar flat dense text color="transparent">
                    <v-toolbar-items>
                        <v-btn icon @click="close()" v-if="showCloseButton">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                    <v-spacer />
                    <v-toolbar-items>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" medium icon color="success" @click="addItem()" v-show="showAddButton()">
                                    <v-icon medium>mdi-account-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ getCreateText() }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" medium icon color="warning" @click="updateCustomer()" v-show="showUpdateButton()">
                                    <v-icon medium>mdi-account-edit</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ getUpdateText() }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" medium icon color="error" @click="deleteCustomer()" v-show="showDeleteButton()">
                                    <v-icon medium>mdi-account-remove</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ getDeleteText() }}</span>
                        </v-tooltip>
                    </v-toolbar-items>
                </v-toolbar>
            </template>
            <v-divider />
            <v-container>
                <v-tabs-items v-model="tab">
                    <v-tab-item key="DETAILS">
                        <template v-if="isCustomerSelected()">
                            <v-row wrap>
                                <v-col :cols="12">
                                    <v-text-field
                                        :color="isCustomerActive ? 'success' : 'error'"
                                        :prepend-inner-icon="isCustomerActive ? 'mdi-check' : 'mdi-close'"
                                        :label="$t('Aktiv')"
                                        hide-details
                                        :value="isCustomerActive ? $t('Ja') : $t('Nein')"
                                        readonly
                                    />
                                </v-col>
                                <v-col :cols="12">
                                    <v-text-field
                                        :color="'primary'"
                                        :prepend-inner-icon="'mdi-office-building'"
                                        :label="$t('Name')"
                                        hide-details
                                        :value="getSelectedCustomer.customer.companyName"
                                        readonly
                                    />
                                </v-col>
                                <v-col :cols="12">
                                    <v-text-field
                                        :color="'primary'"
                                        :prepend-inner-icon="'mdi-web'"
                                        :label="$t('Domain')"
                                        hide-details
                                        :value="getSelectedCustomer.settings.domainName"
                                        readonly
                                    />
                                </v-col>
                                <v-col :cols="12">
                                    <v-text-field
                                        :color="'primary'"
                                        :prepend-inner-icon="'mdi-account'"
                                        :label="$t('Nutzerlizenzen')"
                                        hide-details
                                        :value="getSelectedCustomer.customer.maxLicense == 0 ? $t('Unbegrenzt') : getSelectedCustomer.customer.maxLicense"
                                        readonly
                                    />
                                </v-col>
                                <v-col :cols="12">
                                    <v-text-field
                                        :color="'primary'"
                                        :prepend-inner-icon="'mdi-notebook-outline'"
                                        :label="$t('Kurslizenzen')"
                                        hide-details
                                        :value="getSelectedCustomer.customer.maxCourses == 0 ? $t('Unbegrenzt') : getSelectedCustomer.customer.maxCourses"
                                        readonly
                                    />
                                </v-col>
                                <v-col :cols="12">
                                    <v-text-field
                                        :color="'primary'"
                                        :prepend-inner-icon="'mdi-memory'"
                                        :label="$t('Maximaler Speicherplatz')"
                                        hide-details
                                        :value="
                                            getSelectedCustomer.customer.maxFileStorage == 0 ? $t('Unbegrenzt') : getSelectedCustomer.customer.maxFileStorage
                                        "
                                        readonly
                                    />
                                </v-col>
                            </v-row>
                        </template>
                        <template v-else>
                            {{ 'Wählen Sie einen Kunden aus' }}
                        </template>
                    </v-tab-item>
                    <v-tab-item key="SUPERADMIN">
                        <template v-if="customerHasSuperAdmin">
                            <v-row>
                                <v-col :cols="12">
                                    <v-text-field
                                        :color="isSuperAdminActive ? 'success' : 'error'"
                                        :prepend-inner-icon="isSuperAdminActive ? 'mdi-check' : 'mdi-close'"
                                        :label="$t('Aktiv')"
                                        hide-details
                                        :value="isSuperAdminActive ? $t('Ja') : $t('Nein')"
                                        readonly
                                    />
                                </v-col>
                                <v-col :cols="12">
                                    <v-text-field
                                        :color="getSelectedCustomer.superAdmin.isOnline ? 'success' : 'error'"
                                        :prepend-inner-icon="getSelectedCustomer.superAdmin.isOnline ? 'mdi-check' : 'mdi-clock-outline'"
                                        :label="getSelectedCustomer.superAdmin.isOnline ? $t('Online') : $t('Zuletzt online')"
                                        hide-details
                                        :value="offlineAtText"
                                        readonly
                                    />
                                </v-col>
                                <v-col :cols="12">
                                    <v-text-field
                                        :color="'primary'"
                                        :prepend-inner-icon="'mdi-account'"
                                        :label="$t('Vorname')"
                                        hide-details
                                        :value="getSelectedCustomer.superAdmin.firstName"
                                        readonly
                                    />
                                </v-col>
                                <v-col :cols="12">
                                    <v-text-field
                                        :color="'primary'"
                                        :prepend-inner-icon="'mdi-account'"
                                        :label="$t('Nachname')"
                                        hide-details
                                        :value="getSelectedCustomer.superAdmin.lastName"
                                        readonly
                                    />
                                </v-col>
                                <v-col :cols="12">
                                    <v-text-field
                                        :color="'primary'"
                                        :prepend-inner-icon="'mdi-email'"
                                        :label="$t('Email')"
                                        hide-details
                                        :value="getSelectedCustomer.superAdmin.email"
                                        readonly
                                    />
                                </v-col>
                                <v-col :cols="11">
                                    <v-text-field
                                        :color="'primary'"
                                        :prepend-inner-icon="'mdi-video'"
                                        :label="$t('WWC-Rolle')"
                                        hide-details
                                        :value="getSelectedCustomer.superAdmin.wwc.role"
                                        readonly
                                    />
                                </v-col>
                                <v-col :cols="1">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" medium icon color="warning" @click.stop @click="editWWCRole(getSelectedCustomer.superAdmin.id)">
                                                <v-icon medium>mdi-pencil</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('WWC Rolle ändern') }}</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-else>
                            {{ 'Noch kein Super Admin erstellt' }}
                        </template>
                    </v-tab-item>
                    <v-tab-item key="ADMINS">
                        <template v-if="getSelectedCustomer.admins != null">
                            <v-expand-transition mode="out-in">
                                <keep-alive>
                                    <component
                                        :is="componentSwitch"
                                        v-model="computedSelectedAdmin"
                                        @getAdminInfo="getAdminInfo"
                                        @editWWCRole="editWWCRole"
                                        :tabValue="tabValue"
                                    />
                                </keep-alive>
                            </v-expand-transition>
                        </template>
                        <template v-else>
                            {{ 'Noch keine Admins erstellt' }}
                        </template>
                    </v-tab-item>
                </v-tabs-items>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
import CustomerManagerMixin from '../Mixins/CustomerManagerMixin';
import EditWWCRole from './EditWWCRole';
import AdminList from './AdminList';
import AdminInfo from './AdminInfo';

export default {
    name: 'selected-customer-info',
    components: { EditWWCRole, AdminList, AdminInfo },
    mixins: [CustomerManagerMixin],
    props: {
        tabValue: {
            type: String,
            default: 'WL2'
        }
    },
    data: () => ({
        tab: 0,
        showEditWWCRole: false,
        editWWCRoleId: ''
    }),
    computed: {
        isSuperAdminActive() {
            return this.getSelectedCustomer.superAdmin && this.getSelectedCustomer.superAdmin.active;
        },
        isCustomerActive() {
            return this.isCustomerSelected() && this.getSelectedCustomer.customer.active;
        },
        customerHasSuperAdmin() {
            return this.getSelectedCustomer.superAdmin;
        },
        componentSwitch() {
            if (this.computedSelectedAdmin.hasOwnProperty('email')) {
                return AdminInfo;
            }
            return AdminList;
        },
        showCloseButton() {
            return this.computedSelectedAdmin.hasOwnProperty('email') && this.tab == 2;
        },
        computedSelectedAdmin: {
            get() {
                return this.getSelectedAdmin;
            },
            set(value) {
                this.SET_SELECTED_ADMIN(value);
            }
        },
        offlineAtText() {
            if (!this.getSelectedCustomer && !this.getSelectedCustomer.superAdmin) {
                return this.$t('');
            }
            if (this.getSelectedCustomer.superAdmin.isOnline) {
                return this.$t('Ja');
            }
            if (this.getSelectedCustomer.superAdmin.offlineAt == '') {
                return this.$t('Noch nicht online gewesen');
            }
            return this.getSelectedCustomer.superAdmin.offlineAt;
        }
    },
    methods: {
        showAddButton() {
            return true;
        },
        showUpdateButton() {
            return this.tab < 2;
        },
        showDeleteButton() {
            return this.tab < 1;
        },
        getAdminInfo(admin) {
            this.computedSelectedAdmin = admin;
        },
        isCustomerSelected() {
            return this.getSelectedCustomer && this.getSelectedCustomer.hasOwnProperty('customer');
        },
        getCreateText() {
            switch (this.tab) {
                case 0: {
                    switch (this.tabValue) {
                        case 'WL2':
                            return this.$t('Wiselab2 Kunden erstellen');
                        case 'WWC':
                            return this.$t('WWC Kunden ertellen');
                        default:
                            return this.$t('Wiselab2 Kunden erstellen');
                    }
                }
                case 1:
                    return this.$t('Super Admin erstellen');
                case 2:
                default:
                    return this.$t('Admin erstellen');
            }
        },
        getUpdateText() {
            switch (this.tab) {
                case 0: {
                    switch (this.tabValue) {
                        case 'WL2':
                            return this.$t('Wiselab2 Kunden bearbeiten');
                        case 'WWC':
                            return this.$t('WWC Kunden bearbeiten');
                        default:
                            return this.$t('Wiselab2 Kunden bearbeiten');
                    }
                }
                case 1:
                    return this.$t('Super Admin updaten');
                default:
                    return this.$t('');
            }
        },
        getDeleteText() {
            switch (this.tab) {
                case 0: {
                    switch (this.tabValue) {
                        case 'WL2':
                            return this.$t('Wiselab2 Kunden löschen');
                        case 'WWC':
                            return this.$t('WWC Kunden löschen');
                        default:
                            return this.$t('Wiselab2 Kunden löschen');
                    }
                }
                default:
                    return this.$t('');
            }
        },
        editWWCRole(id) {
            this.showEditWWCRole = true;
            this.editWWCRoleId = id;
        },
        close() {
            this.computedSelectedAdmin = {};
        },
        addItem() {
            if (this.tab < 2) {
                this.addCustomer();
            } else {
                this.addAdmin();
            }
        },
        addCustomer() {
            this.SET_VIEW({ component: 'ADD_UPDATE_CUSTOMER', type: 'ADD', data: {}, tab: this.tabValue });
        },
        updateCustomer() {
            this.SET_VIEW({ component: 'ADD_UPDATE_CUSTOMER', type: 'UPDATE', data: this.getSelectedCustomer, tab: this.tabValue });
        },
        deleteCustomer() {
            this.SET_VIEW({ component: 'DELETE_CUSTOMER', type: 'DELETE', data: this.getSelectedCustomer, tab: this.tabValue });
        },
        addAdmin() {
            this.SET_VIEW({ component: 'ADD_ADMIN', type: 'ADD', data: this.getSelectedCustomer, tab: this.tabValue });
        }
    }
};
</script>
