import axios from '../../plugins/Axios/axios';

const state = {
    mailOptions: {}
};

const getters = {
    getOptData(state) {
        return state.mailOptions;
    }
};

const actions = {
    GET_USER_MAIL_OPTIONS({ commit }) {
        return axios
            .get(`/mail/opt/user`)
            .then((response) => response.data.data)
            .then((response) => commit('updateUserMailOpts', response));
    },
    SET_USER_MAIL_OPTIONS(request) {
        return axios.post(`/mail/opt/user`, request).then((response) => response.data.data);
    }
};

const mutations = {
    clear: (state) => (state.mailOptions = {}),
    updateUserMailOpts: (state, mailOptions) => (state.mailOptions = mailOptions)
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
