import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters('NewsOperations', ['getSelectedNews', 'getVisibilityOptions', 'getNews', 'getPublishedNews'])
    },
    methods: {
        ...mapActions('NewsOperations', [
            'CREATE_NEWS',
            'UPDATE_NEWS',
            'DELETE_NEWS',
            'VISIBILITY_OPTIONS',
            'GET_NEWS',
            'GET_PUBLISHED_NEWS',
            'SET_SELECTED_NEWS'
        ]),
        isNewsObject(obj) {
            return (
                obj.hasOwnProperty('name') &&
                obj.hasOwnProperty('title') &&
                obj.hasOwnProperty('content') &&
                obj.hasOwnProperty('publishedDate') &&
                obj.hasOwnProperty('isPublished') &&
                obj.hasOwnProperty('options') &&
                obj.hasOwnProperty('visibility')
            );
        }
    }
};
