import { mapActions, mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('PlantoolOperations', ['getTasks', 'getTaskMap'])
    },
    methods: {
        ...mapActions('PlantoolOperations', [
            'ADD_TASK',
            'GET_ACTIVE_TASKS',
            'GET_TASK_KNOWLEDGE_UNITS',
            'GET_TASK_OWNERS',
            'GET_TASK_USERS',
            'GET_INACTIVE_TASKS',
            'GET_TASK',
            'DELETE_TASK',
            'UPDATE_TASK',
            'GET_LINK',
            'LOAD_ALL_TASK_OWNERS'
        ])
    }
};
