<template>
    <v-card-text>
        <v-container>
            <v-row align="center" justify="center">
                <v-col>
                    <v-slider always-dirty :min="minimum" :max="maximum" :step="steps" v-model="computedAnswer" :label="computedAnswer" hide-details>
                        <template v-slot:label>
                            <v-layout align-center justify-center fill-height>
                                <v-flex>
                                    <span :class="responsiveFont">{{ computedAnswer }}</span>
                                </v-flex>
                            </v-layout>
                        </template>
                    </v-slider>
                </v-col>
                <v-col cols="auto">
                    <v-btn color="error" icon @click="handleSubtract">
                        <v-icon>mdi-minus</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn color="success" icon @click="handleAdd">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-card-text>
</template>

<script>
export default {
    name: 'picker-option',
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        responsiveFont() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return { 'font-weight-light': true };
                case 'sm':
                    return { 'font-weight-light': true, 'body-2': true };
                case 'md':
                    return { 'font-weight-light': true, subheading: true };
                case 'lg':
                    return { 'font-weight-light': true, title: true };
                case 'xl':
                    return { 'font-weight-light': true, headline: true };
            }
            return '';
        },
        computedAnswer: {
            get() {
                return this.optionsComputed && this.optionsComputed.answer ? this.optionsComputed.answer.toString() : this.minimum.toString();
            },
            set(value) {
                this.$emit('input', [{ ...this.optionsComputed, answer: value }]);
            }
        },
        optionsComputed() {
            return this.value && this.value.knowledgeUnitOptions ? this.value.knowledgeUnitOptions[0] : {};
        },
        minimum() {
            return this.optionsComputed && this.optionsComputed.minimum ? parseInt(this.optionsComputed.minimum) : 0;
        },
        maximum() {
            return this.optionsComputed && this.optionsComputed.maximum ? parseInt(this.optionsComputed.maximum) : 0;
        },
        steps() {
            return this.optionsComputed && this.optionsComputed.steps ? parseInt(this.optionsComputed.steps) : 0;
        }
    },
    methods: {
        handleSubtract() {
            if (parseInt(this.computedAnswer) - this.steps >= this.minimum) {
                this.computedAnswer = parseInt(this.computedAnswer) - this.steps;
            }
        },
        handleAdd() {
            if (parseInt(this.computedAnswer) + this.steps <= this.maximum) {
                this.computedAnswer = parseInt(this.computedAnswer) + this.steps;
            }
        }
    },
    activated() {
        this.$nextTick(() => (this.computedAnswer = this.minimum));
    },
    mounted() {
        this.$nextTick(() => (this.computedAnswer = this.minimum));
    }
};
</script>
