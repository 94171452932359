<template>
    <v-card text>
        <v-toolbar dark dense text color="secondary">
            <v-toolbar-title class="text-body-2">{{ $t('Löschen bestätigen') }}</v-toolbar-title>
        </v-toolbar>
        <v-container grid-list-md>
            <v-layout wrap>
                <v-flex xs12>
                    <span>{{ computedText }}</span>
                    <span class="px-2">:</span>
                    <span class="primary--text">{{ value.name || value.displayName }}</span>
                </v-flex>
                <v-flex xs12>
                    <v-checkbox
                        v-model="confirmDelete"
                        :label="$t('Bestätigen')"
                        :hint="$t('Wenn Sie bereits gelöschte Daten wieder herstellen möchten, wenden Sie sich bitte an den Support.')"
                        persistent-hint
                    />
                </v-flex>
            </v-layout>
        </v-container>
        <v-card-actions>
            <v-btn block color="error" :disabled="!confirmDelete" @click="submit">
                {{ $t('Löschen') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import UsersMixin from '../../Mixins/UserMixin';

export default {
    name: 'users-delete-confirm',
    mixins: [UsersMixin],
    data: () => ({
        confirmDelete: false
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        onSubmit: {
            type: Function,
            default: () => ({})
        }
    },
    computed: {
        computedText() {
            const type = this.isUserOrGroup(this.value);
            switch (type) {
                case 'USER':
                    return this.$t('Sind Sie sicher, dass Sie den folgenden Nutzer löschen wollen');
                case 'GROUP':
                    return this.$t('Sind Sie sicher, dass Sie die folgende Gruppe löschen wollen');
            }
            return '';
        }
    },
    methods: {
        submit() {
            this.onSubmit()(this.value);
        }
    },
    activated() {
        this.confirmDelete = false;
    }
};
</script>
