<template>
    <v-dialog v-model="dialog" scrollable persistent :width="$vuetify.breakpoint.thresholds.xs" :fullscreen="$vuetify.breakpoint.xs">
        <template #activator="{ on, attrs }">
            <v-btn xs12 sm4 block v-bind="attrs" v-on="on">{{ $t('Desktop') }}</v-btn>
        </template>
        <v-card>
            <v-toolbar dense flat color="primary" dark>
                <v-toolbar-title>{{ $t('PWA installieren: Desktop') }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon text @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-subheader>
                    1.
                    {{ $t('Öffnen Sie diese Website mit dem «Chrome» Browser und drücken Sie in der URL-Leiste rechts auf den «Plus» Knopf.') }}
                </v-subheader>
                <v-container fluid grid-list-sm>
                    <v-layout wrap>
                        <v-flex>
                            <img :src="require('@/assets/Install/installDesktop.png')" class="image" alt="lorem" width="100%" height="100%" />
                        </v-flex>
                    </v-layout>
                </v-container>
                <v-subheader>2. {{ $t('Drücken Sie auf «Installieren».') }}</v-subheader>
                <v-container fluid grid-list-sm>
                    <v-layout wrap>
                        <v-flex>
                            <img :src="require('@/assets/Install/installDesktop1.png')" class="image" alt="lorem" width="100%" height="100%" />
                        </v-flex>
                    </v-layout>
                </v-container>
                <v-subheader>3. {{ $t('Wiselab ist zu Ihrem Desktop als App hinzugefügt.') }}</v-subheader>
                <v-container fluid grid-list-sm>
                    <v-layout wrap>
                        <v-flex>
                            <img :src="require('@/assets/Install/installDesktop2.png')" class="image" alt="lorem" width="100%" height="100%" />
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'instuctions-desktop',
    data: () => ({
        dialog: false
    })
};
</script>
