<template>
    <v-card flat>
        <ForumBreadcrumbs />
        <v-data-table
            :headers="threadHeaders"
            :items="getThreads"
            :items-per-page="5"
            :no-data-text="$t('Keine Themen')"
            :no-results-text="$t('Kein Ergebnis')"
            :footer-props="{
                'items-per-page-text': $t('Themen pro Seite'),
                'items-per-page-options': [5, 10, 15, { value: -1, text: $t('Alle') }]
            }"
            :options.sync="pagination"
            :custom-sort="sortThreads"
            :search="search"
            :custom-filter="filter"
        >
            <template #top>
                <v-container>
                    <v-row align="center" justify="center">
                        <v-col>
                            <v-text-field prepend-icon="mdi-magnify" :label="$t('Suchen')" v-model="search" />
                        </v-col>
                        <v-col cols="auto">
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn icon color="success" v-on="on" v-bind="attrs" @click="create" :disabled="!permissions.add">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('Forum erstellen') }}</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template #item.title="{ item }">
                <v-container>
                    <v-row :cols="12" no-gutters justify="center">
                        <v-col :cols="12">
                            <span @click="onClick(item)" class="font-weight-bold text-h6 pointer primary--text">
                                {{ item.title }}
                            </span>
                        </v-col>
                        <v-col :cols="12">
                            <div class="clamp-text" v-html="item.text"></div>
                        </v-col>
                        <v-col :cols="12">
                            <span>{{ `${$t('Erstellt von')}: ` }}</span>
                            <span class="primary--text">{{ `${item.lastUpdatedBy.displayName}` }}</span>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col cols="auto" v-if="!item.canAnswer">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" :small="$vuetify.breakpoint.xs">mdi-lock</v-icon>
                                </template>
                                <span>{{ $t('Dieses Thema ist geschlossen') }}</span>
                            </v-tooltip>
                        </v-col>
                        <v-col cols="auto" v-if="item.sticky">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" :small="$vuetify.breakpoint.xs">mdi-pin</v-icon>
                                </template>
                                <span>{{ $t('Dieses Thema wird priorisiert') }}</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template #item.threadCount="{ item: { answerCount } }">
                <span>{{ answerCount }}</span>
            </template>
            <template #item.latestAnswer="{ item }">
                <UserProfile :value="item.latestAnswer" small />
            </template>
            <template #item.lastUpdated="{ item }">
                <span>{{ formatDate(item.lastUpdated) }}</span>
            </template>
            <template #item.menu="{ item }">
                <v-menu offset-x left>
                    <template #activator="{ on, attrs }">
                        <v-btn icon v-on="on" v-bind="attrs">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list dense>
                        <v-list-item :disabled="!permissions.update" @click="edit(item)">
                            <v-list-item-content>
                                <v-list-item-title>{{ $t('Bearbeiten') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item :disabled="!permissions.delete" @click="remove(item)">
                            <v-list-item-content>
                                <v-list-item-title>{{ $t('Löschen') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import GroupForumMixin from './Mixins/GroupForumMixin';
import ForumHeaders from './TableHeaders/ThreadTableHeaders';
import UserProfile from '../Generic/Profile/UserProfile';

import ForumBreadcrumbs from './Helpers/ForumBreadcrumbs';
import moment from 'moment';

export default {
    name: 'group-forum-thread-list',
    components: { ForumBreadcrumbs, UserProfile },
    mixins: [GroupForumMixin],
    data: () => ({
        threadHeaders: ForumHeaders,
        selectedForum: {},
        pagination: {},
        search: ''
    }),
    props: {
        permissions: {
            type: Object,
            default: () => ({
                add: false,
                update: false,
                delete: false,
                move: false,
                copy: false,
                details: false,
                groups: false
            })
        }
    },
    methods: {
        create(item) {
            this.$emit('create', item);
        },
        edit(item) {
            this.setThread(item);
            this.$emit('update', item);
        },
        remove(item) {
            this.setThread(item);
            this.$emit('delete', item);
        },
        filter(value, search, item) {
            if (search.trim() === '') {
                return true;
            }
            let buildString = [...new Set(search.split(' '))].filter((word) => word !== '').map((word) => word.toLowerCase());
            const text = Object.values(item)
                .filter((data) => typeof data === 'string' || typeof data === 'number')
                .map((data) => {
                    if (typeof data === 'number') {
                        return data.toString();
                    }
                    return data;
                })
                .join(' ')
                .toLowerCase();
            return buildString.every((word) => text.includes(word));
        },
        sortThreads(items) {
            items.sort((a, b) => !a.sticky - !b.sticky || moment(b.lastUpdated).format('YYYYMMDDHHmmss') - moment(a.lastUpdated).format('YYYYMMDDHHmmss'));
            return items;
        },
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD HH:mm');
        },
        setThread(thread) {
            this.SET_SELECTED_THREAD(thread);
            this.$emit('input', thread);
        },
        onClick(thread) {
            this.ADD_FORUM_PATH({
                ...thread,
                href: { name: 'groupForumThreads' }
            });
            this.SET_SELECTED_THREAD(thread);
            this.$router.push({ name: 'groupForumAnswers', query: { allowReply: thread.canAnswer } });
        },
        isSelected(thread) {
            if (this.getSelectedThread.hasOwnProperty('id') && thread.hasOwnProperty('id')) {
                return thread.id === this.getSelectedThread.id;
            }
            return false;
        }
    },
    mounted() {
        this.threadHeaders = this.threadHeaders.map((header) => {
            header.text = this.$t(header.text);
            return header;
        });
        this.GET_THREADS({
            group: this.getSelectedGroup,
            forum: this.getSelectedForum
        });
        this.REMOVE_FORUM_PATH(this.getSelectedThread);
    }
};
</script>

<style scoped>
.clamp-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;
}
</style>
