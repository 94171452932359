<template>
    <v-dialog fullscreen scrollable v-model="dialog" persistent hide-overlay id="ckEditor">
        <template v-slot:activator="{ on }">
            <v-sheet flat>
                <div ref="toolbar"></div>
                <div ref="editor"></div>
            </v-sheet>
        </template>
        <v-card>
            <v-toolbar dense dark text color="primary">
                <v-toolbar-title>{{ $t('Dateimanager') }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="closeEmit(false)">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <template v-if="getLoggedInAdmin.role == 'ROOT' || getLoggedInAdmin.role == 'SALES'">
                <StaticFilemanager selectable @selectedMedia="emitContent" />
            </template>
            <template v-else>
                <Filemanager selectable @selectedMedia="emitContent" />
            </template>
        </v-card>
    </v-dialog>
</template>

<script>
import Config from '../../plugins/CKEditor/configuration/BasicConfig';

import Filemanager from '../FileManager/Prototype/Filemanager';
import StaticFilemanager from '../StaticFileManager/Prototype/Filemanager';

import DecoupledEditor from '@wiselab/ckeditor/build/ckeditor.js';

import { mapGetters } from 'vuex';

export default {
    name: 'text-editor',
    components: { Filemanager, StaticFilemanager },
    data: () => ({
        dialog: false,
        editor: {},
        showAdd: false,
        showUpdate: false,
        showConfirmDelete: false,
        path: []
    }),
    props: {
        loadFilemanager: {
            type: Boolean,
            default: true
        },
        value: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters('LanguageOperations', ['getSelectedLanguage']),
        isDark() {
            return this.$vuetify.theme.dark;
        }
    },
    watch: {
        value(val) {
            if (this.editor instanceof DecoupledEditor) {
                this.editor.getData() !== val && this.editor.setData(val);
            }
        },
        disabled(val) {
            this.editor.isReadOnly = val;
        },
        getSelectedLanguage() {
            this.createEditor();
        }
    },
    methods: {
        closeEmit(val) {
            this.dialog = val;
        },
        emitContent(content) {
            this.dialog = false;
            this.editor.fire('filemanager-file-selected', content);
        },
        handleChanges() {
            this.$emit('input', this.editor.getData());
        },
        getLanguage() {
            if (this.getSelectedLanguage === undefined || this.getSelectedLanguage === null) {
                return 'de';
            }
            if (this.getSelectedLanguage === 'us') {
                return 'en';
            }
            return this.getSelectedLanguage;
        },
        configure() {
            if (!this.loadFilemanager) {
                const config = JSON.parse(JSON.stringify(Config));
                config.toolbar.items = config.toolbar.items.filter((plugin) => plugin !== 'filemanager');
                return {
                    language: this.getLanguage(),
                    removePlugins: ['filemanager'],
                    ...config
                };
            }
            return {
                language: this.getLanguage(),
                ...Config
            };
        },
        createEditor() {
            this.$nextTick(() => {
                if (this.editor instanceof DecoupledEditor) {
                    this.editor.destroy();
                }
                //Hier noch Schriftgröße
                DecoupledEditor.create(this.value, this.configure())
                    .then((editor) => {
                        this.$refs.toolbar.appendChild(editor.ui.view.toolbar.element);
                        this.$refs.editor.appendChild(editor.ui.view.editable.element);
                        this.editor = editor;
                        this.editor.setData(this.value);
                        this.editor.isReadOnly = this.disabled;
                        this.editor.model.document.on('change:data', this.handleChanges);
                        this.editor.on('filemanager-open', () => {
                            this.dialog = !this.dialog;
                        });
                        this.checkIsDarkMode();
                    })
                    .catch((error) => console.error(error));
            });
        },
        checkIsDarkMode() {
            if (this.isDark) {
                this.$nextTick(() => {
                    document.querySelector('.ck-color-ui-dropdown .ck-dropdown__button').click();
                    this.$nextTick(() => {
                        document.querySelector('.ck-color-grid .ck-button').click();
                    });
                });
            }
        }
    },
    mounted() {
        this.createEditor();
        this.blockLatexRendering();
    },
    activated() {
        this.blockLatexRendering();
    },
    deactivated() {
        this.activateLatexRendering();
    },
    destroyed() {
        this.activateLatexRendering();
    },
    beforeDestroy() {
        this.activateLatexRendering();
    }
};
</script>
