<template>
    <List title="Ausgewählte Kataloge" :showAction="false" showOptions :items="value">
        <template v-slot:headerAppend>
            <v-layout align-center justify-end>
                <span>{{ $t('Alle Einstellungen') }}</span>
                <v-btn icon @click="handleGlobalConfig">
                    <v-icon>mdi-wrench</v-icon>
                </v-btn>
            </v-layout>
        </template>
        <template v-slot:append="{ append }">
            <v-menu>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="editCatalogOptions(append)">
                        <v-list-item-action>
                            <v-icon>mdi-wrench</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('Katalog-Einstellungen') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="excludeKnowledgeUnits(append)">
                        <v-list-item-action>
                            <v-icon>mdi-playlist-edit</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('Wissenseinheiten ausschließen') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="removeCatalog(append)">
                        <v-list-item-action>
                            <v-icon>mdi-delete</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('Katalog entfernen') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
    </List>
</template>

<script>
import List from '../../../../Generic/List/AdvancedList';
import CatalogSort from '../../../../Authoringtool/Sort/AuthoringToolSort';

export default {
    name: 'quiz-activity-catalogs',
    components: { List },
    data: () => ({
        menu: false,
        catalogSort: CatalogSort
    }),
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        handleGlobalConfig() {
            this.$emit('globalConfig', {});
        },
        editCatalogOptions(value) {
            this.$emit('editCatalogOptions', value);
        },
        excludeKnowledgeUnits(value) {
            this.$emit('excludeKnowledgeUnits', value);
        },
        removeCatalog(value) {
            this.$emit('removeCatalog', value);
        }
    }
};
</script>
