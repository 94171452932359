<template>
    <v-container fluid>
        <v-item-group mandatory v-model="computedState">
            <v-row justify="space-between" no-gutters>
                <v-col cols="auto" v-for="(state, i) in states" :key="state">
                    <v-item>
                        <v-btn @click="updateStatus(i)" :text="computedState !== state" :color="color(state)" :disabled="!disabled(state)">
                            {{ $t(state) }}
                        </v-btn>
                    </v-item>
                </v-col>
            </v-row>
        </v-item-group>
    </v-container>
</template>

<script>
import WikiMixin from '../Wiki/Mixins/WikiMixin';

export default {
    name: 'state-selector',
    mixins: [WikiMixin],
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        permission: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedState: {
            get() {
                return this.value && this.value.state && this.value.state.stateName ? this.value.state.stateName : '';
            },
            set() {}
        },
        states() {
            return ['APPROVED', 'PENDING', 'DECLINED'];
        }
    },
    methods: {
        disabled(state) {
            return this.permission || this.computedState === state;
        },
        color(state) {
            switch (state) {
                case 'APPROVED':
                    return 'success';
                case 'PENDING':
                    return 'warning';
                case 'DECLINED':
                    return 'error';
            }
            return '';
        },
        updateStatus(index) {
            this.WIKI_CHANGE_STATE({
                wiki: this.value,
                state: { state: this.states[index] }
            }).then(() => (this.value.state.stateName = this.states[index]));
        }
    }
};
</script>
