<template>
    <v-card height="100%">
        <Toolbar :toolbarTitle="$t('Kursmanager - Kurse')" />
        <v-container>
            <v-row align="center">
                <v-col cols="12">
                    <v-tabs show-arrows mandatory v-model="tabs">
                        <v-tab key="MY_COURSES">{{ $t('Meine Kurse') }}</v-tab>
                        <v-tab key="ALL_COURSES">{{ $t('Alle Kurse') }}</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tabs" touchless>
                        <v-tab-item key="MY_COURSES">
                            <MyCourses :isAdmin="true" />
                        </v-tab-item>
                        <v-tab-item key="ALL_COURSES">
                            <AllCourses :isAdmin="true" />
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import CourseMixin from '../../../Components/CourseLearning/CourseManager/Mixins/CourseMixin';
import Toolbar from '../../../Components/Generic/Toolbar';
import MyCourses from '../../../Components/CourseLearning/CourseManager/MyCourses';
import AllCourses from '../../../Components/CourseLearning/CourseManager/AllCourses';

export default {
    name: 'courses',
    mixins: [CourseMixin],
    components: {
        Toolbar,
        MyCourses,
        AllCourses
    },
    data: () => ({
        tabs: 0,
        operationType: 'ADD',
        permissions: {
            add: true,
            update: false,
            delete: false,
            eval: false
        }
    }),
    watch: {
        getSelectedAdminCourse(val) {
            if (Object.keys(val).length > 0) {
                this.isCourseManager().then((result) => {
                    this.permissions = {
                        add: true,
                        update: result,
                        delete: result,
                        eval: result && val.useEvaluation
                    };
                });
            }
        }
    },
    computed: {
        isSelected() {
            return Object.keys(this.getSelectedAdminCourse).length > 0;
        }
    },
    methods: {
        goToEvaluations() {
            this.$router.push({ name: 'evaluations' });
        }
    },
    mounted() {
        this.GET_COURSE_PICTURE();
    }
};
</script>
