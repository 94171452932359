<template>
    <v-card :elevation="3" outlined class="rounded-xl d-flex flex-column" @click="selectScorm()" height="100%">
        <ImageLoader :src="getPhoto()" :alignCenter="false" style="max-height: 237px">
            <v-layout fill-height column>
                <v-flex align-self-end>
                    <v-chip color="amber" class="white--text ma-3"> {{ item.germanCategory }}</v-chip>
                </v-flex>
                <v-flex align-self-start shrink>
                    <v-avatar>
                        <Flag :alpha2="getFlagForLanguage" :size="30" />
                    </v-avatar>
                </v-flex>
            </v-layout>
        </ImageLoader>
        <v-container fluid>
            <v-row>
                <v-col :cols="12">
                    <v-card-title style="word-break: break-word">{{ item.localTitle }} </v-card-title>
                </v-col>
            </v-row>
        </v-container>
        <v-spacer />
        <v-container fluid>
            <v-row>
                <v-col :cols="12" class="text-center" v-if="myCoursePage">
                    <template v-if="item && item.cmi && item.cmi.completion_status">
                        <div>
                            {{ $t('Completion Status') }}: <span class="primary--text">{{ $t(item.cmi.completion_status) }}</span>
                        </div>
                        <div>
                            {{ $t('Success Status') }}: <span class="primary--text">{{ $t(item.cmi.success_status) }}</span>
                        </div>
                        <div>
                            {{ $t('Total Time') }}: <span class="primary--text">{{ item.cmi.total_time }}</span>
                        </div>
                        <div v-if="item.expirationDate">
                            {{ $t('Expiration Date') }}: <span class="primary--text">{{ changeDateFormate(item.expirationDate.slice(0, 10)) }}</span>
                        </div>
                    </template>
                    <span v-if="context != 'SELF_ENROLL' && !(item && item.cmi && item.cmi.completion_status)"
                        >{{ $t('Not Started') }}
                        <div>
                            <div v-if="context === 'START_COURSE' && item.expirationDate">
                                {{ $t('Expiration Date') }}: <span class="primary--text">{{ changeDateFormate(item.expirationDate.slice(0, 10)) }}</span>
                            </div>
                        </div>
                    </span>
                </v-col>
                <v-col :cols="12" class="d-flex justify-center">
                    <v-btn outlined rounded :color="getColor">
                        <template v-if="showState">{{ $t(item.state) }} </template>
                        <template v-else-if="myCoursePage">{{ $t('Launch Course') }}</template>
                        <template v-else>{{ $t('Zum Kurs') }}</template>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>
<script>
import ImageLoader from '../../Generic/ImageLoader';
import ScormMixin from '../Mixins/ScormMixin';
import Flag from '../../Generic/Flags/Flag';

export default {
    name: 'scorm-node',
    components: { ImageLoader, Flag },
    data: () => ({
        picture: ''
    }),
    mixins: [ScormMixin],
    props: {
        item: {
            type: Object,
            default: () => ({})
        },
        context: {
            type: String,
            default: ''
        }
    },
    watch: {
        // item: {
        //     immediate: true,
        //     deep: true
        //     handler() {
        //         this.getPhoto();
        //         if (!this.getDefaultCoursePicture) {
        //             this.GET_COURSE_PICTURE();
        //         }
        //     }
        // }
    },
    computed: {
        myCoursePage() {
            return this.$route.name === 'myCourses';
        },
        getFlagForLanguage() {
            if (this.item && this.item.language) {
                switch (this.item.language.substring(0, 2)) {
                    case 'en':
                        return 'gb';
                    default:
                        return this.item.language.substring(0, 2);
                }
            }
            return '';
        },
        showState() {
            return this.item && this.item.state && (this.item.state == 'APPROVED' || this.item.state == 'PENDING' || this.item.state == 'DECLINED');
        },
        getColor() {
            if (!this.showState) {
                return this.$vuetify.theme.dark ? 'white' : 'black';
            }
            switch (this.item.state) {
                case 'APPROVED':
                    return 'success';
                case 'PENDING':
                    return 'warning';
                case 'DECLINED':
                    return 'error';
                default:
                    return 'primary';
            }
        }
    },
    methods: {
        selectScorm() {
            if (this.showState) {
                return;
            }
            switch (this.context) {
                case 'START_COURSE':
                    this.SET_SELECTED_SCORM(this.item);
                    this.$router.push({ name: 'scormView' });
                    break;
                case 'SHOW_COURSE': {
                    this.SET_SELECTED_SCORM(this.item);
                    let n = this.getView.tabs;
                    this.SET_VIEW({ component: 'scormDisplay', type: this.context, data: this.getSelectedScorm, tabs: n });
                    this.$vuetify.goTo(0);
                    break;
                }
                case 'SELF_ENROLL':
                    this.SET_SELECTED_SCORM(this.item);
                    this.SET_VIEW({ component: 'scormDisplay', type: this.context, data: this.getSelectedScorm, tabs: 0 });
                    this.$vuetify.goTo(0);
                    break;
            }
        },
        getPhoto() {
            if (this.item.scormPackageType === 'OWN') {
                this.picture = this.item.photo;
                return this.item.photo;
            }
            this.GET_MEDIA_BY_ID(this.item.scormPackageId ? this.item.scormPackageId : this.item.id)
                .then((blob) => {
                    const filereader = new FileReader();
                    filereader.onload = () => {
                        this.picture = filereader.result;
                    };
                    filereader.readAsDataURL(blob);
                })
                .catch(() => {
                    this.picture = this.getDefaultCoursePicture;
                });
            return this.picture;
        },
        changeDateFormate(date) {
            let newDate = date.slice(8, 10) + '.' + date.slice(5, 7) + '.' + date.slice(0, 4);
            return newDate;
        }
    },
    beforeMount() {
        // this.getPhoto();
    }
};
</script>
