<template>
    <div>
        <Toolbar :toolbarTitle="$t('Nachricht Löschen')" :showDrawer="false" :lastPath="lastPath" />
        <v-card>
            <v-card-text>
                Sind Sie sicher, dass Sie
                <span class="font-weight-medium">"{{ getSelectedNews.name }}"</span>
                löschen wollen?
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="deleteNews">{{ $t('Löschen') }}</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import Toolbar from '../../Components/Generic/Toolbar';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'delete-news',
    components: { Toolbar },
    data: () => ({
        lastPath: '/News/Editor/'
    }),
    computed: {
        ...mapGetters('NewsOperations', ['getSelectedNews'])
    },
    methods: {
        ...mapActions('NewsOperations', ['DELETE_NEWS']),
        deleteNews() {
            this.DELETE_NEWS(this.getSelectedNews.id).then(() => this.$router.replace(this.lastPath));
        }
    }
};
</script>
