<template>
    <v-layout class="fab-container v-btn--right v-btn--bottom">
        <slot>
            <!-- Add your own Buttons	 -->
        </slot>

        <v-flex v-if="showAddToGroup">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-fab-transition>
                        <v-btn
                            v-on="on"
                            v-show="editMode"
                            color="teal accent-4"
                            fab
                            @click="addToGroup()"
                            :small="$vuetify.breakpoint.xs"
                            :disabled="!permissions.groups"
                        >
                            <v-icon color="white">playlist_add</v-icon>
                        </v-btn>
                    </v-fab-transition>
                </template>
                <span>{{ settingTooltips }}</span>
            </v-tooltip>
        </v-flex>

        <v-flex v-if="showMove">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-fab-transition>
                        <v-btn
                            v-on="on"
                            :disabled="!permissions.move && !permissions.copy"
                            v-show="editMode"
                            color="brown lighten-1"
                            fab
                            :small="$vuetify.breakpoint.xs"
                            @click="moveItem()"
                        >
                            <v-icon color="white">compare_arrows</v-icon>
                        </v-btn>
                    </v-fab-transition>
                </template>
                <span>{{ moveItemTooltips }}</span>
            </v-tooltip>
        </v-flex>

        <v-flex v-if="showPreview">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-fab-transition>
                        <v-btn v-on="on" v-show="editMode" color="cyan lighten-1" fab :small="$vuetify.breakpoint.xs" @click="detailItem()">
                            <v-icon color="white">mdi-eye</v-icon>
                        </v-btn>
                    </v-fab-transition>
                </template>
                <span>{{ $t('Vorschau') }}</span>
            </v-tooltip>
        </v-flex>

        <v-flex v-if="editMode">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-fab-transition>
                        <v-btn v-on="on" :disabled="!permissions.delete" color="error" fab @click="deleteItem()" :small="$vuetify.breakpoint.xs">
                            <v-icon color="white">mdi-delete</v-icon>
                        </v-btn>
                    </v-fab-transition>
                </template>
                <span>{{ $t('Löschen') }}</span>
            </v-tooltip>
        </v-flex>

        <v-flex v-if="editMode || showEdit">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-fab-transition>
                        <v-btn v-on="on" :disabled="!permissions.update" color="amber darken-2" fab @click="updateItem()" :small="$vuetify.breakpoint.xs">
                            <v-icon color="white">mdi-edit</v-icon>
                        </v-btn>
                    </v-fab-transition>
                </template>
                <span>{{ $t('Bearbeiten') }}</span>
            </v-tooltip>
        </v-flex>

        <v-flex v-if="showCheckButton">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-fab-transition>
                        <v-btn v-on="on" color="primary" fab @click="checkItem()" :disabled="!permissions.add">
                            <v-icon color="white">mdi-check</v-icon>
                        </v-btn>
                    </v-fab-transition>
                </template>
                <span>{{ $t(checkButtonTooltips) }}</span>
            </v-tooltip>
        </v-flex>

        <v-flex v-if="showAddButton">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-fab-transition>
                        <v-btn v-on="on" color="success" fab @click="addItem()" :disabled="!permissions.add">
                            <v-icon color="white">mdi-plus</v-icon>
                        </v-btn>
                    </v-fab-transition>
                </template>
                <span>{{ $t('Hinzufügen') }}</span>
            </v-tooltip>
        </v-flex>

        <v-flex v-if="showStart">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-fab-transition>
                        <v-btn v-on="on" :disabled="disableStart" color="secondary" fab @click="startItem()" :small="$vuetify.breakpoint.xs">
                            <v-icon color="white">mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-fab-transition>
                </template>
                <span>{{ $t(tooltipsStart) }}</span>
            </v-tooltip>
        </v-flex>

        <!-- SHORTCUT FLOATING BUTTON -->
        <v-flex v-if="shortCutEditor">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-speed-dial
                        v-model="shortCutMode"
                        direction="top"
                        :open-on-hover="false"
                        transition="slide-y-reverse-transition"
                        :disabled="!permissions.add"
                    >
                        <template v-slot:activator>
                            <v-fab-transition>
                                <v-btn v-on="on" v-model="shortCutMode" color="primary" dark fab :small="$vuetify.breakpoint.xs">
                                    <v-icon>scatter_plot</v-icon>
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-fab-transition>
                        </template>

                        <v-tooltip left v-if="shortCutEditor">
                            <template v-slot:activator="{ on }">
                                <v-fab-transition>
                                    <v-btn :disabled="!permissions.add" fab v-on="on" dark small color="pink lighten-3" @click="shortCutEmit('CONTENT_ONLY')">{{
                                        $t('NC')
                                    }}</v-btn>
                                </v-fab-transition>
                            </template>
                            <span>{{ $t('Nur Content') }}</span>
                        </v-tooltip>

                        <v-tooltip left v-if="shortCutEditor">
                            <template v-slot:activator="{ on }">
                                <v-fab-transition>
                                    <v-btn
                                        :disabled="!permissions.add"
                                        fab
                                        v-on="on"
                                        dark
                                        small
                                        color="blue lighten-1"
                                        @click="shortCutEmit('SINGLE_SELECT')"
                                        >{{ $t('EA') }}</v-btn
                                    >
                                </v-fab-transition>
                            </template>
                            <span>{{ $t('Einzelauswahl') }}</span>
                        </v-tooltip>

                        <v-tooltip left v-if="shortCutEditor">
                            <template v-slot:activator="{ on }">
                                <v-fab-transition>
                                    <v-btn
                                        fab
                                        :disabled="!permissions.add"
                                        v-on="on"
                                        dark
                                        small
                                        color="indigo lighten-1"
                                        @click="shortCutEmit('MULTI_SELECT')"
                                        >{{ $t('MA') }}</v-btn
                                    >
                                </v-fab-transition>
                            </template>
                            <span>{{ $t('Mehrfachauswahl') }}</span>
                        </v-tooltip>

                        <v-tooltip left v-if="shortCutEditor">
                            <template v-slot:activator="{ on }">
                                <v-fab-transition>
                                    <v-btn
                                        fab
                                        :disabled="!permissions.add"
                                        v-on="on"
                                        dark
                                        small
                                        color="deep-orange lighten-1"
                                        @click="shortCutEmit('TRUE_FALSE')"
                                        >{{ $t('RF') }}</v-btn
                                    >
                                </v-fab-transition>
                            </template>
                            <span>{{ $t('Richtig/Falsch') }}</span>
                        </v-tooltip>

                        <v-tooltip left v-if="shortCutEditor">
                            <template v-slot:activator="{ on }">
                                <v-fab-transition>
                                    <v-btn fab :disabled="!permissions.add" v-on="on" dark small color="teal lighten-1" @click="shortCutEmit('FILL_BLANK')">{{
                                        $t('FT')
                                    }}</v-btn>
                                </v-fab-transition>
                            </template>
                            <span>{{ $t('Freitext') }}</span>
                        </v-tooltip>

                        <v-tooltip left v-if="shortCutEditor">
                            <template v-slot:activator="{ on }">
                                <v-fab-transition>
                                    <v-btn
                                        fab
                                        :disabled="!permissions.add"
                                        v-on="on"
                                        dark
                                        small
                                        color="light-green lighten-1"
                                        @click="shortCutEmit('PICKER')"
                                        >{{ $t('NA') }}</v-btn
                                    >
                                </v-fab-transition>
                            </template>
                            <span>{{ $t('Numerische Auswahl') }}</span>
                        </v-tooltip>

                        <v-tooltip left v-if="shortCutEditor">
                            <template v-slot:activator="{ on }">
                                <v-fab-transition>
                                    <v-btn fab :disabled="!permissions.add" v-on="on" dark small color="cyan lighten-1" @click="shortCutEmit('SEQUENCE')">{{
                                        $t('SQ')
                                    }}</v-btn>
                                </v-fab-transition>
                            </template>
                            <span>{{ $t('Sequenz') }}</span>
                        </v-tooltip>
                    </v-speed-dial>
                </template>
                <span>{{ $t('Abkürzung') }}</span>
            </v-tooltip>
        </v-flex>
    </v-layout>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data: () => ({
        shortCutMode: false
    }),
    props: {
        permissions: {
            type: Object,
            default: () => ({
                add: true,
                update: true,
                delete: true,
                move: true,
                copy: true,
                details: true,
                groups: true
            })
        },
        showCheckButton: {
            default: false,
            type: Boolean
        },
        checkButtonTooltips: {
            default: 'Auswählen',
            type: String
        },
        showAddButton: {
            default: true,
            type: Boolean
        },
        shortCutEditor: {
            default: false,
            type: Boolean
        },
        disableStart: {
            default: true,
            type: Boolean
        },
        showStart: {
            default: false,
            type: Boolean
        },
        moveItemTooltips: {
            default: '',
            type: String
        },
        tooltipsStart: {
            default: 'Anfangen',
            type: String
        },
        settingTooltips: {
            default: '',
            type: String
        },
        showEdit: {
            default: false,
            type: Boolean
        },
        editMode: {
            default: false,
            type: Boolean
        },
        disableButtonStartQuiz: {
            default: true,
            type: Boolean
        },
        showPreview: {
            default: false,
            type: Boolean
        },
        showMove: {
            default: false,
            type: Boolean
        },
        showAddToGroup: {
            default: false,
            type: Boolean
        }
    },
    computed: {
        ...mapGetters('General', ['getLoggedInAdmin'])
    },
    methods: {
        deleteItem() {
            this.$emit('deleteItemEmit');
        },
        updateItem() {
            this.$emit('updateItemEmit');
        },
        addItem() {
            this.$emit('addItemEmit');
        },
        checkItem() {
            this.$emit('checkItemEmit');
        },
        detailItem() {
            this.$emit('detailItemEmit');
        },
        moveItem() {
            this.$emit('moveItemEmit');
        },
        addToGroup() {
            this.$emit('addToGroupEmit');
        },
        startQuiz() {
            this.$emit('startQuizEmit');
        },
        // historyItem() {
        // 	this.$emit('historyItemEmit');
        // },
        shortCutEmit(shortCut) {
            this.$emit('ShortCutEmit', shortCut);
        },
        startItem() {
            this.$emit('startItemEmit');
        }
    }
};
</script>

<style scoped>
.fab-container {
    position: fixed;
    z-index: 4;
}
</style>
