import Statistics from '../../Components/Statistics/Dashboard';
import Dashboard from '../../views/Dashboard/Dashboard';

import { superAdmin, admin } from '../Roles';

export default [
    /**
     * Statistics
     * @description
     */
    {
        path: '/Statistics',
        name: 'statistics',
        component: Statistics,
        meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionSet: ['STATISTICS_MANAGEMENT'],
            requiresRole: true,
            roleSet: [superAdmin, admin],
            navigation: {
                type: { name: 'Groups', groupId: 'Reports' },
                priority: 0,
                icon: 'mdi-chart-bar-stacked',
                name: 'User-Reports',
                navigationKey: 'USER_REPORTS'
            }
        }
    },
    {
        path: '/Statistics/Dashboard',
        name: 'statisticsDashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionSet: ['STATISTICS_MANAGEMENT'],
            requiresRole: true,
            roleSet: [superAdmin, admin],
            navigation: {
                type: { name: 'Groups', groupId: 'Reports' },
                priority: 0,
                icon: 'mdi-chart-bar',
                name: 'Systemreports',
                navigationKey: 'SYETEM_REPORTS'
            }
        }
    }
];
