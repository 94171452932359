import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters('CustomerManagerOperations', ['getCustomers', 'getSelectedAdmin', 'getSelectedCustomer', 'getSelectedSuperAdmin', 'getView'])
    },
    methods: {
        ...mapActions('CustomerManagerOperations', [
            'GET_CUSTOMERS',
            'GET_SINGLE_CUSTOMER',
            'SET_SELECTED_CUSTOMER',
            'SET_SELECTED_SUPER_ADMIN',
            'SET_SELECTED_ADMIN',
            'ADD_CUSTOMER',
            'UPDATE_CUSTOMER',
            'DELETE_CUSTOMER',
            'ADD_SUPER_ADMIN',
            'UPDATE_SUPER_ADMIN',
            'UPDATE_WWWC_ROLE',
            'SET_VIEW',
            'SORT_CUSTOMERS'
        ])
    }
};
