import Vue from 'vue';
import Vuex from 'vuex';

import General from './General/LoginOperations';

import CatalogMaterialOperations from './CatalogMaterial/CatalogMaterialOperations';
import User from './User/UserOperations';
import UserPrototype from './User/UserPrototypeOperations';
import CatalogOperations from './Authoringtool/Catalog/CatalogOperations';
import Admin from './Admin/AdminOperations';
import AuthoringToolOperations from './Authoringtool/AuthoringToolOperations';
import AdminCatalogGroupPermission from './Admin/AdminCatalogGroupPermission';
import AdminCatalogSubGroupPermission from './Admin/AdminCatalogSubGroupPermission';
import AdminCatalogPermission from './Admin/AdminCatalogPermission';
import PermissionProfiles from './Permission/PermissionProfiles/PermissionProfiles';
import AuthoringAdminPermission from './Permission/Authoring/AuthoringAdminPermission';
import AdminPermission from './Permission/AdminPermission/AdminPermissions';
import UserPermission from './Permission/UserPermission/UserPermissions';
import CatalogGroupOperations from './Authoringtool/CatalogGroup/CatalogGroupOperations';
import CatalogSubgroupOperations from './Authoringtool/CatalogSubGroup/CatalogSubgroupOperations';
import AdminGroupsOp from './Group/AdminGroups/AdminGroupOperation';
import AdminGroupPermissions from './Permission/AdminGroupPermission/AdminGroupPermissions';
import UserGroupsOp from './Group/UserGroups/UserGroupOperations';
import UserGroupPermissions from './Permission/UserGroupPermission/UserGroupPermissions';
import AppLanguage from './General/GlobalConfig/AppLanguage';
import KnowledgeUnitOperations from './Authoringtool/KnowledgeUnit/KnowledgeUnitOperations';
import KnowledgeContentOperations from './KnowledgeContent/KnowledgeContentOperations';
import WikiOperations from './Wiki/WikiOperations';
import WikiPermissions from './Permission/WikiPermission/WikiPermissions';
import FileManagerOperations from './FileManager/FileManagerOperations';
import StaticFileManagerOperations from './FileManager/StaticFileManagerOperations';
import FAQOperations from './FAQ/FAQOperations';
import SCORMOperations from './SCORM/SCORMOperations';
import PlantoolOperations from './Plantool/PlantoolOperations';
import LoadOperations from './General/LoadOperations';
import ErrorReporterOperations from './General/ErrorReporterOperations';
import NotificationOperations from './General/NotificationOperations';
import NavigationOperations from './General/NavigationOperations';
import LanguageOperations from './General/LanguageOperations';
import SystemSettings from './SystemSettings/SystemSettings';
import NewsOperations from './News/NewsOperations';
import CustomerOperations from './Customer/CustomerOperations';
import UserApp from './UserApp/UserApp';
import StatisticOperations from './Statistic/StatisticOperations';
import ExcelUsersOperations from './ExcelUsers/ExcelUsersOperations';
import ArchiveToolOperations from './ArchiveTool/ArchiveToolOperations';
import DashboardOperations from './Dashboard/DashboardOperations';
import CustomerManagerOperations from './CustomerManager/CustomerManagerOperations';
import ResizeObserverOperations from './ResizeObserver/ResizeObserverOperations';
import CustomerForumOperations from './Forum/CustomerForumOperations';
import GroupForumOperations from './GroupForum/GroupForumOperations';
import CertificateOperations from './Certificates/CertificateOperations';
import ProfileOperations from './Profile/ProfileOperations';
// Course Learning
import ActivityOperations from './CourseLearning/CourseManager/ActivityOperations';
import CourseOperations from './CourseLearning/CourseManager/CourseOperations';
import EvaluationOperations from './CourseLearning/CourseManager/EvaluationOperations';
import GradingTableOperations from './CourseLearning/CourseManager/GradingTableOperations';
import LearningGoalOperations from './CourseLearning/CourseManager/LearningGoalOperations';
import TaxonomyTableOperations from './CourseLearning/CourseManager/TaxonomyTableOperations';
import TopicOperations from './CourseLearning/CourseManager/TopicOperations';
import MaterialOperations from './CourseLearning/CourseManager/MaterialOperations';
import MyCourseForumOperations from './CourseLearning/CourseManager/MyCourseForumOperations';
import MyCourseGroupOperations from './CourseLearning/CourseManager/MyCourseGroupOperations';
import MyCourseGroupForumOperations from './CourseLearning/CourseManager/MyCourseGroupForumOperations';
// Course Templates
import ActivityTemplateOperations from './CourseLearning/CourseTool/ActivityTemplateOperations';
import CourseTemplateOperations from './CourseLearning/CourseTool/CourseTemplateOperations';
import FolderTemplateOperations from './CourseLearning/CourseTool/FolderTemplateOperations';
import GradingTableTemplateOperations from './CourseLearning/CourseTool/GradingTableTemplateOperations';
import LearningGoalTemplateOperations from './CourseLearning/CourseTool/LearningGoalTemplateOperations';
import TopicTemplateOperations from './CourseLearning/CourseTool/TopicTemplateOperations';

import ImageGalleryOperations from './General/ImageGalleryOperations';
import ChatOperations from './Chat/ChatOperations';

Vue.use(Vuex);

export default new Vuex.Store({
    actions: {
        clearAll({ commit }) {
            Object.keys(this.state).forEach((mod) => {
                commit(`${mod}/clear`);
            });
        }
    },
    modules: {
        General,
        Admin,
        AdminCatalogGroupPermission,
        AdminCatalogSubGroupPermission,
        AdminCatalogPermission,
        PermissionProfiles,
        AuthoringAdminPermission,
        AdminPermission,
        UserPermission,
        ExcelUsersOperations,
        User,
        CatalogOperations,
        CatalogGroupOperations,
        CatalogSubgroupOperations,
        AdminGroupsOp,
        AdminGroupPermissions,
        UserGroupsOp,
        UserGroupPermissions,
        AppLanguage,
        KnowledgeUnitOperations,
        KnowledgeContentOperations,
        WikiOperations,
        WikiPermissions,
        FileManagerOperations,
        StaticFileManagerOperations,
        PlantoolOperations,
        LoadOperations,
        ErrorReporterOperations,
        NotificationOperations,
        NavigationOperations,
        LanguageOperations,
        UserApp,
        StatisticOperations,
        SystemSettings,
        NewsOperations,
        CustomerOperations,
        ArchiveToolOperations,
        DashboardOperations,
        CustomerManagerOperations,
        ResizeObserverOperations,
        CustomerForumOperations,
        GroupForumOperations,
        ActivityOperations,
        CourseOperations,
        EvaluationOperations,
        GradingTableOperations,
        LearningGoalOperations,
        TaxonomyTableOperations,
        TopicOperations,
        ActivityTemplateOperations,
        CourseTemplateOperations,
        FolderTemplateOperations,
        GradingTableTemplateOperations,
        LearningGoalTemplateOperations,
        TopicTemplateOperations,
        MaterialOperations,
        ImageGalleryOperations,
        ChatOperations,
        MyCourseForumOperations,
        MyCourseGroupOperations,
        MyCourseGroupForumOperations,
        CertificateOperations,
        ProfileOperations,
        AuthoringToolOperations,
        UserPrototype,
        FAQOperations,
        SCORMOperations,
        CatalogMaterialOperations
    }
});
