<template>
    <v-card height="100%">
        <v-toolbar dark flat color="secondary">
            <v-toolbar-title>{{ $t('Content') }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="toggleChartType">
                <v-icon>{{ iconComputed }}</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <v-container>
                <v-row align="center">
                    <v-col :cols="12" :sm="6">
                        <GChart :type="chartType" :options="calculateChartOptions()" :data="chartData" :resizeDebounce="1000" @ready="onChartCreation" />
                    </v-col>
                    <v-col :cols="12" :sm="6">
                        <v-list dense>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-chip :color="chipClassColor('APPROVED')">{{ $t('Genehmigt') }}</v-chip>
                                </v-list-item-content>
                                <v-list-item-action>
                                    {{ state('APPROVED') }}
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-chip :color="chipClassColor('PENDING')">
                                        {{ $t('Anstehend') }}
                                    </v-chip>
                                </v-list-item-content>
                                <v-list-item-action>
                                    {{ state('PENDING') }}
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-chip :color="chipClassColor('DECLINED')">
                                        {{ $t('Abgelehnt') }}
                                    </v-chip>
                                </v-list-item-content>
                                <v-list-item-action>
                                    {{ state('DECLINED') }}
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider class="ma-2" />
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ $t('Gesamt') }}
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    {{ total }}
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import { GChart } from 'vue-google-charts';
import DashboardMixin from './Mixins/DashboardMixin';

export default {
    name: 'knowledge-content-tally',
    components: { GChart },
    mixins: [DashboardMixin],
    data: () => ({
        chartType: 'PieChart',
        google: null
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        colors() {
            return this.$vuetify.theme.currentTheme;
        },
        iconComputed() {
            if (this.chartType == 'PieChart') return 'mdi-chart-bar';
            else return 'mdi-chart-pie';
        },
        total() {
            if (this.value.hasOwnProperty('totalKnowledgeContents')) {
                return this.value.totalKnowledgeContents;
            }
            return 0;
        },
        chartData() {
            if (this.google) {
                return this.google.visualization.arrayToDataTable([
                    [this.$t('Content'), this.$t('Anzahl'), { role: 'style' }],
                    [this.$t('Abgelehnt'), this.state('DECLINED'), this.colors.error],
                    [this.$t('Ausstehend'), this.state('PENDING'), this.colors.warning],
                    [this.$t('Genehmigt'), this.state('APPROVED'), this.colors.success]
                ]);
            }
            return [];
        },
        isDark() {
            return this.$vuetify.theme.dark;
        }
    },
    methods: {
        onChartCreation(chart, google) {
            this.google = google;
        },
        chipClassColor(state) {
            switch (state) {
                case 'APPROVED':
                    return 'success';
                case 'PENDING':
                    return 'warning';
                case 'DECLINED':
                    return 'error';
            }
            return '';
        },
        toggleChartType() {
            this.chartType === 'PieChart' ? (this.chartType = 'ColumnChart') : (this.chartType = 'PieChart');
        },
        calculateChartOptions() {
            return {
                width: 300,
                height: 300,
                legend: 'none',
                is3D: false,
                backgroundColor: this.isDark ? '#1E1E1E' : '#FFF',
                vAxis: {
                    title: this.$t('Anzahl der Wissensinhalte')
                },
                colors: [this.colors.error, this.colors.warning, this.colors.success],
                animation: { startup: true, duration: 500, easing: 'out' }
            };
        },
        state(key) {
            if (this.value.hasOwnProperty('stateMap')) {
                switch (key) {
                    case 'PENDING':
                        return this.value.stateMap.PENDING ? this.value.stateMap.PENDING : 0;
                    case 'APPROVED':
                        return this.value.stateMap.APPROVED ? this.value.stateMap.APPROVED : 0;
                    case 'DECLINED':
                        return this.value.stateMap.DECLINED ? this.value.stateMap.DECLINED : 0;
                }
            }
            return 0;
        }
    }
};
</script>
