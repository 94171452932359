<template>
    <v-card>
        <v-alert :value="!hasZeroPercent" type="warning">{{ $t('Die Benotungstabelle braucht einen Eintrag mit 0%') }}</v-alert>
        <v-form v-model="computedValidation" ref="form">
            <v-data-table
                :items="gradingTable"
                :no-data-text="$t('Keine Bewertungen verfügbar')"
                :no-results-text="$t('Kein Ergebnis')"
                :hideDefaultFooter="true"
                :items-per-page="-1"
                :footer-props="{
                    'items-per-page-text': $t('Bewertungen pro Seite'),
                    'items-per-page-options': [{ text: $t('Alle'), value: -1 }]
                }"
                :headers="headers"
                :custom-sort="sortTable"
            >
                <template #item.percent="{ item }">
                    <v-container>
                        <v-row>
                            <v-col :cols="12">
                                <v-text-field
                                    type="number"
                                    :step="1"
                                    min="0"
                                    max="100"
                                    suffix="%"
                                    :value="item.percent"
                                    @blur="onPercentChange($event, item)"
                                    :label="$t('Prozent')"
                                    :hint="$t('Geben Sie eine Prozentzahl an, von der aufwärts die Bewertung gesetzt wird')"
                                    persistent-hint
                                    :rules="[percentTooLow, percentTooHigh]"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
                <template #item.name="{ item }">
                    <v-container>
                        <v-row>
                            <v-col :cols="12">
                                <v-text-field
                                    :value="item.name"
                                    @input="onNameChange($event, item)"
                                    :label="$t('Note')"
                                    :hint="$t('Geben Sie der Note eine Bezeichnung')"
                                    persistent-hint
                                    :rules="[notEmpty]"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
                <template #item.action="{ item, index }">
                    <v-container>
                        <v-row>
                            <v-col :cols="12">
                                <v-btn icon color="error" @click="removeGrade(index)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
            </v-data-table>
        </v-form>
        <v-card-actions>
            <v-spacer />
            <v-btn @click="createGrade" color="success">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'grading-table-selector',
    data() {
        return {
            formValidation: false,
            gradingTable: [],
            headers: [
                {
                    text: this.$t('Prozent'),
                    value: 'percent',
                    sortable: false,
                    width: '48.5%'
                },
                {
                    text: this.$t('Bewertung'),
                    value: 'name',
                    sortable: false,
                    width: '48.5%'
                },
                {
                    text: this.$t('Aktion'),
                    value: 'action',
                    sortable: false,
                    width: '5%'
                }
            ]
        };
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        isLoaded: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        value() {
            this.deserialize();
        }
    },
    computed: {
        hasZeroPercent() {
            return this.gradingTable.map((grade) => grade.percent).includes(0);
        },
        computedValidation: {
            get() {
                return this.formValidation;
            },
            set(value) {
                this.formValidation = value;
                this.$emit('validation', value);
            }
        }
    },
    methods: {
        sortTable(items) {
            items.sort((a, b) => a.percent - b.percent);
            return items;
        },
        onPercentChange(value, item) {
            value = value.target.value;
            item.percent = value;
            this.serialize();
        },
        onNameChange(value, item) {
            item.name = value;
            this.serialize();
        },
        deserialize() {
            this.gradingTable = [];
            this.gradingTable = Object.entries(this.value).map(([key, value]) => ({
                percent: parseInt(key * 100),
                name: value
            }));
            this.validateForm();
        },
        serialize() {
            this.$emit(
                'input',
                this.gradingTable.reduce((table, value) => {
                    table[value.percent / 100] = value.name;
                    return table;
                }, {})
            );
            this.validateForm();
        },
        createGrade() {
            let maxPercent = Math.max(...this.gradingTable.map((grade) => grade.percent));
            if (!isFinite(maxPercent) || !this.hasZeroPercent) {
                maxPercent = 0;
            } else {
                maxPercent = Math.min(maxPercent + 5, 100);
            }
            if (!this.gradingTable.map((grade) => grade.percent).includes(100)) {
                this.gradingTable.push({
                    percent: maxPercent,
                    name: ''
                });
                this.serialize();
            }
        },
        removeGrade(index) {
            this.gradingTable.splice(index, 1);
            this.serialize();
        },
        validateForm() {
            this.$nextTick(() => this.$refs.form.validate());
        },
        notEmpty(value) {
            return !!value || this.$t('Die Bezeichnung für die Note darf nicht leer sein');
        },
        percentTooLow(value) {
            return parseInt(value) >= 0 || this.$t('Das Feld muss größer gleich 0 sein');
        },
        percentTooHigh(value) {
            return parseInt(value) <= 100 || this.$t('Das Feld muss kleiner gleich 1 sein');
        }
    },
    mounted() {
        this.deserialize();
        this.serialize();
    }
};
</script>
