<template>
    <v-card>
        <v-toolbar dense flat dark color="primary">
            <v-toolbar-title>{{ $t('Katalog') }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col :cols="6">
                        <v-text-field readonly hide-details :value="catalog.name" :label="$t('Katalogbezeichnung')" />
                    </v-col>
                    <v-col :cols="6">
                        <v-text-field readonly hide-details :value="catalog.description" :label="$t('Katalogbeschreibung')" />
                    </v-col>
                    <v-col :cols="12">
                        <resize-container v-model="dimensions">
                            <GChart type="BarChart" :class="{ dark: isDark }" :data="formatBarChartData" :options="barChartOptions" />
                        </resize-container>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
import { GChart } from 'vue-google-charts';
import ResizeContainer from '../../Generic/Resize/ResizeContainer.vue';

export default {
    name: 'catalog-chart-details',
    components: { GChart, ResizeContainer },
    data: () => ({
        dimensions: {
            height: 0,
            width: 0,
            deltaHeight: 0,
            deltaWidth: 0
        }
    }),
    computed: {
        catalog() {
            return this.value.catalogDetails;
        },
        barChartOptions() {
            return {
                title: this.$t('Intervallergebnis'),
                titleTextStyle: {
                    color: this.isDark ? '#FFF' : '#000'
                },
                width: this.dimensions.width,
                height: 200,
                isStacked: 'percent',
                legend: { position: 'top', maxLines: 3 },
                backgroundColor: this.isDark ? '#1E1E1E' : '#FFF',
                vAxis: {
                    format: 'percent',
                    titleTextStyle: { color: this.isDark ? '#FFF' : '#000' }
                },
                hAxis: {
                    minValue: 0,
                    format: 'percent',
                    ticks: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
                    titleTextStyle: { color: this.isDark ? '#FFF' : '#000' }
                },
                series: {
                    0: { color: this.$vuetify.theme.currentTheme.success },
                    1: { color: this.$vuetify.theme.currentTheme.error },
                    2: { color: this.$vuetify.theme.currentTheme.accent }
                },
                tooltip: { isHtml: this.isDark }
            };
        },
        formatBarChartData() {
            if (this.value.hasOwnProperty('votes')) {
                return [
                    [this.$t('Ergebnis'), this.$t('Richtig'), this.$t('Falsch'), this.$t('Unbeantwortet')],
                    [this.$t('Ergebnis'), this.value.votes.correct, this.value.votes.incorrect, this.value.votes.unanswered]
                ];
            }
            return [
                [this.$t('Ergebnis'), this.$t('Richtig'), this.$t('Falsch'), this.$t('Unbeantwortet')],
                [this.$t('Ergebnis'), 0, 0, 0]
            ];
        },
        isDark() {
            return this.$vuetify.theme.dark;
        }
    },
    props: {
        value: {
            type: Object
        }
    }
};
</script>
