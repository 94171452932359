import axios from '../../plugins/Axios/axios';
import Vue from 'vue';

const state = {
    customers: [],
    selectedCustomer: {},
    selectedSuperAdmin: {},
    selectedAdmin: {},
    view: {
        component: '',
        type: '',
        data: {},
        tab: ''
    }
};

const getters = {
    getCustomers() {
        return state.customers;
    },
    getSelectedCustomer() {
        return state.selectedCustomer;
    },
    getSelectedSuperAdmin() {
        return state.selectedSuperAdmin;
    },
    getSelectedAdmin() {
        return state.selectedAdmin;
    },
    getView(state) {
        return state.view;
    }
};

const actions = {
    CREATE_ADMIN({ commit }, id, ...request) {
        return axios
            .create(`/customers/${id}/admins`, request)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateSelectedAdmin', response);
                return response;
            });
    },
    GET_CUSTOMERS({ commit }) {
        return axios
            .get(`/customers`)
            .then((response) => response.data.data)
            .then((response) => {
                response.forEach((data) => {
                    if (data.settings !== null && data.settings !== undefined) {
                        data.customer.domainName = data.settings.domainName;
                    }
                });
                commit('updateCustomers', response);
                return response;
            });
    },
    GET_SINGLE_CUSTOMER({ commit }, customerId) {
        return axios
            .get(`customers/${customerId}`)
            .then((response) => response.data.data)
            .then((response) => {
                if (response.settings !== null && response.settings !== undefined) {
                    response.customer.domainName = response.settings.domainName;
                }
                commit('updateSelectedCustomer', response);
                return response;
            });
    },
    SET_SELECTED_CUSTOMER({ commit }, customer) {
        commit('updateSelectedCustomer', customer);
    },
    SET_SELECTED_SUPER_ADMIN({ commit }, superAdmin) {
        commit('updateSelectedSuperAdmin', superAdmin);
    },
    SET_SELECTED_ADMIN({ commit }, admin) {
        commit('updateSelectedAdmin', admin);
    },
    ADD_CUSTOMER({ commit, dispatch }, request) {
        return axios
            .post(`/customers`, request)
            .then((response) => response.data.data)
            .then((response) => {
                dispatch('GET_CUSTOMERS');
                return response;
            });
    },
    UPDATE_CUSTOMER({ commit }, request) {
        const { id, ...customer } = request.customer;
        return axios
            .patch(`/customers/${id}`, { customer: customer })
            .then((response) => response.data.data)
            .then((response) => {
                return response;
            });
    },
    DELETE_CUSTOMER({ commit, dispatch }, { id: customerId }) {
        return axios.delete(`/customers/${customerId}`).then(() => {
            dispatch('GET_CUSTOMERS');
        });
    },
    ADD_SUPER_ADMIN({ dispatch }, request) {
        const customerId = request.customer.id;
        return axios.post(`/customers/${customerId}/superadmins`, request).then((response) => response.data.data);
    },
    UPDATE_SUPER_ADMIN({ commit }, { customer, superAdmin }) {
        const { id: customerId } = customer;
        const { id: superAdminid, ...superAdminBody } = superAdmin;

        return axios.patch(`/customers/${customerId}/superadmins/${superAdminid}`, superAdminBody).then((response) => response.data.data);
    },
    UPDATE_WWWC_ROLE({ dispatch }, request) {
        return axios
            .patch(`/webconference/user/role`, request)
            .then((response) => response.data.data)
            .then((response) => {
                dispatch('GET_CUSTOMERS');
                var customerId = state.selectedCustomer.customer.id;
                dispatch('GET_SINGLE_CUSTOMER', customerId);
            });
    },
    SET_VIEW({ commit }, view) {
        commit('updateView', view);
    },
    SORT_CUSTOMERS({ commit }, prop, ascending) {
        commit('sortCustomers', prop, ascending);
    }
};

const mutations = {
    clear: (state) => {
        state.customers = [];
        state.selectedCustomer = {};
    },
    updateCustomers: (state, value) => (state.customers = value),
    updateSelectedCustomer: (state, value) => (state.selectedCustomer = value),
    updateSelectedSuperAdmin: (state, value) => (state.selectedSuperAdmin = value),
    updateView: (state, view) => {
        Vue.set(state, 'view', view);
    },
    updateSelectedAdmin: (state, value) => (state.selectedAdmin = value),
    sortCustomers(state, prop, ascending) {
        state.customers = state.customers.sort((a, b) => {
            return a.customer[prop] < b.customer[prop] ? 1 : -1;
        });
        // switch (prop) {
        //     case 'companyName':
        //         break;
        //     case 'active':
        //         break;
        //     case 'companyDomain':
        //         break;
        //     case 'maxLicenses':
        //         break;
        // }
        if (!ascending) {
            state.customers = state.customers.reverse();
        }
        return state.customers;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
