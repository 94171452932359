import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters('UserApp', [
            'getTaskArchiveResult',
            'getUserIncompleteTaskList',
            'getUserCompleteTaskList',
            'getUserIncompleteArchiveList',
            'getUserCompleteArchiveList',
            'getSelectedTaskArchive',
            'getArchiveStore',
            'getActiveTab',
            'getTaskKnowledgeUnit',
            'getTodaysRemainingKUCount',
            'getTodaysCompletedKUCount',
            'getDailyAnsweredLimit',
            'getQuizHistories',
            'getQuizHistoryDrawerState'
        ])
    },
    methods: {
        ...mapActions('UserApp', [
            'GET_USERTASK_RESULTS',
            'GET_ARCHIVETASK_RESULTS',
            'SELECT_TASK',
            'SELECT_ARCHIVE',
            'GET_INCOMPLETE_USERTASK_LIST',
            'GET_COMPLETE_USERTASK_LIST',
            'GET_INCOMPLETE_ARCHIVE_LIST',
            'GET_COMPLETE_ARCHIVE_LIST',
            'RESET_SELECTED_TASKARCHIVE',
            'RESET_ARCHIVE_STORE',
            'ADD_ARCHIVE',
            'UPDATE_ARCHIVE',
            'UPDATE_ACTIVE_TAB',
            'RESET_TASKARCHIVE_KNOWLEDGEUNIT',
            'GET_TASK_KNOWLEDGEUNIT_BY_ID',
            'GET_ARCHIVE_KNOWLEDGEUNIT_BY_ID',
            'ADD_USERTASK_VOTE',
            'ADD_ARCHIVE_VOTE',
            'UPDATE_QUIZ_HISTORIES',
            'RESET_QUIZ_HISTORIES',
            'UPDATE_QUIZ_HISTORYDRAWER_STATE',
            'KNOWLEDGE_UNIT_PREVIEW',
            'KNOWLEDGE_UNIT_PREVIEW_SUBMIT',
            'LOOP_TASK',
            'LOOP_ARCHIVE'
        ])
    }
};
