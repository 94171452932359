<template>
    <Dashboard :isAdminStatistic="false" />
</template>

<script>
import Dashboard from '../../../Components/Statistics/Dashboard';

export default {
    name: 'user-dashboard',
    components: {
        Dashboard
    }
};
</script>
