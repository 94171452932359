<template>
    <v-container fluid pa-0 ma-0 style="max-height: 300px; overflow-y: auto; overflow-x: hidden">
        <v-row>
            <v-col :cols="12">
                <v-text-field :label="$t('Dateiname')" readonly v-model="value.fileName" hide-details />
            </v-col>
            <v-col :cols="6">
                <v-text-field :label="$t('Dateigröße')" readonly :value="formatBytes(value.size)" hide-details />
            </v-col>
            <v-col :cols="6">
                <v-text-field :label="$t('Dateityp')" readonly :value="fileExtension" hide-details />
            </v-col>
            <v-col :cols="6">
                <v-text-field :label="$t('Erstellt')" readonly :value="formatDate(value.createdAt)" hide-details />
            </v-col>
            <v-col :cols="6">
                <v-text-field :label="$t('Bearbeitet')" readonly :value="formatDate(value.updatedAt)" hide-details />
            </v-col>
            <v-col :cols="12" align-self="center">
                <keep-alive>
                    <component :is="componentSwitch" :src="value.fileURL" />
                </keep-alive>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import ImagePreview from '../../Generic/ImageLoader';
import AudioPreview from '../../FileManager/FilePreviewTypes/FilePreviewAudio';
import VideoPreview from '../../FileManager/FilePreviewTypes/FilePreviewVideo';
import PdfPreview from '../../FileManager/FilePreviewTypes/FilePreviewPDF';
import moment from 'moment';

export default {
    name: 'summary-file',
    components: {
        ImagePreview,
        AudioPreview,
        VideoPreview,
        PdfPreview
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        fileExtension() {
            return this.value && this.value.fileType ? this.value.fileType.substring(this.value.fileType.indexOf('/') + 1) : '';
        },
        fileType() {
            return this.value && this.value.fileType ? this.value.fileType.substring(0, this.value.fileType.indexOf('/')) : '';
        },
        componentSwitch() {
            switch (this.fileType) {
                case 'image':
                    return ImagePreview;
                case 'audio':
                    return AudioPreview;
                case 'video':
                    return VideoPreview;
                case 'application':
                    return PdfPreview;
            }
            return undefined;
        }
    },
    methods: {
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD, HH:mm');
        }
    }
};
</script>
