<template>
    <v-dialog v-model="computedMenu" :width="$vuetify.breakpoint.thresholds.sm" scrollable :fullscreen="$vuetify.breakpoint.xs" persistent>
        <v-card v-if="hasData">
            <v-toolbar dense text dark color="secondary">
                <v-toolbar-title>{{ `${value.user.firstName} ${value.user.lastName} - ${$t('Bewerten')}` }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-tabs v-model="tabs" mandatory show-arrows>
                <v-tab :key="0">{{ $t('Aktivität') }}</v-tab>
                <v-tab :key="1" :disabled="!!computedTypeObject.material">{{ $t('Material') }}</v-tab>
                <v-tab :key="2" :disabled="!hasUserNotes">{{ $t('Lernende Notizen') }}</v-tab>
            </v-tabs>
            <v-card-text height="100%">
                <v-container>
                    <v-tabs-items v-model="tabs">
                        <v-tab-item :key="0">
                            <v-row>
                                <v-col :cols="12">
                                    <v-text-field readonly :label="$t('Name')" :value="computedTypeObject.name" hide-details />
                                </v-col>
                                <v-col :cols="12">
                                    <v-textarea
                                        outlined
                                        no-resize
                                        :rows="3"
                                        :label="$t('Beschreibung')"
                                        :value="!!computedTypeObject.description ? computedTypeObject.description : $t('Keine')"
                                        readonly
                                        hide-details
                                    />
                                </v-col>
                                <v-col :cols="12">
                                    <v-text-field readonly :label="$t('Typ')" :value="$t(computedTypeObject.activityType)" hide-details />
                                </v-col>
                                <v-col :cols="6">
                                    <v-text-field readonly :label="$t('Startdatum')" :value="computedTypeObject.startDate" hide-details />
                                </v-col>
                                <v-col :cols="6">
                                    <v-text-field readonly :label="$t('Enddatum')" :value="computedTypeObject.endDate" hide-details />
                                </v-col>
                                <v-col :cols="6">
                                    <v-switch
                                        readonly
                                        :input-value="computedTypeObject.hideBeforeStart"
                                        hide-details
                                        :label="$t('Vor dem Anfangsdatum verbergen')"
                                        :ripple="false"
                                    />
                                </v-col>
                                <v-col :cols="6">
                                    <v-switch
                                        readonly
                                        :input-value="computedTypeObject.forceStartDate"
                                        hide-details
                                        :label="$t('Startdatum erzwingen')"
                                        :ripple="false"
                                    />
                                </v-col>
                                <v-col :cols="6">
                                    <v-switch
                                        readonly
                                        :input-value="computedTypeObject.forceEndDate"
                                        hide-details
                                        :label="$t('Enddatum erzwingen')"
                                        :ripple="false"
                                    />
                                </v-col>
                                <v-col :cols="12" v-if="type === 'ACTIVITY'">
                                    <component :is="handleComponent" :value="computedAnswer" />
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-tab-item :key="1">
                            <div class="ck ck-content scroll" v-html="computedTypeObject.material" />
                        </v-tab-item>
                        <v-tab-item :key="2">
                            <div class="ck ck-content scroll" v-text="value.userNotes" />
                        </v-tab-item>
                    </v-tabs-items>
                    <v-form v-model="validate" ref="form">
                        <v-row>
                            <v-col :cols="12">
                                <v-divider />
                            </v-col>
                            <v-col :cols="12">
                                <v-text-field
                                    v-model="teacherAssignedScore"
                                    :rules="[maximumPoints, minimumPoints]"
                                    type="number"
                                    :min="0"
                                    :label="$t('Punkte')"
                                    :hint="$t('Setzen Sie die Bewertung fest')"
                                />
                            </v-col>
                            <v-col :cols="12">
                                <v-textarea
                                    v-model="teacherNotes"
                                    :label="$t('Notizen')"
                                    :hint="$t('Sie können eine Nachricht zu der Bewertung hinterlegen')"
                                    no-resize
                                    outlined
                                    hide-details
                                    :rows="3"
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn block color="success" @click="save" :disabled="!validate">
                    {{ $t('Speichern') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import CheckType from './EvaluationTypes/CheckType';
import TextType from './EvaluationTypes/TextFieldType';
import UploadType from './EvaluationTypes/UploadType';
import QuizType from './EvaluationTypes/QuizType';

import CourseMixin from '../../Mixins/CourseMixin';

export default {
    name: 'evaluation-table-menu',
    mixins: [CourseMixin],
    data: () => ({
        tabs: 0,
        teacherAssignedScore: 0,
        teacherNotes: '',
        validate: false
    }),
    watch: {
        menu(value) {
            if (value) {
                if (this.value.teacherAssignedScore === -1) {
                    this.teacherAssignedScore = 0;
                } else {
                    this.teacherAssignedScore = this.value.teacherAssignedScore;
                }
                this.teacherNotes = this.value.teacherNotes;
            }
        }
    },
    props: {
        menu: {
            type: Boolean,
            default: false
        },
        value: {
            type: Object,
            default: () => ({})
        },
        type: {
            type: String,
            default: 'COURSE',
            validator: (v) => ['COURSE', 'TOPIC', 'LEARNING_GOAL', 'ACTIVITY'].indexOf(v) !== -1
        }
    },
    computed: {
        hasData() {
            return Object.keys(this.value).length > 0;
        },
        hasUserNotes() {
            return this.hasData && this.value.userNotes && this.value.userNotes.trim() !== '';
        },
        handleComponent() {
            switch (this.getSelectedActivity.activityType) {
                case 'UPLOAD':
                    return UploadType;
                case 'SELF_CHECK':
                    return CheckType;
                case 'TEXT_FIELD':
                    return TextType;
                case 'QUIZ':
                    return QuizType;
            }
            return '';
        },
        computedTypeObject() {
            switch (this.type) {
                case 'COURSE':
                    return this.getSelectedAdminCourse;
                case 'TOPIC':
                    return this.getSelectedTopic;
                case 'LEARNING_GOAL':
                    return this.getSelectedLearningGoal;
                case 'ACTIVITY':
                    return this.getSelectedActivity;
            }
            return '';
        },
        computedAnswer() {
            const index = this.getActivityAnswers.map((user) => user.user.id).indexOf(this.value.user.id);
            if (index !== -1) {
                return this.getActivityAnswers[index].activityAnswerBasePayload;
            }
            return {};
        },
        computedMenu: {
            get() {
                return this.menu;
            },
            set(value) {
                this.$emit('menu', value);
            }
        }
    },
    methods: {
        close() {
            this.$emit('menu', false);
        },
        maximumPoints(v) {
            return parseInt(v) <= this.computedTypeObject.score || this.$t('Maximale Punktemenge überschritten');
        },
        minimumPoints(v) {
            return parseInt(v) >= 0 || this.$t('Negative Punkte können nicht vergeben werden');
        },
        save() {
            switch (this.type) {
                case 'COURSE':
                    return this.CREATE_COURSE_EVALUATION({
                        course: this.getSelectedAdminCourse,
                        evaluation: [
                            {
                                teacherAssignedScore: this.teacherAssignedScore,
                                teacherNotes: this.teacherNotes,
                                user: { id: this.value.user.id }
                            }
                        ]
                    }).then(() => {
                        this.computedMenu = false;
                    });
                case 'TOPIC':
                    return this.CREATE_TOPIC_EVALUATION({
                        topic: this.getSelectedTopic,
                        evaluation: [
                            {
                                teacherAssignedScore: this.teacherAssignedScore,
                                teacherNotes: this.teacherNotes,
                                user: { id: this.value.user.id }
                            }
                        ]
                    }).then(() => {
                        this.computedMenu = false;
                    });
                case 'LEARNING_GOAL':
                    return this.CREATE_LEARNING_GOAL_EVALUATION({
                        learningGoal: this.getSelectedLearningGoal,
                        evaluation: [
                            {
                                teacherAssignedScore: this.teacherAssignedScore,
                                teacherNotes: this.teacherNotes,
                                user: { id: this.value.user.id }
                            }
                        ]
                    }).then(() => {
                        this.computedMenu = false;
                    });
                case 'ACTIVITY':
                    return this.CREATE_ACTIVITY_EVALUATION({
                        activity: this.getSelectedActivity,
                        evaluation: [
                            {
                                teacherAssignedScore: this.teacherAssignedScore,
                                teacherNotes: this.teacherNotes,
                                user: { id: this.value.user.id }
                            }
                        ]
                    }).then(() => {
                        this.computedMenu = false;
                    });
            }
        }
    }
};
</script>
