<template>
    <v-card text height="100%">
        <v-toolbar flat dense text color="transparent">
            <v-spacer />
            <v-toolbar-items>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon color="success" @click="createKnowledgeContent()" :disabled="!permissions.create">
                            <v-icon medium>mdi-file-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Content erstellen') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon color="success" @click="createFolder()" :disabled="!permissions.create">
                            <v-icon medium>mdi-folder-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Ordner erstellen') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon color="warning" v-show="!isRoot" @click="updateFolder()" :disabled="!permissions.update">
                            <v-icon medium>mdi-folder-edit</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Ordner bearbeiten') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon color="accent" v-show="!isRoot" @click="moveFolder()" :disabled="!permissions.move">
                            <v-icon medium>mdi-folder-move</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Ordner verschieben') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon color="error" v-show="!isRoot" @click="deleteFolder()" :disabled="!permissions.delete">
                            <v-icon medium>mdi-folder-remove</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Ordner löschen') }}</span>
                </v-tooltip>
            </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-list v-show="Object.keys(value).length > 0">
            <v-subheader>{{ $t('Zusätzliche Infos') }}</v-subheader>
            <v-list-item>
                <v-list-item-action>
                    <UserProfile :value="value.createdBy" small :displayName="false" />
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('Erstellt') }}</v-list-item-title>
                    <v-list-item-subtitle>
                        {{ value.createdAt }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-action>
                    <UserProfile :value="value.updatedBy" small :displayName="false" />
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('Bearbeitet') }}</v-list-item-title>
                    <v-list-item-subtitle>
                        {{ value.updatedAt }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>
import KnowledgeContentMixin from '../Mixins/KnowledgeContentMixin';
import UserProfile from '../../Generic/Profile/UserProfile';

export default {
    name: 'knowledge-content-folder-view',
    components: { UserProfile },
    mixins: [KnowledgeContentMixin],
    props: {
        search: {
            type: String,
            default: ''
        },
        value: {
            type: Object,
            default: () => ({})
        },
        permissions: {
            permissions: {
                type: Object,
                default: () => ({
                    add: false,
                    update: false,
                    delete: false,
                    change: false
                })
            }
        }
    },
    computed: {
        isRoot() {
            return Object.keys(this.value).length === 0;
        },
        folders() {
            if (Object.keys(this.value).length > 0) {
                return this.value && this.value.children ? this.value.children : [];
            }
            const rootData = this.getMapData('root');
            return rootData.folders && rootData.contents ? [...rootData.folders, ...rootData.contents] : [];
        }
    },
    methods: {
        filter(items, search) {
            if (search.trim() === '') {
                return items;
            }
            const buildString = search
                .split(' ')
                .filter((word) => word !== '')
                .map((word) => `(?=.*${word})`)
                .join('');
            const regex = new RegExp(buildString, 'i');
            const fulltext = (item) =>
                Object.values(item)
                    .filter((val) => typeof val === 'string' || typeof val === 'number')
                    .map((val) => {
                        if (typeof val === 'number') {
                            return val.toString();
                        }
                        return val;
                    })
                    .join(' ');
            return items.filter((item) => regex.test(fulltext(item)));
        },
        createFolder() {
            this.$emit('switchView', {
                component: 'CREATE_FOLDER',
                type: 'ADD',
                data: {}
            });
        },
        updateFolder() {
            this.$emit('switchView', {
                component: 'UPDATE_FOLDER',
                type: 'UPDATE',
                data: {}
            });
        },
        moveFolder() {
            this.$emit('switchView', {
                component: 'MOVE_FOLDER',
                type: 'MOVE',
                data: {}
            });
        },
        deleteFolder() {
            this.$emit('switchView', {
                component: 'DELETE_FOLDER',
                type: 'DELETE',
                data: {}
            });
        },
        createKnowledgeContent() {
            this.$emit('switchView', {
                component: 'CREATE_KNOWLEDGE_CONTENT',
                type: 'ADD',
                data: {}
            });
        },
        selectItem(item) {
            this.$emit('input', item);
        },
        iconColor(item) {
            if (this.isKnowledgeContentType(item) === 'KNOWLEDGE_CONTENT') {
                switch (item.state.stateName) {
                    case 'APPROVED':
                        return 'success';
                    case 'PENDING':
                        return 'warning';
                    case 'DECLINED':
                        return 'error';
                }
            }
            return '';
        },
        icon(item) {
            return this.isKnowledgeContentType(item) === 'FOLDER' ? 'mdi-folder-outline' : 'mdi-file';
        }
    }
};
</script>
