<template>
    <v-container fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm10 md8 lg6 xl4>
                <v-card>
                    <v-toolbar text dark color="primary">
                        <v-toolbar-title>{{ $t('Passwort setzen') }}</v-toolbar-title>
                    </v-toolbar>
                </v-card>
                <v-card>
                    <v-card-text>
                        <v-form>
                            <v-text-field
                                color="accent"
                                v-model="password"
                                type="password"
                                :hint="$t('Geben Sie Ihr neues Passwort ein')"
                                :label="$t('Passwort')"
                            />
                            <v-text-field
                                color="accent"
                                v-model="passwordConfirm"
                                type="password"
                                :hint="$t('Wiederholen Sie Ihr neues Password')"
                                :label="$t('Passwort wiederholen')"
                            />
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn text>{{ $t('Abbrechen') }}</v-btn>
                        <v-btn color="primary" :disabled="password !== passwordConfirm || password === '' || passwordConfirm === ''" @click="setPassword">{{
                            $t('Passwort Ändern')
                        }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    data: () => ({
        code: '',
        password: '',
        passwordConfirm: ''
    }),
    methods: {
        ...mapActions('General', ['SET_NEW_PASSWORD']),
        setPassword() {
            let requestBody = {
                code: this.code,
                password: this.password
            };
            this.SET_NEW_PASSWORD(requestBody).then(() => this.$router.push({ name: 'login' }));
        }
    },
    mounted() {
        this.code = this.$route.query.code;
    }
};
</script>
