import { mapActions, mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('KnowledgeContentOperations', ['getKnowledgeContent'])
    },
    methods: {
        ...mapActions('KnowledgeContentOperations', ['GET_KNOWLEDGE_CONTENT'])
    }
};
