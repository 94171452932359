<template>
    <v-card>
        <v-container grid-list-md fluid>
            <v-layout wrap>
                <v-flex xs12>
                    <v-list v-if="value.length > 0">
                        <v-list-item v-for="(item, index) in value" :key="`material-${index}`">
                            <v-list-item-action>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on">{{ extractIcon(item.media.fileType) }}</v-icon>
                                    </template>
                                    <span>{{ extractFileType(item.media.fileType) }}</span>
                                </v-tooltip>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn icon @click="updateMaterial(index)">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </v-list-item-action>
                            <v-list-item-action>
                                <v-btn icon @click="removeMaterial(index)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-flex>
                <v-flex xs12>
                    <v-layout align-end justify-end>
                        <MaterialSelector :selectedMaterial="selectedMaterial" @createMaterial="createMaterial" />
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-container>
    </v-card>
</template>

<script>
import MaterialSelector from './MaterialSelector';
import CourseMixin from './Mixins/CourseMixin';

export default {
    name: 'material',
    components: { MaterialSelector },
    mixins: [CourseMixin],
    data: () => ({
        selectedMaterial: {}
    }),
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        extractIcon(value) {
            switch (value.substring(0, value.indexOf('/'))) {
                case 'image':
                    return 'mdi-image';
                case 'application':
                    return 'mdi-file-pdf-box';
                default:
                    return 'mdi-file-document';
            }
        },
        extractFileType(value) {
            return value.substring(value.indexOf('/') + 1, value.length);
        },
        createMaterial({ index: materialIndex, ...newMaterial }) {
            if (materialIndex !== undefined) {
                const updatedMaterials = this.value.map((material, index) => {
                    if (index === materialIndex) {
                        return newMaterial;
                    }
                    return material;
                });
                this.$emit('input', updatedMaterials);
            } else {
                this.$emit('input', [...this.value, newMaterial]);
            }
        },
        removeMaterial(index) {
            this.$emit(
                'input',
                this.value.filter((item, itemIndex) => index !== itemIndex)
            );
        },
        updateMaterial(index) {
            this.selectedMaterial = { ...this.value[index], index: index };
        }
    }
};
</script>
