<template>
    <v-stepper v-model="selectedStep" non-linear :alt-labels="$vuetify.breakpoint.smAndUp" :elevation="0">
        <v-stepper-header>
            <v-stepper-step :step="1" editable @click="clearCatalogGroup" class="text-caption">
                {{ catalogGroupTitle }}
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :step="2" :editable="selectedStep > 2" @click="clearCatalogSubGroup" class="text-caption">
                {{ catalogSubgroupTitle }}
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :step="3" class="text-caption">
                {{ $t('Kataloge') }}
            </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
            <v-stepper-content :step="1" class="ma-0 pa-0">
                <v-card>
                    <v-toolbar flat dense dark color="secondary">
                        <v-toolbar-title>{{ $t('Kataloggruppen') }}</v-toolbar-title>
                        <v-spacer />
                        <v-btn icon @click="() => (showCatalogGroupsOptions = !showCatalogGroupsOptions)">
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <List
                            title="Kataloggruppen"
                            :items="getCatalogGroupList"
                            :showAction="false"
                            @input="selectCatalogGroup"
                            :sortArray="catalogSort"
                            :showOptions="showCatalogGroupsOptions"
                        />
                    </v-card-text>
                </v-card>
            </v-stepper-content>
            <v-stepper-content :step="2" class="ma-0 pa-0">
                <v-card>
                    <v-toolbar flat dense dark color="secondary">
                        <v-toolbar-title>{{ $t('Kataloguntergruppen') }}</v-toolbar-title>
                        <v-spacer />
                        <v-btn icon @click="() => (showCatalogSubgroupsOptions = !showCatalogSubgroupsOptions)">
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <List
                            title="Kataloguntergruppen"
                            :items="getCatalogSubGroupList"
                            :showAction="false"
                            @input="selectCatalogSubgroup"
                            :sortArray="catalogSort"
                            :showOptions="showCatalogSubgroupsOptions"
                        />
                    </v-card-text>
                </v-card>
            </v-stepper-content>
            <v-stepper-content :step="3" class="ma-0 pa-0">
                <v-card>
                    <v-toolbar flat dense dark color="secondary">
                        <v-toolbar-title>{{ $t('Kataloge') }}</v-toolbar-title>
                        <v-spacer />
                        <v-btn icon @click="() => (showCatalogsOptions = !showCatalogsOptions)">
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <List title="Kataloge" :items="getCatalogList" :sortArray="catalogSort" :showOptions="showCatalogsOptions" :showAppend="false">
                            <template v-slot:action="{ action }">
                                <v-badge overlap :content="action.totalKnowledgeUnits">
                                    <v-checkbox
                                        v-model="computedCatalogs"
                                        :value="action"
                                        :value-comparator="compareCatalogs"
                                        :disabled="disabled"
                                        :false-value="action.id"
                                        :true-value="action"
                                        :key="Math.floor(Math.random() * 256)"
                                        :ripple="false"
                                    />
                                </v-badge>
                            </template>
                        </List>
                    </v-card-text>
                </v-card>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</template>

<script>
import List from '../Generic/List/AdvancedList';
import CatalogSort from '../Authoringtool/Sort/AuthoringToolSort';
import AuthoringtoolMixin from '../Authoringtool/Mixins/AuthoringtoolMixin';

export default {
    name: 'archive-catalog-select',
    mixins: [AuthoringtoolMixin],
    components: { List },
    data: () => ({
        selectedStep: 1,
        selectedCatalogGroup: {},
        selectedCatalogSubgroup: {},
        catalogSort: CatalogSort,
        showCatalogGroupsOptions: false,
        showCatalogSubgroupsOptions: false,
        showCatalogsOptions: false
    }),
    props: {
        value: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedCatalogs: {
            get() {
                return this.value;
            },
            set(val) {
                switch (typeof val) {
                    case 'object':
                        return this.$emit('input', val);

                    case 'string':
                        return this.$emit(
                            'input',
                            this.value.filter((item) => item.id !== val)
                        );
                }
            }
        },
        catalogGroupTitle() {
            if (this.selectedCatalogGroup.hasOwnProperty('name')) {
                return this.selectedCatalogGroup.name;
            }
            return this.$t('Kataloggruppen');
        },
        catalogSubgroupTitle() {
            if (this.selectedCatalogSubgroup.hasOwnProperty('name')) {
                return this.selectedCatalogSubgroup.name;
            }
            return this.$t('Kataloguntergruppen');
        }
    },
    methods: {
        clearCatalogGroup() {
            this.selectedCatalogGroup = {};
            this.selectedCatalogSubgroup = {};
        },
        clearCatalogSubGroup() {
            this.selectedCatalogSubgroup = {};
        },
        compareCatalogs(a, b) {
            if (typeof a === 'string' && typeof b === 'string') {
                return a === b;
            }
            if (typeof a === 'string' && typeof b === 'object') {
                return a === b.id;
            }
            if (typeof a === 'object' && typeof b === 'string') {
                return a.id === b;
            }
            return a.id === b.id;
        },
        selectCatalogGroup(group) {
            this.GET_CATALOGSUBGROUP_LIST(group).then(() => {
                this.selectedStep = 2;
                this.selectedCatalogGroup = group;
                this.selectedCatalogSubgroup = {};
            });
        },
        selectCatalogSubgroup(subgroup) {
            this.GET_CATALOG_LIST({
                catalogGroupId: this.selectedCatalogGroup.id,
                catalogSubgroupId: subgroup.id
            }).then(() => {
                this.selectedStep = 3;
                this.selectedCatalogSubgroup = subgroup;
            });
        }
    },
    mounted() {
        this.GET_CATALOGGROUP_LIST();
    }
};
</script>
