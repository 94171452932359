import axios from '../../plugins/Axios/axios';
import Vue from 'vue';

const state = {
    knowledgeContentMap: {},
    view: {
        component: '',
        type: '',
        data: {}
    }
};

// getters
const getters = {
    getKnowledgeContents(state) {
        const contents = Object.values(state.knowledgeContentMap)
            .map((item) => item.contents)
            .flat(1);
        contents.sort((a, b) =>
            a.title.localeCompare(b.title, undefined, {
                sensitivity: 'base',
                numeric: true
            })
        );
        return contents;
    },
    getView(state) {
        return state.view;
    },
    getMapData(state) {
        return (id) => {
            if (state.knowledgeContentMap.hasOwnProperty(id)) {
                return state.knowledgeContentMap[id];
            }
            return { folder: {}, folders: [], contents: [] };
        };
    },
    getParent(state, getters) {
        const findParent = (nodes, id) => {
            const parent = nodes.find((node) => {
                if (node && !!node.children && node.children.length > 0) {
                    return node.children.map(({ id }) => id).includes(id);
                }
            });
            if (!parent) {
                const hasChildren = nodes
                    .filter((node) => !!node.children && node.children.length > 0)
                    .map((node) => node.children)
                    .filter((node) => node.length > 0)
                    .flat();
                if (hasChildren.length > 0) {
                    return findParent(hasChildren, id);
                } else {
                    return undefined;
                }
            }
            return parent;
        };

        return ({ id }) => {
            return findParent(getters.getTree, id);
        };
    },
    getTree(state, getters) {
        const buildTree = (nodes) => {
            if (nodes.folders) {
                return nodes.folders
                    .map((node) => {
                        const nextGen = getters.getMapData(node.id);
                        const children = [
                            ...buildTree(nextGen),
                            ...nextGen.contents.sort((a, b) =>
                                a.title.localeCompare(b.title, undefined, {
                                    numeric: true,
                                    sensitivity: 'base'
                                })
                            )
                        ];
                        return { ...node, children };
                    })
                    .sort((a, b) =>
                        a.name.localeCompare(b.name, undefined, {
                            numeric: true,
                            sensitivity: 'base'
                        })
                    );
            }
            return [];
        };
        const root = getters.getMapData('root');
        if (root && root.contents && root.folders) {
            return [...buildTree(root), ...root.contents];
        }
        return [];
    }
};

// actions
const actions = {
    FIND_ITEM_IN_TREE({ getters }, { id }) {
        const traverse = (tree) => {
            return tree.reduce((acc, curr) => {
                if (curr.id === id) {
                    return curr;
                }
                if (curr.children && curr.children.length > 0) {
                    return traverse(curr.children);
                }
                return acc;
            }, {});
        };
        return traverse(getters.getTree);
    },
    SET_VIEW({ commit }, view) {
        commit('updateView', view);
    },
    GET_KNOWLEDGE_CONTENTS({ commit }) {
        return axios
            .get(`/knowledgecontents/list`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateKnowledgeContentMap', response);
                return response;
            });
    },
    GET_KNOWLEDGE_CONTENTS_IN_FOLDER({ commit }, { folderId = '' }) {
        return axios
            .get(`/knowledgecontentfolders/${folderId}/knowledgecontents`)
            .then((response) => response.data.data)
            .then((response) => {
                return response;
            });
    },
    GET_KNOWLEDGE_CONTENT({ commit }, id) {
        return axios
            .get(`/knowledgecontents/${id}`)
            .then((response) => response.data.data)
            .then((response) => {
                return response;
            });
    },
    CREATE_KNOWLEDGE_CONTENT({ commit, getters }, { folderId, ...knowledgeContent }) {
        return axios
            .post(`/knowledgecontents`, { ...knowledgeContent, folderId })
            .then((response) => response.data.data)
            .then((response) => {
                const mapData = folderId == '' ? getters.getMapData('root') : getters.getMapData(folderId);
                commit('setKnowledgeContentMapData', {
                    [folderId === '' ? 'root' : folderId]: {
                        ...mapData,
                        contents: [...mapData.contents, response]
                    }
                });
                return response;
            });
    },
    UPDATE_KNOWLEDGE_CONTENT({ commit, getters }, { id, folderId, ...knowledgeContent }) {
        return axios
            .patch(`/knowledgecontents/${id}`, knowledgeContent)
            .then((response) => response.data.data)
            .then((response) => {
                const mapData = folderId === '' ? getters.getMapData('root') : getters.getMapData(folderId);
                commit('setKnowledgeContentMapData', {
                    [folderId === '' ? 'root' : folderId]: {
                        ...mapData,
                        contents: mapData.contents.map((content) => {
                            if (content.id === response.id) {
                                return response;
                            }
                            return content;
                        })
                    }
                });
                return response;
            });
    },
    MOVE_KNOWLEDGE_CONTENT({ dispatch }, { source: { id: sourceContentId }, target: { id: targetFolderId } }) {
        return axios
            .patch(`/knowledgecontents/${sourceContentId}/changefolder`, {
                folderId: targetFolderId
            })
            .then((response) => response.data.data)
            .then((response) => dispatch('GET_KNOWLEDGE_CONTENTS').then(() => dispatch('FIND_ITEM_IN_TREE', response)));
    },
    COPY_KNOWLEDGE_CONTENT({ dispatch }, { source: { id: knowledgeContentId }, target: { id: folderId } }) {
        return axios
            .patch(`/knowledgecontents/${knowledgeContentId}/copy`, {
                folderId
            })
            .then((response) => response.data.data)
            .then((response) => dispatch('GET_KNOWLEDGE_CONTENTS').then(() => dispatch('FIND_ITEM_IN_TREE', response)));
    },
    DELETE_KNOWLEDGE_CONTENT({ dispatch }, { id }) {
        return axios.delete(`/knowledgecontents/${id}`).then(() => dispatch('GET_KNOWLEDGE_CONTENTS'));
    },
    CREATE_KNOWLEDGE_CONTENT_FOLDER({ commit, getters }, request) {
        return axios
            .post(`/knowledgecontentfolders`, request)
            .then((response) => response.data.data)
            .then((response) => {
                if (response.parentId === '') {
                    const mapData = getters.getMapData('root');
                    commit('setKnowledgeContentMapData', {
                        root: {
                            ...mapData,
                            folders: [...mapData.folders, response]
                        }
                    });
                } else {
                    const mapData = getters.getMapData(response.parentId);
                    commit('setKnowledgeContentMapData', {
                        [response.parentId]: {
                            ...mapData,
                            folders: [...mapData.folders, response]
                        }
                    });
                }
                return response;
            });
    },
    UPDATE_KNOWLEDGE_CONTENT_FOLDER({ commit, getters }, { id, ...folder }) {
        return axios
            .patch(`/knowledgecontentfolders/${id}`, folder)
            .then((response) => response.data.data)
            .then((response) => {
                const isRoot = response.parentId === '';
                const mapData = isRoot ? getters.getMapData('root') : getters.getMapData(response.parentId);
                commit('setKnowledgeContentMapData', {
                    [isRoot ? 'root' : response.parentId]: {
                        ...mapData,
                        folders: mapData.folders.map((folder) => {
                            if (folder.id === response.id) {
                                return response;
                            }
                            return folder;
                        })
                    }
                });
                return response;
            });
    },
    MOVE_KNOWLEDGE_CONTENT_FOLDER({ dispatch }, { source: { id: sourceId }, target: { id: targetId } }) {
        return axios
            .patch(`/knowledgecontentfolders/${sourceId}`, {
                parentId: targetId
            })
            .then((response) => response.data.data)
            .then((response) => dispatch('GET_KNOWLEDGE_CONTENTS').then(() => dispatch('FIND_ITEM_IN_TREE', response)));
    },
    DELETE_KNOWLEDGE_CONTENT_FOLDER({ dispatch }, { id }) {
        return axios.delete(`/knowledgecontentfolders/${id}`).then(() => dispatch('GET_KNOWLEDGE_CONTENTS'));
    },
    UPDATE_KNOWLEDGE_CONTENT_STATE({ commit, getters }, { id: knowledgeContentId, state, folderId }) {
        return axios
            .patch(`knowledgecontents/${knowledgeContentId}/changestate`, {
                state
            })
            .then((response) => response.data.data)
            .then((response) => {
                const mapData = folderId === '' ? getters.getMapData('root') : getters.getMapData(folderId);
                commit('setKnowledgeContentMapData', {
                    [folderId === '' ? 'root' : folderId]: {
                        ...mapData,
                        contents: mapData.contents.map((content) => {
                            if (content.id === response.id) {
                                return response;
                            }
                            return content;
                        })
                    }
                });
                return response;
            });
    }
};

// mutations
const mutations = {
    clear: (state) => {
        state.knowledgeContentMap = {};
        state.view = {
            component: '',
            type: '',
            data: {}
        };
    },
    updateView: (state, view) => {
        state.view = view;
    },
    setKnowledgeContentMapData: (state, data) => {
        const [key, value] = Object.entries(data)[0];
        Vue.set(state.knowledgeContentMap, key, value);
    },
    updateKnowledgeContentMap: (state, { folders, knowledgeContents }) => {
        const uniqueFolders = [...new Set(folders.map(({ id }) => id))];

        let map = uniqueFolders.reduce((acc, curr) => {
            acc[curr] = {
                folder: folders.find((folder) => folder.id === curr),
                folders: folders.filter((folder) => folder.parentId === curr),
                contents: knowledgeContents.filter((content) => content.folderId === curr)
            };
            return acc;
        }, {});
        map['root'] = {
            folder: {},
            folders: folders.filter((folder) => folder.parentId === ''),
            contents: knowledgeContents.filter((content) => content.folderId === '')
        };
        Vue.set(state, 'knowledgeContentMap', map);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
