<template>
    <v-card text height="100%" style="overflow: auto">
        <v-toolbar flat dense text color="transparent" v-show="getView.type !== ''">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn medium icon @click="reset" v-on="on">
                        <v-icon medium>mdi-chevron-left</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('Zurück') }}</span>
            </v-tooltip>
            <v-toolbar-title class="text-body-2">{{ typeTitle }}</v-toolbar-title>
        </v-toolbar>
        <keep-alive>
            <component
                :is="componentSwitch"
                :value="value"
                :type="getView.type"
                :onSubmit="onSubmit"
                @switchView="handleSwitchView"
                @input="handleInput"
                :search="search"
                @openTree="handleOpenTree"
                :permissions="permissions"
                @onCreate="handleOnCreate"
                @onUpdate="handleOnUpdate"
            />
        </keep-alive>
    </v-card>
</template>
<script>
import KnowledgeContentContentView from './KnowledgeContentContentView';
import KnowledgeContentFolderView from './KnowledgeContentFolderView';
import AddUpdateKnowledgeContent from '../AddUpdateKnowledgeContent';
import KnowledgeContentDeleteConfirm from './KnowledgeContentDeleteConfirm';
import AddUpdateKnowledgeContentFolder from '../AddUpdateKnowledgeContentFolder';
import KnowledgeContentMoveView from './KnowledgeContentMoveView';
import KnowledgeContentCopyView from './KnowledgeContentCopyView';
import KnowledgeContentFolderMoveView from './KnowledgeContentFolderMoveView';

import KnowledgeContentMixin from '../Mixins/KnowledgeContentMixin';

export default {
    name: 'knowledge-content-view',
    components: {
        KnowledgeContentContentView,
        KnowledgeContentFolderView,
        KnowledgeContentMoveView,
        AddUpdateKnowledgeContent,
        KnowledgeContentDeleteConfirm,
        AddUpdateKnowledgeContentFolder,
        KnowledgeContentFolderMoveView,
        KnowledgeContentCopyView
    },
    mixins: [KnowledgeContentMixin],
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        search: {
            type: String,
            default: ''
        },
        permissions: {
            type: Object,
            default: () => ({
                add: false,
                update: false,
                delete: false,
                change: false
            })
        }
    },
    computed: {
        componentSwitch() {
            if (this.getView.component !== '') {
                switch (this.getView.component) {
                    case 'CREATE_KNOWLEDGE_CONTENT':
                        return AddUpdateKnowledgeContent;
                    case 'UPDATE_KNOWLEDGE_CONTENT':
                        return AddUpdateKnowledgeContent;
                    case 'MOVE_KNOWLEDGE_CONTENT':
                        return KnowledgeContentMoveView;
                    case 'COPY_KNOWLEDGE_CONTENT':
                        return KnowledgeContentCopyView;
                    case 'DELETE_KNOWLEDGE_CONTENT':
                        return KnowledgeContentDeleteConfirm;
                    case 'CREATE_FOLDER':
                        return AddUpdateKnowledgeContentFolder;
                    case 'UPDATE_FOLDER':
                        return AddUpdateKnowledgeContentFolder;
                    case 'MOVE_FOLDER':
                        return KnowledgeContentFolderMoveView;
                    case 'DELETE_FOLDER':
                        return KnowledgeContentDeleteConfirm;
                }
            }
            switch (this.isKnowledgeContentType(this.value)) {
                case 'KNOWLEDGE_CONTENT':
                    return KnowledgeContentContentView;
                case 'FOLDER':
                    return KnowledgeContentFolderView;
            }
            return KnowledgeContentFolderView;
        },
        typeTitle() {
            switch (this.getView.component) {
                case 'CREATE_KNOWLEDGE_CONTENT':
                    return this.$t('Content erstellen');
                case 'UPDATE_KNOWLEDGE_CONTENT':
                    return this.$t('Content bearbeiten');
                case 'MOVE_KNOWLEDGE_CONTENT':
                    return this.$t('Content verschieben');
                case 'COPY_KNOWLEDGE_CONTENT':
                    return this.$t('Content kopieren');
                case 'DELETE_KNOWLEDGE_CONTENT':
                    return this.$t('Content löschen');
                case 'CREATE_FOLDER':
                    return this.$t('Ordner erstellen');
                case 'UPDATE_FOLDER':
                    return this.$t('Ordner bearbeiten');
                case 'MOVE_FOLDER':
                    return this.$t('Ordner verschieben');
                case 'DELETE_FOLDER':
                    return this.$t('Ordner löschen');
            }
            return '';
        }
    },
    methods: {
        handleOnCreate(data) {
            this.$emit('onCreate', data);
        },
        handleOnUpdate(data) {
            this.$emit('onUpdate', data);
        },
        onSubmit() {
            switch (this.getView.component) {
                case 'DELETE_KNOWLEDGE_CONTENT':
                    return (v) =>
                        this.DELETE_KNOWLEDGE_CONTENT(v)
                            .then(() => {
                                this.$emit('input', {});
                                this.$emit('onDelete', v);
                            })
                            .then(() => this.reset());
                case 'DELETE_FOLDER':
                    return (v) =>
                        this.DELETE_KNOWLEDGE_CONTENT_FOLDER(v)
                            .then(() => this.$emit('input', {}))
                            .then(() => this.reset());
                case 'CREATE_FOLDER':
                    return (v) =>
                        this.CREATE_KNOWLEDGE_CONTENT_FOLDER(v)
                            .then((response) => this.$emit('input', response))
                            .then(() => this.reset());
                case 'UPDATE_FOLDER':
                    return (v) =>
                        this.UPDATE_KNOWLEDGE_CONTENT_FOLDER(v)
                            .then((response) => this.$emit('input', response))
                            .then(() => this.reset());
                case 'MOVE_KNOWLEDGE_CONTENT':
                    return (v) =>
                        this.MOVE_KNOWLEDGE_CONTENT(v)
                            .then((response) => this.$emit('input', response))
                            .then(() => this.reset());
                case 'MOVE_FOLDER':
                    return (v) =>
                        this.MOVE_KNOWLEDGE_CONTENT_FOLDER(v)
                            .then((response) => this.$emit('input', response))
                            .then(() => this.reset());
            }
            return () => {};
        },
        reset() {
            this.SET_VIEW({ view: '', data: {}, type: '' });
        },
        handleInput(value) {
            this.$emit('input', value);
        },
        handleOpenTree(value) {
            this.$emit('openTree', value);
        },
        handleSwitchView(value) {
            this.SET_VIEW(value);
        }
    }
};
</script>
