<template>
    <v-card-text>
        <v-container>
            <v-row>
                <v-col :cols="getColumn(index)" v-for="(option, index) in optionsComputed" :key="`option-${option.id}`">
                    <OptionNode
                        :value="option"
                        :selected="selectedOptions"
                        @selected="(val) => (selectedOptions = val)"
                        @media="mediaEmit"
                        :disableInteraction="disableInteraction"
                    />
                </v-col>
            </v-row>
        </v-container>
    </v-card-text>
</template>

<script>
import OptionNode from '../Helpers/OptionNode';

export default {
    name: 'single-select-option',
    components: {
        OptionNode
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        valid: {
            type: Boolean,
            default: false
        },
        disableInteraction: {
            default: false,
            type: Boolean
        }
    },
    computed: {
        optionsComputed() {
            return this.value && this.value.knowledgeUnitOptions ? this.value.knowledgeUnitOptions : [];
        },
        selectedOptions: {
            get() {
                const find = this.optionsComputed.find((option) => option.selected);
                this.$emit('valid', !!find);
                return find ? find : {};
            },
            set(value) {
                this.$emit(
                    'input',
                    this.optionsComputed.map((option) => {
                        if (option.id === value.id) {
                            return { ...option, selected: true };
                        }
                        return { ...option, selected: false };
                    })
                );
            }
        }
    },
    methods: {
        getColumn(index) {
            if (index === this.optionsComputed.length - 1 && index % 2 === 0) {
                return 12;
            }
            return 6;
        },
        mediaEmit(val) {
            this.$emit('media', val);
        }
    },
    activated() {
        this.$emit('valid', false);
    },
    mounted() {
        this.$emit('valid', false);
    }
};
</script>
