<template>
    <div ref="container">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'resize-container',
    data: () => ({
        observer: null
    }),
    props: {
        value: {
            type: Object,
            default: () => ({
                height: 0,
                deltaHeight: 0,
                width: 0,
                deltaWidth: 0
            })
        },
        milliseconds: {
            type: Number,
            default: 500
        }
    },
    methods: {
        destroy() {
            this.observer.disconnect();
            this.observer = null;
        },
        tally(entry) {
            this.$emit('input', this.serialize(entry));
        },
        serialize({ contentRect: { height, width } }) {
            return {
                height,
                deltaHeight: parseInt(Math.abs(this.value.height - height)),
                width,
                deltaWidth: parseInt(Math.abs(this.value.width - width))
            };
        },
        throttle(f) {
            let timer = 0;
            return function (...args) {
                clearTimeout(timer);
                timer = setTimeout(function () {
                    f.apply(null, args);
                }, this.milliseconds);
            };
        },
        setup() {
            this.observer = new ResizeObserver(
                this.throttle((entries) => {
                    if (entries.length > 0) {
                        this.tally(entries[0]);
                    }
                })
            );
            this.observer.observe(this.$refs.container);
        }
    },
    mounted() {
        this.setup();
    },
    beforeDestroy() {
        this.destroy();
    }
};
</script>
