import { render, staticRenderFns } from "./GroupForumThreads.vue?vue&type=template&id=287e2a5e&"
import script from "./GroupForumThreads.vue?vue&type=script&lang=js&"
export * from "./GroupForumThreads.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports