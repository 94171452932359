import axios from '../../plugins/Axios/axios';

const state = {
    isDrawerOpened: false,
    favouriteMap: {},
    hiddenMap: {}
};

const getters = {
    getDrawerState() {
        return state.isDrawerOpened;
    },
    getFavouriteMap() {
        return state.favouriteMap;
    },
    getHiddenMap() {
        return state.hiddenMap;
    }
};

const actions = {
    OPEN_DRAWER({ commit }, value) {
        commit('openDrawer', value);
    },
    GET_FAVOURITE_MAP({ commit }) {
        return axios
            .get(`/config/menu`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateFavouriteMap', response.config);
                commit('updateHiddenMap', response.hidden);
                return response.config;
            });
    },
    SAVE_FAVOURITE_MAP({ commit }, request) {
        return axios
            .post(`/config/menu`, { config: request })
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateFavouriteMap', response.config);
                commit('updateHiddenMap', response.hidden);
                return response.config;
            });
    }
};

const mutations = {
    clear: (state) => {
        state.isDrawerOpened = false;
        state.favouriteMap = {};
    },
    updateFavouriteMap: (state, map) => {
        state.favouriteMap = map;
    },
    updateHiddenMap: (state, map) => {
        state.hiddenMap = map;
    },
    openDrawer: (state, value) => {
        state.isDrawerOpened = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
