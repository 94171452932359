<template>
    <v-container>
        <v-row>
            <v-btn-toggle mandatory v-model="computedState" class="flex wrap elevation-0 layout">
                <v-col :cols="4">
                    <v-btn
                        :class="{ 'white--text': computedState === 'APPROVED', 'ma-0': true }"
                        :text="computedState !== 'APPROVED'"
                        block
                        :disabled="!(changeState || computedState === 'APPROVED')"
                        color="success"
                        value="APPROVED"
                        >{{ $t('Genehmigt') }}</v-btn
                    >
                </v-col>
                <v-col :cols="4">
                    <v-btn
                        :class="{ 'white--text': computedState === 'PENDING', 'ma-0': true }"
                        block
                        :text="computedState !== 'PENDING'"
                        :disabled="!(changeState || computedState === 'PENDING')"
                        color="warning"
                        value="PENDING"
                        >{{ $t('Ausstehend') }}</v-btn
                    >
                </v-col>
                <v-col :cols="4">
                    <v-btn
                        :class="{ 'white--text': computedState === 'DECLINED', 'ma-0': true }"
                        :text="computedState !== 'DECLINED'"
                        block
                        :disabled="!(changeState || computedState === 'DECLINED')"
                        color="error"
                        value="DECLINED"
                        >{{ $t('Abgelehnt') }}</v-btn
                    >
                </v-col>
            </v-btn-toggle>
        </v-row>
        <v-flex xs12 v-if="showMessage">
            <v-container pa-2>
                <v-row>
                    <v-col :cols="10">
                        <v-form v-model="valid" ref="form">
                            <v-text-field :label="$t('Grund für das Ablehnen')" v-model="value.stateMessage" persistent-hint :rules="[notEmpty]" />
                        </v-form>
                    </v-col>
                    <v-col :cols="2">
                        <v-card-actions>
                            <v-btn block color="success" :disabled="!valid" @click="submit">
                                {{ $t('Speichern') }}
                            </v-btn>
                        </v-card-actions>
                    </v-col>
                </v-row>
            </v-container>
        </v-flex>
    </v-container>
</template>

<script>
export default {
    name: 'new-state-selector',
    data: () => ({
        changeState: false,
        valid: false,
        incomingState: ''
    }),
    props: {
        value: {
            type: Object,
            default: () => ({
                stateName: 'PENDING',
                stateMessage: ''
            })
        },
        permissions: {
            type: Object,
            default: () => ({})
        },
        onChange: {
            type: Function,
            default: () => {}
        },
        context: {
            type: String,
            default: 'ADDUPDATE'
        }
    },
    computed: {
        computedState: {
            get() {
                return this.value && this.value.stateName ? this.value.stateName : '';
            },
            set(value) {
                this.incomingState = value;
                if (value !== 'DECLINED') {
                    this.onChange({ state: value });
                    this.$emit('input', { state: value });
                }
            }
        },
        showMessage() {
            return this.value.stateName == 'DECLINED' || this.incomingState == 'DECLINED';
        }
    },
    methods: {
        notEmpty(value) {
            return (!!value && value.trim() !== '' && value.trim().length > 0) || this.$t('Das Feld darf nicht leer sein');
        },
        submit() {
            this.onChange({ state: this.incomingState ? this.incomingState : this.value.stateName, message: this.value.stateMessage });
            this.incomingState = '';
        }
    },
    mounted() {
        this.validateEditorState(this.permissions).then((response) => {
            this.changeState =
                Object.values(response).reduce((result, next) => result && next, true) ||
                this.getLoggedInAdmin.role == 'ROOT' ||
                this.getLoggedInAdmin.role == 'SALES';
        });
    }
};
</script>
