<template>
    <div>
        <Toolbar :tooltipMessage="$t('Hier können Sie Datenschutz lesen.')" :toolbarTitle="$t('Datenschutz')" />
        <v-card>
            <v-card-text class="ck ck-content" v-html="getDataProtection" />
        </v-card>
    </div>
</template>

<script>
import Toolbar from '../../Components/Generic/Toolbar';
import { mapGetters } from 'vuex';

export default {
    name: 'data-protection',
    components: {
        Toolbar
    },
    data: () => ({}),
    methods: {},
    computed: {
        ...mapGetters('SystemSettings', ['getDataProtection']),
        translatedTitle() {
            return this.$t(this.toolbarTitle);
        }
    }
};
</script>
