<template>
    <v-card-text>
        <v-data-iterator
            :items="knowledgeUnitOptions"
            :items-per-page="-1"
            :footer-props="{
                'items-per-page-options': [{ text: $t('Alle'), value: -1 }]
            }"
            :no-data-text="$t('Keine Optionen verfügbar')"
            :no-results-text="$t('Kein Ergebnis')"
            :hideDefaultFooter="true"
        >
            <template #default="{ items }">
                <v-list>
                    <v-list-item v-for="item in items" :key="item.id">
                        <v-list-item-action>
                            <v-icon color="success">mdi-check</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            {{ item.text }}
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
        </v-data-iterator>
    </v-card-text>
</template>

<script>
export default {
    name: 'fill-blank-result',
    components: {},
    props: {
        knowledgeUnitOptions: {
            type: Array,
            default: () => []
        }
    }
};
</script>
