<template>
    <div>
        <v-container>
            <v-row justify="center">
                <v-col cols="auto">
                    <v-hover>
                        <ImageLoader :src="getLoggedInAdmin.profileImage" :height="200" :width="300" slot-scope="{ hover }">
                            <v-scale-transition>
                                <div v-if="hover" v-show="$vuetify.breakpoint.mdAndUp">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" color="error" @click="deleteImage" icon>
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('delete') }}</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" color="primary" @click="pickImage" icon>
                                                <v-icon>mdi-folder</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('UPLOAD') }}</span>
                                    </v-tooltip>
                                </div>
                            </v-scale-transition>
                            <v-row fill-height v-show="$vuetify.breakpoint.xs" class="justify-center">
                                <v-btn @click="expand = !expand" dark class="secondary" icon>
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <v-col shrink style="max-width: 75%">
                                    <v-scale-transition>
                                        <div v-show="expand">
                                            <v-btn color="error" @click="deleteImage" icon>
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                            <v-btn color="primary" @click="pickImage" icon>
                                                <v-icon>mdi-folder</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-scale-transition>
                                </v-col>
                            </v-row>
                        </ImageLoader>
                    </v-hover>
                </v-col>
            </v-row>
        </v-container>

        <v-row justify="center">
            <v-card flat>
                <span class="headline font-weight-medium">
                    {{ getLoggedInAdmin.firstName }}
                    {{ getLoggedInAdmin.lastName }}
                </span>
            </v-card>
        </v-row>
        <v-row justify="center">
            <v-card flat>
                <span class="orange--text font-weight-medium">
                    {{ $t(getLoggedInAdmin.role) }}
                </span>
            </v-card>
        </v-row>
        <v-col cols="12">
            <v-row align="center" justify="center">
                <v-col cols="5">
                    <v-divider />
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12">
            <v-card flat>
                <v-container class="pa-0 ma-0">
                    <v-data-iterator :items="userDetails" hide-default-footer>
                        <template #default="{ items }">
                            <v-row no-gutters>
                                <v-col :cols="6" v-for="item in items" :key="item.id">
                                    <v-list-item>
                                        <v-list-item-action>
                                            <v-icon> {{ item.icon }} </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ item.label }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ item.value }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-iterator>
                </v-container>
            </v-card>
        </v-col>

        <!-- UPLOAD PROFILE PICTURE -->
        <v-dialog v-model="showAddPhoto" :width="$vuetify.breakpoint.thresholds.xs" persistent>
            <v-card>
                <v-toolbar flat dark color="secondary">
                    <v-toolbar-title>{{ $t('Profil Bild hochladen') }}</v-toolbar-title>
                    <v-spacer />
                    <v-btn icon @click="() => (showAddPhoto = !showAddPhoto)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <div>
                        <FilePicker v-model="files" :label="$t('Bild Datei')" accept="image/*" />
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn block :disabled="!hasFile" color="success" @click="uploadProfilePicture">{{ $t('Bild hochladen') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import UserViewsMixins from '../../Users/Mixins/UserViewsMixins';
import ImageLoader from '../../Generic/ImageLoader.vue';
import FilePicker from '../../Generic/FilePicker/FilePicker';
export default {
    components: {
        ImageLoader,
        FilePicker
    },
    mixins: [UserViewsMixins],
    data: () => ({
        expand: false,
        showAddPhoto: false,
        files: {},
        image: {
            imageName: '',
            imageBinary: ''
        }
    }),
    computed: {
        userDetails() {
            const attributes = ['displayName', 'email', 'mobileNo', 'street', 'city', 'state'];
            return Object.entries(this.getLoggedInAdmin)
                .filter(([key, value]) => {
                    return attributes.includes(key);
                })
                .map(([key, value]) => {
                    const label = this.$t(
                        key
                            .split(/(?=[A-Z])/)
                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(' ')
                    );
                    return { label, value, icon: this.userDetailsIcon(key) };
                });
        },
        hasFile() {
            return Object.keys(this.files).length > 0;
        }
    },
    methods: {
        pickImage() {
            this.showAddPhoto = true;
        },
        deleteImage() {
            this.RESET_ADMIN_PROFILEPICTURE_BY_ID(this.getLoggedInAdmin.id);
        },
        userDetailsIcon(key) {
            switch (key) {
                case 'displayName':
                    return 'mdi-account';
                case 'email':
                    return 'mdi-email';
                case 'street':
                    return 'mdi-pine-tree';
                case 'city':
                    return 'mdi-city';
                case 'state':
                    return 'mdi-home-city';
                case 'mobileNo':
                    return 'mdi-cellphone-sound';
            }
            return 'mdi-cancel';
        },
        uploadProfilePicture() {
            this.showAddPhoto = false;
            if (this.hasFile) {
                if (Object.keys(this.files).length > 0) {
                    this.image.imageName = this.files[0].name;
                    this.image.imageBinary = this.files[0];
                    this.uploadImage(this.image);
                } else {
                    this.image.imageName = '';
                    this.image.imageBinary = '';
                }
            }
        },
        uploadImage(image) {
            var dataToSend = {
                data: {
                    file: image.imageBinary
                },
                adminId: this.getLoggedInAdmin.id
            };
            this.UPDATE_ADMIN_PROFILEPICTURE(dataToSend).then(() => {
                this.files = {};
                this.GET_ADMIN_PROFILEPICTURE_BY_ID(this.getLoggedInAdmin.id).then(() => {});
            });
        }
    },
    mounted() {
        this.GET_USER_MAIL_OPTIONS();
    }
};
</script>
