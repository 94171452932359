<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon color="accent--text" @click.stop="handleClick">
                <v-icon>{{ favouriteIcon }}</v-icon>
            </v-btn>
        </template>
        <span>{{ $t('Favorisieren') }}</span>
    </v-tooltip>
</template>

<script>
import ChatMixin from './Mixins/ChatMixin';

export default {
    name: 'chat-favourite-user-menu',
    mixins: [ChatMixin],
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    computed: {
        isFavourite() {
            return this.getFavouriteUsers.includes(this.value);
        },
        favouriteIcon() {
            return this.isFavourite ? 'mdi-star' : 'mdi-star-outline';
        }
    },
    methods: {
        handleClick() {
            if (this.isFavourite) {
                this.CHAT_SAVE_FAVOURITE_USERS(this.getFavouriteUsers.filter((user) => user !== this.value));
            } else {
                this.CHAT_SAVE_FAVOURITE_USERS([...this.getFavouriteUsers, this.value]);
            }
        }
    }
};
</script>
