<template>
    <v-card flat>
        <v-toolbar dense flat dark color="secondary">
            <v-toolbar-title>{{ $t('Persönliche Nachrichten') }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
                <v-btn icon @click="toggleUserSearch">
                    <v-icon>mdi-magnify</v-icon>
                </v-btn>
                <v-menu :close-on-content-click="false" offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <ChatNotificationsConfig />
                </v-menu>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-data-iterator
                :items="getUserIds"
                :no-data-text="$t('Keine Nutzer verfügbar')"
                :no-results-text="$t('Kein Ergebnis')"
                :hideDefaultFooter="getUserIds.length === 0"
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-text': $t('Nutzer pro Seite'),
                    'items-per-page-options': [5, 10, 15, 20, 25]
                }"
                :custom-filter="filterItems"
                :search="search"
            >
                <template v-slot:header>
                    <v-expand-transition>
                        <v-card text v-show="showSearch">
                            <v-card-text>
                                <v-text-field
                                    prepend-icon="mdi-magnify"
                                    :label="$t('Nutzer durchsuchen')"
                                    :hint="$t('Geben Sie Begriffe oder Schlagwörter ein')"
                                    v-model="search"
                                    color="accent"
                                />
                            </v-card-text>
                        </v-card>
                    </v-expand-transition>
                </template>

                <template #default="{ items }">
                    <v-list dense>
                        <v-list-item-group v-model="getSelectedChatUser.id">
                            <v-list-item v-for="userId in items" :key="userId" :value="userId" @click="handleClick(userId)">
                                <v-list-item-action>
                                    <v-badge :value="getUnreadMessages(userId) !== 0" :content="getUnreadMessages(userId)" color="red" left overlap>
                                        <v-badge bottom overlap color="white">
                                            <template v-slot:badge>
                                                <v-icon small :color="getUser(userId).online ? 'green' : 'red'">mdi-record-circle-outline</v-icon>
                                            </template>
                                            <UserProfile :value="getUser(userId)" :displayName="false" />
                                        </v-badge>
                                    </v-badge>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>{{ getUser(userId).displayName }}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <NotificationMenu :value="userId" :isUser="true" />
                                </v-list-item-action>
                                <v-list-item-action>
                                    <FavouriteMenu :value="userId" />
                                </v-list-item-action>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </template>
            </v-data-iterator>
        </v-card-text>
    </v-card>
</template>

<script>
import ChatMixin from '../Mixins/ChatMixin';
import UserProfile from '../../Generic/Profile/UserProfile';
import NotificationMenu from '../NotificationMenu';
import FavouriteMenu from '../ChatFavouriteUserMenu';
import ChatNotificationsConfig from '../ChatNotificationsConfig';

export default {
    name: 'personal-chat-drawer',
    components: {
        UserProfile,
        NotificationMenu,
        ChatNotificationsConfig,
        FavouriteMenu
    },
    mixins: [ChatMixin],
    data: () => ({
        showActions: false,
        showSearch: false,
        search: ''
    }),
    methods: {
        handleClick(value) {
            this.$emit('input', this.getUser(value));
            this.$emit('selected');
        },
        toggleUserSearch() {
            this.showSearch = !this.showSearch;
        },
        filterItems(items, search) {
            if (search.trim() === '') {
                return items;
            }
            let buildString = search
                .split(' ')
                .filter((word) => word !== '')
                .map((word) => `(?=.*${word})`)
                .join('');
            let regex = new RegExp(buildString, 'i');

            const test = this.getUsers
                .filter((item) => regex.test(item.firstName) || regex.test(item.lastName) || regex.test(item.displayName || regex.test(item.email)))
                .map((user) => user.id);
            return items.filter((item) => test.includes(item));
        }
    }
};
</script>
