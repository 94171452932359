<template>
    <v-dialog v-model="computedDialog" persistent scrollable :width="$vuetify.breakpoint.thresholds.sm">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" block color="primary">{{ $t('Signatur') }}</v-btn>
        </template>
        <v-card>
            <v-toolbar dense dark text color="secondary">
                <v-toolbar-title>{{ $t('Signatur') }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-container v-if="showEditor">
                <v-row no-gutters>
                    <v-col :cols="12">
                        <div style="overflow-x: hidden; overflow-y: hidden">
                            <Editor v-model="signature" />
                        </div>
                    </v-col>
                </v-row>
            </v-container>
            <v-card-actions>
                <v-btn block color="success" @click="save">
                    {{ $t('Speichern') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Editor from '../Generic/TextEditor';
import SystemSettingsMixin from './Mixins/SystemSettingsMixin';

export default {
    name: 'add-update-signature',
    mixins: [SystemSettingsMixin],
    components: { Editor },
    data: () => ({
        id: '',
        signature: '',
        animationTimer: null,
        showEditor: false
    }),
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        value(value) {
            this.animationTimer = setTimeout(() => {
                this.showEditor = true;
            }, 50);
            if (value) {
                this.GET_SIGNATURE().then(({ signature, id }) => {
                    this.id = id;
                    this.signature = signature;
                });
            } else {
                this.reset();
            }
        }
    },
    computed: {
        computedDialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        reset() {
            this.showEditor = false;
            clearTimeout(this.animationTimer);
            this.animationTimer = null;
            this.id = '';
            this.signature = '';
        },
        close() {
            this.computedDialog = false;
        },
        save() {
            this.UPDATE_SIGNATURE({
                ...(this.id !== '' ? { id: this.id } : {}),
                signature: this.signature
            }).then(() => this.close());
        }
    }
};
</script>
