<template>
    <v-card text height="100%">
        <v-toolbar flat dense color="transparent">
            <v-spacer />
            <v-toolbar-items>
                <CatalogMaterial :catalog="getSelectedCatalog.data" />
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon @click="create({})" :disabled="!permissions.create" color="success">
                            <v-icon medium>mdi-file-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Wissenseinheit erstellen') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon @click="editCatalog()" :disabled="!permissions.update" color="warning">
                            <v-icon medium>mdi-folder-edit</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Katalog bearbeiten') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon @click.stop="copyCatalog()" :disabled="!permissions.copy" color="accent">
                            <v-icon medium>mdi-folder-multiple</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Katalog kopieren') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon @click.stop="moveCatalog()" :disabled="!permissions.move" color="accent">
                            <v-icon medium>mdi-folder-move</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Katalog verschieben') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" medium icon @click="removeCatalog()" :disabled="!permissions.delete" color="error">
                            <v-icon medium>mdi-folder-remove</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Katalog löschen') }}</span>
                </v-tooltip>
            </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-container fluid>
            <v-text-field :label="$t('Wissenseinheiten Suchen')" hide-details v-model="search" />
        </v-container>
        <v-divider />
        <v-data-table
            :headers="headers"
            :items="getKnowledgeUnits(value.id)"
            :no-data-text="$t('keine Wissenseinheiten gefunden')"
            :no-results-text="$t('kein Ergebnis')"
            :items-per-page="5"
            :footer-props="{
                itemsPerPageText: $t('Wissenseinheiten pro Seite'),
                'items-per-page-options': [5, 10, 15, { text: $t('Alle'), value: -1 }]
            }"
            :search="search"
            :custom-filter="filter"
            item-key="id"
            sort-by="order"
            single-expand
        >
            <template v-slot:item="{ item, isExpanded, expand }">
                <tr @click="expandRow(isExpanded, expand)" class="pointer">
                    <td>
                        <v-row justify="center">
                            <v-col :cols="6">
                                <v-menu offset-x right :close-on-content-click="false" :value="getMenu(item)" @input="setMenu(item, $event)">
                                    <template v-slot:activator="{ on }">
                                        <v-btn @click.stop v-on="on" dark color="primary" icon>
                                            <span>{{ item.order }}</span>
                                        </v-btn>
                                    </template>
                                    <v-card :width="$vuetify.breakpoint.thresholds.xs / 2">
                                        <v-card-text>
                                            <v-form :value="getValid(item)" @input="setValid(item, $event)">
                                                <v-text-field
                                                    :value="getOrder(item)"
                                                    @input="setOrder(item, $event)"
                                                    :label="$t('Reihenfolge')"
                                                    :hint="$t('Geben Sie die Reihenfolge an, in die Sie die Wissenseinheit schieben wollen')"
                                                    :rules="orderRules"
                                                    persistent-hint
                                                    type="number"
                                                    min="1"
                                                />
                                            </v-form>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn block color="success" @click.stop="changeOrder(item)" :disabled="!getValid(item)">
                                                {{ $t('Speichern') }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </td>
                    <td>
                        <span class="no-highlight" v-html="truncateQuestion(item)" />
                    </td>
                    <td>
                        <v-row>
                            <v-col :cols="12">
                                <v-chip class="no-highlight" @click.prevent @mousedown.prevent dark :color="questionTypeColor(item)">
                                    {{ $t(item.questionType) }}
                                </v-chip>
                            </v-col>
                        </v-row>
                    </td>
                    <td>
                        <v-row align="end">
                            <v-col>
                                <v-menu :value="getExtendedMenu(item)" @input="setExtendedMenu(item, $event)" offset-x left :close-on-content-click="false">
                                    <template v-slot:activator="{ on }">
                                        <v-btn @click.stop v-on="on" icon>
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item @click.stop="update(item)" :disabled="!permissions.update">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ $t('Bearbeiten') }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item @click.stop="copy(item)" :disabled="!permissions.copy">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ $t('Kopieren') }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item @click.stop="move(item)" :disabled="!permissions.move">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ $t('Verschieben') }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item @click.stop="preview(item)">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ $t('Vorschau') }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider />
                                        <v-list-item @click.stop="remove(item)" :disabled="!permissions.delete">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ $t('Löschen') }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </td>
                </tr>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <v-card text>
                        <v-tabs v-model="tabs">
                            <v-tab key="KNOWLEDGE_BASE">{{ $t('Wissensbasis') }}</v-tab>
                            <v-tab key="KNOWLEDGE_EXPLANATION">{{ $t('Erklärung') }}</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tabs">
                            <v-tab-item key="KNOWLEDGE_BASE">
                                <v-data-iterator
                                    :items="item.knowledgeBase.knowledgeContents"
                                    :no-data-text="$t('Kein Content verfügbar')"
                                    :no-results-text="$t('Kein Ergebnis')"
                                    :items-per-page="5"
                                    :footer-props="{
                                        itemsPerPageText: $t('Content pro Seite'),
                                        'items-per-page-options': [5, 10, 15, { text: $t('Alle'), value: -1 }]
                                    }"
                                    :hideDefaultFooter="item.knowledgeBase.knowledgeContents.length <= 5"
                                >
                                    <template v-slot:item="{ item: content }">
                                        <v-list-item>
                                            <v-list-item-icon>
                                                <v-btn small icon @click="handleKnowledgeContent(item.knowledgeBase, content, 'Wissensbasis')">
                                                    <v-icon :color="contentColor(content)">mdi-file</v-icon>
                                                </v-btn>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title v-html="limitCharacters(content)" />
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-data-iterator>
                            </v-tab-item>
                            <v-tab-item key="KNOWLEDGE_EXPLANATION">
                                <v-textarea
                                    :value="item.knowledgeExplanation.message"
                                    readonly
                                    hide-details
                                    outlined
                                    :label="$t('Kurzerklärung')"
                                    no-resize
                                    class="pa-3"
                                    v-show="item.knowledgeExplanation.message !== ''"
                                />
                                <v-data-iterator
                                    :items="item.knowledgeExplanation.knowledgeContents"
                                    :no-data-text="$t('Kein Content verfügbar')"
                                    :no-results-text="$t('Kein Ergebnis')"
                                    :items-per-page="5"
                                    :footer-props="{
                                        itemsPerPageText: $t('Content pro Seite'),
                                        'items-per-page-options': [5, 10, 15, { text: $t('Alle'), value: -1 }]
                                    }"
                                    :hideDefaultFooter="item.knowledgeExplanation.knowledgeContents.length <= 5"
                                >
                                    <template v-slot:item="{ item: content }">
                                        <v-list-item>
                                            <v-list-item-action>
                                                <v-btn icon @click="handleKnowledgeContent(item.knowledgeExplanation, content, 'Erklärung')">
                                                    <v-icon :color="contentColor(content)">mdi-file</v-icon>
                                                </v-btn>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title v-html="limitCharacters(content)" />
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-data-iterator>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>
                </td>
            </template>
        </v-data-table>
        <v-divider />
        <v-subheader>{{ $t('Zusätzliche Infos') }}</v-subheader>
        <v-list>
            <v-list-item>
                <v-list-item-action>
                    <v-icon>mdi-information-outline</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('Beschreibung') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ value.description || $t('Keine') }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-action>
                    <UserProfile :value="value.createdBy" small :displayName="false" />
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('Erstellt') }}</v-list-item-title>
                    <v-list-item-subtitle>
                        {{ value.createdAt }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-action>
                    <UserProfile :value="value.updatedBy" small :displayName="false" />
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('Bearbeitet') }}</v-list-item-title>
                    <v-list-item-subtitle>
                        {{ value.updatedAt }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <KnowledgeContentPreview v-model="selectedKnowledgeContent" showNext :toolbarTitle="selectedKnowledgeContentTitle" :items="selectedKnowledgeContents" />
        <DeleteKnowledgeContentExceptionDialog
            :value="selectedKnowledgeUnit"
            :dialog="showExceptionDialog"
            @dialog="(value) => (showExceptionDialog = value)"
        />
    </v-card>
</template>

<script>
import KnowledgeContentPreview from '../../KnowledgeContent/KnowledgeContentPreview';
import DeleteKnowledgeContentExceptionDialog from '../DeleteKnowledgeUnitExceptionDialog';
import CatalogMaterial from '../../CatalogMaterial/CatalogMaterial';
import UserProfile from '../../Generic/Profile/UserProfile';

import AuthoringToolMixin from '../Mixins/AuthoringtoolMixin';
export default {
    name: 'knowledge-unit-list-view',
    components: {
        KnowledgeContentPreview,
        DeleteKnowledgeContentExceptionDialog,
        UserProfile,
        CatalogMaterial
    },
    mixins: [AuthoringToolMixin],
    data: () => ({
        showExceptionDialog: false,
        selectedKnowledgeUnit: {},
        knowledgeUnitOrderMap: {},
        tabs: 0,
        selectedKnowledgeContent: {},
        selectedKnowledgeContents: [],
        selectedKnowledgeContentTitle: '',
        search: ''
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        value() {
            this.search = '';
            this.loadKnowledgeUnits();
        }
    },
    computed: {
        headers() {
            return [
                {
                    text: this.$t('Reihenfolge'),
                    align: 'center',
                    sortable: true,
                    value: 'order',
                    width: '15%'
                },
                {
                    text: this.$t('Frage'),
                    align: 'left',
                    sortable: true,
                    value: 'question',
                    width: '55%'
                },
                {
                    text: this.$t('Fragetyp'),
                    align: 'center',
                    sortable: true,
                    value: 'questionType',
                    width: '20%'
                },
                {
                    text: '',
                    align: 'right',
                    sortable: false,
                    value: null,
                    width: '10%'
                }
            ];
        },
        permissions() {
            const parent = this.getParent(this.value);
            return parent
                ? this.getPermissions(parent.id)
                : {
                      create: false,
                      update: false,
                      copy: false,
                      move: false,
                      delete: false
                  };
        },
        orderRules() {
            return [
                (v) => (parseInt(v) <= 0 ? this.$t('Geben Sie bitte eine Zahl größer 0 ein') : true),
                (v) => (parseInt(v) > this.getKnowledgeUnits(this.value.id).length ? this.$t('Geben Sie bitte eine kleinere Zahl an') : true),
                (v) => {
                    const value = String(v);
                    const regex = /^\d+$/;
                    return Number.isNaN(parseInt(v)) || !regex.test(value) ? this.$t('Operatoren sind nicht zulässig') : true;
                }
            ];
        },
        parentPath() {
            let path = [];
            const pathfinder = (parent) => {
                const node = this.getParent(parent);
                if (node !== undefined) {
                    path.unshift(node);
                    pathfinder(node);
                }
            };
            pathfinder(this.value);
            return [...path, this.value];
        }
    },
    methods: {
        handleKnowledgeContent({ knowledgeContents }, content, title) {
            this.selectedKnowledgeContentTitle = title;
            this.selectedKnowledgeContents = knowledgeContents;
            this.selectedKnowledgeContent = content;
        },
        limitCharacters({ title }) {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return title.length > 35 ? `${title.substring(0, 35)}&hellip;` : title;
                case 'sm':
                    return title.length > 50 ? `${title.substring(0, 50)}&hellip;` : title;
                case 'md':
                    return title.length > 65 ? `${title.substring(0, 65)}&hellip;` : title;
                case 'lg':
                    return title.length > 80 ? `${title.substring(0, 80)}&hellip;` : title;
                case 'xl':
                    return title;
            }
            return title;
        },
        contentColor({ state: { stateName } }) {
            switch (stateName) {
                case 'APPROVED':
                    return 'success';
                case 'PENDING':
                    return 'warning';
                case 'DECLINED':
                    return 'error';
            }
            return 'info';
        },
        truncateQuestion({ question }) {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return question.length > 35 ? `${question.substring(0, 35)}&hellip;` : question;
                case 'sm':
                    return question.length > 70 ? `${question.substring(0, 70)}&hellip;` : question;
                case 'md':
                    return question.length > 105 ? `${question.substring(0, 105)}&hellip;` : question;
                case 'lg':
                    return question.length > 140 ? `${question.substring(0, 140)}&hellip;` : question;
                case 'xl':
                    return question;
            }
        },
        questionTypeColor({ questionType }) {
            switch (questionType) {
                case 'SINGLE_SELECT':
                    return 'blue lighten-1';
                case 'MULTI_SELECT':
                    return 'indigo lighten-1';
                case 'PICKER':
                    return 'green lighten-1';
                case 'SEQUENCE':
                    return 'cyan lighten-1';
                case 'TRUE_FALSE':
                    return 'orange lighten-1';
                case 'FILL_BLANK':
                    return 'teal lighten-1';
                case 'BUCKET_SORT':
                    return 'deep-purple lighten-1';
            }
            return '';
        },
        filter(value, search, item) {
            if (search.trim() === '') {
                return true;
            }
            let buildString = [...new Set(search.split(' '))].filter((word) => word !== '').map((word) => word.toLowerCase());
            const text = Object.values(item)
                .filter((data) => typeof data === 'string' || typeof data === 'number')
                .map((data) => {
                    if (typeof data === 'number') {
                        return data.toString();
                    }
                    return data;
                })
                .join(' ')
                .toLowerCase();
            return buildString.every((word) => text.includes(word));
        },
        changeOrder(knowledgeUnit) {
            const catalogGroup = this.parentPath.find((item) => this.isAuthoringToolType(item) === 'CATALOG_GROUP');
            const catalogSubgroup = this.parentPath.find((item) => this.isAuthoringToolType(item) === 'CATALOG_SUBGROUP');
            const catalog = this.parentPath.find((item) => this.isAuthoringToolType(item) === 'CATALOG');
            const order = this.getOrder(knowledgeUnit);
            if (catalogGroup && catalogSubgroup && catalog && order) {
                const request = {
                    catalogGroupId: catalogGroup.id,
                    catalogSubGroupId: catalogSubgroup.id,
                    catalogId: catalog.id,
                    order: order,
                    data: {
                        id: knowledgeUnit.id
                    }
                };
                this.setMenu(knowledgeUnit, false);
                this.UPDATE_KNOWLEDGEUNIT_ORDER(request).then(() => this.loadKnowledgeUnits());
            }
        },
        getValid({ id }) {
            return this.knowledgeUnitOrderMap[id] ? this.knowledgeUnitOrderMap[id].valid : false;
        },
        setValid({ id }, valid) {
            if (typeof valid === 'boolean') {
                this.$set(this.knowledgeUnitOrderMap, id, {
                    ...this.knowledgeUnitOrderMap[id],
                    valid
                });
            }
        },
        getOrder({ id }) {
            return this.knowledgeUnitOrderMap[id] ? this.knowledgeUnitOrderMap[id].order : 0;
        },
        getMenu({ id }) {
            return this.knowledgeUnitOrderMap[id] ? this.knowledgeUnitOrderMap[id].open : false;
        },
        setMenu({ id }, open) {
            if (typeof open === 'boolean') {
                this.$set(this.knowledgeUnitOrderMap, id, {
                    ...this.knowledgeUnitOrderMap[id],
                    open
                });
            }
        },
        getExtendedMenu({ id }) {
            return this.knowledgeUnitOrderMap[id] ? this.knowledgeUnitOrderMap[id].menu : false;
        },
        setExtendedMenu({ id }, menu) {
            if (typeof menu === 'boolean') {
                this.$set(this.knowledgeUnitOrderMap, id, {
                    ...this.knowledgeUnitOrderMap[id],
                    menu
                });
            }
        },
        setOrder({ id }, order) {
            if (typeof order === 'number' || !!parseInt(order)) {
                this.$set(this.knowledgeUnitOrderMap, id, {
                    ...this.knowledgeUnitOrderMap[id],
                    order: parseInt(order)
                });
            }
        },
        loadKnowledgeUnits() {
            this.LOAD_KNOWLEDGE_UNITS(this.value).then(() => {
                this.knowledgeUnitOrderMap = this.getKnowledgeUnits(this.value.id).reduce((map, { id, order }) => {
                    map[id] = { order, open: false, valid: false, menu: false };
                    return map;
                }, {});
            });
        },
        create(knowledgeUnit) {
            this.$emit('switchView', {
                component: 'ADD_KNOWLEDGE_UNIT',
                data: knowledgeUnit,
                type: 'ADD'
            });
            this.setExtendedMenu(knowledgeUnit, false);
        },
        update(knowledgeUnit) {
            this.$emit('switchView', {
                component: 'UPDATE_KNOWLEDGE_UNIT',
                data: knowledgeUnit,
                type: 'UPDATE'
            });
            this.setExtendedMenu(knowledgeUnit, false);
        },
        editCatalog() {
            this.$emit('switchView', {
                component: 'UPDATE_CATALOG',
                data: {},
                type: 'UPDATE'
            });
        },
        copy(knowledgeUnit) {
            this.$emit('switchView', {
                component: 'COPY_KNOWLEDGE_UNIT',
                data: knowledgeUnit,
                type: 'COPY'
            });
            this.setExtendedMenu(knowledgeUnit, false);
        },
        move(knowledgeUnit) {
            this.$emit('switchView', {
                component: 'MOVE_KNOWLEDGE_UNIT',
                data: knowledgeUnit,
                type: 'MOVE'
            });
            this.setExtendedMenu(knowledgeUnit, false);
        },
        preview(knowledgeUnit) {
            this.$emit('switchView', {
                component: 'PREVIEW_KNOWLEDGE_UNIT',
                data: knowledgeUnit,
                type: 'PREVIEW'
            });
            this.setExtendedMenu(knowledgeUnit, false);
        },
        remove(knowledgeUnit) {
            this.DELETE_KNOWLEDGE_UNIT(knowledgeUnit).catch(() => {
                this.selectedKnowledgeUnit = knowledgeUnit;
                this.showExceptionDialog = true;
            });
            this.setExtendedMenu(knowledgeUnit, false);
        },
        copyCatalog() {
            this.$emit('switchView', {
                component: 'COPY_CATALOG',
                data: {},
                type: 'UPDATE'
            });
        },
        moveCatalog() {
            this.$emit('switchView', {
                component: 'MOVE_CATALOG',
                data: {},
                type: 'UPDATE'
            });
        },
        removeCatalog() {
            this.$emit('switchView', {
                component: 'DELETE_CATALOG',
                data: {},
                type: 'DELETE'
            });
        },
        expandRow(isExpanded, expand) {
            expand(!isExpanded);
        }
    },
    activated() {
        this.loadKnowledgeUnits();
    }
};
</script>
