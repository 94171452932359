<template>
    <v-col :cols="6">
        <v-card>
            <v-toolbar flat dark dense color="secondary">
                <v-toolbar-title>{{ $t('App Farben') }} - {{ titleMode }}</v-toolbar-title>
            </v-toolbar>
            <v-container>
                <v-row>
                    <v-col cols="auto">
                        <ColorPicker :label="$t('Primär')" v-model="styles[currentMode].primary" />
                    </v-col>
                    <v-col cols="auto">
                        <ColorPicker :label="$t('Sekundär')" v-model="styles[currentMode].secondary" />
                    </v-col>
                    <v-col cols="auto">
                        <ColorPicker :label="$t('Akzent')" v-model="styles[currentMode].accent" />
                    </v-col>
                    <v-col cols="auto">
                        <ColorPicker :label="$t('Fehler')" v-model="styles[currentMode].error" />
                    </v-col>
                    <v-col cols="auto">
                        <ColorPicker :label="$t('Info')" v-model="styles[currentMode].info" />
                    </v-col>
                    <v-col cols="auto">
                        <ColorPicker :label="$t('Erfolg')" v-model="styles[currentMode].success" />
                    </v-col>
                    <v-col cols="auto">
                        <ColorPicker :label="$t('Warnung')" v-model="styles[currentMode].warning" />
                    </v-col>
                    <v-col cols="auto">
                        <ColorPicker :label="$t('Hintergrund')" v-model="styles[currentMode].background" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col :cols="12">
                        <v-btn block color="primary" @click="$emit('loadDefault', currentMode)">
                            {{ $t('Standardeinstellungen laden') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-col>
</template>

<script>
import ColorPicker from '../../../Components/SystemSettings/Picker/ColorPicker';
export default {
    components: {
        ColorPicker
    },
    props: {
        styles: {
            type: Object,
            default: () => ({})
        },
        titleMode: {
            type: String,
            default: ''
        },
        currentMode: {
            type: String,
            default: ''
        }
    }
};
</script>
