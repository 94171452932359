<template>
    <v-card flat height="100%">
        <v-layout fill-height>
            <v-flex xs3>
                <v-layout column fill-height class="verticalLimit">
                    <v-flex>
                        <slot></slot>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex shrink>
                <v-divider vertical />
            </v-flex>
            <v-flex xs9>
                <v-layout fill-height>
                    <v-flex xs8>
                        <v-layout column fill-height class="verticalLimit">
                            <v-flex>
                                <FilemanagerList
                                    v-model="computedSelectedItem"
                                    @media="handleMedia"
                                    :media="media"
                                    :selectable="selectable"
                                    @selectedMedia="handleSelectedMedia"
                                />
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex shrink>
                        <v-divider vertical />
                    </v-flex>
                    <v-flex xs4>
                        <v-layout column fill-height class="verticalLimit">
                            <v-flex>
                                <keep-alive>
                                    <component
                                        v-model="computedmedia"
                                        :is="component"
                                        :search="search"
                                        @openTree="handleOpenTree"
                                        :permissions="permissions"
                                        @media="handleMedia"
                                    />
                                </keep-alive>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-card>
</template>

<script>
import FolderSelection from './FilemanagerFolderSelector';
import MediaSelection from './MediaSelection';
import FilemanagerList from './FilemanagerList';

export default {
    name: 'static-filemanager-desktop-view',
    components: { FolderSelection, MediaSelection, FilemanagerList },
    props: {
        value: {
            type: Array,
            default: () => []
        },
        component: {
            type: [String, Object],
            default: '<div/>'
        },
        search: {
            type: String,
            default: ''
        },
        media: {
            type: Object,
            default: () => ({})
        },
        permissions: {
            type: Object,
            default: () => ({
                add: false,
                update: false,
                delete: false,
                change: false
            })
        },
        selectable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedmedia: {
            get() {
                if (Object.keys(this.media).length > 0) {
                    return this.media;
                }
                return this.value.length > 0 ? this.value[0] : {};
            },
            set(value) {
                this.$emit('input', [value]);
            }
        },
        computedSelectedItem: {
            get() {
                return this.value.length > 0 ? this.value[0] : {};
            },
            set(value) {
                this.$emit('input', [value]);
            }
        }
    },
    methods: {
        handleSelectedMedia(value) {
            this.$emit('selectedMedia', value);
        },
        handleMedia(value) {
            this.$emit('media', value);
        },
        handleOpenTree(value) {
            this.$emit('openTree', value);
        }
    }
};
</script>
