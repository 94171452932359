<template>
    <v-data-iterator
        :items="proposals"
        :items-per-page="5"
        :footer-props="{
            'items-per-page-text': $t('Änderungsvorschläge pro Seite'),
            'items-per-page-options': [5, 10, 15, { value: -1, text: $t('Alle') }]
        }"
        :no-data-text="$t('Keine Änderungsvorschläge')"
        :no-results-text="$t('Kein Ergebnis')"
        :hideDefaultFooter="getWikisWithProposals <= 5"
    >
        <template v-slot:item="{ item }">
            <v-list-item @click="selectWiki(item)">
                <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <UserProfile :value="item.createdBy" small :displayName="false" />
                </v-list-item-action>
            </v-list-item>
        </template>
    </v-data-iterator>
</template>

<script>
import WikiMixin from '../Mixins/WikiMixin';
import UserProfile from '../../Generic/Profile/UserProfile';

export default {
    name: 'wiki-proposals-list',
    components: { UserProfile },
    mixins: [WikiMixin],
    data: () => ({
        proposals: [],
        hasProposalPermission: false
    }),
    props: {
        wiki: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        wiki: {
            // immediate: true,
            handler(value) {
                this.getProposals(value);
            }
        }
    },
    computed: {
        hasWiki() {
            return Object.keys(this.wiki).length > 0;
        }
    },
    methods: {
        getProposals(value) {
            if (this.hasWiki && this.hasProposalPermission) {
                this.GET_PROPOSALS(value)
                    .then((response) => (this.proposals = response))
                    .then(() => this.$emit('proposals', this.proposals.length));
            }
        },
        selectWiki(value) {
            this.$emit('selectedProposal', value);
        }
    },
    mounted() {
        this.validateEditorState({ proposals: ['MANAGE_PROPOSAL_WIKI'] }).then((response) => {
            this.hasProposalPermission = Object.values(response).reduce((result, next) => result && next, true);
            this.getProposals(this.wiki);
        });
    },
    activated() {
        this.getProposals(this.wiki);
    }
};
</script>
