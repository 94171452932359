import axios from '../../../plugins/Axios/axios';

const state = {
    userPermissionList: [],
    hasPermissions: false
};

// getters
const getters = {
    getUserPermissionList(state) {
        return state.userPermissionList;
    },
    getPermissions(state) {
        return state.hasPermissions;
    }
};

// actions
const actions = {
    ADD_PERMISSION_TO_USER({ commit }, dataToSend) {
        return axios.post(`/permissions`, dataToSend).then((response) => {
            return response;
        });
    },
    GET_ALL_SYSTEM_PERMISSIONS({ commit }) {
        return axios.get('/permissions').then((response) => {
            commit('updateSystemPermissionList', response.data.data.permissions);
            return response;
        });
    },
    GET_USERPERMISSION_LIST_BY_ID({ commit }, userId) {
        return axios.get(`users/${userId}/permissions`).then((response) => {
            commit('updateUserPermissionList', response.data.data.permissions);
            return response;
        });
    },
    CHECK_PERMISSIONS({ commit }, permissions) {
        return axios.post(`/checkPermissions`, permissions).then((response) => response.data.data.hasPermission);
    }
};

// mutations
const mutations = {
    clear: (state) => {
        state.userPermissionList = [];
        state.hasPermissions = false;
    },
    updateUserPermissionList: (state, permissionList) => {
        state.userPermissionList = permissionList;
    },
    updateSystemPermissionList: (state, systemPermissionList) => {
        state.systemPermissionList = systemPermissionList;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
