<template>
    <v-card>
        <v-tabs v-model="tabs" show-arrows v-show="role === 'USER'">
            <v-tab :key="0">{{ $t('Gruppe') }}</v-tab>
            <v-tab :key="1">{{ $t('Gruppenleiter') }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
            <v-tab-item :key="0">
                <v-container>
                    <v-row>
                        <v-col :cols="12">
                            <GroupParentSelector v-model="parentId" :groups="groups" :disabledGroup="computedDisabled" />
                        </v-col>
                        <v-col :cols="12">
                            <v-text-field prepend-icon="mdi-account-multiple" :label="$t('Name')" v-model="name" hide-details />
                        </v-col>
                        <v-col :cols="12">
                            <v-text-field prepend-icon="mdi-account-multiple" :label="$t('Beschreibung')" v-model="description" hide-details />
                        </v-col>
                    </v-row>
                </v-container>
            </v-tab-item>
            <v-tab-item :key="1">
                <UserSelector v-model="admins" :showSelf="true" :showUsers="false" showAdmins />
            </v-tab-item>
        </v-tabs-items>
        <v-card-actions>
            <v-btn block :color="computedColor" :disabled="!hasName" @click="submit">
                {{ computedSubmit }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import GroupParentSelector from './GroupParentSelector';
import UserSelector from '../Generic/Tree/UserGroupTreeSelector';

import admingroupMixin from './Mixins/AdminGroupMixins';
import usergroupMixin from './Mixins/UserGroupMixins';
import UserMixin from '../Users/Mixins/UserMixin';

export default {
    name: 'add-update-group',
    components: { GroupParentSelector, UserSelector },
    mixins: [admingroupMixin, usergroupMixin, UserMixin],
    data: () => ({
        tabs: 0,
        id: '',
        name: '',
        description: '',
        parentId: '',
        admins: []
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        role: {
            type: String,
            default: 'USER'
        },
        type: {
            type: String,
            validator: (v) => ['ADD', 'UPDATE'].includes(v),
            default: 'ADD'
        }
    },
    computed: {
        groups() {
            if (this.role === 'USER') {
                return this.getUserGroupTree;
            }
            return this.getAdminGroupTree;
        },
        computedDisabled() {
            return Object.keys(this.value).length > 0 ? this.value : {};
        },
        groupTree() {
            if (this.role === 'USER') {
                return this.getUserGroupTree;
            }
            return this.getAdminGroupTree;
        },
        hasName() {
            return this.name !== '';
        },
        computedColor() {
            return this.type === 'ADD' ? 'success' : this.type === 'UPDATE' ? 'warning' : 'info';
        },
        computedSubmit() {
            return this.type === 'ADD' ? this.$t('Hinzufügen') : this.type === 'UPDATE' ? this.$t('Bearbeiten') : '';
        }
    },
    methods: {
        reset() {
            this.tabs = 0;
            this.id = '';
            this.name = '';
            this.description = '';
            this.parentId = '';
        },
        serialize() {
            switch (this.type) {
                case 'ADD':
                    return {
                        name: this.name,
                        description: this.description,
                        parentId: this.parentId
                    };
                case 'UPDATE':
                    return {
                        id: this.id,
                        name: this.name,
                        description: this.description,
                        parentId: this.parentId
                    };
            }
        },
        close(group) {
            this.$emit('input', this.getTreeObject(group));
            this.$emit('switchView', { component: '', type: '', data: {} });
        },
        submit() {
            if (this.type === 'ADD') {
                if (this.role === 'USER') {
                    this.ADD_USER_GROUP(this.serialize()).then((result) =>
                        this.SET_GROUP_LEADERS({
                            group: result,
                            admins: this.admins
                        }).then(() => this.close(result))
                    );
                } else {
                    this.ADD_ADMIN_GROUP(this.serialize()).then((result) => this.close(result));
                }
            } else {
                if (this.type === 'UPDATE') {
                    if (this.role === 'USER') {
                        this.UPDATE_USER_GROUP(this.serialize()).then((result) =>
                            this.SET_GROUP_LEADERS({
                                group: result,
                                admins: this.admins
                            }).then(() => this.close(result))
                        );
                    } else {
                        this.UPDATE_ADMIN_GROUP(this.serialize()).then((result) => this.close(result));
                    }
                }
            }
        }
    },
    activated() {
        this.reset();
        const path = this.isUserOrGroup(this.value) === 'USER' ? this.getTreeItemPath(this.value) : [];
        const parent = path.length > 1 ? path[path.length - 2] : this.value;

        if (this.type === 'UPDATE') {
            Object.entries(parent).forEach(([key, val]) => {
                if (this.$data.hasOwnProperty(key)) {
                    this.$data[key] = val;
                }
            });
        }
        if (this.type === 'ADD') {
            if (parent.hasOwnProperty('id')) {
                this.parentId = parent.id;
            }
        }
        if (this.role === 'USER') {
            if (this.type === 'UPDATE') {
                this.GET_GROUP_LEADERS(this.value).then((admins) => (this.admins = admins));
            }
            if (this.type === 'ADD') {
                this.admins = [this.getLoggedInAdmin];
            }
        }
    }
};
</script>
