<template>
    <v-card>
        <Toolbar :toolbarTitle="computedTitle" />
        <Activities :isAdmin="true" :isCourseManagerValue="isCourseManagerValue" />
    </v-card>
</template>

<script>
import CourseMixin from '../../../Components/CourseLearning/CourseManager/Mixins/CourseMixin';

import Toolbar from '../../../Components/Generic/Toolbar';
import Activities from '../../../Components/CourseLearning/CourseManager/Activities';

export default {
    name: 'activities',
    mixins: [CourseMixin],
    components: {
        Toolbar,
        Activities
    },
    data: () => ({
        tabs: 0,
        showModal: false,
        showDeleteModal: false,
        operationType: 'ADD',
        isCourseManagerValue: false
    }),
    computed: {
        computedTitle() {
            if (this.getSelectedLearningGoal.activityTypeName !== null) {
                return `${this.$t('Kursmanager')} - ${this.getSelectedLearningGoal.activityTypeName}`;
            } else if (this.getSelectedTopic.activityTypeName !== null) {
                return `${this.$t('Kursmanager')} - ${this.getSelectedTopic.activityTypeName}`;
            } else if (this.getSelectedAdminCourse.activityTypeName !== null) {
                return `${this.$t('Kursmanager')} - ${this.getSelectedAdminCourse.activityTypeName}`;
            }
            return `${this.$t('Kursmanager')} - ${this.$t('Aktivitäten')}`;
        },
        permissions() {
            return {
                add: this.isCourseManagerValue,
                update: this.isCourseManagerValue,
                delete: this.isCourseManagerValue,
                eval: this.isCourseManagerValue && this.getSelectedAdminCourse.useEvaluation
            };
        },
        isSelected() {
            return Object.keys(this.getSelectedActivity).length > 0;
        }
    },
    mounted() {
        this.isCourseManager().then((response) => (this.isCourseManagerValue = response));
    }
};
</script>
