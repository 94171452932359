<template>
    <v-card text>
        <v-subheader>{{ $t('Wissenseinheit') }}</v-subheader>
        <v-list-item>
            <v-list-item-action>
                <v-avatar color="secondary" size="36">
                    <span class="white--text">{{ knowledgeUnit.order }}</span>
                </v-avatar>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>{{ knowledgeUnit.question }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t(knowledgeUnit.questionType) }}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-subheader>{{ $t('Zielort') }}</v-subheader>
        <CatalogSelectorTree v-model="selectedCatalog" :disabledCatalogs="[this.value]" />
        <v-card-actions>
            <v-btn block color="success" :disabled="!Object.keys(selectedCatalog).length > 0" @click="submit">
                {{ $t('Einfügen') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import CatalogSelectorTree from './SingleCatalogSelectorTree';
import AuthoringToolMixin from '../Mixins/AuthoringtoolMixin';

export default {
    name: 'knowledge-unit-move-view',
    components: { CatalogSelectorTree },
    mixins: [AuthoringToolMixin],
    data: () => ({
        selectedCatalog: {}
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        knowledgeUnit: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        submit() {
            this.MOVE_KNOWLEDGEUNIT({
                knowledgeUnit: this.knowledgeUnit,
                catalog: this.selectedCatalog
            }).then(() => {
                this.$emit('openTree', this.calculateTreePath(this.selectedCatalog));
                this.$emit('input', this.selectedCatalog);
                this.selectedCatalogs = [];
                this.$emit('switchView', { component: '', data: {}, type: '' });
            });
        }
    },
    activated() {
        this.selectedCatalog = {};
    }
};
</script>
