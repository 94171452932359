<template>
    <v-card>
        <v-stepper v-model="step" :alt-labels="$vuetify.breakpoint.smAndUp" :elevation="0">
            <v-stepper-header>
                <v-stepper-step :step="1" editable>{{ $t('Nutzer') }}</v-stepper-step>
                <!-- <v-divider /> -->
                <v-stepper-step :step="2" :editable="users.length > 0">{{ $t('Aufgaben') }}</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
                <v-stepper-content class="pa-0 ma-0" :step="1">
                    <UserSelector v-model="users" />
                    <v-card-actions>
                        <v-spacer />
                        <v-btn :disabled="!(users.length > 0)" color="primary" @click.stop="goTo(2)">
                            {{ $t('Weiter') }}
                        </v-btn>
                    </v-card-actions>
                </v-stepper-content>
                <v-stepper-content class="pa-0 ma-0" :step="2">
                    <List title="Nutzer" :items="users" showOptions expand :itemKeys="['displayName', 'firstName', 'lastName', 'email']" :showAction="false">
                        <template v-slot:content="{ content }">
                            <v-list-item-title>
                                {{ content.displayName }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ content.email }}
                            </v-list-item-subtitle>
                        </template>
                        <template v-slot:expanded="{ expanded }">
                            <List title="Aufgaben" showOptions showAction showAppend :items="userTasks(expanded)">
                                <template v-slot:content="{ content }">
                                    <v-list-item-title>
                                        {{ content.name }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ `${content.startDate} - ${content.endDate}` }}
                                    </v-list-item-subtitle>
                                </template>
                                <template v-slot:action="{ action }">
                                    <v-menu :close-on-content-click="false" :max-width="taskNodeWidth" :min-width="taskNodeWidth">
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon v-on="on">
                                                <v-icon>mdi-information</v-icon>
                                            </v-btn>
                                        </template>
                                        <TaskNode :value="action" :showResults="false" />
                                    </v-menu>
                                </template>
                                <template v-slot:append="{ append }">
                                    <v-btn :disabled="!(append.passed && append.completed)" icon @click="dowloadCertificate(expanded, append)">
                                        <v-icon>mdi-download</v-icon>
                                    </v-btn>
                                </template>
                            </List>
                        </template>
                    </List>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-card>
</template>

<script>
import UserSelector from '../Generic/Tree/UserGroupTreeSelector';
import TaskNode from '../UserApp/Helpers/TaskNode';
import List from '../Generic/List/AdvancedList';

import CertificateMixin from './Mixins/CertificateMixin';
import { saveAs } from 'file-saver';

export default {
    name: 'certificates',
    mixins: [CertificateMixin],
    components: { List, UserSelector, TaskNode },
    data: () => ({
        step: 1,
        users: []
    }),
    watch: {
        step(value) {
            if (value === 2) {
                this.GET_CERTIFICATE_TASKS(this.users);
            }
        }
    },
    computed: {
        taskNodeWidth() {
            return this.$vuetify.breakpoint.xs ? '100%' : this.$vuetify.breakpoint.thresholds.xs;
        }
    },
    methods: {
        dowloadCertificate(user, task) {
            this.DOWNLOAD_USER_TASK_CERTIFICATE({ user, task }).then((response) => {
                saveAs(response, `${task.name}_${task.startDate}_${task.endDate}_${user.email}`.replace(/[^\w\s-]/gi, '_'));
            });
        },
        userTasks({ id = '' }) {
            return this.getUserCertificateTasks(id);
        },
        goTo(value) {
            this.step = value;
        }
    }
};
</script>

<style scoped>
limitHeight {
    max-height: calc(100vh - 250px) !important;
}
</style>
