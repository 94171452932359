import { render, staticRenderFns } from "./TaskArchiveList.vue?vue&type=template&id=648d5c12&"
import script from "./TaskArchiveList.vue?vue&type=script&lang=js&"
export * from "./TaskArchiveList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports