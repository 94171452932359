<template>
    <v-container>
        <v-row>
            <v-col :cols="12">
                <v-textarea readonly hide-details :value="emptyDescription" :label="$t('Beschreibung')" no-resize :rows="3" />
            </v-col>
            <v-col :cols="12" v-show="!isMyCourse">
                <v-textarea readonly hide-details :value="emptyNotes" :label="$t('Notizen')" no-resize :rows="3" />
            </v-col>
            <v-col :cols="6">
                <v-text-field readonly hide-details :value="value.minScore" :label="$t('Minimale Punktzahl')" />
            </v-col>
            <v-col :cols="6">
                <v-text-field readonly hide-details :value="value.score" :label="$t('Maximale Punktzahl')" />
            </v-col>
            <v-col :cols="6">
                <v-text-field readonly hide-details :value="value.startDate" :label="$t('Startdatum')" />
            </v-col>
            <v-col :cols="6">
                <v-text-field readonly hide-details :value="value.endDate" :label="$t('Enddatum')" />
            </v-col>
            <v-col :cols="6">
                <v-text-field
                    readonly
                    hide-details
                    :value="`${convertMinutesInfo} ${$t('à')} ${timeScaleInMinutes} ${$t('Minuten')}`"
                    :label="$t('Arbeitsaufwand')"
                />
            </v-col>
            <v-col :cols="6">
                <v-text-field readonly hide-details :value="`${hiddenDurationInDays} ${computedDays}`" :label="$t('Dauer')" />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: 'course',
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        timeScaleInMinutes: {
            type: Number,
            default: 60
        },
        selectable: {
            type: Boolean,
            default: false
        },
        hideDurationInDays: {
            type: Boolean,
            default: false
        },
        isMyCourse: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hiddenDurationInDays() {
            if (this.hideDurationInDays && this.isMyCourse) {
                return '-';
            }
            return this.value.durationInDays;
        },
        computedDays() {
            if (this.value.durationInDays === 1) {
                return this.$t('Tag');
            }
            return this.$t('Tage');
        },
        emptyDescription() {
            if (this.value.description.length === 0) {
                return '-';
            }
            return this.value.description;
        },
        emptyNotes() {
            if (this.value.notes === null) {
                return '-';
            }
            return this.value.notes;
        },
        convertMinutesInfo() {
            if (this.isMyCourse && this.value.hideEstimatedWorkTimeInMinutes) {
                return '-';
            }
            const timeUnitMinutes = this.value.estimatedWorkTimeInMinutes % this.timeScaleInMinutes;
            const timeUnit = this.value.estimatedWorkTimeInMinutes / this.timeScaleInMinutes;

            if (timeUnitMinutes.toString().length === 1) {
                return `${timeUnit.toString().split('.')[0]}:${'0' + timeUnitMinutes.toString()}`;
            }
            return `${timeUnit.toString().split('.')[0]}:${timeUnitMinutes.toString()} Einheiten`;
        }
    }
};
</script>

<style scoped>
.limit {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
</style>
