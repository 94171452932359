<template>
    <v-card flat>
        <v-tabs show-arrows v-model="tabs">
            <v-tab key="SOLUTION">{{ $t('Lösung') }}</v-tab>
            <v-tab key="EXPLANATION" :disabled="!hasExplanation">{{ $t('Erklärung') }}</v-tab>
            <v-tab-item key="SOLUTION">
                <v-container fluid>
                    <v-row>
                        <v-col>
                            <v-textarea no-resize readonly outlined :value="value.question" :label="questionLabel(value)" hide-details />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <keep-alive>
                                <component
                                    :is="selectComponent(value)"
                                    :knowledgeUnitOptions="value.knowledgeUnitOptions"
                                    :buckets="value.knowledgeUnitBuckets"
                                />
                            </keep-alive>
                        </v-col>
                    </v-row>
                </v-container>
            </v-tab-item>
            <v-tab-item key="EXPLANATION">
                <v-container>
                    <v-row>
                        <v-col :cols="12">
                            <v-textarea no-resize readonly outlined :value="value.knowledgeExplanation.message" :label="$t('Kurzerklärung')" hide-details />
                        </v-col>
                    </v-row>
                    <v-row v-show="explanation.length > 0">
                        <v-col :cols="12">
                            <KnowledgeContentExplanation v-model="computedSelectedKnowledgeExplanation" :items="explanation" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-tab-item>
        </v-tabs>
    </v-card>
</template>

<script>
import ResultSequence from '../../../Components/Quiz/ResultTypes/SequenceResult';
import ResultSingleSelect from '../../../Components/Quiz/ResultTypes/SingleSelectResult';
import ResultMultiSelect from '../../../Components/Quiz/ResultTypes/MultiSelectResult';
import ResultFillBlank from '../../../Components/Quiz/ResultTypes/FillBlankResult';
import ResultNumber from '../../../Components/Quiz/ResultTypes/PickerResult';
import ResultTrueFalse from '../../../Components/Quiz/ResultTypes/TrueFalseResult';
import ResultBucketSort from '../../../Components/Quiz/ResultTypes/BucketResult';

import KnowledgeContentExplanation from '../../../Components/KnowledgeContent/KnowledgeContentExplanation';

export default {
    name: 'quiz-result-knowledge-unit',
    components: {
        ResultSequence,
        ResultSingleSelect,
        ResultMultiSelect,
        ResultFillBlank,
        ResultNumber,
        ResultTrueFalse,
        ResultBucketSort,
        KnowledgeContentExplanation
    },
    data: () => ({
        tabs: 0,
        selectedKnowledgeExplanation: {}
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        hasExplanation() {
            return this.value.knowledgeExplanation.message.trim() !== '' || this.explanation.length > 0;
        },
        explanation() {
            return this.value && this.value.knowledgeExplanation && this.value.knowledgeExplanation.knowledgeContents
                ? this.value.knowledgeExplanation.knowledgeContents
                : [];
        },
        computedSelectedKnowledgeExplanation: {
            get() {
                if (Object.keys(this.selectedKnowledgeExplanation).length > 0) {
                    return this.selectedKnowledgeExplanation;
                }
                return this.explanation.length > 0 ? this.explanation[0] : {};
            },
            set(value) {
                this.selectedKnowledgeExplanation = value;
            }
        }
    },
    methods: {
        questionLabel({ questionType }) {
            return questionType ? `${this.$t(questionType)}-${this.$t('Wissenseinheit')}` : '';
        },
        selectComponent({ questionType }) {
            switch (questionType) {
                case 'SINGLE_SELECT':
                    return ResultSingleSelect;
                case 'MULTI_SELECT':
                    return ResultMultiSelect;
                case 'TRUE_FALSE':
                    return ResultTrueFalse;
                case 'PICKER':
                    return ResultNumber;
                case 'FILL_BLANK':
                    return ResultFillBlank;
                case 'SEQUENCE':
                    return ResultSequence;
                case 'BUCKET_SORT':
                    return ResultBucketSort;
            }
            return undefined;
        }
    },
    activated() {
        this.tabs = 0;
    }
};
</script>
