<template>
    <div>
        <GChart ref="gChart" type="ColumnChart" :class="{ dark: this.isDark }" :data="userStatisticList" :options="chartOptions" :events="chartEvents" />
        <ChartDetail
            :keyComputed="syncedChartData.key"
            :selectedChartDetail="syncedChartData.value"
            :showChartDetail="showChartDetail"
            @showChartDetailEmit="showChartDetailEmit"
        />
    </div>
</template>

<script>
import { GChart } from 'vue-google-charts';
import { mapGetters } from 'vuex';
import moment from 'moment';
import ChartDetail from './ChartDetail';

export default {
    components: {
        GChart,
        ChartDetail
    },
    data() {
        return {
            showChartDetail: false,
            selectedChartDetail: {},
            syncedChartData: {
                key: {},
                value: {}
            },
            chartEvents: {
                select: () => {
                    const table = this.$refs.gChart.chartObject;
                    const selection = table.getSelection().shift();
                    if (typeof selection !== 'undefined' && Object.keys(selection).length > 0) {
                        this.selectedChartDetail = this.mappedDataList[selection.row][selection.column];
                        // synedChartData is important because the <component/> :is and :value need to be synced ( else exception )
                        this.$nextTick(() => {
                            this.syncedChartData = {
                                key: this.keyComputed,
                                value: this.selectedChartDetail
                            };
                            this.showChartDetail = true;
                        });
                    } else if (typeof selection === 'undefined' && Object.keys(this.selectedChartDetail).length > 0) {
                        this.showChartDetail = true;
                    }
                }
            }
        };
    },
    props: {
        statisticAttribute: {
            type: Object,
            default: () => ({})
        },
        userStatisticList: {
            type: Array,
            default: () => []
        },
        mappedDataList: {
            type: Array,
            default: () => []
        },
        keyComputed: {
            type: Object,
            default: () => ({})
        },
        verticalAxisTitle: {
            type: String,
            default: 'Wissenseinheiten beantwortet'
        }
    },
    computed: {
        ...mapGetters('StatisticOperations', ['getUserStatistic']),
        yearTextComputed() {
            switch (this.statisticAttribute.interval.selectedType.code) {
                case 'YEAR':
                    return moment(this.statisticAttribute.interval.startDate).format('YYYY');
                case 'MONTH':
                    return this.$t(moment(this.statisticAttribute.interval.startDate).format('MMMM'));
                case 'DAY':
                    return moment(this.statisticAttribute.interval.startDate).format('YYYY-MM-DD');
                case 'WEEK':
                    return `KW - ${moment(this.statisticAttribute.interval.startDate).isoWeek()}`;
                case 'RANGE':
                    return `${moment(this.statisticAttribute.interval.startDate).format('YYYY-MM-DD')} - ${moment(
                        this.statisticAttribute.interval.endDate
                    ).format('YYYY-MM-DD')}`;
            }
            return moment(this.statisticAttribute.interval.startDate).format('YYYY-MM-DD');
        },
        computeBarHeight() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 250;
                case 'sm':
                    return 400;
                case 'md':
                    return 550;
                case 'lg':
                    return 700;
                case 'xl':
                    return 850;
            }
            return 0;
        },
        chartOptions() {
            return {
                height: this.computeBarHeight,
                width: '100%',
                title: `${this.$t(this.keyComputed.groupTypeKey)} - ${this.yearTextComputed}`,
                titleTextStyle: {
                    color: this.isDark ? '#FFF' : '#000'
                },
                isStacked: true,
                legend: { position: 'top' },
                backgroundColor: this.isDark ? '#1E1E1E' : '#FFF',
                hAxis: {
                    minValue: 0,
                    titleTextStyle: { color: this.isDark ? '#FFF' : '#000' }
                },
                vAxis: {
                    title: this.$t(this.verticalAxisTitle),
                    format: '0',
                    titleTextStyle: { color: this.isDark ? '#FFF' : '#000' }
                },
                animation: { startup: true, duration: 500, easing: 'out' },
                tooltip: { isHtml: this.isDark }
            };
        },
        isDark() {
            return this.$vuetify.theme.dark;
        }
    },
    methods: {
        showChartDetailEmit(val) {
            this.showChartDetail = val;
        }
    }
};
</script>
