import axios from '../../../plugins/Axios/axios';

const state = {
    allCourses: [],
    adminCourses: [],
    attendeeCourses: [],
    attendeePastCourses: [],
    attendeeCurrentCourses: [],
    selectedAdminCourse: {},
    selectedAttendeeCourse: {},
    courseBreadcrumbs: [],
    schedulingMyCourse: [],
    remainingCourseLicences: {},
    defaultCoursePicture: '',
    tabs: 0
};

const getters = {
    getAllCourses() {
        return state.allCourses;
    },
    getAdminCourses() {
        return state.adminCourses;
    },
    getAttendeeCourses() {
        return state.attendeeCourses;
    },
    getAttendeePastCourses() {
        return state.attendeePastCourses;
    },
    getAttendeeCurrentCourses() {
        return state.attendeeCurrentCourses;
    },
    getSelectedAdminCourse() {
        return state.selectedAdminCourse;
    },
    getSelectedAttendeeCourse() {
        return state.selectedAttendeeCourse;
    },
    getCourseBreadcrumbs() {
        return state.courseBreadcrumbs;
    },
    getSchedulingMyCourse() {
        return state.schedulingMyCourse;
    },
    getRemainingCourseLicences() {
        return state.remainingCourseLicences;
    },
    getDefaultCoursePicture() {
        return state.defaultCoursePicture;
    },
    getTabs() {
        return state.tabs;
    }
};

const actions = {
    SET_SELECTED_ADMIN_COURSE({ commit }, course) {
        commit('updateSelectedAdminCourse', course);
    },
    SET_SELECTED_TAB({ commit }, value) {
        commit('updateSelectedTab', value);
    },
    SET_SELECTED_ATTENDEE_COURSE({ commit }, course) {
        commit('updateSelectedAttendeeCourse', course);
    },
    SET_ADMIN_COURSES({ commit }, courses) {
        commit('updateAdminCourses', courses);
    },
    SET_ATTENDEE_COURSES({ commit }, courses) {
        commit('updateAttendeeCourses', courses);
    },
    GET_REMAINING_COURSES({ commit }) {
        return axios
            .get(`/courselearning/courseinstances/amount`)
            .then((response) => response.data.data)
            .then((response) => commit('updateRemainingCourseLicences', response));
    },
    GET_COURSE_ADMINS_AND_ATTENDEES({ commit }, { id: courseId }) {
        return axios.get(`/courselearning/courseinstances/${courseId}/course/users`).then((response) => response.data.data);
    },
    GET_ALL_COURSES({ commit }) {
        return axios
            .get(`/courselearning/courseinstances/courses`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateAllCourses', response);
                return response;
            });
    },
    GET_COURSES_AS_ADMIN({ commit }) {
        return axios
            .get(`/courselearning/courseinstances/courses/admins`)
            .then((response) => response.data.data)
            .then((response) => commit('updateAdminCourses', response));
    },
    GET_COURSES_AS_ATTENDEE({ commit }) {
        return axios
            .get(`/courselearning/courseinstances/courses/attendees`)
            .then((response) => response.data.data)
            .then((response) => commit('updateAttendeeCourses', response));
    },
    GET_CURRENT_COURSES_AS_ATTENDEE({ commit }) {
        return axios
            .get(`/courselearning/courseinstances/courses/attendees/current`)
            .then((response) => response.data.data)
            .then((response) => commit('updateCurrentAttendeeCourses', response));
    },
    GET_PAST_COURSES_AS_ATTENDEE({ commit }) {
        return axios
            .get(`/courselearning/courseinstances/courses/attendees/past`)
            .then((response) => response.data.data)
            .then((response) => commit('updatePastAttendeeCourses', response));
    },
    GET_COURSE({ commit }, { id: courseTempId }) {
        return axios
            .get(`/courselearning/courseinstances/${courseTempId}/course`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateAdminCourses', response);
                commit('updateAttendeeCourses', response);
                commit('updateAllCourses', response);
            });
    },
    CREATE_COURSE({ commit }, course) {
        return axios
            .post(`/courselearning/courseinstances/courses`, course)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateAdminCourses', response);
                commit('updateAllCourses', response);
                return response;
            });
    },
    UPDATE_COURSE({ commit }, course) {
        const { id: courseTempId } = course;
        return axios
            .patch(`/courselearning/courseinstances/${courseTempId}/course`, course)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateAdminCourses', response);
                commit('updateAttendeeCourses', response);
                commit('updateAllCourses', response);
                return response;
            });
    },
    UPDATE_COURSE_ADMINS_AND_ATTENDEES({ commit }, { course: { id: courseId }, ...request }) {
        return axios.patch(`/courselearning/courseinstances/${courseId}/users`, request).then((response) => response.data.data);
    },
    DELETE_COURSE({ commit }, course) {
        const { id: courseTempId } = course;
        return axios.delete(`/courselearning/courseinstances/${courseTempId}`).then(() => {
            commit('deleteAdminCourse', course);
            commit('deleteAttendeeCourse', course);
            commit('deleteAllCourse', course);
        });
    },
    GET_COURSE_SCHEDULING({ commit }, course) {
        const { id: courseTempId } = course;
        return axios.get(`/courselearning/courseinstances/${courseTempId}/scheduling`).then((response) => response.data.data);
    },
    UPDATE_COURSE_SCHEDULING({ commit }, course) {
        const { id: courseTempId, ...learningGoal } = course;

        return axios
            .patch(`/courselearning/courseinstances/${courseTempId}/scheduling`, learningGoal)
            .then((response) => response.data.data)
            .then((response) => commit('updateScheduling', response));
    },
    GET_COURSE_USER_PREFERENCES({ commit }, course) {
        return axios.get(`/courselearning/courseinstances/preferences`, course).then((response) => response.data.data);
    },
    UPDATE_COURSE_USER_PREFERENCES({ commit }, course) {
        return axios.post(`/courselearning/courseinstances/preferences`, course).then((response) => response.data.data);
    },
    GET_COURSE_PICTURE({ commit }) {
        return axios
            .get(`/courselearning/coursepicture`, { responseType: 'blob' })
            .then((response) => response.data)
            .then((response) => {
                const fileReader = new FileReader();
                fileReader.addEventListener(
                    'load',
                    () => {
                        commit('updateDefaultPicture', fileReader.result);
                    },
                    false
                );
                fileReader.readAsDataURL(response);
            });
    },
    COPY_COURSE({ commit }, request) {
        const { id: courseTempId } = request;
        return axios
            .get(`/courselearning/courseinstances/${courseTempId}/copy/INSTANCE`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateAdminCourses', response);
            });
    }
};

const mutations = {
    clear: (state) => {
        state.adminCourses = [];
        state.attendeeCourses = [];
        state.attendeePastCourses = [];
        state.attendeeCurrentCourses = [];
        state.selectedAdminCourse = {};
        state.selectedAttendeeCourse = {};
        state.courseBreadcrumbs = [];
        state.schedulingMyCourse = [];
        state.remainingCourseLicences = {};
        state.defaultCoursePicture = '';
    },
    updateSelectedTab: (state, value) => (state.tabs = value),
    updateDefaultPicture: (state, picture) => (state.defaultCoursePicture = picture),
    updateRemainingCourseLicences: (state, licences) => (state.remainingCourseLicences = licences),
    updateSelectedAdminCourse: (state, course) => (state.selectedAdminCourse = course),
    updateSelectedAttendeeCourse: (state, course) => (state.selectedAttendeeCourse = course),
    updateAllCourses: (state, response) => {
        if (Array.isArray(response)) {
            state.allCourses = response;
        } else {
            if (state.selectedAdminCourse.id == response.id) {
                state.selectedAdminCourse = response;
            }
            if (state.allCourses.map((course) => course.id).includes(response.id)) {
                state.allCourses = state.allCourses.map((course) => {
                    if (course.id === response.id) {
                        return response;
                    }
                    return course;
                });
            } else {
                state.allCourses.push(response);
            }
        }
    },
    updateAdminCourses: (state, response) => {
        if (Array.isArray(response)) {
            state.adminCourses = response;
        } else {
            if (state.selectedAdminCourse) {
                if (state.selectedAdminCourse.id == response.id) {
                    state.selectedAdminCourse = response;
                }
            }
            if (state.adminCourses.map((course) => course.id).includes(response.id)) {
                state.adminCourses = state.adminCourses.map((course) => {
                    if (course.id === response.id) {
                        return response;
                    }
                    return course;
                });
            } else {
                state.adminCourses.push(response);
            }
        }
    },
    updateAttendeeCourses: (state, response) => {
        if (Array.isArray(response)) {
            state.attendeeCourses = response;
        } else {
            if (state.selectedAttendeeCourse.id === response.id) {
                state.selectedAttendeeCourse = response;
            }
            if (state.attendeeCourses.map((course) => course.id).includes(response.id)) {
                state.attendeeCourses = state.attendeeCourses.map((course) => {
                    if (course.id === response.id) {
                        return response;
                    }
                    return course;
                });
            } else {
                state.attendeeCourses.push(response);
            }
        }
    },
    updateCurrentAttendeeCourses: (state, response) => {
        state.attendeeCurrentCourses = response;
    },
    updatePastAttendeeCourses: (state, response) => {
        state.attendeePastCourses = response;
    },
    deleteAdminCourse: (state, course) => {
        if (course.hasOwnProperty('id')) {
            if (state.selectedAdminCourse.id === course.id) {
                state.selectedAdminCourse = {};
            }
            const find = state.adminCourses.map((data) => data.id).indexOf(course.id);
            if (find !== -1) {
                state.adminCourses.splice(find, 1);
            }
        }
    },
    deleteAttendeeCourse: (state, course) => {
        if (course.hasOwnProperty('id')) {
            if (state.selectedAttendeeCourse.id === course.id) {
                state.selectedAttendeeCourse = {};
            }
            const find = state.attendeeCourses.map((data) => data.id).indexOf(course.id);
            if (find !== -1) {
                state.attendeeCourses.splice(find, 1);
            }
        }
    },
    deleteAllCourse: (state, course) => {
        if (course.hasOwnProperty('id')) {
            if (state.selectedAdminCourse.id === course.id) {
                state.selectedAdminCourse = {};
            }
            if (state.selectedAttendeeCourse.id === course.id) {
                state.selectedAttendeeCourse = {};
            }
            const find = state.allCourses.map((data) => data.id).indexOf(course.id);
            if (find !== -1) {
                state.allCourses.splice(find, 1);
            }
        }
    },
    updateScheduling: (state, response) => {
        if (Array.isArray(response)) {
            state.schedulingMyCourse = response;
        } else {
            if (state.schedulingMyCourse.map((thread) => thread.id).includes(response.id)) {
                state.schedulingMyCourse = state.schedulingMyCourse.map((thread) => {
                    if (thread.id === response.id) {
                        return response;
                    }
                    return thread;
                });
            } else {
                state.schedulingMyCourse.push(response);
            }
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
