<template>
    <v-dialog :value="showLoginCookies" persistent :fullscreen="$vuetify.breakpoint.xs" :width="$vuetify.breakpoint.thresholds.xs" scrollable>
        <v-card>
            <v-toolbar dense flat dark color="primary">
                <v-toolbar-title>{{ $t('Datenschutz Hinweis') }}</v-toolbar-title>
                <v-spacer />
                <v-btn text icon @click="closeLoginCookiesModalEmit">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>{{
                $t(
                    'Ein Schlüssel wird lokal in dem Browser auf dem Endgerät gepeichert, sobald der login erfolgreich war. Diese Daten werden nicht von Wiselab oder Drittanbieter verarbeitet. Wenn Sie “Eingeloggt bleiben” ausschalten, wird der Schlüssel gelöscht. Sie können jederzeit unabhängig von der Applikation diese Daten über die Browser Einstellungen löschen. Die Daten werden nach 14 Tagen inaktivität gelöscht.'
                )
            }}</v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn text @click="closeLoginCookiesModalEmit">{{ $t('Ablehnen') }}</v-btn>
                <v-btn color="primary" @click="acceptCookiesEmit">{{ $t('Akzeptieren') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        showLoginCookies: {
            default: false,
            type: Boolean
        }
    },
    methods: {
        closeLoginCookiesModalEmit() {
            this.$emit('closeLoginCookiesModalEmit');
        },
        acceptCookiesEmit() {
            this.$emit('acceptCookiesEmit');
        }
    }
};
</script>
