<template>
    <v-menu v-model="menu" offset-y :close-on-content-click="false" min-width="auto" :nudge-bottom="5">
        <template #activator="{ on, attrs }">
            <v-text-field prepend-icon="mdi-calendar" v-on="on" v-bind="attrs" readonly :label="label" :value="value" hide-details />
        </template>
        <v-date-picker header-color="primary" v-model="computedDate" :type="type" :min="min" :max="max" :locale="getSelectedLanguage" />
    </v-menu>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
    name: 'date-picker',
    data: () => ({
        menu: false
    }),
    props: {
        value: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'date',
            validator: (s) => ['date', 'month', 'year'].includes(s)
        },
        min: {
            type: String,
            default: null
        },
        max: {
            type: String,
            default: null
        },
        format: {
            type: String,
            default: 'YYYY-MM-DD'
        }
    },
    computed: {
        ...mapGetters('LanguageOperations', ['getSelectedLanguage']),
        computedDate: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', moment(value).format(this.format));
                this.menu = false;
            }
        }
    }
};
</script>
