import Plantool from '../../views/Plantool/Plantool';

import { superAdmin, admin } from '../Roles';

export default [
    /**
     * Plantool
     * @description
     */
    {
        path: '/Plantool',
        name: 'plantool',
        component: Plantool,
        meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionSet: ['GET_TASK'],
            requiresRole: true,
            roleSet: [superAdmin, admin],
            navigation: {
                type: { name: 'SingleGroup', groupId: 'Lernmanagement' },
                priority: 0,
                icon: 'mdi-clipboard-outline',
                name: 'Plantool',
                navigationKey: 'PLAN_TOOL'
            }
        }
    }
];
