<template>
    <v-card>
        <v-container>
            <v-row>
                <v-col :cols="12" :sm="6">
                    <v-select
                        :label="$t('Intervall von')"
                        v-model="computedInterval"
                        :items="statistics.interval.type"
                        item-value="title"
                        return-object
                        hide-details
                    >
                        <template v-slot:item="{ item: { title } }">
                            {{ $t(title) }}
                        </template>
                        <template v-slot:selection="{ item: { title } }">
                            {{ $t(title) }}
                        </template>
                    </v-select>
                </v-col>
                <v-col :cols="12" :sm="6">
                    <v-select
                        :label="$t('Gruppiert nach')"
                        v-model="computedGroup"
                        :items="statistics.group.type"
                        item-value="title"
                        return-object
                        hide-details
                    >
                        <template v-slot:item="{ item: { title } }">
                            {{ $t(title) }}
                        </template>
                        <template v-slot:selection="{ item: { title } }">
                            {{ $t(title) }}
                        </template>
                    </v-select>
                </v-col>
                <v-col :cols="12">
                    <PickerDate
                        :type="statistics.interval.selectedType.title"
                        :startDateText="statistics.interval.startDate"
                        :endDateText="statistics.interval.endDate"
                        @pickerStartDateEmit="pickerStartDateEmit"
                        @pickerEndDateEmit="pickerEndDateEmit"
                    />
                    <v-expand-transition>
                        <div v-show="statistics.interval.selectedType.title == 'Range'">
                            <v-label>
                                <span class="text-caption">{{ $t('Geteilt durch Tage') }}</span>
                            </v-label>
                            <v-slider
                                :label="dayDivided.toString()"
                                thumb-label
                                v-model="dayDivided"
                                always-dirty
                                step="1"
                                min="1"
                                :max="dynamicMaxRange"
                                @end="dayDividedEmit()"
                                @click="dayDividedEmit()"
                            />
                        </div>
                    </v-expand-transition>
                </v-col>
            </v-row>
            <v-expand-transition>
                <v-row v-show="showFilter">
                    <v-col :cols="6" v-if="isAdminStatistic">
                        <UserTreeSelect
                            :label="$t('Lernende')"
                            v-model="users"
                            @blur="onBlur"
                            :items="getUserTreeFilteredByUsers(filterList.users)"
                            @setGroupId="setGroupIds($event, 'userGroups')"
                        />
                    </v-col>
                    <v-col :cols="6" v-if="isAdminStatistic">
                        <UserTreeSelect
                            :label="$t('Lehrende / Admin')"
                            v-model="admins"
                            @blur="onBlur"
                            :items="getAdminTreeFilteredByUsers(filterList.users)"
                            @setGroupId="setGroupIds($event, 'adminGroups')"
                        />
                    </v-col>
                    <v-col :cols="12" :sm="6">
                        <v-select
                            v-model="catalogs"
                            @blur="onBlur"
                            :label="$t('Kataloge')"
                            :items="filterList.catalogs"
                            item-text="name"
                            item-value="id"
                            return-object
                            hide-details
                            :disabled="!hasFilterList('catalogs')"
                            multiple
                        />
                    </v-col>
                    <v-col :cols="12" :sm="6">
                        <v-select
                            v-model="knowledgeUnits"
                            @blur="onBlur"
                            :label="$t('Wissenseinheiten')"
                            :items="filterList.knowledgeUnits"
                            item-text="question"
                            item-value="id"
                            return-object
                            hide-details
                            :disabled="!hasFilterList('knowledgeUnits')"
                            multiple
                        />
                    </v-col>
                    <v-col :cols="12">
                        <v-select
                            v-model="tasks"
                            @blur="onBlur"
                            :label="$t('Aufgaben')"
                            :items="filterList.tasks"
                            item-text="name"
                            item-value="id"
                            return-object
                            hide-details
                            :disabled="!hasFilterList('tasks')"
                            multiple
                        />
                    </v-col>
                </v-row>
            </v-expand-transition>
        </v-container>
        <v-divider />
        <v-card-actions>
            <v-btn block color="secondary" @click="hideShowFilter()">
                <v-icon>{{ computedIcon }}</v-icon>
                {{ $t('Filter') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import PickerDate from '../../Components/Generic/PickerDate/PickerDate';
import UserTreeSelect from '../../Components/Generic/Tree/UserTreeSelect';
import UserMixin from '../../Components/Users/Mixins/UserMixin';
import moment from 'moment';

export default {
    name: 'dashboard-manager',
    components: {
        PickerDate,
        UserTreeSelect
    },
    mixins: [UserMixin],
    data() {
        return {
            showFilter: false,
            dayDivided: 1,
            users: [],
            admins: [],
            catalogs: [],
            knowledgeUnits: [],
            tasks: [],
            userGroups: [],
            adminGroups: []
        };
    },
    props: {
        statistics: {
            type: Object,
            default: () => ({})
        },
        filterList: {
            type: Object,
            default: () => ({
                users: [],
                catalogs: [],
                knowledgeUnits: [],
                tasks: [],
                userGroups: [],
                adminGroups: []
            })
        },
        value: {
            type: Object,
            default: () => ({
                users: [],
                catalogs: [],
                knowledgeUnits: [],
                tasks: [],
                userGroups: [],
                adminGroups: []
            })
        },
        isAdminStatistic: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        value(val) {
            Object.entries(val).forEach(([key, value]) => {
                if (this.$data.hasOwnProperty(key)) {
                    if (key !== 'users') {
                        this.$data[key] = value;
                    } else {
                        this.users = value.filter(({ role }) => role === 'USER');
                        this.admins = value.filter(({ role }) => role === 'ADMIN');
                    }
                }
            });
        }
    },
    computed: {
        dynamicMaxRange() {
            return moment(this.statistics.interval.endDate).diff(moment(this.statistics.interval.startDate), 'days') + 1;
        },
        computedIcon() {
            return this.showFilter ? 'mdi-chevron-up' : 'mdi-chevron-down';
        },
        computedInterval: {
            get() {
                return this.statistics.interval.selectedType;
            },
            set(value) {
                this.intervalBy(value);
                this.dayDivided = 1;
                this.$emit('dayDividedEmit', 1);
                this.$nextTick(() =>
                    this.$emit('input', {
                        users: [],
                        catalogs: [],
                        knowledgeUnits: [],
                        tasks: [],
                        userGroups: [],
                        adminGroups: []
                    })
                );
            }
        },
        computedGroup: {
            get() {
                return this.statistics.group.selectedType;
            },
            set(value) {
                this.$nextTick(() =>
                    this.$emit('input', {
                        users: [],
                        catalogs: [],
                        knowledgeUnits: [],
                        tasks: [],
                        userGroups: [],
                        adminGroups: []
                    })
                );
                this.groupBy(value);
            }
        }
    },
    methods: {
        hasFilterList(key) {
            return this.filterList[key] && Array.isArray(this.filterList[key]) ? this.filterList[key].length > 0 : false;
        },
        setGroupIds(group, type) {
            if (group.isChecked) {
                this.value[type].push({ id: group.id });
            } else {
                const ind = this.value[type].findIndex((obj) => obj.id === group.id);
                this.value[type].splice(ind, 1);
            }
        },
        onBlur(event) {
            if (event instanceof FocusEvent) {
                this.$emit('input', {
                    users: [...this.users, ...this.admins],
                    catalogs: this.catalogs,
                    knowledgeUnits: this.knowledgeUnits,
                    tasks: this.tasks,
                    userGroups: this.userGroups,
                    adminGroups: this.adminGroups
                });
            }
        },
        hideShowFilter() {
            this.showFilter = !this.showFilter;
        },
        intervalBy(interval) {
            this.$emit('intervalByEmit', interval);
        },
        groupBy(group) {
            this.$emit('groupByEmit', group);
        },
        pickerStartDateEmit(val) {
            this.$emit('startDateEmit', val);
        },
        pickerEndDateEmit(val) {
            this.$emit('endDateEmit', val);
        },
        dayDividedEmit() {
            this.$emit('dayDividedEmit', this.dayDivided);
        }
    }
};
</script>
