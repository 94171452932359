<template>
    <v-card>
        <v-toolbar flat dense dark color="secondary">
            <v-toolbar-title>{{ $t('Vorschau') }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <component v-if="isFile" :is="file" :src="value.fileURL" />
        </v-card-text>
    </v-card>
</template>

<script>
import ImageLoader from '../Generic/ImageLoader';
import FilePreviewVideo from './FilePreviewTypes/FilePreviewVideo';
import FilePreviewAudio from './FilePreviewTypes/FilePreviewAudio';
import FilePreviewPDF from './FilePreviewTypes/FilePreviewPDF';

export default {
    components: {
        ImageLoader,
        FilePreviewVideo,
        FilePreviewAudio,
        FilePreviewPDF
    },
    data: () => ({}),
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        computedWidth() {
            if (this.fileType === 'application') {
                return this.$vuetify.breakpoint.thresholds.sm;
            }
            return this.$vuetify.breakpoint.thresholds.xs;
        },
        isFile() {
            return Object.keys(this.value).length > 0 && this.value.hasOwnProperty('fileType') && this.value.fileURL !== '';
        },
        file() {
            switch (this.fileType) {
                case 'image':
                    return ImageLoader;
                case 'video':
                    return FilePreviewVideo;
                case 'audio':
                    return FilePreviewAudio;
                case 'application':
                    return FilePreviewPDF;
            }
            return '';
        },
        fileType() {
            if (this.isFile) {
                if (this.value.fileType === undefined) {
                    return undefined;
                }
                return this.value.fileType.substring(0, this.value.fileType.lastIndexOf('/'));
            }
            return '';
        }
    }
};
</script>
