<template>
    <div>
        <v-dialog v-model="showProfile" :width="$vuetify.breakpoint.thresholds.sm" :fullscreen="$vuetify.breakpoint.xs" persistent>
            <template v-slot:activator="{ on }">
                <v-card flat v-on="on">
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col :cols="4">
                                    <UserProfile :value="getLoggedInAdmin" :displayName="false" disabled />
                                </v-col>
                                <v-col :cols="8" class="text-truncate">
                                    <v-row no-gutters align="center" justify="center">
                                        <span class="col-12 text-truncate text-center text-h6"> {{ getLoggedInAdmin.displayName }} </span>
                                        <span class="col-12 text-truncate text-center text-caption"> {{ getLoggedInAdmin.email }} </span>
                                        <span class="col-12 text-truncate text-center text-caption"> {{ $t(getLoggedInAdmin.role) }} </span>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </template>
            <v-card>
                <v-toolbar flat dense dark class="secondary">
                    <v-toolbar-title>{{ $t('Profil') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn text icon @click="showProfile = false">
                        <v-icon color="white">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-container class="pa-0 ma-0">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <Profile />
                        </v-col>
                        <v-col cols="6">
                            <ProfileOptions :value="self" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import UserProfile from '../Generic/Profile/UserProfile.vue';
import UserViewsMixins from '../Users/Mixins/UserViewsMixins';
import Profile from '../Profile/Helpers/Profile.vue';
import ProfileOptions from '../Profile/Helpers/ProfileOptions.vue';

export default {
    components: {
        Profile,
        ProfileOptions,
        UserProfile
    },
    mixins: [UserViewsMixins],
    data: () => ({
        showProfile: false,
        validData: false,
        self: {}
    }),
    watch: {
        getOptData(value) {
            this.options = { ...value };
        }
    },
    computed: {},
    methods: {
        serialize() {
            return {
                notificationFrequency: this.options.notificationFrequency,
                opts: this.options.opts.map(({ userWants: allow, templateName }) => {
                    allow, templateName;
                })
            };
        }
    },
    mounted() {
        this.self = JSON.parse(JSON.stringify(this.getLoggedInAdmin));
        this.GET_USER_MAIL_OPTIONS();
    }
};
</script>

<style>
.selectableCursor {
    cursor: pointer;
}
.overflowtext {
    text-overflow: ellipsis;
}
.dialogScrollLimit {
    overflow-y: auto;
    max-height: 500px !important;
}
</style>
