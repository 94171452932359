<template>
    <v-dialog :value="showErrors" persistent :width="$vuetify.breakpoint.thresholds.xs">
        <v-card>
            <v-toolbar dense flat dark color="primary">
                <v-toolbar-title>{{ $t('Fehler beim anmelden') }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon text @click="closeErrorDialogEmit">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="text-center text-h6 font-weight-light">
                <div v-for="(error, index) in errors" :key="`error-${index}`">
                    {{ $t(error.message) }}
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'error-login',
    props: {
        showErrors: {
            default: false,
            type: Boolean
        },
        errors: {
            default: () => [],
            type: Array
        }
    },
    methods: {
        closeErrorDialogEmit() {
            this.$emit('closeErrorDialogEmit');
        }
    }
};
</script>
