<template>
    <div>
        <!-- TOOLBAR -->
        <v-app-bar flat text color="primary" :clipped-left="false" dark :fixed="isFixed" :app="isApp" :extended="isLoading" :extension-height="extendedHeight">
            <v-app-bar-nav-icon @click.stop="drawerPressed()" v-if="showDrawer"></v-app-bar-nav-icon>
            <v-app-bar-nav-icon @click="closeEmit()" v-if="showCloseButton">
                <v-icon>mdi-close</v-icon>
            </v-app-bar-nav-icon>
            <v-app-bar-nav-icon @click="backEmit()" v-if="!showDrawer && !showCloseButton">
                <v-icon>mdi-chevron-left</v-icon>
            </v-app-bar-nav-icon>

            <v-toolbar-title>{{ toolbarTitle }}</v-toolbar-title>
            <v-spacer />
            <!-- ADD BUTTON -->
            <v-btn v-if="showAddButton" icon @click="addItemEmit">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <!-- CUSTOM BUTTON -->
            <v-btn v-if="showCustomButton" icon @click="customEmit">
                <v-icon>{{ customIcon }}</v-icon>
            </v-btn>
            <!-- INFO -->
            <v-tooltip v-if="showInfoButton" bottom max-width="500px">
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                        <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                </template>
                <div>{{ $t(infoMessage) }}</div>
            </v-tooltip>
            <!-- TOOLTIP -->
            <v-tooltip bottom max-width="500px">
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                        <v-icon>mdi-help-circle-outline</v-icon>
                    </v-btn>
                </template>
                <div>{{ $t(tooltipMessage) }}</div>
            </v-tooltip>
            <!-- MORE ACTION -->
            <div v-if="showMoreButton">
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="primary" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(menu, index) in menus" :key="'m-' + index" @click="moreActionSelectedEmit(menu)">
                            <v-list-item-action>
                                <v-icon :color="menu.color">{{ menu.icon }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ $t(menu.title) }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <div v-if="showTextRight">
                <b>{{ textRight }}</b>
            </div>
            <template v-slot:extension v-if="isLoading">
                <v-progress-linear indeterminate color="primary" />
            </template>
        </v-app-bar>

        <ErrorReporter />
    </div>
</template>

<script>
import ErrorReporter from '../Global/ErrorReporter';
import { mapGetters } from 'vuex';

export default {
    components: {
        ErrorReporter
    },
    data: () => ({
        extendedHeight: 0
    }),
    props: {
        menus: Array,
        tooltipMessage: {
            default: '',
            type: String
        },
        infoMessage: {
            default: '',
            type: String
        },
        showInfoButton: {
            default: false,
            type: Boolean
        },
        lastPath: null,
        isFixed: {
            default: true,
            type: Boolean
        },
        isApp: {
            default: true,
            type: Boolean
        },
        showMoreButton: {
            default: false,
            type: Boolean
        },
        showTextRight: {
            default: false,
            type: Boolean
        },
        showInBlock: {
            default: true,
            type: Boolean
        },
        showViewMode: {
            default: false,
            type: Boolean
        },
        showAdd: {
            default: false,
            type: Boolean
        },
        showCloseButton: {
            default: false,
            type: Boolean
        },
        showCustomButton: {
            default: false,
            type: Boolean
        },
        customIcon: {
            default: 'folder',
            type: String
        },
        textRight: String,
        toolbarTitle: String,
        showAddButton: Boolean,
        showDrawer: {
            default: true,
            type: Boolean
        }
    },
    computed: {
        ...mapGetters('LoadOperations', ['isLoading']),
        drawer: {
            get() {
                return this.getDrawerState;
            },
            set(value) {
                this.OPEN_DRAWER(value);
            }
        },
        hasSlot() {
            if (this.$slots.hasOwnProperty('default')) {
                return this.$slots.default.length > 0;
            }
            return false;
        }
    },
    methods: {
        backEmit() {
            if (this.lastPath != null || this.lastPath != undefined) {
                if (typeof this.lastPath === 'number') {
                    return this.$router.go(this.lastPath);
                }
                return this.$router.push(this.lastPath);
            } else {
                this.$emit('backEmit');
            }
        },
        closeEmit() {
            this.$emit('closeEmit', false);
        },
        moreActionSelectedEmit(menu) {
            if (menu.actionType == 'RESET') {
                this.$emit('resetFieldEmit');
            } else if (menu.actionType == 'ZOOMIN') {
                this.$emit('zoomInEmit');
            } else if (menu.actionType == 'ZOOMOUT') {
                this.$emit('zoomOutEmit');
            } else if (menu.actionType == 'HIDESHOWFOLDER') {
                this.$emit('hideShowFolder');
            } else {
                this.$emit('otherMenuActionEmit', menu.actionType);
            }
        },
        customEmit() {
            this.$emit('customEmit');
        },
        changeViewMode(mode) {
            this.$emit('changeViewModeEmit', mode);
        },
        drawerPressed() {
            this.drawer = !this.drawer;
        },
        searchingItemEmit(searchingValue) {
            this.$emit('searchingItemEmit', searchingValue);
        },
        addItemEmit() {
            this.$emit('addItemEmit');
        }
    },
    updated() {
        if (this.hasSlot) {
            this.extendedHeight = this.$refs.extended.clientHeight;
        }
    }
};
</script>
