<template>
    <v-card height="100%">
        <v-toolbar dark flat color="secondary">
            <v-btn icon @click="close" v-if="showExitButton">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ request.name }}</v-toolbar-title>
            <v-spacer />
        </v-toolbar>
        <v-container ma-0>
            <v-row>
                <v-col :cols="showCardAtBottom ? 12 : 9">
                    <v-card flat ref="card">
                        <v-card-text v-bind:style="{ color: isDark ? 'white' : 'black', fontSize: 1 + 'rem' }">
                            {{ request.description }}
                        </v-card-text>
                        <v-card-title v-if="video && video[0]"> {{ insightTitle() }}</v-card-title>
                        <template v-if="video && video[0]">
                            <FilePreviewVideo :src="getVideo" />
                        </template>
                        <v-card-title v-if="request.targetgroup != ''"> {{ targetGroupTitle() }}</v-card-title>
                        <v-card-text
                            v-if="request.targetgroup != ''"
                            v-bind:style="{ color: isDark ? 'white' : 'black', fontSize: 1 + 'rem' }"
                            v-html="makeHtmlList(request.targetgroup)"
                        />
                        <v-card-title v-if="request.learninggoals != ''"> {{ learningGoalTitle() }}</v-card-title>
                        <v-card-text
                            v-if="request.learninggoals != ''"
                            v-bind:style="{ color: isDark ? 'white' : 'black', fontSize: 1 + 'rem' }"
                            v-html="makeHtmlList(request.learninggoals)"
                        />
                        <v-card-title v-if="request.competencies != ''"> {{ competenciesTitle() }} </v-card-title>
                        <v-card-text v-if="request.competencies != ''">
                            <Tags :items="stringToArray(request.competencies)" />
                        </v-card-text>
                        <v-card-title v-if="request.tags !== ''"> {{ keywordTitle() }} </v-card-title>
                        <v-card-text v-if="request.tags !== ''"> <Tags :items="stringToArray(request.tags)" /> </v-card-text>
                    </v-card>
                </v-col>
                <v-col v-if="!showCardAtBottom" :cols="3">
                    <v-card :elevation="12" outlined class="rounded-xl">
                        <ImageLoader :src="getPhoto()" :alignCenter="true" />
                        <v-card-subtitle v-if="request.germanSubCategory">
                            <v-icon> mdi-target </v-icon>
                            {{ germanSubCategory }}
                        </v-card-subtitle>
                        <v-card-subtitle>
                            <v-icon> mdi-clock-outline </v-icon>
                            {{ timeText() }}
                        </v-card-subtitle>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="showCardAtBottom">
                <v-col :cols="12">
                    <v-card :elevation="12" outlined class="rounded-xl">
                        <ImageLoader :src="getPhoto()" :alignCenter="true" />
                        <v-card-subtitle v-if="request.germanSubCategory">
                            <v-icon> mdi-target </v-icon>
                            {{ germanSubCategory }}
                        </v-card-subtitle>
                        <v-card-subtitle>
                            <v-icon> mdi-clock-outline </v-icon>
                            {{ timeText() }}
                        </v-card-subtitle>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import ImageLoader from '../../Generic/ImageLoader';
import Tags from '../../KnowledgeContent/KnowledgeContentTags';
import FilePreviewVideo from '../../FileManager/FilePreviewTypes/FilePreviewVideo';
import ScormMixin from '../Mixins/ScormMixin';

export default {
    name: 'scorm-preview',
    components: { Tags, ImageLoader, FilePreviewVideo },
    mixins: [ScormMixin],
    props: {
        request: {
            default: () => ({}),
            type: Object
        },
        showExitButton: {
            default: false,
            type: Boolean
        },
        video: {
            type: [Object, FileList],
            default: () => ({})
        },
        picture: {
            default: () => ({}),
            type: [Object, FileList]
        }
    },
    data: () => ({
        loadedVideo: '',
        loadedPicture: ''
    }),
    computed: {
        learningMinutes() {
            return `${this.request.time} ${this.$t('Minuten')}`;
        },
        germanSubCategory() {
            return this.request.germanSubCategory;
        },
        getLanguage() {
            if (this.request && this.request.language) {
                return this.request.language.substring(0, 2);
            }
            return 'de';
        },
        isDark() {
            return this.$vuetify.theme.dark;
        },
        playerWidth() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 300;
                case 'sm':
                    return 500;
                case 'md':
                    return 600;
                case 'lg':
                    return 800;
                case 'xl':
                    return 960;
                default:
                    return 960;
            }
        },
        playerHeight() {
            return this.playerWidth * 0.5;
        },
        getVideo() {
            return this.video && this.video[0] ? this.loadFile(this.video[0], 'video') : '';
        },
        showCardAtBottom() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return true;
                case 'sm':
                    return true;
                case 'md':
                    return false;
                case 'lg':
                    return false;
                case 'xl':
                    return false;
                default:
                    return false;
            }
        }
    },
    methods: {
        changeDateFormate(date) {
            let newDate = date.slice(8, 10) + '.' + date.slice(5, 7) + '.' + date.slice(0, 4);
            return newDate;
        },
        getPhoto() {
            return this.picture && this.picture[0] ? this.loadFile(this.picture[0], 'image') : '';
        },
        timeText() {
            if (!(this.request && this.request.time)) return 'Keine Zeit verfügbar';
            switch (this.getLanguage) {
                case 'de':
                    return this.request.time + ' Minuten';
                case 'en':
                    return this.request.time + ' minutes';
                default:
                    return this.request.time + ' Minuten';
            }
        },
        learningGoalTitle() {
            switch (this.getLanguage) {
                case 'de':
                    return 'Lernziele';
                case 'en':
                    return 'Learning Goals';
                default:
                    return 'Lernziele';
            }
        },
        competenciesTitle() {
            switch (this.getLanguage) {
                case 'de':
                    return 'Kompetenzen';
                case 'en':
                    return 'Competencies';
                default:
                    return 'Kompetenzen';
            }
        },
        targetGroupTitle() {
            switch (this.getLanguage) {
                case 'de':
                    return 'Zielgruppe';
                case 'en':
                    return 'Target Group';
                default:
                    return 'Zielgruppe';
            }
        },
        keywordTitle() {
            switch (this.getLanguage) {
                case 'de':
                    return 'Schlagwörter';
                case 'en':
                    return 'Keywords';
                default:
                    return 'Schlagwörter';
            }
        },
        insightTitle() {
            switch (this.getLanguage) {
                case 'de':
                    return 'Einblicke in das E-Learning';
                case 'en':
                    return 'Insight into E-Learning';
                default:
                    return 'Einblicke in das E-Learning';
            }
        },
        makeHtmlList(string) {
            var newString = string;
            newString = '<ul><li>' + newString + '</li></ul>';
            newString = newString.replace(/;/g, '</li><li>');
            return newString;
        },
        stringToArray(prop) {
            if (!prop) {
                return [];
            }
            if (prop.split(';').length > 1) {
                return prop.split(';');
            }
            return prop.split(',');
        },
        close() {
            this.$emit('close');
        },
        loadFile(file, fileType) {
            new Promise((resolve) => {
                const fileReader = new FileReader();
                fileReader.onload = () => {
                    if (fileType == 'video') {
                        this.loadedVideo = fileReader.result;
                    }
                    if (fileType == 'image') {
                        this.loadedPicture = fileReader.result;
                    }
                };
                fileReader.readAsDataURL(file);
            }).catch(() => {
                if (fileType == 'video') {
                    this.loadedVideo = '';
                }
                if (fileType == 'image') {
                    this.loadedPicture = '';
                }
            });

            return fileType == 'video' ? this.loadedVideo : this.loadedPicture;
        }
    }
};
</script>

<style></style>
