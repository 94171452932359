<template>
    <v-card text>
        <v-tabs v-model="tabs" mandatory>
            <v-tab key="SELECT">{{ $t('Kataloge auswählen') }}</v-tab>
            <v-tab key="SELECTED" :disabled="value.length === 0">
                <v-badge right color="primary">
                    <template v-slot:badge>
                        <span>{{ value.length }}</span>
                    </template>
                    {{ $t('Ausgewählte kataloge') }}
                </v-badge>
            </v-tab>
        </v-tabs>
        <v-card-text>
            <v-tabs-items v-model="tabs" touchless>
                <v-tab-item key="SELECT">
                    <CatalogSelector v-model="computedValue" />
                </v-tab-item>
                <v-tab-item key="SELECTED">
                    <CatalogList
                        v-model="computedValue"
                        @removeCatalog="handleRemoveCatalog"
                        @editCatalogOptions="handleEditCatalogOptions"
                        @excludeKnowledgeUnits="handleExcludeKnowledgeUnits"
                        @globalConfig="handleGlobalConfig"
                    />
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
        <QuizCatalogConfigurator
            v-model="showGlobalConfiguratorModal"
            @options="selectedCatalogOptions({ ...$event, applyToAllCatalogs: true })"
            :enableGlobalConfig="false"
        />
        <QuizCatalogConfigurator
            v-model="showConfiguratorModal"
            isCourseQuiz
            @isGlobal="isGlobal"
            :globalSwitch="globalSwitch"
            :label="catalogOptions.name"
            :options="catalogOptions.options"
            @options="selectedCatalogOptions"
            :isTask="isTask"
        />
    </v-card>
</template>

<script>
import CatalogList from './QuizActivityCatalogs';
import QuizCatalogConfigurator from './QuizCatalogConfigurator';
import CatalogSelector from '../../../../ArchiveTool/ArchiveCatalogSelect';

export default {
    name: 'quiz-activity-catalogs-switch',
    components: { CatalogList, CatalogSelector, QuizCatalogConfigurator },
    data: () => ({
        tabs: 0,
        showConfiguratorModal: false,
        showCatalogGlobalOptions: false,
        showGlobalConfiguratorModal: false,
        catalogOptions: {},
        globalSwitch: false
    }),
    props: {
        value: {
            type: Array,
            default: () => []
        },
        isTask: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        value(value) {
            if (!value.every((catalog) => catalog.hasOwnProperty('options'))) {
                this.$emit(
                    'input',
                    value.map((catalog) => {
                        if (catalog.hasOwnProperty('options')) {
                            return catalog;
                        }
                        return {
                            ...catalog,
                            options: {
                                showKnowledgeBase: true,
                                showExplanation: true,
                                answerTimeLimit: 100,
                                passingQuote: 0,
                                archiveImmediately: false,
                                archiveOnEndDate: false,
                                reopenKnowledgeBase: true,
                                archive: false,
                                archiveOnPass: false,
                                onFailure: 'REPEAT',
                                onFailureList: ['REPEAT', 'END'],
                                sortBy: 'NATURAL',
                                sortList: ['NATURAL', 'RANDOM', 'DATE']
                            }
                        };
                    })
                );
            }
        }
    },
    computed: {
        computedValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        handleGlobalConfig() {
            this.showGlobalConfiguratorModal = true;
        },
        selectedCatalogOptions(val) {
            if (val && val.applyToAllCatalogs) {
                this.$emit(
                    'input',
                    this.value.map((catalog) => {
                        catalog.options = { ...val };
                        return catalog;
                    })
                );
            } else {
                this.$emit(
                    'input',
                    this.value.map(({ id, ...catalog }) => {
                        if (id === this.catalogOptions.id) {
                            return { id, ...catalog, options: val };
                        }
                        return { id, ...catalog };
                    })
                );
            }
        },
        isGlobal(value) {
            this.globalSwitch = value;
        },
        handleEditCatalogOptions(value) {
            this.catalogOptions = value;
            this.showConfiguratorModal = true;
        },
        handleExcludeKnowledgeUnits(value) {
            this.$emit('excludeKnowledgeUnits', value);
        },
        handleRemoveCatalog(value) {
            this.$emit(
                'input',
                this.value.filter((catalog) => catalog.id !== value.id)
            );
        }
    }
};
</script>
