const state = {
    showSelectedImage: false,
    selectedImage: {
        src: '',
        width: '',
        height: '',
        naturalHeight: '',
        naturalWidth: ''
    }
};

const getters = {
    getSelectedImage() {
        return state.selectedImage;
    },
    getShowSelectedImage() {
        return state.showSelectedImage;
    }
};

const actions = {
    SET_SELECTED_IMAGE({ commit }, image) {
        if (image instanceof HTMLImageElement) {
            commit('updateSelectedImage', image);
        } else {
            throw new Error('Parameter must be Type of HTMLImageElement.');
        }
    },
    SET_SHOW_SELECTED_IMAGE({ commit }, show) {
        if (typeof show === 'boolean') {
            commit('updateShowSelectedImage', show);
        } else {
            throw new Error('Parameter must be Type of Boolean');
        }
    }
};

const mutations = {
    clear: (state) => {
        state.selectedImage = {
            src: '',
            width: '',
            height: '',
            naturalHeight: '',
            naturalWidth: ''
        };
        state.showSelectedImage = false;
    },
    updateSelectedImage: (state, image) => {
        if (image instanceof HTMLImageElement) {
            state.selectedImage.src = image.src;
            state.selectedImage.width = image.width;
            state.selectedImage.height = image.height;
            state.selectedImage.naturalWidth = image.naturalWidth;
            state.selectedImage.naturalHeight = image.naturalHeight;
        } else {
            throw new Error('Parameter is not an Instance of HTMLImageElement.');
        }
    },
    updateShowSelectedImage: (state, show) => {
        if (typeof show === 'boolean') {
            state.showSelectedImage = show;
        } else {
            throw new Error('Parameter is not an Instance of HTMLImageElement.');
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
