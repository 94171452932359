export default {
    questionType: [
        {
            type: 'CONTENT_ONLY',
            text: 'Nur Content',
            textShort: 'NW',
            color: 'pink lighten-3',
            colorHex: '#EC407A',
            textColor: 'white',
            description: 'Diese Wissenseinheit besteht nur aus Content'
        },
        {
            type: 'SINGLE_SELECT',
            text: 'Einzelauswahl',
            textShort: 'EA',
            color: 'blue lighten-1',
            colorHex: '#42A5F5',
            textColor: 'white',
            description: 'Wählen Sie genau eine Antwort aus mehreren Optionen'
        },
        {
            type: 'MULTI_SELECT',
            text: 'Mehrfachauswahl',
            textShort: 'MA',
            color: 'indigo lighten-1',
            colorHex: '#5C6BC0',
            textColor: 'white',
            description: 'Wählen Sie ein oder mehrere Antworten aus mehreren Optionen'
        },
        {
            type: 'TRUE_FALSE',
            text: 'Richtig/Falsch',
            textShort: 'WF',
            color: 'deep-orange lighten-1',
            colorHex: '#FF7043',
            textColor: 'white',
            description: 'Wählen Sie genau eine Antwort aus genau Zwei Optionen ( Richtig oder Falsch )'
        },
        {
            type: 'FILL_BLANK',
            text: 'Freitext',
            textShort: 'FR',
            color: 'teal lighten-1',
            colorHex: '#26A69A',
            textColor: 'white',
            description: 'Geben Sie eine Antwort-Phrase an'
        },
        {
            type: 'PICKER',
            text: 'Numerische Auswahl',
            textShort: 'NA',
            color: 'light-green lighten-1',
            colorHex: '#9CCC65',
            textColor: 'white',
            description: 'Wählen Sie eine Zahl aus einer vorgegebenen Spanne an'
        },
        {
            type: 'SEQUENCE',
            text: 'Sequenz',
            textShort: 'SQ',
            color: 'cyan lighten-1',
            colorHex: '#26C6DA',
            textColor: 'white',
            description: 'Ordnen Sie alle Optionen in die richtige Reihenfolge'
        }
    ]
};
