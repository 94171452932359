<template>
    <v-expansion-panels accordion v-if="items.length > 0">
        <v-expansion-panel>
            <v-expansion-panel-header>
                <v-toolbar-title class="text-body-2">{{ $t(label) }}</v-toolbar-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-container fluid grid-list-md>
                    <v-data-iterator
                        :items="items"
                        :no-data-text="$t('')"
                        :no-results-text="$t('')"
                        :items-per-page="5"
                        :footer-props="{
                            'items-per-page-text': $t('Link pro Seite'),
                            'items-per-page-options': [5, 10, 15, { value: -1, text: $t('Alle') }]
                        }"
                        :hideDefaultFooter="items.length <= 5"
                        justify-space-around
                        row
                        wrap
                    >
                        <template v-slot:item="{ item }">
                            <v-row shrink>
                                <span class="primary--text pointer" @click="onClick(item)">{{ item.title }}</span>
                            </v-row>
                        </template>
                    </v-data-iterator>
                </v-container>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>
<script>
export default {
    name: 'wiki-knowledge-content-links',
    props: {
        items: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: 'Links'
        }
    },
    methods: {
        onClick(link) {
            if (!this.disabled) {
                this.$emit('input', link);
            }
        }
    }
};
</script>
