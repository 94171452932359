import GroupForumsGroups from '../../views/GroupForum/GroupForumGroups';
import GroupForumsForums from '../../views/GroupForum/GroupForumForums';
import GroupForumsThreads from '../../views/GroupForum/GroupForumThreads';
import GroupForumsAnswers from '../../views/GroupForum/GroupForumAnswers';

import { superAdmin, admin, user } from '../Roles';

export default [
    {
        path: '/GroupForum/Groups',
        name: 'groupForumGroups',
        component: GroupForumsGroups,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin, admin, user],
            navigation: {
                type: { name: 'Groups', groupId: 'Community' },
                priority: 0,
                icon: 'mdi-forum-outline',
                name: 'Gruppen Foren',
                navigationKey: 'GROUP_FORUMS'
            }
        }
    },
    {
        path: '/GroupForum/Forums',
        name: 'groupForumForums',
        component: GroupForumsForums,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin, admin, user]
        }
    },
    {
        path: '/GroupForum/Threads',
        name: 'groupForumThreads',
        component: GroupForumsThreads,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin, admin, user]
        }
    },
    {
        path: '/GroupForum/Answers',
        name: 'groupForumAnswers',
        component: GroupForumsAnswers,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [superAdmin, admin, user]
        }
    }
];
