<template>
    <v-card>
        <v-toolbar dense flat dark color="primary">
            <v-toolbar-title>{{ $t('Nutzerdetails') }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <chart-detail-row :label="$t('Angezeigter Name')" :display="user.displayName" />
            <chart-detail-row :label="$t('Vorname')" :display="user.firstName" />
            <chart-detail-row :label="$t('Nachname')" :display="user.lastName" />
            <chart-detail-row :label="$t('E-Mail')" :display="user.email" />
            <chart-detail-row :label="$t('Personalnummer')" :display="user.personalNumber" />
            <chart-detail-row :label="$t('Rolle')" :display="role" />
            <GChart type="BarChart" :class="{ dark: isDark }" :data="formatBarChartData" :options="barChartOptions" />
        </v-card-text>
    </v-card>
</template>
<script>
import { GChart } from 'vue-google-charts';
import ChartDetailRow from '../Helpers/chartDetailRow';

export default {
    name: 'user-chart-details',
    components: { GChart, ChartDetailRow },
    data: () => ({}),
    computed: {
        tasks() {
            return this.value.tasks;
        },
        user() {
            return this.value.userDetails;
        },
        role() {
            switch (this.value.userDetails.role) {
                case 'SUPER_ADMIN':
                    return 'Super Admin';
                case 'ADMIN':
                    return 'Admin';
                case 'USER':
                    return 'User';
            }
            return 'User';
        },
        formatBarChartData() {
            if (this.value.hasOwnProperty('votes')) {
                return [
                    [this.$t('Ergebnis'), this.$t('Richtig'), this.$t('Falsch'), this.$t('Unbeantwortet')],
                    [this.$t('Ergebnis'), this.value.votes.correct, this.value.votes.incorrect, this.value.votes.unanswered]
                ];
            }
            return [
                [this.$t('Ergebnis'), this.$t('Richtig'), this.$t('Falsch'), this.$t('Unbeantwortet')],
                [this.$t('Ergebnis'), 0, 0, 0]
            ];
        },
        barChartOptions() {
            return {
                title: this.$t('Intervallergebnis'),
                titleTextStyle: {
                    color: this.isDark ? '#FFF' : '#000'
                },
                width: '100%',
                height: 200,
                isStacked: 'percent',
                legend: { position: 'top', maxLines: 3 },
                backgroundColor: this.isDark ? '#1E1E1E' : '#FFF',
                vAxis: {
                    format: 'percent',
                    titleTextStyle: { color: this.isDark ? '#FFF' : '#000' }
                },
                hAxis: {
                    minValue: 0,
                    format: 'percent',
                    ticks: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
                    titleTextStyle: { color: this.isDark ? '#FFF' : '#000' }
                },
                series: {
                    0: { color: this.$vuetify.theme.currentTheme.success },
                    1: { color: this.$vuetify.theme.currentTheme.error },
                    2: { color: this.$vuetify.theme.currentTheme.accent }
                },
                tooltip: { isHtml: this.isDark }
            };
        },
        isDark() {
            return this.$vuetify.theme.dark;
        }
    },
    props: {
        value: {
            type: Object
        }
    },
    methods: {
        mappedCatalog(catalogId, catalogs) {
            let test = catalogs.find((catalog) => {
                if (catalog.hasOwnProperty('id')) {
                    return catalog.id === catalogId;
                }
                return false;
            });
            return typeof test !== 'undefined' ? test : '';
        }
    }
};
</script>
