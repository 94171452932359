<template>
    <v-snackbar multi-line v-model="showNotification" :timeout="getNotificationTimeout" bottom left>
        {{ $t(getNotificationMessages) }}
        <v-btn color="red" text @click="closeSnackbar">{{ $t('Schließen') }}</v-btn>
    </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    computed: {
        ...mapGetters('NotificationOperations', ['getNotificationMessages', 'getNotificationTimeout', 'getNotificationSwitch']),
        showNotification: {
            get() {
                return this.getNotificationMessages !== 'undefined' && this.getNotificationMessages.trim() !== '' && this.getNotificationSwitch;
            },
            set(val) {
                this.SET_NOTIFICATION_SWITCH(val);
            }
        }
    },
    methods: {
        ...mapActions('NotificationOperations', ['SET_NOTIFICATION_MESSAGE', 'SET_NOTIFICATION_SWITCH']),
        closeSnackbar() {
            this.SET_NOTIFICATION_MESSAGE('');
        }
    }
};
</script>
