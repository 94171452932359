<template>
    <v-card :width="$vuetify.breakpoint.thresholds.xs">
        <v-tabs show-arrows v-model="tabs">
            <v-tab :key="0">{{ $t('Überblick') }}</v-tab>
            <v-tab :key="1">{{ $t('Optionen') }}</v-tab>
            <v-tab :key="2">{{ $t('Kataloge') }}</v-tab>
            <v-tab :key="3">{{ $t('Nutzer') }}</v-tab>
            <v-tab :key="4">{{ $t('Verwalter') }}</v-tab>
        </v-tabs>
        <v-container>
            <v-tabs-items v-model="tabs" touchless>
                <v-tab-item :key="0">
                    <v-container fluid>
                        <v-row>
                            <v-col :cols="12">
                                <v-text-field :label="$t('Name')" :value="value.name" readonly hide-details />
                            </v-col>
                            <v-col :cols="12">
                                <v-text-field :label="$t('Startdatum')" :value="value.startDate" readonly hide-details />
                            </v-col>
                            <v-col :cols="12">
                                <v-text-field :label="$t('Enddatum')" :value="value.endDate" readonly hide-details />
                            </v-col>
                            <v-col :cols="12">
                                <slot />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item :key="1">
                    <v-container fluid>
                        <v-row>
                            <v-col :cols="12" :sm="6">
                                <v-text-field
                                    :label="$t('Erweitertes Enddatum')"
                                    :value="value && value.taskOptions && value.taskOptions.extendEndDate ? value.taskOptions.extendEndDate : $t('Nein')"
                                    hide-details
                                    readonly
                                />
                            </v-col>
                            <v-col :cols="12" :sm="6">
                                <v-text-field
                                    :label="$t('Wissenseinheiten limitieren [Microlearning]')"
                                    :value="value && value.taskOptions && value.taskOptions.limits ? value.taskOptions.limits.toString() : $t('Nein')"
                                    readonly
                                    hide-details
                                />
                            </v-col>
                            <v-col :cols="12" :sm="6">
                                <v-text-field
                                    :label="$t('Bestehensquote')"
                                    :value="
                                        value && value.taskOptions && value.taskOptions.hasPassingQuote ? `${value.taskOptions.passingQuote}%` : $t('Keine')
                                    "
                                    readonly
                                    hide-details
                                />
                            </v-col>
                            <v-col :cols="12" :sm="6">
                                <v-text-field
                                    :label="$t('Katalog Reihenfolge')"
                                    :value="value && value.taskOptions && value.taskOptions.sortBy ? $t(value.taskOptions.sortBy) : ''"
                                    readonly
                                    hide-details
                                />
                            </v-col>
                            <v-col :cols="12">
                                <v-switch
                                    :label="$t('Fortlaufende Aufgabe')"
                                    :input-value="value && value.taskOptions && value.taskOptions.loopingTask ? value.taskOptions.loopingTask : false"
                                    readonly
                                    hide-details
                                />
                            </v-col>
                            <v-col :cols="12" :sm="6">
                                <v-switch
                                    :label="$t('Zertifikat ausstellen')"
                                    :input-value="value && value.taskOptions && value.taskOptions.hasPassingQuote ? value.taskOptions.hasPassingQuote : ''"
                                    readonly
                                    hide-details
                                />
                            </v-col>
                            <v-col :cols="12" :sm="6">
                                <v-switch
                                    :label="$t('Resultat anzeigen')"
                                    :input-value="value && value.taskOptions && value.taskOptions.showResult ? value.taskOptions.showResult : false"
                                    readonly
                                    hide-details
                                />
                            </v-col>
                            <v-col :cols="12" :sm="6">
                                <v-switch
                                    :label="$t('Unbeantwortete Wissenseinheiten anzeigen')"
                                    :input-value="
                                        value && value.taskOptions && value.taskOptions.hideUnansweredKnowledgeUnits
                                            ? value.taskOptions.hideUnansweredKnowledgeUnits
                                            : false
                                    "
                                    readonly
                                    hide-details
                                />
                            </v-col>
                            <v-col :cols="12" :sm="6">
                                <v-switch
                                    :label="$t('Zufällige Antwortoptionen')"
                                    :input-value="value && value.taskOptions && value.taskOptions.shuffleOptions ? value.taskOptions.shuffleOptions : false"
                                    readonly
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item :key="2">
                    <v-expansion-panels focussable>
                        <v-expansion-panel v-for="catalog in value.catalogs" :key="catalog.id">
                            <v-expansion-panel-header>
                                <span>{{ catalog.name }}</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-container fluid>
                                    <v-row>
                                        <v-col :cols="12">
                                            <v-textarea :label="$t('Beschreibung')" :value="catalog.description" no-resize outlined readonly hide-details />
                                        </v-col>
                                        <v-col :cols="12" :sm="6">
                                            <v-text-field
                                                :label="$t('Wissenseinheiten insgesamt')"
                                                :value="catalog.totalKnowledgeUnits"
                                                readonly
                                                hide-details
                                            />
                                        </v-col>
                                        <v-col :cols="12" :sm="6">
                                            <v-text-field :label="$t('Archivierung')" :value="archiveText(catalog.options)" readonly hide-details />
                                        </v-col>
                                        <v-col :cols="12" :sm="6">
                                            <v-text-field
                                                :label="$t('Katalog-Bestehensquote')"
                                                :value="catalog.options.passingQuote ? `${catalog.options.passingQuote}%` : $t('Keine')"
                                                readonly
                                                hide-details
                                            />
                                        </v-col>
                                        <v-col :cols="12" :sm="6">
                                            <v-text-field
                                                :label="$t('Wenn Katalog nicht bestanden')"
                                                :value="$t(catalog.options.onFailure)"
                                                readonly
                                                hide-details
                                            />
                                        </v-col>
                                        <v-col :cols="12" :sm="6">
                                            <v-text-field :label="$t('Katalogsortierung')" :value="$t(catalog.options.sortBy)" readonly hide-details />
                                        </v-col>
                                        <v-col :cols="12" :sm="6">
                                            <v-switch
                                                :label="$t('Wissensbasis anzeigen')"
                                                :input-value="catalog.options.showKnowledgeBase"
                                                readonly
                                                hide-details
                                            />
                                        </v-col>
                                        <v-col :cols="12" :sm="6">
                                            <v-switch
                                                :label="$t('Wissensbasis wiederöffnen')"
                                                :input-value="catalog.options.reopenKnowledgeBase"
                                                readonly
                                                hide-details
                                            />
                                        </v-col>
                                        <v-col :cols="12" :sm="6">
                                            <v-switch :label="$t('Erklärung anzeigen')" :input-value="catalog.options.showExplanation" readonly hide-details />
                                        </v-col>
                                        <v-col :cols="12" :sm="6">
                                            <v-switch
                                                :label="$t('Adaptive Learning')"
                                                :input-value="catalog.options.useSkipping"
                                                readonly
                                                hide-details
                                            ></v-switch>
                                        </v-col>
                                        <template v-if="catalog.options.useSkipping">
                                            <v-col :cols="12" :sm="3">
                                                <v-text-field
                                                    :label="$t('Skipping Percentage')"
                                                    :value="catalog.options.skippingPercentage * 100 + '%'"
                                                    readonly
                                                    hide-details
                                                />
                                            </v-col>
                                            <v-col :cols="12" :sm="3">
                                                <v-text-field
                                                    :label="$t('Tolerance')"
                                                    :value="catalog.options.skippingPercentageTolerance * 100 + '%'"
                                                    readonly
                                                    hide-details
                                                />
                                            </v-col>
                                        </template>
                                    </v-row>
                                </v-container>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-tab-item>
                <v-tab-item :key="3">
                    <v-data-iterator
                        :items="users"
                        :no-data-text="$t('Keine Nutzer verfügbar')"
                        :no-results-text="$t('Kein Ergebnis')"
                        :items-per-page="5"
                        :footer-props="{
                            'items-per-page-options': [5, 10, 15, { value: -1, text: $t('Alle') }]
                        }"
                        :hideDefaultFooter="users.length === 0"
                    >
                        <template v-slot:item="{ item }">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.displayName }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-data-iterator>
                </v-tab-item>
                <v-tab-item :key="4">
                    <v-data-iterator
                        :items="owners"
                        :no-data-text="$t('Keine Admin verfügbar')"
                        :no-results-text="$t('Kein Ergebnis')"
                        :items-per-page="5"
                        :footer-props="{
                            'items-per-page-options': [5, 10, 15, { value: -1, text: $t('Alle') }]
                        }"
                        :hideDefaultFooter="owners.length === 0"
                    >
                        <template v-slot:item="{ item }">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.displayName }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-data-iterator>
                </v-tab-item>
            </v-tabs-items>
        </v-container>
    </v-card>
</template>

<script>
import PlantoolMixin from '../Mixins/PlantoolMixin';

export default {
    name: 'calendar-task',
    mixins: [PlantoolMixin],
    data: () => ({
        tabs: 0,
        users: [],
        owners: []
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        value(value) {
            if (Object.keys(value).length > 0) {
                Promise.all([
                    this.GET_TASK_USERS(value).then((users) => {
                        this.$set(this.$data, 'users', users);
                    }),
                    this.GET_TASK_OWNERS(value).then((owners) => {
                        this.$set(this.$data, 'owners', owners);
                    })
                ]).then(() => {
                    this.tabs = 0;
                    this.$emit('change');
                });
            } else {
                this.tabs = 0;
                this.users = [];
                this.owners = [];
            }
        }
    },
    methods: {
        archiveText({ archive, archiveImmediately, archiveOnEndDate, archiveOnPass }) {
            if (archive) {
                if (archiveImmediately) {
                    return this.$t('Katalog ist archiviert');
                }
                if (archiveOnEndDate) {
                    return this.$t('Katalog wird zum Enddatum archiviert');
                }
                if (archiveOnPass) {
                    return this.$t('Katalog wird archiviert, wenn bestanden');
                }
            }
            return this.$t('Katalog wird nicht archiviert');
        }
    },
    mounted() {
        if (Object.keys(this.value).length > 0) {
            Promise.all([
                this.GET_TASK_USERS(this.value).then((users) => {
                    this.$set(this.$data, 'users', users);
                }),
                this.GET_TASK_OWNERS(this.value).then((owners) => {
                    this.$set(this.$data, 'owners', owners);
                })
            ]).then(() => {
                this.tabs = 0;
                this.$emit('change');
            });
        } else {
            this.users = [];
            this.owners = [];
        }
    }
};
</script>
