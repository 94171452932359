<template>
    <v-card>
        <v-toolbar text dark color="secondary">
            <v-toolbar-title>
                {{ $t('Quiz-Aktivität') }}
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text ref="test">
            <v-select color="accent" v-model="pickerType" :items="pickerTypeItems" attach item-value="value" item-text="name" @change="getQuizActivity">
                <template v-slot:item="{ item }">
                    <div>{{ $t(item.name) }}</div>
                </template>
                <template v-slot:selection="{ item }">
                    <div>{{ $t(item.name) }}</div>
                </template>
            </v-select>
            <div v-if="pickerType == 'Range'">
                <v-label>
                    <span class="text-caption">{{ $t('Geteilt durch Tage') }}</span>
                </v-label>
                <v-slider thumb-label v-model="dayDivided" always-dirty step="1" min="1" max="30" @end="getQuizActivity()" />
            </div>
            <PickerDate
                class="mb-3"
                :type="pickerType"
                :startDateText="computedStartDate"
                :endDateText="computedEndDate"
                @pickerStartDateEmit="(val) => (computedStartDate = val)"
                @pickerEndDateEmit="(val) => (computedStartDate = val)"
            />
            <v-container>
                <component
                    :is="ComponentSelect"
                    :type="chartTypeComputed"
                    :data="computedChartData()"
                    :options="chartOptions"
                    :class="{ dark: this.isDark }"
                    transition="fade-transition"
                    v-if="showChart"
                />
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import DashboardMixin from './Mixins/DashboardMixin';
import PickerDate from '../Generic/PickerDate/PickerDate';
import moment from 'moment';
import IntervalTypes from './Helpers/IntervalTypes';
import { GChart } from 'vue-google-charts';
import ExceptionChartDetails from '../Chart/ChartTypes/ExceptionChartDetails';
import DashboardResizeObserverMixin from './Mixins/DashboardResizeObserverMixin';

export default {
    name: 'quiz-activity',
    mixins: [DashboardMixin, DashboardResizeObserverMixin],
    components: { GChart, PickerDate, ExceptionChartDetails },
    data: () => ({
        pickerType: IntervalTypes[3].value,
        pickerTypeItems: IntervalTypes,
        startDate: '',
        endDate: '',
        dayDivided: 1,
        chartData: [],
        chartOptions: {},
        showChart: true
    }),
    computed: {
        serialize() {
            return {
                interval: {
                    startDate: this.computedStartDate,
                    endDate: this.computedEndDate,
                    type: this.pickerType.toUpperCase(),
                    groupDays: this.dayDivided,
                    month: this.getMonth(),
                    year: this.getYear()
                },
                groupBy: 'USERS',
                list: [],
                filter: {}
            };
        },
        chartTypeComputed() {
            if (this.pickerType == 'Day') return 'ColumnChart';
            else return 'LineChart';
        },
        chartTitleComputed() {
            if (this.pickerType == 'Year') return moment(this.computedStartDate).format('YYYY');
            else if (this.pickerType == 'Range' || this.pickerType == 'Week') {
                if (moment(this.computedStartDate).diff(this.computedEndDate) == 0)
                    return (
                        moment(this.computedStartDate).format('DD MMM') +
                        ' - ' +
                        moment(this.computedEndDate).format('DD MMM') +
                        ', ' +
                        moment(this.computedStartDate).format('YYYY')
                    );
                else return moment(this.computedStartDate).format('DD MMM, YYYY') + ' - ' + moment(this.computedEndDate).format('DD MMM, YYYY');
            } else if (this.pickerType == 'Day') return moment(this.computedStartDate).format('DD MMM, YYYY');
            else if (this.pickerType == 'Month') return this.$t(moment(this.computedStartDate).format('MMMM'));
            return '';
        },
        computedStartDate: {
            get() {
                if (this.startDate === '') {
                    return moment().format('YYYY-MM-DD');
                }
                return this.startDate;
            },
            set(value) {
                if (value.length == 7) {
                    this.startDate = value + '-01';
                } else this.startDate = value;

                this.getQuizActivity();
            }
        },
        computedEndDate: {
            get() {
                if (this.endDate === '') {
                    return moment().format('YYYY-MM-DD');
                }
                return this.endDate;
            },
            set(value) {
                this.endDate = value;
            }
        },
        ComponentSelect() {
            if (this.chartData.length === 0) {
                return ExceptionChartDetails;
            }
            return GChart;
        },
        isDark() {
            return this.$vuetify.theme.dark;
        }
    },
    watch: {
        isDark() {
            this.showChart = false;
            this.$nextTick(() => {
                setTimeout(() => {
                    this.showChart = true;
                }, 300);
            });
        }
    },
    methods: {
        getSizeX() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 300;
                case 'sm':
                    return 500;
                case 'md':
                    return 800;
                case 'lg':
                    return 900;
                case 'xl':
                    return 1000;
            }
        },
        getSizeY() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 200;
                case 'sm':
                    return 250;
                case 'md':
                    return 300;
                case 'lg':
                    return 350;
                case 'xl':
                    return 400;
            }
        },
        calculateChartOptions() {
            let width = this.$refs.test;
            if (width !== undefined) {
                width = width.clientWidth * 0.94;
            } else {
                width = this.getSizeX();
            }
            return {
                title: this.chartTitleComputed,
                titleTextStyle: {
                    fontSize: 24,
                    bold: true,
                    color: this.isDark ? '#FFF' : '#000'
                },
                width: width,
                height: this.getSizeY(),
                legend: 'none',
                backgroundColor: this.isDark ? '#1E1E1E' : '#FFF',
                vAxis: {
                    title: this.$t('Wissenseinheiten beantwortet'),
                    titleTextStyle: { color: this.isDark ? '#FFF' : '#000' }
                },
                animation: { startup: true, duration: 500, easing: 'out' },
                tooltip: { isHtml: this.isDark }
            };
        },
        computedChartData() {
            if (this.chartData.length === 0) {
                return [[this.$t('StartDatum'), this.$t('Aktivität')]];
            }
            let data = [];
            if (this.pickerType == 'Year') {
                data = [
                    [this.$t('StartDatum'), this.$t('Aktivität')],
                    ...this.chartData.map((data) => [this.$t(moment(data.interval.startDate).format('MMMM')), data.voteCount])
                ];
            } else if (this.pickerType == 'Month') {
                data = [
                    [this.$t('StartDatum'), this.$t('Aktivität')],
                    ...this.chartData.map((data) => [moment(data.interval.startDate).format('DD MMM'), data.voteCount])
                ];
            } else if (this.pickerType == 'Week') {
                data = [
                    [this.$t('StartDatum'), this.$t('Aktivität')],
                    ...this.chartData.map((data) => [moment(data.interval.startDate).format('DD-MM'), data.voteCount])
                ];
            } else if (this.pickerType == 'Day') {
                data = [[this.$t('StartDatum'), this.$t('Aktivität')], ...this.chartData.map((data) => [data.interval.startDate, data.voteCount])];
            } else if (this.pickerType == 'Range') {
                data = [
                    [this.$t('StartDatum'), this.$t('Aktivität')],
                    ...this.chartData.map((data) => [moment(data.interval.startDate).format('DD-MM'), data.voteCount])
                ];
            }
            return data;
        },
        getMonth() {
            return moment(this.computedStartDate).month() + 1;
        },
        getYear() {
            return moment(this.computedStartDate).year();
        },
        getQuizActivity() {
            this.onResize();
            this.GET_QUIZ_ACTIVITY(this.serialize).then((response) => {
                return this.$nextTick(() => (this.chartData = response.results));
            });
        },
        onResize() {
            this.$nextTick(() => (this.chartOptions = this.calculateChartOptions()));
        }
    },
    mounted() {
        this.startDate = moment().format('YYYY-MM-DD');
        this.getQuizActivity();
        this.registerResizeElement(this.$refs.test, this.onResize);
    }
};
</script>

<style lang="scss">
.dark svg g text {
    fill: #fff;
}
</style>
