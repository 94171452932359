<template>
    <v-data-iterator
        :items="catalogGroups"
        :no-data-text="$t('Keine Daten verfügbar')"
        :no-results-text="$t('Kein Ergebnis')"
        :items-per-page="5"
        :footer-props="{
            'items-per-page-options': [5, 10, 15, { value: -1, text: $t('Alle') }]
        }"
        :hideDefaultFooter="catalogGroups.length === 0"
    >
        <template #default="{ items }">
            <v-list>
                <v-list-item v-for="item in items" :key="item.id">
                    <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                        <v-list-item-subtitle class="text-truncate">
                            {{ item.description ? item.description : $t('Keine Beschreibung') }}
                        </v-list-item-subtitle>
                        <v-list-item-content>
                            <v-container>
                                <v-row>
                                    <v-col cols="auto" v-for="[key, val] in Object.entries(permissionMap)" :key="`${item.id}-${key}`">
                                        <permission-type-selector
                                            :permissionType="key"
                                            :checked="hasPermissions(item, val)"
                                            :value="val"
                                            :disabled="readOnly"
                                            @input="handleInput(item, $event)"
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-list-item-content>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-layout justify-center align-center fill-height>
                            <v-btn icon @click="routeToSubGroup(item)">
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-layout>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </template>
    </v-data-iterator>
</template>
<script>
import PermissionTypeSelector from './PermissionTypeSelector';
import AuthoringPermissionMixin from '../Mixins/AuthoringPermissionsMixin';
export default {
    name: 'profile-catalog-group',
    components: { PermissionTypeSelector },
    mixins: [AuthoringPermissionMixin],
    data: () => ({
        catalogGroups: [],
        permissionMap: []
    }),
    props: {
        value: {
            type: Array,
            default: () => []
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        value() {
            this.deserialize();
        }
    },
    methods: {
        serialize() {
            return this.catalogGroups
                .filter((group) => group.permissions.length > 0)
                .map((group) => ({
                    id: group.id,
                    permissions: group.permissions
                }));
        },
        deserialize() {
            if (Array.isArray(this.value)) {
                this.catalogGroups.forEach((group) => {
                    if (this.value.map((gr) => gr.id).includes(group.id)) {
                        let find = this.value.find((g) => g.id == group.id);
                        if (typeof find !== 'undefined') {
                            group.permissions = find.permissions;
                        }
                    } else {
                        group.permissions = [];
                    }
                });
            }
        },
        routeToSubGroup(catalogGroup) {
            this.SET_SELECTED_CATALOG_GROUP(catalogGroup);
            this.$emit('step');
        },
        handleInput(catalogGroup, input) {
            let deps = catalogGroup.permissions
                .filter((perm) => perm.hasOwnProperty('dependencies') && perm.dependencies.length > 0)
                .map((perm) => perm.dependencies)
                .flat()
                .map((perm) => perm.permissionCode);
            let inputWithDependencies = input
                .map((permission) => {
                    if (permission.hasOwnProperty('dependencies')) {
                        if (permission.dependencies.length > 0) {
                            return [permission, ...permission.dependencies];
                        }
                    }
                    return [permission];
                })
                .flat();

            if (catalogGroup.hasOwnProperty('permissions')) {
                if (this.hasPermissions(catalogGroup, input)) {
                    inputWithDependencies = inputWithDependencies.filter((permission) => !deps.includes(permission.permissionCode));
                    catalogGroup.permissions = catalogGroup.permissions
                        .filter((permission) => !inputWithDependencies.map((perm) => perm.permissionCode).includes(permission.permissionCode))
                        .filter((permission, index, acc) => acc.map((perm) => perm.permissionCode).indexOf(permission.permissionCode) === index);
                } else {
                    catalogGroup.permissions.push(...inputWithDependencies);
                }
                this.$emit('input', this.serialize());
            }
        },
        hasPermissions(catalogGroup, permissions) {
            if (catalogGroup.hasOwnProperty('permissions')) {
                return permissions
                    .map((permission) => permission.permissionCode)
                    .every((permission) => catalogGroup.permissions.map((perm) => perm.permissionCode).includes(permission));
            }
            return false;
        }
    },
    mounted() {
        this.getAuthoringPermissions(true).then((permissionMap) => (this.permissionMap = permissionMap));
        this.GET_CATALOGGROUP_LIST().then(() => {
            this.catalogGroups = [
                ...this.getCatalogGroupList.map((group) => ({
                    ...group,
                    permissions: []
                }))
            ];
            this.deserialize();
        });
    }
};
</script>
