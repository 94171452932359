<template>
    <v-select
        color="accent"
        :value="value"
        @input="handleInput"
        :items="groups"
        :disabled="!hasGroups"
        :hint="$t(`Wählen Sie ${translateGroups} aus, die Sie dem ${translateUser} zuweisen wollen`)"
        :no-data-text="$t(`Keine ${translateGroups} verfügbar`)"
        :label="translateGroups"
        persistent-hint
        multiple
        return-object
        item-value="id"
        prepend-icon="mdi-account-group"
    >
        <template v-slot:item="{ item: { name, description }, on, attrs }">
            <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-action>
                    <v-icon :color="attrs.inputValue ? 'accent' : ''">
                        {{ attrs.inputValue ? '$vuetify.icons.checkboxOn' : '$vuetify.icons.checkboxOff' }}
                    </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ description }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template>
        <template v-slot:selection="{ item }">
            <v-chip close @click:close="removeItem(item)" color="primary white--text">{{ item.name }}</v-chip>
        </template>
    </v-select>
</template>

<script>
import AdminGroupsMixin from '../Groups/Mixins/AdminGroupMixins';
import UserGroupsMixin from '../Groups/Mixins/UserGroupMixins';

export default {
    name: 'group-selector',
    mixins: [AdminGroupsMixin, UserGroupsMixin],
    props: {
        value: {
            type: Array,
            default: () => []
        },
        showAdminGroups: {
            type: Boolean,
            default: false
        },
        showUserGroups: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        groups() {
            if (this.showAdminGroups && this.showUserGroups) {
                return [...this.getAdminGroups, ...this.getUserGroups].sort((a, b) =>
                    a.name.localeCompare(b.name, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    })
                );
            }
            if (this.showAdminGroups) {
                return [...this.getAdminGroups].sort((a, b) =>
                    a.name.localeCompare(b.name, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    })
                );
            }
            if (this.showUserGroups) {
                return [...this.getUserGroups].sort((a, b) =>
                    a.name.localeCompare(b.name, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    })
                );
            }
            return [];
        },
        hasGroups() {
            return this.groups.length > 0;
        },
        translateUser() {
            if (this.showAdminGroups && this.showUserGroups) {
                return this.$t('Nutzer');
            } else {
                if (this.showAdminGroups && !this.showUserGroups) {
                    return this.$t('Admin');
                }
                if (!this.showAdminGroups && this.showUserGroups) {
                    return this.$t('Nutzer');
                }
            }
            return this.$t('Nutzer');
        },
        translateGroups() {
            if (this.showAdminGroups && this.showUserGroups) {
                return this.$t('Gruppen');
            } else {
                if (this.showAdminGroups && !this.showUserGroups) {
                    return this.$t('Admingruppen');
                }
                if (!this.showAdminGroups && this.showUserGroups) {
                    return this.$t('Usergruppen');
                }
            }
            return this.$t('Keine Gruppen');
        }
    },
    methods: {
        removeItem({ id }) {
            this.$emit('input', this.value.filter((filtered) => filtered.id !== id).sort(this.sortAlphabetically));
        },
        handleInput(value) {
            this.$emit('input', value.sort(this.sortAlphabetically));
        }
    },
    mounted() {
        if (this.showAdminGroups && this.getAdminGroups.length === 0) {
            this.GET_ADMIN_GROUPS();
        }
        if (this.showUserGroups && this.getUserGroups.length === 0) {
            this.GET_USER_GROUPS();
        }
    }
};
</script>
