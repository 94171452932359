<template>
    <v-card :height="getHeight">
        <v-container>
            <v-row>
                <v-col :cols="isSmallDisplay ? 12 : 6">
                    <v-container>
                        <v-form v-model="valid" ref="form">
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        :label="$t('Name')"
                                        v-model="request.name"
                                        :hint="$t('Der Name des SCORMs')"
                                        persistent-hint
                                        :rules="rules('notEmpty')"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-textarea
                                        :label="$t('Beschreibung')"
                                        v-model="request.description"
                                        :hint="$t('Die Beschreibung des SCORMs')"
                                        persistent-hint
                                        :rules="rules('notEmpty')"
                                        auto-grow
                                        rows="1"
                                    />
                                </v-col>
                            </v-row>
                        </v-form>
                        <v-row>
                            <v-col>
                                <FilePicker v-model="video" accept="video/mp4, video/ogg, video/webm" :label="$t('Laden Sie hier ein Video aus dem hoch')" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <TagGenerator
                                    v-model="request.targetgroup"
                                    :showTags="false"
                                    tagColor="secondary"
                                    label="Zielgruppe"
                                    hint="Separieren Sie die Zielgruppen mit einem Semikolon"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <TagGenerator
                                    v-model="request.learninggoals"
                                    :showTags="false"
                                    tagColor="secondary"
                                    label="Lernziele"
                                    hint="Separieren Sie die Lernziele mit einem Semikolon"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <TagGenerator
                                    v-model="request.competencies"
                                    :showTags="false"
                                    tagColor="secondary"
                                    label="Kompetenzen"
                                    hint="Separieren Sie die Kompetenzen mit einem Semikolon"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <TagGenerator
                                    v-model="request.tags"
                                    :showTags="false"
                                    tagColor="secondary"
                                    hint="Separieren Sie die Schlagworte mit einem Semikolon"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <FilePicker v-model="picture" accept="image/*" :label="$t('Laden Sie hier das Thumbnail aus dem Filemanager')" />

                                <!-- <v-input :messages="fotoHint" :label="$t('Laden Sie hier das Thumbnail aus dem Filemanager')">
                                    <template v-slot:prepend>
                                        <v-layout column fill-height justify-center align-center>
                                            <v-btn icon text @click="showFotoDialog = true">
                                                <v-icon> mdi-attachment </v-icon>
                                            </v-btn>
                                        </v-layout>
                                    </template>
                                </v-input> -->
                            </v-col>
                        </v-row>
                        <v-form v-model="validCategoriesAndTime" ref="form">
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        :label="$t('Kategorie')"
                                        v-model="request.germanCategory"
                                        :hint="$t('Die Unterkategorie des SCORMs')"
                                        persistent-hint
                                        :rules="rules('notEmpty')"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        :label="$t('Unterkategorie')"
                                        v-model="request.germanSubCategory"
                                        :hint="$t('Die Unterkategorie des SCORMs')"
                                        persistent-hint
                                        :rules="rules('notEmpty')"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-select
                                        v-model="request.enrollmentType"
                                        :persistent-hint="true"
                                        :hint="enrollmentHint"
                                        :items="enrollmentTypes"
                                        item-text="text"
                                        item-value="type"
                                        :label="$t('Wählen Sie aus, ob sich Lernende selbst eintragen können')"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="request.time"
                                        class="mt-0 pt-0"
                                        single-line
                                        persistent-hint
                                        type="number"
                                        :rules="rules('greaterEqualsZero', 'onlyNumbers', 'wholeNumber', 'notEmpty, notTooBig')"
                                        :label="$t('Bearbeitungszeit')"
                                        :hint="$t('Geben Sie hier die Bearbeitungszeit Ihres Scormkurses in Minuten an')"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>

                        <v-row>
                            <v-col>
                                <FilePicker v-model="file" accept=".zip" :label="$t('Laden Sie hier Ihre Scormdatei hoch')" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <LanguageSelector v-model="request.language" />
                            </v-col>
                        </v-row>
                        <template v-if="isSmallDisplay">
                            <v-row>
                                <v-col :cols="12">
                                    <v-card-actions>
                                        <v-btn color="accent" block @click="showPreview = true">{{ $t('Vorschau') }}</v-btn>
                                    </v-card-actions>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col :cols="6">
                                    <v-card-actions>
                                        <v-btn color="success" block @click="upload()" :disabled="!isValid">{{ $t('Hochladen') }}</v-btn>
                                    </v-card-actions>
                                </v-col>
                                <v-col :cols="6">
                                    <v-card-actions>
                                        <v-btn color="error" block @click="reset()"> {{ $t('Zurücksetzen') }} </v-btn>
                                    </v-card-actions>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-else>
                            <v-row>
                                <v-col :cols="6">
                                    <v-card-actions>
                                        <v-btn color="success" block @click="upload()" :disabled="!isValid">{{ $t('Hochladen') }}</v-btn>
                                    </v-card-actions>
                                </v-col>
                                <v-col :cols="6">
                                    <v-card-actions>
                                        <v-btn color="error" block @click="reset()"> {{ $t('Eingaben löschen') }} </v-btn>
                                    </v-card-actions>
                                </v-col>
                            </v-row>
                        </template>
                    </v-container>
                </v-col>
                <v-divider vertical />
                <v-col v-if="!isSmallDisplay" :cols="6">
                    <ScormPreview :request="request" :video="video" :picture="picture" />
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="showFotoDialog">
            <v-card>
                <v-toolbar dark flat color="secondary">
                    <v-toolbar-title>{{ $t('Wählen Sie das Thumbnail aus') }}</v-toolbar-title>
                    <v-spacer />
                    <v-btn icon @click="() => (showFotoDialog = false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <Filemanager :selectedMedia="request.picture" @selectedMedia="handleSelectedPicture" selectable fileType="image" />
            </v-card>
        </v-dialog>
        <v-dialog v-model="showVideoDialog">
            <v-card>
                <v-toolbar dark flat color="secondary">
                    <v-toolbar-title>{{ $t('Wählen Sie das Thumbnail aus') }}</v-toolbar-title>
                    <v-spacer />
                    <v-btn icon @click="() => (showVideoDialog = false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <Filemanager :selectedMedia="request.video" @selectedMedia="handleSelectedVideo" selectable fileType="video" />
            </v-card>
        </v-dialog>
        <v-dialog v-model="showPreview" fullscreen>
            <ScormPreview :request="request" :showExitButton="true" @close="closePreview" />
        </v-dialog>
    </v-card>
</template>

<script>
import ScormMixin from '../Mixins/ScormMixin';
import TagGenerator from '../../Generic/TagGenerator';
import FilePicker from '../../Generic/FilePicker/FilePicker';
import ScormPreview from './ScormPreview';
import LanguageSelector from '../../Generic/LanguageSelector';
import Filemanager from '../../FileManager/Prototype/Filemanager';
export default {
    name: 'upload-scorm',
    mixins: [ScormMixin],
    components: { TagGenerator, FilePicker, ScormPreview, LanguageSelector, Filemanager },
    data: () => ({
        request: {
            name: '',
            video: {},
            targetgroup: '',
            tags: '',
            competencies: '',
            description: '',
            learninggoals: '',
            time: '',
            germanSubCategory: '',
            germanCategory: '',
            language: '',
            file: {},
            picture: {},
            enrollmentType: ''
        },
        video: {},
        picture: {},
        file: {},
        showFotoDialog: false,
        showVideoDialog: false,
        valid: false,
        validCategoriesAndTime: false,
        showPreview: false,
        enrollmentTypes: [
            { type: 'NO_SELF_ENROLLMENT', text: 'Keine Selbsteinschreibung möglich', hint: 'Lernende können sich nicht selbst in den Kurs einschreiben' },
            {
                type: 'ON_REQUEST',
                text: 'Selbsteinschreibung auf Anfrage',
                hint: 'Lernende können eine Selbsteinschreibung anfragen. Sie erhalten eine Mail, falls sich ein:e Lernende:r einschreiben will.'
            },
            { type: 'SELF_ENROLL', text: 'Selbsteinschreibung möglich', hint: 'Lernende können sich selbst einschreiben' }
        ]
    }),
    methods: {
        rules(...rules) {
            const nonEmpty = (v) => {
                if (typeof v === 'string') {
                    return v.trim() !== '';
                }
                return v !== '';
            };
            return rules
                .map((rule) => {
                    if (rule === 'notEmpty') {
                        return (v) => nonEmpty(v) || this.$t('Das Feld darf nicht leer sein');
                    }
                    if (rule === 'onlyNumbers') {
                        return (v) => !isNaN(v) || this.$t('Es sind nur Zahlen zugelassen');
                    }
                    if (rule === 'greaterEqualsZero') {
                        return (v) => v >= 1 || this.$t('Die Angegebene Zahl muss größer oder gleich 1 sein');
                    }
                    if (rule === 'password') {
                        return (v) => (this.superAdmin.active ? this.rules('notEmpty') : null);
                    }
                    if (rule === 'wholeNumber') {
                        return (v) => v % 1 == 0 || this.$t('Die angegebene Zahl muss eine ganze Zahl sein');
                    }
                    if (rule === 'notTooBig') {
                        return (v) => v <= 9007199254740992 || this.$t('Die angegebene Zahl darf nicht zu groß sein');
                    }
                    return null;
                })
                .filter((rule) => rule !== null);
        },
        handleSelectedPicture(value) {
            this.request.picture = value;
            this.showFotoDialog = false;
        },
        handleSelectedVideo(value) {
            this.request.video = value;
            this.showVideoDialog = false;
        },
        upload() {
            this.request.file = this.file[0];
            if (this.request.picture) {
                this.request.picture = this.picture[0];
            }
            if (this.request.video) {
                this.request.video = this.video[0];
            }
            this.UPLOAD_SCORM(this.request).then(() => {
                this.reset();
            });
        },
        reset() {
            this.request = {
                name: '',
                video: {},
                targetgroup: '',
                tags: '',
                competencies: '',
                description: '',
                learninggoals: '',
                time: '',
                germanSubCategory: '',
                germanCategory: '',
                language: 'de',
                file: {},
                picture: {},
                enrollmentType: ''
            };
            this.video = {};
            this.picture = {};
            this.file = {};
        },
        closePreview() {
            this.showPreview = false;
        }
    },
    computed: {
        enrollmentHint() {
            if (!(this.request && this.request.enrollmentType)) {
                return '';
            }
            return this.enrollmentTypes.filter((model) => model.type === this.request.enrollmentType)[0].hint;
        },
        fotoHint() {
            return this.request.picture.fileName ? this.request.picture.fileName : '';
        },
        videoHint() {
            return this.request.video.fileName ? this.request.video.fileName : '';
        },
        isValid() {
            return this.valid && this.validCategoriesAndTime && this.file.length >= 1;
        },
        isSmallDisplay() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return true;
                case 'sm':
                    return true;
                case 'md':
                    return false;
                case 'lg':
                    return false;
                case 'xl':
                    return false;
                default:
                    return false;
            }
        },
        getHeight() {
            return this.isSmallDisplay ? '500000%' : '100%';
        }
    }
};
</script>
