<template>
    <v-list-item ripple exact :to="value.path">
        <v-list-item-icon>
            <v-badge color="error" :content="badge" :value="hasBadge && badge > 0" overlap>
                <v-icon>{{ navigationData.icon }}</v-icon>
            </v-badge>
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-title>{{ $t(navigationData.name) }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon v-if="excludeFilter">
            <NavigationFavourite :value="navigationData.navigationKey" />
        </v-list-item-icon>
    </v-list-item>
</template>

<script>
import NavigationFavourite from './NavigationFavourite';

export default {
    name: 'navigation-drawer-single-tile',
    components: { NavigationFavourite },
    props: {
        value: {
            type: Object
        }
    },
    computed: {
        excludeFilter() {
            return this.navigationData.type.name !== 'General' && this.navigationData.type.name !== 'Utility';
        },
        badge() {
            if (this.hasBadge) {
                return this.$store.getters[this.navigationData.badge];
            }
            return undefined;
        },
        navigationData() {
            if (this.hasOwn(this.value, 'meta')) {
                if (this.hasOwn(this.value.meta, 'navigation')) {
                    return this.value.meta.navigation;
                }
            }
            return undefined;
        },
        hasBadge() {
            if (this.hasOwn(this.value, 'meta')) {
                if (this.hasOwn(this.value.meta, 'navigation')) {
                    if (this.hasOwn(this.value.meta.navigation, 'badge')) {
                        return !!this.value.meta.navigation.badge;
                    }
                }
            }
            return false;
        }
    }
};
</script>
