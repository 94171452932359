<template>
    <v-card flat>
        <v-container>
            <v-row>
                <v-col :cols="6" v-for="bucket in buckets" :key="bucket.id">
                    <v-card>
                        <v-toolbar dark text dense color="secondary">
                            <v-toolbar-title class="text-body-2">{{ bucket.text }}</v-toolbar-title>
                            <v-spacer />
                            <v-btn icon @click="mediaEmit(bucket.media)">
                                <v-icon>mdi-image</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <draggable :list="bucketList(bucket)" group="buckets">
                                <transition-group name="slide-x-transition" tag="v-row" class="row">
                                    <v-col :cols="12" v-for="option in bucketList(bucket)" :key="`option-${option.id}`">
                                        <OptionNode :value="option" @media="mediaEmit" :disableInteraction="true" />
                                    </v-col>
                                </transition-group>
                            </draggable>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import draggable from 'vuedraggable';
import OptionNode from '../Helpers/OptionNode';

export default {
    name: 'bucket-sort-result',
    components: { draggable, OptionNode },
    props: {
        knowledgeUnitOptions: {
            type: Array,
            default: () => []
        },
        buckets: {
            type: Array,
            default: () => []
        },
        disableInteraction: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        bucketList({ orderNumber }) {
            return this.knowledgeUnitOptions.filter((option) => parseInt(option.sequenceNo) == orderNumber);
        },
        mediaEmit(value) {
            this.$emit('media', value);
        }
    }
};
</script>
