<template>
    <v-card height="100%">
        <v-layout column fill-height>
            <v-flex grow>
                <v-stepper v-model="steps" :alt-labels="$vuetify.breakpoint.smAndUp" non-linear :elevation="0">
                    <v-stepper-header>
                        <v-stepper-step :step="1" editable :rules="taskSettingsRules()">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">{{ $t('Aufgabeneinstellungen') }}</span>
                                </template>
                                <span>{{ $t('Hier können Sie die mindest Anforderungen treffen, damit die Aufgabe erstellt werden kann') }}</span>
                            </v-tooltip>
                        </v-stepper-step>
                        <v-divider />
                        <v-stepper-step :step="2" editable>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">{{ $t('Aufgabenoptionen') }}</span>
                                </template>
                                <span>{{ $t('Hier können Sie Einstellungen treffen, die das Verhalten der Aufgabe ändert') }}</span>
                            </v-tooltip>
                        </v-stepper-step>
                        <v-divider />
                        <v-stepper-step :step="3" editable :rules="catalogSettingsRules()">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">{{ $t('Katalogeinstellungen') }}</span>
                                </template>
                                <span>{{
                                    $t(
                                        'Hier können Sie Kataloge zu der Aufgabe einbinden und einzelne Wissenseinheiten von der Aufgabe ausschließen. Sie können zusätzliche Einstellungen für die eingebundenen Kataloge treffen'
                                    )
                                }}</span>
                            </v-tooltip>
                        </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                        <v-stepper-content :step="1">
                            <v-layout wrap>
                                <v-flex xs12>
                                    <v-text-field
                                        v-model="name"
                                        :label="$t('Aufgabenbezeichnung')"
                                        :rules="[(value) => (!!value && !!value.trim()) || $t('Dieses Feld darf nicht leer sein')]"
                                    />
                                </v-flex>
                            </v-layout>

                            <PickerDate
                                :startDateText="startDate"
                                :endDateText="endDate"
                                :label1="$t('Anfangsdatum')"
                                :label2="$t('Enddatum')"
                                type="Range"
                                :disableStartDate="minimumStartDate"
                                @pickerStartDateEmit="handleStartDate"
                                @pickerEndDateEmit="handleEndDate"
                                :min="minimumEndDate"
                            />
                        </v-stepper-content>

                        <v-stepper-content :step="2">
                            <v-card class="mt-3">
                                <v-toolbar text dark color="secondary">
                                    <v-toolbar-title>{{ $t('Aufgabenoptionen') }}</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text>
                                    <QuizActivityOptions v-model="taskOptions" :isTask="false" />
                                </v-card-text>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-content :step="3">
                            <List :items="archiveCatalogs" showAction showOptions title="Kataloge">
                                <template v-slot:headerAppend>
                                    <v-layout align-center justify-end>
                                        <span>{{ $t('Alle Einstellungen') }}</span>
                                        <v-btn icon @click="handleGlobalConfig">
                                            <v-icon>mdi-wrench</v-icon>
                                        </v-btn>
                                    </v-layout>
                                </template>
                                <template v-slot:action="{ action }">
                                    <v-checkbox
                                        v-model="catalogs"
                                        :value="action"
                                        :value-comparator="comparator"
                                        :key="Math.floor(Math.random() * 256)"
                                        :false-value="action"
                                        :true-value="action"
                                    />
                                </template>
                                <template v-slot:append="{ append }">
                                    <v-menu>
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon v-on="on" :disabled="!isInCatalogs(append)">
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item @click="handleEditCatalogOptions(append)">
                                                <v-list-item-action>
                                                    <v-icon>mdi-wrench</v-icon>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ $t('Katalog-Optionen') }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item @click="handleExcludeKnowledgeUnitsDialog(append)">
                                                <v-list-item-action>
                                                    <v-icon>mdi-playlist-edit</v-icon>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ $t('Wissenseinheiten ausschließen') }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </template>
                            </List>
                            <QuizCatalogConfigurator
                                v-model="showGlobalConfiguratorModal"
                                @options="
                                    handleApplyCatalogOptions({
                                        ...$event,
                                        applyToAllCatalogs: true
                                    })
                                "
                                :enableGlobalConfig="false"
                                :isTask="false"
                            />
                            <QuizCatalogConfigurator
                                v-model="showCatalogConfigurator"
                                :options="catalogOptions.options"
                                @options="handleApplyCatalogOptions"
                                :isTask="false"
                            />
                            <QuizActivityExcludeCatalogs
                                v-model="showExcludeKnowledgeUnits"
                                :catalog="selectedCatalog"
                                :knowledgeUnits="excludeKnowledgeUnits"
                                @knowledgeUnits="handleExcludeKnowledgeUnits"
                            />
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-flex>
            <v-flex shrink>
                <v-card-actions>
                    <v-row>
                        <v-col>
                            <v-btn :disabled="!disableSubmitButton" block :class="submitButtonColor" @click.stop="submit">
                                {{ $t(submitButtonText) }}
                            </v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn class="primary" @click="updateProcessStep" :disabled="steps === 3">
                                {{ $t('Weiter') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-flex>
        </v-layout>
    </v-card>
</template>

<script>
import PickerDate from '../Generic/PickerDate/PickerDate';
import QuizActivityExcludeCatalogs from '../CourseLearning/CourseManager/ActivityTypes/Helpers/QuizActivityExcludeCatalogs';
import QuizCatalogConfigurator from '../CourseLearning/CourseManager/ActivityTypes/Helpers/QuizCatalogConfigurator';
import QuizActivityOptions from '../CourseLearning/CourseManager/ActivityTypes/Helpers/QuizActivityOptions';
import List from '../Generic/List/AdvancedList';

import ArchiveMixin from './Mixins/ArchiveMixin';
import moment from 'moment';

export default {
    name: 'add-update-archive',
    components: {
        PickerDate,
        QuizActivityExcludeCatalogs,
        QuizActivityOptions,
        QuizCatalogConfigurator,
        List
    },
    mixins: [ArchiveMixin],
    data() {
        return {
            steps: 1,
            showLimit: false,
            showExtendedEndDate: false,
            showPassingQuote: false,
            showRecurringTaskOptions: false,
            showExcludeKnowledgeUnits: false,
            showCatalogConfigurator: false,
            showGlobalConfiguratorModal: false,
            selectedCatalog: {},
            archiveCatalogs: [],
            catalogOptions: {},

            id: '',
            name: '',
            color: '',
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
            taskOptions: {
                sortBy: 'NATURAL',
                sortList: ['NATURAL', 'RANDOM', 'DATE'],
                limits: 0,
                extendEndDate: '',
                passingQuote: 0,
                shuffleOptions: false,
                hideUnansweredKnowledgeUnits: false,
                hasPassingQuote: false,
                showResult: true
            },
            catalogs: [],
            excludeKnowledgeUnits: []
        };
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        type: {
            type: String,
            default: 'ADD',
            validator: (value) => ['ADD', 'UPDATE'].includes(value)
        }
    },
    watch: {
        catalogs(value) {
            if (value.length > 0 && !value.every((catalog) => catalog.hasOwnProperty('options'))) {
                this.catalogs = value.map((catalog) => {
                    if (catalog.hasOwnProperty('options')) {
                        return catalog;
                    }
                    return {
                        ...catalog,
                        options: {
                            showKnowledgeBase: true,
                            showExplanation: true,
                            answerTimeLimit: 100,
                            passingQuote: 0,
                            archiveImmediately: false,
                            archiveOnEndDate: false,
                            reopenKnowledgeBase: true,
                            archive: false,
                            archiveOnPass: false,
                            onFailure: 'REPEAT',
                            onFailureList: ['REPEAT', 'END'],
                            sortBy: 'NATURAL',
                            sortList: ['NATURAL', 'RANDOM', 'DATE']
                        }
                    };
                });
            }
        },
        taskOptions(value) {
            if (value.limits > 1) {
                this.showLimit = true;
            }
            if (value.extendEndDate !== '') {
                this.showExtendedEndDate = true;
            }
            if (value.passingQuote > 0) {
                this.showPassingQuote = true;
            }
        },
        value: {
            immediate: true,
            handler(task) {
                if (this.type === 'UPDATE') {
                    Object.entries(task).forEach(([key, value]) => {
                        if (this.$data.hasOwnProperty(key)) {
                            this.$data[key] = value;
                        }
                    });
                }
            }
        }
    },
    computed: {
        computedExtendedEndDate: {
            get() {
                if (!this.showExtendedEndDate) {
                    return '';
                }
                if (moment(this.taskOptions.extendEndDate).isBefore(this.endDate)) {
                    return moment(this.endDate).add(1, 'days').format('YYYY-MM-DD');
                }
                return this.taskOptions.extendEndDate;
            },
            set(value) {
                this.taskOptions.extendEndDate = value;
            }
        },
        minimumStartDate() {
            return this.type === 'UPDATE';
        },
        minimumEndDate() {
            if (!this.startDate) {
                return moment().format('YYYY-MM-DD');
            }
            return this.startDate;
        },
        minimumRecurringExitDate() {
            return moment().add(1, 'days').format('YYYY-MM-DD');
        },
        maxLimitComputed() {
            return this.catalogs.map((catalog) => catalog.totalKnowledgeUnits).reduce((acc, val) => acc + val, 0);
        },
        disableSubmitButton() {
            return this.name !== '' && this.catalogs.length > 0;
        },
        submitButtonColor() {
            return this.type === 'ADD' ? 'success' : 'warning';
        },
        submitButtonText() {
            return this.type === 'ADD' ? this.$t('Hinzufügen') : this.$t('Aktualisieren');
        }
    },
    methods: {
        handleGlobalConfig() {
            this.showGlobalConfiguratorModal = true;
        },
        comparator(a, b) {
            if (a && b && a.id && b.id) {
                return a.id === b.id;
            }
            return false;
        },
        isInCatalogs({ id }) {
            return this.catalogs.map(({ id: catalogId }) => catalogId).includes(id);
        },
        serialize() {
            let { id } = this.getLoggedInAdmin;
            return {
                ...(this.type === 'UPDATE' ? { id: this.id } : {}),
                name: this.name,
                color: this.color,
                startDate: this.startDate,
                endDate: this.endDate,
                notificationOptions: this.notificationOptions,
                taskOptions: {
                    ...this.taskOptions,
                    extendEndDate: this.computedExtendedEndDate
                },
                catalogs: this.catalogs,
                excludeKnowledgeUnits: this.excludeKnowledgeUnits,
                users: [{ id }],
                owners: [{ id }]
            };
        },
        submit() {
            if (this.type === 'ADD') {
                this.ADD_ARCHIVE(this.serialize()).then(() => this.$router.push({ name: 'appArchive' }));
            }
            if (this.type === 'UPDATE') {
                this.UPDATE_ARCHIVE(this.serialize()).then(() => this.$router.push({ name: 'appArchive' }));
            }
        },
        handleStartDate(value) {
            this.startDate = value;
        },
        handleEndDate(value) {
            this.endDate = value;
        },
        handleExcludeKnowledgeUnits(value) {
            this.excludeKnowledgeUnits = value;
        },
        handleExcludeKnowledgeUnitsDialog(value) {
            this.selectedCatalog = value;
            this.showExcludeKnowledgeUnits = true;
        },
        handleEditCatalogOptions(value) {
            this.catalogOptions = this.catalogs.find((catalog) => catalog.id === value.id);
            if (this.catalogOptions) {
                this.showCatalogConfigurator = true;
            }
        },
        handleApplyCatalogOptions({ applyToAllCatalogs, ...options }) {
            if (applyToAllCatalogs) {
                this.catalogs = this.catalogs.map((catalog) => ({
                    ...catalog,
                    options: options
                }));
            } else {
                this.catalogs = this.catalogs.map((catalog) => {
                    if (catalog.id === this.catalogOptions.id) {
                        return { ...catalog, options: options };
                    }
                    return catalog;
                });
            }
        },
        taskSettingsRules() {
            return [() => this.name.trim() !== '' || this.$t('Eingabe fehlt')];
        },
        catalogSettingsRules() {
            return [() => this.catalogs.length > 0 || this.$t('Keine Kataloge angegeben')];
        },
        updateProcessStep() {
            this.steps += 1;
        }
    },
    mounted() {
        this.GET_ARCHIVE_CATALOGS().then((response) => (this.archiveCatalogs = response));
    }
};
</script>

<style scoped>
.v-stepper__header {
    height: auto;
}
</style>
