<template>
    <v-flex grow class="fill-height">
        <v-expand-transition mode="out-in">
            <keep-alive>
                <component :is="componentSwitch" v-model="computedSelectedScorm" :scorms="getEnrollableScorms" :eShopPage="true" nodeContext="SELF_ENROLL" />
            </keep-alive>
        </v-expand-transition>
    </v-flex>
</template>
<script>
import ScormMixin from './Mixins/ScormMixin';
import Scormhomepage from './Scormhomepage';
import ScormDisplay from './ScormDisplay';

export default {
    name: 'enrollable-scorm',
    mixins: [ScormMixin],
    components: { Scormhomepage, ScormDisplay },
    computed: {
        componentSwitch() {
            if (this.getView) {
                switch (this.getView.component) {
                    case 'scormHomepage':
                        return Scormhomepage;
                }
            }
            if (this.computedSelectedScorm.hasOwnProperty('id')) {
                return ScormDisplay;
            }
            return Scormhomepage;
        },
        computedSelectedScorm: {
            get() {
                return this.getSelectedScorm;
            },
            set(value) {
                this.SET_SELECTED_SCORM(value);
            }
        }
    },
    mounted() {
        this.GET_ENROLLABLE_SCORMS();
    }
};
</script>
