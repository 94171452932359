<template>
    <v-container>
        <v-row>
            <v-col :cols="12" v-for="option in knowledgeUnitOptions" :key="`option-${option.id}`">
                <OptionNode :value="option" @media="mediaEmit" :disableInteraction="true" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import OptionNode from '../Helpers/OptionNode';

export default {
    name: 'sequence-result',
    components: {
        OptionNode
    },
    props: {
        knowledgeUnitOptions: {
            default: () => [],
            type: Array
        }
    },
    methods: {
        mediaEmit(val) {
            this.$emit('media', val);
        }
    }
};
</script>
