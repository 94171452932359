const filterBy = (items, date, filterMethod) => {
    if (filterMethod == 'ALLTASK') return items;
    else if (filterMethod == 'YEAR') {
        return items.filter((item) => {
            return moment(item.taskCompletedDate).year() == moment(date).year();
        });
    } else if (filterMethod == 'MONTH') {
        return items.filter((item) => {
            return moment(item.taskCompletedDate).year() == moment(date).year() && moment(item.taskCompletedDate).month() == moment(date).month();
        });
    } else if (filterMethod == 'DATE') {
        let temp = items.filter((item) => {
            return moment(date.startDate) <= moment(item.taskCompletedDate) && moment(item.taskCompletedDate) <= moment(date.endDate);
        });
        return temp;
    }

    return items;
};

import moment from 'moment';

export default {
    filterMethods: [
        {
            name: 'Alle Zertifikate',
            type: 'ALL',
            filter(items) {
                return filterBy(items, 'ALLTASK');
            }
        },
        {
            name: 'Pro Jahr',
            type: 'YEAR',
            filter(items, date) {
                if (this.hasOwnProperty('date')) {
                    return filterBy(items, this.date, 'YEAR');
                }
                return filterBy(items, date, 'YEAR');
            }
        },
        {
            name: 'Nach Monaten',
            type: 'MONTH',
            filter(items, date) {
                if (this.hasOwnProperty('date')) {
                    return filterBy(items, this.date, 'MONTH');
                }
                return filterBy(items, date, 'MONTH');
            }
        },
        {
            name: 'Nach Datum',
            type: 'DATE',
            filter(items, date) {
                if (this.hasOwnProperty('date')) {
                    return filterBy(items, this.date, 'DATE');
                }
                return filterBy(items, date, 'DATE');
            }
        }
    ]
};
