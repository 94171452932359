const state = {
    selectedLanguage: { shortLang: 'de', longLang: 'Deutsch' }
};

// getters
const getters = {
    selectedLanguage(state) {
        return state.selectedLanguage;
    }
};
// actions

// mutations

const mutations = {
    clear: (state) => {}
};

export default {
    namespaced: true,
    state,
    getters,
    mutations
};
