<template>
    <v-card height="100%">
        <Toolbar :toolbarTitle="$t('Hauptseite')" :tooltipMessage="$t('Newsticker & Schlagzeilen.')" />
        <v-container>
            <v-row align="center" justify="center">
                <v-col :cols="12">
                    <v-data-iterator
                        :items="getPublishedNews"
                        :custom-sort="selectedNewsSort.sort"
                        :items-per-page="1"
                        :footer-props="{ itemsPerPageOptions: [1] }"
                        :hide-default-footer="true"
                        :options.sync="pagination"
                        :no-data-text="$t('Kein Artikel verfügbar')"
                        :no-results-text="$t('Kein Ergebnis')"
                        :search="search"
                        :custom-filter="filteredNews"
                        :sort-desc.sync="sortOrder"
                    >
                        <template v-slot:header>
                            <v-container>
                                <v-row align="center">
                                    <v-col>
                                        <v-text-field
                                            autocomplete="new-password"
                                            prepend-icon="mdi-magnify"
                                            :label="$t('Suchen')"
                                            v-model="search"
                                            color="accent"
                                            hide-details
                                        />
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" icon @click="() => (showFilter = !showFilter)">
                                                    <v-icon>mdi-filter</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('Filtern') }}</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                                <v-expand-transition>
                                    <v-row v-show="showFilter" align="center">
                                        <v-col>
                                            <v-select
                                                v-model="selectedNewsSort"
                                                :items="newsSort"
                                                item-text="name"
                                                item-value="name"
                                                return-object
                                                prepend-icon="mdi-sort"
                                                :label="$t('Sortieren')"
                                                color="accent"
                                                hide-details
                                            >
                                                <template v-slot:item="{ item: { name } }">
                                                    {{ $t(name) }}
                                                </template>
                                                <template v-slot:selection="{ item: { name } }">
                                                    {{ $t(name) }}
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="auto" align-self="center">
                                            <v-btn icon @click="reverseList">
                                                <v-icon>mdi-swap-vertical</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-expand-transition>
                                <v-row>
                                    <v-col align="center" justify="center" fill-height>
                                        <v-pagination
                                            v-if="paginationLength !== 0"
                                            v-model="pagination.page"
                                            :length="paginationLength"
                                            :total-visible="5"
                                            circle
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                        <template v-slot:item="items">
                            <v-card>
                                <v-toolbar dense text dark color="primary">
                                    <v-toolbar-title>{{ items.item.title }}</v-toolbar-title>
                                    <v-spacer />
                                    {{ formatDate(items.item.publishedDate) }}
                                </v-toolbar>
                                <v-card-text style="max-height: calc(100vh - 350px)" class="overflow-y-auto">
                                    <div class="ck ck-content" @click="imageGalleryClickHandler" v-html="items.item.content" />
                                    <div style="clear: both"></div>
                                </v-card-text>
                            </v-card>
                        </template>
                    </v-data-iterator>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import NewsSort from '../../Components/News/Sort/NewsSort';
import Toolbar from '../../Components/Generic/Toolbar';

import ImageGalleryMixin from '../../Components/Global/Mixins/ImageGalleryMixin';

import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'news-component',
    mixins: [ImageGalleryMixin],
    components: { Toolbar },
    data: () => ({
        search: '',
        pagination: {},
        newsSort: NewsSort,
        selectedNewsSort: NewsSort[0],
        showFilter: false,
        sortOrder: true
    }),
    computed: {
        ...mapGetters('NewsOperations', ['getPublishedNews']),
        paginationLength() {
            return this.filteredNews(this.getPublishedNews, this.search).length;
        }
    },
    methods: {
        ...mapActions('NewsOperations', ['GET_PUBLISHED_NEWS']),
        formatDate(publishedDate) {
            return moment(publishedDate).format('YYYY-MM-DD');
        },
        reverseList() {
            this.sortOrder = !this.sortOrder;
        },
        filteredNews(items, search) {
            if (search.trim() === '') {
                return items;
            }
            let buildString = search
                .split(' ')
                .filter((word) => word !== '')
                .map((word) => `(?=.*${word})`)
                .join('');
            let regex = new RegExp(buildString, 'i');

            return items.filter((item) => regex.test(item.name) || regex.test(item.title) || regex.test(item.content));
        }
    },
    mounted() {
        this.GET_PUBLISHED_NEWS();
    }
};
</script>
