<template>
    <v-card height="100%">
        <v-toolbar dark flat color="secondary">
            <v-btn icon @click="setStorm({})">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ computedSelectedScorm.localTitle }}</v-toolbar-title>
            <v-spacer />
        </v-toolbar>
        <v-container ma-0>
            <v-row>
                <v-col :cols="6" class="primary--text pointer" @click="setStorm(getPreviousElement())">
                    <v-icon color="primary">mdi-chevron-left</v-icon>
                    {{ getPreviousElement().localTitle }}
                </v-col>
                <v-col :cols="6" class="primary--text pointer" align="right" @click="setStorm(getNextElement())">
                    {{ getNextElement().localTitle }}
                    <v-icon color="primary">mdi-chevron-right</v-icon>
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="showCardAtBottom ? 12 : 9">
                    <v-card flat ref="card">
                        <v-card-text v-bind:style="{ color: isDark ? 'white' : 'black', fontSize: 1 + 'rem' }">
                            {{ computedSelectedScorm.description }}
                        </v-card-text>
                        <template v-if="!!videoID">
                            <v-card-title> {{ insightTitle() }}</v-card-title>
                            <template v-if="isPinktum">
                                <vueVimeoPlayer
                                    ref="player"
                                    :player-height="playerHeight"
                                    :player-width="playerWidth"
                                    v-if="!!videoID && isPinktum"
                                    :video-id="videoID"
                                />
                            </template>
                            <template v-else>
                                <FilePreviewVideo :src="getVideo()" />
                            </template>
                        </template>
                        <v-card-title> {{ targetGroupTitle() }}</v-card-title>
                        <v-card-text
                            v-bind:style="{ color: isDark ? 'white' : 'black', fontSize: 1 + 'rem' }"
                            v-html="makeHtmlList(computedSelectedScorm.targetGroup)"
                        />
                        <v-card-title> {{ learningGoalTitle() }}</v-card-title>
                        <v-card-text
                            v-bind:style="{ color: isDark ? 'white' : 'black', fontSize: 1 + 'rem' }"
                            v-html="makeHtmlList(computedSelectedScorm.learningObjects)"
                        />
                        <v-card-title v-if="computedSelectedScorm.germanCompetencies != ''"> {{ competenciesTitle() }} </v-card-title>
                        <v-card-text v-if="computedSelectedScorm.germanCompetencies != ''">
                            <Tags :items="stringToArray(computedSelectedScorm.germanCompetencies)" />
                        </v-card-text>
                        <v-card-title v-if="computedSelectedScorm.keywords != ''"> {{ keywordTitle() }} </v-card-title>
                        <v-card-text v-if="computedSelectedScorm.keywords != ''"> <Tags :items="stringToArray(computedSelectedScorm.keywords)" /> </v-card-text>
                    </v-card>
                </v-col>
                <v-col v-if="!showCardAtBottom" :cols="3">
                    <ScormDisplayNode
                        :item="computedSelectedScorm"
                        :scormDetail="scormDetail"
                        :isBought="isBought"
                        :context="nodeContext"
                        :adminReportItems="adminReportItems"
                        :userReportItems="userReportItems"
                        :permissions="permissions"
                        @openReportModal="openReportModal"
                        @buyScorm="buyScorm"
                    />
                </v-col>
            </v-row>
            <v-row v-if="showCardAtBottom">
                <ScormDisplayNode
                    :item="computedSelectedScorm"
                    :scormDetail="scormDetail"
                    :isBought="isBought"
                    :context="nodeContext"
                    :adminReportItems="adminReportItems"
                    :userReportItems="userReportItems"
                    :permissions="permissions"
                    @openReportModal="openReportModal"
                    @buyScorm="buyScorm"
                />
            </v-row>
        </v-container>

        <!--Enroll Modal-->
        <v-dialog v-model="showEnrollModal" persistent :width="$vuetify.breakpoint.thresholds.sm" :fullscreen="$vuetify.breakpoint.xs">
            <v-card text>
                <v-layout column align-center justify-center class="modal-layout">
                    <v-btn icon @click="closeEnrollModal" class="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar text dense flat>
                        <v-toolbar-title>{{ $t('Enroll Users') }}</v-toolbar-title>
                    </v-toolbar>
                    <UserSelector
                        :key="userSelectorKey"
                        v-model="enrollData.users"
                        @loader="(val) => (isLoading = val)"
                        :assignedUsers="userReportItems"
                        :assignedAdmins="adminReportItems"
                    />
                    <hr class="hr-line" />
                    <span class="red--text" v-if="errMsg">{{ errMsg }}</span>
                    <v-card-text>
                        <v-card-actions class="justify-center">
                            <v-progress-circular indeterminate color="primary" v-if="isLoading" />
                            <v-btn v-else color="success" block @click="submitEnroll" :disabled="!enrollData.users.length">
                                {{ $t('Enroll') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card-text>
                </v-layout>
            </v-card>
        </v-dialog>

        <!--Report Modal-->
        <v-dialog
            v-model="showReportModal"
            persistent
            :width="$vuetify.breakpoint.thresholds.sm"
            :fullscreen="$vuetify.breakpoint.xs"
            @click:outside="closeReportModal"
        >
            <ReportWindow :admins="adminReportItems" :users="userReportItems" @closeReportModal="closeReportModal" />
        </v-dialog>

        <!-- Self enrollment modal-->
        <v-dialog
            v-model="showSelfEnrollmentModal"
            persistent
            :width="$vuetify.breakpoint.thresholds.sm"
            :fullscreen="$vuetify.breakpoint.xs"
            @click:outside="closeSelfEnrollmentModal"
        >
            <v-card>
                <v-toolbar text dense flat>
                    <v-btn icon @click="closeSelfEnrollmentModal" class="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ computedSelectedScorm.model == 'ON_REQUEST' ? $t('Anfragen') : $t('Einschreiben') }}</v-toolbar-title>
                </v-toolbar>
                <v-container ma-0>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-checkbox v-model="selfEnrollValid" color="primary" :label="checkboxText" hide-details></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col :cols="12">
                                <v-card-actions>
                                    <v-btn color="success" block @click="submitSelfEnroll()" :disabled="!selfEnrollValid">
                                        {{ computedSelectedScorm.model == 'ON_REQUEST' ? $t('Anfragen') : $t('Einschreiben') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
    </v-card>
</template>
<script>
import ScormMixins from './Mixins/ScormMixin';
import Tags from '../KnowledgeContent/KnowledgeContentTags';
import UserSelector from '../Generic/Tree/UserGroupTreeSelector';
import { vueVimeoPlayer } from 'vue-vimeo-player';
import FilePreviewVideo from '../FileManager/FilePreviewTypes/FilePreviewVideo';
import ReportWindow from './Helpers/ReportWindow';
import ScormDisplayNode from './Helpers/ScormDisplayNode';

export default {
    name: 'scorm-display',
    mixins: [ScormMixins],
    components: {
        Tags,
        UserSelector,
        vueVimeoPlayer,
        FilePreviewVideo,
        ReportWindow,
        ScormDisplayNode
    },
    data: () => ({
        video: '',
        showBuyScreen: false,
        isBought: false,
        showEnrollModal: false,
        showSelfEnrollmentModal: false,
        showReportModal: false,
        isLoading: false,
        errMsg: '',
        userSelectorKey: 0,
        enrollData: {
            users: []
        },
        scormDetail: {
            purchaseDate: '',
            expirationDate: '',
            scormPackageType: ''
        },
        userReportItems: [],
        adminReportItems: [],
        selfEnrollValid: false,
        permissions: {
            upload: false,
            buyScorm: false,
            assignUserToScorm: false
        }
    }),
    props: {
        scorms: {
            type: Array,
            default: () => []
        },
        eShopPage: {
            type: Boolean,
            default: false
        },
        nodeContext: {
            type: String,
            default: ''
        }
    },
    computed: {
        computedSelectedScorm: {
            get() {
                return this.getSelectedScorm;
            },
            set(value) {
                this.SET_SELECTED_SCORM(value);
            }
        },
        learningMinutes() {
            return `${this.computedSelectedScorm.learningMinutes} ${this.$t('Minuten')}`;
        },
        videoID() {
            return this.computedSelectedScorm && this.computedSelectedScorm.vimeoTeaser ? this.computedSelectedScorm.vimeoTeaser.substring(18) : undefined;
        },
        getLanguage() {
            if (this.computedSelectedScorm && this.computedSelectedScorm.language) {
                return this.computedSelectedScorm.language.substring(0, 2);
            }
            return 'de';
        },
        isDark() {
            return this.$vuetify.theme.dark;
        },
        isPinktum() {
            return this.computedSelectedScorm.producer == 'Pinktum';
        },
        playerWidth() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 300;
                case 'sm':
                    return 500;
                case 'md':
                    return 600;
                case 'lg':
                    return 800;
                case 'xl':
                    return 960;
                default:
                    return 960;
            }
        },
        playerHeight() {
            return this.playerWidth * 0.5;
        },
        showCardAtBottom() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return true;
                case 'sm':
                    return false;
                case 'md':
                    return false;
                case 'lg':
                    return false;
                case 'xl':
                    return false;
                default:
                    return false;
            }
        },
        checkboxText() {
            return this.computedSelectedScorm.model == 'ON_REQUEST'
                ? this.$t('Wollen Sie ihrem Super Admin eine Anfrage senden, dass Sie den Scorm bearbeiten wollen?')
                : this.$t('Wollen Sie sich in den Kurs einschreiben?');
        },
        hasAssignPermissions() {
            return this.isSuperAdmin || this.permissions.assignUserToScorm;
        },
        hasPurchasePermissions() {
            return this.isSuperAdmin || this.permissions.purchaseScorm;
        },
        isSuperAdmin() {
            return this.getLoggedInAdmin.role === 'SUPER_ADMIN';
        }
    },
    activated() {
        this.$nextTick(() => {
            this.userReportItems = [];
            this.adminReportItems = [];
            this.checkBoughtScorm();
        });
    },
    mounted() {
        this.checkBoughtScorm();
    },
    beforeMount() {
        this.validateEditorState({
            upload: ['UPLOAD_SCORM'],
            assignUserToScorm: ['ASSIGN_USER_TO_SCORM'],
            purchaseScorm: ['BUY_SCORM']
        }).then((result) => (this.permissions = result));
    },
    methods: {
        checkBoughtScorm() {
            const boughtScorm = this.getBoughtScorms.find((obj) => obj.scormPackageId === this.computedSelectedScorm.scormPackageId);

            this.isBought = !!boughtScorm || this.computedSelectedScorm.scormPackageType == 'OWN';
            if (boughtScorm) {
                this.SET_SELECTED_SCORM(boughtScorm);
                this.fetchScormReportActivities();
                this.fetchScormDetails();
            }
        },
        buyScorm() {
            if (!this.isBought && this.hasPurchasePermissions) {
                console.log('TEST');
                let n = this.getView.tabs;
                this.SET_VIEW({ component: 'buyScorm', type: '', data: this.getSelectedScorm, tabs: n });
                return;
            }
            if (!this.isBought) {
                //Noch filter für schon angefragte Kurse
                if (!(this.getSelectedScorm.buyRequestStatus === undefined || this.getSelectedScorm.buyRequestStatus === '')) return;
                this.CREATE_BUY_REQUEST({ scormId: this.getSelectedScorm.id });
                return;
            }
            // AB HIER NUR GEKAUFTE KURSE. ZUERST SELF ENROLLMENT
            if (this.nodeContext == 'SELF_ENROLL') {
                if (
                    this.computedSelectedScorm &&
                    this.computedSelectedScorm.model &&
                    (this.computedSelectedScorm.model == 'SELF_ENROLL' || this.computedSelectedScorm.model == 'ON_REQUEST')
                ) {
                    this.showSelfEnrollmentModal = true;
                }
            } else {
                if (this.hasAssignPermissions) {
                    this.enrollData.users = [];
                    this.showEnrollModal = true;
                    this.errMsg = '';
                    this.userSelectorKey += 1;
                }
            }
        },
        getVideo() {
            return this.computedSelectedScorm.vimeoTeaser;
        },
        learningGoalTitle() {
            switch (this.getLanguage) {
                case 'de':
                    return 'Lernziele';
                case 'en':
                    return 'Learning Goals';
                default:
                    return 'Lernziele';
            }
        },
        competenciesTitle() {
            switch (this.getLanguage) {
                case 'de':
                    return 'Kompetenzen';
                case 'en':
                    return 'Competencies';
                default:
                    return 'Kompetenzen';
            }
        },
        targetGroupTitle() {
            switch (this.getLanguage) {
                case 'de':
                    return 'Zielgruppe';
                case 'en':
                    return 'Target Group';
                default:
                    return 'Zielgruppe';
            }
        },
        keywordTitle() {
            switch (this.getLanguage) {
                case 'de':
                    return 'Schlagwörter';
                case 'en':
                    return 'Keywords';
                default:
                    return 'Schlagwörter';
            }
        },
        insightTitle() {
            switch (this.getLanguage) {
                case 'de':
                    return 'Einblicke in das E-Learning';
                case 'en':
                    return 'Insight into E-Learning';
                default:
                    return 'Einblicke in das E-Learning';
            }
        },
        makeHtmlList(string) {
            var newString = string;
            newString = '<ul><li>' + newString + '</li></ul>';
            newString = newString.replace(/;/g, '</li><li>');
            return newString;
        },
        stringToArray(prop) {
            if (!prop) {
                return [];
            }
            if (prop.split(';').length > 1) {
                return prop.split(';');
            }
            return prop.split(',');
        },
        setStorm(value) {
            if (!value.hasOwnProperty('id')) {
                let n = this.getView.tabs;
                this.SET_VIEW({ component: 'scormHomepage', type: '', data: {}, tabs: n });
            }
            this.computedSelectedScorm = value;
        },
        getPreviousElement() {
            if (!this.getSelectedScorm.hasOwnProperty('id')) {
                return this.scorms[this.scorms.length - 1];
            }
            var currentElement = this.scorms.filter((faq) => {
                return faq.id === this.getSelectedScorm.id;
            })[0];
            var index = this.scorms.indexOf(currentElement);
            if (index <= 0) {
                return this.scorms[this.scorms.length - 1];
            }
            if (index > 0) {
                return this.scorms[index - 1];
            }
            return {};
        },
        getNextElement() {
            if (!this.getSelectedScorm.hasOwnProperty('id')) {
                return this.scorms[this.scorms.length - 1];
            }
            var currentElement = this.scorms.filter((faq) => {
                return faq.id === this.getSelectedScorm.id;
            })[0];
            var index = this.scorms.indexOf(currentElement);
            if (index >= this.scorms.length - 1) {
                return this.scorms[0];
            } else {
                return this.scorms[index + 1];
            }
        },
        closeEnrollModal() {
            this.showEnrollModal = false;
        },
        closeSelfEnrollmentModal() {
            this.showSelfEnrollmentModal = false;
        },
        submitEnroll() {
            this.errMsg = '';
            this.isLoading = true;
            const assignedUsers = [...this.userReportItems, ...this.adminReportItems];
            this.enrollData.users = this.enrollData.users.filter((usr) => !assignedUsers.some((assigUsr) => usr.id === assigUsr.id)).map((user) => user.id);
            const body = { scormId: this.computedSelectedScorm.purchasedId, enrollData: this.enrollData };
            this.ENROLL_SCORM_TO_USERS(body)
                .then(() => {
                    this.closeEnrollModal();
                    this.enrollData.users = [];
                    this.isLoading = false;
                    this.fetchScormReportActivities();
                    this.fetchScormDetails();
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.enrollData.users = [];
                    // this.errMsg = this.$t(err?.response?.data?.errors[0]?.message);
                });
        },
        submitSelfEnroll() {
            this.SELF_ENROLL(this.computedSelectedScorm.id);
            this.showSelfEnrollmentModal = false;
            this.computedSelectedScorm = {};
            this.SET_VIEW({ component: 'scormHomepage', type: '', data: {}, tabs: 0 });
        },
        closeReportModal() {
            this.showReportModal = false;
        },
        openReportModal() {
            this.showReportModal = true;
        },
        fetchScormDetails() {
            this.GET_SPECIFIC_SCORM_DETAILS(this.computedSelectedScorm.id).then((resp) => {
                this.scormDetail = resp;
            });
        },
        fetchScormReportActivities() {
            this.GET_SCORM_REPORT_USER_ACTIVITIES(this.computedSelectedScorm.id).then((resp) => {
                this.userReportItems = resp.activities
                    .map((obj) => {
                        obj.user.progress = obj.cmi.score.raw ? parseInt(obj.cmi.score.raw) : 0;
                        obj.user.completion_status = obj.cmi.completion_status ? obj.cmi.completion_status : 'Not Started';
                        obj.user.success_status = obj.cmi.completion_status ? obj.cmi.success_status : 'Not Started';
                        obj.user.total_time = obj.cmi.completion_status ? obj.cmi.total_time : 'Not Started';
                        return obj.user;
                    })
                    .filter((user) => user.role === 'USER');

                this.adminReportItems = resp.activities
                    .map((obj) => {
                        obj.user.progress = obj.cmi.score.raw ? parseInt(obj.cmi.score.raw) : 0;
                        obj.user.completion_status = obj.cmi.completion_status ? obj.cmi.completion_status : 'Not Started';
                        obj.user.success_status = obj.cmi.completion_status ? obj.cmi.success_status : 'Not Started';
                        obj.user.total_time = obj.cmi.completion_status ? obj.cmi.total_time : 'Not Started';
                        return obj.user;
                    })
                    .filter((user) => user.role === 'ADMIN' || user.role === 'SUPER_ADMIN');
            });
        }
    },
    watch: {
        computedSelectedScorm(scorm) {
            const boughtScorm = this.getBoughtScorms
                //.concat(this.getUploadedScorms)
                .find((obj) => obj.scormPackageId === scorm.scormPackageId);
            this.isBought = !!boughtScorm || scorm.scormPackageType === 'OWN';
            this.userReportItems = [];
            this.adminReportItems = [];
            if (boughtScorm) {
                this.SET_SELECTED_SCORM(boughtScorm);
                this.fetchScormReportActivities();
                this.fetchScormDetails();
            }
        }
    }
};
</script>

<style>
.hr-line {
    width: 100%;
    margin: 18px 0 14px;
}
.v-dialog:not(.v-dialog--fullscreen) {
    max-height: 95%;
}
.card-title {
    padding: 7px 16px;
}
.info-icon {
    margin-right: 4px;
}
.licences {
    padding: 5px 16px;
}
.licence-blocks {
    padding: 0 20px 18px;
}
.licence-label {
    padding: 3px 0 8px;
}
</style>
