import { mapActions } from 'vuex';

export default {
    methods: {
        ...mapActions('DashboardOperations', [
            'GET_KNOWLEDGE_UNIT_TOTAL',
            'GET_KNOWLEDGE_CONTENT_TOTAL',
            'GET_VOTES_TOTAL',
            'GET_USER_ACTIVITY',
            'GET_QUIZ_ACTIVITY',
            'GET_KNOWLEDGE_UNIT_RANKING'
        ])
    }
};
