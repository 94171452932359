<template>
    <div>
        <Toolbar :toolbarTitle="$t(toolbarTitle)" :showDrawer="false" :lastPath="lastPath" tooltipMessage="Hier können Sie eine Nachricht bearbeiten." />
        <v-card>
            <UpdateNews v-model="news" />

            <v-card-actions>
                <v-btn color="warning" block @click="updateNews" :disabled="!isNewsCompleted">{{ $t('Bearbeiten') }}</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import NewsMixin from '../../Components/News/Mixins/NewsMixin';

import Toolbar from '../../Components/Generic/Toolbar';
import UpdateNews from '../../Components/News/AddUpdateNews';

export default {
    name: 'update-news',
    components: { Toolbar, UpdateNews },
    mixins: [NewsMixin],
    data: () => ({
        lastPath: { name: 'newsEditor' },
        toolbarTitle: 'Nachricht Bearbeiten',
        news: {}
    }),
    computed: {
        isNewsCompleted() {
            return true;
        }
    },
    methods: {
        updateNews() {
            this.UPDATE_NEWS(this.news).then(() => this.$router.replace(this.lastPath));
        }
    },
    mounted() {
        this.news = { ...this.getSelectedNews };
    }
};
</script>
