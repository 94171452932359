<template>
    <v-container>
        <v-data-iterator
            :items="children"
            :no-data-text="$t('Keine Medien verfügbar')"
            :no-results-text="$t('Kein Ergebnis')"
            :footer-props="{
                'items-per-page-text': $t('Medien pro Seite'),
                'items-per-page-options': [10, 15, 20, { value: -1, text: $t('Alle') }]
            }"
            :items-per-page="10"
            :custom-filter="filter"
            :search="search"
            :hideDefaultFooter="children.length <= 10"
        >
            <template #header>
                <v-text-field :label="$t('Suchen')" v-model="search" hide-details />
            </template>
            <template #default="{ items }">
                <v-list dense>
                    <v-list-item-group :value="selectedItem" color="primary" :value-comparator="compare">
                        <v-list-item v-for="item in items" :key="item.id" @click="onClick(item)">
                            <v-list-item-action>
                                <v-icon :color="item.color">
                                    {{ icon(item) }}
                                </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.name || item.fileName }}</v-list-item-title>
                                <v-list-item-subtitle>{{ formattedSubtext(item) }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action v-if="selectable && isFilemanagerType(item) === 'FILE'">
                                <v-btn icon @click.stop="handleMediaSelect(item)">
                                    <v-icon>mdi-image-move</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </template>
        </v-data-iterator>
    </v-container>
</template>
<script>
import StaticFilemanagerMixin from '../../Mixin/StaticFilemanagerPrototypeMixin';
import moment from 'moment';

export default {
    name: 'static-filemanager-list',
    mixins: [StaticFilemanagerMixin],
    data: () => ({
        search: '',
        selectedItem: {}
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        media: {
            type: Object,
            default: () => ({})
        },
        selectable: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        media(value) {
            this.selectedItem = value;
        }
    },
    computed: {
        children() {
            if (this.value.children) {
                return [
                    ...this.value.children,
                    ...this.getFolderMedia(this.value).sort((a, b) =>
                        a.fileName.localeCompare(b.fileName, undefined, {
                            numeric: true,
                            sensitivity: 'base'
                        })
                    )
                ];
            }
            return [...this.getTree, ...this.getFolderMedia({ id: 'root' })];
        }
    },
    methods: {
        compare(a, b) {
            return !!a && a.id && this.children[b] && a.id === this.children[b].id;
        },
        extractType({ fileType }) {
            return fileType.substring(0, fileType.indexOf('/'));
        },
        icon(item) {
            switch (this.isFilemanagerType(item)) {
                case 'FILE':
                    switch (this.extractType(item)) {
                        case 'image':
                            return 'mdi-file-image-outline';
                        case 'video':
                            return 'mdi-file-video-outline';
                        case 'application':
                            return 'mdi-file-pdf-box';
                    }
                    break;
                case 'FOLDER':
                    return 'mdi-folder-outline';
            }
            return 'mdi-file-outline';
        },
        onClick(item) {
            if (this.isFilemanagerType(item) === 'FOLDER') {
                this.$emit('input', item);
            } else {
                if (this.media.id === item.id) {
                    this.$emit('media', {});
                } else {
                    this.$emit('media', item);
                }
            }
        },
        filter(items, search) {
            if (search.trim() === '') {
                return items;
            }
            let buildString = [...new Set(search.split(' '))].filter((word) => word !== '');
            return items.filter((item) =>
                buildString.every((word) =>
                    Object.entries(item)
                        .filter(([key, value]) => key === 'name' || key === 'fileName' || key === 'fileType')
                        .map(([key, value]) => value)
                        .join(' ')
                        .toLowerCase()
                        .includes(word.toLowerCase())
                )
            );
        },
        formattedSubtext(item) {
            if (this.isFilemanagerType(item) === 'FOLDER') {
                return `${this.getFolderMedia(item).length} ${this.$t('Dateien')}, ${moment(item.updatedAt).format('YYYY-MM-DD, h:mm A')}`;
            }
            return moment(item.updatedAt).format('YYYY-MM-DD, HH:mm');
        },
        handleMediaSelect(media) {
            this.$emit('selectedMedia', media);
        }
    }
};
</script>
