<template>
    <v-btn-toggle mandatory v-model="computedSelect" style="width: 100%" class="d-flex">
        <v-container>
            <v-row class="flex-wrap">
                <v-col :xs="6" :sm="4" :md="3" v-for="(option, index) in evaluationOptions" :key="`option-${index}`">
                    <v-btn block :value="option.value" :disabled="option.disabled">
                        {{ $t(option.name) }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-btn-toggle>
</template>

<script>
import CourseMixin from '../../Mixins/CourseMixin';

export default {
    name: 'evaluation-selector',
    mixins: [CourseMixin],
    props: {
        value: {
            type: String,
            default: 'COURSE'
        }
    },
    watch: {
        evaluationOptions(value) {
            const find = value.map((obj) => obj.value).indexOf(this.computedSelect);
            if (find !== -1) {
                this.rotate(find);
            }
        }
    },
    computed: {
        computedSelect: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        evaluationOptions() {
            return [
                {
                    name: this.$t('Kurse'),
                    value: 'COURSE',
                    disabled: Object.keys(this.getSelectedAdminCourse).length === 0
                },
                {
                    name: this.$t('Themen'),
                    value: 'TOPIC',
                    disabled: Object.keys(this.getSelectedTopic).length === 0
                },
                {
                    name: this.$t('Lernziele'),
                    value: 'LEARNING_GOAL',
                    disabled: Object.keys(this.getSelectedLearningGoal).length === 0
                },
                {
                    name: this.$t('Aktivitäten'),
                    value: 'ACTIVITY',
                    disabled: Object.keys(this.getSelectedActivity).length === 0
                }
            ];
        }
    },
    methods: {
        rotate(index) {
            const rotation = index % this.evaluationOptions.length;
            if (this.evaluationOptions[rotation].disabled) {
                this.rotate(index + 1);
            } else {
                this.computedSelect = this.evaluationOptions[rotation].value;
            }
        }
    }
};
</script>
