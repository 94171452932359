<template>
    <v-row>
        <v-col>
            <v-menu
                v-model="showStartDatePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="100%"
                class="ma-2"
            >
                <template v-slot:activator="{ on }">
                    <v-text-field
                        color="accent"
                        autocomplete="new-password"
                        attach
                        :disabled="disableStartDate"
                        prepend-icon="mdi-calendar-blank"
                        readonly
                        :label="labelStart"
                        v-model="computedStartDate"
                        v-on="on"
                        hide-details
                    ></v-text-field>
                </template>
                <v-date-picker
                    :locale="language"
                    ref="picker"
                    first-day-of-week="1"
                    v-model="computedStartDate"
                    scrollable
                    :reactive="true"
                    @input="showStartDatePicker = false"
                    type="date"
                    :min="min"
                />
            </v-menu>
        </v-col>
        <v-col>
            <v-menu
                ref="endPicker"
                v-model="showEndDatePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="100%"
                class="ma-2"
                hide-details
            >
                <template v-slot:activator="{ on }">
                    <v-text-field
                        color="accent"
                        autocomplete="new-password"
                        attach
                        readonly
                        :label="labelEnd"
                        prepend-icon="mdi-calendar-blank"
                        v-model="computedEndDate"
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                    :locale="language"
                    ref="rangePicker"
                    first-day-of-week="1"
                    v-model="computedEndDate"
                    scrollable
                    :reactive="true"
                    @input="showEndDatePicker = false"
                    type="date"
                    :min="computedStartDate"
                />
            </v-menu>
        </v-col>
    </v-row>
</template>

<script>
import moment from 'moment';

export default {
    data() {
        return {
            showStartDatePicker: false,
            showEndDatePicker: false
        };
    },
    props: {
        disableStartDate: {
            default: false,
            type: Boolean
        },
        startDateText: String,
        endDateText: String,
        labelStart: String,
        labelEnd: String,
        min: String,
        language: {
            default: 'de',
            type: String
        }
    },
    watch: {
        computedStartDate(val) {
            if (moment(val).isAfter(moment(this.computedEndDate))) {
                this.computedEndDate = val;
            }
        }
    },
    computed: {
        computedStartDate: {
            get() {
                return moment(this.startDateText).format('YYYY-MM-DD');
            },
            set(value) {
                this.$emit('startDateDataEmit', value);
            }
        },
        computedEndDate: {
            get() {
                return moment(this.endDateText).format('YYYY-MM-DD');
            },
            set(value) {
                this.$emit('endDateDataEmit', value);
            }
        }
    }
};
</script>
