import axios from '../../../plugins/Axios/axios';

const state = {
    profiles: [],
    selectedProfile: {}
};

const getters = {
    getProfiles() {
        return state.profiles;
    },
    getSelectedProfile() {
        return state.selectedProfile;
    }
};

const actions = {
    CREATE_PROFILE({ commit }, profile) {
        return axios.post(`permissionprofiles`, profile).then((response) => commit('updateSelectedProfile', response.data.data));
    },
    UPDATE_PROFILE({ commit }, profile) {
        return axios.patch(`permissionprofiles/${profile.id}`, profile).then((response) => response.data.data);
    },
    DELETE_PROFILE({ commit }, profile) {
        commit('updateSelectedProfile', {});
        return axios.delete(`permissionprofiles/${profile.id}`);
    },
    GET_PROFILES({ commit }) {
        return axios.get(`permissionprofiles`).then((response) => {
            commit('updateProfiles', response.data.data);
            return response;
        });
    },
    GET_SINGLE_PROFILE({ commit }, profile) {
        return axios.get(`permissionprofiles/${profile.id}`);
    },
    SET_SELECTED_PROFILE({ commit }, value) {
        commit('updateSelectedProfile', value);
    },
    CHECK_PERMISSIONS({ commit }, permissions) {
        return axios.post(`/checkPermissions`, permissions).then((response) => response.data.data.hasPermission);
    },
    RESET_PROFILE_LIST({ commit }) {
        commit('updateProfiles', []);
    }
};

const mutations = {
    clear: (state) => {
        state.profiles = [];
        state.selectedProfile = {};
    },
    updateProfiles: (state, profiles) => {
        state.profiles = profiles;
    },
    updateSelectedProfile: (state, profile) => {
        state.selectedProfile = profile;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
