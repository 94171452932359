<template>
    <v-card-text>
        <v-text-field outlined :label="$t('Schreiben Sie hier Ihre Anwort')" v-model="computedAnswer" hide-details />
    </v-card-text>
</template>

<script>
export default {
    name: 'fill-blank-option',
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        valid: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        computedAnswer() {
            this.$emit('valid', this.isValid);
        }
    },
    computed: {
        computedAnswer: {
            get() {
                this.$emit('valid', this.isValid);
                return this.optionsComputed && this.optionsComputed.answer ? this.optionsComputed.answer : '';
            },
            set(value) {
                this.$emit('input', [{ ...this.optionsComputed, answer: value }]);
            }
        },
        optionsComputed() {
            return this.value && this.value.knowledgeUnitOptions ? this.value.knowledgeUnitOptions[0] : {};
        },
        isValid() {
            return !!(this.optionsComputed && this.optionsComputed.answer && !!this.optionsComputed.answer.trim());
        }
    },
    activated() {
        this.$emit('valid', false);
    },
    mounted() {
        this.$emit('valid', false);
    }
};
</script>
