<template>
    <v-card>
        <v-container>
            <v-row>
                <v-col :cols="12" v-if="hasFile">
                    <MediaPreviewCard v-model="computedFile" />
                </v-col>
                <v-col :cols="12">
                    <FilePicker v-model="computedFile" :label="label" :accept="allowedFileTypes" />
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import FilePicker from '../../../Generic/FilePicker/FilePicker';
import MediaPreviewCard from '../../../FileManager/MediaPreviewCard';

export default {
    name: 'upload-answer-type',
    components: { FilePicker, MediaPreviewCard },
    props: {
        value: {
            type: [Object, FileList, String, Array],
            default: () => ({})
        },
        settings: {
            type: Object,
            default: () => ({})
        },
        validate: {
            type: Boolean,
            default: false
        },
        answerFile: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        hasFile() {
            return Object.keys(this.answerFile).length > 0;
        },
        isValid() {
            return Object.keys(this.value).length > 0;
        },
        computedFile: {
            get() {
                if (this.answerFile.fileURL === null) {
                    return this.value;
                } else {
                    return this.answerFile;
                }
            },
            set(value) {
                if (value instanceof FileList && Array.of(value).length > 0) {
                    const file = value.item(0);
                    const reader = new FileReader();
                    reader.addEventListener('load', () => {
                        this.$emit('answerFile', {
                            fileName: file.name,
                            fileType: file.type,
                            fileURL: reader.result,
                            size: file.size
                        });
                        this.$emit('input', value);
                        this.$emit('validate', true);
                    });
                    if (file) {
                        reader.readAsDataURL(file);
                    }
                } else {
                    this.$emit('answerFile', {});
                    this.$emit('input', {});
                    this.$emit('validate', false);
                }
            }
        },
        allowedFileTypes() {
            if (this.settings.allowedFileTypes === null) {
                return '';
            }
            return this.settings.allowedFileTypes.map((file) => '.'.concat(file.toLowerCase())).join(',');
        },
        label() {
            if (this.allowedFileTypes === '') {
                return '';
            }
            return `${this.$t('Erlaubte Dateityp(en)')}: ${this.allowedFileTypes}`;
        }
    },
    mounted() {
        this.$emit('validate', this.isValid);
    }
};
</script>
