import axios from '../../../plugins/Axios/axios';

const state = {
    courseGradingTables: [],
    selectedGradingTable: {}
};

const getters = {
    getCourseGradingTables() {
        return state.courseGradingTables;
    },
    getSelectedGradingTable() {
        return state.selectedGradingTable;
    }
};

const actions = {
    SET_GRADING_TABLES({ commit }, gradingTables) {
        commit('updateCourseGradingTables', gradingTables);
    },
    SET_SELECTED_GRADING_TABLE({ commit }, gradingTable) {
        commit('updateSelectedGradingTable', gradingTable);
    },
    GET_COURSE_GRADING_TABLES({ commit }, { id: courseId }) {
        return axios
            .get(`/courselearning/courseinstances/${courseId}/gradingtables`)
            .then((response) => response.data.data)
            .then((response) => commit('updateCourseGradingTables', response));
    },
    GET_GRADING_TABLE({ commit }, { id: gradingTableTempId }) {
        return axios.get(`/courselearning/gradingtableinstances/${gradingTableTempId}`).then((response) => response.data.data);
    },
    CREATE_GRADING_TABLE({ commit }, request) {
        return axios.post(`/courselearning/gradingtableinstances`, request).then((response) => response.data.data);
    },
    UPDATE_GRADING_TABLE({ commit }, request) {
        const { id: gradingTableTempId, ...gradingTable } = request;
        return axios
            .patch(`/courselearning/gradingtableinstances/${gradingTableTempId}`, gradingTable)
            .then((response) => response.data.data)
            .then((response) => commit('updateCourseGradingTables', response));
    },
    UPDATE_COURSE_GRADING_TABLE({ commit }, { course, gradingTables }) {
        const { id: courseId } = course;
        const tables = gradingTables.map((table) => ({ id: table.id }));
        return axios
            .patch(`/courselearning/courseinstances/${courseId}/gradingtables`, tables)
            .then((response) => response.data.data)
            .then((response) => commit('updateCourseGradingTables', response));
    },
    DELETE_GRADING_TABLE({ commit }, request) {
        const { id: gradingTableTempId } = request;
        return axios.delete(`/courselearning/gradingtableinstances/${gradingTableTempId}`).then(() => commit('deleteGradingTable', request));
    }
};

const mutations = {
    clear: (state) => {
        state.courseGradingTables = [];
        state.selectedGradingTable = {};
    },
    updateSelectedGradingTable: (state, response) => (state.selectedGradingTable = response),
    updateCourseGradingTables: (state, response) => {
        if (Array.isArray(response)) {
            state.courseGradingTables = response;
        } else {
            if (response.hasOwnProperty('id')) {
                if (state.selectedGradingTable.id === response.id) {
                    state.selectedGradingTable = response;
                }
                if (state.courseGradingTables.map((course) => course.id).includes(response.id)) {
                    state.courseGradingTables = state.courseGradingTables.map((course) => {
                        if (course.id === response.id) {
                            return response;
                        }
                        return course;
                    });
                } else {
                    state.courseTopics.push(response);
                }
            }
        }
    },
    deleteGradingTable: (state, request) => {
        if (request.hasOwnProperty('id')) {
            if (state.selectedGradingTable.id === request.id) {
                state.selectedGradingTable = {};
            }
            const find = state.courseGradingTables.map((data) => data.id).indexOf(request.id);
            if (find !== -1) {
                state.courseGradingTables.splice(find, 1);
            }
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
