<template>
    <v-overlay :value="delayedDialog" />
    <!-- <v-sheet color="white" class="rounded-circle">
            <v-progress-circular indeterminate color="primary" :size="125" :width="10">
                {{ $t('Laden') }}
            </v-progress-circular>
        </v-sheet>
    </v-overlay> -->
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    data: () => ({
        delayedDialog: false,
        delay: 300,
        delayTimer: null
    }),
    computed: {
        ...mapGetters('LoadOperations', ['isLoading'])
    },
    watch: {
        isLoading(val) {
            if (val) {
                this.delayedDialog = val;
                clearTimeout(this.delayTimer);
                this.delayTimer = null;
            } else {
                this.delayTimer = setTimeout(() => (this.delayedDialog = val), this.delay);
            }
        }
    }
};
</script>
