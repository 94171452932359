import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters('AdminGroupsOp', ['getAdminGroup', 'getAdminGroupTree', 'getAdminGroups', 'getSelectedAdminGroup', 'getAdminGroupUsers'])
    },
    methods: {
        ...mapActions('Admin', ['GET_ADMIN_LIST']),
        ...mapActions('AdminGroupsOp', [
            // GET
            'GET_ADMIN_GROUP_ROOT',
            'GET_ADMIN_SUBGROUPS',
            'GET_ADMIN_GROUP_MEMBERS',
            'GET_ADMIN_GROUPS',
            'GET_ADMIN_GROUP_TREE',
            'GET_ALL_ADMIN_GROUP_USERS',
            // SET
            'SET_SELECTED_ADMIN_GROUP',
            'SET_ADMIN_GROUP_USERS',
            // ADD
            'ADD_ADMIN_GROUP',
            'ADD_ADMIN_SUBGROUP',
            // UPDATE
            'UPDATE_ADMIN_GROUP',
            'UPDATE_ADMIN_SUBGROUP',
            // DELETE
            'DELETE_ADMIN_GROUP'
        ])
    }
};
