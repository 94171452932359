import axios from '../../plugins/Axios/axios';

const state = {
    statistic: [],
    groupItemList: {}
};

// getters
const getters = {
    // DASHBOARD
    getUserStatistic(state) {
        return state.statistic;
    },
    getGroupItemList(state) {
        return Object.assign({}, state.groupItemList, { userGroups: [], adminGroups: [] });
    }
};

// actions
const actions = {
    GET_USERSTATISTIC({ commit }, request) {
        return axios.post('userStatistics', request).then((response) => {
            commit('updateStatistic', response.data.data.results);
            return response;
        });
    },
    GET_ADMINSTATISTIC({ commit }, request) {
        return axios.post('statistics', request).then((response) => {
            commit('updateStatistic', response.data.data.results);
            return response;
        });
    },
    GET_GROUPITEM_LIST({ commit }, request) {
        return axios
            .post('statistics/groupItems', request)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateGroupItemList', response);
                return response;
            });
    },
    EXPORT_STATISTICS({ commit }, request) {
        return axios({
            url: 'statistics/export',
            data: request,
            method: 'post',
            responseType: 'blob'
        }).then((response) => response.data);
    },
    GET_LINK({ commit }, request) {
        return axios.get(request).then((response) => response.data.data);
    }
};

// mutations
const mutations = {
    clear: (state) => {
        state.statistic = [];
        state.groupItemList = {};
    },
    updateStatistic: (state, statistic) => {
        state.statistic = statistic;
    },
    updateGroupItemList: (state, groupItemList) => {
        state.groupItemList = groupItemList;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
