<template>
    <v-card text>
        <v-subheader>{{ $t('Katalog') }}</v-subheader>
        <v-form v-model="valid">
            <v-container grid-list-md>
                <v-layout wrap>
                    <v-flex xs12>
                        <v-text-field v-model="name" :label="$t('Name')" :rules="[notEmpty]" />
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field v-model="description" :label="$t('Beschreibung')" />
                    </v-flex>
                </v-layout>
            </v-container>
        </v-form>
        <v-subheader>{{ $t('Zielort') }}</v-subheader>
        <CatalogSelectorTree v-model="selectedCatalogs" />
        <v-card-actions>
            <v-btn block color="success" :disabled="!computedDisable" @click="submit">
                {{ $t('Einfügen') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import CatalogSelectorTree from './MultipleCatalogSubgroupSelectorTree';
import AuthoringToolMixin from '../Mixins/AuthoringtoolMixin';

export default {
    name: 'catalog-copy-view',
    components: { CatalogSelectorTree },
    mixins: [AuthoringToolMixin],
    data: () => ({
        name: '',
        description: '',
        valid: false,
        selectedCatalogs: []
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        knowledgeUnit: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        computedDisable() {
            return this.selectedCatalogs.length > 0 && this.valid;
        }
    },
    methods: {
        serialize(catalogSubgroup) {
            return {
                catalogGroup: this.getParent(catalogSubgroup),
                catalogSubgroup,
                name: this.name,
                description: this.description
            };
        },
        submit() {
            Promise.all(this.selectedCatalogs.map((catalog) => this.serialize(catalog)).map((payload) => this.COPY_CATALOG(payload))).then((response) => {
                this.$emit('openTree', this.selectedCatalogs.map((catalog) => this.calculateTreePath(catalog)).flat(1));
                if (response.length === 1) {
                    this.$emit('input', response[0]);
                }
                this.$emit('switchView', { component: '', data: {}, type: '' });
                this.selectedCatalogs = [];
            });
        },
        notEmpty(value) {
            return (!!value && value.trim().length > 0) || this.$t('Dieses Feld darf nicht leer sein');
        }
    },
    activated() {
        this.name = this.value.name;
        this.description = this.value.description;
        this.selectedCatalogs = [];
    }
};
</script>
