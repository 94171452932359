import axios from '../../plugins/Axios/axios';

const state = {
    excelUsersMap: {}
};

const getters = {
    getExcelUsersMap() {
        return state.excelUsersMap;
    }
};

const actions = {
    UPLOAD_EXCEL_SHEET({ commit }, sheet) {
        const form = new FormData();
        form.append('file', sheet);
        return axios.post('/excel/scan', form).then((response) => commit('updateExcelUsersMap', response.data.data));
    },
    CONFIRM_EXCEL_SHEET({ commit, dispatch }, sheet) {
        return axios.post('/excel/upload', sheet).then(() => {
            commit('updateExcelUsersMap', {});
            dispatch('UserPrototype/BUILD_USER_MAPS', null, {
                root: true
            }).then(() => dispatch('UserPrototype/BUILD_ADMIN_MAPS', null, { root: true }));
        });
    }
};

const mutations = {
    clear: (state) => {
        state.excelUsersMap = {};
    },
    updateExcelUsersMap: (state, response) => {
        state.excelUsersMap = response;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
