<template>
    <v-card>
        <Toolbar
            :toolbarTitle="toolbarText"
            :tooltipMessage="$t('Auf dieser Seite haben Sie Überblick auf Ihre Themen')"
            :showDrawer="false"
            :lastPath="!(showAdd || showDelete) ? { name: 'forums' } : null"
            @backEmit="reset"
        />
        <v-container fluid>
            <v-row no-gutters>
                <v-col :cols="12">
                    <keep-alive>
                        <component
                            :is="isComponent"
                            :type="type"
                            @create="handleCreate"
                            @update="handleUpdate"
                            @delete="handleDelete"
                            :permissions="editorPermissions"
                            @input="reset"
                        />
                    </keep-alive>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import ForumMixin from '../../Components/GroupForum/Mixins/GroupForumMixin';
import Toolbar from '../../Components/Generic/Toolbar';
import DeleteThread from '../../Components/GroupForum/DeleteForum';
import ThreadList from '../../Components/GroupForum/ThreadList';
import AddUpdateThread from '../../Components/GroupForum/AddUpdateThread';

export default {
    name: 'group-forum-threads',
    mixins: [ForumMixin],
    components: { Toolbar, ThreadList, AddUpdateThread, DeleteThread },
    data: () => ({
        showAdd: false,
        showDelete: false,
        type: 'ADD',
        editorPermissions: {
            add: true,
            update: true,
            delete: true,
            move: false,
            copy: false,
            details: false,
            groups: false
        }
    }),
    computed: {
        isSelected() {
            return Object.keys(this.getSelectedThread).length > 0;
        },
        isComponent() {
            if (this.showAdd) {
                return AddUpdateThread;
            }
            if (this.showDelete) {
                return DeleteThread;
            }
            return ThreadList;
        },
        toolbarText() {
            if (this.showAdd) {
                switch (this.type) {
                    case 'ADD':
                        return this.$t('Thema erstellen');
                    case 'UPDATE':
                        return this.$t('Thema bearbeiten');
                }
            }
            return this.$t('Gruppenforen Themen');
        }
    },
    methods: {
        reset() {
            this.showAdd = false;
            this.showDelete = false;
        },
        handleDelete() {
            this.showDelete = true;
        },
        handleCreate() {
            this.type = 'ADD';
            this.showAdd = true;
        },
        handleUpdate() {
            this.type = 'UPDATE';
            this.showAdd = true;
        }
    }
};
</script>
