<template>
    <v-card height="100%">
        <Toolbar :toolbarTitle="$t('Gruppenforen Gruppen')" />
        <GroupMemberList />
    </v-card>
</template>

<script>
import Toolbar from '../../Components/Generic/Toolbar';
import GroupMemberList from '../../Components/GroupForum/GroupMemberList';

export default {
    name: 'group-forums-groups',
    components: { Toolbar, GroupMemberList }
};
</script>
