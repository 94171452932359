import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters('CourseOperations', [
            'getAdminCourses',
            'getAttendeeCourses',
            'getAttendeeCurrentCourses',
            'getAttendeePastCourses',
            'getSelectedAdminCourse',
            'getSelectedAttendeeCourse',
            'getCourseBreadcrumbs',
            'getAllCourses',
            'getSchedulingMyCourse',
            'getRemainingCourseLicences',
            'getDefaultCoursePicture',
            'getTabs'
        ]),
        ...mapGetters('TopicOperations', ['getCourseTopics', 'getSelectedTopic', 'getSchedulingMyTopic', 'getDefaultTopicPicture']),
        ...mapGetters('LearningGoalOperations', ['getTopicLearningGoals', 'getSelectedLearningGoal', 'getSchedulingMyLearningGoal']),
        ...mapGetters('ActivityOperations', [
            'getActivityAnswers',
            'getSelectedActivity',
            'getLearningGoalActivities',
            'getLearningGoalActivitiesMap',
            'getActivityTypes',
            'getAllowedFileTypes',
            'getSchedulingMyActivity'
        ]),
        ...mapGetters('GradingTableOperations', ['getCourseGradingTables', 'getSelectedGradingTable']),
        ...mapGetters('TaxonomyTableOperations', ['getDefaultTaxonomies', 'getCourseTaxonomies']),
        ...mapGetters('EvaluationOperations', ['getCourseEvaluations']),
        ...mapGetters('MaterialOperations', ['getMaterial']),
        ...mapGetters('MyCourseForumOperations', [
            'getForums',
            'getThreads',
            'getAnswers',
            'getSelectedGroup',
            'getSelectedForum',
            'getSelectedThread',
            'getSelectedAnswer',
            'getForumPath'
        ]),
        ...mapGetters('MyCourseGroupOperations', [
            'getCourseGroups',
            'getSelectedCourseGroup',
            'getSelectedCourseGroupUser',
            'getCourseGroupUsers',
            'getCurrentGroup',
            'getGroupJoinRequests',
            'getCourseGroupSwitch'
        ]),
        ...mapGetters('MyCourseGroupForumOperations', [
            'getCourseGroupForums',
            'getCourseGroupThreads',
            'getCourseGroupAnswers',
            'getCourseGroupSelectedGroup',
            'getCourseGroupSelectedForum',
            'getCourseGroupSelectedThread',
            'getCourseGroupSelectedAnswer',
            'getCourseGroupForumPath'
        ]),
        isAdminCourse() {
            return Object.keys(this.getSelectedAdminCourse).length > 0;
        },
        courseBreadcrumbs() {
            const breadcrumbs = [
                {
                    ...(this.isAdminCourse ? this.getSelectedAdminCourse : this.getSelectedAttendeeCourse),
                    href: { name: this.isAdminCourse ? 'courses' : 'myCourses' }
                },
                {
                    ...this.getSelectedTopic,
                    href: { name: this.isAdminCourse ? 'topics' : 'myTopics' }
                },
                {
                    ...this.getSelectedLearningGoal,
                    href: {
                        name: this.isAdminCourse ? 'learningGoals' : 'myLearningGoals'
                    }
                },
                {
                    ...this.getSelectedActivity,
                    href: {
                        name: this.isAdminCourse ? 'activities' : 'myActivities'
                    }
                }
            ]
                .filter((item) => item.hasOwnProperty('name'))
                .map((item) => ({ text: item.name, href: item.href }));

            const find = breadcrumbs.findIndex((item) => item.href.name === this.$router.currentRoute.name);
            if (find !== -1) {
                return breadcrumbs.slice(0, find);
            }

            return breadcrumbs;
        }
    },
    methods: {
        ...mapActions('ActivityOperations', [
            'GET_ACTIVITY_ANSWERS',
            'SET_LEARNING_GOAL_ACTIVITIES_MAP',
            'SET_LEARNING_GOAL_ACTIVITIES',
            'SUBMIT_FILE_ACTIVITY',
            'SUBMIT_TEXTFIELD_ACTIVITY',
            'SUBMIT_CHECK_ACTIVITY',
            'SUBMIT_INFORM_ACTIVITY',
            'SET_SELECTED_ACTIVITY',
            'GET_ACTIVITY_ALLOWED_FILE_TYPES',
            'GET_ACTIVITY_TYPES',
            'GET_LEARNING_GOAL_ACTIVITY',
            'GET_LEARNING_GOAL_ACTIVITIES',
            'UPDATE_LEARNING_GOAL_ACTIVITIES',
            // File
            'GET_FILE_ACTIVITY',
            'CREATE_FILE_ACTIVITY',
            'UPDATE_FILE_ACTIVITY',
            'DELETE_FILE_ACTIVITY',
            'GET_FILE_ANSWER',
            // Check
            'GET_CHECK_ACTIVITY',
            'CREATE_CHECK_ACTIVITY',
            'UPDATE_CHECK_ACTIVITY',
            'DELETE_CHECK_ACTIVITY',
            // Textfield
            'GET_TEXTFIELD_ACTIVITY',
            'CREATE_TEXTFIELD_ACTIVITY',
            'UPDATE_TEXTFIELD_ACTIVITY',
            'DELETE_TEXTFIELD_ACTIVITY',
            'GET_TEXTFIELD_ANSWER',
            // misc.
            'EVALUATE_OWN_ACTIVITY',
            'REORDER_ACTIVITIES',
            // Schedule
            'GET_ACTIVITY_SCHEDULING',
            'UPDATE_ACTIVITY_SCHEDULING',
            // Inform
            'GET_INFORM_ACTIVITY',
            'CREATE_INFORM_ACTIVITY',
            'UPDATE_INFORM_ACTIVITY',
            'DELETE_INFORM_ACTIVITY',
            // Quiz
            'GET_QUIZ_ACTIVITY',
            'GET_QUIZ_EXCLUDED_KNOWLEDGE_UNITS',
            'GET_QUIZ_KNOWLEDGE_UNITS',
            'GET_USER_QUIZ_ANSWERS',
            'GET_USER_QUIZ',
            'CREATE_QUIZ_ACTIVITY',
            'UPDATE_QUIZ_ACTIVITY',
            'DELETE_QUIZ_ACTIVITY',
            'GET_QUIZ_SOLUTIONS',
            'ADD_QUIZ_SOLUTION',
            'CAST_USER_QUIZ_VOTE'
        ]),
        ...mapActions('CourseOperations', [
            'GET_REMAINING_COURSES',
            'SET_SELECTED_TAB',
            'SET_SELECTED_ADMIN_COURSE',
            'SET_SELECTED_ATTENDEE_COURSE',
            'SET_ADMIN_COURSES',
            'SET_ATTENDEE_COURSES',
            'GET_COURSES_AS_ADMIN',
            'GET_COURSES_AS_ATTENDEE',
            'GET_CURRENT_COURSES_AS_ATTENDEE',
            'GET_PAST_COURSES_AS_ATTENDEE',
            'GET_ALL_COURSES',
            'GET_COURSE',
            'GET_COURSE_ADMINS_AND_ATTENDEES',
            'CREATE_COURSE',
            'UPDATE_COURSE',
            'UPDATE_COURSE_ADMINS_AND_ATTENDEES',
            'DELETE_COURSE',
            'GET_COURSE_SCHEDULING',
            'UPDATE_COURSE_SCHEDULING',
            'GET_COURSE_USER_PREFERENCES',
            'UPDATE_COURSE_USER_PREFERENCES',
            'GET_COURSE_PICTURE',
            'COPY_COURSE'
        ]),
        ...mapActions('EvaluationOperations', [
            'GET_COURSE_EVALUATIONS',
            'GET_TOPIC_EVALUATIONS',
            'GET_LEARNING_GOAL_EVALUATIONS',
            'GET_ACTIVITY_EVALUATIONS',
            'GET_ATTENDEES_ANSWERS',
            'GET_ATTENDEES_EVALUATION',
            'GET_ATTENDEES_COURSE_EVALUATION',
            'CREATE_SELF_EVALUATION',
            'CREATE_COURSE_EVALUATION',
            'CREATE_TOPIC_EVALUATION',
            'CREATE_LEARNING_GOAL_EVALUATION',
            'CREATE_ACTIVITY_EVALUATION'
        ]),
        ...mapActions('GradingTableOperations', [
            'SET_GRADING_TABLES',
            'SET_SELECTED_GRADING_TABLE',
            'GET_COURSE_GRADING_TABLES',
            'UPDATE_COURSE_GRADING_TABLE',
            'GET_GRADING_TABLE',
            'CREATE_GRADING_TABLE',
            'UPDATE_GRADING_TABLE',
            'DELETE_GRADING_TABLE'
        ]),
        ...mapActions('LearningGoalOperations', [
            'SET_LEARNING_GOALS',
            'SET_SELECTED_LEARNING_GOAL',
            'GET_LEARNING_GOAL',
            'GET_TOPIC_LEARNING_GOALS',
            'CREATE_LEARNING_GOAL',
            'UPDATE_LEARNING_GOAL',
            'UPDATE_TOPIC_LEARNING_GOALS',
            'DELETE_LEARNING_GOAL',
            'GET_LEARNING_GOAL_SCHEDULING',
            'UPDATE_LEARNING_GOAL_SCHEDULING',
            'REORDER_LEARNING_GOALS'
        ]),
        ...mapActions('TaxonomyTableOperations', [
            'GET_DEFAULT_TAXONOMIES',
            'GET_COURSE_TAXONOMY_TABLE',
            'GET_TAXONOMY_TABLE',
            'CREATE_TAXONOMY',
            'CREATE_TAXONOMY_TABLE',
            'UPDATE_TAXONOMY',
            'UPDATE_TAXONOMY_TABLE',
            'UPDATE_COURSE_TAXONOMY_TABLE',
            'DELETE_TAXONOMY'
        ]),
        ...mapActions('TopicOperations', [
            'SET_TOPICS',
            'SET_SELECTED_TOPIC',
            'GET_TOPIC',
            'GET_COURSE_TOPICS',
            'CREATE_TOPIC',
            'UPDATE_TOPIC',
            'UPDATE_COURSE_TOPICS',
            'DELETE_TOPIC',
            'GET_TOPIC_SCHEDULING',
            'UPDATE_TOPIC_SCHEDULING',
            'REORDER_TOPICS',
            'GET_TOPIC_PICTURE'
        ]),
        ...mapActions('MaterialOperations', [
            'CREATE_MATERIAL',
            'UPDATE_MATERIAL',
            'UPDATE_COURSE_MATERIALS',
            'UPDATE_ACTIVITY_MATERIALS',
            'DELETE_MATERIAL',
            'GET_COURSE_MATERIALS',
            'GET_ACTIVITY_MATERIALS'
        ]),
        ...mapActions('MyCourseForumOperations', [
            'REMOVE_FORUM_PATH',
            'ADD_FORUM_PATH',
            'SET_SELECTED_GROUP',
            'SET_SELECTED_FORUM',
            'SET_SELECTED_THREAD',
            'SET_SELECTED_ANSWER',
            'GET_FORUMS',
            'CREATE_FORUM',
            'UPDATE_FORUM',
            'DELETE_FORUM',
            'GET_THREADS',
            'CREATE_THREAD',
            'UPDATE_THREAD',
            'DELETE_THREAD',
            'GET_ANSWERS',
            'CREATE_ANSWER',
            'UPDATE_ANSWER',
            'DELETE_ANSWER'
        ]),
        ...mapActions('MyCourseGroupOperations', [
            'SET_SELECTED_COURSE_GROUP',
            'SET_SELECTED_COURSE_GROUP_USER',
            'GET_SELECTED_COURSE_GROUPS',
            'CREATE_SELECTED_COURSE_GROUP',
            'UPDATE_SELECTED_COURSE_GROUP',
            'DELETE_SELECTED_COURSE_GROUP',
            'LEAVE_SELECTED_COURSE_GROUP',
            'SELECTED_COURSE_JOIN_GROUP_REQUEST',
            'SELECTED_COURSE_ACCEPT_GROUP_REQUEST',
            'REMOVE_USER_FROM_SELECTED_COURSE_GROUP',
            'GET_SELECTED_COURSE_GROUP_USERS',
            'GET_CURRENT_SELECTED_COURSE_GROUP',
            'SET_COURSE_SWITCH'
        ]),
        ...mapActions('MyCourseGroupForumOperations', [
            'REMOVE_COURSE_GROUP_FORUM_PATH',
            'ADD_COURSE_GROUP_FORUM_PATH',
            'SET_COURSE_GROUP_SELECTED_GROUP',
            'SET_COURSE_GROUP_SELECTED_FORUM',
            'SET_COURSE_GROUP_SELECTED_THREAD',
            'SET_COURSE_GROUP_SELECTED_ANSWER',
            'GET_COURSE_GROUP_FORUMS',
            'CREATE_COURSE_GROUP_FORUM',
            'UPDATE_COURSE_GROUP_FORUM',
            'DELETE_COURSE_GROUP_FORUM',
            'GET_COURSE_GROUP_THREADS',
            'CREATE_COURSE_GROUP_THREAD',
            'UPDATE_COURSE_GROUP_THREAD',
            'DELETE_COURSE_GROUP_THREAD',
            'GET_COURSE_GROUP_ANSWERS',
            'CREATE_COURSE_GROUP_ANSWER',
            'UPDATE_COURSE_GROUP_ANSWER',
            'DELETE_COURSE_GROUP_ANSWER'
        ]),
        resetCourseSelection() {
            this.SET_SELECTED_ADMIN_COURSE({});
            this.SET_SELECTED_ATTENDEE_COURSE({});
            // this.SET_ADMIN_COURSES([]);
            this.SET_ATTENDEE_COURSES([]);
            this.SET_SELECTED_TOPIC({});
            this.SET_TOPICS([]);
            this.SET_SELECTED_LEARNING_GOAL({});
            this.SET_LEARNING_GOALS([]);
            this.SET_SELECTED_GRADING_TABLE({});
            this.SET_GRADING_TABLES([]);
            this.SET_SELECTED_ACTIVITY({});
            this.SET_LEARNING_GOAL_ACTIVITIES([]);
        },
        resetTopicSelection() {
            this.SET_SELECTED_TOPIC({});
            this.SET_TOPICS([]);
            this.SET_SELECTED_LEARNING_GOAL({});
            this.SET_LEARNING_GOALS([]);
            this.SET_SELECTED_GRADING_TABLE({});
            this.SET_GRADING_TABLES([]);
            this.SET_SELECTED_ACTIVITY({});
            this.SET_LEARNING_GOAL_ACTIVITIES([]);
        },
        resetLearningGoalSelection() {
            this.SET_SELECTED_LEARNING_GOAL({});
            this.SET_LEARNING_GOALS([]);
            this.SET_SELECTED_ACTIVITY({});
            this.SET_LEARNING_GOAL_ACTIVITIES([]);
        },
        resetActivitySelection() {
            this.SET_SELECTED_ACTIVITY({});
            this.SET_LEARNING_GOAL_ACTIVITIES([]);
        },
        isCourseManager() {
            const { id: adminId } = this.getLoggedInAdmin;
            if (adminId) {
                return this.GET_COURSE_ADMINS_AND_ATTENDEES(this.getSelectedAdminCourse).then((response) => {
                    return response.admins.map((admin) => admin.id).includes(adminId);
                });
            }
            return false;
        }
    }
};
