import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
    computed: {
        ...mapGetters('UserPrototype', [
            'getGroupUsers',
            'getUserTree',
            'getUserTreeWithoutRoot',
            'getAdminTree',
            'getAdminTreeWithoutRoot',
            'getParent',
            'getView',
            'getUsersGroups',
            'getUsersProfiles',
            'getTreeObject',
            'getTreeItemPath',
            'getTreeItemCascade',
            'getExplorerConfig',
            'getUserTreeFiltered',
            'getAdminTreeFiltered',
            'getUserTreeFilteredByUsers',
            'getAdminTreeFilteredByUsers'
        ]),
        ...mapGetters('CustomerOperations', ['getTotalLicences', 'getConsumedLicences', 'getRemainingLicences']),
        ...mapGetters('AdminGroupsOp', ['getAdminGroupList']),
        ...mapGetters('User', ['getDeletedUserList', 'getSelectedUser', 'getUserStore', 'getUserList', 'getSelectedUser']),
        ...mapGetters('Admin', [
            'getDeletedAdminList',
            'getSelectedAdmin',
            'getAdminStore',
            'getAdminList',
            'getSelectedProfileList',
            'getSelectedAdminGroupList'
        ]),
        ...mapGetters('UserGroupsOp', ['getUserGroupList']),
        ...mapGetters('ExcelUsersOperations', ['getExcelUsersMap'])
    },
    methods: {
        ...mapActions('UserPrototype', [
            'BUILD_USER_MAPS',
            'BUILD_ADMIN_MAPS',
            'SET_VIEW',
            'GET_EXPLORER_CONFIG',
            'UPDATE_EXPLORER_CONFIG',
            'RESET_EXPLORER_CONFIG',
            'GET_USER_TASK_MAP'
        ]),
        ...mapActions('UserApp', ['GET_USER_TASK']),
        ...mapActions('CustomerOperations', ['GET_LICENCES']),
        ...mapActions('User', [
            'ADD_USER',
            'UPDATE_USER',
            'GET_USER_LIST',
            'DELETE_USER_BY_ID',
            'UPDATE_ROLE_USERTOADMIN',
            'SELECT_USERGROUP_LIST',
            'GET_DELETED_USER_LIST',
            'SELECT_USER',
            'RECOVER_USER',
            'RESEND_ACTIVATIONLINK_BY_ID',
            'ADD_USER_TO_USERGROUP_LIST'
        ]),
        ...mapActions('Admin', [
            'ADD_ADMIN',
            'UPDATE_ADMIN',
            'GET_DELETED_ADMIN_LIST',
            'GET_ADMIN_LIST',
            'RECOVER_ADMIN',
            'SELECT_ADMIN',
            'DELETE_ADMIN_BY_ID',
            'UPDATE_ROLE_ADMINTOUSER',
            'GET_PERMISSION_PROFILES_FROM_USER',
            'SELECT_ADMINGROUP_LIST',
            'SELECT_PROFILE_LIST',
            'ADD_ADMIN_TO_ADMINGROUP_LIST',
            'SET_PERMISSION_PROFILES_TO_USER'
        ]),
        ...mapActions('ExcelUsersOperations', ['UPLOAD_EXCEL_SHEET', 'CONFIRM_EXCEL_SHEET']),
        ...mapMutations('ExcelUsersOperations', ['updateExcelUsersMap']),
        ...mapActions('AdminGroupsOp', [
            // GET
            'GET_ADMIN_GROUP_ROOT',
            'GET_ADMIN_SUBGROUPS',
            'GET_ADMIN_GROUP_MEMBERS',
            'GET_ADMIN_GROUPS',
            'GET_ADMIN_GROUP_TREE',
            'GET_ALL_ADMIN_GROUP_USERS',
            // SET
            'SET_SELECTED_ADMIN_GROUP',
            'SET_ADMIN_GROUP_USERS',
            // ADD
            'ADD_ADMIN_GROUP',
            'ADD_ADMIN_SUBGROUP',
            // UPDATE
            'UPDATE_ADMIN_GROUP',
            'UPDATE_ADMIN_SUBGROUP',
            // DELETE
            'DELETE_ADMIN_GROUP'
        ]),
        ...mapActions('UserGroupsOp', [
            // GET
            'GET_GROUP_LEADERS',
            'GET_USER_GROUP_ROOT',
            'GET_USER_SUBGROUPS',
            'GET_USER_GROUP_TREE',
            'GET_USER_GROUP_MEMBERS',
            'GET_USER_GROUPS',
            'GET_ALL_USER_GROUP_USERS',
            // SET
            'SET_SELECTED_USER_GROUP',
            'SET_USER_GROUP_USERS',
            // ADD
            'ADD_USER_GROUP',
            'ADD_USER_SUBGROUP',
            // UPDATE
            'UPDATE_USER_GROUP',
            // DELETE
            'DELETE_USER_GROUP'
        ]),
        isUserOrGroup(object) {
            if (object.hasOwnProperty('displayName')) {
                return 'USER';
            }
            if (object.hasOwnProperty('name')) {
                return 'GROUP';
            }
            return undefined;
        },
        calculateTreePath(value) {
            let currentNode = value;
            let path = [];
            while (currentNode) {
                path.unshift({ ...currentNode });
                currentNode = this.getParent(currentNode);
            }
            return path;
        },
        getDisplay({ firstName, lastName, displayName, personalNumber }) {
            switch (this.getExplorerConfig.display) {
                case 'FIRSTNAME':
                    return firstName;
                case 'LASTNAME':
                    return lastName;
                case 'DISPLAYNAME':
                    return displayName;
                case 'PERSONALNUMBER':
                    return personalNumber;
                case 'FIRSTNAME_LASTNAME':
                    return `${firstName} ${lastName}`;
                case 'LASTNAME_FIRSTNAME':
                    return `${lastName}, ${firstName}`;
            }
        }
    }
};
