<template>
    <v-card height="100%" flat>
        <slot name="header"></slot>
        <v-container fluid>
            <v-row no-gutters>
                <v-col :cols="12">
                    <keep-alive>
                        <components
                            :is="headerComponentSwitch"
                            :reason="reason"
                            :correctAnswer="correctAnswer"
                            :value="computedSelectedKnowledgeUnit"
                            :onlyShowResult="onlyShowResult"
                            :settings="settings"
                            :onLoop="onLoop"
                            :onStart="onStart"
                            @back="showKnowledgeUnit = false"
                        >
                            <slot></slot>
                        </components>
                    </keep-alive>
                </v-col>
                <v-col :cols="12">
                    <keep-alive>
                        <component v-show="correctAnswer.length > 0" :is="componentSwitch" v-model="computedSelectedKnowledgeUnit" :items="correctAnswer" />
                    </keep-alive>
                </v-col>
            </v-row>
        </v-container>
        <slot name="footer"></slot>
    </v-card>
</template>

<script>
import QuizResultKnowledgeUnitHeader from './QuizResultKnowledgeUnitHeader';
import QuizResultSelectorHeader from './QuizResultSelectorHeader';
import QuizResultSelector from './QuizResultSelector';
import QuizResultKnowledgeUnit from './QuizResultKnowledgeUnit';

export default {
    name: 'quiz-result',
    components: {
        QuizResultSelector,
        QuizResultKnowledgeUnit,
        QuizResultKnowledgeUnitHeader,
        QuizResultSelectorHeader
    },
    data: () => ({
        showExplanation: false,
        selectedContent: {},
        pagination: {
            page: 1
        },
        selectedKnowledgeUnit: {},
        showKnowledgeUnit: false
    }),
    props: {
        settings: {
            type: Object,
            default: () => ({})
        },
        reason: {
            type: String,
            default: ''
        },
        correctAnswer: {
            type: Array,
            default: () => []
        },
        onlyShowResult: {
            type: Boolean,
            default: false
        },
        onLoop: {
            type: Function,
            default: () => new Promise(),
            validator: (func) => func instanceof Promise || 'onResult must be of type Promise'
        },
        onStart: {
            type: Function,
            default: () => new Promise(),
            validator: (func) => func instanceof Promise || 'onStart must be of type Promise'
        }
    },
    watch: {
        correctAnswer: {
            immediate: true,
            handler(value) {
                if (value.length === 1) {
                    this.selectedKnowledgeUnit = value[0];
                    this.showKnowledgeUnit = true;
                } else {
                    this.showKnowledgeUnit = false;
                }
            }
        }
    },
    computed: {
        computedSelectedKnowledgeUnit: {
            get() {
                return this.selectedKnowledgeUnit;
            },
            set(value) {
                this.selectedKnowledgeUnit = value;
                this.showKnowledgeUnit = true;
            }
        },
        headerComponentSwitch() {
            if (this.showKnowledgeUnit) {
                return QuizResultKnowledgeUnitHeader;
            }
            return QuizResultSelectorHeader;
        },
        componentSwitch() {
            if (this.showKnowledgeUnit) {
                return QuizResultKnowledgeUnit;
            }
            return QuizResultSelector;
        }
    },
    methods: {
        handleExplanation() {
            this.showExplanation = true;
        }
    }
};
</script>
