/**
 * @type {Array} of Strings
 * @description insert a String into the array to ignore Loading Operations by Axios. The List parses Axios' url.
 */
const excludeList = [
    'checkPermissions',
    'votes',
    'knowledgeContents/',
    'results',
    'dashboard',
    '/course/users',
    '/evaluations',
    '/answers',
    'admingroups/members',
    'usergroups/members',
    '/config/menu'
];

/**
 * @type {Boolean}
 * @param {String} url
 * @description return whether or not a url is included in the excluded list or not
 */
const excludeTest = (url) => {
    let expr = excludeList.join('|');
    let regex = new RegExp(expr, 'i');
    return regex.test(url);
};

export default {
    excludeTest
};
