<template>
    <v-btn block color="primary" @click.stop="startDownload">
        {{ $t('Antwort runterladen') }}
    </v-btn>
</template>

<script>
import { saveAs } from 'file-saver';
import moment from 'moment';

export default {
    name: 'upload-type',
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        computedFileName() {
            if (this.value.hasOwnProperty('mediaResponse')) {
                if (this.value.mediaResponse.hasOwnProperty('fileName')) {
                    return `${moment().format('YYYY-MM-DD')}_${this.$t(this.value.mediaResponse.fileName)}`;
                }
            }
            return '';
        }
    },
    methods: {
        startDownload() {
            fetch(this.value.mediaResponse.fileURL)
                .then((response) => response.blob())
                .then((response) => saveAs(response, this.computedFileName));
        }
    }
};
</script>

<style scoped>
a {
    text-decoration: none;
}
</style>
