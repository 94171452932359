<template>
    <div class="d-flex flex-column fill-height">
        <Toolbar :tooltipMessage="tooltipMessage" :toolbarTitle="translatedTitle" />

        <v-tabs show-arrows v-model="mainTabs" mandatory slider-color="accent">
            <v-tab key="Generic" :to="{ name: 'systemSettingsGeneric' }" replace>{{ $t('Allgemein') }}</v-tab>
            <v-tab key="Server" :to="{ name: 'systemSettingsMailServer' }" replace>{{ $t('Server') }}</v-tab>
            <v-tab key="Scheduled" :to="{ name: 'systemSettingsMailSchedule' }" replace>{{ $t('Zeitplan') }}</v-tab>
            <v-tab key="Templates" :to="{ name: 'systemSettingsMailTemplates' }" replace>{{ $t('Vorlagen') }}</v-tab>
            <v-tab key="Options" :to="{ name: 'systemSettingsMailOptions' }" replace>{{ $t('Optionen') }}</v-tab>
            <v-tab key="Zertifikat" :to="{ name: 'systemSettingsCertificate' }" replace>{{ $t('Zertifikat') }}</v-tab>
            <v-tab key="NavigationMenu" :to="{ name: 'systemSettingsNavigationMenu' }" replace>
                {{ $t('Navigationsmenü') }}
            </v-tab>
        </v-tabs>
        <router-view />
    </div>
</template>

<script>
import Toolbar from '../../Components/Generic/Toolbar';

export default {
    name: 'system-settings',
    components: {
        Toolbar
    },
    data: () => ({
        toolbarTitle: 'System Einstellungen',
        mainTabs: null,
        tooltipMessage: ''
    }),
    computed: {
        translatedTitle() {
            return this.$t(this.toolbarTitle);
        }
    },
    watch: {
        mainTabs(value) {
            switch (value) {
                case '/SystemSettings/Generic':
                    this.tooltipMessage =
                        'Hier können Sie allgemeine Einstellungen konfigurieren, bzw. Hintergrund und Logo hochladen, Domain anlegen, App Farben wählen, Impressum und Datenschutz schreiben.';
                    break;
                case '/SystemSettings/Mail/Server':
                    this.tooltipMessage = 'Hier können Sie Ihre Server einstellen.';
                    break;
                case '/SystemSettings/Mail/Schedule':
                    this.tooltipMessage =
                        'Hier können Sie Vorlagen hinzufügen, bearbeiten und löschen. Diese Vorlagen müssen zu gewisse Zeitplan gesendet werden.';
                    break;
                case '/SystemSettings/Mail/Templates':
                    this.tooltipMessage = 'Hier können Sie Vorlagen senden, die auf gewisse Veranstaltungen reagieren.';
                    break;
                case '/SystemSettings/Mail/Options':
                    this.tooltipMessage = 'Hier können Sie Vorlagen aktivieren oder so inaktiv lassen.';
                    break;
                case '/SystemSettings/Certificate':
                    this.tooltipMessage = 'Hier können Sie Ihre Zertifikat-Vorlage einstellen';
                    break;
                case '/SystemSettings/NavigationMenu':
                    this.toolbarMessage = 'Hier können Sie Menü-Einträge für Ihre Nutzer / Admin voreinstellen oder beschränken';
            }
        }
    },
    mounted() {
        this.tooltipMessage =
            'Hier können Sie allgemeine Einstellungen konfigurieren, bzw. Hintergrund und Logo hochladen, Domain anlegen, App Farben wählen, Impressum und Datenschutz schreiben.';
    }
};
</script>
