<template>
    <div class="fill-height">
        <Toolbar :toolbarTitle="$t('Passwort ändern')" tooltipMessage="Hier können Sie Ihr aktuelles Passwort ändern." />
        <v-card class="fill-height">
            <v-form v-model="validate">
                <v-container>
                    <v-row>
                        <v-col :cols="12">
                            <v-text-field
                                color="accent"
                                autocomplete="new-password"
                                v-model="currentPassword"
                                :append-icon="passwordVisible.currentPasswordV ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="passwordVisible.currentPasswordV ? 'text' : 'password'"
                                :label="$t('Aktuelles Passwort')"
                                @click:append="passwordVisible.currentPasswordV = !passwordVisible.currentPasswordV"
                                :rules="[rules.required]"
                            />
                        </v-col>
                        <v-col :cols="12">
                            <v-text-field
                                color="accent"
                                autocomplete="new-password"
                                v-model="newPassword"
                                :append-icon="passwordVisible.newPasswordV ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="passwordVisible.newPasswordV ? 'text' : 'password'"
                                :label="$t('Neues Passwort')"
                                @click:append="passwordVisible.newPasswordV = !passwordVisible.newPasswordV"
                                :rules="[rules.required]"
                            />
                        </v-col>
                        <v-col :cols="12">
                            <v-text-field
                                color="accent"
                                autocomplete="new-password"
                                v-model="confirmPassword"
                                :append-icon="passwordVisible.newPasswordConfirmV ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="passwordVisible.newPasswordConfirmV ? 'text' : 'password'"
                                :label="$t('Neues Passwort Bestätigen')"
                                @click:append="passwordVisible.newPasswordConfirmV = !passwordVisible.newPasswordConfirmV"
                                :rules="[rules.required, comparePasswords]"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
            <v-card-actions>
                <v-row align="center" justify="end">
                    <v-col cols="auto">
                        <v-btn text color="primary" @click="reset">{{ $t('Zurücksetzen') }}</v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn :disabled="!validate" color="primary" @click="changePassword()">{{ $t('Aktualisieren') }}</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Toolbar from '../../Components/Generic/Toolbar';

export default {
    name: 'user-change-password',
    components: {
        Toolbar
    },
    data() {
        return {
            passwordVisible: {
                currentPasswordV: false,
                newPasswordV: false,
                newPasswordConfirmV: false
            },
            validate: false,
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            rules: {
                required: (value) => !!value || this.$t('Pflichtfeld'),
                min: (v) => v.length >= 1 || this.$t('Pflichtfeld')
            }
        };
    },
    computed: {
        ...mapGetters('General', ['getLoggedInAdmin']),
        comparePasswords() {
            return this.newPassword !== this.confirmPassword ? this.$t('Passwort muss übereinstimmen') : true;
        }
    },
    methods: {
        ...mapActions('User', ['UPDATE_USER_PASSWORD']),
        ...mapActions('Admin', ['UPDATE_ADMIN_PASSWORD']),
        ...mapActions('ErrorReporterOperations', ['SET_ERROR_MESSAGES', 'CLEAR_ERROR_MESSAGES']),
        changePassword() {
            if (!this.validateOnSave()) return false;
            var dataToSend = {};
            var data = {};
            data.currentPassword = this.currentPassword;
            data.newPassword = this.newPassword;
            data.confirmPassword = this.confirmPassword;
            data.isEmailNotification = true; // need confirmation to make it dynamic
            dataToSend.data = data;
            dataToSend.id = this.getLoggedInAdmin.id;

            if (this.getLoggedInAdmin.role == 'USER') {
                this.UPDATE_USER_PASSWORD(dataToSend)
                    .then(() => {
                        this.CLEAR_ERROR_MESSAGES();
                        this.reset();
                    })
                    .catch((err) => {
                        let msg = err.response.data.errors.map((error) => error.message);
                        this.SET_ERROR_MESSAGES([{ message: msg[0] }]);
                    });
            } else if (this.getLoggedInAdmin.role == 'ADMIN' || this.getLoggedInAdmin.role == 'SUPER_ADMIN') {
                this.UPDATE_ADMIN_PASSWORD(dataToSend)
                    .then(() => {
                        this.CLEAR_ERROR_MESSAGES();
                        this.reset();
                    })
                    .catch((err) => {
                        let msg = err.response.data.errors.map((error) => error.message);
                        this.SET_ERROR_MESSAGES([{ message: msg[0] }]);
                    });
            }
        },
        reset() {
            this.currentPassword = '';
            this.confirmPassword = '';
            this.newPassword = '';
        },
        validateOnSave() {
            if (this.currentPassword === '' || this.newPassword === '' || this.confirmPassword === '') {
                this.SET_ERROR_MESSAGES([{ message: this.$t('Alle Felder sind Pflichtfelder.') }]);
                return false;
            } else if (this.newPassword !== this.confirmPassword) {
                this.SET_ERROR_MESSAGES([{ message: this.$t('Passwörter stimmen nicht überein.') }]);
                return false;
            } else {
                this.CLEAR_ERROR_MESSAGES();
                return true;
            }
        }
    }
};
</script>
