import axios from '../../../plugins/Axios/axios';

const state = {};

const getters = {};

const actions = {
    GET_TEMPLATE_COURSES_AS_ADMIN({ commit }) {
        return axios
            .get(`/courselearning/course/admin`)
            .then((response) => response.data.data)
            .then((response) => commit('updateAdminCourses', response));
    },
    GET_TEMPLATE_COURSES_AS_ATTENDEE({ commit }) {
        return axios
            .get(`/courselearning/course/attending`)
            .then((response) => response.data.data)
            .then((response) => commit('updateAttendeeCourses', response));
    },
    GET_COURSE_TEMPLATE({ commit }, request) {
        const { id: courseTempId } = request;
        return axios.get(`/courselearning/course/${courseTempId}`).then((response) => response.data.data);
    },
    GET_COURSE_TEMPLATE_TOPICS({ commit }, request) {
        const { id: courseTempId } = request;
        return axios.get(`/courselearning/course/${courseTempId}/topics`).then((response) => response.data.data);
    },
    CREATE_COURSE_TEMPLATE({ commit }, request) {
        return axios.post(`/courselearning/course`, request).then((response) => response.data.data);
    },
    UPDATE_COURSE_TEMPLATE({ commit }, request) {
        const { id: courseTempId, ...course } = request;
        return axios.patch(`/courselearning/course/${courseTempId}`, course).then((response) => response.data.data);
    },
    UPDATE_COURSE_TEMPLATE_TOPICS({ commit }, { course, topics }) {
        const topicIds = topics.map((topic) => ({ id: topic.id }));
        const { id: courseTempId } = course;
        return axios.patch(`/courselearning/course/${courseTempId}/topics`, topicIds).then((response) => response.data.data);
    },
    DELETE_COURSE_TEMPLATE({ commit }, request) {
        const { id: courseTempId } = request;
        return axios.delete(`/courselearning/course/${courseTempId}`);
    }
};

const mutations = {
    clear: (state) => {}
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
