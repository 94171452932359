<template>
    <v-card height="100%">
        <v-container fluid ma-0 pa-0 fill-height>
            <v-layout column>
                <v-flex shrink>
                    <Toolbar :tooltipMessage="$t('Hier können Sie Kurse kaufen')" :toolbarTitle="$t('E-Shop')" />
                </v-flex>
                <v-flex shrink>
                    <v-tabs slider-color="accent" v-model="computedSelectedTab" grow>
                        <v-tab key="E-SHOP">{{ $t('E-Shop') }}</v-tab>
                        <v-tab key="PURCHASED" v-if="hasAssignPermission">{{ $t('Gekaufte/Eigene Kurse') }}</v-tab>
                        <v-tab key="REQUESTS" v-if="hasPurchasePermissionAndAssignPermission">{{ $t('Anfragen bearbeiten') }}</v-tab>
                        <v-tab key="SCORM_UPLOAD" v-if="hasUploadPermission"> {{ $t('Scorm Kurs hochladen') }} </v-tab>
                    </v-tabs>
                </v-flex>
                <v-flex grow>
                    <v-tabs-items v-model="computedSelectedTab" touchless mandatory class="fill-height">
                        <v-tab-item key="E-SHOP" class="fill-height">
                            <v-flex grow class="fill-height">
                                <v-expand-transition mode="out-in">
                                    <keep-alive>
                                        <component
                                            :is="componentSwitch"
                                            v-model="computedSelectedScorm"
                                            :scorms="eShopList"
                                            :eShopPage="true"
                                            node-context="SHOW_COURSE"
                                        />
                                    </keep-alive>
                                </v-expand-transition>
                            </v-flex>
                        </v-tab-item>
                        <v-tab-item key="PURCHASED" class="fill-height" v-if="hasAssignPermission">
                            <v-flex grow>
                                <v-expand-transition mode="out-in">
                                    <keep-alive>
                                        <component
                                            :is="componentSwitch"
                                            v-model="computedSelectedScorm"
                                            :scorms="boughtScormList"
                                            :eShopPage="false"
                                            node-context="SHOW_COURSE"
                                        />
                                    </keep-alive>
                                </v-expand-transition>
                            </v-flex>
                        </v-tab-item>
                        <v-tab-item key="REQUESTS" class="fill-height" v-if="hasPurchasePermissionAndAssignPermission">
                            <v-flex grow class="fill-height">
                                <ScormRequests />
                            </v-flex>
                        </v-tab-item>
                        <v-tab-item key="SCORM_UPLOAD" class="fill-height" v-if="hasUploadPermission">
                            <v-flex grow class="fill-height">
                                <UploadScorm />
                            </v-flex>
                        </v-tab-item>
                    </v-tabs-items>
                </v-flex>
            </v-layout>
        </v-container>
    </v-card>
</template>

<script>
import ScormMixins from '../../Components/SCORM/Mixins/ScormMixin';
import Scormhomepage from '../../Components/SCORM/Scormhomepage';
import ScormDisplay from '../../Components/SCORM/ScormDisplay';
import Toolbar from '../../Components/Generic/Toolbar';
import BuyScorm from '../../Components/SCORM/Helpers/BuyScorm';
import UploadScorm from '../../Components/SCORM/Helpers/UploadScorm';
import ScormRequests from '../../Components/SCORM/ScormRequests';

export default {
    name: 'scorm',
    mixins: [ScormMixins],
    components: { Scormhomepage, Toolbar, BuyScorm, UploadScorm, ScormRequests },
    data: () => ({
        permissions: {
            upload: false,
            buyScorm: false,
            assignUserToScorm: false
        }
    }),
    computed: {
        computedSelectedScorm: {
            get() {
                return this.getSelectedScorm;
            },
            set(value) {
                this.SET_SELECTED_SCORM(value);
            }
        },
        computedSelectedTab: {
            get() {
                console.log(this.getView.tabs);
                return this.getView.tabs;
            },
            set(value) {
                this.SET_VIEW({ component: '', type: '', data: {} });
                this.getView.tabs = value;
                this.SET_SELECTED_SCORM({});
            }
        },
        componentSwitch() {
            if (this.getView) {
                switch (this.getView.component) {
                    case 'scormHomepage':
                        return Scormhomepage;
                    case 'buyScorm':
                        return BuyScorm;
                }
            }
            if (this.computedSelectedScorm.hasOwnProperty('id')) {
                return ScormDisplay;
            }

            return Scormhomepage;
        },
        eShopList() {
            return this.getScormList.filter((obj) => !this.getBoughtScorms.some((obj2) => obj.id === obj2.scormPackageId));
        },
        boughtScormList() {
            return this.getBoughtScorms;
        },
        hasSuperAdminPermission() {
            return this.getLoggedInAdmin.role == 'SUPER_ADMIN';
        },
        hasPurchasePermissionAndAssignPermission() {
            return this.hasSuperAdminPermission || (this.permissions.buyScorm && this.permissions.assignUserToScorm);
        },
        hasAssignPermission() {
            return this.hasSuperAdminPermission || this.permissions.assignUserToScorm;
        },
        hasUploadPermission() {
            return this.hasSuperAdminPermission || this.permissions.upload;
        }
    },
    beforeMount() {
        this.validateEditorState({
            upload: ['UPLOAD_SCORM'],
            assignUserToScorm: ['ASSIGN_USER_TO_SCORM'],
            buyScorm: ['BUY_SCORM']
        }).then((result) => (this.permissions = result));
    },
    mounted() {
        this.GET_SCORM_LIST();
        this.GET_BOUGHT_SCORMS();
        if (this.hasSuperAdminPermission) {
            this.GET_UPLOADED_SCORMS();
        }
    },
    beforeDestroy() {
        this.SET_SELECTED_SCORM({});
    }
};
</script>
