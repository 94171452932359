import axios from '../../../plugins/Axios/axios';
import Vue from 'vue';

const state = {
    currentGroup: {},
    selectedCourseGroups: [],
    selectedCourseGroup: {},
    selectedCourseGroupUser: {},
    courseGroupUsersMap: {},
    courseGroupJoinRequests: {},
    courseGroupSwitch: ''
};

const getters = {
    getCourseGroups() {
        return state.selectedCourseGroups;
    },
    getSelectedCourseGroup() {
        return state.selectedCourseGroup;
    },
    getSelectedCourseGroupUser() {
        return state.selectedCourseGroupUser;
    },
    getCourseGroupUsers() {
        return (id) => {
            if (state.courseGroupUsersMap.hasOwnProperty(id)) {
                return state.courseGroupUsersMap[id];
            }
            return undefined;
        };
    },
    getGroupJoinRequests() {
        return (id) => {
            if (state.courseGroupJoinRequests.hasOwnProperty(id)) {
                return state.courseGroupJoinRequests[id];
            }
            return undefined;
        };
    },
    getCurrentGroup() {
        return state.currentGroup;
    },
    getCourseGroupSwitch() {
        return state.courseGroupSwitch;
    }
};
const actions = {
    SET_SELECTED_COURSE_GROUP_USER({ commit }, user) {
        commit('updateSelectedCourseGroupUser', user);
    },
    SET_SELECTED_COURSE_GROUP({ commit }, group) {
        commit('updateSelectedCourseGroup', group);
    },
    GET_SELECTED_COURSE_GROUPS({ commit }, { course: { id: courseId } }) {
        return axios
            .get(`/courselearning/courseinstances/${courseId}/groups`)
            .then((response) => response.data.data)
            .then((response) => commit('updateSelectedCourseGroups', response));
    },
    CREATE_SELECTED_COURSE_GROUP({ commit }, { course: { id: courseId }, group }) {
        return axios
            .post(`/courselearning/courseinstances/${courseId}/groups`, group)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateSelectedCourseGroups', response);
                commit('updateSelectedCourseCurrentGroup', response);
            });
    },
    UPDATE_SELECTED_COURSE_GROUP({ commit }, { course: { id: courseId }, groupId, group }) {
        return axios
            .patch(`/courselearning/courseinstances/${courseId}/groups/${groupId}`, group)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateSelectedCourseGroups', response);
                commit('updateSelectedCourseCurrentGroup', response);
            });
    },
    DELETE_SELECTED_COURSE_GROUP({ commit }, { course: { id: courseId }, group }) {
        const { id: groupId } = group;
        return axios
            .delete(`/courselearning/courseinstances/${courseId}/groups/${groupId}`)
            .then((response) => response.data.data)
            .then(() => commit('deleteSelectedCourseGroup', group));
    },
    GET_SELECTED_COURSE_GROUP_USERS({ commit }, { course: { id: courseId }, group: { id: groupId } }) {
        return axios
            .get(`/courselearning/courseinstances/${courseId}/groups/${groupId}/users`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateGroupJoinRequestsMap', {
                    key: groupId,
                    value: response.joinRequests
                });
                commit('updatecourseGroupUsersMap', {
                    key: groupId,
                    value: response.users
                });
            });
    },
    LEAVE_SELECTED_COURSE_GROUP({ commit }, { course: { id: courseId }, group: { id: groupId } }) {
        return axios.post(`/courselearning/courseinstances/${courseId}/groups/${groupId}/leave`).then((response) => response.data);
    },
    SELECTED_COURSE_JOIN_GROUP_REQUEST({ commit }, { course: { id: courseId }, group: { id: groupId } }) {
        return axios.post(`/courselearning/courseinstances/${courseId}/groups/${groupId}/join`).then((response) => response.data.data);
    },
    SELECTED_COURSE_ACCEPT_GROUP_REQUEST({ commit }, { course: { id: courseId }, group: { id: groupId }, user: { id: userId } }) {
        return axios.post(`/courselearning/courseinstances/${courseId}/groups/${groupId}/accept`, { id: userId }).then((response) => response.data.data);
    },
    REMOVE_USER_FROM_SELECTED_COURSE_GROUP({ commit }, { course: { id: courseId }, group: { id: groupId }, user: { id: userId } }) {
        return axios.post(`/courselearning/courseinstances/${courseId}/groups/${groupId}/remove`, { id: userId }).then((response) => response.data.data);
    },
    GET_CURRENT_SELECTED_COURSE_GROUP({ commit }, { course: { id: courseId } }) {
        return axios
            .get(`/courselearning/courseinstances/${courseId}/groups/current`)
            .then((response) => response.data.data)
            .then((response) => commit('updateSelectedCourseCurrentGroup', response));
    },
    SET_COURSE_SWITCH({ commit }, courseGroupSwitch) {
        commit('updateCourseGroupSwitch', courseGroupSwitch);
    }
};

const mutations = {
    clear: (state) => {
        state.currentGroup = {};
        state.selectedCourseGroups = [];
        state.selectedCourseGroup = {};
        state.selectedCourseGroupUser = {};
        state.courseGroupUsersMap = [];
        state.courseGroupJoinRequests = [];
        state.courseGroupSwitch = '';
    },
    updateSelectedCourseGroups: (state, response) => {
        if (Array.isArray(response)) {
            state.selectedCourseGroups = response;
        } else {
            if (state.selectedCourseGroups.map((group) => group.id).includes(response.id)) {
                state.selectedCourseGroups = state.selectedCourseGroups.map((group) => {
                    if (group.id === response.id) {
                        return response;
                    }
                    return group;
                });
            } else {
                state.selectedCourseGroups.push(response);
            }
        }
    },
    updateSelectedCourseGroup: (state, group) => (state.selectedCourseGroup = group),
    updateSelectedCourseGroupUser: (state, user) => (state.selectedCourseGroupUser = user),
    updatecourseGroupUsersMap: (state, users) => Vue.set(state.courseGroupUsersMap, users.key, users.value),
    updateGroupJoinRequestsMap: (state, users) => Vue.set(state.courseGroupJoinRequests, users.key, users.value),
    updateSelectedCourseCurrentGroup: (state, group) => (state.currentGroup = group),
    deleteSelectedCourseGroup: (state, group) => {
        const index = state.selectedCourseGroups.map((groupMap) => groupMap.id).indexOf(group.id);
        if (index !== -1) {
            state.selectedCourseGroups.splice(index, 1);
            state.currentGroup = {};
        }
    },
    updateCourseGroupSwitch: (state, courseGroupSwitch) => (state.courseGroupSwitch = courseGroupSwitch)
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
