<template>
    <div>
        <List :items="getWikisWithProposals" :showAppend="false" @input="selectWiki" showOptions title="Wiki" :itemKeys="['name', 'tags']">
            <template v-slot:action="{ action }">
                <v-icon medium :class="getStateColor(action)">mdi-file-document-outline</v-icon>
            </template>
            <template v-slot:content="{ content }">
                <v-list-item-title>{{ content.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ tags(content) }}</v-list-item-subtitle>
            </template>
        </List>
        <WikiProposalsViewer v-model="selectedWiki" />
    </div>
</template>

<script>
import WikiMixin from './Mixins/WikiMixin';
import WikiProposalsViewer from './Helpers/WikiProposalsDialog';
import List from '../Generic/List/AdvancedList';

export default {
    name: 'wiki-proposals',
    components: { WikiProposalsViewer, List },
    mixins: [WikiMixin],
    data: () => ({
        search: '',
        debounceTimer: null,
        selectedWiki: {},
        hasProposalPermission: false
    }),
    methods: {
        tags({ tags }) {
            return tags ? tags : '';
        },
        getStateColor({ state: { stateName = '' } = {} } = {}) {
            switch (stateName) {
                case 'PENDING':
                    return 'warning--text';
                case 'ACCEPTED':
                    return 'success--text';
                case 'DECLINED':
                    return 'error--text';
            }
            return 'info--text';
        },
        selectWiki(value) {
            this.selectedWiki = value;
            this.$emit('selected', value);
        }
    },
    mounted() {
        this.validateEditorState({ proposals: ['MANAGE_PROPOSAL_WIKI'] }).then((response) => {
            this.hasProposalPermission = Object.values(response).reduce((result, next) => result && next, true);
            if (this.hasProposalPermission) {
                this.GET_WIKI_WITH_PROPOSALS();
            }
        });
    }
};
</script>
