import axios from '../../../plugins/Axios/axios';

const state = {};

const getters = {};

const actions = {
    GET_LEARNING_GOAL_TEMPLATE({ commit }, request) {
        const { id: learningGoalTempId } = request;
        return axios.get(`/courselearning/learninggoal/${learningGoalTempId}`).then((response) => response.data.data);
    },
    GET_LEARNING_GOAL_TEMPLATE_ACTIVITIES({ commit }, request) {
        const { id: learningGoalTempId } = request;
        return axios.get(`/courselearning/learninggoal/${learningGoalTempId}/activities`).then((response) => response.data.data);
    },
    CREATE_LEARNING_GOAL_TEMPLATE({ commit }, request) {
        return axios.post(`/courselearning/learninggoal`, request).then((response) => response.data.data);
    },
    UPDATE_LEARNING_GOAL_TEMPLATE({ commit }, request) {
        const { id: learningGoalTempId, ...learningGoal } = request;
        return axios.patch(`/courselearning/learninggoal/${learningGoalTempId}`, learningGoal).then((response) => response.data.data);
    },
    UPDATE_LEARNING_GOAL_TEMPLATE_ACTIVITIES({ commit }, { learningGoal, activities }) {
        const { id: learningGoalTempId } = learningGoal;
        const activityIds = activities.map((activity) => ({ id: activity.id }));
        return axios.patch(`/courselearning/learninggoal/${learningGoalTempId}/activities`, activityIds).then((response) => response.data.data);
    },
    DELETE_LEARNING_GOAL_TEMPLATE({ commit }, request) {
        const { id: learningGoalTempId } = request;
        return axios.delete(`/courselearning/learninggoal/${learningGoalTempId}`);
    }
};

const mutations = {
    clear: (state) => {}
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
