<template>
    <v-dialog v-model="computedDialog" persistent scrollable :width="$vuetify.breakpoint.thresholds.sm" :fullscreen="$vuetify.breakpoint.xs">
        <v-card>
            <v-toolbar dense dark color="secondary" text>
                <v-toolbar-title>{{ $t('Wissenseinheiten ausschließen') }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <List
                    :items="catalogKnowledgeUnits"
                    :showAppend="false"
                    :showOptions="true"
                    :itemKeys="['question', 'questionType']"
                    :label="$t('Wissenseinheiten')"
                >
                    <template v-slot:action="{ action }">
                        <v-checkbox
                            :value="action"
                            :input-value="knowledgeUnits"
                            @change="handleInput"
                            :value-comparator="comparator"
                            :key="`${action.id}-${Math.floor(Math.random() * 256)}`"
                        />
                    </template>
                    <template v-slot:content="{ content }">
                        <v-list-item-title>{{ content.question }}</v-list-item-title>
                        <v-list-item-subtitle>{{ $t(content.questionType) }}</v-list-item-subtitle>
                    </template>
                </List>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import AuthoringToolMixin from '../../../../Authoringtool/Mixins/AuthoringtoolMixin';
import List from '../../../../Generic/List/AdvancedList';

export default {
    name: 'quiz-activity-exclude-catalogs',
    mixins: [AuthoringToolMixin],
    components: { List },
    data: () => ({
        catalogKnowledgeUnits: []
    }),
    props: {
        value: {
            type: Boolean,
            default: false
        },
        catalog: {
            type: Object,
            default: () => ({})
        },
        knowledgeUnits: {
            type: Array,
            default: () => []
        }
    },
    watch: {
        value(value) {
            if (!value) {
                this.catalogKnowledgeUnits = [];
            }
        },
        catalog(value) {
            if (value.hasOwnProperty('id')) {
                this.GET_KNOWLEDGEUNITS_BY_CATALOG(value).then((response) => {
                    this.catalogKnowledgeUnits = response;
                });
            }
        }
    },
    computed: {
        computedDialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        handleInput(value) {
            this.$emit('knowledgeUnits', value);
        },
        comparator(a, b) {
            return a && b && a.id && b.id && a.id === b.id;
        },
        close() {
            this.computedDialog = false;
        }
    }
};
</script>
