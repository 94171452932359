<template>
    <v-data-iterator
        :items="catalogSubGroups"
        :no-data-text="$t('Keine Daten verfügbar')"
        :no-results-text="$t('Kein Ergebnis')"
        :items-per-page="5"
        :footer-props="{
            'items-per-page-options': [5, 10, 15, { value: -1, text: $t('Alle') }]
        }"
        :hideDefaultFooter="catalogSubGroups.length === 0"
    >
        <template #default="{ items }">
            <v-list>
                <v-list-item v-for="item in items" :key="item.id">
                    <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                        <v-list-item-subtitle class="text-truncate">
                            {{ item.description ? item.description : $t('Keine Beschreibung') }}
                        </v-list-item-subtitle>
                        <v-list-item-content>
                            <v-container>
                                <v-row>
                                    <v-col cols="auto" v-for="[key, val] in Object.entries(permissionMap)" :key="`${item.id}-${key}`">
                                        <permission-type-selector
                                            :permissionType="key"
                                            :checked="hasPermissions(item, val)"
                                            :value="val"
                                            :disabled="readOnly"
                                            @input="handleInput(item, $event)"
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-list-item-content>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </template>
    </v-data-iterator>
</template>
<script>
import PermissionTypeSelector from './PermissionTypeSelector';
import AuthoringPermissionMixin from '../Mixins/AuthoringPermissionsMixin';
export default {
    name: 'profile-catalog-sub-group',
    components: { PermissionTypeSelector },
    mixins: [AuthoringPermissionMixin],
    data: () => ({
        catalogSubGroups: [],
        permissionMap: []
    }),
    props: {
        value: {
            type: Array,
            default: () => []
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        value() {
            this.deserialize();
        },
        getSelectedCatalogGroup() {
            this.getAuthoringPermissions(true).then((permissionMap) => (this.permissionMap = permissionMap));
            this.GET_CATALOGSUBGROUP_LIST(this.getSelectedCatalogGroup).then(() => {
                this.catalogSubGroups = [
                    ...this.getCatalogSubGroupList.map((group) => ({
                        ...group,
                        permissions: []
                    }))
                ];
                this.deserialize();
            });
        }
    },
    methods: {
        serialize() {
            return this.catalogSubGroups.map((subGroup) => ({
                id: subGroup.id,
                permissions: subGroup.permissions
            }));
        },
        deserialize() {
            if (Array.isArray(this.value)) {
                this.catalogSubGroups.forEach((subGroup) => {
                    if (this.value.map((gr) => gr.id).includes(subGroup.id)) {
                        let find = this.value.find((g) => g.id == subGroup.id);
                        if (typeof find !== 'undefined') {
                            subGroup.permissions = find.permissions;
                        }
                    } else {
                        subGroup.permissions = [];
                    }
                });
            }
        },
        handleInput(catalogSubGroup, input) {
            let deps = catalogSubGroup.permissions
                .filter((perm) => perm.hasOwnProperty('dependencies') && perm.dependencies.length > 0)
                .map((perm) => perm.dependencies)
                .flat()
                .map((perm) => perm.permissionCode);
            let inputWithDependencies = input
                .map((permission) => {
                    if (permission.hasOwnProperty('dependencies')) {
                        if (permission.dependencies.length > 0) {
                            return [permission, ...permission.dependencies];
                        }
                    }
                    return [permission];
                })
                .flat();

            if (catalogSubGroup.hasOwnProperty('permissions')) {
                if (this.hasPermissions(catalogSubGroup, input)) {
                    inputWithDependencies = inputWithDependencies.filter((permission) => !deps.includes(permission.permissionCode));
                    catalogSubGroup.permissions = catalogSubGroup.permissions
                        .filter((permission) => !inputWithDependencies.map((perm) => perm.permissionCode).includes(permission.permissionCode))
                        .filter((permission, index, acc) => acc.map((perm) => perm.permissionCode).indexOf(permission.permissionCode) === index);
                } else {
                    catalogSubGroup.permissions.push(...inputWithDependencies);
                }
                this.$emit('input', this.serialize());
            }
        },
        hasPermissions(catalogSubGroup, permissions) {
            if (catalogSubGroup.hasOwnProperty('permissions')) {
                return permissions
                    .map((permission) => permission.permissionCode)
                    .every((permission) => catalogSubGroup.permissions.map((perm) => perm.permissionCode).includes(permission));
            }
            return false;
        }
    }
};
</script>
