<template>
    <v-text-field :value="value.text" readonly :label="$t('Antwort')" :hint="computedHint" persistent-hint />
</template>

<script>
export default {
    name: 'text-field-type',
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        computedHint() {
            if (this.value.hasOwnProperty('activity')) {
                const activity = this.value.activity;
                if (activity.hasOwnProperty('activitySpecification')) {
                    const spec = activity.activitySpecification;
                    if (spec.hasOwnProperty('minWordCount') && spec.hasOwnProperty('maxWordCount')) {
                        return `min ${spec.minWordCount} ${this.$t('Wörter')}, max ${spec.maxWordCount} ${this.$t('Wörter')}`;
                    }
                }
            }
            return '';
        }
    }
};
</script>
