<template>
    <v-card height="100%">
        <v-tabs v-model="tabs">
            <v-tab key="TREE" ripple>{{ $t('Explorer') }}</v-tab>
            <v-tab key="ACTION" ripple>{{ $t('Details') }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs" touchless>
            <v-tab-item key="TREE">
                <slot></slot>
            </v-tab-item>
            <v-tab-item key="ACTION">
                <keep-alive>
                    <component
                        v-model="computedSelectedItem"
                        :is="component"
                        :search="search"
                        @openTree="handleOpenTree"
                        :permissions="permissions"
                        :tree="tree"
                    />
                </keep-alive>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>

<script>
export default {
    name: 'users-mobile-view',
    data: () => ({
        tabs: 1
    }),
    computed: {
        computedSelectedItem: {
            get() {
                return this.value.length > 0 ? this.value[0] : {};
            },
            set(value) {
                this.$emit('input', [value]);
            }
        }
    },
    props: {
        value: {
            type: Array,
            default: () => []
        },
        component: {
            type: [String, Object],
            default: '<div/>'
        },
        search: {
            type: String,
            default: ''
        },
        selectable: {
            type: Boolean,
            default: false
        },
        permissions: {
            type: Object,
            default: () => ({
                add: false,
                update: false,
                delete: false,
                change: false
            })
        },
        tree: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        handleOpenTree(value) {
            this.$emit('openTree', value);
        }
    },
    activated() {
        if (this.selectable) {
            this.tabs = 0;
        }
    }
};
</script>
