import axios from '../../../plugins/Axios/axios';
import Vue from 'vue';

const state = {
    userGroupTree: [],
    userGroupMap: {},
    userGroupUserMap: {},
    selectedUserGroup: {}
};

// getters
const getters = {
    getUserGroupUsers(state) {
        return (userGroupId) => {
            if (state.userGroupUserMap.hasOwnProperty(userGroupId)) {
                const users = state.userGroupUserMap[userGroupId];
                users.sort((a, b) =>
                    a.displayName.localeCompare(b.displayName, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    })
                );
                return users;
            }
            return undefined;
        };
    },
    getUserGroup(state) {
        return (userGroupId) => {
            if (state.userGroupMap.hasOwnProperty(userGroupId)) {
                return state.userGroupMap[userGroupId];
            }
            return undefined;
        };
    },
    getUserGroupTree(state) {
        return state.userGroupTree;
    },
    getUserGroups(state) {
        return Object.values(state.userGroupMap);
    },
    getSelectedUserGroup(state) {
        return state.selectedUserGroup;
    }
};

// actions
const actions = {
    GET_GROUP_LEADERS({ commit }, { id: groupId }) {
        return axios.get(`/usergroups/${groupId}/admins`).then((response) => response.data.data.users);
    },
    SET_GROUP_LEADERS({ commit }, { group: { id: groupId }, admins }) {
        return axios.post(`/usergroups/${groupId}/admins`, {
            users: admins.map(({ id }) => id)
        });
    },
    SET_SELECTED_USER_GROUP({ commit }, userGroup) {
        return commit('updateSelectedUserGroup', userGroup);
    },
    GET_USER_GROUPS({ commit }) {
        return axios
            .get(`/usergroups/all`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateUserGroupMap', response);
                return response;
            });
    },
    GET_USER_GROUP_TREE({ commit }) {
        return axios
            .get(`usergroups/treeview`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateUserGroupTree', response);
                return response;
            });
    },
    GET_USER_GROUP_MEMBERS({ commit }, { id: groupId }) {
        return axios.get(`/usergroups/${groupId}/user`).then((response) => response.data.data.users);
    },
    GET_USER_GROUP_ROOT({ commit }) {
        return axios
            .get('/usergroups')
            .then((response) => response.data.data.groups)
            .then((response) => {
                commit('updateUserGroupMap', response);
                return response;
            });
    },
    GET_USER_SUBGROUPS({ commit }, { id: userGroupId }) {
        return axios
            .get(`usergroups/${userGroupId}/subgroups`)
            .then((response) => response.data.data.subgroups)
            .then((response) => {
                commit('updateUserGroupMap', response);
                return response;
            });
    },
    GET_ALL_USER_GROUP_USERS({ commit, dispatch }) {
        return Promise.all([
            axios
                .get(`/usergroups/usersmap`)
                .then((response) => response.data.data)
                .then((response) => {
                    commit('updateUserGroupUsersMap', response);
                    return response;
                }),
            dispatch('GET_USER_GROUPS'),
            dispatch('GET_USER_GROUP_TREE')
        ]);
    },
    SET_USER_GROUP_USERS({ commit, rootGetters }, { user: { id: userId }, groups }) {
        return axios
            .patch(`/usergroups/user/${userId}`, { groups })
            .then((response) => response.data.data.groups)
            .then((response) => {
                commit('UserPrototype/updateAssignedGroups', { [userId]: response }, { root: true });
                commit('removeUserFromGroupMap', userId);
                const user = rootGetters['User/getUserList'].find((user) => user.id === userId);
                if (user) {
                    commit('addUserToGroupMap', { user, groups });
                }
                return response;
            });
    },
    ADD_USER_GROUP({ commit, dispatch }, request) {
        return axios
            .post('usergroups', request)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateUserGroupMap', response);
                commit('updateSelectedUserGroup', response);
                return dispatch('GET_USER_GROUP_TREE').then(() => response);
            });
    },
    UPDATE_USER_GROUP({ commit, dispatch }, { id: userGroupId, ...group }) {
        return axios
            .patch(`/usergroups/${userGroupId}`, group)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateUserGroupMap', response);
                commit('updateSelectedUserGroup', response);
                return dispatch('GET_USER_GROUP_TREE').then(() => response);
            });
    },
    DELETE_USER_GROUP({ commit, dispatch }, { id: userGroupId }) {
        return axios.delete(`usergroups/${userGroupId}`).then(() => {
            commit('deleteUserGroup', userGroupId);
            dispatch('UserPrototype/BUILD_USER_MAPS', null, { root: true });
        });
    },
    REMOVE_USER_FROM_GROUP_MAP({ commit }, userId) {
        commit('removeUserFromGroupMap', userId);
    }
};

// mutations
const mutations = {
    clear: (state) => {
        state.userGroupTree = [];
        state.userGroupMap = {};
        state.userGroupUserMap = {};
        state.selectedUserGroup = {};
    },
    updateSelectedUserGroup: (state, userGroup) => {
        state.selectedUserGroup = userGroup;
    },
    updateUserGroupUsersMap: (state, data) => {
        Vue.set(state, 'userGroupUserMap', data);
    },
    updateUserGroupMap: (state, data) => {
        if (Array.isArray(data)) {
            state.userGroupMap = data.reduce((accu, { id, ...rest }) => {
                accu[id] = { id, ...rest };
                return accu;
            }, {});
        } else {
            if (data instanceof Object) {
                if (state.selectedUserGroup.id === data.id) {
                    state.selectedUserGroup = data;
                }
                Vue.set(state.userGroupMap, data.id, data);
            }
        }
    },
    updateUserGroupTree: (state, data) => {
        state.userGroupTree = data;
    },
    deleteUserGroup: (state, data) => {
        Vue.delete(state.userGroupMap, data);
        Vue.delete(state.userGroupUserMap, data);
    },
    addUserToGroupMap: (state, { user, groups }) => {
        const newMap = {
            ...groups.reduce((acc, curr) => {
                acc[curr] = [];
                return acc;
            }, {}),
            ...state.userGroupUserMap
        };
        Vue.set(
            state,
            'userGroupUserMap',
            Object.entries(newMap).reduce((acc, [key, value]) => {
                if (groups.includes(key) && !value.map(({ id }) => id).includes(user.id)) {
                    acc[key] = [...value, user];
                } else {
                    acc[key] = value;
                }
                return acc;
            }, {})
        );
    },
    removeUserFromGroupMap: (state, userId) => {
        Vue.set(
            state,
            'userGroupUserMap',
            Object.entries(state.userGroupUserMap).reduce((acc, [key, value]) => {
                const users = value.filter((user) => user.id !== userId);
                if (users.length > 0) {
                    acc[key] = users;
                }

                return acc;
            }, {})
        );
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
