<template>
    <v-container fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm10 md8 lg6 xl4>
                <v-card>
                    <v-toolbar text dark color="primary">
                        <v-toolbar-title>{{ $t('Konto aktivieren') }}</v-toolbar-title>
                    </v-toolbar>
                    <v-alert class="ma-0" :value="true" v-for="error in errors" :key="error.code" type="warning">{{ $t(error.message) }}</v-alert>
                    <v-card-text>
                        <LanguageSelector />
                        <v-form v-model="validate" ref="form">
                            <v-text-field
                                color="accent"
                                autocomplete="new-password"
                                :type="showPassword ? 'text' : 'password'"
                                @click:append="() => (showPassword = !showPassword)"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules('notEmpty'), rules('matchConfirm')]"
                                v-model="computedPassword"
                                :label="$t('Neues Passwort')"
                            ></v-text-field>
                            <v-text-field
                                color="accent"
                                autocomplete="new-password"
                                :type="showCheckPassword ? 'text' : 'password'"
                                @click:append="() => (showCheckPassword = !showCheckPassword)"
                                :append-icon="showCheckPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules('notEmpty'), rules('matchPassword')]"
                                v-model="computedConfirm"
                                :label="$t('Passwort bestätigen')"
                            ></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-row>
                            <v-spacer />
                            <v-col>
                                <v-btn text class="primary--text" @click="reset()">{{ $t('Zurücksetzen') }}</v-btn>
                            </v-col>
                            <v-col>
                                <v-btn :disabled="!validate" class="primary" @click="submit()">{{ $t('Abschicken') }}</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import LanguageSelector from '../../Components/Global/LanguageSelector';
import { mapActions } from 'vuex';

export default {
    name: 'activate-account',
    data: () => ({
        showPassword: false,
        showCheckPassword: false,
        password: '',
        activationCode: '',
        confirmPassword: '',
        errors: [],
        validate: false
    }),
    components: { LanguageSelector },
    computed: {
        computedPassword: {
            get() {
                return this.password;
            },
            set(value) {
                this.password = value;
                this.$refs.form.validate();
            }
        },
        computedConfirm: {
            get() {
                return this.confirmPassword;
            },
            set(value) {
                this.confirmPassword = value;
                this.$refs.form.validate();
            }
        }
    },
    methods: {
        ...mapActions('General', ['ACTIVATE_USER', 'AUTO_LOGIN']),
        submit() {
            this.ACTIVATE_USER({
                activationCode: this.activationCode,
                password: this.password
            })
                .then((response) => {
                    if (response.isWebConferenceUser) {
                        const fullToken = `${response.tokenType} ${response.accessToken}`;
                        window.location.replace(`https://bbb.wiselab.io/b/u/token/${fullToken}`);
                    }
                    return response;
                })
                .then((response) => {
                    if (!response.isWebConferenceUser) {
                        this.AUTO_LOGIN(response).then(() => {
                            this.$nextTick(() => {
                                this.$router.replace({ name: 'landingPage' });
                                this.OPEN_DRAWER(true);
                            });
                        });
                    }
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                });
        },
        reset() {
            this.password = '';
            this.confirmPassword = '';
        },
        rules(type) {
            switch (type) {
                case 'notEmpty':
                    return (value) => (!!value && value !== null && value !== undefined) || this.$t('Pflichtfeld');
                case 'matchPassword':
                    return (value) => value.trim() === this.password || this.$t('Das Bestätigungspasswort stimmt nicht überein.');
                case 'matchConfirm':
                    return (value) => value.trim() === this.confirmPassword || this.$t('Das Bestätigungspasswort stimmt nicht überein.');
            }
        }
    },
    mounted() {
        this.activationCode = this.$route.query.activationCode;
    }
};
</script>
