<template>
    <v-dialog v-model="showDialog" scrollable :width="$vuetify.breakpoint.thresholds.sm" persistent>
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="success">
                {{ $t('Material hinzufügen') }}
            </v-btn>
        </template>
        <v-card>
            <v-toolbar dense flat dark color="secondary">
                <v-toolbar-title>{{ computedTitle }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="() => (showDialog = false)">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-tabs v-model="tabs" slider-color="accent" mandatory>
                <v-tab key="SETTINGS">{{ $t('Einstellungen') }}</v-tab>
                <v-tab key="MATERIAL">{{ $t('Datei') }}</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabs" touchless>
                <v-tab-item key="SETTINGS">
                    <v-container fluid grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-text-field
                                    v-model="name"
                                    :label="$t('Name')"
                                    :hint="$t('Sie können dem Material eine alternative Bezeichnung geben')"
                                    persistent-hint
                                />
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field
                                    v-model="description"
                                    :label="$t('Beschreibung')"
                                    :hint="$t('Sie können dem Material eine Beschreibung hinzufügen')"
                                    persistent-hint
                                />
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="MATERIAL">
                    <Filemanager
                        v-if="initialLoad"
                        @selectedMedia="
                            (value) => {
                                media = value;
                                tabs = 0;
                            }
                        "
                        selectable
                    />
                </v-tab-item>
            </v-tabs-items>
            <v-card-actions>
                <v-btn block :color="typeColor" @click="createMaterial" :disabled="!validMaterial">
                    {{ computedConfirmTitle }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Filemanager from '../../FileManager/Prototype/Filemanager';

export default {
    name: 'material-selector',
    components: { Filemanager },
    data: () => ({
        tabs: 0,
        name: '',
        description: '',
        media: {},
        mediaPath: {},
        showDialog: false,
        initialLoad: false,
        type: 'ADD'
    }),
    props: {
        selectedMaterial: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        selectedMaterial(value) {
            this.reset();
            this.showDialog = true;
            this.type = 'UPDATE';
            Object.entries(value).forEach(([key, value]) => {
                if (this.$data.hasOwnProperty(key)) {
                    this.$data[key] = value;
                }
            });
        },
        media(value, oldValue) {
            this.name = value.fileName;
            if (Object.keys(value).length === 0) {
                if (Object.keys(oldValue).length > 0 && this.name === oldValue.fileName) {
                    this.name = '';
                }
            }
        },
        showDialog(value) {
            if (!value) {
                this.reset();
            }
            if (value && !this.initialLoad) {
                this.initialLoad = true;
            }
        }
    },
    computed: {
        typeColor() {
            if (this.type === 'UPDATE') {
                return 'warning';
            }
            return 'success';
        },
        validMaterial() {
            return Object.keys(this.media).length > 0;
        },
        computedTitle() {
            if (this.type === 'UPDATE') {
                return this.$t('Material Bearbeiten');
            }
            return this.$t('Material Hinzufügen');
        },
        computedConfirmTitle() {
            if (this.type === 'UPDATE') {
                return this.$t('Bearbeiten');
            }
            return this.$t('Hinzufügen');
        }
    },
    methods: {
        reset() {
            this.tabs = 0;
            this.name = '';
            this.description = '';
            this.media = {};
            this.mediaPath = {};
            this.type = 'ADD';
        },
        createMaterial() {
            if (this.type === 'ADD') {
                this.$emit('createMaterial', {
                    name: this.name,
                    description: this.description,
                    media: this.media
                });
                this.reset();
                this.showDialog = false;
            }
            if (this.type === 'UPDATE') {
                this.$emit('createMaterial', {
                    name: this.name,
                    description: this.description,
                    media: this.media,
                    index: this.selectedMaterial.index
                });
                this.reset();
                this.showDialog = false;
            }
        }
    }
};
</script>
