import axios from '../../plugins/Axios/axios';

const state = {
    selectedUsers: [],
    selectedCatalogs: []
};

const getters = {
    getSelectedUsers() {
        return state.selectedUsers;
    },
    getSelectedCatalogs() {
        return state.selectedCatalogs;
    }
};

const actions = {
    GET_USER_ARCHIVE_CATALOGS({ commit }, user) {
        return axios.get(`/users/${user.id}/archivecatalogs`).then((response) => response.data.data);
    },
    GET_MULTIPLE_ARCHIVE_CATALOGS({ commit }, users) {
        return axios.post(`/users/archivecatalogs`, { users: users }).then((response) => response.data.data);
    },
    ASSIGN_USER_ARCHIVE_CATALOGS({ commit }, request) {
        return axios.post(`/archivecatalogs`, request);
    }
};

const mutations = {
    clear: (state) => {
        state.selectedCatalogs = [];
        state.selectedUsers = [];
    },
    updateSelectedUsers: (state, users) => {
        state.selectedUsers = users;
    },
    updateSelectedCatalogs: (state, catalogs) => {
        state.selectedCatalogs = catalogs;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
