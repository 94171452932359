<template>
    <v-card>
        <Toolbar :toolbarTitle="computedTitle" :showDrawer="!showMyAnswer" @backEmit="() => (showMyAnswer = false)" />
        <keep-alive>
            <component
                :is="componentSwitch"
                @activity="handleActivity"
                v-model="showMyAnswer"
                :activity="activity"
                @evaluation="handleEvaluation"
                @selectedKnowledgeContent="handleSelectedKnowledgeContent"
                :selectedKnowledgeContent="selectedKnowledgeContent"
                :hasMaterial="hasMaterial"
            />
        </keep-alive>
        <MyEvaluation v-model="showMyEvaluation" :activity="activity" />
        <KnowledgeContentPreview v-model="computedSelectedKnowledgeContent" toolbarTitle="Content" />
    </v-card>
</template>

<script>
import CourseMixin from '../../../Components/CourseLearning/CourseManager/Mixins/CourseMixin';

import Toolbar from '../../../Components/Generic/Toolbar';
import Editor from '../../../Components/Generic/Editor/Editor';
import KnowledgeContentPreview from '../../../Components/KnowledgeContent/KnowledgeContentPreview';
import MyLearningGoals from '../../../Components/CourseLearning/MyCourses/MyLearningGoals';
import MyAnswer from '../../../Components/CourseLearning/MyCourses/MyAnswer';
import MyEvaluation from '../../../Components/CourseLearning/MyCourses/MyEvaluation';

export default {
    name: 'my-topics',
    mixins: [CourseMixin],
    components: {
        Toolbar,
        Editor,
        MyLearningGoals,
        MyAnswer,
        MyEvaluation,
        KnowledgeContentPreview
    },
    data: () => ({
        showMyAnswer: false,
        showMyEvaluation: false,
        activity: {},
        selectedKnowledgeContent: [],
        hasMaterial: false
    }),
    watch: {
        showMyAnswer(value) {
            if (!value) {
                if (this.activity && this.activity.id) {
                    const learningGoalId = Object.entries(this.getLearningGoalActivitiesMap).find(([key, value]) => {
                        return value.map((activity) => activity.id).includes(this.activity.id);
                    });
                    if (learningGoalId) {
                        this.GET_LEARNING_GOAL_ACTIVITY({
                            learningGoal: { id: learningGoalId[0] },
                            activity: this.activity
                        });
                    }
                }
            }
        }
    },
    computed: {
        componentSwitch() {
            if (this.showMyAnswer) {
                this.getActivityMaterial();
                return MyAnswer;
            }
            return MyLearningGoals;
        },
        computedTitle() {
            if (this.getSelectedAttendeeCourse.learningGoalTypeName === null && this.getSelectedTopic.learningGoalTypeName === null) {
                return `${this.$t('Meine Kurse')} - ${this.$t('Lernziele')}`;
            } else if (this.getSelectedTopic.learningGoalTypeName !== null) {
                return `${this.$t('Meine Kurse')} - ${this.getSelectedTopic.learningGoalTypeName}`;
            }
            return `${this.$t('Meine Kurse')} - ${this.getSelectedAttendeeCourse.learningGoalTypeName}`;
        },
        computedSelectedKnowledgeContent: {
            get() {
                if (this.selectedKnowledgeContent.length > 0) {
                    return this.selectedKnowledgeContent[0];
                }
                return {};
            },
            set() {
                this.selectedKnowledgeContent = [];
            }
        }
    },
    methods: {
        getActivityMaterial() {
            this.GET_ACTIVITY_MATERIALS(this.activity).then(
                () => (this.hasMaterial = this.getMaterial(this.activity).length > 0 || this.activity.material !== '')
            );
        },
        handleSelectedKnowledgeContent(value) {
            if (value.length > 0) {
                this.selectedKnowledgeContent = [value[value.length - 1]];
            } else {
                this.selectedKnowledgeContent = [];
            }
        },
        handleActivity(value) {
            this.activity = value;
            this.showMyAnswer = true;
        },
        handleEvaluation(value) {
            this.activity = value;
            this.showMyEvaluation = true;
        }
    },
    created() {
        this.resetLearningGoalSelection();
    }
};
</script>
