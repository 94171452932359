import { mapGetters, mapActions } from 'vuex';
/**
 * @description This Mixin is intended for "AuthoringPermissions". It provides a convenience Interface for Store
 */
export default {
    data: () => ({
        stepperPosition: 1,
        authoringPermissionList: [
            'GET_KNOWLEDGE_UNIT',
            'ADD_KNOWLEDGE_UNIT',
            'DELETE_KNOWLEDGE_UNIT',
            'UPDATE_KNOWLEDGE_UNIT',
            'ADD_CATALOG_SUB_GROUP',
            'UPDATE_CATALOG_SUB_GROUP',
            'DELETE_CATALOG_SUB_GROUP',
            'ADD_CATALOG',
            'UPDATE_CATALOG',
            'DELETE_CATALOG',
            'MOVE_KNOWLEDGE_UNIT',
            'COPY_KNOWLEDGE_UNIT'
        ]
    }),
    computed: {
        ...mapGetters('AuthoringAdminPermission', ['getSelectedAdmins', 'getSelectedCatalogGroup', 'getSelectedCatalogSubGroup', 'getSystemPermissionList']),
        ...mapGetters('CatalogGroupOperations', ['getCatalogGroupList']),
        ...mapGetters('CatalogSubgroupOperations', ['getCatalogSubGroupList']),
        selectedAdmins: {
            get() {
                return this.getSelectedAdmins;
            },
            set(value) {
                this.SET_SELECTED_ADMINS(value);
            }
        },
        selectedCatalogGroup: {
            get() {
                return this.getSelectedCatalogGroup;
            },
            set(value) {
                this.SET_SELECTED_CATALOG_GROUP(value);
            }
        },
        selectedCatalogSubGroup: {
            get() {
                return this.getSelectedCatalogSubGroup;
            },
            set(value) {
                this.SET_SELECTED_CATALOG_SUB_GROUP(value);
            }
        },
        isCatalogGroupSelected: {
            get() {
                return Object.keys(this.getSelectedCatalogGroup).length > 0;
            }
        },
        isCatalogSubGroupSelected: {
            get() {
                return Object.keys(this.getSelectedCatalogSubGroup).length > 0;
            }
        },
        stepperItems: {
            get() {
                return [
                    {
                        text: 'Admin wählen',
                        selectedText: '',
                        editable: true,
                        href: '',
                        step: '1',
                        url: { name: 'authoringPermissions' }
                    },
                    {
                        text: 'Kataloggruppe wählen',
                        selectedText: this.selectedCatalogGroup.hasOwnProperty('name') ? this.selectedCatalogGroup.name : '',
                        editable: this.isCatalogGroupSelected,
                        href: '',
                        step: '2',
                        url: { name: 'authoringPermissionsCatalogGroup' }
                    },
                    {
                        text: 'Kataloguntergruppe wählen',
                        selectedText: '',
                        editable: this.isCatalogSubGroupSelected,
                        href: '',
                        step: '3',
                        url: { name: 'authoringPermissionsCatalogSubGroup' }
                    }
                ];
            }
        }
    },
    methods: {
        ...mapActions('AuthoringAdminPermission', [
            'SET_SELECTED_ADMINS',
            'SET_SELECTED_CATALOG_GROUP',
            'SET_SELECTED_CATALOG_SUB_GROUP',
            'GET_ADMIN_CATALOGGROUPPERMISSION_LIST',
            'GET_ADMIN_CATALOGSUBGROUPPERMISSION_LIST',
            'GET_ALL_SYSTEM_PERMISSIONS',
            'GET_ADMINPERMISSION_LIST_BY_ID',
            'ADD_CATALOG_GROUP_PERMISSION',
            'ADD_CATALOG_SUB_GROUP_PERMISSION',
            'REMOVE_CATALOG_GROUP_PERMISSION',
            'REMOVE_CATALOG_SUB_GROUP_PERMISSION',
            'GET_AUTHORING_PERMISSIONS_FOR_CATALOG_GROUP_FOR_ADMINS',
            'GET_AUTHORING_PERMISSIONS_FOR_CATALOG_SUB_GROUP_FOR_ADMINS'
        ]),
        ...mapActions('CatalogGroupOperations', ['GET_CATALOGGROUP_LIST']),
        ...mapActions('CatalogSubgroupOperations', ['GET_CATALOGSUBGROUP_LIST']),
        getAdminPermissions(admins) {
            let request = { admins: admins };
            if (this.isCatalogGroupSelected) {
                request = {
                    ...request,
                    catalogGroupId: this.selectedCatalogGroup.id
                };
            } else {
                return new Promise((resolve, reject) => reject('No Catalog Group is selected'));
            }
            if (this.isCatalogSubGroupSelected) {
                request = {
                    ...request,
                    catalogSubGroupId: this.selectedCatalogSubGroup.id
                };
                return this.GET_AUTHORING_PERMISSIONS_FOR_CATALOG_SUB_GROUP_FOR_ADMINS(request);
            } else {
                return this.GET_AUTHORING_PERMISSIONS_FOR_CATALOG_GROUP_FOR_ADMINS(request);
            }
        },

        getAuthoringPermissions(isAuthoringPermissions) {
            return this.GET_ALL_SYSTEM_PERMISSIONS(isAuthoringPermissions).then(() => {
                return this.getSystemPermissionList
                    .filter((permission) => {
                        return this.authoringPermissionList.includes(permission.permissionCode);
                    })
                    .reduce((acc, cur) => {
                        (acc[cur.type] = acc[cur.type] || []).push(cur);
                        return acc;
                    }, {});
            });
        },
        clearSelectedCatalogGroup() {
            this.selectedCatalogGroup = {};
        },
        clearSelectedCatalogSubGroup() {
            this.selectedCatalogSubGroup = {};
        },
        clearSelections() {
            this.clearSelectedCatalogGroup();
            this.clearSelectedCatalogSubGroup();
        }
    },
    mounted() {
        if (this.getSystemPermissionList.length === 0) {
            this.GET_ALL_SYSTEM_PERMISSIONS(false);
        }
    }
};
