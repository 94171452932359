<template>
    <v-menu v-model="showDatePicker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="100%">
        <template v-slot:activator="{ on }">
            <v-text-field
                color="accent"
                autocomplete="new-password"
                attach
                :disabled="textBoxDisabled"
                prepend-icon="mdi-calendar-blank"
                readonly
                :label="label"
                v-model="dateTextComputed"
                v-on="on"
                hide-details
            />
        </template>
        <v-date-picker v-model="computedDateData" scrollable :reactive="true" @input="showDatePicker = false" type="month" :locale="language"></v-date-picker>
    </v-menu>
</template>

<script>
import moment from 'moment';
import MonthCollection from '../../../../services/DataStructures/MonthCollection';

export default {
    data() {
        return {
            dateData: moment().format('YYYY-MM-DD'),
            showDatePicker: false
        };
    },
    computed: {
        computedDateData: {
            get() {
                return this.dateData;
            },
            set(value) {
                this.$emit('dateDataEmit', value);
            }
        },
        dateTextComputed() {
            var date = moment(this.dateText);
            return this.$t(MonthCollection.monthNames[date.month()]);
        }
    },
    props: {
        dateText: String,
        textBoxDisabled: {
            default: false,
            type: Boolean
        },
        language: {
            default: 'de',
            type: String
        },
        label: String
    },
    mounted() {
        this.$emit('dateDataEmit', this.dateData);
    }
};
</script>
