<template>
    <v-container fluid>
        <v-row>
            <v-slide-x-transition :hide-on-leave="$vuetify.breakpoint.xs">
                <v-col cols="auto" v-if="$vuetify.breakpoint.smAndUp && hasMedia">
                    <v-row no-gutters align="center" justify="center">
                        <v-col cols="auto">
                            <ImageLoader class="pointer" @click.native="mediaEmit" :src="thumbnailSource" :width="160" :height="160" />
                        </v-col>
                    </v-row>
                </v-col>
            </v-slide-x-transition>
            <v-col cols="auto" class="flex-shrink-1" v-if="$vuetify.breakpoint.smAndUp && hasMedia">
                <v-divider :vertical="$vuetify.breakpoint.smAndUp" />
            </v-col>
            <v-col class="flex-grow-1">
                <v-row align="end" justify="end" no-gutters>
                    <v-col cols="auto">
                        <v-menu bottom offset-y :max-width="$vuetify.breakpoint.thresholds.xs">
                            <template v-slot:activator="{ on }">
                                <v-layout v-on="on" class="pointer">
                                    <v-flex shrink>
                                        <span>{{ value.completedKUCount + 1 }}</span>
                                    </v-flex>
                                    <v-flex shrink>
                                        <span>/</span>
                                    </v-flex>
                                    <v-flex shrink>
                                        <span class="primary--text">{{ value.totalKUCount }}</span>
                                    </v-flex>
                                </v-layout>
                            </template>
                            <v-card>
                                <v-card-text>
                                    <ul>
                                        <li>
                                            <span>
                                                {{ $t('Aktuelle Wissenseinheit der Aufgabe') }}
                                            </span>
                                        </li>
                                        <li>
                                            <span class="primary--text">
                                                {{ $t('Gesamtanzahl der Wissenseinheiten') }}
                                            </span>
                                        </li>
                                    </ul>
                                </v-card-text>
                            </v-card>
                        </v-menu>
                    </v-col>

                    <v-col cols="auto" v-show="isMicrolearning">
                        <v-divider vertical class="mx-2" />
                    </v-col>

                    <v-col cols="auto" v-show="isMicrolearning">
                        <v-menu bottom offset-y :max-width="$vuetify.breakpoint.thresholds.xs">
                            <template v-slot:activator="{ on }">
                                <v-layout v-on="on" class="pointer">
                                    <v-flex shrink>
                                        <span>{{ value.todaysRemainingKUCount }}</span>
                                    </v-flex>
                                    <v-flex shrink>
                                        <span>/</span>
                                    </v-flex>
                                    <v-flex shrink>
                                        <span class="warning--text">{{ value.dailyAnsweredLimit }}</span>
                                    </v-flex>
                                </v-layout>
                            </template>
                            <v-card>
                                <v-card-text>
                                    <ul>
                                        <li>
                                            <span>
                                                {{ $t('Anzahl der verbleibenden Antworten, die Heute abgegeben werden können') }}
                                            </span>
                                        </li>
                                        <li>
                                            <span class="warning--text">
                                                {{ $t('Gesamtanzahl der Antworten, die Heute abgegeben werden können') }}
                                            </span>
                                        </li>
                                    </ul>
                                </v-card-text>
                            </v-card>
                        </v-menu>
                    </v-col>

                    <v-col cols="auto" v-if="knowledgeUnit && knowledgeUnit.catalogOptions && knowledgeUnit.catalogOptions.name">
                        <v-divider vertical class="mx-2" />
                    </v-col>

                    <v-col cols="auto" v-if="knowledgeUnit && knowledgeUnit.catalogOptions && knowledgeUnit.catalogOptions.name">
                        <v-menu :disabled="!!knowledgeUnit.catalogOptions.name" bottom offset-y :max-width="$vuetify.breakpoint.thresholds.xs">
                            <template v-slot:activator="{ on }">
                                <span
                                    v-on="on"
                                    :class="{
                                        'primary--text': !knowledgeUnit.catalogOptions.name,
                                        pointer: !knowledgeUnit.catalogOptions.name
                                    }"
                                    >{{ knowledgeUnit.catalogOptions.name }}</span
                                >
                            </template>
                            <v-card>
                                <v-card-text>
                                    {{
                                        knowledgeUnit.catalogOptions && knowledgeUnit.catalogOptions.description ? knowledgeUnit.catalogOptions.description : ''
                                    }}
                                </v-card-text>
                            </v-card>
                        </v-menu>
                    </v-col>

                    <v-col cols="auto" v-if="computedLabel">
                        <v-divider vertical class="mx-2" />
                    </v-col>

                    <v-col cols="auto" v-if="computedLabel">
                        <v-menu bottom offset-y :max-width="$vuetify.breakpoint.thresholds.xs">
                            <template v-slot:activator="{ on }">
                                <span v-on="on" class="primary--text pointer">{{ computedLabel }}</span>
                            </template>
                            <v-card>
                                <v-card-text>
                                    {{ taskDescription }}
                                </v-card-text>
                            </v-card>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row>
                    <v-container>
                        <span :class="responsiveFont">{{ computedQuestion }}</span>
                    </v-container>
                </v-row>
            </v-col>
            <v-col cols="auto" class="flex-shrink-1">
                <v-divider :vertical="$vuetify.breakpoint.smAndUp" />
            </v-col>
            <v-col cols="auto" class="flex-shrink-1">
                <v-row class="flex-column" align="center" justify="center">
                    <v-col cols="auto" class="align-self-center" v-if="$vuetify.breakpoint.xs">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-on="on" v-bind="attrs" fab small depressed color="warning" :disabled="!hasMedia" @click="mediaEmit">
                                    <v-icon>{{ mediaIcon }}</v-icon>
                                </v-btn>
                            </template>
                            <span class="text-caption">{{ $t('Miniaturbild') }}</span>
                        </v-tooltip>
                    </v-col>
                    <v-col cols="auto" class="align-self-center">
                        <v-tooltip :left="$vuetify.breakpoint.smAndUp" :bottom="$vuetify.breakpoint.xs">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-on="on"
                                    v-bind="attrs"
                                    fab
                                    small
                                    depressed
                                    color="secondary"
                                    :disabled="!(hasKnowledgeBase && hasSetting('reopenKnowledgeBase') && hasSetting('showKnowledgeBase') && enableSubmit)"
                                    @click="knowledgeBaseEmit"
                                >
                                    <v-icon> {{ knowledgeBaseIcon }}</v-icon>
                                </v-btn>
                            </template>
                            <span class="text-caption">{{ $t('Wissensbasis') }}</span>
                        </v-tooltip>
                    </v-col>
                    <v-col cols="auto" class="align-self-end">
                        <v-tooltip :left="$vuetify.breakpoint.smAndUp" :bottom="$vuetify.breakpoint.xs">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-on="on" v-bind="attrs" fab depressed :color="submitColor" :disabled="!isSubmitDisabled" @click="submitVote">
                                    <v-icon :large="$vuetify.breakpoint.mdAndUp"> {{ submitIcon }}</v-icon>
                                </v-btn>
                            </template>
                            <span class="text-caption">{{ submitText }}</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ImageLoader from '../../Generic/ImageLoader';
import FileSVG from '../../../assets/Resources/SVG/file.svg';
import MusicSVG from '../../../assets/Resources/SVG/music.svg';
import MovieSVG from '../../../assets/Resources/SVG/movie.svg';

import moment from 'moment';

export default {
    name: 'quiz-header',
    components: { ImageLoader },
    data: () => ({
        optionStartingTime: ''
    }),
    props: {
        isNext: {
            type: Boolean,
            default: false
        },
        value: {
            type: Object,
            default: () => ({})
        },
        settings: {
            type: Object,
            default: () => ({})
        },
        enableSubmit: {
            type: Boolean,
            default: false
        },
        valid: {
            type: Boolean,
            default: true
        },
        intermission: {
            type: Boolean,
            default: undefined
        }
    },
    watch: {
        enableSubmit(value) {
            if (value) {
                this.optionStartingTime = moment();
            }
        }
    },
    computed: {
        responsiveFont() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return { 'font-weight-light': true };
                case 'sm':
                    return { 'font-weight-light': true, 'body-2': true };
                case 'md':
                    return { 'font-weight-light': true, subheading: true };
                case 'lg':
                    return { 'font-weight-light': true, title: true };
                case 'xl':
                    return { 'font-weight-light': true, headline: true };
            }
            return '';
        },
        isMicrolearning() {
            return this.settings && this.settings.quiz && this.settings.quiz.taskOptions && this.settings.quiz.taskOptions.hasPassingQuote;
        },
        hasMedia() {
            return this.knowledgeUnit && this.knowledgeUnit.thumbnail && this.knowledgeUnit.thumbnail !== '';
        },
        mediaIcon() {
            return this.hasMedia ? 'mdi-image' : 'mdi-image-broken';
        },
        thumbnailSource() {
            if (this.knowledgeUnit && this.knowledgeUnit.thumbnail && this.knowledgeUnit.thumbnailFileType) {
                let type = this.knowledgeUnit.thumbnailFileType.substring(0, this.knowledgeUnit.thumbnailFileType.indexOf('/'));
                switch (type) {
                    case 'audio':
                        return MusicSVG;
                    case 'application':
                        return FileSVG;
                    case 'video':
                        return MovieSVG;
                }
            }
            return this.knowledgeUnit && this.knowledgeUnit.thumbnail ? this.knowledgeUnit.thumbnail : '';
        },
        hasKnowledgeBase() {
            return (
                this.knowledgeUnit &&
                this.knowledgeUnit.knowledgeBase &&
                this.knowledgeUnit.knowledgeBase.knowledgeContents &&
                this.knowledgeUnit.knowledgeBase.knowledgeContents.length > 0
            );
        },
        knowledgeBaseIcon() {
            return this.hasKnowledgeBase && this.hasSetting('reopenKnowledgeBase') && this.hasSetting('showKnowledgeBase') ? 'mdi-eye' : 'mdi-eye-off';
        },
        hasKnowledgeUnit() {
            return Object.keys(this.knowledgeUnit).length > 0;
        },
        knowledgeUnit() {
            return Object.keys(this.value).length > 0 &&
                this.value.knowledgeUnit &&
                Array.isArray(this.value.knowledgeUnit) &&
                this.value.knowledgeUnit.length > 0
                ? this.value.knowledgeUnit[0]
                : {};
        },
        isSubmitDisabled() {
            return (this.enableSubmit && this.valid && this.hasKnowledgeUnit) || this.isNext;
        },
        submitColor() {
            if (this.isNext) {
                return 'accent';
            }
            if (this.intermission === undefined) {
                return 'success';
            }
            return this.intermission ? 'success' : 'error';
        },
        submitIcon() {
            if (this.isNext) {
                return 'mdi-comment-arrow-right-outline';
            }
            if (this.intermission === undefined) {
                return 'mdi-chevron-right';
            }
            return this.intermission ? 'mdi-thumb-up' : 'mdi-thumb-down';
        },
        taskDescription() {
            if (this.knowledgeUnit && this.knowledgeUnit.questionType) {
                switch (this.knowledgeUnit.questionType) {
                    case 'SEQUENCE':
                        return this.$t(
                            'Bringen Sie die Optionen in die richtige Reihenfolge von oben nach unten. Halten Sie dafür auf die Option gedrückt und ziehen Sie die Option an die richtige Stelle'
                        );
                    case 'SINGLE_SELECT':
                        return this.$t('Wählen Sie genau eine richtige Option. Um eine Option auszuwählen, drücken oder klicken Sie auf die Option');
                    case 'MULTI_SELECT':
                        return this.$t(
                            'Wählen Sie eine oder mehrere richtige Optionen. Um eine Option auszuwählen, drücken oder klicken Sie auf die Option. Drücken Sie auf eine ausgewählte Option, um diese abzuwählen'
                        );
                    case 'PICKER':
                        return this.$t(
                            'Stellen Sie den Schieber auf den richtigen Wert ein. Sie können entweder den Cursor an die richtige Stelle ziehen und/oder die Plus- und Minusknöpfe drücken, um auf den richtigen Wert zu kommen'
                        );
                    case 'CONTENT_ONLY':
                        return this.$t('Keine Aktion nötig. Klicken Sie auf Abgeben, wenn Sie fertig sind.');
                    case 'TRUE_FALSE':
                        return this.$t(
                            'Entscheiden Sie, ob die Aussage richtig oder falsch ist mit. Klicken Sie auf den "Richtig" oder "Falsch" Knopf, um eines dieser Optionen auszuwählen'
                        );
                    case 'FILL_BLANK':
                        return this.$t('Beantworten Sie die Frage, indem Sie im Textfeld in den Optionen Ihre Antwort schreiben');
                    case 'BUCKET_SORT':
                        return this.$t('Ziehen Sie die Optionen in die richtigen Kategorien');
                }
            }
            return '';
        },
        computedLabel() {
            return this.knowledgeUnit && this.knowledgeUnit.questionType ? this.$t(this.knowledgeUnit.questionType) : '';
        },
        computedQuestion() {
            return this.knowledgeUnit && this.knowledgeUnit.question ? this.knowledgeUnit.question : '';
        },
        submitText() {
            return this.isNext ? this.$t('Weiter') : this.$t('Abgabe');
        }
    },
    methods: {
        serializeSubmit() {
            if (this.knowledgeUnit.questionType) {
                const baseVoteRequest = {
                    isOfflineVote: false,
                    knowledgeUnitId: this.knowledgeUnit.id,
                    catalogId: this.knowledgeUnit.catalogId,
                    answeringTime: moment.utc(moment.duration(moment().diff(this.optionStartingTime)).asMilliseconds()).format('HH:mm:ss')
                };
                switch (this.knowledgeUnit.questionType) {
                    case 'SINGLE_SELECT':
                        return {
                            ...baseVoteRequest,
                            knowledgeUnitOptions: this.knowledgeUnit.knowledgeUnitOptions.filter((option) => option.selected)
                        };
                    case 'MULTI_SELECT':
                        return {
                            ...baseVoteRequest,
                            knowledgeUnitOptions: this.knowledgeUnit.knowledgeUnitOptions.filter((option) => option.selected)
                        };
                    case 'FILL_BLANK':
                        return {
                            ...baseVoteRequest,
                            answer: this.knowledgeUnit.knowledgeUnitOptions[0].answer
                        };
                    case 'PICKER':
                        return {
                            ...baseVoteRequest,
                            answer: this.knowledgeUnit.knowledgeUnitOptions[0].answer
                        };
                    case 'SEQUENCE':
                        return {
                            ...baseVoteRequest,
                            knowledgeUnitOptions: this.knowledgeUnit.knowledgeUnitOptions.map(({ id }, index) => ({
                                id,
                                answeredOrder: index
                            }))
                        };
                    case 'TRUE_FALSE':
                        return {
                            ...baseVoteRequest,
                            knowledgeUnitOptions: this.knowledgeUnit.knowledgeUnitOptions.filter((option) => option.selected)
                        };
                    case 'CONTENT_ONLY':
                        return { ...baseVoteRequest };
                    case 'BUCKET_SORT':
                        return {
                            ...baseVoteRequest,
                            knowledgeUnitOptions: this.knowledgeUnit.knowledgeUnitOptions
                        };
                }
            }
        },
        submitVote() {
            if (this.isNext) {
                this.$emit('isNext');
            } else {
                this.$nextTick(() => this.$emit('submit', this.serializeSubmit()));
            }
        },
        mediaEmit() {
            const {
                thumbnail: fileURL,
                thumbnailFileName: fileName,
                thumbnailFileSize: size,
                thumbnailFileType: fileType,
                thumbnailMediaId: mediaId
            } = this.knowledgeUnit;
            this.$emit('media', { fileURL, fileName, size, fileType, mediaId });
        },
        knowledgeBaseEmit() {
            this.$emit('knowledgeBase', this.knowledgeUnit.knowledgeBase);
        },
        hasSetting(key) {
            if (this.knowledgeUnit && this.knowledgeUnit.catalogOptions && this.knowledgeUnit.catalogOptions.options) {
                if (this.knowledgeUnit.catalogOptions.options.hasOwnProperty(key)) {
                    return this.knowledgeUnit.catalogOptions.options[key] ? this.knowledgeUnit.catalogOptions.options[key] : false;
                }
            } else {
                if (this.settings && this.settings.quiz && this.settings.quiz.catalogs) {
                    const settings = this.settings.quiz.catalogs.find((catalog) => catalog.id == this.knowledgeUnit.catalogId);
                    if (settings !== undefined && settings !== null && settings !== -1) {
                        return settings.options[key] ? settings.options[key] : false;
                    }
                }
            }

            return false;
        }
    }
};
</script>
