const sortByKey = (items, index, isDesc, key) => {
    items.sort((a, b) => {
        if (isDesc) {
            if (typeof b[key] === 'string' && typeof a[key] === 'string') {
                return b[key].localeCompare(a[key], {
                    numeric: true,
                    sensitivity: 'base'
                });
            } else {
                return b[key] - a[key];
            }
        }
        if (typeof b[key] === 'string' && typeof a[key] === 'string') {
            return a[key].localeCompare(b[key], {
                numeric: true,
                sensitivity: 'base'
            });
        } else {
            return a[key] - b[key];
        }
    });
    return items;
};

export default [
    {
        name: 'Nach Namen sortieren',
        sort: (items, index, isDesc) => {
            return sortByKey(items, index, isDesc, 'name');
        }
    }
];
