<template>
    <v-card>
        <Toolbar
            :toolbarTitle="$t('Gruppenforen Foren')"
            :tooltipMessage="$t('Auf dieser Seite haben Sie Überblick auf Ihre Gruppenforen')"
            :showDrawer="false"
            :lastPath="!(showAdd || showDelete) ? { name: 'groupForumGroups' } : null"
            @backEmit="reset"
        />
        <v-container fluid>
            <v-row no-gutters>
                <v-col :cols="12">
                    <keep-alive>
                        <component
                            :is="isComponent"
                            :type="type"
                            @create="handleCreate"
                            @update="handleUpdate"
                            @delete="handleDelete"
                            :permissions="editorPermissions"
                            @input="reset"
                        />
                    </keep-alive>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import ForumMixin from '../../Components/GroupForum/Mixins/GroupForumMixin';
import Toolbar from '../../Components/Generic/Toolbar';
import Editor from '../../Components/Generic/Editor/Editor';
import DeleteForum from '../../Components/GroupForum/DeleteForum';
import ForumList from '../../Components/GroupForum/ForumList';
import AddUpdateForum from '../../Components/GroupForum/AddUpdateForum';

export default {
    name: 'group-forum-forums',
    mixins: [ForumMixin],
    components: { Toolbar, ForumList, Editor, AddUpdateForum, DeleteForum },
    data: () => ({
        showAdd: false,
        showDelete: false,
        type: 'ADD',
        editorPermissions: {
            add: false,
            update: false,
            delete: false,
            move: false,
            copy: false,
            details: false,
            groups: false
        }
    }),
    computed: {
        isSelected() {
            return Object.keys(this.getSelectedForum).length > 0;
        },
        isComponent() {
            if (this.showAdd) {
                return AddUpdateForum;
            }
            if (this.showDelete) {
                return DeleteForum;
            }
            return ForumList;
        },
        toolbarText() {
            if (this.showAdd) {
                switch (this.type) {
                    case 'ADD':
                        return this.$t('Forum erstellen');
                    case 'UPDATE':
                        return this.$t('Forum bearbeiten');
                }
            }
            return this.$t('Foren');
        }
    },
    methods: {
        reset() {
            this.showAdd = false;
            this.showDelete = false;
        },
        handleDelete() {
            this.showDelete = true;
        },
        handleCreate() {
            this.type = 'ADD';
            this.showAdd = true;
        },
        handleUpdate() {
            this.type = 'UPDATE';
            this.showAdd = true;
        }
    },
    mounted() {
        const isGroupOwner = this.getLoggedInAdmin.id === this.getSelectedGroup.createdBy || this.getLoggedInAdmin.role === 'SUPER_ADMIN';
        const permissions = {
            add: isGroupOwner,
            update: isGroupOwner,
            delete: isGroupOwner,
            move: false,
            copy: false,
            details: false,
            groups: false
        };
        this.validateEditorState(permissions).then((result) => {
            this.editorPermissions = result;
        });
    }
};
</script>
