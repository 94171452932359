<template>
    <v-container fluid>
        <v-row>
            <v-col :cols="12">
                <v-stepper v-model="stepper">
                    <v-stepper-header>
                        <v-stepper-step :step="1" :editable="stepper > 1 || type === 'UPDATE'">{{ computedPresetText }}</v-stepper-step>
                        <v-stepper-step :step="2" :editable="stepper > 2 || type === 'UPDATE'">{{ computedFirstStepText }}</v-stepper-step>
                        <v-stepper-step :step="3" :editable="type === 'UPDATE'">{{ $t('Details') }}</v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-stepper-content :step="1">
                            <v-row>
                                <v-col :cols="6">
                                    <v-btn block @click="handleFile" color="primary" :disabled="isFileOrLink(selectedMaterial) === 'FILE' && type === 'UPDATE'">
                                        {{ $t('Datei') }}
                                    </v-btn>
                                </v-col>
                                <v-col :cols="6">
                                    <v-btn block @click="handleLink" color="primary" :disabled="isFileOrLink(selectedMaterial) === 'LINK' && type === 'UPDATE'">
                                        {{ $t('Link') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>
                        <v-stepper-content :step="2" class="pa-0">
                            <keep-alive>
                                <component :is="componentSwitch" :selectedMaterial="selectedMaterial" @selectedMaterial="handleMaterial" />
                            </keep-alive>
                        </v-stepper-content>
                        <v-stepper-content :step="3">
                            <v-form ref="form" v-model="validate">
                                <v-row>
                                    <v-col :cols="6">
                                        <v-text-field v-model="computedName" :rules="[nameRule]" :label="$t('Material Bezeichnung')" />
                                    </v-col>
                                    <v-col :cols="6">
                                        <v-text-field
                                            v-model="computedOrder"
                                            type="number"
                                            min="1"
                                            :max="materials.length + 1"
                                            :rules="[orderRule]"
                                            :label="$t('Reihenfolge')"
                                        />
                                    </v-col>
                                    <v-col :cols="12">
                                        <keep-alive>
                                            <component :is="summaryComponent" :value="computedMaterial" />
                                        </keep-alive>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-col>
        </v-row>
        <v-row>
            <v-col :cols="12">
                <v-btn block :color="computedSaveButtonColor" @click="save" :disabled="!saveDisabled">
                    {{ computedSaveButtonText }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import FileView from './FileView';
import LinkView from './LinkView';
import SummaryLink from './SummaryLink';
import SummaryFile from './SummaryFile';
import CatalogMaterialMixin from '../Mixins/CatalogMaterialMixin';

export default {
    name: 'add-update-material',
    components: { FileView, LinkView, SummaryLink, SummaryFile },
    mixins: [CatalogMaterialMixin],
    data: () => ({
        stepper: 1,
        validate: false
    }),
    props: {
        catalog: {
            type: Object,
            default: () => ({})
        },
        selectedMaterial: {
            type: Object,
            default: () => ({})
        },
        type: {
            type: String,
            default: 'ADD',
            validator: (v) => ['ADD', 'UPDATE'].includes(v)
        },
        materials: {
            type: Array,
            default: () => []
        }
    },
    watch: {
        stepper(value) {
            if (value === 3) {
                this.$refs['form'].validate();
            }
        }
    },
    computed: {
        saveDisabled() {
            return this.stepper === 3 && this.selectedMaterial.name && this.selectedMaterial.name.trim() !== '' && this.validate;
        },
        summaryComponent() {
            switch (this.isFileOrLink(this.selectedMaterial)) {
                case 'FILE':
                    return SummaryFile;
                case 'LINK':
                    return SummaryLink;
            }
            return '';
        },
        computedMaterial() {
            switch (this.isFileOrLink(this.selectedMaterial)) {
                case 'FILE':
                    return this.selectedMaterial.media;
                case 'LINK':
                    return this.selectedMaterial.link;
            }
            return '';
        },
        computedName: {
            get() {
                return this.selectedMaterial.name ? this.selectedMaterial.name : '';
            },
            set(value) {
                this.$emit('selectedMaterial', { ...this.selectedMaterial, name: value });
            }
        },
        computedOrder: {
            get() {
                return this.selectedMaterial.order ? this.selectedMaterial.order : 0;
            },
            set(value) {
                this.$emit('selectedMaterial', { ...this.selectedMaterial, order: value });
            }
        },
        computedFirstStepText() {
            switch (this.isFileOrLink(this.selectedMaterial)) {
                case 'FILE':
                    return this.$t('Datei auswählen');
                case 'LINK':
                    return this.$t('Link einstellen');
            }
            return '';
        },
        computedPresetText() {
            switch (this.type) {
                case 'ADD':
                    return this.$t('Materialtyp auswählen');
                case 'UPDATE':
                    return this.$t('Materialtyp ändern');
            }
            return '';
        },
        computedSaveButtonText() {
            switch (this.type) {
                case 'ADD':
                    return this.$t('Material hinzufügen');
                case 'UPDATE':
                    return this.$t('Material Bearbeiten');
            }
            return '';
        },
        computedSaveButtonColor() {
            switch (this.type) {
                case 'ADD':
                    return 'success';
                case 'UPDATE':
                    return 'warning';
            }
            return '';
        },
        componentSwitch() {
            switch (this.isFileOrLink(this.selectedMaterial)) {
                case 'FILE':
                    return FileView;
                case 'LINK':
                    return LinkView;
            }
            return '';
        }
    },
    methods: {
        orderRule(v) {
            if (parseInt(v) < 0) {
                return this.$t('Stellen Sie eine Zahl größer 0 ein');
            }
            if (parseInt(v) > this.materials.length + 1) {
                return this.$t('Stellen Sie eine kleinere Zahl ein');
            }
            return true;
        },
        nameRule(v) {
            return !!v || this.$t('Die Materialbezeichnung darf nicht leer sein');
        },
        handleStepper() {
            if (this.stepper < 3) {
                this.stepper++;
            }
        },
        handleMaterial(value) {
            this.$emit('selectedMaterial', { ...this.selectedMaterial, ...value });
            this.stepper++;
        },
        handleFile() {
            this.$emit('selectedMaterial', { id: '', name: '', order: this.materials.length + 1, media: { fileName: '', fileURL: '', fileType: '', size: 0 } });
            this.stepper++;
        },
        handleLink() {
            this.$emit('selectedMaterial', { id: '', name: '', order: this.materials.length + 1, link: '' });
            this.stepper++;
        },
        save() {
            switch (this.type) {
                case 'ADD':
                    return this.ADD_MATERIAL({
                        catalog: this.catalog,
                        material: { ...this.selectedMaterial, order: this.order - 1 }
                    })
                        .then(() => this.GET_MATERIALS({ catalog: this.catalog }))
                        .then(() => this.$emit('switchView', { type: '', data: {} }));
                case 'UPDATE':
                    return this.UPDATE_MATERIAL({
                        catalog: this.catalog,
                        material: { ...this.selectedMaterial, order: this.order - 1 }
                    })
                        .then(() => this.GET_MATERIALS({ catalog: this.catalog }))
                        .then(() => this.$emit('switchView', { type: '', data: {} }));
            }
        }
    },
    activated() {
        if (this.type === 'ADD') {
            this.$emit('selectedMaterial', {});
            this.stepper = 1;
        }
        if (this.type === 'UPDATE') {
            this.stepper = 3;
        }
    }
};
</script>
