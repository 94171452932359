<template>
    <v-card flat>
        <ForumBreadcrumbs />
        <v-data-table
            :headers="forumHeaders"
            :items="getForums"
            :items-per-page="5"
            :no-data-text="$t('Keine Foren')"
            :no-results-text="$t('Kein Ergebnis')"
            :footer-props="{
                'items-per-page-text': $t('Foren pro Seite'),
                'items-per-page-options': [5, 10, 15, { value: -1, text: $t('Alle') }]
            }"
            :options.sync="pagination"
            :custom-sort="sortForums"
            :search="search"
            :custom-filter="filter"
            @update:sort-desc="(val) => (isPriority = val)"
        >
            <template #top>
                <v-container>
                    <v-row align="center" justify="center">
                        <v-col>
                            <v-text-field prepend-icon="mdi-magnify" :label="$t('Suchen')" v-model="search" />
                        </v-col>
                        <v-col cols="auto">
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn icon color="success" v-on="on" v-bind="attrs" @click="create" :disabled="!permissions.add">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('Forum erstellen') }}</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template #item.title="{ item }">
                <v-container>
                    <v-row :cols="12" :sm="10" no-gutters justify="center">
                        <v-col :cols="12">
                            <span @click="onClick(item)" class="font-weight-bold text-h6 pointer primary--text">
                                {{ item.title }}
                            </span>
                        </v-col>
                        <v-col :cols="12">
                            <span>{{ item.description }}</span>
                        </v-col>
                        <v-col :cols="12">
                            <span>{{ `${$t('Erstellt von')}: ` }}</span>
                            <span class="primary--text">{{ `${item.lastUpdatedBy.displayName}` }}</span>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template #item.order="{ item }">
                <v-chip outlined color="primary">
                    <span>{{ getPriority(item) }}</span>
                </v-chip>
            </template>
            <template #item.threadCount="{ item: { threadCount } }">
                <span>{{ threadCount }}</span>
            </template>
            <template #item.latestAnswer="{ item: { latestAnswer } }">
                <UserProfile v-if="latestAnswer !== undefined && latestAnswer !== null" :value="latestAnswer.user" small />
            </template>
            <template #item.thread="{ item }">
                <v-container>
                    <v-row no-gutters v-if="item.latestAnswer !== undefined && item.latestAnswer !== null">
                        <v-col :cols="12">
                            <span class="text-body-1 primary--text pointer clamp-text" @click="onThreadClick(item, item.latestAnswer)">
                                {{ item.latestAnswer.title }}
                            </span>
                        </v-col>
                        <v-col :cols="12">
                            <span>{{ formatDate(item.latestAnswer.lastUpdated) }}</span>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template #item.menu="{ item }">
                <v-menu offset-x left>
                    <template #activator="{ on, attrs }">
                        <v-btn icon v-on="on" v-bind="attrs">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list dense>
                        <v-list-item :disabled="!permissions.update" @click="edit(item)">
                            <v-list-item-content>
                                <v-list-item-title>{{ $t('Bearbeiten') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item :disabled="!permissions.delete" @click="remove(item)">
                            <v-list-item-content>
                                <v-list-item-title>{{ $t('Löschen') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import ForumMixin from './Mixins/ForumMixin';
import ForumSort from './Sort/ForumSort';
import ForumHeaders from './TableHeaders/ForumTableHeaders';
import Priorities from './Priority/Priorities';
import UserProfile from '../Generic/Profile/UserProfile';
import ForumBreadcrumbs from './Helpers/ForumBreadcrumbs';

import moment from 'moment';

export default {
    name: 'forum-list',
    components: {
        ForumBreadcrumbs,
        UserProfile
    },
    mixins: [ForumMixin],
    data: () => ({
        forumSort: ForumSort,
        forumHeaders: ForumHeaders,
        pagination: {},
        priorities: [],
        search: '',
        isPriority: false
    }),
    props: {
        permissions: {
            type: Object,
            default: () => ({
                add: false,
                update: false,
                delete: false,
                move: false,
                copy: false,
                details: false,
                groups: false
            })
        }
    },
    methods: {
        create(item) {
            this.$emit('create', item);
        },
        edit(item) {
            this.setForum(item);
            this.$emit('update', item);
        },
        remove(item) {
            this.setForum(item);
            this.$emit('delete', item);
        },
        filter(value, search, item) {
            if (search.trim() === '') {
                return true;
            }
            let buildString = [...new Set(search.split(' '))].filter((word) => word !== '').map((word) => word.toLowerCase());
            const text = Object.values(item)
                .filter((data) => typeof data === 'string' || typeof data === 'number')
                .map((data) => {
                    if (typeof data === 'number') {
                        return data.toString();
                    }
                    return data;
                })
                .join(' ')
                .toLowerCase();
            return buildString.every((word) => text.includes(word));
        },
        sortForums(items) {
            if (this.isPriority) {
                return items.sort((a, b) => a.order - b.order);
            }
            return items.sort((a, b) => b.order - a.order);
        },
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD HH:mm');
        },
        getPriority({ order }) {
            const find = this.priorities.find((priority) => priority.order === order);
            if (find !== undefined) {
                return find.display;
            }
            return '';
        },
        setForum(forum) {
            this.SET_SELECTED_FORUM(forum);
            this.$emit('input', forum);
        },
        onClick(forum) {
            this.ADD_FORUM_PATH({ ...forum, href: { name: 'forums' } });
            this.SET_SELECTED_FORUM(forum);
            this.$router.push({ name: 'threads' });
        },
        onThreadClick(forum, thread) {
            this.ADD_FORUM_PATH({ ...forum, href: { name: 'forums' } });
            this.ADD_FORUM_PATH({ ...thread, href: { name: 'threads' } });
            this.SET_SELECTED_FORUM(forum);
            this.SET_SELECTED_THREAD(thread);
            this.$router.push({ name: 'answers', query: { allowReply: thread.canAnswer } });
        },
        isSelected(forum) {
            if (this.getSelectedForum.hasOwnProperty('id') && forum.hasOwnProperty('id')) {
                return forum.id === this.getSelectedForum.id;
            }
            return false;
        }
    },
    mounted() {
        this.forumHeaders = this.forumHeaders.map((header) => {
            header.text = this.$t(header.text);
            return header;
        });
        this.priorities = Priorities('Foren').map((priority) => {
            priority.hint = this.$t(priority.hint);
            priority.display = this.$t(priority.display);
            return priority;
        });
        this.GET_ALL_FORUMS();
        this.REMOVE_FORUM_PATH(this.getSelectedForum);
    }
};
</script>

<style scoped>
.clamp-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;
}
</style>
