import axios from '../../../plugins/Axios/axios';
import Vue from 'vue';

const state = {
    learningGoalActivities: [],
    learningGoalActivitiesMap: [],
    selectedActivity: {},
    activityTypes: [],
    allowedFileTypes: {},
    activityAnswers: [],
    schedulingMyActivity: []
};

const getters = {
    getLearningGoalActivities() {
        return state.learningGoalActivities.sort((a, b) => a.order - b.order);
    },
    getSelectedActivity() {
        return state.selectedActivity;
    },
    getActivityTypes() {
        return state.activityTypes;
    },
    getAllowedFileTypes() {
        return state.allowedFileTypes;
    },
    getLearningGoalActivitiesMap() {
        return state.learningGoalActivitiesMap;
    },
    getActivityAnswers() {
        return state.activityAnswers;
    },
    getSchedulingMyActivity() {
        return state.schedulingMyActivity;
    }
};

const actions = {
    SET_LEARNING_GOAL_ACTIVITIES({ commit }, activities) {
        commit('updateLearningGoalActivities', activities);
    },
    SET_LEARNING_GOAL_ACTIVITIES_MAP({ commit }, activity) {
        commit('updateLearningGoalActivitiesMap', activity);
    },
    SET_SELECTED_ACTIVITY({ commit }, activity) {
        commit('updateSelectedActivity', activity);
    },
    GET_ACTIVITY_TYPES({ commit }) {
        return axios
            .get(`courselearning/activities/types`)
            .then((response) => response.data.data)
            .then((response) => commit('updateActivityTypes', response));
    },
    GET_ACTIVITY_ALLOWED_FILE_TYPES({ commit }) {
        return axios
            .get(`/courselearning/activities/fileupload/allowedtypes`)
            .then((response) => response.data.data)
            .then((response) => commit('updateAllowedFileTypes', response));
    },
    GET_LEARNING_GOAL_ACTIVITY({ commit }, { activity: { id: activityId }, learningGoal: { id: learningGoalId } }) {
        return axios
            .get(`/courselearning/learninggoalinstances/${learningGoalId}/activities/${activityId}`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateLearningGoalActivitiesMap', response);
                return response;
            });
    },
    GET_LEARNING_GOAL_ACTIVITIES({ commit }, { map = false, ...request }) {
        const { id: learningGoalTempId } = request;
        return axios
            .get(`/courselearning/learninggoalinstances/${learningGoalTempId}/activities`)
            .then((response) => response.data.data)
            .then((response) => {
                if (!map) {
                    commit('updateLearningGoalActivities', response);
                } else {
                    commit('updateLearningGoalActivitiesMap', {
                        key: learningGoalTempId,
                        value: response
                    });
                }
                return response;
            });
    },
    UPDATE_LEARNING_GOAL_ACTIVITIES({ commit, dispatch }, { learningGoal, activities }) {
        const { id: learningGoalTempId } = learningGoal;
        const activityIds = activities.map((activity) => ({ id: activity.id }));
        return axios
            .patch(`/courselearning/learninggoalinstances/${learningGoalTempId}/activities`, activityIds)
            .then((response) => response.data.data)
            .then((response) => {
                dispatch('LearningGoalOperations/GET_LEARNING_GOAL', learningGoal, {
                    root: true
                });
                commit('updateLearningGoalActivities', response);
            });
    },
    GET_ACTIVITY_ANSWERS({ commit }, { activity: { id: activityId } }) {
        return axios
            .get(`/courselearning/activities/${activityId}/answers`)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateActivityAnswers', response);
                return response;
            })
            .catch(() => commit('updateActivityAnswers', []));
    },
    // FILE ACTIVITY
    GET_FILE_ACTIVITY({ commit }, { activity: { id: fileUploadTempId } }) {
        return axios.get(`/courselearning/fileuploadactivityinstances/${fileUploadTempId}`).then((response) => response.data.data);
    },
    CREATE_FILE_ACTIVITY({ commit }, request) {
        return axios.post(`/courselearning/fileuploadactivityinstances`, request).then((response) => response.data.data);
    },
    UPDATE_FILE_ACTIVITY({ commit }, { id: fileUploadTempId, ...file }) {
        return axios
            .patch(`/courselearning/fileuploadactivityinstances/${fileUploadTempId}`, file)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateLearningGoalActivities', response);
                return response;
            });
    },
    DELETE_FILE_ACTIVITY({ commit }, request) {
        const { id: fileUploadTempId } = request;
        return axios.delete(`/courselearning/fileuploadactivityinstances/${fileUploadTempId}`).then(() => commit('deleteLearningGoalsActivities', request));
    },
    GET_FILE_ANSWER({ commit }, { id: fileUploadTempId }) {
        return axios.get(`/courselearning/fileuploadactivityinstances/${fileUploadTempId}/answers`).then((response) => response.data.data);
    },
    // CHECK ACTIVITY
    GET_CHECK_ACTIVITY({ commit }, request) {
        const { id: fileUploadTempId } = request;
        return axios.get(`/courselearning/selfcheckactivityinstances/${fileUploadTempId}`).then((response) => response.data.data);
    },
    CREATE_CHECK_ACTIVITY({ commit }, request) {
        return axios.post(`/courselearning/selfcheckactivityinstances`, request).then((response) => response.data.data);
    },
    UPDATE_CHECK_ACTIVITY({ commit }, { id: selfCheckTempId, ...check }) {
        return axios
            .patch(`/courselearning/selfcheckactivityinstances/${selfCheckTempId}`, check)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateLearningGoalActivities', response);
                return response;
            });
    },
    DELETE_CHECK_ACTIVITY({ commit }, request) {
        const { id: selfCheckTempId } = request;
        return axios.delete(`/courselearning/selfcheckactivityinstances/${selfCheckTempId}`).then(() => commit('deleteLearningGoalsActivities', request));
    },
    // TEXTFIELD ACTIVITY
    GET_TEXTFIELD_ACTIVITY({ commit }, { id: textfieldActivityId }) {
        return axios.get(`/courselearning/textfieldactivityinstances/${textfieldActivityId}`).then((response) => response.data.data);
    },
    CREATE_TEXTFIELD_ACTIVITY({ commit }, request) {
        return axios.post(`/courselearning/textfieldactivityinstances`, request).then((response) => response.data.data);
    },
    UPDATE_TEXTFIELD_ACTIVITY({ commit }, { id: textfieldActivityId, ...textfieldActivity }) {
        return axios
            .patch(`/courselearning/textfieldactivityinstances/${textfieldActivityId}`, textfieldActivity)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateLearningGoalActivities', response);
                return response;
            });
    },
    DELETE_TEXTFIELD_ACTIVITY({ commit }, request) {
        const { id: textfieldActivityId } = request;
        return axios.delete(`/courselearning/textfieldactivityinstances/${textfieldActivityId}`).then(() => commit('deleteLearningGoalsActivities', request));
    },
    GET_TEXTFIELD_ANSWER({ commit }, request) {
        const { id: textfieldActivityId } = request;
        return axios.get(`/courselearning/textfieldactivityinstances/${textfieldActivityId}/answers`).then((response) => response.data.data);
    },
    //QUIZ ACTIVITY
    GET_QUIZ_ACTIVITY({ commit }, { id: activityId }) {
        return axios.get(`/courselearning/quizactivityinstances/${activityId}`).then((response) => response.data.data);
    },
    GET_QUIZ_EXCLUDED_KNOWLEDGE_UNITS({ commit }, { id: activityId }) {
        return axios.get(`/courselearning/quizactivityinstances/${activityId}/excludeknowledgeunits`).then((response) => response.data.data.knowledgeUnits);
    },
    GET_QUIZ_KNOWLEDGE_UNITS({ commit }, { id: activityId }) {
        return axios.get(`/courselearning/quizactivityinstances/${activityId}/knowledgeunits`).then((response) => response.data.data.knowledgeUnits);
    },
    GET_USER_QUIZ_ANSWERS({ commit }, { id: activityId }) {
        return axios.get(`/courselearning/quizactivityinstances/${activityId}/userquiz/answers`).then((response) => response.data.data);
    },
    GET_USER_QUIZ({ commit }, { id: activityId }) {
        return axios.get(`/courselearning/quizactivityinstances/${activityId}/userquiz/knowledgeunits`).then((response) => response.data.data);
    },
    CREATE_QUIZ_ACTIVITY({ commit }, request) {
        return axios.post(`/courselearning/quizactivityinstances`, request).then((response) => response.data.data);
    },
    UPDATE_QUIZ_ACTIVITY({ commit }, { id: activityId, ...quizActivity }) {
        return axios
            .patch(`/courselearning/quizactivityinstances/${activityId}`, quizActivity)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateLearningGoalActivities', response);
                return response;
            });
    },
    DELETE_QUIZ_ACTIVITY({ commit }, request) {
        const { id: activityId } = request;
        return axios.delete(`/courselearning/quizactivityinstances/${activityId}`).then(() => commit('deleteLearningGoalsActivities', request));
    },
    GET_QUIZ_SOLUTIONS({ commit }, { id: activityId }) {
        return axios.get(`/courselearning/quizactivityinstances/${activityId}/examplesolutions`).then((response) => response.data.data);
    },
    ADD_QUIZ_SOLUTION({ commit }, { activity: { id: activityId }, solution }) {
        return axios.post(`/courselearning/quizactivityinstances/${activityId}/examplesolutions`, solution).then((response) => response.data.data);
    },
    CAST_USER_QUIZ_VOTE({ commit }, { activity: { id: activityId }, vote }) {
        return axios.post(`/courselearning/quizactivityinstances/${activityId}/userquiz/votes`, vote).then((response) => response.data.data);
    },
    //INFORM ACTIVITY
    GET_INFORM_ACTIVITY({ commit }, { id: informActivityId }) {
        return axios.get(`/courselearning/informactivityinstances/${informActivityId}`).then((response) => response.data.data);
    },
    CREATE_INFORM_ACTIVITY({ commit }, request) {
        return axios.post(`/courselearning/informactivityinstances`, request).then((response) => response.data.data);
    },
    UPDATE_INFORM_ACTIVITY({ commit }, { id: informActivityId, ...informActivity }) {
        return axios
            .patch(`/courselearning/informactivityinstances/${informActivityId}`, informActivity)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateLearningGoalActivities', response);
                return response;
            });
    },
    DELETE_INFORM_ACTIVITY({ commit }, request) {
        let { id: informActivityId } = request;
        return axios.delete(`/courselearning/informactivityinstances/${informActivityId}`).then(() => commit('deleteLearningGoalsActivities', request));
    },
    //SUBMIT ACTIVITIES
    SUBMIT_FILE_ACTIVITY({ commit }, { activity: { id: activityId }, file }) {
        const form = new FormData();
        form.append('file', file);
        return axios
            .post(`/courselearning/fileuploadactivityinstances/${activityId}/answers`, form)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateLearningGoalActivitiesMap', response.activity);
                return response;
            });
    },
    SUBMIT_TEXTFIELD_ACTIVITY({ commit }, { activity: { id: activityId, activityType: type }, text }) {
        return axios
            .post(`/courselearning/textfieldactivityinstances/${activityId}/answers`, { text, type })
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateLearningGoalActivitiesMap', response.activity);
                return response;
            });
    },
    SUBMIT_CHECK_ACTIVITY({ commit }, { activity, check }) {
        const { id: activityId, activityType: type } = activity;
        const options = check.map(({ answered: checked, ...rest }) => ({
            checkPoint: rest,
            checked,
            activity,
            type: 'SingleSelfCheckAnswer'
        }));
        return axios
            .post(`/courselearning/selfcheckactivityinstances/${activityId}/answers`, { type, answers: options })
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateLearningGoalActivitiesMap', response.activity);
                return response;
            });
    },
    SUBMIT_INFORM_ACTIVITY({ commit }, { activity: { id: activityId, activityType: type } }) {
        return axios
            .post(`/courselearning/informactivityinstances/${activityId}/answers`, {
                type
            })
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateLearningGoalActivitiesMap', response.activity);
                return response;
            });
    },
    // Evaluate
    EVALUATE_OWN_ACTIVITY({ commit }, { activity, evaluation }) {
        const { id: activityId } = activity;
        return axios.post(`/courselearning/activities/${activityId}/evaluations/self`, evaluation);
    },
    REORDER_ACTIVITIES({ commit }, { learningGoalId, ...activity }) {
        return axios
            .patch(`/courselearning/learninggoalinstances/${learningGoalId}/activities/reorder`, activity)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateLearningGoalActivities', response);
                return response;
            });
    },
    // Scheduling
    GET_ACTIVITY_SCHEDULING({ commit }, { id: activityInsId }) {
        return axios.get(`/courselearning/activities/${activityInsId}/scheduling`).then((response) => response.data.data);
    },
    UPDATE_ACTIVITY_SCHEDULING({ commit }, { id: activityInsId, ...topic }) {
        return axios
            .patch(`/courselearning/activities/${activityInsId}/scheduling`, topic)
            .then((response) => response.data.data)
            .then((response) => commit('updateScheduling', response));
    }
};

const mutations = {
    clear: (state) => {
        state.learningGoalActivities = [];
        state.learningGoalActivitiesMap = [];
        state.selectedActivity = {};
        state.allowedFileTypes = {};
        state.activityTypes = [];
        state.schedulingMyTopic = [];
    },
    updateActivityAnswers: (state, activities) => (state.activityAnswers = activities),
    updateSelectedActivity: (state, activity) => (state.selectedActivity = activity),
    updateActivityTypes: (state, response) => (state.activityTypes = response),
    updateAllowedFileTypes: (state, response) => {
        state.allowedFileTypes = response.reduce((map, obj) => {
            map[obj.type] = response.filter((type) => type.type === obj.type).map((type) => type.name);
            return map;
        }, {});
    },
    updateLearningGoalActivitiesMap: (state, { key, value, ...rest }) => {
        if (!!key && !!value) {
            Vue.set(state.learningGoalActivitiesMap, key, value);
        } else {
            Object.entries(state.learningGoalActivitiesMap).forEach(([key, activities]) => {
                if (activities.map((activity) => activity.id).includes(rest.id)) {
                    state.learningGoalActivitiesMap[key] = activities.map((activity) => {
                        if (activity.id === rest.id) {
                            return rest;
                        }
                        return activity;
                    });
                }
            });
            state.learningGoalActivitiesMap = {
                ...state.learningGoalActivitiesMap
            };
        }
    },
    updateLearningGoalActivities: (state, request) => {
        if (Array.isArray(request)) {
            state.learningGoalActivities = request;
        } else {
            if (request.hasOwnProperty('id')) {
                if (state.selectedActivity.id === request.id) {
                    state.selectedActivity = request;
                }
                if (state.learningGoalActivities.map((course) => course.id).includes(request.id)) {
                    state.learningGoalActivities = state.learningGoalActivities.map((activity) => {
                        if (activity.id === request.id) {
                            return request;
                        }
                        return activity;
                    });
                } else {
                    state.learningGoalActivities.push(request);
                }
            }
        }
    },
    deleteLearningGoalsActivities: (state, request) => {
        if (request.hasOwnProperty('id')) {
            if (state.selectedActivity.id === request.id) {
                state.selectedActivity = {};
            }
            const find = state.learningGoalActivities.map((data) => data.id).indexOf(request.id);
            if (find !== -1) {
                state.learningGoalActivities.splice(find, 1);
            }
        }
    },
    updateScheduling: (state, response) => {
        if (Array.isArray(response)) {
            state.schedulingMyActivity = response;
        } else {
            if (state.schedulingMyActivity.map((activity) => activity.id).includes(response.id)) {
                state.schedulingMyActivity = state.schedulingMyActivity.map((activity) => {
                    if (activity.id === response.id) {
                        return response;
                    }
                    return activity;
                });
            } else {
                state.schedulingMyActivity.push(response);
            }
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
