<template>
    <v-dialog v-model="showDialog" persistent :width="$vuetify.breakpoint.thresholds.sm" :fullscreen="$vuetify.breakpoint.xs">
        <v-card>
            <v-toolbar dense dark flat color="seconary">
                <v-toolbar-title>{{ $t('Hinweis') }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="() => (showDialog = false)">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-layout column>
                    <v-flex>
                        <span>{{ $t('Wenn diese Wissenseinheit gelöscht wird, wird diese Wissenseinheit aus allen laufenden Aufgaben entfernt') }}</span>
                    </v-flex>
                    <v-flex>
                        <ul>
                            <li>
                                <span>{{ value.question }}</span>
                            </li>
                        </ul>
                    </v-flex>
                    <v-flex>
                        <span class="error--text">{{ $t('Sind Sie sicher, dass Sie diese Wissenseinheit löschen wollen?') }}</span>
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="remove">{{ $t('Löschen') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import AuthoringToolMixin from './Mixins/AuthoringtoolMixin';

export default {
    name: 'delete-knowledge-unit-exception-dialog',
    mixins: [AuthoringToolMixin],
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        dialog: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        showDialog: {
            get() {
                return this.dialog;
            },
            set(value) {
                this.$emit('dialog', value);
            }
        }
    },
    methods: {
        remove() {
            this.SELECT_KNOWLEDGEUNIT(this.value);
            this.UPDATE_EXCEPTION_REMOVE(this.value)
                .then(() => this.DELETE_KNOWLEDGE_UNIT(this.value))
                .then(() => (this.showDialog = false));
        },
        cancel() {
            this.showDialog = false;
        }
    }
};
</script>
