<template>
    <div>
        <Toolbar :toolbarTitle="$t('Chat')" :showDrawer="true" tooltipMessage="Chat" />
        <v-tabs v-model="tabs" show-arrows slider-color="accent">
            <v-tab ripple :key="0">
                {{ $t('Persönliche Nachrichten') }}
            </v-tab>
            <v-tab ripple :key="1">
                {{ $t('Gruppennachrichten') }}
            </v-tab>
            <v-tab-item :key="0">
                <PersonalChat />
            </v-tab-item>
            <v-tab-item :key="1">
                <GroupChat />
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>
import ChatMixins from '../../Components/Chat/Mixins/ChatMixin';
import Toolbar from '../../Components/Generic/Toolbar';
import PersonalChat from '../../Components/Chat/PersonalChat/PersonalChat';
import GroupChat from '../../Components/Chat/GroupChat/GroupChat';
import Editor from '../../Components/Generic/Editor/Editor';
import PullUpMenu from '../../Components/Generic/PullUpMenu/PullUpMenu';
import PersonalChatDrawer from '../../Components/Chat/PersonalChat/PersonalChatDrawer';
import GroupChatDrawer from '../../Components/Chat/GroupChat/GroupChatDrawer';

export default {
    name: 'chat',
    components: {
        Toolbar,
        PersonalChat,
        GroupChat
    },
    mixins: [ChatMixins],
    data: () => ({
        tabs: 0
    }),
    beforeDestroy() {
        this.SET_SELECTED_CHAT_USER({});
        this.SET_SELECTED_CHAT_GROUP({});
        this.BUILD_GROUP_LIST();
        this.BUILD_USER_LIST();
    }
};
</script>
