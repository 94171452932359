import { admin, superAdmin, user } from '../../Roles';
import MyCourses from '../../../views/CourseLearning/MyCourses/MyCourses';
import MyTopics from '../../../views/CourseLearning/MyCourses/MyTopics';
import MyLearningGoals from '../../../views/CourseLearning/MyCourses/MyLearningGoals';

export default [
    {
        path: '/MyCourses',
        name: 'myCourses',
        component: MyCourses,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [admin, superAdmin, user],
            navigation: {
                type: { name: 'Groups', groupId: 'Meine Lernumgebung' },
                priority: 0,
                icon: 'mdi-notebook-outline',
                name: 'Meine Kurse',
                navigationKey: 'MY_COURSES',
                badge: 'ChatOperations/getTotalMyCourseCount'
            }
        }
    },
    {
        path: '/MyCourses/MyTopics',
        name: 'myTopics',
        component: MyTopics,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [admin, superAdmin, user]
        }
    },
    {
        path: '/MyCourses/MyTopics/MyLearningGoals',
        name: 'myLearningGoals',
        component: MyLearningGoals,
        meta: {
            requiresAuth: true,
            requiresPermission: false,
            permissionSet: [],
            requiresRole: true,
            roleSet: [admin, superAdmin, user]
        }
    }
];
