<template>
    <v-container fluid ma-0 pa-0 fill-height>
        <v-layout column>
            <v-flex shrink>
                <Toolbar
                    :toolbarTitle="$t('Root-Dateimanager')"
                    showDrawer
                    tooltipMessage="Hier können Sie Medien und Ordner hinzufügen, die hinzugefügte Ordner und Medien anschauen, bearbeiten und löschen."
                    showInfoButton
                    infoMessage="Sie können Dateien verschieben indem Sie diese in Ordner ziehen"
                />
            </v-flex>
            <v-flex grow>
                <Filemanager />
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import Toolbar from '../../Components/Generic/Toolbar';
import Filemanager from '../../Components/StaticFileManager/Prototype/Filemanager';
import StaticFilemanagerMixin from '../../Components/StaticFileManager/Mixin/StaticFilemanagerPrototypeMixin';

export default {
    name: 'static-file-manager',
    mixins: [StaticFilemanagerMixin],
    components: {
        Toolbar,
        Filemanager
    }
};
</script>
