<template>
    <v-data-iterator
        :items="contents"
        :no-data-text="$t('Kein Content verfügbar')"
        :no-results-text="$t('Kein Ergebnis')"
        :footer-props="{
            'items-per-page-text': $t('Content pro Seite'),
            'items-per-page-options': [5, 10, 15, { text: $t('Alle'), value: -1 }]
        }"
        :items-per-page="5"
        :hideDefaultFooter="contents.length <= 5"
        :custom-sort="sortedContents"
    >
        <template v-slot:default="{ items }">
            <v-list>
                <v-list-item v-for="item in items" :key="item.id">
                    <v-list-item-action>
                        <v-row align="center" justify="center" no-gutters>
                            <v-col :cols="12">
                                <v-btn icon @click="markContent(item)">
                                    <v-icon :color="computeState(item)">mdi-file</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                        <v-list-item-subtitle>{{ $t(item.state.stateName) }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </template>
    </v-data-iterator>
</template>

<script>
export default {
    name: 'knowledge-content-content',
    props: {
        contents: {
            type: Array,
            default: () => []
        },
        value: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        sortedContents(items) {
            items.sort((a, b) =>
                a.title.localeCompare(b.title, undefined, {
                    numeric: true,
                    sensitivity: 'base'
                })
            );
            return items;
        },
        computeState({ state: { stateName } }) {
            switch (stateName) {
                case 'APPROVED':
                    return 'success';
                case 'PENDING':
                    return 'warning';
                case 'DECLINED':
                    return 'error';
            }
        },
        markContent(content) {
            this.$emit('input', [content]);
        }
    }
};
</script>
