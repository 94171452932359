<template>
    <v-container grid-list-md>
        <div class="mb-3">
            <CourseBreadcrumbs />
            <Topic
                :value="getSelectedTopic"
                :isLearningGoal="true"
                :isCourseManagerValue="isCourseManagerValue"
                @selectedKnowledgeContent="handleSelectedKnowledgeContent"
                :selectedKnowledgeContent="selectedKnowledgeContent"
            />
        </div>
        <v-card>
            <v-toolbar dark flat dense color="secondary">
                <v-toolbar-title>{{ $t('Lernziele') }}</v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                    <AddUpdateLearningGoal type="ADD" />
                    <AddUpdateLearningGoal type="UPDATE" />
                    <DeleteLearningGoal />
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" :small="$vuetify.breakpoint.xs" @click="goToEvaluations" color="yellow">
                                <v-icon color="white">mdi-check-all</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('Bewertungen') }}</span>
                    </v-tooltip>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                <Draggable v-if="getTopicLearningGoals.length > 0" v-model="computedTopicLearningGoals" :move="onMove">
                    <transition-group name="slide-x-transition" tag="v-layout" class="layout row wrap">
                        <v-flex xs12 sm6 md6 lg6 xl6 v-for="item in getTopicLearningGoals" :key="item.id">
                            <LearningGoal
                                :timeScaleInMinutes="selectedCourse.timeScaleInMinutes"
                                :value="item"
                                @input="(value) => (selectedLearningGoal = value)"
                                :selectable="true"
                                :isLearningGoal="true"
                                :selected="selectedLearningGoal"
                                @selected="handleCardClick"
                                :isAdmin="isAdmin"
                                @selectedKnowledgeContent="handleSelectedKnowledgeContent"
                                :selectedKnowledgeContent="selectedKnowledgeContent"
                            />
                        </v-flex>
                    </transition-group>
                </Draggable>
                <v-layout column v-if="getTopicLearningGoals.length === 0">
                    <v-flex align-self-center justify-self-center>
                        <span>{{ $t('Keine Lernziele verfügbar') }}</span>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
        <KnowledgeContentPreview toolbarTitle="Content" v-model="computedSelectedKnowledgeContent" />
    </v-container>
</template>

<script>
import LearningGoal from './Helpers/LearningGoal/LearningGoal';
import Topic from './Helpers/Topic/Topic';
import CourseBreadcrumbs from './Breadcrumbs/CourseBreadcrumbs';
import KnowledgeContentPreview from '../../KnowledgeContent/KnowledgeContentPreview';
import AddUpdateLearningGoal from './AddUpdateLearningGoal';
import DeleteLearningGoal from './DeleteLearningGoals';
import CourseMixin from './Mixins/CourseMixin';
import Draggable from 'vuedraggable';

export default {
    name: 'learning-goals',
    mixins: [CourseMixin],
    components: {
        Topic,
        LearningGoal,
        CourseBreadcrumbs,
        Draggable,
        KnowledgeContentPreview,
        AddUpdateLearningGoal,
        DeleteLearningGoal
    },
    data: () => ({
        showSearch: false,
        search: '',
        dragged: {
            order: 0,
            elementId: ''
        },
        selectedKnowledgeContent: []
    }),
    props: {
        isAdmin: {
            type: Boolean,
            value: false
        },
        isCourseManagerValue: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedTopicLearningGoals: {
            get() {
                return this.getTopicLearningGoals;
            },
            set() {
                this.REORDER_LEARNING_GOALS({
                    topicId: this.getSelectedTopic.id,
                    id: this.dragged.elementId,
                    order: this.dragged.order
                });
            }
        },
        selectedLearningGoal: {
            get() {
                return this.getSelectedLearningGoal;
            },
            set(value) {
                this.SET_SELECTED_LEARNING_GOAL(value);
            }
        },
        selectedCourse: {
            get() {
                if (this.isAdmin) {
                    return this.getSelectedAdminCourse;
                }
                return this.getSelectedAttendeeCourse;
            },
            set(value) {
                if (this.isAdmin) {
                    this.SET_SELECTED_ADMIN_COURSE(value);
                } else {
                    this.SET_SELECTED_ATTENDEE_COURSE(value);
                }
            }
        },
        computedSelectedKnowledgeContent: {
            get() {
                if (this.selectedKnowledgeContent.length > 0) {
                    return this.selectedKnowledgeContent[0];
                }
                return {};
            },
            set() {
                this.selectedKnowledgeContent = [];
            }
        }
    },
    methods: {
        goToEvaluations() {
            this.$router.push({ name: 'evaluations' });
        },
        handleSelectedKnowledgeContent(value) {
            if (value.length > 0) {
                this.selectedKnowledgeContent = [value[0]];
            } else {
                this.selectedKnowledgeContent = [];
            }
        },
        onMove(event) {
            this.dragged.order = event.relatedContext.index;
            if (event.relatedContext.index > event.draggedContext.index) {
                this.dragged.order++;
            }
            this.dragged.elementId = event.draggedContext.element.id;
        },
        handleCardClick(value) {
            if (this.selectedLearningGoal.id === value.id) {
                this.selectedLearningGoal = value;
                // this.ADD_COURSE_BREADCRUMB({
                // 	breadcrumb: this.getSelectedLearningGoal,
                // 	href: { name: 'learningGoals' }
                // });
                this.$router.push({ name: 'activities' });
            } else {
                this.selectedLearningGoal = value;
            }
        }
    },
    mounted() {
        this.GET_TOPIC_LEARNING_GOALS(this.getSelectedTopic);
        // this.REMOVE_COURSE_BREADCRUMB(this.getSelectedLearningGoal);
        this.resetLearningGoalSelection();
    }
};
</script>
