<template>
    <div>
        <ForumBreadcrumbs />
        <v-data-iterator
            :items="answers"
            :items-per-page="5"
            :footer-props="{
                'items-per-page-text': $t('Antworten pro Seite'),
                'items-per-page-options': [5, 10, 15, { value: -1, text: $t('Alle') }],
                'show-first-last-page': true
            }"
            :no-data-text="$t('Keine Antworten')"
            :no-results-text="$t('Kein Ergebnis')"
            :custom-sort="sortAnswers"
            :hide-default-footer="answers.length === 0"
            :page.sync="page"
            ref="answers"
        >
            <template v-slot:header>
                <v-card flat outlined>
                    <v-container>
                        <v-row align="center">
                            <v-col cols="auto">
                                <UserProfile :value="getSelectedThread.user" :displayName="false" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col :cols="12">
                                <v-divider />
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col :cols="12">
                                <span class="font-weight-bold text-h6 primary--text">{{ getSelectedThread.title }}</span>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col :cols="12">
                                <div class="ck ck-content" v-html="getSelectedThread.text" />
                                <div style="clear: both" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col :cols="12">
                                <v-divider />
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="auto">
                                <span class="text-caption">{{ `${$t('Erstellt')}: ${formatDate(getSelectedThread.createdAt)}` }}</span>
                            </v-col>
                            <v-spacer />
                            <v-col cols="auto" v-if="isEdited(getSelectedThread)">
                                <span class="text-caption">{{ `${$t('Zuletzt bearbeitet')}: ${formatDate(getSelectedThread.lastUpdated)}` }}</span>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </template>
            <template v-slot:default="{ items }">
                <v-container>
                    <v-row v-for="item in items" :key="item.id">
                        <v-col :cols="12">
                            <component
                                :is="isComponent(item.edit)"
                                :value="item"
                                @quote="handleQuote"
                                @edit="handleEdit(item, $event)"
                                @delete="handleDelete(item)"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template #footer v-if="$route.query.allowReply">
                <AddAnswer ref="addAnswer" v-model="text" @saved="handleSave" />
            </template>
        </v-data-iterator>
    </div>
</template>

<script>
import ThreadAnswer from './Helpers/ThreadAnswer';
import ThreadEditAnswer from './Helpers/ThreadEditAnswer';
import UserProfile from '../Generic/Profile/UserProfile';
import ForumBreadcrumbs from './Helpers/ForumBreadcrumbs';
import AddAnswer from './AddAnswer';

import GroupForumMixin from './Mixins/GroupForumMixin';

import moment from 'moment';

export default {
    name: 'group-forum-answer-list',
    components: {
        UserProfile,
        ForumBreadcrumbs,
        ThreadAnswer,
        ThreadEditAnswer,
        AddAnswer
    },
    mixins: [GroupForumMixin],
    data: () => ({
        answers: [],
        pagination: {},
        text: '',
        page: 0
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        getAnswers(value) {
            this.answers = value.map((answer) => {
                answer['edit'] = false;
                return answer;
            });
        }
    },
    computed: {
        sortAnswers() {
            return (items) => {
                items.sort((a, b) => moment(a.createdAt).format('YYYYMMDDHHmmss') - moment(b.createdAt).format('YYYYMMDDHHmmss'));
                return items;
            };
        }
    },
    methods: {
        isEdited({ createdAt, lastUpdated }) {
            return this.formatDate(createdAt) !== this.formatDate(lastUpdated);
        },
        handleQuote(quote) {
            this.text += quote;
            this.$vuetify.goTo(this.$refs.addAnswer, {
                duration: 1000
            });
        },
        handleDelete(item) {
            this.DELETE_ANSWER({
                group: this.getSelectedGroup,
                forum: this.getSelectedForum,
                thread: this.getSelectedThread,
                answer: item
            });
        },
        handleSave() {
            this.page = this.$refs.answers.$children[0].pageCount;
        },
        createAnswer() {
            this.$emit('create', {});
        },
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD HH:mm');
        },
        handleEdit(item, edit) {
            const find = this.answers.map((answer) => answer.id).indexOf(item.id);
            if (find !== undefined) {
                this.$set(this.answers, find, {
                    ...this.answers[find],
                    edit: edit
                });
            }
        },
        isComponent(edit) {
            if (edit) {
                return ThreadEditAnswer;
            }
            return ThreadAnswer;
        },
        isSelected(answer) {
            if (this.value.hasOwnProperty('id')) {
                return answer.id === this.value.id;
            }
            return false;
        }
    },
    mounted() {
        this.GET_ANSWERS({
            group: this.getSelectedGroup,
            forum: this.getSelectedForum,
            thread: this.getSelectedThread
        }).then(() => {
            this.answers = this.getAnswers.map((answer) => {
                answer['edit'] = false;
                return answer;
            });
        });
    }
};
</script>
