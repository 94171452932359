<template>
    <v-card>
        <v-card-text>
            <v-form v-model="validateForm" ref="form">
                <v-textarea
                    v-model="computedText"
                    :label="$t('Antwort')"
                    :hint="hint"
                    persistent-hint
                    :rules="[minWordCountRule, maxWordCountRule]"
                    auto-grow
                />
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
import CourseMixin from '../../CourseManager/Mixins/CourseMixin';

export default {
    name: 'upload-answer-type',
    mixins: [CourseMixin],
    data: () => ({
        validateForm: false
    }),
    props: {
        value: {
            type: [String, Object, FileList, Array],
            default: ''
        },
        settings: {
            type: Object,
            default: () => ({})
        },
        validate: {
            type: Boolean,
            default: false
        },
        answerText: {
            type: String,
            default: ''
        }
    },
    computed: {
        computedText: {
            get() {
                if (this.value instanceof String) {
                    return this.value;
                } else if (this.answerText !== '') {
                    return this.answerText;
                }
                return '';
            },
            set(value) {
                this.$emit('input', value);
                this.$emit('validate', this.validateForm);
            }
        },
        // Make textfield count words
        maxWordCount() {
            if (this.settings.maxWordCount === 0) {
                return false;
            }
            return this.settings.maxWordCount;
        },
        hint() {
            if (this.settings.minWordCount === 0 && this.settings.maxWordCount === 0) {
                return '';
            }
            return `min: ${this.settings.minWordCount} ${this.$t('Wörter')}, max: ${this.settings.maxWordCount} ${this.$t('Wörter')}`;
        }
    },
    methods: {
        minWordCountRule(value) {
            const wordLength = value ? value.split(' ').filter((word) => word.trim() !== '').length : 0;
            return wordLength >= this.settings.minWordCount || this.settings.minWordCount === 0 || `Min: ${this.settings.minWordCount} ${this.$t('Wörter')}`;
        },
        maxWordCountRule(value) {
            const wordLength = value ? value.split(' ').filter((word) => word.trim() !== '').length : 0;
            return wordLength <= this.settings.maxWordCount || this.settings.maxWordCount === 0 || `Max: ${this.settings.maxWordCount} ${this.$t('Wörter')}`;
        }
    },
    mounted() {
        this.$refs.form.validate();
    }
};
</script>
