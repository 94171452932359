<template>
    <v-card flat height="100%" style="overflow: auto">
        <v-toolbar flat dense text color="transparent">
            <v-spacer />
            <v-toolbar-items>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="showGroupPerformance" v-on="on" medium icon color="teal accent-4" @click.stop :disabled="!hasGroupEffortsPermission">
                            <v-icon medium>mdi-clipboard-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Gruppen Leistungen') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="createGroup" v-on="on" medium icon color="success" @click.stop :disabled="!permissions.create">
                            <v-icon medium>mdi-account-multiple-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Untergruppe erstellen') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="createUser" v-on="on" medium icon color="success" @click.stop :disabled="!permissions.create">
                            <v-icon medium>mdi-account-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Nutzer erstellen') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="updateGroup" v-on="on" medium icon color="warning" @click.stop :disabled="!permissions.update">
                            <v-icon medium>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Gruppe bearbeiten') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="deleteGroup" v-on="on" medium icon color="error" @click.stop :disabled="!permissions.delete">
                            <v-icon medium>mdi-delete</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('Gruppe löschen') }}</span>
                </v-tooltip>
            </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-container>
            <v-row>
                <v-col :cols="12">
                    <v-text-field prepend-inner-icon="mdi-account-multiple" :label="$t('Name')" hide-details :value="value.name" readonly />
                </v-col>
                <v-col :cols="12">
                    <v-text-field
                        prepend-inner-icon="mdi-account-multiple"
                        :label="$t('Beschreibung')"
                        hide-details
                        :value="value.description ? value.description : $t('Keine')"
                        readonly
                    />
                </v-col>
            </v-row>
        </v-container>
        <v-subheader v-if="user && admins.length > 0">{{ $t('Gruppenleiter') }}</v-subheader>
        <v-data-iterator
            :items="admins"
            :no-data-text="$t('Keine Daten')"
            :no-results-text="$t('Kein Ergebnis')"
            :items-per-page="-1"
            :footer-props="{
                'items-per-page-options': [{ text: $t('Alle'), value: -1 }]
            }"
            :custom-filter="filter"
            :search="search"
            hideDefaultFooter
            v-if="user && admins.length > 0"
        >
            <template v-slot:item="{ item }">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>{{ item.displayName }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-data-iterator>
    </v-card>
</template>

<script>
import UsersMixin from '../../Mixins/UserMixin';
export default {
    name: 'users-group-display-view',
    mixins: [UsersMixin],
    data: () => ({
        admins: []
    }),
    props: {
        admin: {
            type: Boolean,
            default: false
        },
        user: {
            type: Boolean,
            default: false
        },
        search: {
            type: String,
            default: ''
        },
        value: {
            type: Object,
            default: () => ({})
        },
        permissions: {
            permissions: {
                type: Object,
                default: () => ({
                    add: false,
                    update: false,
                    delete: false,
                    change: false
                })
            }
        }
    },
    watch: {
        value(value) {
            this.admins = [];
            if (this.user) {
                this.GET_GROUP_LEADERS(value).then((admins) => (this.admins = admins));
            }
        }
    },
    computed: {
        hasGroupEffortsPermission() {
            return this.admins.map(({ id }) => id).includes(this.getLoggedInAdmin.id) || this.getLoggedInAdmin.role === 'SUPER_ADMIN';
        },
        children() {
            return this.value.children ? this.value.children : [];
        }
    },
    methods: {
        filter(items, search) {
            if (!search || search.trim() === '') {
                return items;
            }
            let buildString = [...new Set(search.split(' '))].filter((word) => word !== '');
            return items.filter((item) =>
                buildString.some((word) =>
                    Object.values(item)
                        .filter((val) => typeof val === 'string' || typeof val === 'number')
                        .map((val) => {
                            if (val === 'number') {
                                return val.toString();
                            }
                            return val;
                        })
                        .join(' ')
                        .toLowerCase()
                        .includes(word.toLowerCase())
                )
            );
        },
        iconColor(item) {
            if (this.isUserOrGroup(item) === 'USER') {
                return item.active ? 'success' : 'warning';
            }
            return '';
        },
        selectItem(item) {
            this.$emit('input', item);
        },
        showGroupPerformance() {
            this.$emit('switchView', {
                component: 'GROUP_EFFORTS',
                type: 'ADD',
                data: {}
            });
        },
        createUser() {
            this.$emit('switchView', {
                component: 'CREATE_USER',
                type: 'ADD',
                data: {}
            });
        },
        createGroup() {
            this.$emit('switchView', {
                component: 'CREATE_GROUP',
                type: 'ADD',
                data: {}
            });
        },
        updateGroup() {
            this.$emit('switchView', {
                component: 'UPDATE_GROUP',
                type: 'UPDATE',
                data: {}
            });
        },
        deleteGroup() {
            this.$emit('switchView', {
                component: 'DELETE_GROUP',
                type: 'DELETE',
                data: {}
            });
        }
    },
    activated() {
        this.admins = [];
        if (this.user) {
            this.GET_GROUP_LEADERS(this.value).then((admins) => (this.admins = admins));
        }
    }
};
</script>
