<template>
    <v-menu v-model="menu" offset-x :close-on-content-click="false" :offset-overflow="true">
        <template v-slot:activator="{ on }">
            <v-input persistent-hint :hint="$t('Wählen Sie die FAQ-Gruppe aus, in der das FAQ aufzufinden ist')">
                <template v-slot:prepend>
                    <v-btn v-on="on" icon>
                        <v-icon>mdi-folder-edit</v-icon>
                    </v-btn>
                    <span>{{ $t('Ausgewählte FAQ-Gruppe: ') + getSelectedFaqGroupName() }}</span>
                </template>
            </v-input>
        </template>
        <v-card :width="calculateMenuWidth">
            <v-toolbar dark color="secondary">
                <v-toolbar-title>{{ $t('FAQ-Gruppe auswählen') }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-data-iterator
                    :items="computedItems"
                    :no-data-text="$t('Keine FAQ-Gruppen gefunden')"
                    :no-results-text="$t('kein Ergebnis')"
                    :footer-props="{
                        'items-per-page-text': $t('FAQ-Gruppen pro Seite'),
                        'items-per-page-options': [5, 10, 15, { value: -1, text: $t('Alle') }]
                    }"
                    :hide-default-footer="computedItems.length <= 5"
                >
                    <template v-slot:item="{ item }">
                        <v-list-item :value="isSelected(item.group)" @click="onClick(item.group)">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.group.name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-data-iterator>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
import FAQMixin from '../Mixins/FAQMixins';

export default {
    name: 'faq-group-selector',
    mixins: [FAQMixin],
    data: () => ({
        selectedFAQGroup: {},
        menu: false
    }),
    props: {
        id: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'ADD'
        }
    },
    computed: {
        calculateMenuWidth() {
            return this.$vuetify.breakpoint.smAndUp ? this.$vuetify.breakpoint.thresholds.xs : undefined;
        },
        computedItems() {
            return this.getFaqMap;
        }
    },
    methods: {
        getSelectedFaqGroupName() {
            switch (this.type) {
                case 'ADD':
                    return this.getSelectedFaqGroup.name;
                case 'UPDATE': {
                    return this.getSelectedFaqGroup.name;
                }
            }
            return this.getSelectedFaqGroup;
        },
        isSelected(group) {
            return this.getSelectedFaqGroup.id === group.id;
        },
        onClick(group) {
            this.menu = false;
            this.SET_SELECTED_FAQ_GROUP(group);
        }
    }
};
</script>
