<template>
    <v-card height="100%">
        <Toolbar
            :toolbarTitle="$t('Zertifikatsmanager')"
            :tooltipMessage="$t('Hier können Sie Zertifikate andere Nutzer herunterladen und die Abgaben betrachten')"
        />
        <v-container>
            <v-row>
                <v-col>
                    <Certificates />
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import Toolbar from '../../Components/Generic/Toolbar';
import Certificates from '../../Components/Certificates/Certificates';

export default {
    name: 'certificate-manager',
    components: { Toolbar, Certificates }
};
</script>
