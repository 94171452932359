export default (type) => [
    {
        display: 'Sehr Hoch',
        hint: `${type} mit sehr hoher Priorität werden als erstes gelistet`,
        order: 4
    },
    {
        display: 'Hoch',
        hint: `${type} mit hoher Priorität werden nach ${type} mit sehr hoher Priorität und vor ${type} mit normaler Priorität gelistet`,
        order: 3
    },
    {
        display: 'Normal',
        hint: `${type} mit normaler Priorität werden nach ${type} mit hoher Priorität und vor ${type} mit niedriger Priorität gelistet`,
        order: 2
    },
    {
        display: 'Niedrig',
        hint: `${type} mit niedriger Priorität werden nach ${type} mit normaler Priorität und vor ${type} mit sehr niedriger Priorität gelistet`,
        order: 1
    },
    {
        display: 'Sehr Niedrig',
        hint: `${type} mit sehr niedriger Priorität als letztes gelistet`,
        order: 0
    }
];
