var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-iterator',{attrs:{"items":_vm.items,"items-per-page":4,"hideDefaultFooter":_vm.items.length < 4,"options":_vm.pagination,"footer-props":{
        'items-per-page-text': _vm.$t('Wissenseinheiten pro Seite'),
        'items-per-page-options': [4, 8, { value: -1, text: _vm.$t('Alle') }]
    },"value":_vm.computedSelection},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"default",fn:function({ items, selected }){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',_vm._l((items),function(item){return _c('v-col',{key:item.id,attrs:{"cols":6}},[_c('v-card',{class:{
                            primary: selected,
                            pointer: true,
                            'no-highlight': true,
                            'd-flex': true,
                            'flex-column': true
                        },attrs:{"dark":selected,"height":"100%"},on:{"click":function($event){return _vm.selectItem(item)}}},[_c('v-toolbar',{attrs:{"flat":"","dark":"","dense":"","color":"secondary"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t(item.questionType)))])],1),_c('v-card',{staticClass:"d-flex flex-column",attrs:{"flat":"","height":"100%"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('span',[_vm._v(_vm._s(item.question))])])],1)],1),_c('v-spacer'),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('v-sheet',{attrs:{"color":_vm.answerColor(item),"dark":"","pa-0":"","ma-0":""}},[_c('v-card-text',[_c('span',[_vm._v(_vm._s(_vm.answerText(item)))])])],1)],1)],1)],1)],1)],1)],1)}),1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }