/**
 * import your Routes and export them
 */
import GenericRoutes from './Generic/Routes';
import AppRoutes from './App/Routes';
import ArchiveToolRoutes from './ArchiveTool/Routes';
import AuthoringToolRoutes from './AuthoringTool/Routes';
import FileManagerRoutes from './FileManager/Routes';
import KnowledgeContentRoutes from './KnowledgeContent/Routes';
import NewsRoutes from './News/Routes';
import PermissionRoutes from './Permission/Routes';
import PlanToolRoutes from './PlanTool/Routes';
import StatisticsRoutes from './Statistics/Routes';
import SystemSettingsRoutes from './SystemSettings/Routes';
import UserRoutes from './Users/Routes';
import WikiRoutes from './Wiki/Routes';
import CustomerRoutes from './CustomerManager/Routes';
import ForumRoutes from './Forum/Routes';
import GroupForumRoutes from './GroupForum/Routes';
import CourseToolRoutes from './CourseLearning/CourseTool/Routes';
import CourseManagerRoutes from './CourseLearning/CourseManager/Routes';
import MyCoursesRoutes from './CourseLearning/MyCourses/Routes';
import ChatRoutes from './Chat/Routes';
import CertificateRoutes from './Certificates/Routes';
import FAQRoutes from './FAQ/Routes';
import ScormRoutes from './SCORM/Routes';

export default [
    ...GenericRoutes,
    ...AppRoutes,
    ...ArchiveToolRoutes,
    ...AuthoringToolRoutes,
    ...FileManagerRoutes,
    ...KnowledgeContentRoutes,
    ...NewsRoutes,
    ...PermissionRoutes,
    ...PlanToolRoutes,
    ...StatisticsRoutes,
    ...SystemSettingsRoutes,
    ...UserRoutes,
    ...WikiRoutes,
    ...CustomerRoutes,
    ...ForumRoutes,
    ...GroupForumRoutes,
    ...CourseToolRoutes,
    ...CourseManagerRoutes,
    ...MyCoursesRoutes,
    ...ChatRoutes,
    ...CertificateRoutes,
    ...FAQRoutes,
    ...ScormRoutes
];
