import axios from '../../../plugins/Axios/axios';
import Vue from 'vue';

const state = {
    catalogGroupList: [],
    selectedCatalogGroup: {
        itemType: 'CATALOGGROUP',
        itemTitle: 'Kataloggruppe',
        path: '/Authoringtool/CatalogGroup',
        data: {}
    },
    operationType: ''
};

// getters
const getters = {
    getCatalogGroupList(state) {
        return state.catalogGroupList;
    },
    getSelectedCatalogGroup(state) {
        return state.selectedCatalogGroup;
    },
    getOperationType(state) {
        return state.operationType;
    }
};

// actions
const actions = {
    ADD_CATALOGGROUP({ commit, dispatch, rootGetters }, request) {
        return axios
            .post('cataloggroups', request)
            .then((response) => response.data.data)
            .then((response) => {
                const catalogGroups = rootGetters['AuthoringToolOperations/getMapData']('root');
                commit('AuthoringToolOperations/updateMap', { root: [...catalogGroups, response] }, { root: true });
                dispatch('AuthoringToolOperations/LOAD_PERMISSIONS', { catalogGroup: response }, { root: true });
                commit('updateCatalogGroupList', { ...response });
                // commit('updateSelectedCatalogGroup', response);
                return response;
            });
    },
    DELETE_CATALOGGROUP_BY_ID({ commit, rootGetters }, { id: catalogGroupId }) {
        return axios.delete(`cataloggroups/${catalogGroupId}`).then(() => {
            const catalogGroups = rootGetters['AuthoringToolOperations/getMapData']('root').filter((catalogGroup) => catalogGroup.id !== catalogGroupId);
            commit('AuthoringToolOperations/updateMap', { root: catalogGroups }, { root: true });
            // commit('updateSelectedCatalogGroup', {});
            commit('deleteCatalogGroup', catalogGroupId);
            return {};
        });
    },
    GET_CATALOGGROUP_LIST({ commit }) {
        return axios.get('cataloggroups?sort=createdAt,desc').then((response) => {
            commit('updateCatalogGroupList', response.data.data.groups);
            return response;
        });
    },
    GET_SINGLE_CATALOGGROUP_BY_ID({ commit }, catalogGroupId) {
        return axios.get(`/cataloggroups/${catalogGroupId}`).then((response) => {
            commit('updateCatalogGroupList', response.data.data);
            return response;
        });
    },
    SELECT_CATALOGGROUP({ commit }, selectedCatalogGroup) {
        commit('updateSelectedCatalogGroup', selectedCatalogGroup);
        return selectedCatalogGroup;
    },
    UPDATE_CATALOGGROUP({ commit, rootGetters }, { id: catalogGroupId, ...catalogGroup }) {
        return axios
            .patch(`/cataloggroups/${catalogGroupId}`, catalogGroup)
            .then((response) => response.data.data)
            .then((response) => {
                commit('updateCatalogGroupList', { ...response });
                const catalogGroups = rootGetters['AuthoringToolOperations/getMapData']('root').map((catalogGroup) => {
                    if (catalogGroup.id === response.id) {
                        return response;
                    }
                    return catalogGroup;
                });
                commit('AuthoringToolOperations/updateMap', { root: catalogGroups }, { root: true });
                // commit('updateSelectedCatalogGroup', response);
                return response;
            });
    }
};

// mutations
const mutations = {
    clear: (state) => {
        state.catalogGroupList = [];
        state.selectedCatalogGroup = {
            itemType: 'CATALOGGROUP',
            itemTitle: 'Kataloggruppe',
            path: '/Authoringtool/CatalogGroup',
            data: {}
        };
    },
    updateCatalogGroupList: (state, catalogGroupList) => {
        if (Array.isArray(catalogGroupList)) {
            Vue.set(state, 'catalogGroupList', catalogGroupList);
        } else {
            if (state.catalogGroupList.map(({ id }) => id).includes(catalogGroupList.id)) {
                Vue.set(
                    state,
                    'catalogGroupList',
                    state.catalogGroupList.map((group) => {
                        if (group.id === catalogGroupList.id) {
                            return catalogGroupList;
                        }
                        return group;
                    })
                );
            } else {
                Vue.set(state.catalogGroupList, [...state.catalogGroupList, catalogGroupList]);
            }
        }
    },
    updateSelectedCatalogGroup: (state, selectedCatalogGroup) => {
        Vue.set(state.selectedCatalogGroup, 'data', selectedCatalogGroup);
    },
    deleteCatalogGroup: (state, groupId) => {
        const index = state.catalogGroupList.map(({ id }) => id).findIndex((id) => id === groupId);
        if (index !== -1) {
            Vue.delete(state.catalogGroupList, index);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
