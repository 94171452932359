<template>
    <v-menu v-model="show" offset-y :close-on-content-click="false" :min-width="$vuetify.breakpoint.thresholds.xs">
        <template v-slot:activator="{ on: menu }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn v-on="{ ...tooltip, ...menu }" icon>
                        <v-icon>mdi-table</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('Taxonomien') }}</span>
            </v-tooltip>
        </template>
        <component
            :is="componentSwitch"
            @switchComponent="(value) => (taxonomySwitch = value)"
            :selectable="selectable"
            :taxonomy="taxonomy"
            @taxonomy="(value) => (taxonomy = value)"
            @close="(val) => (show = val)"
            v-model="selectedTaxonomies"
        />
    </v-menu>
</template>

<script>
import CourseMixin from './Mixins/CourseMixin';

import TaxonomySelector from './Helpers/TaxonomyTable/TaxonomySelector';
import TaxonomyCreator from './Helpers/TaxonomyTable/CreateTaxonomyTable';
import TaxonomyUpdator from './Helpers/TaxonomyTable/UpdateDeleteTaxonomyTable';

export default {
    name: 'taxonomy-table',
    components: { TaxonomySelector, TaxonomyCreator, TaxonomyUpdator },
    mixins: [CourseMixin],
    data: () => ({
        taxonomySwitch: '',
        taxonomy: {},
        show: false
    }),
    props: {
        value: {
            type: [Array, Object],
            default: () => []
        },
        selectable: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        isCourse: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        componentSwitch() {
            switch (this.taxonomySwitch) {
                case 'ADD':
                    return TaxonomyCreator;
                case 'UPDATE':
                    return TaxonomyUpdator;
                default:
                    return TaxonomySelector;
            }
        },
        selectedTaxonomies: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    }
};
</script>
