<template>
    <v-card flat height="100%">
        <v-container fluid fill-height>
            <v-layout wrap fill-height>
                <v-flex xs12 fill-height>
                    <v-card flat outlined height="100%">
                        <v-row>
                            <v-col :cols="12">
                                <v-container fluid>
                                    <v-row>
                                        <v-col :cols="12">
                                            <v-row align="center">
                                                <v-col>
                                                    <v-text-field
                                                        prepend-icon="mdi-magnify"
                                                        :label="$t('Suchen')"
                                                        v-model="computedSearch"
                                                        hide-details
                                                        :loading="debouncer !== null"
                                                    />
                                                </v-col>
                                                <v-col cols="auto">
                                                    <v-menu v-model="menu" bottom :close-on-content-click="false" offset-y>
                                                        <template #activator="{ on, attrs }">
                                                            <v-btn icon v-on="on" v-bind="attrs" :disabled="!isSelected">
                                                                <v-icon>{{ menu ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <CalendarTask :value="value" @change="() => (menu = true)">
                                                            <v-container fluid>
                                                                <v-row justify="end">
                                                                    <v-col cols="auto">
                                                                        <v-tooltip bottom>
                                                                            <template v-slot:activator="{ on }">
                                                                                <v-btn icon v-on="on" :disabled="!isDeletable" @click="update" color="warning">
                                                                                    <v-icon>mdi-pencil</v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t('Aufgabe bearbeiten') }}</span>
                                                                        </v-tooltip>
                                                                    </v-col>
                                                                    <v-col cols="auto">
                                                                        <v-tooltip bottom>
                                                                            <template v-slot:activator="{ on }">
                                                                                <v-btn icon v-on="on" :disabled="!isDeletable" @click="remove" color="error">
                                                                                    <v-icon>mdi-delete</v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t('Aufgabe löschen') }}</span>
                                                                        </v-tooltip>
                                                                    </v-col>
                                                                    <v-col cols="auto">
                                                                        <v-tooltip bottom>
                                                                            <template v-slot:activator="{ on }">
                                                                                <v-btn
                                                                                    icon
                                                                                    v-on="on"
                                                                                    :disabled="!isSelected"
                                                                                    @click="copy"
                                                                                    color="accent light-1"
                                                                                >
                                                                                    <v-icon>mdi-content-copy</v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t('Aufgabe kopieren') }}</span>
                                                                        </v-tooltip>
                                                                    </v-col>
                                                                    <v-col cols="auto">
                                                                        <v-tooltip bottom>
                                                                            <template v-slot:activator="{ on }">
                                                                                <v-btn
                                                                                    icon
                                                                                    v-on="on"
                                                                                    :disabled="!(isSelected && permissions.report)"
                                                                                    @click="report"
                                                                                    color="teal lighten-1"
                                                                                >
                                                                                    <v-icon>mdi-chart-bar-stacked</v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>{{ $t('Report') }}</span>
                                                                        </v-tooltip>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-container>
                                                        </CalendarTask>
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn icon v-on="on" @click="create" color="success">
                                                                <v-icon>mdi-plus</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>{{ $t('Aufgabe Erstellen') }}</span>
                                                    </v-tooltip>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-checkbox v-model="showInactiveTasks" :label="$t('Inaktive Aufgaben anzeigen')" hide-details />
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-checkbox v-model="showAllTasks" :label="$t('Alle Aufgaben anzeigen')" hide-details />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                            <v-col :cols="12">
                                <v-layout>
                                    <v-flex shrink>
                                        <v-btn small outlined icon @click="prev" class="mx-2" :disabled="search !== ''">
                                            <v-icon>mdi-chevron-left</v-icon>
                                        </v-btn>
                                    </v-flex>
                                    <v-flex align-self-center justify-self-center>
                                        <v-layout align-center justify-center>
                                            <v-flex shrink class="current-month">
                                                <span class="primary--text">{{ currentMonth }}</span>
                                            </v-flex>
                                            <v-flex shrink>
                                                <span>{{ currentYear }}</span>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex shrink>
                                        <v-btn small outlined icon @click="next" class="mx-2" :disabled="search !== ''">
                                            <v-icon>mdi-chevron-right</v-icon>
                                        </v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-col>
                            <v-col :cols="12">
                                <v-calendar
                                    v-show="search.trim() === ''"
                                    ref="calendar"
                                    :now="today"
                                    :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                                    type="month"
                                    color="primary"
                                    v-model="day"
                                    :events="filteredTasks"
                                    event-start="startDate"
                                    event-end="endDate"
                                    :event-color="eventColor"
                                    event-overlap-mode="stack"
                                    @click:event="handleEvent"
                                    :event-more="false"
                                    show-week
                                />
                                <v-data-iterator
                                    v-show="search.trim() !== ''"
                                    :items="filteredTasks"
                                    :no-data-text="$t('Keine Aufgaben verfügbar')"
                                    :no-results-text="$t('Kein Ergebnis')"
                                    :items-per-page="10"
                                    :footer-props="{
                                        'items-per-page-options': [5, 10, 15, { value: -1, text: $t('Alle') }]
                                    }"
                                    :hideDefaultFooter="filteredTasks.length <= 10"
                                    :search="search"
                                    :custom-filter="filter"
                                    :value="[value]"
                                >
                                    <template v-slot:item="{ item, selected }">
                                        <v-list-item @click.stop="selectTask(item)" v-if="$vuetify.breakpoint.mdAndUp">
                                            <v-list-item-action>
                                                <v-btn icon @click.stop="goToCalendar(item)">
                                                    <v-icon>mdi-calendar-today</v-icon>
                                                </v-btn>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title :class="{ 'primary--text': selected }">{{ item.name }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ `${item.startDate} - ${item.endDate}` }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-data-iterator>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
        <v-dialog v-model="showDelete" scrollable :width="$vuetify.breakpoint.thresholds.sm" persistent :fullscreen="$vuetify.breakpoint.xs">
            <DeleteConfirm
                hasToolbar
                deleteMessage="Sind Sie sicher, dass Sie diese Aufgabe löschen wollen?"
                :toolbarTitle="$t('Aufgabe löschen')"
                @deleteItem="handleRemove"
                @closeEmit="() => (showDelete = false)"
            />
        </v-dialog>
    </v-card>
</template>

<script>
import moment from 'moment';
import PlanToolMixin from './Mixins/PlantoolMixin';
import DeleteConfirm from '../Generic/DeleteConfirm';
import CalendarTask from './Helpers/CalendarTask';

export default {
    name: 'calendar-view',
    components: { DeleteConfirm, CalendarTask },
    mixins: [PlanToolMixin],
    data() {
        return {
            menu: false,
            day: moment().format('YYYY-MM-DD'),
            showInactiveTasks: false,
            showAllTasks: false,
            debouncer: null,
            search: '',
            searchConfirm: '',
            showDelete: false,
            permissions: { report: false }
            // selectedEvent: {}
        };
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        value(value) {
            if (Object.keys(value).length > 0) {
                this.$nextTick(() => (this.menu = true));
            }
        }
    },
    computed: {
        computedSelectedTask: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.value.hasOwnProperty('id')) {
                    if (this.value.id === value.id) {
                        this.$nextTick(() => (this.menu = true));
                    } else {
                        this.$emit('input', value);
                    }
                } else {
                    this.$emit('input', value);
                }
            }
        },
        isDeletable() {
            return this.isSelected && moment(this.value.endDate).isSameOrAfter(moment().format('YYYY-MM-DD'));
        },
        isSelected() {
            return Object.keys(this.value).length > 0;
        },
        computedSearch: {
            get() {
                return this.search;
            },
            set(value) {
                this.search = value;
                if (this.debouncer) {
                    clearTimeout(this.debouncer);
                    this.debouncer = null;
                }
                this.debouncer = setTimeout(() => {
                    this.searchConfirm = value;
                    this.debouncer = null;
                }, 1500);
            }
        },
        filteredTasks() {
            return this.getTasks.filter(this.filterInactiveTasks).filter(this.filterOwnTasks);
        },
        currentYear() {
            return moment(this.day).format('YYYY');
        },
        currentMonth() {
            return this.$t(moment(this.day).format('MMMM'));
        },
        currentMonthTasks() {
            const currentMonth = moment(this.day).format('MMMM');
            return this.filteredTasks.filter(({ startDate, endDate }) => {
                const sDate = moment(startDate).format('MMMM');
                const eDate = moment(endDate).format('MMMM');
                return sDate === currentMonth || eDate === currentMonth;
            });
        },
        today() {
            return moment().format('YYYY-MM-DD');
        }
    },
    methods: {
        eventColor(event) {
            return event.color ? event.color : 'primary';
        },
        handleEvent({ event, nativeEvent }) {
            this.selectTask(event);
            // this.selectedEvent = nativeEvent.target;
        },
        create() {
            this.$emit('switchView', { type: 'ADD', component: 'ADD_TASK' });
        },
        update() {
            this.$emit('switchView', {
                type: 'UPDATE',
                component: 'UPDATE_TASK'
            });
        },
        remove() {
            this.showDelete = true;
        },
        handleRemove() {
            this.DELETE_TASK(this.value).then(() => (this.showDelete = false));
        },
        copy() {
            this.$emit('switchView', { type: 'COPY', component: 'COPY_TASK' });
        },
        report() {
            this.$emit('switchView', { type: 'REPORT', component: 'REPORT_STATISTICS' });
        },
        goToCalendar(task) {
            this.search = '';
            this.day = task.startDate;
            this.$emit('input', task);
        },
        selectTask(task) {
            if (this.value && this.value.id && this.value.id === task.id) {
                this.$emit('input', {});
            } else {
                this.$emit('input', task);
            }
        },
        filter(items) {
            if (this.search.trim() === '') {
                return items;
            }
            let buildString = [...new Set(this.searchConfirm.split(' '))].filter((word) => word !== '');
            return items.filter((item) => {
                const values = Object.values(item)
                    .filter((val) => typeof val === 'string' || typeof val === 'number')
                    .map((val) => {
                        if (val === 'number') {
                            return val.toString();
                        }
                        return val;
                    })
                    .join(' ')
                    .toLowerCase();
                return buildString.some((word) => values.includes(word.toLowerCase()));
            });
        },
        filterInactiveTasks({ endDate }) {
            return moment(endDate).isSameOrAfter(moment(), 'day') || this.showInactiveTasks;
        },
        filterOwnTasks({ owner }) {
            return owner || this.showAllTasks;
        },
        prev() {
            this.$refs.calendar.prev();
        },
        next() {
            this.$refs.calendar.next();
        }
    },
    mounted() {
        Promise.all([this.GET_ACTIVE_TASKS(), this.GET_INACTIVE_TASKS()]);
        this.validateEditorState({ report: ['STATISTICS_MANAGEMENT'] }).then((result) => (this.permissions = result));
    }
};
</script>
<style scoped>
.v-calendar-weekly {
    display: table;
    table-layout: fixed;
}
.v-calendar-weekly__week {
    height: auto;
    display: table-row;
}
.v-calendar-weekly__day {
    display: table-cell;
    width: calc(100% / 7);
}
.v-calendar-weekly__head {
    height: auto;
    display: table-row;
}
.v-calendar-weekly__head-weekday {
    display: table-cell;
    width: calc(100% / 7);
}
.current-month {
    margin-right: 5px;
}
</style>
