<template>
    <v-container ma-0 pa-0 fluid fill-height>
        <v-layout column>
            <v-flex grow>
                <keep-alive>
                    <component
                        :is="componentSwitch"
                        :isPreview="isPreview"
                        @isPreview="handlePreview"
                        :settings="settings"
                        :onSubmit="onSubmit"
                        :onStart="handleOnStart"
                        :onLoop="onLoop"
                        :correctAnswer="answers"
                        :reason="reason"
                        @media="handleMedia"
                        transition="slide-x-transition"
                    />
                </keep-alive>
            </v-flex>
        </v-layout>
        <FMPreview :showDialog="showMedia" @showDialog="(value) => (showMedia = value)" :value="media" />
    </v-container>
</template>

<script>
import Quiz from './Helpers/Quiz';
import Explanation from './Helpers/QuizResult';
import FMPreview from '../FileManager/MediaPreview';

export default {
    name: 'quiz-app',
    components: { Quiz, Explanation, FMPreview },
    data: () => ({
        isPreview: false,
        result: {},
        answers: [],
        media: {},
        showMedia: false
    }),
    props: {
        // the "settings" Object, defining "Quiz"
        settings: {
            type: Object,
            default: () => ({})
        },
        onSubmit: {
            type: Function,
            default: () => new Promise(),
            validator: (func) => func instanceof Promise || 'onSubmit must be of type Promise'
        },
        onStart: {
            type: Function,
            default: () => new Promise(),
            validator: (func) => func instanceof Promise || 'onStart must be of type Promise'
        },
        onResult: {
            type: Function,
            default: () => new Promise(),
            validator: (func) => func instanceof Promise || 'onResult must be of type Promise'
        },
        onLoop: {
            type: Function,
            default: () => new Promise(),
            validator: (func) => func instanceof Promise || 'onLoop must be of type Promise'
        }
    },
    computed: {
        componentSwitch() {
            return this.result && this.result.complete ? Explanation : Quiz;
        },
        reason() {
            return this.result && this.result.taskDoneReason ? this.result.taskDoneReason : '';
        }
    },
    methods: {
        handlePreview(value) {
            this.isPreview = value;
        },
        handleOnStart() {
            return this.onStart().then((response) => {
                this.result = response;
                if (this.result && this.result.complete) {
                    this.onResult().then((response) => (this.answers = response.knowledgeUnitsResults));
                }
                return response;
            });
        },
        handleMedia(value) {
            this.showMedia = true;
            this.media = value;
        }
    },
    activated() {
        this.result = {};
    },
    mounted() {
        this.result = {};
    }
};
</script>
