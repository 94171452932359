export default [
    {
        text: 'Themen',
        value: 'title',
        align: 'left',
        sortable: false,
        width: '70%'
    },
    {
        text: 'Antworten',
        value: 'threadCount',
        align: 'left',
        sortable: false,
        width: '10%'
    },
    {
        text: 'Letzte Antwort',
        value: 'latestAnswer',
        align: 'left',
        sortable: false,
        width: '10%'
    },
    {
        text: 'Datum & Uhrzeit',
        value: 'lastUpdated',
        align: 'left',
        sortable: false,
        width: '10%'
    },
    {
        text: '',
        value: 'menu',
        align: 'left',
        sortable: false,
        width: 'auto'
    }
];
