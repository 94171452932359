<template>
    <v-breadcrumbs :items="getForumPath" divider=">">
        <template v-slot:item="{ item }">
            <span class="primary--text pointer" @click="onClickRoute(item)">{{ item.title }}</span>
        </template>
    </v-breadcrumbs>
</template>
<script>
import ForumMixin from '../Mixins/GroupForumMixin';
export default {
    name: 'forum-breadcrumbs',
    mixins: [ForumMixin],
    methods: {
        onClickRoute(path) {
            this.$router.replace(path.href).then(() => this.REMOVE_FORUM_PATH(path));
        }
    }
};
</script>

<style scoped>
.pointer {
    cursor: pointer;
}
</style>
